import cn from 'classnames'
import SectionHeader from 'pages/landing/landingConstructor/details/SectionHeader/SectionHeader'
import SectionWrapper from 'pages/landing/landingConstructor/details/SectionWrapper/SectionWrapper'
import TakeAPart from 'pages/landing/landingConstructor/details/TakeAPart/TakeAPart'
import SectionContent from 'pages/landing/landingConstructor/sections/programSection/SectionContent/SectionContent'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import BulletsSections from 'pages/landing/landingConstructor/sections/programSection/bulletsSections/BulletsSections/BulletsSections'
import 'pages/landing/landingConstructor/sections/programSection/ProgramSection/ProgramSection.scss'

// Секция одностраничника с рассказом с пунктами что должно быть и не быть у ученика
function ProgramSection() {
	const landing = useLandingConstructorStore((store) => store.landing)
	const { colorScheme } = landing

	return (
		<SectionWrapper
			extraClass={cn(
				'land-const-program-section',
				'land-const-program-section--' + colorScheme
			)}
		>
			<SectionHeader
				imagePropsPathArr={[
					'sections',
					'program',
					'header',
					'imageUrl',
				]}
				headerPropsPathArr={['sections', 'program', 'header', 'text']}
				white
			/>
			<div>
				<SectionContent />
				<BulletsSections />
				<TakeAPart sectionName="program" />
			</div>
		</SectionWrapper>
	)
}

export default ProgramSection
