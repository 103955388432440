import React from 'react'
import cn from 'classnames'
import EditableText from 'pages/landing/landingConstructor/details/editableElems/EditableText/EditableText'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import 'pages/landing/landingConstructor/sections/waysSections/WaysHeader/WaysHeader.scss'

// Секция одностраничника с частозадаваемыми вопросами
function WaysHeader() {
	const landing = useLandingConstructorStore((store) => store.landing)
	const { colorScheme } = landing

	const headerClasses = [
		'land-const-ways-section-header',
		'land-const-ways-section-header--' + colorScheme,
	]

	return (
		<h2 className={cn(headerClasses)}>
			<EditableText
				propsPathArr={['sections', 'ways', 'header', 'text']}
				placeholder="Заголовок раздела"
			/>
		</h2>
	)
}

export default WaysHeader
