import { Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import useAddSchoolModalStore from './zustand/store'
import { useSetVisibilityToAddSchoolModal } from './fn/openCloseAddSchoolModal'
import ModalWithForm from 'ui/ModalWithForm/ModalWithForm'
import {
	onSchoolDomainInputChange,
	useManageSchoolDomainInput,
} from './fn/form'
import {
	UFormNewBottomButtonsAfterGray,
	UFormNewGeneralError,
	UFormNewInputCaption,
	UFormNewRow,
} from 'ui/UForm/UForm'
import { useGetOnSubmit } from './fn/submit'
import { setErrorsToForm } from 'pages/student/profile/AccountForm/fn/form'
import Site from 'parts/constants/site'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'
import { FieldsNames } from './fn/form'
import UrlService from 'parts/services/UrlService'

function AddSchoolModal() {
	const { t } = useTranslation()
	const [form] = Form.useForm()

	const modalState = useAddSchoolModalStore((state) => state)

	const closeModal = useSetVisibilityToAddSchoolModal(false)

	const onSubmit = useGetOnSubmit(form)
	setErrorsToForm(form)

	return (
		<ModalWithForm
			title={t('mySchools.addSchoolModalHeader')}
			open={modalState.isOpen}
			onCancel={closeModal}
		>
			<Form
				form={form}
				name="addSchool"
				onFinish={onSubmit}
				layout="vertical"
			>
				<GrayBlock>
					<SchoolNameInput />
					<SchoolDomainInput />
				</GrayBlock>
				<UFormNewGeneralError message={modalState.formErrors.message} />
				<Bottom />
			</Form>
		</ModalWithForm>
	)
}

export default AddSchoolModal

// Обычное текстовое поле
function SchoolNameInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow>
			<Form.Item
				name={FieldsNames.SchoolName}
				label={t('mySchools.addSchoolModalFieldSchoolNameLabel')}
				rules={[
					{
						required: true,
						message: t(
							'mySchools.addSchoolModalFieldSchoolNameRequired'
						),
					},
				]}
			>
				<Input type="text" />
			</Form.Item>
		</UFormNewRow>
	)
}

// Домен школы
function SchoolDomainInput() {
	const { t } = useTranslation()
	const form = Form.useFormInstance()

	// Следить за изменениями поля названия школы и писать в это поле транслитерированное значение
	useManageSchoolDomainInput()

	const rootDomain = UrlService.getRootDomainFromUrl()

	return (
		<>
			<UFormNewRow topOffset>
				<Form.Item
					name={FieldsNames.Domain}
					label={t('mySchools.addSchoolModalFieldDomainLabel')}
				>
					<Input
						addonBefore={Site.protocol}
						addonAfter={rootDomain}
						type="text"
						onChange={(e) => {
							onSchoolDomainInputChange(e, form)
						}}
					/>
				</Form.Item>
			</UFormNewRow>
			<UFormNewInputCaption>
				{t('mySchools.addSchoolModalFieldDomainNote')}
			</UFormNewInputCaption>
		</>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom() {
	const { t } = useTranslation()
	const form = Form.useFormInstance()

	const modalState = useAddSchoolModalStore((state) => state)
	const closeModal = useSetVisibilityToAddSchoolModal(false)

	return (
		<UFormNewBottomButtonsAfterGray>
			<Button
				disabled={
					modalState.isSubmitting ||
					form.getFieldsError().filter(({ errors }) => errors.length)
						.length > 0
				}
				onClick={closeModal}
			>
				{t('mySchools.addSchoolModalCancelButton')}
			</Button>

			<Form.Item shouldUpdate>
				{() => {
					const formErrors = form
						.getFieldsError()
						.filter(({ errors }) => errors.length)

					return (
						<Button
							type="primary"
							htmlType="submit"
							disabled={
								modalState.isSubmitting || formErrors.length > 0
							}
							loading={modalState.isSubmitting}
						>
							{t('mySchools.addSchoolModalOkButton')}
						</Button>
					)
				}}
			</Form.Item>
		</UFormNewBottomButtonsAfterGray>
	)
}
