import { Button, Tag } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { BuildOutlined, TeamOutlined } from '@ant-design/icons'
import AppUrls from 'parts/constants/pageUrl'
import Avatar from 'ui/Avatar/Avatar'
import { UTableBCell, UTableBRow, UTableTextWithIcon } from 'ui/UTable/UTable'
import { useGetDeleteUser } from './fn/deleteUser'
import { GroupsStoreType } from '../zustand/storeTypes'

type TableRowProps = {
	employee: GroupsStoreType.Employee
}

// Ряд таблицы сотрудников
function TableRow(props: TableRowProps) {
	const { employee } = props

	const userPageUrl = AppUrls.Employee_Works(employee.id).url

	return (
		<UTableBRow bgOnHover>
			<UTableBCell href={userPageUrl} key="fullName">
				<div className="staff-table__name-wrapper">
					<Avatar src={employee.avatar} width={24} />
					<p className="staff-table__text">{employee.fullName}</p>
				</div>
			</UTableBCell>
			<UTableBCell href={userPageUrl} key="role">
				<p className="staff-table__text">{employee.role}</p>
			</UTableBCell>
			<UTableBCell href={userPageUrl} key="phone">
				<p className="staff-table__text">{employee.phone}</p>
			</UTableBCell>
			<UTableBCell href={userPageUrl} key="email">
				{employee.email && (
					<UTableTextWithIcon
						text={employee.email}
						Icon={TeamOutlined}
					/>
				)}
			</UTableBCell>
			<UTableBCell href={userPageUrl} key="groupsNum">
				{employee.groupsNum > 0 && (
					<UTableTextWithIcon
						text={employee.groupsNum}
						Icon={BuildOutlined}
					/>
				)}
			</UTableBCell>
			<UTableBCell href={userPageUrl} key="activity">
				{employee.isActivated ? (
					<Tag color="blue">Активный</Tag>
				) : (
					<Tag color="red">Не активный</Tag>
				)}
			</UTableBCell>
			<UTableBCell href={userPageUrl} key="delete">
				<DeleteButton employeeId={employee.id} />
			</UTableBCell>
		</UTableBRow>
	)
}

export default TableRow

type DeleteButtonProps = {
	employeeId: number
}

// Кнопка удаления сотрудника
function DeleteButton(props: DeleteButtonProps) {
	const { employeeId } = props

	const deleteUser = useGetDeleteUser(employeeId)

	return (
		<Button
			icon={<DeleteOutlined />}
			type="text"
			danger
			onClick={deleteUser}
			size="small"
		/>
	)
}
