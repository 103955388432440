import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import courseTeachEn from '../../parts/i18n/en/courseTeachEn'
import { Course, ExpressLesson, TextBlockType } from './types'

export function useGetExpressCourse(courseId: 'how-teach' | 'how-create') {
	const { t } = useTranslation()

	const ExpressCourses: Course[] = [
		{
			id: 'how-teach',
			title: t('courseTeach.mainTitle'),
			subtitle: t('courseTeach.mainSubtitle'),
			image: '/images/express/lesson-2.png',
			lessons: getHowTeachLessons(t),
		},
		{
			id: 'how-create',
			title: t('courseCreate.mainTitle'),
			subtitle: t('courseCreate.mainSubtitle'),
			image: '/images/express/lesson-1.png',
			lessons: getHowCreateLessons(t),
		},
	]

	return ExpressCourses.find((course) => course.id === courseId)
}

export function getHowTeachLessons(t: TFunction<'translation', undefined>) {
	const ExpressLessonHowTeach: ExpressLesson[] = [
		{
			no: 1,
			title: {
				title: t('courseTeach.lesson1Title'),
				subtitle: t('courseTeach.lesson1Subtitle'),
				image: '/images/express/how_teach/cover-1.svg',
			},
			blocks: [
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson1Block1'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson1Block2'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson1Block3'),
				},
				{
					blockType: TextBlockType.Alert,
					content: t('courseTeach.lesson1Block4'),
				},
			],
		},
		{
			no: 2,
			title: {
				title: t('courseTeach.lesson2Title'),
				subtitle: t('courseTeach.lesson2Subtitle'),
				image: '/images/express/how_teach/cover-2.svg',
			},
			blocks: [
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson2Block1'),
				},
				{
					blockType: TextBlockType.Alert,
					content: t('courseTeach.lesson2Block2'),
				},
			],
		},
		{
			no: 3,
			title: {
				title: t('courseTeach.lesson3Title'),
				subtitle: t('courseTeach.lesson3Subtitle'),
				image: '/images/express/how_teach/cover-3.svg',
			},
			blocks: [
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson3Block1'),
				},
				{
					blockType: TextBlockType.Alert,
					content: t('courseTeach.lesson3Block2'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson3Block3'),
				},
				{
					blockType: TextBlockType.Video,
					content: 'https://youtu.be/JTzqqUa-xWg',
				},
			],
		},
		{
			no: 4,
			title: {
				title: t('courseTeach.lesson4Title'),
				subtitle: t('courseTeach.lesson4Subtitle'),
				image: '/images/express/how_teach/cover-4.svg',
			},
			blocks: [
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson4Block1'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson4Block2'),
				},
			],
		},
		{
			no: 5,
			title: {
				title: t('courseTeach.lesson5Title'),
				subtitle: t('courseTeach.lesson5Subtitle'),
				image: '/images/express/how_teach/cover-5.svg',
			},
			blocks: [
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson5Block1'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson5Block2'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson5Block3'),
				},
			],
		},
		{
			no: 6,
			title: {
				title: t('courseTeach.lesson6Title'),
				subtitle: t('courseTeach.lesson6Subtitle'),
				image: '/images/express/how_teach/cover-6.svg',
			},
			blocks: [
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson6Block1'),
				},
			],
		},
		{
			no: 7,
			title: {
				title: t('courseTeach.lesson7Title'),
				subtitle: t('courseTeach.lesson7Subtitle'),
				image: '/images/express/how_teach/cover-7.svg',
			},
			blocks: [
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson7Block1'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson7Block2'),
				},
			],
		},
		{
			no: 8,
			title: {
				title: t('courseTeach.lesson8Title'),
				subtitle: t('courseTeach.lesson8Subtitle'),
				image: '/images/express/how_teach/cover-8.svg',
			},
			blocks: [
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson8Block1'),
				},
				{
					blockType: TextBlockType.Alert,
					content: t('courseTeach.lesson8Block2'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson8Block3'),
				},
			],
		},
		{
			no: 9,
			title: {
				title: t('courseTeach.lesson9Title'),
				subtitle: t('courseTeach.lesson9Subtitle'),
				image: '/images/express/how_teach/cover-9.svg',
			},
			blocks: [
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson9Block1'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson9Block2'),
				},
			],
		},
		{
			no: 10,
			title: {
				title: t('courseTeach.lesson10Title'),
				subtitle: t('courseTeach.lesson10Subtitle'),
				image: '/images/express/how_teach/cover-10.svg',
			},
			blocks: [
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson10Block1'),
				},
				{
					blockType: TextBlockType.Alert,
					content: t('courseTeach.lesson10Block2'),
				},
			],
		},
		{
			no: 11,
			title: {
				title: t('courseTeach.lesson11Title'),
				subtitle: t('courseTeach.lesson11Subtitle'),
				image: '/images/express/how_teach/cover-11.svg',
			},
			blocks: [
				{
					blockType: TextBlockType.SubTitle,
					content: t('courseTeach.lesson11Block1'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson11Block2'),
				},
				{
					blockType: TextBlockType.SubTitle,
					content: t('courseTeach.lesson11Block3'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson11Block4'),
				},
				{
					blockType: TextBlockType.SubTitle,
					content: t('courseTeach.lesson11Block5'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson11Block6'),
				},
				{
					blockType: TextBlockType.SubTitle,
					content: t('courseTeach.lesson11Block7'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson11Block8'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson11Block9'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson11Block10'),
				},
				{
					blockType: TextBlockType.SubTitle,
					content: t('courseTeach.lesson11Block11'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson11Block12'),
				},
				{
					blockType: TextBlockType.SubTitle,
					content: t('courseTeach.lesson11Block13'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseTeach.lesson11Block14'),
				},
			],
		},
	]

	return ExpressLessonHowTeach
}

export function getHowCreateLessons(t: TFunction<'translation', undefined>) {
	const ExpressLessonHowCreate: ExpressLesson[] = [
		{
			no: 1,
			title: {
				title: t('courseCreate.lesson1Title'),
				subtitle: t('courseCreate.lesson1Subtitle'),
				image: '/images/express/how_create/cover-1.png',
			},
			blocks: [
				{
					blockType: TextBlockType.Alert,
					content: t('courseCreate.lesson1Block1'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson1Block2'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson1Block3'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson1Block4'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson1Block5'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson1Block6'),
				},
				{
					blockType: TextBlockType.Video,
					content: 'https://youtu.be/vRe0B05rh88',
				},
			],
		},
		{
			no: 2,
			title: {
				title: '',
				subtitle: '',
				image: '/images/express/how_create/cover-2.png',
			},
			blocks: [
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson2Block1'),
				},
				{
					blockType: TextBlockType.SubTitle,
					content: t('courseCreate.lesson2Block2'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson2Block3'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson2Block4'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson2Block5'),
				},
				{
					blockType: TextBlockType.Alert,
					content: t('courseCreate.lesson2Block6'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson2Block7'),
				},
				{
					blockType: TextBlockType.SubTitle,
					content: t('courseCreate.lesson2Block8'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson2Block9'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson2Block10'),
				},
				{
					blockType: TextBlockType.List,
					content: [
						t('courseCreate.lesson2Block11_1'),
						t('courseCreate.lesson2Block11_2'),
						t('courseCreate.lesson2Block11_3'),
						t('courseCreate.lesson2Block11_4'),
						t('courseCreate.lesson2Block11_5'),
						t('courseCreate.lesson2Block11_6'),
						t('courseCreate.lesson2Block11_7'),
					],
				},
				{
					blockType: TextBlockType.SubTitle,
					content: t('courseCreate.lesson2Block12'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson2Block13'),
				},
			],
		},
		{
			no: 3,
			title: {
				title: t('courseCreate.lesson3Title'),
				subtitle: t('courseCreate.lesson3Subtitle'),
				image: '/images/express/how_create/cover-8.png',
			},
			blocks: [
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson3Block1'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson3Block2'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson3Block3'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson3Block4'),
				},
				{
					blockType: TextBlockType.Video,
					content: 'https://youtu.be/OhWBi-uHHhY',
				},
			],
		},
		{
			no: 4,
			title: {
				title: t('courseCreate.lesson4Title'),
				subtitle: t('courseCreate.lesson4Subtitle'),
				image: '/images/express/how_create/cover-3.png',
			},
			blocks: [
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson4Block1'),
				},
				{
					blockType: TextBlockType.Alert,
					content: t('courseCreate.lesson4Block2'),
				},
				{
					blockType: TextBlockType.Video,
					content: 'https://youtu.be/OhWBi-uHHhY',
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson4Block3'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson4Block4'),
				},
				{
					blockType: TextBlockType.Alert,
					content: t('courseCreate.lesson4Block5'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson4Block6'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson4Block7'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson4Block8'),
				},
				{
					blockType: TextBlockType.Video,
					content: 'https://youtu.be/pda7oso18ns',
				},
			],
		},
		{
			no: 5,
			title: {
				title: t('courseCreate.lesson5Title'),
				subtitle: t('courseCreate.lesson5Subtitle'),
				image: '/images/express/how_create/cover-4.png',
			},
			blocks: [
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson5Block1'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson5Block2'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson5Block3'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson5Block4'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson5Block5'),
				},
				{
					blockType: TextBlockType.Video,
					content: 'https://youtu.be/qdt681q8DXk',
				},
			],
		},
		{
			no: 6,
			title: {
				title: t('courseCreate.lesson6Title'),
				subtitle: t('courseCreate.lesson6Subtitle'),
				image: '/images/express/how_create/cover-5.png',
			},
			blocks: [
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson6Block1'),
				},
				{
					blockType: TextBlockType.Alert,
					content: t('courseCreate.lesson6Block2'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson6Block3'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson6Block4'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson6Block5'),
				},
				{
					blockType: TextBlockType.SubTitle,
					content: t('courseCreate.lesson6Block6'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson6Block7'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson6Block8'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson6Block9'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson6Block10'),
				},
				{
					blockType: TextBlockType.Video,
					content: 'https://youtu.be/sFdg3_14cm8',
				},
				{
					blockType: TextBlockType.SubTitle,
					content: t('courseCreate.lesson6Block11'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson6Block12'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson6Block13'),
				},
				{
					blockType: TextBlockType.Alert,
					content: t('courseCreate.lesson6Block14'),
				},
			],
		},
		{
			no: 7,
			title: {
				title: t('courseCreate.lesson7Title'),
				subtitle: t('courseCreate.lesson7Subtitle'),
				image: '/images/express/how_create/cover-6.png',
			},
			blocks: [
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson7Block1'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson7Block2'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson7Block3'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson7Block4'),
				},
				{
					blockType: TextBlockType.Alert,
					content: t('courseCreate.lesson7Block5'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson7Block6'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson7Block7'),
				},
				{
					blockType: TextBlockType.Alert,
					content: t('courseCreate.lesson7Block8'),
				},
				{
					blockType: TextBlockType.Video,
					content: 'https://youtu.be/wsEUuvSTJb4',
				},
			],
		},
		{
			no: 8,
			title: {
				title: t('courseCreate.lesson8Title'),
				subtitle: t('courseCreate.lesson8Subtitle'),
				image: '/images/express/how_create/cover-7.png',
			},
			blocks: [
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson8Block1'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson8Block2'),
				},
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson8Block3'),
				},
				{
					blockType: TextBlockType.Alert,
					content: t('courseCreate.lesson8Block4'),
				},
				{
					blockType: TextBlockType.Video,
					content: 'https://youtu.be/SHxiMKg2sL8',
				},
			],
		},

		{
			no: 9,
			title: {
				title: t('courseCreate.lesson9Title'),
				subtitle: t('courseCreate.lesson9Subtitle'),
				image: '/images/express/how_create/cover-9.svg',
			},
			blocks: [
				{
					blockType: TextBlockType.Text,
					content: t('courseCreate.lesson9Block1'),
				},
			],
		},
	]

	return ExpressLessonHowCreate
}
