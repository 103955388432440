import React from 'react'
import { useTranslation } from 'react-i18next'
import { WritingOrAudioAnswerWasSentFn } from '../../common/useLiftViewDuration'
import AudioPlayer from '../AudioPlayer/AudioPlayer'
import { needToShowDictaphone, useManagePlayers } from './fn/main'
import SuccessMessage from '../SuccessMessage/SuccessMessage'
import './AudioAnswerBlock.scss'

type AudioAnswerBlockProps = {
	// Запуск этой функции сообщает, что ответ был послан
	writingOrAudioAnswerWasSentFn?: WritingOrAudioAnswerWasSentFn
}

// Блок с микрофоном для записи и отправке голосового ответа
function AudioAnswerBlock(props: AudioAnswerBlockProps) {
	const { writingOrAudioAnswerWasSentFn } = props

	const { t } = useTranslation()

	const {
		state,
		onRecord,
		onStopRecord,
		onPlay,
		onPause,
		onDelete,
		onSend,
		audioSent,
	} = useManagePlayers(writingOrAudioAnswerWasSentFn)

	if (audioSent) {
		return <SuccessMessage />
	}

	const showDictaphone = needToShowDictaphone(state)

	return (
		<section className="exercise-audio-audio-answer">
			{showDictaphone ? (
				<AudioPlayer
					lifeStatus={state.dictaphone.lifeStatus}
					seconds={state.dictaphone.seconds}
					onRecord={() => onRecord()}
					onStopRecord={() => onStopRecord()}
					onPlay={() => {}}
					onPause={() => {}}
					onDelete={() => {}}
					onSend={() => {}}
				/>
			) : (
				<MaxPlayersHelpNote />
			)}
			{state.players.length > 0 && (
				<>
					<h3 className="exercise-audio-audio-answer__draft-header">
						Черновики
					</h3>
					<div className="exercise-audio-audio-answer__players">
						{state.players.map((playerData) => {
							return (
								<AudioPlayer
									lifeStatus={playerData.lifeStatus}
									seconds={playerData.seconds}
									onRecord={() => {}}
									onStopRecord={() => {}}
									onPlay={() => onPlay(playerData.id)}
									onPause={() => onPause(playerData.id)}
									onDelete={() => onDelete(playerData.id)}
									onSend={() => onSend(playerData.id)}
									key={playerData.id}
								/>
							)
						})}
					</div>
				</>
			)}
		</section>
	)
}

export default AudioAnswerBlock

// Подсказка если уже сделали три записи
function MaxPlayersHelpNote() {
	return (
		<p className="exercise-audio-audio-answer__help-note">
			Чтобы сделать ещё одну запись удалите одну из предыдущих.
		</p>
	)
}
