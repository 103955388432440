import { useMemo } from 'react'
import { createFileListFromUrlList } from 'ui/upload/UploadFileInForm/fn/fn'
import useLessonPageHeaderAdminStore from '../../zustand/store'

/**
 * Возвращает адрес существующей обложки курса для поля загрузки изображения.
 */
export function useGetCoverSrc() {
	const lesson = useLessonPageHeaderAdminStore((state) => state.lessonAdmin)

	return useMemo(
		function () {
			if (!lesson) {
				return []
			}

			return lesson.cover ? createFileListFromUrlList([lesson.cover]) : []
		},
		[lesson]
	)
}
