import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import useMenuStore from 'app/menu/zustand/menuStore'
import { useGetUserRole, UserRolesObj } from 'parts/utils/hooks/hooks'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import RestrictionsService from 'parts/services/RestrictionsService'
import {
	adminMenuItems,
	curatorMenuItems,
	managerMenuItems,
	studentMenuItems,
} from './menuItems'
import useSystemStore from 'parts/systemStore/systemStore'

/** Возвращает массив пунктов меню */
export function useGetMenuItems() {
	const userRoles = useGetUserRole()
	const school = useSystemStore((store) => store.school)
	const unansweredFeedCount = useMenuStore(
		(store) => store.unansweredFeedCount
	)

	const canUseCRM = RestrictionsService.useCanUseCRM()

	if (!userRoles.rolesGot) return []

	return getMenuItems(userRoles, canUseCRM, school.id, unansweredFeedCount)
}

/** Возвращает массив адресов, которые должны быть выделены в меню исходя из текущего адреса */
export function useGetSelectedMenuItems(): string[] {
	const { pathname } = location
	const userRoles = useGetUserRole()
	const school = useSystemStore((store) => store.school)

	const unansweredFeedCount = useMenuStore(
		(store) => store.unansweredFeedCount
	)

	const canUseCRM = RestrictionsService.useCanUseCRM()

	const menuItems = getMenuItems(
		userRoles,
		canUseCRM,
		school.id,
		unansweredFeedCount
	)

	const item = menuItems.find((item) => {
		// @ts-ignore
		return pathname.startsWith(item.key)
	})

	if (!item) return ['']

	// @ts-ignore
	return [item.key]
}

/**
 * Возвращает пункты меню исходя из роли пользователя
 * @param userRoles — объект с ролью пользователя
 * @param unansweredFeedCount — количество неотвеченных ответов
 */
export function getMenuItems(
	userRoles: UserRolesObj,
	canUseCRM: boolean,
	schoolId: number,
	unansweredFeedCount?: number
): ItemType[] {
	if (userRoles.isAdmin) {
		return adminMenuItems(canUseCRM, Number(unansweredFeedCount), schoolId)
	} else if (userRoles.isManager) {
		return managerMenuItems(
			canUseCRM,
			Number(unansweredFeedCount),
			schoolId
		)
	} else if (userRoles.isCurator) {
		return curatorMenuItems(
			canUseCRM,
			Number(unansweredFeedCount),
			schoolId
		)
	} else {
		return studentMenuItems()
	}
}

/** Обработчик щелчка по пункту меню */
export function useGetHandleMenuClick(canUseCRM: boolean) {
	const navigate = useNavigate()
	const userRoles = useGetUserRole()
	const school = useSystemStore((store) => store.school)

	return useCallback(
		function (e: any) {
			const menuItems = getMenuItems(userRoles, canUseCRM, school.id)

			// @ts-ignore
			const menuItem = menuItems.find((item) => item.key === e.key)
			if (!menuItem) return

			// @ts-ignore
			navigate(menuItem.key)

			// Закрыть телефонное меню
			useMenuStore.getState().updateStore({ isMobileMenuOpen: false })
		},
		[userRoles]
	)
}
