export enum FormHelpSections {
	Default = 'default',
	Start = 'start',
	Mode = 'mode',
	Open = 'open',
	Duration = 'duration',
	Access = 'access',
}

// Тип данных по которым будет генерироваться разметка справки исходя из выделенного поля
namespace FormHelpType {
	export type Elements = (CardHeader | Text | List | ExampleCard)[]

	// Заголовок карточки
	export type CardHeader = {
		type: 'header'
		text: string
	}

	// Текст
	export type Text = {
		type: 'text'
		text: string
	}

	export type List = {
		type: 'list'
		textBefore?: string
		items: string[]
	}

	// Текст
	export type ExampleCard = {
		type: 'exampleCard'
		header1?: string
		header2?: string
		iconType: 'cup' | 'book'
		texts: Text[]
	}
}

export default FormHelpType
