import cn from 'classnames'
import { ArrowRightOutlined } from '@ant-design/icons'
import './DatesRange.scss'

type DatesRangeProps = {
	// Начальная дата
	dateFrom: string
	// Конечная дата
	dateTo?: null | string
	// Должны ли флаги быть цветными.
	// В противном случае будет оттенками серого цвета.
	colored?: boolean
	extraClass?: string
}

// Компонент с флагами и датами
function DatesRange(props: DatesRangeProps) {
	const { dateFrom, dateTo, colored = false, extraClass } = props

	return (
		<div
			className={cn(
				'dates-range',
				colored && 'dates-range--black',
				extraClass
			)}
		>
			<span className="dates-range__date">
				<img
					className="dates-range__flag"
					src={
						colored
							? '/images/signs/FlagBlue.svg'
							: '/images/signs/FlagTwoTone.svg'
					}
					alt="arrow"
				/>
				<span>{dateFrom}</span>
			</span>
			{dateTo && <ArrowRightOutlined />}
			{dateTo && (
				<span className="dates-range__date">
					<img
						className="dates-range__flag"
						src={
							colored
								? '/images/signs/FlagGreen.svg'
								: '/images/signs/FlagTwoTone.svg'
						}
						alt="arrow"
					/>
					<span>{dateTo}</span>
				</span>
			)}
		</div>
	)
}

export default DatesRange
