import SectionHeader from 'pages/landing/landingBuilder/details/SectionHeader/SectionHeader'
import SectionWrapper from 'pages/landing/landingBuilder/details/SectionWrapper/SectionWrapper'
import TakeAPart from '../../../details/TakeAPart/TakeAPart'
import useLandingBuilderStore from '../../../zustand/store'
import PortraitBlocks from '../PortraitBlocks/PortraitBlocks'
import './PortraitSection.scss'

// Секция одностраничника с рассказом для кого предназначается курс
function PortraitSection() {
	const landing = useLandingBuilderStore((store) => store.landing)

	return (
		<SectionWrapper>
			<SectionHeader
				text={landing.sections.portrait.header.text}
				imageSrc={landing.sections.portrait.header.imageUrl}
			/>
			<div>
				<div className="landing-portrait-section">
					<PortraitBlocks />
				</div>
				<TakeAPart sectionName="portrait" />
			</div>
		</SectionWrapper>
	)
}

export default PortraitSection
