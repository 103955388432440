// Страница списка курсов
const adminTrainingsRu = {
	// ЭКРАН СООБЩАЮЩИЙ ПРО ОТСУТСТВИЕ СОЗДАННЫХ КУРСОВ
	// Верхняя надпись сообщения про отсутствие созданных курсов
	noTrainingCreatedFirstLine:
		'В этом разделе, Вы можете создать курсы, марафоны, тренинги.',
	// Нижняя надпись сообщения про отсутствие созданных курсов
	noTrainingCreatedSecondLine:
		'Давайте сделаем это прямо сейчас. Нажмите кнопку "Добавить".',
	// Сообщение на странице со списком курсов для менеджера и куратора
	noTrainingCreatedInCuratorPage:
		'На этой странице отобразятся курсы, которые создаст администратор школы',

	// Надпись на кнопке добавления курса
	addTrainingButton: 'Добавить',

	// МОДАЛЬНОЕ ОКНО ДОБАВЛЕНИЯ КУРСА
	// Заголовок модального окна добавления курса
	addTrainingModalTitle: 'Добавление курса',
	editTrainingModalTitle: 'Редактирование курса',
	// Подпись к полю с названием создаваемого курса
	addTrainingModalFieldTrainingNameLabel: 'Название',
	// Текст ошибки в поле с названием создаваемого курса
	addTrainingModalFieldTrainingNameError: 'Введите название курса.',
	// Подпись к полю с описанием создаваемого курса
	addTrainingModalFieldTrainingDescriptionLabel: 'Описание',
	// Подпись к полю с отображением курса на главной странице
	addTrainingModalFieldTrainingShowOnMainLabel: 'Мобильное приложение',
	addTrainingModalFieldTrainingShowOnMainCaption:
		'Отобразить на главном экране',
	// Подпись к полю с обложкой создаваемого курса
	addTrainingModalFieldTrainingCoverLabel: 'Обложка',
	// Подпись к полю с типом курса
	addTrainingModalFieldTrainingTypeLabel: 'Тип курса',
	// Подпись к полю с уровнем курса
	addTrainingModalFieldTrainingLevelLabel: 'Уровень',
	// Подпись к полю язык курса
	addTrainingModalFieldTrainingLangLabel: 'Язык курса',
	// Порядковый номер курса
	addTrainingModalFieldTrainingOrderLabel: 'порядковый № курса',
	// Кнопка отмены создания курса
	addTrainingModalCancelButton: 'Отмена',
	// Кнопка создания курса
	addTrainingModalOkButton: 'Сохранить',
	// Сообщение про невозможность создания курса из-за ошибки
	addTrainingModalCannotCreateDueError: 'Не удалось создать курс.',
	// Сообщение про успешное создание курса
	addTrainingModalTrainingCreatedMessage: 'Курс добавлен.',
	// Сообщение об успешном изменении курса
	editTrainingModalTrainingCreatedMessage: 'Курс обновлен.',
	// Надпись карточке добавления нового курса
	addTrainingButtonCard: 'Добавить курс',

	// КАРТОЧКА КУРСА
	// Сообщение про неудачную попытку удаления курса
	trainingCardCannotDeleteTrainingError: 'Не удалось удалить курс',
	// Сообщение про успешное удаление курса
	trainingCardTrainingDeletedMessage: 'Курс удалён',
	// Заголовок модального окна подтверждения удаления курса
	trainingCardDeleteModalHeader: 'Удаление курса',
	// Текст модального окна подтверждения удаления курса
	trainingCardDeleteModalText: 'Вы уверены, что хотите удалить курс?',
	// Кнопка «Группы»
	trainingCardGroupsButton: 'Группы',
	// Кнопка «Настройки групп»
	trainingCardGroupSettingsButton: 'Настройки групп',
	// Кнопка удаления курса
	trainingCardDeleteTrainingButton: 'Удалить',
}

export default adminTrainingsRu
