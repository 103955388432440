import { Button, Form, Input, Radio, Divider, UploadFile } from 'antd'
import useSystemStore from 'parts/systemStore/systemStore'
import { RemoveUserModalController } from '../RemoveUserModal/RemoveUserModalController'
import {
	dateFormat,
	FieldNames,
	getInitialValues,
	setErrorsToForm,
} from './fn/form'
import {
	UFormNewLRParts,
	UFormNewWhiteWrapper,
	UFormNewRow,
	UFormNewGeneralError,
	UFormNewBottomButtonsWithBorder,
	UFormNewButtonsContainer,
} from 'ui/UForm/UForm'
import { useGetOnSubmit } from './fn/submit'
import DatePicker from 'ui/DatePicker/DatePicker'
import { regExps } from 'parts/utils/regexp'
import Site from 'parts/constants/site'
import UploadAvatar from 'ui/upload/UploadFileInForm/UploadAvatar'
import useChangeEmailStore from '../../modal/ChangeEmailModal/state/store'
import useChangePasswordStore from '../../modal/ChangePasswordModal/zustand/store'
import useProfileModalStore from './zustand/store'

// Пример формы регистрации
function AccountForm() {
	const user = useSystemStore((store) => store.user)

	const [form] = Form.useForm()
	setErrorsToForm(form)
	const initialValues = getInitialValues(user)
	const onSubmit = useGetOnSubmit()

	const formErrors = useProfileModalStore((store) => store.formErrors)

	return (
		<Form
			form={form}
			name="register"
			initialValues={initialValues}
			onFinish={onSubmit}
			layout="vertical"
		>
			<UFormNewWhiteWrapper>
				<UFormNewLRParts>
					<AvatarInput
						initialAvatarUrls={initialValues[FieldNames.Avatar]}
					/>
					<>
						<FirstNameInput />
						<LastNameInput />
						<BirthDayAndGenderInput />
						<Divider />
						<ChangeEmailAndPassword />
						<Divider />
						<CountryInput />
						<PhoneInput />
					</>
				</UFormNewLRParts>
				<Bottom />
				<UFormNewGeneralError message={formErrors.message} />
			</UFormNewWhiteWrapper>
		</Form>
	)
}

export default AccountForm

type AvatarInputProps = {
	initialAvatarUrls: UploadFile[]
}

// Поле для загрузки фотографии пользователя
function AvatarInput(props: AvatarInputProps) {
	const { initialAvatarUrls } = props

	return (
		<Form.Item name={FieldNames.Avatar}>
			<UploadAvatar
				inputName={FieldNames.Avatar}
				initialFileList={initialAvatarUrls}
				maxCount={1}
			/>
		</Form.Item>
	)
}

function FirstNameInput() {
	return (
		<UFormNewRow>
			<Form.Item name={FieldNames.FirstName} label="Имя">
				<Input type="text" />
			</Form.Item>
		</UFormNewRow>
	)
}

function LastNameInput() {
	return (
		<UFormNewRow topOffset>
			<Form.Item name={FieldNames.LastName} label="Фамилия">
				<Input type="text" />
			</Form.Item>
		</UFormNewRow>
	)
}

function BirthDayAndGenderInput() {
	return (
		<UFormNewRow topOffset>
			<Form.Item name={FieldNames.Birthday} label="Дата рождения:">
				<DatePicker
					placeholder="Выберите дату"
					format={dateFormat}
					className="student-profile-account__birth-date-input"
				/>
			</Form.Item>
			<Form.Item name="gender" label="Пол">
				<Radio.Group>
					<Radio value="male">Мужской</Radio>
					<Radio value="female">Женский</Radio>
				</Radio.Group>
			</Form.Item>
		</UFormNewRow>
	)
}

function ChangeEmailAndPassword() {
	const user = useSystemStore((store) => store.user)

	const openChangeEmailModal = useChangeEmailStore((state) => state.openModal)
	const openChangePasswordModal = useChangePasswordStore(
		(state) => state.openModal
	)

	return (
		<UFormNewRow topOffset>
			<Button onClick={() => openChangeEmailModal(user.id)}>
				Изменение почты...
			</Button>
			<Button onClick={() => openChangePasswordModal(user.id)}>
				Изменение пароля...
			</Button>
		</UFormNewRow>
	)
}

function CountryInput() {
	return (
		<UFormNewRow topOffset>
			<Form.Item label="Страна" name={FieldNames.Country}>
				<Input type="text" />
			</Form.Item>
			<Form.Item label="Город" name={FieldNames.City}>
				<Input type="text" />
			</Form.Item>
		</UFormNewRow>
	)
}

function PhoneInput() {
	return (
		<UFormNewRow topOffset>
			<Form.Item
				rules={[
					{
						pattern: regExps.phone,
						message:
							'Телефон в неправильном формате. Пример: ' +
							Site.examplePhone,
					},
				]}
				name={FieldNames.Phone}
				label="Телефон"
			>
				<Input />
			</Form.Item>
		</UFormNewRow>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom() {
	const form = Form.useFormInstance()
	const isSubmitting = useProfileModalStore((store) => store.isSubmitting)

	return (
		<UFormNewBottomButtonsWithBorder>
			<Button type="text" onClick={RemoveUserModalController.open}>
				Удалить учётную запись
			</Button>
			<UFormNewButtonsContainer>
				<Button
					disabled={isSubmitting}
					onClick={() => form.resetFields()}
				>
					Отменить
				</Button>
				<Form.Item shouldUpdate>
					{() => {
						const formErrors = form
							.getFieldsError()
							.filter(({ errors }) => errors.length)

						return (
							<Button
								block
								type="primary"
								htmlType="submit"
								disabled={isSubmitting || formErrors.length > 0}
								loading={isSubmitting}
							>
								Сохранить
							</Button>
						)
					}}
				</Form.Item>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsWithBorder>
	)
}
