import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { groupQuery } from 'parts/requests/group/groupQuery'
import useDeleteModalStore from 'pages/modal/DeleteModal/store/store'

/** Удаление группы */
export function useGetDeleteGroup(trainigId: number, groupId: number) {
	const { t } = useTranslation()
	const updateStore = useDeleteModalStore((state) => state.updateStore)

	const queryClient = useQueryClient()

	const { mutate } = groupQuery.deleteGroup.useMutation({
		onError: () => {
			message.error('Не удалось удалить группу')
		},
		onSuccess: () => {
			message.success('Группа удалена')
			// Пометить список групп неактуальным.
			// После этого React Query скачает новый список
			queryClient.refetchQueries({
				queryKey: [groupQuery.getTrainingGroups(trainigId).key],
			})
		},
	})

	return function () {
		updateStore({
			isOpen: true,
			header: 'Удаление группы',
			text: 'Уверены, что хотите удалить группу?',
			onOk: () => mutate(groupId),
		})
	}
}
