import React from 'react'
import { Divider } from 'antd'
import SchoolName from '../SchoolName/SchoolName'
import MainMenuItems from '../MainMenuItems/MainMenuItems'
import ExpressAdsBlock from 'widgets/expressCourse/components/AdsBlock/AdsBlock'
import { useGetUserRole, useGoToPage } from 'parts/utils/hooks/hooks'
import AppUrls from 'parts/constants/pageUrl'
import useMenuSiderStore from '../MenuSider/zustand/store'

// Основное меню на левой части страниц
function MainMenu() {
	const userRoles = useGetUserRole()
	const goToExpressCoursesPage = useGoToPage(AppUrls.MyExpressCourses().url)
	const isCollapsed = useMenuSiderStore((store) => store.isCollapsed)

	return (
		<>
			<SchoolName />
			<Divider className="full-main-menu__divider" />
			<MainMenuItems />
			{userRoles.isAdmin && !isCollapsed && (
				<ExpressAdsBlock onClick={goToExpressCoursesPage} />
			)}
		</>
	)
}

export default MainMenu
