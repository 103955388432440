import React from 'react'

function CheckThin() {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.4247 4.4247L6.00044 12.849L1.57617 8.4247L2.4247 7.57617L6.00044 11.1519L13.5762 3.57617L14.4247 4.4247Z"
			/>
		</svg>
	)
}

export default CheckThin
