import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import authRequests from './auth/authRequest'
import Site from '../constants/site'

const $api = axios.create({
	withCredentials: false,
	baseURL: Site.protocol + Site.rootDomain,
	// baseURL: 'https://dev.credu.ai',
})

$api.interceptors.request.use((config: AxiosRequestConfig) => {
	config.headers!.Authorization = `Bearer ${localStorage.getItem('token')}`
	return config
})

const responseAuthInterceptor = async (error: AxiosError) => {
	if (
		error.response?.status === 401 &&
		error.config &&
		!error.config._isRetry
	) {
		const originalRequest = error.config
		originalRequest._isRetry = true

		const response = await authRequests.refresh()

		window.localStorage.setItem('token', response.data.accessToken)
		return $api.request(originalRequest)
	}

	throw error
}

$api.interceptors.response.use(
	(config: AxiosRequestConfig) => config,
	responseAuthInterceptor
)

export default $api
