import { produce } from 'immer'
import TrainingEntityTypes from '../types/TrainingEntityTypes'

// Методы для работы с курсами
const ExerciseService = {
	/**
	 * Находит и возвращает упражнение
	 * @param exercises
	 * @param {Number} exerciseId — id упражнения
	 */
	getExerciseInExercises(
		exercises: TrainingEntityTypes.ExerciseItem[],
		exerciseId: number
	): null | TrainingEntityTypes.ExerciseItem {
		for (let k = 0; k < exercises.length; k++) {
			const exercise = exercises[k]

			if (exercise.id === exerciseId) {
				return exercise
			}
		}

		return null
	},

	/**
	 * Находит и возвращает упражнение
	 * @param {Number} lesson — объект урока
	 * @param {Number} exerciseId — id упражнения
	 */
	getExerciseInLesson(
		lesson:
			| null
			| TrainingEntityTypes.LessonAdmin
			| TrainingEntityTypes.LessonStudent,
		exerciseId: number
	): null | TrainingEntityTypes.ExerciseItem {
		if (!lesson) return null

		return this.getExerciseInExercises(lesson.exercises, exerciseId)
	},

	/**
	 * Находит и возвращает следующее упражнение
	 * @param {Number} lesson — объект урока
	 * @param {Number} exerciseId — id упражнения
	 */
	getNextExerciseInLesson(
		lesson:
			| null
			| TrainingEntityTypes.LessonAdmin
			| TrainingEntityTypes.LessonStudent,
		exerciseId: number
	): null | TrainingEntityTypes.ExerciseItem {
		if (!lesson) return null

		for (let k = 0; k < lesson.exercises.length; k++) {
			const exercise = lesson.exercises[k]
			const nextExercise = lesson.exercises[k + 1]

			if (exercise.id === exerciseId) {
				if (nextExercise) {
					return nextExercise
				}

				break
			}
		}

		return null
	},

	/**
	 * Перемещает упражнение вверх или вниз по массиву упражнений
	 * @param exercises — массив упражнений
	 * @param {Number} exerciseId — id упражнения
	 * @param {String} direction — направление перемещения упражнения
	 */
	moveExerciseUpOrDown(
		exercises: TrainingEntityTypes.ExerciseItem[],
		exerciseId: number,
		direction: 'up' | 'down'
	) {
		// Переместить упражнение по массиву упражнений
		const updatedExercises = produce(exercises, (draftExercises) => {
			for (let i = 0; i < draftExercises.length; i++) {
				const exercise = draftExercises[i]
				if (exercise.id !== exerciseId) continue

				draftExercises.splice(i, 1)

				let baseIdx = direction == 'up' ? i - 1 : i + 1
				draftExercises.splice(baseIdx, 0, exercise)
				return draftExercises
			}
		})

		// Заново проставить свойство order чтобы сервер отдавал их в правильном порядке
		return this.actualizeExerciseOrderProp(updatedExercises)
	},

	getExerciseIdxInExercises(
		exercises: TrainingEntityTypes.ExerciseItem[],
		exerciseId: number
	) {
		return exercises.findIndex((exercise) => exercise.id === exerciseId)
	},

	/**
	 * Возвращает упражнение относительно упражнения с переданным идентификатором.
	 * Это требуется если нужно получить упражнение, находящееся выше или ниже определённого.
	 * @param exercises — массив упражнений.
	 * @param targetExerciseId — id урока относительно которого будет искаться другой урок.
	 * @param relativePosition — позиция искомого урока относительно целевого. Например -1 для поиска предыдущего урока.
	 */
	getExerciseInRelationToTargetExerciseId(
		exercises: TrainingEntityTypes.ExerciseItem[],
		targetExerciseId: number,
		relativePosition: number
	) {
		const exerciseIdx = this.getExerciseIdxInExercises(
			exercises,
			targetExerciseId
		)

		return exercises[exerciseIdx + relativePosition]
	},

	/**
	 * Функция возвращает булево значение возможно ли изменения порядка следования упражнения
	 * @param exercises — массив упражнений
	 * @param {Number} exerciseId — id упражнения
	 * @param {String} direction — направление перемещения упражнения
	 */
	isExerciseMovingAllowed(
		exercises: TrainingEntityTypes.ExerciseItem[],
		exerciseId: number,
		direction: 'up' | 'down'
	): boolean {
		for (let k = 0; k < exercises.length; k++) {
			const exercise = exercises[k]
			if (exercise.id !== exerciseId) continue

			if (direction == 'up') {
				return k > 0
			} else if (direction == 'down') {
				return k < exercises.length - 1
			}
		}

		return false
	},

	// ЭТО МОЖНО УДАЛИТЬ
	/**
	 * Удаление упражнения из урока
	 * @param lessons — массив уроков
	 * @param lessonId — id урока
	 * @param exerciseId — id упражнения
	 */
	/*deleteExerciseInLessons(
		lessons: TrainingEntityTypes.LessonAdmin[],
		lessonId: number,
		exerciseId: number
	): TrainingEntityTypes.LessonAdmin[] {
		return produce(lessons, (draftLessons) => {
			const lesson = LessonAdminService.findLessonByIdInLessons(
				draftLessons,
				lessonId
			)
			if (!lesson) return

			lesson.exercises = lesson.exercises.filter((exercise) => {
				return exercise.id !== exerciseId
			})
		})
	},*/

	/**
	 * Удаление изображения из упражнения с изображениями
	 * @param {Object} training — объект курса
	 * @param {Number} lessonId — id урока
	 * @param {Number} exerciseId — id упражнения
	 * @param deleteImageUrl
	 */
	deleteImageFromImageExercise(
		training: TrainingEntityTypes.TrainingAdmin,
		lessonId: number,
		exerciseId: number,
		deleteImageUrl: string
	): TrainingEntityTypes.TrainingAdmin {
		return produce(training, (draft) => {
			const thisLesson = draft.lessons.find(
				(lesson) => lesson.id == lessonId
			)
			if (!thisLesson) return draft

			const thisExercise = thisLesson.exercises.find(
				(exercise) => exercise.id == exerciseId
			)
			if (!thisExercise || thisExercise.type != 'image') return draft

			thisExercise.item.image = thisExercise.item.image.filter(
				(image) => image != deleteImageUrl
			)
		})
	},

	/**
	 * Удаление изображения из упражнения с сопоставлением изображений и описаний
	 * @param {Object} training — объект курса
	 * @param {Number} lessonId — id урока
	 * @param {Number} exerciseId — id упражнения
	 * @param deleteImageUrl
	 */
	deleteImageFromImageDescExercise(
		training: TrainingEntityTypes.TrainingAdmin,
		lessonId: number,
		exerciseId: number,
		deleteImageUrl: string
	): TrainingEntityTypes.TrainingAdmin {
		return produce(training, (draft) => {
			const thisLesson = draft.lessons.find(
				(lesson) => lesson.id == lessonId
			)
			if (!thisLesson) return draft

			const thisExercise = thisLesson.exercises.find(
				(exercise) => exercise.id == exerciseId
			)
			if (!thisExercise || thisExercise.type != 'descriptionImg')
				return draft

			thisExercise.item.images = thisExercise.item.images.filter(
				(image) => image.image != deleteImageUrl
			)
		})
	},

	/**
	 * Пробегает по всем упражнениям урока и ставит свойство order в порядке следования упражнений
	 * @param exercises — массив упражнений
	 */
	actualizeExerciseOrderProp(
		exercises: TrainingEntityTypes.ExerciseItem[]
	): TrainingEntityTypes.ExerciseItem[] {
		return produce(exercises, (draftExercises) => {
			for (let k = 0; k < draftExercises.length; k++) {
				draftExercises[k].order = k
			}
		})
	},
}

export default ExerciseService
