import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import AuthPage from '../../../../common/AuthPage/AuthPage'
import ContentWrapper from '../../../../common/ContentWrapper/ContentWrapper'
import AppUrls from 'parts/constants/pageUrl'
import RecoveryStartForm from '../RecoveryStartForm/RecoveryStartForm'

// Страница с формой ввода почты для отправки ссылки на страницу сброса пароля
function RecoverStartPage() {
	const { t } = useTranslation()

	return (
		<AuthPage>
			<ContentWrapper
				header={t('auth.recoverPageHeader')}
				headerDesc={t('auth.recoverPageText')}
				bottomText={
					<Link to={AppUrls.Login().url}>
						{t('auth.recoverPageLoginButton')}
					</Link>
				}
			>
				<RecoveryStartForm />
			</ContentWrapper>
		</AuthPage>
	)
}

export default RecoverStartPage
