import { Button, Form, Input } from 'antd'
import useChangePasswordStore from './zustand/store'
import ModalWithForm from '../../../ui/ModalWithForm/ModalWithForm'
import {
	UFormNewBottomButtonsAfterGray,
	UFormNewButtonsContainer,
	UFormNewGeneralError,
	UFormNewRow,
} from 'ui/UForm/UForm'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'
import { FieldNames, setErrorsToForm } from './fn/form'
import { useGetOnSubmit } from './fn/submit'

function ChangePasswordModal() {
	const [form] = Form.useForm()

	const [isOpen, closeModal, formErrors] = useChangePasswordStore((state) => [
		state.isOpen,
		state.closeModal,
		state.formErrors,
	])

	const onSubmit = useGetOnSubmit(form)
	setErrorsToForm(form)

	return (
		<ModalWithForm
			title="Изменение пароля"
			open={isOpen}
			onCancel={closeModal}
		>
			<Form form={form} onFinish={onSubmit} layout="vertical">
				<GrayBlock>
					<CurrentPasswordInput />
					<NewPasswordInput />
					<NewPasswordAgainInput />
				</GrayBlock>
				<UFormNewGeneralError message={formErrors.message} />
				<Bottom />
			</Form>
		</ModalWithForm>
	)
}

export default ChangePasswordModal

function CurrentPasswordInput() {
	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.CurrentPassword}
				label="Текущий пароль"
				rules={[
					{
						required: true,
						message: 'Напишите текущий пароль',
					},
					{
						min: 6,
						message: 'Пароль должен содержать не меньше 6 символов',
					},
					{
						max: 21,
						message: 'Пароль должен содержать не более 21 символа',
					},
				]}
			>
				<Input type="password" />
			</Form.Item>
		</UFormNewRow>
	)
}

function NewPasswordInput() {
	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.NewPassword}
				label="Новый пароль"
				rules={[
					{
						required: true,
						message: 'Напишите новый пароль',
					},
					{
						min: 6,
						message: 'Пароль должен содержать не меньше 6 символов',
					},
					{
						max: 21,
						message: 'Пароль должен содержать не более 21 символа',
					},
				]}
			>
				<Input type="password" />
			</Form.Item>
		</UFormNewRow>
	)
}

function NewPasswordAgainInput() {
	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.NewPasswordAgain}
				label="Новый пароль ещё раз"
				rules={[
					{
						required: true,
						message: 'Напишите новый пароль ещё раз',
					},
					{
						min: 6,
						message: 'Пароль должен содержать не меньше 6 символов',
					},
					{
						max: 21,
						message: 'Пароль должен содержать не более 21 символа',
					},
					({ getFieldValue }) => ({
						validator(_, value) {
							if (
								!value ||
								getFieldValue('newPassword') === value
							) {
								return Promise.resolve()
							}
							return Promise.reject(
								new Error('Пароли не совпадают.')
							)
						},
					}),
				]}
			>
				<Input type="password" />
			</Form.Item>
		</UFormNewRow>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom() {
	const form = Form.useFormInstance()

	const isSubmitting = useChangePasswordStore((state) => state.isSubmitting)

	return (
		<UFormNewBottomButtonsAfterGray>
			<div />
			<UFormNewButtonsContainer>
				<Button
					disabled={
						isSubmitting ||
						form
							.getFieldsError()
							.filter(({ errors }) => errors.length).length > 0
					}
					onClick={() => form.resetFields()}
				>
					Отмена
				</Button>

				<Form.Item shouldUpdate>
					{() => {
						const formErrors = form
							.getFieldsError()
							.filter(({ errors }) => errors.length)

						return (
							<Button
								type="primary"
								htmlType="submit"
								disabled={isSubmitting || formErrors.length > 0}
								loading={isSubmitting}
							>
								Изменить пароль
							</Button>
						)
					}}
				</Form.Item>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
