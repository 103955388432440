export const DELETED_ELEMENT_TIMEOUT = 24 * 60 * 60 * 1000 // 1 day

export const FILE_UPLOAD_MAX_BYTES = 3 * 1024 * 1024 // 3 MiB

export const S3_STORAGE_PREFIXES = {
	collabFiles: 'files/collab',
	shareLinkFiles: 'files/shareLinks',
	chatFiles: 'files/chat',
}

export const PRECEDING_ELEMENT_KEY = '__precedingElement__'

export enum EVENT {
	COPY = 'copy',
	PASTE = 'paste',
	CUT = 'cut',
	KEYDOWN = 'keydown',
	KEYUP = 'keyup',
	MOUSE_MOVE = 'mousemove',
	RESIZE = 'resize',
	UNLOAD = 'unload',
	FOCUS = 'focus',
	BLUR = 'blur',
	DRAG_OVER = 'dragover',
	DROP = 'drop',
	GESTURE_END = 'gestureend',
	BEFORE_UNLOAD = 'beforeunload',
	GESTURE_START = 'gesturestart',
	GESTURE_CHANGE = 'gesturechange',
	POINTER_MOVE = 'pointermove',
	POINTER_DOWN = 'pointerdown',
	POINTER_UP = 'pointerup',
	STATE_CHANGE = 'statechange',
	WHEEL = 'wheel',
	TOUCH_START = 'touchstart',
	TOUCH_END = 'touchend',
	HASHCHANGE = 'hashchange',
	VISIBILITY_CHANGE = 'visibilitychange',
	SCROLL = 'scroll',
	// custom events
	EXCALIDRAW_LINK = 'excalidraw-link',
	MENU_ITEM_SELECT = 'menu.itemSelect',
	MESSAGE = 'message',
}

export const INITIAL_SCENE_UPDATE_TIMEOUT = 5000

export enum WS_SCENE_EVENT_TYPES {
	INIT = 'SCENE_INIT',
	UPDATE = 'SCENE_UPDATE',
}

export const LOAD_IMAGES_TIMEOUT = 500

export const ROOM_ID_BYTES = 10
export const CURSOR_SYNC_TIMEOUT = 33 // ~30fps
export const SYNC_FULL_SCENE_INTERVAL_MS = 20000
export const FILE_UPLOAD_TIMEOUT = 300

export const WS_EVENTS = {
	SERVER_VOLATILE: 'server-volatile-broadcast',
	SERVER: 'server-broadcast',
	SERVER_MESSAGE: 'server-message',
	SERVER_EVENT: 'server-event',
	SERVER_LIVE_START: 'server-live-start',
	SERVER_GET_LIVE_DURATION: 'server-get-live-duration',
	SERVER_GET_LIVE_INITIAL_STATE: 'server-get-live-initial-state',
}

export enum LIVE_EVENT_TYPES {
	LIVE_GET_TIME_LESSON = 'live-get-time-lesson',
	LIVE_NOT_STARTED = 'live-not-started',
	LIVE_STARTED = 'live-started',
	LIVE_ADD_WHITEBOARD = 'live-add-whiteboard',
	LIVE_CLOSE_TAB = 'live-close-tab',
	LIVE_CHANGE_TAB = 'live-change-tab',
	LIVE_INITIAL_STATE = 'live-inital-state',
	LIVE_SCREENSHARING_STARTED = 'live-screensharing-started',
	LIVE_SCREENSHARING_ENDED = 'live-screensharing-end',
	LIVE_PARTICIPANT_STATE_CHANGE = 'live-participant-state-change',
	LIVE_PLAY_AUDIO_TRACK = 'live-play-audio-track',
	LIVE_PAUSE_AUDIO_TRACK = 'live-pause-audio-track',
	LIVE_RESUME_AUDIO_TRACK = 'live-resume-audio-track',
	LIVE_SEEK_AUDIO_TRACK = 'live-seek-audio-track',
	LIVE_CLOSE_AUDIO_PLAYER = 'live-close-audio-player',
	LIVE_PLAY_VIDEO_TRACK = 'live-play-video-track',
	LIVE_SHOW_PDF_DOCUMENT = 'live-show-pdf-document',
	LIVE_SET_PDF_PAGE = 'live-pdf-set-page',
	LIVE_ADD_PARTICIPANT = 'live-add-participant',
	LIVE_REMOVE_PARTICIPANT = 'live-remove-participant',
	LIVE_PARTICIPANTS_LIST = 'live-participants-list',
	LIVE_START_RECORDING = 'live-start-recording',
}

export const ACTIVE_THRESHOLD = 3000
export const IDLE_THRESHOLD = 60000

export const LIBRARY_SIDEBAR_TAB = 'library'

export const DEFAULT_FONT_SIZE = 20

export const WINDOWS_EMOJI_FALLBACK_FONT = 'Segoe UI Emoji'
export const ARROW_LABEL_FONT_SIZE_TO_MIN_WIDTH_RATIO = 11
export const ARROW_LABEL_WIDTH_FRACTION = 0.7

export const isChrome = navigator.userAgent.indexOf('Chrome') !== -1
export const isSafari =
	!isChrome && navigator.userAgent.indexOf('Safari') !== -1
export const isFirefox = navigator.userAgent.toLowerCase().includes('firefox')

export const VERTICAL_ALIGN = {
	TOP: 'top',
	MIDDLE: 'middle',
	BOTTOM: 'bottom',
}

export const LINE_CONFIRM_THRESHOLD = 8 // px

export const BOUND_TEXT_PADDING = 5

export const TEXT_ALIGN = {
	LEFT: 'left',
	CENTER: 'center',
	RIGHT: 'right',
}

export const DRAGGING_THRESHOLD = 10 // px

export const SHIFT_LOCKING_ANGLE = Math.PI / 12

export const WHITEBOARD_LIBRARY_LIST = [
	'3d-coordinate-systems-graphs',
	'3d-shapes',
	'baby-characters',
	'basic-shapes',
	'biology',
	'boardgame',
	'body-builder-kit-1',
	'canvases',
	'character-kit-1',
	'charts',
	'coordinates',
	'electrical-engineering',
	'emojis',
	'female-heads-diverse',
	'go-icons',
	'golang-gophers',
	'halloween-elements',
	'information-architecture',
	'Logic-Gates',
	'macroeconomics',
	'math-teacher-library',
	'mathematical-symbols',
	'medicine',
	'organic-chemistry-basics',
	'periodic-table',
	'picasso-s-line-drawings',
	'polygons',
	'robots',
	'science-chemistry-gcse',
	'some-handdrawn-signs',
	'stars',
	'stick-figures',
	'storytelling',
	'valentine-s-day',
]

export const WHITEBOARD_LIBRARY_EXTENSTION = 'excalidrawlib'
