import {
	Block,
	BlockOuter,
} from 'pages/landing/landingConstructor/sections/programSection/ProgramBlock/ProgramBlock'
import programBlocksManager from 'pages/landing/landingConstructor/sections/programSection/programBlocksManager/programBlocksManager'
import 'pages/landing/landingConstructor/sections/programSection/ProgramBlocks/ProgramBlocks.scss'

// Обёртка с двумя колонками куда помещаются блоки с описанием пользователя, которому подойдёт предлагаемый курс
function ProgramBlocks() {
	const leftBlocks = programBlocksManager.useGetColumnBlocks(1)
	const rightBlocks = programBlocksManager.useGetColumnBlocks(2)

	let blockIdx = -1

	return (
		<div className="land-const-program-blocks">
			<div className="land-const-program-blocks__column">
				{leftBlocks.map((block) => {
					blockIdx++

					return (
						<BlockOuter block={block} key={block.id}>
							<Block
								block={block}
								blockIdx={blockIdx}
								key={block.id}
							/>
						</BlockOuter>
					)
				})}
			</div>
			<div className="land-const-program-blocks__column">
				{rightBlocks.map((block) => {
					blockIdx++

					return (
						<BlockOuter block={block} key={block.id}>
							<Block
								block={block}
								blockIdx={blockIdx}
								key={block.id}
							/>
						</BlockOuter>
					)
				})}
			</div>
		</div>
	)
}

export default ProgramBlocks
