import { useEffect, useState } from 'react'
import useGroupStore from '../../zustand/store'
import groupsRequest from 'parts/requests/group/groupsRequest'
import EntityTypes from 'parts/types/EntityTypes'
import GroupsApiTypes from 'parts/requests/group/groupsApiTypes'

/** Возвращает название группы для заголовка страницы */
export function useGetInitialHeaderText(): string {
	const group = useGroupStore((state) => state.group)
	const [groupName, setGroupName] = useState('')

	useEffect(
		function () {
			if (!group) return

			setGroupName(group.name)
		},
		[group]
	)

	return groupName
}

/**
 * Возвращает обработчик изменения текста заголовка.
 * При изменении на сервер делается запрос на сохранение нового имени группы.
 */
export function useGetSaveHeaderTextOnServerAfterChange() {
	const group = useGroupStore((state) => state.group)

	return function (newGroupName: string) {
		if (!group) return

		groupsRequest.createOrUpdateGroup(
			getUpdateGroupDto(group, newGroupName)
		)
	}
}

/**
 * Формирует DTO для изменения имени группы.
 * @param group — данные группы
 * @param newGroupName — новое имя группы
 */
function getUpdateGroupDto(
	group: EntityTypes.GroupData,
	newGroupName: string
): GroupsApiTypes.CreateOrUpdateGroupDto {
	return {
		id: group.id,
		name: newGroupName,
		trainingId: group.trainingId,
		type: group.type,
	}
}
