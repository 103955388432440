import $api from '../http'
import UserApiTypes from '../user/userApiTypes'

// Запросы к методам работы с пользователями
const superAdminRequests = {
	// Получение пользователей школы.
	getAdmins() {
		return $api.get<UserApiTypes.GetUsers>('/api/superadmin/admins/')
	},

	// Получение пользователя с переданным идентификатором
	getAdmin(adminId: string | number) {
		return $api.get<UserApiTypes.GetUser>(
			'/api/superadmin/admins/' + adminId
		)
	},
}

export default superAdminRequests
