import { useEffect, useState } from 'react'
import GroupsApiTypes from '../requests/group/groupsApiTypes'
import EntityTypes from '../types/EntityTypes'
import TrainingEntityTypes from '../types/TrainingEntityTypes'
import { trainingQuery } from '../requests/training/trainingQuery'

const GroupsService = {
	/**
	 * Получает список курсов и возвращает объект с идентификаторами курсов и идентификаторами групп входящих в них.
	 * Пример: {
	 *     1: [20, 28],
	 *     5: [6, 19]
	 * }
	 * @param trainings — массив курсов
	 */
	getTrainingsWithGroupsIds(trainings: TrainingEntityTypes.TrainingAdmin[]): {
		[key: number]: number[]
	} {
		const result: { [key: number]: number[] } = {}

		trainings.map((training) => {
			result[training.id] = training.groups.map((group) => {
				return group.id
			})
		})

		return result
	},

	/**
	 * Получает с сервера массив курсов и возвращает объект с идентификаторами курсов и идентификаторами групп входящих в них.
	 * Пример: {
	 *     1: [20, 28],
	 *     5: [6, 19]
	 * }
	 */
	useGetAllTrainingsGroupsIds(): { [key: number]: number[] } {
		const { data: trainingsRes } =
			trainingQuery.getTrainingsForAdmin.useQuery()

		const [trainingsGroupsIds, setTrainingsGroupsIds] = useState<{
			[key: number]: number[]
		}>({})

		useEffect(
			function () {
				if (!trainingsRes) return

				const trainingsWithGroups =
					GroupsService.getTrainingsWithGroupsIds(trainingsRes.data)

				setTrainingsGroupsIds(trainingsWithGroups)
			},
			[trainingsRes]
		)

		return trainingsGroupsIds
	},

	/**
	 * Принимает объект, где написаны идентификаторы всех курсов и их групп и необработанный массив с отмеченными группами
	 * и возвращает массив отмеченных групп.
	 * @param allTrainingsGroupsIds — объект с идентификаторами всех курсов и их групп.
	 * Например: {582: [2, 5], 583: [7, 8, 9]}
	 * @param selectedTrainingsGroupsIds — Массив вида [['582'], ['583', '98'], ['583', '97']].
	 * Первый элемент — это id курса, во втором id группы.
	 * Если выбраны все группы курса, то в массиве будет только одно число.
	 * Если выбраны несколько групп курса, то id курса будет повторяться в нескольких массивах.
	 */
	getSelectedGroupsFromTrainings(
		allTrainingsGroupsIds: {
			[key: number]: number[]
		},
		selectedTrainingsGroupsIds: (string | number)[][]
	): number[] {
		const selectedGroupsId: number[] = []

		selectedTrainingsGroupsIds.forEach((trainingArr) => {
			if (trainingArr.length === 1) {
				const objKey = parseInt(trainingArr[0].toString())

				allTrainingsGroupsIds[objKey].forEach((groupId) => {
					selectedGroupsId.push(groupId)
				})
			} else {
				const groupId = parseInt(trainingArr[1].toString())
				selectedGroupsId.push(groupId)
			}
		})

		return selectedGroupsId
	},

	/**
	 * Находит группу с переданным идентификатором в массиве групп
	 * @param groups
	 * @param groupId
	 */
	getGroupFromGroupsArr(groups: EntityTypes.GroupData[], groupId: number) {
		return groups.find((group) => group.id == groupId)
	},

	/**
	 * Находит активную группу в массиве групп
	 * @param groups — массив групп
	 */
	getActiveGroup(groups: EntityTypes.GroupData[]) {
		const activeGroup = groups.find((group) => group.isActive)

		return activeGroup || null
	},
}

export default GroupsService
