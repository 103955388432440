import { Alert, Button, Form, FormInstance, Input } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useManageForm } from './fn/submit'
import ServerTypes from 'parts/types/ServerTypes'
import { UFormOld } from 'ui/UFormOld/UFormOld'
import StepMessage from '../../recoveryStart/Message/StepMessage'
import './RecoveryCheckForm.scss'

// Форма подтверждения почты
function RecoveryCheckForm() {
	const [form] = Form.useForm()

	const { isLoading, onSubmit, formErrors } = useManageForm(form)

	return (
		<UFormOld>
			<Form
				form={form}
				name="confirmEmail"
				onFinish={onSubmit}
				layout="vertical"
			>
				<CodeInput />
				<Bottom loading={isLoading} />
				<GeneralError formErrors={formErrors} />
				<StepMessage step={2} />
			</Form>
		</UFormOld>
	)
}

export default RecoveryCheckForm

function CodeInput() {
	const { t } = useTranslation()

	return (
		<Form.Item
			name="code"
			rules={[
				{
					required: true,
					message: t(
						'auth.recoveryPasswordCheckFormFieldCodeRequired'
					),
				},
				/*{
					min: 5,
					message: 'Должно быть 5 цифр',
				},
				{
					max: 5,
					message: 'Должно быть 5 цифр',
				},*/
			]}
		>
			<Input
				size="large"
				className="confirm-email-page-content__input"
				autoFocus
			/>
		</Form.Item>
	)
}

type BottomProps = {
	loading: boolean
}

function Bottom(props: BottomProps) {
	const form = Form.useFormInstance()
	const { loading } = props

	const { t } = useTranslation()

	return (
		<Form.Item
			shouldUpdate
			className="confirm-email-page-content__bottom-form-item"
		>
			{() => {
				const formErrors = form
					.getFieldsError()
					.filter(({ errors }) => errors.length)

				return (
					<Button
						block
						size="large"
						type="primary"
						htmlType="submit"
						disabled={loading || formErrors.length > 0}
						loading={loading}
					>
						{t('auth.recoveryPasswordCheckFormOkButton')}{' '}
						<ArrowRightOutlined />
					</Button>
				)
			}}
		</Form.Item>
	)
}

type GeneralErrorProps = {
	formErrors: ServerTypes.ErrorResponse
}

// Показ общей ошибки при отправке формы
function GeneralError(props: GeneralErrorProps) {
	const { formErrors } = props

	return formErrors.message ? (
		<Alert message={formErrors.message} type="error" />
	) : null
}
