import React, { MouseEventHandler } from 'react'
import { Alert } from 'antd'
import { useTranslation } from 'react-i18next'
import { ModalLayout } from 'ui/ModalLayout/ModalLayout'
import { useSetFormData } from './fn/state'
import ExerciseFormFooter from '../common/ExerciseFormFooter/ExerciseFormFooter'
import { DropWordsExerciseFormStateType } from './fn/stateType'
import HtmlEditor from 'ui/HTMLEditor/HTMLEditor'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { submitHandler } from './fn/submit'
import { AddTextBlockButton, TextBlocks } from './TextBlocks'
import { onChangeTaskInput } from './fn/changeForm'
import useExercisesListAdminStore from '../../zustand/store'
import './DropWordsForm.scss'

type GapsFormProps = {
	saveExercise: (args: TrainingEntityTypes.ExerciseItem) => void // Функция сохраняющая данные упражнения на сервере и в Хранилище
	closeModalFn: MouseEventHandler<HTMLElement> // Функция закрытия модального окна
}

// Форма составления упражнения с пропусками слов
function DropWordsForm(props: GapsFormProps) {
	const { saveExercise, closeModalFn } = props

	useSetFormData()

	const formState = useExercisesListAdminStore(
		(store) => store.formData
	) as DropWordsExerciseFormStateType

	const { t } = useTranslation()
	if (!formState) return null

	return (
		<ModalLayout
			key="1"
			title="Перетаскивание слов в пропуски"
			cancelBtn={false}
			isModalVisible
			closeModalFn={closeModalFn}
			footer={null}
		>
			<ExerciseInput formState={formState} />
			<Alert
				message="Чтобы слово сделать пропуском обрамите его фигурными скобками
				или выделите слово и нажмите на кнопку «Сделать пропуском»,
				тогда программа сделает это автоматически."
				type="warning"
			/>
			<TextBlocks formState={formState} />
			<AddTextBlockButton />
			<ExerciseFormFooter
				formState={formState}
				saveExercise={saveExercise}
				closeModalFn={closeModalFn}
				isSubmitButtonDisabled={formState.isFormInvalid}
				submitHandler={() => submitHandler(saveExercise)}
			/>
		</ModalLayout>
	)
}

type ExerciseVideoProps = {
	formState: DropWordsExerciseFormStateType
}

// Поле с текстом задания
function ExerciseInput(props: ExerciseVideoProps) {
	const { formState } = props

	const { t } = useTranslation()

	return (
		<>
			<label className="exercise-drop-words__label">Задание</label>
			<HtmlEditor
				initialValue={formState.taskInput.value}
				onChangeValue={onChangeTaskInput}
			/>
		</>
	)
}

export default DropWordsForm
