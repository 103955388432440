import { Cascader } from 'antd'
import {
	useGetCascaderOptions,
	useGetOnPaySystemChange,
} from './fn/onPaySystemChange'

function PaySystemForm() {
	const options = useGetCascaderOptions()
	const onStatusChange = useGetOnPaySystemChange()

	return (
		<Cascader options={options} onChange={onStatusChange as any} multiple />
	)
}

export default PaySystemForm
