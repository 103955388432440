import useStudentsStore from '../../../../StudentsTableCurator/zustand/store'
import { StudentsStoreType } from '../../../../StudentsTableCurator/zustand/storeTypes'

/** Формирует из списка состояний данные для формирования пунктов каскадера */
export function useGetCascaderOptions() {
	return Object.values(StudentsStoreType.SearchValuesStatuses).map(
		(status) => {
			return {
				label: status.name,
				value: status.status,
			}
		}
	)
}

/** Обработчик изменения Каскадера. Новые значения записывает в Состояние */
export function useGetOnStatusChange() {
	const setSearchValuesProp = useStudentsStore(
		(store) => store.setSearchValuesProp
	)

	return function (status: [StudentsStoreType.SearchValuesStatus]) {
		setSearchValuesProp(
			StudentsStoreType.SearchValueNames.Status,
			//@ts-ignore
			status[0]
		)
	}
}
