import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'
import { Socket } from 'socket.io-client'
import { WS_EVENTS } from 'widgets/livelesson/liveLesson/components/LiveWhiteboard/constants'

export class WebSocketService {
	private socket: Socket | null = null
	private liveId: number | null = null
	private chatListeners: Function[] = []
	private eventListeners: Function[] = []

	static webSocketService: WebSocketService

	public static getWebSocketService() {
		if (!WebSocketService.webSocketService) {
			WebSocketService.webSocketService = new WebSocketService()
		}
		return WebSocketService.webSocketService
	}

	public open(socket: Socket, liveId: number) {
		this.socket = socket
		this.liveId = liveId
		this.chatListeners = []
		this.eventListeners = []
		this.socket.on('init-room', () => {
			if (this.socket) {
				this.socket.emit('join-room', this.liveId)
			}
		})
		this.socket.on('client-message', (message: string) => {
			const msg = JSON.parse(message)
			this.chatListeners.forEach((item) => {
				item(msg)
			})
		})
		this.socket.on('client-event', (message: string) => {
			const msg = JSON.parse(message)
			this.eventListeners.forEach((item) => {
				item(msg)
			})
		})
	}

	public close() {
		if (!this.socket) {
			return
		}
		this.socket.close()
		this.socket = null
		this.liveId = null
	}

	public addChatListener(func: Function) {
		this.chatListeners.push(func)
	}

	public addEventListener(func: Function) {
		this.eventListeners.push(func)
	}

	public removeChatListener(func: Function) {
		this.chatListeners = this.chatListeners.filter((item) => item !== func)
	}

	public removeEventListener(func: Function) {
		this.eventListeners = this.eventListeners.filter(
			(item) => item !== func
		)
	}

	public isOpen() {
		return !!(this.socket && this.liveId)
	}

	public sendChatMessage(data: LiveLessonEntityTypes.ChatMessage) {
		if (this.socket && this.isOpen()) {
			const json = JSON.stringify(data)

			return this.socket.emitWithAck(
				WS_EVENTS.SERVER_MESSAGE,
				this.liveId?.toString(),
				json
			)
		}

		return Promise.resolve()
	}

	public sendLiveEvent(event: LiveLessonEntityTypes.LiveEvent) {
		if (this.socket && this.isOpen()) {
			const json = JSON.stringify(event)

			return this.socket.emitWithAck(
				WS_EVENTS.SERVER_EVENT,
				this.liveId?.toString(),
				json
			)
		}

		return Promise.resolve()
	}

	public sendLiveStart() {
		if (this.isOpen()) {
			this.socket?.emit(WS_EVENTS.SERVER_LIVE_START, this.liveId)
		}
	}

	public sendGetLiveDuration() {
		if (this.isOpen()) {
			this.socket?.emit(WS_EVENTS.SERVER_GET_LIVE_DURATION, this.liveId)
		}
	}

	public sendGetLiveInitialState() {
		if (this.isOpen()) {
			this.socket?.emit(
				WS_EVENTS.SERVER_GET_LIVE_INITIAL_STATE,
				this.liveId
			)
		}
	}
}
