import useAddLiveModalStore from './store'

/**
 * Открывает модальное окно создания нового live-урока или редактирования существующего
 * @param isOpen — должно ли окно быть открыто
 * @param editedLessonId — id редактируемого урока
 */
export function useGetChangeModalVisibility(
	isOpen: boolean,
	editedLessonId?: number
) {
	const updateStore = useAddLiveModalStore((state) => state.updateState)

	return function () {
		updateStore({
			lessonId: editedLessonId,
			isOpen,
		})
	}
}
