import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import AppUrls from 'parts/constants/pageUrl'
import CommentsContainer from '../CommentsRouter/CommentsRouter'
import Footer from 'ui/Footer/Footer'
import { useGetUserRole } from 'parts/utils/hooks/hooks'

/** Страница коментариев */
function CommentsPage() {
	const userRoles = useGetUserRole()

	return (
		<PageSkeleton>
			<PageHeader
				breadcrumbs={[
					{
						path: AppUrls.Comments().url,
						breadcrumbName: AppUrls.Comments().name,
					},
				]}
				header={AppUrls.Comments().name}
			/>
			<ArticleContentWrapper innerWrapperWidth="big">
				<CommentsContainer />
			</ArticleContentWrapper>
			<Footer isRightSideHidden={!userRoles.isAdminOrManager} />
		</PageSkeleton>
	)
}

export default CommentsPage
