import useTransferStudentsModalStore from '../zustand/store'

/** Возвращает функцию, открывающую или закрывающую модальное окно передачи учеников другому куратору */
export function useGetSetVisibilityToTransferStudentsModal(isVisible: boolean) {
	const updateState = useTransferStudentsModalStore(
		(state) => state.updateState
	)

	return function () {
		updateState({ isOpen: isVisible })
	}
}
