import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { message } from 'antd'
import useEmployeeStore from '../../../main/zustand/store'
import useDeleteModalStore from '../../../../modal/DeleteModal/store/store'
import { groupQuery } from 'parts/requests/group/groupQuery'

/** Исключение сотрудника из группы */
export function useGetLeaveGroup(groupId: number) {
	const { t } = useTranslation()

	const employee = useEmployeeStore((store) => store.employee)!
	const updateModalStore = useDeleteModalStore((state) => state.updateStore)

	const queryClient = useQueryClient()

	const { mutate } = groupQuery.removeUserFromGroup.useMutation({
		onError: () => {
			message.error('Не удалось исключить пользователя')
		},
		onSuccess: () => {
			message.success('Пользователь удалён')

			// Пометить список групп, в которых добавлен пользователь, неактуальными.
			// После этого React Query скачает новый список
			// TODO Требуется поставить идентификатор группы, которую покинул пользователь
			/*queryClient.refetchQueries({
				queryKey: [groupQuery.getUserGroups().key],
			})*/
		},
	})

	return function () {
		updateModalStore({
			isOpen: true,
			header: 'Вы действительно хотите убрать сотрудника из группы?',
			text: 'Это действие нельзя отменить.',
			onOk: () =>
				mutate({
					groupId,
					userId: employee.id,
				}),
		})
	}
}
