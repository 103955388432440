import { useEffect } from 'react'
import { FormInstance } from 'antd'
import dayjs from 'dayjs'
import PaymentTypes from 'parts/types/PaymentTypes'
import { setErrorsToFields } from 'parts/utils/form'
import useAddStudentStore from '../zustand/store'

export enum FieldNames {
	Email = 'email',
	Phone = 'phone',
	Name = 'name',
	Surname = 'surname',
	GetAccess = 'getAccess',

	Training = 'training',
	PurchaseDate = 'purchaseDate',
	PaymentSystem = 'paymentSystem',
	Currency = 'currency',
	Amount = 'amount',
	PaymentMode = 'paymentMode',
	CuratorId = 'curatorId',
}

export type FormValuesType = {
	[FieldNames.Email]: string
	[FieldNames.Phone]: undefined | string
	[FieldNames.Name]: undefined | string
	[FieldNames.Surname]: undefined | string
	[FieldNames.GetAccess]: undefined | boolean

	// Массив вида ['571', '82']. В первом id курса, во втором id группы
	[FieldNames.Training]: [string, string]
	[FieldNames.PurchaseDate]: dayjs.Dayjs
	[FieldNames.PaymentSystem]: PaymentTypes.System
	[FieldNames.Currency]: PaymentTypes.Currency
	[FieldNames.Amount]: string
	[FieldNames.PaymentMode]: PaymentTypes.PricePeriod
	[FieldNames.CuratorId]: string
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useAddStudentStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
