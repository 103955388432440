import { Tag } from 'antd'
import Avatar from 'ui/Avatar/Avatar'
import { UTableBCell, UTableBRow } from 'ui/UTable/UTable'
import { StudentsStoreType } from '../StudentsTableCurator/zustand/storeTypes'

type TableRowProps = {
	student: StudentsStoreType.Student
}

// Ряд таблицы учеников
function TableRow(props: TableRowProps) {
	const { student } = props

	return (
		<UTableBRow>
			<UTableBCell key="fullName">
				<div className="students-table__name-wrapper">
					<Avatar src={student.avatar} width={24} />
					<p className="students-table__text">{student.fullName}</p>
				</div>
			</UTableBCell>
			<UTableBCell key="trainingName">
				<p className="students-table__text">{student.trainingName}</p>
			</UTableBCell>
			<UTableBCell key="groupName">
				<p className="students-table__text">{student.groupName}</p>
			</UTableBCell>
			<UTableBCell key="groupStart">
				<p className="students-table__text">{student.groupStart}</p>
			</UTableBCell>
			<UTableBCell key="groupEnd">
				<p className="students-table__text">{student.groupEnd}</p>
			</UTableBCell>
			<UTableBCell key="activity">
				<p className="students-table__text">
					<StudentStatus student={student} />
				</p>
			</UTableBCell>
		</UTableBRow>
	)
}

export default TableRow

type StudentStatusProps = {
	student: StudentsStoreType.Student
}

function StudentStatus(props: StudentStatusProps) {
	const { student } = props

	return student.isActivated ? (
		<Tag color="blue">
			{
				StudentsStoreType.SearchValuesStatuses[
					StudentsStoreType.SearchValuesStatus.IsActivated
				].name
			}
		</Tag>
	) : (
		<Tag color="red">
			{
				StudentsStoreType.SearchValuesStatuses[
					StudentsStoreType.SearchValuesStatus.IsNotActivated
				].name
			}
		</Tag>
	)
}
