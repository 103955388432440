import cn from 'classnames'
import { useGetColorScheme } from 'pages/landing/landingConstructor/tariffs/card/common/common'
import EditableText from 'pages/landing/landingConstructor/tariffs/card/common/EditableText/EditableText'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import { Button } from 'antd'
import {
	DeleteOutlined,
	PlusOutlined,
	StrikethroughOutlined,
} from '@ant-design/icons'
import {
	useGetCloneThesis,
	useGetDeleteThesis,
	useGetSetCrossOutThesisStatus,
} from 'pages/landing/landingConstructor/tariffs/card/Theses/fn/handlers'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'
import 'pages/landing/landingConstructor/tariffs/card/Theses/Theses.scss'

type ThesesProps = {
	tariff: TariffsStoreType.Tariff
}

function Theses(props: ThesesProps) {
	const { tariff } = props

	const classes = ['tariff-card-theses']
	if (tariff.isFlashed) {
		classes.push('tariff-card-theses--white')
	}

	return (
		<div className={cn(classes)}>
			{tariff.theses.map((thesis, i) => {
				return (
					<Thesis
						tariff={tariff}
						thesis={thesis}
						key={thesis.id}
						thesisIdx={i}
					/>
				)
			})}
		</div>
	)
}

export default Theses

type ThesisProps = {
	tariff: TariffsStoreType.Tariff
	thesis: TariffsStoreType.TariffThesis
	thesisIdx: number
}

function Thesis(props: ThesisProps) {
	const { tariff, thesis, thesisIdx } = props
	const colorScheme = useGetColorScheme()

	return (
		<div className="tariff-card-theses__thesis">
			<DeleteButton tariff={tariff} thesis={thesis} />
			<CrossOutButton tariff={tariff} thesis={thesis} />
			<CloneButton tariff={tariff} thesis={thesis} />
			<p
				className={cn(
					'tariff-card-theses__thesis-text',
					'tariff-card-theses__thesis-text--' + colorScheme,
					thesis.crossedOut &&
						'tariff-card-theses__thesis-text--cross-out'
				)}
			>
				<EditableText
					tariffInnerId={tariff.innerId}
					propsPathArr={['theses', thesisIdx, 'text']}
					placeholder="Тезис"
					afterChange={tariffsManager.setNeedToSave.bind(
						tariffsManager
					)}
				/>
			</p>
		</div>
	)
}

type ButtonProps = {
	tariff: TariffsStoreType.Tariff
	thesis: TariffsStoreType.TariffThesis
}

// Кнопка удаления тезиса
function DeleteButton(props: ButtonProps) {
	const { tariff, thesis } = props

	const deleteThesis = useGetDeleteThesis(tariff.innerId, thesis.id)

	if (tariff.theses.length < 2) return null

	return (
		<Button
			size="small"
			shape="circle"
			icon={<DeleteOutlined />}
			className="tariff-card-theses__thesis-button tariff-card-theses__thesis-button--delete"
			onClick={deleteThesis}
		/>
	)
}

// Кнопка переключения зачеркивания тезиса
function CrossOutButton(props: ButtonProps) {
	const { tariff, thesis } = props

	const setCrossOutStatus = useGetSetCrossOutThesisStatus(
		tariff.innerId,
		thesis.id
	)

	return (
		<Button
			type={thesis.crossedOut ? 'primary' : 'default'}
			size="small"
			shape="circle"
			icon={<StrikethroughOutlined />}
			className="tariff-card-theses__thesis-button tariff-card-theses__thesis-button--strike"
			onClick={setCrossOutStatus}
		/>
	)
}

// Кнопка дублирования тезиса
function CloneButton(props: ButtonProps) {
	const { tariff, thesis } = props

	const cloneThesis = useGetCloneThesis(tariff.innerId, thesis.id)

	return (
		<Button
			size="small"
			shape="circle"
			icon={<PlusOutlined />}
			className="tariff-card-theses__thesis-button tariff-card-theses__thesis-button--add"
			onClick={cloneThesis}
		/>
	)
}
