import { Button, Form, Input } from 'antd'
import ModalWithForm from '../../../ui/ModalWithForm/ModalWithForm'
import useSetConfirmationCodeStore from './state/store'
import {
	UFormNewBottomButtonsAfterGray,
	UFormNewButtonsContainer,
	UFormNewGeneralError,
	UFormNewRow,
} from '../../../ui/UForm/UForm'
import { useGetOnSubmit } from './fn/submit'
import { GrayBlock } from '../../../ui/wrappers/GrayBlock/GrayBlock'
import { FieldNames, setErrorsToForm } from './fn/form'

function SetConfirmationCodeModal() {
	const [form] = Form.useForm()

	const [isOpen, closeModal, formErrors] = useSetConfirmationCodeStore(
		(state) => [state.isOpen, state.closeModal, state.formErrors]
	)

	const onSubmit = useGetOnSubmit()
	setErrorsToForm(form)

	return (
		<ModalWithForm
			title="Подтверждение почты"
			open={isOpen}
			onCancel={closeModal}
		>
			<Form
				form={form}
				name="setConfirmationCode"
				onFinish={onSubmit}
				layout="vertical"
			>
				<GrayBlock>
					<CodeInput />
				</GrayBlock>
				<UFormNewGeneralError message={formErrors.message} />
				<Bottom />
			</Form>
		</ModalWithForm>
	)
}

export default SetConfirmationCodeModal

function CodeInput() {
	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.ActivationCode}
				label="Код подтверждения почты"
				rules={[
					{
						required: true,
						message: 'Напишите код подтверждения почты',
					},
				]}
			>
				<Input type="text" />
			</Form.Item>
		</UFormNewRow>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom() {
	const form = Form.useFormInstance()

	const isSubmitting = useSetConfirmationCodeStore(
		(state) => state.isSubmitting
	)

	return (
		<UFormNewBottomButtonsAfterGray>
			<div />
			<UFormNewButtonsContainer>
				<Button
					disabled={
						isSubmitting ||
						form
							.getFieldsError()
							.filter(({ errors }) => errors.length).length > 0
					}
				>
					Отмена
				</Button>

				<Form.Item shouldUpdate>
					{() => {
						const formErrors = form
							.getFieldsError()
							.filter(({ errors }) => errors.length)

						return (
							<Button
								type="primary"
								htmlType="submit"
								disabled={isSubmitting || formErrors.length > 0}
								loading={isSubmitting}
							>
								Подтвердить
							</Button>
						)
					}}
				</Form.Item>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
