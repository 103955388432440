import { LandingTypes } from 'parts/types/LandingTypes'

/** Возвращает данные одностраничника по умолчанию */
export const defaultLandingData: LandingTypes.Landing = {
	colorScheme: LandingTypes.ColorScheme.ColorScheme_1,
	sections: {
		main: {
			isOpen: true,
			texts: {
				topHeader: 'Аудио-курс по английскому языку',
				header: 'Заговори на английском за 30 часов!',
				description:
					'Быстрый и интенсивный курс английского языка: заговори свободно и уверенно всего за 30 часов!',
			},
			imageUrl: '/landing/images/main-section.jpg',
			// Текст на кнопке перехода к покупке
			buyBtnText: 'Записаться на курс',
		},
		video: {
			isOpen: true,
			header: {
				imageUrl: '/landing/signs/waves.svg',
				text: 'Видео о курсе',
			},
			texts: {
				description:
					'За 30 секунд расскажем о чем это курс и почему стоит его купить!',
			},
			youtubeVideoUrl: 'https://www.youtube.com/watch?v=7SFtYd1mWgs',
			buyBtnText: 'Записаться на курс',
		},
		portrait: {
			isOpen: true,
			header: {
				imageUrl: '/landing/signs/waves.svg',
				text: 'Вам подходит этот курс, если вы:',
			},
			// Блоки с описанием ученика
			blocks: [
				{
					id: 1,
					columnNum: 1,
					colorTheme: 'color',
					header: 'Переехали за границу',
					text: 'У вас возникают трудности с коммуникацией с местными, сложно снять квартиру, найти работу, устроить ребенка в детсад, общаться с родителями в школе. Вызывает ужас от оформления документов, общение в госучреждениях или элементарный поход к врачу?',
					imageUrl: '/landing/images/portrait-plane.png',
				},
				{
					id: 2,
					columnNum: 1,
					colorTheme: 'white',
					header: 'Бизнесмен',
					text: 'Хотите открыть бизнес в новой стране, ищете новых партнеров за рубежом, инвесторов в свой стартап?Проблема с коммуникаций с персоналом и администрацией? Отсутствие нетворкинга в новой стране?',
					imageUrl: '/landing/images/portrait-human.png',
				},
				{
					id: 3,
					columnNum: 2,
					colorTheme: 'white',
					header: 'В поисках работы',
					text: 'Страшно пройти интервью, общение с коллегами на английском вызывает страх и не можете выдавить и слова? Не предлагают хороший оффер из-за отсутствия навыков общения на английском? Затягивается повышение по службе и/или повышения ЗП?',
					imageUrl: '/landing/images/portrait-lens.png',
				},
				{
					id: 4,
					columnNum: 2,
					colorTheme: 'color',
					header: 'Часто путешествуете',
					text: 'Не чувствуете себя безопасно за границей, появляется ощущение беспомощности, падает самооценка и появляется неуверенность при попытке заговорить или попросить о помощи? Переплачиваете, потому что покупаете у “своих”?',
					imageUrl: '/landing/images/portrait-map.png',
				},
			],
			buyBtnText: 'Записаться на курс',
		},
		existence: {
			isOpen: true,
			header: {
				imageUrl: '/landing/signs/waves.svg',
				text: 'Вам подходит этот курс, если у вас:',
			},
			imageUrl: '/landing/images/existence.png',
			// Блоки с описанием ученика
			blocks: [
				{
					id: 1,
					columnNum: 1,
					colorTheme: 'green',
					header: 'Есть',
					text: 'потребность реанимировать свой английский?',
					imageUrl: '/landing/images/existence_1.png',
				},
				{
					id: 2,
					columnNum: 1,
					colorTheme: 'red',
					header: 'Нет',
					text: 'желания учить грамматику или ничего не понимаете в ней',
					imageUrl: '/landing/images/existence_2.png',
				},
				{
					id: 3,
					columnNum: 1,
					colorTheme: 'green',
					header: 'Есть',
					text: 'желание понимать на слух носителей?',
					imageUrl: '/landing/images/existence_3.png',
				},
				{
					id: 4,
					columnNum: 2,
					colorTheme: 'red',
					header: 'Не',
					text: 'получается составить слова в предложения?',
					imageUrl: '/landing/images/existence_4.png',
				},
				{
					id: 5,
					columnNum: 2,
					colorTheme: 'green',
					header: 'Хотите',
					text: 'знать “живую речь”, а не слова из старых учебников?',
					imageUrl: '/landing/images/existence_5.png',
				},
				{
					id: 6,
					columnNum: 2,
					colorTheme: 'red',
					header: 'Нет',
					text: 'времени на английский и вы занятой человек',
					imageUrl: '/landing/images/existence_6.png',
				},
			],
			buyBtnText: 'Записаться на курс',
		},
		time: {
			isOpen: true,
			header: {
				imageUrl: '/landing/signs/waves.svg',
				text: 'За время обучения вы научитесь',
			},
			imageUrl: '/landing/images/time.png',
			// Блоки с описанием ученика
			blocks: [
				{
					id: 1,
					columnNum: 1,
					text: 'Грамматически верно выражать свои мысли используя готовые фразы',
				},
				{
					id: 2,
					columnNum: 1,
					text: 'Поймете правила произношения',
				},
				{
					id: 3,
					columnNum: 1,
					text: 'Научитесь выбирать верную интонацию',
				},
				{
					id: 4,
					columnNum: 1,
					text: 'Гораздо лучше понимать устную английскую речь',
				},
				{
					id: 5,
					columnNum: 1,
					text: 'Формулировать собственные вопросы к работодателю',
				},
				{
					id: 6,
					columnNum: 2,
					text: 'Легко запомните 500+ готовых фраз, а не слов по отдельности',
				},
				{
					id: 7,
					columnNum: 2,
					text: 'Значительно (на 85%) расширите свой словарный запас',
				},
				{
					id: 8,
					columnNum: 2,
					text: 'Использовать формальный и неформальный стиль общения',
				},
				{
					id: 9,
					columnNum: 2,
					text: 'Не теряться и давать ответ на каверзные и неожиданные вопросы',
				},
			],
			buyBtnText: 'Записаться на курс',
		},
		tariffs: {
			isOpen: true,
			header: {
				imageUrl: '/landing/signs/waves.svg',
				text: 'Тарифы',
			},
			buyBtnText: 'Записаться на курс',
		},
		program: {
			isOpen: true,
			header: {
				imageUrl: '/landing/signs/waves.svg',
				text: 'Программа обучения',
			},
			// Блоки с описанием ученика
			blocks: [
				{
					id: 1,
					columnNum: 1,
					header: 'Небольшая беседа',
					text:
						'Рассказ о себе\n' +
						'Проявление интереса\n' +
						'Как представить себя\n' +
						'Снять напряжение в общении\n' +
						'Как оставаться на связи\n' +
						'Фразы, чтобы попрощаться',
					imageUrl: '/landing/images/program_1.png',
				},
				{
					id: 2,
					columnNum: 1,
					header: 'Встречи с людьми из прошлого',
					text:
						'Как поприветствовать, если Вы давно не виделись\n' +
						'Знакомство с новыми людьми\n' +
						'Как представить своих друзей и знакомых\n' +
						'Обмен новостями\n' +
						'Как рассказать о своем прошлом\n' +
						'Как завершить разговор',
					imageUrl: '/landing/images/program_2.png',
				},
				{
					id: 3,
					columnNum: 1,
					header: 'Нравится, не нравится и интересы',
					text: `Как выразить свои чувства
Как рассказать о том, что нравится
И не нравится
Предпочтения
Интересы
Желания и зависимость
Безразличие
Как предложить что-либо
Свидания и приглашения
Выражение эмоций
Комплименты`,
					imageUrl: '/landing/images/program_3.png',
				},
				{
					id: 4,
					columnNum: 2,
					header: 'Вещи и покупки',
					text: `Как описать вещи на английском
Свойства и характеристики
Как рассказать о стоимости покупки на английском?
Что сказать, если товар Вам не нравится?
Как спросить про наличие и доступность товаров
Что сказать, если Вам товар не понравился?
Как дать инструкции на английском
Выражение эмоций`,
					imageUrl: '/landing/images/program_4.png',
				},
				{
					id: 5,
					columnNum: 2,
					header: 'Проблемы и советы',
					text:
						'Как спросить «что случилось?»\n' +
						'Как рассказать о проблеме на английском\n' +
						'Просьба о помощи или совете\n' +
						'Сочувствие и понимание\n' +
						'Как совет Совет и помощь\n' +
						'Благодарность на английском\n' +
						'Как пожаловаться на английском',
					imageUrl: '/landing/images/program_5.png',
				},
				{
					id: 6,
					columnNum: 2,
					header: 'Решения и цели',
					text:
						'Что мы собираемся делать?\n' +
						'Как предложить Варианты и альтернативы\n' +
						'Как рассказать о причинах\n' +
						'Преимущества и недостатки\n' +
						'Как принять решение\n' +
						'Как выразить нерешительность\n' +
						'Как составить расписание и установить сроки\n' +
						'Цели и планы\n' +
						'Поощрение, признание и поздравления\n' +
						'Выражение эмоций',
					imageUrl: '/landing/images/program_6.png',
				},
			],
			bulletsBlocks: [
				{
					id: 1,
					header: 'Ваш успех в английском всего за 3 шага',
					bullets: [
						{
							id: 1,
							text: 'Слушаете аудио урок в любое свободное время',
							imageUrl: '/landing/images/program-bullet_1.png',
						},
						{
							id: 2,
							text: 'Составляете свои примеры как в уроке',
							imageUrl: '/landing/images/program-bullet_2.png',
						},
						{
							id: 3,
							text: 'Повторяете вслух и запоминаете навсегда',
							imageUrl: '/landing/images/program-bullet_3.png',
						},
					],
				},
				{
					id: 2,
					header: 'Что входит в обучение?',
					bullets: [
						{
							id: 1,
							text: '30 тем на самые популярные темы',
							imageUrl: '/landing/images/program-bullet_4.png',
						},
						{
							id: 2,
							text: '100 аудио уроков по 20 минут',
							imageUrl: '/landing/images/program-bullet_5.png',
						},
						{
							id: 3,
							text: '30 часов времени звучания курса',
							imageUrl: '/landing/images/program-bullet_6.png',
						},
						{
							id: 4,
							text: 'Пожизненный доступ к курсу',
							imageUrl: '/landing/images/program-bullet_7.png',
						},
						{
							id: 5,
							text: 'Доступ к обновлению курса',
							imageUrl: '/landing/images/program-bullet_8.png',
						},
					],
				},
			],
			buyBtnText: 'Записаться на курс',
		},
		ways: {
			isOpen: true,
			header: {
				text: 'Сейчас у вас есть 2 пути',
			},
			wrongWay: {
				header: 'Остаться на прежнем уровне',
				text: 'Продолжать и дальше ничего не делать, думать что у вас нет времени, Вам не дано или использовать другую стандартную отговорку',
			},
			rightWay: {
				header: 'Сделать шаг и пройти обучение',
				text: 'В буквальном смысле улучшить свою жизнь, чувствовать себя уверенно за границей, в переговорах, на собеседовании или любой непредвиденной ситуации',
			},
			buyBtnText: 'Записаться на курс',
		},
		faq: {
			isOpen: true,
			header: {
				imageUrl: '/landing/images/faq.png',
				text: 'Ответы на популярные вопросы',
			},
			faqBlocks: [
				{
					id: 1,
					question:
						'Какой уровень английского необходим для прохождения курса?',
					answer: 'Для прохождения курса подойдёт начальный уровень знаний в английском.',
					isOpen: true,
				},
				{
					id: 2,
					question: 'Есть ли возможность выбора тем курса?',
					answer: 'Такой возможности нет и не предвидется.',
					isOpen: true,
				},
				{
					id: 3,
					question:
						'Какие форматы могут быть использованы для обучения, например, аудио-файлы или видео-уроки?',
					answer: 'Ответ...',
					isOpen: false,
				},
				{
					id: 4,
					question:
						'Есть ли возможность получить обратную связь от преподавателя?',
					answer: 'Ответ...',
					isOpen: false,
				},
				{
					id: 5,
					question: 'Как долго будет доступен курс после покупки?',
					answer: 'Ответ...',
					isOpen: false,
				},
				{
					id: 6,
					question:
						'Как происходит оценка прогресса и какие результаты можно ожидать после прохождения курса?',
					answer: 'Ответ...',
					isOpen: false,
				},
				{
					id: 7,
					question:
						'Какие особенности процесса обучения имеются на курсе?',
					answer: 'Ответ...',
					isOpen: false,
				},
				{
					id: 8,
					question:
						'Является ли курс привязанным к определённой школе или организации?',
					answer: 'Ответ...',
					isOpen: false,
				},
				{
					id: 9,
					question:
						'Как организована поддержка учащихся во время обучения?',
					answer: 'Ответ...',
					isOpen: false,
				},
				{
					id: 10,
					question:
						'Какие инструменты используются для проверки знаний после прохождения курса?',
					answer: 'Ответ...',
					isOpen: false,
				},
			],
			buyBtnText: 'Записаться на курс',
		},
	},
}
