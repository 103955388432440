import { useTranslation } from 'react-i18next'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import { EmptyBlock } from 'ui/EmptyBlock/EmptyBlock'
import { TrainingListStudentController } from '../trainingsListStudent/TrainingListStudentController'
import Loader from 'ui/Loader/Loader'

// Статья со списком курсов для ученика
function TrainingsStudentArticle() {
	const { t } = useTranslation()
	const trainingListRouter = TrainingListStudentController.router()

	return (
		<ArticleContentWrapper
			center={
				trainingListRouter.status == 'loading' ||
				trainingListRouter.status == 'noData'
			}
		>
			{trainingListRouter.status == 'loading' && <Loader />}
			{trainingListRouter.status == 'noData' && (
				<EmptyBlock
					text={t(
						'studentTrainings.trainingsPageNoTrainingsPurchased'
					)}
				/>
			)}
			{trainingListRouter.status == 'hasData' &&
				trainingListRouter.element}
		</ArticleContentWrapper>
	)
}

export default TrainingsStudentArticle
