import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

/** Возвращает булево значение, находится ли пользователь на странице с переданным адресом. */
export function useIsCurrentPage(address: string) {
	const { pathname } = useLocation()

	return useMemo(
		function () {
			return address == pathname
		},
		[pathname]
	)
}
