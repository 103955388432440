import EntityTypes from 'parts/types/EntityTypes'

/**
 * Возвращает список данные, для формирования выпадающего списка с выбором когда открывается курс.
 * @param isGroupPaid — группа платная?
 */
export function getStartDateOptions(isGroupPaid: boolean) {
	const startDateOptions = [
		{
			label: 'Открытие 1 урока',
			value: EntityTypes.GroupStartCondition.OpenFirstLesson,
		},
		{
			label: 'Дата старта курса',
			value: EntityTypes.GroupStartCondition.StartDate,
		},
	]

	// Если группа платная, то добавить дополнительный пункт
	if (isGroupPaid) {
		startDateOptions.unshift({
			label: 'Дата покупки',
			value: EntityTypes.GroupStartCondition.PurchaseDate,
		})
	}

	return startDateOptions
}

// Данные, для формирования выпадающего списка с выбором режима прохождения уроков
export const passingModeOptions = [
	{
		label: 'Открытый',
		value: EntityTypes.GroupMode.Open,
		comment: 'Все задания в марафоне сразу доступны для выполнения',
	},
	{
		label: 'По интервалам',
		value: EntityTypes.GroupMode.ByIntervals,
		comment: 'Новые уроки будут открываться в указанные дни	недели',
	},
	{
		label: 'По дням недели',
		value: EntityTypes.GroupMode.ByWeekDays,
		comment: 'Новые уроки будут открываться в указанные дни недели',
	},
]

// Данные, для формирования выпадающего списка интервала открытия уроков
export const intervalOptions = Object.values(
	EntityTypes.OpeningByIntervals
).filter((value) => typeof value !== 'number')

// Данные, для формирования выпадающего списка для открытия уроков курса
export const openingByWeekDaysOptions: EntityTypes.SelectDayOptions[] = [
	{
		label: 'Понедельник',
		value: EntityTypes.OpeningByWeekDays.Monday,
		disabled: false,
	},
	{
		label: 'Вторник',
		value: EntityTypes.OpeningByWeekDays.Tuesday,
		disabled: false,
	},
	{
		label: 'Среда',
		value: EntityTypes.OpeningByWeekDays.Wednesday,
		disabled: false,
	},
	{
		label: 'Четверг',
		value: EntityTypes.OpeningByWeekDays.Thursday,
		disabled: false,
	},
	{
		label: 'Пятница',
		value: EntityTypes.OpeningByWeekDays.Friday,
		disabled: false,
	},
	{
		label: 'Суббота',
		value: EntityTypes.OpeningByWeekDays.Saturday,
		disabled: false,
	},
	{
		label: 'Воскресение',
		value: EntityTypes.OpeningByWeekDays.Sunday,
		disabled: false,
	},
	{
		label: 'По будням',
		value: EntityTypes.OpeningByWeekDays.OnWeekdays,
		disabled: false,
	},
	{
		label: 'Каждый день',
		value: EntityTypes.OpeningByWeekDays.Everyday,
		disabled: false,
	},
	{
		label: 'По выходным',
		value: EntityTypes.OpeningByWeekDays.OnWeekends,
		disabled: false,
	},
]
