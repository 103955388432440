import { EXERCISE_ATTEMPTS } from '../../../common/exerciseConst'
import useExerciseStore from '../../zustand/exerciseState'
import { useGetClearHtmlBlocks } from '../../zustand/fn/modifyStore'

/**
 * Хук запускает функцию запускающую прохождение упражнения заново
 */
export function useGetPassExerciseAgainFn() {
	const clearHtmlBlocks = useGetClearHtmlBlocks()

	const saveStoreForRestore = useExerciseStore(
		(store) => store.saveStoreForRestore
	)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		// Отправить на состояние с экраном результата
		updateStore({
			stage: 'NotReadyToCheck',
			saveStoreForRestore: saveStoreForRestore + 1,
			attempts: EXERCISE_ATTEMPTS,
		})

		clearHtmlBlocks()
	}
}
