import EntityTypes from './EntityTypes'

namespace TrainingEntityTypes {
	// Данные курса для администратора
	export type TrainingAdmin = {
		id: number
		name: string // Английский для продолжающих
		description: string // Для тех, кто учил английский в школе или университете
		cover: null | string
		isPublished: boolean
		lessons: LessonAdmin[]
		landingUrl: string
		groupCount: number // 12
		lessonCount: number // 0
		// Общее количество учеников во всех группах проходящих этот курс
		studentCount: number
		createdAt: string // "2022-10-11T16:02:13.777Z"
		groups: EntityTypes.GroupData[]
		level?: string
		lang?: string
		type?: string
		order?: number
		showOnMainScreen?: boolean
	}

	export type LessonAdmin = {
		trainingId?: number
		id: number
		name: string
		description: string
		cover: string
		order: number // Порядковый номер урока
		exercises: ExerciseItem[]
		isFree: boolean
	}

	// Данные курса для ученика
	export type TrainingStudent = {
		// id курса
		trainingId: number
		//  Название курса
		trainingName: string
		// Описание курса
		trainingDescription: string
		// id группы, которая есть у курса и в составе этой группы ученик проходит его.
		groupId: number
		// Название группы, которая есть у курса и в составе этой группы ученик проходит его.
		groupName: string
		// Обложка курса
		cover?: string
		// Время начала группы, на которую записан ученик.
		// Если дата начала курса считается от даты начала группы или стрстроковая дата покупки, то дата.
		// Если открытие первого урока, то 'firstLessonOpening'. После открытия в startDate будет приходить дата.
		startDate: 'firstLessonOpening' | string
		// Продолжительность группы в днях
		durationInDays: number
		// Сколько в днях ученик имеет доступ на курс
		accessInDays: number
		// Процент прохождения курса
		progress: number
		// Количество невыполненных уроков (учитываются недоступные)
		unperformedLessons: number
		// Количество невыполненных упражнений (учитываются недоступные уроки)
		unperformedExercises: number
		lessons: LessonStudent[]
	}

	export type LessonStudent = {
		id: number
		name: string
		description: string
		// Обложка урока
		cover?: string
		// Когда урок открывается.
		// Если все уроки в группе изначально открыты, то записывается или дата покупки группы или дата начала группы.
		// Если уроки открываются по интервалам или по дням недели, то записывается рассчитываемая дата.
		// Если дата начала группы зависит от открытия первого урока, то записывается 'firstLessonOpening'
		startDate: 'firstLessonOpening' | string
		// Процент выполненности урока
		// Хотя в существующих данных есть, но я могу это понять по данным упражнений.
		performed: number
		exercises: ExerciseItem[]
	}

	// Урок без идентификатора
	export type BareLesson = Omit<LessonAdmin, 'id'>

	export type ExerciseItem =
		| VideoExercise
		| AudioExercise
		| ImageExercise
		| TextExercise
		| TestExercise
		| TrueOrFalseExercise
		| GapsInWordsExercise
		| ChooseWordExercise
		| DropWordExercise
		| DescriptionImgExercise

	export type ExerciseTypes =
		| 'video'
		| 'audio'
		| 'image'
		| 'text'
		| 'test'
		| 'trueOrFalse'
		| 'gapsInWords'
		| 'chooseWord'
		| 'dropWords'
		| 'descriptionImg'

	// Типы упражнений, где есть форма генерирования текста
	export type ExerciseTypesWithGenerator =
		| 'text'
		| 'test-single-answer'
		| 'test-many-answers'
		| 'trueOrFalse'
		| 'gapsInWords'
		| 'chooseWord'
		| 'dropWords'

	// Упражнение "Загрузить видео"
	export interface VideoExercise {
		id?: number
		lessonId: number
		type: 'video'
		// Порядковый номер упражнения
		order: number
		item: {
			task: string
			video: Video[]
		}
		// Существует только для ученика
		status: ExerciseProgressStatus
		// Существует только для ученика ("2022-12-27T13:20:56.908Z")
		statusUpdated: string
	}
	export interface Video {
		name: string
		link: string
		video: string
	}

	// Упражнение "Загрузить аудио"
	export interface AudioExercise {
		id?: number
		lessonId: number
		type: 'audio'
		order: number // Порядковый номер упражнения
		item: {
			task: string
			audio: string[]
			// true если на упражнение не нужно давать ответ
			noneAnswer: boolean
			// На упражнение нужно давать голосовой ответ
			audioAnswer: boolean
			// На упражнение нужно давать письменный ответ
			writtenAnswer: boolean
			// Есть транскрипция
			transcription: string
		}
		status: ExerciseProgressStatus // Существует только для ученика
		statusUpdated: string // Существует только для ученика ("2022-12-27T13:20:56.908Z")
	}

	// Упражнение "Загрузить изображение"
	export interface ImageExercise {
		id?: number
		lessonId: number
		type: 'image'
		order: number // Порядковый номер упражнения
		item: {
			task: string
			image: string[]
			// true если на упражнение не нужно давать ответ
			noneAnswer: boolean
			// На упражнение нужно давать голосовой ответ
			audioAnswer: boolean
			// На упражнение нужно давать письменный ответ
			writtenAnswer: boolean
			// Есть транскрипция
			transcription: string
		}
		status: ExerciseProgressStatus // Существует только для ученика
		statusUpdated: string // Существует только для ученика ("2022-12-27T13:20:56.908Z")
	}

	// Упражнение "Загрузить текст"
	export interface TextExercise {
		id?: number
		lessonId: number
		type: 'text'
		order: number // Порядковый номер упражнения
		item: {
			task: string
			text: string
			// Текст задания на генерирование текста
			generatedTextPrompt: string
		}
		status: ExerciseProgressStatus // Существует только для ученика
		statusUpdated: string // Существует только для ученика ("2022-12-27T13:20:56.908Z")
	}

	// Упражнение "Создание теста"
	export interface TestExercise {
		id?: number
		lessonId: number
		type: 'test'
		order: number // Порядковый номер упражнения
		item: {
			name: string
			task: string
			// Текст задания на генерирование текста
			generatedTextPrompt: string
			questions: TestQuestion[]
		}
		status: ExerciseProgressStatus // Существует только для ученика
		statusUpdated: string // Существует только для ученика ("2022-12-27T13:20:56.908Z")
	}

	export interface TestQuestion {
		id: number
		text: string
		audio: string // Ссылка на загруженный звуковой файл
		video: string // Ссылка на загруженное видео
		videoLink: string // Ссылка на видео на внешнем ресурсе (YouTube)
		image: string // Ссылка на загруженное изображение
		multipleSelect: boolean // Возможно ли несколько правильных ответов?
		answers: TestAnswer[] // Массив ответов
	}

	export interface TestAnswer {
		id: number
		answer: string // Текст ответа
		correct: boolean // Правильный ли вариант ответа
	}

	// Упражнение "Выбрать ложь или истину"
	export interface TrueOrFalseExercise {
		id?: number
		lessonId: number
		type: 'trueOrFalse'
		order: number // Порядковый номер упражнения
		item: {
			task: string
			statements: TrueOrFalseStatement[]
		}
		status: ExerciseProgressStatus // Существует только для ученика
		statusUpdated: string // Существует только для ученика ("2022-12-27T13:20:56.908Z")
	}

	export interface TrueOrFalseStatement {
		text: string
		correct: boolean
		image: string
		// Текст задания на генерирование текста
		generatedTextPrompt: string
	}

	// Упражнение "Ввести слова в пропуски"
	export type GapsInWordsExercise = {
		id?: number
		type: 'gapsInWords'
		order: number // Порядковый номер упражнения
		item: {
			task: string
			texts: GapsInWordsTextBlock[]
		}
		status: ExerciseProgressStatus // Существует только для ученика
		statusUpdated: string // Существует только для ученика ("2022-12-27T13:20:56.908Z")
	}

	export type GapsInWordsTextBlock = {
		text: string
		// Текст задания на генерирование текста
		generatedTextPrompt: string
	}

	// Упражнение "Выбрать правильную форму слова"
	export interface ChooseWordExercise {
		id?: number
		lessonId: number
		type: 'chooseWord'
		order: number // Порядковый номер упражнения
		item: {
			task: string
			texts: GapsInWordsTextBlock[]
		}
		status: ExerciseProgressStatus // Существует только для ученика
		statusUpdated: string // Существует только для ученика ("2022-12-27T13:20:56.908Z")
	}

	// Упражнение "Перетаскивание слов в пропуски"
	export interface DropWordExercise {
		id?: number
		lessonId: number
		type: 'dropWords'
		order: number // Порядковый номер упражнения
		item: {
			task: string
			texts: DropWordText[]
		}
		status: ExerciseProgressStatus // Существует только для ученика
		statusUpdated: string // Существует только для ученика ("2022-12-27T13:20:56.908Z")
	}

	export interface DropWordText {
		word: string
		// Текст задания на генерирование текста
		generatedTextPrompt: string
		wrongWords: string[]
	}

	// Упражнение "Сопоставить описание и изображение"
	export interface DescriptionImgExercise {
		id?: number
		lessonId: number
		type: 'descriptionImg'
		order: number // Порядковый номер упражнения
		item: {
			task: string
			images: DescriptionImgItem[]
			wrong: string[]
		}
		status: ExerciseProgressStatus // Существует только для ученика
		statusUpdated: string // Существует только для ученика ("2022-12-27T13:20:56.908Z")
	}

	export interface DescriptionImgItem {
		image: string
		description: string
	}

	// Статус прохождения упражнения учеником:
	// 0 (упражнение ещё не прошли)
	// 1 (упражнение пройдено)
	export enum ExerciseProgressStatus {
		PENDING = 0,
		SUCCESS = 1,
	}
}

export default TrainingEntityTypes
