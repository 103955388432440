import useAddVideoUrlModalStore from 'pages/landing/landingConstructor/details/AddVideoUrlModal/zustand/store'

/**
 * Возвращает функцию, открывающую/закрывающую модальное окно добавления нового ученика
 * @param isVisible — должно ли окно быть видимым
 */
export function useGetSetVisibilityToModal(isVisible: boolean) {
	const updateStore = useAddVideoUrlModalStore((state) => state.updateStore)

	return function () {
		updateStore({ isOpen: isVisible })
	}
}
