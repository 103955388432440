import React, { useRef } from 'react'
import ExerciseStateType from './fn/ExerciseStateType'
import HtmlEditorContentView from 'ui/HTMLEditorContentView/HTMLEditorContentView'
import { useSetInnerHtml } from '../common/useSetInnerHtml'
import { Question } from './Question'
import { Bottom } from './Bottom'
import './TestExerciseView.scss'

type ExerciseProps = {
	state: ExerciseStateType.Main
	setState: React.Dispatch<React.SetStateAction<ExerciseStateType.Main>>
}

// Компонент упражнения-теста
function Exercise(props: ExerciseProps) {
	const { state, setState } = props

	return (
		<>
			<Name name={state.exerciseName} />
			<Task task={state.exerciseTask} />
			<Question state={state} setState={setState} />
			<Bottom state={state} setState={setState} />
		</>
	)
}

export default Exercise

type NameProps = {
	name: string
}

// Название теста
function Name(props: NameProps) {
	const { name } = props

	const areaRef = useRef(null)
	useSetInnerHtml(areaRef, name)

	if (!name) return null

	return <HtmlEditorContentView areaRef={areaRef} />
}

type TaskProps = {
	task: string
}

// Задача
function Task(props: TaskProps) {
	const { task } = props

	const areaRef = useRef(null)
	useSetInnerHtml(areaRef, task)

	if (!task) return null

	return (
		<HtmlEditorContentView
			areaRef={areaRef}
			outerClass="exercise-test-content__task"
		/>
	)
}
