import i18next from 'i18next'
import Site from './site'
import UrlService from '../services/UrlService'
import { v4 as uuidv4 } from 'uuid'

export const AuthUrls = {
	Login() {
		return {
			url: '/login',
			name: i18next.t('url.auth.Login'), // 'Вход',
		}
	},
	Register() {
		return {
			url: '/register',
			name: i18next.t('url.auth.Register'), // 'Регистрация',
		}
	},
	Register_Owner_Start() {
		return {
			url: '/register',
			name: i18next.t('url.auth.Register_Owner_Start'), // 'Регистрация администратора',
		}
	},
	Register_Owner_School() {
		return {
			url: '/register/owner/school',
			name: i18next.t('url.auth.Register_Owner_School'), // 'Создание школы',
		}
	},
	Register_Methodist() {
		return {
			url: '/register/methodist',
			name: i18next.t('url.auth.Register_Methodist'), // 'Регистрация методиста',
		}
	},
	// Страница ввода проверочного кода присланного для подтверждения почты
	Register_ConfirmEmail() {
		return {
			url: '/register/confirm',
			name: i18next.t('url.auth.Register_ConfirmEmail'), // 'Ввод проверочного кода',
		}
	},
	// Страница ввода почты на которую должен прийти код подтверждения
	RecoveryStart() {
		return {
			url: '/recovery/start',
			name: i18next.t('url.auth.RecoveryStart'), // 'Восстановление пароля',
		}
	},
	// Страница ввода кода подтверждения почты
	RecoveryCheck(email: string) {
		return {
			urlShort: '/recovery/check',
			url: '/recovery/check?email=' + email,
			name: i18next.t('url.auth.RecoveryCheck'), // 'Ввод кода подтверждения',
		}
	},
	// Страница ввода нового пароля
	RecoverySet(code: string) {
		return {
			urlShort: '/recovery/set',
			url: '/recovery/set?code=' + code,
			name: i18next.t('url.auth.RecoverySet'), // 'Установка нового пароля',
		}
	},
	// Страница с уведомлением о применении нового пароля
	RecoveryFinish() {
		return {
			url: '/recovery/finish',
			name: i18next.t('url.auth.RecoveryFinish'), // 'Пароль установлен',
		}
	},
	// MainLanding() {
	// 	return {
	// 		url: '/tutors/',
	// 		name: i18next.t('url.auth.MainLanding'), // 'Лендинг',
	// 	}
	// },
	DeleteAccount() {
		return {
			url: '/delete-account',
			name: i18next.t('url.auth.DeleteAccount'), // 'Удаление аккаунта',
		}
	},
	DeleteAccountMailSent() {
		return {
			url: '/delete-account-mail-sent',
			name: i18next.t('url.auth.DeleteAccount'), // 'Удаление аккаунта',
		}
	},
	DeleteAccountConfirm(deleteAccountCode: number | string) {
		return {
			url: '/delete-account/' + deleteAccountCode,
			name: i18next.t('url.auth.DeleteAccountConfirm'), // 'Подтверждение удаления аккаунта',
		}
	},
	DeleteAccountDone() {
		return {
			url: '/delete-account-done',
			name: i18next.t('url.auth.DeleteAccount'), // 'Удаление аккаунта',
		}
	},
}

export const MainUrls = {
	Main() {
		return {
			url: '/',
			name: i18next.t('url.main.Main'), // 'Главная',
		}
	},
	// Главная страница школы на поддомене третьего уровня
	SchoolMain(domain: string) {
		return {
			url: Site.protocol + domain,
			name: i18next.t('url.main.SchoolMain'), // 'Школа',
		}
	},
	Success() {
		return {
			url: '/success',
			name: i18next.t('url.main.Success'), // 'Успешна покупка',
		}
	},
	Fail() {
		return {
			url: '/fail',
			name: i18next.t('url.main.Fail'), // 'Покупка не удалась',
		}
	},
	Tutors() {
		return {
			url: '/tutors',
			name: 'Tutors landing',
		}
	},
}

export const StudentUrls = {
	Students() {
		return {
			url: '/students',
			name: i18next.t('url.student.Students'), // 'Ученики',
		}
	},
	// Форма с редактированием учётной записи ученика
	Student_Account(studentId: number | string) {
		return {
			url: '/students/' + studentId,
			name: i18next.t('url.student.Student_Account'), // 'Профиль',
		}
	},
	// Заказы пользователя
	Student_Orders(studentId: number | string) {
		return {
			url: '/students/' + studentId + '/orders',
			name: i18next.t('url.student.Student_Orders'), // 'Заказы',
		}
	},
	// Журнал действий пользователя
	Student_History(studentId: number | string) {
		return {
			url: '/students/' + studentId + '/history',
			name: i18next.t('url.student.Student_History'), // 'Журнал действий',
		}
	},
	// Страница с данными по посещаемости курсов
	Student_Visits(studentId: number | string) {
		return {
			url: '/students/' + studentId + '/visits',
			name: i18next.t('url.student.Student_Visits'), // 'Посещения',
		}
	},
	// Страница с данными по посещаемости курса группы
	Student_Visit(studentId: number | string, trainingId: number | string) {
		return {
			url: '/students/' + studentId + '/visits/' + trainingId,
			name: i18next.t('url.student.Student_Visit'), // 'Курс',
		}
	},
}

export const StaffUrls = {
	Staff() {
		return {
			url: '/staff',
			name: i18next.t('url.staff.Employee'), // 'Сотрудники',
		}
	},
	// Страница сотрудника
	// Страницы ученика по адресу /students/50
	Employee(employeeId: number | string) {
		return {
			// Форма с редактированием учётной записи сотрудника
			url: '/staff/' + employeeId,
			name: i18next.t('url.staff.Employee'), // 'Сотрудники',
		}
	},
	// Права сотрудника
	Employee_Works(employeeId: number | string) {
		return {
			url: '/staff/' + employeeId + '/groups',
			name: i18next.t('url.staff.Employee_Works'), // 'Группы сотрудника',
		}
	},
	// Права сотрудника
	Employee_Rights(employeeId: number | string) {
		return {
			url: '/staff/' + employeeId + '/rights',
			name: i18next.t('url.staff.Employee_Rights'), // 'Права пользователя',
		}
	},
}

export const TrainingUrls = {
	Trainings() {
		return {
			url: '/trainings',
			name: i18next.t('url.training.Trainings'), // 'Мои курсы',
		}
	},
	// Страница курса вида /trainings/55 или /trainings/55-42
	// Для администратора достаточно знать только идентификатор курса
	// Для ученика ещё нужно знать идентификатор группы, поэтому он будет отделён дефисом
	Training(trainingId: string | number, groupId?: string | number) {
		let courseId = trainingId

		if (groupId)
			courseId = UrlService.createTrainingAndGroupIdsUrl(
				courseId,
				groupId
			)

		return {
			url: '/trainings/' + courseId,
			name: i18next.t('url.training.Training'), // 'Курс',
		}
	},
	Training_Groups(trainingId: string | number) {
		return {
			url: `/trainings/${trainingId}/groups`,
			name: i18next.t('url.training.Training_Groups'), // 'Группы',
		}
	},
	// Конструктор одностраничника курса для продажи
	Training_LandingConstructor(trainingId: string | number) {
		return {
			url: `/trainings/${trainingId}/constructor`,
			name: i18next.t('url.training.Training_LandingConstructor'), // 'Конструктор сайта',
		}
	},
	// Предпросмотр одностраничника с продажей курса во время редактирования
	Training_LandingConstructorPreview(trainingId: string | number) {
		return {
			url: `/trainings/${trainingId}/constructor/preview`,
			name: i18next.t('url.training.Training_LandingConstructorPreview'), // 'Предпросмотр одностраничника',
		}
	},
	Training_GroupConditions(
		trainingId: string | number,
		groupId: string | number
	) {
		return {
			url: `/trainings/${trainingId}/groups/${groupId}`,
			name: i18next.t('url.training.Training_GroupConditions'), // 'Условия прохождения',
		}
	},
	Training_GroupCurators(
		trainingId: string | number,
		groupId: string | number
	) {
		return {
			url: `/trainings/${trainingId}/groups/${groupId}/curators`,
			name: i18next.t('url.training.Training_GroupCurators'), // 'Кураторы и менеджеры',
		}
	},
	Training_GroupOpenLessons(
		trainingId: string | number,
		groupId: string | number
	) {
		return {
			url: `/trainings/${trainingId}/groups/${groupId}/open-lessons`,
			name: i18next.t('url.training.Training_GroupOpenLessons'), // 'Открытые уроки',
		}
	},
	Lesson(trainingId: string | number, lessonId: string | number) {
		return {
			url: '/trainings/' + trainingId + `/lessons/${lessonId}`,
			name: i18next.t('url.training.Lesson'), // 'Конструктор упражнения',
		}
	},
	Exercise(
		trainingId: string | number,
		lessonId: string | number,
		exerciseId: string | number
	) {
		return {
			url: `/trainings/${trainingId}/lessons/${lessonId}/exercises/${exerciseId}`,
			name: i18next.t('url.training.Exercise'), // 'Прохождение упражнения',
		}
	},
	LandingConstructor(trainingId: string | number) {
		return {
			url: `/landing-constructor/${trainingId}`,
			name: i18next.t('url.training.LandingConstructor'), // 'Конструктор сайта',
		}
	},
}

export const LandingUrl = {
	// В landingId идентификатор одностраничника вида 3_193744
	Landing(landingId: string) {
		return {
			url: '/lp/' + landingId,
			name: '',
		}
	},
}

export const MyUrls = {
	// Мои школы
	MySchools() {
		return {
			url: '/my/schools',
			name: i18next.t('url.my.MySchools'), // 'Мои школы',
		}
	},
	// Моя школа
	MySchool(schoolId: string | number) {
		return {
			url: '/my/schools/' + schoolId,
			name: i18next.t('url.my.MySchool'), // 'Моя школа',
		}
	},
	// Мои системы оплат
	MyPayments() {
		return {
			url: '/my/payments',
			name: i18next.t('url.my.MyPayments'), // 'Мои системы оплат',
		}
	},
	// Мои системы оплат
	MyPayment(paymentSystem: string) {
		return {
			url: '/my/payments/' + paymentSystem,
			name: paymentSystem,
		}
	},
	MySubscription() {
		return {
			url: '/my/subscription',
			name: i18next.t('url.my.MySubscription'), // 'Моя подписка',
		}
	},
	MySubscriptionSuccess() {
		return {
			url: '/my/subscription/success',
			name: i18next.t('url.my.MySubscriptionSuccess'), // 'Моя подписка',
		}
	},
	MySubscriptionFail() {
		return {
			url: '/my/subscription/fail',
			name: i18next.t('url.my.MySubscriptionFail'), // 'Моя подписка',
		}
	},
	MyPaymentsHistory() {
		return {
			url: '/my/paymentsHistory',
			name: i18next.t('url.my.MyPaymentsHistory'), // 'Моя подписка',
		}
	},
	MyExpressCourses() {
		return {
			url: '/my/express',
			name: 'Экспресс курс',
		}
	},
	MyExpressCourse(course: string) {
		return {
			url: '/my/express/' + course,
			name: course,
		}
	},
	MyExpressLesson(courseName: string, lessonId: number | string) {
		return {
			url: `/my/express/${courseName}/${lessonId}`,
			name: 'Прохождение упражнения',
		}
	},
	MyFiles() {
		return {
			url: '/my/files',
			name: 'Файлы',
		}
	},
}

export const LeadsUrls = {
	Leads() {
		return {
			url: '/leads',
			name: i18next.t('url.leads.Leads'), // 'Лиды/покупки',
		}
	},
}

export const ProductUrls = {
	Products() {
		return {
			url: '/products',
			name: i18next.t('url.product.Products'), // 'Лента ответов',
		}
	},
}

export const MessageUrls = {
	Messages() {
		return {
			url: '/messages',
			name: i18next.t('url.message.Messages'), // 'Сообщения',
		}
	},
}

export const AnswerUrls = {
	AnswersFeed() {
		return {
			url: '/answers-feed/',
			name: i18next.t('url.answer.AnswersFeed'), // 'Лента ответов',
		}
	},
	AnswersFeedGroup(groupId: string | number) {
		return {
			url: '/answers-feed/' + groupId,
			name: i18next.t('url.answer.AnswersFeedGroup'), // 'Лента ответов',
		}
	},
}

export const ProfileUrls = {
	// Форма с редактированием учётной записи пользователя
	Profile() {
		return {
			url: '/profile',
			name: i18next.t('url.profile.Profile'), // 'Профиль',
		}
	},
}

export const CommentsUrls = {
	Comments() {
		return {
			url: '/comments/',
			name: i18next.t('url.comment.Comment'), // 'Комментарии',
		}
	},
}

export const AudioCourseUrls = {
	AudioCourse() {
		return {
			url: '/audiocourse',
			name: i18next.t('url.audioCourse.AudioCourse'), // 'Заговори на английском за 30 часов',
		}
	},
}

//Мои live-уроки
export const LiveLessonsUrls = {
	// Адрес страницы с входом на эфир вида
	// /livelessons/link/155fd853-3a69-46db-b7cd-65c5ef4cde79
	// сам эфир на странице вида
	// /livelessons/96
	LiveLessonEntrance(uniqueString = uuidv4()) {
		return {
			url: '/livelessons/link/' + uniqueString,
			name: 'Live-урок',
		}
	},
	// Комнаты с эфирами
	LiveRooms() {
		return {
			url: '/live',
			name: 'Live-уроки',
		}
	},
	// Страница live-урока
	LiveLesson(liveLessonId: string | number) {
		return {
			url: '/live/lessons/' + liveLessonId,
			name: 'Live-урок',
		}
	},
	// Уроки школы
	SchoolLessons: {
		url: '/live/school-lessons',
		name: 'Уроки школы',
	},
	// Урок школы
	SchoolLesson(lessonId: string | number) {
		return {
			url: '/live/school-lessons/' + lessonId,
			name: 'Урок школы',
		}
	},
}

export const SuperAdminUrls = {
	SuperAdminDashboard() {
		return {
			url: '/dashboard',
			name: 'Dashboard',
		}
	},
}

const AppUrls = {
	...AuthUrls,
	...MainUrls,
	...StudentUrls,
	...StaffUrls,
	...TrainingUrls,
	...MyUrls,
	...LeadsUrls,
	...ProductUrls,
	...MessageUrls,
	...AnswerUrls,
	...ProfileUrls,
	...AudioCourseUrls,
	...LiveLessonsUrls,
	...SuperAdminUrls,
	...CommentsUrls,
}

export default AppUrls
