import { useEffect } from 'react'
import { shallow } from 'zustand/shallow'
import {
	defaultMeetingRoomId,
	defaultTabs,
} from 'widgets/livelesson/liveLesson/defaultTabs'
import { LivelessonController } from 'widgets/livelesson/liveLesson/LivelessonController'
import useLivelessonStore from 'widgets/livelesson/liveLesson/zustand/store'

function LiveLesson() {
	const livelessonRouter = LivelessonController.router()
	const { addTab, removeTab, setCurrentTab, tabsList } = useLivelessonStore(
		(state) => ({
			addTab: state.addTab,
			removeTab: state.removeTab,
			setCurrentTab: state.setCurrentTab,
			tabsList: state.tabsList,
		}),
		shallow
	)

	useEffect(() => {
		// remove all tabs
		for (const tab of tabsList) {
			removeTab(tab.id)
		}
		// Create main tabs (meeting room, whiteboard, library)
		for (const tab of defaultTabs) {
			addTab(tab)
		}
		setCurrentTab(defaultMeetingRoomId)
	}, [])

	return (
		<>
			{livelessonRouter.status == 'hasLivelesson' &&
				livelessonRouter.element}
		</>
	)
}

export default LiveLesson
