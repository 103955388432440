import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { answerFeedQuery } from 'parts/requests/answerFeed/answerFeedQuery'
import EntityTypes from 'parts/types/EntityTypes'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import AnswerFeedTypes from 'parts/requests/answerFeed/answerFeedApiTypes'
import AppUrls from 'parts/constants/pageUrl'
import UrlService from 'parts/services/UrlService'
import { ANSWERS_LENGTH_IN_PAGE } from '../../common/answersFeedConsts'
import useAnswersFeedStore from '../store'

/**
 * Делает запросы на получение данных для списка ответов и ставит в Состояние.
 * Наполняет Хранилище данными как на странице полного списка ответов, так и для страницы ответов конкретной группы.
 */
export function useFillInStore() {
	// Получить все ответы учеников
	useFetchAllAnswersAndSetToStore()
	// Получить ответы учеников для указанной группы
	useFetchGroupAnswersAndSetToStore()

	// Получение курсов для администратора
	useFetchAdminTrainingsAndSetToStore()

	// Поставить успешный статус загрузки если все данные загрузились успешно
	useSetSuccessStatus()
}

/** Получает с сервера список всех ответов и ставит в Хранилище */
function useFetchAllAnswersAndSetToStore() {
	const location = useLocation()
	const updateStore = useAnswersFeedStore((state) => state.updateStore)

	const getAnswersParams = useGetAnswersParams()

	// Получить список ответов
	const { data: answersFeedRes } = answerFeedQuery
		.getAllAnswersFeed(getAnswersParams)
		.useQuery({
			// Делать запрос только если нахожусь на странице всех ответов
			enabled: location.pathname === AppUrls.AnswersFeed().url,
		})

	useEffect(
		function () {
			if (
				!answersFeedRes ||
				location.pathname !== AppUrls.AnswersFeed().url
			)
				return

			const preparedAnswersFeed = prepareAnswersFeed(
				answersFeedRes.data.items
			)

			updateStore({
				answersFeed: preparedAnswersFeed,
				totalAnswers: answersFeedRes.data.totalItems,
			})
		},
		[answersFeedRes, location.pathname]
	)
}

/** Получает с сервера список ответов конкретной группы и ставит в Хранилище */
function useFetchGroupAnswersAndSetToStore() {
	const groupId = UrlService.useGetGroupId()

	const updateStore = useAnswersFeedStore((state) => state.updateStore)

	const getAnswersParams = useGetAnswersParams()

	// Получить список ответов
	const { data: answersFeedRes } = answerFeedQuery
		.getGroupAnswersFeed(groupId, getAnswersParams)
		.useQuery({
			// Делать запрос только если в адресной строке указан идентификатор группы
			enabled: !!groupId,
		})

	useEffect(
		function () {
			if (!answersFeedRes) return

			const preparedAnswersFeed = prepareAnswersFeed(
				answersFeedRes.data.items
			)

			updateStore({
				answersFeed: preparedAnswersFeed,
				totalAnswers: answersFeedRes.data.totalItems,
			})
		},
		[answersFeedRes]
	)
}

/** Возвращает параметры запроса массива ответов */
export function useGetAnswersParams(): AnswerFeedTypes.GetAnswerFeedParams {
	const currentPageNum = useAnswersFeedStore((state) => state.currentPageNum)

	return useMemo(
		function () {
			return {
				offset: (currentPageNum - 1) * ANSWERS_LENGTH_IN_PAGE,
				limit: ANSWERS_LENGTH_IN_PAGE,
			}
		},
		[currentPageNum]
	)
}

function prepareAnswersFeed(rawAnswersFeed: EntityTypes.Answer[]) {
	return rawAnswersFeed
		.map((feed) => {
			feed.answerFeedItems = feed.answerFeedItems.sort((a, b) => {
				return a.id - b.id
			})

			return feed
		})
		.sort((a, b) => {
			return b.id - a.id
		})
}

/** Получает с сервера список курсов и ставит в Хранилище */
function useFetchAdminTrainingsAndSetToStore() {
	const updateStore = useAnswersFeedStore((state) => state.updateStore)
	const userRoles = useGetUserRole()

	// Получить список курсов для администратора если пользователь не ученик
	const { data: trainingsForAdminRes } =
		trainingQuery.getTrainingsForAdmin.useQuery({
			enabled: userRoles.notStudent,
		})

	useEffect(
		function () {
			if (!trainingsForAdminRes) return

			updateStore({
				trainingsAdmin: trainingsForAdminRes.data,
			})
		},
		[trainingsForAdminRes]
	)
}

/**
 * Следит за загрузкой списка ответов и курсов.
 * Если загрузить удалось, то ставит успешный статус загрузки.
 */
function useSetSuccessStatus() {
	const answersFeed = useAnswersFeedStore((state) => state.answersFeed)
	const updateStore = useAnswersFeedStore((state) => state.updateStore)
	const trainings = useAnswersFeedStore((state) => state.trainingsAdmin)
	const userRoles = useGetUserRole()

	useEffect(
		function () {
			if (!answersFeed) return
			if (userRoles.notStudent && !trainings) return

			updateStore({
				dataLoadingStatus: 'success',
			})
		},
		[answersFeed, trainings, userRoles]
	)
}
