import { createNewTextFormDataItem } from './check'

export type GapsExerciseFormStateType =
	| GapsInWordsExerciseFormStateType
	| ChooseWordExerciseFormStateType

// Тип данных формы на заполнение пропусков в словах
export type GapsInWordsExerciseFormStateType =
	ExerciseFormStateType<'gapsInWords'>

// Тип данных формы на выбор правильного варианта слова в пропусках
export type ChooseWordExerciseFormStateType =
	ExerciseFormStateType<'chooseWord'>

export type ExerciseType = 'gapsInWords' | 'chooseWord'

export type ExerciseFormStateType<T extends ExerciseType> = {
	exerciseId: null | number // id упражнения. Если это новое упражнение, то id не существует
	order: number
	type: T // Тип упражнения
	// Поле «Задание»
	taskInput: {
		value: string // Значение поля «Задание»
	}
	texts: TextStateItemType[] // Данные об указанных видео
	isFormInvalid: boolean // Является ли форма неверной
}

export type TextStateItemType = {
	id: number // Идентификатор блока с данными указанного видео
	text: string // Значение поля «Задать название видео»
	noTextError: boolean // Нужно ли показывать сообщение с ошибкой про отсутствие текста в редакторе
	wrongBracesPairError: boolean // Нужно ли показывать сообщение с ошибкой про неправильный порядок пар закрывающих и открывающих фигурных скобок
	wrongBracesTextError: boolean // Нужно ли показывать сообщение с ошибкой про неправильный формат текста в фигурных скобках
	// Сгенерированный текст, который нужно поместить в поле с текстом
	// Нельзя использовать text потому что в него пишется введённый текст.
	// А как только текст сгенерировался, то он попадает в generatedText,
	// затем пишется в редактор, а из редактора попадает в свойство text.
	generatedText: string
	// Текст задания на генерирование текста
	generatedTextPrompt: string
}

// Функция возвращает объект начального состояния формы упражнения
export function getFormInitialState(exerciseType: ExerciseType) {
	const state: ExerciseFormStateType<typeof exerciseType> = {
		exerciseId: null,
		order: 0,
		type: exerciseType,
		taskInput: {
			value: '',
		},
		texts: [createNewTextFormDataItem(1)],
		isFormInvalid: false,
	}

	return state
}
