import { useTranslation } from 'react-i18next'
import { EXERCISE_DONE } from '../../../common/exerciseConst'
import { ExerciseControlPanelButton } from '../../../common/ExerciseControlPanelContainer/ExerciseControlPanelContainer'
import { stateManager } from '../../stateManager/stateManager'
import useExerciseStore from '../../zustand/exerciseState'
import { useGetDoneExercise } from '../../../common/doneExercise'
import { useGetGoToNextExercise } from '../../../common/prevAndNextExerciseUrls'
import { useAfterDoneExercise } from './afterDoneExercise'

/** Возвращает массив с настройками кнопок для передачи в компонент ExerciseControlPanelContainer */
export function useGetButtonsConfig(): ExerciseControlPanelButton[] {
	const { t } = useTranslation()

	const doneCounter = useExerciseStore((store) => store.doneCounter)
	const isEveryStatementAnswered = stateManager.useIsEveryStatementAnswered()

	// Функция открывающая следующее упражнение если упражнение существует
	// или undefined если не существует.
	const goToNextExercise = useGetGoToNextExercise()

	// Кнопка перемещающая к новому упражнению
	function getNewExerciseBtn(
		text: 'next' | 'skip'
	): ExerciseControlPanelButton {
		const tMap = {
			next: t('exercises.controlBtnNextExercise'),
			skip: t('exercises.controlBtnSkipExercise'),
		}

		return {
			text: tMap[text],
			active: !!goToNextExercise,
			onClick: goToNextExercise,
			dataCyAttr: 'next-exercise-btn',
		}
	}

	// Функция, выполняемая при нажатии на кнопку «Выполнено».
	const doneExercise = useGetDoneExercise(
		useAfterDoneExercise(),
		useExerciseStore.getState().durationsInSeconds[
			useExerciseStore.getState().durationsInSeconds.length - 1
		]
	)

	// Кнопка отмечающая упражнение выполненным
	const doneBtnDisabled: ExerciseControlPanelButton = {
		text: t('exercises.controlBtnDone'),
		active: false,
		onClick: () => {},
		dataCyAttr: 'done-exercise-btn',
	}

	// Кнопка отмечающая упражнение выполненным
	const doneBtnEnabled: ExerciseControlPanelButton = {
		text: t('exercises.controlBtnDone'),
		active: true,
		primary: true,
		onClick: doneExercise,
		dataCyAttr: 'done-exercise-btn',
	}

	// Если упражнение не отметили выполненным трижды и ответили не на все вопросы
	if (doneCounter < EXERCISE_DONE && !isEveryStatementAnswered) {
		return [getNewExerciseBtn('skip'), doneBtnDisabled]
	}
	// Если упражнение не отметили выполненным трижды и ответили на все вопросы
	else if (doneCounter < EXERCISE_DONE && isEveryStatementAnswered) {
		return [getNewExerciseBtn('skip'), doneBtnEnabled]
	}
	// Если упражнение отметили выполненным трижды
	return [getNewExerciseBtn('next')]
}
