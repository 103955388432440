import Result from '../common/Result/Result'
import {
	LiftViewDuration,
	useLiftViewDuration,
} from '../common/useLiftViewDuration'
import Exercise from './Exercise'
import { useManageExerciseState } from './fn/main'
import { getExerciseResult, useGetOnPassAgainButtonClick } from './fn/result'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import './TestExerciseView.scss'

type TestExerciseViewProps = {
	exercise: TrainingEntityTypes.TestExercise
	liftViewDuration?: LiftViewDuration
}

// Вывод упражнения-теста в уроке
function TestExerciseView(props: TestExerciseViewProps) {
	const { exercise, liftViewDuration } = props

	const { state, setState } = useManageExerciseState(exercise)

	// Считает время прошедшее после открытия упражнения
	// и передаёт количество секунд в liftViewDuration
	const durationSec = useLiftViewDuration(liftViewDuration, state.showResult)

	const onPassAgainButtonClick = useGetOnPassAgainButtonClick(
		exercise,
		setState
	)
	const { passedNum, totalNum } = getExerciseResult(state)

	return (
		<>
			{state.showResult ? (
				<Result
					onPassAgainButtonClick={onPassAgainButtonClick}
					seconds={durationSec}
					passed={passedNum}
					total={totalNum}
				/>
			) : (
				<Exercise state={state} setState={setState} />
			)}
		</>
	)
}

export default TestExerciseView
