export namespace StudentsStoreType {
	export type State = {
		// Статус загрузки данных:
		// loading (данные загружаются)
		// error (при загрузке данных произошла ошибка)
		// success (данные загружены)
		dataLoadingStatus: 'loading' | 'error' | 'success'
		// Массив учеников школы.
		students: Student[]
		// Номер текущей страницы
		currentPageNum: number
		// Общее количество студентов
		totalStudents: number
		// Количество учеников на странице
		studentsPerPage: number
		// Поисковые значения
		searchValues: SearchValues

		// Метод изменяющий значение свойств
		updateStore: UpdateStore

		// Метод изменяющий значение свойств объекта searchValues
		setSearchValuesProp: SetSearchValuesProp
	}

	export enum SearchValueNames {
		FullName = 'fullName',
		Email = 'email',
		Phone = 'phone',
		Country = 'country',
		Status = 'status',
	}

	// Параметры поиска
	export type SearchValues = {
		[SearchValueNames.FullName]: string
		[SearchValueNames.Email]: string
		[SearchValueNames.Phone]: string
		[SearchValueNames.Country]: string
		[SearchValueNames.Status]: SearchValuesStatus
		isDeleted: false
	}

	export enum SearchValuesStatus {
		All = '',
		IsActivated = 'IsActivated',
		IsNotActivated = 'IsNotActivated',
	}

	export const SearchValuesStatuses = {
		[SearchValuesStatus.All]: {
			status: SearchValuesStatus.All,
			value: 'Все',
		},
		[SearchValuesStatus.IsActivated]: {
			status: SearchValuesStatus.IsActivated,
			value: 'Активирован',
		},
		[SearchValuesStatus.IsNotActivated]: {
			status: SearchValuesStatus.IsNotActivated,
			value: 'Не активирован',
		},
	}

	export type Student = {
		id: number
		avatar: string
		fullName: string
		email: string
		phone: string
		country: string
		isActivated: boolean
	}

	// Установщик новых значений корневого объекта через объект.
	export type UpdateStore = (obj: Partial<State>) => void

	export type SetSearchValuesProp = <K extends SearchValueNames>(
		groupProp: K,
		elemProp: SearchValues[K]
	) => void
}
