import { useEffect } from 'react'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import UrlService from 'parts/services/UrlService'
import { defaultLandingData } from 'pages/landing/landingConstructor/zustand/fillStore/defaultLandingData'
import { syncOneObjectOntoAnother } from 'parts/utils/objects/syncOneObjectOntoAnother'

/** Получает данные для построения конструктора и помещает их в Зустанд (для тарифов отдельная загрузка) */
export function useFillInStore() {
	useFetchLandingAndSetToStore()
	useFetchTrainingAndSetToStore()

	useSetSuccessStatus()
}

function useFetchLandingAndSetToStore() {
	const trainingId = UrlService.useGetTrainingId()

	const { data: landingRes } = trainingQuery
		.getDraftTrainingLanding(trainingId)
		.useQuery({
			onError: queryError,
		})

	useEffect(
		function () {
			if (!landingRes) return

			const landing = landingRes.data ? landingRes.data : {}

			// Если ранее сохранили неполный набор данных для построения одностраничника,
			// то это добавит недостающие свойства
			const preparedLanding = syncOneObjectOntoAnother(
				landing,
				defaultLandingData
			)

			const { updateStore } = useLandingConstructorStore.getState()

			updateStore({
				landing: preparedLanding,
			})
		},
		[landingRes]
	)
}

function useFetchTrainingAndSetToStore() {
	const trainingId = UrlService.useGetTrainingId()

	// Получить курс
	const { data: trainingRes } = trainingQuery
		.getTrainingForAdmin(trainingId)
		.useQuery({
			onError: queryError,
		})

	useEffect(
		function () {
			if (!trainingRes) return

			const { updateStore } = useLandingConstructorStore.getState()

			const training = trainingRes.data

			updateStore({
				training,
			})
		},
		[trainingRes]
	)
}

/**
 * Функция запускаемая при ошибке запроса данных. Срабатывает для любых запросов.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 * @param err — объект ошибки.
 */
function queryError(err: unknown) {
	const { updateStore } = useLandingConstructorStore.getState()

	updateStore({
		dataLoadingStatus: 'error',
	})
}

/** Следит за загрузкой всех данных и после ставит в Состояние успешный статус */
function useSetSuccessStatus() {
	const store = useLandingConstructorStore.getState()

	useEffect(
		function () {
			if (!store.training || !store.landing) {
				return
			}

			store.updateStore({ dataLoadingStatus: 'success' })
		},
		[store.training, store.landing]
	)
}
