import { useEffect, useRef, useState } from 'react'
import AppUrls from 'parts/constants/pageUrl'
import { useGoToPage } from 'parts/utils/hooks/hooks'
import { getRusNounByNumber } from 'parts/utils/string'

export function useGetRedirectToLoginPage() {
	const goToLoginPage = useGoToPage(AppUrls.Login().url)
	const [seconds, setSeconds] = useState(10)

	const intervalIdRef = useRef<null | NodeJS.Timer>(null)

	useEffect(function () {
		intervalIdRef.current = setInterval(() => {
			setSeconds((seconds) => seconds - 1)
		}, 1000)

		return function () {
			clearTimer(intervalIdRef.current)
		}
	}, [])

	useEffect(
		function () {
			if (seconds === 0) {
				clearTimer(intervalIdRef.current)
				goToLoginPage()
			}
		},
		[seconds]
	)

	return {
		description: getDescription(seconds),
	}
}

function clearTimer(timerId: null | NodeJS.Timer) {
	if (timerId) {
		clearInterval(timerId)
	}
}

function getDescription(seconds: number) {
	const secondsNoun = getRusNounByNumber(
		seconds,
		'секунду',
		'секунды',
		'секунд'
	)

	return `Нажмите кнопку «Войти» или через ${seconds} ${secondsNoun} вас перекинет на страницу авторизации`
}
