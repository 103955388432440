import UserApiTypes from 'parts/requests/user/userApiTypes'
import { dateStrToHumanDateStr, isDatePassed } from 'parts/utils/time'
import { CardStickerColors } from 'ui/wrappers/CardSticker/CardSticker'
import { getNumberAndNounStr, getRusNounByNumber } from 'parts/utils/string'
import LangService, { LangNames } from 'parts/i18n/LangService'

// Статус группы: notOpen (ещё не запущена), open (запущена), died (завершена)
type GroupLiveType = 'notOpen' | 'open' | 'finished'

export type CardDetailsType = {
	groupLiveStatus: GroupLiveType // Статус группы
	trainingName: string // Имя курса
	trainingId: number
	groupId: number
	groupName: string // Имя группы
	groupStart: string // Когда начинается группа
	groupEnd: string // Когда заканчивается группа
	progress: number // Прогресс выполнения курса
	unperformed: null | {
		lessons: string // Строка вида '3 уроках'
		exercises: string // Строка вида '12 упражнений'
	}
	nextLessonOpenDate: null | string
	allLessonsOpen: boolean
}

/**
 * Формирует объект с информацией для отрисовки карточки курса
 * @param {Object} visitData — данные для карточки группы курса из сервера
 */
export function getCardDetails(
	visitData: UserApiTypes.StudentTrainingsVisitStatsItem
): CardDetailsType {
	return {
		groupLiveStatus: getGroupStatus(visitData),
		trainingName: visitData.trainingName,
		trainingId: visitData.trainingId,
		groupId: visitData.groupId,
		groupName: visitData.groupName,
		groupStart: getGroupStartOrFinishStr(visitData.groupStart),
		groupEnd: getGroupStartOrFinishStr(visitData.groupEnd),
		progress: visitData.progress || 0,
		unperformed: getUnperformedStrings(visitData),
		nextLessonOpenDate: getNextLessonOpenDate(visitData),
		allLessonsOpen: visitData.allLessonsOpen,
	}
}

/**
 * Принимает строку с датой начала или конца группы и возвращает дату в человеческом формате.
 * Вместо даты может быть null или строка 'firstLessonOpening'.
 * @param groupStartOrFinish
 */
function getGroupStartOrFinishStr(groupStartOrFinish: string) {
	if (!groupStartOrFinish) {
		return 'Не известна'
	} else if (groupStartOrFinish === 'firstLessonOpening') {
		return 'Открытие первого урока'
	}

	return dateStrToHumanDateStr(groupStartOrFinish)
}

/**
 * Возвращает статус курса:
 * notOpen (ещё не открылся для прохождения)
 * open (открыт для прохождения)
 * finished (группа завершилась и прошёл срок доступа к курсу)
 * @param {Object} visitData — данные для карточки группы курса из сервера
 */
export function getGroupStatus(
	visitData: UserApiTypes.StudentTrainingsVisitStatsItem
): GroupLiveType {
	const { groupStart, groupEnd } = visitData

	// Если дата начала ещё не наступила, то курс не начался... Вернуть статус notOpen
	if (!isDatePassed(groupStart)) {
		return 'notOpen'
	}

	// Если дата конца наступила, то курс завершился, вернуть статус died
	// В противном случае open
	return isDatePassed(groupEnd) ? 'finished' : 'open'
}

/**
 * Возвращает объект со строками где сказано сколько уроков и упражнений не завершил ученик.
 * Вида: {lessons: '3 уроках', exercises: '6 упражнений'}.
 * @param {Object} visitData — данные для карточки группы курса из сервера
 */
function getUnperformedStrings(
	visitData: UserApiTypes.StudentTrainingsVisitStatsItem
) {
	if (!visitData.unperformed) {
		return null
	}

	const lang = LangService.getCurrentLangFromLocalStorage()

	const { lessons, exercises } = visitData.unperformed

	if (lang === LangNames.RU) {
		const lessonsNoun = getRusNounByNumber(
			lessons,
			'уроке',
			'уроках',
			'уроках'
		)
		const exercisesNoun = getRusNounByNumber(
			exercises,
			'упражнение',
			'упражнения',
			'упражнений'
		)

		return {
			lessons: lessons + ' ' + lessonsNoun,
			exercises: exercises + ' ' + exercisesNoun,
		}
	} else if (lang === LangNames.EN) {
		const lessonsNoun = getNumberAndNounStr(
			lessons,
			'No lessons',
			'lesson',
			'lessons'
		)
		const exercisesNoun = getRusNounByNumber(
			exercises,
			'No exercises',
			'exercise',
			'exercises'
		)

		return {
			lessons: lessons + ' ' + lessonsNoun,
			exercises: exercises + ' ' + exercisesNoun,
		}
	}

	return null
}

/**
 * Возвращает дату следующего урока
 * @param {Object} visitData — данные для карточки группы курса из сервера
 */
function getNextLessonOpenDate(
	visitData: UserApiTypes.StudentTrainingsVisitStatsItem
) {
	if (!visitData.nextLessonOpenDate) {
		return null
	}

	return dateStrToHumanDateStr(visitData.nextLessonOpenDate)
}

export function getStickerDetails(cardDetails: CardDetailsType): {
	text: string
	color: CardStickerColors
} {
	if (cardDetails.groupLiveStatus == 'notOpen') {
		return { text: 'Скоро начало', color: CardStickerColors.Purple }
	} else if (cardDetails.groupLiveStatus == 'open') {
		return { text: 'В процессе', color: CardStickerColors.Green }
	} else {
		return { text: 'Завершена', color: CardStickerColors.Orange }
	}
}
