import { ReactNode } from 'react'
import { Modal } from 'antd'

type ModalLayoutProps = {
	isModalVisible: boolean
	closeModalFn: Function
	title: string
	cancelBtn: boolean
	children: ReactNode
	footer: ReactNode
}

export function ModalLayout(props: ModalLayoutProps) {
	const { isModalVisible, closeModalFn, title, cancelBtn, children, footer } =
		props

	const handleCancel = () => {
		closeModalFn(false)
	}

	return (
		<Modal
			title={title}
			width={600}
			open={isModalVisible}
			onCancel={handleCancel}
			footer={footer}
			keyboard={true}
			mask={true}
			maskClosable={false}
			cancelButtonProps={{
				style: { display: cancelBtn ? 'block' : 'none' },
			}}
		>
			{children}
		</Modal>
	)
}
