import { create } from 'zustand'
import { StudentsStoreType } from './storeTypes'

const useStudentsStore = create<StudentsStoreType.State>((set) => {
	return {
		schoolHasStudents: false,
		students: null,

		searchValues: {
			fullName: '',
			trainingName: '',
			groupName: '',
			status: undefined,
			isDeleted: false,
		},

		/**
		 * Установщик новых значений корневого объекта.
		 * @param obj — объект со свойствами и значениями, которые должны быть изменены в Сотоянии
		 */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},

		/**
		 * Установщик новых значений объекта поиска по полям таблицы учеников.
		 * @param propKey — название свойства объекта searchValues
		 * @param propValue — значение свойства объекта searchValues
		 */
		setSearchValuesProp(propKey, propValue) {
			return set((state) => {
				const newSearchValues = { ...state.searchValues }
				newSearchValues[propKey] = propValue

				return { ...state, ...{ searchValues: newSearchValues } }
			})
		},
	}
})

export default useStudentsStore
