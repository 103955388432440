import TrainingEntityTypes from '../types/TrainingEntityTypes'
import TrainingStudentService from './TrainingStudentService'
import UrlService from './UrlService'

// Статус прохождения урока
export enum LessonStatus {
	// Урок ещё не открыт
	Unavailable = 'unavailable',
	// Урок открыт, но не выполнен полностью
	Process = 'process',
	// Урок открыт и все упражнения выполнены
	Finished = 'finished',
}

// Методы для работы с курсами
const LessonStudentService = {
	// Возвращает дату открытия ближайшего закрытого урока
	getNearestLessonOpenDateForStudent(
		training: TrainingEntityTypes.TrainingStudent
	): null | Date {
		const now = new Date()

		// Максимальная дата до которой ученик может просматривать курс
		const maxTrainingAccessDate =
			TrainingStudentService.getMaxStudentTrainingAccessDate(training)

		// Если начало курса зависит от открытия первого урока
		// или неизвестна максимальная дата до которой можно просмотривать курс
		// (это свидетельствует, что время начала зависит от открытия первого урока),
		// то ничего не возвращать
		if (
			training.startDate == 'firstLessonOpening' ||
			!maxTrainingAccessDate
		) {
			return null
		}

		// Если максимальное время до которого курс можно просматривать превышает текущую дату, то ничего не возвращать
		if (now > maxTrainingAccessDate) {
			return null
		}

		// Если группа ещё не запущена, то возвратить время начала группы
		if (now < new Date(training.startDate)) {
			return new Date(training.startDate)
		}

		// Перебрать уроки и возвратить время ближайшего неоткрытого урока
		for (let i = 0; i < training.lessons.length; i++) {
			const lesson = training.lessons[i]

			const lessonStartDate = new Date(lesson.startDate)

			if (lessonStartDate > now) {
				return lessonStartDate
			}
		}

		// Если все уроки открыты, то ничего не возвращать
		return null
	},

	/**
	 * Возвращает статус прохождения урока
	 * @param lesson — урок
	 */
	getLessonStatus(lesson: TrainingEntityTypes.LessonStudent): LessonStatus {
		if (lesson.performed == 100) {
			return LessonStatus.Finished
		}

		const lessonDate = new Date(lesson.startDate)
		if (lessonDate > new Date()) {
			return LessonStatus.Unavailable
		}

		return LessonStatus.Process
	},

	/**
	 * Находит в курсе урок по переданному идентификатору
	 * @param training — курс
	 * @param lessonId — id урока
	 */
	getLessonById(
		training: TrainingEntityTypes.TrainingStudent,
		lessonId: number
	) {
		const lesson = training.lessons.find((lesson) => lesson.id === lessonId)
		return lesson ? lesson : null
	},

	getLessonIdx(
		lessons: TrainingEntityTypes.LessonStudent[],
		lessonId: number
	) {
		return lessons.findIndex((lesson) => lesson.id === lessonId)
	},

	/**
	 * Является ли урок первым?
	 * @param {Object} training — объект курса
	 * @param {Number} lessonId — id урока
	 */
	isFirstLesson(
		training: TrainingEntityTypes.TrainingStudent,
		lessonId: number
	) {
		return training.lessons[0]?.id === lessonId
	},

	/**
	 * Получает данные курса и вычисляет урока по id из адресной строки.
	 * Возвращает данные урока
	 */
	useGetLesson() {
		const trainingQuery = TrainingStudentService.useGetTraining()
		const lessonId = UrlService.useGetLessonId()

		// Если данные загружаются, то вернуть undefined
		if (trainingQuery.isLoading) {
			return undefined
		}

		// Если курса нет или возникла ошибка при загрузке, то вернуть null
		if (!trainingQuery.data || trainingQuery.isError) return null

		return this.getLessonById(trainingQuery.data.data, lessonId)
	},

	/**
	 * Возвращает урок относительно урока с переданным идентификатором.
	 * Это требуется если нужно получить урок, который находится выше или ниже определённого.
	 * @param lessons — массив уроков.
	 * @param targetLessonId — id урока относительно которого будет искаться другой урок.
	 * @param relativePosition — позиция искомого урока относительно целевого. Например -1 для поиска предыдущего урока.
	 */
	getLessonInRelationToTargetLessonId(
		lessons: TrainingEntityTypes.LessonStudent[],
		targetLessonId: number,
		relativePosition: number
	) {
		const lessonIdx = this.getLessonIdx(lessons, targetLessonId)

		return lessons[lessonIdx + relativePosition]
	},
}

export default LessonStudentService
