import React, { useRef } from 'react'
import {
	ExerciseChangesDataMadeByUser,
	SaveExerciseChangesMadeByUser,
} from '../../common/useLiftViewDuration'
import useExerciseStore from '../zustand/exerciseState'
import { useCreateInnerHtml, useSetSelectChangeHandler } from './fn/exercise'
import {
	useGetInputContentHandler,
	useRestoreExercise,
	useSaveStateOnServerForRestore,
} from './fn/saveAndRestoreExercise'
import HtmlEditorContentView from 'ui/HTMLEditorContentView/HTMLEditorContentView'
import { TaskExerciseView } from '../../common/TaskExerciseView/TaskExerciseView'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

type ExerciseProps = {
	exercise: TrainingEntityTypes.ChooseWordExercise
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

function Exercise(props: ExerciseProps) {
	const { exercise, saveExerciseChangesMadeByUser, exerciseRestoreData } =
		props

	const textBlocks = useExerciseStore((store) => store.textBlocks)

	const textsRef = useRef<null | HTMLDivElement>(null)
	// Установка функции отслеживающей изменение выпадающих списков с выбором правильного слова
	useSetSelectChangeHandler(textsRef)

	// Занимается сохранением состояния упражнения для восстановления при открытии
	const inputContentHandler = useGetInputContentHandler(
		textsRef.current,
		saveExerciseChangesMadeByUser
	)
	useSaveStateOnServerForRestore(
		textsRef.current,
		saveExerciseChangesMadeByUser
	)

	return (
		<>
			{exercise.item.task && (
				<TaskExerciseView htmlString={exercise.item.task} />
			)}
			<div
				className="exercise-choose-word-content__text-blocks"
				ref={textsRef}
				onInput={inputContentHandler}
			>
				{textBlocks.map((textBlock) => {
					return (
						<TextBlock
							htmlString={textBlock.text}
							key={textBlock.id}
						/>
					)
				})}
			</div>
		</>
	)
}

export default Exercise

type TextBlockProps = {
	htmlString: string
}

// Блок текста с пропусками
function TextBlock(props: TextBlockProps) {
	const { htmlString } = props

	const areaRef = useRef(null)
	useCreateInnerHtml(areaRef, htmlString)

	return <HtmlEditorContentView areaRef={areaRef} />
}
