import React from 'react'
import { Button, Form, Input, Select, UploadFile } from 'antd'
import { useTranslation } from 'react-i18next'
import {
	UFormNewBlockHeader,
	UFormNewRow,
	UFormNewBottomButtonsWithBorder,
	UFormNewDivider,
	UFormNewGeneralError,
	UFormNewButtonsContainer,
} from 'ui/UForm/UForm'
import { FieldNames, setErrorsToForm, useGetInitialValues } from './fn/form'
import { useGetOnSubmit } from './fn/submit'
import useAccountStore from '../zustand/store'
import { UFormNewLRParts, UFormNewWhiteWrapper } from 'ui/UForm/UForm'
import UploadAvatar from 'ui/upload/UploadFileInForm/UploadAvatar'
import EntityTypes from 'parts/types/EntityTypes'
import useChangeEmailStore from '../../../modal/ChangeEmailModal/state/store'
import useEmployeeStore from '../../main/zustand/store'

const { Option } = Select

function AccountForm() {
	const { t } = useTranslation()

	const [form] = Form.useForm()
	const initialValues = useGetInitialValues()

	const onSubmit = useGetOnSubmit()
	setErrorsToForm(form)

	const formErrors = useAccountStore((state) => state.formErrors)

	return (
		<Form
			form={form}
			name="register"
			initialValues={initialValues}
			onFinish={onSubmit}
			layout="vertical"
		>
			<UFormNewWhiteWrapper>
				<UFormNewLRParts>
					<AvatarInput
						initialAvatarUrls={initialValues[FieldNames.Avatar]}
					/>
					<>
						<UFormNewBlockHeader
							text={t('employee.employeeFormHeader')}
						/>
						<RoleInput />
						<NameInput />
						<LastNameInput />
						<UFormNewDivider />
						<CountryInput />
						<CityInput />
						<PhoneInput />
						<UFormNewDivider />
						<ChangeEmailButton />
					</>
				</UFormNewLRParts>
				<Bottom />
				<UFormNewGeneralError message={formErrors.message} />
			</UFormNewWhiteWrapper>
		</Form>
	)
}

export default AccountForm

type AvatarInputProps = {
	initialAvatarUrls: UploadFile[]
}

// Обычное текстовое поле
function AvatarInput(props: AvatarInputProps) {
	const { initialAvatarUrls } = props

	return (
		<Form.Item name={FieldNames.Avatar}>
			<UploadAvatar
				inputName={FieldNames.Avatar}
				initialFileList={initialAvatarUrls}
				maxCount={1}
			/>
		</Form.Item>
	)
}

// Поле с выбором роли
function RoleInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.Role}
				label={t('employee.employeeFormRoleLabel')}
				rules={[
					{
						required: true,
						message: t('employee.employeeFormRoleInputRequired'),
					},
				]}
			>
				<Select
					showSearch
					placeholder={t('employee.employeeFormRoleInputNotSelect')}
					optionFilterProp="children"
				>
					<Option
						value={EntityTypes.UserRole.CURATOR}
						key={EntityTypes.UserRole.CURATOR}
					>
						{t('employee.employeeFormRoleInputCurator')}
					</Option>
					<Option
						value={EntityTypes.UserRole.MANAGER}
						key={EntityTypes.UserRole.MANAGER}
					>
						{t('employee.employeeFormRoleInputManager')}
					</Option>
				</Select>
			</Form.Item>
		</UFormNewRow>
	)
}

function NameInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.FirstName}
				label={t('employee.employeeFormNameLabel')}
			>
				<Input />
			</Form.Item>
		</UFormNewRow>
	)
}

function LastNameInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.LastName}
				label={t('employee.employeeFormLastNameLabel')}
			>
				<Input />
			</Form.Item>
		</UFormNewRow>
	)
}

function CountryInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Country}
				label={t('employee.employeeFormCountryLabel')}
			>
				<Input />
			</Form.Item>
		</UFormNewRow>
	)
}

function CityInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.City}
				label={t('employee.employeeFormCityLabel')}
			>
				<Input />
			</Form.Item>
		</UFormNewRow>
	)
}

function PhoneInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Phone}
				label={t('employee.employeeFormPhoneLabel')}
			>
				<Input />
			</Form.Item>
		</UFormNewRow>
	)
}

function ChangeEmailButton() {
	const { t } = useTranslation()

	const employee = useEmployeeStore((store) => store.employee)

	const openChangeEmailModal = useChangeEmailStore((state) => state.openModal)

	return (
		<UFormNewRow topOffset>
			<Button onClick={() => openChangeEmailModal(employee!.id)}>
				{t('employee.employeeFormChangeEmailBtn')}
			</Button>
		</UFormNewRow>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom() {
	const { t } = useTranslation()

	const form = Form.useFormInstance()
	const isSubmitting = useAccountStore((state) => state.isSubmitting)

	return (
		<UFormNewBottomButtonsWithBorder>
			<div />
			<UFormNewButtonsContainer>
				<Button type="text">
					{t('employee.employeeFormCancelBtn')}
				</Button>
				<Form.Item shouldUpdate>
					{() => {
						const formErrors = form
							.getFieldsError()
							.filter(({ errors }) => errors.length)

						return (
							<Button
								block
								type="primary"
								htmlType="submit"
								disabled={isSubmitting || formErrors.length > 0}
								loading={isSubmitting}
							>
								{t('employee.employeeFormOkBtn')}
							</Button>
						)
					}}
				</Form.Item>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsWithBorder>
	)
}
