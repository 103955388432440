import { SIDEPANEL_TABS } from '../../../defaultTabs'
import { SCREEN_MD } from 'parts/constants/breakpoints'

export function getDefaultCurrentSidepanelTab() {
	return window.innerWidth <= SCREEN_MD ? null : SIDEPANEL_TABS.PARTICIPANTS
}

export function getDefaultIsSidepanelHide() {
	return window.innerWidth <= SCREEN_MD ? true : false
}
