// import { useQueryClient } from 'react-query'
import { useGetStudentsTableRouter } from './fn/studentsTableRouter'
// import { userQuery } from 'parts/requests/user/userQuery'

export const StudentsTableCuratorController = {
	router: useGetStudentsTableRouter,
	// Функция, помечающая данные массива курсов неактуальными.
	// После этого React Query скачает новый массив курсов
	/*useGetUpdate: function () {
		const queryClient = useQueryClient()

		return function () {
			// Пометить список учеников неактуальным. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [userQuery.getUsers.key],
			})
		}
	},*/
}
