import { DownloadExerciseFileStatus } from '../../common/exerciseFormCommonFunc'

export type DescriptionImgExerciseFormStateType = {
	exerciseId: null | number // id упражнения. Если это новое упражнение, то id не существует
	type: 'descriptionImg'
	order: number
	// Поле «Описание задания»
	taskInput: {
		value: string // Значение поля «Задание»
	}
	matches: FormMatchStateItemType[] // Массив сопоставлений
	wrong: string[] // Массив неправильных слов
	isFormInvalid: boolean // Является ли форма неверной
}

export type FormMatchStateItemType = {
	id: number // Идентификатор блока с данными вопроса
	text: string // Текст вопроса
	noTextError: boolean // Флаг сообщающий, что нужно показывать текст ошибки про ненаписанное описание изображения
	noImageError: boolean // Флаг сообщающий, что нужно показывать текст ошибки про незагруженную картинку
	image: ImageFile
}

export type ImageFile = {
	link: string // Адрес загруженного файла
	status: DownloadExerciseFileStatus // Статус загрузки
	progress: number // Процент загрузки файла
	fileName: string // Название загружаемого файла
}

export function getFormMatchStateItem(matchId: number) {
	const formQuestionStateItem: FormMatchStateItemType = {
		id: matchId,
		text: '',
		noTextError: false,
		noImageError: false,
		image: {
			link: '',
			status: 'empty',
			progress: 0,
			fileName: '',
		},
	}

	return formQuestionStateItem
}

// Объект начального состояния формы видео-упражнения
export const testFormInitialState: DescriptionImgExerciseFormStateType = {
	exerciseId: null,
	type: 'descriptionImg',
	order: 0,
	taskInput: {
		value: '', // Значение поля «Описание задания»
	},
	matches: [getFormMatchStateItem(1)],
	wrong: [],
	isFormInvalid: false,
}
