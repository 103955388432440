import { produce } from 'immer'
import { LandingTypes } from 'parts/types/LandingTypes'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import timeBlocksManager from 'pages/landing/landingConstructor/sections/timeSection/TimeBlocksManager/timeBlocksManager'

/**
 * Добавляет новый блок в массив блоков
 * @param anchorBlockId — id блока, относительно которого будет добавляться новый блок.
 * Если null, тогда в массиве блоков пусто и новый будет добавлен первом элементом.
 * @param columnNum — номер колонки, куда будет добавлен новый блок.
 * @param position — позиция куда будет добавлен новый блок: сверху или снизу.
 */
export function useGetAddNewBlock(
	anchorBlockId: null | number,
	columnNum: 1 | 2,
	position: 'top' | 'bottom' = 'top'
) {
	const [landing, updateStore] = useLandingConstructorStore((s) => [
		s.landing,
		s.updateStore,
	])

	return function () {
		if (!landing) return

		const newBlock = createNewBlock(landing.sections.time.blocks, columnNum)

		const anchorBlockIdx = timeBlocksManager.getBlockIdx(
			landing.sections.time.blocks,
			anchorBlockId
		)

		const updatedLanding = produce(landing, (draft) => {
			if (position === 'top') {
				draft.sections.time.blocks.splice(anchorBlockIdx, 0, newBlock)
			} else {
				draft.sections.time.blocks.splice(
					anchorBlockIdx + 1,
					0,
					newBlock
				)
			}
		})

		updateStore({ landing: updatedLanding })
	}
}

/**
 * Создаёт объект нового блока с описанием чему научится человек после обучения.
 * @param blocks — массив блоков с описанием чему научится человек после обучения.
 * @param columnNum — номер колонки, куда будет добавлен новый блок.
 */
function createNewBlock(
	blocks: LandingTypes.TimeBlock[],
	columnNum: 1 | 2
): LandingTypes.TimeBlock {
	return {
		id: createNewBlockId(blocks),
		columnNum,
		text: '',
	}
}

/**
 * Возвращает id блока, который должен быть у нового блока
 * @param blocks — массив блоков с описанием чему научится человек после обучения.
 */
function createNewBlockId(blocks: LandingTypes.TimeBlock[]) {
	let newBlockId = 0

	for (let i = 0; i < blocks.length; i++) {
		const currentBlock = blocks[i]

		if (currentBlock.id > newBlockId) {
			newBlockId = currentBlock.id
		}
	}

	return newBlockId + 1
}
