import { create } from 'zustand'
import { GroupStaffStoreType } from './storeTypes'

const useGroupStaffStore = create<GroupStaffStoreType.State>((set) => ({
	schoolHasStaff: false,
	staff: null,

	updateState(obj) {
		return set((state) => {
			return obj
		})
	},
}))

export default useGroupStaffStore
