import { useEffect } from 'react'
import {
	formInitialState,
	FormStatementType,
	TrueOrFalseExerciseFormStateType,
} from './stateType'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { exercisesFormsManager } from '../../../exercisesFormsManager'
import useExercisesListAdminStore from '../../../zustand/store'

/** Хук устанавливающий данные формы в Хранилище */
export function useSetFormData() {
	const exercises = useExercisesListAdminStore((store) => store.exercises)
	const exerciseId = useExercisesListAdminStore((store) => store.exerciseId)

	const setDataToExerciseFormStore =
		exercisesFormsManager.useGetSetDataToExerciseFormStore()

	useEffect(
		function () {
			let formData: TrueOrFalseExerciseFormStateType = formInitialState

			const exercise = exercises.find(
				(exercise) => exercise.id === exerciseId
			)

			if (exercise) {
				formData = convertExerciseDataToFormStateData(
					exercise as TrainingEntityTypes.TrueOrFalseExercise
				)
			}

			setDataToExerciseFormStore(formData)
		},
		[exerciseId]
	)
}

/**
 * Функция переводящая объект упражнения в объект состояния формы редактирования упражнения
 * @param {Object} exercise — объект упражнения
 */
function convertExerciseDataToFormStateData(
	exercise: TrainingEntityTypes.TrueOrFalseExercise
): TrueOrFalseExerciseFormStateType {
	const formStateData: TrueOrFalseExerciseFormStateType = {
		exerciseId: exercise.id || null,
		order: exercise.order,
		taskInput: {
			value: exercise.item.task,
		},
		statements: [],
		isFormInvalid: false,
	}

	formStateData.statements = exercise.item.statements.map((statement, i) => {
		return getStatementObj(statement, i)
	})

	function getStatementObj(
		statementData: TrainingEntityTypes.TrueOrFalseStatement,
		i: number
	): FormStatementType {
		return {
			id: i + 1,
			text: statementData.text,
			correct: statementData.correct,
			image: {
				link: statementData.image || '',
				status: 'empty',
				progress: 0,
				fileName: '',
			},
			noTextError: false,
			generatedTextPrompt: statementData.generatedTextPrompt,
			generatedTextError: null,
		}
	}

	return formStateData
}
