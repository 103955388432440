import $api from '../http'
import EntityTypes from '../../types/EntityTypes'
import GroupsApiTypes from './groupsApiTypes'

// Запросы к методам получения групп
const groupRequests = {
	createOrUpdateGroup(dto: GroupsApiTypes.CreateOrUpdateGroupDto) {
		return $api.post<GroupsApiTypes.CreateOrUpdateGroup>('/api/groups', dto)
	},

	// Получение групп курса
	getAdminTrainingGroups(trainingId: number | string) {
		return $api.get<GroupsApiTypes.GetAdminTrainingGroups>(
			`/api/groups/by-training/${trainingId}`
		)
	},

	// Получение кураторов группы.
	getGroupCurators(groupId: number | string) {
		return $api.get<GroupsApiTypes.GetGroupCurators>(
			`/api/groups/${groupId}/curators`
		)
	},

	// Активация группы
	activateGroup(groupId: string | number) {
		return $api.post<GroupsApiTypes.ActivateGroup>(
			`/api/groups/activate/${groupId}`,
			{
				id: groupId,
			}
		)
	},

	// Удаление группы
	deleteGroup(groupId: number) {
		return $api.delete<GroupsApiTypes.DeleteGroup>(`/api/groups/${groupId}`)
	},

	// Восстановление группы
	restoreGroup(groupId: number) {
		return $api.post<GroupsApiTypes.RestoreGroup>(
			`/api/groups/restore/${groupId}`,
			{ id: groupId }
		)
	},

	// Удаление тарифа
	deleteTariff(tariffId: number) {
		return $api.delete<GroupsApiTypes.DeleteTariff>(
			`/api/groups/tariff/${tariffId}`
		)
	},

	// Получение групп, в которых состоит пользователь
	getUserGroups(userId: number | string) {
		return $api.get<GroupsApiTypes.GetUserGroups>(
			'/api/groups/by-user/' + userId
		)
	},

	// Создание или редактирование тарифов группы
	createOrEditTariffs(data: {
		groupId: string | number
		tariffs: EntityTypes.Tariff[]
	}) {
		return $api.post<GroupsApiTypes.CreateOrEditTariffs>(
			`/api/groups/${data.groupId}/tariffs`,
			data.tariffs
		)
	},

	// Получение тарифов группы
	getTariffs(groupId: string | number) {
		return $api.get<GroupsApiTypes.GetTariffs>(
			`/api/groups/${groupId}/tariffs`
		)
	},

	// Добавление пользователя в группу
	addUserToGroup(data: GroupsApiTypes.AddUserToGroupDto) {
		return $api.post<GroupsApiTypes.AddUserToGroup>(
			'/api/groups/add-user',
			data
		)
	},

	// Удаление пользователя из группы
	removeUserFromGroup(data: GroupsApiTypes.RemoveUserFromGroupDto) {
		return $api.post<GroupsApiTypes.RemoveUserFromGroup>(
			'/api/groups/remove-user',
			data
		)
	},

	// Перемещение учеников между кураторами
	moveStudentsBetweenCurators(
		data: GroupsApiTypes.MoveStudentsBetweenCuratorsDto
	) {
		return $api.post('/api/groups/curator/move-students', data)
	},

	getGroupById(groupId: number | string) {
		return $api.get<GroupsApiTypes.GetGroup>(`/api/groups/${groupId}`)
	},
}

export default groupRequests
