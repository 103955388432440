import { create } from 'zustand'
import { MakeOrderStoreType } from './storeTypes'

const useMakeOrderStore = create<MakeOrderStoreType.State>((set) => {
	return {
		isOpen: false,
		isSubmitting: false,
		formErrors: {},

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useMakeOrderStore
