import SectionHeader from 'pages/landing/landingConstructor/details/SectionHeader/SectionHeader'
import PortraitBlocks from 'pages/landing/landingConstructor/sections/portraitSection/PortraitBlocks/PortraitBlocks'
import AddNewBlockSection from 'pages/landing/landingConstructor/sections/portraitSection/AddNewBlockSection/AddNewBlockSection'
import SectionWrapper from 'pages/landing/landingConstructor/details/SectionWrapper/SectionWrapper'
import 'pages/landing/landingConstructor/sections/portraitSection/PortraitSection/PortraitSection.scss'
import TakeAPart from 'pages/landing/landingConstructor/details/TakeAPart/TakeAPart'

// Секция одностраничника с рассказом для кого предназначается курс
function PortraitSection() {
	return (
		<SectionWrapper>
			<SectionHeader
				imagePropsPathArr={[
					'sections',
					'portrait',
					'header',
					'imageUrl',
				]}
				headerPropsPathArr={['sections', 'portrait', 'header', 'text']}
			/>
			<div>
				<div className="land-const-portrait-section">
					<PortraitBlocks />
					<AddNewBlockSection />
				</div>
				<TakeAPart sectionName="portrait" />
			</div>
		</SectionWrapper>
	)
}

export default PortraitSection
