import { message } from 'antd'
import { schoolQuery } from 'parts/requests/school/schoolQuery'
import useDeleteModalStore from '../../../../modal/DeleteModal/store/store'
import useStaffStore from '../../zustand/store'

/** Удаление пользователя */
export function useGetDeleteUser(userId: number) {
	const updateModalStore = useDeleteModalStore((state) => state.updateStore)

	const deleteUserMutation = schoolQuery.deleteUser.useMutation({
		onError: onError,
		onSuccess: () => mutateOnSuccess(userId),
	})

	return function () {
		updateModalStore({
			isOpen: true,
			header: 'Вы действительно хотите удалить ученика?',
			text: 'Это действие нельзя отменить.',
			onOk: () => {
				deleteUserMutation.mutate(userId)
			},
		})
	}
}

function mutateOnSuccess(userId: number) {
	const { updateStore, staff } = useStaffStore.getState()

	const updatedStaff = staff.filter((employee) => {
		return employee.id !== userId
	})

	updateStore({
		staff: updatedStaff,
	})

	message.success('Сотрудник удалён')
}

function onError() {
	message.error('Не удалось удалить сотрудника')
}
