import { trainingQuery } from 'parts/requests/training/trainingQuery'
import { message } from 'antd'
import { useGoToPage } from 'parts/utils/hooks/hooks'
import AppUrls from 'parts/constants/pageUrl'
import { handleCopyToClipboard } from 'parts/utils/os'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import UrlService from 'parts/services/UrlService'
import { AddGroupModalController } from 'widgets/groups/addGroupModal/AddGroupModalController'

/** Возвращает функцию, переадресовывающую на страницу курса */
export function useGetGoToTrainingPage() {
	const trainingId = UrlService.useGetTrainingId()

	return useGoToPage(AppUrls.Training(trainingId).url)
}

/** Возвращает функцию, вставляющую в буфер обмена адрес опубликованного одностраничника */
export function useGetCopyLandingPageUrl() {
	const training = useLandingConstructorStore((store) => store.training)!

	return function () {
		handleCopyToClipboard(
			training.landingUrl,
			'Ссылка на одностраничник скопирована'
		)
	}
}

/** Возвращает адрес страницы для предпросмотра одностраничника */
export function useGetPreviewPageUrl() {
	const trainingId = UrlService.useGetTrainingId()

	return AppUrls.Training_LandingConstructorPreview(trainingId).url
}

/** Возвращает обработчику щелчка по ссылке на страницу предпросмотра одностраничника */
export function useGetGoToPreviewPageUrl() {
	// Возвращает функцию, открывающую модальное окно добавления новой активной группы,
	// если в курс она не добавлена.
	const openAddGroupModal = useGetOpenAddGroupModalIfNecessary()

	return function (e: any) {
		// Если нужно добавить активную группу, то вместо перехода на страницу открывает модальное окно созданий активной группы
		if (openAddGroupModal) {
			e.preventDefault()

			openAddGroupModal()
			return
		}
	}
}

/** Возвращает функцию, делающую последние сохранённые данные одностраничника опубликованными */
export function useGetPublishLanding() {
	const trainingId = UrlService.useGetTrainingId()
	const publishTrainingLanding =
		trainingQuery.publishTrainingLanding.useMutation({
			onSuccess: () => {
				message.success('Опубликовано.')
			},
		})

	// Возвращает функцию, открывающую модальное окно добавления новой активной группы,
	// если в курс она не добавлена.
	const openAddGroupModal = useGetOpenAddGroupModalIfNecessary()

	return function () {
		// Если нужно добавить активную группу, то вместо перехода на страницу открывает модальное окно созданий активной группы.
		// Одностраничник не будет опубликован без активной группы
		if (openAddGroupModal) {
			openAddGroupModal()
			return
		}

		publishTrainingLanding.mutate(trainingId)
	}
}

function useGetOpenAddGroupModalIfNecessary() {
	const training = useLandingConstructorStore((state) => state.training)

	if (!training) {
		return undefined
	}

	const hasActiveGroup = training.groups.find((group) => group.isActive)

	return hasActiveGroup ? undefined : AddGroupModalController.useGetOpen
}
