import ModalWithForm from 'ui/ModalWithForm/ModalWithForm'
import AddEmployeeForm from '../AddEmployeeForm/AddEmployeeForm'
import { AddEmployeeModalController } from '../AddEmployeeModalController'
import useAddEmployeeModalStore from './zustand/store'

// Модальное окно добавления нового ученика
function AddEmployeeModal() {
	const modalState = useAddEmployeeModalStore((state) => state)

	return (
		<ModalWithForm
			title="Добавление сотрудника"
			open={modalState.isOpen}
			onCancel={AddEmployeeModalController.close}
		>
			<AddEmployeeForm />
		</ModalWithForm>
	)
}

export default AddEmployeeModal
