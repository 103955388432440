import React from 'react'
import MainImage from 'pages/landing/landingConstructor/sections/timeSection/MainImage/MainImage'
import TimeBlocks from 'pages/landing/landingConstructor/sections/timeSection/TimeBlocks/TimeBlocks'
import AddNewBlockSection from 'pages/landing/landingConstructor/sections/timeSection/AddNewBlockSection/AddNewBlockSection'
import 'pages/landing/landingConstructor/sections/timeSection/SectionContent/SectionContent.scss'

function TimeContent() {
	return (
		<div className="land-const-time-section-content">
			<div className="land-const-time-section-content__content">
				<TimeBlocks />
				<AddNewBlockSection />
			</div>
			<MainImage />
		</div>
	)
}

export default TimeContent
