import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import { useGetCreateTariff } from 'pages/landing/landingConstructor/tariffs/content/AddTariffButton/fn/createTariff'
import CreateNewCard from 'ui/CreateNewCard/CreateNewCard'

// Кнопка добавления нового тарифа
function AddTariffButton() {
	const tariffs = tariffsManager.store.tariffs
	const createTariff = useGetCreateTariff()

	return (
		<CreateNewCard
			onClick={createTariff}
			imgSrc={!tariffs.length ? '/images/illustrations/1.svg' : ''}
			text="Добавить тариф"
			disabled={tariffs.length > 2}
		/>
	)
}

export default AddTariffButton
