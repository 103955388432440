import MainSection from '../../sections/MainSection/MainSection'
import Footer from '../../details/Footer/Footer'
import FaqSection from '../../sections/faqSection/FaqSection/FaqSection'
import ProgramSection from '../../sections/programSection/ProgramSection/ProgramSection'
import VideoSection from '../../sections/VideoSection/VideoSection'
import PortraitSection from '../../sections/portraitSection/PortraitSection/PortraitSection'
import TimeSection from '../../sections/timeSection/TimeSection/TimeSection'
import WaysSection from '../../sections/waysSections/WaysSection/WaysSection'
import ExistenceSection from '../../sections/existenceSection/ExistenceSection/ExistenceSection'
import TariffsSection from '../../sections/programSection/TariffsSection/TariffsSection'
import './LandingBuilder.scss'

function LandingBuilder() {
	return (
		<div className="landing">
			<MainSection />
			<div className="landing__sections">
				<VideoSection />
				<PortraitSection />
				<ExistenceSection />
				<TimeSection />
				<ProgramSection />
				<TariffsSection />
				<WaysSection />
				<FaqSection />
			</div>
			<Footer />
		</div>
	)
}

export default LandingBuilder
