import React, { useCallback, useEffect, useState } from 'react'
import { FormInstance } from 'antd'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import ServerTypes from 'parts/types/ServerTypes'
import AppUrls from 'parts/constants/pageUrl'
import { setErrorsToFields } from 'parts/utils/form'

export type FormValues = {
	code: string
}

/**
 * Функция возвращающая данные для работы формы
 * @param {Object} form — объект формы
 */
export function useManageForm(form: FormInstance) {
	// Идёт ли загрузка данных
	const [isLoading, setIsLoading] = useState(false)
	// В случае ошибки сюда попадут данные ошибки
	const [formErrors, setFormErrors] = useState<ServerTypes.ErrorResponse>({})

	// Обработчик отправки формы
	const onSubmit = useGetOnSubmit(setIsLoading, setFormErrors)

	// В случае появления ошибок показать их в форме
	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])

	return {
		isLoading,
		onSubmit,
		formErrors,
	}
}

/**
 * Хук возвращающий обработчик отправки формы
 * @param {Function} setIsLoading — функция устанавливающая статус ожидания ответа от сервера.
 * @param {Function} setFormErrors — функция устанавливающая объект ошибок в Состояние ошибок
 */
function useGetOnSubmit(
	setIsLoading: (isLoading: boolean) => void,
	setFormErrors: React.Dispatch<React.SetStateAction<{}>>
) {
	const navigate = useNavigate()

	return useCallback(async (values: FormValues) => {
		try {
			// Отправить на страницу выбора школы
			navigate(AppUrls.RecoverySet(values.code).url)
		} catch (err) {
			const error = err as AxiosError<ServerTypes.ErrorResponse>

			if (error.response) {
				// Поставить данные ошибки в Состояние, чтобы показать их в форме
				setFormErrors(error.response.data)
			}
		}
	}, [])
}
