import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import { AxiosError } from 'axios'
import lessonsRequests from 'parts/requests/lessons/lessonsRequests'
import ServerTypes from 'parts/types/ServerTypes'
import useLessonsPresetsStore from '../../zustand/store'

export function reset() {
	const { updateStore } = useLessonsPresetsStore.getState()
	updateStore({ selectedPreset: null, isOpenDeletePresetConfirmation: false })
}

export function useGetCloseModal() {
	return useCallback(reset, [])
}

export function useDeletePreset() {
	const { t } = useTranslation()

	const [presetId, presets, updateStore] = useLessonsPresetsStore((state) => [
		state.selectedPreset,
		state.presets,
		state.updateStore,
	])

	return async function () {
		reset()

		try {
			if (!presetId) return

			const response = await lessonsRequests.deletePreset(presetId)

			if (response.data) {
				// Закрыть модальное окно
				updateStore({
					presets: presets?.filter(
						(preset) => preset.id !== presetId
					),
				})

				// Показать уведомление об удалении
				message.success(t('adminTraining.deletePresetModalError'))
			}
		} catch (err) {
			const error = err as AxiosError<ServerTypes.ErrorResponse>

			if (error.response) {
				message.error(t('adminTraining.deletePresetModalError'))
			}
		}
	}
}
