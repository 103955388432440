import { SyntheticEvent } from 'react'
import { useQueryClient } from 'react-query'
import exercisesRequests from 'parts/requests/exercises/exercisesRequests'
import { lessonsQuery } from 'parts/requests/lessons/lessonsQuery'
import ExerciseService from 'parts/services/ExerciseService'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import useExercisesListAdminStore from '../../zustand/store'

/**
 * Хук отдаёт функцию изменяющую порядок упражнения
 * @param {Number} exerciseId — id упражнения, у которого нужно изменить порядок
 * @param {String} direction — направление перемещения упражнения
 */
export function useGetMoveExerciseFn(
	exerciseId: number,
	direction: 'up' | 'down'
) {
	const queryClient = useQueryClient()

	const exercises = useExercisesListAdminStore((store) => store.exercises)
	const lessonId = useExercisesListAdminStore((store) => store.lessonId)
	const updateStore = useExercisesListAdminStore((store) => store.updateStore)

	return function (event: SyntheticEvent) {
		event.preventDefault()

		const updatedExercises = ExerciseService.moveExerciseUpOrDown(
			exercises,
			exerciseId,
			direction
		)

		synchronizeExercisesOrder(exercises, updatedExercises)

		updateStore({ exercises: updatedExercises })

		setTimeout(() => {
			// Пометить данные курса неактуальными. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [lessonsQuery.getLesson(lessonId).key],
			})
		}, 1000)
	}
}
function synchronizeExercisesOrder(
	oldExercises: TrainingEntityTypes.ExerciseItem[],
	updatedExercises: TrainingEntityTypes.ExerciseItem[]
) {
	oldExercises.forEach((oldExercise) => {
		const updatedExercise = ExerciseService.getExerciseInExercises(
			updatedExercises,
			oldExercise.id!
		)
		if (!updatedExercise) return

		if (oldExercise.order === updatedExercise.order) {
			return
		}

		exercisesRequests.updateExercise({
			id: updatedExercise.id,
			order: updatedExercise.order,
		})
	})
}

/**
 * Хук отдаёт булево значение должна ли кнопка перемещающая вперёд и назад упражнение быть неактивной.
 * @param exerciseId
 * @param {String} direction — направление перемещения
 */
export function useIsMoveExerciseBtnDisabled(
	exerciseId: number,
	direction: 'up' | 'down'
) {
	const exercises = useExercisesListAdminStore((store) => store.exercises)

	return !ExerciseService.isExerciseMovingAllowed(
		exercises,
		exerciseId,
		direction
	)
}
