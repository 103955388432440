import React, { FC } from 'react'
import { Card, Col, Row, Typography, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useGetDeletePreset } from 'widgets/presets/presetsListDrawer/Preset/fn/actions'
import './Preset.scss'

interface IPresetProps {
	description: null | string
	name: string
	icon: JSX.Element
	onPresetClick: () => void
	onPresetDelete: () => void
}

const Preset: FC<IPresetProps> = ({
	description,
	name,
	icon,
	onPresetClick,
	onPresetDelete,
}) => {
	const { t } = useTranslation()
	const deletePreset = useGetDeletePreset(onPresetDelete)

	return (
		<Card hoverable className="drawer-preset" onClick={onPresetClick}>
			<Row align="middle">
				<Col className="drawer-preset__icon">{icon}</Col>
				<Col className="drawer-preset__content">
					<Row justify="space-between">
						<Col>
							<Typography className="drawer-preset__name">
								{name}
							</Typography>
							<Typography className="drawer-preset__description">
								{description}
							</Typography>
						</Col>
						<Col className="drawer-preset__delete-wrapper">
							<Button
								type="link"
								className="drawer-preset__delete"
								onClick={deletePreset}
							>
								{t('adminTraining.presetCardDeleteButton')}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Card>
	)
}

export default Preset
