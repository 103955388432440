import { Form } from 'antd'
import { produce } from 'immer'
// import useTariffsConstructorStore from 'pages/landingConstructor/tariffsSection/zustand/store'
import { FieldsNames } from 'pages/landing/landingConstructor/tariffs/panel/payment/MainPaymentForm/fn/form'
import {
	paySystemAndCurrencyAdaptation,
	updateFormAndDataDependOnChangedField,
} from 'pages/landing/landingConstructor/tariffs/panel/common'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'

/**
 * Возвращает обработчик выбора значения из выпадающего списка формы изменения данных оплаты.
 * @param fieldName — имя поля
 * @param priceId — id объекта дополнительной цены
 */
export function useGetOnSelectChange(fieldName: FieldsNames, priceId: number) {
	const form = Form.useFormInstance()

	return function (newValue: string) {
		const { updateStore, activeTariffId, tariffs, stripeSetupDone } =
			tariffsManager.store

		const newTariffs = produce(tariffs, (draft) => {
			if (!draft || !activeTariffId) return

			const additionalPrice =
				tariffsManager.getAdditionalPriceInActiveTariff(priceId)
			if (!additionalPrice) return

			// Автоматически корректировать выбор платёжной системы или валюты на правильный
			const wasChanges = paySystemAndCurrencyAdaptation(
				additionalPrice,
				form,
				fieldName,
				newValue,
				stripeSetupDone
			)

			if (wasChanges) return

			// Если сценарий выше не поставил новые значения в форму и в данные, то поставить на этом шаге
			updateFormAndDataDependOnChangedField(
				form,
				additionalPrice,
				fieldName,
				newValue
			)

			// ЕСЛИ В ФОРМЕ ПОЯВЯТСЯ ДРУГИЕ ПОЛЯ КРОМЕ ПЛАТЁЖНОЙ СИСТЕМЫ И ВАЛЮТЫ,
			// ТО ПРИДЁТСЯ ПИСАТЬ ЛОГИКУ ПО СОХРАНЕНИЮ ИХ ЗНАЧЕНИЙ В ДАННЫХ
		})

		updateStore({ tariffs: newTariffs })

		// Сохранить на сервере при изменении выпадающего списка
		tariffsManager.setNeedToSave()
	}
}
