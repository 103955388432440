import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import usePagesScrollStore from './zustand/store'
import { PagesScrollStoreType } from './zustand/storeTypes'

/** Хук наблюдает за переходом на новую страницу и ставит элементам прокрутку если в Хранилище есть данные о прокрутке об этой странице */
export function useSetScrollAfterEnterToPage() {
	let { pathname } = useLocation()

	useEffect(
		function () {
			const { pagesScroll } = usePagesScrollStore.getState()

			// Задержка перед прокруткой чтобы отрисовались прокручиваемые элементы
			setTimeout(() => {
				getElemAndSetScroll(pagesScroll, pathname)
			}, 500)
		},
		[pathname]
	)
}

/**
 * Ищет в объекте Хранилища данные о прокрутке элемента и если имеются, то находит этот элемент и ставит прокрутку
 * @param pagesScrollStore — данные о прокрученных элементах на страницах
 * @param pathname — относительный адрес страницы
 */
function getElemAndSetScroll(
	pagesScrollStore: PagesScrollStoreType.PagesScroll,
	pathname: string
) {
	// Найти данные о прокручиваемом элементе на странице
	const pageScroll = pagesScrollStore[pathname]
	if (!pageScroll) return

	// Найти прокручиваемый элемент
	const $scrolledElems = document.getElementsByClassName(pageScroll.classes)
	if (!$scrolledElems || !$scrolledElems[0]) return

	// Поставить прокрутку
	$scrolledElems[0].scrollTop = pageScroll.top
	$scrolledElems[0].scrollLeft = pageScroll.left
}
