import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import AppUrls from 'parts/constants/pageUrl'
import Footer from 'ui/Footer/Footer'
import { useGetUserRole } from 'parts/utils/hooks/hooks'

/** Страница моих курсов и моих систем оплат */
function MyPageManagerCuratorStudent() {
	const { t } = useTranslation()
	const userRoles = useGetUserRole()

	return (
		<PageSkeleton>
			<PageHeader
				breadcrumbs={[]}
				header="Мой кабинет"
				tabs={[
					{
						label: AppUrls.MySchools().name,
						address: AppUrls.MySchools().url,
					},
				]}
			/>
			<Outlet />
			<Footer isRightSideHidden={!userRoles.isAdminOrManager} />
		</PageSkeleton>
	)
}

export default MyPageManagerCuratorStudent
