import { useTranslation } from 'react-i18next'
import AuthPage from '../../../../common/AuthPage/AuthPage'
import ContentWrapper from '../../../../common/ContentWrapper/ContentWrapper'
import FinishPageContent from '../FinishPageContent/FinishPageContent'
import { useGetRedirectToLoginPage } from './fn/redirectToLoginPage'

// Страница с формой ввода нового пароля взамен забытого
function RecoveryFinishPage() {
	const { t } = useTranslation()
	const { description } = useGetRedirectToLoginPage()

	return (
		<AuthPage>
			<ContentWrapper
				header={t('auth.finishRecoverPasswordPageHeader')}
				headerDesc={description}
			>
				<FinishPageContent />
			</ContentWrapper>
		</AuthPage>
	)
}

export default RecoveryFinishPage
