import React from 'react'
import PaymentTypes from 'parts/types/PaymentTypes'
import { useTranslation } from 'react-i18next'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import {
	CardSticker,
	CardStickerColors,
} from 'ui/wrappers/CardSticker/CardSticker'
import RestrictionsService from '../../../../../parts/services/RestrictionsService'
import { PaymentCard } from '../PaymentCard/PaymentCard'
import { paymentSystemsData } from '../paymentSystems'
import { getCardConnectionType, useGetPaymentsData } from './fn/getPaymentsData'
import './MyPayments.scss'

// Список всех карточек для подключения платёжных систем
function MyPayments() {
	const { t } = useTranslation()
	const paymentsData = useGetPaymentsData()

	const canIntegrateWithPaySystems =
		RestrictionsService.useCanIntegrateWithPaySystems()

	if (!canIntegrateWithPaySystems) {
		return null
	}

	return (
		<ArticleContentWrapper innerWrapperClass="my-payments">
			{Object.entries(paymentSystemsData).map((paymentSystem) => {
				const [name, data] = paymentSystem

				const connectionType = getCardConnectionType(
					paymentsData,
					name as PaymentTypes.System.UKassa
				)

				if (connectionType == 'idle') {
					return (
						<PaymentCard
							connectionType={connectionType}
							paymentSystemName={name}
							data={data}
							key={name}
						/>
					)
				} else {
					return (
						<CardSticker
							text={
								connectionType == 'on'
									? t(
											'myPayments.paymentsListConnectionStatusOn'
									  )
									: t(
											'myPayments.paymentsListConnectionStatusOff'
									  )
							}
							color={
								connectionType == 'on'
									? CardStickerColors.Green
									: CardStickerColors.Orange
							}
							key={name}
						>
							<PaymentCard
								connectionType={connectionType}
								paymentSystemName={name}
								data={data}
							/>
						</CardSticker>
					)
				}
			})}
		</ArticleContentWrapper>
	)
}

export default MyPayments
