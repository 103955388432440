import { useEffect } from 'react'
import { schoolQuery } from 'parts/requests/school/schoolQuery'
import SchoolApiTypes from 'parts/requests/school/schoolApiTypes'
import { MySchoolsListStoreType } from '../zustand/storeTypes'
import useMakeOrderStore from '../zustand/store'

/**
 * Делает запрос на получение списка школ и текущей школы, формирует данные для Состояния и ставит в него
 * @param userId — id текущего пользователя
 */
export function useFillInStore(userId: number) {
	const updateStore = useMakeOrderStore((state) => state.updateStore)

	const schoolsQuery = schoolQuery.getSchoolsByUser.useQuery(userId)
	const currentSchoolQuery = schoolQuery.getCurrentSchool.useQuery()

	useEffect(
		function () {
			if (!schoolsQuery.data || !currentSchoolQuery.data) return

			const storeSchools = prepareServSchoolsToStore(
				schoolsQuery.data.data,
				currentSchoolQuery.data.data
			)
			updateStore({ schools: storeSchools })
		},
		[
			schoolsQuery.status,
			schoolsQuery.data,
			currentSchoolQuery.status,
			currentSchoolQuery.data,
		]
	)
}

/**
 * Принимает с сервера массив школ и готовит данные для отрисовки карточек школ
 * @param servSchools — массив школ с сервера
 * @param currentSchool — текущая школа
 */
function prepareServSchoolsToStore(
	servSchools: SchoolApiTypes.GetSchoolsByUser,
	currentSchool: SchoolApiTypes.School
): MySchoolsListStoreType.School[] {
	return servSchools.map((school) => {
		return {
			id: school.id,
			domain: school.domain,
			name: school.name,
			cover: school.cover,
			current: school.id == currentSchool.id,
		}
	})
}
