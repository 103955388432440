import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import { TrainingGroupsTableController } from 'widgets/groups/trainingGroupsTable/AddGroupModalController'
import NoGroupsScreen from 'widgets/groups/trainingGroupsTable/GroupsTable/NoGroups'
import UrlService from 'parts/services/UrlService'
import { AddGroupModalController } from 'widgets/groups/addGroupModal/AddGroupModalController'

// Страница списка групп курса
function GroupsArticle() {
	const trainingId = UrlService.useGetTrainingId()

	const groupsTableRouter = TrainingGroupsTableController.router(trainingId)

	const inCenter =
		groupsTableRouter.status === 'loading' ||
		groupsTableRouter.status == 'noGroups'

	return (
		<ArticleContentWrapper center={inCenter}>
			{groupsTableRouter.status == 'loading' && groupsTableRouter.element}
			{groupsTableRouter.status == 'noGroups' && <NoGroupsScreen />}
			{groupsTableRouter.status == 'hasGroups' &&
				groupsTableRouter.element}
			{AddGroupModalController.element(trainingId)}
		</ArticleContentWrapper>
	)
}

export default GroupsArticle
