import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import { multiplyTo100 } from 'parts/utils/number'
import EntityTypes from 'parts/types/EntityTypes'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'

/** Переводит данные состояния в данные для сохранения на сервере */
export function convertStoreTariffsToServerTariffs(): EntityTypes.Tariff[] {
	const { tariffs, training } = tariffsManager.store

	return tariffs.map((stateTariff) => {
		const tariff: EntityTypes.Tariff = {
			id: stateTariff.dbId,
			groupId: stateTariff.groupId,
			isFlashed: stateTariff.isFlashed,
			name: stateTariff.header,
			description: stateTariff.description,
			theses: createTheses(stateTariff.theses),
			prices: createPrices(stateTariff),
			landingUrl: training.landingUrl,
			trainingCover: training.cover,
			trainingName: training.name,
		}

		if (stateTariff.dbId) {
			tariff.id = stateTariff.dbId
		}

		return tariff
	})
}

function createTheses(
	stateTheses: TariffsStoreType.TariffThesis[]
): EntityTypes.TariffThesis[] {
	return stateTheses.map((thesis) => {
		const obj: EntityTypes.TariffThesis = { text: thesis.text }

		if (thesis.crossedOut) {
			obj.crossedOut = thesis.crossedOut
		}

		return obj
	})
}

function createPrices(
	stateTariff: TariffsStoreType.Tariff
): EntityTypes.TariffPrice[] {
	const prices: EntityTypes.TariffPrice[] = []

	const { mainPrice } = stateTariff

	prices.push({
		isMain: true,
		text: mainPrice.text,
		currency: mainPrice.currency,
		price: multiplyTo100(mainPrice.price) || 0,
		oldPrice: multiplyTo100(mainPrice.oldPrice) || 0,
		period: mainPrice.period,
		paymentSystem: mainPrice.paymentSystem,
		buttonText: mainPrice.buttonText || 'Записаться',
	})

	stateTariff.prices.forEach((additionalStatePrice) => {
		prices.push({
			isMain: false,
			text: additionalStatePrice.text,
			currency: additionalStatePrice.currency,
			price: multiplyTo100(additionalStatePrice.price) || 0,
			oldPrice: multiplyTo100(additionalStatePrice.oldPrice) || 0,
			period: additionalStatePrice.period,
			paymentSystem: additionalStatePrice.paymentSystem,
			buttonText: additionalStatePrice.buttonText,
		})
	})

	return prices
}
