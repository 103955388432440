import { Space } from 'antd'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import { HorizontalPresetsListController } from 'widgets/presets/horizontalPresetsList/HorizontalPresetsList/HorizontalPresetsListController'
import { LessonsListAdminWrapperController } from 'widgets/lessonsListAdmin/LessonsListAdminController'
import UrlService from 'parts/services/UrlService'
import RestrictionsService from 'parts/services/RestrictionsService'
import HasErrorScreen from '../HasErrorScreen/HasErrorScreen'
import NoLessonsScreen from '../NoLessonsScreen/NoLessonsScreen'

// Страница с предустановками уроков и списком уроков
function TrainingArticleForAdmin() {
	const trainingId = UrlService.useGetTrainingId()
	const userRoles = useGetUserRole()

	const canUseLessonTemplate = RestrictionsService.useCanUseLessonTemplate()

	const presetsListRouter = HorizontalPresetsListController.router()
	const lessonsListRouter =
		LessonsListAdminWrapperController.router(trainingId)

	return (
		<ArticleContentWrapper>
			<Space
				direction="vertical"
				size="large"
				style={{ display: 'flex' }}
			>
				{userRoles.isAdmin && canUseLessonTemplate && presetsListRouter}
				{lessonsListRouter.status === 'noLessons' && (
					<NoLessonsScreen />
				)}
				{lessonsListRouter.status === 'error' && <HasErrorScreen />}
				{(lessonsListRouter.status === 'noLessons' ||
					lessonsListRouter.status === 'hasLessons') &&
					lessonsListRouter.element}
			</Space>
		</ArticleContentWrapper>
	)
}

export default TrainingArticleForAdmin
