import useColorSchemeModalStore from 'pages/landing/landingConstructor/details/ColorSchemeModal/zustand/store'

/**
 * Возвращает функцию, открывающую/закрывающую модальное окно добавления нового ученика
 * @param isVisible — должно ли окно быть видимым
 */
export function useGetSetVisibilityToModal(isVisible: boolean) {
	const updateStore = useColorSchemeModalStore((state) => state.updateStore)

	return function () {
		updateStore({ isOpen: isVisible })
	}
}
