import { useEffect, useState } from 'react'
import useExerciseStore from '../../zustand/exerciseState'

/**
 * Хук возвращает булево значение должна ли быть активной или кнопка перемещения к предыдущему или следующему вопросу
 * @param {String} btnType — тип кнопки: prev (переход к предыдущему вопросу) или next (следующему вопросу)
 */
export function usePrevNextButtonEnabled(btnType: 'prev' | 'next') {
	const currentQuestionNumber = useExerciseStore(
		(store) => store.currentQuestionIdx
	)
	const questions = useExerciseStore((store) => store.questions)

	const [enabled, setEnabled] = useState(false)

	useEffect(
		function () {
			if (btnType == 'prev') {
				setEnabled(currentQuestionNumber > 0)
			} else if (btnType == 'next') {
				setEnabled(currentQuestionNumber < questions.length - 1)
			}
		},
		[currentQuestionNumber, btnType, questions]
	)

	return enabled
}

/**
 * Хук возвращает обработчик кнопок перехода к предыдущему и следующему вопросу
 * @param {String} btnType — тип кнопки: prev (переход к предыдущему вопросу) или next (следующему вопросу)
 */
export function useGetPrevNextButtonHandler(btnType: 'prev' | 'next') {
	const currentQuestionNumber = useExerciseStore(
		(store) => store.currentQuestionIdx
	)
	const saveStoreForRestore = useExerciseStore(
		(store) => store.saveStoreForRestore
	)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		const questionNumberMap = {
			prev: currentQuestionNumber - 1,
			next: currentQuestionNumber + 1,
		}

		updateStore({
			currentQuestionIdx: questionNumberMap[btnType],
			saveStoreForRestore: saveStoreForRestore + 1,
		})
	}
}
