import { Form, FormInstance } from 'antd'
import { useEffect } from 'react'
import {
	makeSelectOptionsEnabledExceptOne,
	setErrorsToFields,
} from 'parts/utils/form'
import useTransferStudentsModalStore from '../zustand/store'

export enum FieldNames {
	FromCuratorId = 'fromCuratorId',
	ToCuratorId = 'toCuratorId',
	StudentsCount = 'studentsCount',
}

export type FormValuesType = {
	// От какого куратора передать учеников
	[FieldNames.FromCuratorId]: number
	// Какому куратору передать учеников
	[FieldNames.ToCuratorId]: number
	// Сколько передать учеников
	[FieldNames.StudentsCount]: number
}

/** Возвращает обработчик изменения выпадающего списка кураторов у одного из которых нужно убрать учеников */
export function useGetOnFromCuratorSelectChange() {
	const form = Form.useFormInstance()

	const curatorOptions = useTransferStudentsModalStore(
		(store) => store.toCuratorOptions
	)
	const updateModalState = useTransferStudentsModalStore(
		(store) => store.updateState
	)

	return (selectedFromCuratorVal: string) => {
		// Подготовить массив пунктов,
		// где выбранный пункт будет заблокирован чтобы его было невозможно выбрать
		const updatedOptions = makeSelectOptionsEnabledExceptOne(
			curatorOptions,
			parseInt(selectedFromCuratorVal)
		)

		// Поставить обновлённый массив в список «Получит учеников»
		updateModalState({ toCuratorOptions: updatedOptions })

		// Если выбранный пункт уже стоит в списке «Получит учеников», то обнулить выбранное значение
		if (
			form.getFieldValue(FieldNames.ToCuratorId) == selectedFromCuratorVal
		) {
			form.setFieldValue(FieldNames.ToCuratorId, null)
		}
	}
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useTransferStudentsModalStore(
		(state) => state.formErrors
	)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
