import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import ExpressCourseCard from '../components/CourseCard/CourseCard'
import ExpressTitle from '../components/Title/ExpressTitle'
import { useNavigate } from 'react-router-dom'
import AppUrls from 'parts/constants/pageUrl'
import styles from './Courses.module.scss'

const ExpressCourses: FC = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	return (
		<div className={styles.ExpressCourses}>
			<ExpressTitle
				title={t('express.mainPageTitle')}
				subtitle={t('express.mainPageSubtitle')}
				image="/images/express/title-image.png"
			/>
			<div className={styles.ExpressCourses__CoursesList}>
				{/* <ExpressCourseCard
					image="/images/express/screen-1.png"
					lessonCount={t('express.mainPageHowToTeachCardLessonCount')}
					title={t('express.mainPageHowToTeachCardTitle')}
					subtitle={t('express.mainPageHowToTeachCardSubtitle')}
					onClick={() =>
						navigate(AppUrls.MyExpressCourse('how-teach').url)
					}
				/> */}
				<ExpressCourseCard
					image="/images/express/screen-2.png"
					lessonCount={t('express.mainPageHowToCreateCardTitle')}
					title={t('express.mainPageHowToCreateCardTitle')}
					subtitle={t('express.mainPageHowToCreateCardSubtitle')}
					onClick={() =>
						navigate(AppUrls.MyExpressCourse('how-create').url)
					}
				/>
			</div>
		</div>
	)
}

export default ExpressCourses
