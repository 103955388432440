import dayjs from 'dayjs'
import { FieldsNames, FormValues } from './form'
import useCondStore from '../zustand/store'

/** Возвращает первоначальные значения полей формы изменения условий прохождения группы */
export function useGetInitialValues(): FormValues {
	const group = useCondStore((state) => state.group)

	const { startDate, startTime } = getStartDateAndTimeDate(group.startDate)

	return {
		[FieldsNames.StartCondition]: group.startCondition,
		[FieldsNames.StartDate]: startDate,
		[FieldsNames.StartTime]: startTime,
		[FieldsNames.PassingMode]: group.mode,
		// @ts-ignore
		[FieldsNames.Interval]: group.openLessonCondition,
		// @ts-ignore
		[FieldsNames.OpeningByDay]: group.openLessonCondition || [],
		[FieldsNames.Duration]: group.duration,
		[FieldsNames.Access]: group.accessToCourse,
	}
}

/**
 * Возвращает значения полей даты и времени начала прохождения
 * @param startDateStr — строка с датой начала прохождения курса
 */
function getStartDateAndTimeDate(startDateStr: string | null) {
	if (!startDateStr) {
		return {
			startDate: null,
			startTime: null,
		}
	}

	return {
		startDate: dayjs(startDateStr),
		startTime: dayjs(startDateStr),
	}
}
