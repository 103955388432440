import { useEffect } from 'react'
import TrainingAdminService from 'parts/services/TrainingAdminService'
import useAddEmployeeModalStore from '../store'

export function fillInStore() {
	const updateAddEmployeeModalStore = useAddEmployeeModalStore(
		(store) => store.updateStore
	)

	const trainingOptions =
		TrainingAdminService.useFetchTrainingsAndGroupsAndConvertToCascaderOption()

	useEffect(
		function () {
			if (!trainingOptions) return

			updateAddEmployeeModalStore({ trainingOptions: trainingOptions })
		},
		[trainingOptions]
	)
}
