import {
	DatePicker,
	Button,
	Checkbox,
	Cascader,
	Form,
	Input,
	Select,
} from 'antd'
import { useTranslation } from 'react-i18next'
import ModalWithForm from 'ui/ModalWithForm/ModalWithForm'
import useMakeOrderStore from './zustand/store'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'
import { FieldNames, setErrorsToForm } from './fn/form'
import { useGetSetVisibilityToMakeOrderModal } from './fn/openCloseModal'
import PaymentTypes from 'parts/types/PaymentTypes'
import {
	UFormNewBlockHeader,
	UFormNewBottomButtonsAfterGray,
	UFormNewButtonsContainer,
	UFormNewDivider,
	UFormNewRow,
} from 'ui/UForm/UForm'
import { useMakeOrder } from './fn/submit'
import UserService from 'parts/services/UserService'
import TrainingAdminService from 'parts/services/TrainingAdminService'

const { Option } = Select

// Модальное окно создания нового зака
function MakeOrderModal() {
	const { t } = useTranslation()
	const [form] = Form.useForm()

	const submit = useMakeOrder()
	setErrorsToForm(form)

	const modalState = useMakeOrderStore((state) => state)
	const closeModal = useGetSetVisibilityToMakeOrderModal(false)

	return (
		<ModalWithForm
			title={t('student.makeOrderModalHeader')}
			open={modalState.isOpen}
			onCancel={closeModal}
		>
			<Form
				form={form}
				name="addStudent"
				layout="vertical"
				onFinish={submit}
			>
				<GrayBlock>
					<UFormNewBlockHeader
						text={t('student.makeOrderModalPurchaseBlockHeader')}
					/>
					<TrainingsSelect />
					<DateAndBuyWayInputs />
					<CurrencyValueAndTimeInputs />
					<CuratorsSelect />
					<UFormNewDivider />
					<GetAccessToAccountInput />
				</GrayBlock>
				<Bottom />
			</Form>
		</ModalWithForm>
	)
}

export default MakeOrderModal

// Выпадающий список с выбором курса и группы
function TrainingsSelect() {
	const { t } = useTranslation()
	const options = TrainingAdminService.useGetTrainingsListAsCascaderOption()

	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.Training}
				label={t('student.makeOrderModalFieldGroupLabel')}
				rules={[
					{
						required: true,
						message: t(
							'student.makeOrderModalFieldGroupRequiredError'
						),
					},
				]}
			>
				<Cascader options={options} />
			</Form.Item>
		</UFormNewRow>
	)
}

// Поля для указания даты покупки и способа оплаты
function DateAndBuyWayInputs() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.PurchaseDate}
				label={t('student.makeOrderModalFieldPurchaseDateLabel')}
				rules={[
					{
						required: true,
						message: t(
							'student.makeOrderModalFieldPurchaseDateRequiredError'
						),
					},
				]}
			>
				<DatePicker
					format="YYYY/MM/DD"
					className="add-student-form__date"
				/>
			</Form.Item>
			<Form.Item
				name={FieldNames.PaymentSystem}
				label={t('student.makeOrderModalFieldPaymentSystemLabel')}
				rules={[
					{
						required: true,
						message: t(
							'student.makeOrderModalFieldPaymentSystemRequiredError'
						),
					},
				]}
			>
				<Select>
					{Object.values(PaymentTypes.Systems).map((system) => {
						return (
							<Option value={system.system} key={system.system}>
								{system.name}
							</Option>
						)
					})}
				</Select>
			</Form.Item>
		</UFormNewRow>
	)
}

// Поля для указания валюты, стоимости и за какой период была оплата
function CurrencyValueAndTimeInputs() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Currency}
				label={t('student.makeOrderModalFieldCurrencyLabel')}
				rules={[
					{
						required: true,
						message: t(
							'student.makeOrderModalFieldCurrencyRequiredError'
						),
					},
				]}
			>
				<Select
					showSearch
					placeholder="RUB"
					optionFilterProp="children"
				>
					{Object.values(PaymentTypes.Currencies).map((currency) => {
						return (
							<Option
								value={currency.currency}
								key={currency.currency}
							>
								{currency.currency}
							</Option>
						)
					})}
				</Select>
			</Form.Item>
			<Form.Item
				name={FieldNames.Amount}
				label={t('student.makeOrderModalFieldAmountLabel')}
				rules={[
					{
						required: true,
						message: t(
							'student.makeOrderModalFieldAmountRequiredError'
						),
					},
				]}
			>
				<Input type="number" />
			</Form.Item>
			{/*<Form.Item
				name={FieldNames.PaymentMode}
				label="За что"
				rules={[
					{
						required: true,
						message: 'Срок',
					},
				]}
			>
				<Select showSearch optionFilterProp="children">
					{Object.values(PaymentTypes.PricePeriods).map((period) => {
						return (
							<Option value={period.period} key={period.period}>
								{period.name}
							</Option>
						)
					})}
				</Select>
			</Form.Item>*/}
		</UFormNewRow>
	)
}

// Выпадающий список с выбором куратора
function CuratorsSelect() {
	const { t } = useTranslation()
	const curatorsList = UserService.useGetCuratorsList(true)

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.CuratorId}
				label={t('student.makeOrderModalFieldCuratorLabel')}
				/*rules={[
					{
						required: true,
						message: 'Укажите куратора',
					},
				]}*/
			>
				<Select
					showSearch
					placeholder={t(
						'student.makeOrderModalFieldCuratorNotSelectedItem'
					)}
					optionFilterProp="children"
				>
					{curatorsList.map((curator) => {
						return (
							<Option value={curator.id} key={curator.id}>
								{curator.name}
							</Option>
						)
					})}
				</Select>
			</Form.Item>
		</UFormNewRow>
	)
}

// Флаг Выдать доступ в личный кабинет
function GetAccessToAccountInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow>
			<Form.Item name={FieldNames.GetAccess} valuePropName="checked">
				<Checkbox>
					{t('student.makeOrderModalGetAccessCheckbox')}
				</Checkbox>
			</Form.Item>
		</UFormNewRow>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
// Кнопки подтверждения и закрытия окна
function Bottom() {
	const { t } = useTranslation()

	const isSubmitting = useMakeOrderStore((state) => state.isSubmitting)
	const closeModal = useGetSetVisibilityToMakeOrderModal(false)

	return (
		<UFormNewBottomButtonsAfterGray>
			<div />
			<UFormNewButtonsContainer>
				<Button onClick={closeModal}>
					{t('student.makeOrderModalCancelButton')}
				</Button>
				<Button
					type="primary"
					htmlType="submit"
					disabled={isSubmitting}
					loading={isSubmitting}
				>
					{t('student.makeOrderModalSubmitButton')}
				</Button>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
