import { Drawer } from 'antd'
import { useTranslation } from 'react-i18next'
import { DataDrawerType, useGetMenuData } from './fn/menuData'
import { useGetOnExerciseItemClick } from './fn/clicks'
import { getDrawerWidth } from './fn/getDrawerWidth'
import { exercisesAsideManager } from './zustand/exercisesAsideManager'
import useExercisesAsideStore from './zustand/store'
import './ExercisesAside.scss'

// Выдвигающаяся боковая панель со списком упражнений для создания
function ExercisesAside() {
	const { t } = useTranslation()
	const isPanelOpen = useExercisesAsideStore((state) => state.isPanelOpen)

	const drawerWidth = getDrawerWidth()
	const menuData = useGetMenuData()

	return (
		<Drawer
			title="Создание нового упражнения"
			placement="right"
			width={drawerWidth}
			onClose={exercisesAsideManager.close}
			open={isPanelOpen}
			className="exercises-aside"
		>
			<div className="exercises-aside__items">
				{menuData.map((item) => {
					return <ExerciseItem item={item} key={item.id} />
				})}
			</div>
		</Drawer>
	)
}

export default ExercisesAside

type ExerciseMenuItemProps = {
	item: DataDrawerType
}

function ExerciseItem(props: ExerciseMenuItemProps) {
	const { item } = props

	const onItemClick = useGetOnExerciseItemClick(item.formType)

	return (
		<div className="exercises-aside__item" onClick={onItemClick}>
			<img
				src={item.picSrc || '/images/empty-image.png'}
				className="exercises-aside__item-image"
				alt=""
			/>
			<div className="exercises-aside__item-content">
				<h3 className="exercises-aside__item-header">{item.title}</h3>
				<p className="exercises-aside__item-desc">{item.text}</p>
			</div>
		</div>
	)
}
