import { produce } from 'immer'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import useExercisesListAdminStore from '../../../zustand/store'
import { getFormState, setFormState } from '../../common/exerciseFormCommonFunc'
import { TrueOrFalseExerciseFormStateType } from './stateType'

/**
 * Обработчик отправки формы
 * @param {Function} saveExercise — функция сохраняющая упражнение на сервере и в Состоянии
 */
export function submitHandler(
	saveExercise: (args: TrainingEntityTypes.ExerciseItem) => void
) {
	const isFormValid = validateForm()

	if (!isFormValid) {
		return
	}

	const formState = getFormState<TrueOrFalseExerciseFormStateType>()

	const exerciseData = convertFormStateDataToExerciseData(formState)
	saveExercise(exerciseData)
}

/** Функция проверяет правильность заполнения формы, изменяет Состояние для показа ошибок и возвращает булево значение является ли форма правильной */
function validateForm() {
	// Есть ли в форме ошибки
	// Обозначает отсутствие в форме блоков утверждений ошибки отсутствия текста утверждения
	let formHasNoTextError = false

	const formState = getFormState<TrueOrFalseExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		// Проверка того, что в каждом блоке утверждения написан текст
		draft.statements.forEach((statement) => {
			if (!statement.text) {
				statement.noTextError = true
				formHasNoTextError = true
			}
		})

		if (formHasNoTextError) {
			draft.isFormInvalid = true
		}
	})

	setFormState(newState)

	return !newState.isFormInvalid
}

/**
 * Функция переводит данные формы редактирования упражнения в данные упражнения для сохранения на сервере.
 * @param {Object} formState — объект с данными формы
 */
function convertFormStateDataToExerciseData(
	formState: TrueOrFalseExerciseFormStateType
) {
	const lessonId = useExercisesListAdminStore.getState().lessonId

	let exerciseData: TrainingEntityTypes.TrueOrFalseExercise = {
		type: 'trueOrFalse',
		lessonId,
		order: formState.order,
		item: {
			task: formState.taskInput.value,
			statements: formState.statements.map((statement, i) => {
				return {
					text: statement.text,
					correct: statement.correct,
					image: statement.image.link,
					generatedTextPrompt: statement.generatedTextPrompt,
				}
			}),
		},
		status: 0, // Чтобы TS не ругался
		statusUpdated: '', // Чтобы TS не ругался
	}

	if (formState.exerciseId) {
		exerciseData.id = formState.exerciseId
	}

	return exerciseData
}
