import axios from 'axios'
import fileRequests from 'parts/requests/files/fileRequest'
import UploadFile from '../components/UploadFile/UploadFile'
import useLiveLibraryStore from '../zustand/store'
import UploadFileTypes from 'parts/constants/uploadTypes'
import { getCurrentPath, getFileNameWOSpace } from '../../../fn/liveUtils'
import useSystemStore from 'parts/systemStore/systemStore'
import { liveLessonQuery } from 'parts/requests/liveLesson/liveLessonQuery'
import LiveLessonApiTypes from 'parts/requests/liveLesson/liveLessonApiTypes'
import { useGetCreateStorageItem } from '../../../fn/createStorageItem'
import { MessageInstance } from 'antd/es/message/interface'

function useUploadFile(messageApi: MessageInstance) {
	const isUploadShow = useLiveLibraryStore(
		(store) => store.isUploadDialogShow
	)
	const setIsUploadShow = useLiveLibraryStore(
		(store) => store.setIsUploadDialogShow
	)
	const setIsFileUploading = useLiveLibraryStore(
		(store) => store.setIsFileUploading
	)
	const setUploadFileName = useLiveLibraryStore(
		(store) => store.setUploadFilename
	)
	const selectedFolder = useLiveLibraryStore((store) => store.selectedFolder)
	const setUploadPercentCompleted = useLiveLibraryStore(
		(store) => store.setUploadPercentCompleted
	)
	const school = useSystemStore((store) => store.school)
	const onCreateStorageItem = useGetCreateStorageItem()
	const { status, data: storageItems } =
		liveLessonQuery.getStorageItems.useQuery()

	const changeUploadInput = async (file: File) => {
		// Show Loader
		setUploadFileName(file.name)
		setIsFileUploading(true)
		setIsUploadShow(false)

		const prefix = selectedFolder
			? `${school.id}/${getCurrentPath(
					storageItems?.data,
					selectedFolder.id,
					false
			  )
					.map((item) => item.name)
					.join('/')}`
			: `${school.id}`

		const { data } = await fileRequests.getFilesUrl({
			uploadType: UploadFileTypes.STORAGE,
			fileName: getFileNameWOSpace(file.name),
			fileType: file.type,
			prefix,
		})
		axios
			.put(data.signedRequest, file, {
				headers: {
					'Cache-Control': 'public,max-age=31536000,immutable',
					'x-amz-acl': 'public-read',
				},
				onUploadProgress: (progressEvent) => {
					const uploadPercentCompleted = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total
					)
					setUploadPercentCompleted(uploadPercentCompleted)
				},
			})
			.then(() => {
				const newFile: LiveLessonApiTypes.CreateStorageItem = {
					parentId: selectedFolder ? selectedFolder.id : null,
					name: file.name,
					fileType: file.type,
					fileSize: file.size,
					s3link: data.url,
					isFolder: false,
				}

				onCreateStorageItem(newFile)
			})
			.then(() => messageApi.info('Файл успешно загружен'))
			.finally(() => {
				setIsUploadShow(false)
				setIsFileUploading(false)
			})
	}

	const handleBtnClick = () => {
		setIsUploadShow(false)
	}

	return (
		<UploadFile
			open={isUploadShow}
			onOk={handleBtnClick}
			onCancel={handleBtnClick}
			changeUploadInput={changeUploadInput}
		/>
	)
}

export default useUploadFile
