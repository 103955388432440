import { useTranslation } from 'react-i18next'
import Loader from 'ui/Loader/Loader'
import RestrictionsService from 'parts/services/RestrictionsService'
import ContentWrapper from '../screens/ContentWrapper/ContentWrapper'
import { useSetLiveLessonIdToSystemStore } from './fn/setLiveLessonIdToSystemStore'
import useLiveLessonEntrancePageStore from '../zustand/store'
import { useCheckLiveLessonEntranceId } from './fn/checkLiveLessonEntranceId'

// Страница входа на урок. Но страница самого урока имеет другой адрес.
// В зависимости от того, вошёл ли пользователь в учётную записи или нет показываются различные экраны.
function LiveLessonEntrancePage() {
	const { t } = useTranslation()

	const liveEntranceIdCheckStatus = useLiveLessonEntrancePageStore(
		(store) => store.liveEntranceIdCheckStatus
	)

	useCheckLiveLessonEntranceId()
	useSetLiveLessonIdToSystemStore()

	if (!RestrictionsService.useCanUseLiveLessons()) {
		return null
	}

	// Проверка передали ли правильный идентификатор входа в эфир урока
	if (liveEntranceIdCheckStatus === 'loading') {
		return <Loader />
	} else if (liveEntranceIdCheckStatus === 'error') {
		return <p>{t('live.liveEntrancePageWrongLink')}</p>
	}

	// После того, как в Системном Хранилище появится идентификатор эфира урока,
	// то хук в <App /> переадресует пользователя на страницу эфира урока если он авторизован.
	// Поэтому дальше сценарий не пойдёт...

	return <ContentWrapper />
}

export default LiveLessonEntrancePage
