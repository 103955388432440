import cn from 'classnames'
import { LandingTypes } from 'parts/types/LandingTypes'
import useLandingBuilderStore from '../../../zustand/store'
import './ExistenceBlock.scss'

type BlockProps = {
	blockIdx: number
	block: LandingTypes.ExistenceBlock
}

export function Block(props: BlockProps) {
	const { blockIdx, block } = props

	const articleClasses = ['landing-existence-block']
	if (block.colorTheme == 'green') {
		articleClasses.push('landing-existence-block--green')
	} else {
		articleClasses.push('landing-existence-block--red')
	}

	return (
		<article className={cn(articleClasses)}>
			<BlockContent blockIdx={blockIdx} />
		</article>
	)
}

type BlockContentProps = {
	blockIdx: number
}

function BlockContent(props: BlockContentProps) {
	const { blockIdx } = props
	const landing = useLandingBuilderStore((store) => store.landing)

	return (
		<div className="landing-existence-block__content">
			<div className="landing-existence-block__text-part">
				<h3 className="landing-existence-block__header">
					{landing.sections.existence.blocks[blockIdx].header}
				</h3>
				<p className="landing-existence-block__text">
					{landing.sections.existence.blocks[blockIdx].text}
				</p>
			</div>
			<div className="landing-existence-block__img-part">
				<img
					src={landing.sections.existence.blocks[blockIdx].imageUrl}
					className="landing-existence-block__img-part-img"
					alt=""
				/>
			</div>
		</div>
	)
}
