import React, { useRef } from 'react'
import {
	ExerciseChangesDataMadeByUser,
	SaveExerciseChangesMadeByUser,
} from '../../common/useLiftViewDuration'
import useExerciseStore, { ExerciseStateType } from '../zustand/exerciseState'
import {
	useGetBlurContentHandler,
	useRestoreExercise,
	useSaveStateOnServerForRestore,
} from './fn/saveAndRestoreExercise'
import { useCreateInnerHtml, useSetTextsChangeHandler } from './fn/exercise'
import HtmlEditorContentView from 'ui/HTMLEditorContentView/HTMLEditorContentView'
import { TaskExerciseView } from '../../common/TaskExerciseView/TaskExerciseView'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

type ExerciseProps = {
	exercise: TrainingEntityTypes.GapsInWordsExercise
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

function Exercise(props: ExerciseProps) {
	const { exercise, saveExerciseChangesMadeByUser, exerciseRestoreData } =
		props

	const textBlocks = useExerciseStore((store) => store.textBlocks)

	const textsRef = useRef<null | HTMLDivElement>(null)

	// Установка функции отслеживающей ввод текста в пропуски
	useSetTextsChangeHandler(textsRef)

	// Занимается сохранением состояния упражнения для восстановления при открытии
	const blurContentHandler = useGetBlurContentHandler(
		textsRef.current,
		saveExerciseChangesMadeByUser
	)
	useSaveStateOnServerForRestore(
		textsRef.current,
		saveExerciseChangesMadeByUser
	)

	return (
		<>
			{exercise.item.task && (
				<TaskExerciseView htmlString={exercise.item.task} />
			)}
			<div
				className="exercise-gaps-content__text-blocks"
				ref={textsRef}
				onBlur={blurContentHandler}
			>
				{textBlocks.map((textBlock: ExerciseStateType.TextBlock) => {
					return (
						<TextBlock
							htmlString={textBlock.htmlString}
							key={textBlock.id}
						/>
					)
				})}
			</div>
		</>
	)
}

export default Exercise

type TextBlockProps = {
	htmlString: string
}

// Блок текста с пропусками
function TextBlock(props: TextBlockProps) {
	const { htmlString } = props

	const areaRef = useRef(null)
	useCreateInnerHtml(areaRef, htmlString)

	return <HtmlEditorContentView areaRef={areaRef} />
}
