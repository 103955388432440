import { FieldsNames, FormValues } from './form'
import useOpenLessonsStore from '../zustand/store'

/** Возвращает первоначальные значения полей формы изменения открытых уроков группы */
export function useGetInitialValues(): FormValues {
	const group = useOpenLessonsStore((state) => state.group)
	const lessons = useOpenLessonsStore((state) => state.lessons)

	const openLessons = lessons.map((l) => group.openLessons.includes(l.id))

	return {
		[FieldsNames.OpenLessons]: openLessons,
	}
}
