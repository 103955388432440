import Loader from 'ui/Loader/Loader'
import LandingBuilderPage from '../../landingBuilder/main/LandingBuilderPage/LandingBuilderPage'
import { useFillInStore } from '../zustand/fillStore/fillStore'
import useLandingPreviewStore from '../zustand/store'

// Страница с предпросмотром редактируемого одностраничника с продажей курса
function LandingConstructorPreviewPage() {
	useFillInStore()

	const [dataLoadingStatus, errorMessage, landing, tariffs] =
		useLandingPreviewStore((store) => [
			store.dataLoadingStatus,
			store.errorMessage,
			store.landing,
			store.tariffs,
		])

	if (dataLoadingStatus === 'loading') {
		return <Loader />
	} else if (dataLoadingStatus === 'error') {
		if (errorMessage) {
			return <p>{errorMessage}</p>
		}

		return <p>При загрузке данных произошла ошибка</p>
	}

	return <LandingBuilderPage landing={landing} tariffs={tariffs} />
}

export default LandingConstructorPreviewPage
