import { Block } from '../ProgramBlock/ProgramBlock'
import programBlocksManager from '../programBlocksManager/programBlocksManager'
import './ProgramBlocks.scss'

// Обёртка с двумя колонками куда помещаются блоки с описанием пользователя, которому подойдёт предлагаемый курс
function ProgramBlocks() {
	const leftBlocks = programBlocksManager.useGetColumnBlocks(1)
	const rightBlocks = programBlocksManager.useGetColumnBlocks(2)

	let blockIdx = -1

	return (
		<div className="landing-program-blocks">
			<div className="landing-program-blocks__column">
				{leftBlocks.map((block) => {
					blockIdx++

					return (
						<Block
							block={block}
							blockIdx={blockIdx}
							key={block.id}
						/>
					)
				})}
			</div>
			<div className="landing-program-blocks__column">
				{rightBlocks.map((block) => {
					blockIdx++

					return (
						<Block
							block={block}
							blockIdx={blockIdx}
							key={block.id}
						/>
					)
				})}
			</div>
		</div>
	)
}

export default ProgramBlocks
