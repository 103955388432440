import { useMemo } from 'react'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import useTrainingHeaderAdminStore from '../../zustand/store'

/** Возвращает заголовок страницы и описание курса */
export function useGetInitialHeaderText() {
	const training = useTrainingHeaderAdminStore((store) => store.training)

	return useMemo(
		function () {
			if (!training) {
				return { initialHeaderText: '', initialDescriptionText: '' }
			} else {
				return {
					initialHeaderText: training.name,
					initialDescriptionText: training.description,
				}
			}
		},
		[training]
	)
}

/**
 * Возвращает обработчик изменения текста заголовка.
 * При изменении на сервер делается запрос на сохранение нового имени группы.
 */
export function useGetSaveHeaderTextOnServerAfterChange(
	propName: keyof TrainingEntityTypes.TrainingAdmin
) {
	const training = useTrainingHeaderAdminStore((store) => store.training)

	const successTrainingMutation = useGetMutateOnSuccess()

	const updateTrainingMutation =
		trainingQuery.createOrUpdateTraining.useMutation({
			onError: useMutateOnError(),
			onSuccess: successTrainingMutation,
		})

	return async function (propValue: any) {
		const trainingCopy = Object.assign(training, {
			[propName]: propValue,
		})

		updateTrainingMutation.mutate(trainingCopy)
	}
}

/** Функция, выполняемая при появлении ошибки после запроса на изменение курса */
function useMutateOnError() {
	return function (err: unknown) {
		const { t } = useTranslation()
		message.error(t('adminTraining.errorWhileUpdateTraining'))
	}
}

/** Функция, выполняемая при успешном запросе изменение данных курса */
function useGetMutateOnSuccess() {
	const queryClient = useQueryClient()

	return function () {
		queryClient.refetchQueries({
			queryKey: [trainingQuery.getTrainingsForAdmin.key],
		})
	}
}
