import React from 'react'
import ExerciseStateType from './fn/ExerciseStateType'
import { getWordButtonClasses } from './fn/classes'

type WordsProps = {
	state: ExerciseStateType.Main
}

// Блок слов на верхней части упражнения
export function Words(props: WordsProps) {
	const { state } = props

	return (
		<div className="exercise-desc-image-content__words">
			{state.descriptions.map((word, i) => (
				<Word wordObj={word} position="inside" key={i} />
			))}
		</div>
	)
}

type WordProps = {
	wordObj: ExerciseStateType.DescriptionData
	position: 'inside' | 'outside'
}

export function Word(props: WordProps) {
	const { wordObj, position } = props

	const buttonClasses = getWordButtonClasses(position)

	return (
		<button className={buttonClasses} draggable data-word-id={wordObj.id}>
			{wordObj.text}
		</button>
	)
}
