import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import UrlService from 'parts/services/UrlService'

/**
 * При размонтировании конструктора одностраничника помечает данные неактуальными.
 * Это требуется чтобы Реакт Квери знал, что нужно их перекачать заново.
 * Иначе я могу в конструкторе их поменять, они будут сохранены.
 * Но если переключиться на другую страницу и снова перейти в Конструктор, то Реакт Квери отдаст старые закешированные данные.
 */
export function useRefetchQueryCacheOnUnmount() {
	const trainingId = UrlService.useGetTrainingId()
	const queryClient = useQueryClient()

	useEffect(function () {
		return function () {
			// Пометить данные курса неактуальными. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [
					trainingQuery.getDraftTrainingLanding(trainingId).key,
				],
			})
		}
	}, [])
}
