import React from 'react'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import AccountForm from '../AccountForm/AccountForm'

function StudentProfileArticle() {
	return (
		<ArticleContentWrapper
			innerWrapperClass="student-profile"
			innerWrapperWidth="small"
		>
			<AccountForm />
		</ArticleContentWrapper>
	)
}

export default StudentProfileArticle
