import React from 'react'

export const dataTransferFormat = 'Text'
type AreaType = 'words' | 'images'

type ButtonInfoType = {
	text: string
	wordId: string
	startDragArea: AreaType
}

/**
 * Функция получает элемент кнопки с текстом и достаёт её текст и идентификатор.
 * @param {HTMLElement} $button — элемент кнопки
 */
export function getInfoFrom$button($button: HTMLButtonElement): ButtonInfoType {
	const wordId = $button.dataset.wordId
	const text = $button.textContent

	return {
		text: text || '',
		wordId: wordId || '',
		startDragArea: 'words',
	}
}

/**
 * Функция возвращает данные передаваемые при перетаскивании
 * @param {Object} event — объект события
 */
export function getInfoFromDataTransfer(
	event: React.DragEvent<HTMLElement>
): ButtonInfoType {
	// Получение строки вида 'Слово__2__words'
	// где первое перемещаемое слово,
	// число — её идентификатор,
	// а последнее место откуда тащат слово: words (панель со всеми словами) или text (текст упражнения со слотами приёма слов)
	const buttonTransferStr = event.dataTransfer.getData(dataTransferFormat)

	const strParts = buttonTransferStr?.split('__')

	return {
		text: strParts[0],
		wordId: strParts[1],
		startDragArea: strParts[2] as AreaType,
	}
}

/**
 * Функция создаёт кнопку с текстом для перетаскивания
 * @param {String} text — текст кнопки
 * @param {String} textId — id кнопки с текстом
 */
export function createTextButton(text: string, textId: string) {
	const $button = document.createElement('button')

	$button.draggable = true
	$button.classList.add('exercise-desc-image-content__word')
	$button.classList.add('exercise-desc-image-content__word--full-wide')
	$button.dataset.wordId = textId
	$button.textContent = text

	return $button
}

/**
 * Функция возвращает тип обёртки где находится переданный элемент:
 * words (элемент находится в обёртке со всеми текстами) или images (элемент находится в изображениях)
 * @param {HTMLElement} $elem — элемент у которого нужно узнать тип родительской обёртки
 */
export function getParentBlockType($elem: HTMLElement): null | AreaType {
	let current$Elem = $elem

	for (;;) {
		if (
			current$Elem.classList.contains(
				'exercise-desc-image-content__words'
			)
		) {
			return 'words'
		} else if (
			current$Elem.classList.contains(
				'exercise-desc-image-content__images'
			)
		) {
			return 'images'
		}

		if (!current$Elem.parentElement) {
			return null
		}

		current$Elem = current$Elem.parentElement
	}
}

/**
 * Функция делает кнопку неактивной (без возможности перетаскивания)
 * @param {HTMLElement} $button — элемент кнопки
 */
export function disable$button($button: HTMLElement) {
	// Класс забледняющий кнопку с перетаскиваемым словом
	$button.classList.add('exercise-desc-image-content__word--ghost')
	// Убрать возможность перемещения элемента
	$button.removeAttribute('draggable')
}

/**
 * Функция делает кнопку активной (с возможностью перетаскивания)
 * @param {HTMLElement} $button — элемент кнопки
 */
export function enable$button($button: HTMLButtonElement) {
	// Убрать класс забледняющий кнопку с перетаскиваемым словом
	$button.classList.remove('exercise-desc-image-content__word--ghost')
	// Убрать возможность перемещения элемента
	$button.setAttribute('draggable', 'true')
}

/**
 * Функция возвращает тип элемент или области списка слов или области текста
 * @param {HTMLElement} $element — элемент, с которого будет идти поиск вверх к корневому элементу упражнения, а затем от него к требуемой области
 * @param {String} areaType — тип области, которую нужно найти
 */
export function get$Area(
	$element: HTMLElement,
	areaType: AreaType
): null | HTMLDivElement {
	let current$Elem = $element

	for (;;) {
		if (
			current$Elem.classList.contains(
				'exercise-desc-image-content__exercise-wrapper'
			)
		) {
			break
		}

		if (!current$Elem.parentElement) {
			return null
		}

		current$Elem = current$Elem.parentElement
	}

	if (areaType == 'words') {
		return current$Elem.getElementsByClassName(
			'exercise-desc-image-content__words'
		)[0] as HTMLDivElement
	} else {
		return current$Elem.getElementsByClassName(
			'exercise-desc-image-content__images'
		)[0] as HTMLDivElement
	}
}

/**
 * Функция находит кнопку по переданному идентификатору в переданном контейнере.
 * @param {HTMLDivElement} $area — область где нужно искать кнопку
 * @param {String} textId — id искомой кнопки
 */
export function get$textButtonById(
	$area: HTMLElement,
	textId: string
): null | HTMLButtonElement {
	const $buttons = $area.getElementsByClassName(
		'exercise-desc-image-content__word'
	)

	for (let i = 0; i < $buttons.length; i++) {
		const $button = $buttons[i]

		if ($button.getAttribute('data-word-id') == textId.toString()) {
			return $button as HTMLButtonElement
		}
	}

	return null
}

/**
 * Находит по id кнопку находящуюся в компоненте, удаляет её и очищает слот от лишнего класса
 * @param {HTMLElement} $area — обёртка где находится слово
 * @param {String} textId — id перемещаемого текста
 */
export function clear$slot($area: HTMLDivElement, textId: string) {
	const $button = get$textButtonById($area, textId)
	if (!$button) return

	const $slot = $button.parentNode
	if ($slot && $slot instanceof HTMLElement) {
		removeShadowFrom$slot($slot)
	}

	$button.remove()
}

/**
 * Функция ставит данные, которые будут храниться при перетаскивании текста
 * @param {Object} event — объект события
 * @param {String} text — перемещаемый текст
 * @param {String} wordId — id перемещаемого текста
 * @param {String} areaType — тип области
 */
export function setTransferData(
	event: React.DragEvent<HTMLDivElement>,
	text: string,
	wordId: string,
	areaType: AreaType
) {
	event.dataTransfer.setData(
		dataTransferFormat,
		text + '__' + wordId + '__' + areaType
	)
}

/**
 * Функция получает обёртку слов и добавляет класс затеняющий её.
 * @param {HTMLElement} $words — обёртка слов
 */
export function addShadowTo$words($words: HTMLSpanElement) {
	$words.classList.add('exercise-desc-image-content__words--shadow')
}

/**
 * Функция убирает затемняющий класс с обёртки содержащей список слов
 * @param {HTMLElement} $words — обёртка слов
 */
export function removeShadowFrom$images($words: HTMLSpanElement) {
	$words.classList.remove('exercise-desc-image-content__words--shadow')
}

/**
 * Функция добавляет в элемент слота класс затеняющий его.
 * @param {HTMLElement} $slot — элемент слота
 */
export function addShadowTo$slot($slot: HTMLElement) {
	$slot.classList.add('exercise-desc-image-content__slot--shadow')
}

/**
 * Функция удаляет из слота класс затеняющий слот.
 * @param {HTMLElement} $slot — элемент слота
 */
export function removeShadowFrom$slot($slot: HTMLElement) {
	$slot.classList.remove('exercise-desc-image-content__slot--shadow')
}

/**
 * Функция добавляет в слот класс увеличивающий его ширину.
 * @param {HTMLElement} $word — элемент текста
 */
export function addFullWidthTo$word($word: HTMLElement) {
	$word.classList.add('exercise-desc-image-content__word--full-wide')
}

/**
 * Функция удаляет из слота класс уменьшающий его ширину.
 * @param {HTMLElement} $word — элемент слота
 */
export function removeFullWidthFrom$word($word: HTMLElement) {
	$word.classList.remove('exercise-desc-image-content__word--full-wide')
}

/**
 * Функция следит чтобы все неактивные кнопки со словами присутствовали в тексте.
 * Если нет, то кнопки восстанавливаются чтобы их можно было перетаскивать.
 * @param {HTMLElement} $wordsArea — область слов
 * @param {HTMLElement} $textArea — область текста
 */
export function cureExercise($wordsArea: HTMLElement, $textArea: HTMLElement) {
	const $topGhostButtons = $wordsArea.getElementsByClassName(
		'exercise-desc-image-content__word--ghost'
	)

	for (let i = 0; i < $topGhostButtons.length; i++) {
		const $button = $topGhostButtons[i] as HTMLButtonElement
		const topButtonInfo = getInfoFrom$button($button)

		const $buttonInTextArea = get$textButtonById(
			$textArea,
			topButtonInfo.wordId
		)

		if (!$buttonInTextArea) {
			enable$button($button)
		}
	}
}

export function is$slot($elem: HTMLElement) {
	return $elem.classList.contains('exercise-desc-image-content__slot')
}
