import {
	UTableHCell,
	UTableHeaderCellSearchContent,
	UTableHRow,
	UTableSearchButton,
} from 'ui/UTable/UTable'
import { StudentsStoreType } from '../../StudentsTableCurator/zustand/storeTypes'
import {
	getClearSearchValueFn,
	useIsClearButtonVisible,
} from './fn/clearSearchValue'
import FullNameForm from '../forms/FullNameForm/FullNameForm'
import TrainingNameForm from '../forms/TrainingNameForm/TrainingNameForm'
import GroupNameForm from '../forms/PhoneForm/GroupNameForm'
import StatusForm from '../forms/StatusForm/StatusForm'

// Заголовочный ряд таблицы учеников
function TableHeader() {
	// Видимы ли кнопки очистки значений поиска по колонке
	const {
		isFullNameClearBtnOpen,
		isTrainingNameClearBtnOpen,
		isGroupNameClearBtnOpen,
		isStatusesClearBtnOpen,
	} = useIsClearButtonVisible()

	const clearFullName = getClearSearchValueFn(
		StudentsStoreType.SearchValueNames.FullName
	)
	const clearTrainingName = getClearSearchValueFn(
		StudentsStoreType.SearchValueNames.TrainingName
	)
	const clearGroupName = getClearSearchValueFn(
		StudentsStoreType.SearchValueNames.GroupName
	)
	const clearStatus = getClearSearchValueFn(
		StudentsStoreType.SearchValueNames.Status
	)

	return (
		<UTableHRow>
			<UTableHCell>
				<UTableHeaderCellSearchContent
					text="Имя и фамилия"
					isClearBtnVisible={isFullNameClearBtnOpen}
					onClearBtnClick={clearFullName}
					buttonWithForm={
						<UTableSearchButton ButtonWithForm={<FullNameForm />} />
					}
				/>
			</UTableHCell>
			<UTableHCell>
				<UTableHeaderCellSearchContent
					text="Курс"
					isClearBtnVisible={isTrainingNameClearBtnOpen}
					onClearBtnClick={clearTrainingName}
					buttonWithForm={
						<UTableSearchButton
							ButtonWithForm={<TrainingNameForm />}
						/>
					}
				/>
			</UTableHCell>
			<UTableHCell>
				<UTableHeaderCellSearchContent
					text="Группа"
					isClearBtnVisible={isGroupNameClearBtnOpen}
					onClearBtnClick={clearGroupName}
					buttonWithForm={
						<UTableSearchButton
							ButtonWithForm={<GroupNameForm />}
						/>
					}
				/>
			</UTableHCell>
			<UTableHCell>Начало</UTableHCell>
			<UTableHCell>Конец</UTableHCell>
			<UTableHCell>
				<UTableHeaderCellSearchContent
					text="Статус"
					isClearBtnVisible={isStatusesClearBtnOpen}
					onClearBtnClick={clearStatus}
					buttonWithForm={
						<UTableSearchButton ButtonWithForm={<StatusForm />} />
					}
				/>
			</UTableHCell>
		</UTableHRow>
	)
}

export default TableHeader
