import React, { useEffect } from 'react'
import { UploadFile } from 'antd'
import UploadFileTypes from 'parts/constants/uploadTypes'
import downloadFileToAmazon from 'parts/services/downloadFileToAmazon'
import { GetUploadedFilesType } from '../UploadImage'
import { useIsFirstRender } from 'parts/utils/hooks/hooks'

/**
 * Функция получает массив строк с адресами загруженных файлов
 * и преобразует его в массив данных для компонента загрузки файлов
 * чтобы он показал эти файлы как уже загруженные.
 * @param fileList — массив с адресами загруженных файлов
 */
export function createFileListFromUrlList(fileList: string[]): UploadFile[] {
	return fileList.map((fileSrc, i) => {
		const filePathParts = fileSrc.split('/')
		const fileName = filePathParts[filePathParts.length - 1]

		return {
			uid: i.toString(),
			name: fileName,
			status: 'done',
			url: fileSrc,
		}
	})
}

/**
 * Собственный обработчик загрузки файлов для поля ввода
 * @param setFileList — функция изменяющая массив сведений об изначальных и загруженных файлов
 */
export function useGetMakeRequest(
	setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>
) {
	return async function (e: any) {
		if (!e.target.files) return

		const file = e.target.files[0]

		await downloadFileToAmazon(
			file,
			FileList as any,
			UploadFileTypes.LANDINGS,
			{
				beforeDownloading(fileName: string) {},
				whileDownloading(
					percentCompleted,
					cancelDownloading,
					fileUrl
				) {},
				onSuccess(fileName: string, fileUrl: string) {
					// Поставить загруженный файл в массив загруженных файлов
					setFileList((state) => createFileListFromUrlList([fileUrl]))
				},
				onError(fileName: string) {},
			}
		)
	}
}

/**
 * Запускает функцию принимающую массив загруженных файлов
 * @param getUploadedFiles — функция, принимающая загруженные файлы, чтобы компонент выше смог получить их адреса
 * @param fileList — массив загруженных файлов
 */
export function useSetFileUrlsToGetUploadedFilesFn(
	getUploadedFiles: undefined | GetUploadedFilesType,
	fileList: UploadFile[]
) {
	if (!getUploadedFiles) return

	const isFirstRender = useIsFirstRender()

	useEffect(
		function () {
			if (isFirstRender) return

			getUploadedFiles(fileList)
		},
		[fileList]
	)
}
