import RowsContainer from 'ui/RowsContainer/RowsContainer'
import TrainingCardForStudent from '../trainingCardForStudent/TrainingCardForStudent/TrainingCardForStudent'
import useTrainingsStudentStore from '../zustand/store'

// Список курсов для ученика
function TrainingsListStudent() {
	const trainingsStudent = useTrainingsStudentStore(
		(state) => state.trainingsStudent
	)

	return (
		<RowsContainer>
			{trainingsStudent.map((training) => {
				return (
					<TrainingCardForStudent
						key={training.id}
						training={training}
					/>
				)
			})}
		</RowsContainer>
	)
}

export default TrainingsListStudent
