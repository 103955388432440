import React, { useRef } from 'react'
import {
	ExerciseChangesDataMadeByUser,
	SaveExerciseChangesMadeByUser,
} from '../common/useLiftViewDuration'
import {
	useGetBlurContentHandler,
	useRestoreGapTextsInTextBlockOnMount,
} from './fn/saveAndRestoreExercise'
import {
	getAttemptsText,
	getUnfilledGapsText,
	useCreateInnerHtml,
	useGetResultButtonFn,
	useSetTextsChangeHandler,
	useSetUnfilledGapsNum,
} from './fn/exercise'
import { Button } from 'antd'
import { ExerciseStateType } from './fn/state'
import HtmlEditorContentView from 'ui/HTMLEditorContentView/HTMLEditorContentView'
import { TaskExerciseView } from '../common/TaskExerciseView/TaskExerciseView'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

type ExerciseProps = {
	exercise: TrainingEntityTypes.GapsInWordsExercise
	exerciseState: ExerciseStateType
	setExerciseState: React.Dispatch<React.SetStateAction<ExerciseStateType>>
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

function Exercise(props: ExerciseProps) {
	const {
		exercise,
		exerciseState,
		setExerciseState,
		saveExerciseChangesMadeByUser,
		exerciseRestoreData,
	} = props

	const textsRef = useRef<null | HTMLDivElement>(null)

	// Установка функции отслеживающей ввод текста в пропуски
	useSetTextsChangeHandler(textsRef, exerciseState, setExerciseState)

	// При открытии посчитать количество незаполненных пропусков
	useSetUnfilledGapsNum(textsRef, setExerciseState, exercise)

	// Занимается сохранением состояния упражнения для восстановления при открытии
	const blurContentHandler = useGetBlurContentHandler(
		saveExerciseChangesMadeByUser
	)
	// Занимается восстановлением состояния упражнения при открытии
	useRestoreGapTextsInTextBlockOnMount(textsRef.current, exerciseRestoreData)

	return (
		<>
			{exercise.item.task && (
				<TaskExerciseView htmlString={exercise.item.task} />
			)}
			<div
				className="exercise-gaps-content__text-blocks"
				ref={textsRef}
				onBlur={blurContentHandler}
			>
				{exercise.item.texts.map((textBlock, i) => {
					return <TextBlock htmlString={textBlock.text} key={i} />
				})}
			</div>
			<Bottom
				exerciseState={exerciseState}
				setExerciseState={setExerciseState}
				textsRef={textsRef}
			/>
		</>
	)
}

export default Exercise

type TextBlockProps = {
	htmlString: string
}

// Блок текста с пропусками
function TextBlock(props: TextBlockProps) {
	const { htmlString } = props

	const areaRef = useRef(null)
	useCreateInnerHtml(areaRef, htmlString)

	return <HtmlEditorContentView areaRef={areaRef} />
}

type BottomProps = {
	exerciseState: ExerciseStateType
	setExerciseState: React.Dispatch<React.SetStateAction<ExerciseStateType>>
	textsRef: React.MutableRefObject<HTMLDivElement | null>
}

// Нижняя часть упражнения с кнопкой проверки введённых символов
function Bottom(props: BottomProps) {
	const { exerciseState, setExerciseState, textsRef } = props

	const attemptsText = getAttemptsText(exerciseState.attempts)

	const resultButtonFn = useGetResultButtonFn(
		exerciseState,
		setExerciseState,
		textsRef
	)

	return (
		<div className="exercise-gaps-content__bottom">
			<span className="exercise-gaps-content__bottom-attempts">
				{attemptsText}
			</span>
			<div>
				<span className="exercise-gaps-content__bottom-open-gaps">
					{getUnfilledGapsText(exerciseState.unfilledGaps)}
				</span>
				<Button type="primary" onClick={resultButtonFn}>
					{exerciseState.resultButtonText}
				</Button>
			</div>
		</div>
	)
}
