import { useTranslation } from 'react-i18next'
import { FormStatementType } from './fn/stateType'
import {
	DownloadingFileBlock,
	ExerciseFileDragger,
	SuccessDownloadedFileBlock,
} from '../common/FormFileInput/FormFileInput'
import { RcFile } from 'antd/lib/upload/interface'
import {
	beforeUploadFile,
	removeDownloadedFile,
	setFileDownloadedStatusToDefault,
} from './fn/changeForms'

type DownloadImageInputProps = {
	statementData: FormStatementType
}

// Различные варианты вида кнопки загрузки файла
export function DownloadImageInput(props: DownloadImageInputProps) {
	const { statementData } = props
	const { status, progress, fileName, link } = statementData.image

	const { t } = useTranslation()

	// Если ещё не указывали файл для загрузки
	if (status == 'empty') {
		return (
			<ExerciseFileDragger
				acceptFileExe=".jpg,.jpeg,.png"
				beforeUpload={(file: RcFile, FileList: RcFile[]) => {
					return beforeUploadFile(file, FileList, statementData.id)
				}}
				hintText="Нажмите или перетащите изображение в эту область "
				size="small"
			/>
		)
	} else if (status == 'downloading') {
		// Если идёт загрузка указанного файла
		return (
			<DownloadingFileBlock
				fileLink={link}
				fileName={fileName}
				progress={progress}
				cancelDownloading={() =>
					setFileDownloadedStatusToDefault(statementData.id)
				}
			/>
		)
	} else if (status == 'success') {
		// Успешная загрузка файла
		return (
			<SuccessDownloadedFileBlock
				fileName={fileName}
				removeFile={() => removeDownloadedFile(statementData)}
				imageSrc={link}
			/>
		)
	} else {
		// Неудачная загрузка файла
		return (
			<p className="exercise-true-false-image-error-upload__text">
				Не удалось загрузить файл {fileName}
			</p>
		)
	}
}
