import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Input, InputRef, Tag, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import {
	manageEditInput,
	manageNewInput,
	useGetHandleClose,
} from './fn/WrongAnswers-func'
import './WrongAnswers.scss'

type WrongAnswersProps = {
	tags: string[] // Массив элементов
	setTags: (tags: string[]) => void // Функция принимающая массив элементов
}

function WrongAnswers(props: WrongAnswersProps) {
	const { tags, setTags } = props

	const { t } = useTranslation()

	const {
		inputRef,
		inputVisible,
		inputValue,
		setInputValue,
		showInput,
		handleInputChange,
		handleInputConfirm,
	} = manageNewInput(tags, setTags)

	const {
		editInputRef,
		editInputIndex,
		setEditInputIndex,
		editInputValue,
		setEditInputValue,
		handleEditInputConfirm,
		handleEditInputChange,
	} = manageEditInput(tags, setTags, inputValue, setInputValue)

	const handleClose = useGetHandleClose(tags, setTags)

	return (
		<>
			<label className="exercise-drop-words__label exercise-drop-words__label--top-margin">
				Неправильные ответы
			</label>
			{tags.map((tag, index) => {
				if (editInputIndex === index) {
					return (
						<EditableInput
							key={index}
							inputRef={editInputRef}
							value={editInputValue}
							onChange={handleEditInputChange}
							handleEditInputConfirm={handleEditInputConfirm}
						/>
					)
				}

				const isLongTag = tag.length > 20

				const tagElem = (
					<Tag
						className="exercise-tags__tag"
						key={tag}
						onClose={() => handleClose(tag)}
					>
						<span
							onDoubleClick={(e) => {
								if (index !== 0) {
									e.preventDefault()
									setEditInputIndex(index)
									setEditInputValue(tag)
								}
							}}
						>
							{isLongTag ? `${tag.slice(0, 20)}...` : tag}
						</span>
					</Tag>
				)

				return isLongTag ? (
					<Tooltip title={tag} key={tag}>
						{tagElem}
					</Tooltip>
				) : (
					tagElem
				)
			})}
			{inputVisible && (
				<EditableInput
					inputRef={inputRef}
					value={inputValue}
					onChange={handleInputChange}
					handleEditInputConfirm={handleInputConfirm}
				/>
			)}
			{!inputVisible && (
				<Tag className="exercise-tags__plus" onClick={showInput}>
					<PlusOutlined /> Добавить
				</Tag>
			)}
		</>
	)
}

export default WrongAnswers

type EditableInputProps = {
	inputRef: React.RefObject<InputRef>
	value: string
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	handleEditInputConfirm: () => void
}

function EditableInput(props: EditableInputProps) {
	const { inputRef, value, onChange, handleEditInputConfirm } = props

	return (
		<Input
			ref={inputRef}
			type="text"
			size="small"
			className="exercise-tags__input"
			value={value}
			onChange={onChange}
			onBlur={handleEditInputConfirm}
			onPressEnter={handleEditInputConfirm}
		/>
	)
}
