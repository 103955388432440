import dayjs from 'dayjs'

/**
 * Возвращает дату ответа
 * @param createdAt — строка с датой ответа
 */
export function getAnswerDate(createdAt: string) {
	const now = new Date()
	const currentDate = dayjs(now)

	return currentDate.diff(createdAt, 'day') > 1
		? dayjs(createdAt).locale('ru').format('DD MMMM YYYY в HH:MM')
		: dayjs(createdAt).locale('ru').fromNow()
}
