import React from 'react'
import { Button } from 'antd'
import AppUrls from 'parts/constants/pageUrl'
import { useGoToPage } from 'parts/utils/hooks/hooks'
import { useTranslation } from 'react-i18next'
import s from './FinishPageContent.module.scss'

function FinishPageContent() {
	const { t } = useTranslation()
	const goToLoginPage = useGoToPage(AppUrls.Login().url)

	return (
		<div className={s.wrapper}>
			<Button type="primary" onClick={goToLoginPage}>
				{t('auth.finishRecoverPasswordLoginLink')}
			</Button>
		</div>
	)
}

export default FinishPageContent
