import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import ScreenWrapper from '../ScreenWrapper/ScreenWrapper'
import { useGetOnButtonClick } from './fn/registerUser'
import './AccountCreatedScreen.scss'

function AccountCreatedScreen() {
	const { t } = useTranslation()
	const onButtonClick = useGetOnButtonClick()

	return (
		<ScreenWrapper
			header={t('live.liveEntranceAccountCreatedScreenHeader')}
			illustration
		>
			<div className="live-lesson-creation__content">
				<p className="live-lesson-creation__tip">
					{t('live.liveEntranceAccountCreatedScreenText')}
				</p>
				<Button
					block
					size="large"
					type="primary"
					onClick={onButtonClick}
				>
					{t('live.liveEntranceAccountCreatedScreenICheckedEmail')}
				</Button>
			</div>
		</ScreenWrapper>
	)
}

export default AccountCreatedScreen
