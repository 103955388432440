import useStudentsStore from '../../../../zustand/store'
import { StudentsStoreType } from '../../../../zustand/storeTypes'

export enum FieldsNames {
	// Поле со строкой поиска
	FullName = 'fullName',
}

export interface FormValues {
	[FieldsNames.FullName]: string
}

/** Обработчик отправки формы поиска по имени */
export function useGetOnFullNameChange() {
	const setSearchValuesProp = useStudentsStore(
		(store) => store.setSearchValuesProp
	)

	return function (values: FormValues) {
		setSearchValuesProp(
			StudentsStoreType.SearchValueNames.FullName,
			values[FieldsNames.FullName]
		)
	}
}
