import { FormInstance } from 'antd'
import { useEffect } from 'react'
import useSetConfirmationCodeStore from '../../SetConfirmationCodeModal/state/store'
import { setErrorsToFields } from 'parts/utils/form'

export enum FieldNames {
	CurrentEmail = 'currentEmail',
	NewEmail = 'newEmail',
	Password = 'password',
}

export type FormValues = {
	[FieldNames.CurrentEmail]: string
	[FieldNames.NewEmail]: string
	[FieldNames.Password]: string
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useSetConfirmationCodeStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
