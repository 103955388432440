import { ColumnsType } from 'antd/es/table'
import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'
import useLivelessonStore from '../../../zustand/store'
import { Button } from 'antd'
import {
	AppstoreAddOutlined,
	CheckOutlined,
	FileTextOutlined,
	FolderOutlined,
} from '@ant-design/icons'
import { humanFileSize } from '../../../fn/liveUtils'
import { ItemAction } from '../components/ItemAction/ItemAction'
import useStorageItemClick from './useStorageItemClick'

const iconStyle = { color: '#2573E6' }

function useLibraryColumns(isLive: boolean) {
	const playlist = useLivelessonStore((store) => store.playlist)
	const addToPlaylist = useLivelessonStore((store) => store.addToPlaylist)
	const removeFromPlaylist = useLivelessonStore(
		(store) => store.removeFromPlaylist
	)
	const { onStorageItemClick } = useStorageItemClick()

	const renderPlaylistButton = (item: LiveLessonEntityTypes.StorageItem) => {
		if (item.fileType.includes('audio')) {
			// если файл добавлен в плейлист
			if (playlist.find((i) => i.id === item.id)) {
				return (
					<Button
						icon={<CheckOutlined style={iconStyle} />}
						onClick={() => removeFromPlaylist(item)}
					>
						Добавлено
					</Button>
				)
			} else {
				return (
					<Button
						icon={<AppstoreAddOutlined style={iconStyle} />}
						onClick={() => addToPlaylist(item)}
					>
						Добавить в плейлист
					</Button>
				)
			}
		}
		// if (item.fileType.includes('video')) {
		// 	return (
		// 		<Button
		// 			icon={<PlayCircleOutlined style={iconStyle} />}
		// 			onClick={handlePlayItem}
		// 		>
		// 			Воспроизвести
		// 		</Button>
		// 	)
		// }

		return null
	}

	const columns: ColumnsType<LiveLessonEntityTypes.StorageItem> = [
		{
			title: 'Имя',
			dataIndex: 'name',
			key: 'name',
			render: (text, record) => (
				<div
					style={{
						display: 'flex',
						gap: '10px',
						alignItems: 'center',
					}}
				>
					{record.isFolder ? (
						<FolderOutlined
							style={{ color: '#ACB4C0', fontSize: '16px' }}
						/>
					) : (
						<FileTextOutlined
							style={{ color: '#ACB4C0', fontSize: '16px' }}
						/>
					)}
					<a onClick={() => onStorageItemClick(record.id)}>{text}</a>
				</div>
			),
		},
		{
			title: '',
			dataIndex: 'playlist',
			key: 'playlist',
			render: (_, row) => renderPlaylistButton(row),
		},
		{
			title: 'Размер',
			width: 100,
			dataIndex: 'size',
			key: 'size',
			render: (_, record) =>
				record.isFolder ? '' : humanFileSize(record.fileSize, true),
		},
		{
			title: 'Тип',
			width: 140,
			dataIndex: 'fileType',
			key: 'fileType',
			render: (text) => (text === 'folder' ? 'Папка' : text),
		},
		{
			title: 'Дата загрузки',
			width: 180,
			key: 'date',
			dataIndex: 'uploadDate',
		},
		{
			title: '',
			width: 60,
			key: 'actions',
			render: (_, record) => (
				<ItemAction storageItem={record} isLive={isLive} />
			),
		},
	]

	return {
		columns: isLive
			? columns
			: columns.filter((col) => col.key !== 'playlist'),
	}
}

export default useLibraryColumns
