import React, { useContext } from 'react'
import { Button, Form, FormInstance, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { StateContext } from '../state/stateContext'
import {
	UFormNewBottomButtonsAfterGray,
	UFormNewButtonsContainer,
	UFormNewGeneralError,
	UFormNewInputCaption,
	UFormNewRow,
} from 'ui/UForm/UForm'
import ModalWithForm from 'ui/ModalWithForm/ModalWithForm'
import { useManageForm } from './fn/submit'
import { useGetCloseDeleteSchoolModal } from './fn/openCloseRemoveSchoolModal'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'

function RemoveSchoolModal() {
	const { t } = useTranslation()
	const [form] = Form.useForm()

	const { state, setState } = useContext(StateContext)

	const closeModal = useGetCloseDeleteSchoolModal()

	const { onSubmit, formErrors } = useManageForm()

	return (
		<ModalWithForm
			title={t('mySchools.deleteSchoolModalHeader')}
			open={state.isRemoveSchoolModalOpen}
			onCancel={closeModal}
		>
			<Form
				form={form}
				name="removeSchool"
				onFinish={onSubmit}
				layout="vertical"
			>
				<GrayBlock>
					<SchoolNameInput form={form} />
				</GrayBlock>
				<Bottom form={form} />
				<UFormNewGeneralError message={formErrors.message} />
			</Form>
		</ModalWithForm>
	)
}

export default RemoveSchoolModal

type SchoolDomainInputProps = {
	form: FormInstance
}

// Обычное текстовое поле
function SchoolNameInput(props: SchoolDomainInputProps) {
	const { form } = props

	const { t } = useTranslation()
	const { state, setState } = useContext(StateContext)
	const schoolName = state.school?.name || ''

	return (
		<>
			<UFormNewRow>
				<Form.Item
					name="schoolName"
					label={
						<span>
							{t('mySchools.deleteSchoolModalFieldNameLabel')} «
							<strong className="bold-600">{schoolName}</strong>»
						</span>
					}
					rules={[
						{
							required: true,
							message: t(
								'mySchools.deleteSchoolModalFieldNameRequired'
							),
						},
						{
							pattern: new RegExp(schoolName),
							message: t(
								'mySchools.deleteSchoolModalFieldNameRequired'
							),
						},
					]}
				>
					<Input type="text" />
				</Form.Item>
			</UFormNewRow>
			<UFormNewInputCaption>
				<span className="error-color">
					{t('mySchools.deleteSchoolModalFieldNameBeAwareFirst')}
				</span>{' '}
				{t('mySchools.deleteSchoolModalFieldNameBeAwareSecond')}
			</UFormNewInputCaption>
		</>
	)
}

type BottomProps = {
	form: FormInstance
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom(props: BottomProps) {
	const { form } = props

	const { t } = useTranslation()
	const { state, setState } = useContext(StateContext)
	const closeModal = useGetCloseDeleteSchoolModal()

	return (
		<UFormNewBottomButtonsAfterGray>
			<div />
			<UFormNewButtonsContainer>
				<Button
					disabled={
						state.removeSchoolSubmitting ||
						form
							.getFieldsError()
							.filter(({ errors }) => errors.length).length > 0
					}
					onClick={closeModal}
				>
					{t('mySchools.deleteSchoolModalCancelButton')}
				</Button>

				<Form.Item shouldUpdate>
					{() => {
						const formErrors = form
							.getFieldsError()
							.filter(({ errors }) => errors.length)

						return (
							<Button
								type="primary"
								htmlType="submit"
								danger
								disabled={
									state.removeSchoolSubmitting ||
									formErrors.length > 0
								}
								loading={state.removeSchoolSubmitting}
							>
								{t('mySchools.deleteSchoolModalOkButton')}
							</Button>
						)
					}}
				</Form.Item>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
