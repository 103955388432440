import TransferStudentsModal from './TransferStudentsModal'
import useTransferStudentsModalStore from './zustand/store'

export const TransferStudentsModalController = {
	element: <TransferStudentsModal />,
	open() {
		const updateStore = useTransferStudentsModalStore.getState().updateState
		updateStore({ isOpen: true })
	},
	close() {
		const updateStore = useTransferStudentsModalStore.getState().updateState
		updateStore({ isOpen: false })
	},
}
