import React from 'react'
import { Button } from 'antd'
import ExerciseStateType from './fn/ExerciseStateType'
import {
	getAttemptsText,
	getUnfilledSlotsText,
	useGetResultButtonFn,
} from './fn/bottom'

type BottomProps = {
	state: ExerciseStateType.Main
	setState: React.Dispatch<React.SetStateAction<ExerciseStateType.Main>>
	textsRef: React.MutableRefObject<HTMLDivElement | null>
}

// Нижняя часть упражнения с кнопкой проверки введённых символов
function Bottom(props: BottomProps) {
	const { state, setState, textsRef } = props

	const attemptsText = getAttemptsText(state.attempts)
	const resultButtonFn = useGetResultButtonFn(state, setState, textsRef)

	return (
		<div className="exercise-desc-image-content__bottom">
			<span className="exercise-desc-image-content__bottom-attempts">
				{attemptsText}
			</span>
			<div>
				<span className="exercise-desc-image-content__bottom-open-gaps">
					{getUnfilledSlotsText(textsRef)}
				</span>
				<Button type="primary" onClick={resultButtonFn}>
					{state.resultButtonText}
				</Button>
			</div>
		</div>
	)
}

export default Bottom
