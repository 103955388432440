import React, { memo } from 'react'
import { Button } from 'antd'
import cn from 'classnames'
import { stateManager } from '../stateManager/stateManager'
import StatementsNavigation from '../StatementsNavigation/StatementsNavigation'
import ExerciseStateType from '../zustand/ExerciseStateType'
import { useTranslation } from 'react-i18next'
import { generateRightAnswerText } from './fn/main'
import { useGetOnTrueFalseButtonClick } from './fn/bottom'
import { TaskExerciseView } from '../../common/TaskExerciseView/TaskExerciseView'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

type ExerciseProps = {
	exercise: TrainingEntityTypes.TrueOrFalseExercise
}

// Упражнение
function Exercise(props: ExerciseProps) {
	const { exercise } = props

	return (
		<div className="exercise-true-false-content">
			{exercise.item.task && (
				<TaskExerciseView htmlString={exercise.item.task} />
			)}
			<StatementsNavigation />
			<Statement />
			<Bottom />
		</div>
	)
}

export default Exercise

function Statement() {
	const statement = stateManager.useGetCurrentStatement()
	if (!statement) return null

	const textClasses = ['exercise-true-false-content__statement-text']
	if (statement.text.length < 120) {
		textClasses.push('exercise-true-false-content__statement-text--big')
	}

	return (
		<div className="exercise-true-false-content__statement">
			<p className={cn(textClasses)}>{statement.text}</p>
			<Image statement={statement} />
			<MemoAnswer statement={statement} />
		</div>
	)
}

type ImageProps = {
	statement: ExerciseStateType.Statement
}

function Image(props: ImageProps) {
	const { statement } = props
	if (!statement.image) return null

	return (
		<img
			className="exercise-true-false-content__statement-image"
			src={statement.image}
			alt=""
		/>
	)
}

type AnswerProps = {
	statement: ExerciseStateType.Statement
}

function Answer(props: AnswerProps) {
	const { statement } = props

	const { t } = useTranslation()

	if (!statement.userAnswer) {
		return null
	}

	if (
		(statement.userAnswer == 'yes' && statement.correct) ||
		(statement.userAnswer == 'not' && !statement.correct)
	) {
		return (
			<p className="exercise-true-false-content__right-statement">
				{generateRightAnswerText()}
			</p>
		)
	} else {
		return (
			<p className="exercise-true-false-content__wrong-statement">
				Ой! Похоже, это неверный ответ
			</p>
		)
	}
}

const MemoAnswer = memo(Answer)

function Bottom() {
	const { t } = useTranslation()

	const statement = stateManager.useGetCurrentStatement()
	const onFalseButtonClick = useGetOnTrueFalseButtonClick('false')
	const onTrueButtonClick = useGetOnTrueFalseButtonClick('true')

	if (!statement) return null

	return (
		<div className="exercise-true-false-content__bottom">
			<div className="exercise-true-false-content__bottom-center">
				<Button
					danger
					onClick={onFalseButtonClick}
					disabled={!!statement.userAnswer}
				>
					Ложь
				</Button>
				<Button
					className="exercise-true-false-content__bottom-true-button"
					onClick={onTrueButtonClick}
					disabled={!!statement.userAnswer}
				>
					Правда
				</Button>
			</div>
		</div>
	)
}
