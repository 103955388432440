import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import AuthPage from '../../../../common/AuthPage/AuthPage'
import ContentWrapper from '../../../../common/ContentWrapper/ContentWrapper'
import AppUrls from 'parts/constants/pageUrl'
import RecoveryCheckForm from '../RecoveryCheckForm/RecoveryCheckForm'

// Страница подтверждения почты
function RecoveryCheckPage() {
	const { t } = useTranslation()

	return (
		<AuthPage>
			<ContentWrapper
				backBthHref={AppUrls.Register().url}
				header={t('auth.recoveryPasswordCheckPageHeader')}
				headerDesc={
					<p>{t('auth.recoveryPasswordCheckPageDescription')}</p>
				}
				bottomText={
					<>
						<Link to={AppUrls.Login().url} className="link">
							{t('auth.recoveryPasswordCheckPageLoginLink')}
						</Link>
						{t('auth.recoveryPasswordCheckPageIfHasAccount')}
					</>
				}
			>
				<RecoveryCheckForm />
			</ContentWrapper>
		</AuthPage>
	)
}

export default RecoveryCheckPage
