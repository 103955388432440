import { useEffect } from 'react'
import { useGoToPage } from 'parts/utils/hooks/hooks'
import AppUrls from 'parts/constants/pageUrl'
import useLiveLessonEntrancePageStore from '../../../zustand/store'

/**
 * Наблюдает за свойством emailCheckStatus.
 * Если оно равно registered, то переадресовывает на страницу входа
 * потому что пользователь уже имеет учётную запись и ему нужно в неё войти.
 */
export function useGoToLoginPageIfEmailIsRegistered() {
	const currentRole = useLiveLessonEntrancePageStore(
		(store) => store.currentRole
	)
	const goToLoginPage = useGoToPage(AppUrls.Login().url)

	useEffect(
		function () {
			if (currentRole !== 'allowedRole') {
				return
			}

			goToLoginPage()
		},
		[currentRole]
	)
}
