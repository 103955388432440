import { useGetToggleVisibilitySubscriptionCanceledModal } from '../../SubscriptionCanceledModal/fn/openCloseSubscriptionCanceledModal'

export function useGetOnRejectButtonClick() {
	const openSubscriptionCanceledModal =
		useGetToggleVisibilitySubscriptionCanceledModal()

	return function () {
		openSubscriptionCanceledModal()
	}
}
