import { message } from 'antd'
import { AxiosError } from 'axios'
import groupRequests from 'parts/requests/group/groupsRequest'
import GroupsApiTypes from 'parts/requests/group/groupsApiTypes'
import ServerTypes from 'parts/types/ServerTypes'
import { useTranslation } from 'react-i18next'
import useAddStaffInGroupModalStore from '../zustand/store'
import { AddStaffModalStoreType } from '../zustand/storeTypes'
import EntityTypes from 'parts/types/EntityTypes'
import { StaffTableAdminController } from '../../staffTable/StaffTable/StaffTableAdminController'
import UrlService from 'parts/services/UrlService'

export function useGetOnSubmit() {
	const { t } = useTranslation()
	const groupId = UrlService.useGetGroupId()

	const [stuff, updateStore] = useAddStaffInGroupModalStore((state) => [
		state.staff,
		state.updateStore,
	])

	const updateGroupStaffTable = StaffTableAdminController.useGetUpdate()

	return async function () {
		if (!stuff) return

		try {
			// Поставить статус загрузки
			updateStore({ isSubmitting: true })

			const checkedStuff = getCheckedStuff(stuff)

			const responses = await makeRequests(groupId, checkedStuff)

			// Послать сигнал, что данные в таблице сотрудников группы устарели
			// и их нужно заново перекачасть.
			updateGroupStaffTable()

			// Закрыть модальное окно и стереть поисковую строку
			updateStore({ isOpen: false })
			updateStore({ search: '' })

			message.success('Сотрудники добавлены.')
		} catch (err) {
			const error = err as AxiosError<ServerTypes.ErrorResponse>

			if (error.response) {
				// Поставить данные ошибки в Состояние, чтобы показать их в форме
				updateStore({ formErrors: error.response.data })
			}

			message.error(
				'Одного или нескольких сотрудников не удалось добавить к этой группе.'
			)
		} finally {
			// Убрать статус загрузки
			updateStore({ isSubmitting: false })
		}
	}
}

function getCheckedStuff(stuff: AddStaffModalStoreType.Employee[]) {
	return stuff.filter((employee) => {
		return employee.addToGroupChecked
	})
}

/**
 * Делает запрос на добавление выбранных сотрудников к группе
 * @param groupId — id группы
 * @param stuff — массив сотрудников
 */
async function makeRequests(
	groupId: number,
	stuff: AddStaffModalStoreType.Employee[]
) {
	const requests = stuff.map((employee) => {
		return groupRequests.addUserToGroup(getDto(groupId, employee))
	})

	return await Promise.all(requests)
}

/**
 * Формирует DTO для отправки запроса
 * @param groupId — id группы
 * @param employee
 */
function getDto(
	groupId: number,
	employee: AddStaffModalStoreType.Employee
): GroupsApiTypes.AddUserToGroupDto {
	return {
		groupId,
		userId: employee.id,
		role:
			employee.type === 'manager'
				? EntityTypes.UserRole.MANAGER
				: EntityTypes.UserRole.CURATOR,
	}
}
