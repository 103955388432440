import { useTranslation } from 'react-i18next'
import { QueryClient, useQueryClient } from 'react-query'
import { message } from 'antd'
import { exercisesQuery } from 'parts/requests/exercises/exercisesQuery'
import { lessonsQuery } from 'parts/requests/lessons/lessonsQuery'
import UrlService from 'parts/services/UrlService'
import useDeleteModalStore from 'pages/modal/DeleteModal/store/store'

/**
 * Хук возвращает функцию, открывающую модальное окно для подтверждения удаления упражнения.
 * @param {Number} exerciseId — id упражнения
 */
export function useGetOpenDeleteExerciseModal(exerciseId: number) {
	const { t } = useTranslation()
	const lessonId = UrlService.useGetLessonId()
	const updateStore = useDeleteModalStore((state) => state.updateStore)

	const deleteExercise = useGetDeleteExercise(lessonId, exerciseId)

	return function () {
		updateStore({
			isOpen: true,
			header: 'Вы действительно хотите удалить упражнение?',
			text: 'Это действие нельзя отменить.',
			onOk: deleteExercise,
		})
	}
}

/**
 * Возвращает функцию удаляющую урок.
 * @param lessonId — id урока, где находится удаляемое упражнение
 * @param exerciseId — id удаляемого упражнения
 */
function useGetDeleteExercise(lessonId: number, exerciseId: number) {
	const queryClient = useQueryClient()

	const deleteExerciseMutation = exercisesQuery.deleteExercise.useMutation({
		onError: useGetMutateOnError(),
		onSuccess: useGetMutateOnSuccess(queryClient, lessonId),
	})

	return function () {
		deleteExerciseMutation.mutate(exerciseId)
	}
}

/** Функция, выполняемая при появлении ошибки после запроса на удаление урока */
function useGetMutateOnError() {
	const { t } = useTranslation()

	return function (err: unknown) {
		message.error('Не удалось удалить упражнение.')
	}
}

/**
 * Функция, выполняемая при успешном запросе удаления урока.
 * @param queryClient — клиент React Query
 * @param lessonId — id урока, из которого удаляют упражнение.
 */
function useGetMutateOnSuccess(queryClient: QueryClient, lessonId: number) {
	const { t } = useTranslation()

	return function () {
		message.success('Упражнение удалёно')

		// Пометить данные курса неактуальными. После этого React Query скачает обновлённые данные.
		queryClient.refetchQueries({
			queryKey: [lessonsQuery.getLesson(lessonId).key],
		})
	}
}
