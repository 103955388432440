import { useGoToPage } from 'parts/utils/hooks/hooks'
import AppUrls from 'parts/constants/pageUrl'

/**
 * Возвращает функция переадресующую на страницу конкретной группы
 * @param trainingId — id курса
 * @param groupId — id группы, на страницу которой нужно переадресовать пользователя
 */
export function useGetGoToGroupPage(trainingId: number, groupId: number) {
	return useGoToPage(
		AppUrls.Training_GroupConditions(trainingId, groupId).url
	)
}
