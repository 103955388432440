import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'
import useLivelessonStore from '../zustand/store'
import { getPdfTab } from './liveUtils'
import { WebSocketService } from 'parts/services/WebSocketService'
import { LIVE_EVENT_TYPES } from '../components/LiveWhiteboard/constants'

function usePdfTab() {
	const setCurrentTab = useLivelessonStore((store) => store.setCurrentTab)
	const addTab = useLivelessonStore((store) => store.addTab)

	return function (
		file: LiveLessonEntityTypes.StorageItem | undefined,
		tabId?: string
	) {
		if (!file) return

		const pdfTab = getPdfTab(file, tabId)

		addTab(pdfTab)
		setCurrentTab(pdfTab.id)

		if (!tabId) {
			WebSocketService.getWebSocketService().sendLiveEvent({
				type: LIVE_EVENT_TYPES.LIVE_SHOW_PDF_DOCUMENT,
				body: {
					track: file,
					tabId: pdfTab.id,
				},
			})
		}

		return pdfTab
	}
}

export default usePdfTab
