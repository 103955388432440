import { useGetToggleVisibilityGiveImpressionModal } from '../../GiveImpressionModal/fn/openCloseGiveImpressionModal'
import { useGetToggleVisibilityTakePauseModal } from './openCloseTakePauseModal'

export function useGetCancelSubscription() {
	const closeTakePauseModal = useGetToggleVisibilityTakePauseModal()
	const openGiveImpressionModal = useGetToggleVisibilityGiveImpressionModal()

	return function () {
		closeTakePauseModal()
		openGiveImpressionModal()
	}
}
