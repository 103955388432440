import { create } from 'zustand'
import { PagesScrollStoreType } from './storeTypes'

// Тут сохраняются данные о прокрутке элементов на странице
const usePagesScrollStore = create<PagesScrollStoreType.State>((set) => {
	return {
		pagesScroll: {},

		/**
		 * Установщик новых значений корневого объекта.
		 * @param obj — объект со свойствами и значениями, которые должны быть изменены в Сотоянии
		 */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default usePagesScrollStore
