import { SyntheticEvent } from 'react'
import { message } from 'antd'
import { AxiosError } from 'axios'
import ServerTypes from 'parts/types/ServerTypes'
import { useTranslation } from 'react-i18next'
import { HorizontalPresetsListController } from 'widgets/presets/horizontalPresetsList/HorizontalPresetsList/HorizontalPresetsListController'
import lessonsRequests from 'parts/requests/lessons/lessonsRequests'

export function useGetCreatePreset(lessonId: number) {
	const { t } = useTranslation()
	const refetchPresets = HorizontalPresetsListController.useGetUpdate()

	return async function (e: SyntheticEvent) {
		// Чтобы предотвратить переход на карточку урока
		e.stopPropagation()

		try {
			const response = await lessonsRequests.makeLessonPreset(lessonId)

			if (response.data) {
				// Показать уведомление о добавлении урока
				message.success(
					t('adminTraining.lessonCardTemplateCreatedMessage')
				)
				refetchPresets()
			}
		} catch (err) {
			const error = err as AxiosError<ServerTypes.ErrorResponse>

			if (error.response) {
				message.error(
					t(
						'adminTraining.lessonCardErrorWhileTemplateCreationMessage'
					)
				)
			}
		}
	}
}
