/**
 * Делит число на 100
 * @param num — число для преобразования
 */

export function divideTo100(num: string | number): number {
	return Math.round(convertToNumber(num) / 100)
}

/**
 * Умножает число на 100
 * @param num — число для преобразования
 */
export function multiplyTo100(num: string | number): number {
	return convertToNumber(num) * 100
}

/**
 * Преобразует значение в число или выбрасывает ошибку
 * @param num — преобразуемое значение
 */
function convertToNumber(num: string | number): number {
	if (num == '0') return 0
	// Может быть любой пробельный символ. Их можно воспринимать как ноль.
	if (typeof num === 'string' && num.trim() === '') return 0

	const number = typeof num === 'string' ? parseInt(num) : num

	if (isNaN(number)) {
		throw new Error('Не удалось преобразовать в число 2')
	}

	return number
}
