import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import { breadcrumbs } from './fn/breadcrumbs'
import { useGetTabs } from './fn/getTabsConfig'
import { useGetHeaderTexts } from './fn/headerAndDescription'

function PageHeaderManagerCurator() {
	const { headerText, descriptionText } = useGetHeaderTexts()
	const tabsConfig = useGetTabs()

	return (
		<PageHeader
			breadcrumbs={breadcrumbs}
			header={headerText}
			description={descriptionText}
			tabs={tabsConfig}
		/>
	)
}

export default PageHeaderManagerCurator
