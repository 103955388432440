import AppUrls from 'parts/constants/pageUrl'
import { PageHeaderTab } from 'ui/pageHeader/Tabs/Tabs'
import RestrictionsService from 'parts/services/RestrictionsService'
import useTrainingHeaderAdminStore from '../../zustand/store'

export function useGetTabs(): PageHeaderTab[] {
	const training = useTrainingHeaderAdminStore((store) => store.training)
	const canUseSiteConstructor = RestrictionsService.useCanUseSiteConstructor()

	if (!training) {
		return []
	}

	const tabs = [
		{
			label: AppUrls.Training(training.id).name,
			address: AppUrls.Training(training.id).url,
		},
	]

	if (canUseSiteConstructor) {
		tabs.push({
			label: AppUrls.Training_Groups(training.id).name,
			address: AppUrls.Training_Groups(training.id).url,
		})
	}

	tabs.push({
		label: AppUrls.Training_LandingConstructor(training.id).name,
		address: AppUrls.Training_LandingConstructor(training.id).url,
	})

	return tabs
}
