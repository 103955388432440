import { create } from 'zustand'
import { EXERCISE_ATTEMPTS } from '../../common/exerciseConst'

// Состояние упражнения, где хранится продолжительность прохождения попыток
// Сделано отдельно потому что не получилось хранить это в конечном автомате.
export namespace ExerciseStateType {
	export type State = {
		stage: 'NotReadyToCheck' | 'ReadyForCheck' | 'Checked' | 'Result'
		// Сколько сделано попыток (независимо от того выполнили ли упражнение или нет)
		attempts: number
		// Сколько раз нажали на кнопку выполнения.
		// Если три раза, то упражнение больше нельзя отмечать выполненным.
		// Если при загрузке упражнения оно отмечено выполненным, то сразу стоит число 3.
		doneCounter: number
		// Статистика на сколько вопросов ответили в каждой попытке
		statistics: Statistic[]
		questions: Question[]
		currentQuestionIdx: number
		durationsInSeconds: number[]
		// Флаг сигнализирующий сохранить состояние на сервере
		// чтобы при следующем открытии упражнения восстанавливать значения.
		// Чтобы состояние сохранилось нужно увеличить значение флага.
		saveStoreForRestore: number

		// Метод изменяющий значение свойств
		updateStore: UpdateStore
	}

	export type Statistic = {
		// На сколько вопросов ученик дал ответ
		passedQuestions: number
	}

	export type Question = {
		id: number
		text: string
		audio: string // Ссылка на загруженный звуковой файл
		video: string // Ссылка на загруженное видео
		videoLink: string // Ссылка на видео на внешнем ресурсе (YouTube)
		image: string // Ссылка на загруженное изображение
		multipleSelect: boolean // Возможно ли несколько правильных ответов?
		showCorrect: boolean // Показать ли правильные варианты ответа (закрашивать ли ответы цветной рамочкой)
		disableQuestion: boolean // Заблокирован ли вопрос чтобы нельзя было изменить ответы
		answers: Answer[]
	}

	export type Answer = {
		id: number
		text: string // Текст ответа
		view: 'unknown' | 'wrong' | 'right' // Как показывать элемент ответа: unknown (неизвестно, wrong (неправильный), right (правильный)
		checked: boolean // Отметил ли пользователь этот вариант ответа
		correct: boolean // Правильный ли вариант ответа
	}

	// Установщик новых значений корневого объекта через объект.
	export type UpdateStore = (obj: Partial<State>) => void
}

const useExerciseStore = create<ExerciseStateType.State>((set) => {
	return {
		stage: 'NotReadyToCheck',
		attempts: EXERCISE_ATTEMPTS,
		doneCounter: 0,
		statistics: [],
		questions: [],
		currentQuestionIdx: 0,
		durationsInSeconds: [],
		saveStoreForRestore: 0,

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useExerciseStore
