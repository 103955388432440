import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import AuthPage from '../../../../../common/AuthPage/AuthPage'
import ContentWrapper from '../../../../../common/ContentWrapper/ContentWrapper'
import AppUrls from 'parts/constants/pageUrl'
import { useReturnToStartPage } from '../../common/returnToStartPage'
import MakeSchoolForm from '../MakeSchoolForm/MakeSchoolForm'

// Страница с формой регистрации администратора
function RegisterOwnerSchoolArticle() {
	const { t } = useTranslation()

	// Перебросить на страницу с вводом данных регистрируемого пользователя если в Хранилище их нет.
	// Так может случиться если эту страницу открыли сразу, а не перешли из предыдущей.
	useReturnToStartPage()

	return (
		<AuthPage>
			<ContentWrapper
				backBthHref={AppUrls.Register().url}
				header={AppUrls.Register().name}
				headerDesc={t('auth.schoolCreationPageHeader')}
				bottomText={
					<>
						Уже есть аккаунт{' '}
						<Link to={AppUrls.Login().url} className="link">
							{t('auth.schoolCreationPageLoginLink')}
						</Link>
					</>
				}
			>
				<MakeSchoolForm />
			</ContentWrapper>
		</AuthPage>
	)
}

export default RegisterOwnerSchoolArticle
