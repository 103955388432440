import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import LessonPageHeader from '../LessonPageHeader/LessonPageHeader'
import LessonStudentArticle from '../article/LessonStudentArticle/LessonStudentArticle'

/** Страница урока для ученика */
function LessonStudentPage() {
	return (
		<PageSkeleton>
			<LessonPageHeader />
			<LessonStudentArticle />
		</PageSkeleton>
	)
}

export default LessonStudentPage
