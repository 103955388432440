import { Publisher, Subscriber } from 'openvidu-browser'
import EntityTypes from './EntityTypes'
import { LIVE_EVENT_TYPES } from 'widgets/livelesson/liveLesson/components/LiveWhiteboard/constants'

namespace LiveLessonEntityTypes {
	// Данные live-урока для администратора
	export type LiveLesson = {
		id: number
		title: string
		scheduledFor: string // "2022-10-11T16:02:13.777Z"
		timeZone: string
		teacher: EntityTypes.User
		teacherId: number
		link: string
		participants: EntityTypes.User[]
		participantsIds: string[]
	}

	export type Participant = {
		id: number
		email: string
		firstName?: string
		lastName?: string
		avatar?: string
		subscriber?: Subscriber | Publisher
		isVideoOn: boolean
		isAudioOn: boolean
		screenSharing?: Subscriber
		isSpeaking: boolean
	}

	export type Tab = {
		id: string
		name: string
		type: TabType
		extra: TabExtra | null
	}

	export type TabExtra = {
		view?: string
		participantId?: number
		track?: LiveLessonEntityTypes.StorageItem
	}

	export enum ViewType {
		SingleView = 'singleView',
		MultiView = 'multiView',
	}

	export enum TabType {
		MeetingRoom = 'meetingRoom',
		WhiteBoard = 'whiteboard',
		Library = 'library',
		Player = 'player',
		ScreenShare = 'screenShare',
		PdfViewer = 'pdfViewer',
		// Tests = 'tests',
		// GoTest = 'goTest',
	}

	export type ChatMessage = {
		my?: boolean
		from: EntityTypes.User
		link?: string
		body: string
		notify?: boolean
	}

	export type LiveEventGetDuration = {
		duration: number
	}

	export type LiveEventAddWhiteboard = {
		whiteboardId: string
		whiteboardName: string
	}

	export type LiveInitialState = {
		tabs: Record<string, string>
		chat: string[]
		isRecording: boolean
	}

	export type LiveScreensharing = {
		participantId: number
		tabId: string
	}

	export type LiveParticipantStateChange = {
		participantId: number
		isCameraOn: boolean
		isMicOn: boolean
	}

	export type LivePlayTrack = {
		track: LiveLessonEntityTypes.StorageItem
		tabId?: string
	}

	export type LiveSeekAudioTrack = {
		position: number
	}

	export type LiveCloseTab = {
		tabId: string
	}

	export type LiveChangeTab = {
		tabId: string
	}

	export type LiveSetDocumentPage = {
		page: number
	}

	export type LiveAddParticipant = {
		participant: Participant
	}

	export type LiveRemoveParticipant = {
		participant: Participant
	}

	export type LiveParticipantsList = {
		participants: Participant[]
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	export type LiveEvent<T = any> = {
		type: LIVE_EVENT_TYPES
		body?: T
	}

	export type StorageItem = {
		id: number
		parentId: number | null
		name: string
		s3link: string
		fileSize: number
		fileType: string
		uploadDate?: string
		ownerId?: number
		isFolder: boolean
	}

	export type StopRecordingResponse = {
		status: string
		duration: number
		size: number
		url: string
	}
}

export default LiveLessonEntityTypes
