import { ReactNode } from 'react'
import cn from 'classnames'
import './SectionWrapper.scss'

type SectionWrapperProps = {
	extraClass?: string
	children: [ReactNode, ReactNode]
	idAttr?: string
}

// Обёртка секции с заголовком и содержимом
function SectionWrapper(props: SectionWrapperProps) {
	const { extraClass, children, idAttr } = props
	const [headerElem, contentElem] = children

	return (
		<section
			className={cn('landing-section-wrapper', extraClass)}
			id={idAttr || ''}
		>
			{headerElem}
			{contentElem}
		</section>
	)
}

export default SectionWrapper
