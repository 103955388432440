import React from 'react'
import HistoryModal from '../common/HistoryModal/HistoryModal'
import { Button, Form, FormInstance } from 'antd'
import { useGetOnRejectButtonClick } from './fn/onRejectButtonClick'
import { useGetToggleVisibilityPresentModal } from './fn/openClosePresentModal'
import usePresentFormStore from './zustand/store'
import s from './PresentModal.module.scss'

function PresentModal() {
	const [form] = Form.useForm()

	const isOpen = usePresentFormStore((store) => store.isOpen)
	const closePresentModal = useGetToggleVisibilityPresentModal()

	return (
		<HistoryModal
			iconSrc="/images/subscriptionsHistory/GiftOutlined.svg"
			title="У нас для вас предложение!"
			open={isOpen}
			footerButtons={[<CancelButton key={1} />, <RejectButton key={2} />]}
			onCancel={closePresentModal}
		>
			<PresentForm form={form} />
		</HistoryModal>
	)
}

export default PresentModal

type ReasonFormProps = {
	form: FormInstance
}

function PresentForm(props: ReasonFormProps) {
	const { form } = props

	return (
		<div>
			<p className={s.topText}>
				Оставайтесь с нами и получите скидку 50% на 3 месяца!
			</p>
			<div className={s.blueBlock}>
				<p className={s.blueBlockTop}>
					Ваше ограниченное по времени предложение
				</p>
				<p className={s.blueBlockBottom}>Скидка 50% на 3 месяца</p>
				<div className={s.blueBlockBottomButton}>
					<Button type="primary" size="large">
						Согласиться с предложением
					</Button>
				</div>
			</div>
		</div>
	)
}

function CancelButton() {
	const onClick = useGetToggleVisibilityPresentModal()

	return (
		<Button type="link" onClick={onClick}>
			Отмена
		</Button>
	)
}

function RejectButton() {
	const onClick = useGetOnRejectButtonClick()

	return <Button onClick={onClick}>Отвергнуть предложение</Button>
}
