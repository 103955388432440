import React from 'react'
import ExerciseStateType from './fn/ExerciseStateType'

type SlotProps = {
	descObj: ExerciseStateType.DescriptionData
}

// Место, куда можно сбрасывать перетаскиваемые описания
export function Slot(props: SlotProps) {
	const { descObj } = props

	return (
		<>
			<div
				className="exercise-desc-image-content__slot"
				data-right={descObj.rightWord}
			/>
			<p className="exercise-desc-image-content__right-answer">
				{descObj.rightWord}
			</p>
		</>
	)
}
