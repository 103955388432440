import React from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import HtmlEditor from 'ui/HTMLEditor/HTMLEditor'
import {
	ExerciseChangesDataMadeByUser,
	SaveExerciseChangesMadeByUser,
	WritingOrAudioAnswerWasSentFn,
} from '../../common/useLiftViewDuration'
import {
	useGetOnBlurHandler,
	useRestoreTextAnswerBlockOnMount,
} from './fn/saveAndRestoreTextAnswer'
import {
	useManageAnswerSending,
	useManageEditor,
} from './fn/TextAnswerBlock-func'
import SuccessMessage from '../SuccessMessage/SuccessMessage'
import './TextAnswerBlock.scss'

type TextAnswerBlockProps = {
	// Запуск этой функции сообщает, что ответ был послан
	writingOrAudioAnswerWasSentFn?: WritingOrAudioAnswerWasSentFn
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

// Блок с редактором где нужно написать ответ на аудио-задание
function TextAnswerBlock(props: TextAnswerBlockProps) {
	const {
		writingOrAudioAnswerWasSentFn,
		saveExerciseChangesMadeByUser,
		exerciseRestoreData,
	} = props

	const { t } = useTranslation()

	const { value, onChange } = useManageEditor()
	const { showMessage, onButtonClick } = useManageAnswerSending(
		value,
		writingOrAudioAnswerWasSentFn
	)

	// Занимается сохранением состояния упражнения для восстановления при открытии
	const onBlur = useGetOnBlurHandler(value, saveExerciseChangesMadeByUser)
	// Занимается восстановлением состояния упражнения при открытии
	const restoredEditorContent =
		useRestoreTextAnswerBlockOnMount(exerciseRestoreData)

	if (showMessage) {
		return <SuccessMessage />
	}

	return (
		<>
			<HtmlEditor
				initialValue={value}
				onChangeValue={onChange}
				onBlur={onBlur}
				newValue={restoredEditorContent}
			/>
			<div className="exercise-audio-text-answer__bottom">
				<Button onClick={onButtonClick}>Отправить</Button>
			</div>
		</>
	)
}

export default TextAnswerBlock
