import Loader from '../../../ui/Loader/Loader'
import { schoolQuery } from 'parts/requests/school/schoolQuery'
import MySchoolsList from '../MySchoolsList'
import { useFillInStore } from './fillInStore'
import useSystemStore from 'parts/systemStore/systemStore'

type GetMySchoolsListRouterReturn = {
	status: 'loading' | 'hasSchools' | 'noSchools' | 'unknown'
	element: JSX.Element
}

/** Хук возвращающий статус компонента MySchoolsList и сам компонент в зависимости от статуса */
export function useGetMySchoolsListRouter(): GetMySchoolsListRouterReturn {
	const user = useSystemStore((store) => store.user)

	// Получить список школ и текущую школу
	const schoolsQuery = schoolQuery.getSchoolsByUser.useQuery(user.id)
	const currentSchoolQuery = schoolQuery.getCurrentSchool.useQuery()

	useFillInStore(user.id)

	if (
		schoolsQuery.status == 'loading' ||
		currentSchoolQuery.status == 'loading'
	) {
		return {
			status: 'loading',
			element: <Loader />,
		}
	} else if (
		schoolsQuery.status == 'success' &&
		currentSchoolQuery.status == 'success'
	) {
		if (!schoolsQuery.data || !currentSchoolQuery.data) {
			return unknownObj
		}

		if (schoolsQuery.data.data.length) {
			return {
				status: 'hasSchools',
				element: <MySchoolsList />,
			}
		} else {
			return {
				status: 'noSchools',
				element: <p>noSchools</p>,
			}
		}
	} else {
		return unknownObj
	}
}

const unknownObj: GetMySchoolsListRouterReturn = {
	status: 'unknown',
	element: <p>unknown</p>,
}
