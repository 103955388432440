import { ReactNode } from 'react'
// import { Button } from 'antd'
// import cn from 'classnames'
import ControlsFrame from 'pages/landing/landingConstructor/details/ControlsFrame/ControlsFrame'
import { LandingTypes } from 'parts/types/LandingTypes'
import EditableText from 'pages/landing/landingConstructor/details/editableElems/EditableText/EditableText'
import EditableImage from 'pages/landing/landingConstructor/details/editableElems/EditableImage/EditableImage'
import programBlocksManager from 'pages/landing/landingConstructor/sections/programSection/programBlocksManager/programBlocksManager'
import 'pages/landing/landingConstructor/sections/programSection/ProgramBlock/ProgramBlock.scss'

type BlockOuterProps = {
	block: LandingTypes.ProgramBlock
	children: ReactNode
}

export function BlockOuter(props: BlockOuterProps) {
	const { block, children } = props

	const addNewBlockInTop = programBlocksManager.useGetAddNewBlock(
		block.id,
		block.columnNum,
		'top'
	)
	const addNewBlockInBottom = programBlocksManager.useGetAddNewBlock(
		block.id,
		block.columnNum,
		'bottom'
	)
	const deleteBlock = programBlocksManager.useGetDeleteBlock(block.id)

	return (
		<ControlsFrame
			topAddBtnHandler={addNewBlockInTop}
			bottomAddBtnHandler={addNewBlockInBottom}
			deleteBtnHandler={deleteBlock}
		>
			{children}
		</ControlsFrame>
	)
}

type BlockProps = {
	blockIdx: number
	block: LandingTypes.ProgramBlock
}

export function Block(props: BlockProps) {
	const { blockIdx, block } = props

	return (
		<article className="land-const-program-block">
			<div className="land-const-program-block__img-part">
				<EditableImage
					propsPathArr={[
						'sections',
						'program',
						'blocks',
						blockIdx,
						'imageUrl',
					]}
				/>
			</div>
			<div className="land-const-program-block__text-part">
				<h3 className="land-const-program-block__header">
					<EditableText
						propsPathArr={[
							'sections',
							'program',
							'blocks',
							blockIdx,
							'header',
						]}
						placeholder="Заголовок"
					/>
				</h3>
				<p className="land-const-program-block__text">
					<EditableText
						propsPathArr={[
							'sections',
							'program',
							'blocks',
							blockIdx,
							'text',
						]}
					/>
				</p>
			</div>
		</article>
	)
}
