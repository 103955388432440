import { create } from 'zustand'
import { ErrorPageStoreType } from './storeType'

const useErrorPageStore = create<ErrorPageStoreType.State>((set) => {
	return {
		error: null,

		/**
		 * Установщик новых значений корневого объекта.
		 * @param obj — объект со свойствами и значениями, которые должны быть изменены в Сотоянии
		 */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useErrorPageStore
