import React from 'react'
import { Outlet } from 'react-router-dom'
import MobileMenu from '../menu/MobileMenu/MobileMenu'
import { MemoizedMenuSider } from '../menu/MenuSider/MenuSider'
import './MainPart.scss'

// Обёртка контентных страниц приложения
function MainPart() {
	return (
		<main className="main-part">
			<MobileMenu />
			<aside className="main-part__aside">
				<MemoizedMenuSider />
			</aside>
			<article className="main-part__article">
				<Outlet />
			</article>
		</main>
	)
}

export default MainPart
