import React from 'react'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import { GroupConditionsFormController } from '../groupConditionsForm/ConditionsForm/GroupConditionsFormController'
import UrlService from 'parts/services/UrlService'

// Страница с формой условий прохождения группы
function ConditionsArticle() {
	const trainingId = UrlService.useGetTrainingId()
	const groupId = UrlService.useGetGroupId()

	const groupConditionsFormRouter = GroupConditionsFormController.router(
		trainingId,
		groupId
	)

	return (
		<ArticleContentWrapper center innerWrapperWidth="small">
			{groupConditionsFormRouter.element}
		</ArticleContentWrapper>
	)
}

export default ConditionsArticle
