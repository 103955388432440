import { Avatar, Button } from 'antd'
import cn from 'classnames'
import { BankOutlined, EditOutlined } from '@ant-design/icons'
import { useGetUserRole, useGoToPage } from 'parts/utils/hooks/hooks'
import AppUrls from 'parts/constants/pageUrl'
import { useTranslation } from 'react-i18next'
import {
	UBlockBorderColor,
	UCardBlock,
} from 'ui/wrappers/UCardBlock/UCardBlock'
import { AddSchoolModalController } from 'pages/my/admin/mySchoolsAdmin/AddSchoolModal/AddSchoolModalController'
import {
	CardSticker,
	CardStickerColors,
} from 'ui/wrappers/CardSticker/CardSticker'
import Site from 'parts/constants/site'
import RestrictionsService from '../../parts/services/RestrictionsService'
import CreateNewCard from '../../ui/CreateNewCard/CreateNewCard'
import useMakeOrderStore from './zustand/store'
import { MySchoolsListStoreType } from './zustand/storeTypes'
import './MySchoolsList.scss'

// Список школ пользователя
function MySchoolsList() {
	const userRoles = useGetUserRole()
	const schools = useMakeOrderStore((state) => state.schools)

	const canCreateNewSchool = RestrictionsService.useCanCreateNewSchool()

	return (
		<div className="my-schools">
			{schools.map((school) => {
				return school.current ? (
					<CardSticker
						text="Вы в этой школе"
						color={CardStickerColors.Blue}
						key={school.id + 1}
					>
						<SchoolCard school={school} />
					</CardSticker>
				) : (
					<SchoolCard school={school} key={school.id} />
				)
			})}
			{userRoles.isAdmin && canCreateNewSchool && <CreateNewSchoolCard />}
		</div>
	)
}

export default MySchoolsList

type SchoolCardProps = {
	school: MySchoolsListStoreType.School
}

function SchoolCard(props: SchoolCardProps) {
	const { school } = props

	const { t } = useTranslation()
	const userRoles = useGetUserRole()
	const goToEditSchoolPage = useGoToPage(AppUrls.MySchool(school.id).url)

	return (
		<UCardBlock
			extraClass={cn('my-schools__card')}
			borderColor={
				school.current ? UBlockBorderColor.Blue : UBlockBorderColor.Gray
			}
		>
			<Avatar
				size={80}
				icon={<BankOutlined />}
				className="my-schools__card-avatar"
				src={school.cover}
			/>
			<p className="my-schools__card-domain">{school.domain}</p>
			<p className="my-schools__card-name">{school.name}</p>
			<div className="my-schools__card-go-to-school-wrapper">
				<GotoSchoolButton school={school} />
			</div>
			{userRoles.isAdmin && (
				<Button
					type="text"
					icon={<EditOutlined />}
					block
					onClick={goToEditSchoolPage}
				>
					Редактировать
				</Button>
			)}
		</UCardBlock>
	)
}

function GotoSchoolButton(props: SchoolCardProps) {
	const { school } = props

	const { t } = useTranslation()
	const goToMainPage = useGoToPage(AppUrls.Main().url)

	if (school.current) {
		return (
			<Button type="primary" block onClick={goToMainPage}>
				На главную страницу
			</Button>
		)
	} else {
		return (
			<a
				href={Site.protocol + school.domain + AppUrls.Main().url}
				className="my-schools__card-go-to-school-link"
			>
				<Button type="primary" ghost block>
					Перейти в школу
				</Button>
			</a>
		)
	}
}

function CreateNewSchoolCard() {
	const { t } = useTranslation()

	return (
		<CreateNewCard
			onClick={AddSchoolModalController.open}
			imgSrc="/images/illustrations/1.svg"
			text="Добавить школу"
		/>
	)
}
