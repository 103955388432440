const studentTrainingEn = {
	// Экран с текстом про отсутствие уроков
	noLessonsScreen: 'No lessons',
	// Сообщение при ошибке при получении данных курса
	errorWhileDownloadTraining: 'There was an error in receiving data.',

	// ЭКРАН ПРО ОТСУТСТВИЕ КУРСА
	// Заголовок экрана
	noTrainingScreenHeader: "The course doesn't exist",
	noTrainingScreenText:
		'It has been deleted or an incorrect address has been entered',
	noTrainingScreenGoToTrainingsBtn: 'Go to My Courses',

	// КАРТОЧКА УРОКА
	// Подсказка про недоступный урок
	lessonCardForbiddenLessonAlt: 'The lesson is unavailable',

	// ЗАГОЛОВОК СТРАНИЦЫ
	// Статус курса: Ожидание открытия первого урока
	trainingHeaderStatusWaiting: 'Waiting for the first lesson to open',
	// Статус курса: В процессе
	trainingHeaderStatusProcess: 'In process',
	// Статус курса: Группа завершена
	trainingHeaderStatusGroupFinished: 'The group is complete',
	// Статус курса: Курс недоступен для просмотра
	trainingHeaderStatusForbidden: 'The course is not available for viewing',
	// Фраза «Следующий урок» после которой идёт дата следующего урока. Пробел справа обязателен.
	trainingHeaderNextLessonPhrase: 'Next lesson',
	// Фраза «Осталось выполнить» после которой идёт количество оставшихся уроков. Пробел справа обязателен.
	trainingHeaderRestLessonsNumPhrase: 'There are lessons to be learned',
}

export default studentTrainingEn
