import { useEffect } from 'react'
import useEmployeeStore from '../../zustand/store'
import userRequests from 'parts/requests/user/userRequest'
import { EmployeeStoreType } from '../../zustand/storeTypes'
import UrlService from 'parts/services/UrlService'

/** Получает данные о выбранном ученике и помещает в Зустанд */
export function useFillInStore() {
	const employeeId = UrlService.useGetEmployeeId()
	const updateStore = useEmployeeStore((store) => store.updateStore)

	useEffect(
		function () {
			if (!employeeId) return

			getEmployeeDataFromBDAndSetToStore(employeeId, updateStore)
		},
		[employeeId]
	)
}

/**
 * Загружает данные ученика по переданному идентификатору и ставит в Хранилище.
 * @param {Number} employeeId — id сотрудника
 * @param updateStore — функция обновляющая значения свойств Хранилища.
 */
async function getEmployeeDataFromBDAndSetToStore(
	employeeId: string | number,
	updateStore: EmployeeStoreType.UpdateStore
) {
	try {
		const response = await userRequests.getUser(employeeId)

		if (response.status === 200) {
			updateStore({ employee: response.data })
		}
	} catch (err) {
		updateStore({ employee: null })
	}
}
