const liveRu = {
	// СТРАНИЦА ВХОДА В ЭФИР
	liveEntrancePageWrongLink: 'Неверный адрес входа в эфир урока',

	// ЭКРАН «ПАРОЛЬ СОЗДАН»
	// Заголовок экрана
	liveEntranceAccountCreatedScreenHeader:
		'Ваш аккаунт создан, проверьте почту',
	// Сообщение «Логин и пароль для доступа в личный кабинет мы отправили на почту, которую вы указали.»
	liveEntranceAccountCreatedScreenText:
		'Логин и пароль для доступа в личный кабинет мы отправили на почту, которую вы указали.',
	// Сообщение «Я проверил почту, перехожу к уроку»
	liveEntranceAccountCreatedScreenICheckedEmail:
		'Я проверил почту, перехожу к уроку',

	// ЭКРАН СООБЩАЮЩИЙ, ЧТО СОТРУДНИКУ ДРУГОЙ ШКОЛЫ НЕЛЬЗЯ ПОПАСТЬ В ЭФИР
	// Заголовок экрана
	liveEntranceСantAccessScreenHeader: 'Вы не можете попасть на этот урок :(',
	// Описание экрана
	liveEntranceСantAccessScreenDescription:
		'Вы зарегистрированы в другой школе как администратор, куратор или менеджер. Для входа на урок в этой школе вам нужно зарегистрироваться как студент с помощью новой электронной почты.',
	// Кнопка «Зарегистрироваться как студент»
	liveEntranceСantAccessScreenRegBtn: 'Зарегистрироваться как студент',

	// ЭКРАН С ФОРМОЙ ВВОДА ПОЧТЫ
	// Заголовок экрана
	liveEntranceEmailFormScreenHeader: 'Вход на урок',
	// Описание экрана
	liveEntranceEmailFormScreenDescription:
		'Для доступа на урок введите свою электронную почту.',
	// Поле ввода почты: подпись
	liveEntranceEmailFormInputEmailLabel: 'Почта',
	// Поле ввода почты: сообщение про неправильный формат введённой почты
	liveEntranceEmailFormInputEmailWrongEmail:
		'Почта в неправильном формате. Например: mail@mail.com',
	// Поле ввода почты: сообщение про обязательное поле
	liveEntranceEmailFormInputEmailRequired: 'Введите почту.',
	// Кнопка входа на урок
	liveEntranceEmailFormOkBtn: 'Войти на урок',

	// ЭКРАН С СООБЩЕНИЕМ ОБ ОШИБКЕ
	// Заголовок страницы
	liveEntranceErrorScreenHeader: 'Что-то пошло не так :(',
	// Описание страницы
	liveEntranceErrorScreenDescription:
		'Произошла ошибка, попробуйте войти ещё раз. Наша команда уже работает над этой ошибкой',
	// Заголовок страницы
	liveEntranceErrorScreenBtn: 'Попробовать снова',

	// ЭКРАН РЕГИСТРАЦИИ
	// Заголовок экрана
	liveEntranceRegFormScreenHeader: 'Вход на урок',
	// Описание экрана
	liveEntranceRegFormScreenDescription:
		'Для доступа на урок введите своё имя.',
	// Поле ввода почты: подпись
	liveEntranceRegFormInputEmailLabel: 'Почта',
	// Поле ввода почты: сообщение про неправильный формат введённой почты
	liveEntranceRegFormInputEmailWrongEmail:
		'Почта в неправильном формате. Например: mail@mail.com',
	// Поле ввода почты: сообщение про неправильный формат введённой почты
	liveEntranceRegFormInputEmailRequired: 'Введите почту.',
	// Поле ввода имени: подпись
	liveEntranceRegFormInputNameLabel: 'Имя',
	// Поле ввода имени: сообщение про обязательное поле
	liveEntranceRegFormInputNameRequired: 'Введите имя.',
	// Кнопка регистрации
	liveEntranceRegFormOkBtn: 'Войти на урок',
}

export default liveRu
