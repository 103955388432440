import EditableDescription from 'ui/pageHeader/EditableDescription/EditableDescription'
import EditableHeader from 'ui/pageHeader/EditableHeader/EditableHeader'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import AddExerciseButton from '../../AddExerciseButton/AddExerciseButton'
import LessonAvatarUploadInput from '../LessonAvatarUploadInput/LessonAvatarUploadInput'
import { useGetBreadcrumbs } from './fn/breadcrumbs'
import {
	useGetInitialHeaderText,
	useGetSaveHeaderTextOnServerAfterChange,
} from './fn/pageHeader'
import { useFillInStore } from '../zustand/fn/fillStore'

// Заголовок страницы урока для администратора
function LessonPageHeader() {
	useFillInStore()

	const breadcrumbs = useGetBreadcrumbs()
	const { initPageHeader, initPageDescription } = useGetInitialHeaderText()

	const saveHeader = useGetSaveHeaderTextOnServerAfterChange('name')
	const saveDescription =
		useGetSaveHeaderTextOnServerAfterChange('description')

	return (
		<PageHeader
			image={<LessonAvatarUploadInput />}
			breadcrumbs={breadcrumbs}
			header={
				<EditableHeader
					initialValue={initPageHeader}
					afterChange={saveHeader}
				/>
			}
			description={
				<EditableDescription
					initialValue={initPageDescription}
					afterChange={saveDescription}
				/>
			}
			buttons={[<AddExerciseButton key={1} />]}
		/>
	)
}

export default LessonPageHeader
