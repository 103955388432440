import { create } from 'zustand'
import { CondStoreType } from './storeType'
import { FormHelpSections } from '../../FormHelp/fn/dataTypes'

const useCondStore = create<CondStoreType.State>((set) => {
	return {
		dataLoadingStatus: 'loading',
		trainingId: 0,
		group: undefined as any,
		isSubmitting: false,
		formErrors: {},
		helpSection: FormHelpSections.Default,

		/**
		 * Установщик новых значений корневого объекта.
		 * @param obj — объект со свойствами и значениями, которые должны быть изменены в Сотоянии
		 */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useCondStore
