import React, { ReactNode } from 'react'
import './GrayBlock.scss'
import cn from 'classnames'

type GrayBlockProps = {
	padding?: 'small' | 'middle'
	extraClass?: string
	children: ReactNode | ReactNode[]
}

// Серый контейнер для полей формы
export function GrayBlock(props: GrayBlockProps) {
	const { padding = 'small', extraClass = '', children } = props

	return (
		<div
			className={cn(
				'gray-block',
				'gray-block--padding-' + padding,
				extraClass
			)}
		>
			{children}
		</div>
	)
}
