import { Button, Input } from 'antd'
import React, { useContext } from 'react'
import { StateContext } from './fn/stateType'
import {
	useGetOnTextareaChange,
	useGetSubmitBtnText,
	useIsSubmitBtnDisabled,
	useIsSubmitBtnLoading,
	useIsTextareaDisabled,
	useManageError,
} from './fn/changeState'
import { useGetOnSubmit } from './fn/onSubmit'

const { TextArea } = Input

type ContentProps = {
	error: null | string
}

// Блок с формой генерирования текста по заданию
function Content(props: ContentProps) {
	const { error } = props

	const { state, setState } = useContext(StateContext)

	useManageError(setState, error)

	return (
		<div className="generator__content">
			<p className="generator__intro-text">{state.introText}</p>
			<div className="generator__examples">
				{state.examples.map((example, i) => {
					return (
						<p className="generator__example" key={i}>
							{example}
						</p>
					)
				})}
			</div>
			{state.bottomText && (
				<p className="generator__intro-text">{state.bottomText}</p>
			)}
			<form className="generator__form">
				<TextAreaInput />
				<SubmitButton />
				<ErrorMessage />
			</form>
		</div>
	)
}

export default Content

// Поле для ввода задания на генерирование текста
function TextAreaInput() {
	const { state } = useContext(StateContext)

	const onTextareaChange = useGetOnTextareaChange()
	const isDisabled = useIsTextareaDisabled()

	return (
		<TextArea
			rows={2}
			onChange={(value) => onTextareaChange(value)}
			disabled={isDisabled}
			defaultValue={state.promptText}
		/>
	)
}

// Кнопка генерирования текста по заданию
function SubmitButton() {
	const text = useGetSubmitBtnText()
	const isDisabled = useIsSubmitBtnDisabled()
	const isLoading = useIsSubmitBtnLoading()
	const onSubmit = useGetOnSubmit()

	return (
		<div className="generator__form-bottom">
			<Button
				disabled={isDisabled}
				onClick={onSubmit}
				loading={isLoading}
			>
				{text}
			</Button>
		</div>
	)
}

function ErrorMessage() {
	const { state, setState } = useContext(StateContext)

	if (!state.error) return null

	return <p className="generator__error">{state.error}</p>
}
