const employeeEn = {
	// ФОРМА ИЗМЕНЕНИЯ ДАННЫХ СОТРУДНИКА
	// Заголовок формы
	employeeFormHeader: 'Employee data',

	// Подпись поля «Должность»
	employeeFormRoleLabel: 'Position',
	// Сообщение про необходимость указания значения в поле «Должность»
	employeeFormRoleInputRequired: 'Indicate position',
	// Пункт «Не выбрана» выпадающего списка «Должность»
	employeeFormRoleInputNotSelect: 'Not selected',
	// Пункт «Куратор» выпадающего списка «Должность»
	employeeFormRoleInputCurator: 'Curator',
	// Пункт «Менеджер» выпадающего списка «Должность»
	employeeFormRoleInputManager: 'Manager',
	// Подпись поля «Имя»
	employeeFormNameLabel: 'Name',
	// Подпись поля «Фамилия»
	employeeFormLastNameLabel: 'Surname',
	// Подпись поля «Страна»
	employeeFormCountryLabel: 'Country',
	// Подпись поля «Город»
	employeeFormCityLabel: 'City',
	// Подпись поля «Телефон»
	employeeFormPhoneLabel: 'Phone number',
	// Кнопка «Изменение почты...»
	employeeFormChangeEmailBtn: 'Mail change...',
	// Кнопка «Отменить»
	employeeFormCancelBtn: 'Cancel',
	// Кнопка «Сохранить»
	employeeFormOkBtn: 'Save',
	// Сообщение «Данные сохранены» после отправки формы
	employeeFormDataWasSaved: 'Data saved',

	// ВЫПАДАЮЩИЙ СПИСОК ДЕЙСТВИЙ НАД СОТРУДНИКОМ
	// Заголовочный пункт «Действия» выпадающего списка
	actionListHeaderItem: 'Actions',
	// Пункт выпадающего списка «Отправить доступ»
	actionListItemSendAccess: 'Send access',
	// Пункт выпадающего списка «Отправить доступ»
	actionListItemEdit: 'Edit',
	// Пункт выпадающего списка «Удалить»
	actionListItemDelete: 'Delete',
}

export default employeeEn
