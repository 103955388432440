import React from 'react'
import NoContent from 'ui/NoContent/NoContent'
import AppUrls from 'parts/constants/pageUrl'

function NoLessonContent() {
	return (
		<NoContent
			header="Урок не существует"
			text="Он был удалён или введён неправильный адрес"
			btnConf={{
				text: 'Перейти на Мои курсы',
				to: AppUrls.Trainings().url,
			}}
		/>
	)
}

export default NoLessonContent
