import React from 'react'
import { Image } from 'antd'
import './ImageFrame.scss'
import cn from 'classnames'

type ImageFrameProps = {
	src: string
	alt?: string
	extraClass?: string
}

function ImageFrame(props: ImageFrameProps) {
	const { src, alt = 'Image', extraClass } = props

	return (
		<div className={cn('exercise-image', extraClass)}>
			<div className="exercise-image__inner">
				<Image src={src} alt={alt} />
			</div>
		</div>
	)
}

export default ImageFrame
