import { produce } from 'immer'
import useLandingBuilderStore from '../../../zustand/store'
import faqBlocksManager from './faqBlocksManager'

/**
 * Возвращает функцию, меняющую открытость блока с вопросом и ответом
 * @param blockId — id блока
 */
export function toggleBlockVisibility(blockId: number) {
	const [landing, updateStore] = useLandingBuilderStore((s) => [
		s.landing,
		s.updateStore,
	])

	return function () {
		if (!landing) return

		const updatedLanding = produce(landing, (draft) => {
			const blockIdx = faqBlocksManager.getBlockIdx(
				draft.sections.faq.faqBlocks,
				blockId
			)

			const block = draft.sections.faq.faqBlocks[blockIdx]
			block.isOpen = !block.isOpen
		})

		updateStore({ landing: updatedLanding })
	}
}
