import { useEffect } from 'react'
import ReactQuill from 'react-quill'

/**
 * Наблюдает за изменением свойства newValue и заменяет текст в редакторе на это значение
 * @param {Object} reactQuillRef — ссылка на редактор
 * @param {String} newValue — новый контент редактора
 */
export function useWatchAndSetNewValueToEditor(
	reactQuillRef: null | ReactQuill,
	newValue: null | string
) {
	useEffect(
		function () {
			if (!reactQuillRef || !newValue) return
			const editor = reactQuillRef.getEditor()
			reactQuillRef.setEditorContents(editor, newValue)
		},
		[reactQuillRef, newValue]
	)
}
