import useLandingBuilderStore from '../../../../zustand/store'
import BulletsSection from '../BulletsSection/BulletsSection'
import './BulletsSections.scss'

function BulletsSections() {
	const landing = useLandingBuilderStore((store) => store.landing)
	const { bulletsBlocks } = landing.sections.program

	return (
		<div className="landing-prog-bullets-sections">
			{bulletsBlocks.map((bulletBlock, i) => {
				return (
					<BulletsSection
						bulletBlockIdx={i}
						bulletBlock={bulletBlock}
						key={bulletBlock.id}
					/>
				)
			})}
		</div>
	)
}

export default BulletsSections
