import cn from 'classnames'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'
import EditableText from 'pages/landing/landingConstructor/tariffs/card/common/EditableText/EditableText'
import 'pages/landing/landingConstructor/tariffs/card/Description/Description.scss'
import { useGetColorScheme } from '../common/common'

type DescriptionProps = {
	tariff: TariffsStoreType.Tariff
}

// Описание карточки
function Description(props: DescriptionProps) {
	const { tariff } = props

	const classes = ['tariff-card-description']
	if (tariff.isFlashed) {
		classes.push('tariff-card-description--white')
	}

	return (
		<p className={cn(classes)}>
			<EditableText
				tariffInnerId={tariff.innerId}
				propsPathArr={['description']}
				placeholder="Описание"
				afterChange={tariffsManager.setNeedToSave.bind(tariffsManager)}
			/>
		</p>
	)
}

export default Description
