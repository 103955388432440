import { Button } from 'antd'
import cn from 'classnames'
import React from 'react'
import AppUrls from 'parts/constants/pageUrl'
import { useGoToPage } from 'parts/utils/hooks/hooks'
import { getTextsFromUrlQueries } from './fn/getTexts'
import s from './MySubscriptionNotification.module.scss'

type NotificationType = 'fail' | 'success'

type MySubscriptionNotificationProps = {
	notificationType: NotificationType
}

function MySubscriptionNotification(props: MySubscriptionNotificationProps) {
	const { notificationType } = props

	return (
		<div className={s.page}>
			<div className={s.generalWrapper}>
				<PanelSign notificationType={notificationType} />
				<h3 className={s.panelHeader}>
					{notificationType === 'fail'
						? 'Ошибка платежа'
						: 'Платёж успешно завершён'}
				</h3>
				{notificationType === 'fail' ? (
					<FailContent />
				) : (
					<SuccessContent />
				)}
			</div>
		</div>
	)
}

export default MySubscriptionNotification

type PanelSignProps = {
	notificationType: NotificationType
}

function PanelSign(props: PanelSignProps) {
	const { notificationType } = props

	const mapper = {
		fail: (
			<div className={cn(s.signRound, s.signRoundFail)}>
				<img
					src="/images/signs/ExclamationCircleOutlined.svg"
					className={s.signIcon}
					alt=""
				/>
			</div>
		),
		success: (
			<div className={cn(s.signRound, s.signRoundSuccess)}>
				<img
					src="/images/signs/CheckCircleTwoTone.svg"
					className={s.signIcon}
					alt=""
				/>
			</div>
		),
	}

	return mapper[notificationType]
}

function FailContent() {
	const texts = getTextsFromUrlQueries()
	const goToSubscriptionPage = useGoToPage(AppUrls.MySubscription().url)

	return (
		<div className={s.errorWrapper}>
			<p className={s.errorDetailsText}>{texts.errorText}</p>
			<div className={s.errorBottom}>
				<p className={s.errorSupportText}>
					Связаться со службой поддержки
				</p>
				<Button type="primary" onClick={goToSubscriptionPage}>
					Повторить платеж
				</Button>
			</div>
		</div>
	)
}

function SuccessContent() {
	const texts = getTextsFromUrlQueries()
	const goToSubscriptionPage = useGoToPage(AppUrls.MySubscription().url)

	return (
		<div>
			<div className={s.successWrapper}>
				<div className={s.successRow}>
					<p className={s.successParagraphHeader}>
						Дата и время платежа
					</p>
					<p className={s.successParagraphText}>
						{texts.paymentDate}
					</p>
				</div>
				<div className={s.successRow}>
					<p className={s.successParagraphHeader}>Оплаченный план</p>
					<p className={s.successParagraphText}>{texts.planName}</p>
				</div>
				<div className={s.successRow}>
					<p className={s.successParagraphHeader}>Сумма оплаты</p>
					<p className={s.successParagraphText}>{texts.sum}</p>
				</div>
				<div className={s.successRow}>
					<p className={s.successParagraphHeader}>
						Квитанция об оплате
					</p>
					<p className={s.successParagraphText}>
						<a href={texts.invoice}>Скачать квитанцию</a>
					</p>
				</div>
			</div>
			<div className={s.successBottom}>
				<Button type="primary" onClick={goToSubscriptionPage}>
					Продолжить
				</Button>
			</div>
		</div>
	)
}
