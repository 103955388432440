import dataFromJsonFile from './timeZones.json'

export function getTimeZones(): TimeZonesListType[] {
	const listOfUtcStrings = dataFromJsonFile.timezones.reduce(
		(acc: string[], utcZone) => {
			return [...acc, ...utcZone.utc.flat()]
		},
		[]
	)

	return listOfUtcStrings.map((item, i) => {
		return { id: i, value: listOfUtcStrings[i] }
	})
}

type TimeZonesListType = {
	id: number
	value: string
}
