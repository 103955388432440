import React, { MouseEventHandler } from 'react'
import { Button, Input, Upload } from 'antd'
import { RcFile } from 'antd/lib/upload/interface'
import { useTranslation } from 'react-i18next'
import { ModalLayout } from 'ui/ModalLayout/ModalLayout'
import useExercisesListAdminStore from '../../zustand/store'
import {
	isDownloadingFile,
	beforeUploadFile,
	useGetOnChangeTaskInput,
	onChangeVideoInput,
	closeVideoBlock,
	addNewVideoBlock,
	submitHandler,
	setVideoDownloadedStatusToDefault,
	useSetFormData,
	isVideoLinkInputDisabled,
	removeDownloadedFile,
} from './fn/VideoForm-func'
import {
	VideoExerciseFormStateType,
	FormVideoStateItemType,
} from './fn/formState'
import {
	LinkOutlined,
	MinusCircleOutlined,
	PaperClipOutlined,
	PlusCircleOutlined,
} from '@ant-design/icons'
import {
	DownloadingFileBlock,
	SuccessDownloadedFileBlock,
} from '../common/FormFileInput/FormFileInput'
import ExerciseFormFooter from '../common/ExerciseFormFooter/ExerciseFormFooter'
import HtmlEditor from 'ui/HTMLEditor/HTMLEditor'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import './VideoForm.scss'

type VideoFormProps = {
	saveExercise: (args: TrainingEntityTypes.ExerciseItem) => void // Функция сохраняющая данные упражнения на сервере и в Хранилище
	closeModalFn: MouseEventHandler<HTMLElement> // Функция закрытия модального окна
}

// Форма составления видео-упражнения
function VideoForm(props: VideoFormProps) {
	const { saveExercise, closeModalFn } = props

	const { t } = useTranslation()
	useSetFormData()

	const formState = useExercisesListAdminStore(
		(store) => store.formData
	) as VideoExerciseFormStateType
	if (!formState) return null

	return (
		<ModalLayout
			key="1"
			title="Добавление видео"
			cancelBtn={false}
			isModalVisible
			closeModalFn={closeModalFn}
			footer={null}
		>
			<TaskInput formState={formState} />
			{formState.videos.map((videoData) => {
				return (
					<VideoBlock
						key={videoData.id}
						videoData={videoData}
						formState={formState}
					/>
				)
			})}
			<AddVideoBlockButton />
			<ExerciseFormFooter
				formState={formState}
				saveExercise={saveExercise}
				closeModalFn={closeModalFn}
				isSubmitButtonDisabled={
					isDownloadingFile() || formState.isFormInvalid
				}
				submitHandler={() => submitHandler(saveExercise)}
			/>
		</ModalLayout>
	)
}

export default VideoForm

type ExerciseVideoProps = {
	formState: VideoExerciseFormStateType
}

// Поле с текстом задания
function TaskInput(props: ExerciseVideoProps) {
	const { formState } = props

	const { t } = useTranslation()
	const onChangeTaskInput = useGetOnChangeTaskInput()

	return (
		<>
			<label className="exercise-video__label">Задание к видео</label>
			<HtmlEditor
				initialValue={formState.videoTask.value}
				onChangeValue={onChangeTaskInput}
			/>
			<div className="exercise-video__hr" />
		</>
	)
}

type VideoBlockProps = {
	videoData: FormVideoStateItemType
	formState: VideoExerciseFormStateType
}

// Блок с полем ввода ссылки к видео на внешнем ресурсе, кнопкой загрузки и полем ввода названия видео
function VideoBlock(props: VideoBlockProps) {
	const { videoData, formState } = props
	const isLinkInputDisabled = isVideoLinkInputDisabled(videoData)
	const { t } = useTranslation()

	return (
		<>
			<div className="exercise-video-block">
				<div className="exercise-video-block__left">
					<label className="exercise-video__label">
						Ссылка на видео
					</label>
					<Input
						value={videoData.link}
						onChange={(event) =>
							onChangeVideoInput(event, 'videoLink', videoData.id)
						}
						disabled={isLinkInputDisabled}
						prefix={<LinkOutlined />}
					/>
					{videoData.error && (
						<p className="exercise-video__input-error">
							{videoData.error}
						</p>
					)}
					<DownloadInputVariants videoData={videoData} />
					<label className="exercise-video__label">
						Название видео
					</label>
					<Input
						value={videoData.videoName}
						onChange={(event) =>
							onChangeVideoInput(event, 'videoName', videoData.id)
						}
					/>
				</div>
				{formState.videos.length > 1 && (
					<div className="exercise-video-block__right">
						<Button
							shape="circle"
							icon={<MinusCircleOutlined />}
							onClick={(event) =>
								closeVideoBlock(event, videoData.id)
							}
						/>
					</div>
				)}
			</div>
			<div className="exercise-video__hr" />
		</>
	)
}

type DownloadInputVariantsProps = {
	videoData: FormVideoStateItemType
}

// Различные варианты вида кнопки загрузки видео
function DownloadInputVariants(props: DownloadInputVariantsProps) {
	const { videoData } = props
	const { status, progress, fileName } = videoData.downloadFile
	const { t } = useTranslation()

	// Если ещё не указывали видео для загрузки
	if (status == 'empty') {
		return (
			<div className="exercise-video-uploading-btn__wrapper">
				<DownloadInput videoData={videoData} />
			</div>
		)
	}
	// Если идёт загрузка указанного видео
	else if (status == 'downloading') {
		return (
			<DownloadingFileBlock
				className="exercise-video-uploading"
				fileName={fileName}
				progress={progress}
				cancelDownloading={() =>
					setVideoDownloadedStatusToDefault(videoData.id)
				}
			/>
		)
	}
	// Успешная загрузка видео
	else if (status == 'success') {
		return (
			<SuccessDownloadedFileBlock
				className="exercise-video-success-upload"
				fileName={fileName}
				removeFile={() => removeDownloadedFile(videoData.id)}
			/>
		)
	}
	// Неудачная загрузка видео
	else {
		return (
			<div className="exercise-video-error-upload">
				<p className="exercise-video-error-upload__text">
					Не удалось загрузить файл {fileName}
				</p>
				<DownloadInput videoData={videoData} />
			</div>
		)
	}
}

type DownloadInputProps = {
	videoData: FormVideoStateItemType
}

// Кнопка загрузки видео
function DownloadInput(props: DownloadInputProps) {
	const { videoData } = props
	const { t } = useTranslation()

	return (
		<div className="exercise-video-uploading-btn__wrapper">
			<Upload
				name="file"
				showUploadList={false}
				beforeUpload={(file: RcFile, FileList: RcFile[]) =>
					beforeUploadFile(file, FileList, videoData.id)
				}
				accept=".mp4"
			>
				<Button
					icon={<PaperClipOutlined />}
					type="link"
					disabled={!!videoData.link}
				>
					Загрузить из файла
				</Button>
			</Upload>
		</div>
	)
}

// Кнопка добавления нового блока с видео
function AddVideoBlockButton() {
	const { t } = useTranslation()

	return (
		<div className="exercise-video-add-video-block">
			<Button onClick={() => addNewVideoBlock()}>
				<PlusCircleOutlined width={17} height={17} /> Добавить видео
			</Button>
		</div>
	)
}
