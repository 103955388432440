import MediaForm from '../MediaForm/MediaForm'
import TestForm from '../TestForm/TestForm'
import TextForm from '../TextForm/TextForm'
import VideoForm from '../VideoForm/VideoForm'
import { useGetCloseModalFn, useGetSaveExerciseFn } from './fn/saveExercise'
import TrueOrFalseForm from '../TrueOrFalseForm/TrueOrFalseForm'
import GapsInTextForm from '../GapsInTextForm/GapsInTextForm'
import DropWordsForm from '../DropWordsForm/DropWordsForm'
import DescriptionImgForm from '../DescriptionImgForm/DescriptionImgForm'
import useExercisesListAdminStore from '../../zustand/store'

// Наложение на экране показывающее форму редактирования упражнения
function ExercisesFormsBox() {
	const exerciseType = useExercisesListAdminStore(
		(store) => store.exerciseType
	)

	const closeModalFn = useGetCloseModalFn()
	const saveExercise = useGetSaveExerciseFn(closeModalFn)

	return (
		<>
			{exerciseType === 'video' && (
				<VideoForm
					saveExercise={saveExercise}
					closeModalFn={closeModalFn}
				/>
			)}
			{exerciseType === 'audio' && (
				<MediaForm
					saveExercise={saveExercise}
					closeModalFn={closeModalFn}
					exerciseType="audio"
				/>
			)}
			{exerciseType === 'image' && (
				<MediaForm
					saveExercise={saveExercise}
					closeModalFn={closeModalFn}
					exerciseType="image"
				/>
			)}
			{exerciseType === 'text' && (
				<TextForm
					saveExercise={saveExercise}
					closeModalFn={closeModalFn}
				/>
			)}
			{exerciseType === 'test' && (
				<TestForm
					saveExercise={saveExercise}
					closeModalFn={closeModalFn}
				/>
			)}
			{exerciseType === 'gapsInWords' && (
				<GapsInTextForm
					exerciseType="gapsInWords"
					saveExercise={saveExercise}
					closeModalFn={closeModalFn}
				/>
			)}
			{exerciseType === 'trueOrFalse' && (
				<TrueOrFalseForm
					saveExercise={saveExercise}
					closeModalFn={closeModalFn}
				/>
			)}
			{exerciseType === 'chooseWord' && (
				<GapsInTextForm
					exerciseType="chooseWord"
					saveExercise={saveExercise}
					closeModalFn={closeModalFn}
				/>
			)}
			{exerciseType === 'dropWords' && (
				<DropWordsForm
					saveExercise={saveExercise}
					closeModalFn={closeModalFn}
				/>
			)}
			{exerciseType === 'descriptionImg' && (
				<DescriptionImgForm
					saveExercise={saveExercise}
					closeModalFn={closeModalFn}
				/>
			)}
		</>
	)
}

export default ExercisesFormsBox
