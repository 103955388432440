import React, { useState } from 'react'
import { Badge, MenuProps, Menu } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import EntityTypes from 'parts/types/EntityTypes'
import AppUrls from 'parts/constants/pageUrl'
import useAnswersFeedStore from '../../zustand/store'
import styles from './AnswerFeedNavigationMenu.module.scss'

export default function AnswerFeedNavigationMenu() {
	const trainings = useAnswersFeedStore((state) => state.trainingsAdmin)
	const answersFeed = useAnswersFeedStore((state) => state.answersFeed)

	const [counter, setCounter] = useState<Record<number, number>>({})
	const navigate = useNavigate()

	let trainingAnswersCounter: Record<number, number> = {}

	const menuItems = trainings.map((training) => {
		return {
			label: (
				<Badge
					offset={[10, 0]}
					count={
						counter[training.id] > 0 ? counter[training.id] : null
					}
					style={{
						backgroundColor: 'rgba(59, 130, 246, 0.1)',
						color: '#1dabf2',
					}}
				>
					{training?.name}
				</Badge>
			),
			key: training.id,
			children: training.groups.map((group) => {
				const answerCount = answersFeed.reduce((acc, next) => {
					if (
						next.groupId === group.id &&
						next.status !== EntityTypes.AnswerStatus.Checked
					) {
						acc++
					}
					return acc
				}, 0)

				trainingAnswersCounter = {
					...trainingAnswersCounter,
					[training.id]: trainingAnswersCounter[training.id]
						? trainingAnswersCounter[training.id] + answerCount
						: answerCount,
				}

				return {
					label: (
						<div className={styles.MenuItem}>
							<span>{group.name}</span>
							<span>{answerCount}</span>
						</div>
					),
					key: group.id,
				}
			}),
		}
	})

	const onClick: MenuProps['onClick'] = (e) => {
		navigate(AppUrls.AnswersFeedGroup(e.key).url)
	}

	return (
		<Menu
			className={styles.Menu}
			onClick={onClick}
			mode="horizontal"
			items={[
				{
					label: <Link to={AppUrls.AnswersFeed().url}>Все</Link>,
					key: '',
				},
				...menuItems,
			]}
		/>
	)
}
