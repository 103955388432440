import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import { LessonsListAdminWrapperController } from 'widgets/lessonsListAdmin/LessonsListAdminController'
import NoLessonsScreen from '../NoLessonsScreen/NoLessonsScreen'

/** Страница со списком уроков для эфира */
function SchoolLessonsAdminPage() {
	const lessonsListRouter = LessonsListAdminWrapperController.router()

	const isContentInCenter =
		lessonsListRouter.status == 'loading' ||
		lessonsListRouter.status == 'error' ||
		lessonsListRouter.status == 'noLessons'

	return (
		<ArticleContentWrapper center={isContentInCenter}>
			{lessonsListRouter.status === 'noLessons' && <NoLessonsScreen />}
			{(lessonsListRouter.status === 'noLessons' ||
				lessonsListRouter.status === 'hasLessons') &&
				lessonsListRouter.element}
		</ArticleContentWrapper>
	)
}

export default SchoolLessonsAdminPage
