import { useEffect } from 'react'
import { FormInstance } from 'antd'
import { setErrorsToFields } from 'parts/utils/form'
import useConfirmEmailStore from '../zustand/store'

export enum FieldNames {
	ActivationCode = 'activationCode',
}

export type FormValuesType = {
	[FieldNames.ActivationCode]: string
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useConfirmEmailStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
