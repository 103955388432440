import { Form } from 'antd'
import { produce } from 'immer'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import { FieldsNames } from 'pages/landing/landingConstructor/tariffs/panel/payment/MainPaymentForm/fn/form'
import {
	paySystemAndCurrencyAdaptation,
	updateFormAndDataDependOnChangedField,
} from 'pages/landing/landingConstructor/tariffs/panel/common'

/**
 * Возвращает обработчик изменения выпадающего списка
 * @param fieldName — название поля
 */
export function useGetOnSelectChange(fieldName: FieldsNames) {
	const form = Form.useFormInstance()

	return function (newValue: string) {
		const store = tariffsManager.store

		const newTariffs = produce(store.tariffs, (draft) => {
			if (!draft || !store.activeTariffId) return

			// Получить данные тарифа
			const tariff = tariffsManager.findTariff(
				draft,
				store.activeTariffId
			)
			if (!tariff) return

			// Автоматически корректировать выбор платёжной системы или валюты на правильный
			const wasChanges = paySystemAndCurrencyAdaptation(
				tariff.mainPrice,
				form,
				fieldName,
				newValue,
				store.stripeSetupDone
			)

			if (wasChanges) return

			// Если сценарий выше не поставил новые значения в форму и в данные, то поставить на этом шаге
			updateFormAndDataDependOnChangedField(
				form,
				tariff.mainPrice,
				fieldName,
				newValue
			)

			// ЕСЛИ В ФОРМЕ ПОЯВЯТСЯ ДРУГИЕ ПОЛЯ КРОМЕ ПЛАТЁЖНОЙ СИСТЕМЫ И ВАЛЮТЫ,
			// ТО ПРИДЁТСЯ ПИСАТЬ ЛОГИКУ ПО СОХРАНЕНИЮ ИХ ЗНАЧЕНИЙ В ДАННЫХ
		})

		store.updateStore({ tariffs: newTariffs })

		// Сохранить на сервере при изменении выпадающего списка
		tariffsManager.setNeedToSave()
	}
}
