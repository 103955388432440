export enum TextBlockType {
	Text = 'text',
	Alert = 'alert',
	SubTitle = 'subtitle',
	List = 'list',
	Video = 'video',
}

export type LessonContentBlock = {
	content: string | string[]
	blockType: TextBlockType
}

export type LessonTitle = {
	title: string
	subtitle: string
	image: string
}

export type ExpressLesson = {
	no: number
	title: LessonTitle
	// name: string
	blocks: LessonContentBlock[]
}

export type Course = {
	id: string
	title: string
	subtitle: string
	image: string
	lessons: ExpressLesson[]
}
