import { LessonStatus } from 'parts/services/LessonStudentService'
import { TrainingStoreType } from '../../zustand/storeTypes'

/**
 * Возвращает булево значение доступен ли урок для прохождения
 * @param training — курс
 * @param lesson — урок
 * @param isFirstLesson — это первый урок?
 */
export function useIsCardActive(
	training: TrainingStoreType.Training,
	lesson: TrainingStoreType.Lesson,
	isFirstLesson: boolean
) {
	if (training.startDate === 'firstLessonOpening' && !isFirstLesson) {
		return false
	}

	return lesson.status !== LessonStatus.Unavailable
}
