import liveRu from '../ru/liveRu'
import mainRu from '../ru/mainRu'
import adminTrainingEn from './adminTrainingEn'
import answersFeedEn from './answersFeedEn'
import authEn from './authEn'
import employeeEn from './employeeEn'
import courseCreateEn from './courseCreateEn'
import courseTeachEn from './courseTeachEn'
import exercisesEn from './exercisesEn'
import filesEn from './filesEn'
import expressEn from './expressEn'
import leadsEn from './leadsEn'
import mainMenuEn from './mainMenuEn'
import adminTrainingsEn from './adminTrainingsEn'
import myPaymentsEn from './myPaymentsEn'
import mySchoolsEn from './mySchoolsEn'
import staffEn from './staffEn'
import studentEn from './studentEn'
import studentsEn from './studentsEn'
import studentTrainingEn from './studentTrainingEn'
import studentTrainingsEn from './studentTrainingsEn'
import urlEn from './url'

const mainEn: typeof mainRu = {
	url: urlEn,
	mainMenu: mainMenuEn,
	adminTrainings: adminTrainingsEn,
	studentTrainings: studentTrainingsEn,
	adminTraining: adminTrainingEn,
	studentTraining: studentTrainingEn,
	students: studentsEn,
	student: studentEn,
	leads: leadsEn,
	answersFeed: answersFeedEn,
	myPayments: myPaymentsEn,
	mySchools: mySchoolsEn,
	auth: authEn,
	exercises: exercisesEn,
	files: filesEn,
	live: liveRu,
	express: expressEn,
	courseTeach: courseTeachEn,
	courseCreate: courseCreateEn,
	staff: staffEn,
	employee: employeeEn,
}

export default mainEn
