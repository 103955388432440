import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import { ExercisesListAdminController } from 'widgets/exercisesListForAdmin/ExercisesListAdminController'
import NoLessonContent from '../NoLessonContent/NoLessonContent'

// Блок вывода упражнений урока для администратора
function LessonArticle() {
	const exercisesRouter = ExercisesListAdminController.router()

	const inCenter =
		exercisesRouter.status == 'loading' ||
		exercisesRouter.status == 'error' ||
		exercisesRouter.status == 'noExercises'

	return (
		<ArticleContentWrapper center={inCenter}>
			{exercisesRouter.status === 'error' && <NoLessonContent />}
			{(exercisesRouter.status === 'noExercises' ||
				exercisesRouter.status === 'hasExercises') &&
				exercisesRouter.element}
		</ArticleContentWrapper>
	)
}

export default LessonArticle
