import { create } from 'zustand'
import { TrainingStats } from './storeTypes'

// Хранилище с данными для отрисовки статистики прохождения уроков курса учеником
const useTrainingStatsStore = create<TrainingStats.State>((set) => {
	return {
		// Название курса
		trainingName: '',
		// Название группы
		groupName: '',
		lessons: null,

		updateState(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useTrainingStatsStore
