import RowsContainer from 'ui/RowsContainer/RowsContainer'
import useLessonsStudentStore from '../zustand/store'
import LessonCard from '../LessonCard/LessonCard'

function TrainingLessons() {
	const lessons = useLessonsStudentStore((store) => store.lessons)

	return (
		<RowsContainer>
			{lessons.map((lesson, i) => {
				return (
					<LessonCard
						lesson={lesson}
						isFirstLesson={i == 0}
						key={i}
					/>
				)
			})}
		</RowsContainer>
	)
}

export default TrainingLessons
