import { useCallback } from 'react'
import useDeleteModalStore from '../store/store'

/** Хук возвращает функцию открывающую окно с вопросом удалить ли пользователя */
export function useGetSetModalVisible(isVisible: boolean) {
	const updateModalStore = useDeleteModalStore((state) => state.updateStore)

	return useCallback(() => {
		updateModalStore({ isOpen: isVisible })
	}, [])
}

/** Обработчик нажатия на кнопку подтверждения */
export function useGetOnOkHandler() {
	const updateModalStore = useDeleteModalStore((state) => state.updateStore)
	const onOk = useDeleteModalStore((state) => state.onOk)
	const afterOk = useDeleteModalStore((state) => state.afterOk)

	return useCallback(async () => {
		if (!onOk) return

		await onOk()

		if (afterOk) {
			afterOk()
		}

		updateModalStore({ isOpen: false })
	}, [onOk])
}
