import { getRusNounByNumber } from 'parts/utils/string'
import { ResultStatistic } from '../Result'

export function getAttemptText(
	attemptNum: number,
	statsObj: ResultStatistic,
	totalQuestions: number
) {
	const passedNoun = getRusNounByNumber(
		statsObj.passedQuestions,
		' верный',
		'верных',
		'верных'
	)

	return `Попытка ${attemptNum}: ${statsObj.passedQuestions} ${passedNoun} из ${totalQuestions}`
}
