import { useCallback, useEffect, useState } from 'react'
import UrlService from 'parts/services/UrlService'
import { useIsFirstRender } from 'parts/utils/hooks/hooks'
import { SaveExerciseChangesMadeByUser } from '../../../common/useLiftViewDuration'
import useExerciseStore, {
	ExerciseStateType,
} from '../../zustand/exerciseState'

type RestoreDataType = {
	blocksGaps: BlocksSelectRestore
	store: Omit<ExerciseStateType.State, 'updateStore'>
}

// Текстовый блок — это массив строк с выбранными значениями.
// Поэтому текстовые блоки — это массивы массивов строк
type BlocksSelectRestore = string[][]

/**
 * Возвращает обработчик перетаскивания кнопки с текстом в дырку.
 * После формируется объект с текстом брошенных кнопок в дырки и сохраняется на сервере
 * чтобы при открытии упражнения в следующий раз было восстановлено положение кнопок с текстом, которое сделал ученик.
 */
export function useGetInputContentHandler(
	$generalWrapper: null | HTMLDivElement,
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
) {
	const exerciseId = UrlService.useGetExerciseId()
	const store = useExerciseStore((store) => store)

	return useCallback(
		function () {
			if (!$generalWrapper || !saveExerciseChangesMadeByUser) return

			const restoreData = createRestoreData($generalWrapper, store)

			// Передать в функцию, которая сохранит данные на сервере.
			saveExerciseChangesMadeByUser(restoreData)
		},
		[$generalWrapper, exerciseId]
	)
}

export function useSaveStateOnServerForRestore(
	$generalWrapper: null | HTMLDivElement,
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
) {
	// Задержка, чтобы не сохранял данные после открытия страницы
	const isFirstRender = useIsFirstRender(2000)
	const store = useExerciseStore((store) => store)

	useEffect(
		function () {
			if (
				isFirstRender ||
				!$generalWrapper ||
				!saveExerciseChangesMadeByUser
			)
				return

			const restoreData = createRestoreData($generalWrapper, store)

			// Передать в функцию, которая сохранит данные на сервере.
			saveExerciseChangesMadeByUser(restoreData)
		},
		[store.saveStoreForRestore]
	)
}

function createRestoreData(
	$generalWrapper: HTMLDivElement,
	store: ExerciseStateType.State
): RestoreDataType {
	const { updateStore, ...storeClone } = store

	return {
		blocksGaps: getSlotsRestoreObj($generalWrapper),
		store: storeClone,
	}
}

function getSlotsRestoreObj($generalWrapper: HTMLDivElement) {
	const blocksSelectsRestoreObj: BlocksSelectRestore = []

	for (let i = 0; i < $generalWrapper.children.length; i++) {
		// Блок текста с выпадающими списками
		const $textBlock = $generalWrapper.children[i]

		// Выпадающие списки
		const $selectLists: NodeListOf<HTMLSelectElement> =
			$textBlock.querySelectorAll('.exercise-choose-word-content__select')

		for (let j = 0; j < $selectLists.length; j++) {
			if (!blocksSelectsRestoreObj[i]) {
				blocksSelectsRestoreObj.push([])
			}

			blocksSelectsRestoreObj[i].push($selectLists[j].value)
		}
	}

	return blocksSelectsRestoreObj
}

// ==========================

export function useRestoreExercise(
	exerciseRestoreDataPromise: Promise<RestoreDataType>
) {
	const $generalWrapper: null | HTMLDivElement = document.querySelector(
		'.exercise-choose-word-content__text-blocks'
	)

	const textBlocks = useExerciseStore((store) => store.textBlocks)
	const updateStore = useExerciseStore((store) => store.updateStore)

	const [textBlocksIsReady, setTextBlocksIsReady] = useState(false)

	useEffect(
		function () {
			if (!textBlocks) return

			setTextBlocksIsReady(true)
		},
		[textBlocks]
	)

	useEffect(
		function () {
			if (!textBlocksIsReady || !$generalWrapper) return

			exerciseRestoreDataPromise.then((restoreData) => {
				if (!restoreData?.store) return

				// Расставить текст по слотам изображений
				// Автоматически перетащить кнопки в те места, куда они были перетащены в прошлый раз.
				setWordsInSlots($generalWrapper, restoreData.blocksGaps)

				updateStore(restoreData.store)
			})
		},
		[textBlocksIsReady]
	)
}

/**
 *
 * @param $generalWrapper
 * @param restoreData
 */
function setWordsInSlots(
	$generalWrapper: HTMLDivElement,
	restoreData: string[][]
) {
	for (let i = 0; i < $generalWrapper.children.length; i++) {
		// Блок текста с выпадающими списками
		const $textBlock = $generalWrapper.children[i]

		// Выпадающие списки
		const $selects: NodeListOf<HTMLSelectElement> =
			$textBlock.querySelectorAll('.exercise-choose-word-content__select')

		// Перебрать выпадающие списки и установить сохранённые значения
		for (let j = 0; j < $selects.length; j++) {
			// Пропустить если в данных нет текста для этого выпадающего списка
			if (!restoreData[i] || !restoreData[i][j]) continue

			// Поставить сохранённое значение
			$selects[j].value = restoreData[i][j]
		}
	}
}
