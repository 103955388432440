import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import { EmptyBlock } from 'ui/EmptyBlock/EmptyBlock'
import { ExercisesListAdminController } from '../ExercisesListAdminController'

function NoExercises() {
	const { t } = useTranslation()

	return (
		<ArticleContentWrapper center>
			<EmptyBlock
				text={
					<>
						Пока не создано ни одного упражнения.
						<br />
						Ученики ждут вызовов!
					</>
				}
				button={<AddExerciseButton key={1} />}
			/>
		</ArticleContentWrapper>
	)
}

export default NoExercises

// Кнопка добавляющая в урок новое упражнение
function AddExerciseButton() {
	const { t } = useTranslation()

	return (
		<Button
			type="primary"
			icon={<PlusOutlined />}
			onClick={ExercisesListAdminController.openExercisesAside}
		>
			Добавить упражнение
		</Button>
	)
}
