import $api from '../http'
import UserApiTypes from './openAIApiTypes'
import OpenAIApiTypes from './openAIApiTypes'

// Запросы к методам работы с пользователями
const openAIRequest = {
	// Генерирование текста на запрос
	generate(dto: OpenAIApiTypes.GenerateDto) {
		return $api.post<UserApiTypes.Generate>('/api/openai/generated', dto)
	},
}

export default openAIRequest
