import { Navigate, Route } from 'react-router-dom'
import AppUrls from 'parts/constants/pageUrl'
import EntityTypes from 'parts/types/EntityTypes'
import AnswersFeedPage from '../../pages/answersFeed/page/AnswersFeedPage/AnswersFeedPage'
import ExerciseStudentPageNew from '../../pages/exerciseNew/ExerciseStudentPageNew/ExerciseStudentPageNew'
import { LiveRoomsPagesWrapper } from '../../pages/liveLessons/liveRoomsPagesWrapper/LiveRoomsAdminPage/LiveRoomsPagesWrapper'
import LiveRoomsArticle from '../../pages/liveLessons/rooms/LiveRoomsArticle/LiveRoomsArticle'
import {
	getLandingRoute,
	getLiveLessonEntranceRoute,
	getSuccessAndFailRoutes,
	RouterVarNames,
} from './special/otherRoutes'
import ErrorPage from 'pages/duty/ErrorPage/ErrorPage'
import ProtectedRoute from 'ui/ProtectedRoute/ProtectedRoute'
import AppLayout from '../AppLayout/AppLayout'
import MainPart from '../MainPart/MainPart'
import ProfilePage from 'pages/profile/ProfilePage/ProfilePage'
import TrainingsStudentPage from 'pages/trainings/student/TrainingsPage/TrainingsArticle'
import TrainingStudentPage from 'pages/training/student/trainingPage/TrainingStudentPage/TrainingStudentPage'
import ExerciseStudentPage from 'pages/exercise/ExerciseStudentPage/ExerciseStudentPage'
import LessonStudentPage from 'pages/lesson/student/LessonStudentPage/LessonStudentPage'
import MySchoolsArticle from 'pages/my/admin/mySchoolsAdmin/MySchoolsArticle/MySchoolsArticle'
import MySchoolArticle from 'pages/my/admin/mySchoolAdmin/MySchoolArticle/MySchoolArticle'
import MyPageManagerCuratorStudent from 'pages/my/managerCuratorStudent/MyPageManagerCuratorStudent/MyPageManagerCuratorStudent'
import { LiveLessonPage } from 'pages/liveLessons/live/LiveLessonPage'

export default function getStudentRouter(user: null | EntityTypes.User) {
	return (
		<Route>
			{getSuccessAndFailRoutes()}
			{getLandingRoute()}
			{getAppRouterWithLive(user)}
			{getLiveLessonEntranceRoute()}
		</Route>
	)
}

function getAppRouterWithLive(user: null | EntityTypes.User) {
	return (
		<Route>
			<Route
				path={AppUrls.LiveLesson(':' + RouterVarNames.LiveLessonId).url}
				element={
					<ProtectedRoute
						isAllowed={!!user}
						redirectPath={AppUrls.Login().url}
					>
						<LiveLessonPage />
					</ProtectedRoute>
				}
			/>
			{getAppRoutes(user)}
		</Route>
	)
}

function getAppRoutes(user: null | EntityTypes.User) {
	return (
		<Route
			element={
				<ProtectedRoute
					isAllowed={!!user}
					redirectPath={AppUrls.Login().url}
				>
					<AppLayout />
				</ProtectedRoute>
			}
		>
			<Route element={<MainPart />}>
				<Route element={<MyPageManagerCuratorStudent />}>
					<Route
						path={AppUrls.MySchools().url}
						element={<MySchoolsArticle />}
					/>
				</Route>
				<Route
					path={AppUrls.MySchool(':' + RouterVarNames.SchoolId).url}
					element={<MySchoolArticle />}
				/>
				<Route
					path={AppUrls.Profile().url + '/*'}
					element={<ProfilePage />}
				/>
				<Route
					path={AppUrls.Trainings().url}
					element={<TrainingsStudentPage />}
				/>
				<Route
					element={<TrainingStudentPage />}
					path={AppUrls.Training(':' + RouterVarNames.TrainingId).url}
				/>
				<Route
					path={
						AppUrls.Lesson(
							':' + RouterVarNames.TrainingId,
							':' + RouterVarNames.LessonId
						).url
					}
					element={<LessonStudentPage />}
				/>
				<Route
					path={
						AppUrls.Exercise(
							':' + RouterVarNames.TrainingId,
							':' + RouterVarNames.LessonId,
							':' + RouterVarNames.ExerciseId
						).url
					}
					element={<ExerciseStudentPageNew />}
				/>
				<Route element={<LiveRoomsPagesWrapper />}>
					<Route
						path={AppUrls.LiveRooms().url}
						element={<LiveRoomsArticle />}
					/>
				</Route>
				{/*<Route path={AppUrls.Messages().url} element={<Messages />} />*/}
				<Route
					path={AppUrls.AnswersFeed().url}
					element={<AnswersFeedPage />}
				/>
				<Route
					path={AppUrls.Login().url}
					element={<Navigate to={AppUrls.Main().url} />}
				/>
				<Route path="*" element={<ErrorPage errorCode={404} />} />
			</Route>
		</Route>
	)
}
