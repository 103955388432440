const studentTrainingRu = {
	// Экран с текстом про отсутствие уроков
	noLessonsScreen: 'Уроков нет',
	// Сообщение при ошибке при получении данных курса
	errorWhileDownloadTraining: 'Произошла ошибка при получении данных.',

	// ЭКРАН ПРО ОТСУТСТВИЕ КУРСА
	// Заголовок экрана
	noTrainingScreenHeader: 'Курс не существует',
	noTrainingScreenText: 'Он был удалён или введён неправильный адрес',
	noTrainingScreenGoToTrainingsBtn: 'Перейти на Мои курсы',

	// КАРТОЧКА УРОКА
	// Подсказка про недоступный урок
	lessonCardForbiddenLessonAlt: 'Урок недоступен',

	// ЗАГОЛОВОК СТРАНИЦЫ
	// Статус курса: Ожидание открытия первого урока
	trainingHeaderStatusWaiting: 'Ожидание открытия первого урока',
	// Статус курса: В процессе
	trainingHeaderStatusProcess: 'В процессе',
	// Статус курса: Группа завершена
	trainingHeaderStatusGroupFinished: 'Группа завершена',
	// Статус курса: Курс недоступен для просмотра
	trainingHeaderStatusForbidden: 'Курс недоступен для просмотра',
	// Фраза «Следующий урок» после которой идёт дата следующего урока. Пробел справа обязателен.
	trainingHeaderNextLessonPhrase: 'Следующий урок ',
	// Фраза «Осталось выполнить» после которой идёт количество оставшихся уроков. Пробел справа обязателен.
	trainingHeaderRestLessonsNumPhrase: 'Осталось выполнить ',
}

export default studentTrainingRu
