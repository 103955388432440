import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import './CreateNewCard.scss'
import cn from 'classnames'

type CreateNewCardProps = {
	text: string
	imgSrc?: null | string
	onClick: () => void
	disabled?: boolean
	extraClass?: string
}

// Карточка создания новой сущности
function CreateNewCard(props: CreateNewCardProps) {
	const { text, imgSrc, onClick, disabled, extraClass } = props

	return (
		<button
			className={cn('create-new-card', extraClass)}
			onClick={onClick}
			disabled={disabled}
		>
			<div className="create-new-card__content">
				{imgSrc && (
					<img
						src={imgSrc}
						className="create-new-card__image"
						alt="Illustration"
					/>
				)}
				<div className="create-new-card__text-wrapper">
					<PlusOutlined className="create-new-card__text-icon" />
					<p className="create-new-card__text">{text}</p>
				</div>
			</div>
		</button>
	)
}

export default CreateNewCard
