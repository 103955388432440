import React from 'react'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import UrlService from 'parts/services/UrlService'
import { GroupOpenLessonsFormController } from '../groupOpenLessonsForm/OpenLessonsForm/GroupOpenLessonsFormController'

// Страница с формой условий прохождения группы
function OpenLessonsArticle() {
	const trainingId = UrlService.useGetTrainingId()
	const groupId = UrlService.useGetGroupId()

	const groupConditionsFormRouter = GroupOpenLessonsFormController.router(
		trainingId,
		groupId
	)

	return (
		<ArticleContentWrapper center innerWrapperWidth="small">
			{groupConditionsFormRouter.element}
		</ArticleContentWrapper>
	)
}

export default OpenLessonsArticle
