import { useEffect } from 'react'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { isDoneAllowActive } from '../../../audio/zustand/fn/fillStore'
import useExerciseStore from '../exerciseState'

export function useFillStore(exercise: TrainingEntityTypes.ImageExercise) {
	const updateStore = useExerciseStore((store) => store.updateStore)

	useEffect(
		function () {
			updateStore({
				answerWasSent:
					exercise.status ===
					TrainingEntityTypes.ExerciseProgressStatus.SUCCESS,
				doneAllow: isDoneAllowActive(exercise),
			})
		},
		[exercise.id]
	)
}
