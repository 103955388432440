import React from 'react'
import { useGetTrainingGroups } from '../common/getGroups'
import { GroupsTableView } from '../GroupsTable/GroupsTable'
import TableRow from '../TableRow/TableRow'

type GroupsTableRowsProps = {
	tableView: GroupsTableView
	trainingId: number
}

function GroupsTableRows(props: GroupsTableRowsProps) {
	const { tableView, trainingId } = props

	const training = useGetTrainingGroups(trainingId)
	if (!training || !training.groups.length) return null

	return (
		<>
			{training.groups.map((group) => {
				return (
					<TableRow
						key={group.groupId}
						tableView={tableView}
						group={group}
						trainingId={trainingId}
					/>
				)
			})}
		</>
	)
}

export default GroupsTableRows
