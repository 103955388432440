import { Button } from 'antd'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import { useGoToPage } from 'parts/utils/hooks/hooks'
import AppUrls from 'parts/constants/pageUrl'
import UrlService from 'parts/services/UrlService'

// Страница со ссылкой на конструктор одностраничника конкретного курса
function LandingConstructorArticle() {
	const trainingId = UrlService.useGetTrainingId()

	const toToConstructor = useGoToPage(
		AppUrls.LandingConstructor(trainingId).url
	)

	return (
		<ArticleContentWrapper>
			<Button onClick={toToConstructor}>
				{AppUrls.LandingConstructor('').name}
			</Button>
		</ArticleContentWrapper>
	)
}

export default LandingConstructorArticle
