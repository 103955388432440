import ExerciseContainer, {
	ExerciseContainerType,
} from '../../common/ExerciseContainer/ExerciseContainer'
import { Button } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import {
	ExerciseChangesDataMadeByUser,
	SaveExerciseChangesMadeByUser,
} from '../../common/useLiftViewDuration'
import ExerciseControlPanel from '../ExerciseControlPanel/ExerciseControlPanel'
import useExerciseStore from '../zustand/exerciseState'
import { useFillStore } from '../zustand/fn/fillStore'
import { useGetOpenDeleteImageModalFn } from './fn/deleteImage'
import { TranscriptionBlock } from '../../common/TranscriptionBlock/TranscriptionBlock'
import ImageFrame from '../../common/ImageFrame/ImageFrame'
import { TaskExerciseView } from '../../common/TaskExerciseView/TaskExerciseView'
import MediaAnswerBlock from '../../mediaAnswerBlock/MediaAnswerBlock/MediaAnswerBlock'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import {
	useRestoreStore,
	useSaveStateOnServerForRestore,
} from './fn/saveAndRestoreState'
import { useGetAnswerWasSent } from './fn/useGetDisableDoneButton'
import './ImageExerciseView.scss'

type AudioExerciseViewProps = {
	// Для какого типа страницы предназначается обёртка упражнения:
	// constructorPage — страница конструктора. Будут скрыты кнопки перехода по упражнениям и кнопки снизу
	// exercisePage — страница упражнения для ученика. Будет полный набор элементов.
	exerciseContainerType: ExerciseContainerType
	exercise: TrainingEntityTypes.ImageExercise
	// Адрес предыдущего и следующего упражнения
	prevExerciseUrl?: string
	nextExerciseUrl?: string
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

// Вывод упражнения с картинкой в уроке
function ImageExerciseView(props: AudioExerciseViewProps) {
	const {
		exerciseContainerType,
		exercise,
		prevExerciseUrl,
		nextExerciseUrl,
		saveExerciseChangesMadeByUser,
		exerciseRestoreData,
	} = props

	useFillStore(exercise)

	const answerWasSent = useExerciseStore((store) => store.answerWasSent)

	const answerWasSentFn = useGetAnswerWasSent()

	useSaveStateOnServerForRestore(saveExerciseChangesMadeByUser)
	useRestoreStore(exerciseRestoreData)

	return (
		<ExerciseContainer
			type={exerciseContainerType}
			prevExerciseUrl={prevExerciseUrl}
			nextExerciseUrl={nextExerciseUrl}
		>
			<>
				{exercise.item.task && (
					<TaskExerciseView htmlString={exercise.item.task} />
				)}
				<div className="exercise-image-content__images-wrapper">
					{exercise.item.image.map((audioHref, idx) => {
						return (
							<ExerciseImage
								exercise={exercise}
								src={audioHref}
								key={idx}
							/>
						)
					})}
				</div>
				<TranscriptionBlock exercise={exercise} />
				<MediaAnswerBlock
					exercise={exercise}
					writingOrAudioAnswerWasSentFn={answerWasSentFn}
					answerWasSentAlready={!answerWasSent}
				/>
			</>
			<ExerciseControlPanel />
		</ExerciseContainer>
	)
}

export default ImageExerciseView

type ExerciseImageProps = {
	exercise: TrainingEntityTypes.ImageExercise
	src: string
}

function ExerciseImage(props: ExerciseImageProps) {
	const { exercise, src } = props

	const userRoles = useGetUserRole()

	const openDeleteImageModal = useGetOpenDeleteImageModalFn(
		src,
		exercise.id || 0
	)

	if (!userRoles.rolesGot) {
		return null
	}

	return (
		<div className="exercise-image-content__pic">
			{userRoles.isAdmin && (
				<Button
					className="exercise-image-content__pic-close-btn"
					shape="circle"
					size="small"
					icon={<CloseCircleOutlined />}
					onClick={openDeleteImageModal}
				/>
			)}
			<ImageFrame src={src} alt="Картинка из упражнения" />
		</div>
	)
}
