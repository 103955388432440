import React from 'react'
import { Space } from 'antd'
import CommentsPagination from '../CommentsPagination/CommentsPagination'
import LayoutForAdmin from '../LayoutForAdmin/LayoutForAdmin'

// Контейнер контентной части комментариев
function MainContainer() {
	return (
		<Space direction="vertical" size={30} style={{ width: '100%' }}>
			<LayoutForAdmin />
			<CommentsPagination />
		</Space>
	)
}

export default MainContainer
