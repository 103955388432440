import UrlService from 'parts/services/UrlService'
import ExpressTitle from '../components/Title/ExpressTitle'
import LessonCard from '../components/LessonCard/LessonCard'
import { useGetExpressCourse } from '../lessonsContent'

import styles from './Course.module.scss'

const ExpressCourse = () => {
	const courseName = UrlService.useGetExpressCourseId() as
		| 'how-teach'
		| 'how-create'
	const course = useGetExpressCourse(courseName)

	if (!course) {
		return null
	}

	return (
		<div className={styles.Course}>
			<ExpressTitle
				title={course.title}
				subtitle={course.subtitle}
				image={course.image}
			/>
			<div className={styles.Course__ExercisesList}>
				{course.lessons.map((lesson) => (
					<LessonCard
						key={lesson.no}
						no={lesson.no}
						courseName={courseName}
						title={lesson.title.title}
						subtitle={lesson.title.subtitle}
						image={lesson.title.image}
					/>
				))}
			</div>
		</div>
	)
}

export default ExpressCourse
