import { useEffect } from 'react'
import { FormInstance } from 'antd'
import { setErrorsToFields } from 'parts/utils/form'
import useRegisterAdminStore from '../zustand/store'

export enum FieldNames {
	Email = 'email',
	Phone = 'phone',
	Password = 'password',
	Legal = 'legal',
}

export type FormValuesType = {
	[FieldNames.Email]: string
	[FieldNames.Phone]: string
	[FieldNames.Password]: string
	[FieldNames.Legal]: boolean
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useRegisterAdminStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}

/** Возвращает первоначальные значения полей формы редактирования ученика. */
export function useGetInitialValues(): FormValuesType {
	const registerAdminStore = useRegisterAdminStore((state) => state)

	return {
		[FieldNames.Email]: registerAdminStore.email,
		[FieldNames.Phone]: registerAdminStore.phone,
		[FieldNames.Password]: registerAdminStore.password,
		[FieldNames.Legal]: registerAdminStore.legal,
	}
}
