import React from 'react'
import { ExerciseStateType } from '../../../descriptionImage/zustand/exerciseState'
import useExerciseStore from '../../zustand/exerciseState'
import {
	addShadowTo$slot,
	addShadowTo$words,
	createTextButton,
	disable$button,
	get$Area,
	getInfoFrom$button,
	getInfoFromDataTransfer,
	getParentBlockType,
	clear$slot,
	removeShadowFrom$slot,
	removeShadowFrom$words,
	setTransferData,
	enable$button,
	get$textButtonById,
	cureExercise,
	removeWideFrom$slot,
	addWideTo$slot,
	is$slot,
} from './manipulateElems'

/**
 * Обработчик события начала перетаскивания кнопки с текстом
 * @param {Object} event — объект события
 */
export function onDragStart(event: React.DragEvent<HTMLDivElement>) {
	const $draggedButton = event.target as HTMLButtonElement

	const { wordId, text } = getInfoFrom$button($draggedButton)
	const parentBlockType = getParentBlockType($draggedButton)

	if (parentBlockType == 'words') {
		disable$button($draggedButton)

		setTransferData(event, text, wordId, 'words')
	} else if (parentBlockType == 'text') {
		setTransferData(event, text, wordId, 'text')

		const $slot = $draggedButton.parentNode

		if ($slot && $slot instanceof HTMLElement) {
			removeShadowFrom$slot($draggedButton)
			addWideTo$slot($slot)
		}
	}
}

/**
 * Обработчик входа курсора мыши на элемент при перетаскивании кнопки с текстом.
 * @param {Object} event — объект события
 */
export function onDragEnter(event: React.DragEvent<HTMLDivElement>) {
	const $elemUnderCursor = event.target as HTMLDivElement

	if (
		$elemUnderCursor.classList.contains('exercise-drop-word-content__words')
	) {
		addShadowTo$words($elemUnderCursor)
	} else if (is$slot($elemUnderCursor)) {
		addShadowTo$slot($elemUnderCursor)
	}
}

/**
 * Обработчик ухода курсора мыши с элемента при перетаскивании кнопки с текстом.
 * @param {Object} event — объект события
 */
export function onDragLeave(event: React.DragEvent<HTMLDivElement>) {
	const $leavedElem = event.target as HTMLDivElement

	if ($leavedElem.classList.contains('exercise-drop-word-content__words')) {
		removeShadowFrom$words($leavedElem)
	} else if (is$slot($leavedElem)) {
		removeShadowFrom$slot($leavedElem)
	}
}

/**
 * Обработчик бросания перетаскиваемого текста.
 * @param {Object} event — объект события
 */
export function useGetOnDrop(
	event: React.DragEvent<HTMLDivElement>,
	updateStore: ExerciseStateType.UpdateStore
) {
	event.preventDefault()

	// Элемент, в который был брошен перетаскиваемый элемент
	const $dropArea = event.target as HTMLDivElement

	const { startDragArea, wordId, text } = getInfoFromDataTransfer(event)

	const parentBlockType = getParentBlockType($dropArea)

	if (parentBlockType == 'words') {
		dragFromTextDropInWords($dropArea, wordId)
	} else if (parentBlockType == 'text') {
		if (startDragArea == 'words' && is$slot($dropArea)) {
			// Отправить на состояние, где упражнение можно проверить
			updateStore({ stage: 'ReadyForCheck' })

			dragFromWordsDropInText($dropArea, text, wordId)
		}
	}
}

/**
 * Функция выполняет действия при бросании перетаскиваемого текста, который тащили из списка слов и бросили в текст
 * @param {HTMLElement} $slot
 * @param {String} text
 * @param {String} wordId — id перетаскиваемого слова
 */
function dragFromWordsDropInText(
	$slot: HTMLDivElement,
	text: string,
	wordId: string
) {
	// Создать кнопку и поставить в слот
	const $slotButton = createTextButton(text, wordId)
	$slot.append($slotButton)
	removeWideFrom$slot($slot)
}

/**
 * Функция выполняет действия при бросании перетаскиваемого текста, который тащили из текста в список слов.
 * @param {HTMLElement} $dropArea — элемент, в который была брошена кнопка (область слов)
 * @param {String} textId — текст кнопки
 */
function dragFromTextDropInWords($dropArea: HTMLDivElement, textId: string) {
	// Обёртка всех кнопок с текстом и обёртка текста
	const $wordsArea = get$Area($dropArea, 'words')
	const $textArea = get$Area($dropArea, 'text')
	if (!$wordsArea || !$textArea) return

	clear$slot($textArea, textId)

	const $button = get$textButtonById($wordsArea, textId)

	if ($button) {
		enable$button($button)
	}

	removeShadowFrom$words($wordsArea)
}

/**
 * Обработчик события onDragOver. Нужен чтобы заработал onDrop
 * @param {Object} event — объект события
 */
export function onDragOver(event: React.DragEvent<HTMLDivElement>) {
	event.preventDefault()
}

/**
 * Обработчик события onDragEnd.
 * Требуется, чтобы отлавливать сброс перетаскиваемого элемента в любом месте страницы
 * и восстанавливать его если он не находится в тексте.
 * @param {Object} event — объект события
 */
export function onDragEnd(event: React.DragEvent<HTMLDivElement>) {
	const droppedButton = event.target as HTMLElement

	// Обёртка всех кнопок с текстом и обёртка текста
	const $wordsArea = get$Area(droppedButton, 'words')
	const $textArea = get$Area(droppedButton, 'text')
	if (!$wordsArea || !$textArea) return

	cureExercise($wordsArea, $textArea)
}
