import { FC } from 'react'
import { Button } from 'antd'

import styles from './CourseCard.module.scss'

interface ExpressCourseCardProps {
	image: string
	lessonCount: string
	title: string
	subtitle: string
	onClick?: () => void
}

const ExpressCourseCard: FC<ExpressCourseCardProps> = ({
	image,
	lessonCount,
	title,
	subtitle,
	onClick,
}) => {
	return (
		<div className={styles.CourseCard}>
			<div className={styles.CourseCard__Image}>
				<img src={image} />
			</div>
			<div className={styles.CourseCard__Content}>
				<div className={styles.CourseCard__ContentText}>
					<div className={styles.CourseCard__ContentCount}>
						{lessonCount}
					</div>
					<div className={styles.CourseCard__ContentTitle}>
						{title}
					</div>
					<div className={styles.CourseCard__ContentSubTitle}>
						{subtitle}
					</div>
				</div>
				<div className={styles.CourseCard__ContentButton}>
					<Button type="primary" onClick={onClick}>
						Посмотреть курс
					</Button>
				</div>
			</div>
		</div>
	)
}

export default ExpressCourseCard
