import { useEffect } from 'react'
import { FormInstance } from 'antd'
import EntityTypes from 'parts/types/EntityTypes'
import { setErrorsToFields } from 'parts/utils/form'
import useAddEmployeeModalStore from '../zustand/store'

export enum FieldNames {
	Email = 'email',
	Role = 'role',
	AddToGroups = 'addToGroups',

	Name = 'name',
	Surname = 'surname',
	GetAccess = 'getAccess',
}

export type FormValuesType = {
	[FieldNames.Email]: string
	[FieldNames.Role]: EntityTypes.UserRole
	// Это массив строковых массивов, в которые нужно добавить сотрудника.
	// В массиве будет или одно или два числа.
	// Первое указывает на id курса, второе на id группы.
	// Если только одно число, то значит выбрали все группы курса.
	// Например: [['570'], ['571', '82'], ['571', '86']]
	[FieldNames.AddToGroups]: [string, string][]
	[FieldNames.Name]: undefined | string
	[FieldNames.Surname]: undefined | string
	[FieldNames.GetAccess]: undefined | boolean
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useAddEmployeeModalStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
