import { TaskExerciseView } from '../common/TaskExerciseView/TaskExerciseView'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import VideoFrame from 'ui/VideoFrame/VideoFrame'
import {
	LiftViewDuration,
	useLiftViewDuration,
} from '../common/useLiftViewDuration'
import './VideoExerciseView.scss'

type VideoExerciseViewProps = {
	exercise: TrainingEntityTypes.VideoExercise
	liftViewDuration?: LiftViewDuration
}

// Вывод видео-упражнения в уроке
function VideoExerciseView(props: VideoExerciseViewProps) {
	const { exercise, liftViewDuration } = props

	// Считает время прошедшее после открытия упражнения
	// и передаёт количество секунд в liftViewDuration
	useLiftViewDuration(liftViewDuration)

	return (
		<>
			{exercise.item.task && (
				<TaskExerciseView htmlString={exercise.item.task} />
			)}
			{exercise.item.video.map((videoBlock, idx) => {
				return (
					<div
						className="exercise-video-content__video-block"
						key={idx}
					>
						{videoBlock.name && (
							<p className="exercise-video-content__video-name">
								{videoBlock.name}
							</p>
						)}
						<VideoFrame
							downloadedVideoLink={videoBlock.video}
							youTubeLink={videoBlock.link}
							name={videoBlock.name}
						/>
					</div>
				)
			})}
		</>
	)
}

export default VideoExerciseView
