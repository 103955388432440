import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useEffect } from 'react'
import useExerciseStore from '../exerciseState'
import ExerciseStateType from '../ExerciseStateType'

export function fillStore(exercise: TrainingEntityTypes.TrueOrFalseExercise) {
	const updateStore = useExerciseStore((store) => store.updateStore)

	useEffect(
		function () {
			const emptyState = createEmptyState({
				exercise,
				stateType: 'new',
			})

			updateStore(emptyState)
		},
		[exercise.id]
	)
}

/**
 * Создаёт объект состояния, который нужно слить с инициализированным объектом состояния
 * @param args
 */
export function createEmptyState(args: {
	exercise: TrainingEntityTypes.TrueOrFalseExercise
	// Создать новое состояние или очищенное для ещё одного прохождения упражнения
	stateType: 'new' | 'cleared'
	exerciseRestoreData?: ExerciseStateType.State
	newSaveStoreForRestore?: number
}): Partial<ExerciseStateType.State> {
	const state: Partial<ExerciseStateType.State> = {
		currentStatementIdx: 0,
		statements: createStatementsForStore(args.exercise),
		readyToShowResult: false,
		showResult: false,
	}

	if (args.stateType === 'new') {
		state.doneCounter = 0
		state.durationsInSeconds = []
		state.statistics = []
		0
	}

	if (args.newSaveStoreForRestore) {
		state.saveStoreForRestore = args.newSaveStoreForRestore
	}

	return state
}

/**
 * Формирует массив утверждений для объекта состояния упражнения
 * @param {Object} exercise — объект с данными упражнения
 */
function createStatementsForStore(
	exercise: TrainingEntityTypes.TrueOrFalseExercise
): ExerciseStateType.Statement[] {
	return exercise.item.statements.map((statement, i) => {
		const newStatement: ExerciseStateType.Statement = {
			id: i,
			text: statement.text,
			image: statement.image,
			correct: statement.correct,
			userAnswer: null,
		}

		return newStatement
	})
}
