import { useEffect, useRef } from 'react'
import { OpenVidu, Session } from 'openvidu-browser'
import { message } from 'antd'
import liveLessonRequests from 'parts/requests/liveLesson/livelessonRequest'
import RecordDialog from '../components/RecordDialog/RecordDialog'
import useLivelessonStore from '../zustand/store'
import LiveLessonService from 'parts/services/LiveLessonService'
import { ParticipantRole, ParticipantVideoType } from './liveUtils'
import useSystemStore from 'parts/systemStore/systemStore'
import useIsTeacher from './useIsTeacher'
import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'
import { ScreenSharingState } from './useLiveScreenshare'
import { LIVE_EVENT_TYPES } from '../components/LiveWhiteboard/constants'
import { WebSocketService } from 'parts/services/WebSocketService'

export enum RecordingState {
	IDLE = 'idle',
	STARTING = 'starting',
	PREPARING = 'preparing',
	PREPARED = 'prepared',
	CAPTURE_SCREEN = 'capture-screen',
	STARTED = 'started',
	STOPPING = 'stopping',
}

function useLiveRecording() {
	const openViduRecording = useRef<OpenVidu | null>(null)
	const openViduRecordingSession = useRef<Session | null>(null)
	const screenTracks = useLivelessonStore((s) => s.screenTracks)
	const setScreenTracks = useLivelessonStore((s) => s.setScreenTracks)
	const recordingState = useLivelessonStore((s) => s.recordingState)
	const screenShareState = useLivelessonStore((s) => s.screenShareState)
	const screenShareTab = useLivelessonStore((s) => s.screenShareTab)
	const setRecordingState = useLivelessonStore((s) => s.setRecordingState)
	const setCurrentTab = useLivelessonStore((s) => s.setCurrentTab)
	const removeTab = useLivelessonStore((s) => s.removeTab)
	const tabsList = useLivelessonStore((s) => s.tabsList)
	const live = useLivelessonStore((s) => s.live)
	const user = useSystemStore((s) => s.user)
	const isTeacher = useIsTeacher()

	useEffect(() => {
		if (recordingState === RecordingState.STARTING) {
			prepareRecording()
		}
	}, [recordingState])

	const removeScreenShare = (event: LiveLessonEntityTypes.LiveEvent) => {
		removeTab((event.body as LiveLessonEntityTypes.LiveScreensharing).tabId)
		setCurrentTab(tabsList[0].id)
	}

	const prepareRecording = () => {
		if (live && recordingState === RecordingState.STARTING) {
			setRecordingState(RecordingState.PREPARING)

			return getToken(live.id)
				.then((token) => {
					console.log('useLiveRecording::getToken', token)

					return initSession(token)
				})
				.then(({ session, openVidu }) => {
					openViduRecording.current = openVidu
					openViduRecordingSession.current = session

					if (!openVidu.checkScreenSharingCapabilities()) {
						message.error(
							'Демонстрация экрана не поддерживается вашим браузером. Рекомендуем использовать Google Chrome'
						)
						setRecordingState(RecordingState.IDLE)
						return
					}

					setRecordingState(RecordingState.PREPARED)
				})
				.catch((err) => {
					setRecordingState(RecordingState.IDLE)
					message.error('Возникала ошибка при старте записи экрана')
					console.error('Recording err:', err)
				})
		}
	}

	const initSession = async (token: string) => {
		console.log('useLiveSRecording::initSession 1')
		const openViduApi = new OpenVidu()
		const mySession = openViduApi.initSession()
		console.log('useLiveSRecording::initSession 2')
		return mySession
			.connect(token, {
				clientData: {
					participantId: user.id,
					participantRole: isTeacher
						? ParticipantRole.Teacher
						: ParticipantRole.Student,
					participantVideoType: ParticipantVideoType.Screen,
				},
			})
			.then(() => ({
				openVidu: openViduApi,
				session: mySession,
			}))
			.catch((err) => {
				throw err
			})
	}

	const getToken = (liveId: number) => {
		return LiveLessonService.getToken(liveId).then((response) => {
			console.log('useLiveRecording::getToken', response)

			return response.token
		})
	}

	const initPublisher = (
		session: Session,
		openVidu: OpenVidu,
		live: LiveLessonEntityTypes.LiveLesson
	) => {
		console.log('useLiveRecording::initPublisher 1')
		const publisher = openVidu.initPublisher(undefined, {
			videoSource: 'screen',
			publishVideo: true,
		})
		console.log('useLiveRecording::initPublisher 1')
		publisher.on('accessDenied', (event) => {
			message.error('Доступ к демонстрации экрана запрещен')
			setRecordingState(RecordingState.IDLE)
		})

		publisher.once('accessAllowed', () => {
			session.publish(publisher)

			liveLessonRequests
				.startRecording({
					liveId: live.id,
				})
				.then(() => {
					setRecordingState(RecordingState.STARTED)
					message.info('Запись начата')
					WebSocketService.getWebSocketService().sendLiveEvent({
						type: LIVE_EVENT_TYPES.LIVE_START_RECORDING,
					})
				})
				.catch((err) => console.log(err))

			publisher.stream
				.getMediaStream()
				.getVideoTracks()[0]
				.addEventListener('ended', () => {
					// openViduScreenSession.current?.disconnect()
					stopRecording()
					LiveLessonService.leaveParticipant(live.id)
					if (screenShareState === ScreenSharingState.STARTED) {
						const event = {
							type: LIVE_EVENT_TYPES.LIVE_SCREENSHARING_ENDED,
							body: {
								participantId: user.id,
								tabId: screenShareTab?.id,
							},
						}
						WebSocketService.getWebSocketService().sendLiveEvent(
							event
						)
						removeScreenShare(event)
					}
					setRecordingState(RecordingState.IDLE)
				})

			setScreenTracks(publisher.stream.getMediaStream().getTracks())
			console.log('useLiveRecording::accessAllowed')
		})
	}

	const startRecording = () => {
		const openVidu = openViduRecording.current
		const session = openViduRecordingSession.current

		if (
			openVidu &&
			session &&
			live &&
			recordingState === RecordingState.PREPARED
		) {
			setRecordingState(RecordingState.CAPTURE_SCREEN)
			initPublisher(session, openVidu, live)
		}
	}

	const stopRecording = () => {
		if (live) {
			setRecordingState(RecordingState.IDLE)
			message.info('Запись остановлена')
			screenTracks.forEach((track) => track.stop())
			liveLessonRequests
				.stopRecording({
					liveId: live.id,
				})
				.then((resp) => {
					console.log(resp.data)
				})
				.catch((err) => console.log(err))
		}
	}

	const handleRecordCancel = () => {
		if (recordingState === RecordingState.PREPARED) {
			setRecordingState(RecordingState.IDLE)
		}
		if (recordingState === RecordingState.STOPPING) {
			setRecordingState(RecordingState.STARTED)
		}
	}

	const handleRecordOk = () => {
		if (recordingState === RecordingState.PREPARED) {
			startRecording()
		}
		if (recordingState === RecordingState.STOPPING) {
			stopRecording()
		}
	}

	return (
		<RecordDialog
			title={
				recordingState === RecordingState.PREPARED
					? 'Запись видео'
					: 'Остановить запись в облако?'
			}
			content={
				recordingState === RecordingState.PREPARED
					? [
							'После подтверждения мы начнем запись урока.',
							'После завершения отправим вам на почту ссылку на	запись',
					  ]
					: [
							'После того как запись будет остановлена, вам	будет отправлено письмом ссылка на видео и вы 								сможете найти запись в разделе Файлы, в папке	Записи',
					  ]
			}
			visible={
				recordingState === RecordingState.PREPARED ||
				recordingState === RecordingState.STOPPING
			}
			okText={
				recordingState === RecordingState.PREPARED
					? 'Подтверждаю'
					: 'Остановить запись'
			}
			onOk={handleRecordOk}
			onCancel={handleRecordCancel}
		/>
	)
}

export default useLiveRecording
