import React from 'react'
import { Modal, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { GrayBlock } from 'ui/wrappers'
import useLessonsPresetsStore from '../zustand/store'
import {
	useChangeApplyPresetModalVisibility,
	useGetConfirmApplyPreset,
} from './fn/actions'

export default function ApplyPresetConfirmation() {
	const { t } = useTranslation()
	const isOpenApplyPresetConfirmation = useLessonsPresetsStore(
		(state) => state.isOpenApplyPresetConfirmation
	)

	const closeModal = useChangeApplyPresetModalVisibility(false)()
	const confirm = useGetConfirmApplyPreset()

	return (
		<Modal
			title={t('adminTraining.applyPresetModalTitle')}
			open={isOpenApplyPresetConfirmation}
			onCancel={closeModal}
			onOk={confirm}
			okText={t('adminTraining.applyPresetModalOnBtn')}
			cancelText={t('adminTraining.applyPresetModalCancelBtn')}
		>
			<GrayBlock>
				<Typography>
					{t('adminTraining.applyPresetModalText')}
				</Typography>
			</GrayBlock>
		</Modal>
	)
}
