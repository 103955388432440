import React from 'react'
import useAddStaffInGroupModalStore from '../zustand/store'

export enum FieldNames {
	Search = 'search',
}

export type FormValuesType = {
	[FieldNames.Search]: string
}

/**
 * Возвращает обработчик изменения поля поиска.
 * Введённая строка записывается в Состояние чтобы по ней фильтровался список сотрудников.
 * */
export function useGetOnSearchInputChange() {
	const updateState = useAddStaffInGroupModalStore(
		(store) => store.updateStore
	)

	return (e: React.ChangeEvent<HTMLInputElement>) => {
		updateState({ search: e.target.value })
	}
}
