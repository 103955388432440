const leadsRu = {
	// ЭКРАН С СООБЩЕНИЕМ ОБ ОТСТУТСТВИИ ПОКУПОК
	// Сообщение про отсутствие покупок
	noLeadsScreenNoLeads: 'В этом разделе будут отображаться все',
	noLeadsScreenNoLeads2: 'покупки ваших курсов, марафонов и тренингов',
	// СТАТИСТИКА ПО ПОКУПКАМ
	// Заголовок раздела общего количества покупок
	leadsStatsTotalPurchasesHeader: 'Покупок всего',
	// Заголовок раздела количества покупок за сегодня
	leadsStatsTodayPurchasesHeader: 'Покупок за сегодня',

	// ТАБЛИЦА С ПОКУПКАМИ
	// Заголовок ряда «Дата»
	leadsTableHeaderDate: 'Дата',
	// Заголовок ряда «Электронная почта»
	leadsTableHeaderEmail: 'Электронная почта',
	// Заголовок ряда «Телефон»
	leadsTableHeaderPhone: 'Телефон',
	// Заголовок ряда «Стоимость»
	leadsTableHeaderCost: 'Стоимость',
	// Заголовок ряда «Платежная система»
	leadsTableHeaderPaymentSystem: 'Платежная система',
	// Заголовок ряда «Статус»
	leadsTableHeaderStatus: 'Статус',
	// Статус платежа: Оплачен
	leadsTableStatusPaid: 'Оплачен',
	// Статус платежа: Не оплачен
	leadsTableStatusUnPaid: 'Не оплачен',
	leadsTablePaySystemHaveNotSelected: 'Не задано',
}

export default leadsRu
