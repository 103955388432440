import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AppUrls from 'parts/constants/pageUrl'
import useSystemStore from 'parts/systemStore/systemStore'

/**
 * При заходе на страницу входа на эфир урока вида
 * http://localhost:3000/livelessons/link/23954f70-8794-4389-b526-12060ec3512c
 * с сервера получается числовой идентификатор урока и ставится в Системное Хранилище.
 * Это нужно потому что пользователь может быть как авторизован (тогда его сразу перекидывает со страницы входа на страницу эфира урока),
 * так и не авторизован, тогда после страницы входа в эфир урока попадает на страницу ввода почты.
 * А затем или система его авторизовывает (если он ещё не зарегистрирован) или посылает на страницу авторизации (если почта уже зарегистрирована).
 * После обнуляет значение liveLessonId.
 */
export function useGoToLiveLessonPageIfNecessary() {
	const navigate = useNavigate()
	const liveLessonId = useSystemStore((store) => store.liveLessonId)
	const updateStore = useSystemStore((store) => store.updateStore)

	useEffect(
		function () {
			if (!liveLessonId) return
			navigate(AppUrls.LiveLesson(liveLessonId).url)

			updateStore({ liveLessonId: null })
		},
		[liveLessonId]
	)
}
