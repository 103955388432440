export type DropWordsExerciseFormStateType = {
	exerciseId: null | number // id упражнения. Если это новое упражнение, то id не существует
	type: 'dropWords' // Тип упражнения
	order: number
	// Поле «Задание»
	taskInput: {
		value: string // Значение поля «Задание»
	}
	texts: TextStatementItemType[] // Данные об указанных видео
	isFormInvalid: boolean // Является ли форма неверной
}

export type TextStatementItemType = {
	id: number // Идентификатор блока текстового блока
	text: string // Текст с пропусками
	wrongAnswers: string[] // Массив неправильных слов
	noTextError: boolean // Нужно ли показывать сообщение с ошибкой про отсутствие текста в редакторе
	wrongBracesPairError: boolean // Нужно ли показывать сообщение с ошибкой про неправильный порядок пар закрывающих и открывающих фигурных скобок
	// Сгенерированный текст, который нужно поместить в поле с текстом
	generatedText: string
	// Текст задания на генерирование текста
	generatedTextPrompt: string
}

// Функция возвращает объект начального состояния формы упражнения
export function getFormInitialState() {
	const state: DropWordsExerciseFormStateType = {
		exerciseId: null,
		type: 'dropWords',
		order: 0,
		taskInput: {
			value: '',
		},
		texts: [getNewTextFormDataItem(1)],
		isFormInvalid: false,
	}

	return state
}

/**
 * Функция создаёт и возвращает объект данных текста с пропусками
 * @param {Number} id — id данных
 * @param {String} text — текст данных
 */
export function getNewTextFormDataItem(id: number, text = '') {
	const textStateItem: TextStatementItemType = {
		id,
		text,
		wrongAnswers: [],
		noTextError: false,
		wrongBracesPairError: false,
		generatedText: '',
		generatedTextPrompt: '',
	}

	return textStateItem
}
