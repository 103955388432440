import React, { MouseEventHandler } from 'react'
import { Alert, Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { ModalLayout } from 'ui/ModalLayout/ModalLayout'
import useExercisesListAdminStore from '../../zustand/store'
import { onChangeTaskInput, addNewTextBlock } from './fn/changeForm'
import { ExerciseType, GapsExerciseFormStateType } from './fn/stateType'
import ExerciseFormFooter from '../common/ExerciseFormFooter/ExerciseFormFooter'
import HtmlEditor from 'ui/HTMLEditor/HTMLEditor'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useSetFormData } from './fn/state'
import { submitHandler } from './fn/submit'
import TextBlocks from './TextBlock'
import './GapsInTextForm.scss'

type GapsFormProps = {
	exerciseType: ExerciseType // Тип упражнения: gapsInWords (заполнить пропуски в словах) или chooseWord (выбрать правильный вариант слова)
	saveExercise: (args: TrainingEntityTypes.ExerciseItem) => void // Функция сохраняющая данные упражнения на сервере и в Хранилище
	closeModalFn: MouseEventHandler<HTMLElement> // Функция закрытия модального окна
}

// Форма составления упражнения с пропусками слов (ввод слов или выбор)
function GapsInTextForm(props: GapsFormProps) {
	const { exerciseType, saveExercise, closeModalFn } = props

	const { t } = useTranslation()
	useSetFormData(exerciseType)

	const formState = useExercisesListAdminStore(
		(store) => store.formData
	) as GapsExerciseFormStateType

	if (!formState) return null

	const modalTitle =
		exerciseType == 'gapsInWords'
			? 'Ввод слов в пропуски'
			: 'Выбор правильной формы слова'

	return (
		<ModalLayout
			key="1"
			title={modalTitle}
			cancelBtn={false}
			isModalVisible
			closeModalFn={closeModalFn}
			footer={null}
		>
			<ExerciseInput formState={formState} />
			<AdvertText exerciseType={exerciseType} />
			<TextBlocks exerciseType={exerciseType} formState={formState} />
			<AddTextBlockButton />
			<ExerciseFormFooter
				formState={formState}
				saveExercise={saveExercise}
				closeModalFn={closeModalFn}
				isSubmitButtonDisabled={formState.isFormInvalid}
				submitHandler={() => submitHandler(exerciseType, saveExercise)}
			/>
		</ModalLayout>
	)
}

export default GapsInTextForm

type ExerciseInputProps = {
	formState: GapsExerciseFormStateType
}

// Поле с текстом задания
function ExerciseInput(props: ExerciseInputProps) {
	const { formState } = props

	const { t } = useTranslation()

	return (
		<>
			<label className="exercise-gaps-in-words__label">Задание</label>
			<HtmlEditor
				initialValue={formState.taskInput.value}
				onChangeValue={onChangeTaskInput}
			/>
		</>
	)
}

type AdvertTextProps = {
	exerciseType: ExerciseType
}

function AdvertText(props: AdvertTextProps) {
	const { exerciseType } = props

	const { t } = useTranslation()

	let advertText =
		exerciseType == 'gapsInWords'
			? 'Чтобы слово сделать пропуском, который должен заполнить ученик, обрамите его фигурными скобками или выделите слово и нажмите на кнопку «Сделать пропуском», тогда программа сделает это автоматически.'
			: 'Чтобы ученик в упражнении мог выбрать одно из нескольких вариантов слов обрамите текст фигурными скобками или выделите текст и нажмите на кнопку «Сделать пропуском», тогда программа сделает это автоматически. Текст в фигурных скобках должен быть в формате {+begin,began,begun}: варианты слов отделяются запятыми, а перед правильным словом стоит плюс. Плюс может быть перед любым словом не зависимо от его положения.'

	return <Alert message={advertText} type="warning" />
}

// Кнопка добавления нового редактора текста
function AddTextBlockButton() {
	const { t } = useTranslation()

	return (
		<div className="exercise-gaps-in-words-add-text-block">
			<Button onClick={() => addNewTextBlock()}>
				<PlusCircleOutlined width={17} height={17} /> Добавить текст
			</Button>
		</div>
	)
}
