import EntityTypes from 'parts/types/EntityTypes'
import { create } from 'zustand'
import { StudentsStoreType } from './storeTypes'
import SearchValuesStatus = StudentsStoreType.SearchValuesStatus
import { USERS_LENGTH_IN_PAGE } from '../../common/usersConsts'

const useStudentsStore = create<StudentsStoreType.State>((set) => {
	return {
		dataLoadingStatus: 'loading',
		students: [],
		currentPageNum: 1,
		totalStudents: 0,
		studentsPerPage: USERS_LENGTH_IN_PAGE,

		searchValues: {
			fullName: '',
			email: '',
			phone: '',
			country: '',
			status: SearchValuesStatus.All,
			roles: [EntityTypes.UserRole.STUDENT],
			isDeleted: false,
		},

		/**
		 * Установщик новых значений корневого объекта.
		 * @param obj — объект со свойствами и значениями, которые должны быть изменены в Сотоянии
		 */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},

		/**
		 * Установщик новых значений объекта поиска по полям таблицы учеников.
		 * @param propKey — название свойства объекта searchValues
		 * @param propValue — значение свойства объекта searchValues
		 */
		setSearchValuesProp(propKey, propValue) {
			return set((state) => {
				const newSearchValues = { ...state.searchValues }
				newSearchValues[propKey] = propValue

				return {
					...state,
					...{ searchValues: newSearchValues },
					currentPageNum: 1,
				}
			})
		},
	}
})

export default useStudentsStore
