import React, { FC, ReactNode } from 'react'
import cn from 'classnames'
import './Scrollbar.scss'

interface IScrollbarProps {
	className?: string
	children: ReactNode
}

const Scrollbar: FC<IScrollbarProps> = ({ className, children }) => (
	<div className={cn('ui-scrollbar', className ? className : '')}>
		<div className="scrollbar__content">{children}</div>
	</div>
)

export default Scrollbar
