import { create } from 'zustand'
import { OpenLessonsStoreType } from './storeType'

const useOpenLessonsStore = create<OpenLessonsStoreType.State>((set) => {
	return {
		dataLoadingStatus: 'loading',
		trainingId: 0,
		group: undefined as any,
		lessons: [],
		isSubmitting: false,
		formErrors: {},

		/**
		 * Установщик новых значений корневого объекта.
		 * @param obj — объект со свойствами и значениями, которые должны быть изменены в Сотоянии
		 */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useOpenLessonsStore
