import { useQueryClient } from 'react-query'
import { useGetStudentsTableRouter } from './fn/leadsTableRouter'
import { paymentsQuery } from 'parts/requests/payments/paymentsQuery'

export const LeadsTableController = {
	router: useGetStudentsTableRouter,
	// Функция, помечающая данные массива курсов неактуальными.
	// После этого React Query скачает новый массив курсов
	useGetUpdate: function () {
		const queryClient = useQueryClient()

		return function () {
			// Пометить список покупок неактуальным. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [paymentsQuery.getPayments.key],
			})
		}
	},
}
