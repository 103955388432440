import React, { useCallback, useState } from 'react'
import {
	CheckCircleOutlined,
	ClockCircleOutlined,
	StopOutlined,
} from '@ant-design/icons'
import { RuleObject } from 'antd/lib/form'
import { StoreValue } from 'antd/lib/form/interface'
import UserService from '../../services/UserService'

// Тип статуса проверки введённой почты на существование
type EmailCheckStatusType = 'dontExist' | 'waiting' | 'exists'

/**
 * Хук возвращает обработчик изменения поля ввода почты.
 * После каждого изменения он делает запрос на сервер для проверки существования введённой почты.
 * И в зависимости от ответа возвращает различные статусы.
 * От статуса зависит вид значка справа от поля ввода и показ ошибки при отправке формы.
 */
export function useCheckIsEmailExists() {
	const [emailCheckStatus, setEmailCheckStatus] =
		useState<EmailCheckStatusType>('dontExist')

	// Функция запускаемая при изменении значения поля с почтой
	const onChangeEmailInput = useCallback(function (
		e: React.ChangeEvent<HTMLInputElement>
	) {
		const { value } = e.target

		// Поставить тип waiting чтобы показать, что идёт запрос на сервер
		setEmailCheckStatus('waiting')

		UserService.isUserExist(value).then((isExist) => {
			if (isExist) {
				// Поставить тип exists, чтобы показать, что почта уже зарегистрирована
				setEmailCheckStatus('exists')
			} else {
				// Поставить тип dontExist, чтобы показать, что почта ещё не зарегистрирована
				setEmailCheckStatus('dontExist')
			}
		})
	},
	[])

	return {
		onChangeEmailInput,
		emailCheckStatus,
	}
}

/**
 * Возвращает компонент значка, который должен показываться в зависимости от статуса проверки почты на существование
 * @param {String} checkStatus — статус проверки поля на существование введённой почты.
 */
export function getEmailInputSuffix(checkStatus: EmailCheckStatusType) {
	if (checkStatus == 'dontExist') {
		return CheckCircleOutlined
	} else if (checkStatus == 'waiting') {
		return ClockCircleOutlined
	} else {
		return StopOutlined
	}
}

/**
 * Возвращает объект вставляемый в массив rules поля ввода почты.
 * Если в функцию передать значение exists, то будет выброшена ошибка, что почта уже зарегистрирована.
 * Это предотвратит отправку формы регистрации нового ученика.
 * В других случаях ошибки не будет.
 * @param {String} checkStatus — статус проверки поля на существование введённой почты.
 */
export function getEmailInputValidator(checkStatus: EmailCheckStatusType) {
	return {
		validator: async (_: RuleObject, names: StoreValue) => {
			if (checkStatus == 'exists') {
				return Promise.reject(
					new Error('Такая почта уже зарегистрирована')
				)
			}
		},
	}
}
