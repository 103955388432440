import { create } from 'zustand'
import { AssignEmployeeToGroupModalStoreType } from './storeTypes'

const useAssignEmployeeToGroupModalStore =
	create<AssignEmployeeToGroupModalStoreType.State>((set) => ({
		isOpen: false,
		isSubmitting: false,
		formErrors: {},

		/**
		 * Установщик новых значений корневого объекта.
		 * @param obj — объект со свойствами и значениями, которые должны быть изменены в Сотоянии
		 */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}))

export default useAssignEmployeeToGroupModalStore
