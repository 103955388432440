import React from 'react'
import { Button } from 'antd'
import {
	ArrowLeftOutlined,
	LinkOutlined,
	SettingOutlined,
} from '@ant-design/icons'
import {
	useGetCopyLandingPageUrl,
	useGetGoToPreviewPageUrl,
	useGetGoToTrainingPage,
	useGetPreviewPageUrl,
	useGetPublishLanding,
} from 'pages/landing/landingConstructor/details/TopToolbar/fn/buttonHandlers'
import { ColorSchemeModalController } from 'pages/landing/landingConstructor/details/ColorSchemeModal/ColorSchemeModalController'
import 'pages/landing/landingConstructor/details/TopToolbar/TopToolbar.scss'
import { Link } from 'react-router-dom'
import useLandingConstructorStore from '../../zustand/store'
import { useGetSaveDataStatus } from './fn/getSaveDataStatus'

function TopToolbar() {
	return (
		<div className="land-const-top-toolbar">
			<div className="land-const-top-toolbar__left">
				<ComeBackToTrainingButton />
				<SaveStatus />
			</div>
			<div className="land-const-top-toolbar__middle">
				<LinkButton />
				<SettingsButton />
			</div>
			<RightButtons />
		</div>
	)
}

export default TopToolbar

function SaveStatus() {
	const { statusText } = useGetSaveDataStatus()

	return (
		<span className="land-const-top-toolbar__save-status">
			{statusText}
		</span>
	)
}

function ComeBackToTrainingButton() {
	const goToTrainingPage = useGetGoToTrainingPage()

	return (
		<Button
			type="link"
			icon={<ArrowLeftOutlined />}
			onClick={goToTrainingPage}
		>
			В курс
		</Button>
	)
}

function LinkButton() {
	const copyLandingPageUrl = useGetCopyLandingPageUrl()

	return <Button icon={<LinkOutlined />} onClick={copyLandingPageUrl} />
}

function SettingsButton() {
	return (
		<Button
			icon={<SettingOutlined />}
			onClick={ColorSchemeModalController.open}
		/>
	)
}

function RightButtons() {
	const previewPageUrl = useGetPreviewPageUrl()

	const previewLanding = useGetGoToPreviewPageUrl()
	const publishLanding = useGetPublishLanding()

	return (
		<div className="land-const-top-toolbar__right-part">
			<Link to={previewPageUrl} target="_blank" onClick={previewLanding}>
				Предпросмотр
			</Link>

			<Button type="primary" onClick={publishLanding}>
				Опубликовать
			</Button>
		</div>
	)
}
