import React from 'react'
import { Col, Row, Tooltip } from 'antd'
import { AreaChartOutlined, BarsOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Scrollbar } from 'ui'
import useLessonsPresetsStore from '../../zustand/store'
import Preset from '../Preset/Preset'
import ApplyPresetConfirmation from '../../ApplyPresetConfirmation/ApplyPresetConfirmation'
import { useGetOpenPresetsListDrawer } from 'widgets/presets/presetsListDrawer/PresetsListDrawer/fn/useGetDrawerActions'
import PresetsListDrawer from 'widgets/presets/presetsListDrawer/PresetsListDrawer/PresetsListDrawer'
import { useChangeApplyPresetModalVisibility } from '../../ApplyPresetConfirmation/fn/actions'
import '../../../../app/App/css/variables.scss'
import './PresetsList.scss'

export default function PresetsList() {
	const { t } = useTranslation()

	const presets = useLessonsPresetsStore((state) => state.presets)

	const openApplyPresetConfirmation =
		useChangeApplyPresetModalVisibility(true)
	const openPresetsDrawer = useGetOpenPresetsListDrawer()

	return (
		<Scrollbar className="lessons-presets-list__scrollbar">
			<Row
				wrap={false}
				gutter={16}
				className="lessons-presets-list__wrapper"
			>
				{presets!.map((preset) => (
					<Tooltip
						key={preset.id}
						placement="top"
						title={preset.description}
					>
						<Col>
							<Preset
								name={preset.name}
								icon={
									<AreaChartOutlined
										style={{ color: '#1677FF' }}
									/>
								}
								onPresetClick={openApplyPresetConfirmation(
									preset.id
								)}
							/>
						</Col>
					</Tooltip>
				))}
				<div className="lessons-presets-list__all-presets-wrapper">
					<Preset
						name={t('adminTraining.allPresetsButton')}
						icon={<BarsOutlined style={{ color: '#1677FF' }} />}
						onPresetClick={openPresetsDrawer}
					/>
				</div>
			</Row>
			<ApplyPresetConfirmation />
			<PresetsListDrawer />
		</Scrollbar>
	)
}
