import _ from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { shallow } from 'zustand/shallow'
import { OpenVidu, Publisher, Session, StreamEvent } from 'openvidu-browser'
import LiveLessonService from 'parts/services/LiveLessonService'
import { WebSocketService } from 'parts/services/WebSocketService'
import EntityTypes from 'parts/types/EntityTypes'
import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'
import {
	ParticipantRole,
	ParticipantVideoType,
	getConnectionData,
} from './liveUtils'
import useLivelessonStore from '../zustand/store'
import useSystemStore from 'parts/systemStore/systemStore'
import { MessageInstance } from 'antd/es/message/interface'
import { LIVE_EVENT_TYPES } from '../components/LiveWhiteboard/constants'
import useIsTeacher from './useIsTeacher'
import { RecordingState } from './useLiveRecording'
import liveLessonRequests from 'parts/requests/liveLesson/livelessonRequest'
import { ScreenSharingState } from './useLiveScreenshare'

export enum LiveLessonState {
	Idle = 'idle',
	Prepared = 'prepared',
	Ready = 'ready',
	Started = 'started',
}

const TeacherCameraResolution = '640x480'
const ParticipantCameraResolution = '320x240'
const DefaultAudioState = true
const DefaultVideoState = false

function useLiveSession(messageApi: MessageInstance) {
	const openViduRef = useRef<OpenVidu | null>(null)
	const openViduSession = useRef<Session | null>(null)
	const [localPublisher, setLocalPublisher] = useState<Publisher>()
	const [isFrontCamera, setIsFrontCamera] = useState(true)
	const {
		addParticipant,
		setParticipantVideoStatus,
		setParticipantAudioStatus,
		setParticipantScreenSharing,
		setParticipantSubscriber,
		setParticipantIsSpeaking,
	} = useLivelessonStore(
		(s) => ({
			addParticipant: s.addParticipant,
			setParticipantVideoStatus: s.setParticipantVideoStatus,
			setParticipantAudioStatus: s.setParticipantAudioStatus,
			setParticipantScreenSharing: s.setParticipantScreenSharing,
			setParticipantSubscriber: s.setParticipantSubscriber,
			setParticipantIsSpeaking: s.setParticipantIsSpeaking,
		}),
		shallow
	)
	const participants = useLivelessonStore((s) => s.participants)
	const live = useLivelessonStore((s) => s.live)
	const user = useSystemStore((s) => s.user)
	const setIsCameraBlockAlertVisible = useLivelessonStore(
		(s) => s.setIsCameraBlockAlertVisible
	)
	const recordingState = useLivelessonStore((s) => s.recordingState)
	const screenShareState = useLivelessonStore((s) => s.screenShareState)
	const screenTracks = useLivelessonStore((s) => s.screenTracks)
	const liveState = useLivelessonStore((s) => s.liveState)
	const setLiveState = useLivelessonStore((s) => s.setLiveState)
	const isTeacher = useIsTeacher()
	const localParticipant = useMemo(
		() => participants.find((p) => p.id === user.id),
		[participants, user.id]
	)

	useEffect(() => {
		if (localParticipant && localPublisher) {
			localPublisher.publishVideo(localParticipant.isVideoOn)
			localPublisher.publishAudio(localParticipant.isAudioOn)
		}
	}, [localParticipant, localPublisher])

	const prepareLiveLesson = async (
		liveLesson: LiveLessonEntityTypes.LiveLesson
	) => {
		if (liveState !== LiveLessonState.Idle) {
			return
		}

		try {
			const { default: socketIOClient } = await import(
				/* webpackChunkName: "socketIoClient" */ 'socket.io-client'
			)

			const socketServerData = await LiveLessonService.getMessageServer()

			WebSocketService.getWebSocketService().open(
				socketIOClient(socketServerData.url + 'live', {
					path: socketServerData.path,
					transports: socketServerData.polling
						? ['websocket', 'polling']
						: ['websocket'],
				}),
				liveLesson.id
			)
		} catch (error: any) {
			console.error(error)
			return
		}

		const my: LiveLessonEntityTypes.Participant = {
			id: user.id,
			email: user.email,
			firstName: user.firstName ?? 'Guest',
			lastName: user.lastName ?? '',
			avatar: user.avatar,
			isAudioOn: false,
			isVideoOn: false,
			isSpeaking: false,
		}
		addParticipant(my)

		WebSocketService.getWebSocketService().sendLiveEvent({
			type: LIVE_EVENT_TYPES.LIVE_ADD_PARTICIPANT,
			body: {
				participant: my,
			},
		})

		if (user.id === live?.teacher.id) {
			WebSocketService.getWebSocketService().sendLiveStart()
		} else {
			WebSocketService.getWebSocketService().sendGetLiveDuration()
		}
		WebSocketService.getWebSocketService().sendGetLiveInitialState()

		setLiveState(LiveLessonState.Prepared)
	}

	const joinToLiveLesson = (
		liveLesson: LiveLessonEntityTypes.LiveLesson,
		user: EntityTypes.User
	) => {
		if (liveState !== LiveLessonState.Ready) {
			return
		}

		return getToken(liveLesson.id)
			.then((token) => {
				console.log('useLiveSession::getToken:', token)

				return initSession(token, user.id)
			})
			.then(({ session, openVidu }) => {
				openViduRef.current = openVidu
				openViduSession.current = session

				return initPublisher(session, openVidu, user.id)
			})
			.catch((err) => {
				messageApi.open({
					type: 'error',
					content: 'Возникла ошибка при создании сессии',
				})
				console.error(
					'There was an error connecting to the session:',
					err.code,
					err.message
				)
			})
	}

	const initSession = (token: string, userId: number) => {
		console.log('useLiveSession::initSession')
		const myOpenVidu = new OpenVidu()
		const mySession = myOpenVidu.initSession()

		mySession.on('streamCreated', (event) => {
			onStreamCreated(event, mySession)
		})

		mySession.on('streamDestroyed', (event) => {
			onStreamDestroyed(event)
		})

		// On every asynchronous exception...
		mySession.on('exception', (exception) => {
			if (exception.name === 'ICE_CONNECTION_FAILED') {
				console.warn('Stream  broke!')
				console.warn('Reconnection process automatically started')
			}
			if (exception.name === 'ICE_CONNECTION_DISCONNECTED') {
				console.warn('Stream  disconnected!')
				console.warn(
					'Giving it some time to be restored. If not possible, reconnection process will start'
				)
			}
			console.error(exception)
		})

		mySession.on('reconnecting', () =>
			messageApi.open({
				type: 'warning',
				content: 'Упс! Попытка переподключиться к сеансу',
			})
		)
		mySession.on('reconnected', () =>
			messageApi.open({
				type: 'warning',
				content: 'Ура! Вы успешно повторно подключились к сеансу',
			})
		)
		mySession.on('sessionDisconnected', (event) => {
			if (event.reason === 'networkDisconnect') {
				messageApi.open({
					type: 'warning',
					content: 'Черт возьми... Вы потеряли соединение с сеансом.',
				})
			} else {
				// Disconnected from the session for other reason than a network drop
				console.log('useLiveSession::OnSessionDisconnected')
			}
		})

		return mySession
			.connect(token, {
				clientData: {
					participantId: userId,
					participantRole: isTeacher
						? ParticipantRole.Teacher
						: ParticipantRole.Student,
					participantVideoType: ParticipantVideoType.Camera,
				},
			})
			.then(() => {
				return {
					session: mySession,
					openVidu: myOpenVidu,
				}
			})
			.catch((error) => {
				throw error
			})
	}

	const initPublisher = async (
		session: Session,
		openVidu: OpenVidu,
		userId: number
	) => {
		try {
			const devices = await openVidu.getDevices()
			const videoDevices = devices.filter(
				(device) => device.kind === 'videoinput'
			)

			console.log('useLiveSession::initPublisher 1')
			const publisher = openVidu.initPublisher(undefined, {
				videoSource: videoDevices[0].deviceId,
				publishAudio: DefaultAudioState,
				publishVideo: DefaultVideoState,
				resolution: isTeacher
					? TeacherCameraResolution
					: ParticipantCameraResolution,
				frameRate: 30,
				mirror: true,
			})
			console.log('useLiveSession::initPublisher 2')
			publisher.on('accessAllowed', () => {
				session.publish(publisher)
				setParticipantSubscriber(userId, publisher)
				setParticipantAudioStatus(
					userId,
					DefaultAudioState,
					true,
					false
				)
				setParticipantVideoStatus(
					userId,
					DefaultVideoState,
					true,
					false
				)
				setLocalPublisher(publisher)
				setLiveState(LiveLessonState.Started)
				console.log('useLiveSession::initPublisher accessAllowed')
			})
			publisher.on('accessDenied', () => {
				setIsCameraBlockAlertVisible(true)
			})
		} catch (err) {
			// TODO: send to sentry
			console.error(err)
		}
	}

	const toggleCamera = () => {
		if (openViduRef.current && openViduSession.current && localPublisher) {
			const OV = openViduRef.current
			const session = openViduSession.current
			const publisher = localPublisher

			OV.getDevices().then((devices) => {
				// Getting only the video devices
				const videoDevices = devices.filter(
					(device) => device.kind === 'videoinput'
				)

				if (videoDevices && videoDevices.length > 1) {
					// Creating a new publisher with specific videoSource
					// In mobile devices the default and first camera is the front one
					const newPublisher = OV.initPublisher(undefined, {
						videoSource: isFrontCamera
							? videoDevices[1].deviceId
							: videoDevices[0].deviceId,
						publishAudio: DefaultAudioState,
						publishVideo: DefaultVideoState,
						mirror: isFrontCamera, // Setting mirror enable if front camera is selected
					})

					// Changing isFrontCamera value
					setIsFrontCamera(!isFrontCamera)

					// Unpublishing the old publisher
					session.unpublish(publisher).then(() => {
						console.log('Old publisher unpublished!')

						// Assigning the new publisher to our global variable 'publisher'
						setLocalPublisher(newPublisher)

						// Publishing the new publisher
						session.publish(publisher).then(() => {
							console.log('New publisher published!')
						})
					})
				}
			})
		}
	}

	const onStreamCreated = (event: StreamEvent, session: Session) => {
		const subscriber = session.subscribe(event.stream, undefined)

		const { clientData, serverData } = getConnectionData(
			subscriber.stream.connection
		)

		subscriber.on('publisherStartSpeaking', () => {
			setParticipantIsSpeaking(serverData.id, true)
		})

		subscriber.on('publisherStopSpeaking', () => {
			setParticipantIsSpeaking(serverData.id, false)
		})

		if (clientData.participantVideoType === ParticipantVideoType.Camera) {
			setParticipantAudioStatus(
				serverData.id,
				subscriber.stream.audioActive,
				true,
				true
			)
			setParticipantVideoStatus(
				serverData.id,
				subscriber.stream.videoActive,
				true,
				true
			)

			setParticipantSubscriber(serverData.id, subscriber)

			subscriber.on('streamPropertyChanged', (event) => {
				if (
					event.changedProperty === 'audioActive' &&
					event.reason === 'publishAudio'
				) {
					setParticipantAudioStatus(
						serverData.id,
						!!event.newValue,
						true,
						true
					)
				}
				if (
					event.changedProperty === 'videoActive' &&
					event.reason === 'publishVideo'
				) {
					setParticipantVideoStatus(
						serverData.id,
						!!event.newValue,
						true,
						true
					)
				}
			})
		}

		if (clientData.participantVideoType === ParticipantVideoType.Screen) {
			console.log(
				`Recieve screen sharing from ${clientData.participantRole} with id: ${clientData.participantId}`
			)

			setParticipantScreenSharing(clientData.participantId, subscriber)
		}
	}

	const onStreamDestroyed = (event: StreamEvent) => {
		const { serverData } = getConnectionData(event.stream.connection)

		setParticipantSubscriber(serverData.id, undefined)
		setParticipantAudioStatus(serverData.id, false, true, true)
		setParticipantVideoStatus(serverData.id, false, true, true)
	}

	const leaveLiveLesson = (live: LiveLessonEntityTypes.LiveLesson) => {
		const my = participants.find((p) => p.id === user.id)
		if (liveState !== LiveLessonState.Idle && my) {
			const event = {
				type: LIVE_EVENT_TYPES.LIVE_REMOVE_PARTICIPANT,
				body: {
					participant: _.omit(my, ['subscriber', 'screenSharing']),
				},
			}
			WebSocketService.getWebSocketService().sendLiveEvent(event)
		}

		if (recordingState === RecordingState.STARTED) {
			screenTracks.forEach((track) => track.stop())
			liveLessonRequests
				.stopRecording({
					liveId: live.id,
				})
				.then((resp) => {
					console.log(resp.data)
				})
				.catch((err) => console.log(err))
		}

		if (screenShareState === ScreenSharingState.STARTED) {
			screenTracks.forEach((track) => track.stop())
		}

		if (liveState === LiveLessonState.Started && openViduSession.current) {
			const session = openViduSession.current

			try {
				if (session) {
					session.disconnect()
				}
			} catch (error) {
				console.error(error)
			} finally {
				openViduSession.current = null
				LiveLessonService.leaveParticipant(live.id)
					.then(() => {
						console.log('leave ok')
					})
					.catch((err) => console.log('leaveRoom Err:', err))
					.finally(() => {
						WebSocketService.getWebSocketService().close()
					})
			}
		}
		setLiveState(LiveLessonState.Idle)
	}

	const getToken = (liveId: number) => {
		return LiveLessonService.getToken(liveId).then((response) => {
			console.log('useLiveSession::getToken', response)

			return response.token
		})
	}

	return {
		prepareLiveLesson,
		joinToLiveLesson,
		leaveLiveLesson,
		toggleCamera,
	}
}

export default useLiveSession
