import React, { ReactElement, useRef } from 'react'
import { useCreateMarkupAndSetToTextArea } from './fn'
import { ExerciseType } from '../../GapsInTextForm/fn/stateType'
import './GapsFlasher.scss'

type GapsFlasherProps = {
	exerciseType: ExerciseType
	children: ReactElement // Редактор HTML оборачиваемым компонентом
	editorHtmlStr: string // Строка HTML из редактора
}

/**
 * Обёртка редактора добавляющая элемент ниже редактора где находятся <span> — ы
 * подсвечивающие слова заключённые в фигурные скобки.
 */
function GapsFlasher(props: GapsFlasherProps) {
	const { exerciseType, children, editorHtmlStr } = props
	const textAreaRef = useRef<null | HTMLDivElement>(null)

	// Сформировать разметку подсвечивающую слова в фигурных скобках
	useCreateMarkupAndSetToTextArea(textAreaRef, editorHtmlStr, exerciseType)

	return (
		<div className="html-editor-rewrite gaps-flasher">
			<div
				className="ql-container gaps-flasher__text-area"
				ref={textAreaRef}
			/>
			<div className="gaps-flasher__editor-wrapper">{children}</div>
		</div>
	)
}

export default GapsFlasher
