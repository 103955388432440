import useExerciseHeaderStudentStore from '../zustand/store'

/** Возвращает заголовок страницы и описание курса */
export function useGetHeaderTexts() {
	const lesson = useExerciseHeaderStudentStore((state) => state.lesson)

	return {
		pageHeader: lesson.name,
		pageDescription: lesson.description || '',
	}
}
