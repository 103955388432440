import React, { useContext } from 'react'
import { FormInstance, UploadFile } from 'antd'
import { translite } from 'parts/utils/string'
import { StateContext } from '../../state/stateContext'
import { createFileListFromUrlList } from 'ui/upload/UploadFileInForm/fn/fn'

export enum FieldNames {
	SchoolName = 'schoolName',
	Domain = 'domain',
	Description = 'description',
	Cover = 'cover',
	Oferta = 'oferta',
}

export interface FormValuesType {
	[FieldNames.SchoolName]: string
	[FieldNames.Domain]: string
	[FieldNames.Description]: string
	[FieldNames.Cover]: UploadFile[]
	[FieldNames.Oferta]: UploadFile[]
}

/**
 * Хук следит за изменением значения поля имени школы и ставит в поле домена школе транслитерированное значение домена.
 * @param {Object} form — объект формы
 */
export function useGetOnNameInputChange(form: FormInstance) {
	return function (e: React.ChangeEvent<HTMLInputElement>) {
		const transliteratedSchoolName = translite(e.target.value)
			.toLowerCase()
			.replaceAll(' ', '')

		form.setFields([
			{
				name: 'domain',
				value: transliteratedSchoolName,
				errors: [],
			},
		])
	}
}

/** Возвращает первоначальные значения полей формы редактирования сотрудника. */
export function useGetInitialValues(): FormValuesType {
	const { state, setState } = useContext(StateContext)

	const school = state.school!

	let domain = school.domain.split('.')[0]

	return {
		[FieldNames.SchoolName]: school.name,
		[FieldNames.Domain]: domain,
		[FieldNames.Description]: school.description || '',
		[FieldNames.Cover]: school.cover
			? createFileListFromUrlList([school.cover])
			: [],
		[FieldNames.Oferta]: school.oferta
			? createFileListFromUrlList([school.oferta])
			: [],
	}
}
