import React from 'react'
import AppUrls from 'parts/constants/pageUrl'
import { useTranslation } from 'react-i18next'
import NoContent from 'ui/NoContent/NoContent'

// Компонент сообщающий о наличии ошибки при загрузке данных курса
function HasErrorScreen() {
	const { t } = useTranslation()

	return (
		<NoContent
			header={t('adminTraining.wrongTrainingScreenHeader')}
			text={t('adminTraining.wrongTrainingScreenText')}
			btnConf={{
				text: t('adminTraining.wrongTrainingScreenRedirectButton'),
				to: AppUrls.Trainings().url,
			}}
		/>
	)
}

export default HasErrorScreen
