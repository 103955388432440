import React from 'react'
import { Button } from 'antd'
import cn from 'classnames'
import dayjs from 'dayjs'
import { DeleteOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import micReadySrc from './images/micReady.svg'
import micRecordingSrc from './images/micRecording.svg'
import playSrc from './images/play.svg'
import pauseSrc from './images/pause.svg'
import waveSrc from './images/wave.svg'
import waveTomatoSrc from './images/waveTomato.svg'
import './AudioPlayer.scss'

type AudioPlayerProps = {
	lifeStatus: AudioPlayerLifeStatus
	seconds: number
	onRecord: () => void
	onStopRecord: () => void
	onPlay: () => void
	onPause: () => void
	onDelete: () => void
	onSend: () => void
}
export type AudioPlayerLifeStatus =
	| 'empty' // В микрофон ещё ничего не записано
	| 'recording' // Идёт запись
	| 'stop' // Запись остановлена
	| 'play' // Запись воспроизводится

// Магнитофон для записи или воспроизведения записанного звука
function AudioPlayer(props: AudioPlayerProps) {
	return (
		<section className="exercise-audio-player">
			<div className="exercise-audio-player__left">
				<LeftCircle {...props} />
				<SoundWave {...props} />
				<Timer {...props} />
			</div>
			<div className="exercise-audio-player__right">
				<RightPart {...props} />
			</div>
		</section>
	)
}

export default AudioPlayer

// Элемент с изображением микрофона или кнопки запуска воспроизведения или паузы
function LeftCircle(props: AudioPlayerProps) {
	const { lifeStatus, onPlay, onPause } = props

	if (lifeStatus == 'empty') {
		return <img src={micReadySrc} alt="Начать запись" />
	} else if (lifeStatus == 'recording') {
		return (
			<div className="exercise-audio-player__rec-circle">
				<div className="exercise-audio-player__rec-flame-circle exercise-audio-player__rec-flame-circle--delay" />
				<div className="exercise-audio-player__rec-flame-circle" />
				<img
					src={micRecordingSrc}
					className="exercise-audio-player__rec-pic-circle"
					alt="Идёт запись"
				/>
			</div>
		)
	} else if (lifeStatus == 'stop') {
		return (
			<button
				className="exercise-audio-player__action-circle"
				onClick={onPlay}
			>
				<img src={playSrc} alt="Пауза" />
			</button>
		)
	} else {
		return (
			<button
				className="exercise-audio-player__action-circle"
				onClick={onPause}
			>
				<img src={pauseSrc} alt="Пауза" />
			</button>
		)
	}
}

// Звуковая волна
function SoundWave(props: AudioPlayerProps) {
	const { lifeStatus } = props

	let waveUrl = waveSrc
	if (lifeStatus == 'recording') {
		waveUrl = waveTomatoSrc
	}

	let classes = ['exercise-audio-player__wave']
	if (lifeStatus == 'empty') {
		classes.push('exercise-audio-player__wave--pale')
	}

	return <img src={waveUrl} className={cn(classes)} alt="Звуковая волна" />
}

// Таймер
function Timer(props: AudioPlayerProps) {
	const { seconds } = props

	const time = dayjs(seconds * 1000).format('mm:ss')

	return <p className="exercise-audio-player__timer">{time}</p>
}

// Правая часть микрофона
function RightPart(props: AudioPlayerProps) {
	const { lifeStatus, onRecord, onStopRecord, onDelete, onSend } = props

	const { t } = useTranslation()

	if (lifeStatus == 'empty') {
		return (
			<Button
				onClick={onRecord}
				type="primary"
				className="exercise-audio-player__record-button"
				data-cy="record-audio-btn"
			>
				Записать
			</Button>
		)
	} else if (lifeStatus == 'recording') {
		return (
			<div className="exercise-audio-player__divider-wrapper">
				<p className="exercise-audio-player__record-text">
					Идёт запись
				</p>
				<div className="exercise-audio-player__divider" />
				<Button
					onClick={onStopRecord}
					data-cy="stop-recording-audio-btn"
				>
					Стоп
				</Button>
			</div>
		)
	} else if (lifeStatus == 'stop' || lifeStatus == 'play') {
		return (
			<div className="exercise-audio-player__divider-wrapper">
				<Button
					icon={<DeleteOutlined />}
					danger
					onClick={onDelete}
					className="exercise-audio-player__delete"
				/>
				<div className="exercise-audio-player__divider" />
				<Button onClick={onSend} data-cy="send-recording-audio-btn">
					Отправить
				</Button>
			</div>
		)
	}

	return null
}
