import React from 'react'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'

/**
 * Возвращает обработчик изменения текста заголовка
 * @param tariffInnerId — внутренний идентификатор тарифа
 * @param priceType — тип изменяемой цены: текущая или старая
 */
export function useGetChangeMainPrice(
	tariffInnerId: number,
	priceType: 'price' | 'oldPrice'
) {
	return function (e: React.SyntheticEvent<HTMLHeadingElement>) {
		const paragraphElement = e.target as HTMLHeadingElement
		// Нужно умножать на 100 потому что сервер хранит целые значения
		const price = paragraphElement.innerText

		tariffsManager.changeMainPriceTextAndSetToStore(
			tariffInnerId,
			price,
			priceType
		)

		// Сохранить на сервере
		tariffsManager?.setNeedToSave()
	}
}

/**
 * Обработчик нажатия клавиш при вводе цены. Предотвращает печать нечисловых символов.
 * @param e — объект события нажатия клавиш.
 */
export function keyDownPriceHandler(
	e: React.KeyboardEvent<HTMLParagraphElement>
) {
	const allowedButtons = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight']

	for (let i = 0; i < 10; i++) {
		allowedButtons.push(i.toString())
	}

	if (allowedButtons.includes(e.key)) return
	e.preventDefault()
}
