import AddStudentModal from './AddStudentModal'
import useAddStudentStore from './zustand/store'

export const AddStudentModalController = {
	element: <AddStudentModal />,
	open() {
		const updateStore = useAddStudentStore.getState().updateStore
		updateStore({ isOpen: true })
	},
	close() {
		const updateStore = useAddStudentStore.getState().updateStore
		updateStore({ isOpen: false })
	},
}
