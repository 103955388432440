import { AxiosResponse } from 'axios'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryClient, useQueryClient } from 'react-query'
import { message } from 'antd'
import { exercisesQuery } from 'parts/requests/exercises/exercisesQuery'
import exercisesRequests from 'parts/requests/exercises/exercisesRequests'
import { lessonsQuery } from 'parts/requests/lessons/lessonsQuery'
import ExerciseService from 'parts/services/ExerciseService'
import UrlService from 'parts/services/UrlService'
import { exercisesFormsManager } from '../../../exercisesFormsManager'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import useExercisesListAdminStore from '../../../zustand/store'

export function useGetCloseModalFn() {
	return useCallback(function () {
		exercisesFormsManager.clearStore()
	}, [])
}

/**
 * Хук возвращает функцию ставящую новое или обновлённое упражнение в курс и сохраняющее его на сервере.
 * @param {Function} closeModalFn — функция закрывающая модальное окно
 */
export function useGetSaveExerciseFn(closeModalFn: () => void) {
	const lessonId = UrlService.useGetLessonId()
	const queryClient = useQueryClient()
	const { t } = useTranslation()

	const createExerciseMutation = exercisesQuery.createExercise.useMutation({
		onError: getMutateOnError('Не удалось создать упражнение.'),
		onSuccess: getMutateOnSuccess(queryClient, lessonId, true),
	})

	const updateExerciseMutation = exercisesQuery.updateExercise.useMutation({
		onError: getMutateOnError('Не удалось обновить упражнение.'),
		onSuccess: getMutateOnSuccess(queryClient, lessonId, false),
	})

	return async function (exercise: TrainingEntityTypes.ExerciseItem) {
		if (exercise.id) {
			updateExerciseMutation.mutate(exercise)
		} else {
			const preparedExercise = prepareExercise(exercise)
			createExerciseMutation.mutate(preparedExercise)
		}

		closeModalFn()
	}
}

function prepareExercise(
	exercise: TrainingEntityTypes.ExerciseItem
): TrainingEntityTypes.ExerciseItem {
	const exercises = useExercisesListAdminStore.getState().exercises
	const insertBeforeExerciseId =
		useExercisesListAdminStore.getState().insertBeforeExerciseId

	if (insertBeforeExerciseId) {
		const beforeExerciseIdx = ExerciseService.getExerciseIdxInExercises(
			exercises,
			insertBeforeExerciseId
		)

		if (beforeExerciseIdx < 0) return exercise

		return { ...exercise, order: beforeExerciseIdx }
	}

	return {
		...exercise,
		order: exercises.length,
	}
}

/** Функция, выполняемая при появлении ошибки после запроса на удаление урока */
function getMutateOnError(messageText: string) {
	return function (err: unknown) {
		message.error(messageText)
	}
}

/**
 * Функция, выполняемая при успешном запросе добавления или редактирования упражнения.
 * @param queryClient — клиент React Query
 * @param lessonId — id урока, где будет упражнение.
 * @param isNewExercise
 */
function getMutateOnSuccess(
	queryClient: QueryClient,
	lessonId: number,
	isNewExercise: boolean
) {
	return function (
		exerciseRes: AxiosResponse<TrainingEntityTypes.ExerciseItem>
	) {
		const thisExercise = exerciseRes.data
		const exercises = useExercisesListAdminStore.getState().exercises
		const updateStore = useExercisesListAdminStore.getState().updateStore

		const updatedExercises = [...exercises]
		updatedExercises.splice(
			thisExercise.order,
			isNewExercise ? 0 : 1,
			thisExercise
		)

		updatedExercises.forEach((exercise, i) => {
			if (exercise.order === i) {
				return
			}

			exercise.order = i

			exercisesRequests.updateExercise({
				id: exercise.id,
				order: i,
			})
		})

		updateStore({
			exercises: updatedExercises,
			newExerciseId: thisExercise.id,
		})

		setTimeout(() => {
			// Пометить урок неактуальным. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [lessonsQuery.getLesson(lessonId).key],
			})
		}, 1000)
	}
}
