const mySchoolsEn = {
	// СТАТЬЯ СО СПИСКОМ ШКОЛ
	// Кнопка создания новой школы
	schoolsArticleAddSchoolButton: 'Create a school',
	// Сообщение про отсутствие школ (первая строка)
	schoolsArticleNoSchoolsFirstLine: "You don't have schools.",
	// Сообщение про отсутствие школ (вторая строка)
	schoolsArticleNoSchoolsSecondLine: 'Now is the time to create it! ',

	// МОДАЛЬНОЕ ОКНО ДОБАВЛЕНИЯ НОВОЙ ШКОЛЫ
	// Заголовок модального окна
	addSchoolModalHeader: 'Creation of a school',
	// Подпись поля «Название школы»
	addSchoolModalFieldSchoolNameLabel: 'Name of school',
	// Сообщение об обязательности заполнения поля «Название школы»
	addSchoolModalFieldSchoolNameRequired: 'Write the name',
	// Подпись поля «Домен школы»
	addSchoolModalFieldDomainLabel: "The school's domain",
	// Сообщение как писать домен
	addSchoolModalFieldDomainNote:
		'It is possible to use Latin characters, numbers or hyphens. Maximum 15 characters.',
	// Кнопка отмены изменения формы
	addSchoolModalCancelButton: 'Cancellation',
	// Кнопка создания новой школы
	addSchoolModalOkButton: 'Create school',
	// Сообщение про невозможность создать школу из-за ошибки сервера
	addSchoolModalCannotCreateSchool: 'It was not possible to create a school.',
	// Сообщение про успешное создание школы на сервере
	addSchoolModalSchoolCreated: 'A new school has been created.',

	// МОДАЛЬНОЕ ОКНО РЕДАКТИРОВАНИЯ ШКОЛЫ
	// Подпись поля «Название школы»
	editSchoolModalFieldSchoolNameLabel: 'Name of school',
	// Подпись поля «Название школы»
	editSchoolModalFieldDomainLabel: "The school's domain",
	// Подпись поля «Название школы»
	editSchoolModalFieldDomainNote:
		'It is possible to use Latin characters, numbers or hyphens. Maximum 15 characters.',
	// Подпись поля «Описание»
	editSchoolModalFieldDescriptionLabel: 'Description',
	// Подпись поля «Оферта»
	editSchoolModalFieldOfferLabel: 'Offer description ',
	// Кнопка удаления школы
	editSchoolModalDeleteSchoolButton: 'Delete the school',
	// Кнопка сохранения школы
	editSchoolModalSaveSchoolButton: 'Save',

	// МОДАЛЬНОЕ ОКНО УДАЛЕНИЯ ШКОЛЫ
	// Заголовок модального окна удаления школы
	deleteSchoolModalHeader: 'Удаление школы',
	// Подпись поля с названием удаляемой школы
	deleteSchoolModalFieldNameLabel: 'To remove a school, enter its name',
	// Сообщение с просьбой написать название удаляемой школы
	deleteSchoolModalFieldNameRequired:
		'Write the name of the school to be removed',
	// Первая строка сообщения ниже поля ввода названия удаляемой школы
	deleteSchoolModalFieldNameBeAwareFirst: 'Pay attention!',
	// Вторая строка сообщения ниже поля ввода названия удаляемой школы
	deleteSchoolModalFieldNameBeAwareSecond:
		'Deletion may result in the loss of all school data.',
	// Кнопка отмены удаления школы
	deleteSchoolModalCancelButton: 'Cancellation',
	// Кнопка подтверждения удаления школы
	deleteSchoolModalOkButton: 'Delete the school',
	// Сообщение об успешном удалении школы
	deleteSchoolModalSuccessMessage: 'School deleted.',
}

export default mySchoolsEn
