import React from 'react'
import MainImage from 'pages/landing/landingConstructor/sections/existenceSection/MainImage/MainImage'
import ExistenceBlocks from 'pages/landing/landingConstructor/sections/existenceSection/ExistenceBlocks/ExistenceBlocks'
import AddNewBlockSection from 'pages/landing/landingConstructor/sections/existenceSection/AddNewBlockSection/AddNewBlockSection'
import 'pages/landing/landingConstructor/sections/existenceSection/SectionContent/SectionContent.scss'

function ExistenceContent() {
	return (
		<div className="land-const-existence-section-content">
			<MainImage />
			<div>
				<ExistenceBlocks />
				<AddNewBlockSection />
			</div>
		</div>
	)
}

export default ExistenceContent
