import { useEffect } from 'react'
import useTrainingsStudentStore from '../store'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import { convertTrainingsToCardsDataForStore } from './dataConverter'

/** Делает запрос на получение списка школ и текущей школы, формирует данные для Состояния и ставит в него */
export function useFillInStore() {
	const updateStore = useTrainingsStudentStore((state) => state.updateStore)

	// Получить список курсов
	const { data } = trainingQuery.getTrainingsForStudent.useQuery({
		onError: queryError,
	})

	useEffect(
		function () {
			if (!data) return

			const cardsForStore = convertTrainingsToCardsDataForStore(data.data)

			updateStore({
				trainingsStudent: cardsForStore,
				dataLoadingStatus: data.data.length ? 'hasData' : 'noData',
			})
		},
		[data]
	)
}

/**
 * Функция запускаемая при ошибке запроса данных. Срабатывает для любых запросов.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 * @param err — объект ошибки.
 */
function queryError(err: unknown) {
	const { updateStore } = useTrainingsStudentStore.getState()

	updateStore({ dataLoadingStatus: 'error' })
}
