import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import Loader from 'ui/Loader/Loader'
import { ExerciseArticleControllerNew } from '../ExerciseArticleNew/ExerciseArticleControllerNew'
import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import NoContent from 'ui/NoContent/NoContent'
import AppUrls from 'parts/constants/pageUrl'
import { ExercisePageHeaderController } from '../ExercisePageHeader/ExercisePageHeaderController'

function ExerciseStudentPageNew() {
	const pageHeaderRouter = ExercisePageHeaderController.router()
	const exerciseArticleRouter = ExerciseArticleControllerNew.router()

	if (
		pageHeaderRouter.status === 'loading' ||
		exerciseArticleRouter.status === 'loading'
	) {
		return (
			<ArticleContentWrapper center>
				<Loader />
			</ArticleContentWrapper>
		)
	} else if (
		pageHeaderRouter.status === 'noData' &&
		exerciseArticleRouter.status === 'noTraining'
	) {
		return (
			<ArticleContentWrapper center>
				<NoContent
					header="Упражнения не существует"
					text="Оно было удалено или введён неправильный адрес."
					btnConf={{
						text: 'Перейти на Мои курсы',
						to: AppUrls.Trainings().url,
					}}
				/>
			</ArticleContentWrapper>
		)
	}

	return (
		<PageSkeleton>
			{pageHeaderRouter.element}
			{exerciseArticleRouter.element}
		</PageSkeleton>
	)
}

export default ExerciseStudentPageNew
