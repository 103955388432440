import { WebSocketService } from 'parts/services/WebSocketService'
import useLiveSidepanelStore from '../zustand/store'
import useSystemStore from 'parts/systemStore/systemStore'

function useSendToChat() {
	const user = useSystemStore((s) => s.user)
	const addChatMessage = useLiveSidepanelStore((s) => s.addChatMessage)

	const sendMessage = (message: string) => {
		if (message) {
			const msg = {
				from: user,
				body: message,
				my: true,
			}
			return WebSocketService.getWebSocketService()
				.sendChatMessage(msg)
				.then(() => {
					addChatMessage(msg)
				})
		}

		return Promise.resolve()
	}

	const sendFile = (message: string, link: string) => {
		if (message) {
			const msg = {
				from: user,
				body: message,
				link,
				my: true,
			}
			return WebSocketService.getWebSocketService()
				.sendChatMessage(msg)
				.then(() => {
					addChatMessage(msg)
				})
		}

		return Promise.resolve()
	}

	return {
		sendFile,
		sendMessage,
	}
}

export default useSendToChat
