import { useTranslation } from 'react-i18next'
import CreateNewCard from 'ui/CreateNewCard/CreateNewCard'
import { exercisesAsideManager } from '../ExercisesAside/zustand/exercisesAsideManager'
import './AddExerciseBottomButton.scss'

// Кнопка открывающая модальное окно добавления нового курса
function AddExerciseBottomButton() {
	const { t } = useTranslation()

	return (
		<CreateNewCard
			text="Добавить упражнение"
			onClick={exercisesAsideManager.open}
			extraClass="add-exercise-bottom-button"
		/>
	)
}

export default AddExerciseBottomButton
