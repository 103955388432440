import { useMemo } from 'react'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import AddLiveButton from '../../../rooms/AddLiveRoomButton/AddLiveButton'

export function useGetButtons() {
	const userRoles = useGetUserRole()

	return useMemo(function () {
		if (!userRoles.isAdmin) return []

		return [<AddLiveButton key="1" />]
	}, [])
}
