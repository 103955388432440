import { AxiosError, AxiosResponse } from 'axios'
import { schoolQuery } from 'parts/requests/school/schoolQuery'
import AutoLogin from 'parts/services/AutoLogin'
import { UseMutationResult } from 'react-query'
import AuthApiTypes from 'parts/requests/auth/authApiTypes'
import { authQuery } from 'parts/requests/auth/authQuery'
import AppUrls from 'parts/constants/pageUrl'
import { MakeSchoolStoreType } from '../zustand/storeTypes'
import ServerTypes from 'parts/types/ServerTypes'
import { FieldNames, FormValuesType } from './form'
import useMakeSchoolStore from '../zustand/store'
import SchoolApiTypes from 'parts/requests/school/schoolApiTypes'
import useRegisterAdminStore from '../../../registerOwnerStart/RegistrationForm/zustand/store'
import Site from 'parts/constants/site'

/**
 * Хук возвращающий обработчик отправки формы создания школы.
 * Тут последовательно Создаётся школа -> Авторизовывается новый администратор -> Генерируется токен для авторизации.
 * Авторизация требуется для метода генерирования токена для авторизации.
 * Этот токен требуется чтобы при переадресации на сайт школы вписать его в адресную строку.
 * И уже сайт школы с помощью токена авторизовывает пользователя.
 * */
export function useGetOnSubmit() {
	const updateFormStore = useMakeSchoolStore((store) => store.updateStore)
	const ownerId = useRegisterAdminStore((state) => state.userId)!
	const email = useRegisterAdminStore((state) => state.email)
	const password = useRegisterAdminStore((state) => state.password)

	// Запрос на генерирование токена, который будет встроен в адрес сайта школы
	const generateTokenQuery = authQuery.generateAuthToken.useMutation({
		onSuccess: getGenerateTokenMutateOnSuccess(),
	})

	// Запрос на авторизацию администратора.
	// Требуется в дальнейшем для генерирования токена для передачи в адресе
	// для последующей автоматической авторизации на сайте школы.
	const loginQuery = authQuery.login.useMutation({
		onSuccess: loginMutateOnSuccess(generateTokenQuery),
	})

	// Запрос на добавление школы
	const addSchoolQuery = schoolQuery.addSchool.useMutation({
		onMutate: getAddSchoolMutateOnBefore(updateFormStore),
		onError: getMutateOnError(updateFormStore),
		onSuccess: addSchoolMutateOnSuccess(
			email,
			password,
			loginQuery,
			updateFormStore
		),
	})

	return async (fieldsValue: FormValuesType) => {
		const addSchoolDto = getMakeSchoolDto(fieldsValue, ownerId)
		// Сделать запрос на создание школы
		addSchoolQuery.mutate(addSchoolDto)
	}
}

/**
 * Формирует DTO для отправки запроса
 * @param fieldsValue — значения полей формы
 * @param ownerId — id администратора, которому будет принадлежать школа.
 */
function getMakeSchoolDto(
	fieldsValue: FormValuesType,
	ownerId: number
): SchoolApiTypes.AddSchoolDto {
	const newSchoolDomain =
		fieldsValue[FieldNames.Domain].toLowerCase() + '.' + Site.rootDomain

	return {
		name: fieldsValue[FieldNames.SchoolName], // "English school",
		domain: newSchoolDomain, // "irinaschool.com"
		oferta: '',
		// id пользователя, которому принадлежит школа
		ownerId,
	}
}

/**
 * Функция, выполняемая до запроса на создание школы
 * @param updateFormStore — функция, изменяющая объект состояния страниц регистрации администратора
 */
function getAddSchoolMutateOnBefore(
	updateFormStore: MakeSchoolStoreType.UpdateStore
) {
	return function () {
		// Поставить статус загрузки
		updateFormStore({ isSubmitting: true })
	}
}

/** Функция, выполняемая при успешном запросе на добавление домена школы. */
function addSchoolMutateOnSuccess(
	email: string,
	password: string,
	loginQuery: UseMutationResult<
		AxiosResponse<AuthApiTypes.Login, any>,
		any,
		AuthApiTypes.LoginDto,
		any
	>,
	updateFormStore: MakeSchoolStoreType.UpdateStore
) {
	return function (
		makeSchoolResp: AxiosResponse<SchoolApiTypes.AddSchoolByAuthorizedUser>
	) {
		// Получить домен новой школы и поставить в Хранилище.
		// Потребуется при переадресации на домен школы
		const { domain } = makeSchoolResp.data // "school.credu.ai"

		// Авторизовать пользователя
		loginQuery.mutate({ email, password })

		updateFormStore({ schoolDomain: domain })
	}
}

// =================

/** Функция, выполняемая при успешном запросе авторизации пользователя. */
function loginMutateOnSuccess(
	generateTokenQuery: UseMutationResult<AxiosResponse<string>>
) {
	return function () {
		setTimeout(() => {
			generateTokenQuery.mutate({})
		}, 0)
	}
}

// =================

/** Функция, выполняемая при успешном запросе на генерирование токена для последующей авторизации */
function getGenerateTokenMutateOnSuccess() {
	return function (
		generatedTokenRes: AxiosResponse<AuthApiTypes.GenerateAuthToken>
	) {
		const { schoolDomain } = useMakeSchoolStore.getState()

		// Получить токен для автоматической авторизации на сайте школы
		const generatedToken = generatedTokenRes.data

		// Вписать в url школы токен и отправить туда
		const queryUrl =
			AutoLogin.createQueryUrlWithGeneratedToken(generatedToken)
		const url =
			Site.protocol + schoolDomain + AppUrls.Main().url + '?' + queryUrl

		window.location.href = url
	}
}

// =================

/**
 * Универсальная функция, выполняемая при появлении ошибки после запроса
 * @param updateFormStore — функция, изменяющая объект состояния страниц регистрации администратора
 */
function getMutateOnError(updateFormStore: MakeSchoolStoreType.UpdateStore) {
	return function (err: unknown) {
		const error = err as AxiosError<ServerTypes.ErrorResponse>

		if (error.response) {
			// Поставить данные ошибки в Состояние, чтобы показать их в форме
			updateFormStore({ formErrors: error.response.data })
		}
	}
}
