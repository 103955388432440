import { MessageInstance } from 'antd/es/message/interface'

export function useCopyTextToClipboard(messageApi: MessageInstance) {
	return function (text: string) {
		if (navigator.clipboard) {
			navigator.clipboard.writeText(text).then(
				function () {
					// message.info('Ссылка скопирована')
					messageApi.open({
						type: 'success',
						content: 'Ссылка скопирована',
						style: {
							marginTop: '90vh',
						},
					})
				},
				function (err) {
					console.error('Async: Could not copy text: ', err)
				}
			)
		}
	}
}
