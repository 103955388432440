import { Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { UFormNewRow } from 'ui/UForm/UForm'
import ScreenWrapper from '../ScreenWrapper/ScreenWrapper'
import { FieldNames } from './fn/form'
import { useGetOnSubmit } from './fn/onSubmit'

// Форма ввода почты чтобы по ней узнать статус почты и роль пользователя
function EmailFormScreen() {
	const { t } = useTranslation()
	const [form] = Form.useForm()

	const onSubmit = useGetOnSubmit()

	return (
		<ScreenWrapper
			header={t('live.liveEntranceEmailFormScreenHeader')}
			description={t('live.liveEntranceEmailFormScreenDescription')}
		>
			<Form
				form={form}
				name="enterByEmail"
				layout="vertical"
				onFinish={onSubmit}
			>
				<EmailInput />
				<Bottom />
			</Form>
		</ScreenWrapper>
	)
}

export default EmailFormScreen

function EmailInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.Email}
				label={t('live.liveEntranceEmailFormInputEmailLabel')}
				rules={[
					{
						type: 'email',
						message: t(
							'live.liveEntranceEmailFormInputEmailWrongEmail'
						),
					},
					{
						required: true,
						message: t(
							'live.liveEntranceEmailFormInputEmailRequired'
						),
					},
				]}
			>
				<Input
					type="email"
					placeholder="mail@mail.com"
					size="large"
					autoComplete="username"
				/>
			</Form.Item>
		</UFormNewRow>
	)
}

function Bottom() {
	const { t } = useTranslation()
	const form = Form.useFormInstance()

	return (
		<UFormNewRow topOffset>
			<Form.Item shouldUpdate>
				{() => {
					const formErrors = form
						.getFieldsError()
						.filter(({ errors }) => errors.length)

					return (
						<Button
							block
							size="large"
							type="primary"
							htmlType="submit"
							disabled={formErrors.length > 0}
							// loading={loading}
						>
							{t('live.liveEntranceEmailFormOkBtn')}
						</Button>
					)
				}}
			</Form.Item>
		</UFormNewRow>
	)
}
