export const UrlQueries = {
	errorText: 'errorText',
	// Дата и время платежа
	paymentDate: 'paymentDate',
	planName: 'planName',
	sum: 'sum',
	invoice: 'invoice',
}

export function getTextsFromUrlQueries() {
	const url = new URL(location.href)

	return {
		errorText:
			url.searchParams.get(UrlQueries.errorText) ||
			'При оплате произошла неизвестная ошибка',
		paymentDate:
			url.searchParams.get(UrlQueries.paymentDate) || 'Неизвестно',
		planName: url.searchParams.get(UrlQueries.planName) || 'Неизвестно',
		sum: url.searchParams.get(UrlQueries.sum) || 'Неизвестно',
		invoice: url.searchParams.get(UrlQueries.invoice) || 'Не найдена',
	}
}
