import { useTranslation } from 'react-i18next'
import RoundIconAndTextCard from 'ui/RoundIconAndTextCard/RoundIconAndTextCard'
import useStudentsStatsStore from './zustand/store'
import { TeamOutlined, UserAddOutlined } from '@ant-design/icons'
import './StudentsStats.scss'

// Статистика по успешным покупкам за всё время и за сегодня
function StudentsStatsAdmin() {
	const { t } = useTranslation()
	const stats = useStudentsStatsStore((store) => store)

	return (
		<div className="students-stats">
			<RoundIconAndTextCard
				icon={<TeamOutlined />}
				header={t('students.studentsStatsTotalStudentsHeader')}
				num={stats.totalStudentsNum}
			/>
			<RoundIconAndTextCard
				icon={<UserAddOutlined />}
				header={t('students.studentsStatsTodayStudentsHeader')}
				num={stats.newStudentsNum}
			/>
		</div>
	)
}

export default StudentsStatsAdmin
