import { useTranslation } from 'react-i18next'
import Loader from 'ui/Loader/Loader'
import { useFillInStore } from '../../zustand/fillStore/fillInStore'
import useTrainingHeaderStudentStore from '../../zustand/store'
import { TrainingHeaderStudentStoreType } from '../../zustand/storeTypes'
import TrainingPageHeaderStudent from '../TrainingPageHeaderStudent'
import { useTrainingViewed } from './useTrainingViewed'

type RouterReturn = {
	status: TrainingHeaderStudentStoreType.DataLoadingStatus
	element: JSX.Element
}

/** Хук возвращающий статус компонента TrainingList и сам компонент в зависимости от статуса */
export function useGetTrainingPageHeaderRouter(): RouterReturn {
	const { t } = useTranslation()

	// Получить данные для отрисовки шапки и поставить в Состояние
	useFillInStore()

	// отправить на сервер уведомление о просмотре курса
	useTrainingViewed()

	const dataLoadingStatus = useTrainingHeaderStudentStore(
		(state) => state.dataLoadingStatus
	)

	if (dataLoadingStatus == 'loading') {
		return {
			status: dataLoadingStatus,
			element: <Loader />,
		}
	} else if (dataLoadingStatus === 'error') {
		return {
			status: dataLoadingStatus,
			element: <p>{t('studentTraining.errorWhileDownloadTraining')}</p>,
		}
	}

	return {
		status: dataLoadingStatus,
		element: <TrainingPageHeaderStudent />,
	}
}
