import { Alert, Button, Checkbox, Form, Space } from 'antd'
import {
	UFormNewBottomButtonsAfterGray,
	UFormNewButtonsContainer,
	UFormNewRow,
} from 'ui/UForm/UForm'
import ModalWithForm from 'ui/ModalWithForm/ModalWithForm'
import { FieldNames } from './fn/form'
import { useGetToggleVisibilityToCancelSubscriptionModal } from './fn/openCloseCancelSubscriptionModal'
import { useGetOnSubmit } from './fn/submit'
import useCancelSubscriptionModalStore from './zustand/store'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'

function CancelSubscriptionModal() {
	const [form] = Form.useForm()

	const isModalOpen = useCancelSubscriptionModalStore((store) => store.isOpen)
	const closeCancelSubscriptionModal =
		useGetToggleVisibilityToCancelSubscriptionModal()

	const onSubmit = useGetOnSubmit(closeCancelSubscriptionModal)

	return (
		<ModalWithForm
			title="Отмена подписки"
			open={isModalOpen}
			onCancel={closeCancelSubscriptionModal}
		>
			<Form
				form={form}
				name="removeSchool"
				onFinish={onSubmit}
				layout="vertical"
			>
				<GrayBlock>
					<Space size="middle" direction="vertical">
						<Alert
							message="При отмене подписки, ваши ученики утратят доступ ко всем материалам, которые вы создали."
							type="error"
						/>
						<Check />
					</Space>
				</GrayBlock>
				<Bottom />
			</Form>
		</ModalWithForm>
	)
}

export default CancelSubscriptionModal

// Обычное текстовое поле
function Check() {
	const updateModalState = useCancelSubscriptionModalStore(
		(store) => store.updateState
	)

	return (
		<>
			<UFormNewRow>
				<Form.Item
					name={FieldNames.IUnderstandConsequences}
					valuePropName="checked"
				>
					<Checkbox
						onChange={(e) => {
							updateModalState({
								IUnderstandConsequencesChecked:
									e.target.checked,
							})
						}}
					>
						Да, я понимаю все последствия отмены подписки и принимаю
						их
					</Checkbox>
				</Form.Item>
			</UFormNewRow>
		</>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom() {
	const form = Form.useFormInstance()
	const closeModal = useGetToggleVisibilityToCancelSubscriptionModal()
	const isSubmitting = useCancelSubscriptionModalStore(
		(store) => store.isSubmitting
	)
	const IUnderstandConsequencesChecked = useCancelSubscriptionModalStore(
		(store) => store.IUnderstandConsequencesChecked
	)

	return (
		<UFormNewBottomButtonsAfterGray>
			<div />
			<UFormNewButtonsContainer>
				<Button
					disabled={
						isSubmitting ||
						form
							.getFieldsError()
							.filter(({ errors }) => errors.length).length > 0
					}
					onClick={closeModal}
				>
					Отмена
				</Button>

				<Form.Item shouldUpdate>
					{() => {
						const formErrors = form
							.getFieldsError()
							.filter(({ errors }) => errors.length)

						return (
							<Button
								type="primary"
								htmlType="submit"
								danger
								disabled={
									isSubmitting ||
									formErrors.length > 0 ||
									!IUnderstandConsequencesChecked
								}
								loading={isSubmitting}
							>
								Отменить подписку
							</Button>
						)
					}}
				</Form.Item>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
