import { create } from 'zustand'
import { ProfileModalStoreeType } from './storeTypes'

const useProfileModalStore = create<ProfileModalStoreeType.State>((set) => {
	return {
		isModalOpen: false,
		isSubmitting: false,
		formErrors: {},

		/**
		 * Установщик новых значений корневого объекта.
		 * @param obj — объект со свойствами и значениями, которые должны быть изменены в Сотоянии
		 */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useProfileModalStore
