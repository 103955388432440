import { useTranslation } from 'react-i18next'
import usePasswordRecoveryStore from '../../../zustand/store'

export function useGetDescriptionText() {
	const { t } = useTranslation()

	const email = usePasswordRecoveryStore((store) => store.email)

	return t('auth.recoverSetPasswordPageLetterWasSent')
}
