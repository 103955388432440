import { useCallback } from 'react'
import { useGoToPage } from 'parts/utils/hooks/hooks'
import AppUrls from 'parts/constants/pageUrl'
import { CardDetailsType } from './cardDetails'
import UrlService from 'parts/services/UrlService'

/**
 * Возвращает обработчик щечка по карточку курса
 * @param {Object} cardDetails — объект с информацией для отрисовки карточки курса
 */
export function useGetGoToStatisticPage(cardDetails: CardDetailsType) {
	const studentId = UrlService.useGetStudentId()

	const goToStatisticsPage = useGoToPage(
		AppUrls.Student_Visit(studentId, cardDetails.groupId).url
	)

	return useCallback(function () {
		if (cardDetails.groupLiveStatus == 'notOpen') {
			return
		}

		goToStatisticsPage()
	}, [])
}
