/** Объект с регулярными выражениями */
export const regExps = {
	/**
	 * Нужно было чтобы это соответствовало таким строкам:
	 * +79261234567
	 * 89261234567
	 * 79261234567
	 * +7 926 123 45 67
	 * 8(926)123-45-67
	 * 123-45-67
	 * 9261234567
	 * 79261234567
	 * (495)1234567
	 * (495) 123 45 67
	 * 89261234567
	 * 8-926-123-45-67
	 * 8 927 1234 234
	 * 8 927 12 12 888
	 * 8 927 12 555 12
	 * 8 927 123 8 123
	 * Но по факту мне не удалось написать правильное регулярное выражение, поэтому сделал его максимально широким.
	 */
	phone: /.{6,20}/,
}
