import React from 'react'
import { Button, Input } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import {
	DescriptionImgExerciseFormStateType,
	FormMatchStateItemType,
} from './fn/formState'
import { removeMatchBlock } from './fn/submit'
import { DownloadImage } from './DownloadImage'
import { onChangeImageDescriptionInput } from './fn/changeForm'
import useExercisesListAdminStore from '../../zustand/store'

// Блок соответствий
export function MatchesBlocks() {
	const { t } = useTranslation()

	const formState = useExercisesListAdminStore(
		(store) => store.formData
	) as DescriptionImgExerciseFormStateType
	if (!formState) return null

	const matches = formState.matches.map((matchData) => {
		return (
			<MatchBlock
				key={matchData.id}
				matchData={matchData}
				showRemoveButton={formState.matches.length > 1}
			/>
		)
	})

	return (
		<>
			<h3 className="exercise-description-img-matches__header">
				Сопоставления
			</h3>
			{matches}
		</>
	)
}

type MatchBlockProps = {
	matchData: FormMatchStateItemType
	showRemoveButton: boolean
}

// Компонент соответствия картинки и описания
function MatchBlock(props: MatchBlockProps) {
	const { matchData, showRemoveButton } = props

	const { t } = useTranslation()

	return (
		<div className="exercise-description-img-match">
			<div className="exercise-description-img-match__content">
				<label className="exercise-description-img__label">
					Изображение
				</label>
				<DownloadImage matchData={matchData} />
				{matchData.noImageError && (
					<p className="exercise-description-img__input-error">
						Загрузите изображение
					</p>
				)}
				<ImageDescription matchData={matchData} />
			</div>
			{showRemoveButton && (
				<Button
					className="exercise-description-img-match__remove-button"
					shape="circle"
					icon={<MinusCircleOutlined />}
					onClick={(event) => removeMatchBlock(matchData.id)}
				/>
			)}
		</div>
	)
}

type ImageDescriptionProps = {
	matchData: FormMatchStateItemType
}

// Поле для ввода описания картинки
function ImageDescription(props: ImageDescriptionProps) {
	const { matchData } = props

	const { t } = useTranslation()

	return (
		<>
			<label className="exercise-description-img__label exercise-description-img__label--top-margin">
				Описание
			</label>
			<Input
				value={matchData.text}
				onChange={(event) =>
					onChangeImageDescriptionInput(event, matchData.id)
				}
			/>
			{matchData.noTextError && (
				<p className="exercise-description-img__input-error">
					Напишите описание изображения
				</p>
			)}
		</>
	)
}
