import useAddSchoolModalStore from 'pages/my/admin/mySchoolsAdmin/AddSchoolModal/zustand/store'

/** Возвращает функцию, ставящую видимость модального окна создания новой школы */
export function useSetVisibilityToAddSchoolModal(isVisible: boolean) {
	const updateStore = useAddSchoolModalStore((state) => state.updateStore)

	return function () {
		updateStore({ isOpen: isVisible })
	}
}
