import { TFunction } from 'i18next'
import { CardStickerColors } from 'ui/wrappers/CardSticker/CardSticker'
import { getRusNounByNumber } from 'parts/utils/string'
import LangService, { LangNames } from 'parts/i18n/LangService'
import { TrainingsStoreType } from '../../../zustand/storeTypes'

/**
 * Возвращает объект настроек для наклейки справа от карточки курса
 * @param cardDetails — объект с переработанными данными курса для отрисовки карточки курса
 * @param t — функция подставляющая текст на выбранном языке
 */
export function getStickerDetails(
	cardDetails: TrainingsStoreType.TrainingCard,
	t: TFunction<'translation'>
): {
	text: string
	color: CardStickerColors
} {
	if (cardDetails.groupId === 366) {
		return {
			text: t('studentTrainings.trainingCardStickerSoon'),
			color: CardStickerColors.Purple,
		}
	} else if (cardDetails.trainingStatus == 'notOpen') {
		return {
			text: t('studentTrainings.trainingCardStickerSoon'),
			color: CardStickerColors.Purple,
		}
	} else if (
		cardDetails.trainingStatus == 'openPartly' ||
		cardDetails.trainingStatus == 'openFully' ||
		cardDetails.trainingStatus == 'firstLessonOpeningWait'
	) {
		return {
			text: t('studentTrainings.trainingCardStickerAvailable'),
			color: CardStickerColors.Green,
		}
	} else {
		return {
			text: t('studentTrainings.trainingCardStickerFinished'),
			color: CardStickerColors.Orange,
		}
	}
}

/**
 * Возвращает строку с количеством невыполненных упражнений.
 * @param exercisesNum — количество невыполненных упражнений
 */
export function getUnperformedExercisesString(exercisesNum: number) {
	const lang = LangService.getCurrentLangFromLocalStorage()

	if (lang === LangNames.RU) {
		const exercisesNoun = getRusNounByNumber(
			exercisesNum,
			'упражнение',
			'упражнения',
			'упражнений'
		)

		return exercisesNum + ' ' + exercisesNoun
	} else if (lang === LangNames.EN) {
		if (exercisesNum === 0) {
			return 'No exercise'
		} else if (exercisesNum === 1) {
			return exercisesNum + ' exercise'
		} else {
			return exercisesNum + ' exercises'
		}
	}
}

/**
 * Возвращает строку с количеством невыполненных уроков.
 * @param lessonsNum — количество непрошедших уроков
 */
export function getUnperformedLessonsString(lessonsNum: number) {
	const lang = LangService.getCurrentLangFromLocalStorage()

	if (lang === LangNames.RU) {
		const lessonsNoun = getRusNounByNumber(
			lessonsNum,
			'уроке',
			'уроках',
			'уроках'
		)
		return lessonsNum + ' ' + lessonsNoun
	} else if (lang === LangNames.EN) {
		if (lessonsNum === 0) {
			return 'No lesson'
		} else if (lessonsNum === 1) {
			return lessonsNum + ' lesson'
		} else {
			return lessonsNum + ' lessons'
		}
	}
}
