import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { authQuery } from 'parts/requests/auth/authQuery'
import useConfirmFormStore from '../zustand/store'
import ServerTypes from 'parts/types/ServerTypes'
import { ConfirmFormStoreType } from '../zustand/storeTypes'
import { FormValuesType } from './form'
import AppUrls from 'parts/constants/pageUrl'

/** Хук возвращающий обработчик отправки формы */
export function useGetOnSubmit() {
	const updateFormStore = useConfirmFormStore((store) => store.updateStore)

	// Запрос удаление пользователя
	const deleteAccountRequestQuery = authQuery.deleteAccount.useMutation({
		onMutate: getDeleteAccountReqBeforeMutate(updateFormStore),
		onError: getMutateOnError(updateFormStore),
		onSuccess: getDeleteAccountReqSuccessMutate(),
	})

	return async (values: FormValuesType) => {
		deleteAccountRequestQuery.mutate({
			code: values.code,
		})
	}
}

/**
 * Функция, выполняемая до запроса на удаление аккаунта
 * @param updateFormStore — функция, изменяющая объект состояния формы входа
 */
function getDeleteAccountReqBeforeMutate(
	updateFormStore: ConfirmFormStoreType.UpdateStore
) {
	return function () {
		// Поставить статус загрузки
		updateFormStore({ isSubmitting: true })
	}
}

/**
 * Функция, выполняемая при успешном запросе на вход пользователя.
 * @param updateFormStore — функция, изменяющая объект состояния формы входа
 * @param generateTokenQuery — запрос на генерирование токена для автоматической авторизации
 */
function getDeleteAccountReqSuccessMutate() {
	const navigate = useNavigate()

	return function () {
		navigate(AppUrls.DeleteAccountDone().url)
	}
}

/**
 * Универсальная функция, выполняемая при появлении ошибки после запроса
 * @param updateFormStore — функция, изменяющая объект состояния формы входа пользователя
 */
function getMutateOnError(updateFormStore: ConfirmFormStoreType.UpdateStore) {
	return function (err: unknown) {
		const error = err as AxiosError<ServerTypes.ErrorResponse>

		if (error.response) {
			// Поставить данные ошибки в Состояние, чтобы показать их в форме
			updateFormStore({ formErrors: error.response.data })
		}

		updateFormStore({ isSubmitting: false })
	}
}
