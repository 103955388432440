import { Button, Switch } from 'antd'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import { useTranslation } from 'react-i18next'
import { UTableBCell, UTableBRow, UTableTextWithIcon } from 'ui/UTable/UTable'
import {
	BuildOutlined,
	DeleteOutlined,
	EditTwoTone,
	TeamOutlined,
} from '@ant-design/icons'
import DatesRange from 'ui/DatesRange/DatesRange'
import { useGetActivateGroupHandler } from 'widgets/groups/trainingGroupsTable/GroupsTable/fn/activation'
import { useGetGoToGroupPage } from 'widgets/groups/trainingGroupsTable/GroupsTable/fn/handlers'
import { GroupsTableView } from '../GroupsTable/GroupsTable'
import { GroupsStoreType } from '../zustand/storeTypes'
import { useGetDeleteGroup } from '../GroupsTable/fn/deleteGroup'

type TableRowProps = {
	tableView: GroupsTableView
	group: GroupsStoreType.Group
	trainingId: number
}

function TableRow(props: TableRowProps) {
	const { tableView, group, trainingId } = props

	const userRoles = useGetUserRole()

	return (
		<UTableBRow>
			<UTableBCell>
				<UTableTextWithIcon text={group.name} Icon={BuildOutlined} />
			</UTableBCell>
			<UTableBCell>
				<ActivationInteractive group={group} trainingId={trainingId} />
			</UTableBCell>
			{userRoles.isAdminOrManager && (
				<UTableBCell>
					<UTableTextWithIcon
						text={group.maxStudentsNum}
						Icon={TeamOutlined}
					/>
				</UTableBCell>
			)}
			{userRoles.isCurator && (
				<UTableBCell>
					<UTableTextWithIcon
						text={group.studentsNum}
						Icon={TeamOutlined}
					/>
				</UTableBCell>
			)}
			<UTableBCell>
				<Period group={group} />
			</UTableBCell>
			{userRoles.isAdminOrManager && (
				<UTableBCell>
					<GoToGroupPageButton
						trainingId={trainingId}
						group={group}
						tableView={tableView}
					/>
				</UTableBCell>
			)}
		</UTableBRow>
	)
}

export default TableRow

type CommonProps = {
	group: GroupsStoreType.Group
}

// Когда группа действует
function Period(props: CommonProps) {
	const { group } = props

	if (!group.startDate) {
		return <div className="all-groups-table__period-null">Не указано</div>
	}

	return (
		<div>
			<DatesRange
				dateFrom={group.startDate}
				dateTo={group.endDate}
				colored
			/>
		</div>
	)
}

type ActivationInteractiveProps = {
	trainingId: number
	group: GroupsStoreType.Group
}

// Переключатель активации группы (активный)
function ActivationInteractive(props: ActivationInteractiveProps) {
	const { trainingId, group } = props

	const activateGroup = useGetActivateGroupHandler(trainingId, group.groupId)

	return (
		<Switch
			checked={group.isActive}
			onChange={activateGroup}
			disabled={group.isActive}
		/>
	)
}

type GoToGroupPageButtonProps = {
	trainingId: number
	// Где будет показана таблицы: на странице групп или в карточке курса
	tableView: GroupsTableView
	group: GroupsStoreType.Group
}

// Кнопка открытия страницы группы
function GoToGroupPageButton(props: GoToGroupPageButtonProps) {
	const { trainingId, tableView, group } = props

	const { t } = useTranslation()
	const goToPage = useGetGoToGroupPage(group.trainingId, group.groupId)
	const deleteGroup = useGetDeleteGroup(trainingId, group.groupId)

	return (
		<div className="all-groups-table__actions-wrapper">
			<Button
				size="small"
				icon={<EditTwoTone />}
				type="link"
				onClick={goToPage}
			>
				Настроить
			</Button>
			{tableView == 'groupsPage' && (
				<Button
					size="small"
					icon={<DeleteOutlined />}
					type="link"
					danger
					onClick={deleteGroup}
				/>
			)}
		</div>
	)
}
