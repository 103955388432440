import { useEffect } from 'react'
import { Form } from 'antd'
import { FieldsNames } from './form'
import EntityTypes from 'parts/types/EntityTypes'

/** Возвращает булево значение видны ли поля с указанием даты и времени начала группы */
export function useIsStartDateInputVisible() {
	const form = Form.useFormInstance()

	const startConditionFieldValue = Form.useWatch(
		FieldsNames.StartCondition,
		form
	)

	return startConditionFieldValue == EntityTypes.GroupStartCondition.StartDate
}

/** Возвращает булево значение, соответствует ли текущий режим прохождения переданному */
export function useIsIntervalInputVisible(groupMode: EntityTypes.GroupMode) {
	const form = Form.useFormInstance()

	const passingModeFieldValue = Form.useWatch(FieldsNames.PassingMode, form)

	return passingModeFieldValue == groupMode
}

/**
 * В форме некоторые ряды с полями скрываются при переключении на другой режим даты начала обучения.
 * Но если они не заполнены, затем нажали на кнопку отправки, форма заблокировалась и переключили на другой режим даты начала обучения, то форма продолжает быть заблокированной.
 * Хук при скрытии поля проверить форму на правильность и если открытые поля верные разблокирует кнопку отправки формы.
 * @param isSectionVisible — видим ли ряд формы
 */
export function revalidateFormAfterSectionHiding(isSectionVisible: boolean) {
	const form = Form.useFormInstance()

	useEffect(
		function () {
			if (isSectionVisible) return
			form.validateFields()
		},
		[isSectionVisible]
	)
}
