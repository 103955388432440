import { create } from 'zustand'
import { LessonsListStoreType } from './storeTypes'

// Хранилище для данных курса.
const useLessonsListAdminStore = create<LessonsListStoreType.State>((set) => {
	return {
		dataLoadingStatus: 'loading',
		trainingId: undefined,
		lessons: undefined as any,

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useLessonsListAdminStore
