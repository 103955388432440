import { useMemo } from 'react'
import { useContext } from 'react'
import { StateContext } from '../../state/stateContext'
import PaymentTypes from 'parts/types/PaymentTypes'
import { StateType } from '../../state/stateType'

export type UKassaFormValuesType = {
	shopId: string
	secretKey: string
}

export type StripeFormValuesType = {
	apiKey: string
	webhookSecret: string
}

export type FormValuesType = UKassaFormValuesType | StripeFormValuesType

/** Возвращает первоначальные значения полей формы редактирования платёжной системы. */
export function useGetInitialValues() {
	const { state, setState } = useContext(StateContext)

	return useMemo(
		function (): null | FormValuesType {
			if (!state.initialFormData) return null

			if (state.paymentSystemType == PaymentTypes.System.UKassa) {
				const formValues =
					state.initialFormData as StateType.UKassaFormData

				return {
					shopId: formValues.fields.shopId.initValue,
					secretKey: formValues.fields.secretKey.initValue,
				}
			} else if (state.paymentSystemType == PaymentTypes.System.Stripe) {
				const formValues =
					state.initialFormData as StateType.StripeFormData

				return {
					apiKey: formValues.fields.apiKey.initValue,
					webhookSecret: formValues.fields.webhookSecret.initValue,
				}
			}

			return null
		},
		[state]
	)
}
