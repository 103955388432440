import React, { SyntheticEvent, useCallback, useEffect } from 'react'
import { getGapWords } from '../../ChooseWordView/fn/exercise'
import { ExerciseStateType } from './stateType'
import { getRusNounByNumber } from 'parts/utils/string'

/**
 * Функция создаёт HTML текстового блока с пропусками
 * @param {Object} editorRef — объект со ссылкой на блок текста с пропусками
 * @param {String} htmlString — строка с HTML из которой нужно создать разметку с пропусками
 */
export function useCreateInnerHtml(
	editorRef: React.MutableRefObject<null | HTMLElement>,
	htmlString: string
): void {
	useEffect(
		function () {
			const $editor = editorRef.current as HTMLElement
			if (!$editor) return

			// Получение массива пропущенных слов
			const gapWords = getGapWords(htmlString)

			// Создание строки с разметкой текстового блока с пропусками и вставка в редактор
			$editor.innerHTML = setGapsToHtmlString(gapWords, htmlString)
		},
		[htmlString]
	)
}

/**
 * Функция создаёт строку с разметкой текстового блока с пропусками.
 * Слова, которые должны быть в разметке пропусками обрамлены фигурными скобками.
 * И функция ставит вместо таких слов другую разметку в которую можно вписывать слова
 * @param {Array} gapWords — массив пропущенных слов
 * @param {String} htmlString — строка с HTML из которой нужно создать разметку с пропусками
 */
function setGapsToHtmlString(gapWords: string[], htmlString: string) {
	let newHtmlString = htmlString

	let gapWordsIdx = 0 // idx текущего слова из массива пропущенных слов
	let openBraceIdx = 0 // Номер буквы с которого начинается открывающая фигурная скобка
	let closeBraceIdx = 0 // Номер буквы с которого начинается закрывающая фигурная скобка

	for (let i = 0; i < newHtmlString.length; i++) {
		const letter = newHtmlString[i]

		if (letter == '{') {
			openBraceIdx = i
		} else if (letter == '}') {
			closeBraceIdx = i

			// Получить пропущенное слово из массива пропущенных слов
			const currentSlotWord = gapWords[gapWordsIdx++]
			// Создать разметку, которая должна быть вместо пропущенного слова
			const slotStr = createSlotStr(currentSlotWord)
			// Поставить разметку
			newHtmlString =
				newHtmlString.substring(0, openBraceIdx) +
				slotStr +
				newHtmlString.substring(closeBraceIdx + 1)

			// Увеличить счётчик на размер вставляемого слова чтобы не делать лишние проходы цикла
			i = i - currentSlotWord.length + slotStr.length
		}
	}

	return newHtmlString
}

/**
 * Возвращает строку разметки, в которой нужно заменить слово, которое должно быть пропуском.
 * Это <span> с contenteditable для возможности ввести слово.
 * А следом пишется правильное слово чтобы показать его если ученик не смог ввести правильный вариант.
 * @param {String} rightWord — правильное слово, которые нужно ввести в пропуск
 */
function createSlotStr(rightWord: string) {
	const editableSpan = `<span data-right="${rightWord}" class="exercise-drop-word-content__slot exercise-drop-word-content__slot--wide"></span>`
	const rightAnswerSpan = `<span class="exercise-drop-word-content__right-answer">${rightWord}</span>`
	return editableSpan + rightAnswerSpan
}

/**
 * Хук возвращает обработчик нажатия на кнопку просмотра результат
 * @param {Object} state — объект состояния упражнения
 * @param {Function} setState — функция устанавливающая новый объект состояния
 * @param {Object} textsRef — объект со ссылкой на обёртку текстов с пропусками
 */
export function useGetResultButtonFn(
	state: ExerciseStateType.Main,
	setState: React.Dispatch<React.SetStateAction<ExerciseStateType.Main>>,
	textsRef: React.MutableRefObject<HTMLDivElement | null>
) {
	return useCallback(
		function (event: SyntheticEvent) {
			const $texts = textsRef.current
			if (!$texts) return

			// Копия объекта состояния упражнения
			const stateCopy = { ...state }

			// Если упражнение готово к просмотру результата, то показать его
			if (stateCopy.readyToShowResult) {
				stateCopy.showResult = true
				setState(stateCopy)
				return
			}

			// Проверить введённые ответы
			const {
				allSlotsFilledRight,
				successAnswersCounter,
				totalSlotsCounter,
			} = checkSlots($texts)

			stateCopy.attempts--

			if (allSlotsFilledRight || !stateCopy.attempts) {
				// Показать правильные ответы если это требуется
				showRightAnswers($texts)

				stateCopy.readyToShowResult = true
				stateCopy.resultButtonText = 'Результат'

				stateCopy.rightAnswers = successAnswersCounter
				stateCopy.totalSlots = totalSlotsCounter
			}

			setState(stateCopy)
		},
		[state]
	)
}

/**
 * Функция получает все элементы пропуска
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
function get$Slots($texts: HTMLDivElement) {
	return $texts.querySelectorAll('.exercise-drop-word-content__slot')
}

/**
 * Функция перебирает пропуски и для каждого пропуска запускает переданную функцию, в которую передаёт элемент пропуска
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 * @param {Function} handler — функция запускаемая для каждого пропуска в которую передаёт элемент пропуска
 */
function enumerateSlots(
	$texts: HTMLDivElement,
	handler: ($gap: HTMLElement) => void
) {
	const $gaps = get$Slots($texts)

	for (let i = 0; i < $gaps.length; i++) {
		const $gap = $gaps[i]

		if ($gap.nodeType == 1) {
			handler($gap as HTMLElement)
		}
	}
}

/**
 * Функция проверяет введённые в пропуски слова и задаёт им классы в зависимости от правильности слова
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
function checkSlots($texts: HTMLDivElement) {
	let allSlotsFilledRight = true // Правильно ли заполнены все пропуски
	let successAnswersCounter = 0 // Количество правильно введённых слов

	enumerateSlots($texts, ($slot) => {
		// Получение правильного слова
		const rightWord = $slot.getAttribute('data-right') as string

		// Перетащенное слова
		const droppedWord = getDroppedWord($slot)

		const isWordsEqual = clearString(rightWord) === clearString(droppedWord)

		if (isWordsEqual) {
			setSuccessToSlot($slot)
			successAnswersCounter++
		} else {
			setErrorToSlot($slot)
			allSlotsFilledRight = false
		}
	})

	return {
		allSlotsFilledRight,
		successAnswersCounter,
		totalSlotsCounter: get$Slots($texts).length,
	}
}

function getDroppedWord($slot: HTMLElement): string {
	return $slot.innerText
}

function setSuccessToSlot($slot: HTMLElement) {
	$slot.classList.remove('exercise-drop-word-content__slot--fail')
	$slot.classList.add('exercise-drop-word-content__slot--success')

	const $button = $slot.children[0] as HTMLButtonElement

	if ($button) {
		$button.classList.remove('exercise-drop-word-content__word--error')
		$button.classList.add('exercise-drop-word-content__word--success')
	}
}

function setErrorToSlot($slot: HTMLElement) {
	$slot.classList.remove('exercise-drop-word-content__slot--success')
	$slot.classList.add('exercise-drop-word-content__slot--fail')

	const $button = $slot.children[0] as HTMLButtonElement

	if ($button) {
		$button.classList.remove('exercise-drop-word-content__word--success')
		$button.classList.add('exercise-drop-word-content__word--error')
	}
}

/**
 * Функция преобразует слово для правильного сравнения
 * @param {String} str — строка, которую нужно подготовить для сравнения
 */
function clearString(str: string) {
	let preparedStr = str.toLowerCase().trim()
	preparedStr = preparedStr.replace(/\./g, '')
	preparedStr = preparedStr.replace(/<\/?[\w\s="'-]*>/g, '')
	return preparedStr
}

/**
 * Получение текста с количеством незаполненных пропусков
 * @param {Number} unfilledGaps — количество незаполненных пропусков
 */
export function getUnfilledGapsText(unfilledGaps: number) {
	if (!unfilledGaps) return ''

	const lastWord = getRusNounByNumber(
		unfilledGaps,
		' Остался',
		'Осталось',
		'Осталось'
	)
	const gapsWord = getRusNounByNumber(
		unfilledGaps,
		'пропуск',
		'пропуска',
		'пропусков'
	)

	return lastWord + ' ' + unfilledGaps + ' ' + gapsWord
}

/**
 * Функция показывает правильный вариант слова после пропуска если пользователь ввёл неверный вариант
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
function showRightAnswers($texts: HTMLDivElement) {
	enumerateSlots($texts, ($slot) => {
		if (
			$slot.classList.contains('exercise-drop-word-content__slot--fail')
		) {
			const $rightWord = $slot.nextElementSibling

			if ($rightWord) {
				$rightWord.classList.add(
					'exercise-drop-word-content__right-answer--open'
				)
			}
		}
	})
}
