import React, { FC } from 'react'
import { Button, Dropdown, Space } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'
import './DotsMenu.scss'
import { ItemType } from 'antd/es/menu/hooks/useItems'

interface IDotsMenuProps {
	menuItems: ItemType[] | undefined
}

const DotsMenu: FC<IDotsMenuProps> = ({ menuItems = [] }) => {
	return (
		<Space>
			<Dropdown
				className="ui-dropdown"
				overlayClassName="ui-dropdown__menu"
				menu={{ items: menuItems }}
			>
				<Button type="link">
					<EllipsisOutlined />
				</Button>
			</Dropdown>
		</Space>
	)
}

export default DotsMenu
