enum UploadFileTypes {
	EXERCISE_AUDIO = 'exercise_audio',
	EXERCISE_VIDEO = 'exercise_video',
	EXERCISE_IMAGES = 'exercise_images',
	// Изображения пользователя
	USER_AVATAR = 'user_avatar',
	// Обложки курсов и уроков
	IMAGE = 'images',
	// ПРОЧИЕ ФАЙЛЫ
	FILE = 'file',
	// Файлы одностраничников
	LANDINGS = 'landings',
	WHITEBOARD = 'whiteboard',
	CHAT = 'chat',
	STORAGE = 'storage',
}

export default UploadFileTypes
