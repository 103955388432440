import useStudentsStore from '../../../../zustand/store'
import { StudentsStoreType } from '../../../../zustand/storeTypes'

export enum FieldsNames {
	// Поле со строкой поиска
	Country = 'country',
}

export interface FormValues {
	[FieldsNames.Country]: string
}

/** Обработчик отправки формы поиска по почте */
export function useGetOnCountryChange() {
	const setSearchValuesProp = useStudentsStore(
		(store) => store.setSearchValuesProp
	)

	return function (values: FormValues) {
		setSearchValuesProp(
			StudentsStoreType.SearchValueNames.Country,
			values[FieldsNames.Country]
		)
	}
}
