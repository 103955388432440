import React from 'react'
import cn from 'classnames'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import TakeAPartButton from 'pages/landing/landingConstructor/details/TakeAPartButton/TakeAPartButton'
import EditableText from 'pages/landing/landingConstructor/details/editableElems/EditableText/EditableText'
import EditableImage from 'pages/landing/landingConstructor/details/editableElems/EditableImage/EditableImage'
import 'pages/landing/landingConstructor/sections/MainSection/MainSection.scss'

// Первая секция одностраничника
function MainSection() {
	const landing = useLandingConstructorStore((store) => store.landing)
	const { colorScheme } = landing

	return (
		<section
			className={cn(
				'land-const-main-section',
				'land-const-main-section--' + colorScheme
			)}
		>
			<div className="land-const-main-section__left">
				<div className="land-const-main-section__top-header-wrapper">
					<p
						className={cn(
							'land-const-main-section__top-header',
							'land-const-main-section__top-header--' +
								colorScheme
						)}
					>
						<EditableText
							propsPathArr={[
								'sections',
								'main',
								'texts',
								'topHeader',
							]}
						/>
					</p>
				</div>
				<h1 className="land-const-main-section__header">
					<EditableText
						propsPathArr={['sections', 'main', 'texts', 'header']}
						placeholder="Заголовок курса"
					/>
				</h1>
				<p className="land-const-main-section__description">
					<EditableText
						propsPathArr={[
							'sections',
							'main',
							'texts',
							'description',
						]}
					/>
				</p>
				<TakeAPartButton sectionName="main" />
			</div>
			<div className="land-const-main-section__right">
				<EditableImage
					propsPathArr={['sections', 'main', 'imageUrl']}
				/>
			</div>
		</section>
	)
}

export default MainSection
