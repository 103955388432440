import ExerciseService from 'parts/services/ExerciseService'
import UrlService from 'parts/services/UrlService'
import useExerciseStudentStore from '../../zustand/store'

export function useGetExercise() {
	const exerciseId = UrlService.useGetExerciseId()

	const lesson = useExerciseStudentStore((state) => state.lesson)

	return ExerciseService.getExerciseInLesson(lesson, exerciseId)!
}

export function useGetExerciseMeta() {
	const exerciseId = UrlService.useGetExerciseId()

	const exercisesMeta = useExerciseStudentStore(
		(state) => state.exercisesMeta
	)

	return exercisesMeta.find(
		(exercisesMeta) => exercisesMeta.id === exerciseId
	)
}
