import { useRef } from 'react'
import ExerciseContainer, {
	ExerciseContainerType,
} from '../../common/ExerciseContainer/ExerciseContainer'
import HtmlEditorContentView from 'ui/HTMLEditorContentView/HTMLEditorContentView'
import { useSetInnerHtml } from '../../common/useSetInnerHtml'
import { TaskExerciseView } from '../../common/TaskExerciseView/TaskExerciseView'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import ExerciseControlPanel from '../ExerciseControlPanel/ExerciseControlPanel'
import './TextExerciseView.scss'

type TextExerciseViewProps = {
	// Для какого типа страницы предназначается обёртка упражнения:
	// constructorPage — страница конструктора. Будут скрыты кнопки перехода по упражнениям и кнопки снизу
	// exercisePage — страница упражнения для ученика. Будет полный набор элементов.
	exerciseContainerType: ExerciseContainerType
	exercise: TrainingEntityTypes.TextExercise
	// Адрес предыдущего и следующего упражнения
	prevExerciseUrl?: string
	nextExerciseUrl?: string
}

// Вывод текстового упражнения в уроке
function TextExerciseViewNew(props: TextExerciseViewProps) {
	const {
		exerciseContainerType,
		exercise,
		prevExerciseUrl,
		nextExerciseUrl,
	} = props

	const areaRef = useRef(null)
	useSetInnerHtml(areaRef, exercise.item.text)

	return (
		<ExerciseContainer
			type={exerciseContainerType}
			prevExerciseUrl={prevExerciseUrl}
			nextExerciseUrl={nextExerciseUrl}
		>
			<>
				{exercise.item.task && (
					<TaskExerciseView htmlString={exercise.item.task} />
				)}
				<HtmlEditorContentView
					areaRef={areaRef}
					outerClass="exercise-task"
				/>
			</>
			<ExerciseControlPanel exercise={exercise} />
		</ExerciseContainer>
	)
}

export default TextExerciseViewNew
