import AddStuffInGroupModal from './AddStaffInGroupModal'
import useAddStaffInGroupModalStore from './zustand/store'

export const AddStaffInGroupModalController = {
	element: <AddStuffInGroupModal key="StuffInGroupModal" />,
	open() {
		const { updateStore } = useAddStaffInGroupModalStore.getState()
		updateStore({ isOpen: true })
	},
	close() {
		const { updateStore } = useAddStaffInGroupModalStore.getState()
		updateStore({ isOpen: false })
	},
}
