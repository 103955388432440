import cn from 'classnames'
import useLandingBuilderStore from 'pages/landing/landingBuilder/zustand/store'
import SectionHeader from '../../../details/SectionHeader/SectionHeader'
import SectionWrapper from '../../../details/SectionWrapper/SectionWrapper'
import TakeAPart from '../../../details/TakeAPart/TakeAPart'
import SectionContent from '../SectionContent/SectionContent'
import BulletsSections from '../bulletsSections/BulletsSections/BulletsSections'
import './ProgramSection.scss'

// Секция одностраничника с рассказом с пунктами, что должно быть и не быть у ученика
function ProgramSection() {
	const landing = useLandingBuilderStore((store) => store.landing)
	const { colorScheme } = landing

	return (
		<SectionWrapper
			extraClass={cn(
				'landing-program-section',
				'landing-program-section--' + colorScheme
			)}
		>
			<SectionHeader
				imageSrc={landing.sections.program.header.imageUrl}
				text={landing.sections.program.header.text}
				white
			/>
			<div>
				<SectionContent />
				<BulletsSections />
				<TakeAPart sectionName="program" />
			</div>
		</SectionWrapper>
	)
}

export default ProgramSection
