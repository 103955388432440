import cn from 'classnames'
import { useGetColorScheme } from 'pages/landing/landingConstructor/tariffs/card/common/common'
import React, { ReactNode } from 'react'
import { Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useGetDeleteTariff } from 'pages/landing/landingConstructor/tariffs/card/CardWrapper/fn/deleteTariff'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'
import 'pages/landing/landingConstructor/tariffs/card/CardWrapper/CardWrapper.scss'

type CardWrapperProps = {
	tariff: TariffsStoreType.Tariff
	children: ReactNode
}

// Обёртка цены и кнопки покупки
function CardWrapper(props: CardWrapperProps) {
	const { tariff, children } = props

	return (
		<div className={cn('tariff-card-wrapper')}>
			<DeleteButton tariff={tariff} />
			{children}
		</div>
	)
}

export default CardWrapper

type DeleteButtonProps = {
	tariff: TariffsStoreType.Tariff
}

// Кнопка удаления тарифа
function DeleteButton(props: DeleteButtonProps) {
	const { tariff } = props

	const deleteTariff = useGetDeleteTariff(tariff)

	return (
		<Button
			size="small"
			shape="circle"
			icon={<DeleteOutlined />}
			className="tariff-card-wrapper__delete-button"
			onClick={deleteTariff}
		/>
	)
}
