import { UploadProgress } from '../components/UploadProgress/UploadProgress'
import useLiveLibraryStore from '../zustand/store'

function useUploadProgress() {
	const isFileUploading = useLiveLibraryStore((s) => s.isFileUploading)
	const uploadFileName = useLiveLibraryStore((s) => s.uploadFileName)
	const uploadPercentCompleted = useLiveLibraryStore(
		(s) => s.uploadPercentCompleted
	)

	return (
		<UploadProgress
			isVisible={isFileUploading}
			fileName={uploadFileName}
			progress={uploadPercentCompleted}
		/>
	)
}

export default useUploadProgress
