import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import { HomeOutlined } from '@ant-design/icons'
import { HeaderRouteType, routesBegin } from './fn/types'

type BreadcrumbsProps = {
	breadcrumbs: HeaderRouteType[] // Массив объектов с описанием маршрута в хлебных крошках
}

// Хлебные крошки сверху шапки страницы
function Breadcrumbs(props: BreadcrumbsProps) {
	const { breadcrumbs } = props
	const routes = routesBegin.concat(breadcrumbs)

	const items = routes.map((route, i) => {
		return {
			title: (
				<>
					{i == 0 && (
						<HomeOutlined className="page-header__breadcrumb-home-icon" />
					)}
					<Link to={route.path}>{route.breadcrumbName}</Link>
				</>
			),
		}
	})

	return <Breadcrumb items={items} />
}

export default Breadcrumbs
