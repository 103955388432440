import AppUrls from 'parts/constants/pageUrl'
import ExerciseService from 'parts/services/ExerciseService'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

// Объект с методами манипулированием упражнением
const exercisesViewsManager = {
	getAttemptsText,
	getPrevOrNextExerciseUrl,
}

export default exercisesViewsManager

/**
 * Получение текста с количеством доступных попыток просмотра результата
 * @param {Number} attempts — количество доступных попыток просмотра результата
 */
function getAttemptsText(attempts: number) {
	if (attempts > 1) {
		return 'Осталось ' + attempts + ' попытки'
	} else if (attempts == 1) {
		return 'Осталась 1 попытка'
	} else {
		return 'Попыток не осталось'
	}
}

// Я БЫ ЭТУ ФУНКЦИЮ ПЕРЕНЕС В ExerciseService
/**
 * Возвращает адрес предыдущего или следующего упражнения относительно переданного
 * @param trainingIdStr — строка с идентификатором курса и группы, в которой находится ученик. Например 100-50.
 * @param lesson — урок для ученика
 * @param exerciseId — id упражнения, относительно которого будет искаться предыдущее или следующее
 * @param direction — направление поиска: предыдущее или следующее
 */
function getPrevOrNextExerciseUrl(
	trainingIdStr: string,
	lesson: TrainingEntityTypes.LessonStudent,
	exerciseId: number,
	direction: 'prev' | 'next'
) {
	const prevExercise =
		ExerciseService.getExerciseInRelationToTargetExerciseId(
			lesson.exercises,
			exerciseId,
			-1
		)
	const nextExercise =
		ExerciseService.getExerciseInRelationToTargetExerciseId(
			lesson.exercises,
			exerciseId,
			1
		)

	if (direction === 'prev') {
		return getExerciseUrlFromExercise(trainingIdStr, lesson, prevExercise)
	} else {
		return getExerciseUrlFromExercise(trainingIdStr, lesson, nextExercise)
	}
}

function getExerciseUrlFromExercise(
	trainingIdStr: string,
	lesson: TrainingEntityTypes.LessonStudent,
	exercise?: TrainingEntityTypes.ExerciseItem
) {
	if (!exercise) return ''

	const lessonId = lesson.id
	const exerciseId = exercise.id

	if (!lessonId || !exerciseId) {
		return ''
	}

	return AppUrls.Exercise(trainingIdStr, lessonId, exerciseId).url
}
