import { useEffect } from 'react'
import { AxiosResponse } from 'axios'
import { useQueryClient } from 'react-query'
import { authQuery } from 'parts/requests/auth/authQuery'
import useSystemStore from 'parts/systemStore/systemStore'
import AuthApiTypes from 'parts/requests/auth/authApiTypes'

/** Скачивает с сервера данные пользователя и ставит в Хранилище при загрузке компонента */
export function useSetUser() {
	const queryClient = useQueryClient()
	const user = useSystemStore((store) => store.user)

	useEffect(
		function () {
			if (user === undefined) {
				queryClient.refetchQueries({
					queryKey: [authQuery.refresh.key],
				})
			}
		},
		[user]
	)

	authQuery.refresh.useQuery({
		onSuccess: querySuccess,
		onError: queryError,
	})
}

/**
 * Функция запускаемая при ошибке запроса данных. Срабатывает для любых запросов.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 * @param userRes
 */
function querySuccess(userRes: AxiosResponse<AuthApiTypes.Refresh>) {
	const updateSystemStore = useSystemStore.getState().updateStore
	updateSystemStore({ user: userRes.data.user })

	localStorage.setItem('user', JSON.stringify(userRes.data.user))
	localStorage.setItem('token', userRes.data.accessToken)
}

/**
 * Функция запускаемая при ошибке запроса данных. Срабатывает для любых запросов.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 * @param err — объект ошибки.
 */
function queryError(err: unknown) {
	// message.error('При получении данных пользователя произошла ошибка.')

	localStorage.removeItem('user')
	localStorage.removeItem('token')

	const updateSystemStore = useSystemStore.getState().updateStore
	updateSystemStore({ user: null as any })
}
