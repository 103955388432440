import { create } from 'zustand'
import { DeleteModalStoreType } from './storeTypes'

const useDeleteModalStore = create<DeleteModalStoreType.State>((set) => {
	return {
		isOpen: false,
		isSubmitting: false,

		header: '',
		text: '',
		onOk: null,
		afterOk: null,

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useDeleteModalStore
