import SectionHeader from '../../../details/SectionHeader/SectionHeader'
import SectionWrapper from '../../../details/SectionWrapper/SectionWrapper'
import TakeAPart from '../../../details/TakeAPart/TakeAPart'
import useLandingBuilderStore from '../../../zustand/store'
import SectionContent from '../SectionContent/SectionContent'

// Секция одностраничника с рассказом с пунктами, что должно быть и не быть у ученика
function ExistenceSection() {
	const landing = useLandingBuilderStore((store) => store.landing)

	return (
		<SectionWrapper>
			<SectionHeader
				imageSrc={landing.sections.existence.header.imageUrl}
				text={landing.sections.existence.header.text}
			/>
			<div>
				<SectionContent />
				<TakeAPart sectionName="existence" />
			</div>
		</SectionWrapper>
	)
}

export default ExistenceSection
