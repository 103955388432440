import { Block } from '../PortraitBlock/PortraitBlock'
import portraitBlocksManager from '../PortraitBlocksManager/PortraitBlocksManager'
import './PortraitBlocks.scss'

// Обёртка с двумя колонками куда помещаются блоки с описанием пользователя, которому подойдёт предлагаемый курс
function PortraitBlocks() {
	const leftBlocks = portraitBlocksManager.useGetColumnBlocks(1)
	const rightBlocks = portraitBlocksManager.useGetColumnBlocks(2)

	let blockIdx = -1

	return (
		<div className="landing-portrait-blocks">
			<div className="landing-portrait-blocks__column">
				{leftBlocks.map((block) => {
					blockIdx++

					return (
						<Block
							block={block}
							blockIdx={blockIdx}
							key={block.id}
						/>
					)
				})}
			</div>
			<div className="landing-portrait-blocks__column">
				{rightBlocks.map((block) => {
					blockIdx++

					return (
						<Block
							block={block}
							blockIdx={blockIdx}
							key={block.id}
						/>
					)
				})}
			</div>
		</div>
	)
}

export default PortraitBlocks
