import { create } from 'zustand'
import { LeadsStoreType } from './storeTypes'

const useLeadsStore = create<LeadsStoreType.State>((set) => {
	const ValueNames = LeadsStoreType.SearchValueNames

	return {
		leads: null,

		searchValues: {
			[ValueNames.DateFrom]: null,
			[ValueNames.DateTo]: null,
			[ValueNames.Email]: '',
			[ValueNames.Phone]: '',
			[ValueNames.PriceFrom]: undefined,
			[ValueNames.PriceTo]: undefined,
			[ValueNames.Trainings]: [],
			[ValueNames.PaySystems]: [],
			[ValueNames.Statuses]: [],
		},

		/**
		 * Установщик новых значений корневого объекта.
		 * @param obj — объект со свойствами и значениями, которые должны быть изменены в Сотоянии
		 */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},

		/**
		 * Установщик новых значений объекта поиска по полям таблицы учеников.
		 * @param propKey — название свойства объекта searchValues
		 * @param propValue — значение свойства объекта searchValues
		 */
		setSearchValuesProp(propKey, propValue) {
			return set((state) => {
				const newSearchValues = { ...state.searchValues }
				newSearchValues[propKey] = propValue

				return { ...state, ...{ searchValues: newSearchValues } }
			})
		},
	}
})

export default useLeadsStore
