import React, { useContext } from 'react'
import { Button, Form, FormInstance, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { StateContext } from '../state/stateContext'
import {
	UFormNewBottomButtonsAfterGray,
	UFormNewButtonsContainer,
	UFormNewGeneralError,
	UFormNewInputCaption,
	UFormNewRow,
} from 'ui/UForm/UForm'
import ModalWithForm from 'ui/ModalWithForm/ModalWithForm'
import { useGetCloseStopPaymentModal } from './fn/openCloseRemoveSchoolModal'
import { useManageForm } from './fn/submit'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'

// Модальное окно с формой отключения платёжной системы
function StopPaymentModal() {
	const { t } = useTranslation()
	const [form] = Form.useForm()

	const { state } = useContext(StateContext)

	const closeModal = useGetCloseStopPaymentModal()

	const { onSubmit, formErrors } = useManageForm()

	return (
		<ModalWithForm
			title={t('myPayments.stopPaymentSystemModalHeader')}
			open={state.stopPaySystemModal.isOpen}
			onCancel={closeModal}
		>
			<Form
				form={form}
				name="stopPayment"
				onFinish={onSubmit}
				layout="vertical"
			>
				<GrayBlock>
					<PaySystemNameInput />
				</GrayBlock>
				<UFormNewGeneralError message={formErrors.message} />
				<Bottom form={form} />
			</Form>
		</ModalWithForm>
	)
}

export default StopPaymentModal

// Обычное текстовое поле
function PaySystemNameInput() {
	const { t } = useTranslation()
	const { state, setState } = useContext(StateContext)
	const paySystemName = state.paymentSystemType || ''

	return (
		<>
			<UFormNewRow>
				<Form.Item
					name="paySystemName"
					label={
						<span>
							{t(
								'myPayments.stopPaymentSystemModalFieldNameLabel'
							)}{' '}
							«
							<strong className="bold-600">
								{paySystemName}
							</strong>
							»
						</span>
					}
					rules={[
						{
							required: true,
							message: t(
								'myPayments.stopPaymentSystemModalFieldNameRequired'
							),
						},
						{
							pattern: new RegExp(paySystemName),
							message:
								'myPayments.stopPaymentSystemModalFieldNameRequired',
						},
					]}
				>
					<Input type="text" />
				</Form.Item>
			</UFormNewRow>
			<UFormNewInputCaption>
				<span className="error-color">
					{t('myPayments.stopPaymentSystemModalWarningFirstLine')}
				</span>{' '}
				{t('myPayments.stopPaymentSystemModalWarningSecondLine')}
			</UFormNewInputCaption>
		</>
	)
}

type BottomProps = {
	form: FormInstance
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom(props: BottomProps) {
	const { form } = props

	const { t } = useTranslation()
	const { state, setState } = useContext(StateContext)
	const closeModal = useGetCloseStopPaymentModal()

	return (
		<UFormNewBottomButtonsAfterGray>
			<div />
			<UFormNewButtonsContainer>
				<Button
					disabled={
						state.stopPaySystemModal.isSubmitting ||
						form
							.getFieldsError()
							.filter(({ errors }) => errors.length).length > 0
					}
					onClick={closeModal}
				>
					{t('myPayments.stopPaymentSystemModalCancelButton')}
				</Button>

				<Form.Item shouldUpdate>
					{() => {
						const formErrors = form
							.getFieldsError()
							.filter(({ errors }) => errors.length)

						return (
							<Button
								type="primary"
								htmlType="submit"
								disabled={
									state.stopPaySystemModal.isSubmitting ||
									formErrors.length > 0
								}
								loading={state.stopPaySystemModal.isSubmitting}
							>
								{t('myPayments.stopPaymentSystemModalOkButton')}
							</Button>
						)
					}}
				</Form.Item>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
