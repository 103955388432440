import useSystemStore from 'parts/systemStore/systemStore'
import { useEffect, useRef } from 'react'
import { AxiosResponse } from 'axios'
import { convertServerDataToStateData } from 'pages/landing/landingConstructor/tariffs/zustand/fillStore/convertServerDataToStateData'
import { groupQuery } from 'parts/requests/group/groupQuery'
import SchoolApiTypes from 'parts/requests/school/schoolApiTypes'
import { schoolQuery } from 'parts/requests/school/schoolQuery'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import GroupsService from 'parts/services/GroupsService'
import PaymentTypes from 'parts/types/PaymentTypes'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import typeChecker from 'parts/utils/typeChecker'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import useTariffsConstructorStore from 'pages/landing/landingConstructor/tariffs/zustand/store'
import UrlService from 'parts/services/UrlService'
import EntityTypes from 'parts/types/EntityTypes'

/** Делает запросы для формирования Состояния тарифов в конструкторе одностраничника */
export function useFillInStore() {
	const school = useSystemStore((store) => store.school)
	const schoolId = school.id
	const trainingId = UrlService.useGetTrainingId()
	const groupId = useTariffsConstructorStore((state) => state.groupId)

	// Запросить все группы курса, чтобы поставить в Состояние id активной группы
	const { data: groupsRes } = groupQuery
		.getTrainingGroups(trainingId)
		.useQuery({
			onError: queryError,
		})
	useWatchGroupsRes(groupsRes)

	// Запросить тарифы активной группы, чтобы поставить в Состояние
	const { data: tariffsRes } = groupQuery.getTariffs(groupId).useQuery({
		enabled: groupId > 0,
		onError: queryError,
	})
	useWatchTariffsRes(tariffsRes, groupId)

	// Запросить настройки платёжных систем, чтобы выяснить есть ли настройка Страйпа
	const { data: paymentsRes } = schoolQuery.getPaymentsData.useQuery(
		schoolId || 0,
		{
			enabled: typeChecker.isNumber(schoolId),
			onError: queryError,
		}
	)
	useWatchPaymentsRes(paymentsRes)

	// Запросить курс и поставить в Состояние
	const { data: trainingRes } = trainingQuery
		.getTrainingForAdmin(trainingId)
		.useQuery({
			// onSuccess: trainingQuerySuccess,
			onError: queryError,
		})
	useWatchTrainingRes(trainingRes)

	useSetSuccessStatusIfAllDataDownloaded()
}

/**
 * Функция запускаемая при удачном получении списка групп курса.
 * Получает id активной группы и ставит в Состояние.
 * Если такой группы нет, то ставит соответствующий статус загрузки чтобы пользователь понял почему не загрузились тарифы.
 * @param groupsRes — ответ от сервера со списком групп
 */
function useWatchGroupsRes(
	groupsRes: undefined | AxiosResponse<EntityTypes.GroupData[]>
) {
	useEffect(
		function () {
			if (!groupsRes) return

			const activeGroup = GroupsService.getActiveGroup(groupsRes.data)

			if (activeGroup) {
				tariffsManager.updateStore({
					groupId: activeGroup.id,
				})
			} else {
				tariffsManager.updateStore({
					dataLoadingStatus: 'noActiveGroup',
				})
			}
		},
		[groupsRes]
	)
}

/**
 * Функция запускаемая при удачном получении списка тарифов курса
 * @param tariffsRes — ответ от сервера со списком тарифов
 * @param groupId — id активной группы
 */
function useWatchTariffsRes(
	tariffsRes: undefined | AxiosResponse<EntityTypes.Tariff[]>,
	groupId?: number
) {
	useEffect(
		function () {
			if (!tariffsRes || !groupId) return

			const tariffsForStore = convertServerDataToStateData(
				tariffsRes.data,
				groupId
			)

			tariffsManager.updateStore({
				tariffs: tariffsForStore,
			})
		},
		[tariffsRes]
	)
}

/**
 * Функция запускаемая при удачном получении списка настроек подключений к платёжным системам школы.
 * Вычисляет подключен ли к школе Страйп и ставит булево значение в свойство stripeSetupDone Состояния.
 * @param paymentsRes — ответ от сервера
 */
function useWatchPaymentsRes(
	paymentsRes: undefined | AxiosResponse<SchoolApiTypes.GetPaymentData>
) {
	useEffect(
		function () {
			if (!paymentsRes) return

			// Найти тариф с настроенным Страйпом
			const paymentWithStripe = paymentsRes.data.find((payMethod) => {
				return (
					payMethod.paymentSystem == PaymentTypes.System.Stripe &&
					payMethod.isConnected
				)
			})

			tariffsManager.updateStore({
				stripeSetupDone: !!paymentWithStripe,
			})
		},
		[paymentsRes]
	)
}

/**
 * Функция запускаемая при удачном получении курса, к которому принадлежат тарифы.
 * Вычисляет подключен ли к школе Страйп и ставит булево значение в свойство stripeSetupDone Состояния.
 * @param trainingRes — ответ от сервера
 */
function useWatchTrainingRes(
	trainingRes: undefined | AxiosResponse<TrainingEntityTypes.TrainingAdmin>
) {
	useEffect(
		function () {
			if (!trainingRes) return

			tariffsManager.updateStore({
				training: trainingRes.data,
			})
		},
		[trainingRes]
	)
}

/**
 * Функция запускаемая при ошибке запроса данных. Срабатывает для любых запросов.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 * @param err — объект ошибки.
 */
function queryError(err: unknown) {
	tariffsManager.updateStore({
		dataLoadingStatus: 'error',
	})
}

/**
 * Следит за загрузкой данных для Состояния.
 * Если всё загружено, то ставит dataLoadingStatus в success.
 */
function useSetSuccessStatusIfAllDataDownloaded() {
	const state = useTariffsConstructorStore((state) => {
		return {
			tariffs: state.tariffs,
			training: state.training,
			dataLoadingStatus: state.dataLoadingStatus,
		}
	})

	// Флаг, чтобы избежать циклического вызова useEffect при обновлении dataLoadingStatus
	const useEffectIsOn = useRef(true)

	useEffect(
		function () {
			if (!useEffectIsOn.current) return

			if (
				state.tariffs &&
				state.training &&
				state.dataLoadingStatus !== 'error'
			) {
				useEffectIsOn.current = false

				tariffsManager.updateStore({
					dataLoadingStatus: 'success',
				})
			}
		},
		[state]
	)
}
