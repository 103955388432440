import { ReactNode } from 'react'
import { Button } from 'antd'
import cn from 'classnames'
import ControlsFrame from 'pages/landing/landingConstructor/details/ControlsFrame/ControlsFrame'
import { LandingTypes } from 'parts/types/LandingTypes'
import EditableText from 'pages/landing/landingConstructor/details/editableElems/EditableText/EditableText'
import EditableImage from 'pages/landing/landingConstructor/details/editableElems/EditableImage/EditableImage'
import {
	getToggleThemeBtnText,
	useGetToggleThemeBtnClasses,
} from 'pages/landing/landingConstructor/sections/existenceSection/ExistenceBlock/fn/block'
import existenceBlocksManager from 'pages/landing/landingConstructor/sections/existenceSection/ExistenceBlocksManager/existenceBlocksManager'
import 'pages/landing/landingConstructor/sections/existenceSection/ExistenceBlock/ExistenceBlock.scss'

type BlockOuterProps = {
	block: LandingTypes.ExistenceBlock
	children: ReactNode
}

export function BlockOuter(props: BlockOuterProps) {
	const { block, children } = props

	const addNewBlockInTop = existenceBlocksManager.useGetAddNewBlock(
		block.id,
		block.columnNum,
		'top'
	)
	const addNewBlockInBottom = existenceBlocksManager.useGetAddNewBlock(
		block.id,
		block.columnNum,
		'bottom'
	)
	const deleteBlock = existenceBlocksManager.useGetDeleteBlock(block.id)

	return (
		<ControlsFrame
			topAddBtnHandler={addNewBlockInTop}
			bottomAddBtnHandler={addNewBlockInBottom}
			deleteBtnHandler={deleteBlock}
		>
			{children}
		</ControlsFrame>
	)
}

type BlockProps = {
	blockIdx: number
	block: LandingTypes.ExistenceBlock
}

export function Block(props: BlockProps) {
	const { blockIdx, block } = props

	const articleClasses = ['land-const-existence-block']
	if (block.colorTheme == 'green') {
		articleClasses.push('land-const-existence-block--green')
	} else {
		articleClasses.push('land-const-existence-block--red')
	}

	return (
		<article className={cn(articleClasses)}>
			<BlockContent blockIdx={blockIdx} />
			<BlockSettings block={block} blockId={block.id} />
		</article>
	)
}

type BlockContentProps = {
	blockIdx: number
}

function BlockContent(props: BlockContentProps) {
	const { blockIdx } = props

	return (
		<div className="land-const-existence-block__content">
			<div className="land-const-existence-block__text-part">
				<h3 className="land-const-existence-block__header">
					<EditableText
						propsPathArr={[
							'sections',
							'existence',
							'blocks',
							blockIdx,
							'header',
						]}
						placeholder="Заголовок"
					/>
				</h3>
				<p className="land-const-existence-block__text">
					<EditableText
						propsPathArr={[
							'sections',
							'existence',
							'blocks',
							blockIdx,
							'text',
						]}
					/>
				</p>
			</div>
			<div className="land-const-existence-block__img-part">
				<EditableImage
					propsPathArr={[
						'sections',
						'existence',
						'blocks',
						blockIdx,
						'imageUrl',
					]}
				/>
			</div>
		</div>
	)
}

type BlockSettingsProps = {
	blockId: number
	block: LandingTypes.ExistenceBlock
}

function BlockSettings(props: BlockSettingsProps) {
	const { blockId, block } = props

	const btnText = getToggleThemeBtnText(block)
	const btnClasses = useGetToggleThemeBtnClasses(block)
	const toggleTheme = existenceBlocksManager.useGetToggleTheme(blockId)

	return (
		<div className="land-const-existence-block__settings">
			<Button
				size="small"
				shape="round"
				className={btnClasses}
				onClick={toggleTheme}
			>
				{btnText}
			</Button>
		</div>
	)
}
