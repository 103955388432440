import { FC } from 'react'
import { Button, Modal, Upload } from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

interface UploadFileProps {
	open: boolean
	onOk: () => void
	onCancel: () => void
	changeUploadInput: (file: File) => void
}

const { Dragger } = Upload

const UploadFile: FC<UploadFileProps> = (props) => {
	const { open, onOk, onCancel, changeUploadInput } = props
	const { t } = useTranslation()

	return (
		<Modal
			title={t('files.filesTableUploadFileModalHeader')}
			open={open}
			onOk={onOk}
			onCancel={onCancel}
			footer={
				<div>
					<Button key="back" onClick={onCancel}>
						{t('files.filesTableUploadFileModalCancelBtn')}
					</Button>
				</div>
			}
		>
			<Dragger
				name="file"
				beforeUpload={(file) => {
					changeUploadInput(file)
					return false
				}}
				showUploadList={false}
			>
				<p className="ant-upload-drag-icon">
					<CloudUploadOutlined />
				</p>
				<p className="ant-upload-text">
					{t('files.filesTableUploadFileModalText')}
				</p>
			</Dragger>
		</Modal>
	)
}

export default UploadFile
