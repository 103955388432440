/** Функция возвращает случайную строку положительного ответа. */
export function generateRightAnswerText() {
	const possibleAnswers = [
		'Вот это да, супер 👏',
		'Вы молодец 👍',
		'Все отлично!',
		'Awesome 👏',
		'Perfect 🤩',
		'You’re doing great 👍🏻',
		'Поздравляю, Вы все сделали верно!',
		'Продолжайте в том же духе! 🎉',
		'Вы отлично справились! 👏',
	]

	const randomIdx = Math.round(Math.random() * possibleAnswers.length)

	return possibleAnswers[randomIdx]
}
