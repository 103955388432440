import { create } from 'zustand'
import { TransferStudentsModalStoreType } from './storeTypes'

const useTransferStudentsModalStore =
	create<TransferStudentsModalStoreType.State>((set) => ({
		isOpen: false,
		isSubmitting: false,
		formErrors: {},
		fromCuratorOptions: [],
		toCuratorOptions: [],

		updateState(obj) {
			return set((state) => {
				return obj
			})
		},
	}))

export default useTransferStudentsModalStore
