import React from 'react'
import { Button } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import {
	ExerciseChangesDataMadeByUser,
	LiftViewDuration,
	SaveExerciseChangesMadeByUser,
	useLiftViewDuration,
	WritingOrAudioAnswerWasSentFn,
} from '../common/useLiftViewDuration'
import { useGetOpenDeleteImageModalFn } from './fn/ImageExerciseView-func'
import { TranscriptionBlock } from '../common/TranscriptionBlock/TranscriptionBlock'
import ImageFrame from '../common/ImageFrame/ImageFrame'
import { TaskExerciseView } from '../common/TaskExerciseView/TaskExerciseView'
import MediaAnswerBlock from '../mediaAnswerBlock/MediaAnswerBlock/MediaAnswerBlock'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import './ImageExerciseView.scss'

type AudioExerciseViewProps = {
	exercise: TrainingEntityTypes.ImageExercise
	liftViewDuration?: LiftViewDuration
	// Запуск этой функции сообщает, что ответ был послан
	writingOrAudioAnswerWasSentFn?: WritingOrAudioAnswerWasSentFn
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

// Вывод упражнения с картинкой в уроке
function ImageExerciseView(props: AudioExerciseViewProps) {
	const {
		exercise,
		liftViewDuration,
		writingOrAudioAnswerWasSentFn,
		saveExerciseChangesMadeByUser,
		exerciseRestoreData,
	} = props

	// Считает время прошедшее после открытия упражнения
	// и передаёт количество секунд в liftViewDuration
	useLiftViewDuration(liftViewDuration)

	return (
		<>
			{exercise.item.task && (
				<TaskExerciseView htmlString={exercise.item.task} />
			)}
			<div className="exercise-image-content__images-wrapper">
				{exercise.item.image.map((audioHref, idx) => {
					return (
						<ExerciseImage
							exercise={exercise}
							src={audioHref}
							key={idx}
						/>
					)
				})}
			</div>
			<TranscriptionBlock exercise={exercise} />
			<MediaAnswerBlock
				exercise={exercise}
				writingOrAudioAnswerWasSentFn={writingOrAudioAnswerWasSentFn}
				saveExerciseChangesMadeByUser={saveExerciseChangesMadeByUser}
				exerciseRestoreData={exerciseRestoreData}
			/>
		</>
	)
}

export default ImageExerciseView

type ExerciseImageProps = {
	exercise: TrainingEntityTypes.ImageExercise
	src: string
}

function ExerciseImage(props: ExerciseImageProps) {
	const { exercise, src } = props

	const userRoles = useGetUserRole()

	const openDeleteImageModal = useGetOpenDeleteImageModalFn(
		src,
		exercise.id || 0
	)

	if (!userRoles.rolesGot) {
		return null
	}

	return (
		<div className="exercise-image-content__pic">
			{userRoles.isAdmin && (
				<Button
					className="exercise-image-content__pic-close-btn"
					shape="circle"
					size="small"
					icon={<CloseCircleOutlined />}
					onClick={openDeleteImageModal}
				/>
			)}
			<ImageFrame src={src} alt="Картинка из упражнения" />
		</div>
	)
}
