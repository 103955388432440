import { create } from 'zustand'
import { GroupsStoreType } from './storeTypes'

const useStaffStore = create<GroupsStoreType.State>((set) => {
	return {
		staff: undefined as any,
		dataLoadingStatus: 'loading',

		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useStaffStore
