import React from 'react'
import PaymentTypes from 'parts/types/PaymentTypes'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'

/**
 * Возвращает обработчик изменения текста в блоке дополнительной цены
 * @param propName — название свойства в данных дополнительной цены
 * @param additionalPriceId — id объекта дополнительной цены
 */
export function useGetChangeText(
	propName: 'price' | 'oldPrice',
	additionalPriceId: number
) {
	const tariff = tariffsManager.useGetActiveTariff()

	return function (e: React.SyntheticEvent<HTMLElement>) {
		if (!tariff) return

		const $element = e.target as HTMLHeadingElement
		const text = $element.innerText

		tariffsManager.changeTextInAdditionalPriceAndSetToStore(
			tariff.innerId,
			additionalPriceId,
			propName,
			text
		)

		// Сохранить на сервере
		tariffsManager?.setNeedToSave()
	}
}

export function useGetCurrencySign(
	price: TariffsStoreType.TariffPrice
): string {
	return PaymentTypes.Currencies[price.currency].sign
}

export function findAdditionalPriceIdx(
	tariff: TariffsStoreType.Tariff,
	priceId: number
) {
	return tariffsManager.findAdditionalPriceIdxInTariff(tariff, priceId)
}
