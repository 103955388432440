import { useEffect, useState } from 'react'
import { Button, Popconfirm } from 'antd'
import {
	CloudDownloadOutlined,
	DeleteOutlined,
	ExportOutlined,
	FileAddOutlined,
	PlusOutlined,
	UploadOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import useLiveLibraryStore from '../zustand/store'
import { MessageInstance } from 'antd/es/message/interface'
import usePdfTab from '../../../fn/usePdfTab'
import useSendToChat from '../../LiveSidepanel/fn/useSendToChat'
import useDeleteStorageItems from './useDeleteStorageItem'
import usePlayerTab from '../../../fn/usePlayerTab'

import styles from '../LiveLibrary.module.scss'

const iconStyle = { color: '#2573E6' }

function useLiveLibraryButtons(messageApi: MessageInstance) {
	const { t } = useTranslation()

	const [actionButtons, setActionButtons] = useState([
		'new-folder',
		'upload-file',
	])
	const { sendFile } = useSendToChat()
	const { isShowConfirm, onConfirmOk, onConfirmCancel, deleteItems } =
		useDeleteStorageItems()
	const createPlayerTab = usePlayerTab()
	const setIsUploadShow = useLiveLibraryStore(
		(store) => store.setIsUploadDialogShow
	)
	const setIsFolderDialogShow = useLiveLibraryStore(
		(store) => store.setIsFolderDialogShow
	)
	const selectedItems = useLiveLibraryStore((store) => store.selectedItems)
	const createPdfTab = usePdfTab()

	useEffect(() => {
		if (selectedItems.length === 0) {
			setActionButtons(['new-folder', 'upload-file'])
		} else {
			if (selectedItems.find((i) => i.fileType === 'folder')) {
				setActionButtons(['delete'])
			} else {
				setActionButtons(['send-to-chat', 'download', 'delete'])
			}
		}
	}, [selectedItems])

	// Удаление выделенных файлов
	const handleOnDelete = () => {
		deleteItems(selectedItems)
	}

	const handleOnAddToClass = () => {
		selectedItems.forEach((item) => {
			if (item.fileType.includes('video')) {
				createPlayerTab(item)
			}
			if (item.fileType.includes('PDF')) {
				createPdfTab(item)
			} else {
				messageApi.warning('Такой тип файлов пока не поддерживается')
			}
		})
	}

	const handleOnSendToChat = async () => {
		for (const item of selectedItems) {
			await sendFile(item.name, item.s3link)
		}
	}

	const handleOnDownload = () => {
		messageApi.info('Функция в разработке')
	}

	const handleFolderDialogShow = () => {
		setIsFolderDialogShow(true)
	}

	const actionButtonMap: Record<string, React.ReactNode> = {
		'new-folder': (
			<Button
				type="primary"
				icon={<PlusOutlined />}
				onClick={handleFolderDialogShow}
			>
				<span className={styles.StorageHeader__ActionLabel}>
					{t('files.filesTableNewFolderBtn')}
				</span>
			</Button>
		),
		'upload-file': (
			<Button
				icon={<UploadOutlined style={iconStyle} />}
				onClick={() => setIsUploadShow(true)}
			>
				<span className={styles.StorageHeader__ActionLabel}>
					{t('files.filesTableUploadFileBtn')}
				</span>
			</Button>
		),
		delete: (
			<Popconfirm
				title="Удалить"
				description={t('files.filesTableDeletePopupHeader')}
				open={isShowConfirm}
				onConfirm={onConfirmOk}
				onCancel={onConfirmCancel}
			>
				<Button
					icon={<DeleteOutlined style={iconStyle} />}
					onClick={handleOnDelete}
				>
					{t('files.filesTableDeletePopupBtn')}
				</Button>
			</Popconfirm>
		),
		'add-to-class': (
			<Button
				type="primary"
				icon={<FileAddOutlined />}
				onClick={handleOnAddToClass}
			>
				{t('files.filesTableAddToClassBtn')}
			</Button>
		),
		'send-to-chat': (
			<Button
				icon={<ExportOutlined style={iconStyle} />}
				onClick={handleOnSendToChat}
			>
				{t('files.filesTableSendToChatBtn')}
			</Button>
		),
		download: (
			<Button
				icon={<CloudDownloadOutlined style={iconStyle} />}
				onClick={handleOnDownload}
			>
				{t('files.filesTableDownloadBtn')}
			</Button>
		),
	}

	const mobileActionButtonMap: Record<string, React.ReactNode> = {
		'new-folder': (
			<Button
				type="primary"
				icon={<PlusOutlined />}
				onClick={handleFolderDialogShow}
			/>
		),
		'upload-file': (
			<Button
				icon={<UploadOutlined style={iconStyle} />}
				onClick={() => setIsUploadShow(true)}
			/>
		),
		delete: (
			<Popconfirm
				title="Удалить"
				description="Вы действительно хотите удалить?"
				open={isShowConfirm}
				onConfirm={onConfirmOk}
				onCancel={onConfirmCancel}
			>
				<Button
					icon={<DeleteOutlined style={iconStyle} />}
					onClick={handleOnDelete}
				/>
			</Popconfirm>
		),
		'add-to-class': (
			<Button
				type="primary"
				icon={<FileAddOutlined />}
				onClick={handleOnAddToClass}
			/>
		),
		'send-to-chat': (
			<Button
				icon={<ExportOutlined style={iconStyle} />}
				onClick={handleOnSendToChat}
			/>
		),
		download: (
			<Button
				icon={<CloudDownloadOutlined style={iconStyle} />}
				onClick={handleOnDownload}
			/>
		),
	}

	return {
		actionButtonMap,
		mobileActionButtonMap,
		actionButtons,
	}
}

export default useLiveLibraryButtons
