import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './app/App/App'
import { Provider } from 'react-redux'
import useErrorPageStore from './pages/duty/ErrorPage/zustand/store'
import store from './parts/store'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/browser'
import ErrorPage from './pages/duty/ErrorPage/ErrorPage'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import './parts/i18n/config'

const myFallback = <ErrorPage errorCode={500} />

Sentry.init({
	// process.env.SENTRY_DSN
	dsn: 'https://7650d7be5b554d17ac1bff912635f1aa@sentry.internal.credu.ai/2',
	integrations: [new BrowserTracing()],
	tracesSampleRate: 1.0,
	environment: process.env.REACT_APP_ENV,
})

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 0,
			cacheTime: 30 * (60 * 1000), // 30 минут
			staleTime: 30 * (60 * 1000), // 30 минут
			refetchOnWindowFocus: false,
		},
	},
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
	<QueryClientProvider client={queryClient}>
		<Provider store={store}>
			<BrowserRouter>
				<DndProvider backend={HTML5Backend}>
					<Sentry.ErrorBoundary
						fallback={myFallback}
						onError={(error) => {
							useErrorPageStore.getState().updateStore({ error })
						}}
					>
						<App />
					</Sentry.ErrorBoundary>
				</DndProvider>
			</BrowserRouter>
		</Provider>
	</QueryClientProvider>
)
