import { FormInstance, message } from 'antd'
import { QueryClient, useQueryClient } from 'react-query'
import AnswerFeedTypes from 'parts/requests/answerFeed/answerFeedApiTypes'
import { answerFeedQuery } from 'parts/requests/answerFeed/answerFeedQuery'
import UrlService from 'parts/services/UrlService'
import { useGetAnswersParams } from '../../zustand/fillStore/fillInStore'
import { FieldNames, FormValuesType } from './form'

/** Хук возвращающий обработчик отправки формы регистрации администратора школы */
export function useGetOnSubmit(answerFeedId: number, form: FormInstance<any>) {
	const queryClient = useQueryClient()
	const getAnswersParams = useGetAnswersParams()
	const groupId = UrlService.useGetGroupId()

	// Объект с методом mutate для создания запроса на регистрацию пользователя в качестве администратора
	const answerFeedAddReplyQuery =
		answerFeedQuery.answerFeedAddReply.useMutation({
			onError: getMutateOnError(),
			onSuccess: getMutateOnAfter(
				queryClient,
				form,
				getAnswersParams,
				groupId
			),
		})

	return async (fieldsValue: FormValuesType) => {
		// Данные, которые передадутся в функцию-обработчик запроса
		const addReplyDto: AnswerFeedTypes.AnswerFeedAddReplyDto = {
			answerFeedId,
			text: fieldsValue[FieldNames.Text],
		}

		// Сделать запрос на добавление ответа
		answerFeedAddReplyQuery.mutate(addReplyDto, {
			onSuccess: (res) => {
				console.log('SUCCESS 2')
			},
		})
	}
}

/** Универсальная функция, выполняемая при появлении ошибки после запроса */
function getMutateOnError() {
	return function (err: unknown) {
		message.error('Не удалось добавить ответ.')
	}
}

/**
 * Функция, выполняемая после запроса на регистрацию пользователя
 * @param queryClient — клиент React Query
 * @param form — ссылка на форму
 * @param getAnswersParams — параметры запроса на получение всех ответов или ответов конкретной группы.
 * @param groupId — id группы если находятся на странице ответов группы.
 */
function getMutateOnAfter(
	queryClient: QueryClient,
	form: FormInstance,
	getAnswersParams: AnswerFeedTypes.GetAnswerFeedParams,
	groupId: number
) {
	return function () {
		// Если находятся на странице ответов группы, то пометить данные ответов группы неактуальными.
		// После этого React Query скачает обновлённые данные.
		if (groupId) {
			queryClient.refetchQueries({
				queryKey: [
					answerFeedQuery.getGroupAnswersFeed(
						groupId,
						getAnswersParams
					).key,
				],
			})
		}

		// Пометить все данные ответов неактуальными потому что добавили ответ.
		// Это нужно в случае если пользователь вернётся на страницу всех ответов.
		queryClient.refetchQueries({
			queryKey: [answerFeedQuery.getAllAnswersFeed(getAnswersParams).key],
		})

		form.resetFields()
	}
}
