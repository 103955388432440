import { useEffect, useState } from 'react'
import { ExerciseStateType, initialState } from './stateType'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { getGapWords } from '../../ChooseWordView/fn/exercise'

/**
 * Функция создаёт местное Состояние для упражнения
 * @param {Object} exercise — данные упражнения
 */
export function useCreateExerciseState(
	exercise: TrainingEntityTypes.DropWordExercise
) {
	const [state, setState] = useState<ExerciseStateType.Main>(initialState)

	useEffect(
		function () {
			setState(convertExerciseDataToExerciseState(exercise))
		},
		[exercise]
	)

	return {
		state,
		setState,
	}
}

/**
 * Функция получает объект данных упражнения и создаёт данные для местного состояния
 * @param {Object} exercise — данные упражнения из БД
 */
export function convertExerciseDataToExerciseState(
	exercise: TrainingEntityTypes.DropWordExercise
): ExerciseStateType.Main {
	return {
		totalSlots: 0,
		rightAnswers: 0,
		attempts: 3,
		textBlocks: exercise.item.texts.map((textObj, i) => {
			return {
				id: i + 1,
				htmlString: textObj.word,
				words: getGapsWords(textObj),
			}
		}),

		showResult: false,
		readyToShowResult: false,
		resultButtonText: 'Проверить',
	}
}

/**
 * Функция формирует и возвращает массив слов, которые нужно перетаскивать в пропуски
 * @param {Object} textObj — данные текстового блока
 */
function getGapsWords(
	textObj: TrainingEntityTypes.DropWordText
): ExerciseStateType.Word[] {
	const gapStrings: string[] = getGapWords(textObj.word)

	const gapWords = gapStrings.map((gapString, i) => {
		return {
			id: i + 1,
			word: gapString,
			inDock: true,
		}
	})

	const lastId = gapWords.length + 1

	textObj.wrongWords.forEach((wrongWord, i) => {
		gapWords.push({
			id: lastId + i,
			word: wrongWord,
			inDock: true,
		})
	})

	// Перемешать слова чтобы не было возможно их угадать
	gapWords.sort().sort(() => {
		return Math.random() - 0.5 < 0 ? 1 : -1
	})

	return gapWords
}
