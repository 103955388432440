import { useEffect, useState } from 'react'
import userRequests from 'parts/requests/user/userRequest'
import UserApiTypes from 'parts/requests/user/userApiTypes'
import UrlService from 'parts/services/UrlService'

// Статичные данные для проверки разметки. Потом можно удалить.
const dummyData: UserApiTypes.StudentTrainingsVisitStatsItem[] = [
	{
		trainingId: 1,
		groupId: 1,
		trainingName: 'Все времена',
		groupName: 'Будущая группа',
		groupStart: '2023-03-01T09:17:27.639Z',
		groupEnd: '2023-03-08T09:17:27.639Z',
		progress: null,
		unperformed: null,
		nextLessonOpenDate: '2023-02-01T09:17:27.639Z',
		allLessonsOpen: false,
	},
	{
		trainingId: 2,
		groupId: 2,
		trainingName: 'Предлоги, союзы, междометья и всякие прочие мелочи',
		groupName: 'Текущая группа',
		groupStart: '2023-01-30T09:17:27.639Z',
		groupEnd: '2023-03-01T09:17:27.639Z',
		progress: 44,
		unperformed: {
			lessons: 3,
			exercises: 6,
		},
		nextLessonOpenDate: '2023-02-01T09:17:27.639Z',
		allLessonsOpen: false,
	},
	{
		trainingId: 3,
		groupId: 3,
		trainingName:
			'Хватит говорить неправильно или 50 правил, которые нарушают все',
		groupName: 'Завершившаяся группа',
		groupStart: '2022-02-01T09:17:27.639Z',
		groupEnd: '2023-01-01T09:17:27.639Z',
		progress: 56,
		unperformed: {
			lessons: 1,
			exercises: 3,
		},
		nextLessonOpenDate: null,
		allLessonsOpen: true,
	},
]

export function useGetVisits() {
	const studentId = UrlService.useGetStudentId()

	const [visits, setVisits] =
		useState<null | UserApiTypes.StudentTrainingsVisitStats>(null)

	useEffect(function () {
		getVisits(studentId).then((data) => {
			setVisits(data)
		})
	}, [])

	return visits
}

async function getVisits(studentId: number) {
	const response = await userRequests.getStudentStatistics(studentId)

	if (response.status == 200) {
		return response.data
	}
	return null
}
