import { produce } from 'immer'
import { LandingTypes } from 'parts/types/LandingTypes'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'

/** Возвращает функцию изменяющую текст в Состоянии одностраничника */
export function useGetUpdateTextInLandingStore<
	A extends keyof LandingTypes.Landing,
	B extends keyof LandingTypes.Landing[A],
	C extends keyof LandingTypes.Landing[A][B],
	D extends keyof LandingTypes.Landing[A][B][C],
	E extends keyof LandingTypes.Landing[A][B][C][D],
	F extends keyof LandingTypes.Landing[A][B][C][D][E],
	G extends keyof LandingTypes.Landing[A][B][C][D][E][F]
>() {
	const [landing, updateStore] = useLandingConstructorStore((store) => [
		store.landing,
		store.updateStore,
	])

	/**
	 * Изменяет значение текстового свойства в Состоянии одностраничника.
	 * @param propsPathArr — массив с названиями свойств и индексов с путём до свойства, которое нужно изменить.
	 * @param newText — текст, на который нужно изменить текущий текст.
	 */
	return function (
		propsPathArr: [A, B?, C?, D?, E?, F?, G?],
		newText: string
	) {
		const newLanding = produce(landing, (draft) => {
			let parentObj: any = draft

			for (let i = 0; i < propsPathArr.length; i++) {
				const isLastItem = i == propsPathArr.length - 1
				if (isLastItem) break

				parentObj = parentObj[propsPathArr[i]]
			}

			const lastPropName = propsPathArr[propsPathArr.length - 1]

			parentObj[lastPropName] = newText
		})

		updateStore({ landing: newLanding })
	}
}
