import { useGetToggleVisibilitySubscriptionCanceledModal } from './openCloseSubscriptionCanceledModal'

export function useGetCancelSubscription() {
	const closeSubscriptionCanceledModal =
		useGetToggleVisibilitySubscriptionCanceledModal()

	return function () {
		closeSubscriptionCanceledModal()
	}
}
