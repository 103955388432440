import React from 'react'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import AppUrls from 'parts/constants/pageUrl'
import { useTranslation } from 'react-i18next'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import RestrictionsService from '../../../parts/services/RestrictionsService'
import { AddEmployeeModalController } from '../staffTable/addEmployeeModal/AddEmployeeModalController'

function StaffPageHeader() {
	return (
		<PageHeader
			breadcrumbs={[
				{
					path: AppUrls.Staff().url,
					breadcrumbName: AppUrls.Staff().name,
				},
			]}
			header={AppUrls.Staff().name}
			buttons={[<AddEmployeeButton key="1" />]}
		/>
	)
}

export default StaffPageHeader

function AddEmployeeButton() {
	const { t } = useTranslation()

	const canCreateNewManager = RestrictionsService.useCanCreateNewManager()
	const canCreateNewCurator = RestrictionsService.useCanCreateNewCurator()

	if (!canCreateNewManager && !canCreateNewCurator) {
		return null
	}

	return (
		<Button
			type="primary"
			icon={<PlusOutlined />}
			onClick={AddEmployeeModalController.open}
		>
			{t('staff.addEmployeeBtn')}
		</Button>
	)
}
