const exercisesEn = {
	// КНОПКИ УПРАВЛЕНИЯ УПРАЖНЕНИЕМ СНИЗУ
	// Кнопка перехода к следующему упражнению
	controlBtnNextExercise: 'Next',
	// Кнопка перехода пропуска упражнения
	controlBtnSkipExercise: 'Skip',
	// Кнопка прохождения упражнения с новой попытки
	controlBtnNewAttempt: 'Once again',
	// Кнопка перезапуска упражнения
	controlBtnPassAgain: 'Go through again',
	// Кнопка сообщающая серверу о выполнении упражнения
	controlBtnDone: 'Done',
	// Кнопка проверяющая упражнение
	controlBtnCheck: 'Check',
	// Кнопка показывающая результат выполнения упражнения со статистикой
	controlBtnResult: 'Show result',
}

export default exercisesEn
