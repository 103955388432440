import TableHeader from '../tableHeader/TableHeader/TableHeader'
import TableRow from '../TableRow/TableRow'
import useLeadsStore from './zustand/store'
import { UTable, UTableBody } from 'ui/UTable/UTable'
import './LeadsTable.scss'

// Содержимое страницы со списком заказов
function LeadsTable() {
	const leads = useLeadsStore((store) => store.leads)
	if (!leads) return null

	return (
		<UTable>
			<TableHeader />
			<UTableBody>
				{leads.map((lead) => (
					<TableRow lead={lead} key={lead.id} />
				))}
			</UTableBody>
		</UTable>
	)
}

export default LeadsTable
