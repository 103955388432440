import { message } from 'antd'
import { useMemo } from 'react'

export function handleCopyToClipboard(text: string, info: string) {
	navigator.clipboard.writeText(text)
	message.success(info)
}

/** Возвращает булево значение, является ли экран трогательным */
export function useIsTouchScreen() {
	return useMemo(function () {
		return 'ontouchstart' in document.documentElement
	}, [])
}
