import { create } from 'zustand'
import { GroupsStoreType } from 'widgets/groups/trainingGroupsTable/zustand/storeTypes'

const useGroupsStore = create<GroupsStoreType.State>((set) => {
	return {
		trainingsGroups: [],

		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useGroupsStore
