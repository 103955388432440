// Страница списка курсов
const studentTrainingsRu = {
	// СТРАНИЦА СО СПИСКОМ КУРСОВ
	// Сообщение Пока не приобретено ни одного курса.
	trainingsPageNoTrainingsPurchased: 'Пока не приобретено ни одного курса.',
	// Сообщение «Произошла ошибка при получении данных.» при попытке получения списка курсов
	trainingsPageErrorWhileGetData: 'Произошла ошибка при получении данных.',
	// Заголовок экрана с сообщением про отсутствие курсов
	trainingsPageNoContentHeader: 'Курсов нет',
	// Текст экрана с сообщением про отсутствие курсов
	trainingsPageNoContentText: 'Купите свой первый курс',

	// КАРТОЧКА КУРСА
	// Наклейка со статусом курса для ученика: Скоро начало
	trainingCardStickerSoon: 'Скоро начало',
	// Наклейка со статусом курса для ученика: Доступен
	trainingCardStickerAvailable: 'Доступен',
	// Наклейка со статусом курса для ученика: Скоро начало
	trainingCardStickerFinished: 'Скоро начало',
	// Статус курса: Все открытые задания выполнены
	trainingCardStatusAllFinished: 'Все открытые задания выполнены',
	// Статус курса: Осталось выполнить
	trainingCardStatusRemainsToComplete: 'Осталось выполнить',
	// Статус курса: Занятия еще не начались
	trainingCardStatusHasNotBegun: 'Занятия еще не начались',
	// Статус курса: Курс успешно завершён!
	trainingCardStatusSuccessfulFinished: 'Курс успешно завершён!',
	// Надпись «Старт занятий» после которой дата начала занятий
	trainingCardTrainingStart: 'Старт занятий',
	// Надпись «Курс начнётся после открытия первого урока»
	trainingCardTrainingStartAfterFirstLessonOpening:
		'Курс начнётся после открытия первого урока',
	// Надпись «Следующий урок» после которой дата следующего урока
	trainingCardNextLesson: 'Следующий урок',
}

export default studentTrainingsRu
