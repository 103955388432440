import { Dispatch, SetStateAction, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SchoolApiTypes from 'parts/requests/school/schoolApiTypes'
import schoolRequests from 'parts/requests/school/schoolRequest'
import useSystemStore from 'parts/systemStore/systemStore'
import { StateContext } from '../../state/stateContext'
import { StateType } from '../../state/stateType'

/** Скачивает школу если в Состоянии компонента свойство needToDownloadSchool в true */
export function useDownloadSchool() {
	const { state, setState } = useContext(StateContext)
	const user = useSystemStore((store) => store.user)
	let { schoolId } = useParams()

	useEffect(
		function () {
			if (!user || !state.needToDownloadSchools || !schoolId) {
				return
			}

			getSchoolAndSetToState(state, setState, schoolId)
		},
		[state.needToDownloadSchools, user?.id]
	)
}

/**
 * Получает с сервера список покупок, конвертирует в формат данных Состояния таблицы и добавляет в Состояние
 * @param {Object} state — объект состояния таблицы
 * @param {Function} setState — установщик нового объекта Состояния таблицы
 * @param {String} schoolId — id школы
 */
async function getSchoolAndSetToState(
	state: StateType.State,
	setState: Dispatch<SetStateAction<StateType.State>>,
	schoolId: string | number
) {
	const response = await schoolRequests.getSchool(schoolId)
	if (response.status !== 200) return

	const school = convertRawSchoolDataToStateData(response.data)

	const newState: StateType.State = { ...state, school }
	setState(newState)
}

/**
 * Переводит данные присланные с сервера в данные для Состояния для отрисовки рядов таблицы
 * @param {Array} rawSchool — данные школы с сервера
 */
function convertRawSchoolDataToStateData(
	rawSchool: SchoolApiTypes.GetSchool
): StateType.School {
	return {
		id: rawSchool.id,
		domain: rawSchool.domain,
		name: rawSchool.name,
		description: rawSchool.description,
		cover: rawSchool.cover,
		oferta: rawSchool.oferta,
	}
}
