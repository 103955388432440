import React, { useCallback, useEffect, useRef, useState } from 'react'
import { InputRef } from 'antd'

/**
 * Функция аккомулирующая логику работы поля для создания нового тега
 * @param {Array} tags — массив тегов
 * @param {Function} setTags — функция обновляющая массив тегов
 */
export function manageNewInput(
	tags: string[],
	setTags: (tags: string[]) => void
) {
	const inputRef = useRef<InputRef>(null)
	const [inputVisible, setInputVisible] = useState(false)
	const [inputValue, setInputValue] = useState('')

	useEffect(() => {
		if (inputVisible) {
			inputRef.current?.focus()
		}
	}, [inputVisible])

	const showInput = () => {
		setInputVisible(true)
	}

	// Функция берущая значение поля ввода и кладящая в местное состояние
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value)
	}

	// Функция вставляющая содержимое текстового поля в массив тегов в качестве нового значения
	const handleInputConfirm = () => {
		if (inputValue && tags.indexOf(inputValue) === -1) {
			setTags([...tags, inputValue])
		}
		setInputVisible(false)
		setInputValue('')
	}

	return {
		inputRef,
		inputVisible,
		inputValue,
		setInputValue,
		showInput,
		handleInputChange,
		handleInputConfirm,
	}
}

/**
 * Функция аккомулирующая логику работы поля редактирования текста существующего тега
 * @param {Array} tags — массив тегов
 * @param {Function} setTags — функция обновляющая массив тегов
 * @param inputValue
 * @param setInputValue
 */
export function manageEditInput(
	tags: string[],
	setTags: (tags: string[]) => void,
	inputValue: string,
	setInputValue: React.Dispatch<React.SetStateAction<string>>
) {
	const editInputRef = useRef<InputRef>(null)

	const [editInputIndex, setEditInputIndex] = useState(-1)
	const [editInputValue, setEditInputValue] = useState('')

	useEffect(() => {
		editInputRef.current?.focus()
	}, [inputValue])

	const handleEditInputConfirm = () => {
		const newTags = [...tags]
		newTags[editInputIndex] = editInputValue
		setTags(newTags)
		setEditInputIndex(-1)
		setInputValue('')
	}

	const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEditInputValue(e.target.value)
	}

	return {
		editInputRef,
		editInputIndex,
		setEditInputIndex,
		editInputValue,
		setEditInputValue,
		handleEditInputConfirm,
		handleEditInputChange,
	}
}

export function useGetHandleClose(
	tags: string[],
	setTags: (tags: string[]) => void
) {
	return useCallback(
		function (removedTag: string) {
			const newTags = tags.filter((tag) => tag !== removedTag)
			setTags(newTags)
		},
		[tags]
	)
}
