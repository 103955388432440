export namespace StudentsStoreType {
	export type State = {
		// Школа имеет учеников?
		schoolHasStudents: boolean
		// Массив сотрудников школы. Null если ещё не скачан.
		students: null | Student[]
		// Поисковые значения
		searchValues: SearchValues

		// Метод изменяющий значение свойств
		updateStore: UpdateStore

		// Метод изменяющий значение свойств объекта searchValues
		setSearchValuesProp: SetSearchValuesProp
	}

	export enum SearchValueNames {
		FullName = 'fullName',
		TrainingName = 'trainingName',
		GroupName = 'groupName',
		Status = 'status',
	}

	// Параметры поиска
	export type SearchValues = {
		[SearchValueNames.FullName]: string
		[SearchValueNames.TrainingName]: string
		[SearchValueNames.GroupName]: string
		[SearchValueNames.Status]: undefined | SearchValuesStatus
		isDeleted: false
	}

	export enum SearchValuesStatus {
		IsActivated = 'IsActivated',
		IsNotActivated = 'IsNotActivated',
	}

	export const SearchValuesStatuses = {
		[SearchValuesStatus.IsActivated]: {
			status: SearchValuesStatus.IsActivated,
			name: 'Активирован',
		},
		[SearchValuesStatus.IsNotActivated]: {
			status: SearchValuesStatus.IsNotActivated,
			name: 'Не активирован',
		},
	}

	export type Student = {
		id: number
		avatar: string
		fullName: string
		trainingName: string
		groupName: string
		groupStart: string
		groupEnd: string
		isActivated: boolean
	}

	// Установщик новых значений корневого объекта через объект.
	export type UpdateStore = (obj: Partial<State>) => void

	export type SetSearchValuesProp = <K extends SearchValueNames>(
		groupProp: K,
		elemProp: SearchValues[K]
	) => void
}
