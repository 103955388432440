/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import TrainingLessons from '../trainingLessons/TrainingLessons/TrainingLessons'
import useLessonsStudentStore from '../trainingLessons/zustand/store'

function TrainingStudentArticle() {
	const { t } = useTranslation()
	const lessons = useLessonsStudentStore((store) => store.lessons)

	const hasLessons = !!lessons?.length

	return (
		<ArticleContentWrapper center={!hasLessons} innerWrapperWidth="middle">
			{hasLessons ? (
				<TrainingLessons />
			) : (
				<p>{t('studentTraining.noLessonsScreen')}</p>
			)}
		</ArticleContentWrapper>
	)
}

export default TrainingStudentArticle
