import { create } from 'zustand'
import { AddTrainingModalStoreType } from './storeTypes'

const useAddTrainingModalStore = create<AddTrainingModalStoreType.State>(
	(set) => {
		return {
			isOpen: false,
			isSubmitting: false,
			formErrors: {},

			/**
			 * Установщик новых значений корневого объекта.
			 */
			updateStore(obj) {
				return set((state) => {
					return obj
				})
			},
		}
	}
)

export default useAddTrainingModalStore
