import { create } from 'zustand'
import ExerciseStateType from './ExerciseStateType'

const useExerciseStore = create<ExerciseStateType.State>((set) => {
	return {
		doneAllow: false,
		answerWasSent: false,

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useExerciseStore
