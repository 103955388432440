import React, { useContext } from 'react'
import cn from 'classnames'
import { RightCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { StateContext } from './fn/stateType'
import { useGetChangeFormVisibility } from './fn/changeState'

// Заголовок блока генерирования текста по заданию
function Header() {
	const { t } = useTranslation()

	const { state, setState } = useContext(StateContext)
	const changeFormVisibility = useGetChangeFormVisibility()

	return (
		<h2 className="generator__header" onClick={changeFormVisibility}>
			Генерирование текста
			<RightCircleOutlined
				className={cn(
					'generator__collapse-icon',
					state.open && 'generator__collapse-icon--open'
				)}
			/>
		</h2>
	)
}

export default Header
