import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGetLogs } from './fn/useGetLogs'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import useStudentStore from '../../main/zustand/store'
import Loader from 'ui/Loader/Loader'
import StudentHistoryTable from '../HistoryTable/StudentHistoryTable'

// История действий пользователя
function StudentHistoryArticle() {
	const { t } = useTranslation()

	const student = useStudentStore((store) => store.student)
	const logs = useGetLogs()

	if (!logs || !student) {
		return (
			<ArticleContentWrapper center>
				<Loader />
			</ArticleContentWrapper>
		)
	} else if (!logs.length) {
		return (
			<ArticleContentWrapper center>
				<p>{t('student.historyArticleNoActions')}</p>
			</ArticleContentWrapper>
		)
	} else {
		return (
			<ArticleContentWrapper innerWrapperWidth="full">
				<StudentHistoryTable logs={logs} />
			</ArticleContentWrapper>
		)
	}
}

export default StudentHistoryArticle
