import AudioFrame from 'ui/AudioFrame/AudioFrame'
import ImageFrame from '../../common/ImageFrame/ImageFrame'
import VideoFrame from 'ui/VideoFrame/VideoFrame'
import { ExerciseStateType } from '../zustand/exerciseState'

type MediaProps = {
	question: ExerciseStateType.Question
}

// Картинка, запись или видео к вопросу
function Media(props: MediaProps) {
	const { question } = props

	return (
		<div className="exercise-test-content__files">
			{question.image && (
				<div className="exercise-test-content__file-image-w">
					<ImageFrame src={question.image} />
				</div>
			)}
			{question.audio && <AudioFrame src={question.audio} />}
			{question.video ||
				(question.videoLink && (
					<VideoFrame
						downloadedVideoLink={question.video}
						youTubeLink={question.videoLink}
					/>
				))}
		</div>
	)
}

export default Media
