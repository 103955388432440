import React from 'react'
import { Button } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import useExerciseStore, { ExerciseStateType } from '../zustand/exerciseState'
import { useGetOpenDeleteModalFn } from './fn/deleteImage'
import ImageFrame from '../../common/ImageFrame/ImageFrame'
import { Slot } from './Slot'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

type ImagesWithSlotsProps = {
	exercise: TrainingEntityTypes.DescriptionImgExercise
}

// Блок с изображениями и их описания
export function ImagesWithSlots(props: ImagesWithSlotsProps) {
	const { exercise } = props

	const images = useExerciseStore((store) => store.images)
	const descriptions = useExerciseStore((store) => store.descriptions)

	return (
		<div className="exercise-desc-image-content__images">
			{images.map((imageObj) => {
				const descObj = descriptions.find(
					(desc) => desc.id == imageObj.id
				)

				return (
					<ImageWithSlot
						exercise={exercise}
						imageObj={imageObj}
						descObj={descObj!}
						key={imageObj.id}
					/>
				)
			})}
		</div>
	)
}

type ExerciseImageProps = {
	exercise: TrainingEntityTypes.DescriptionImgExercise
	imageObj: ExerciseStateType.ImageData
	descObj: ExerciseStateType.DescriptionData
}

// Блок изображения с местом для перетаскивания описания
function ImageWithSlot(props: ExerciseImageProps) {
	const { exercise, imageObj, descObj } = props

	const openDeleteImageModal = useGetOpenDeleteModalFn(
		imageObj.imageSrc,
		exercise.id!
	)

	return (
		<div className="exercise-desc-image-content__image-wrapper">
			<Button
				className="exercise-desc-image-content__close-btn"
				shape="circle"
				size="small"
				icon={<CloseCircleOutlined />}
				onClick={openDeleteImageModal}
			/>
			<ImageFrame src={imageObj.imageSrc} alt="Картинка из упражнения" />
			<Slot descObj={descObj} />
		</div>
	)
}
