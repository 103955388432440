import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import { useGetAddNewBlock } from 'pages/landing/landingConstructor/sections/programSection/programBlocksManager/addNewBlock'
import { useGetDeleteBlock } from 'pages/landing/landingConstructor/sections/programSection/programBlocksManager/deleteBlock'
import { LandingTypes } from 'parts/types/LandingTypes'

// Объект с методами манипулирования блоков с описанием пользователя, которому подойдёт предлагаемый курс
const programBlocksManager = {
	/** Получение всех блоков */
	useGetBlocks() {
		const landing = useLandingConstructorStore((store) => store.landing)
		return landing.sections.program.blocks
	},

	/**
	 * Получение блоков указанной колонки
	 * @param columnNum — номер колонки, у которой нужно получить блоки
	 */
	useGetColumnBlocks(columnNum: 1 | 2) {
		return this.useGetBlocks().filter((block) => {
			return block.columnNum == columnNum
		})
	},

	// Добавление нового блока
	useGetAddNewBlock,

	// Удаление существующего блока
	useGetDeleteBlock,

	/**
	 * Возвращает индекс по которому находится блок с переданным идентификатором
	 * @param blocks — массив блоков с описанием пользователя которому подойдёт курс
	 * @param blockId — id блока, у которого нужно получить индекс.
	 */
	getBlockIdx(
		blocks: LandingTypes.ProgramBlock[],
		blockId: null | number
	): number {
		if (!blockId) return 0

		return blocks.findIndex((block) => block.id === blockId)
	},
}

export default programBlocksManager
