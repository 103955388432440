import React from 'react'
import { Button, Checkbox, Input, Tooltip } from 'antd'
import {
	MinusCircleOutlined,
	PlusCircleOutlined,
	QuestionCircleOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import RestrictionsService from '../../../../parts/services/RestrictionsService'
import useExercisesListAdminStore from '../../zustand/store'
import {
	FormStatementType,
	TrueOrFalseExerciseFormStateType,
} from './fn/stateType'
import {
	addNewStatement,
	onChangeCorrectInput,
	onChangeStatementTextInput,
	removeStatement,
} from './fn/changeForms'
import { alphabet } from 'parts/utils/string'
import { DownloadImageInput } from './DownloadImageInput'
import Generator from '../Generator/Generator'
import {
	useGetGeneratedPromptAndSetToStore,
	useGetGeneratedTextAndSetToStore,
} from './fn/generatedText'

// Блок утверждений
export function Statements() {
	const { t } = useTranslation()

	const canUseAiGeneration = RestrictionsService.useCanUseAiGeneration()

	const formState = useExercisesListAdminStore(
		(store) => store.formData
	) as TrueOrFalseExerciseFormStateType
	if (!formState) return null

	return (
		<>
			<label className="exercise-true-false__label exercise-true-false__label--top-margin">
				Утверждения
				<Tooltip title="Поставьте флаг справа утверждения если оно правильное">
					<QuestionCircleOutlined className="exercise-true-false__question-mark" />
				</Tooltip>
			</label>
			<p className="exercise-true-false-statements__help-text">
				Напишите утверждение и отметьте верно ли оно.
			</p>
			{formState.statements.map((statement, i) => {
				if (!alphabet[i]) return null

				return (
					<Statement
						statementData={statement}
						letter={alphabet[i]}
						showRemoveButton={formState.statements.length > 1}
						key={statement.id}
					/>
				)
			})}
			<AddNewStatementButton />
		</>
	)
}

type StatementProps = {
	statementData: FormStatementType
	letter: string
	showRemoveButton: boolean
}

// Компонент утверждения
function Statement(props: StatementProps) {
	const { statementData, letter, showRemoveButton } = props

	// Функция получающая сгенерированный текст и помещающая его в поле с текстом упражнения
	const getGeneratedText = useGetGeneratedTextAndSetToStore(statementData.id)
	const getGeneratedPrompt = useGetGeneratedPromptAndSetToStore(
		statementData.id
	)

	const canUseAiGeneration = RestrictionsService.useCanUseAiGeneration()

	return (
		<div className="exercise-true-false-statement">
			<div className="exercise-true-false-statement__letter-part">
				{letter}
			</div>
			<div className="exercise-true-false-statement__content-part">
				<StatementTextInput statementData={statementData} />
				{canUseAiGeneration && (
					<Generator
						exerciseType="trueOrFalse"
						getGeneratedText={getGeneratedText}
						initialPrompt={statementData.generatedTextPrompt}
						getGeneratedPrompt={getGeneratedPrompt}
						error={statementData.generatedTextError}
					/>
				)}
				<div className="exercise-true-false-statement__file-input-wrapper">
					<DownloadImageInput statementData={statementData} />
				</div>
			</div>
			<CorrectPart statementData={statementData} />
			{showRemoveButton && (
				<RemoveStatementButton statementData={statementData} />
			)}
		</div>
	)
}

type RemoveStatementButtonProps = {
	statementData: FormStatementType
}

// Кнопка удаляющая блок с утверждением
function RemoveStatementButton(props: RemoveStatementButtonProps) {
	const { statementData } = props

	return (
		<Button
			className="exercise-true-false-statement__remove-button"
			shape="circle"
			icon={<MinusCircleOutlined />}
			onClick={(event) => {
				removeStatement(statementData.id)
			}}
		/>
	)
}

type StatementTextInputProps = {
	statementData: FormStatementType
}

// Поле для ввода текста утверждения
function StatementTextInput(props: StatementTextInputProps) {
	const { statementData } = props

	const { t } = useTranslation()

	return (
		<>
			<Input
				value={statementData.text}
				onChange={(event) =>
					onChangeStatementTextInput(event, statementData.id)
				}
				placeholder="Утверждение"
			/>
			{statementData.noTextError && (
				<p className="exercise-true-false__input-error">
					Напишите утверждение.
				</p>
			)}
		</>
	)
}

type CorrectPartProps = {
	statementData: FormStatementType
}

function CorrectPart(props: CorrectPartProps) {
	const { statementData } = props

	return (
		<div className="exercise-true-false-statement__correct-part">
			<Checkbox
				checked={statementData.correct}
				onChange={(event) =>
					onChangeCorrectInput(event, statementData.id)
				}
			/>
		</div>
	)
}

// Кнопка добавления нового блока утверждения
function AddNewStatementButton() {
	return (
		<div className="exercise-true-false-add-statement">
			<Button
				className="exercise-true-false-add-question-button"
				onClick={() => addNewStatement()}
			>
				<PlusCircleOutlined width={17} height={17} /> Добавить
				утверждение
			</Button>
		</div>
	)
}
