import React from 'react'
import { UTable, UTableBody } from 'ui/UTable/UTable'
import useHistoryStore from '../zustand/store'
import TableHeader from './TableHeader'
import TableRow from './TableRow'

function HistoryTable() {
	const historyItems = useHistoryStore((store) => store.historyItems)

	return (
		<UTable>
			<TableHeader />
			<UTableBody>
				{(historyItems || []).map((historyItem) => (
					<TableRow historyItem={historyItem} key={historyItem.id} />
				))}
			</UTableBody>
		</UTable>
	)
}

export default HistoryTable
