import { useCallback, useEffect, useState } from 'react'
import UrlService from 'parts/services/UrlService'
import { SaveExerciseChangesMadeByUser } from '../../common/useLiftViewDuration'
import { createTextButton, disable$button } from './manipulateElems'

// Тип объекта, в котором будут храниться данные для восстановления текста, написанного учеником в дырках.
type BlocksGapsRestore = {
	// Описание вставленные в слоты по порядку.
	slotsTexts: string[]
}

/**
 * Возвращает обработчик перетаскивания кнопки с текстом в дырку.
 * После формируется объект с текстом брошенных кнопок в дырки и сохраняется на сервере
 * чтобы при открытии упражнения в следующий раз было восстановлено положение кнопок с текстом, которое сделал ученик.
 */
export function useGetDropWordHandler(
	$generalWrapper: null | HTMLDivElement,
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
) {
	const exerciseId = UrlService.useGetExerciseId()

	return useCallback(
		function (e: any) {
			if (!$generalWrapper || !saveExerciseChangesMadeByUser) return

			// Объект данных, сохраняемый на сервере.
			const slotsRestoreObj: BlocksGapsRestore = {
				slotsTexts: [],
			}

			// Слоты куда ученик перетаскивает кнопки
			const $slots = get$slots($generalWrapper)

			// Перебрать слоты
			for (let i = 0; i < $slots.length; i++) {
				// Поставить в данные текст кнопки, который должен быть в слоте.
				// При восстановлении сохранённый текст будет преобразована в перетащенную кнопку.
				slotsRestoreObj.slotsTexts.push($slots[i].innerText)
			}

			// Передать в функцию, которая сохранит данные на сервере.
			saveExerciseChangesMadeByUser(slotsRestoreObj)
		},
		[$generalWrapper, exerciseId]
	)
}

/**
 * Получает данные для восстановления вида упражнения, которое ученик сделал.
 * @param $generalWrapper — обёртка кнопок, изображений и описаний.
 * @param exerciseRestoreData — данные для восстановления упражнения.
 */
export function useRestoreGapTextsInTextBlockOnMount(
	$generalWrapper: null | HTMLDivElement,
	exerciseRestoreData?: Promise<BlocksGapsRestore>
) {
	const exerciseId = UrlService.useGetExerciseId()

	// Сюда попадут данные для восстановления как будут скачаны.
	const [restoreData, setRestoreData] = useState<string[]>([])

	useEffect(
		function () {
			if (!exerciseRestoreData) return

			exerciseRestoreData.then((data) => {
				if (!data?.slotsTexts) return

				setRestoreData(data.slotsTexts)
			})
		},
		[exerciseId]
	)

	useEffect(
		function () {
			if (!restoreData.length || !$generalWrapper) return

			// Автоматически перетащить кнопки в те места, куда они были перетащены в прошлый раз.
			setWordsInSlots($generalWrapper, restoreData)
		},
		[restoreData]
	)
}

/**
 *
 * @param $generalWrapper
 * @param restoreData
 */
function setWordsInSlots(
	$generalWrapper: HTMLDivElement,
	restoreData: string[]
) {
	const $words = $generalWrapper.querySelector(
		'.exercise-desc-image-content__words'
	)
	if (!$words) return

	// Слоты куда ученик перетаскивает кнопки
	const $slots = get$slots($generalWrapper)

	// Перебрать слоты
	for (let i = 0; i < $slots.length; i++) {
		// Текст, который должен быть в дырке
		const textForSlot = restoreData[i]

		// Найти кнопку с этим текстом на обёртке кнопок
		const textButton = getTextButtonByText($words, textForSlot)
		if (!textButton) continue

		// Сделать кнопку неперетаскиваемой чтобы её нельзя было перетащить ещё раз.
		// Ниже сделаю кнопку, которая будет в дырке и уже её перетащить можно.
		disable$button(textButton)

		// Создать перетащенную кнопку
		const draggedButton = createTextButton(
			textForSlot,
			textButton.dataset.wordId!
		)

		// Поставить в дырку перетащенную кнопку
		$slots[i].append(draggedButton)
	}
}

/**
 * Находит в главной обёртке упражнения слоты куда перетаскиваются кнопки описания изображений
 * @param $generalWrapper — обёртка кнопок, изображений и описаний.
 */
function get$slots($generalWrapper: HTMLDivElement): NodeListOf<HTMLElement> {
	// Обёртка с текстовыми блоками с дырками куда ученик перетаскивает кнопки
	return $generalWrapper.querySelectorAll(
		'.exercise-desc-image-content__slot'
	)
}

/**
 * Ищет в обёртке с кнопками кнопку с переданным текстом
 * @param $words — обёртка с кнопками для перетаскивания
 * @param text — текст, который должен быть у искомой кнопке
 */
function getTextButtonByText(
	$words: Element,
	text: string
): null | HTMLElement {
	const buttons = $words.querySelectorAll(
		'.exercise-desc-image-content__word'
	)

	for (let i = 0; i < buttons.length; i++) {
		const thisButton = buttons[i]

		if (thisButton.textContent === text) {
			return thisButton as HTMLElement
		}
	}

	return null
}
