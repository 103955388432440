import { useEffect, useState } from 'react'
import EntityTypes from 'parts/types/EntityTypes'
import ExerciseStateType, { initialExerciseState } from './ExerciseStateType'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

/**
 * Хук возвращает объект состояния упражнения
 * @param {Object} exercise — объект с данными упражнения
 */
export function useManageExerciseState(
	exercise: TrainingEntityTypes.TestExercise
) {
	const [state, setState] =
		useState<ExerciseStateType.Main>(initialExerciseState)

	useEffect(
		function () {
			setState(convertExerciseDataToExerciseState(exercise))
		},
		[exercise]
	)

	return { state, setState }
}

/**
 * Функция получает объект упражнения и переводит его в объект состояния упражнения
 * @param {Object} exercise — объект с данными упражнения
 */
export function convertExerciseDataToExerciseState(
	exercise: TrainingEntityTypes.TestExercise
): ExerciseStateType.Main {
	return {
		exerciseName: exercise.item.name,
		exerciseTask: exercise.item.task,
		currentQuestionNumber: 1,
		totalQuestions: exercise.item.questions.length,
		questions: getQuestions(exercise),
		showResult: false,
	}
}

/**
 * Получает объект упражнения и возвращает массив вопросов для Состояния упражнения.
 * @param {Object} exercise — объект с данными упражнения
 */
function getQuestions(
	exercise: TrainingEntityTypes.TestExercise
): ExerciseStateType.Question[] {
	return exercise.item.questions.map((question) => {
		return {
			id: question.id,
			text: question.text,
			audio: question.audio,
			video: question.video,
			videoLink: question.videoLink,
			image: question.image,
			multipleSelect: question.multipleSelect,
			attempts: 3,
			active: true,
			showCorrect: false,
			resultButtonEnable: true,
			disableQuestion: false,
			answers: getAnswers(question.answers),
		}
	})
}

/**
 * Получает ответы вопроса упражнения и возвращает массив ответов для Состояния упражнения.
 * @param {Array} answers — массив ответов на вопрос из данных упражнения
 */
function getAnswers(
	answers: TrainingEntityTypes.TestAnswer[]
): ExerciseStateType.Answer[] {
	return answers.map((answer) => {
		return {
			id: answer.id,
			text: answer.answer,
			view: 'unknown',
			checked: false,
			correct: answer.correct,
		}
	})
}

/**
 * Получение текущего вопроса.
 * @param {Object} state — объект состояния упражнения.
 */
export function useGetCurrentQuestion(state: ExerciseStateType.Main) {
	const { questions, currentQuestionNumber } = state
	return questions[currentQuestionNumber - 1] || null
}

/**
 * Возвращает вопрос с переданным идентификатором.
 * @param {Object} state — объект состояния упражнения.
 * @param {Number} questionId — id вопроса
 */
export function getQuestion(
	state: ExerciseStateType.Main,
	questionId: number
): undefined | ExerciseStateType.Question {
	return state.questions.find((question) => question.id === questionId)
}

/**
 * Возвращает ответ на вопрос по переданному идентификатору
 * @param {Object} state — объект состояния упражнения.
 * @param {Number} questionId — id вопроса
 * @param {Number} answerId — id ответа
 */
export function getAnswer(
	state: ExerciseStateType.Main,
	questionId: number,
	answerId: number
): undefined | ExerciseStateType.Answer {
	const question = getQuestion(state, questionId)
	if (!question) return undefined

	return question.answers.find((answer) => answer.id === answerId)
}
