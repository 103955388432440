import { useEffect } from 'react'
import { liveLessonQuery } from 'parts/requests/liveLesson/liveLessonQuery'
import useLivelessonStore from '../zustand/store'
import UrlService from 'parts/services/UrlService'

/** Делает запрос на получение live-урока, формирует данные для Состояния и ставит в него */
export function useFillInStore() {
	const setLive = useLivelessonStore((state) => state.setLive)
	const liveId = UrlService.useGetLiveId()

	// Получить live-урок
	const { status, data } = liveLessonQuery.getLiveLesson(liveId).useQuery()

	useEffect(
		function () {
			if (!data) return
			setLive(data.data)
		},
		[status, data]
	)
}
