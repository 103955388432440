import { Button, Upload } from 'antd'
import React from 'react'
import cn from 'classnames'
import { CloseCircleOutlined } from '@ant-design/icons'
import { RcFile } from 'antd/lib/upload/interface'
import { useTranslation } from 'react-i18next'
import './FormFileInput.scss'

type DownloadingFileBlockProps = {
	className?: string
	fileLink?: string
	fileName: string
	progress: number
	cancelDownloading: () => void
}

// Блок сообщающий о загрузке файла
export function DownloadingFileBlock(props: DownloadingFileBlockProps) {
	const {
		className = '',
		fileLink = '',
		fileName,
		progress,
		cancelDownloading,
	} = props

	return (
		<div className={cn('exercise-uploading-block', className)}>
			<div className="exercise-uploading-block__left">
				<DownloadingFilePreviewImage fileLink={fileLink} />
			</div>
			<div className="exercise-uploading-block__center">
				<div className="exercise-uploading-block__center-top">
					<p className="exercise-uploading-block__text">{fileName}</p>
					<p className="exercise-uploading-block__text">
						{progress + '%'}
					</p>
				</div>
				<div className="exercise-uploading-block__progress">
					<div
						className="exercise-uploading-block__progress-bar"
						style={{ width: progress + '%' }}
					/>
				</div>
			</div>
			<div className="exercise-uploading-block__right">
				<Button
					size="large"
					shape="circle"
					type="link"
					icon={<CloseCircleOutlined />}
					onClick={cancelDownloading}
				/>
			</div>
		</div>
	)
}

type DownloadingFilePreviewImageProps = {
	fileLink: string
}

// Изображение или значок загружаемого файла
function DownloadingFilePreviewImage(props: DownloadingFilePreviewImageProps) {
	const { fileLink } = props

	let fileIconSrc = ''
	if (
		fileLink.endsWith('.jpg') ||
		fileLink.endsWith('.jpeg') ||
		fileLink.endsWith('.png')
	) {
		fileIconSrc = fileLink
	}

	if (fileIconSrc) {
		return (
			<div className="exercise-uploading-block__block">
				<img
					className="exercise-uploading-block__pic"
					src={fileIconSrc}
					alt="Значок"
				/>
			</div>
		)
	} else {
		return (
			<div className="exercise-uploading-block__circle">
				<img src="/images/document-text.svg" alt="Значок" />
			</div>
		)
	}
}

type SuccessDownloadedFileBlockProps = {
	className?: string
	fileName: string
	removeFile: () => void
	imageSrc?: string
}

// Блок с сообщением об успешной загрузке файла
export function SuccessDownloadedFileBlock(
	props: SuccessDownloadedFileBlockProps
) {
	const { className, fileName, removeFile, imageSrc } = props

	return (
		<div className={cn('exercise-success-upload-block', className)}>
			{imageSrc && (
				<img
					className="exercise-success-upload-block__image"
					src={imageSrc}
					alt=""
				/>
			)}
			<p className="exercise-success-upload-block__text">
				Загружен файл {fileName}
			</p>
			<Button
				size="large"
				shape="circle"
				type="link"
				icon={<CloseCircleOutlined />}
				className="exercise-success-upload-block__close"
				onClick={removeFile}
			/>
		</div>
	)
}

const { Dragger } = Upload

type ExerciseFileDraggerProps = {
	acceptFileExe: string // Какие типы файлов принимает поле ввода
	beforeUpload: (file: RcFile, FileList: RcFile[]) => void // Функция запускаемая перед загрузкой файла
	hintText?: string
	size?: 'small' | 'medium'
}

// Компонент поля ввода в области загрузки, в который можно перетащить файл.
export function ExerciseFileDragger(props: ExerciseFileDraggerProps) {
	const {
		acceptFileExe,
		beforeUpload,
		hintText = 'Нажмите или перетащите файл в эту область',
		size = 'medium',
	} = props

	const { t } = useTranslation()

	return (
		<div className="">
			<Dragger
				name="file"
				// Возможно потребуется условие когда можно загружать множество файлов.
				// multiple
				showUploadList={false}
				beforeUpload={beforeUpload}
				accept={acceptFileExe}
				className="exercise-dragger-uploading"
			>
				<p
					className={cn(
						'ant-upload-hint',
						'ant-upload-hint--' + size
					)}
				>
					{hintText}
				</p>
			</Dragger>
		</div>
	)
}
