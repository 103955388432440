import React from 'react'
import { Button, Radio, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import {
	FileType,
	FormQuestionStateItemType,
	TestExerciseFormStateType,
} from './fn/stateTypes'
import {
	MinusCircleOutlined,
	PictureOutlined,
	PlaySquareOutlined,
	SoundOutlined,
} from '@ant-design/icons'
import {
	onChangeAnswerRadios,
	onChangeMediaTabs,
	onChangeQuestionTextInput,
	removeQuestion,
} from './fn/changeForms'
import { VideoBlock } from './VideoFile'
import { DownloadMediaInputVariants } from './MediaFiles'
import Answers from './Answers'
import HtmlEditor from 'ui/HTMLEditor/HTMLEditor'
import useExercisesListAdminStore from '../../zustand/store'

// Блок вопросов
export function QuestionsBlocks() {
	// Состояние упражнения
	const formState = useExercisesListAdminStore(
		(store) => store.formData
	) as TestExerciseFormStateType

	const questions = formState.questions.map((questionData) => {
		return (
			<Question
				key={questionData.id}
				questionData={questionData}
				showRemoveButton={formState.questions.length > 1}
			/>
		)
	})

	return (
		<>
			<h3 className="exercise-test-questions__header">Вопросы</h3>
			{questions}
		</>
	)
}

type QuestionProps = {
	questionData: FormQuestionStateItemType
	showRemoveButton: boolean
}

// Компонент вопроса
function Question(props: QuestionProps) {
	const { questionData, showRemoveButton } = props

	return (
		<div className="exercise-test-question">
			<div className="exercise-test-question__content">
				<QuestionQuestion questionData={questionData} />
				<QuestionFileTabs questionData={questionData} />
				<AnswersCountRadios questionData={questionData} />
				<Answers questionData={questionData} />
			</div>
			{showRemoveButton && (
				<Button
					className="exercise-test-question__remove-button"
					shape="circle"
					icon={<MinusCircleOutlined />}
					onClick={(event) => removeQuestion(questionData.id)}
				/>
			)}
		</div>
	)
}

type QuestionQuestionProps = {
	questionData: FormQuestionStateItemType
}

// Поле для ввода текста вопроса
function QuestionQuestion(props: QuestionQuestionProps) {
	const { questionData } = props

	const { t } = useTranslation()

	return (
		<>
			<label className="exercise-test__label">Вопрос</label>
			<HtmlEditor
				initialValue={questionData.questionText}
				onChangeValue={(value) =>
					onChangeQuestionTextInput(value, questionData.id)
				}
				// newValue={questionData.generatedText}
			/>
			{questionData.noQuestionTextError && (
				<p className="exercise-test__input-error">
					Напишите текст вопроса.
				</p>
			)}
		</>
	)
}

type QuestionFileTabsProps = {
	questionData: FormQuestionStateItemType
}

// Вкладки с выбором типов загружаемых файлов к вопросу
function QuestionFileTabs(props: QuestionFileTabsProps) {
	const { questionData } = props

	const { t } = useTranslation()

	const tabsItems = [
		{
			label: <SoundOutlined />,
			key: 'audio',
			children: (
				<DownloadMediaInputVariants
					questionData={questionData}
					fileType="audio"
				/>
			),
		},
		{
			label: <PlaySquareOutlined />,
			key: 'video',
			children: <VideoBlock questionData={questionData} />,
		},
		{
			label: <PictureOutlined />,
			key: 'picture',
			children: (
				<DownloadMediaInputVariants
					questionData={questionData}
					fileType="image"
				/>
			),
		},
	]

	return (
		<>
			<label className="exercise-test__label exercise-test-question__tabs-input">
				Прикреплённый файл
			</label>
			<Tabs
				onChange={(targetKey) => {
					onChangeMediaTabs(targetKey as FileType, questionData.id)
				}}
				type="card"
				items={tabsItems}
				activeKey={questionData.mediaType}
				className="exercise-test-question__tabs"
			/>
		</>
	)
}

type AnswersCountRadiosProps = {
	questionData: FormQuestionStateItemType
}

// Переключатели «Одиночный выбор» и «Множественный выбор»
function AnswersCountRadios(props: AnswersCountRadiosProps) {
	const { t } = useTranslation()

	const { questionData } = props
	return (
		<div className="exercise-test-answers-count">
			<label className="exercise-test-answers-count__label">
				Количество ответов:
			</label>
			<Radio
				checked={!questionData.multipleSelect}
				onChange={(e) =>
					onChangeAnswerRadios(e, 'one', questionData.id)
				}
			>
				Один
			</Radio>
			<Radio
				checked={questionData.multipleSelect}
				onChange={(e) =>
					onChangeAnswerRadios(e, 'multiple', questionData.id)
				}
			>
				Несколько
			</Radio>
		</div>
	)
}
