import useLiveLessonEntrancePageStore from '../../../zustand/store'

/**
 * Возвращает обработчик кнопки «Попробовать снова».
 * Так как кнопка должна отправлять на форму ввода новой почты,
 * то можно задать роль unknown и другой хук получит это значение и отправит на экран ввода почты.
 */
export function useGetOnButtonClick() {
	const updatePageState = useLiveLessonEntrancePageStore(
		(store) => store.updateState
	)

	return function () {
		updatePageState({ currentRole: 'unknown' })
	}
}
