import { useTranslation } from 'react-i18next'
import Loader from 'ui/Loader/Loader'
import NoContent from 'ui/NoContent/NoContent'
import GroupConditionsForm from '../GroupConditionsForm'
import { useFillInStore } from '../zustand/fillStore/fillInStore'
import useCondStore from '../zustand/store'

type GetTCompRouterReturn = {
	status: 'loading' | 'error' | 'noData' | 'hasData'
	element: JSX.Element
}

/** Хук возвращающий статус компонента GroupConditionsForm и сам компонент в зависимости от статуса */
export function useGetGroupConditionsFormRouter(
	trainingId: number,
	groupId: number
): GetTCompRouterReturn {
	const { t } = useTranslation()

	const dataLoadingStatus = useCondStore((state) => state.dataLoadingStatus)

	useFillInStore(trainingId, groupId)

	if (dataLoadingStatus == 'loading') {
		return {
			status: 'loading',
			element: <Loader />,
		}
	} else if (dataLoadingStatus === 'error') {
		return {
			status: 'error',
			element: <p>Произошла ошибка при получении данных.</p>,
		}
	} else if (dataLoadingStatus == 'noData') {
		return {
			status: 'noData',
			element: (
				<NoContent
					header="Данные не существуют"
					text="Они были удалены или введён неправильный адрес"
				/>
			),
		}
	}

	return {
		status: 'hasData',
		element: <GroupConditionsForm />,
	}
}
