import { produce } from 'immer'
import useExercisesListAdminStore from '../../../zustand/store'
import { TextExerciseFormStateType } from './formState'
import { getFormState, setFormState } from '../../common/exerciseFormCommonFunc'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

/**
 * Обработчик отправки формы
 * @param {Function} saveExercise — функция сохраняющая упражнение на сервере и в Состоянии
 */
export function submitHandler(
	saveExercise: (args: TrainingEntityTypes.ExerciseItem) => void
) {
	const isFormValid = validateForm()
	if (!isFormValid) return

	const formState = getFormState<TextExerciseFormStateType>()

	const exerciseData = convertFormStateDataToExerciseData(formState)

	saveExercise(exerciseData)
}

/** Функция проверяет правильность заполнения формы, изменяет Состояние для показа ошибок и возвращает булево значение является ли форма правильной */
function validateForm() {
	let isFormValid = true

	const formState = getFormState<TextExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		if (!draft.textInput.value) {
			draft.textInput.error = 'Это поле должно быть заполнено'
			draft.isFormInvalid = true

			isFormValid = false
		}
	})

	setFormState(newState)

	return isFormValid
}

/**
 * Функция переводит данные формы редактирования упражнения в данные упражнения для сохранения на сервере.
 * @param {Object} formState — Объект с данными формы
 */
function convertFormStateDataToExerciseData(
	formState: TextExerciseFormStateType
) {
	const lessonId = useExercisesListAdminStore.getState().lessonId

	const exerciseData: TrainingEntityTypes.TextExercise = {
		type: 'text',
		lessonId,
		order: formState.order,
		item: {
			task: formState.taskInput.value,
			text: formState.textInput.value,
			generatedTextPrompt: formState.generatedTextPrompt,
		},
		status: 0, // Чтобы TS не ругался
		statusUpdated: '', // Чтобы TS не ругался
	}

	if (formState.exerciseId) {
		exerciseData.id = formState.exerciseId
	}

	return exerciseData
}
