import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import {
	FieldNames,
	FormValuesType,
} from 'pages/landing/landingConstructor/details/AddVideoUrlModal/fn/form'
import useAddVideoUrlModalStore from 'pages/landing/landingConstructor/details/AddVideoUrlModal/zustand/store'

export function useGetOnSubmit() {
	const [updateMainStore, landing] = useLandingConstructorStore((state) => [
		state.updateStore,
		state.landing,
	])

	const updateModalState = useAddVideoUrlModalStore(
		(state) => state.updateStore
	)

	return async function (values: FormValuesType) {
		if (!landing) return

		const landingCopy = { ...landing }

		landingCopy.sections.video.youtubeVideoUrl = values[FieldNames.VideoUrl]
		updateMainStore({ landing: landingCopy })

		// Закрыть модальное окно
		updateModalState({ isOpen: false })
	}
}
