import { create } from 'zustand'
import { SubscriptionCanceledFormStoreType } from './storeTypes'

const useSubscriptionCanceledFormStore =
	create<SubscriptionCanceledFormStoreType.State>((set) => {
		return {
			isOpen: false,
			isSubmitting: false,

			/** Установщик новых значений корневого объекта. */
			updateStore(obj) {
				return set((state) => {
					return obj
				})
			},
		}
	})

export default useSubscriptionCanceledFormStore
