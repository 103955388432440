export namespace StateType {
	export type State = {
		// Школа
		school: null | School
		// Требуется ли загрузить данные школы
		needToDownloadSchools: boolean
		// Открыто ли окно удаления школы
		isRemoveSchoolModalOpen: boolean
		// Отправляются ли данные удаления школы
		removeSchoolSubmitting: boolean
	}

	export type School = {
		id: number
		domain: string
		cover?: string
		name: string
		description?: string
		oferta?: string
	}
}

export const emptyState: StateType.State = {
	school: null,
	needToDownloadSchools: true,
	isRemoveSchoolModalOpen: false,
	removeSchoolSubmitting: false,
}
