import { create } from 'zustand'
import { AddEmployeeModalStoreType } from './storeTypes'

const useAddEmployeeModalStore = create<AddEmployeeModalStoreType.State>(
	(set) => {
		return {
			isOpen: false,
			isSubmitting: false,
			formErrors: {},
			isPurchaseOpen: false,
			trainingOptions: [],

			/** Установщик новых значений корневого объекта. */
			updateStore(obj) {
				return set((state) => {
					return obj
				})
			},
		}
	}
)

export default useAddEmployeeModalStore
