import React from 'react'
import cn from 'classnames'
import useLandingBuilderStore from '../../zustand/store'
import './SectionHeader.scss'

type SectionHeaderProps = {
	text: string
	imageSrc: string
	// Заголовок белым цветом?
	white?: true
}

// Заголовок секции одностраничника
function SectionHeader(props: SectionHeaderProps) {
	const { text, imageSrc, white } = props

	const landing = useLandingBuilderStore((store) => store.landing)
	const { colorScheme } = landing

	const headerClasses = ['landing-section-header__header']
	if (white) {
		headerClasses.push('landing-section-header__header--white')
	} else {
		headerClasses.push('landing-section-header__header--' + colorScheme)
	}

	return (
		<div className="landing-section-header">
			<img
				src={imageSrc}
				className="landing-section-header__image"
				alt=""
			/>
			<h2 className={cn(headerClasses)}>{text}</h2>
		</div>
	)
}

export default SectionHeader
