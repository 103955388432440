import { useCallback } from 'react'
import { produce } from 'immer'
import { TextExerciseFormStateType } from './formState'
import { getFormState, setFormState } from '../../common/exerciseFormCommonFunc'

/**
 * Обработчик изменения поля «Задание»
 * @param {String} newValue — строка HTML из редактора
 */
export function onChangeTaskInput(newValue: string) {
	const formState = getFormState<TextExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		draft.taskInput.value = newValue
	})

	setFormState(newState)
}

/**
 * Обработчик изменения поля «Текст»
 * @param {String} newValue — строка HTML из редактора
 */
export function onChangeTextInput(newValue: string) {
	const formState = getFormState<TextExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		draft.textInput.value = newValue

		if (newValue) {
			draft.textInput.error = ''
			draft.isFormInvalid = false
		}
	})

	setFormState(newState)
}

/**
 * Возвращает функцию получающая сгенерированный текст
 * и помещающая его в поле с текстом упражнения
 */
export function useGetGeneratedTextAndSetToStore() {
	return useCallback(function (generatedText: string) {
		const formState = getFormState<TextExerciseFormStateType>()

		const newState = produce(formState, (draft) => {
			draft.generatedText = generatedText.trim()
		})

		setFormState(newState)
	}, [])
}

/**
 * Возвращает функцию получающую текст запроса на генерирование текста.
 * Эта информация сохраняется в данных упражнения
 */
export function useGetGeneratedPromptAndSetToStore() {
	return useCallback(function (generatedPrompt: string) {
		const formState = getFormState<TextExerciseFormStateType>()

		const newState = produce(formState, (draft) => {
			draft.generatedTextPrompt = generatedPrompt
		})

		setFormState(newState)
	}, [])
}

/** Функция, возвращающая значение для свойства newValue компонента <HtmlEditor /> */
export function useGetNewValueInputValue() {
	const formState = getFormState<TextExerciseFormStateType>()

	return formState.generatedText ?? null
}
