// Объект с методами проверки примитивных типов
const typeChecker = {
	isNumber(value: unknown) {
		if (typeof value !== 'number') {
			return false
		}

		return !isNaN(+value)
	},
	isStringNumber(value: unknown) {
		if (typeof value === 'string') {
			return !isNaN(+value)
		}

		return false
	},
	isString(value: unknown) {
		return typeof value === 'string'
	},
	isNullish(value: unknown) {
		return typeof value === null || value === undefined
	},
}

export default typeChecker
