import React from 'react'
import { Button } from 'antd'
import s from './ExerciseControlPanelContainer.module.scss'

type ExerciseControlPanelContainerProps = {
	// Текст с левой стороны
	leftText?: string
	// Конфигурация для отрисовки кнопок с правой части упражнения
	buttons: ExerciseControlPanelButton[]
}

export type ExerciseControlPanelButton = {
	// Кнопка синяя?
	primary?: boolean
	// Кнопка активна?
	active: boolean
	// Текст на кнопке
	text: string
	// Обработчик щелчка по кнопке или тип действия
	// nextExercise — переход к следующему упражнению
	// done — переход к следующему упражнению
	// check — проверка упражнения
	// clearUserChanges — очистка упражнения после изменения учеником
	onClick?: undefined | null | (() => void)
	// Значение атрибута data-cy, чтобы получать кнопку при тестировании
	dataCyAttr: string
}

// Обёртка для нижней части упражнения где будет писаться текст
// с оставшимися попытками и кнопки управления упражнением.
function ExerciseControlPanelContainer(
	props: ExerciseControlPanelContainerProps
) {
	const { leftText, buttons } = props

	return (
		<div className={s.container}>
			{<span className={s.leftText}>{leftText}</span> || <div />}
			<div className={s.buttons}>
				{buttons.map((buttonConf, i) => {
					return <ControlButton buttonConf={buttonConf} key={i} />
				})}
			</div>
		</div>
	)
}

export default ExerciseControlPanelContainer

type ControlButtonProps = {
	buttonConf: ExerciseControlPanelButton
}

function ControlButton(props: ControlButtonProps) {
	const { buttonConf } = props

	return (
		<Button
			disabled={!buttonConf.active}
			type={buttonConf.primary ? 'primary' : 'default'}
			onClick={buttonConf.onClick ?? (() => {})}
			data-cy={buttonConf.dataCyAttr}
		>
			{buttonConf.text}
		</Button>
	)
}
