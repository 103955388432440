import { useMutation, useQuery } from 'react-query'
import UserApiTypes from './userApiTypes'
import EntityTypes from '../../types/EntityTypes'
import userRequests from './userRequest'
import { MutationOptions, QueryOptions } from '../common'

export const userQuery = {
	// Получение пользователей школы.
	getUsers(params: UserApiTypes.GetUsersParams = {}) {
		return {
			key: 'getUsers-' + JSON.stringify(params),
			useQuery(options: QueryOptions<UserApiTypes.GetUsers> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => userRequests.getUsers(params),
					...options,
				})
			},
		}
	},

	getUsersPaginated(params: UserApiTypes.GetUsersParams = {}) {
		return {
			key: 'getUsersPaginated-' + JSON.stringify(params),
			useQuery(
				options: QueryOptions<UserApiTypes.GetUsersPaginated> = {}
			) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => userRequests.getUsersPaginated(params),
					...options,
				})
			},
		}
	},

	getStudentCount() {
		return {
			key: 'getStudentCount',
			useQuery(options: QueryOptions<number> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => userRequests.getStudentCount(),
					...options,
				})
			},
		}
	},

	getStudentCountToday() {
		return {
			key: 'getStudentCountToday',
			useQuery(options: QueryOptions<number> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => userRequests.getStudentCountToday(),
					...options,
				})
			},
		}
	},

	getStudentCountThisMonth() {
		return {
			key: 'getStudentCountThisMonth',
			useQuery(options: QueryOptions<number> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => userRequests.getStudentCountThisMonth(),
					...options,
				})
			},
		}
	},

	// Получение пользователя с переданным идентификатором
	getUser(userId: string | number) {
		return {
			key: 'getUser-' + userId,
			useQuery(options: QueryOptions<UserApiTypes.GetUser> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => userRequests.getUser(userId),
					...options,
				})
			},
		}
	},

	// Добавление нового пользователя (роль задаётся отдельным запросом)
	addUser: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(userRequests.addUser, options)
		},
	},

	// Обновление пользователя с переданным идентификатором
	updateUser: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(userRequests.updateUser, options)
		},
	},

	// Поиск пользователей по почте
	searchByEmail: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(userRequests.searchByEmail, options)
		},
	},

	// Возвращает булево значение зарегистрирована ли почта в системе
	checkEmailStatus: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(userRequests.checkEmailStatus, options)
		},
	},

	// Получение списка кураторов школы
	getCurators() {
		return {
			key: 'getCurators',
			useQuery(options: QueryOptions<UserApiTypes.GetCurators> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: userRequests.getCurators,
					...options,
				})
			},
		}
	},

	// Получение списка учеников школы администратором или менеджером
	getStudentsByAdmin: {
		key: 'getStudentsByAdmin',
		useQuery(options: QueryOptions<UserApiTypes.GetAdminStudents> = {}) {
			return useQuery({
				queryKey: [this.key],
				queryFn: () => userRequests.getStudentsByAdmin(),
				...options,
			})
		},
	},

	// Получение списка учеников прикреплённых к куратору
	getCuratorStudents(curatorId: number | string) {
		return {
			key: 'getCuratorStudents-' + curatorId,
			useQuery(
				params: UserApiTypes.GetUsersParams,
				options: QueryOptions<UserApiTypes.GetCuratorStudents> = {}
			) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () =>
						userRequests.getCuratorStudents(curatorId, params),
					...options,
				})
			},
		}
	},

	// Добавление ученика куратору
	addStudentToCurator: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(userRequests.addStudentToCurator, options)
		},
	},

	// Получение пользователей с определённой ролью
	getUserByRole(role: EntityTypes.UserRole) {
		return {
			key: 'getByRole-' + role,
			useQuery(options: QueryOptions<UserApiTypes.GetUserByRole> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => userRequests.getUserByRole(role),
					...options,
				})
			},
		}
	},

	// Создание оплаты за курс в ручном режиме
	createManualPayment: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(userRequests.createManualPayment, options)
		},
	},

	// Получение журнала посещения пользователя
	getUserLogs(userId: number | string) {
		return {
			key: 'getUserLogs-' + userId,
			useQuery(options: QueryOptions<UserApiTypes.GetUserLogs> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => userRequests.getUserLogs(userId),
					...options,
				})
			},
		}
	},

	// Блокировка пользователя
	banUser: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(userRequests.banUser, options)
		},
	},

	// Разблокировка пользователя
	unBanUser: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(userRequests.unBanUser, options)
		},
	},

	// Изменение почты пользователя
	updateEmail: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(userRequests.updateEmail, options)
		},
	},

	// Изменение пароля пользователя с указанным id администратором
	updatePasswordByAdmin: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(userRequests.updatePasswordByAdmin, options)
		},
	},

	// Отправка доступа на почту пользователя
	sendAccess: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(userRequests.sendAccess, options)
		},
	},

	// Получение статистики по прохождению курсов учеником
	getStudentStatistics(userId: number | string) {
		return {
			key: 'getStudentStatistics-' + userId,
			useQuery(
				options: QueryOptions<UserApiTypes.StudentTrainingsVisitStats> = {}
			) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => userRequests.getStudentStatistics(userId),
					...options,
				})
			},
		}
	},

	// Получение статистики по прохождению курса учеником
	getStudentStatistic(params: GetStudentStatisticData) {
		return {
			key: `getStudentStatistic_${params.studentId}-${params.groupId}`,
			useQuery() {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => getStudentStatistic(params),
				})
			},
		}
	},

	// Получение списка сотрудников (кураторов и менеджеров) школы.
	getStaff: {
		key: 'getStaff',
		useQuery() {
			return useQuery({
				queryKey: [this.key],
				queryFn: getStaff,
			})
		},
	},
}

/** Получение кураторов и менеджеров школы. */
async function getStaff() {
	const params: UserApiTypes.GetUsersParams = {
		filter: {
			roles: [EntityTypes.UserRole.CURATOR, EntityTypes.UserRole.MANAGER],
			isDeleted: false,
		},
	}

	return userRequests.getUsers(params)
}

export type GetStudentStatisticData = {
	studentId: number | string
	groupId: number | string
}

/**
 * Получение статистики по прохождению курса учеником
 * @param data — данные по ученику и его группе
 */
async function getStudentStatistic(data: GetStudentStatisticData) {
	const { studentId, groupId } = data
	return userRequests.getStudentStatistic(studentId, groupId)
}
