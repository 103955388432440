import { useEffect } from 'react'
import { AxiosError } from 'axios'
import { message } from 'antd'
import dayjs from 'dayjs'
import { liveLessonQuery } from 'parts/requests/liveLesson/liveLessonQuery'
import LiveLessonApiTypes from 'parts/requests/liveLesson/liveLessonApiTypes'
import useSystemStore from 'parts/systemStore/systemStore'
import ServerTypes from 'parts/types/ServerTypes'
import { useGetUserRole, UserRolesObj } from 'parts/utils/hooks/hooks'
import useLiveRoomStore from '../store'

/** Делает запрос на получение списка комнат с эфиром, формирует данные для Состояния и ставит в него */
export function useFillInStore() {
	const userRoles = useGetUserRole()
	const updateStore = useLiveRoomStore((state) => state.updateStore)

	// Получить список live-уроков
	const { data: liveRoomsRes } = liveLessonQuery.getLiveLessons.useQuery({
		onError: queryError,
	})

	useEffect(
		function () {
			if (!liveRoomsRes) return

			let liveRooms = filterRoomsForRoles(userRoles, liveRoomsRes.data)
			liveRooms = sortLiveRooms(liveRooms)

			updateStore({
				liveRooms: liveRooms,
				dataLoadingStatus: liveRooms.length ? 'hasRooms' : 'noRooms',
			})
		},
		[liveRoomsRes]
	)
}

/**
 * Функция запускаемая при ошибке запроса данных. Срабатывает для любых запросов.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 * @param err — объект ошибки.
 */
function queryError(err: unknown) {
	const error = err as AxiosError<ServerTypes.ErrorResponse>

	const { updateStore } = useLiveRoomStore.getState()

	updateStore({
		dataLoadingStatus: 'error',
		errorMessage: error.message,
	})

	message.error('При получении списка комнат произошла ошибка.')
}

/**
 * Фильтрация списка комнат для разных категорий пользователей:
 * для ученика только комнаты с его участием,
 * для куратора аналогично,
 * менеджеру и администратору видны все комнаты.
 * @param userRoles
 * @param liveRooms
 */
function filterRoomsForRoles(
	userRoles: UserRolesObj,
	liveRooms: LiveLessonApiTypes.GetLiveLessons
) {
	const { user } = useSystemStore.getState()

	if (userRoles.isStudent) {
		return liveRooms.filter((room) => {
			const studentInRoom = room.participants.find((student) => {
				return student.id === user?.id
			})

			return !!studentInRoom
		})
	} else if (userRoles.isCurator) {
		return liveRooms.filter((room) => {
			return room.teacher.id === user?.id
		})
	}

	return liveRooms
}

/**
 * Сортировка комнаты по дате начала (ближайшие выше)
 * @param liveRooms
 */
function sortLiveRooms(liveRooms: LiveLessonApiTypes.GetLiveLessons) {
	return liveRooms.sort((a, b) =>
		dayjs(a.scheduledFor).isAfter(dayjs(b.scheduledFor)) ? 1 : -1
	)
}
