import React, { useContext } from 'react'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import { routesEnd } from './fn/routes'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import EditSchoolForm from '../EditSchoolForm/EditSchoolForm'
import { useGetState } from '../state/state'
import { StateContext } from '../state/stateContext'
import { useDownloadSchool } from './fn/downloadSchools'
import Loader from 'ui/Loader/Loader'
import RemoveSchoolModal from '../RemoveSchoolModal/RemoveSchoolModal'
import Footer from 'ui/Footer/Footer'
import { useGetUserRole } from 'parts/utils/hooks/hooks'

/** Страница моей школы */
function MySchoolArticle() {
	const { state, setState } = useGetState()

	return (
		<StateContext.Provider value={{ state, setState }}>
			<MySchoolRouter />
		</StateContext.Provider>
	)
}

export default MySchoolArticle

function MySchoolRouter() {
	useDownloadSchool()
	const { state, setState } = useContext(StateContext)
	const userRoles = useGetUserRole()

	if (!state.school) {
		return (
			<ArticleContentWrapper center>
				<Loader />
			</ArticleContentWrapper>
		)
	} else {
		return (
			<PageSkeleton>
				<PageHeader
					breadcrumbs={routesEnd}
					header={state.school.name}
					description={state.school.description}
				/>
				<ArticleContentWrapper center>
					<EditSchoolForm />
					<RemoveSchoolModal />
				</ArticleContentWrapper>
				<Footer isRightSideHidden={!userRoles.isAdminOrManager} />
			</PageSkeleton>
		)
	}
}
