import { useTranslation } from 'react-i18next'
import CreateNewCard from 'ui/CreateNewCard/CreateNewCard'
import RowsContainer from 'ui/RowsContainer/RowsContainer'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import { AddLessonModalController } from '../../AddLessonModal/AddLessonModalController'
import Card from '../lessonCard/Card/Card'
import { EditLessonModalController } from '../../editLessonModal/EditLessonModalController'
import useLessonsListAdminStore from '../../zustand/store'

function LessonsListAdmin() {
	const { t } = useTranslation()
	const userRoles = useGetUserRole()
	const lessons = useLessonsListAdminStore((store) => store.lessons)

	return (
		<>
			<RowsContainer>
				{lessons?.map((lesson, i) => {
					return (
						<Card lessonIdx={i} lesson={lesson} key={lesson.id} />
					)
				})}

				{userRoles.isAdmin && lessons.length > 0 && (
					<CreateNewCard
						text={t('adminTraining.lessonsListAddLessonCard')}
						onClick={AddLessonModalController.open}
					/>
				)}
			</RowsContainer>
			{EditLessonModalController.element}
			{AddLessonModalController.element}
		</>
	)
}

export default LessonsListAdmin
