import { useMutation, useQuery } from 'react-query'
import { MutationOptions } from '../common'
import paymentsRequests from './paymentsRequests'
import PaymentsApiTypes from './paymentsApiTypes'

export const paymentsQuery = {
	pay: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(paymentsRequests.pay, options)
		},
	},

	// Получение массива оплат пользователя
	getPaymentsOfUser(userId: string | number) {
		return {
			key: 'getPaymentsOfUser-' + userId,
			useQuery() {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => paymentsRequests.getPaymentsOfUser(userId),
				})
			},
		}
	},

	// Получение списка оплат в школе.
	getPayments: {
		key: 'getPayments',
		useQuery(params?: PaymentsApiTypes.GetPaymentsParams) {
			return useQuery({
				queryKey: [this.key],
				queryFn: () => paymentsRequests.getPayments(params),
			})
		},
	},

	// Получение списка тарифных планов школы.
	getTariffPlans: {
		key: 'getTariffPlans',
		useQuery() {
			return useQuery({
				queryKey: [this.key],
				queryFn: () => paymentsRequests.getTariffPlans(),
			})
		},
	},
	// Оплата тарифа
	payTariffPlan: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(paymentsRequests.payTariffPlan, options)
		},
	},
	// Отмена текущей подписки на тариф
	cancelCurrentTariffPlan: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(
				paymentsRequests.cancelCurrentTariffPlan,
				options
			)
		},
	},
	// Получение списка оплат тарифов
	getAdminSubscriptionsHistory: {
		key: 'getAdminSubscriptionsHistory',
		useQuery() {
			return useQuery({
				queryKey: [this.key],
				queryFn: () => paymentsRequests.getAdminSubscriptionsHistory(),
			})
		},
	},
}
