import { ReactNode } from 'react'
import { getRusNounByNumber } from 'parts/utils/string'
import { useTranslation } from 'react-i18next'
import MetaOutputText from 'ui/MetaOutputText/MetaOutputText'
import useGroupStore from '../../zustand/store'

/** Возвращает объект настройки компонента с серой текстом с деталями группы */
export function useGetMetaPanelConfig(): ReactNode[] {
	const { t } = useTranslation()
	const group = useGroupStore((state) => state.group)

	if (!group) return []

	return [
		<MetaOutputText
			textConfigs={[
				{
					text:
						// eslint-disable-next-line no-nested-ternary
						group.type === 'paid'
							? 'Платная группа'
							: group.type === 'free'
							? 'Бесплатная группа'
							: 'Тестовая группа',
					strong: true,
				},
			]}
		/>,
		<MetaOutputText
			textConfigs={[
				{
					text:
						(group.numOfParticipants ?? 0) +
						' ' +
						getRusNounByNumber(
							group.numOfParticipants,
							'место',
							'места',
							'мест'
						),
					strong: true,
				},
			]}
		/>,
	]
}
