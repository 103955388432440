import { useEffect } from 'react'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import useExerciseStore from '../exerciseState'

export function useFillStore(exercise: TrainingEntityTypes.AudioExercise) {
	const updateStore = useExerciseStore((store) => store.updateStore)

	useEffect(
		function () {
			updateStore({
				answerWasSent:
					exercise.status ===
					TrainingEntityTypes.ExerciseProgressStatus.SUCCESS,
				doneAllow: isDoneAllowActive(exercise),
			})
		},
		[exercise.id]
	)
}

/**
 * Возвращает булево значение активна ли кнопка Выполнено
 * @param exercise — объект упражнения
 */
export function isDoneAllowActive(
	exercise:
		| TrainingEntityTypes.AudioExercise
		| TrainingEntityTypes.ImageExercise
) {
	// Кнопка Выполнено доступна если упражнение ещё не выполнили
	let doneAllow =
		exercise.status === TrainingEntityTypes.ExerciseProgressStatus.PENDING

	// Но если ответ предусмотрен и упражнение ещё не выполнили, то кнопка выполнения не активна
	if (
		(exercise.item.audioAnswer || exercise.item.writtenAnswer) &&
		exercise.status === TrainingEntityTypes.ExerciseProgressStatus.PENDING
	) {
		doneAllow = false
	}

	return doneAllow
}
