const expressRu = {
	// БАННЕР НА ГЛАВНОЙ СТРАНИЦЕ
	// Заголовок баннера
	mainPageTitle: 'Экспресс курсы по работе с Credu',
	// Подзаголовок баннера
	mainPageSubtitle: 'Пройдя курсы вы узнаете',

	// КАРТОЧКА КУРСА Как проводить Live-уроки
	mainPageHowToTeachCardLessonCount: '12 подробных уроков',
	mainPageHowToTeachCardTitle: 'Как проводить Live-уроки',
	mainPageHowToTeachCardSubtitle:
		'Платформа Креду была разработана специально для проведения онлайн уроков. Мы создали для вас 12 подсказок, которые ответят на большинство ваших вопросов. Начните знакомство с платформой прямо сейчас!',

	// КАРТОЧКА КУРСА Как создавать курсы на Сredu
	mainPageHowToCreateCardLessonCount: '12 подробных уроков',
	mainPageHowToCreateCardTitle: 'Как создавать курсы на Сredu',
	mainPageHowToCreateCardSubtitle:
		'На Credu Вы можете создать любой онлайн курс: от мини-курса в три лекции с автоматической проверкой домашнего задания до большого обручающего курса с промежуточным тестированием, ручной проверкой домашнего задания и фидбеком ученикам, аудио и видео упражнениями, вебинарами. Пройдите наши мини-уроки и узнайте, как быстро подготовить и запустить курс Вашей мечты.',
}

export default expressRu
