import { create } from 'zustand'
import { CancelSubscriptionModalStoreType } from './storeTypes'

const useCancelSubscriptionModalStore =
	create<CancelSubscriptionModalStoreType.State>((set) => {
		return {
			isOpen: false,
			isSubmitting: false,
			IUnderstandConsequencesChecked: false,
			// formErrors: {},

			updateState(obj) {
				return set((state) => {
					return obj
				})
			},
		}
	})

export default useCancelSubscriptionModalStore
