import TableHeader from '../tableHeader/TableHeader/TableHeader'
import TableRow from '../TableRow/TableRow'
import { UTable, UTableBody } from 'ui/UTable/UTable'
import useStudentsStore from './zustand/store'
import './StudentsTable.scss'

// Содержимое страницы со списком учеников
function StudentsTableCurator() {
	const students = useStudentsStore((store) => store.students)
	if (!students) return null

	return (
		<UTable>
			<TableHeader />
			<UTableBody>
				{students.map((student) => {
					return (
						<TableRow
							student={student}
							key={student.id + Math.random()}
						/>
					)
				})}
			</UTableBody>
		</UTable>
	)
}

export default StudentsTableCurator
