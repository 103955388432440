import React from 'react'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import AccountForm from '../AccountForm/AccountForm'

function EmployeeProfileArticle() {
	return (
		<ArticleContentWrapper innerWrapperWidth="small">
			<AccountForm />
		</ArticleContentWrapper>
	)
}

export default EmployeeProfileArticle
