import { useQueryClient } from 'react-query'
import { liveLessonQuery } from 'parts/requests/liveLesson/liveLessonQuery'
import { useGetLiveLessonRouter } from './fn/useGetLiveLessonRouter'

export const LivelessonController = {
	router: useGetLiveLessonRouter,
	// Функция, помечающая данные массива неактуальными.
	// После этого React Query скачает новый массив
	useGetUpdate: function () {
		const queryClient = useQueryClient()

		return function (liveId: number | string) {
			// Пометить список неактуальным. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [liveLessonQuery.getLiveLesson(liveId).key],
			})
		}
	},
}
