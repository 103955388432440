import React from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { stateManager } from '../stateManager/stateManager'
import useExerciseStore from '../zustand/exerciseState'
import s from './StatementsNavigation.module.scss'

function StatementsNavigation() {
	const currentStatementIdx = useExerciseStore(
		(store) => store.currentStatementIdx
	)
	const statements = useExerciseStore((store) => store.statements)

	const hasPrevStatement = stateManager.isMoveToStatementAllowed(
		'prev',
		currentStatementIdx,
		statements
	)
	const goToPrevStatement = stateManager.useGetGoToStatement('prev')

	const hasNextStatement = stateManager.isMoveToStatementAllowed(
		'next',
		currentStatementIdx,
		statements
	)
	const goToNextStatement = stateManager.useGetGoToStatement('next')

	return (
		<nav className={s.container}>
			<Button
				onClick={goToPrevStatement}
				icon={<LeftOutlined />}
				disabled={!hasPrevStatement}
			/>
			<Counter />
			<Button
				onClick={goToNextStatement}
				icon={<RightOutlined />}
				disabled={!hasNextStatement}
			/>
		</nav>
	)
}

export default StatementsNavigation

function Counter() {
	const currentStatementIdx = useExerciseStore(
		(store) => store.currentStatementIdx
	)
	const statements = useExerciseStore((store) => store.statements)

	return (
		<p className={s.counter}>
			{stateManager.getStatementCounter(currentStatementIdx, statements)}
		</p>
	)
}
