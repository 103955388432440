namespace ExerciseStateType {
	export type Main = {
		exerciseId: number // id упражнения
		exerciseTask: string // Задача упражнения
		images: ImageData[] // Массив вопросов
		descriptions: DescriptionData[] // Массив утверждений

		totalImages: number // Общее количество изображений к которым нужно было подобрать описания
		answeredImages: number // Для скольки изображений правильно подобрали описания
		attempts: number // Количество доступных попыток
		readyToShowResult: boolean // Всё готово для показа результата (к переводу showResult в true)
		showResult: boolean // Показать ли окно результата
		resultButtonText: string // Текст на кнопке показа результата
	}

	export type ImageData = {
		id: number // id правильного утверждения и по-совместительству id изображения
		imageSrc: string // Ссылка на загруженное изображение
		givenDescId: null | number // id утверждения, который поставил пользователь
	}

	export type DescriptionData = {
		id: number // id утверждения и по-совместительству id изображения (если такое изображение существует)
		text: string // Текст утверждения
		rightWord: string
	}
}

export default ExerciseStateType

// Объект первоначального состояния упражнения, чтобы забить useState.
export const initialState: ExerciseStateType.Main = {
	exerciseId: 0,
	exerciseTask: '',
	images: [],
	descriptions: [],
	totalImages: 0,
	answeredImages: 0,
	attempts: 0,
	readyToShowResult: false,
	showResult: false,
	resultButtonText: '',
}
