import { getCompRouterObj } from 'parts/utils/component'
import { userQuery } from 'parts/requests/user/userQuery'
import UserApiTypes from 'parts/requests/user/userApiTypes'
import EntityTypes from 'parts/types/EntityTypes'
import StudentsStatsAdmin from '../StudentsStatsAdmin'
import { useFillInStore } from './fillInStore'

export const getAllStudentQueryParams: UserApiTypes.GetUsersParams = {
	filter: {
		fullName: '',
		email: '',
		phone: '',
		country: '',
		roles: [EntityTypes.UserRole.STUDENT],
		isDeleted: false,
	},
}

/** Хук возвращающий статус компонента LeadsStats и сам компонент в зависимости от статуса */
export function useGetStudentsStatsRouter() {
	// Получить список учеников
	const query = userQuery.getStudentCount().useQuery()

	useFillInStore()

	return getCompRouterObj(query, <StudentsStatsAdmin />)
}
