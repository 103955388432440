import { Button, Table, TableProps } from 'antd'
import EntityTypes from 'parts/types/EntityTypes'
import AnswerDialog from '../components/AnswerDialog/AnswerDialog'
import useAnswerToComment from './fn/useAnswerToComment'
import { useArchiveComment } from './fn/useArchiveComment'
import styles from './Comments.module.scss'
import { useUnarchiveComment } from './fn/useUnArchiveComment'

type CommentsProps = {
	comments: EntityTypes.CommentItem[]
	archive: boolean
}

interface DataType {
	key: number
	email: string
	date: Date
	training: string
	lesson: string
	comment: string
	answer?: string
}

export default function Comments(props: CommentsProps) {
	const { comments, archive } = props
	const {
		isShowDialog,
		onDialogOk,
		onDialogCancel,
		answerToComment,
		answerText,
	} = useAnswerToComment()
	const archiveCommnt = useArchiveComment()
	const unarchiveCommnt = useUnarchiveComment()

	const handleAnswerToComment = (commentId: number) => {
		const comment = comments.find((comment) => comment.id === commentId)
		if (comment) {
			answerToComment(comment)
		}
	}

	const handleArchiveComment = (commentId: number) => {
		if (archive) {
			unarchiveCommnt(commentId)
		} else {
			archiveCommnt(commentId)
		}
	}

	const commentsColumns: TableProps<DataType>['columns'] = [
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Дата',
			dataIndex: 'date',
			key: 'date',
			render: (date: string) => new Date(date).toLocaleDateString(),
		},
		{
			title: 'Курс',
			dataIndex: 'training',
			key: 'training',
		},
		{
			title: 'Урок',
			dataIndex: 'lesson',
			key: 'lesson',
		},
		{
			title: 'Комментарий',
			dataIndex: 'comment',
			key: 'comment',
		},
		{
			title: 'Ответ',
			dataIndex: 'answer',
			key: 'answer',
			render: (answer: string, record: DataType) =>
				answer ? (
					<div className={styles.CommetAnswer}>
						<div>{answer || 'Ответа нет'}</div>
						<div>
							<Button
								type="link"
								onClick={() =>
									handleAnswerToComment(record.key)
								}
							>
								Редактировать
							</Button>
						</div>
					</div>
				) : (
					<Button
						type="primary"
						onClick={() => handleAnswerToComment(record.key)}
					>
						Ответить
					</Button>
				),
		},
		{
			title: 'Кнопки',
			dataIndex: '',
			key: 'x',
			render: (_, record: DataType) => (
				<Button danger onClick={() => handleArchiveComment(record.key)}>
					{archive ? 'Из архива' : 'В архив'}
				</Button>
			),
		},
	]

	const dataSource = comments.map((comment) => ({
		key: comment.id,
		email: comment.ownerEmail,
		date: comment.date,
		training: comment.trainingName,
		lesson: comment.lessonName,
		comment: comment.text,
		answer: comment.answer,
	}))

	return (
		<>
			<div className={styles.Comments}>
				<Table<DataType>
					dataSource={dataSource}
					columns={commentsColumns}
					pagination={false}
				/>
			</div>
			<AnswerDialog
				open={isShowDialog}
				initialAnswer={answerText}
				okButtonText="Сохранить"
				onOk={onDialogOk}
				onCancel={onDialogCancel}
				title="Ответ на комментарий"
			/>
		</>
	)
}
