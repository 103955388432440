import { useEffect, useRef, useState } from 'react'
import Loader from 'ui/Loader/Loader'
import { userQuery } from 'parts/requests/user/userQuery'
import StudentsTableCurator from '../StudentsTableCurator'
import UserApiTypes from 'parts/requests/user/userApiTypes'
import useSystemStore from 'parts/systemStore/systemStore'
import { useFillInStore } from '../zustand/fn/fillInStore'

type GetTrainingListRouterReturn = {
	status: 'loading' | 'hasStudents' | 'noStudents' | 'unknown'
	element: JSX.Element
}

/** Хук возвращающий статус компонента StudentsTable и сам компонент в зависимости от статуса */
export function useGetStudentsTableRouter(): GetTrainingListRouterReturn {
	const user = useSystemStore((store) => store.user)

	// Получить список учеников
	const { status, data } = userQuery
		.getCuratorStudents(user.id)
		.useQuery(initialStudentsQueryParams)

	useFillInStore()

	// Тут хранится булево значение можно ли возвращать другой результат.
	// Это нужно потому что запрос на получение учеников будет запускаться всякий раз при фильтрации.
	// Но после первого успешного результата нужно перестать обновлять возвращаемый результат чтобы компонент выше знал есть ли изначально ученики или нет.
	// При фильтрации количество учеников может быть равно нулю.
	const stopUpdatingRef = useRef<boolean>(false)

	const [result, setResult] =
		useState<GetTrainingListRouterReturn>(unknownObj)

	useEffect(
		function () {
			if (stopUpdatingRef.current) {
				return
			}

			if (status == 'loading') {
				setResult({
					status: 'loading',
					element: <Loader />,
				})
			} else if (status == 'success' && data) {
				if (data.data.length) {
					setResult({
						status: 'hasStudents',
						element: <StudentsTableCurator />,
					})
				} else {
					setResult({
						status: 'noStudents',
						element: <p>noStudents</p>,
					})
				}

				stopUpdatingRef.current = true
			}
		},
		[status]
	)

	return result
}

const unknownObj: GetTrainingListRouterReturn = {
	status: 'unknown',
	element: <p>unknown</p>,
}

/** Начальные параметры получения с сервера массива учеников */
export const initialStudentsQueryParams: UserApiTypes.GetCuratorStudentsParams =
	{
		filter: {
			isDeleted: false,
		},
	}
