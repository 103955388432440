import { StudentOrdersTableController } from '../OrdersTable/StudentOrdersTableController'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import UrlService from 'parts/services/UrlService'

// Страница с таблицей с покупками совершёнными пользователем
export default function StudentOrdersArticle() {
	const studentId = UrlService.useGetStudentId()

	const ordersTableRouter = StudentOrdersTableController.router(studentId)

	return (
		<ArticleContentWrapper center={ordersTableRouter.status === 'loading'}>
			{ordersTableRouter.element}
		</ArticleContentWrapper>
	)
}
