import { RangeValueType } from 'parts/types/miscTypes'
import useLeadsStore from '../../../../LeadsTable/zustand/store'
import { LeadsStoreType } from '../../../../LeadsTable/zustand/storeTypes'

/** Обработчик поля с диапазоном дат создания заказа. Новые данные записываются в Состояние. */
export function useGetOnDateChange() {
	const setSearchValuesProp = useLeadsStore(
		(store) => store.setSearchValuesProp
	)

	return function (dates: RangeValueType) {
		if (!dates) return

		const [dateFrom, dataTo] = dates

		const { DateFrom, DateTo } = LeadsStoreType.SearchValueNames
		setSearchValuesProp(DateFrom, dateFrom)
		setSearchValuesProp(DateTo, dataTo)
	}
}
