import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { EditExerciseFormDataType } from 'parts/types/miscTypes'
import useExercisesListAdminStore from './zustand/store'
import { ExercisesListStoreType } from './zustand/storeTypes'

// Менеджер с методами взаимодействия с Состоянием
export const exercisesFormsManager = {
	// Возвращает функцию открывающую форму редактирования упражнения
	useGetOpenExerciseForm() {
		const updateStore = useExercisesListAdminStore(
			(store) => store.updateStore
		)

		return (exerciseType: null | TrainingEntityTypes.ExerciseTypes) => {
			updateStore({ exerciseType: exerciseType })
		}
	},

	// Возвращает функцию открывающую форму редактирования упражнения
	useGetSetDataToExerciseFormStore() {
		const updateStore = useExercisesListAdminStore(
			(store) => store.updateStore
		)

		return function (formData: null | EditExerciseFormDataType) {
			updateStore({ formData: formData })
		}
	},

	// Обновляет данные Состояния
	updateStore(obj: Partial<ExercisesListStoreType.State>) {
		useExercisesListAdminStore.getState().updateStore(obj)
	},

	// Возвращает функцию открывающую форму редактирования упражнения
	clearStore() {
		useExercisesListAdminStore.getState().updateStore({
			exerciseId: null,
			exerciseType: null,
			formData: null,
			insertBeforeExerciseId: null,
		})
	},
}
