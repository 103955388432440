import React, { useRef } from 'react'
import {
	ExerciseChangesDataMadeByUser,
	SaveExerciseChangesMadeByUser,
} from '../common/useLiftViewDuration'
import {
	useGetDropWordHandler,
	useRestoreGapTextsInTextBlockOnMount,
} from './fn/saveAndRestoreExercise'
import { ExerciseStateType } from './fn/stateType'
import Bottom from './Bottom'
import Words from './Words'
import TextBlock from './TextBlock'
import {
	onDragEnd,
	onDragEnter,
	onDragLeave,
	onDragOver,
	onDragStart,
	onDrop,
} from './fn/drag'
import { TaskExerciseView } from '../common/TaskExerciseView/TaskExerciseView'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import ExerciseDropAdvert from '../common/DropAdvert/ExerciseDropAdvert'

type ExerciseProps = {
	exercise: TrainingEntityTypes.DropWordExercise
	state: ExerciseStateType.Main
	setState: React.Dispatch<React.SetStateAction<ExerciseStateType.Main>>
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

// Компонент группы упражнений
function Exercise(props: ExerciseProps) {
	const {
		exercise,
		state,
		setState,
		saveExerciseChangesMadeByUser,
		exerciseRestoreData,
	} = props

	const textsRef = useRef<null | HTMLDivElement>(null)

	// Занимается сохранением состояния упражнения для восстановления при открытии
	const dropWordHandler = useGetDropWordHandler(saveExerciseChangesMadeByUser)
	// Занимается восстановлением состояния упражнения при открытии
	useRestoreGapTextsInTextBlockOnMount(textsRef.current, exerciseRestoreData)

	return (
		<>
			{exercise.item.task && (
				<TaskExerciseView htmlString={exercise.item.task} />
			)}
			<ExerciseDropAdvert />
			<div
				className="exercise-drop-word-content__text-blocks"
				ref={textsRef}
				onDrop={dropWordHandler}
			>
				{state.textBlocks.map((exerciseBlock, i) => {
					return (
						<TextBlockWrapper
							exerciseBlock={exerciseBlock}
							key={i}
						/>
					)
				})}
			</div>
			<Bottom state={state} setState={setState} textsRef={textsRef} />
		</>
	)
}

type TextBlockWrapperProps = {
	exerciseBlock: ExerciseStateType.TextBlock
}

// Блок со словами для перетаскивания и текстом с пропусками
function TextBlockWrapper(props: TextBlockWrapperProps) {
	const { exerciseBlock } = props
	return (
		<div
			className="exercise-drop-word-content__exercise-wrapper"
			onDragStart={onDragStart}
			onDragEnter={onDragEnter}
			onDragLeave={onDragLeave}
			onDrop={onDrop}
			onDragOver={onDragOver}
			onDragEnd={onDragEnd}
		>
			<Words words={exerciseBlock.words} />
			<TextBlock htmlString={exerciseBlock.htmlString} />
		</div>
	)
}

export default Exercise
