import { useEffect } from 'react'
import { userQuery } from 'parts/requests/user/userQuery'
import EntityTypes from 'parts/types/EntityTypes'
import useAddLiveModalStore from '../zustand/store'

/** Делает запрос на получение id администратора, формирует данные для Состояния и ставит в него */
export function useFillInStore() {
	const updateStore = useAddLiveModalStore((state) => state.updateState)

	const { data: adminDataRes, status } = userQuery
		.getUserByRole(EntityTypes.UserRole.ADMIN)
		.useQuery({
			onError: queryError,
		})

	useEffect(
		function () {
			if (!adminDataRes) return

			updateStore({
				dataLoadingStatus: 'hasData',
				schoolAdminId: adminDataRes.data[0].id,
				schoolAdminName: `${adminDataRes.data[0].firstName || ''} ${
					adminDataRes.data[0].lastName || ''
				}`,
			})
		},
		[status, adminDataRes]
	)
}

/**
 * Функция запускаемая при ошибке запроса данных. Срабатывает для любых запросов.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 * @param err — объект ошибки.
 */
function queryError(err: unknown) {
	const { updateState } = useAddLiveModalStore.getState()

	updateState({ dataLoadingStatus: 'error' })
}
