import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import EntityTypes from '../../../types/EntityTypes'
import AnswerFeedStoreType from './AnswerFeedStoreType'

const initialState: AnswerFeedStoreType.State = {
	answerFeed: [],
	unansweredFeedCount: 0,
}

export const answerFeedSlice = createSlice({
	name: 'answerFeed',
	initialState,
	reducers: {
		getAnswerFeed(state, action: PayloadAction<EntityTypes.Answer_OLD[]>) {
			state.answerFeed = action.payload
				.map((feed) => {
					feed.answerFeedItems = feed.answerFeedItems.sort((a, b) => {
						return a.id - b.id
					})

					return feed
				})
				.sort((a, b) => {
					return b.id - a.id
				})
		},
		deleteAnswerFeedReply(
			state,
			action: PayloadAction<{ replyId: number }>
		) {
			state.answerFeed = state.answerFeed.map((feed) => {
				feed.answerFeedItems = feed.answerFeedItems.filter(
					(reply) => reply.id !== action.payload.replyId
				)
				return feed
			})
		},
		getUnansweredFeedCount(state, action: PayloadAction<number>) {
			state.unansweredFeedCount = action.payload
		},
	},
})

export default answerFeedSlice.reducer
