import { produce } from 'immer'
import { LandingTypes } from 'parts/types/LandingTypes'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import faqBlocksManager from 'pages/landing/landingConstructor/sections/faqSection/faqBlocksManager/faqBlocksManager'

/**
 * Добавляет новый блок в массив блоков
 * @param anchorBlockId — id блока, относительно которого будет добавляться новый блок.
 * Если null, тогда в массиве блоков пусто и новый будет добавлен первом элементом.
 * @param position — позиция куда будет добавлен новый блок: сверху или снизу.
 */
export function useGetAddNewBlock(
	anchorBlockId: null | number,
	position: 'top' | 'bottom' = 'top'
) {
	const [landing, updateStore] = useLandingConstructorStore((s) => [
		s.landing,
		s.updateStore,
	])

	return function () {
		if (!landing) return

		const newBlock = createNewBlock(landing.sections.faq.faqBlocks)

		const anchorBlockIdx = faqBlocksManager.getBlockIdx(
			landing.sections.faq.faqBlocks,
			anchorBlockId
		)

		const updatedLanding = produce(landing, (draft) => {
			if (position === 'top') {
				draft.sections.faq.faqBlocks.splice(anchorBlockIdx, 0, newBlock)
			} else {
				draft.sections.faq.faqBlocks.splice(
					anchorBlockIdx + 1,
					0,
					newBlock
				)
			}
		})

		updateStore({ landing: updatedLanding })
	}
}

/**
 * Создаёт объект нового блока с вопросом и ответом
 * @param faqBlocks — массив блоков
 */
function createNewBlock(
	faqBlocks: LandingTypes.FaqBlock[]
): LandingTypes.FaqBlock {
	return {
		id: createNewBlockId(faqBlocks),
		question: '',
		answer: '',
		isOpen: true,
	}
}

/**
 * Возвращает id блока, который должен быть у нового блока
 * @param blocks — массив блоков с описанием пользователя которому подойдёт курс
 */
function createNewBlockId(blocks: LandingTypes.FaqBlock[]) {
	let newBlockId = 0

	for (let i = 0; i < blocks.length; i++) {
		const currentBlock = blocks[i]

		if (currentBlock.id > newBlockId) {
			newBlockId = currentBlock.id
		}
	}

	return newBlockId + 1
}
