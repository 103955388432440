import React from 'react'
import AudioFrame from 'ui/AudioFrame/AudioFrame'
import { TranscriptionBlock } from '../common/TranscriptionBlock/TranscriptionBlock'
import { TaskExerciseView } from '../common/TaskExerciseView/TaskExerciseView'
import {
	SaveExerciseChangesMadeByUser,
	LiftViewDuration,
	useLiftViewDuration,
	WritingOrAudioAnswerWasSentFn,
	ExerciseChangesDataMadeByUser,
} from '../common/useLiftViewDuration'
import MediaAnswerBlock from '../mediaAnswerBlock/MediaAnswerBlock/MediaAnswerBlock'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import './AudioExerciseView.scss'

type AudioExerciseViewProps = {
	exercise: TrainingEntityTypes.AudioExercise
	liftViewDuration?: LiftViewDuration
	// Запуск этой функции сообщает, что ответ был послан
	writingOrAudioAnswerWasSentFn?: WritingOrAudioAnswerWasSentFn
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

// Вывод аудио-упражнения в уроке
function AudioExerciseView(props: AudioExerciseViewProps) {
	const {
		exercise,
		liftViewDuration,
		writingOrAudioAnswerWasSentFn,
		saveExerciseChangesMadeByUser,
		exerciseRestoreData,
	} = props

	// Считает время прошедшее после открытия упражнения
	// и передаёт количество секунд в liftViewDuration
	useLiftViewDuration(liftViewDuration)

	return (
		<>
			{exercise.item.task && (
				<TaskExerciseView htmlString={exercise.item.task} />
			)}
			<div className="exercise-audio-content__audios-wrapper">
				{exercise.item.audio.map((audioHref, idx) => {
					if (idx < exercise.item.audio.length - 1) {
						return (
							<React.Fragment key={idx}>
								<AudioFrame src={audioHref} />
								<div className="exercise-audio-content__audios-hr" />
							</React.Fragment>
						)
					}
					return <AudioFrame src={audioHref} key={idx} />
				})}
			</div>
			<TranscriptionBlock exercise={exercise} />
			<MediaAnswerBlock
				exercise={exercise}
				writingOrAudioAnswerWasSentFn={writingOrAudioAnswerWasSentFn}
				saveExerciseChangesMadeByUser={saveExerciseChangesMadeByUser}
				exerciseRestoreData={exerciseRestoreData}
			/>
		</>
	)
}

export default AudioExerciseView
