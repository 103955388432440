import schoolRequests from 'parts/requests/school/schoolRequest'

/** Получает с сервера данные настроек платёжных сервисов подключенных к школе */
export async function getPaymentsDataOfSchool(schoolId: number) {
	// Отправить запрос и получить ответ
	const response = await schoolRequests.getPaymentsData(schoolId)

	if (response.data) {
		return response.data
	}
}

/**
 * Тип подключения карточки платёжной системы:
 * idle — данные для подключения ещё не переданы
 * off — платёжная система отключена
 * on — платёжная система включена
 */
export type CardConnectionType = 'idle' | 'off' | 'on'
