import React from 'react'
import { useTranslation } from 'react-i18next'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import RestrictionsService from '../../../parts/services/RestrictionsService'
import { LeadsTableController } from '../leadsTable/LeadsTable/LeadsTableController'
import { EmptyBlock } from 'ui/EmptyBlock/EmptyBlock'
import RowsContainer from 'ui/RowsContainer/RowsContainer'
import { LeadsStatsController } from '../LeadsStats/LeadsStatsController'

/** Страница со списком покупок */
function LeadsArticle() {
	const leadsStatsRouter = LeadsStatsController.router()
	const leadsTableRouter = LeadsTableController.router()

	const inCenter =
		leadsTableRouter.status == 'loading' ||
		leadsTableRouter.status == 'noLeads'

	const containerWidth =
		leadsTableRouter.status == 'hasLeads' ? 'full' : 'small'

	const canUseCRM = RestrictionsService.useCanUseCRM()

	if (!canUseCRM) {
		return null
	}

	return (
		<ArticleContentWrapper
			center={inCenter}
			innerWrapperWidth={containerWidth}
		>
			<RowsContainer>
				{leadsStatsRouter.element}
				{leadsTableRouter.status == 'loading' &&
					leadsTableRouter.element}
				{leadsTableRouter.status == 'noLeads' && <NoLeads />}
				{leadsTableRouter.status == 'hasLeads' &&
					leadsTableRouter.element}
			</RowsContainer>
		</ArticleContentWrapper>
	)
}

export default LeadsArticle

function NoLeads() {
	const { t } = useTranslation()

	return (
		<EmptyBlock
			text={
				<>
					{t('leads.noLeadsScreenNoLeads')}
					<br />
					{t('leads.noLeadsScreenNoLeads2')}
				</>
			}
		/>
	)
}
