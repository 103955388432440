import React from 'react'
import cn from 'classnames'

type HtmlEditorContentViewProps = {
	areaRef?: React.MutableRefObject<null | HTMLDivElement> // Объект возвращаемый через useRef()
	outerClass?: string // Дополнительный класс задаваемый обёртке контента
	contentExtraClass?: string
}

// Обёртка для помещения контента сгенерированном в редакторе.
// Здесь применены классы для аналогично отображения элементов.
function HtmlEditorContentView(props: HtmlEditorContentViewProps) {
	const { areaRef, outerClass = '', contentExtraClass = '' } = props

	return (
		<div className={cn('html-editor-rewrite', outerClass)}>
			<div
				className={cn('ql-container', contentExtraClass)}
				ref={areaRef}
			/>
		</div>
	)
}

export default HtmlEditorContentView
