import React from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import EntityTypes from 'parts/types/EntityTypes'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import { useTranslation } from 'react-i18next'
import { useDeleteAnswer } from './fn/deleteAnswer'

type DeleteButtonProps = {
	answer: EntityTypes.Answer
}

// Кнопка удаления ответа
function DeleteButton(props: DeleteButtonProps) {
	const { answer } = props
	const { t } = useTranslation()

	const userRoles = useGetUserRole()

	const deleteAnswer = useDeleteAnswer(answer.id)

	if (!userRoles.isAdmin || !answer.groupId) return null

	return (
		<Button type="text" onClick={deleteAnswer} size="small">
			<DeleteOutlined />
			{t('answersFeed.answerBlockDeleteAnswerButton')}
		</Button>
	)
}

export default DeleteButton
