import { Button, Col, Form, Input, Row } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import SendConfirmationCodeToEmailBtn from 'widgets/SendConfirmationCodeToEmailBtn/SendConfirmationCodeToEmailBtn'
import { useGetOnSubmit } from './fn/submit'
import useConfirmEmailStore from './zustand/store'
import { UFormNewGeneralError } from 'ui/UForm/UForm'
import { useGetSendConfirmationCode } from './fn/sendConfirmationCode'
import { FieldNames, setErrorsToForm } from './fn/form'
import './ConfirmForm.scss'

// Форма подтверждения почты
function ConfirmForm() {
	const [form] = Form.useForm()

	const onSubmit = useGetOnSubmit()
	setErrorsToForm(form)

	const formErrors = useConfirmEmailStore((state) => state.formErrors)

	return (
		<Form
			form={form}
			name="confirmEmail"
			onFinish={onSubmit}
			layout="vertical"
		>
			<CodeInput />
			<UFormNewGeneralError message={formErrors.message} />
			<Bottom />
			<EmailWarning />
			<SendCodeBtn />
		</Form>
	)
}

export default ConfirmForm

function CodeInput() {
	const { t } = useTranslation()

	return (
		<Form.Item
			name={FieldNames.ActivationCode}
			rules={[
				{
					required: true,
					message: t('auth.startAdminRegFormFieldCodeRequired'),
				},
				{
					min: 5,
					message: t('auth.startAdminRegFormFieldCodeLength'),
				},
				{
					max: 5,
					message: t('auth.startAdminRegFormFieldCodeLength'),
				},
			]}
		>
			<Input
				size="large"
				className="confirm-email-page-content__input"
				placeholder="00000"
				autoFocus
			/>
		</Form.Item>
	)
}

function Bottom() {
	const { t } = useTranslation()

	const form = Form.useFormInstance()
	const confirmEmailStore = useConfirmEmailStore((state) => state)

	return (
		<Form.Item
			shouldUpdate
			className="confirm-email-page-content__bottom-form-item"
		>
			{() => {
				const formErrors = form
					.getFieldsError()
					.filter(({ errors }) => errors.length)

				return (
					<Button
						block
						size="large"
						type="primary"
						htmlType="submit"
						disabled={
							confirmEmailStore.isSubmitting ||
							formErrors.length > 0
						}
						loading={confirmEmailStore.isSubmitting}
					>
						{t('auth.startAdminRegFormOkButton')}{' '}
						<ArrowRightOutlined />
					</Button>
				)
			}}
		</Form.Item>
	)
}

function EmailWarning() {
	const { t } = useTranslation()

	return (
		<p className="confirm-email-page-content__email-warning">
			{t('auth.startAdminRegFormWarning')}
		</p>
	)
}

function SendCodeBtn() {
	const sendConfirmationCode = useGetSendConfirmationCode()

	return (
		<Row justify="center">
			<Col>
				<SendConfirmationCodeToEmailBtn reqFn={sendConfirmationCode} />
			</Col>
		</Row>
	)
}
