import useExerciseStore from '../../zustand/exerciseState'

/**
 * Возвращает функцию, выполняемую после нажатия на кнопку «Выполнено» и отправки запроса.
 * Она ставит в Состояние свойство showResult в true чтобы появился экран со статистикой.
 * И свойство doneAllow если
 */
export function useGetAfterDoneExercise() {
	const saveStoreForRestore = useExerciseStore(
		(store) => store.saveStoreForRestore
	)
	const doneCounter = useExerciseStore((store) => store.doneCounter)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		updateStore({ saveStoreForRestore: saveStoreForRestore + 1 })

		// Отправить на состояние с экраном результата
		updateStore({ stage: 'Result', doneCounter: doneCounter + 1 })
	}
}
