import cn from 'classnames'
import React from 'react'
import TakeAPartButton from '../../../details/TakeAPartButton/TakeAPartButton'
import useLandingBuilderStore from '../../../zustand/store'
import './WaysBlocks.scss'
import './WaysBlock.scss'

export function WaysBlocks() {
	return (
		<div className="landing-ways-blocks">
			<WrongBlock />
			<RightBlock />
		</div>
	)
}

export function WrongBlock() {
	const landing = useLandingBuilderStore((store) => store.landing)

	return (
		<div className="landing-ways-block landing-ways-block--wrong">
			<div className="landing-ways-block__content">
				<h3 className="landing-ways-block__header">
					{landing.sections.ways.wrongWay.header}
				</h3>
				<p className="landing-ways-block__text">
					{landing.sections.ways.wrongWay.text}
				</p>
			</div>
			<img
				src="/landing/images/two-ways-sad.png"
				className="landing-ways-block__emoji landing-ways-block__emoji--sad"
				alt=""
			/>
			<div className="landing-ways-block__btn-wrapper">
				<button className="landing-ways-block__do-nothing-btn">
					Ничего не делать
				</button>
			</div>
		</div>
	)
}

export function RightBlock() {
	const landing = useLandingBuilderStore((store) => store.landing)
	const { colorScheme } = landing

	const wrapperClasses = [
		'landing-ways-block',
		'landing-ways-block--right',
		'landing-ways-block--right--' + colorScheme,
	]

	return (
		<div className={cn(wrapperClasses)}>
			<div className="landing-ways-block__content">
				<h3 className="landing-ways-block__header">
					{landing.sections.ways.rightWay.header}
				</h3>
				<p className="landing-ways-block__text">
					{landing.sections.ways.rightWay.text}
				</p>
			</div>
			<img
				src="/landing/images/two-ways-happy.png"
				className="land-const-ways-block__emoji land-const-ways-block__emoji--happy"
				alt=""
			/>
			<div className="landing-ways-block__btn-wrapper">
				<TakeAPartButton sectionName="ways" />
			</div>
		</div>
	)
}
