import { DatePicker, Form, Input, Select, Cascader, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { UFormNewBlockHeader, UFormNewRow } from 'ui/UForm/UForm'
import { FieldNames } from './fn/form'
import PaymentTypes from 'parts/types/PaymentTypes'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import useAddStudentStore from './zustand/store'
import TrainingAdminService from 'parts/services/TrainingAdminService'
import UserService from 'parts/services/UserService'

const { Option } = Select

// Форма с новой оплатой
function PurchaseForm() {
	const { t } = useTranslation()
	const isOpen = useAddStudentStore((store) => store.isPurchaseOpen)

	return (
		<>
			{isOpen && (
				<>
					<UFormNewBlockHeader
						text={t('students.purchaseFormPersonalBlockHeader')}
					/>
					<TrainingsSelect />
					<DateAndBuyWayInputs />
					<CurrencyValueAndTimeInputs />
					<CuratorsSelect />
				</>
			)}
			<ShowHideButton />
		</>
	)
}

export default PurchaseForm

// Выпадающий список с выбором курса и группы
function TrainingsSelect() {
	const { t } = useTranslation()
	const options = TrainingAdminService.useGetTrainingsListAsCascaderOption()

	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.Training}
				label={t('students.purchaseFormFieldGroupLabel')}
				rules={[
					{
						required: true,
						message: t(
							'students.purchaseFormFieldGroupRequiredError'
						),
					},
				]}
			>
				<Cascader options={options} />
			</Form.Item>
		</UFormNewRow>
	)
}

// Поля для указания даты покупки и способа оплаты
function DateAndBuyWayInputs() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.PurchaseDate}
				label={t('students.purchaseFormFieldPurchaseDateLabel')}
				rules={[
					{
						required: true,
						message: t(
							'students.purchaseFormFieldPurchaseDateRequiredError'
						),
					},
				]}
			>
				<DatePicker
					format="YYYY/MM/DD"
					className="add-student-form__date"
				/>
			</Form.Item>
			<Form.Item
				name={FieldNames.PaymentSystem}
				label={t('students.purchaseFormFieldPaymentSystemLabel')}
				rules={[
					{
						required: true,
						message: t(
							'students.purchaseFormFieldPaymentSystemRequiredError'
						),
					},
				]}
			>
				<Select>
					{Object.values(PaymentTypes.Systems).map((system) => {
						return (
							<Option value={system.system} key={system.system}>
								{system.name}
							</Option>
						)
					})}
				</Select>
			</Form.Item>
		</UFormNewRow>
	)
}

// Поля для указания валюты, стоимости и за какой период была оплата
function CurrencyValueAndTimeInputs() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Currency}
				label={t('students.purchaseFormFieldCurrencyLabel')}
				rules={[
					{
						required: true,
						message: t(
							'students.purchaseFormFieldCurrencyRequiredError'
						),
					},
				]}
			>
				<Select
					showSearch
					placeholder="RUB"
					optionFilterProp="children"
				>
					{Object.values(PaymentTypes.Currencies).map((currency) => {
						return (
							<Option
								value={currency.currency}
								key={currency.currency}
							>
								{currency.currency}
							</Option>
						)
					})}
				</Select>
			</Form.Item>
			<Form.Item
				name={FieldNames.Amount}
				label={t('students.purchaseFormFieldAmountLabel')}
				rules={[
					{
						required: true,
						message: t(
							'students.purchaseFormFieldAmountRequiredError'
						),
					},
				]}
			>
				<Input type="number" />
			</Form.Item>
			{/*<Form.Item
				name={FieldNames.PaymentMode}
				label="За что"
				rules={[
					{
						required: true,
						message: 'Срок',
					},
				]}
			>
				<Select showSearch optionFilterProp="children">
					{Object.values(PaymentTypes.PricePeriods).map((period) => {
						return (
							<Option value={period.period} key={period.period}>
								{period.name}
							</Option>
						)
					})}
				</Select>
			</Form.Item>*/}
		</UFormNewRow>
	)
}

// Выпадающий список с выбором куратора
function CuratorsSelect() {
	const { t } = useTranslation()
	const curatorsList = UserService.useGetCuratorsList(true)

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.CuratorId}
				label={t('students.purchaseFormFieldCuratorLabel')}
				/*rules={[
					{
						required: true,
						message: 'Укажите куратора',
					},
				]}*/
			>
				<Select
					showSearch
					placeholder={t(
						'students.purchaseFormFieldCuratorEmptyItem'
					)}
					optionFilterProp="children"
					allowClear
				>
					{curatorsList.map((curator) => {
						return (
							<Option value={curator.id} key={curator.id}>
								{curator.name}
							</Option>
						)
					})}
				</Select>
			</Form.Item>
		</UFormNewRow>
	)
}

// Кнопка открывающая/скрывающая форму покупки
function ShowHideButton() {
	const { t } = useTranslation()

	const [updateStore, isOpen] = useAddStudentStore((state) => [
		state.updateStore,
		state.isPurchaseOpen,
	])

	const text = isOpen
		? t('students.purchaseFormDeletePurchaseButton')
		: t('students.purchaseFormAddPurchaseButton')
	const icon = isOpen ? <MinusOutlined /> : <PlusOutlined />

	return (
		<UFormNewRow topOffset>
			<Button
				icon={icon}
				onClick={() => updateStore({ isPurchaseOpen: !isOpen })}
			>
				{text}
			</Button>
		</UFormNewRow>
	)
}
