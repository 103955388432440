import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import React, { useMemo } from 'react'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'
import { useGetUpdateTextInTariffStore } from 'pages/landing/landingConstructor/tariffs/card/common/EditableText/fn/updateTextInTariffStore'

/**
 * Возвращает обработчик изменения редактируемого спана
 * @param tariffInnerId — идентификатор карточки у которой изменяют текст
 * @param propsPathArr — массив с названиями свойств и индексов с путём до свойства, в котором находится редактируемый текст.
 * @param afterChange — функция, запускаемая после изменения текста
 */
export function useGetOnInput<
	A extends keyof TariffsStoreType.Tariff,
	B extends keyof TariffsStoreType.Tariff[A],
	C extends keyof TariffsStoreType.Tariff[A][B],
	D extends keyof TariffsStoreType.Tariff[A][B][C],
	E extends keyof TariffsStoreType.Tariff[A][B][C][D],
	F extends keyof TariffsStoreType.Tariff[A][B][C][D][E],
	G extends keyof TariffsStoreType.Tariff[A][B][C][D][E][F]
>(
	tariffInnerId: number,
	propsPathArr: [A, B?, C?, D?, E?, F?, G?],
	afterChange?: () => void
) {
	const updateTextInLanding = useGetUpdateTextInTariffStore(tariffInnerId)

	return (event: React.FormEvent<HTMLSpanElement>) => {
		// @ts-ignore
		const newText = event.target.textContent

		// @ts-ignore
		updateTextInLanding(propsPathArr, newText)

		if (afterChange) {
			afterChange()
		}
	}
}

/**
 * Возвращает изначальный текст абзаца при открытии тарифа.
 * Не стал делать связывание текста с данными потому что при редактировании курсор улетает в начало редактируемого блока
 * @param tariffInnerId — внутренний идентификатор тарифа (для БД — отдельный)
 * @param propsPathArr — массив с названиями свойств и индексов с путём до свойства, у которого нужно получить значение.
 */
export function useGetText<
	A extends keyof TariffsStoreType.Tariff,
	B extends keyof TariffsStoreType.Tariff[A],
	C extends keyof TariffsStoreType.Tariff[A][B],
	D extends keyof TariffsStoreType.Tariff[A][B][C],
	E extends keyof TariffsStoreType.Tariff[A][B][C][D],
	F extends keyof TariffsStoreType.Tariff[A][B][C][D][E],
	G extends keyof TariffsStoreType.Tariff[A][B][C][D][E][F]
>(tariffInnerId: number, propsPathArr: [A, B?, C?, D?, E?, F?, G?]) {
	const tariff = tariffsManager.getTariff(tariffInnerId)

	return useMemo(function () {
		let parentObj: any = tariff

		for (let i = 0; i < propsPathArr.length; i++) {
			const isLastItem = i == propsPathArr.length - 1
			if (isLastItem) break

			parentObj = parentObj[propsPathArr[i]]
		}

		const lastPropName = propsPathArr[propsPathArr.length - 1]

		return parentObj[lastPropName]
	}, [])
}
