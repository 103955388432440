import { Button } from 'antd'
import React, { useEffect } from 'react'
import AppUrls from '../../../parts/constants/pageUrl'
import { useGoToPage } from '../../../parts/utils/hooks/hooks'
import { getImageUrl, getText } from './fn/getText'
import { useNavigate } from 'react-router-dom'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import './ErrorPage.scss'
import useErrorPageStore from './zustand/store'

type ErrorPageProps = {
	errorCode: ErrorCodeType
	// Адрес, куда нужно перенаправить пользователя в случае попадания на эту страницу
	redirectUrl?: string
}
export type ErrorCodeType = 403 | 404 | 500

function ErrorPage(props: ErrorPageProps) {
	const { errorCode, redirectUrl } = props

	const navigate = useNavigate()
	const error = useErrorPageStore((store) => store.error)

	const imageUrl = getImageUrl(errorCode)
	const { headerText, descText } = getText(errorCode)

	const goToMainPage = useGoToPage(AppUrls.Main().url)

	useEffect(
		function () {
			if (redirectUrl) {
				navigate(redirectUrl)
			}
		},
		[redirectUrl]
	)

	return (
		<ArticleContentWrapper center innerWrapperWidth="middle">
			<div className="error-page">
				<img
					src={imageUrl}
					className="error-page__illustration"
					alt="error illustration"
				/>
				<div className="error-page__content">
					<h1 className="error-page__header">{headerText}</h1>
					<p className="error-page__text">{descText}</p>
					{error && (
						<p className="error-page__error-text">
							Ошибка: {error.message}
						</p>
					)}
					<div className="error-page__button-wrapper">
						<Button onClick={goToMainPage} type="primary">
							На главную
						</Button>
					</div>
				</div>
			</div>
		</ArticleContentWrapper>
	)
}

export default ErrorPage
