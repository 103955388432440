import TrainingEntityTypes from './TrainingEntityTypes'
import PaymentTypes from './PaymentTypes'

namespace EntityTypes {
	// Думаю нужно сделать в единственном числе
	export enum UserRole {
		ADMIN = 'ADMIN',
		STUDENT = 'STUDENT',
		CURATOR = 'CURATOR',
		MANAGER = 'MANAGER',
		SUPERADMIN = 'SUPERADMIN',
	}

	export enum UserBanReason {
		Spammer,
	}

	export type User = {
		id: number
		email: string
		isActivated?: boolean
		firstName?: string
		lastName?: string
		avatar?: string
		createdAt: string // '2022-09-19T12:01:13.153Z'
		lastVisit?: string // '2022-09-19T12:01:13.153Z'
		role: UserRole
		groups: GroupData[]
		banned: boolean
		banReason: UserBanReason
		phone: string // '+79272556677'
		instagram: string // 'https://www.instagram.com/mariapedraza_/'
		country?: string // 'Russia'
		city?: string // 'Kazan'
		birthday?: string // '2022-12-30T11:20:19.663Z'
		sex?: Gender
		isDeleted: boolean
		tariffPlan: PaymentTypes.TariffPlan
	}

	export enum Gender {
		Male = 'male',
		Female = 'female',
	}

	export interface Curator extends User {
		students: User[]
		studentCount: number
	}

	export enum GroupType {
		Free = 'free',
		Pay = 'paid',
	}

	// Группа, в которой находится ученик
	export type GroupData = {
		// Идентификатор группы
		id: number
		// Название группы
		name: string
		// Тип группы (платная, бесплатная, тестовая)
		type: string
		// Количество участников в группе (учеников и сотрудников)
		numOfParticipants: number
		// При каком условии открывается доступ к курсу?
		startCondition: GroupStartCondition
		// Как открываются уроки: сразу, по интервалам или в определённые дни
		mode: GroupMode
		// Если в mode указали 1, то уроки должны открываться по интервалам в днях.
		// И в массиве указывается одно значение из списка возможных вариантов интервалов.
		// Если в mode указали 2, то уроки должны открываться в определённые дни недели.
		// И в массиве указывается несколько значений из списка дней недели.
		openLessonCondition: OpenLessonCondition
		// Дата, когда ученик начал курс ("2022-11-22T10:34:42.455Z") или null если ещё не начал
		startDate: null | string
		// Продолжительность курса в днях
		duration: number
		// id школы
		// schoolId: number
		// id курса, к которому закреплена группа
		trainingId: number
		// Группа удалена?
		isDeleted?: boolean
		// Группа активна?
		isActive?: boolean
		// Время доступа к материалам курса (в днях) после завершения группы
		accessToCourse: number
		tariffs: Tariff[]
		// Ученики, кураторы и менеджеры записанные в эту группу
		users: EntityTypes.User[]
		createdAt: string // "2022-11-22T10:34:42.455Z"
		// Айди открытых уроков для тестовых групп
		openLessons: number[]
	}

	export type OpenLessonCondition = (OpeningByIntervals | OpeningByWeekDays)[]

	// Когда начинается обучение в группе?
	export enum GroupStartCondition {
		// Точная дата
		StartDate = 0,
		// Сразу после покупке
		PurchaseDate = 1,
		// После открытия первого урока
		OpenFirstLesson = 2,
	}

	// Как открываются уроки в группе
	export enum GroupMode {
		// Открыты все сразу
		Open = 0,
		// Открываются через интервалы в днях. День недели указывается в
		ByIntervals = 1,
		// Открываются в определённые дни
		ByWeekDays = 2,
	}

	export enum OpeningByIntervals {
		AfterOneDay = 20,
		AfterTwoDays = 21,
		AfterThreeDays = 22,
		AfterFourDays = 23,
		AfterFiveDays = 24,
		AfterSixDays = 25,
		AfterSevenDays = 26,
	}

	export enum OpeningByWeekDays {
		Monday = 1,
		Tuesday = 2,
		Wednesday = 3,
		Thursday = 4,
		Friday = 5,
		Saturday = 6,
		Sunday = 0,
		OnWeekdays = 7,
		Everyday = 8,
		OnWeekends = 9,
	}

	export type Tariff = {
		id: null | number
		groupId: number
		// Выделен ли тариф? (карточка выделяется цветом)
		isFlashed: boolean
		// Название тарифа
		name: string
		// Описание тарифа
		description: string
		// Тезисы
		theses: TariffThesis[]
		// Цены
		prices: TariffPrice[]
		// Адрес курса
		landingUrl: string
		// Обложка курса
		trainingCover: null | string
		// Название курса
		trainingName: string
		createdAt?: string // date
		updatedAt?: string // date
	}

	export type TariffThesis = {
		// Текст тезиса
		text: string
		// Тезис перечёркнут?
		crossedOut?: boolean
	}

	// Цена тарифа
	export type TariffPrice = {
		// Основной тариф?
		isMain: boolean
		// Текст до стоимости (на основном тарифе пустой)
		text: string
		// Валюта
		currency: PaymentTypes.Currency
		// Текущая цена
		price: number
		// Старая цена (зачёркнутая)
		oldPrice: number
		// Период (1 или 2)
		period: PaymentTypes.PricePeriod
		// Платёжная система
		paymentSystem: PaymentTypes.System
		// Текст на кнопке покупки
		buttonText: string
	}

	// ===============================

	export type SelectDayOptions = {
		label: string
		value: EntityTypes.OpeningByWeekDays
		disabled: boolean
	}

	export type CommentItem = {
		id: number
		ownerId: number
		ownerEmail: string
		exerciseId: number
		trainingName: string
		lessonName: string
		text: string
		answer?: string
		isArchived: boolean
		date: Date
	}

	// ПОТОМ УДАЛИТЬ
	export type AnswerFeedItem_OLD = {
		id: number
		text: string
		createdAt: Date
		userId: number
		user: EntityTypes.User
	}

	// ПОТОМ УДАЛИТЬ
	export type Answer_OLD = {
		id: number
		user: EntityTypes.User
		groupId: number
		status: AnswerStatus
		exercise: TrainingEntityTypes.ExerciseItem
		createdAt: Date
		answerFeedItems: AnswerFeedItem_OLD[]
		text: string
		audio: string
	}

	export type Answer = {
		id: number
		user: EntityTypes.User
		groupId: number
		status: AnswerStatus
		exercise: TrainingEntityTypes.ExerciseItem
		createdAt: string // '2023-10-12T09:48:04.857Z'
		answerFeedItems: AnswerFeedItem[]
		text: string
		audio: string
	}

	export type AnswerFeedItem = {
		id: 1
		text: string
		createdAt: string // '2023-10-12T09:48:04.857Z'
		userId: number
	}

	export enum AnswerStatus {
		Checking = 0,
		Checked = 1,
		NotChecked = 2,
	}

	// Журнал действий пользователя
	export type UserLogs = UserLog[]

	export type UserLogName =
		| 'login'
		| 'payment'
		| 'wrong password'
		| 'recovery'

	export type UserLog = {
		id: number
		name: UserLogName
		description: string
		createdAt: string // '2023-01-04T08:22:23.820Z'
	}

	// Оплата пользователя
	export type PaymentOfUser = {
		id: number
		status: string
		training: string
		group: string
		paymentDate: string // '2023-01-06T17:14:10.071Z'
		price: number
		currency: PaymentTypes.Currency
	}

	export type Exercise = {
		id: number
		type: string
		exercise: string
		order: number
	}
	export type Lesson = {
		id: number
		cover: null | string
		name: string
		description: null | string
		exercises: Exercise[]
		isGlobalPreset?: boolean
	}
}

export default EntityTypes
