import EntityTypes from 'parts/types/EntityTypes'
import { divideTo100 } from 'parts/utils/number'

/**
 * Так как с сервера все цены тарифов приходят умноженными на 100, то функция проходит по всем ценам и делит на 100
 * @param tariffs — массив тарифов
 */
export function prepareTariffs(tariffs: EntityTypes.Tariff[]) {
	tariffs.forEach((tariff) => {
		tariff.prices.forEach((price) => {
			price.price = divideTo100(price.price)
			price.oldPrice = divideTo100(price.oldPrice)
		})
	})
}
