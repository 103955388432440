import {
	ExerciseChangesDataMadeByUser,
	LiftViewDuration,
	SaveExerciseChangesMadeByUser,
	useLiftViewDuration,
} from '../common/useLiftViewDuration'
import { useCreateExerciseState } from './fn/state'
import Exercise from './Exercise'
import Result from '../common/Result/Result'
import { useGetPassExerciseAgainFn } from './fn/result'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import './GapsInWordsExerciseView.scss'

type GapsInWordsExerciseViewProps = {
	exercise: TrainingEntityTypes.GapsInWordsExercise
	liftViewDuration?: LiftViewDuration
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

// Упражнение по выбору правильного слова (обвес + упражнение + результат)
function GapsInWordsExerciseView(props: GapsInWordsExerciseViewProps) {
	const {
		exercise,
		liftViewDuration,
		saveExerciseChangesMadeByUser,
		exerciseRestoreData,
	} = props

	const { exerciseState, setExerciseState } = useCreateExerciseState()

	// Считает время прошедшее после открытия упражнения
	// и передаёт количество секунд в liftViewDuration
	const durationSec = useLiftViewDuration(
		liftViewDuration,
		exerciseState.showResult
	)

	// Функция, запускающая прохождение упражнения заново
	const passExerciseAgainFn = useGetPassExerciseAgainFn(setExerciseState)

	return (
		<>
			{exerciseState.showResult ? (
				<Result
					onPassAgainButtonClick={passExerciseAgainFn}
					seconds={durationSec}
					passed={exerciseState.rightAnswers}
					total={exerciseState.totalGaps}
				/>
			) : (
				<Exercise
					exercise={exercise}
					exerciseState={exerciseState}
					setExerciseState={setExerciseState}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
					exerciseRestoreData={exerciseRestoreData}
				/>
			)}
		</>
	)
}

export default GapsInWordsExerciseView
