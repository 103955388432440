import React, { ReactNode } from 'react'
import {
	ArrowDownOutlined,
	ArrowUpOutlined,
	MoreOutlined,
	PlusOutlined,
} from '@ant-design/icons'
import { Button, Dropdown, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useGetOpenExercisesAsideFn } from './fn/ConstructorSettingsLayout-func'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import { useGetDropdownItems } from './fn/lessonOptions'
import {
	useGetMoveExerciseFn,
	useIsMoveExerciseBtnDisabled,
} from './fn/moveExercise'
import './ConstructorSettingsLayout.scss'

type ConstructorSettingsLayoutProps = {
	exercise: TrainingEntityTypes.ExerciseItem
	children: ReactNode
}

/** Обёртка упражнения с кнопкой вставки нового упражнения, редактирования и удаления текущего упражнения. */
function ConstructorSettingsLayout(props: ConstructorSettingsLayoutProps) {
	const { children, exercise } = props

	const { t } = useTranslation()
	const userRoles = useGetUserRole()

	const openExercisesAside = useGetOpenExercisesAsideFn(
		exercise?.id as number
	)

	const moveExerciseUp = useGetMoveExerciseFn(exercise?.id as number, 'up')
	const moveExerciseDown = useGetMoveExerciseFn(
		exercise?.id as number,
		'down'
	)
	const isMoveUpExerciseBtnDisabled = useIsMoveExerciseBtnDisabled(
		exercise?.id as number,
		'up'
	)
	const isMoveDownExerciseBtnDisabled = useIsMoveExerciseBtnDisabled(
		exercise?.id as number,
		'down'
	)

	const dropdownItems = useGetDropdownItems(exercise)

	return (
		<div className="constructor-layout" data-exercise-id={exercise.id}>
			<div>
				<Tooltip title="Добавить упражнение">
					<Button
						shape="circle"
						icon={<PlusOutlined />}
						onClick={openExercisesAside}
					/>
				</Tooltip>
			</div>
			<div className="constructor-layout__content">
				<div className="constructor-layout__right">
					<div className="constructor-layout__settings">
						<div className="constructor-layout__buttons">
							<Tooltip placement="top" title="Поднять выше">
								<Button
									onClick={moveExerciseUp}
									disabled={isMoveUpExerciseBtnDisabled}
								>
									<ArrowUpOutlined />
								</Button>
							</Tooltip>
							<Tooltip placement="top" title="Опустить ниже">
								<Button
									onClick={moveExerciseDown}
									disabled={isMoveDownExerciseBtnDisabled}
								>
									<ArrowDownOutlined />
								</Button>
							</Tooltip>
						</div>
						{userRoles.isAdminOrCurator && (
							<Dropdown menu={{ items: dropdownItems }}>
								<Button
									type="text"
									icon={
										<MoreOutlined
											style={{ fontSize: 20 }}
										/>
									}
								/>
							</Dropdown>
						)}
					</div>
				</div>
				<div className="constructor-layout__left">{children}</div>
			</div>
		</div>
	)
}

export default ConstructorSettingsLayout
