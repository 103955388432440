import { useMemo } from 'react'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import { useTranslation } from 'react-i18next'
import { useGetEditExercise } from './ConstructorSettingsLayout-func'
import { useGetOpenDeleteExerciseModal } from './deleteExercise'

/**
 * Возвращает массив пунктов, которые должны быть в выпадающем списке в упражнении
 * @param exercise — данные об упражнении
 */
export function useGetDropdownItems(
	exercise: TrainingEntityTypes.ExerciseItem
) {
	const { t } = useTranslation()
	const userRoles = useGetUserRole()

	const editExercise = useGetEditExercise(exercise.id || 0, exercise.type)
	const openDeleteExerciseModal = useGetOpenDeleteExerciseModal(
		exercise.id || 0
	)

	return useMemo(
		function () {
			const items: ItemType[] = [
				{ label: 'Редактировать', key: 'edit', onClick: editExercise },
			]

			if (userRoles.isAdmin) {
				items.push({
					label: 'Удалить',
					key: 'delete',
					onClick: openDeleteExerciseModal,
				})
			}

			return items
		},
		[userRoles]
	)
}
