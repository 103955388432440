import { FC } from 'react'
import dayjs from 'dayjs'
import cn from 'classnames'
import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'
import { Checkbox } from 'antd'
import { FileTextOutlined, FolderOutlined } from '@ant-design/icons'
import {
	humanFileSize,
	humanFileType,
} from 'widgets/livelesson/liveLesson/fn/liveUtils'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { ItemAction } from '../ItemAction/ItemAction'

import styles from './StorageItem.module.scss'

interface StorageItemProps {
	item: LiveLessonEntityTypes.StorageItem
	checked: boolean
	onClick: (itemId: number) => void
	onChecked: (item: LiveLessonEntityTypes.StorageItem, state: boolean) => void
}
const StorageItem: FC<StorageItemProps> = (props) => {
	const { item, onClick, onChecked, checked } = props
	const fileType =
		humanFileType(item.fileType) === 'folder'
			? 'Папка'
			: humanFileType(item.fileType)

	const onChange = (e: CheckboxChangeEvent) => {
		onChecked(item, e.target.checked)
	}

	const onContentClick = () => {
		onClick(item.id)
	}

	return (
		<div
			className={cn(styles.StorageItem, {
				[styles.StorageItem__ItemChecked]: checked,
			})}
		>
			<div className={styles.StorageItem__CheckBox}>
				<Checkbox onChange={onChange} checked={checked} />
			</div>
			<div
				className={styles.StorageItem__Content}
				onClick={onContentClick}
			>
				<div className={styles.StorageItem__Name}>
					{item.isFolder ? <FolderOutlined /> : <FileTextOutlined />}
					<span className={styles.StorageItem__FileName}>
						{item.name}
					</span>
				</div>
				<div className={styles.StorageItem__SizeType}>
					{!item.isFolder && (
						<span>{humanFileSize(item.fileSize)}</span>
					)}
					<span>{fileType}</span>
				</div>
				<div className={styles.StorageItem__UploadDate}>
					{dayjs(item.uploadDate).format('YYYY-MM-DD hh:mm:ss')}
				</div>
			</div>
			<div className={styles.StorageItem__Actions}>
				<ItemAction storageItem={item} mobile isLive={true} />
			</div>
		</div>
	)
}

export default StorageItem
