import $api from '../http'
import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'
import LiveLessonApiTypes from './liveLessonApiTypes'

// Запросы к методам получения уроков
const liveLessonRequests = {
	// Если есть id урока — редактирование урока, если нет id — создание нового урока
	// ЭТО НУЖНО ПЕРЕДЕЛАТЬ НА ДВА РАЗНЫХ МЕТОДА
	createOrUpdateLiveLesson(
		dto: LiveLessonApiTypes.CreateOrUpdateLiveLessonDto
	) {
		if (dto.id) {
			return $api.put<LiveLessonApiTypes.CreateOrUpdateLiveLesson>(
				'/api/live',
				dto
			)
		} else {
			return $api.post<LiveLessonApiTypes.CreateOrUpdateLiveLesson>(
				'/api/live',
				dto
			)
		}
	},

	// Получение списка комнат администратором.
	getLiveLessons() {
		return $api.get<LiveLessonApiTypes.GetLiveLessons>('/api/live')
	},

	getMessageServer() {
		return $api.get<LiveLessonApiTypes.GetMessageServer>(
			'/api/live/message-server'
		)
	},

	getLiveLesson(liveId: number | string) {
		return $api.get<LiveLessonEntityTypes.LiveLesson>('/api/live/' + liveId)
	},

	// Удаление live-урока
	deleteLiveLesson(liveId: number) {
		return $api.delete<LiveLessonApiTypes.DeleteLiveLesson>(
			`/api/live/${liveId}`
		)
	},

	// Получение openvidu токена
	getLiveLessonToken(liveId: number | string) {
		return $api.get<LiveLessonApiTypes.GetToken>(
			'/api/live/token/' + liveId
		)
	},

	leaveParticipant(dto: LiveLessonApiTypes.LeaveParticipantDto) {
		return $api.post<LiveLessonApiTypes.LeaveParticipant>(
			'/api/live/leave',
			dto
		)
	},

	getLiveWhiteboardScene(liveId: number, whiteboardId: string) {
		return $api.get<LiveLessonApiTypes.GetLiveWhiteboardScene>(
			`/api/live/${liveId}/whiteboard/${whiteboardId}`
		)
	},

	saveLiveWhiteboardScene(
		dto: LiveLessonApiTypes.SaveLiveWhiteboardSceneDto
	) {
		return $api.post<LiveLessonApiTypes.SaveLiveWhiteboardScene>(
			'/api/live/whiteboard',
			dto
		)
	},

	// Получение идентификатор эфира по идентификатору входа в эфир
	getLiveLessonIdByLiveLessonEntranceId(liveLessonEntranceId: string) {
		return $api.get<LiveLessonApiTypes.GetLiveLessonIdByLiveLessonEntranceId>(
			'/api/live/live-id-by-uuid/' + liveLessonEntranceId
		)
	},

	getStorageItems() {
		return $api.get<LiveLessonApiTypes.GetStorageItem[]>(
			'/api/live/storage'
		)
	},

	createStorageItem(data: LiveLessonApiTypes.CreateStorageItem) {
		return $api.post<LiveLessonEntityTypes.StorageItem>(
			'/api/live/storage',
			data
		)
	},

	renameStorageItem(data: LiveLessonApiTypes.RenameStorageItem) {
		return $api.patch<Boolean>('/api/live/storage', data)
	},

	deleteStorageItem(storageItemId: number) {
		return $api.delete<Boolean>(`/api/live/storage/${storageItemId}`)
	},

	startRecording(data: LiveLessonApiTypes.VideoRecordingDto) {
		return $api.post<Boolean>('/api/live/start-recording', data)
	},

	stopRecording(data: LiveLessonApiTypes.VideoRecordingDto) {
		return $api.post<LiveLessonEntityTypes.StopRecordingResponse>(
			'/api/live/stop-recording',
			data
		)
	},
}

export default liveLessonRequests
