import { useEffect } from 'react'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { EXERCISE_DONE } from '../../../common/exerciseConst'
import useExerciseStore from '../exerciseState'

export function useFillStore(exercise: TrainingEntityTypes.ChooseWordExercise) {
	const updateStore = useExerciseStore((store) => store.updateStore)

	useEffect(
		function () {
			const doneCounter =
				exercise.status ===
				TrainingEntityTypes.ExerciseProgressStatus.SUCCESS
					? EXERCISE_DONE
					: 0

			const newTextBlocks = exercise.item.texts.map((textObj, i) => {
				return {
					id: i + 1,
					text: textObj.text,
					generatedTextPrompt: textObj.generatedTextPrompt,
				}
			})

			updateStore({ doneCounter, textBlocks: newTextBlocks })
		},
		[exercise.id]
	)
}

/**
 * Очищает текстовые блоки от введённых слов через задание нового идентификатора.
 * Тогда Реакт полностью перерисует текстовые блоки.
 */
export function useGetClearTextBlocks() {
	const textBlocks = useExerciseStore((store) => store.textBlocks)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		const newTextBlocks = textBlocks.map((textBlock) => {
			return { ...textBlock, id: textBlock.id + 1000 }
		})

		updateStore({ textBlocks: newTextBlocks })
	}
}
