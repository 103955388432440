import { AxiosResponse } from 'axios'
import { useEffect } from 'react'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { lessonsQuery } from 'parts/requests/lessons/lessonsQuery'
import UrlService from 'parts/services/UrlService'
import useExercisesListAdminStore from '../store'

/**
 * Хук получающий данные курса и помещающий их в Хранилище.
 * Затем массив уроков курса будет использован для отрисовки списка уроков
 */
export function useFillInStore() {
	const lessonId = UrlService.useGetLessonId()

	const { data: lessonRes } = lessonsQuery.getLesson(lessonId).useQuery({
		onError: queryError,
	})

	useEffect(
		function () {
			if (!lessonRes) return

			lessonsQuerySuccess(lessonRes)
		},
		[lessonRes]
	)
}

/**
 * Функция запускаемая при удачном получении списка уроков.
 * @param lesson — урок
 */
function lessonsQuerySuccess(
	lesson: AxiosResponse<TrainingEntityTypes.LessonAdmin>
) {
	const { updateStore } = useExercisesListAdminStore.getState()

	const { exercises } = lesson.data

	updateStore({
		exercises,
		dataLoadingStatus: exercises.length ? 'hasExercises' : 'noExercises',
		lessonId: lesson.data.id,
	})
}

/**
 * Функция запускаемая при ошибке запроса данных.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 * @param err — объект ошибки.
 */
function queryError(err: unknown) {
	const { updateStore } = useExercisesListAdminStore.getState()
	updateStore({ dataLoadingStatus: 'error' })
}
