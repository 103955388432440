import { useMemo } from 'react'
import AppUrls from 'parts/constants/pageUrl'
import { HeaderRouteType } from 'ui/pageHeader/PageHeader/fn/types'
import useLessonPageHeaderAdminStore from '../../zustand/store'

/** Возвращает массив для формирования хлебных крошек выше заголовка страницы урока */
export function useGetBreadcrumbs(): HeaderRouteType[] {
	const training = useLessonPageHeaderAdminStore(
		(state) => state.trainingAdmin
	)

	return useMemo(
		function () {
			if (!training) return []

			return [
				{
					path: AppUrls.Trainings().url,
					breadcrumbName: AppUrls.Trainings().name,
				},
				{
					path: AppUrls.Training(training.id).url,
					breadcrumbName: training.name,
				},
			]
		},
		[training]
	)
}
