import EditableText from 'pages/landing/landingConstructor/tariffs/card/common/EditableText/EditableText'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import React, { useRef } from 'react'
import cn from 'classnames'
import {
	findAdditionalPriceIdx,
	useGetChangeText,
	useGetCurrencySign,
} from 'pages/landing/landingConstructor/tariffs/card/additionalPrices/AdditionalPrice/fn/changeText'
import { keyDownPriceHandler } from 'pages/landing/landingConstructor/tariffs/card/Price/fn/changePrice'
import { useSetTextInEditableElementInMount } from 'parts/utils/hooks/hooks'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'
import 'pages/landing/landingConstructor/tariffs/card/additionalPrices/AdditionalPrice/AdditionalPrice.scss'

type AdditionalPricePropType = {
	tariff: TariffsStoreType.Tariff
	price: TariffsStoreType.TariffPrice
}

// Блок настройки дополнительной цены ниже карточки тарифа
function AdditionalPrice(props: AdditionalPricePropType) {
	const { tariff, price } = props

	return (
		<div className="additional-price">
			<Text tariff={tariff} price={price} />
			<div className="additional-price__prices">
				<div className="additional-price__price-wrapper">
					<Price tariff={tariff} price={price} />
					<CurrencySign type="price" tariff={tariff} price={price} />
				</div>
				<div className="additional-price__price-wrapper additional-price__price-wrapper--old">
					<OldPrice tariff={tariff} price={price} />
					<CurrencySign
						type="oldPrice"
						tariff={tariff}
						price={price}
					/>
				</div>
			</div>
			<BuyButton tariff={tariff} price={price} />
		</div>
	)
}

export default AdditionalPrice

// Текст левее цен
function Text(props: AdditionalPricePropType) {
	const { tariff, price } = props

	const priceIdx = findAdditionalPriceIdx(tariff, price.id)

	return (
		<p className="additional-price__text">
			<EditableText
				tariffInnerId={tariff.innerId}
				propsPathArr={['prices', priceIdx, 'text']}
				placeholder="Для оплаты в другой валюте 👉🏻"
				afterChange={tariffsManager.setNeedToSave.bind(tariffsManager)}
			/>
		</p>
	)
}

// Цена
function Price(props: AdditionalPricePropType) {
	const { price } = props

	const textElemRef = useRef(null)
	useSetTextInEditableElementInMount(price.price, textElemRef)

	const changeText = useGetChangeText('price', price.id)

	return (
		<p
			className={cn(
				'additional-price__price',
				!price.price && 'additional-price__price--empty'
			)}
			contentEditable
			suppressContentEditableWarning
			onInput={changeText}
			onKeyDown={keyDownPriceHandler}
			ref={textElemRef}
		/>
	)
}

// Старая цена
function OldPrice(props: AdditionalPricePropType) {
	const { price } = props

	const textElemRef = useRef(null)
	useSetTextInEditableElementInMount(price.oldPrice, textElemRef)

	const changeText = useGetChangeText('oldPrice', price.id)

	return (
		<p
			className={cn(
				'additional-price__price',
				!price.oldPrice && 'additional-price__price--empty'
			)}
			contentEditable
			suppressContentEditableWarning
			onInput={changeText}
			onKeyDown={keyDownPriceHandler}
			ref={textElemRef}
		/>
	)
}

type CurrencySignPropType = {
	type: 'price' | 'oldPrice'
	tariff: TariffsStoreType.Tariff
	price: TariffsStoreType.TariffPrice
}

// Значок валюты
function CurrencySign(props: CurrencySignPropType) {
	const { type, tariff, price } = props

	const currencySign = useGetCurrencySign(price)

	return <span>{currencySign}</span>
}

// Кнопка покупки
function BuyButton(props: AdditionalPricePropType) {
	const { tariff, price } = props

	const priceIdx = findAdditionalPriceIdx(tariff, price.id)

	return (
		<p
			className={cn(
				'additional-price__buy-button',
				!price.buttonText && 'additional-price__buy-button--empty'
			)}
		>
			<EditableText
				tariffInnerId={tariff.innerId}
				propsPathArr={['prices', priceIdx, 'buttonText']}
				placeholder="Записаться"
				afterChange={tariffsManager.setNeedToSave.bind(tariffsManager)}
			/>
		</p>
	)
}
