import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

export function getDefinedTimeZoneTime(scheduled: string, tz: string) {
	dayjs.extend(utc)
	dayjs.extend(timezone)

	const selectedTime = dayjs(scheduled)
	return selectedTime.tz(tz, true).toISOString()
}
