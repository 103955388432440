import { LandingTypes } from 'parts/types/LandingTypes'
import {
	BulletOuter,
	Bullet,
} from 'pages/landing/landingConstructor/sections/programSection/bulletsSections/Bullet/Bullet'
import 'pages/landing/landingConstructor/sections/programSection/bulletsSections/Bullets/Bullets.scss'

// Обёртка с двумя колонками куда помещаются блоки с описанием пользователя, которому подойдёт предлагаемый курс
type BulletsProps = {
	bulletBlockIdx: number
	bullets: LandingTypes.ProgramBullet[]
}

function Bullets(props: BulletsProps) {
	const { bulletBlockIdx, bullets } = props

	return (
		<div className="land-const-prog-bullets-blocks">
			{bullets.map((bullet, i) => {
				return (
					<BulletOuter blockIdx={i} bullet={bullet} key={bullet.id}>
						<Bullet blockIdx={bulletBlockIdx} bulletIdx={i} />
					</BulletOuter>
				)
			})}
		</div>
	)
}

export default Bullets
