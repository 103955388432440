import {
	checkSelectInputs,
	showRightAnswers,
} from '../../ChooseWordViewNew/fn/exercise'
import useExerciseStore, {
	ExerciseStateType,
} from '../../zustand/exerciseState'

export function useGetCheckExerciseFn() {
	const statistics = useExerciseStore((store) => store.statistics)
	const attempts = useExerciseStore((store) => store.attempts)
	const saveStoreForRestore = useExerciseStore(
		(store) => store.saveStoreForRestore
	)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		const $texts = document.getElementsByClassName(
			'exercise-choose-word-content__text-blocks'
		)[0] as HTMLDivElement
		if (!$texts) return

		// Если попытки закончились, то показать правильные варианты
		if (attempts <= 1) {
			setTimeout(() => {
				showRightAnswers($texts)
			}, 0)
		}

		// Проверить введённые ответы
		const {
			allSelectsFilledRight,
			successAnswersCounter,
			totalGapsCounter,
		} = checkSelectInputs($texts)

		// Отправить на состояние с экраном проверки
		updateStore({
			stage: 'Checked',
			saveStoreForRestore: saveStoreForRestore + 1,
			totalSlots: totalGapsCounter,
			statistics: getStatistics(statistics, successAnswersCounter),
			attempts: attempts - 1,
		})
	}
}

function getStatistics(
	currentStats: ExerciseStateType.Statistic[],
	passedQuestions: number
): ExerciseStateType.Statistic[] {
	if (currentStats.length === 3) {
		return currentStats
	}

	const newStatistics = [...currentStats]
	newStatistics.push({
		passedQuestions: passedQuestions,
	})

	return newStatistics
}
