import { useMemo } from 'react'
import AppUrls from 'parts/constants/pageUrl'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import { PageHeaderTab } from 'ui/pageHeader/Tabs/Tabs'

export function useGetTabConfig() {
	const userRoles = useGetUserRole()

	return useMemo(function () {
		const tabsConfig: PageHeaderTab[] = [
			{ label: 'Комнаты', address: AppUrls.LiveRooms().url },
		]

		// if (userRoles.isAdminOrCurator) {
		// 	tabsConfig.push({
		// 		label: 'Упражнения к урокам',
		// 		address: AppUrls.SchoolLessons.url,
		// 	})
		// }

		return tabsConfig
	}, [])
}
