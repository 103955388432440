import AppUrls from 'parts/constants/pageUrl'

export const routesBegin = [
	{
		path: AppUrls.Main().url,
		breadcrumbName: 'Главная',
	},
]

export type HeaderRouteType = {
	path: string
	breadcrumbName: string
}
