const studentEn = {
	// ТАБЛИЦА С ИСТОРИЕЙ ДЕЙСТВИЙ ПОЛЬЗОВАТЕЛЯ
	// Текст «Журнал действий» в заголовке таблицы
	historyTableStatusJournal: 'Action Log',
	// Статус «Активен» в заголовке таблицы
	historyTableStatusActive: 'Active',
	// Заголовок ряда Дата
	historyTableHeaderDate: 'Date',
	// Заголовок ряда Действие
	historyTableHeaderAction: 'Action',
	// Статус действия: Вход в учетную запись
	historyTableActionNameLogin: 'Logging in to account',
	// Статус действия: Оплата курса
	historyTableActionNamePayment: 'Paying for a course',
	// Статус действия: Неудачный вход
	historyTableActionNameWrongPassword: 'Failed entry',
	// Статус действия: Сброс пароля
	historyTableActionNameRecovery: 'Password reset',
	// Статус действия: Отключение ученика от эфира
	historyTableActionNameLiveParticipantDisconnected:
		'Disconnecting the student from the live stream',
	// Статус действия: Подключение ученика к эфиру
	historyTableActionNameLiveParticipantConnected:
		'Connecting the student to the live stream',

	// СТАТЬЯ СО СПИСКОМ ДЕЙСТВИЙ СОВЕРШЁННЫХ ПОЛЬЗОВАТЕЛЕМ (История)
	// Сообщение, что ученик не совершил ещё ни одного действия
	historyArticleNoActions: 'The student has not yet performed any actions.',

	// МЕНЮ С ВЫБОРОМ ДЕЙСТВИЙ НАД ВЫБРАННЫМ УЧЕНИКОМ
	// Текст на кнопке открывающей форму создания нового заказа
	pageHeaderOrderButton: 'Order',
	// Текст на кнопке открывающей выпадающий список с действиями
	pageHeaderActionsButton: 'Actions',
	// Отправить доступ
	pageHeaderActionsMenuSendAccess: 'Send access',
	// Разблокировать
	pageHeaderActionsMenuUnBan: 'Unblock',
	// Блокировать
	pageHeaderActionsMenuBan: 'Block',
	// Выйти из всех устройств
	pageHeaderActionsMenuExit: 'Unlogging on all devices',
	// Редактировать
	pageHeaderActionsMenuEdit: 'Edit',
	// Удалить
	pageHeaderActionsMenuRemove: 'Delete',
	// Сообщение при удачной блокировке пользователя
	pageHeaderActionsUserBannedSuccessful: 'User is blocked',
	// Сообщение при ошибке при блокировке пользователя
	pageHeaderActionsUserBannedError: 'Failed to lock the user',
	// Сообщение при удачной разблокировке пользователя
	pageHeaderActionsUserUnBannedSuccessful: 'User unlocked',
	// Сообщение при ошибке при разблокировке пользователя
	pageHeaderActionsUserUnBannedError: 'Failed to unlock user',
	// Сообщение при удачной разблокировке пользователя
	pageHeaderActionsUserExitSuccessful: 'User has logged out of all devices',
	// Сообщение при ошибке при разблокировке пользователя
	pageHeaderActionsUserExitError: 'Failed to unlog user on all devices',

	// МОДАЛЬНОЕ ОКНО УДАЛЕНИЯ ПОЛЬЗОВАТЕЛЯ
	// Заголовок модального окна
	deleteStudentModalHeader: 'Do you really want to delete a student?',
	// Текст модального окна
	deleteStudentModalText: 'This action cannot be undone.',
	// Сообщение об успешном удалении ученика
	deleteStudentModalSuccess: 'User deleted',
	// Сообщение о неудачном удалении ученика
	deleteStudentModalFail: 'User deleted',

	// ЭКРАН С СООБЩЕНИЕМ ОБ ОШИБКЕ С ОТОБРАЖЕНИЕМ УЧЕНИКА
	// Заголовок экрана про несуществующего ученика
	noStudentScreenHeader: "Student doesn't exist ",
	// Текст на экране про несуществующего ученика
	noStudentScreenText:
		'The student has been deleted or an incorrect address has been entered',
	noStudentScreenGoToStudentsPageBtn: 'Go to the list of all students',

	// МОДАЛЬНОЕ ОКНО НОВОГО ЗАКАЗА
	// Заголовок модального окна
	makeOrderModalHeader: 'New order',
	// Заголовок блока с покупкой
	makeOrderModalPurchaseBlockHeader: 'New order ',
	// Подпись поля «Группа»
	makeOrderModalFieldGroupLabel: 'Group',
	// Сообщение об обязательном поля для поля «Группа»
	makeOrderModalFieldGroupRequiredError: 'Indicate the group',
	// Подпись поля «Дата покупки»
	makeOrderModalFieldPurchaseDateLabel: 'Date of purchase',
	// Сообщение об обязательном поля для поля «Дата покупки»
	makeOrderModalFieldPurchaseDateRequiredError: 'Enter date of purchase',
	// Подпись поля «Способ оплаты»
	makeOrderModalFieldPaymentSystemLabel: 'Payment method',
	// Сообщение об обязательном поля для поля «Способ оплаты»
	makeOrderModalFieldPaymentSystemRequiredError: 'Specify payment method',
	// Подпись поля «Валюта»
	makeOrderModalFieldCurrencyLabel: 'Currency',
	// Сообщение об обязательном поля для поля «Валюта»
	makeOrderModalFieldCurrencyRequiredError: 'Specify currency ',
	// Подпись поля «Стоимость»
	makeOrderModalFieldAmountLabel: 'Price',
	// Сообщение об обязательном поля для поля «Стоимость»
	makeOrderModalFieldAmountRequiredError: 'Specify the price',
	// Подпись поля «Куратор»
	makeOrderModalFieldCuratorLabel: 'Curator',
	// Пункт поля «Куратор» «Не выбран»
	makeOrderModalFieldCuratorNotSelectedItem: 'Not selected',
	// Флаг «Выдать доступ в личный кабинет»
	makeOrderModalGetAccessCheckbox: 'Grant access to the personal account',
	// Кнопка создания нового заказа по данным введённым в форму
	makeOrderModalSubmitButton: 'Save',
	// Кнопка отмены создания нового заказа
	makeOrderModalCancelButton: 'Cancellation',
	// Сообщение про невозможность создать заказ из-за серверной ошибки
	makeOrderModalCannotMakeOrder: 'Failed to place an order.',
	// Сообщение про невозможность добавить оплату
	makeOrderModalCannotCreatePayment: 'Failed to add payment',
	// Сообщение про невозможность добавить ученика куратору
	makeOrderModalCannotAddStudentToCurator:
		'Failed to add a student to the supervisor',

	// ТАБЛИЦА ЗАКАЗОВ УЧЕНИКА
	// Заголовок ряда Курс
	ordersTableHeaderTraining: 'Course',
	// Заголовок ряда Группа
	ordersTableHeaderGroup: 'Group',
	// Заголовок ряда Дата
	ordersTableHeaderDate: 'Date',
	// Заголовок ряда Стоимость
	ordersTableHeaderPrice: 'Prcie',
	// Заголовок ряда Статус
	ordersTableHeaderStatus: 'Status',
	// Статус покупки: Покупка
	ordersTableStatusPayment: 'Purchase',
	// Сообщение «Не куплено ни одного курса.»
	ordersTableNoPayments: 'Not a single course has been purchased.',

	// ФОРМА С ИЗМЕНЕНИЕМ ДАННЫХ УЧЕНИКА
	// Заголовок группы полей формы
	accountFormBlockHeader: 'Account',
	// Текст Зарегистрирован после которого идёт дата регистрации ученика
	accountFormRegisteredText: 'Registered',
	// Подпись поля «Имя»
	accountFormFieldFirstNameLabel: 'Name',
	// Подпись поля «Фамилия»
	accountFormFieldLastNameLabel: 'Surname',
	// Подпись поля «День рождения»
	accountFormFieldBirthdayLabel: 'Date of birth',
	// Подпись поля «Пол»
	accountFormFieldGenderLabel: 'Gender',
	// Переключатель «Мужской» поля «Пол»
	accountFormFieldGenderRadioMan: 'Male',
	// Переключатель «Женский» поля «Пол»
	accountFormFieldGenderRadioWoman: 'Female',
	// Кнопка «Изменение почты»
	accountFormChangeEmailButton: 'Change e-mail...',
	// Кнопка «Изменение пароля...»
	accountFormChangePasswordButton: 'Change password... EN',
	// Подпись поля «Город»
	accountFormFieldCityLabel: 'City',
	// Подпись поля «Страна»
	accountFormFieldCountryLabel: 'Country',
	// Подпись поля «Телефон»
	accountFormFieldPhoneLabel: 'Phone number',
	// Подпись поля «Инстаграм»
	accountFormFieldInstagramLabel: 'Instagram Account',
	// Кнопка «Удалить учётную запись»
	accountFormDeleteAccountButton: 'Delete account',
	// Кнопка сохранения данных формы изменения данных ученика
	accountFormDeleteSaveButton: 'Save',
	// Сообщение об успешном изменении данных при сохранении формы
	accountFormFormSavedSuccessfully: 'Save',

	// СТРАНИЦА ПОСЕЩЕНИЙ УЧЕНИКА
	// Сообщение про то, что ученик ещё не добавлен ни в одну группу
	studentVisitsArticleNoGroups: 'There are no groups.',

	// КАРТОЧКА ПОСЕЩЕНИЯ УЧЕНИКА
	// Строка «Осталось выполнить» после которой идёт количество непройденных упражнений и уроков
	studentVisitCardLastTasks: "There's still work to be done",
	// Строка «Группа завершена»
	studentVisitCardGroupFinished: 'Group completed',
	// Строка «Старт занятий» после которой идёт дата следующего занятия
	studentVisitCardStart: 'Start of classes',
	// Строка «Все уроки открыты»
	studentVisitCardAllLessonsOpened: 'All lessons are open',
	// Строка «Следующий урок» после которой идёт дата следующего урока
	studentVisitCardNextLesson: 'Next lesson',
}

export default studentEn
