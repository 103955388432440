import { create } from 'zustand'
import { SendCodeBtnStoreType } from './storeTypes'

// Сколько кнопка остаётся заблокированной после нажатия (в секундах)
export const timeSession = 45

const useSendCodeBtnStore = create<SendCodeBtnStoreType.State>((set) => {
	return {
		// Сколько осталось секунд до разблокировки кнопки отправки кода на почту
		secondsLeft: timeSession,

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useSendCodeBtnStore
