import { useQueryClient } from 'react-query'
import { useGetStaffTableRouter } from './fn/useGetLessonsRouter'
import { userQuery } from 'parts/requests/user/userQuery'
import { groupQuery } from 'parts/requests/group/groupQuery'
import EntityTypes from 'parts/types/EntityTypes'
import UrlService from 'parts/services/UrlService'

export const StaffTableAdminController = {
	router: useGetStaffTableRouter,
	// Функция, помечающая данные курса неактуальными. После этого React Query скачает новый массив курсов
	useGetUpdate: function () {
		const groupId = UrlService.useGetGroupId()
		const queryClient = useQueryClient()

		return function () {
			// Пометить данные курса неактуальными. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [userQuery.getCurators().key],
			})
			// Пометить данные курса неактуальными. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [groupQuery.getGroupCurators(groupId).key],
			})
			// Пометить данные курса неактуальными. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [
					userQuery.getUserByRole(EntityTypes.UserRole.MANAGER).key,
				],
			})
		}
	},
}
