import { LandingTypes } from 'parts/types/LandingTypes'
import useLandingBuilderStore from 'pages/landing/landingBuilder/zustand/store'
import './ProgramBlock.scss'

type BlockProps = {
	blockIdx: number
	block: LandingTypes.ProgramBlock
}

export function Block(props: BlockProps) {
	const { blockIdx, block } = props
	const landing = useLandingBuilderStore((store) => store.landing)

	return (
		<article className="landing-program-block">
			<div className="landing-program-block__img-part">
				<img
					src={landing.sections.program.blocks[blockIdx].imageUrl}
					className="landing-program-block__img-part-img"
					alt=""
				/>
			</div>
			<div className="landing-program-block__text-part">
				<h3 className="landing-program-block__header">
					{landing.sections.program.blocks[blockIdx].header}
				</h3>
				<p className="landing-program-block__text">
					{landing.sections.program.blocks[blockIdx].text}
				</p>
			</div>
		</article>
	)
}
