import { Button } from 'antd'
import timeBlocksManager from 'pages/landing/landingConstructor/sections/timeSection/TimeBlocksManager/timeBlocksManager'

// Две кнопки создающие новый блок или в первой или во второй колонке.
// Появляются если в одной или двух колонках нет блоков.
function AddNewBlockSection() {
	const leftBlocks = timeBlocksManager.useGetColumnBlocks(1)
	const rightBlocks = timeBlocksManager.useGetColumnBlocks(2)

	const addNewBlockOnColumn_1 = timeBlocksManager.useGetAddNewBlock(null, 1)
	const addNewBlockOnColumn_2 = timeBlocksManager.useGetAddNewBlock(null, 2)

	return (
		<div>
			{!leftBlocks.length && (
				<Button onClick={addNewBlockOnColumn_1}>
					Добавить блок в первую колонку
				</Button>
			)}
			{!rightBlocks.length && (
				<Button onClick={addNewBlockOnColumn_2}>
					Добавить блок во вторую колонку
				</Button>
			)}
		</div>
	)
}

export default AddNewBlockSection
