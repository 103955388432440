import { message } from 'antd'
import { AxiosError } from 'axios'
import ServerTypes from 'parts/types/ServerTypes'
import { FieldsNames, FormValues } from './form'
import { groupQuery } from 'parts/requests/group/groupQuery'
import { OpenLessonsStoreType } from '../zustand/storeType'
import EntityTypes from 'parts/types/EntityTypes'
import GroupsApiTypes from 'parts/requests/group/groupsApiTypes'
import { GroupOpenLessonsFormController } from '../GroupOpenLessonsFormController'
import useOpenLessonsStore from '../zustand/store'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

/** Хук возвращающий обработчик отправки формы */
export function useGetOnSubmit() {
	const updateStore = useOpenLessonsStore((state) => state.updateStore)
	const group = useOpenLessonsStore((state) => state.group)
	const lessons = useOpenLessonsStore((state) => state.lessons)
	const trainingId = useOpenLessonsStore((state) => state.trainingId)

	const updateTrainingGroups = GroupOpenLessonsFormController.useGetUpdate()

	// Объект с методом mutate для создания запроса
	const updateGroupMutation = groupQuery.createOrUpdateGroup.useMutation({
		onMutate: getMutateOnBefore(updateStore),
		onError: getMutateOnError(updateStore),
		onSuccess: getMutateOnSuccess(trainingId, updateTrainingGroups),
		onSettled: getMutateOnAfter(updateStore),
	})

	return async (values: FormValues) => {
		updateGroupMutation.mutate(getDto(group, lessons, values))
		console.log(values)
	}
}

/**
 * Функция, выполняемая до запроса на добавление сотрудника в группы
 * @param updateStore — функция изменяющая объект состояния формы
 */
function getMutateOnBefore(updateStore: OpenLessonsStoreType.UpdateStore) {
	return function () {
		// Поставить статус загрузки
		updateStore({ isSubmitting: true })
	}
}

/**
 * Функция, выполняемая при появлении ошибки после запроса на добавление сотрудника в группы
 * @param updateStore — функция изменяющая объект состояния формы
 */
function getMutateOnError(updateStore: OpenLessonsStoreType.UpdateStore) {
	return function (err: unknown) {
		const error = err as AxiosError<ServerTypes.ErrorResponse>

		if (error.response) {
			// Поставить данные ошибки в Состояние, чтобы показать их в форме
			updateStore({ formErrors: error.response.data })
		}

		message.error('Не удалось сохранить данные.')
	}
}

/**
 * Функция, выполняемая при успешном запросе на добавление сотрудника в группы
 * @param trainingId — id курса
 * @param updateTrainingGroups — функция, помечающая данные групп курса неактуальными. После этого React Query автоматически скачает новый список
 */
function getMutateOnSuccess(
	trainingId: number,
	updateTrainingGroups: (trainingId: number) => void
) {
	return function () {
		message.success('Настройки обновлены.')

		// Пометить список групп курса неактуальным.
		// После этого React Query скачает новый список
		updateTrainingGroups(trainingId)
	}
}

/**
 * Функция, выполняемая после запроса на добавление сотрудника в группы
 * @param updateStore — функция изменяющая объект состояния формы
 */
function getMutateOnAfter(updateStore: OpenLessonsStoreType.UpdateStore) {
	return function () {
		// Убрать статус загрузки
		updateStore({ isSubmitting: false })
	}
}

/**
 * Возвращает объект с телом запрос на изменение условий прохождения группы
 * @param group — данные группы
 * @param values — значения полей формы изменений условий прохождения группы
 */
function getDto(
	group: EntityTypes.GroupData,
	lessons: TrainingEntityTypes.LessonAdmin[],
	values: FormValues
): GroupsApiTypes.CreateOrUpdateGroupDto {
	console.log(group)
	return {
		id: group.id,
		trainingId: group.trainingId,
		name: group.name,
		type: group.type,
		startCondition: group.startCondition,
		mode: group.mode,
		openLessonCondition: group.openLessonCondition,
		startDate: group.startDate,
		duration: group.duration,
		accessToCourse: group.accessToCourse,
		openLessons: values[FieldsNames.OpenLessons].reduce<number[]>(
			(acc, item, idx) => {
				if (item) {
					acc.push(lessons[idx].id)
				}
				return acc
			},
			[]
		),
	}
}
