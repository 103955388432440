import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useGetChangeModalVisibility } from '../CreateOrEditLiveRoomModal/zustand/storeManager'

// Кнопка открывающая модальное окно добавления нового live-урока
function AddLiveButton() {
	const openModal = useGetChangeModalVisibility(true)

	return (
		<Button type="primary" icon={<PlusOutlined />} onClick={openModal}>
			Создать комнату
		</Button>
	)
}

export default AddLiveButton
