import React from 'react'
import { WritingOrAudioAnswerWasSentFn } from '../../common/useLiftViewDuration'
import AnswerWrapper from '../AnswerWrapper/AnswerWrapper'
import TextAnswerBlock from '../TextAnswerBlock/TextAnswerBlock'
import AudioAnswerBlock from '../AudioAnswerBlock/AudioAnswerBlock'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useGetUserRole } from 'parts/utils/hooks/hooks'

type MediaAnswerBlockProps = {
	exercise:
		| TrainingEntityTypes.AudioExercise
		| TrainingEntityTypes.ImageExercise
	// Запуск этой функции сообщает, что ответ был послан
	writingOrAudioAnswerWasSentFn?: WritingOrAudioAnswerWasSentFn
	// Разрешена ли отправка письменного или голосового ответа?
	answerWasSentAlready: boolean
}

// Текстовый или голосовой ответ пользователя на упражнение
function MediaAnswerBlock(props: MediaAnswerBlockProps) {
	const { exercise, writingOrAudioAnswerWasSentFn, answerWasSentAlready } =
		props

	const userRoles = useGetUserRole()

	if (!userRoles.rolesGot || userRoles.isAdminOrManagerOrCurator) {
		return null
	}

	return (
		<>
			{exercise.item.writtenAnswer && (
				<AnswerWrapper answerType="text">
					<TextAnswerBlock
						writingOrAudioAnswerWasSentFn={
							writingOrAudioAnswerWasSentFn
						}
						answerWasSentAlready={answerWasSentAlready}
					/>
				</AnswerWrapper>
			)}
			{exercise.item.audioAnswer && (
				<AnswerWrapper answerType="audio">
					<AudioAnswerBlock
						writingOrAudioAnswerWasSentFn={
							writingOrAudioAnswerWasSentFn
						}
						answerWasSentAlready={answerWasSentAlready}
					/>
				</AnswerWrapper>
			)}
		</>
	)
}

export default MediaAnswerBlock
