import { Navigate, Outlet } from 'react-router-dom'

export type ProtectedRouteProps = {
	isAllowed: boolean
	redirectPath: string
	children: any // JSX.Element[] | JSX.Element
}

export default function ProtectedRoute({
	isAllowed,
	redirectPath,
	children,
}: ProtectedRouteProps) {
	if (!isAllowed) {
		return <Navigate to={redirectPath} replace />
	}

	return children ? children : <Outlet />
}
