import { Button, Checkbox, Form, Input, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { AddGroupModalController } from '../AddGroupModalController'
import useAddGroupModalStore from '../zustand/store'
import {
	UFormNewBottomButtonsAfterGray,
	UFormNewButtonsContainer,
	UFormNewGeneralError,
	UFormNewRow,
} from 'ui/UForm/UForm'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'
import { useGetOnSubmit } from './fn/submit'
import { FieldNames, setErrorsToForm } from './fn/form'

type AddGroupModalProps = {
	// id курса, которому будет принадлежать создаваемая группа
	trainingId: number
	// Должна ли группа быть активной при создании?
	isActive: boolean
	// Требуется ли дать сигнал Реакт Квери перекачать данные курса?
	refreshTrainingAfterCreation: boolean
}

// Форма создания новой группы
function AddGroupForm(props: AddGroupModalProps) {
	const { trainingId, isActive, refreshTrainingAfterCreation } = props

	const [form] = Form.useForm()
	const modalState = useAddGroupModalStore((state) => state)

	const onSubmit = useGetOnSubmit(
		trainingId,
		isActive,
		form,
		refreshTrainingAfterCreation
	)
	setErrorsToForm(form)

	return (
		<Form form={form} name="register" onFinish={onSubmit} layout="vertical">
			<GrayBlock>
				<GroupNameInput />
				<GroupTypeInput />
				<ParticipantsInput />
			</GrayBlock>
			<UFormNewGeneralError message={modalState.formErrors.message} />
			<Bottom />
		</Form>
	)
}

export default AddGroupForm

// Поле ввода названия создаваемой группы
function GroupNameInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.GroupName}
				label="Название группы"
				rules={[
					{
						required: true,
						message: 'Напишите название',
					},
				]}
			>
				<Input type="text" placeholder="Зимняя группа" />
			</Form.Item>
		</UFormNewRow>
	)
}

// Это нужно оставить закомментированной
// Флаг является ли группа платной
function PaidInput() {
	return (
		<UFormNewRow topOffset>
			<Form.Item name={FieldNames.Type} valuePropName="checked">
				<Checkbox>Платная</Checkbox>
			</Form.Item>
		</UFormNewRow>
	)
}

function GroupTypeInput() {
	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Type}
				label="Тип группы"
				rules={[
					{
						required: true,
						message: 'Выберите тип группы',
					},
				]}
			>
				<Select>
					<Select.Option value="paid">Платная</Select.Option>
					<Select.Option value="free">Бесплатная</Select.Option>
					<Select.Option value="test">Тестовая</Select.Option>
				</Select>
			</Form.Item>
		</UFormNewRow>
	)
}

// Количество мест в группе
function ParticipantsInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Participants}
				label="Количество мест в группе"
				tooltip="Укажите планируемое количество учеников в группе. Система автоматически будет распределять учеников по кураторам, при покупке курса. Так вы сможете отследить насколько загружены кураторы и стоит ли добавить нового. Это, не обязательно к заполнению"
			>
				<Input type="number" />
			</Form.Item>
		</UFormNewRow>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom() {
	const { t } = useTranslation()

	const form = Form.useFormInstance()
	const modalState = useAddGroupModalStore((state) => state)

	return (
		<UFormNewBottomButtonsAfterGray>
			<div />
			<UFormNewButtonsContainer>
				<Button
					disabled={
						modalState.isSubmitting ||
						form
							.getFieldsError()
							.filter(({ errors }) => errors.length).length > 0
					}
					onClick={AddGroupModalController.useGetClose}
				>
					Отмена
				</Button>

				<Form.Item shouldUpdate>
					{() => {
						const formErrors = form
							.getFieldsError()
							.filter(({ errors }) => errors.length)

						return (
							<Button
								type="primary"
								htmlType="submit"
								disabled={
									modalState.isSubmitting ||
									formErrors.length > 0
								}
								loading={modalState.isSubmitting}
							>
								Создать группу
							</Button>
						)
					}}
				</Form.Item>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
