import cn from 'classnames'
import useLandingBuilderStore from '../../../zustand/store'
import Header from '../Header/Header'
import Description from '../Description/Description'
import Theses from '../Theses/Theses'
import Price from '../Price/Price'
import BuyButton from '../BuyButton/BuyButton'
import AdditionalPrices from '../additionalPrices/AdditionalPrices/AdditionalPrices'
import EntityTypes from 'parts/types/EntityTypes'
import './Card.scss'

type CardProps = {
	tariff: EntityTypes.Tariff
}

// Карточка тарифа
function Card(props: CardProps) {
	const { tariff } = props

	const colorScheme = useLandingBuilderStore(
		(store) => store.landing.colorScheme
	)

	return (
		<div className="p-tariff-card">
			<div className="p-tariff-card__card-wrapper">
				<div
					className={cn(
						'p-tariff-card__card',
						tariff.isFlashed &&
							'p-tariff-card__card--' + colorScheme
					)}
				>
					<Header tariff={tariff} />
					<Description tariff={tariff} />
					<Theses tariff={tariff} />
					<Price tariff={tariff} />
					<BuyButton tariff={tariff} />
				</div>
				<AdditionalPrices tariff={tariff} />
			</div>
		</div>
	)
}

export default Card
