import { useEffect } from 'react'
import { groupQuery } from 'parts/requests/group/groupQuery'
import GroupsService from 'parts/services/GroupsService'
import useOpenLessonsStore from '../store'
import { lessonsQuery } from 'parts/requests/lessons/lessonsQuery'

/**
 * Скачивает группы курса и ставит в Состояние группу с переданным идентификатором
 * @param trainingId — id курса
 * @param groupId — id группы
 */
export function useFillInStore(trainingId: number, groupId: number) {
	const updateState = useOpenLessonsStore((state) => state.updateStore)

	// Получить все группы курса
	const { data: trainingGroupsRes } = groupQuery
		.getTrainingGroups(trainingId)
		.useQuery({
			onError: queryError,
		})
	// Получить уроки курса
	const { data: trainingLessonsRes } = lessonsQuery
		.getTrainingLessons(trainingId)
		.useQuery({
			onError: queryError,
		})

	useEffect(
		function () {
			if (!trainingGroupsRes || !trainingLessonsRes) return

			const group = GroupsService.getGroupFromGroupsArr(
				trainingGroupsRes.data,
				groupId
			)

			const lessons = trainingLessonsRes.data

			if (group) {
				updateState({
					dataLoadingStatus: 'noData',
				})
			}

			updateState({
				trainingId,
				group,
				lessons,
				dataLoadingStatus: 'hasData',
			})
		},
		[trainingGroupsRes, trainingLessonsRes]
	)
}

/**
 * Функция запускаемая при ошибке запроса данных. Срабатывает для любых запросов.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 * @param err — объект ошибки.
 */
function queryError(err: unknown) {
	const { updateStore } = useOpenLessonsStore.getState()

	updateStore({ dataLoadingStatus: 'error' })
}
