import { useRef } from 'react'
import { useSetInnerHtml } from '../useSetInnerHtml'
import HtmlEditorContentView from 'ui/HTMLEditorContentView/HTMLEditorContentView'
import './TaskExerciseView.scss'

type TaskExerciseViewProps = {
	htmlString: string
}

// Вывод задания к упражнению в уроке (универсальный компонент)
export function TaskExerciseView(props: TaskExerciseViewProps) {
	const { htmlString } = props

	const areaRef = useRef(null)
	useSetInnerHtml(areaRef, htmlString)

	return (
		<HtmlEditorContentView areaRef={areaRef} outerClass="exercise-task" />
	)
}
