import { AxiosError, AxiosResponse } from 'axios'
import { message } from 'antd'
import useSystemStore from 'parts/systemStore/systemStore'
import { userQuery } from 'parts/requests/user/userQuery'
import ServerTypes from 'parts/types/ServerTypes'
import EntityTypes from 'parts/types/EntityTypes'
import useProfileModalStore from '../zustand/store'
import { ProfileModalStoreeType } from '../zustand/storeTypes'
import { FieldNames, FormValuesType } from './form'
import UserApiTypes from 'parts/requests/user/userApiTypes'

/** Хук возвращающий обработчик отправки формы */
export function useGetOnSubmit() {
	const user = useSystemStore((store) => store.user)
	const updateModalStore = useProfileModalStore((store) => store.updateStore)

	const updateUserQuery = userQuery.updateUser.useMutation({
		onMutate: getMutateOnBefore(updateModalStore),
		onError: getMutateOnError(updateModalStore),
		onSettled: getMutateOnAfter(updateModalStore),
	})

	return async function (values: FormValuesType) {
		updateUserQuery.mutate(getDto(values, user.id))
	}
}

/**
 * Формирует DTO для отправки запроса
 * @param {Array} values — массив значений полей формы
 * @param {Number} userId — id пользователя
 */
function getDto(
	values: FormValuesType,
	userId: number
): UserApiTypes.UpdateUserDto {
	return {
		id: userId,
		firstName: values[FieldNames.FirstName],
		lastName: values[FieldNames.LastName],
		avatar: values[FieldNames.Avatar].length
			? values[FieldNames.Avatar][0].url!
			: '',
		phone: values[FieldNames.Phone],
		instagram: values[FieldNames.Instagram],
		country: values[FieldNames.Country],
		city: values[FieldNames.City],
		birthday: values[FieldNames.Birthday]?.toString() || undefined,
		sex: values.gender,
	}
}

/**
 * Функция, выполняемая до запросов
 * @param updateModalStore — функция изменяющая данные в объекте состояния модального окна
 */
function getMutateOnBefore(
	updateModalStore: ProfileModalStoreeType.UpdateStore
) {
	return function () {
		// Поставить статус загрузки
		updateModalStore({ isSubmitting: true })
	}
}

/**
 * Универсальная функция, выполняемая при появлении ошибки после запроса
 * @param updateModalStore — функция изменяющая данные в объекте состояния модального окна
 */
function getMutateOnError(
	updateModalStore: ProfileModalStoreeType.UpdateStore
) {
	return function (err: unknown) {
		const error = err as AxiosError<ServerTypes.ErrorResponse>

		if (error.response) {
			// Поставить данные ошибки в Состояние, чтобы показать их в форме
			updateModalStore({ formErrors: error.response.data })
		}

		// Убрать статус загрузки
		updateModalStore({ isSubmitting: false })
	}
}

/**
 * Функция, выполняемая при успешном запросе на удаление пользователя.
 * @param updateModalStore — функция изменяющая данные в объекте состояния модального окна
 */
function getMutateOnAfter(
	updateModalStore: ProfileModalStoreeType.UpdateStore
) {
	return function (userRes: AxiosResponse<EntityTypes.User>) {
		message.success('Данные сохранены')
		// Убрать статус загрузки и закрыть модальное окно
		updateModalStore({ isSubmitting: false, isModalOpen: false })

		const updateSystemStore = useSystemStore.getState().updateStore
		updateSystemStore({ user: userRes.data })
	}
}
