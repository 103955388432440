import AddTrainingModal from './AddTrainingModal'
import useAddTrainingModalStore from './zustand/store'

export const AddTrainingModalController = {
	element: <AddTrainingModal />,
	open() {
		const updateStore = useAddTrainingModalStore.getState().updateStore
		updateStore({ isOpen: true })
	},
	close() {
		const updateStore = useAddTrainingModalStore.getState().updateStore
		updateStore({ isOpen: false })
	},
}
