import { produce } from 'immer'
import { LandingTypes } from 'parts/types/LandingTypes'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import bulletsBlockManager from 'pages/landing/landingConstructor/sections/programSection/bulletsSections/bulletsBlockManager/bulletsBlockManager'

/**
 * Добавляет новый блок в массив блоков
 * @param blockIdx — индекс блока с пунктами
 * @param anchorBulletId — id блока, относительно которого будет добавляться новый блок.
 * Если null, тогда в массиве блоков пусто и новый будет добавлен первом элементом.
 * @param position — позиция куда будет добавлен новый блок: сверху или снизу.
 */
export function useGetAddNewBullet(
	blockIdx: number,
	anchorBulletId: null | number,
	position: 'top' | 'bottom' = 'top'
) {
	const [landing, updateStore] = useLandingConstructorStore((s) => [
		s.landing,
		s.updateStore,
	])

	return function () {
		if (!landing) return

		const newBullet = createNewBullet(
			landing.sections.program.bulletsBlocks[blockIdx].bullets
		)

		const anchorBulletIdx = bulletsBlockManager.getBulletIdx(
			landing.sections.program.blocks,
			anchorBulletId
		)

		const updatedLanding = produce(landing, (draft) => {
			const bullets =
				draft.sections.program.bulletsBlocks[blockIdx].bullets
			if (position === 'top') {
				bullets.splice(anchorBulletIdx, 0, newBullet)
			} else {
				bullets.splice(anchorBulletIdx + 1, 0, newBullet)
			}
		})

		updateStore({ landing: updatedLanding })
	}
}

/**
 * Создаёт объект нового блока с описанием пользователя которому подойдёт курс
 * @param bullets — массив пунктов
 */
function createNewBullet(
	bullets: LandingTypes.ProgramBullet[]
): LandingTypes.ProgramBullet {
	return {
		id: createNewBulletId(bullets),
		text: '',
		imageUrl: '/landing/signs/empty-image.svg',
	}
}

/**
 * Возвращает id блока, который должен быть у нового блока
 * @param bullets — массив блоков с описанием пользователя которому подойдёт курс
 */
function createNewBulletId(bullets: LandingTypes.ProgramBullet[]) {
	let newBulletId = 0

	for (let i = 0; i < bullets.length; i++) {
		const currentBullet = bullets[i]

		if (currentBullet.id > newBulletId) {
			newBulletId = currentBullet.id
		}
	}

	return newBulletId + 1
}
