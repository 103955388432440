import useStudentsStore from '../../../../zustand/store'
import { StudentsStoreType } from '../../../../zustand/storeTypes'

export enum FieldsNames {
	// Поле со строкой поиска
	Email = 'email',
}

export interface FormValues {
	[FieldsNames.Email]: string
}

/** Обработчик отправки формы поиска по почте */
export function useGetOnEmailChange() {
	const setSearchValuesProp = useStudentsStore(
		(store) => store.setSearchValuesProp
	)

	return function (values: FormValues) {
		setSearchValuesProp(
			StudentsStoreType.SearchValueNames.Email,
			values[FieldsNames.Email].toLowerCase()
		)
	}
}
