import { FormInstance, message } from 'antd'
import { AxiosError } from 'axios'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { FieldNames, FormValuesType } from './form'
import TrainingApiTypes from 'parts/requests/training/trainingApiTypes'
// import { getFirstLoadedFileUrl } from 'ui/upload/UploadFileInForm/fn/fn'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import ServerTypes from 'parts/types/ServerTypes'
import { TrainingListController } from 'widgets/trainingsListAdmin/TrainingListController'
import { EditTrainingModalStoreType } from '../zustand/storeTypes'
import useEditTrainingModalStore from '../zustand/store'

export function useGetOnSubmit(form: FormInstance) {
	const { t } = useTranslation()
	const [trainingId, updateStore] = useEditTrainingModalStore((state) => [
		state.trainingId,
		state.updateStore,
	])

	const updateDataInTrainingsList = TrainingListController.useGetUpdate()

	// Объект с методом mutate для создания запроса
	const updateTrainingMutation = trainingQuery.updateTraining.useMutation({
		onMutate: getMutateOnBefore(updateStore),
		onError: getMutateOnError(updateStore, t),
		onSuccess: getMutateOnSuccess(
			updateStore,
			updateDataInTrainingsList,
			t
		),
		onSettled: getMutateOnAfter(updateStore),
	})

	return async function (values: FormValuesType) {
		updateTrainingMutation.mutate(getDto(trainingId, values))
	}
}

/**
 * Функция, выполняемая до запроса на добавление сотрудника в группы
 * @param updateStore
 */
function getMutateOnBefore(
	updateStore: EditTrainingModalStoreType.UpdateState
) {
	return function () {
		// Поставить статус загрузки
		updateStore({ isSubmitting: true })
	}
}

/**
 * Функция, выполняемая при появлении ошибки после запроса на добавление сотрудника в группы
 * @param updateStore — функция изменяющая объект состояния модального окна
 * @param t
 */
function getMutateOnError(
	updateStore: EditTrainingModalStoreType.UpdateState,
	t: TFunction<'translation'>
) {
	return function (err: unknown) {
		const error = err as AxiosError<ServerTypes.ErrorResponse>

		if (error.response) {
			// Поставить данные ошибки в Состояние, чтобы показать их в форме
			updateStore({ formErrors: error.response.data })
		}

		message.error(t('adminTrainings.addTrainingModalCannotCreateDueError'))
	}
}

/**
 * Функция, выполняемая при успешном запросе на добавление сотрудника в группы
 * @param updateStore — функция изменяющая объект состояния модального окна
 * @param form — форма
 * @param updateDataInTrainingsList — функция, помечающая данные списка курсов неактуальными. После этого React Query автоматически скачает новый список
 */
function getMutateOnSuccess(
	updateStore: EditTrainingModalStoreType.UpdateState,
	updateDataInTrainingsList: () => void,
	t: TFunction<'translation'>
) {
	return function () {
		// Закрыть модальное окно
		updateStore({ isOpen: false })

		message.success(
			t('adminTrainings.editTrainingModalTrainingCreatedMessage')
		)

		// Пометить список курсов неактуальным.
		// После этого React Query автоматически скачает новый список
		updateDataInTrainingsList()

		updateStore({ formErrors: {} })
	}
}

/**
 * Функция, выполняемая после запроса на добавление сотрудника в группы
 * @param updateStore — функция изменяющая объект состояния модального окна
 */
function getMutateOnAfter(updateStore: EditTrainingModalStoreType.UpdateState) {
	return function () {
		// Убрать статус загрузки
		updateStore({ isSubmitting: false })
	}
}

/**
 * Возвращает DTO для создания нового курса
 * @param {Object} values — данные введённые в форму
 */
function getDto(
	trainingId: number,
	values: FormValuesType
): TrainingApiTypes.UpdateTrainingDto {
	return {
		id: trainingId,
		name: values[FieldNames.Name],
		description: values[FieldNames.Description] || '',
		// cover: getFirstLoadedFileUrl(values[FieldNames.Cover]),
		level: values[FieldNames.Level],
		lang: values[FieldNames.Lang],
		order: values[FieldNames.Order],
		type: values[FieldNames.BlockName],
		showOnMainScreen: values[FieldNames.ShowOnMain],
	}
}
