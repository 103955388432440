import React, { ReactNode } from 'react'
import './ToggleBlock.scss'
import cn from 'classnames'

type ToggleBlockProps = {
	open: boolean
	onHeaderClick: (open: boolean) => void
	size?: 'small' | 'medium'
	header: string
	children: ReactNode | ReactNode[]
}

function ToggleBlock(props: ToggleBlockProps) {
	const { open, onHeaderClick, size = 'small', header, children } = props

	return (
		<div className="toggle-block">
			<header
				className={cn(
					'toggle-block__header',
					'toggle-block__header--' + size,
					open && 'toggle-block__header--open'
				)}
				onClick={() => onHeaderClick(open)}
			>
				<img
					src="/images/signs/CaretDownFilled.svg"
					className={cn(
						'toggle-block__header-sign',
						open && 'toggle-block__header-sign--open'
					)}
					alt="Значок свёрнутости"
				/>
				<h3 className="toggle-block__header-text">{header}</h3>
			</header>
			{open && <div className="toggle-block__content">{children}</div>}
		</div>
	)
}

export default ToggleBlock
