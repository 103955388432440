import { LiveLessonEntrancePageStoreType } from '../../zustand/storeTypes'
import AccountCreatedScreen from '../AccountCreatedScreen/AccountCreatedScreen'
import CantAccessToLessonScreen from '../CantAccessToLessonScreen/CantAccessToLessonScreen'
import EmailFormScreen from '../EmailFormScreen/EmailFormScreen'
import ErrorScreen from '../ErrorScreen/ErrorScreen'
import RegFormScreen from '../RegFormScreen/RegFormScreen'
import { useGoToLoginPageIfEmailIsRegistered } from './fn/goToLoginPage'
import useLiveLessonEntrancePageStore from '../../zustand/store'
import './ContentWrapper.scss'

// Страница с формой ввода почты для регистрации или сообщения о создании учётной записи
function ContentWrapper() {
	const currentRole = useLiveLessonEntrancePageStore(
		(store) => store.currentRole
	)
	const unregisteredStudentStatus = useLiveLessonEntrancePageStore(
		(store) => store.unregisteredStudentStatus
	)

	useGoToLoginPageIfEmailIsRegistered()

	return (
		<div className="live-lesson-reg-page">
			<img
				src="/images/site/logo.svg"
				className="live-lesson-reg-page__logo"
				alt="logo"
			/>
			<div className="live-lesson-reg-page__content">
				{currentRole === 'unknown' && <EmailFormScreen />}
				<UnregisteredUserScreens
					currentRole={currentRole}
					unregisteredStudentStatus={unregisteredStudentStatus}
				/>
				{currentRole === 'staffFromOtherSchool' && (
					<CantAccessToLessonScreen />
				)}
				{currentRole === 'error' && <ErrorScreen />}
			</div>
		</div>
	)
}

export default ContentWrapper

type TestProps = {
	currentRole: LiveLessonEntrancePageStoreType.CurrentRole
	unregisteredStudentStatus: LiveLessonEntrancePageStoreType.UnregisteredStudentStatus
}

function UnregisteredUserScreens(props: TestProps) {
	const { currentRole, unregisteredStudentStatus } = props

	if (currentRole !== 'unregisteredUser') {
		return null
	}

	if (unregisteredStudentStatus === 'unregistered') {
		return <RegFormScreen />
	} else if (unregisteredStudentStatus === 'accountCreated') {
		return <AccountCreatedScreen />
	} else if (unregisteredStudentStatus === 'error') {
		return <ErrorScreen />
	}

	return null
}
