import { create } from 'zustand'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'

// Состояние тарифов на одностраничнике
const useTariffsConstructorStore = create<TariffsStoreType.State>((set) => {
	return {
		dataLoadingStatus: 'loading',
		training: undefined as any,
		groupId: undefined as any,
		syncTariffsDataWithHTML: 0,
		tariffs: undefined as any,
		isPanelOpen: false,
		activeTariffId: null,
		activeSegmentId: null,
		needToSaveOnServer: 0,
		stripeSetupDone: false,
		isModalWithCodeOpen: false,
		isMobilePanelOpen: false,

		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useTariffsConstructorStore
