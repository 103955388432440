import { create } from 'zustand'
import { AddStaffModalStoreType } from './storeTypes'

const useAddStaffInGroupModalStore = create<AddStaffModalStoreType.State>(
	(set) => ({
		isOpen: false,
		isSubmitting: false,
		search: '',
		formErrors: {},
		staff: null,

		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	})
)

export default useAddStaffInGroupModalStore
