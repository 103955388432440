import cn from 'classnames'

/**
 * Функция возвращает классы, которые должны быть на кнопке со словом
 * @param {String} position — где находится кнопка со словом: inside (в списке всех слов), outside (в одном из слотов описаний изображений)
 */
export function getWordButtonClasses(position: 'inside' | 'outside') {
	const classes = ['exercise-desc-image-content__word']
	classes.push('exercise-desc-image-content__word--' + position)
	return cn(classes)
}
