import React, { ReactElement } from 'react'
import CheckThin from './CheckThin'
import CheckBold from './CheckBold'
import RadioThin from './RadioThin'
import RadioBold from './RadioBold'

type SignProps = {
	type: 'checkThin' | 'checkBold' | 'radioThin' | 'radioBold'
	className: string
}

function Sign(props: SignProps) {
	const { type, className } = props

	let path: ReactElement | null = null

	if (type == 'checkThin') {
		path = <CheckThin />
	} else if (type == 'checkBold') {
		path = <CheckBold />
	} else if (type == 'radioThin') {
		path = <RadioThin />
	} else if (type == 'radioBold') {
		path = <RadioBold />
	}

	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			{path}
		</svg>
	)
}

export default Sign
