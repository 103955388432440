import { message } from 'antd'
import React from 'react'

/** Возвращает обработчик изменения текста редактируемого элемента. Написанный текст передаёт переданной функции. */
export function useGetChangeText(
	afterChange: (text: string) => void,
	maxCharacters?: number
) {
	return async function (e: React.SyntheticEvent<HTMLSpanElement>) {
		const text = await getTextFromEvent(e)

		// @ts-ignore
		const isDelSymbols = e.key === 'Backspace' || e.key === 'Delete'

		if (maxCharacters && text.length > maxCharacters && !isDelSymbols) {
			e.preventDefault()

			message.info(
				'Длина не может превышать ' + maxCharacters + ' символов.'
			)

			return
		}

		afterChange(text)
	}
}

async function getTextFromEvent(
	e: React.SyntheticEvent<HTMLSpanElement>
): Promise<string> {
	return new Promise((resolve, reject) => {
		if (e.type === 'keydown') {
			const $span = e.target as HTMLSpanElement
			setTimeout(() => {
				resolve($span.innerText)
			}, 0)
		} else if (e.type === 'paste') {
			// @ts-ignore
			const clipboardData = e.clipboardData || window.clipboardData
			reject(clipboardData.getData('Text'))
		}

		return ''
	})
}

/** Возвращает функцию ставящую фокус в редактируемый текст. */
export function getSetFocusInText(
	textElemRef: React.MutableRefObject<null | HTMLSpanElement>
) {
	return function () {
		const input = textElemRef.current
		if (!input) return

		input.focus()
	}
}
