import { useCallback } from 'react'
import useMenuStore from 'app/menu/zustand/menuStore'

/** Хук возвращает обработчик нажатия на кнопку открывающую/скрывающую главное меню для телефона */
export function useGetOpenMenuToggle() {
	const isMobileMenuOpen = useMenuStore((store) => store.isMobileMenuOpen)

	return useCallback(
		function () {
			useMenuStore
				.getState()
				.updateStore({ isMobileMenuOpen: !isMobileMenuOpen })
		},
		[isMobileMenuOpen]
	)
}
