import { lessonsQuery } from 'parts/requests/lessons/lessonsQuery'
import useLessonsPresetsStore from '../../../zustand/store'
import { AxiosResponse } from 'axios'
import PresetsEntityTypes from 'parts/types/PresetsEntityTypes'

/** Делает запрос на получение списка шаблонов */
export default function useFillInStore() {
	lessonsQuery.getPresets().useQuery({
		onSuccess: presetsQuerySuccess,
		onError: presetsQueryError,
	})
}

function presetsQuerySuccess(
	presets: AxiosResponse<PresetsEntityTypes.Preset[]>
) {
	useLessonsPresetsStore
		.getState()
		.updateStore({ dataLoadingStatus: 'success', presets: presets.data })
}

function presetsQueryError() {
	useLessonsPresetsStore
		.getState()
		.updateStore({ dataLoadingStatus: 'error' })
}
