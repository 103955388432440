import React from 'react'
import { useTranslation } from 'react-i18next'
import { Drawer, Tabs } from 'ui'
import { presetVariants } from './fn/consts'
import { useGetClosePresetsListDrawer } from 'widgets/presets/presetsListDrawer/PresetsListDrawer/fn/useGetDrawerActions'
import useLessonsPresetsStore from '../../zustand/store'
import PresetsList from '../PresetsList/PresetsList'

export default function PresetsListDrawer() {
	const { t } = useTranslation()

	const isPresetsDrawerOpen = useLessonsPresetsStore(
		(state) => state.isOpenPresetsListDrawer
	)

	const close = useGetClosePresetsListDrawer()
	const variants = [
		{
			label: t('adminTraining.presetsTabsPublic'),
			key: presetVariants.GLOBAL,
			children: <PresetsList isPublic />,
		},
		{
			label: t('adminTraining.presetsTabsYours'),
			key: presetVariants.LOCAL,
			children: <PresetsList />,
		},
	]

	return (
		<Drawer
			title={t('adminTraining.presetsDrawerTitle')}
			placement="right"
			width={500}
			onClose={close}
			open={isPresetsDrawerOpen}
		>
			<Tabs items={variants} defaultActiveKey={presetVariants.GLOBAL} />
		</Drawer>
	)
}
