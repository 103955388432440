import { useEffect, useRef } from 'react'
import useExerciseStore from '../../zustand/exerciseState'

/**
 * Подсчитывает время на прохождение каждой попытки и записывает в Состояние
 */
export function useSetAttemptsDuration() {
	const currentStatementIdx = useExerciseStore(
		(store) => store.currentStatementIdx
	)
	const showResult = useExerciseStore((store) => store.showResult)
	const updateStore = useExerciseStore((store) => store.updateStore)
	const intervalRef = useRef<null | NodeJS.Timer>(null)

	useEffect(
		function () {
			if (currentStatementIdx === 0 && !showResult) {
				const { durationsInSeconds } = useExerciseStore.getState()
				updateStore({ durationsInSeconds: [...durationsInSeconds, 0] })

				intervalRef.current = setInterval(() => {
					const { durationsInSeconds } = useExerciseStore.getState()
					const newDurations = [...durationsInSeconds]
					newDurations[newDurations.length - 1] =
						newDurations[newDurations.length - 1] + 1

					updateStore({ durationsInSeconds: newDurations })
				}, 1000)
			} else if (showResult && intervalRef.current) {
				clearInterval(intervalRef.current)
			}
		},
		[currentStatementIdx, showResult]
	)
}
