import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import useStudentStore from '../../zustand/store'
import useDeleteModalStore from '../../../../modal/DeleteModal/store/store'
import AppUrls from 'parts/constants/pageUrl'
import schoolRequests from 'parts/requests/school/schoolRequest'

/** Удаление пользователя */
export function useGetDeleteUser() {
	const { t } = useTranslation()

	const updateStore = useDeleteModalStore((state) => state.updateStore)
	const student = useStudentStore((store) => store.student)
	const navigate = useNavigate()

	const deleteUser = useGetDeleteUserHandler(student?.id)

	return function () {
		if (!student) return

		updateStore({
			isOpen: true,
			header: t('student.deleteStudentModalHeader'),
			text: t('student.deleteStudentModalText'),
			onOk: deleteUser,
			afterOk: () => {
				navigate(AppUrls.Students().url)
			},
		})
	}
}

/** Обработчик удаления пользователя */
export function useGetDeleteUserHandler(userId: undefined | number) {
	const { t } = useTranslation()

	return async () => {
		if (!userId) return

		const deleteUserRes = await schoolRequests.deleteUser(userId)

		if (deleteUserRes.status === 200) {
			message.success(t('student.deleteStudentModalSuccess'))
		} else {
			message.error(t('student.deleteStudentModalFail'))
		}
	}
}
