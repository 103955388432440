import { useEffect } from 'react'
import EntityTypes from 'parts/types/EntityTypes'
import { LandingTypes } from 'parts/types/LandingTypes'
import useLandingBuilderStore from '../store'

/**
 * Получает данные для построения одностраничника и помещает в Зустанд
 * @param landing — данные одностраничника
 * @param tariffs
 */
export function useFillInStore(
	landing: LandingTypes.Landing,
	tariffs: EntityTypes.Tariff[]
) {
	const updateStore = useLandingBuilderStore((store) => store.updateStore)

	useEffect(function () {
		updateStore({ landing, tariffs })
	}, [])
}
