const adminTrainingRu = {
	// Сообщение при ошибке при обновлении данных курса
	errorWhileUpdateTraining: 'При сохранении курса произошла ошибка',
	// Сообщение при ошибке при получении данных курса
	errorWhileDownloadTraining: 'Не удалось загрузить данные курса',
	// Надпись на кнопке добавления урока
	addLessonButton: 'Добавить',
	// Надпись на кнопке добавления группы
	addGroupButton: 'Добавить группу',

	// ЭКРАН ПОКАЗЫВАЮЩИЙ НЕПРАВИЛЬНЫЙ АДРЕС КУРСА
	// Заголовок экрана про неправильный адрес курса
	wrongTrainingScreenHeader: 'Курс не существует',
	// Текст экрана про неправильный адрес курса
	wrongTrainingScreenText: 'Он был удалён или введён неправильный адрес',
	// Текст на кнопке переадресации в Мои курсы на экране про неправильный адрес курса
	wrongTrainingScreenRedirectButton: 'Перейти в Мои курсы',

	// ЭКРАН ПРО ОТСУТСТВИЕ УРОКОВ В КУРСЕ
	noLessonsScreenFirstTextLne: 'Пока не создано ни одного урока.',
	noLessonsScreenSecondTextLne: 'Пора приниматься за творчество!',

	// ШАБЛОНЫ УРОКОВ
	// Сообщение об ошибке при получении шаблонов уроков с сервера
	lessonPresetErrorWhileDataLoading: 'При получении данных произошла ошибка.',
	// Сообщение об ошибке при не возможность создать шаблон урок при запросе на сервер
	lessonPresetCreationError: 'Не удалось создать урок по шаблону.',
	// Сообщение об успешном добавлении урока
	lessonPresetSuccessfullyAdded: 'Урок успешно добавлен',

	// МОДАЛЬНОЕ ОКНО ПРИМЕНЕНИЯ ШАБЛОНА К УРОКУ
	// Заголовок модального окна
	applyPresetModalTitle: 'Создать урок из шаблона?',
	// Кнопка подтверждения в модальном окне
	applyPresetModalOnBtn: 'Создать',
	// Кнопка отмены в модальном окне
	applyPresetModalCancelBtn: 'Отмена',
	// Текст модального окна
	applyPresetModalText: 'Создайте урок, используя этот шаблон',

	// МОДАЛЬНОЕ ОКНО УДАЛЕНИЯ ШАБЛОНА
	// Заголовок модального окна
	deletePresetModalTitle: 'Удалить этот шаблон?',
	// Кнопка подтверждения в модальном окне
	deletePresetModalOkBtn: 'Удалить',
	// Кнопка отмены в модальном окне
	deletePresetModalCancelBtn: 'Отмена',
	// Текст в модальном окне
	deletePresetModalText:
		'Шаблон будет удален из библиотеки ваших шаблонов. Это действие не приведет к удалению урока.',
	// Сообщение после успешного удаления шаблона
	deletePresetModalSuccess: 'Шаблон удален',
	// Сообщение об ошибке после попытки удаления шаблона
	deletePresetModalError: 'Ошибка удаления шаблона',

	// СПИСОК ШАБЛОНОВ УРОКОВ
	// Кнопка Все шаблоны
	allPresetsButton: 'Все шаблоны',
	// Сообщение про ненайденные шаблоны
	presetsHasNotFound: 'Шаблоны не найдены',
	// Вкладка «Публичные шаблоны»
	presetsTabsPublic: 'Публичные шаблоны',
	// Вкладка «Ваши шаблоны»
	presetsTabsYours: 'Ваши шаблоны',
	// Заголовок боковой панели со списком шаблонов уроков
	presetsDrawerTitle: 'Шаблоны',

	// КАРТОЧКА ШАБЛОНА УРОКА
	// Кнопка удаления шаблона
	presetCardDeleteButton: 'Удалить',

	// СПИСОК УРОКОВ КУРСА
	// Ошибка при получении списка уроков
	lessonsListErrorWhileLoadingData: 'При получении данных произошла ошибка.',
	// Текст на карточке-кнопке добавления урока
	lessonsListAddLessonCard: 'Добавить урок',

	// МОДАЛЬНОЕ ОКНО СОЗДАНИЯ УРОКА
	// Заголовок модального окна
	addLessonModalTitle: 'Добавление урока',
	// Подпись поля названия урока
	addLessonModalNameFieldLabel: 'Название',
	// Ошибка если поле названия урока не заполнили
	addLessonModalNameFieldRequiredError: 'Введите название урока.',
	// Подпись поля описания урока
	addLessonModalDescriptionFieldLabel: 'Описание',
	// Подпись поля обложки урока
	addLessonModalCoverFieldLabel: 'Обложка',
	// Кнопка отмены изменения формы
	addLessonModalCancelBtn: 'Отмена',
	// Кнопка создания урока
	addLessonModalOkBtn: 'Добавить',
	// Ошибка сервера при создании урока
	addLessonModalReqErrorWhileCreation: 'Не удалось добавить урок',
	// Сообщение после успешного создания урока
	addLessonModalLessonCreatedMessage: 'Урок создан.',

	// МОДАЛЬНОЕ ОКНО РЕДАКТИРОВАНИЯ УРОКА
	// Заголовок модального окна
	editLessonModalTitle: 'Редактирование урока',
	// Подпись поля названия урока
	editLessonModalNameFieldLabel: 'Название',
	// Ошибка если поле названия урока не заполнили
	editLessonModalNameFieldRequiredError: 'Введите название урока.',
	// Подпись поля описания урока
	editLessonModalDescriptionFieldLabel: 'Описание',
	// Подпись поля обложки урока
	editLessonModalCoverFieldLabel: 'Обложка',
	// Кнопка отмены изменения формы
	editLessonModalCancelBtn: 'Отмена',
	// Кнопка создания урока
	editLessonModalOkBtn: 'Добавить',
	// Ошибка сервера при создании урока
	editLessonModalReqErrorWhileSave: 'Не удалось изменить урок.',
	// Сообщение после успешного создания урока
	editLessonModalLessonCreatedMessage: 'Урок обновлён.',

	// МОДАЛЬНОЕ ОКНО УДАЛЕНИЯ УРОКА
	// Заголовок модального окна
	deleteLessonModalTitle: 'Вы действительно хотите удалить урок?',
	// Текст модального окна
	deleteLessonModalText: 'Это действие нельзя отменить.',
	// Ошибка сервера при удалении урока
	deleteLessonModalReqError: 'Не удалось удалить урок.',

	// КАРТОЧКА УРОКА
	// Кнопка редактирования урока
	lessonCardButtonEdit: 'Редактировать',
	// Кнопка перемещающая урок выше
	lessonCardButtonUp: 'Выше',
	// Кнопка перемещающая урок ниже
	lessonCardButtonDown: 'Ниже',
	// Кнопка открывающая модальное окно подтверждения удаления урока
	lessonCardButtonDelete: 'Удалить',
	// Кнопка создающая шаблон урока на основе другого урока
	lessonCardButtonCreateTemplate: 'Сделать шаблоном',
	// Текст 'Урок № '. Пробел справа обязателен.
	lessonCardLessonNumber: 'Удалить',
	// Сообщение про успешное создание шаблона урока
	lessonCardTemplateCreatedMessage: 'Шаблон успешно добавлен',
	// Сообщение про ошибку при создании шаблона урока
	lessonCardErrorWhileTemplateCreationMessage: 'Ошибка создания шаблона',
}

export default adminTrainingRu
