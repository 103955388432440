/**
 * Функция проверяет введённые в пропуски слова и задаёт им классы в зависимости от правильности слова
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
export function checkSlots($texts: HTMLDivElement) {
	let allSlotsFilledRight = true // Правильно ли заполнены все пропуски
	let successAnswersCounter = 0 // Количество правильно введённых слов

	enumerateSlots($texts, ($slot) => {
		// Получение правильного слова
		const rightWord = $slot.getAttribute('data-right') as string

		// Перетащенное слова
		const droppedWord = getDroppedWord($slot)

		const isWordsEqual = clearString(rightWord) === clearString(droppedWord)

		if (isWordsEqual) {
			setSuccessToSlot($slot)
			successAnswersCounter++
		} else {
			setErrorToSlot($slot)
			allSlotsFilledRight = false
		}
	})

	return {
		allSlotsFilledRight,
		successAnswersCounter,
		totalSlotsCounter: get$Slots($texts).length,
	}
}

/**
 * Функция перебирает пропуски и для каждого пропуска запускает переданную функцию, в которую передаёт элемент пропуска
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 * @param {Function} handler — функция запускаемая для каждого пропуска в которую передаёт элемент пропуска
 */
function enumerateSlots(
	$texts: HTMLDivElement,
	handler: ($gap: HTMLElement) => void
) {
	const $slots = get$Slots($texts)

	for (let i = 0; i < $slots.length; i++) {
		const $slot = $slots[i]

		if ($slot.nodeType == 1) {
			handler($slot as HTMLElement)
		}
	}
}

/**
 * Функция получает все элементы пропуска
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
function get$Slots($texts: HTMLDivElement) {
	return $texts.querySelectorAll('.exercise-desc-image-content__slot')
}

function getDroppedWord($slot: HTMLElement): string {
	return $slot.innerText
}

/**
 * Функция преобразует слово для правильного сравнения
 * @param {String} str — строка, которую нужно подготовить для сравнения
 */
function clearString(str: string) {
	let preparedStr = str.toLowerCase().trim()
	preparedStr = preparedStr.replace(/\./g, '')
	preparedStr = preparedStr.replace(/<\/?[\w\s="'-]*>/g, '')
	return preparedStr
}

function setSuccessToSlot($slot: HTMLElement) {
	$slot.classList.remove('exercise-desc-image-content__slot--error')
	$slot.classList.add('exercise-desc-image-content__slot--success')

	const $button = $slot.children[0] as HTMLButtonElement

	if ($button) {
		$button.classList.remove('exercise-desc-image-content__word--error')
		$button.classList.add('exercise-desc-image-content__word--success')
	}
}

function setErrorToSlot($slot: HTMLElement) {
	$slot.classList.remove('exercise-desc-image-content__slot--success')
	$slot.classList.add('exercise-desc-image-content__slot--error')

	const $button = $slot.children[0] as HTMLButtonElement

	if ($button) {
		$button.classList.remove('exercise-desc-image-content__word--success')
		$button.classList.add('exercise-desc-image-content__word--error')
	}
}

/**
 * Функция показывает правильный вариант слова после пропуска если пользователь ввёл неверный вариант
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
export function showRightAnswers($texts: HTMLDivElement) {
	enumerateSlots($texts, ($slot) => {
		if (
			$slot.classList.contains('exercise-desc-image-content__slot--error')
		) {
			const $rightWord = $slot.nextElementSibling

			if ($rightWord) {
				$rightWord.classList.add(
					'exercise-drop-word-content__right-answer--open'
				)
			}
		}
	})
}
