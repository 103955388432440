import { useEffect, useState } from 'react'
import useLandingConstructorStore from '../../../zustand/store'
import { LandingConstructorStoreType } from '../../../zustand/storeTypes'

export function useGetSaveDataStatus() {
	const [statusText, setStatusText] = useState('')

	const saveDataStatus = useLandingConstructorStore(
		(store) => store.saveDataStatus
	)

	useEffect(
		function () {
			const statusMap: Record<
				LandingConstructorStoreType.SaveDataStatus,
				string
			> = {
				hasUnsavedData: 'Есть несохранённые данные',
				saving: 'Идёт сохранение',
				saved: 'Сохранено',
			}

			setStatusText(statusMap[saveDataStatus])
		},
		[saveDataStatus]
	)

	return { statusText }
}
