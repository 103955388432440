import React from 'react'
import { useTranslation } from 'react-i18next'
import {
	UTable,
	UTableBCell,
	UTableBody,
	UTableBRow,
	UTableHCell,
	UTableHRow,
} from 'ui/UTable/UTable'
import EntityTypes from 'parts/types/EntityTypes'
import { dateStrToHumanDateAndTime } from 'parts/utils/time'
import './StudentHistoryTable.scss'
import { Tag } from 'antd'

type StudentHistoryTableProps = {
	logs: EntityTypes.UserLogs
}

export default function StudentHistoryTable(props: StudentHistoryTableProps) {
	const { logs } = props

	return (
		<UTable header={<Status />}>
			<TableHeader />
			<UTableBody>
				{logs.map((log) => (
					<TableRow log={log} key={log.id} />
				))}
			</UTableBody>
		</UTable>
	)
}

function Status() {
	const { t } = useTranslation()

	return (
		<div className="student-history-table__top">
			{t('student.historyTableStatusJournal')}{' '}
			<Tag color="green">{t('student.historyTableStatusActive')}</Tag>
		</div>
	)
}

function TableHeader() {
	const { t } = useTranslation()

	return (
		<UTableHRow>
			<UTableHCell>{t('student.historyTableHeaderDate')}</UTableHCell>
			<UTableHCell>{t('student.historyTableHeaderAction')}</UTableHCell>
		</UTableHRow>
	)
}

type TableRowProps = {
	log: EntityTypes.UserLog
}

function TableRow(props: TableRowProps) {
	const { log } = props

	return (
		<UTableBRow>
			<UTableBCell key="date">
				{dateStrToHumanDateAndTime(log.createdAt)}
			</UTableBCell>
			<UTableBCell key="price">
				<Action log={log} />
			</UTableBCell>
		</UTableBRow>
	)
}

function Action(props: TableRowProps) {
	const { log } = props

	const { t } = useTranslation()

	// Значок и его класс
	let iconSrc = ''
	let name = ''

	if (log.name == 'login') {
		iconSrc = 'CheckCircleTwoTone'
		name = t('student.historyTableActionNameLogin')
	} else if (log.name == 'payment') {
		iconSrc = 'CreditCardTwoTone'
		name = t('student.historyTableActionNamePayment')
	} else if (log.name == 'wrong password') {
		iconSrc = 'CloseCircleTwoTone'
		name = t('student.historyTableActionNameWrongPassword')
	} else if (log.name == 'recovery') {
		iconSrc = 'ReloadOutlined'
		name = t('student.historyTableActionNameRecovery')
	} else if (log.name == 'live_participant_disconnected') {
		iconSrc = 'bullet'
		name = t('student.historyTableActionNameLiveParticipantDisconnected')
	} else if (log.name == 'live_participant_connected') {
		iconSrc = 'bullet'
		name = t('student.historyTableActionNameLiveParticipantConnected')
	}

	return (
		<span className="student-history-table__action-wrapper">
			{name} <img src={'/images/signs/' + iconSrc + '.svg'} alt="" />
		</span>
	)
}
