import { UTable, UTableBody } from 'ui/UTable/UTable'
import AddGroupRow from 'widgets/groups/trainingGroupsTable/AddGroupRow/AddGroupRow'
import GroupsTableRows from 'widgets/groups/trainingGroupsTable/GroupsTableRows/GroupsTableRows'
import TableHeader from 'widgets/groups/trainingGroupsTable/TableHeader/TableHeader'

export type GroupsTableView = 'groupsPage' | 'trainingCard'

type GroupsTableProps = {
	// Где будет показана таблицы: на странице групп или в карточке курса
	tableView?: GroupsTableView
	trainingId: number
}

// Таблица групп курса
function GroupsTable(props: GroupsTableProps) {
	const { tableView = 'groupsPage', trainingId } = props

	return (
		<div>
			<UTable
				extraClass={
					tableView == 'trainingCard' &&
					' all-groups-table__plain-table'
				}
			>
				<TableHeader />
				<UTableBody>
					<GroupsTableRows
						tableView={tableView}
						trainingId={trainingId}
					/>
					<AddGroupRow tableView={tableView} />
				</UTableBody>
			</UTable>
		</div>
	)
}

export default GroupsTable
