import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { EmptyBlock } from 'ui/EmptyBlock/EmptyBlock'
import { AddLessonModalController } from 'widgets/lessonsListAdmin/AddLessonModal/AddLessonModalController'
import s from './NoLessonsScreen.module.scss'

// Компонент сообщающий об отсутствии уроков в курсе
function NoLessonsScreen() {
	const { t } = useTranslation()

	return (
		<div className={s.wrapper}>
			<EmptyBlock
				text={
					<>
						{t('adminTraining.noLessonsScreenFirstTextLne')}
						<br />
						{t('adminTraining.noLessonsScreenSecondTextLne')}
					</>
				}
				button={<AddLessonButton />}
			/>
		</div>
	)
}

export default NoLessonsScreen

function AddLessonButton() {
	const { t } = useTranslation()

	return (
		<Button
			type="primary"
			icon={<PlusOutlined />}
			onClick={AddLessonModalController.open}
		>
			{t('adminTraining.addLessonButton')}
		</Button>
	)
}
