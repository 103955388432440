import Result from '../common/Result/Result'
import {
	ExerciseChangesDataMadeByUser,
	LiftViewDuration,
	SaveExerciseChangesMadeByUser,
	useLiftViewDuration,
} from '../common/useLiftViewDuration'
import Exercise from './Exercise'
import { useManageExerciseState } from './fn/main'
import { calculateStats, useGetOnPassAgainButtonClick } from './fn/result'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import './TrueOrFalseExerciseView.scss'
import {
	useRestoreStateOnMount,
	useSaveStateOnServerForRestore,
} from './fn/useSaveStateOnServerForRestore'

type TrueOrFalseExerciseViewProps = {
	exercise: TrainingEntityTypes.TrueOrFalseExercise
	liftViewDuration?: LiftViewDuration
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

// Вывод упражнения ложь или истина в уроке
function TrueOrFalseExerciseView(props: TrueOrFalseExerciseViewProps) {
	const {
		exercise,
		liftViewDuration,
		saveExerciseChangesMadeByUser,
		exerciseRestoreData,
	} = props

	const { state, setState } = useManageExerciseState(exercise)

	// Считает время прошедшее после открытия упражнения
	// и передаёт количество секунд в liftViewDuration
	const durationSec = useLiftViewDuration(liftViewDuration, state.showResult)

	// Занимается сохранением состояния упражнения для восстановления при открытии
	// useSaveStateOnServerForRestore(state, saveExerciseChangesMadeByUser)
	// Занимается восстановлением состояния упражнения при открытии
	// useRestoreStateOnMount(state, setState, exerciseRestoreData)

	const onPassAgainButtonClick = useGetOnPassAgainButtonClick(
		exercise,
		setState
	)
	const { passedNum, totalNum } = calculateStats(state)

	return (
		<>
			{state.showResult ? (
				<Result
					onPassAgainButtonClick={onPassAgainButtonClick}
					seconds={durationSec}
					passed={passedNum}
					total={totalNum}
				/>
			) : (
				<Exercise exerciseState={state} setExerciseState={setState} />
			)}
		</>
	)
}

export default TrueOrFalseExerciseView
