import React, { MouseEventHandler } from 'react'
import { Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { ModalLayout } from 'ui/ModalLayout/ModalLayout'
import RestrictionsService from '../../../../parts/services/RestrictionsService'
import {
	onChangeNameInput,
	onChangeTaskInput,
	addNewQuestion,
} from './fn/changeForms'
import { TestExerciseFormStateType } from './fn/stateTypes'
import { QuestionsBlocks } from './Questions'
import ExerciseFormFooter from '../common/ExerciseFormFooter/ExerciseFormFooter'
import HtmlEditor from 'ui/HTMLEditor/HTMLEditor'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import useExercisesListAdminStore from '../../zustand/store'
import { useSetFormData } from './fn/setFormData'
import { isDownloadingFile } from './fn/check'
import { submitHandler } from './fn/submit'
import Generator from '../Generator/Generator'
import {
	useGetGeneratedPromptAndSetToStore,
	useGetGeneratedTextAndSetToStore,
} from './fn/generatedText'
import './TestForm.scss'

type TestFormProps = {
	saveExercise: (args: TrainingEntityTypes.ExerciseItem) => void // Функция сохраняющая данные упражнения на сервере и в Хранилище
	closeModalFn: MouseEventHandler<HTMLElement> // Функция закрытия модального окна
}

// Форма составления звукового-упражнения
function TestForm(props: TestFormProps) {
	const { saveExercise, closeModalFn } = props

	useSetFormData()

	const canUseAiGeneration = RestrictionsService.useCanUseAiGeneration()

	const formState = useExercisesListAdminStore(
		(store) => store.formData
	) as TestExerciseFormStateType

	const { t } = useTranslation()
	// Функция получающая сгенерированный текст и помещающая его в поле с текстом упражнения
	const getGeneratedText = useGetGeneratedTextAndSetToStore()
	const getGeneratedPrompt = useGetGeneratedPromptAndSetToStore()

	if (!formState) return null

	return (
		<ModalLayout
			key="1"
			title="Создание теста"
			cancelBtn={false}
			isModalVisible
			closeModalFn={closeModalFn}
			footer={null}
		>
			<NameInput formState={formState} />
			<TaskInput formState={formState} />
			{canUseAiGeneration && (
				<Generator
					exerciseType="test-single-answer"
					getGeneratedText={getGeneratedText}
					initialPrompt={formState.generatedTextPrompt}
					getGeneratedPrompt={getGeneratedPrompt}
					error={formState.generatedTextError}
				/>
			)}
			<QuestionsBlocks />
			<AddNewQuestionButton />
			<ExerciseFormFooter
				formState={formState}
				saveExercise={saveExercise}
				closeModalFn={closeModalFn}
				isSubmitButtonDisabled={
					isDownloadingFile() || formState.isFormInvalid
				}
				submitHandler={() => submitHandler(saveExercise)}
			/>
		</ModalLayout>
	)
}

export default TestForm

type NameInputProps = {
	formState: TestExerciseFormStateType
}

// Поле с названием теста
function NameInput(props: NameInputProps) {
	const { formState } = props

	const { t } = useTranslation()

	return (
		<>
			<label className="exercise-test__label">Название теста</label>
			<HtmlEditor
				initialValue={formState.nameInput.value}
				onChangeValue={onChangeNameInput}
			/>
		</>
	)
}

type TestDescriptionProps = {
	formState: TestExerciseFormStateType
}

// Поле с текстом задания
function TaskInput(props: TestDescriptionProps) {
	const { formState } = props

	const { t } = useTranslation()

	return (
		<>
			<label className="exercise-test__label exercise-test__label--top-margin">
				Описание задания
			</label>
			<HtmlEditor
				initialValue={formState.taskInput.value}
				onChangeValue={onChangeTaskInput}
			/>
		</>
	)
}

// Кнопка добавления нового блока вопроса
function AddNewQuestionButton() {
	const { t } = useTranslation()

	return (
		<Button
			className="exercise-test-add-question-button"
			onClick={() => addNewQuestion()}
		>
			<PlusCircleOutlined width={17} height={17} /> Добавить вопрос
		</Button>
	)
}
