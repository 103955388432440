import LiveLessonApiTypes from 'parts/requests/liveLesson/liveLessonApiTypes'
import { useTranslation } from 'react-i18next'
import FolderDialog from '../components/NewFolder/FolderDialog'
import useLiveLibraryStore from '../zustand/store'
import { getFileNameWOSpace } from '../../../fn/liveUtils'
import { useGetCreateStorageItem } from '../../../fn/createStorageItem'

function useFolderDialog() {
	const { t } = useTranslation()

	const selectedFolder = useLiveLibraryStore((store) => store.selectedFolder)
	const isFolderDialogShow = useLiveLibraryStore(
		(store) => store.isFolderDialogShow
	)
	const setIsFolderDialogShow = useLiveLibraryStore(
		(store) => store.setIsFolderDialogShow
	)
	const onCreateStorageItem = useGetCreateStorageItem()

	const handleCreateNewFolder = (folderName: string) => {
		const newFile: LiveLessonApiTypes.CreateStorageItem = {
			parentId: selectedFolder ? selectedFolder.id : null,
			name: folderName,
			fileType: 'folder',
			fileSize: 0,
			s3link: getFileNameWOSpace(folderName),
			isFolder: true,
		}

		onCreateStorageItem(newFile)
		setIsFolderDialogShow(false)
	}

	const handleFolderDialogClose = () => {
		setIsFolderDialogShow(false)
	}

	return (
		<FolderDialog
			open={isFolderDialogShow}
			onCancel={handleFolderDialogClose}
			onOk={handleCreateNewFolder}
			title={t('files.filesTableNewFolderBtn')}
			initialFolderName=""
		/>
	)
}

export default useFolderDialog
