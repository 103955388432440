import { useEffect, useRef } from 'react'
import useExerciseStore from '../../zustand/exerciseState'

/** Подсчитывает время на прохождение каждой попытки и записывает в Состояние */
export function useSetAttemptsDuration() {
	const stage = useExerciseStore((store) => store.stage)
	const updateStore = useExerciseStore((store) => store.updateStore)
	const intervalRef = useRef<null | NodeJS.Timer>(null)

	useEffect(
		function () {
			if (stage === 'NotReadyToCheck') {
				const { durationsInSeconds } = useExerciseStore.getState()
				updateStore({ durationsInSeconds: [...durationsInSeconds, 0] })

				intervalRef.current = setInterval(() => {
					const { durationsInSeconds } = useExerciseStore.getState()
					const newDurations = [...durationsInSeconds]
					newDurations[newDurations.length - 1] =
						newDurations[newDurations.length - 1] + 1

					updateStore({ durationsInSeconds: newDurations })
				}, 1000)
			} else if (stage === 'Checked' && intervalRef.current) {
				clearInterval(intervalRef.current)
			}
		},
		[stage]
	)
}
