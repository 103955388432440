import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import { getHeaderName, routesEnd } from './fn/routes'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import EditPaymentForm from '../EditPaymentForm/EditPaymentForm'
import {
	useSetPaymentCommonDataToCtx,
	useSetPaymentSystemTypeToCtx,
} from './fn/getPaymentData'
import { StateContext } from '../state/stateContext'
import StopPaymentModal from '../StopPaymentModal/StopPaymentModal'
import Loader from 'ui/Loader/Loader'
import { useSetFormDataToCtx } from './fn/setFormDataToState'
import {
	CardSticker,
	CardStickerColors,
} from 'ui/wrappers/CardSticker/CardSticker'
import Footer from 'ui/Footer/Footer'
import { useGetUserRole } from 'parts/utils/hooks/hooks'

function MyPaymentContent() {
	const { t } = useTranslation()
	const userRoles = useGetUserRole()
	useSetPaymentSystemTypeToCtx()
	useSetPaymentCommonDataToCtx()
	useSetFormDataToCtx()

	const { state } = useContext(StateContext)
	const { paymentSystemCommonData, initialFormData } = state

	if (!paymentSystemCommonData || !initialFormData) {
		return (
			<ArticleContentWrapper center>
				<Loader />
			</ArticleContentWrapper>
		)
	} else {
		return (
			<PageSkeleton>
				<PageHeader
					breadcrumbs={routesEnd}
					header={getHeaderName(paymentSystemCommonData.name, t)}
				/>
				<ArticleContentWrapper center>
					{state.connectionType !== 'idle' ? (
						<CardSticker
							text={
								state.connectionType == 'on'
									? t(
											'myPayments.editPaymentFormConnectionOn'
									  )
									: t(
											'myPayments.editPaymentFormConnectionOff'
									  )
							}
							color={
								state.connectionType == 'on'
									? CardStickerColors.Blue
									: CardStickerColors.Orange
							}
						>
							<EditPaymentForm />
						</CardSticker>
					) : (
						<EditPaymentForm />
					)}
					<StopPaymentModal />
				</ArticleContentWrapper>
				<Footer isRightSideHidden={!userRoles.isAdminOrManager} />
			</PageSkeleton>
		)
	}
}

export default MyPaymentContent
