import {
	Block,
	BlockOuter,
} from 'pages/landing/landingConstructor/sections/existenceSection/ExistenceBlock/ExistenceBlock'
import existenceBlocksManager from 'pages/landing/landingConstructor/sections/existenceSection/ExistenceBlocksManager/existenceBlocksManager'
import 'pages/landing/landingConstructor/sections/existenceSection/ExistenceBlocks/ExistenceBlocks.scss'

// Обёртка с двумя колонками куда помещаются блоки с описанием пользователя, которому подойдёт предлагаемый курс
function ExistenceBlocks() {
	const leftBlocks = existenceBlocksManager.useGetColumnBlocks(1)
	const rightBlocks = existenceBlocksManager.useGetColumnBlocks(2)

	let blockIdx = -1

	return (
		<div className="land-const-existence-blocks">
			<div className="land-const-existence-blocks__column">
				{leftBlocks.map((block) => {
					blockIdx++

					return (
						<BlockOuter block={block} key={block.id}>
							<Block
								block={block}
								blockIdx={blockIdx}
								key={block.id}
							/>
						</BlockOuter>
					)
				})}
			</div>
			<div className="land-const-existence-blocks__column">
				{rightBlocks.map((block) => {
					blockIdx++

					return (
						<BlockOuter block={block} key={block.id}>
							<Block
								block={block}
								blockIdx={blockIdx}
								key={block.id}
							/>
						</BlockOuter>
					)
				})}
			</div>
		</div>
	)
}

export default ExistenceBlocks
