import React from 'react'
import { Card, Col, Row, Typography } from 'antd'
import './Preset.scss'

type PresetProps = {
	name: string
	icon: JSX.Element
	onPresetClick: () => void
}

function Preset(props: PresetProps) {
	const { name, icon, onPresetClick } = props

	return (
		<Card className="lesson-preset" onClick={onPresetClick}>
			<Row gutter={[8, 0]} align="middle" wrap={false}>
				<Col className="lesson-preset__icon">{icon}</Col>
				<Col className="lesson-preset__content">
					<Typography className="lesson-preset__name">
						{name}
					</Typography>
				</Col>
			</Row>
		</Card>
	)
}

export default Preset
