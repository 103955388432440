import { useCallback, useEffect, useState } from 'react'
import UrlService from 'parts/services/UrlService'
import { useIsFirstRender } from 'parts/utils/hooks/hooks'
import { SaveExerciseChangesMadeByUser } from '../../../common/useLiftViewDuration'
import useExerciseStore, {
	ExerciseStateType,
} from '../../zustand/exerciseState'
import {
	createTextButton,
	disable$button,
	removeWideFrom$slot,
} from './manipulateElems'

type RestoreDataType = {
	// Текстовый блок — это массив строк.
	// Поэтому текстовые блоки — это массивы массивов строк
	textBlocks: TextBlocksRestore
	store: Omit<ExerciseStateType.State, 'updateStore'>
}

type TextBlocksRestore = string[][]

/**
 * Возвращает обработчик перетаскивания кнопки с текстом в дырку.
 * После формируется объект с текстом брошенных кнопок в дырки и сохраняется на сервере
 * чтобы при открытии упражнения в следующий раз было восстановлено положение кнопок с текстом, которое сделал ученик.
 */
export function useGetDropWordHandler(
	$generalWrapper: null | HTMLDivElement,
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
) {
	const exerciseId = UrlService.useGetExerciseId()
	const store = useExerciseStore((store) => store)

	return useCallback(
		function (e: any) {
			if (!$generalWrapper || !saveExerciseChangesMadeByUser) return

			const restoreData = createRestoreData($generalWrapper, store)

			// Передать в функцию, которая сохранит данные на сервере.
			saveExerciseChangesMadeByUser(restoreData)
		},
		[$generalWrapper, exerciseId]
	)
}

export function useSaveStateOnServerForRestore(
	$generalWrapper: null | HTMLDivElement,
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
) {
	// Задержка, чтобы не сохранял данные после открытия страницы
	const isFirstRender = useIsFirstRender(2000)
	const store = useExerciseStore((store) => store)

	useEffect(
		function () {
			if (
				isFirstRender ||
				!$generalWrapper ||
				!saveExerciseChangesMadeByUser
			)
				return

			const restoreData = createRestoreData($generalWrapper, store)

			// Передать в функцию, которая сохранит данные на сервере.
			saveExerciseChangesMadeByUser(restoreData)
		},
		[store.saveStoreForRestore]
	)
}

function createRestoreData(
	$generalWrapper: HTMLDivElement,
	store: ExerciseStateType.State
): RestoreDataType {
	const { updateStore, ...storeClone } = store

	return {
		textBlocks: getSlotsRestoreObj($generalWrapper),
		store: storeClone,
	}
}

function getSlotsRestoreObj($generalWrapper: HTMLDivElement) {
	// Объект данных, сохраняемый на сервере.
	const blocksGapsRestore: TextBlocksRestore = []

	// Перебрать блоки с текстом
	for (let i = 0; i < $generalWrapper.children.length; i++) {
		// Блок текста с дырками
		const $textBlock = $generalWrapper.children[i]

		// Элементы, в которые можно бросить слова
		const $gaps = $textBlock.querySelectorAll(
			'.exercise-drop-word-content__slot'
		) as NodeListOf<HTMLElement>

		// Перебрать дырки конкретного текстового блока
		for (let j = 0; j < $gaps.length; j++) {
			// Если нет массива для слов текстового блока, то создать.
			if (!blocksGapsRestore[i]) {
				blocksGapsRestore.push([])
			}

			// Поставить в данные текст кнопки, который должен быть в дырке.
			// При восстановлении сохранённый текст будет преобразована в перетащенную кнопку.
			blocksGapsRestore[i].push($gaps[j].innerText)
		}
	}

	return blocksGapsRestore
}

// ==========================

export function useRestoreExercise(
	exerciseRestoreDataPromise: Promise<RestoreDataType>
) {
	const textBlocks = useExerciseStore((store) => store.textBlocks)
	const updateStore = useExerciseStore((store) => store.updateStore)

	const $generalWrapper: null | HTMLDivElement = document.querySelector(
		'.exercise-drop-word-content__text-blocks'
	)

	const [textBlocksIsReady, setTextBlocksIsReady] = useState(false)

	useEffect(
		function () {
			if (!textBlocks) return

			setTextBlocksIsReady(true)
		},
		[textBlocks]
	)

	useEffect(
		function () {
			if (!textBlocksIsReady || !$generalWrapper) return

			exerciseRestoreDataPromise.then((restoreData) => {
				if (!restoreData?.store) return

				// Автоматически перетащить кнопки в те места, куда они были перетащаны в прошлый раз.
				setWordsInGaps($generalWrapper, restoreData.textBlocks)

				// Поставить сохранённые данные в Состояние
				updateStore(restoreData.store)
			})
		},
		[textBlocksIsReady]
	)
}

/**
 *
 * @param $textBlocksWrapper
 * @param restoreData
 */
function setWordsInGaps(
	$textBlocksWrapper: HTMLDivElement,
	restoreData: TextBlocksRestore
) {
	// Перебрать текстовые блоки с дырками для перетаскивания
	for (let i = 0; i < $textBlocksWrapper.children.length; i++) {
		const $words = $textBlocksWrapper.children[i].querySelector(
			'.exercise-drop-word-content__words'
		)
		if (!$words) continue

		// Блок текста с дырками
		const $textBlock = $textBlocksWrapper.children[i]

		// Элементы, в которые перетаскиваются слова
		const $gaps = $textBlock.querySelectorAll(
			'.exercise-drop-word-content__slot'
		)

		// Перебрать дырки
		for (let j = 0; j < $gaps.length; j++) {
			// Пропустить если в данных нет текста для этой дырки
			if (!restoreData[i] || !restoreData[i][j]) continue

			// Текст, который должен быть в дырке
			const textForGap = restoreData[i][j]
			// Найти кнопку с этим текстом на обёртке кнопок
			const textButton = getTextButtonByText($words, textForGap)
			if (!textButton) continue

			// Сделать кнопку неперетаскиваемой чтобы её нельзя было перетащить ещё раз.
			// Ниже сделаю кнопку, котораю будет в дырке и уже её перетащить можно.
			disable$button(textButton)

			// Получить дырку и удалить класс задающий ширину чтобы она полностью обнимала перетащенную кнопку
			const $gap = $gaps[j] as HTMLElement
			removeWideFrom$slot($gap)

			// Создать перетащенную кнопку
			const draggedButton = createTextButton(
				textForGap,
				textButton.dataset.wordId!
			)

			// Поставить в дырку перетащенную кнопку
			$gaps[j].append(draggedButton)
		}
	}
}

/**
 * Ищет в обёртке с кнопками кнопку с переданным текстом
 * @param $words — обёртка с кнопками для перетаскивания
 * @param text — текст, который должен быть у искомой кнопке
 */
function getTextButtonByText(
	$words: Element,
	text: string
): null | HTMLElement {
	const buttons = $words.querySelectorAll('.exercise-drop-word-content__word')

	for (let i = 0; i < buttons.length; i++) {
		const thisButton = buttons[i]

		if (thisButton.textContent === text) {
			return thisButton as HTMLElement
		}
	}

	return null
}
