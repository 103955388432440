import {
	FilesObjType,
	FormQuestionStateItemType,
	TestExerciseFormStateType,
} from './stateTypes'

/**
 * Создаёт объект вопроса для состояния упражнения
 * @param {Number} questionId — id вопроса
 */
export function createFormQuestionStateItem(
	questionId: number
): FormQuestionStateItemType {
	return {
		id: questionId,
		questionText: '',
		noQuestionTextError: false,
		mediaType: 'audio',
		files: createEmptyFilesObj(),
		multipleSelect: false,
		answers: [
			{
				id: 1,
				text: '',
				correct: false,
			},
			{
				id: 2,
				text: '',
				correct: false,
			},
		],
		noAnswerTextError: false,
		noRightAnswerCheckedError: false,
	}
}

export function createEmptyFilesObj(): FilesObjType {
	return {
		audio: {
			link: '',
			status: 'empty',
			progress: 0,
			fileName: '',
		},
		video: {
			videoLink: '',
			link: '',
			status: 'empty',
			progress: 0,
			fileName: '',
		},
		image: {
			link: '',
			status: 'empty',
			progress: 0,
			fileName: '',
		},
	}
}

// Объект начального состояния формы видео-упражнения
export const testFormInitialState: TestExerciseFormStateType = {
	exerciseId: null,
	order: 0,
	nameInput: {
		value: '',
	},
	taskInput: {
		value: '', // Значение поля «Описание задания»
	},
	generatedText: '',
	generatedTextPrompt: '',
	generatedTextError: null,
	questions: [createFormQuestionStateItem(1)],
	isFormInvalid: false,
}
