import React, { ReactNode } from 'react'
import cn from 'classnames'
import './UFormOld.scss'

// В БУДУЩЕМ ЭТОТ КОМПОНЕНТ НУЖНО УДАЛИТЬ КАК УСТАРЕВШИЙ

type UFormOldProps = {
	children: ReactNode | ReactNode[]
	whiteBg?: boolean // Добавить ли белый фоновый цвет?
	grayBg?: boolean // Добавить ли серый фоновый цвет?
	padding?: boolean // Добавить ли отступы от обёртки до полей?
}

// Разметка для сборки стандартной формы
export function UFormOld(props: UFormOldProps) {
	const { children, whiteBg, grayBg, padding = false } = props

	const classes = ['uform-old']
	if (whiteBg) classes.push('uform-old--white-bg')
	if (grayBg) classes.push('uform-old--gray-bg')
	if (padding) classes.push('uform-old--padding')

	return <div className={cn(classes)}>{children}</div>
}

type UFormOldRowProps = {
	topOffset?: boolean // Отступ сверху
	children: ReactNode | ReactNode[]
}

// Ряд с полем/полями ввода
export function UFormOldRow(props: UFormOldRowProps) {
	const { topOffset = false, children } = props

	const classes = ['uform-old__row']

	if (topOffset) {
		classes.push('uform-old__row--top-offset')
	}

	if (Array.isArray(children) && children.length == 2) {
		classes.push('uform-old__row--2-cell')
	}

	return <div className={cn(classes)}>{children}</div>
}

type UFormOldBottomButtonsProps = {
	children: ReactNode | ReactNode[]
}

// Нижняя часть с кнопками очистки формы и отправки
export function UFormOldBottomButtons(props: UFormOldBottomButtonsProps) {
	const { children } = props

	return <div className="uform-old__bottom">{children}</div>
}
