import React, { SyntheticEvent, useCallback, useEffect } from 'react'
import { ExerciseStateType } from './state'
import { getRusNounByNumber } from 'parts/utils/string'
import { shuffleArray } from 'parts/utils/array'

/**
 * Функция создаёт HTML текстового блока с пропусками
 * @param {Object} editorRef — объект со ссылкой на блок текста с пропусками
 * @param {String} htmlString — строка с HTML из которой нужно создать разметку с пропусками
 */
export function useCreateInnerHtml(
	editorRef: React.MutableRefObject<null | HTMLElement>,
	htmlString: string
): void {
	useEffect(
		function () {
			const $editor = editorRef.current as HTMLElement
			if (!$editor) return

			// Получение массива тексов, которые будут в выпадающем списке
			const gapWords = getGapWords(htmlString)

			// Создание строки с разметкой текстового блока с пропусками и вставка в редактор
			$editor.innerHTML = setSelectListToHtmlString(gapWords, htmlString)
		},
		[htmlString]
	)
}

/**
 * Функция создаёт строку с разметкой текстового блока с выпадающими списками.
 * Текст, который используется для создания выпадающих список должен быть в разметке обрамлены фигурными скобками.
 * И функция ставит вместо таких текстов выпадающий список
 * @param {Array} gapWords — массив пропущенных слов
 * @param {String} htmlString — строка с HTML из которой нужно создать разметку с пропусками
 */
function setSelectListToHtmlString(gapWords: string[], htmlString: string) {
	let newHtmlString = htmlString

	let gapWordsIdx = 0 // idx текущего слова из массива пропущенных слов
	let openBraceIdx = 0 // Номер буквы с которого начинается открывающая фигурная скобка
	let closeBraceIdx = 0 // Номер буквы с которого начинается закрывающая фигурная скобка

	for (let i = 0; i < newHtmlString.length; i++) {
		const letter = newHtmlString[i]

		if (letter == '{') {
			openBraceIdx = i
		} else if (letter == '}') {
			closeBraceIdx = i

			// Получить текст, который затем используется для создания выпадающего списка
			const currentGapWord = gapWords[gapWordsIdx++]
			// Создать выпадающий список с выбором возможных слов из которых нужно выбрать правильное
			const selectListStr = createSelectList(currentGapWord)
			// Поставить разметку
			newHtmlString =
				newHtmlString.substring(0, openBraceIdx) +
				selectListStr +
				newHtmlString.substring(closeBraceIdx + 1)

			// Увеличить счётчик на размер вставляемого слова чтобы не делать лишние проходы цикла
			i = i - currentGapWord.length + selectListStr.length
		}
	}

	return newHtmlString
}

/**
 * Возвращает строку разметки содержащей выпадающий список из которого нужно выбрать правильное слово.
 * @param {String} textInBraces — текст из которого создаётся выпадающий список вида {+begin,began,begun} где слова выпадающего списка разделены запятыми, а правильное слово помечено знаком «+».
 */
function createSelectList(textInBraces: string): string {
	const selectListItems = getSelectListItems(textInBraces)

	let correctWord = ''

	const options = selectListItems.map((listItem) => {
		const correct = listItem.correct ? 'true' : 'false'

		if (correct == 'true') {
			correctWord = listItem.word
		}

		return `<option value='${listItem.word}' data-right='${correct}'>${listItem.word}</option>`
	})

	options.unshift('<option value="">Выберите</option>')

	const selectStr = `<select name="" class='exercise-choose-word-content__select'>${options}</select>`

	const rightAnswerSpan = `<span class="exercise-choose-word-content__right-answer">${correctWord}</span>`
	return selectStr + rightAnswerSpan
}

type SelectListItemType = {
	word: string
	correct: boolean
}

function getSelectListItems(textInBraces: string): SelectListItemType[] {
	const words = textInBraces?.split(',').filter((word) => word !== '')

	const wordsDataArr = words.map((word) => {
		const trimmedWord = word.trim()
		const correct = trimmedWord.startsWith('+')

		return {
			word: correct ? trimmedWord.slice(1) : trimmedWord,
			correct,
		}
	})

	// Перемещать элементы массива чтобы было сложнее угадать правильный ответ
	shuffleArray(wordsDataArr)

	return wordsDataArr
}

/**
 * Хук возвращает обработчик нажатия на кнопку просмотра результат
 * @param {Object} exerciseState — объект состояния упражнения
 * @param {Function} setExerciseState — функция устанавливающая новый объект состояния
 * @param {Object} textsRef — объект со ссылкой на обёртку текстов с пропусками
 */
export function useGetResultButtonFn(
	exerciseState: ExerciseStateType,
	setExerciseState: React.Dispatch<React.SetStateAction<ExerciseStateType>>,
	textsRef: React.MutableRefObject<HTMLDivElement | null>
) {
	return useCallback(
		function (e: SyntheticEvent) {
			const $texts = textsRef.current
			if (!$texts) return

			// Копия объекта состояния упражнения
			const exerciseStateCopy = { ...exerciseState }

			// Поставить время начала выполнения упражнения если ещё не начинали
			if (!exerciseStateCopy.startTime) {
				exerciseStateCopy.startTime = new Date()
			}

			// Если упражнение готово к просмотру результата, то показать его
			if (exerciseStateCopy.readyToShowResult) {
				exerciseStateCopy.showResult = true
				setExerciseState(exerciseStateCopy)
				return
			}

			// Проверить введённые ответы
			const {
				allSelectsFilledRight,
				successAnswersCounter,
				totalGapsCounter,
			} = checkSelectInputs($texts)

			exerciseStateCopy.attempts--

			if (allSelectsFilledRight || !exerciseStateCopy.attempts) {
				// Показать правильные ответы если это требуется
				showRightAnswers($texts)

				exerciseStateCopy.readyToShowResult = true
				exerciseStateCopy.resultButtonText = 'Результат'

				exerciseStateCopy.rightAnswers = successAnswersCounter
				exerciseStateCopy.totalGaps = totalGapsCounter
			}

			setExerciseState(exerciseStateCopy)
		},
		[exerciseState]
	)
}

/**
 * Хук возвращает функцию отслеживающую изменения выпадающих списков с выбором правильного слова
 * @param {Object} textsRef — объект со ссылкой на обёртку текстов с пропусками
 * @param {Object} exerciseState — объект состояния упражнения
 * @param {Function} setExerciseState — функция устанавливающая новый объект состояния
 */
export function useSetSelectChangeHandler(
	textsRef: React.MutableRefObject<HTMLDivElement | null>,
	exerciseState: ExerciseStateType,
	setExerciseState: React.Dispatch<React.SetStateAction<ExerciseStateType>>
) {
	useEffect(
		function () {
			const $texts = textsRef.current
			if (!$texts) return

			// Создание устанавливаемого обработчика
			const inputHandler = getAfterSelectChangedFn(
				$texts,
				setExerciseState
			)

			$texts.addEventListener('change', inputHandler)

			return function () {
				$texts.removeEventListener('input', inputHandler)
			}
		},
		[textsRef]
	)
}

/**
 * Функция возвращает обработчик ввода текста в пропуски
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 * @param {Function} setExerciseState — функция устанавливающая новый объект состояния
 */
function getAfterSelectChangedFn(
	$texts: HTMLDivElement,
	setExerciseState: React.Dispatch<React.SetStateAction<ExerciseStateType>>
) {
	return function (e?: Event) {
		const $select = e?.target

		// При вводе убрать классы поясняющие, что выбрано правильное слово или ошибка
		if ($select) {
			// @ts-ignore
			$select.classList.remove(
				'exercise-choose-word-content__select--success'
			)
			// @ts-ignore
			$select.classList.remove(
				'exercise-choose-word-content__select--fail'
			)
		}

		// Обновить состояния упражнения...
		setExerciseState((state) => {
			const stateCopy = { ...state }

			// Поставить время начала выполнения упражнения если ещё не начинали
			if (!stateCopy.startTime) {
				stateCopy.startTime = new Date()
			}

			// Пересчитать количество незаполненных пропусков
			stateCopy.unfilledSelects = getUnfilledSelectsNum($texts)

			return stateCopy
		})
	}
}

/**
 * Функция считает количество пропусков без введённых символов
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
function getUnfilledSelectsNum($texts: HTMLDivElement) {
	let unfilledGapsCounter = 0

	enumerateSelects($texts, ($gap) => {
		if (!$gap.childNodes[0]) {
			unfilledGapsCounter++
		}
	})

	return unfilledGapsCounter
}

/**
 * Функция получает все элементы пропуска
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
function get$Selects($texts: HTMLDivElement): NodeListOf<HTMLSelectElement> {
	return $texts.querySelectorAll('.exercise-choose-word-content__select')
}

/**
 * Функция перебирает выпадающие списки и для каждого запускает переданную функцию, в которую передаёт перебираемый выпадающий список
 * @param {HTMLElement} $texts — обёртка текстов с выпадающими списками
 * @param {Function} handler — функция запускаемая для каждого пропуска в которую передаёт элемент пропуска
 */
function enumerateSelects(
	$texts: HTMLDivElement,
	handler: ($select: HTMLSelectElement) => void
) {
	const $selects = get$Selects($texts)

	for (let i = 0; i < $selects.length; i++) {
		const $select = $selects[i]

		if ($select.nodeType == 1) {
			handler($select as HTMLSelectElement)
		}
	}
}

/**
 * Функция проверяет выбранные значение я выпадающих списках и задаёт им классы в зависимости от правильности выбора
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
function checkSelectInputs($texts: HTMLDivElement) {
	let allSelectsFilledRight = true // Правильно ли заполнены все пропуски
	let successAnswersCounter = 0 // Количество правильно введённых слов

	enumerateSelects($texts, ($select) => {
		// Option выбранный пользователем
		const selectedOption = $select.options[$select.selectedIndex]

		if (selectedOption.dataset.right == 'true') {
			$select.classList.remove(
				'exercise-choose-word-content__select--fail'
			)
			$select.classList.add(
				'exercise-choose-word-content__select--success'
			)
			successAnswersCounter++
		} else {
			$select.classList.remove(
				'exercise-choose-word-content__select--success'
			)
			$select.classList.add('exercise-choose-word-content__select--fail')
			allSelectsFilledRight = false
		}
	})

	return {
		allSelectsFilledRight,
		successAnswersCounter,
		totalGapsCounter: get$Selects($texts).length,
	}
}

/**
 * Получение текста с количеством доступных попыток просмотра результата
 * @param {Number} attempts — количество доступных попыток просмотра результата
 */
export function getAttemptsText(attempts: number) {
	if (attempts > 1) {
		return attempts + ' попытки'
	} else if (attempts == 1) {
		return '1 попытка'
	} else {
		return 'Попыток не осталось'
	}
}

/**
 * Получение текста с количеством незаполненных пропусков
 * @param {Number} unfilledGaps — количество незаполненных пропусков
 */
export function getUnfilledSelectsText(unfilledGaps: number): string {
	if (!unfilledGaps) return ''

	const lastWord = getRusNounByNumber(
		unfilledGaps,
		' Остался',
		'Осталось',
		'Осталось'
	)
	const gapsWord = getRusNounByNumber(
		unfilledGaps,
		'пропуск',
		'пропуска',
		'пропусков'
	)

	return lastWord + ' ' + unfilledGaps + ' ' + gapsWord
}

/**
 * Функция показывает правильный вариант слова после выпадающего списка если пользователь выбрал неверный вариант
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
function showRightAnswers($texts: HTMLDivElement) {
	enumerateSelects($texts, ($select) => {
		if (
			$select.classList.contains(
				'exercise-choose-word-content__select--fail'
			)
		) {
			const $rightWord = $select.nextElementSibling

			if ($rightWord) {
				$rightWord.classList.add(
					'exercise-choose-word-content__right-answer--open'
				)
			}
		}
	})
}

/**
 * Хук при загрузке компонента упражнения считает количество незаполненных пропусков и ставит его в местное состояние
 * @param {Object} textsRef — объект со ссылкой на обёртку текстов с пропусками
 * @param {Function} setExerciseState — функция устанавливающая новый объект состояния
 */
export function useSetUnfilledSelectsNum(
	textsRef: React.MutableRefObject<HTMLDivElement | null>,
	setExerciseState: React.Dispatch<React.SetStateAction<ExerciseStateType>>
) {
	useEffect(function () {
		const $texts = textsRef.current
		if (!$texts) return

		setExerciseState((state) => {
			const stateCopy = { ...state }

			stateCopy.unfilledSelects = getUnfilledSelectsNum($texts)

			return stateCopy
		})
	}, [])
}

/**
 * Функция возвращает массив пропущенных слов
 * @param {String} htmlString — строка с HTML из которой нужно создать разметку с пропусками
 */
export function getGapWords(htmlString: string) {
	const gapWords: string[] = []

	let collectLetters = false
	let gapWord = ''

	for (let i = 0; i < htmlString.length; i++) {
		const letter = htmlString[i]

		if (letter == '{') {
			collectLetters = true
			continue
		} else if (letter == '}') {
			gapWords.push(gapWord)
			collectLetters = false
			gapWord = ''
		}

		if (collectLetters) {
			gapWord += letter
		}
	}

	return gapWords
}
