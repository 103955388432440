import useGroupsStore from 'widgets/groups/trainingGroupsTable/zustand/store'

/**
 * Возвращает массив групп прикреплённых к указанному курсу
 * @param trainingId — id курса, курсы которого нужно получить
 */
export function useGetTrainingGroups(trainingId: number) {
	const { trainingsGroups } = useGroupsStore.getState()

	return trainingsGroups.find(
		(training) => training.trainingId === trainingId
	)
}
