import { useTranslation } from 'react-i18next'
import { Loader } from 'ui'
import useLessonsPresetsStore from '../../../zustand/store'
import useFillInStore from './fillInStore'
import PresetsList from '../../PresetsList/PresetsList'

/** Хук возвращающий статус компонента PresetsList и сам компонент в зависимости от статуса */
export default function useGetPresetsListRouter() {
	const { t } = useTranslation()

	// Получить список шаблонов и поставить в Состояние
	useFillInStore()

	const dataLoadingStatus = useLessonsPresetsStore(
		(state) => state.dataLoadingStatus
	)

	if (dataLoadingStatus == 'loading') {
		return <Loader />
	} else if (dataLoadingStatus == 'error') {
		return <p>{t('adminTraining.lessonPresetErrorWhileDataLoading')}</p>
	}

	return <PresetsList />
}
