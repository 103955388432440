import React, { ReactNode } from 'react'
import './RowsContainer.scss'

type RowsContainerProps = {
	children: ReactNode | ReactNode[]
}

// Контейнер дающий вертикальные отступы между элементами: карточками, например.
function RowsContainer(props: RowsContainerProps) {
	const { children } = props

	return <div className="rows-container">{children}</div>
}

export default RowsContainer
