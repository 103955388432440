import React, { FC, createRef, useEffect, useState } from 'react'
import { InputRef, Modal } from 'antd'
import TextArea from 'antd/es/input/TextArea'

import styles from './AnswerDialog.module.scss'

interface AnswerDialogProps {
	open: boolean
	initialAnswer?: string
	onOk?: (answer: string) => void
	onCancel?: () => void
	okButtonText?: string
	title: string
}

const AnswerDialog: FC<AnswerDialogProps> = (props) => {
	const {
		open,
		onOk: onStore,
		initialAnswer = '',
		onCancel: onClose,
		okButtonText,
		title,
	} = props
	const [answer, setAnswer] = useState<string>(initialAnswer)
	const [isSaveBtnActive, setIsSaveBtnActive] = useState<boolean>(false)
	const inputRef = createRef<InputRef>()

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus()
		}
	}, [inputRef])

	useEffect(() => {
		setAnswer(initialAnswer)
	}, [initialAnswer])

	const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const text = e.target.value

		setAnswer(text)
		if (text === '') {
			setIsSaveBtnActive(false)
		} else {
			setIsSaveBtnActive(true)
		}
	}

	const handleOnStoreAnswer = () => {
		if (onStore) {
			onStore(answer)
			setAnswer('')
			if (inputRef.current) {
				inputRef.current.focus()
			}
		}
	}

	return (
		<Modal
			open={open}
			onOk={handleOnStoreAnswer}
			onCancel={onClose}
			cancelText="Отмена"
			okText={okButtonText || 'Создать'}
			okButtonProps={{ disabled: !isSaveBtnActive }}
			width={550}
			centered
			title={title}
		>
			<div className={styles.Answer}>
				<TextArea
					ref={inputRef}
					className={styles.Answer__Input}
					placeholder="Оставьте ответ на комментарий"
					value={answer}
					onChange={handleInputChange}
				/>
			</div>
		</Modal>
	)
}

export default AnswerDialog
