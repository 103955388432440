// import { useQueryClient } from 'react-query'
import { AddLessonModalController } from './AddLessonModal/AddLessonModalController'
// import { trainingQuery } from 'parts/requests/training/trainingQuery'
import { useGetLessonsAdminRouter } from './useGetLessonsRouter'

export const LessonsListAdminWrapperController = {
	// Если требуются уроки курса, то передаётся идентификатор.
	// Если не передан, то будут загружены уроки школы
	router: (trainingId?: number) => useGetLessonsAdminRouter(trainingId),
	// Функция, помечающая данные курса неактуальными. После этого React Query скачает новый массив курсов
	/*useGetUpdate: function (trainingId: number | string) {
		const queryClient = useQueryClient()

		return function () {
			// Пометить данные курса неактуальными. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [trainingQuery.getTrainingForAdmin(trainingId).key],
			})
		}
	},*/
	// Открывает модальное окно добавления нового урока
	openAddLessonModal: AddLessonModalController.open,
}
