import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import AppUrls from 'parts/constants/pageUrl'
import AuthPage from '../../../../../common/AuthPage/AuthPage'
import ContentWrapper from '../../../../../common/ContentWrapper/ContentWrapper'
import ConfirmForm from '../ConfirmFormAfterReg/ConfirmForm'
import { useReturnToStartPage } from '../../common/returnToStartPage'

// Страница подтверждения почты
function ConfirmEmailPageAfterReg() {
	const { t } = useTranslation()

	// Перебросить на страницу с вводом данных регистрируемого пользователя если в Хранилище их нет.
	// Так может случиться если эту страницу открыли сразу, а не перешли из предыдущей.
	useReturnToStartPage()

	useEffect(() => {
		// Отправить событие в Google Analytics
		window.dataLayer.push({
			event: 'Lead',
			category: 'surveyData2',
			action: 'sent',
			label: 'Lead',
		})
	}, [])

	return (
		<AuthPage>
			<ContentWrapper
				backBthHref={AppUrls.Register_Owner_Start().url}
				header={t('auth.confirmEmailPageHeader')}
				headerDesc={t('auth.confirmEmailPageDescription')}
				bottomText={
					<>
						<Link to={AppUrls.Login().url} className="link">
							{t('auth.confirmEmailPageLoginLink')}
						</Link>
						{t('auth.confirmEmailPageIfHasAccount')}
					</>
				}
			>
				<ConfirmForm />
			</ContentWrapper>
		</AuthPage>
	)
}

export default ConfirmEmailPageAfterReg
