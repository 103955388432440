import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { ExerciseControlPanelButton } from '../../../common/ExerciseControlPanelContainer/ExerciseControlPanelContainer'
import { useGetDoneExercise } from '../../../common/doneExercise'
import { useGetGoToNextExercise } from '../../../common/prevAndNextExerciseUrls'
import { ExerciseStateType } from '../../VideoExerciseView/fn/stateType'
import { useGetAfterDoneExercise } from './afterDoneExercise'
import { useGetExerciseDuration } from '../../VideoExerciseView/fn/getExerciseDuration'

/**
 * Возвращает массив с настройками кнопок для передачи в компонент ExerciseControlPanelContainer
 * @param state — объект состояния упражнения.
 * @param setState — функция, обновляющая состояние упражнения
 */
export function useGetButtonsConfig(
	state: ExerciseStateType.Main,
	setState: Dispatch<SetStateAction<ExerciseStateType.Main>>
): ExerciseControlPanelButton[] {
	const { t } = useTranslation()

	const duration = useGetExerciseDuration()

	// Функция, выполняемая при нажатии на кнопку «Выполнено».
	const doneExercise = useGetDoneExercise(
		useGetAfterDoneExercise(state, setState),
		duration
	)

	// Функция открывающая следующее упражнение если упражнение существует
	// или undefined если не существует.
	const goToNextExercise = useGetGoToNextExercise()

	return [
		{
			text: t('exercises.controlBtnNextExercise'),
			active: !!goToNextExercise,
			onClick: goToNextExercise,
			dataCyAttr: 'next-exercise-btn',
		},
		{
			text: t('exercises.controlBtnDone'),
			active: state.doneAllow,
			primary: true,
			onClick: doneExercise,
			dataCyAttr: 'done-exercise-btn',
		},
	]
}
