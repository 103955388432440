import useGiveImpressionFormStore from '../zustand/store'

/** Возвращает функцию, открывающую модальное окно удаления школы */
export function useGetToggleVisibilityGiveImpressionModal() {
	const isModalOpen = useGiveImpressionFormStore((store) => store.isOpen)
	const updateModalState = useGiveImpressionFormStore(
		(store) => store.updateStore
	)

	return function () {
		updateModalState({ isOpen: !isModalOpen })
	}
}
