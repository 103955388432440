import EntityTypes from 'parts/types/EntityTypes'
import { getNumberAndNounStr, getRusNounByNumber } from 'parts/utils/string'
import LangService, { LangNames } from 'parts/i18n/LangService'

type PaymentStats = {
	// В ключ будет записывать название валюты
	[key: string]: {
		currency: string
		coursesNumber: number
		coursesSum: number
	}
}

export function getPaymentStats(payments: EntityTypes.PaymentOfUser[]) {
	// Получить объект с данными об оплатах по типу PaymentStats
	const paymentsData = getPaymentsData(payments)

	// Возвратить массив строк вида ['3 курса за 8169 RUB', '1 курс за 200 USD', ]
	return Object.values(paymentsData).map((payStats) => {
		return `${payStats.coursesNumber} ${gitCoursesWord(
			payStats.coursesNumber
		)} на ${payStats.coursesSum / 100} ${payStats.currency}`
	})
}

/**
 * Получает массив оплат и возвращает объект с данными сколько было куплено курсов и на какую сумму в определённых валютах
 * @param {Array} payments — массив с данными об оплатах
 */
export function getPaymentsData(
	payments: EntityTypes.PaymentOfUser[]
): PaymentStats {
	const paymentsData: PaymentStats = {}

	payments.forEach((payment) => {
		if (!paymentsData[payment.currency]) {
			paymentsData[payment.currency] = {
				currency: payment.currency,
				coursesNumber: 0,
				coursesSum: 0,
			}
		}

		paymentsData[payment.currency].coursesNumber += 1
		paymentsData[payment.currency].coursesSum += payment.price
	}, 0)

	return paymentsData
}

function gitCoursesWord(trainingsCount: number) {
	const lang = LangService.getCurrentLangFromLocalStorage()

	if (lang === LangNames.RU) {
		return getRusNounByNumber(trainingsCount, 'курс', 'курса', 'курсов')
	} else if (lang === LangNames.EN) {
		if (trainingsCount === 1) {
			return 'training'
		}

		return 'trainings'
	}
}
