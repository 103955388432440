import { UTableHCell, UTableHRow } from 'ui/UTable/UTable'

// Заголовочный ряд таблицы подписок
function TableHeader() {
	return (
		<UTableHRow>
			<UTableHCell>Дата и время платежа</UTableHCell>
			<UTableHCell>План</UTableHCell>
			<UTableHCell>Сумма оплаты</UTableHCell>
			<UTableHCell>Квитанция об оплате</UTableHCell>
		</UTableHRow>
	)
}

export default TableHeader
