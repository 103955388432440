import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import ModalWithForm from 'ui/ModalWithForm/ModalWithForm'
import AddGroupForm from '../AddGroupForm/AddGroupForm'
import { AddGroupModalController } from '../AddGroupModalController'
import useAddGroupModalStore from '../zustand/store'

type AddGroupModalProps = {
	// id курса, которому будет принадлежать создаваемая группа
	trainingId: number
	// Должна ли группа быть активной при создании?
	isActive?: boolean
	// Элемент, который будет находиться в модальном окне выше формы создания группы
	elemBeforeForm?: ReactNode
	// Требуется ли дать сигнал Реакт Квери перекачать данные курса?
	refreshTrainingAfterCreation?: boolean
}

// Модальное окно создания новой группы
function AddGroupModal(props: AddGroupModalProps) {
	const { t } = useTranslation()

	const {
		trainingId,
		isActive = false,
		elemBeforeForm = null,
		refreshTrainingAfterCreation = false,
	} = props

	const modalState = useAddGroupModalStore((state) => state)

	return (
		<ModalWithForm
			title="Добавление группы"
			open={modalState.isModalOpen}
			onCancel={AddGroupModalController.useGetClose}
		>
			{elemBeforeForm}
			<AddGroupForm
				trainingId={trainingId}
				isActive={isActive}
				refreshTrainingAfterCreation={refreshTrainingAfterCreation}
			/>
		</ModalWithForm>
	)
}

export default AddGroupModal
