import cn from 'classnames'
import EntityTypes from 'parts/types/EntityTypes'
import useLandingBuilderStore from '../../../zustand/store'
import './Theses.scss'

type ThesesProps = {
	tariff: EntityTypes.Tariff
}

function Theses(props: ThesesProps) {
	const { tariff } = props

	return (
		<div className="p-tariff-card-theses">
			{tariff.theses.map((thesis, i) => {
				return thesis.text ? (
					<Thesis tariff={tariff} thesis={thesis} key={i} />
				) : null
			})}
		</div>
	)
}

export default Theses

type ThesisProps = {
	tariff: EntityTypes.Tariff
	thesis: EntityTypes.TariffThesis
}

function Thesis(props: ThesisProps) {
	const { tariff, thesis } = props

	const colorScheme = useLandingBuilderStore(
		(store) => store.landing.colorScheme
	)

	const prefix = tariff.isFlashed ? 'flashed' : 'default'
	const thesisTextCls = [
		'p-tariff-card-theses__thesis-text',
		'p-tariff-card-theses__thesis-text--' + prefix + '--' + colorScheme,
	]
	if (thesis.crossedOut) {
		thesisTextCls.push('p-tariff-card-theses__thesis-text--cross-out')
	}

	return (
		<div className="p-tariff-card-theses__thesis">
			<p className={cn(thesisTextCls)}>{thesis.text}</p>
		</div>
	)
}
