import { ReactNode } from 'react'
import { Button } from 'antd'
import ControlsFrame from 'pages/landing/landingConstructor/details/ControlsFrame/ControlsFrame'
import { LandingTypes } from 'parts/types/LandingTypes'
import EditableText from 'pages/landing/landingConstructor/details/editableElems/EditableText/EditableText'
import faqBlocksManager from 'pages/landing/landingConstructor/sections/faqSection/faqBlocksManager/faqBlocksManager'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import 'pages/landing/landingConstructor/sections/faqSection/FaqBlock/FaqBlock.scss'

type BlockOuterProps = {
	block: LandingTypes.FaqBlock
	blockIdx: number
	children: ReactNode
}

export function BlockOuter(props: BlockOuterProps) {
	const { block, children } = props

	const addNewBlockInTop = faqBlocksManager.useGetAddNewBlock(block.id, 'top')
	const addNewBlockInBottom = faqBlocksManager.useGetAddNewBlock(
		block.id,
		'bottom'
	)
	const deleteBlock = faqBlocksManager.useGetDeleteBlock(block.id)

	return (
		<ControlsFrame
			topAddBtnHandler={addNewBlockInTop}
			bottomAddBtnHandler={addNewBlockInBottom}
			deleteBtnHandler={deleteBlock}
		>
			{children}
		</ControlsFrame>
	)
}

type BlockProps = {
	blockIdx: number
	block: LandingTypes.FaqBlock
}

export function Block(props: BlockProps) {
	const { blockIdx, block } = props

	const toggleVisibility = faqBlocksManager.toggleBlockVisibility(block.id)

	return (
		<article className="land-const-faq-block">
			<div className="land-const-faq-block__text-part">
				<h3 className="land-const-faq-block__question">
					<EditableText
						propsPathArr={[
							'sections',
							'faq',
							'faqBlocks',
							blockIdx,
							'question',
						]}
						placeholder="Заголовок"
					/>
				</h3>
				{block.isOpen && (
					<p className="land-const-faq-block__answer">
						<EditableText
							propsPathArr={[
								'sections',
								'faq',
								'faqBlocks',
								blockIdx,
								'answer',
							]}
						/>
					</p>
				)}
			</div>

			<div className="land-const-faq-block__right-part">
				<Button
					icon={
						block.isOpen ? (
							<MinusCircleOutlined />
						) : (
							<PlusCircleOutlined />
						)
					}
					onClick={toggleVisibility}
				/>
			</div>
		</article>
	)
}
