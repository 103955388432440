import { Button, Tag } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import Avatar from 'ui/Avatar/Avatar'
import AppUrls from 'parts/constants/pageUrl'
import { UTableBCell, UTableBRow } from 'ui/UTable/UTable'
import { useGetDeleteUser } from './fn/deleteUser'
import { StudentsStoreType } from '../zustand/storeTypes'
import { useGetUserRole } from 'parts/utils/hooks/hooks'

type TableRowProps = {
	student: StudentsStoreType.Student
}

// Ряд таблицы учеников
function TableRow(props: TableRowProps) {
	const { student } = props

	const userRoles = useGetUserRole()
	const userPageUrl = userRoles.isAdminOrManager
		? AppUrls.Student_Orders(student.id).url
		: undefined

	return (
		<UTableBRow bgOnHover={userRoles.isAdminOrManager}>
			<UTableBCell href={userPageUrl} key="fullName">
				<div className="students-table__name-wrapper">
					<Avatar src={student.avatar} width={24} />
					<p className="students-table__text">{student.fullName}</p>
				</div>
			</UTableBCell>
			<UTableBCell href={userPageUrl} key="email">
				<p className="students-table__text">{student.email}</p>
			</UTableBCell>
			<UTableBCell href={userPageUrl} key="phone">
				<p className="students-table__text">{student.phone}</p>
			</UTableBCell>
			<UTableBCell href={userPageUrl} key="country">
				<p className="students-table__text">{student.country}</p>
			</UTableBCell>
			<UTableBCell href={userPageUrl} key="activity">
				<p className="students-table__text">
					<StudentStatus student={student} />
				</p>
			</UTableBCell>
			{userRoles.isAdmin && (
				<UTableBCell href={userPageUrl} key="delete">
					<DeleteButton student={student} />
				</UTableBCell>
			)}
		</UTableBRow>
	)
}

export default TableRow

type StudentStatusProps = {
	student: StudentsStoreType.Student
}

function StudentStatus(props: StudentStatusProps) {
	const { student } = props
	const { t } = useTranslation()

	return student.isActivated ? (
		<Tag color="blue">{t('students.studentsTableStudentActivated')}</Tag>
	) : (
		<Tag color="red">{t('students.studentsTableStudentNotActivated')}</Tag>
	)
}

type DeleteButtonProps = {
	student: StudentsStoreType.Student
}

// Кнопка удаления ученика
function DeleteButton(props: DeleteButtonProps) {
	const { student } = props

	const deleteUser = useGetDeleteUser(student.id)

	return (
		<Button
			icon={<DeleteOutlined />}
			type="text"
			danger
			onClick={deleteUser}
			size="small"
		/>
	)
}
