const filesRu = {
	// СТРАНИЦА ФАЙЛОВ ЭФИРА
	// Заголовок страницы
	pageHeader: 'Файлы для лайв уроков',

	// ТАБЛИЦА ФАЙЛОВ К ЭФИРАМ
	// Текст «Файлы к занятиям»
	filesTableExercisesFiles: 'Файлы к занятиям',
	// Кнопка «Новая папка»
	filesTableNewFolderBtn: 'Новая папка',
	// Кнопка «Загрузить файл»
	filesTableUploadFileBtn: 'Загрузить файл',
	// Заголовок всплывашки подтверждения удаления файла
	filesTableDeletePopupHeader: 'Удалить',
	// Кнопка во всплывашке подтверждения удаления файла
	filesTableDeletePopupBtn: 'Удалить',
	// Кнопка «Добавить в класс»
	filesTableAddToClassBtn: 'Добавить в класс',
	// Кнопка «Добавить в класс»
	filesTableSendToChatBtn: 'Отправить в чат',
	// Кнопка «Добавить в класс»
	filesTableDownloadBtn: 'Загрузить',
	// Заголовок модального окна загрузки файла
	filesTableUploadFileModalHeader: 'Загрузить файл',
	// Кнопка отмены модального окна загрузки файла
	filesTableUploadFileModalCancelBtn: 'Отмена',
	// Текст модального окна загрузки файла
	filesTableUploadFileModalText:
		'Нажмите или перетащите сюда файл, чтобы его загрузить',
}

export default filesRu
