import { message } from 'antd'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'

/** Возвращает обработчик нажатия на кнопку удаления тарифа */
export function useGetDeleteTariff() {
	const tariff = tariffsManager.useGetActiveTariff()

	return function () {
		if (!tariff) return

		tariffsManager.deleteTariffAndSetToStore(tariff.innerId)
		tariffsManager.updateStore({ activeTariffId: null })

		// Сохранить на сервере
		tariffsManager.setNeedToSave()
		message.success('Тариф удалён')
	}
}
