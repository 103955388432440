import AppUrls from 'parts/constants/pageUrl'
import { ReactNode, useEffect, useState } from 'react'
import { PageHeaderTab } from 'ui/pageHeader/Tabs/Tabs'
import useStudentStore from '../../zustand/store'
import {
	InstagramOutlined,
	MailOutlined,
	PhoneOutlined,
} from '@ant-design/icons'
import { HeaderRouteType } from 'ui/pageHeader/PageHeader/fn/types'
import MetaOutputText from 'ui/MetaOutputText/MetaOutputText'

export function getBreadcrumbs(): HeaderRouteType[] {
	return [
		{
			path: AppUrls.Students().url,
			breadcrumbName: AppUrls.Students().name,
		},
	]
}

/** Возвращает данные для формирования вкладок */
export function useGetTabsData() {
	const student = useStudentStore((store) => store.student)
	const [tabsData, setTabsData] = useState<PageHeaderTab[]>([])

	useEffect(
		function () {
			if (!student) return

			setTabsData([
				{
					label: AppUrls.Student_Orders(student.id).name,
					address: AppUrls.Student_Orders(student.id).url,
				},
				{
					label: AppUrls.Student_Visits(student.id).name,
					address: AppUrls.Student_Visits(student.id).url,
				},
				{
					label: AppUrls.Student_History(student.id).name,
					address: AppUrls.Student_History(student.id).url,
				},
			])
		},
		[student]
	)

	return tabsData
}

/** Возвращает объект настройки компонента с серой текстом с деталями группы */
export function useGetMetaPanelConfig(): ReactNode[] {
	const student = useStudentStore((state) => state.student)

	if (!student) return []

	return [
		<MetaOutputText
			icon={MailOutlined}
			textConfigs={[
				{
					text: student.email,
				},
			]}
		/>,
		<MetaOutputText
			icon={PhoneOutlined}
			textConfigs={[
				{
					text: student.phone,
				},
			]}
		/>,
		<MetaOutputText
			icon={InstagramOutlined}
			textConfigs={[
				{
					text: student.instagram,
				},
			]}
		/>,
	]
}
