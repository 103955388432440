import { useQueryClient } from 'react-query'
import { lessonsQuery } from 'parts/requests/lessons/lessonsQuery'
import useGetPresetsListRouter from './fn/useGetPresetsListRouter'

export const HorizontalPresetsListController = {
	router: useGetPresetsListRouter,
	// Функция, помечающая данные шаблонов неактуальными. После этого React Query скачает новые данные шаблонов.
	useGetUpdate: function () {
		const queryClient = useQueryClient()

		return function () {
			// Пометить данные шаблонов неактуальными. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [lessonsQuery.getPresets().key],
			})
		}
	},
}
