import {
	ExerciseChangesDataMadeByUser,
	SaveExerciseChangesMadeByUser,
} from '../../common/useLiftViewDuration'
import ExerciseControlPanel from '../ExerciseControlPanel/ExerciseControlPanel'
import { useFillStore } from '../zustand/fn/fillStore'
import Exercise from './Exercise'
import Result from '../../common/Result/Result'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import ExerciseContainer, {
	ExerciseContainerType,
} from '../../common/ExerciseContainer/ExerciseContainer'
import useExerciseStore from '../zustand/exerciseState'
import { useGetPassExerciseAgainFn } from './fn/result'
import { useRestoreExercise } from './fn/saveAndRestoreExercise'
import { useSetAttemptsDuration } from './fn/setAttemptsDuration'
import './GapsInWordsExerciseView.scss'

type GapsInWordsExerciseViewProps = {
	// Для какого типа страницы предназначается обёртка упражнения:
	// constructorPage — страница конструктора. Будут скрыты кнопки перехода по упражнениям и кнопки снизу
	// exercisePage — страница упражнения для ученика. Будет полный набор элементов.
	exerciseContainerType: ExerciseContainerType
	exercise: TrainingEntityTypes.GapsInWordsExercise
	// Адрес предыдущего и следующего упражнения
	prevExerciseUrl?: string
	nextExerciseUrl?: string
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

// Упражнение по выбору правильного слова (обвес + упражнение + результат)
function GapsInWordsExerciseViewNew(props: GapsInWordsExerciseViewProps) {
	const {
		exerciseContainerType,
		exercise,
		prevExerciseUrl,
		nextExerciseUrl,
		saveExerciseChangesMadeByUser,
		exerciseRestoreData,
	} = props

	const stage = useExerciseStore((store) => store.stage)
	const totalSlots = useExerciseStore((store) => store.totalSlots)
	const statistics = useExerciseStore((store) => store.statistics)
	const attempts = useExerciseStore((store) => store.attempts)

	useFillStore(exercise)

	// Функция запускающая прохождение упражнения заново
	const passExerciseAgainFn = useGetPassExerciseAgainFn()

	useSetAttemptsDuration()
	const durationsInSeconds = useExerciseStore(
		(store) => store.durationsInSeconds
	)

	// Восстановить данные упражнения при открытии
	useRestoreExercise(exerciseRestoreData)

	return (
		<ExerciseContainer
			type={exerciseContainerType}
			prevExerciseUrl={prevExerciseUrl}
			nextExerciseUrl={nextExerciseUrl}
		>
			{stage === 'Result' ? (
				<Result
					seconds={durationsInSeconds[durationsInSeconds.length - 1]}
					totalQuestions={totalSlots}
					stats={statistics}
					passAgainFn={attempts ? undefined : passExerciseAgainFn}
				/>
			) : (
				<Exercise
					exercise={exercise}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
					exerciseRestoreData={exerciseRestoreData}
				/>
			)}
			<ExerciseControlPanel />
		</ExerciseContainer>
	)
}

export default GapsInWordsExerciseViewNew
