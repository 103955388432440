import { message } from 'antd'
import { AxiosError, AxiosResponse } from 'axios'
import paymentsRequests from 'parts/requests/payments/paymentsRequests'
import ServerTypes from 'parts/types/ServerTypes'
import { FieldsNames, FormValues } from './form'
import EntityTypes from 'parts/types/EntityTypes'
import PaymentsApiTypes from 'parts/requests/payments/paymentsApiTypes'
import AppUrls from 'parts/constants/pageUrl'
import usePayModalStore from '../../zustand/store'

/** Возвращает обработчик отправки формы покупки курса */
export function useGetOnSubmit() {
	const tariff = usePayModalStore((state) => state.tariff)
	const price = usePayModalStore((state) => state.price)

	return async (values: FormValues) => {
		if (!tariff || !tariff.id || !price) return

		if (!values[FieldsNames.Agree]) return

		const payDto = getDto(values, tariff, price)

		try {
			const response = await paymentsRequests.pay(payDto)
			goToSuccessPage(response)
		} catch (err) {
			const error = err as AxiosError<ServerTypes.ErrorResponse>

			if (error.response) {
				const respMessage = error.response.data.message

				message.error(
					respMessage ||
						'Произошла неизвестная ошибка при попытке оплаты'
				)
			}
		}
	}
}

/**
 * Формирует DTO для отправки запроса
 * @param {Array} values — массив значений полей формы
 * @param tariff — данные тарифа
 * @param price — данные цены
 */
function getDto(
	values: FormValues,
	tariff: EntityTypes.Tariff,
	price: EntityTypes.TariffPrice
): PaymentsApiTypes.PayDto {
	const { origin } = window.location

	return {
		paymentSystem: price.paymentSystem,
		tariffId: tariff.id!,
		customerEmail: values[FieldsNames.Email],
		customerPhone: values[FieldsNames.Phone],
		successUrl: `${origin}${AppUrls.Success().url}`,
		cancelUrl: `${origin}${AppUrls.Fail().url}`,
	}
}

/**
 * После удачной оплаты отправляет на страницу успеха
 * @param response — данные отправляемые серверу на спешную оплату
 */
function goToSuccessPage(response: AxiosResponse<PaymentsApiTypes.Pay>) {
	if (window.parent.length > 0) {
		window.parent.location.replace(response.data.url)
	} else {
		window.location.replace(response.data.url)
	}
}
