import { useGetLiveFormRouter } from './useGetLiveFormRouter'
import { useQueryClient } from 'react-query'
import { userQuery } from 'parts/requests/user/userQuery'
import EntityTypes from 'parts/types/EntityTypes'

export const LivelessonFormController = {
	router: useGetLiveFormRouter,
	// Функция, помечающая данные массива неактуальными.
	// После этого React Query скачает новый массив
	useGetUpdate: function () {
		const queryClient = useQueryClient()

		return function () {
			// Пометить список неактуальным. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [
					userQuery.getUserByRole(EntityTypes.UserRole.ADMIN).key,
				],
			})
		}
	},
}
