import React, { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalLayout } from 'ui/ModalLayout/ModalLayout'
import { onChangeTaskInput } from './fn/changeForms'
import { TrueOrFalseExerciseFormStateType } from './fn/stateType'
import { Statements } from './Statements'
import ExerciseFormFooter from '../common/ExerciseFormFooter/ExerciseFormFooter'
import HtmlEditor from 'ui/HTMLEditor/HTMLEditor'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { isDownloadingFile } from './fn/check'
import { submitHandler } from './fn/submit'
import { useSetFormData } from './fn/state'
import useExercisesListAdminStore from '../../zustand/store'
import './TrueOrFalseForm.scss'

type TrueOrFalseFormProps = {
	saveExercise: (args: TrainingEntityTypes.ExerciseItem) => void // Функция сохраняющая данные упражнения на сервере и в Хранилище
	closeModalFn: MouseEventHandler<HTMLElement> // Функция закрытия модального окна
}

// Форма составления упражнения утверждений где нужно отметить это правда или ложь
function TrueOrFalseForm(props: TrueOrFalseFormProps) {
	const { saveExercise, closeModalFn } = props

	const { t } = useTranslation()

	// Получение данных формы
	useSetFormData()

	const formState = useExercisesListAdminStore(
		(store) => store.formData
	) as TrueOrFalseExerciseFormStateType
	if (!formState) return null

	return (
		<ModalLayout
			key="1"
			title="Выбор лжи или истины"
			cancelBtn={false}
			isModalVisible
			closeModalFn={closeModalFn}
			footer={null}
		>
			<TaskInput formState={formState} />
			<Statements />
			<ExerciseFormFooter
				formState={formState}
				saveExercise={saveExercise}
				closeModalFn={closeModalFn}
				isSubmitButtonDisabled={
					isDownloadingFile() || formState.isFormInvalid
				}
				submitHandler={() => submitHandler(saveExercise)}
			/>
		</ModalLayout>
	)
}

export default TrueOrFalseForm

type TaskInputProps = {
	formState: TrueOrFalseExerciseFormStateType
}

// Поле с заданием
function TaskInput(props: TaskInputProps) {
	const { formState } = props

	const { t } = useTranslation()

	return (
		<>
			<label className="exercise-true-false__label">Задание</label>
			<HtmlEditor
				initialValue={formState.taskInput.value}
				onChangeValue={onChangeTaskInput}
			/>
		</>
	)
}
