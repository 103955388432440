/**
 * Функция возвращает массив пропущенных слов
 * @param {String} htmlString — строка с HTML из которой нужно создать разметку с пропусками
 */
export function getGapWords(htmlString: string) {
	const gapWords: string[] = []

	let collectLetters = false
	let gapWord = ''

	for (let i = 0; i < htmlString.length; i++) {
		const letter = htmlString[i]

		if (letter == '{') {
			collectLetters = true
			continue
		} else if (letter == '}') {
			gapWords.push(gapWord)
			collectLetters = false
			gapWord = ''
		}

		if (collectLetters) {
			gapWord += letter
		}
	}

	return gapWords
}
