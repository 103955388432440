import React, { memo } from 'react'
import { Button } from 'antd'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { generateRightAnswerText, useGetCurrentStatement } from './fn/main'
import ExerciseStateType from './fn/ExerciseStateType'
import {
	getStatementCounter,
	getResultButtonText,
	useGetOnTrueFalseButtonClick,
	useGetOnNextButtonClick,
} from './fn/bottom'
import { TaskExerciseView } from '../common/TaskExerciseView/TaskExerciseView'

type ExerciseProps = {
	exerciseState: ExerciseStateType.Main
	setExerciseState: React.Dispatch<
		React.SetStateAction<ExerciseStateType.Main>
	>
}

// Упражнение
function Exercise(props: ExerciseProps) {
	const { exerciseState, setExerciseState } = props

	return (
		<div className="exercise-true-false-content">
			{exerciseState.exerciseTask && (
				<TaskExerciseView htmlString={exerciseState.exerciseTask} />
			)}
			<Statement exerciseState={exerciseState} />
			<Bottom
				exerciseState={exerciseState}
				setExerciseState={setExerciseState}
			/>
		</div>
	)
}

export default Exercise

type StatementProps = {
	exerciseState: ExerciseStateType.Main
}

function Statement(props: StatementProps) {
	const { exerciseState } = props

	const statement = useGetCurrentStatement(exerciseState)
	if (!statement) return null

	const textClasses = ['exercise-true-false-content__statement-text']
	if (statement.text.length < 120) {
		textClasses.push('exercise-true-false-content__statement-text--big')
	}

	return (
		<div className="exercise-true-false-content__statement">
			<p className={cn(textClasses)}>{statement.text}</p>
			<Image statement={statement} />
			<MemoAnswer statement={statement} />
		</div>
	)
}

type ImageProps = {
	statement: ExerciseStateType.Statement
}

function Image(props: ImageProps) {
	const { statement } = props

	return statement.image ? (
		<img
			className="exercise-true-false-content__statement-image"
			src={statement.image}
			alt=""
		/>
	) : null
}

type AnswerProps = {
	statement: ExerciseStateType.Statement
}

function Answer(props: AnswerProps) {
	const { statement } = props

	const { t } = useTranslation()

	if (!statement.userAnswer) {
		return null
	}

	if (
		(statement.userAnswer == 'yes' && statement.correct) ||
		(statement.userAnswer == 'not' && !statement.correct)
	) {
		return (
			<p className="exercise-true-false-content__right-statement">
				{generateRightAnswerText()}
			</p>
		)
	} else {
		return (
			<p className="exercise-true-false-content__wrong-statement">
				Ой! Похоже, это неверный ответ
			</p>
		)
	}
}

const MemoAnswer = memo(Answer)

type BottomProps = {
	exerciseState: ExerciseStateType.Main
	setExerciseState: React.Dispatch<
		React.SetStateAction<ExerciseStateType.Main>
	>
}

function Bottom(props: BottomProps) {
	const { exerciseState, setExerciseState } = props

	const { t } = useTranslation()

	const statement = useGetCurrentStatement(exerciseState)

	const counter = getStatementCounter(exerciseState)
	const onFalseButtonClick = useGetOnTrueFalseButtonClick(
		exerciseState,
		setExerciseState,
		statement?.id,
		'false'
	)
	const onTrueButtonClick = useGetOnTrueFalseButtonClick(
		exerciseState,
		setExerciseState,
		statement?.id,
		'true'
	)

	if (!statement) return null

	return (
		<div className="exercise-true-false-content__bottom">
			<span className="exercise-true-false-content__bottom-counter">
				{counter}
			</span>
			<div className="exercise-true-false-content__bottom-center">
				<Button
					danger
					onClick={onFalseButtonClick}
					disabled={!!statement.userAnswer}
				>
					Ложь
				</Button>
				<Button
					className="exercise-true-false-content__bottom-true-button"
					onClick={onTrueButtonClick}
					disabled={!!statement.userAnswer}
				>
					Правда
				</Button>
			</div>
			<NextButton
				exerciseState={exerciseState}
				setExerciseState={setExerciseState}
			/>
		</div>
	)
}

type ResultButtonProps = {
	exerciseState: ExerciseStateType.Main
	setExerciseState: React.Dispatch<
		React.SetStateAction<ExerciseStateType.Main>
	>
}

function NextButton(props: ResultButtonProps) {
	const { exerciseState, setExerciseState } = props

	const statement = useGetCurrentStatement(exerciseState)
	if (!statement) return null

	const onButtonClick = useGetOnNextButtonClick(
		exerciseState,
		setExerciseState
	)
	const buttonText = getResultButtonText(exerciseState)

	return (
		<Button
			type="primary"
			onClick={onButtonClick}
			disabled={!statement.userAnswer}
		>
			{buttonText}
		</Button>
	)
}
