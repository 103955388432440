import { useParams } from 'react-router-dom'
import { HeaderRouteType } from 'ui/pageHeader/PageHeader/fn/types'
import AppUrls from 'parts/constants/pageUrl'
import { useEffect, useState } from 'react'
import TrainingStudentService from 'parts/services/TrainingStudentService'
import { RouterVarNames } from '../../../../../app/routes/special/otherRoutes'

export const trainingsBreadcrumbs: HeaderRouteType = {
	path: AppUrls.Trainings().url,
	breadcrumbName: AppUrls.Trainings().name,
}

/** Возвращает массив для формирования хлебных крошек выше заголовка страницы урока */
export function useGetBreadcrumbs() {
	const params = useParams()
	const trainingId = params[RouterVarNames.TrainingId]!

	const { data } = TrainingStudentService.useGetTraining()

	const [routes, setRoutes] = useState<HeaderRouteType[]>([
		trainingsBreadcrumbs,
	])

	useEffect(
		function () {
			if (!data) return

			const routes: HeaderRouteType[] = [
				{
					path: AppUrls.Trainings().url,
					breadcrumbName: AppUrls.Trainings().name,
				},
				{
					path: AppUrls.Training(trainingId).url,
					breadcrumbName: data.data.trainingName,
				},
			]

			setRoutes(routes)
		},
		[data]
	)

	return routes
}
