import useAddStaffInGroupModalStore from '../zustand/store'
import { AddStaffModalStoreType } from '../zustand/storeTypes'

/**
 * Функция помечает выбранного сотрудника как добавляемого к текущей группе.
 * @param curatorId — id добавляемого сотрудника
 */
export function useGetOnEmployeeClick(curatorId: number) {
	const [stuffOutsideOfGroup, updateStore] = useAddStaffInGroupModalStore(
		(state) => [state.staff, state.updateStore]
	)

	return function () {
		if (!stuffOutsideOfGroup) return

		const updatedStuff = toggleAddToGroupCheckedInEmployee(
			stuffOutsideOfGroup,
			curatorId
		)

		updateStore({ staff: updatedStuff })
	}
}

/**
 * Проходит по всему массиву кураторов
 * и переворачивает значение свойства addToGroupChecked у куратора с переданным id.
 * @param stuff — массив сотрудников
 * @param curatorId — id куратора, у которого нужно перевернуть значение свойства addToGroupChecked
 */
function toggleAddToGroupCheckedInEmployee(
	stuff: AddStaffModalStoreType.Employee[],
	curatorId: number
): AddStaffModalStoreType.Employee[] {
	return stuff.map((employee) => {
		if (employee.id == curatorId) {
			return Object.assign(employee, {
				addToGroupChecked: !employee.addToGroupChecked,
			})
		}

		return employee
	})
}
