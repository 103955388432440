export namespace ExerciseStateType {
	export type Main = {
		// Разрешено ли нажимать на кнопку «Выполнено».
		// Если при загрузке упражнения оно отмечено выполненным, то стоит false.
		doneAllow: boolean
	}
}

export const initialState: ExerciseStateType.Main = {
	doneAllow: true,
}
