import { produce } from 'immer'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'

/**
 * Возвращает функцию, удаляющую существующий блок
 * @param blockIdx — id блока, где содержится удаляемый пункт
 * @param bulletId — id удаляемого пункта
 */
export function useGetDeleteBullet(blockIdx: number, bulletId: number) {
	const [landing, updateStore] = useLandingConstructorStore((s) => [
		s.landing,
		s.updateStore,
	])

	return function () {
		if (!landing) return

		const updatedLanding = produce(landing, (draft) => {
			draft.sections.program.bulletsBlocks[blockIdx].bullets =
				draft.sections.program.bulletsBlocks[blockIdx].bullets.filter(
					(block) => block.id !== bulletId
				)
		})

		updateStore({ landing: updatedLanding })
	}
}
