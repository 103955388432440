import cn from 'classnames'
import { LandingTypes } from 'parts/types/LandingTypes'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'

/**
 * Возвращает текст, которые должен быть на кнопке переключения темы
 * @param block — блок с описанием пользователя, которому подойдёт курс
 */
export function getToggleThemeBtnText(block: LandingTypes.PortraitBlock) {
	return block.colorTheme == 'white' ? 'Сделать цветным' : 'Сделать белым'
}

/**
 * Возвращает строку с классами, которые должны быть на кнопке переключения темы
 * @param block — блок с описанием пользователя, которому подойдёт курс
 */
export function useGetToggleThemeBtnClasses(
	block: LandingTypes.PortraitBlock
): string {
	const landing = useLandingConstructorStore((store) => store.landing)
	const { colorScheme } = landing

	const btnClasses = ['land-const-portrait-section__change-theme-btn']

	if (block.colorTheme == 'white') {
		btnClasses.push(
			'land-const-portrait-section__change-theme-btn--' + colorScheme
		)
	}

	return cn(btnClasses)
}
