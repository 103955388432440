import { produce } from 'immer'
import { RcFile } from 'antd/lib/upload/interface'
import useExercisesListAdminStore from '../../../zustand/store'
import {
	DescriptionImgExerciseFormStateType,
	FormMatchStateItemType,
	getFormMatchStateItem,
} from './formState'
import downloadFileToAmazon from 'parts/services/downloadFileToAmazon'
import UploadFileTypes from 'parts/constants/uploadTypes'
import { getFormState, setFormState } from '../../common/exerciseFormCommonFunc'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { getMatchBlockById } from './check'
import { changeDownloadFileState } from './changeForm'

/**
 * Функция удаляет ответ в объекте соответствия
 * @param {Number} matchId — id объекта соответствия
 */
export function removeMatchBlock(matchId: number) {
	const formState = getFormState<DescriptionImgExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const questionObj = getMatchBlockById(draft, matchId)
		if (!questionObj) return draft

		draft.matches = draft.matches.filter((matchBlock) => {
			return matchBlock.id !== matchId
		})
	})

	setFormState(newState)
}

/** Обработчик кнопки создания нового блока соответствия */
export function addNewMatchBlock() {
	const formState = getFormState<DescriptionImgExerciseFormStateType>()

	let maxMatchId = 0

	formState.matches.forEach((matchBlock) => {
		if (matchBlock.id > maxMatchId) {
			maxMatchId = matchBlock.id
		}
	})

	const newMatch = getFormMatchStateItem(++maxMatchId)

	const newState = produce(formState, (draft) => {
		draft.matches.push(newMatch)
	})

	setFormState(newState)
}

/**
 * Функция загружает файл, получает его адрес на сервере и все данные записывает в местное Хранилище
 * @param {Object} file — данные о загружаемом файле
 * @param {Array} FileList
 * @param {Number} questionId — id объекта вопроса
 */
export async function beforeUploadFile(
	file: RcFile,
	FileList: RcFile[],
	questionId: number
) {
	await downloadFileToAmazon(
		file,
		FileList,
		UploadFileTypes.EXERCISE_IMAGES,
		{
			beforeDownloading(fileName: string) {
				// Поставить в состояние аудио-блока статус загрузки файла
				changeDownloadFileState(questionId, {
					status: 'downloading',
					progress: 0,
					fileName,
				})
			},
			whileDownloading(
				percentCompleted,
				cancelDownloading,
				fileUrl: string
			) {
				// Если статус загружаемого файла не равен 'downloading', то есть его изменили, то прекратить загрузку
				const questionBlock = getMatchBlockById(
					getFormState<DescriptionImgExerciseFormStateType>(),
					questionId
				)

				if (
					questionBlock &&
					questionBlock.image.status !== 'downloading'
				) {
					cancelDownloading()
				}

				// Поставить значение загруженных процентов в Состояние
				changeDownloadFileState(questionId, {
					progress: percentCompleted,
					link: fileUrl,
				})
			},
			onSuccess(fileName: string, url: string) {
				changeDownloadFileState(questionId, {
					link: url,
					status: 'success',
					fileName,
				})

				const formState =
					getFormState<DescriptionImgExerciseFormStateType>()

				const newState = produce(formState, (draft) => {
					const matchBlock = getMatchBlockById(draft, questionId)
					if (!matchBlock) return draft

					matchBlock.noImageError = false
					draft.isFormInvalid = false
				})

				setFormState(newState)
			},
			onError(fileName: string) {
				changeDownloadFileState(questionId, {
					status: 'error',
					fileName,
				})
			},
		}
	)
}

/**
 * Функция ставит статус загружаемого файла в значение empty.
 * Это действие дополнительно выполняется после прекращения загрузки файла.
 * @param {Number} questionId — id объекта вопроса
 */
export function setFileDownloadedStatusToDefault(questionId: number) {
	changeDownloadFileState(questionId, { status: 'empty' })
}

/**
 * Обработчик нажатия на кнопку удаления загруженного файла
 * @param {Object} questionData — id блока c данными вопроса
 */
export function removeDownloadedFile(questionData: FormMatchStateItemType) {
	const formState = getFormState<DescriptionImgExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const question = getMatchBlockById(draft, questionData.id)
		if (!question) return draft

		question.image.link = ''
		question.image.status = 'empty'
		question.image.progress = 0
		question.image.fileName = ''
	})

	setFormState(newState)
}

/**
 * Обработчик отправки формы
 * @param {Function} saveExercise — функция сохраняющая упражнение на сервере и в Состоянии
 */
export function submitHandler(
	saveExercise: (args: TrainingEntityTypes.ExerciseItem) => void
) {
	const isFormValid = validateForm()

	if (!isFormValid) {
		return
	}

	const formState = getFormState<DescriptionImgExerciseFormStateType>()

	const exerciseData = convertFormStateDataToExerciseData(formState)
	saveExercise(exerciseData)
}

/** Функция проверяет правильность заполнения формы, изменяет Состояние для показа ошибок и возвращает булево значение является ли форма правильной */
function validateForm() {
	// Есть ли в форме какое-то незаполненное поле с описанием картинки
	let formHasNoTextError = false
	// Есть ли в форме незагруженная картинка
	let formHasBrokenImageLink = false

	const formState = getFormState<DescriptionImgExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		// Проверка того, что к каждому ответу написано описание и загруженая картинка
		draft.matches.forEach((match) => {
			if (!match.text) {
				match.noTextError = true
				formHasNoTextError = true
			}

			if (match.image.status !== 'success') {
				match.noImageError = true
				formHasBrokenImageLink = true
			}
		})

		if (formHasNoTextError || formHasBrokenImageLink) {
			draft.isFormInvalid = true
		}
	})

	setFormState(newState)

	return !newState.isFormInvalid
}

/**
 * Функция переводит данные формы редактирования упражнения в данные упражнения для сохранения на сервере.
 * @param {Object} formState — Объект с данными формы
 */
function convertFormStateDataToExerciseData(
	formState: DescriptionImgExerciseFormStateType
) {
	const lessonId = useExercisesListAdminStore.getState().lessonId

	let exerciseData: TrainingEntityTypes.DescriptionImgExercise = {
		type: 'descriptionImg',
		lessonId,
		order: formState.order,
		item: {
			task: formState.taskInput.value,
			images: formState.matches.map((match, i) => {
				return {
					image: match.image.link,
					description: match.text,
				}
			}),
			wrong: formState.wrong,
		},
		status: 0, // Чтобы TS не ругался
		statusUpdated: '', // Чтобы TS не ругался
	}

	if (formState.exerciseId) {
		exerciseData.id = formState.exerciseId
	}

	return exerciseData
}

/**
 * Функция устанавливает новый массив неправильных ответов в объект текста
 * @param {Array} newWrongWords — массив неправильных слов
 */
export function setWrongWords(newWrongWords: string[]) {
	const formState = getFormState<DescriptionImgExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		draft.wrong = newWrongWords
	})

	setFormState(newState)
}
