import useExerciseStore from '../../zustand/exerciseState'
import { useGetClearHtmlBlocks } from '../../zustand/fn/modifyStore'

/**
 * Убирает изменения, сделанные пользователем
 */
export function useGetOneMoreTime() {
	const clearHtmlBlocks = useGetClearHtmlBlocks()

	const saveStoreForRestore = useExerciseStore(
		(store) => store.saveStoreForRestore
	)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		// Отправить на состояние, где упражнение начато сначала
		updateStore({ stage: 'NotReadyToCheck' })

		updateStore({ saveStoreForRestore: saveStoreForRestore + 1 })

		clearHtmlBlocks()
	}
}
