import React from 'react'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useGetState } from './fn/getState'
import { StateContext } from './fn/stateType'
import Content from './Content'
import Header from './Header'
import './Generator.scss'

type GeneratorProps = {
	// Тип упражнения
	exerciseType: TrainingEntityTypes.ExerciseTypesWithGenerator
	// Изначальный текст задания на генерирование текста
	initialPrompt: string
	// Функция получающая сгенерированный текст упражнения,
	// чтобы разобрать его и поставить в Состояние самого упражнения для отрисовки
	getGeneratedText: (text: string) => void
	// Функция получающая текст на генерирование текста упражнения для сохранения в данных упражнения
	getGeneratedPrompt: (text: string) => void
	// Текст сообщения об ошибке, которое нужно передать компоненту, что он показал в себе
	error: null | string
}

// Блок с формой для написания задания и кнопкой генерирования задания для ChatGPT.
// Сгенерированный текст будет принять и обработан формой упражнения, где находится этот блок.
function Generator(props: GeneratorProps) {
	const {
		exerciseType,
		initialPrompt,
		getGeneratedText,
		getGeneratedPrompt,
		error,
	} = props

	const { state, setState } = useGetState(
		exerciseType,
		initialPrompt,
		getGeneratedText,
		getGeneratedPrompt
	)

	return (
		<StateContext.Provider value={{ state, setState }}>
			<div className="generator">
				<Header />
				{state.open && <Content error={error} />}
			</div>
		</StateContext.Provider>
	)
}

export default Generator
