import React from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import {
	UTable,
	UTableBCell,
	UTableBody,
	UTableBRow,
	UTableHCell,
	UTableHRow,
	UTableTextWithIcon,
} from 'ui/UTable/UTable'
import useEmployeeGroupsStore from '../zustand/store'
import { EmployeeGroupsStoreType } from '../zustand/storeTypes'
import {
	BuildOutlined,
	DeleteOutlined,
	PlusOutlined,
	TeamOutlined,
} from '@ant-design/icons'
import DatesRange from 'ui/DatesRange/DatesRange'
import { useGetSetVisibilityToAssignEmployeeToGroupModal } from '../AssignEmployeeToGroupModal/fn/openCloseModal'
import { useGetLeaveGroup } from './fn/leaveGroup'

export default function EmployeeGroupsTable() {
	const groups = useEmployeeGroupsStore((state) => state.employeeGroups)!

	return (
		<UTable>
			<TableHeader />
			<UTableBody>
				{groups.map((group) => (
					<TableRow group={group} key={group.groupId} />
				))}
				<AddStuffRow />
			</UTableBody>
		</UTable>
	)
}

function TableHeader() {
	const { t } = useTranslation()

	return (
		<UTableHRow>
			<UTableHCell>Курс</UTableHCell>
			<UTableHCell>Группа</UTableHCell>
			<UTableHCell>Мест в группе</UTableHCell>
			<UTableHCell>Заполнено</UTableHCell>
			<UTableHCell>Проводится</UTableHCell>
			<UTableHCell>Действия</UTableHCell>
		</UTableHRow>
	)
}

type TableRowProps = {
	group: EmployeeGroupsStoreType.Group
}

function TableRow(props: TableRowProps) {
	const { group } = props

	return (
		<UTableBRow>
			<UTableBCell key="training">{group.trainingName}</UTableBCell>
			<UTableBCell key="groupName">
				<UTableTextWithIcon
					text={group.groupName}
					Icon={BuildOutlined}
				/>
			</UTableBCell>
			<UTableBCell key="numOfParticipants">
				<UTableTextWithIcon
					text={group.numOfParticipants}
					Icon={TeamOutlined}
				/>
			</UTableBCell>
			<UTableBCell key="studentsCount">
				<UTableTextWithIcon
					text={group.studentsCount}
					Icon={TeamOutlined}
					iconColor="green"
				/>
			</UTableBCell>
			<UTableBCell key="price">
				{group.startDate && group.finishDate && (
					<DatesRange
						dateFrom={group.startDate}
						dateTo={group.finishDate}
						colored
					/>
				)}
			</UTableBCell>
			<UTableBCell key="status">
				<ActionButtons group={group} />
			</UTableBCell>
		</UTableBRow>
	)
}

type GoToGroupPageButtonProps = {
	group: EmployeeGroupsStoreType.Group
}

// Кнопка открытия страницы группы
function ActionButtons(props: GoToGroupPageButtonProps) {
	const { group } = props

	const leaveGroup = useGetLeaveGroup(group.groupId)

	return (
		<div className="all-groups-table__actions-wrapper">
			{/*<Button
				size="small"
				icon={<EditTwoTone />}
				type="link"
				// onClick={openModal}
			>
				Передать
			</Button>*/}
			<Button
				size="small"
				icon={<DeleteOutlined />}
				type="link"
				danger
				onClick={leaveGroup}
			/>
		</div>
	)
}

// Ряд с кнопкой добавления сотрудника к группе
function AddStuffRow() {
	const { t } = useTranslation()

	const openModal = useGetSetVisibilityToAssignEmployeeToGroupModal(true)

	return (
		<UTableBRow>
			<UTableBCell colSpan={5}>
				<Button icon={<PlusOutlined />} onClick={openModal}>
					Добавить в группу
				</Button>
			</UTableBCell>
		</UTableBRow>
	)
}
