import useLeadsStore from '../../../LeadsTable/zustand/store'
import { LeadsStoreType } from '../../../LeadsTable/zustand/storeTypes'

/** Возвращает объект с булевыми значениями видимости кнопок поиска по колонке */
export function useIsClearButtonVisible() {
	const searchValues = useLeadsStore((store) => store.searchValues)
	const ValueNames = LeadsStoreType.SearchValueNames

	return {
		isDateClearBtnOpen:
			!!searchValues[ValueNames.DateFrom] ||
			!!searchValues[ValueNames.DateTo],
		isEmailClearBtnOpen: !!searchValues[ValueNames.Email].length,
		isPhoneClearBtnOpen: !!searchValues[ValueNames.Phone].length,
		isPriceClearBtnOpen:
			!!searchValues[ValueNames.PriceFrom] ||
			!!searchValues[ValueNames.PriceTo],
		isStatusesClearBtnOpen: !!searchValues[ValueNames.Statuses].length,
		isPaySystemsClearBtnOpen: !!searchValues[ValueNames.PaySystems].length,
	}
}

/**
 * Возвращает обработчик нажатия на кнопку обнуления введённого поискового запроса в форме поиска по колонке таблицы
 * @param searchType — тип колонки для поиска
 */
export function getClearSearchValueFn(
	searchType: LeadsStoreType.SearchValueNames
) {
	const setSearchValuesProp = useLeadsStore(
		(store) => store.setSearchValuesProp
	)
	const ValueNames = LeadsStoreType.SearchValueNames

	return function () {
		if (
			searchType == ValueNames.DateFrom ||
			searchType == ValueNames.DateTo
		) {
			setSearchValuesProp(ValueNames.DateTo, null)
			setSearchValuesProp(ValueNames.DateFrom, null)
		} else if (
			searchType == ValueNames.Email ||
			searchType == ValueNames.Phone
		) {
			setSearchValuesProp(ValueNames.DateTo, null)
			setSearchValuesProp(ValueNames.DateFrom, null)
		} else if (
			searchType == ValueNames.Statuses ||
			searchType == ValueNames.PaySystems
		) {
			setSearchValuesProp(searchType, [])
		} else if (
			searchType == ValueNames.PriceFrom ||
			searchType == ValueNames.PriceTo
		) {
			setSearchValuesProp(ValueNames.PriceFrom, undefined)
			setSearchValuesProp(ValueNames.PriceTo, undefined)
		}
	}
}
