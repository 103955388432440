import { DownloadExerciseFileStatus } from '../../common/exerciseFormCommonFunc'

export type TrueOrFalseExerciseFormStateType = {
	exerciseId: null | number // id упражнения. Если это новое упражнение, то id не существует
	order: number
	// Поле «Задание»
	taskInput: {
		value: string // Значение поля «Описание задания»
	}
	// Утверждения
	statements: FormStatementType[] // Массив утверждений
	isFormInvalid: boolean // Является ли форма неверной
}

export type FormStatementType = {
	id: number // Идентификатор блока с данными утверждения
	text: string // Текст утверждения
	correct: boolean // true если утверждение верно
	image: ImageFile
	noTextError: boolean
	// Текст задания на генерирование текста
	generatedTextPrompt: string
	// Текст ошибки, который нужно передать компоненту, генерирующий текст
	generatedTextError: null | string
}

export type ImageFile = {
	link: string // Адрес загруженного файла
	status: DownloadExerciseFileStatus // Статус загрузки
	progress: number // Процент загрузки файла
	fileName: string // Название загружаемого файла
}

/**
 * Создаёт пустой объект утверждения
 * @param {Number} statementId — id утверждения
 */
export function createFormStatementItem(
	statementId: number
): FormStatementType {
	return {
		id: statementId,
		text: '',
		correct: false,
		image: {
			link: '',
			status: 'empty',
			progress: 0,
			fileName: '',
		},
		noTextError: false,
		generatedTextPrompt: '',
		generatedTextError: null,
	}
}

// Объект начального состояния формы упражнения
export const formInitialState: TrueOrFalseExerciseFormStateType = {
	exerciseId: null,
	order: 0,
	taskInput: {
		value: '', // Значение поля «Описание задания»
	},
	statements: [createFormStatementItem(1)],
	isFormInvalid: false,
}
