import SectionHeader from 'pages/landing/landingConstructor/details/SectionHeader/SectionHeader'
import SectionWrapper from 'pages/landing/landingConstructor/details/SectionWrapper/SectionWrapper'
import TakeAPart from 'pages/landing/landingConstructor/details/TakeAPart/TakeAPart'
import SectionContent from 'pages/landing/landingConstructor/sections/existenceSection/SectionContent/SectionContent'

// Секция одностраничника с рассказом с пунктами что должно быть и не быть у ученика
function ExistenceSection() {
	return (
		<SectionWrapper>
			<SectionHeader
				imagePropsPathArr={[
					'sections',
					'existence',
					'header',
					'imageUrl',
				]}
				headerPropsPathArr={['sections', 'existence', 'header', 'text']}
			/>
			<div>
				<SectionContent />
				<TakeAPart sectionName="existence" />
			</div>
		</SectionWrapper>
	)
}

export default ExistenceSection
