import { useEffect, useState } from 'react'
import { message } from 'antd'
import { QueryClient, useQueryClient } from 'react-query'
import { authQuery } from '../requests/auth/authQuery'
import userRequests from '../requests/user/userRequest'
import UserApiTypes from '../requests/user/userApiTypes'
import authRequests from '../requests/auth/authRequest'
import { userQuery } from '../requests/user/userQuery'
import useSystemStore from '../systemStore/systemStore'
import { getUserName } from '../utils/string'
import { AutoLoginLSKeys } from './AutoLogin'

// Методы для работы с пользователем
const UserService = {
	// Проверка существования пользователя по почте
	async isUserExist(email: string): Promise<boolean> {
		try {
			const usersWithEmailRes = await userRequests.searchByEmail({
				email,
			})

			// После убрать проверку на 201
			if (
				usersWithEmailRes.status == 200 ||
				usersWithEmailRes.status == 201
			) {
				return usersWithEmailRes.data.length > 0
			}
		} catch (error) {
			message.error('Ошибка проверки существования пользователя')
			throw new Error(JSON.stringify(error))
		}

		return true
	},

	/** Выход пользователя */
	async logout() {
		const updateSystemStore = useSystemStore.getState().updateStore

		try {
			await authRequests.logout()

			document.cookie = 'refreshToken=; path=/; max-age=0'
			// Удаление всех ключей.
			window.localStorage.clear()

			// Поставить undefined чтобы при выходе вместо страницы 404
			// показывал загрузку
			updateSystemStore({ user: undefined as any })
		} catch (error) {
			// Если возникла ошибка, то refreshToken недействителен.
			// Поэтому запросить новый.
			const refreshRes = await authRequests.refresh()
			// Поставить в localStorage потому что authRequests.logout() берёт его оттуда
			localStorage.setItem('token', refreshRes.data.accessToken)

			// И затем заново сделать запрос на выход.
			UserService.logout()
		}
	},

	/**
	 * Получения журнала действий пользователя
	 * @param {number} userId — id пользователя
	 */
	async getUserLogs(userId: number | string) {
		try {
			const response = await userRequests.getUserLogs(userId)

			if (response.status === 200) {
				return response.data
			}

			return null
		} catch (error) {
			message.error('Ошибка получения списка кураторов')
			throw new Error(JSON.stringify(error))
		}

		return null
	},

	// ВМЕСТО ЭТОГО ИСПОЛЬЗОВАТЬ React Query!!!
	async sendAccess(userId: number) {
		const sendAccessDto: UserApiTypes.SendAccessDto = {
			userId: userId,
		}

		try {
			const response = await userRequests.sendAccess(sendAccessDto)

			if (response.status === 200) {
				message.success('Доступ отправлен')
			}
		} catch (err) {
			message.error('Не удалось отправить доступ пользователю')
		}
	},

	/**
	 * Получает с сервера список кураторов и возвращает сформированный массив
	 * данными для отрисовки выпадающего списка кураторов в форме добавления новой оплаты
	 * @param withEmptyItem — добавить ли пустой пункт выпадающего списка?
	 */
	useGetCuratorsList(withEmptyItem?: boolean) {
		const [curatorsList, setCuratorsList] = useState<CuratorType[]>([])

		useEffect(function () {
			userRequests.getCurators().then((res) => {
				if (res.status !== 200) return

				const curators: CuratorType[] = res.data.map((curator) => {
					return {
						id: curator.id.toString(),
						name: `${curator.firstName || ''} ${
							curator.lastName || ''
						}`,
					}
				})

				if (withEmptyItem) {
					curators.push({
						id: 'none',
						name: 'Не выбрано',
					})
				}

				setCuratorsList(curators)
			})
		}, [])

		return curatorsList
	},

	/**
	 * Получает с сервера список кураторов и возвращает сформированный массив
	 * с данными для отрисовки выпадающего списка кураторов в форме live-урока
	 */
	useGetLivelessonsTutors() {
		const [tutorsList, setTutorsList] = useState<CuratorType[]>([])
		const { data } = userQuery.getCurators().useQuery()

		useEffect(
			function () {
				if (!data) return
				const tutors = data.data.map((tutor) => {
					return {
						id: tutor.id.toString(),
						name: `${tutor.firstName || ''} ${
							tutor.lastName || ''
						}`,
					}
				})
				setTutorsList(tutors)
			},
			[data]
		)

		return tutorsList
	},

	useGetLivelessonsStudents() {
		const [studentsList, setStudentsList] = useState<StudentType[]>([])
		const { data } = userQuery.getStudentsByAdmin.useQuery()

		useEffect(
			function () {
				if (!data) return
				const students = data.data.map((student) => {
					return {
						id: student.id.toString(),
						name: getUserName(student),
					}
				})
				setStudentsList(students)
			},
			[data]
		)

		return studentsList
	},
}

type CuratorType = {
	id: string
	name: string
}

type StudentType = {
	id: string
	name: string
}

export default UserService
