import React from 'react'
import { createFileListFromUrlList } from 'ui/upload/UploadFileInForm/fn/fn'
import UploadImage from 'ui/upload/UploadImage/UploadImage'
import { LandingTypes } from 'parts/types/LandingTypes'
import {
	useGetInitialImageSrc,
	useGetUpdateLandingStoreAfterUploadImage,
} from 'pages/landing/landingConstructor/details/editableElems/EditableImage/fn/updateLandingStoreAfterUploadImage'

type EditableTextProps<
	A extends keyof LandingTypes.Landing,
	B extends keyof LandingTypes.Landing[A],
	C extends keyof LandingTypes.Landing[A][B],
	D extends keyof LandingTypes.Landing[A][B][C],
	E extends keyof LandingTypes.Landing[A][B][C][D],
	F extends keyof LandingTypes.Landing[A][B][C][D][E],
	G extends keyof LandingTypes.Landing[A][B][C][D][E][F]
> = {
	propsPathArr: [A, B?, C?, D?, E?, F?, G?]
	extraClass?: string
}

// Компонент редактируемого изображения
function EditableImage<
	A extends keyof LandingTypes.Landing,
	B extends keyof LandingTypes.Landing[A],
	C extends keyof LandingTypes.Landing[A][B],
	D extends keyof LandingTypes.Landing[A][B][C],
	E extends keyof LandingTypes.Landing[A][B][C][D],
	F extends keyof LandingTypes.Landing[A][B][C][D][E],
	G extends keyof LandingTypes.Landing[A][B][C][D][E][F]
>(props: EditableTextProps<A, B, C, D, E, F, G>) {
	const { propsPathArr, extraClass } = props

	const initialImageSrc = useGetInitialImageSrc(propsPathArr)

	const updateLandingAfterUpload =
		useGetUpdateLandingStoreAfterUploadImage(propsPathArr)

	return (
		<UploadImage
			initialFileList={createFileListFromUrlList([initialImageSrc])}
			getUploadedFiles={updateLandingAfterUpload}
			extraClass={extraClass}
		/>
	)
}

export default EditableImage
