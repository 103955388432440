import React, { useContext } from 'react'
import { Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useGetInitialValues } from './fn/form'
import { useManageForm } from './fn/submit'
import {
	UFormNewLRParts,
	UFormNewWhiteWrapper,
	UFormNewRow,
	UFormNewGeneralError,
	UFormNewBottomButtonsWithBorder,
	UFormNewButtonsContainer,
} from 'ui/UForm/UForm'
import { StateContext } from '../state/stateContext'
import { useGetOpenDeleteSchoolModal } from '../StopPaymentModal/fn/openCloseRemoveSchoolModal'
import PaymentNameBlock from '../../myPaymentsAdmin/PaymentNameBlock/PaymentNameBlock'
import PaymentConditionsBlock from '../../myPaymentsAdmin/PaymentConditionsBlock/PaymentConditionsBlock'
import './EditPaymentForm.scss'

// Форма с данными для приёма платежей на одну из платёжных систем
function EditPaymentForm() {
	const { t } = useTranslation()
	const { state } = useContext(StateContext)

	const [form] = Form.useForm()
	const initialValues = useGetInitialValues()

	const { isLoading, onSubmit, formErrors } = useManageForm(form)

	if (!initialValues || !state.paymentSystemCommonData) return null

	return (
		<Form
			form={form}
			name="register"
			initialValues={initialValues}
			onFinish={onSubmit}
			layout="vertical"
		>
			<UFormNewWhiteWrapper>
				<UFormNewLRParts>
					<div className="payment-form__left-part">
						<PaymentNameBlock
							data={state.paymentSystemCommonData}
						/>
						<PaymentConditionsBlock
							data={state.paymentSystemCommonData}
							view="vertical"
						/>
					</div>
					<>
						<h3 className="payment-form__header">
							{t('myPayments.editPaymentFormHeader')}
						</h3>
						<Fields />
					</>
				</UFormNewLRParts>
				<UFormNewGeneralError message={formErrors.message} />
				<Bottom loading={isLoading} />
			</UFormNewWhiteWrapper>
		</Form>
	)
}

export default EditPaymentForm

// Генерируемые поля
function Fields() {
	const { t } = useTranslation()
	const { state, setState } = useContext(StateContext)
	if (!state.initialFormData) return null

	return (
		<>
			{Object.entries(state.initialFormData.fields).map(
				([fieldName, fieldData]) => {
					if (fieldData.inputType == 'text') {
						return (
							<UFormNewRow topOffset key={fieldName}>
								<Form.Item
									name={fieldName}
									label={fieldData.inputLabel}
									tooltip={{
										title: fieldData.tooltip,
										icon: <QuestionCircleOutlined />,
									}}
									rules={[
										{
											required: true,
											message: t(
												'myPayments.editPaymentFormRequiredField'
											),
										},
									]}
								>
									<Input
										type="text"
										placeholder={fieldData.placeholder}
									/>
								</Form.Item>
							</UFormNewRow>
						)
					}

					return null
				}
			)}
		</>
	)
}

type BottomProps = {
	loading: boolean
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom(props: BottomProps) {
	const { t } = useTranslation()
	const form = Form.useFormInstance()
	const { loading } = props
	const { state, setState } = useContext(StateContext)
	const openDeleteSchoolModal = useGetOpenDeleteSchoolModal()

	return (
		<UFormNewBottomButtonsWithBorder>
			{state.connectionType == 'on' ? (
				<Button type="text" onClick={openDeleteSchoolModal}>
					{t('myPayments.editPaymentFormSwitchOffSystemButton')}
				</Button>
			) : (
				<div />
			)}
			<UFormNewButtonsContainer>
				<Form.Item shouldUpdate>
					{() => {
						const formErrors = form
							.getFieldsError()
							.filter(({ errors }) => errors.length)

						return (
							<Button
								block
								type="primary"
								htmlType="submit"
								disabled={loading || formErrors.length > 0}
								loading={loading}
							>
								{state.formSubmitType == 'save'
									? t('myPayments.editPaymentFormSaveButton')
									: t('myPayments.editPaymentFormOnButton')}
							</Button>
						)
					}}
				</Form.Item>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsWithBorder>
	)
}
