import { useEffect } from 'react'
import trainingRequests from 'parts/requests/training/trainingRequest'
import useTrainingHeaderStudentStore from '../../zustand/store'

/**
 * Хук при загрузке страницы проверяет, что показываемое упражнение принадлежит первому урока.
 * И если это так, то отправляет на сервер запрос сообщающий, что первый урок был открыт.
 * После этого ученику становятся доступны все последующие уроки.
 */
export function useTrainingViewed() {
	const training = useTrainingHeaderStudentStore((state) => state.training)

	useEffect(
		function () {
			if (!training) return

			sayToServerTrainingViewed(training.trainingId)
		},
		[training]
	)
}

/**
 * Отправляет на сервер запрос сообщающий, что тренинг был просмотрен.
 * @param {Number} trainingId — id курса
 */
async function sayToServerTrainingViewed(trainingId: number) {
	try {
		await trainingRequests.sendViewed({ trainingId })
	} catch (err) {
		console.log(err)
	}
}
