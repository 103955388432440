import { useEffect } from 'react'
import { useIsFirstRender } from 'parts/utils/hooks/hooks'
import { SaveExerciseChangesMadeByUser } from '../../../common/useLiftViewDuration'
import useExerciseStore from '../../zustand/exerciseState'
import ExerciseStateType from '../../zustand/ExerciseStateType'

/**
 * Наблюдает за объектом состояния упражнения и передаёт функции сохраняющей его на сервере
 * чтобы при следующем открытии упражнения восстановить это состояние.
 * @param saveExerciseChangesMadeByUser — функция, которая будет вызываться при изменении упражнения пользователем.
 * В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
 */
export function useSaveStateOnServerForRestore(
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
) {
	// Задержка, чтобы не сохранял данные после открытия страницы
	const isFirstRender = useIsFirstRender(2000)
	const store = useExerciseStore((store) => store)

	useEffect(
		function () {
			if (isFirstRender || !saveExerciseChangesMadeByUser) return

			saveExerciseChangesMadeByUser({
				doneAllow: store.doneAllow,
				answerWasSent: store.answerWasSent,
			})
		},
		[store]
	)
}

export function useRestoreStore(
	exerciseRestoreDataPromise: Promise<ExerciseStateType.State>
) {
	const updateStore = useExerciseStore((store) => store.updateStore)

	useEffect(function () {
		exerciseRestoreDataPromise.then((restoreData) => {
			if (!restoreData) return

			updateStore(restoreData)
		})
	}, [])
}
