import React from 'react'
import usePayModalStore from '../zustand/store'
import { getCoursePrice } from './fn/getPrice'
import s from './MakeOrderModal.module.scss'

function ModalTop() {
	const tariff = usePayModalStore((state) => state.tariff)
	const price = usePayModalStore((state) => state.price)

	if (!tariff || !tariff.trainingName || !price) return null

	return (
		<>
			<h2>Оформление заказа</h2>
			<div className={s.OrderData}>
				{tariff?.trainingCover && (
					<img src={tariff?.trainingCover} alt="" />
				)}
				<span>{tariff?.trainingName}</span>
				<span>{getCoursePrice(price)}</span>
			</div>
		</>
	)
}

export default ModalTop
