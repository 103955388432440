import { useEffect } from 'react'
import { FormInstance } from 'antd'
import { setErrorsToFields } from 'parts/utils/form'
import useEditTrainingModalStore from '../zustand/store'

export enum FieldNames {
	Name = 'name',
	Description = 'description',
	BlockName = 'blockName',
	Level = 'level',
	Lang = 'lang',
	Order = 'order',
	ShowOnMain = 'showOnMainScreen',
}

export type FormValuesType = {
	[FieldNames.Name]: string
	[FieldNames.Description]?: string
	[FieldNames.BlockName]?: string
	[FieldNames.Level]?: string
	[FieldNames.Lang]?: string
	[FieldNames.Order]?: number
	[FieldNames.ShowOnMain]?: boolean
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useEditTrainingModalStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
