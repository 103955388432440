import React from 'react'
import { Outlet } from 'react-router-dom'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import AddSchoolButton from '../mySchoolsAdmin/AddSchoolButton/AddSchoolButton'
import { AddSchoolModalController } from '../mySchoolsAdmin/AddSchoolModal/AddSchoolModalController'
import Footer from 'ui/Footer/Footer'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import { useGetTabsConfig } from './fn/getTabsConfig'

/** Страница моих курсов и моих систем оплат */
function MyPageAdmin() {
	const userRoles = useGetUserRole()

	const tabsConfig = useGetTabsConfig()

	return (
		<PageSkeleton>
			<PageHeader
				breadcrumbs={[]}
				header="Мой кабинет"
				buttons={[<AddSchoolButton key={1} />]}
				tabs={tabsConfig}
			/>
			<>
				<Outlet />
				{AddSchoolModalController.element}
			</>
			<Footer isRightSideHidden={!userRoles.isAdminOrManager} />
		</PageSkeleton>
	)
}

export default MyPageAdmin
