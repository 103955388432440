import { useEffect } from 'react'
import useSystemStore from '../../../../../../../parts/systemStore/systemStore'
import { userQuery } from 'parts/requests/user/userQuery'
import UserApiTypes from 'parts/requests/user/userApiTypes'
import useStudentsStore from '../store'
import { StudentsStoreType } from '../storeTypes'
import studentsManager from './studentsManager'

/** При монтировании компонента и при изменении объекта с фильтрами таблицы учеников делает запрос
 * на получение списка учеников, формирует данные для Состояния и ставит в него */
export function useFillInStore() {
	const user = useSystemStore((store) => store.user)

	const [updateStore, searchValues] = useStudentsStore((state) => [
		state.updateStore,
		state.searchValues,
	])

	// Получить список учеников в зависимости от параметров
	const { data, refetch } = userQuery
		.getCuratorStudents(user.id)
		.useQuery(getQueryParams(searchValues))

	// При изменении данных подготовить их под формат используемый в таблице учеников и поставить
	useEffect(
		function () {
			if (!data) return

			const preparedStudents =
				studentsManager.convertServStudentsToStateStudents(data.data)

			updateStore({
				students: preparedStudents,
			})
		},
		[data]
	)

	useEffect(
		function () {
			// Сделать новый запрос при изменении объекта с фильтрами таблицы
			refetch()
		},
		[searchValues]
	)
}

function getQueryParams(
	searchValues: StudentsStoreType.SearchValues
): UserApiTypes.GetUsersParams {
	const params: UserApiTypes.GetCuratorStudentsParams = {
		filter: {
			fullName: searchValues.fullName,
			trainingName: searchValues.trainingName,
			groupName: searchValues.groupName,
			isDeleted: false,
		},
	}

	const isActivated =
		searchValues.status == StudentsStoreType.SearchValuesStatus.IsActivated
	const isNotActivated =
		searchValues.status ==
		StudentsStoreType.SearchValuesStatus.IsNotActivated

	// Если указали только статус isActivated...
	if (isActivated && !isNotActivated) {
		params.filter!.isActivated = true
	}
	// Если указали только статус isNotActivated...
	if (!isActivated && isNotActivated) {
		params.filter!.isActivated = false
	}
	// Если указали и isActivated и isNotActivated, то ничего не передавать
	// и тогда сервер пришлёт учеников с обоими статусами...

	return params
}
