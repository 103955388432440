import React from 'react'
import s from './NoPayments.module.scss'

function NoPayments() {
	return (
		<div className={s.wrapper}>
			<p className={s.text}>У вас ещё не было платежей</p>
		</div>
	)
}

export default NoPayments
