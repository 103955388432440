import { produce } from 'immer'
import { ExerciseType, GapsExerciseFormStateType } from './stateType'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { getFormState, setFormState } from '../../common/exerciseFormCommonFunc'
import { isTextCorrectForChooseWordGap } from '../../common/GapsFlasher/fn'
import { textHasValidBracesPairs } from './check'
import useExercisesListAdminStore from '../../../zustand/store'

/**
 * Обработчик отправки формы
 * @param {String} exerciseType — тип упражнения: gapsInWords (заполнить пропуски в словах) или chooseWord (выбрать правильный вариант слова)
 * @param {Function} saveExercise — функция сохраняющая упражнение на сервере и в Состоянии
 */
export function submitHandler(
	exerciseType: ExerciseType,
	saveExercise: (args: TrainingEntityTypes.ExerciseItem) => void
) {
	const formState = getFormState<GapsExerciseFormStateType>()

	const isFormValid = validateForm(exerciseType)
	if (!isFormValid) return

	const exerciseData = convertFormStateDataToExerciseData(
		formState,
		exerciseType
	)
	saveExercise(exerciseData)
}

/**
 * Функция проверяет правильность заполнения формы, изменяет Состояние для показа ошибок и возвращает булево значение является ли форма правильной
 * @param {String} exerciseType — тип упражнения: gapsInWords (заполнить пропуски в словах) или chooseWord (выбрать правильный вариант слова)
 */
function validateForm(exerciseType: ExerciseType) {
	let isFormValid = true

	const formState = getFormState<GapsExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		draft.texts.forEach((textBlock, i) => {
			// Проверка на отсутствие текста в текстовом блоке
			if (!textBlock.text) {
				draft.texts[i].noTextError = true
				draft.isFormInvalid = true

				isFormValid = false
			}

			// Проверка на то, что идёт правильный порядок открывающих и закрывающих фигурных скобок.
			if (textBlock.text && !textHasValidBracesPairs(textBlock.text)) {
				draft.texts[i].wrongBracesPairError = true
				draft.isFormInvalid = true

				isFormValid = false
			}

			// Проверка на то, что идёт правильный порядок открывающих и закрывающих фигурных скобок.
			if (
				textBlock.text &&
				exerciseType == 'chooseWord' &&
				!isTextValidForChooseWordExerciseText(textBlock.text)
			) {
				draft.texts[i].wrongBracesTextError = true
				draft.isFormInvalid = true
				isFormValid = false
			}
		})
	})

	setFormState(newState)

	return isFormValid
}

/**
 * Функция переводит данные формы в данные упражнения
 * @param {Object} formState — объект состояния формы
 * @param {String} exerciseType — тип упражнения: gapsInWords (заполнить пропуски в словах) или chooseWord (выбрать правильный вариант слова)
 */
function convertFormStateDataToExerciseData(
	formState: GapsExerciseFormStateType,
	exerciseType: ExerciseType
) {
	const lessonId = useExercisesListAdminStore.getState().lessonId

	const exerciseData:
		| TrainingEntityTypes.GapsInWordsExercise
		| TrainingEntityTypes.ChooseWordExercise = {
		type: exerciseType,
		lessonId,
		order: formState.order,
		item: {
			task: formState.taskInput.value,
			texts: formState.texts.map((textBlock) => {
				return {
					text: textBlock.text,
					generatedTextPrompt: textBlock.generatedTextPrompt,
				}
			}),
		},
		status: 0, // Чтобы TS не ругался
		statusUpdated: '', // Чтобы TS не ругался
	}

	if (formState.exerciseId) {
		exerciseData.id = formState.exerciseId
	}

	return exerciseData
}

/**
 * Функция перебирает введённый пользователем текст в редактор и проверяет,
 * что слова в фигурных скобках соответствуют требованиями для таких слов для упражнения
 * на выбор правильного слова из предложенных
 * @param {String} text — html из редактора.
 */
function isTextValidForChooseWordExerciseText(text: string): boolean {
	let isOpenBraceExist = false
	let words = ''

	for (let i = 0; i < text.length; i++) {
		const letter = text[i]

		if (letter == '{') {
			isOpenBraceExist = true
		} else if (letter == '}') {
			if (!isOpenBraceExist) {
				return false
			}

			if (!isTextCorrectForChooseWordGap(words)) {
				return false
			}

			isOpenBraceExist = false
			words = ''
		}

		if (isOpenBraceExist && letter != '{') {
			words += letter
		}
	}

	return true
}
