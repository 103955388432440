import { useEffect } from 'react'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import useExerciseStore, {
	ExerciseStateType,
} from '../../zustand/exerciseState'

/**
 * Хук возвращает объект состояния упражнения
 * @param {Object} exercise — объект с данными упражнения
 */
export function useFillExerciseState(
	exercise: TrainingEntityTypes.TestExercise
) {
	const updateStore = useExerciseStore((store) => store.updateStore)

	useEffect(
		function () {
			updateStore({
				questions: getQuestions(exercise),
			})
		},
		[exercise]
	)
}

/**
 * Получает объект упражнения и возвращает массив вопросов для Состояния упражнения.
 * @param {Object} exercise — объект с данными упражнения
 */
function getQuestions(
	exercise: TrainingEntityTypes.TestExercise
): ExerciseStateType.Question[] {
	return exercise.item.questions.map((question) => {
		return {
			id: question.id,
			text: question.text,
			audio: question.audio,
			video: question.video,
			videoLink: question.videoLink,
			image: question.image,
			multipleSelect: question.multipleSelect,
			showCorrect: false,
			disableQuestion: false,
			answers: getAnswers(question.answers),
		}
	})
}

/**
 * Получает ответы вопроса упражнения и возвращает массив ответов для Состояния упражнения.
 * @param {Array} answers — массив ответов на вопрос из данных упражнения
 */
function getAnswers(
	answers: TrainingEntityTypes.TestAnswer[]
): ExerciseStateType.Answer[] {
	return answers.map((answer) => {
		return {
			id: answer.id,
			text: answer.answer,
			view: 'unknown',
			checked: false,
			correct: answer.correct,
		}
	})
}

/** Получение текущего вопроса. */
export function useGetCurrentQuestion() {
	const questions = useExerciseStore((store) => store.questions)
	const currentQuestionNumber = useExerciseStore(
		(store) => store.currentQuestionIdx
	)
	return questions[currentQuestionNumber] || null
}

/**
 * Возвращает вопрос с переданным идентификатором.
 * @param questions
 * @param {Number} questionId — id вопроса
 */
export function getQuestion(
	questions: ExerciseStateType.Question[],
	questionId: number
): undefined | ExerciseStateType.Question {
	return questions.find((question) => question.id === questionId)
}

/**
 * Возвращает ответ на вопрос по переданному идентификатору
 * @param questions
 * @param {Number} questionId — id вопроса
 * @param {Number} answerId — id ответа
 */
export function getAnswer(
	questions: ExerciseStateType.Question[],
	questionId: number,
	answerId: number
): undefined | ExerciseStateType.Answer {
	const question = getQuestion(questions, questionId)
	if (!question) return undefined

	return question.answers.find((answer) => answer.id === answerId)
}
