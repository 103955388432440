import React from 'react'
import { getUserPic } from 'parts/utils/string'
import './Avatar.scss'

type AvatarProps = {
	src?: null | string
	alt?: string
	width: number
}

function Avatar(props: AvatarProps) {
	const { src, alt = 'Фотография пользователя', width } = props

	const picSrc = getUserPic(src)

	return (
		<div style={{ width: width, height: width }} className="avatar">
			<img src={picSrc} className="avatar__pic" alt={alt} />
		</div>
	)
}

export default Avatar
