import React from 'react'
import AppUrls from 'parts/constants/pageUrl'
import { useTranslation } from 'react-i18next'
import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import AddTrainingButton from '../AddTrainingButton/AddTrainingButton'
import TrainingsArticle from '../TrainingsArticle/TrainingsArticle'
import Footer from 'ui/Footer/Footer'
import { useGetUserRole } from 'parts/utils/hooks/hooks'

/** Страница со списком курсов */
export default function TrainingsAdminPage() {
	const { t } = useTranslation()
	const userRoles = useGetUserRole()

	return (
		<PageSkeleton>
			<PageHeader
				breadcrumbs={[
					{
						path: AppUrls.Trainings().url,
						breadcrumbName: AppUrls.Trainings().name,
					},
				]}
				header={AppUrls.Trainings().name}
				buttons={[<AddTrainingButton key={1} />]}
			/>
			<TrainingsArticle />
			<Footer isRightSideHidden={!userRoles.isAdminOrManager} />
		</PageSkeleton>
	)
}
