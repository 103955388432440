import React, { useEffect } from 'react'
import useExerciseStore, {
	ExerciseStateType,
} from '../../zustand/exerciseState'

/**
 * Функция создаёт HTML текстового блока с пропусками
 * @param {Object} editorRef — объект со ссылкой на блок текста с пропусками
 * @param {String} htmlString — строка с HTML из которой нужно создать разметку с пропусками
 */
export function useCreateInnerHtml(
	editorRef: React.MutableRefObject<null | HTMLElement>,
	htmlString: string
): void {
	useEffect(
		function () {
			const $editor = editorRef.current as HTMLElement
			if (!$editor) return

			// Создание строки с разметкой текстового блока с пропусками и вставка в редактор
			$editor.innerHTML = setGapsToHtmlString(htmlString)
		},
		[htmlString]
	)
}

/**
 * Функция создаёт строку с разметкой текстового блока с пропусками.
 * Слова, которые должны быть в разметке пропусками обрамлены фигурными скобками.
 * И функция ставит вместо таких слов другую разметку в которую можно вписывать слова
 * @param {String} htmlString — строка с HTML из которой нужно создать разметку с пропусками
 */
function setGapsToHtmlString(htmlString: string) {
	let newHtmlString = ''

	// Слово, которое ученик должен написать в дырке. Собирается во время прохода
	let gapWord = ''
	// Находится ли сейчас цикл в слове, которое ученик должен написать в дырке?
	let inGap = false

	// Проход по строке из редактора
	for (let i = 0; i < htmlString.length; i++) {
		const letter = htmlString[i]

		if (letter == '{') {
			// Поставить флаг, что курсор зашёл в слово, которые должно быть скрыто от ученика. Он его должен написать в дырке.
			inGap = true
		} else if (letter == '}') {
			// Поставить флаг, что курсор вышел из слов, которые должно быть скрыто от ученика и которое нужно написать в дырке.
			inGap = false

			// Создать разметку, которая должна быть вместо пропущенного слова
			const editableSpanStr = createEditableSpan(gapWord)

			// Поставить разметку в собираемую строку.
			newHtmlString += editableSpanStr

			// Очистить слово в дырке потому что курсор вышел из этого слова.
			gapWord = ''
		} else if (inGap) {
			// Если курсор находится в слове для дырки, то собирать символы в gapWord
			gapWord += htmlString[i]
		} else {
			// Во всех остальных случаях собирать в исходную строку символы, которые не являются фигурными скобками и не должны быть скрытыми.
			newHtmlString += htmlString[i]
		}
	}

	return newHtmlString
}

/**
 * Возвращает строку разметки, которой нужно заменить слово, которое должно быть пропуском.
 * Это <span> с contenteditable для возможности ввести слово.
 * А следом пишется правильное слово, чтобы показать его, если ученик не смог ввести правильный вариант.
 * @param {String} rightWord — правильное слово, которые нужно ввести в пропуск
 */
function createEditableSpan(rightWord: string) {
	const editableSpan = `<span contenteditable="true" data-right="${rightWord}" class="exercise-gaps-content__gap"></span>`
	const rightAnswerSpan = `<span class="exercise-gaps-content__right-answer">${rightWord}</span>`
	return editableSpan + rightAnswerSpan
}

/**
 * Хук возвращает функцию отслеживающую ввод текста в пропуски
 * @param {Object} textsRef — объект со ссылкой на обёртку текстов с пропусками
 */
export function useSetTextsChangeHandler(
	textsRef: React.MutableRefObject<HTMLDivElement | null>
) {
	const updateStore = useExerciseStore((store) => store.updateStore)

	useEffect(
		function () {
			const $texts = textsRef.current
			if (!$texts) return

			// Создание устанавливаемого обработчика
			const inputHandler = getAfterGapTextChangedFn($texts, updateStore)

			$texts.addEventListener('input', inputHandler)

			return function () {
				$texts.removeEventListener('input', inputHandler)
			}
		},
		[textsRef]
	)
}

/**
 * Функция возвращает обработчик ввода текста в пропуски
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
function getAfterGapTextChangedFn(
	$texts: HTMLDivElement,
	updateStore: ExerciseStateType.UpdateStore
) {
	return function (e?: Event) {
		const $gap = e?.target

		// При вводе убрать классы поясняющие, что написано правильное слово или ошибка
		if ($gap) {
			// @ts-ignore
			$gap.classList.remove('exercise-gaps-content__gap--success')
			// @ts-ignore
			$gap.classList.remove('exercise-gaps-content__gap--fail')
		}

		// Отправить на состояние, где упражнение можно проверить
		updateStore({ stage: 'ReadyForCheck' })
	}
}

/**
 * Функция получает все элементы пропуска
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
function get$Gaps($texts: HTMLDivElement) {
	return $texts.querySelectorAll('.exercise-gaps-content__gap')
}

/**
 * Функция перебирает пропуски и для каждого пропуска запускает переданную функцию, в которую передаёт элемент пропуска
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 * @param {Function} handler — функция запускаемая для каждого пропуска в которую передаёт элемент пропуска
 */
function enumerateGaps(
	$texts: HTMLDivElement,
	handler: ($gap: HTMLElement) => void
) {
	const $gaps = get$Gaps($texts)

	for (let i = 0; i < $gaps.length; i++) {
		const $gap = $gaps[i]

		if ($gap.nodeType == 1) {
			handler($gap as HTMLElement)
		}
	}
}

/**
 * Функция проверяет введённые в пропуски слова и задаёт им классы в зависимости от правильности слова
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
export function checkGaps($texts: HTMLDivElement) {
	let allGapsFilledRight = true // Правильно ли заполнены все пропуски
	let successAnswersCounter = 0 // Количество правильно введённых слов

	enumerateGaps($texts, ($gap) => {
		// Получение правильного слова
		const rightWord = $gap.getAttribute('data-right') as string

		// Слово введённое пользователем
		const textNode = $gap.childNodes[0] || document.createTextNode('')
		const userWord = textNode.textContent as string

		const isWordsEqual = clearString(rightWord) === clearString(userWord)

		if (isWordsEqual) {
			$gap.classList.remove('exercise-gaps-content__gap--fail')
			$gap.classList.add('exercise-gaps-content__gap--success')

			successAnswersCounter++
		} else {
			$gap.classList.remove('exercise-gaps-content__gap--success')
			$gap.classList.add('exercise-gaps-content__gap--fail')
			allGapsFilledRight = false
		}
	})

	return {
		allGapsFilledRight,
		successAnswersCounter,
		totalGapsCounter: get$Gaps($texts).length,
	}
}

/**
 * Функция преобразует слово для правильного сравнения
 * @param {String} str — строка, которую нужно подготовить для сравнения
 */
function clearString(str: string) {
	let preparedStr = str.toLowerCase().trim()
	preparedStr = preparedStr.toLowerCase()
	preparedStr = preparedStr.replace(/\./g, '')
	preparedStr = preparedStr.replace(/<\/?[\w\s="'-]*>/g, '')

	// Заменить все апострофы одинарной кавычкой чтобы не считал ошибкой написанный апостроф
	// eslint-disable-next-line
	preparedStr = preparedStr.replace(/[`‘’]/g, "'")
	return preparedStr
}

/**
 * Функция показывает правильный вариант слова после пропуска если пользователь ввёл неверный вариант
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
export function showRightAnswers($texts: HTMLDivElement) {
	enumerateGaps($texts, ($gap) => {
		if ($gap.classList.contains('exercise-gaps-content__gap--fail')) {
			const $rightWord = $gap.nextElementSibling

			if ($rightWord) {
				$rightWord.classList.add(
					'exercise-gaps-content__right-answer--open'
				)
			}
		}
	})
}
