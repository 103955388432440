import cn from 'classnames'
import { LandingTypes } from 'parts/types/LandingTypes'

/**
 * Возвращает текст, которые должен быть на кнопке переключения темы
 * @param block — блок с описанием пользователя, которому подойдёт курс
 */
export function getToggleThemeBtnText(block: LandingTypes.ExistenceBlock) {
	return block.colorTheme == 'green' ? 'Сделать красным' : 'Сделать зелёным'
}

/**
 * Возвращает строку с классами, которые должны быть на кнопке переключения темы
 * @param block — блок с описанием пользователя, которому подойдёт курс
 */
export function useGetToggleThemeBtnClasses(
	block: LandingTypes.ExistenceBlock
): string {
	const btnClasses = ['land-const-existence-block__change-theme-btn']

	if (block.colorTheme === 'green') {
		btnClasses.push('land-const-existence-block__change-theme-btn--red')
	} else {
		btnClasses.push('land-const-existence-block__change-theme-btn--green')
	}

	return cn(btnClasses)
}
