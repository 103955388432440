import { FC } from 'react'

import styles from './AdsBlock.module.scss'

interface ExpressAdsBlockProps {
	onClick?: () => void
}

const ExpressAdsBlock: FC<ExpressAdsBlockProps> = ({ onClick }) => {
	const handleOnClick = () => {
		if (onClick) {
			onClick()
		}
	}

	return (
		<div className={styles.AdsBlock}>
			<div className={styles.AdsBlock__Sign}>
				<div className={styles.Sign__Pin}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="11"
						height="11"
						viewBox="0 0 11 11"
						fill="none"
					>
						<circle
							cx="5.6351"
							cy="5.74073"
							r="4.08238"
							transform="rotate(18.2348 5.6351 5.74073)"
							fill="#96E788"
						/>
						<circle
							cx="5.63618"
							cy="5.73975"
							r="2.33279"
							transform="rotate(18.2348 5.63618 5.73975)"
							fill="#2573E6"
							stroke="white"
							strokeWidth="1.94399"
						/>
					</svg>
				</div>
				<div className={styles.Sign__Logo}>C</div>
				<div className={styles.Sign__Text}>Credu</div>
			</div>
			<div>
				<div className={styles.AdsBlock__Text}>Узнай все</div>
				<div className={styles.AdsBlock__Text}>возможности</div>
				<div className={styles.AdsBlock__Text}>платформы Credu</div>
				<div className={styles.AdsBlock__Text}>бесплатно</div>
			</div>
			<div className={styles.AdsBlock__Button} onClick={handleOnClick}>
				Пройти курс
			</div>
		</div>
	)
}

export default ExpressAdsBlock
