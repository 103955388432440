import EditLessonModal from './EditLessonModal/EditLessonModal'
import useEditLessonModalStore from './zustand/store'

export const EditLessonModalController = {
	element: <EditLessonModal />,
	open(lessonId: number) {
		const updateStore = useEditLessonModalStore.getState().updateStore
		updateStore({ isModalOpen: true, lessonId })
	},
	close() {
		const updateStore = useEditLessonModalStore.getState().updateStore
		updateStore({ isModalOpen: false, lessonId: 0 })
	},
}
