import ModalWithForm from 'ui/ModalWithForm/ModalWithForm'
import useAddLiveModalStore from './zustand/store'
import { useGetChangeModalVisibility } from './zustand/storeManager'
import { LivelessonFormController } from './fn/LivelessonFormController'

// Модальное окно создания или редактирования live-урока
function AddLiveModal() {
	const livelessonFormRouter = LivelessonFormController.router()

	const modalState = useAddLiveModalStore((state) => state)

	const title = modalState.lessonId
		? 'Редактирование комнаты'
		: 'Создание комнаты'

	const close = useGetChangeModalVisibility(false)

	return (
		<ModalWithForm title={title} open={modalState.isOpen} onCancel={close}>
			{livelessonFormRouter.status == 'hasData' &&
				livelessonFormRouter.element}
		</ModalWithForm>
	)
}

export default AddLiveModal
