import cn from 'classnames'
import EntityTypes from 'parts/types/EntityTypes'
import useLandingBuilderStore from '../../../zustand/store'
import './Header.scss'

type HeaderProps = {
	tariff: EntityTypes.Tariff
}

// Заголовок карточки
function Header(props: HeaderProps) {
	const { tariff } = props

	const colorScheme = useLandingBuilderStore(
		(store) => store.landing.colorScheme
	)

	if (!tariff.name) return null

	const classes = ['p-tariff-card-header']
	if (tariff.isFlashed) {
		classes.push('p-tariff-card-header--black')
	} else {
		classes.push('p-tariff-card-header--' + colorScheme)
	}

	return <h2 className={cn(classes)}>{tariff.name}</h2>
}

export default Header
