import cn from 'classnames'
import EntityTypes from 'parts/types/EntityTypes'
import useLandingBuilderStore from '../../../zustand/store'
import { findMainPrice } from '../../common/findMainPrice'
import { useGetChangeModalVisibility } from '../../modal/MakeOrderModal/fn/fn'
import './BuyButton.scss'

type HeaderProps = {
	tariff: EntityTypes.Tariff
}

// Кнопка покупки
function BuyButton(props: HeaderProps) {
	const { tariff } = props
	const mainPrice = findMainPrice(tariff)

	const colorScheme = useLandingBuilderStore(
		(store) => store.landing.colorScheme
	)

	const openModal = useGetChangeModalVisibility(true, tariff, mainPrice!)

	if (!mainPrice) return null

	const classes = [
		'p-tariff-card-buy-btn',
		'p-tariff-card-buy-btn--' + colorScheme,
	]

	return (
		<button className={cn(classes)} onClick={openModal}>
			{mainPrice.buttonText}
		</button>
	)
}

export default BuyButton
