import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'

export function sortLiveLessons(
	a: LiveLessonEntityTypes.LiveLesson,
	b: LiveLessonEntityTypes.LiveLesson
) {
	if (a.id > b.id) {
		return -1
	}
	if (a.id < b.id) {
		return 1
	}
	return 0
}
