import { useQueryClient } from 'react-query'
import { useGetTrainingStatsRouter } from './fn/useGetTrainingStatsRouter'
import {
	GetStudentStatisticData,
	userQuery,
} from 'parts/requests/user/userQuery'

export const TrainingStatsController = {
	router: useGetTrainingStatsRouter,
	// Функция, помечающая данные курса неактуальными. После этого React Query скачает новый массив статистики по урокам
	useGetUpdate: function () {
		const queryClient = useQueryClient()

		return function (queryParams: GetStudentStatisticData) {
			// Пометить данные курса неактуальными. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [userQuery.getStudentStatistic(queryParams).key],
			})
		}
	},
}
