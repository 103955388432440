import {
	ArrowDownOutlined,
	ArrowUpOutlined,
	DeleteOutlined,
	ToolOutlined,
} from '@ant-design/icons'
import { Button } from 'antd'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import { useTranslation } from 'react-i18next'
import { useGetDeleteLessonModal } from './fn/deleteLesson'
import { useGetShowEditLessonModal } from './fn/editLesson'
import { useGetMoveLesson, useIsMoveLessonBtnDisabled } from './fn/moveLesson'

type ButtonsProps = {
	lessonIdx: number // Порядковый номер урока
	lesson: TrainingEntityTypes.LessonAdmin
}

function Buttons(props: ButtonsProps) {
	const { lessonIdx, lesson } = props

	const { t } = useTranslation()
	const userRoles = useGetUserRole()

	const showEditLessonModal = useGetShowEditLessonModal(lesson.id)
	const deleteLessonModal = useGetDeleteLessonModal(lesson.id)

	const moveLessonUp = useGetMoveLesson(lessonIdx, 'up')
	const moveLessonDown = useGetMoveLesson(lessonIdx, 'down')

	const moveLessonUpDisabled = useIsMoveLessonBtnDisabled(lessonIdx, 'up')
	const moveLessonDownDisabled = useIsMoveLessonBtnDisabled(lessonIdx, 'down')

	return (
		<div className="edit-lesson-card__buttons">
			<div className="edit-lesson-card__left-buttons">
				{userRoles.isAdminOrManagerOrCurator && (
					<>
						<Button
							icon={<ToolOutlined />}
							size="small"
							type="link"
							onClick={showEditLessonModal}
						>
							{t('adminTraining.lessonCardButtonEdit')}
						</Button>
						<Button
							icon={<ArrowUpOutlined />}
							size="small"
							type="link"
							onClick={moveLessonUp}
							disabled={moveLessonUpDisabled}
						>
							{t('adminTraining.lessonCardButtonUp')}
						</Button>
						<Button
							icon={<ArrowDownOutlined />}
							size="small"
							type="link"
							onClick={moveLessonDown}
							disabled={moveLessonDownDisabled}
						>
							{t('adminTraining.lessonCardButtonDown')}
						</Button>
					</>
				)}
			</div>
			{userRoles.isAdmin && (
				<Button
					icon={<DeleteOutlined />}
					danger
					size="small"
					type="text"
					onClick={deleteLessonModal}
				>
					{t('adminTraining.lessonCardButtonDelete')}
				</Button>
			)}
		</div>
	)
}

export default Buttons
