import { useEffect } from 'react'
import { paymentsQuery } from 'parts/requests/payments/paymentsQuery'
import useHistoryStore from '../store'
import { convertHistoryResToStoreHistory } from './convertHistoryResToStoreHistory'

export function useFillStore() {
	const updateHistoryStore = useHistoryStore((store) => store.updateStore)

	const { data: subscriptionsHistoryRes } =
		paymentsQuery.getAdminSubscriptionsHistory.useQuery()

	useEffect(
		function () {
			if (!subscriptionsHistoryRes) return

			const historyForStore = convertHistoryResToStoreHistory(
				subscriptionsHistoryRes.data
			)

			updateHistoryStore({
				dataLoadingStatus: historyForStore.length
					? 'hasItems'
					: 'noItems',
				historyItems: historyForStore,
			})
		},
		[subscriptionsHistoryRes]
	)
}
