import React from 'react'
import { PaymentSystemCommonDataType } from '../paymentSystems'
import './PaymentNameBlock.scss'

type PaymentNameBlockType = {
	data: PaymentSystemCommonDataType
}

// Название и логотип платёжной системы
function PaymentNameBlock(props: PaymentNameBlockType) {
	const { data } = props

	return (
		<div className="payment-name-block">
			<img
				src={data.logo}
				className="payment-name-block-logo"
				alt="logo"
			/>
			<div>
				<p className="payment-name-block-name">{data.name}</p>
				<a
					className="payment-name-block-site link"
					href={'https://' + data.site}
				>
					{data.site}
				</a>
			</div>
		</div>
	)
}

export default PaymentNameBlock
