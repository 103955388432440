import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import useDeleteModalStore from '../../../../../modal/DeleteModal/store/store'
import { groupQuery } from 'parts/requests/group/groupQuery'
import { StaffTableAdminController } from '../../StaffTable/StaffTableAdminController'
import UrlService from 'parts/services/UrlService'

/** Исключение сотрудника из группы */
export function useGetLeaveGroup(employeeId: number) {
	const { t } = useTranslation()

	const groupId = UrlService.useGetGroupId()

	const updateModalStore = useDeleteModalStore((state) => state.updateStore)
	const updateGroupStaffTable = StaffTableAdminController.useGetUpdate()

	const { mutate } = groupQuery.removeUserFromGroup.useMutation({
		onError: () => {
			message.error('Не удалось удалить пользователя')
		},
		onSuccess: () => {
			message.success('Сотрудник исключён из группы')
			// Пометить список сотрудников назначенных группе неактуальным.
			// После этого React Query скачает новый список
			updateGroupStaffTable()
		},
	})

	return function () {
		updateModalStore({
			isOpen: true,
			header: 'Удаление сотрудника из группы',
			text: 'Вы уверены, что хотите убрать сотрудника из группы?',
			onOk: () =>
				mutate({
					groupId,
					userId: employeeId,
				}),
		})
	}
}
