import { UTableBCell, UTableBRow } from 'ui/UTable/UTable'
import { HistoryStatsStoreType } from '../zustand/storeTypes'
import s from './HistoryRow.module.scss'

type TableRowProps = {
	historyItem: HistoryStatsStoreType.HistoryItem
}

// Ряд таблицы учеников
function TableRow(props: TableRowProps) {
	const { historyItem } = props

	return (
		<UTableBRow>
			<UTableBCell key="date">
				<p className={s.text}>{historyItem.paymentDate}</p>
			</UTableBCell>
			<UTableBCell key="name">
				<p className={s.text}>{historyItem.planName}</p>
			</UTableBCell>
			<UTableBCell key="sum">
				<p className={s.text}>{historyItem.paymentSum}</p>
			</UTableBCell>
			<UTableBCell key="link">
				{historyItem.invoiceLink ? (
					<p className={s.link}>
						<a href={historyItem.invoiceLink}>Скачать квитанцию</a>
					</p>
				) : (
					<p className={s.text}>Для данный платёж нет накладной.</p>
				)}
			</UTableBCell>
		</UTableBRow>
	)
}

export default TableRow
