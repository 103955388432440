import { useEffect } from 'react'
import { FormInstance, UploadFile } from 'antd'
import useEditLessonModalStore from '../../zustand/store'
import { setErrorsToFields } from 'parts/utils/form'

export enum FieldNames {
	Name = 'name',
	Description = 'description',
	Cover = 'cover',
}

export type FormValuesType = {
	[FieldNames.Name]: string
	[FieldNames.Description]: string
	[FieldNames.Cover]: UploadFile[]
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useEditLessonModalStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
