import { ReactNode } from 'react'
import useTariffsConstructorStore from '../../zustand/store'
import Loader from 'ui/Loader/Loader'
import { useFillInStore } from 'pages/landing/landingConstructor/tariffs/zustand/fillStore/fillInStore'
import NoActiveGroupScreen from '../noActiveGroup/NoActiveGroupScreen/NoActiveGroupScreen'
import PageParts from '../PageParts/PageParts'
import './TariffsMain.scss'

// Секция с настройкой тарифов одностраничника
function TariffsMain() {
	useFillInStore()

	const [dataLoadingStatus, training] = useTariffsConstructorStore(
		(state) => [state.dataLoadingStatus, state.training]
	)

	if (dataLoadingStatus == 'loading') {
		return (
			<CenterWrapper>
				<Loader />
			</CenterWrapper>
		)
	} else if (
		!training.groups.length ||
		dataLoadingStatus == 'noActiveGroup'
	) {
		return (
			<CenterWrapper>
				<NoActiveGroupScreen />
			</CenterWrapper>
		)
	} else if (dataLoadingStatus == 'error') {
		return (
			<CenterWrapper>
				<p>При получении данных произошла ошибка.</p>
			</CenterWrapper>
		)
	}

	return <PageParts />
}

export default TariffsMain

type CenterWrapperPropType = {
	children: ReactNode
}

function CenterWrapper(props: CenterWrapperPropType) {
	return <div className="land-const-tariffs-section">{props.children}</div>
}
