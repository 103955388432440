import axios from 'axios'
import $api from '../http'
import AuthApiTypes from './authApiTypes'

// Запросы к методам получения текущего пользователя
const authRequests = {
	// Регистрация администратора школ
	registerAdmin(dto: AuthApiTypes.RegisterAdminDto) {
		return axios.post<AuthApiTypes.RegisterAdmin>(
			'/api/auth/admin-register-only',
			dto
		)
	},

	// Регистрация администратора школ вместе с созданием школы
	registerAdminWithSchool(dto: AuthApiTypes.RegisterAdminWithSchoolDto) {
		return axios.post<AuthApiTypes.RegisterAdminWithSchool>(
			'/api/auth/admin-register',
			dto
		)
	},

	// Регистрация ученика
	registerStudent(dto: AuthApiTypes.RegisterStudentDto) {
		return axios.post<AuthApiTypes.RegisterStudent>(
			'/api/auth/student-register',
			dto
		)
	},

	// Отправка кода подтверждения почты на почту пользователя
	sendEmailCode(dto: AuthApiTypes.SendEmailCodeDto) {
		return axios.post<AuthApiTypes.SendEmailCode>(
			'/api/auth/resend-code',
			dto
		)
	},

	// Обратная отправка кода подтверждения пользователем (все случаи кроме регистрации)
	checkEmailCode(dto: AuthApiTypes.CheckEmailCodeDto) {
		return axios.post<AuthApiTypes.CheckEmailCode>(
			'/api/auth/check-code',
			dto
		)
	},

	// Отправка кода пользователем, присланного на его почту для подтверждения существования почты при регистрации
	activateUserByCode(dto: AuthApiTypes.ActivateUserByCodeDto) {
		return axios.post<AuthApiTypes.ActivateUserByCode>(
			'/api/auth/activate-by-code',
			dto
		)
	},

	// Вход пользователя
	login(dto: AuthApiTypes.LoginDto) {
		return axios.post<AuthApiTypes.Login>('/api/auth/login', dto, {
			withCredentials: true,
		})
	},

	// Получение данных пользователя по почте и паролю
	userIdByCredentials(dto: AuthApiTypes.UserIdByCredentialsDto) {
		return axios.post<AuthApiTypes.UserIdByCredentials>(
			'/api/auth/user-id',
			dto
		)
	},

	// Отправка токена для авторизации и получение данных пользователя
	refresh() {
		return axios.get<AuthApiTypes.Refresh>('/api/auth/refresh', {
			withCredentials: true,
		})
	},

	// Выход пользователя из всех устройств
	exit(dto: AuthApiTypes.ExitDto) {
		return $api.post<AuthApiTypes.Exit>('/api/auth/exit', dto)
	},

	// Сброс refreshToken на сервере
	logout() {
		return axios.post<AuthApiTypes.Logout>('/api/auth/logout', null, {
			withCredentials: true,
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		})
	},

	// Отправляет на почту ссылку для смены/восстановления пароля
	recoverPasswordByEmail(email: string) {
		return $api.post<AuthApiTypes.RecoverPasswordByEmail>(
			'/api/auth/recovery',
			{ email }
		)
	},

	// Проверка правильности кода восстановления пароля
	checkRecoveryCode(code: string) {
		return $api.get<AuthApiTypes.CheckRecoveryCode>(
			`/api/auth/recovery/${code}`
		)
	},

	changePasswordWithCode: function (
		dto: AuthApiTypes.changePasswordWithCodeDto
	) {
		return $api.post<AuthApiTypes.ChangePasswordWithCode>(
			'/api/auth/password-recovery-code',
			dto
		)
	},

	// Изменение собственного пароля текущим пользователем (администратор свой пароль меняет другим методом)
	updatePasswordOfCurrentUser(
		dto: AuthApiTypes.UpdatePasswordOfCurrentUserDto
	) {
		return $api.patch<AuthApiTypes.UpdatePasswordOfCurrentUser>(
			'/api/auth/user/password',
			dto
		)
	},

	// Изменение пароля пользователя с переданным идентификатором (обновляется администратором)
	updatePasswordOfUserById(dto: AuthApiTypes.UpdatePasswordOfUserByIdDto) {
		return $api.patch<AuthApiTypes.UpdatePasswordOfUserById>(
			'/api/auth/password',
			dto
		)
	},

	// Генерирование токена для последующей авторизации текущего пользователя.
	// Токен можно передать как зашифрованную строку и через POST /auth/login-by-token получить токен авторизации в куках.
	generateAuthToken() {
		return $api.post<AuthApiTypes.GenerateAuthToken>(
			'/api/auth/generate-token',
			{ withCredentials: true },
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			}
		)
	},

	// Получение токена авторизации посредством сгенерированного токена методом POST /auth/generate-token.
	loginByGeneratedToken(dto: AuthApiTypes.LoginByGeneratedTokenDto) {
		return axios.post<AuthApiTypes.LoginByGeneratedToken>(
			'/api/auth/login-by-token',
			dto,
			{ withCredentials: true }
		)
	},

	// Запрос на удаление аккаунта
	deleteAccountRequest(dto: AuthApiTypes.DeleteAccountRequestDto) {
		return axios.post<boolean>('/api/auth/delete-account-request', dto)
	},

	// Запрос на удаление аккаунта
	deleteAccount(dto: AuthApiTypes.DeleteAccountDto) {
		return axios.post<boolean>('/api/auth/delete-account', dto)
	},
}

export default authRequests
