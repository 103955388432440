import React, { FC } from 'react'

import styles from './ExpressTitle.module.scss'

interface ExpressTitleProps {
	title: string
	subtitle: string
	image: string
}

const ExpressTitle: FC<ExpressTitleProps> = ({ title, subtitle, image }) => {
	return (
		<div className={styles.Title}>
			<div className={styles.Title__Text}>
				<div className={styles.Title__Title}>{title}</div>
				<div className={styles.Title__SubTitle}>{subtitle}</div>
			</div>
			<div className={styles.Title__Image}>
				<img src={image} />
			</div>
		</div>
	)
}

export default ExpressTitle
