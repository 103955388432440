import useAddStudentStore from '../zustand/store'

/**
 * Возвращает функцию, открывающую/закрывающую модальное окно добавления нового ученика
 * @param isVisible — должно ли окно быть видимым
 */
export function useGetSetVisibilityToAddStudentModal(isVisible: boolean) {
	const updateStore = useAddStudentStore((state) => state.updateStore)

	return function () {
		updateStore({ isOpen: isVisible })
	}
}
