import React from 'react'
import HistoryModal from '../common/HistoryModal/HistoryModal'
import { Button, Form, FormInstance, Input, Radio, Space } from 'antd'
import { UFormNewRow } from 'ui/UForm/UForm'
import { FieldsNames } from './fn/form'
import { useGetToggleVisibilityReasonModal } from './fn/openCloseReasonModal'
import { useGetOnSubmit } from './fn/submit'
import useReasonFormStore from './zustand/store'
import s from './ReasonForm.module.scss'

function ReasonModal() {
	const [form] = Form.useForm()

	const isOpen = useReasonFormStore((store) => store.isOpen)
	const closeModal = useGetToggleVisibilityReasonModal()

	return (
		<HistoryModal
			iconSrc="/images/subscriptionsHistory/CommentOutlined.svg"
			title="Поделитесь впечатлениями о платформе!"
			open={isOpen}
			footerButtons={[
				<SaveSubscriptionButton form={form} key={1} />,
				<ContinueButton form={form} key={2} />,
			]}
			onCancel={closeModal}
		>
			<ReasonForm form={form} />
		</HistoryModal>
	)
}

export default ReasonModal

type ReasonFormProps = {
	form: FormInstance
}

function ReasonForm(props: ReasonFormProps) {
	const { form } = props
	const updateStore = useReasonFormStore((store) => store.updateStore)

	return (
		<div className={s.wrapper}>
			<p className={s.panelText}>
				Расскажите о причине отмены подписки. Это позволит стать нам
				лучше!
			</p>
			<Form form={form} name="register" layout="vertical">
				<UFormNewRow>
					<Form.Item name={FieldsNames.Reason}>
						<Radio.Group
							onChange={(val) =>
								updateStore({
									reasonRadioValue: val.target.value,
								})
							}
						>
							<Space size="small" direction="vertical">
								<Radio value={FieldsNames.ReasonNotRequired}>
									Больше не требуется
								</Radio>
								<Radio value={FieldsNames.ReasonExpensive}>
									Дорого
								</Radio>
								<Radio value={FieldsNames.ReasonBadSupport}>
									Не устраивает поддержка
								</Radio>
								<Radio
									value={FieldsNames.ReasonNoFunctionality}
								>
									Нет подходящего функционала
								</Radio>
								<Radio value={FieldsNames.ReasonAnother}>
									Другое
								</Radio>
							</Space>
						</Radio.Group>
					</Form.Item>
				</UFormNewRow>
				<UFormNewRow topOffset>
					<Form.Item name={FieldsNames.FullAnswer}>
						<Input.TextArea placeholder="Расскажите, что вам не понравилось" />
					</Form.Item>
				</UFormNewRow>
			</Form>
		</div>
	)
}

type SaveSubscriptionButtonProps = {
	form: FormInstance
}

function SaveSubscriptionButton(props: SaveSubscriptionButtonProps) {
	const { form } = props

	const isSubmitting = useReasonFormStore((store) => store.isSubmitting)

	const closeModal = useGetToggleVisibilityReasonModal()

	const formErrors = form
		.getFieldsError()
		.filter(({ errors }) => errors.length)

	return (
		<Button
			disabled={isSubmitting || formErrors.length > 0}
			onClick={closeModal}
			type="primary"
		>
			Сохранить подписку
		</Button>
	)
}

type ContinueButtonProps = {
	form: FormInstance
}

function ContinueButton(props: ContinueButtonProps) {
	const { form } = props

	const onSubmit = useGetOnSubmit(form)
	const isSubmitting = useReasonFormStore((store) => store.isSubmitting)

	const formErrors = form
		.getFieldsError()
		.filter(({ errors }) => errors.length)

	const reasonRadioValue = useReasonFormStore(
		(store) => store.reasonRadioValue
	)

	return (
		<Button
			htmlType="submit"
			disabled={
				isSubmitting || formErrors.length > 0 || !reasonRadioValue
			}
			loading={isSubmitting}
			onClick={onSubmit}
		>
			Продолжить
		</Button>
	)
}
