import { useEffect } from 'react'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { exercisesFormsManager } from '../../../exercisesFormsManager'
import useExercisesListAdminStore from '../../../zustand/store'
import {
	FormAnswerStateItemType,
	FormQuestionStateItemType,
	TestExerciseFormStateType,
} from './stateTypes'
import { testFormInitialState } from './state'

/**
 * Хук получающий данные упражнения из данных курса и переводящих их в данные для Состояния упражнения.
 * Получившейся объекта устанавливается в Хранилище
 * */
export function useSetFormData() {
	const exercises = useExercisesListAdminStore((store) => store.exercises)
	const exerciseId = useExercisesListAdminStore((store) => store.exerciseId)

	const setDataToExerciseFormStore =
		exercisesFormsManager.useGetSetDataToExerciseFormStore()

	useEffect(
		function () {
			let formData: TestExerciseFormStateType = testFormInitialState

			const exercise = exercises.find(
				(exercise) => exercise.id === exerciseId
			)

			if (exercise) {
				formData = convertExerciseDataToFormStateData(
					exercise as TrainingEntityTypes.TestExercise
				)
			}

			setDataToExerciseFormStore(formData)
		},
		[exerciseId]
	)
}

/**
 * Функция переводящая объект упражнения в объект состояния формы редактирования упражнения
 * @param {Object} exercise — объект упражнения
 */
function convertExerciseDataToFormStateData(
	exercise: TrainingEntityTypes.TestExercise
): TestExerciseFormStateType {
	const formStateData: TestExerciseFormStateType = {
		exerciseId: exercise.id || null,
		order: exercise.order,
		nameInput: {
			value: exercise.item.name,
		},
		taskInput: {
			value: exercise.item.task,
		},
		generatedText: '',
		generatedTextPrompt: exercise.item.generatedTextPrompt,
		generatedTextError: null,
		questions: [],
		isFormInvalid: false,
	}

	formStateData.questions = exercise.item.questions.map((question, i) => {
		return getQuestionObj(question, i)
	})

	function getQuestionObj(
		questionData: TrainingEntityTypes.TestQuestion,
		i: number
	): FormQuestionStateItemType {
		return {
			id: i + 1,
			questionText: questionData.text,
			noQuestionTextError: false,
			mediaType: 'audio',
			files: {
				audio: {
					link: questionData.audio,
					status: questionData.audio ? 'success' : 'empty',
					progress: 0,
					fileName: '',
				},
				video: {
					videoLink: questionData.videoLink,
					link: questionData.video,
					status: questionData.video ? 'success' : 'empty',
					progress: 0,
					fileName: '',
				},
				image: {
					link: questionData.image,
					status: questionData.image ? 'success' : 'empty',
					progress: 0,
					fileName: '',
				},
			},
			multipleSelect: questionData.multipleSelect,
			answers: questionData.answers.map((answerObj, k) => {
				return getAnswerObj(answerObj, k + 1)
			}),
			noAnswerTextError: false,
			noRightAnswerCheckedError: false,
		}
	}

	function getAnswerObj(
		answerData: TrainingEntityTypes.TestAnswer,
		i: number
	): FormAnswerStateItemType {
		return {
			id: i,
			text: answerData.answer,
			correct: answerData.correct,
		}
	}

	return formStateData
}
