import React from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyBlock } from 'ui/EmptyBlock/EmptyBlock'
import AddGroupButton from 'pages/training/admin/articles/groups/AddGroupButton/AddGroupButton'

function NoGroupsScreen() {
	const { t } = useTranslation()

	return (
		<EmptyBlock
			text={
				<>
					У вас нет созданных групп.
					<br />
					Добавьте их прямо сейчас.
				</>
			}
			button={<AddGroupButton />}
		/>
	)
}

export default NoGroupsScreen
