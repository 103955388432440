import React from 'react'
import cn from 'classnames'
import EditableImage from 'pages/landing/landingConstructor/details/editableElems/EditableImage/EditableImage'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import 'pages/landing/landingConstructor/sections/timeSection/MainImage/MainImage.scss'

// Главное изображение справа в секции с описанием чему научится человек после обучения.
function MainImage() {
	const landing = useLandingConstructorStore((store) => store.landing)
	const { colorScheme } = landing

	return (
		<div
			className={cn(
				'land-const-time-main-img',
				'land-const-time-main-img--' + colorScheme
			)}
		>
			<EditableImage propsPathArr={['sections', 'time', 'imageUrl']} />
		</div>
	)
}

export default MainImage
