import { message } from 'antd'
import { AxiosError } from 'axios'
import ServerTypes from 'parts/types/ServerTypes'
import { useTranslation } from 'react-i18next'
import { FieldNames, FormValuesType } from './form'
import useAccountStore from '../../zustand/store'
import UserApiTypes from 'parts/requests/user/userApiTypes'
import userRequests from 'parts/requests/user/userRequest'
import useStudentStore from '../../../main/zustand/store'

/** Хук возвращающий обработчик отправки формы */
export function useGetOnSubmit() {
	const { t } = useTranslation()
	const updateFormStore = useAccountStore((state) => state.updateStore)

	const [student, updateStore] = useStudentStore((store) => [
		store.student,
		store.updateStore,
	])

	return async (values: FormValuesType) => {
		if (!student) return

		try {
			// Поставить статус загрузки
			updateFormStore({ isSubmitting: true })

			// Отправить запрос и получить ответ
			const response = await userRequests.updateUser(
				getDto(values, student.id)
			)

			if (response.data) {
				// Установить в Хранилище введённые данные чтобы они показывались в шапке вместо старых
				updateStore({ student: response.data })

				message.success(t('student.accountFormFormSavedSuccessfully'))
			}
		} catch (err) {
			const error = err as AxiosError<ServerTypes.ErrorResponse>

			if (error.response) {
				// Поставить данные ошибки в Состояние, чтобы показать их в форме
				updateFormStore({ formErrors: error.response.data })
			}
		} finally {
			// Убрать статус загрузки
			updateFormStore({ isSubmitting: false })
		}
	}
}

/**
 * Формирует DTO для отправки запроса
 * @param {Array} values — массив значений полей формы
 * @param {Number} studentId — id ученика
 */
function getDto(
	values: FormValuesType,
	studentId: number
): UserApiTypes.UpdateUserDto {
	return {
		id: studentId,
		firstName: values[FieldNames.FirstName],
		lastName: values[FieldNames.LastName],
		phone: values[FieldNames.Phone],
		instagram: values[FieldNames.Instagram],
		country: values[FieldNames.Country],
		birthday: values[FieldNames.Birthday]?.toString() || undefined,
		city: values[FieldNames.City], // 'Kazan'
		sex: values[FieldNames.Gender], // 'male'
	}
}
