import LessonStudentService from 'parts/services/LessonStudentService'
import TrainingStudentService from 'parts/services/TrainingStudentService'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { getNumberAndNounStr, getRusNounByNumber } from 'parts/utils/string'
import { dateStrToHumanDateStr } from 'parts/utils/time'
import LangService, { LangNames } from 'parts/i18n/LangService'
import { TrainingHeaderStudentStoreType } from '../storeTypes'

export function convertTrainingToHeaderData(
	training: TrainingEntityTypes.TrainingStudent
): TrainingHeaderStudentStoreType.Header {
	return {
		trainingName: training.trainingName,
		trainingDescription: training.trainingDescription,
		groupStartDate: getGroupStartDate(training),
		groupEndDate: getGroupEndDate(training),
		nextLessonDate: getNextLessonDate(training),
		leftToExecute: getLeftToExecute(training),
		totalProgress: training.progress,
	}
}

/**
 * Возвращает дату начала группы в человеческом формате
 * @param training — данные курса для ученика
 */
function getGroupStartDate(training: TrainingEntityTypes.TrainingStudent) {
	const startDate =
		TrainingStudentService.getStudentTrainingGroupStartDate(training)

	return startDate ? dateStrToHumanDateStr(startDate) : null
}

/**
 * Возвращает дату окончания группы в человеческом формате
 * @param training — данные курса для ученика
 */
function getGroupEndDate(training: TrainingEntityTypes.TrainingStudent) {
	const endDate =
		TrainingStudentService.getStudentTrainingGroupEndDate(training)

	return endDate ? dateStrToHumanDateStr(endDate) : null
}

/**
 * Возвращает дату открытия следующего урока в человеческом формате.
 * Это дата первого неоткрытого урока
 * @param training — данные курса для ученика
 */
function getNextLessonDate(
	training: TrainingEntityTypes.TrainingStudent
): null | string {
	const nextLessonOpenDate =
		LessonStudentService.getNearestLessonOpenDateForStudent(training)

	return nextLessonOpenDate ? dateStrToHumanDateStr(nextLessonOpenDate) : null
}

function getLeftToExecute(training: TrainingEntityTypes.TrainingStudent) {
	let { unperformedExercises, unperformedLessons } = training

	if (!unperformedExercises && !unperformedLessons) {
		return null
	}

	const lang = LangService.getCurrentLangFromLocalStorage()

	if (lang === LangNames.RU) {
		const lessonsWord = getRusNounByNumber(
			unperformedLessons,
			'уроке',
			'уроках',
			'уроках'
		)
		const exercisesWord = getRusNounByNumber(
			unperformedExercises,
			'упражнение',
			'упражнения',
			'упражнений'
		)

		return `${unperformedExercises} ${exercisesWord} в ${unperformedLessons} ${lessonsWord}` // '120 упражнений в 30 уроках'
	} else if (lang === LangNames.EN) {
		const lessons = getNumberAndNounStr(
			unperformedLessons,
			'No lessons',
			'lesson',
			'lessons'
		)
		const exercises = getRusNounByNumber(
			unperformedExercises,
			'No exercises',
			'exercise',
			'exercises'
		)

		return `${lessons} in ${exercises}` // '120 упражнений в 30 уроках'
	}

	return null
}
