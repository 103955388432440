import { create } from 'zustand'
import { SystemStoreType } from 'parts/systemStore/systemStoreTypes'

const useSystemStore = create<SystemStoreType.State>((set) => {
	return {
		school: undefined as any,
		user: undefined as any,
		liveLessonId: null,

		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useSystemStore
