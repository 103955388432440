import { useEffect } from 'react'
import UserApiTypes from 'parts/requests/user/userApiTypes'
import { userQuery } from 'parts/requests/user/userQuery'
import EntityTypes from 'parts/types/EntityTypes'
import { getRoleName, getUserName, getUserPic } from 'parts/utils/string'
import useStaffStore from '../store'
import { GroupsStoreType } from '../storeTypes'

export const getStaffParams: UserApiTypes.GetUsersParams = {
	filter: {
		roles: [EntityTypes.UserRole.CURATOR, EntityTypes.UserRole.MANAGER],
		isDeleted: false,
	},
}

/** В таблице групп для администратора или менеджера нужно показывать группы курса, а для куратора группы курса где он назначен.
 * Поэтому используются один из двух запросов в зависимости от роли пользователя.
 * Функция скачивает с сервера данные по группам или курса или сотрудника, формирует массив групп и возвращает его. */
export function useFillInStore() {
	const updateStore = useStaffStore((store) => store.updateStore)

	const { data: staffRes } = userQuery.getUsers(getStaffParams).useQuery({
		onError: queryError,
	})

	// Если данные получены с сервера
	useEffect(
		function () {
			if (!staffRes) return

			// Подготовить массив сотрудников для помещения в Состояние...
			const staffForStore = convertServStaffToStateStaff(staffRes.data)

			updateStore({
				staff: staffForStore,
				dataLoadingStatus: staffForStore.length
					? 'hasStaff'
					: 'noStaff',
			})
		},
		[staffRes]
	)
}

function convertServStaffToStateStaff(
	rawStaff: UserApiTypes.GetUsers
): GroupsStoreType.Staff {
	return rawStaff.map((user) => {
		return {
			id: user.id,
			avatar: getUserPic(user.avatar),
			fullName: getUserName(user),
			role: getRoleName(user.role),
			phone: user.phone,
			email: user.email,
			groupsNum: user.groups.length,
			isActivated: !!user.isActivated,
		}
	})
}

/**
 * Функция запускаемая при ошибке запроса данных. Срабатывает для любых запросов.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 * @param err — объект ошибки.
 */
function queryError(err: unknown) {
	const { updateStore } = useStaffStore.getState()

	updateStore({
		dataLoadingStatus: 'error',
	})
}
