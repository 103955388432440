import { message } from 'antd'
import { AxiosError } from 'axios'
import ServerTypes from 'parts/types/ServerTypes'
import { useTranslation } from 'react-i18next'
import { FieldNames, FormValuesType } from './form'
import useAccountStore from '../../zustand/store'
import UserApiTypes from 'parts/requests/user/userApiTypes'
import userRequests from 'parts/requests/user/userRequest'
import useEmployeeStore from '../../../main/zustand/store'
import { getFirstLoadedFileUrl } from 'ui/upload/UploadFileInForm/fn/fn'

/** Хук возвращающий обработчик отправки формы */
export function useGetOnSubmit() {
	const { t } = useTranslation()
	const updateFormStore = useAccountStore((state) => state.updateStore)

	const [employee, updateStore] = useEmployeeStore((store) => [
		store.employee,
		store.updateStore,
	])

	return async (values: FormValuesType) => {
		if (!employee) return

		try {
			// Поставить статус загрузки
			updateFormStore({ isSubmitting: true })

			// Отправить запрос и получить ответ
			const response = await userRequests.updateUser(
				getDto(values, employee.id)
			)

			if (response.data) {
				// Установить в Хранилище введённые данные чтобы они показывались в шапке вместо старых
				updateStore({ employee: response.data })

				message.success(t('employee.employeeFormDataWasSaved'))
			}
		} catch (err) {
			const error = err as AxiosError<ServerTypes.ErrorResponse>

			if (error.response) {
				// Поставить данные ошибки в Состояние, чтобы показать их в форме
				updateFormStore({ formErrors: error.response.data })
			}
		} finally {
			// Убрать статус загрузки
			updateFormStore({ isSubmitting: false })
		}
	}
}

/**
 * Формирует DTO для отправки запроса
 * @param {Array} values — массив значений полей формы
 * @param {Number} employeeId — id ученика
 */
function getDto(
	values: FormValuesType,
	employeeId: number
): UserApiTypes.UpdateUserDto {
	return {
		id: employeeId,
		firstName: values[FieldNames.FirstName],
		lastName: values[FieldNames.LastName],
		role: values[FieldNames.Role],
		avatar: getFirstLoadedFileUrl(values[FieldNames.Avatar]),
		phone: values[FieldNames.Phone],
		country: values[FieldNames.Country],
		city: values[FieldNames.City],
	}
}
