import useMenuStore from 'app/menu/zustand/menuStore'
import React from 'react'
import { useAutoCloseMobileMenu, useGetCloseMobileMenuFn } from './fn/closeMenu'
import MainMenu from '../MainMenu/MainMenu'
import './MobileMenu.scss'

// Главное меню для телефона
function MobileMenu() {
	const isMobileMenuOpen = useMenuStore((store) => store.isMobileMenuOpen)
	useAutoCloseMobileMenu()

	const closeMobileMenu = useGetCloseMobileMenuFn()

	if (!isMobileMenuOpen) {
		return null
	}

	return (
		<div className="mobile-menu" onClick={closeMobileMenu}>
			<div className="mobile-menu__inner">
				<MainMenu />
			</div>
		</div>
	)
}

export default MobileMenu
