import { useEffect, useMemo } from 'react'
import UserApiTypes from 'parts/requests/user/userApiTypes'
import EntityTypes from 'parts/types/EntityTypes'
import { userQuery } from 'parts/requests/user/userQuery'
import { groupQuery } from 'parts/requests/group/groupQuery'
import useAddStaffInGroupModalStore from '../zustand/store'
import { AddStaffModalStoreType } from '../zustand/storeTypes'
import { getUserName } from 'parts/utils/string'
import UrlService from 'parts/services/UrlService'

/** Наполняет Зустанд данными, которые будут использоваться на странице сотрудников группы */
export function useFillInStore() {
	const groupId = UrlService.useGetGroupId()

	const stuff = useGetStuff()
	const updateState = useAddStaffInGroupModalStore(
		(store) => store.updateStore
	)

	useEffect(
		function () {
			if (!stuff) return

			fillInStore(stuff, updateState, groupId)
		},
		[stuff]
	)
}

type Staff = {
	allCurators: EntityTypes.Curator[]
	groupCurators: EntityTypes.Curator[]
	allManagers: UserApiTypes.GetUserByRole
}

/** Скачивает с сервера и возвращает массив сотрудников */
function useGetStuff(): undefined | Staff {
	const groupId = UrlService.useGetGroupId()

	const { data: allCuratorsData } = userQuery.getCurators().useQuery()

	const { data: groupCuratorsData } = groupQuery
		.getGroupCurators(groupId)
		.useQuery()

	const { data: managersData } = userQuery
		.getUserByRole(EntityTypes.UserRole.MANAGER)
		.useQuery()

	return useMemo(
		function () {
			if (!allCuratorsData || !groupCuratorsData || !managersData) return

			return {
				allCurators: allCuratorsData.data,
				groupCurators: groupCuratorsData.data,
				allManagers: managersData.data,
			}
		},
		[allCuratorsData, groupCuratorsData, managersData]
	)
}

/**
 * Наполняет объект Состояния данными
 * @param staff
 * @param updateState
 * @param groupId — id текущей группы
 */
async function fillInStore(
	staff: Staff,
	updateState: AddStaffModalStoreType.UpdateStore,
	groupId: number
) {
	const { allCurators, groupCurators, allManagers } = staff

	// Подготовить массив кураторов для помещения в Состояние...
	const curatorsForStore = convertServCuratorsToStateCurators(
		allCurators,
		groupCurators
	)

	// Подготовить массив менеджеров для помещения в Состояние...
	const managersForStore = convertServManagersToStateManagers(
		allManagers,
		groupId
	)

	// Поставить их в Состояние
	updateState({
		staff: [...curatorsForStore, ...managersForStore],
	})
}

/**
 * Принимает массив всех кураторов школы и кураторов прикреплённых школе и составляет массив с данными кураторов нужные в компонентах
 * @param curators — все кураторы школы
 * @param groupCurators — кураторы назначенные группе
 */
function convertServCuratorsToStateCurators(
	curators: EntityTypes.Curator[],
	groupCurators: EntityTypes.Curator[]
): AddStaffModalStoreType.Curator[] {
	const curatorsOutsideTheGroup = curators.filter((curator) => {
		// Есть ли текущий группе?
		return !groupCurators.find((groupCurator) => {
			return groupCurator.id == curator.id
		})
	})

	return curatorsOutsideTheGroup.map((curator) => {
		return {
			type: 'curator',
			id: curator.id,
			avatar: curator.avatar || null,
			name: getUserName(curator),
			// Количество учеников по плану
			studentCount: curator.studentCount,
			// Фактическое количество учеников
			actualStudentCount: curator.students.length,
			// Отмечен ли куратор флагом для последующему добавления к группе?
			addToGroupChecked: false,
		}
	})
}

/**
 * Принимает массив всех кураторов школы и кураторов прикреплённых школе и составляет массив с данными кураторов нужные в компонентах
 * @param managers — все менеджеры школы
 * @param groupId — id текущей группы
 */
function convertServManagersToStateManagers(
	managers: UserApiTypes.UserByRole[],
	groupId: number
): AddStaffModalStoreType.Manager[] {
	const managersOutsideTheGroup = managers.filter((manager) => {
		// Есть ли текущий группе?
		return !manager.groups.find((group) => group.id == groupId)
	})

	return managersOutsideTheGroup.map((manager) => {
		return {
			type: 'manager',
			id: manager.id,
			avatar: manager.avatar || null,
			name: getUserName(manager),
			groupsNumber: manager.groups.length,
			// Отмечен ли куратор флагом для последующему добавления к группе?
			addToGroupChecked: false,
		}
	})
}
