import React from 'react'
import { Button, DatePicker, Form, Input, Radio } from 'antd'
import { useTranslation } from 'react-i18next'
import {
	UFormNewBlockHeader,
	UFormNewBottomButtonsWithBorder,
	UFormNewDivider,
	UFormNewGeneralError,
	UFormNewRow,
	UFormNewWhiteWrapper,
} from 'ui/UForm/UForm'
import { useGetRegisterDate } from './fn/misc'
import { FieldNames, setErrorsToForm, useGetInitialValues } from './fn/form'
import EntityTypes from 'parts/types/EntityTypes'
import { useGetOnSubmit } from './fn/submit'
import useAccountStore from '../zustand/store'
import { useGetDeleteUser } from '../../main/HeaderButtons/fn/deleteUser'
import useChangeEmailStore from '../../../modal/ChangeEmailModal/state/store'
import useStudentStore from '../../main/zustand/store'
import useChangePasswordStore from '../../../modal/ChangePasswordModal/zustand/store'
import './AccountForm.scss'

function AccountForm() {
	const { t } = useTranslation()

	const [form] = Form.useForm()
	const initialValues = useGetInitialValues()

	const onSubmit = useGetOnSubmit()
	setErrorsToForm(form)

	const registerDate = useGetRegisterDate()
	const formErrors = useAccountStore((state) => state.formErrors)

	return (
		<Form
			form={form}
			name="register"
			initialValues={initialValues}
			onFinish={onSubmit}
			layout="vertical"
		>
			<UFormNewWhiteWrapper>
				<UFormNewBlockHeader
					text={t('student.accountFormBlockHeader')}
				/>
				<p className="student-profile-form__register-text">
					{t('student.accountFormRegisteredText')} {registerDate}
				</p>
				<NameInputs />
				<BirthdayAndGenderInputs />
				<UFormNewDivider />
				<ChangeEmailAndPassword />
				<UFormNewDivider />
				<CityAndCountryInputs />
				<PhoneAndInstagramInputs />
				<Bottom />
				<UFormNewGeneralError message={formErrors.message} />
			</UFormNewWhiteWrapper>
		</Form>
	)
}

export default AccountForm

function NameInputs() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.FirstName}
				label={t('student.accountFormFieldFirstNameLabel')}
			>
				<Input />
			</Form.Item>
			<Form.Item
				name={FieldNames.LastName}
				label={t('student.accountFormFieldLastNameLabel')}
			>
				<Input />
			</Form.Item>
		</UFormNewRow>
	)
}

function BirthdayAndGenderInputs() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Birthday}
				label={t('student.accountFormFieldBirthdayLabel')}
			>
				<DatePicker format="YYYY/MM/DD" />
			</Form.Item>
			<Form.Item
				name={FieldNames.Gender}
				label={t('student.accountFormFieldGenderLabel')}
			>
				<Radio.Group>
					<Radio value={EntityTypes.Gender.Male}>
						{t('student.accountFormFieldGenderRadioMan')}
					</Radio>
					<Radio value={EntityTypes.Gender.Female}>
						{t('student.accountFormFieldGenderRadioWoman')}
					</Radio>
				</Radio.Group>
			</Form.Item>
		</UFormNewRow>
	)
}

function ChangeEmailAndPassword() {
	const { t } = useTranslation()
	const student = useStudentStore((state) => state.student)

	const openChangeEmailModal = useChangeEmailStore((state) => state.openModal)
	const openChangePasswordModal = useChangePasswordStore(
		(state) => state.openModal
	)

	return (
		<UFormNewRow topOffset>
			<Button onClick={() => openChangeEmailModal(student!.id)}>
				{t('student.accountFormChangeEmailButton')}
			</Button>
			<Button onClick={() => openChangePasswordModal(student!.id)}>
				{t('student.accountFormChangePasswordButton')}
			</Button>
		</UFormNewRow>
	)
}

function CityAndCountryInputs() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.City}
				label={t('student.accountFormFieldCityLabel')}
			>
				<Input />
			</Form.Item>
			<Form.Item
				name={FieldNames.Country}
				label={t('student.accountFormFieldCountryLabel')}
			>
				<Input />
			</Form.Item>
		</UFormNewRow>
	)
}

function PhoneAndInstagramInputs() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Phone}
				label={t('student.accountFormFieldPhoneLabel')}
			>
				<Input />
			</Form.Item>
			<Form.Item
				name={FieldNames.Instagram}
				label={t('student.accountFormFieldInstagramLabel')}
			>
				<Input />
			</Form.Item>
		</UFormNewRow>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom() {
	const { t } = useTranslation()
	const form = Form.useFormInstance()
	const isSubmitting = useAccountStore((state) => state.isSubmitting)

	const deleteUser = useGetDeleteUser()

	return (
		<UFormNewBottomButtonsWithBorder>
			<Button type="text" onClick={deleteUser}>
				{t('student.accountFormDeleteAccountButton')}
			</Button>
			<Form.Item shouldUpdate>
				{() => {
					const formErrors = form
						.getFieldsError()
						.filter(({ errors }) => errors.length)

					return (
						<Button
							block
							type="primary"
							htmlType="submit"
							disabled={isSubmitting || formErrors.length > 0}
							loading={isSubmitting}
						>
							{t('student.accountFormDeleteSaveButton')}
						</Button>
					)
				}}
			</Form.Item>
		</UFormNewBottomButtonsWithBorder>
	)
}
