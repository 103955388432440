import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import AppUrls from 'parts/constants/pageUrl'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import LangService from '../../../../parts/i18n/LangService'
import useExerciseStudentStore from '../../zustand/store'
import { ExerciseStudentStoreType } from '../../zustand/storeTypes'
import UrlService from 'parts/services/UrlService'

import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

/**
 * Функция получает номер статуса упражнения и возвращает его текстовое название
 * @param {Number} status — статус упражнения
 */
export function useTransformStatusToText(
	status: null | TrainingEntityTypes.ExerciseProgressStatus
) {
	const { t } = useTranslation()

	if (status == 1) {
		return t('exercises.controlBtnDone')
	} else {
		return 'Не выполнено'
	}
}

/**
 * Возвращает время изменения статуса упражнения в специальном формате
 * @param {String} timeStr — время изменения статуса упражнения
 */
export function getExerciseTime(timeStr: null | string) {
	if (!timeStr) return null

	const currentLang = LangService.getCurrentLangFromLocalStorage()

	const exerciseDate = new Date(timeStr)
	const nowDate = new Date()
	const difference = +nowDate - +exerciseDate

	// Если прошедшее время меньше двух часов, то возвратить строку вида «минуту назад», «час назад»...
	if (difference < 1000 * 60 * 60 * 2) {
		if (currentLang === 'en') {
			return dayjs().locale(currentLang).from(dayjs(exerciseDate))
		} else {
			return (
				dayjs().locale(currentLang).from(dayjs(exerciseDate), true) +
				' назад'
			)
		}
	}
	// В противном случае вернуть точное время
	else {
		return dayjs(exerciseDate).locale(currentLang).format('D MMM YYYY')
	}
}

/**
 * Обработчик щелчка по упражнению в ленты навигации по упражнениям
 * @param {Object} exerciseConfig — объект состояния упражнения в ленте навигации по упражнениям
 */
export function useGetOnExerciseClick(
	exerciseConfig: ExerciseStudentStoreType.ExerciseMeta
) {
	const navigate = useNavigate()

	const [training, lesson, groupId] = useExerciseStudentStore((state) => [
		state.trainingStudent,
		state.lesson,
		state.groupId,
	])

	return function () {
		if (!training) return

		// Перейти на страницу этого упражнения
		const exerciseUrl = AppUrls.Exercise(
			UrlService.createTrainingAndGroupIdsUrl(
				training.trainingId,
				groupId
			),
			lesson.id,
			exerciseConfig.id
		).url

		navigate(exerciseUrl)
	}
}
