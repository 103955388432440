import { useEffect } from 'react'
import useExercisesListAdminStore from '../../../zustand/store'
import {
	DescriptionImgExerciseFormStateType,
	FormMatchStateItemType,
	testFormInitialState,
} from './formState'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { exercisesFormsManager } from '../../../exercisesFormsManager'

/** Хук устанавливающий данные формы в Хранилище */
export function useSetFormData() {
	const exercises = useExercisesListAdminStore((store) => store.exercises)
	const exerciseId = useExercisesListAdminStore((store) => store.exerciseId)

	const setDataToExerciseFormStore =
		exercisesFormsManager.useGetSetDataToExerciseFormStore()

	useEffect(
		function () {
			let formData: DescriptionImgExerciseFormStateType =
				testFormInitialState

			const exercise = exercises.find(
				(exercise) => exercise.id === exerciseId
			)

			if (exercise) {
				formData = convertExerciseDataToFormStateData(
					exercise as TrainingEntityTypes.DescriptionImgExercise
				)
			}

			setDataToExerciseFormStore(formData)
		},
		[exerciseId]
	)
}

/**
 * Функция переводящая объект упражнения в объект состояния формы редактирования упражнения
 * @param {Object} exercise — объект упражнения
 */
function convertExerciseDataToFormStateData(
	exercise: TrainingEntityTypes.DescriptionImgExercise
): DescriptionImgExerciseFormStateType {
	const formStateData: DescriptionImgExerciseFormStateType = {
		exerciseId: exercise.id || null,
		type: 'descriptionImg',
		order: exercise.order,
		taskInput: {
			value: exercise.item.task,
		},
		matches: [],
		wrong: exercise.item.wrong,
		isFormInvalid: false,
	}

	formStateData.matches = exercise.item.images.map((imageObj, i) => {
		return getMatchObj(imageObj, i)
	})

	return formStateData
}

function getMatchObj(
	imageData: TrainingEntityTypes.DescriptionImgItem,
	i: number
): FormMatchStateItemType {
	return {
		id: i + 1,
		text: imageData.description,
		noTextError: false,
		noImageError: false,
		image: {
			link: imageData.image,
			status: 'success',
			progress: 0,
			fileName: '',
		},
	}
}
