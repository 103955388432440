import AddSchoolModal from './AddSchoolModal'
import useAddSchoolModalStore from './zustand/store'

export const AddSchoolModalController = {
	element: <AddSchoolModal />,
	open() {
		const updateStore = useAddSchoolModalStore.getState().updateStore
		updateStore({ isOpen: true })
	},
	close() {
		const updateStore = useAddSchoolModalStore.getState().updateStore
		updateStore({ isOpen: false })
	},
}
