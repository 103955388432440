import { useEffect } from 'react'
import {
	GetStudentStatisticData,
	userQuery,
} from 'parts/requests/user/userQuery'
import { getRusNounByNumber } from '../../../../../../parts/utils/string'
import useTrainingStatsStore from '../zustand/store'
import { TrainingStats } from '../zustand/storeTypes'
import UserApiTypes from 'parts/requests/user/userApiTypes'
import { dateStrToHumanDateAndTime } from 'parts/utils/time'

export function useFillInStore(
	studentId: number | string,
	groupId: number | string
) {
	const updateState = useTrainingStatsStore((state) => state.updateState)

	// Получить данные по статистике посещения курса
	const queryData: GetStudentStatisticData = { groupId, studentId }
	const { data } = userQuery.getStudentStatistic(queryData).useQuery()

	useEffect(
		function () {
			if (!data) return

			const stats = data.data

			const lessons = convertServLessonsToStateLessons(stats.lessons)
			updateState({
				lessons,
				trainingName: stats.trainingName,
				groupName: stats.groupName,
			})
		},
		[data]
	)
}

/**
 * Получает ответ от сервере со статистикой прохождения уроков курса и преобразовывает в формат данных используемый для отрисовки карточек с уроками
 * @param lessons — данные с сервера со статистикой прохождения уроков курса
 */
function convertServLessonsToStateLessons(
	lessons: UserApiTypes.StudentLessonVisitStats[]
): TrainingStats.Lesson[] {
	return lessons.map((lesson, i) => {
		const isLastItem = i == lessons.length - 1

		return {
			id: lesson.id,
			idx: i + 1,
			name: lesson.name,
			isOpen: lesson.isOpen,
			status: getLessonStatus(lesson),
			openDate: dateStrToHumanDateAndTime(lesson.openDate),
			duration: getDurationStr(lesson),
			isLastLesson: isLastItem,
		}
	})
}

/**
 * Возвращает строку с информацией о продолжительности выполнения упражнения
 * @param lesson — данные с сервера со статистикой прохождения урока курса
 */
function getDurationStr(lesson: UserApiTypes.StudentLessonVisitStats) {
	if (!lesson.isOpen) {
		return 'Урок не открывался'
	}

	const { duration } = lesson

	if (duration === 0) {
		return 'Ни одно упражнение не выполнено'
	}

	// Если провёл меньше минуты, то показывать в секундах
	if (duration < 60) {
		return getSecondsStr(duration)
	}
	// Если провёл меньше часа, то показывать в минутах
	else if (duration < 60 * 60) {
		const minutes = Math.floor(duration / 60)
		const seconds = duration % 60

		return getMinutesStr(minutes) + ' и ' + getSecondsStr(seconds)
	}
	// В остальных случаях показывать часы
	else {
		const hours = Math.floor(duration / 60 / 60)
		const minutes = Math.floor(((duration - hours) / 60) % 60)
		const seconds = duration % 60

		return (
			getHoursStr(hours) +
			', ' +
			getMinutesStr(minutes) +
			', ' +
			getSecondsStr(seconds)
		)
	}
}

function getSecondsStr(seconds: number) {
	return (
		seconds +
		' ' +
		getRusNounByNumber(seconds, 'секунда', 'секунды', 'секунд')
	)
}

function getMinutesStr(minutes: number) {
	return (
		minutes + ' ' + getRusNounByNumber(minutes, 'минута', 'минуты', 'минут')
	)
}

function getHoursStr(hours: number) {
	return hours + ' ' + getRusNounByNumber(hours, 'час', 'часа', 'часов')
}

export type LessonStatStatus = 'progress' | 'success' | 'closed'

/**
 * Возвращает статус урока: в процессе прохождения, пройден или закрыт.
 * @param lesson — статистика по прохождению урока
 */
export function getLessonStatus(
	lesson: UserApiTypes.StudentLessonVisitStats
): LessonStatStatus {
	let status: LessonStatStatus = 'progress'

	if (lesson.status == 'success') {
		status = 'success'
	} else if (!lesson.isOpen) {
		status = 'closed'
	}

	return status
}
