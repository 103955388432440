import {
	Block,
	BlockOuter,
} from 'pages/landing/landingConstructor/sections/timeSection/TimeBlock/TimeBlock'
import timeBlocksManager from 'pages/landing/landingConstructor/sections/timeSection/TimeBlocksManager/timeBlocksManager'
import 'pages/landing/landingConstructor/sections/timeSection/TimeBlocks/TimeBlocks.scss'

// Обёртка с двумя колонками куда помещаются блоки с описанием чему научится человек после обучения.
function TimeBlocks() {
	const leftBlocks = timeBlocksManager.useGetColumnBlocks(1)
	const rightBlocks = timeBlocksManager.useGetColumnBlocks(2)

	let blockIdx = -1

	return (
		<div className="land-const-time-blocks">
			<div className="land-const-time-blocks__column">
				{leftBlocks.map((block) => {
					blockIdx++

					return (
						<BlockOuter block={block} key={block.id}>
							<Block
								block={block}
								blockIdx={blockIdx}
								key={block.id}
							/>
						</BlockOuter>
					)
				})}
			</div>
			<div className="land-const-time-blocks__column">
				{rightBlocks.map((block) => {
					blockIdx++

					return (
						<BlockOuter block={block} key={block.id}>
							<Block
								block={block}
								blockIdx={blockIdx}
								key={block.id}
							/>
						</BlockOuter>
					)
				})}
			</div>
		</div>
	)
}

export default TimeBlocks
