import useExerciseStore from '../zustand/exerciseState'
import ExerciseStateType from '../zustand/ExerciseStateType'

export const stateManager = {
	// getPassedQuestionsNumber,
	getStatementCounter,
	useGetCurrentStatement,
	getStatement,
	useGetGoToStatement,
	isMoveToStatementAllowed,
	useIsEveryStatementAnswered,
	useGetPassedStatementsNum,
}

/**
 * Функция возвращает количество отгаданных утверждений
 * @param statements
 */
/*function getPassedQuestionsNumber(statements: ExerciseStateType.Statement[]) {
	let passedQuestions = 0

	statements.forEach((statement) => {
		if (
			(statement.correct && statement.userAnswer === 'yes') ||
			(!statement.correct && statement.userAnswer === 'not')
		) {
			passedQuestions++
		}
	})

	return passedQuestions
}*/

/**
 * Возвращает строку с текущим номером утверждения и общим количеством. Например 2 из 5.
 * @param currentStatementIdx
 * @param statements
 */
export function getStatementCounter(
	currentStatementIdx: number,
	statements: ExerciseStateType.Statement[]
) {
	return currentStatementIdx + 1 + '/' + statements.length
}

/**
 * Возвращает объект текущего утверждения
 */
export function useGetCurrentStatement() {
	const statements = useExerciseStore((store) => store.statements)

	const currentStatementIdx = useExerciseStore(
		(store) => store.currentStatementIdx
	)
	return getStatement(statements, currentStatementIdx)
}

export function getStatement(
	statements: ExerciseStateType.Statement[],
	statementIdx: number
) {
	return statements[statementIdx] || null
}

/**
 * Возвращает функцию, перемещающую на предыдущее или следующее утверждение.
 * @param direction
 */
function useGetGoToStatement(direction: 'prev' | 'next') {
	const currentStatementIdx = useExerciseStore(
		(store) => store.currentStatementIdx
	)
	const statements = useExerciseStore((store) => store.statements)
	const saveStoreForRestore = useExerciseStore(
		(store) => store.saveStoreForRestore
	)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		const isAllowed = isMoveToStatementAllowed(
			direction,
			currentStatementIdx,
			statements
		)

		if (!isAllowed) return

		if (direction === 'prev') {
			updateStore({ currentStatementIdx: currentStatementIdx - 1 })
		} else {
			updateStore({ currentStatementIdx: currentStatementIdx + 1 })
		}

		updateStore({ saveStoreForRestore: saveStoreForRestore + 1 })
	}
}

/**
 * Возвращает булево значение можно ли перейти на предыдущее или следующее утверждение.
 * @param direction
 * @param currentStatementIdx
 * @param statements
 */
function isMoveToStatementAllowed(
	direction: 'prev' | 'next',
	currentStatementIdx: number,
	statements: ExerciseStateType.Statement[]
) {
	const mapper = {
		prev: currentStatementIdx > 0,
		next: currentStatementIdx < statements.length - 1,
	}

	return mapper[direction]
}

/**
 * Возвращает булево значение на каждое ли утверждение был дан ответ
 */
function useIsEveryStatementAnswered() {
	const statements = useExerciseStore((store) => store.statements)
	let isEveryStatementAnswered = true

	statements.forEach((statement) => {
		if (!statement.userAnswer) {
			isEveryStatementAnswered = false
		}
	})

	return isEveryStatementAnswered
}

/** Возвращает количество утверждений, на которое пользователь дал верный ответ */
function useGetPassedStatementsNum() {
	const statements = useExerciseStore((store) => store.statements)

	return statements.reduce((accumulator, statement) => {
		if (
			(statement.correct && statement.userAnswer === 'yes') ||
			(!statement.correct && statement.userAnswer === 'not')
		) {
			return accumulator + 1
		}

		return accumulator
	}, 0)
}
