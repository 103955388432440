const myPaymentsEn = {
	//СТРАНИЦА С НАСТРОЙКОЙ КАРТОЧКИ ОПЛАТЫ
	// Заголовок страницы после которой будет название системы оплаты
	paymentPageHeader: 'Payment system settings',

	// ДАННЫЕ ФОРМЫ РЕДАКТИРОВАНИЯ СИСТЕМЫ ОПЛАТЫ
	// Текст «Идентификатор магазина»
	paymentFormDataShopId: 'Store ID',
	// Текст «Содержит 16 символов»
	paymentFormDataContain_16_letters: 'Contains 16 characters',
	// Текст «Введите идентификатор магазина»
	paymentFormDataContainTypeShopId: 'Enter the store ID',
	// Текст «Введите ключ API»
	paymentFormDataContainTypeApiKey: 'Enter the API key',
	// Текст «Публикуемый ключ»
	paymentFormDataContainPublicKey: 'Published key',
	// Текст «Введите публикуемый ключ»
	paymentFormDataContainTypePublicKey: 'Enter the key to be published',
	// Текст «Введите секретный ключ»
	paymentFormDataContainTypeSecretKey: 'Enter the secret key',
	// Текст «Комиссия»
	paymentFormDataCommission: 'Commission',
	// Текст «Валюты платежей»
	paymentFormDataCurrenciesFrom: 'Payment currencies',
	// Текст «Подключают»
	paymentFormDataConnect: 'Connecting',
	// Текст «В каких странах работает»
	paymentFormDataWhichCountriesDoesItWork: 'In which countries it operates',
	// Текст с категорией лиц, которым доступна оплата: Частных лиц
	paymentFormDataCategoryIndividuals: 'Private individuals',
	// Текст с категорией лиц, которым доступна оплата: Юр. лиц
	paymentFormDataCategoryLegalEntity: 'Legal entities',
	// Текст с категорией лиц, которым доступна оплата: ИП
	paymentFormDataCategoryEntrepreneur: 'Small buisness',

	// СПИСОК КАРТОЧЕК ДЛЯ ПОДКЛЮЧЕНИЯ СИСТЕМ ОПЛАТ
	// Система оплаты подключена
	paymentsListConnectionStatusOn: 'Connected',
	// Система оплаты отключена
	paymentsListConnectionStatusOff: 'Disabled',

	// КАРТОЧКА С КРАТКОЙ ИНФОРМАЦИЕЙ О ПЛАТЁЖНОЙ СИСТЕМЕ
	// Кнопка «Настройки системы»
	paymentShortCardSettingsButton: 'System settings',
	// Кнопка «Подключить»
	paymentShortCardConnectButton: 'Connect',

	// ФОРМА РЕДАКТИРОВАНИЯ ПОДКЛЮЧЕНИЯ К ОДНОЙ ИЗ СИСТЕМ ОПЛАТЫ
	// Текст выше формы настройки системы оплаты
	editPaymentFormHeader: 'To connect, fill in the form',
	// Сообщение про обязательное поле.
	editPaymentFormRequiredField: 'This is a required field',
	// Кнопка отключения системы оплаты
	editPaymentFormSwitchOffSystemButton: 'Disable',
	// Кнопка сохранения формы
	editPaymentFormSaveButton: 'Save',
	// Кнопка подключения системы оплаты
	editPaymentFormOnButton: 'Connect',
	// Статус подключения платёжной системы: Включена
	editPaymentFormConnectionOn: 'Enabled',
	// Статус подключения платёжной системы: Отключена
	editPaymentFormConnectionOff: 'Disabled',
	// Сообщение про удачное сохранение данных формы
	editPaymentFormDataUpdated: 'Data saved',
	// Сообщение «Платёжная система подключена» после удачного запрос на подключение платёжной системы
	editPaymentFormPaySystemConnected: 'The payment system is connected',

	// МОДАЛЬНОЕ ОКНО ОТКЛЮЧЕНИЯ СИСТЕМЫ ОПЛАТЫ
	// Заголовок модального окна
	stopPaymentSystemModalHeader: 'Disabling the payment system',
	// Подпись поля ввода названия платёжной системы для подтверждения остановки
	stopPaymentSystemModalFieldNameLabel:
		'To disable the payment system, enter the name of the payment system',
	// Подпись поля ввода названия платёжной системы для подтверждения остановки
	stopPaymentSystemModalFieldNameRequired:
		'Write the name of the payment system to be disabled',
	// Предупреждение: Будьте внимательны!
	stopPaymentSystemModalWarningFirstLine: 'Pay attention!',
	// Предупреждение к чему приведёт отключение платёжной системы
	stopPaymentSystemModalWarningSecondLine:
		'The shutdown will result in the cancellation of accepting payments through this system.',
	// Кнопка отмены отключения платёжной системы
	stopPaymentSystemModalCancelButton: 'Cancellation',
	// Кнопка отключения платёжной системы
	stopPaymentSystemModalOkButton: 'Disconnect',
	// Сообщение об успешном отключении платёжной системы
	stopPaymentSystemModalSuccess: 'The payment system has been disabled.',
}

export default myPaymentsEn
