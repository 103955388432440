import { FormInstance, message } from 'antd'
import { AxiosError } from 'axios'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { FieldNames, FormValuesType } from './form'
import useAddTrainingModalStore from '../zustand/store'
import TrainingApiTypes from 'parts/requests/training/trainingApiTypes'
import { getFirstLoadedFileUrl } from 'ui/upload/UploadFileInForm/fn/fn'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import ServerTypes from 'parts/types/ServerTypes'
import { AddTrainingModalStoreType } from '../zustand/storeTypes'
import { TrainingListController } from 'widgets/trainingsListAdmin/TrainingListController'

export function useGetOnSubmit(form: FormInstance) {
	const { t } = useTranslation()
	const updateStore = useAddTrainingModalStore((state) => state.updateStore)

	const updateDataInTrainingsList = TrainingListController.useGetUpdate()

	// Объект с методом mutate для создания запроса
	const addTrainingMutation =
		trainingQuery.createOrUpdateTraining.useMutation({
			onMutate: getMutateOnBefore(updateStore),
			onError: getMutateOnError(updateStore, t),
			onSuccess: getMutateOnSuccess(
				updateStore,
				form,
				updateDataInTrainingsList,
				t
			),
			onSettled: getMutateOnAfter(updateStore),
		})

	return async function (values: FormValuesType) {
		addTrainingMutation.mutate(getDto(values))
	}
}

/**
 * Функция, выполняемая до запроса на добавление сотрудника в группы
 * @param updateStore
 */
function getMutateOnBefore(updateStore: AddTrainingModalStoreType.UpdateState) {
	return function () {
		// Поставить статус загрузки
		updateStore({ isSubmitting: true })
	}
}

/**
 * Функция, выполняемая при появлении ошибки после запроса на добавление сотрудника в группы
 * @param updateStore — функция изменяющая объект состояния модального окна
 * @param t
 */
function getMutateOnError(
	updateStore: AddTrainingModalStoreType.UpdateState,
	t: TFunction<'translation'>
) {
	return function (err: unknown) {
		const error = err as AxiosError<ServerTypes.ErrorResponse>

		if (error.response) {
			// Поставить данные ошибки в Состояние, чтобы показать их в форме
			updateStore({ formErrors: error.response.data })
		}

		message.error(t('adminTrainings.addTrainingModalCannotCreateDueError'))
	}
}

/**
 * Функция, выполняемая при успешном запросе на добавление сотрудника в группы
 * @param updateStore — функция изменяющая объект состояния модального окна
 * @param form — форма
 * @param updateDataInTrainingsList — функция, помечающая данные списка курсов неактуальными. После этого React Query автоматически скачает новый список
 */
function getMutateOnSuccess(
	updateStore: AddTrainingModalStoreType.UpdateState,
	form: FormInstance,
	updateDataInTrainingsList: () => void,
	t: TFunction<'translation'>
) {
	return function () {
		// Закрыть модальное окно
		updateStore({ isOpen: false })

		message.success(
			t('adminTrainings.addTrainingModalTrainingCreatedMessage')
		)

		// Пометить список курсов неактуальным.
		// После этого React Query автоматически скачает новый список
		updateDataInTrainingsList()

		form.resetFields()

		updateStore({ formErrors: {} })
	}
}

/**
 * Функция, выполняемая после запроса на добавление сотрудника в группы
 * @param updateStore — функция изменяющая объект состояния модального окна
 */
function getMutateOnAfter(updateStore: AddTrainingModalStoreType.UpdateState) {
	return function () {
		// Убрать статус загрузки
		updateStore({ isSubmitting: false })
	}
}

/**
 * Возвращает DTO для создания нового курса
 * @param {Object} values — данные введённые в форму
 */
function getDto(values: FormValuesType): TrainingApiTypes.CreateTrainingDto {
	return {
		name: values[FieldNames.Name],
		description: values[FieldNames.Description] || '',
		landingUrl: '', // Ссылка генерируется автоматически на сервере
		isPublished: false,
		cover: getFirstLoadedFileUrl(values[FieldNames.Cover]),
		lessons: [],
		groups: [],
		level: values[FieldNames.Level],
		lang: values[FieldNames.Lang],
		order: values[FieldNames.Order],
		type: values[FieldNames.BlockName],
		showOnMainScreen: values[FieldNames.ShowOnMain],
	}
}
