import { create } from 'zustand'
import { GiveImpressionFormStoreType } from './storeTypes'

const useGiveImpressionFormStore = create<GiveImpressionFormStoreType.State>(
	(set) => {
		return {
			isOpen: false,
			isSubmitting: false,
			expressionValue: '',

			/** Установщик новых значений корневого объекта. */
			updateStore(obj) {
				return set((state) => {
					return obj
				})
			},
		}
	}
)

export default useGiveImpressionFormStore
