import dayjs from 'dayjs'
import { message } from 'antd'
import AppUrls from 'parts/constants/pageUrl'
import Site from 'parts/constants/site'
import useLiveRoomStore from 'widgets/livelessons/liveRoomsList/zustand/store'
import useAddLiveModalStore from '../zustand/store'

export function getAdminId() {
	const schoolAdminId = useAddLiveModalStore((state) => state.schoolAdminId)
	return schoolAdminId
}

export function useCreateLivelessonLink() {
	return Site.protocol + Site.schoolDomain + AppUrls.LiveLessonEntrance().url
}

// Функции ниже возвращают значения полей формы созданного урока
export function getTitle(liveId: number) {
	const livelesson = getLiveLesson(liveId)!
	return livelesson && livelesson?.title
}

export function getScheduled(liveId: number) {
	const livelesson = getLiveLesson(liveId)!
	return livelesson ? livelesson?.scheduledFor : dayjs()
}

export function getTimeZone(liveId: number) {
	const livelesson = getLiveLesson(liveId)!
	return livelesson && livelesson?.timeZone
}

export function getTeacher(liveId: number) {
	const livelesson = getLiveLesson(liveId)!
	return livelesson && livelesson?.teacher.id
}

/* Возвращает массив строк с id учеников */
export function getStudentsIdsConvertedToStr(liveId: number): string[] {
	const studentIds = getStudentsIds(liveId)

	return studentIds.map((student) => student.toString())
}

export function getLink(liveId: number) {
	const livelesson = getLiveLesson(liveId)!
	return livelesson && livelesson?.link
}

function getLiveLesson(liveId: number) {
	const livelessonsAdmin = useLiveRoomStore((state) => state.liveRooms)
	return livelessonsAdmin.find((lesson) => lesson.id === liveId)
}

function getStudentsIds(liveId: number): number[] {
	const livelesson = getLiveLesson(liveId)!
	if (!livelesson || !livelesson.participants) return []

	return livelesson.participants.map((student) => {
		return student.id
	})
}

export function useGetPushTextToClipboard(text: string) {
	return function () {
		copyToClipboard(text)
	}
}

export async function copyToClipboard(text: string) {
	try {
		await navigator.clipboard.writeText(text)
		message.success('Скопировано в буфер обмена')
	} catch (err) {
		message.error('Не удалось скопировать строку в буфер обмена')
	}
}
