import { useMutation, useQuery } from 'react-query'
import { MutationOptions, QueryOptions } from '../common'
import AuthApiTypes from './authApiTypes'
import authRequests from './authRequest'

export const authQuery = {
	// Регистрация администратора школ
	registerAdmin: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(authRequests.registerAdmin, options)
		},
	},

	// Регистрация администратора школ вместе с созданием школы
	registerAdminWithSchool: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(authRequests.registerAdminWithSchool, options)
		},
	},

	// Регистрация ученика
	registerStudent: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(authRequests.registerStudent, options)
		},
	},

	// Отправка кода подтверждения почты на почту пользователя
	sendEmailCode: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(authRequests.sendEmailCode, options)
		},
	},

	// Обратная отправка кода подтверждения пользователем (все случаи кроме регистрации)
	checkEmailCode: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(authRequests.checkEmailCode, options)
		},
	},

	// Отправка кода пользователем, присланного на его почту для подтверждения существования почты при регистрации
	activateUserByCode: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(authRequests.activateUserByCode, options)
		},
	},

	// Вход пользователя
	login: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(authRequests.login, options)
		},
	},

	// Получение данных пользователя по почте и паролю
	userByCredentials: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(authRequests.userIdByCredentials, options)
		},
	},

	// Отправка токена для авторизации и получение данных пользователя
	refresh: {
		key: 'refresh',
		useQuery(options: QueryOptions<AuthApiTypes.Refresh> = {}) {
			return useQuery({
				queryKey: [this.key],
				queryFn: authRequests.refresh,
				...options,
			})
		},
	},

	// Вход пользователя
	exit: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(authRequests.exit, options)
		},
	},

	// Сброс refreshToken на сервере
	logout: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(authRequests.logout, options)
		},
	},

	// Отправляет на почту ссылку для смены/восстановления пароля
	recoverPasswordByEmail: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(authRequests.recoverPasswordByEmail, options)
		},
	},

	// Проверка правильности кода восстановления пароля
	checkRecoveryCode: {
		key: 'checkRecoveryCode',
		useQuery(
			code: string,
			options: QueryOptions<AuthApiTypes.CheckRecoveryCode> = {}
		) {
			return useQuery({
				queryKey: [this.key],
				queryFn: () => authRequests.checkRecoveryCode(code),
				...options,
			})
		},
	},

	changePasswordWithCode: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(authRequests.changePasswordWithCode, options)
		},
	},

	// Изменение собственного пароля текущим пользователем (администратор свой пароль меняет другим методом)
	updatePasswordOfCurrentUser: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(
				authRequests.updatePasswordOfCurrentUser,
				options
			)
		},
	},

	// Изменение пароля пользователя с переданным идентификатором (обновляется администратором)
	updatePasswordOfUserById: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(authRequests.updatePasswordOfUserById, options)
		},
	},

	// Генерирование токена для последующей авторизации текущего пользователя.
	// Токен можно передать как зашифрованную строку и через POST /auth/login-by-token получить токен авторизации в куках.
	generateAuthToken: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(authRequests.generateAuthToken, options)
		},
	},

	// Получение токена авторизации посредством сгенерированного токена методом POST /auth/generate-token.
	loginByGeneratedToken: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(authRequests.loginByGeneratedToken, options)
		},
	},

	// Отправка запроса на удаление аккаунта
	deleteAccountRequest: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(authRequests.deleteAccountRequest, options)
		},
	},

	// Удаление аккаунта
	deleteAccount: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(authRequests.deleteAccount, options)
		},
	},
}
