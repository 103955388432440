const myPaymentsRu = {
	//СТРАНИЦА С НАСТРОЙКОЙ КАРТОЧКИ ОПЛАТЫ
	// Заголовок страницы после которой будет название системы оплаты
	paymentPageHeader: 'Настройки системы оплаты',

	// ДАННЫЕ ФОРМЫ РЕДАКТИРОВАНИЯ СИСТЕМЫ ОПЛАТЫ
	// Текст «Идентификатор магазина»
	paymentFormDataShopId: 'Идентификатор магазина',
	// Текст «Содержит 16 символов»
	paymentFormDataContain_16_letters: 'Содержит 16 символов',
	// Текст «Введите идентификатор магазина»
	paymentFormDataContainTypeShopId: 'Введите идентификатор магазина',
	// Текст «Введите ключ API»
	paymentFormDataContainTypeApiKey: 'Введите ключ API',
	// Текст «Публикуемый ключ»
	paymentFormDataContainPublicKey: 'Публикуемый ключ',
	// Текст «Введите публикуемый ключ»
	paymentFormDataContainTypePublicKey: 'Введите публикуемый ключ',
	// Текст «Введите секретный ключ»
	paymentFormDataContainTypeSecretKey: 'Введите секретный ключ',
	// Текст «Комиссия»
	paymentFormDataCommission: 'Комиссия',
	// Текст «Валюты платежей»
	paymentFormDataCurrenciesFrom: 'Валюты платежей',
	// Текст «Подключают»
	paymentFormDataConnect: 'Подключают',
	// Текст «В каких странах работает»
	paymentFormDataWhichCountriesDoesItWork: 'В каких странах работает',
	// Текст с категорией лиц, которым доступна оплата: Частных лиц
	paymentFormDataCategoryIndividuals: 'Частных лиц',
	// Текст с категорией лиц, которым доступна оплата: Юр. лиц
	paymentFormDataCategoryLegalEntity: 'Юр. лиц',
	// Текст с категорией лиц, которым доступна оплата: ИП
	paymentFormDataCategoryEntrepreneur: 'ИП',

	// СПИСОК КАРТОЧЕК ДЛЯ ПОДКЛЮЧЕНИЯ СИСТЕМ ОПЛАТ
	// Система оплаты подключена
	paymentsListConnectionStatusOn: 'Подключена',
	// Система оплаты отключена
	paymentsListConnectionStatusOff: 'Отключена',

	// КАРТОЧКА С КРАТКОЙ ИНФОРМАЦИЕЙ О ПЛАТЁЖНОЙ СИСТЕМЕ
	// Кнопка «Настройки системы»
	paymentShortCardSettingsButton: 'Настройки системы',
	// Кнопка «Подключить»
	paymentShortCardConnectButton: 'Подключить',

	// ФОРМА РЕДАКТИРОВАНИЯ ПОДКЛЮЧЕНИЯ К ОДНОЙ ИЗ СИСТЕМ ОПЛАТЫ
	// Текст выше формы настройки системы оплаты
	editPaymentFormHeader: 'Для подключения заполните форму',
	// Сообщение про обязательное поле.
	editPaymentFormRequiredField: 'Это обязательное поле',
	// Кнопка отключения системы оплаты
	editPaymentFormSwitchOffSystemButton: 'Отключить',
	// Кнопка сохранения формы
	editPaymentFormSaveButton: 'Сохранить',
	// Кнопка подключения системы оплаты
	editPaymentFormOnButton: 'Подключить',
	// Статус подключения платёжной системы: Включена
	editPaymentFormConnectionOn: 'Включена',
	// Статус подключения платёжной системы: Отключена
	editPaymentFormConnectionOff: 'Отключена',
	// Сообщение про удачное сохранение данных формы
	editPaymentFormDataUpdated: 'Данные сохранены',
	// Сообщение «Платёжная система подключена» после удачного запрос на подключение платёжной системы
	editPaymentFormPaySystemConnected: 'Платёжная система подключена',

	// МОДАЛЬНОЕ ОКНО ОТКЛЮЧЕНИЯ СИСТЕМЫ ОПЛАТЫ
	// Заголовок модального окна
	stopPaymentSystemModalHeader: 'Отключение системы оплаты',
	// Подпись поля ввода названия платёжной системы для подтверждения остановки
	stopPaymentSystemModalFieldNameLabel:
		'Для отключения системы оплаты введите ее название',
	// Подпись поля ввода названия платёжной системы для подтверждения остановки
	stopPaymentSystemModalFieldNameRequired:
		'Напишите название отключаемой платёжной системы',
	// Предупреждение: Будьте внимательны!
	stopPaymentSystemModalWarningFirstLine: 'Будьте внимательны!',
	// Предупреждение к чему приведёт отключение платёжной системы
	stopPaymentSystemModalWarningSecondLine:
		'Отключение приведет к отмене приема платежей через эту систему.',
	// Кнопка отмены отключения платёжной системы
	stopPaymentSystemModalCancelButton: 'Отмена',
	// Кнопка отключения платёжной системы
	stopPaymentSystemModalOkButton: 'Отключить',
	// Сообщение об успешном отключении платёжной системы
	stopPaymentSystemModalSuccess: 'Платёжная система отключена.',
}

export default myPaymentsRu
