import { getFormState } from '../../common/exerciseFormCommonFunc'
import { TestExerciseFormStateType } from './stateTypes'

/** Функция перебирает объект состояния формы и возвращает булево значение есть ли там файл загружаемый в настоящий момент. */
export function isDownloadingFile() {
	const formState = getFormState<TestExerciseFormStateType>()

	for (let i = 0; i < formState.questions.length; i++) {
		const { files } = formState.questions[i]

		if (
			files.audio.status == 'downloading' ||
			files.image.status == 'downloading' ||
			files.video.status == 'downloading'
		) {
			return true
		}
	}

	return false
}

/**
 * Функция принимает объект состояния формы и находит объект вопроса по переданному идентификатору
 * @param {Object} formState — объект состояния формы
 * @param {Number} questionId — id объекта вопроса
 */
export function getQuestionById(
	formState: TestExerciseFormStateType,
	questionId: number
) {
	return formState.questions.find((question) => question.id == questionId)
}

/**
 * Функция принимает объект состояния формы и находит объект ответа по переданному идентификатору
 * @param {Object} formState — объект состояния формы
 * @param {Number} questionId — id объекта вопроса с искомым ответом
 * @param {Number} answerId — id объекта ответа
 */
export function getAnswerById(
	formState: TestExerciseFormStateType,
	questionId: number,
	answerId: number
) {
	const question = getQuestionById(formState, questionId)

	if (!question) return null

	const answer = question.answers.find((answer) => {
		return answer.id == answerId
	})

	return answer || null
}

/**
 * Функция проверяет, что как минимум 1 ответ в вопросе помечен правильным
 * @param {Object} formState — объект состояния формы
 * @param {Number} questionId — id объекта вопроса, где ищется ответ отмеченный как правильный
 */
export function isAtLeastAnswerChecked(
	formState: TestExerciseFormStateType,
	questionId: number
) {
	let isAtLeastChecked = false

	const question = getQuestionById(formState, questionId)
	if (!question) return false

	question.answers.forEach((answer) => {
		if (answer.text && answer.correct) {
			isAtLeastChecked = true
		}
	})

	return isAtLeastChecked
}

/**
 * Функция проверяет, что как минимум два ответа в массиве вопросов имеют текст ответа.
 * @param {Object} formState — объект состояния формы.
 * @param {Number} questionId — id объекта вопроса, где ищется ответ отмеченный как правильный
 */
export function isQuestionHasAtLeastTwoAnswersWithText(
	formState: TestExerciseFormStateType,
	questionId: number
) {
	let answerBlockWithTextCount = 0

	// Проверка того, что в блоках ответов есть как минимум два ответа с введённым текстом.
	const question = getQuestionById(formState, questionId)
	if (!question) return false

	question.answers.forEach((answer) => {
		if (answer.text) {
			answerBlockWithTextCount++
		}
	})

	return answerBlockWithTextCount >= 2
}
