import MyPaymentContent from '../MyPaymentContent/MyPaymentContent'
import { useGetState } from '../state/state'
import { StateContext } from '../state/stateContext'

/** Страница с формой настройки подключения к конкретной платёжной системе */
function MyPaymentArticle() {
	const { state, setState } = useGetState()

	return (
		<StateContext.Provider value={{ state, setState }}>
			<MyPaymentContent />
		</StateContext.Provider>
	)
}

export default MyPaymentArticle
