import { HeaderRouteType } from 'ui/pageHeader/PageHeader/fn/types'
import AppUrls from 'parts/constants/pageUrl'

/** Возвращает массив хлебных крошек. Функция требуется потому что в обычной константе есть проблемы с i18n. */
export function getBreadcrumbs(): HeaderRouteType[] {
	return [
		{
			path: AppUrls.Trainings().url,
			breadcrumbName: AppUrls.Trainings().name,
		},
	]
}
