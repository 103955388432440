import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { EmptyBlock } from 'ui/EmptyBlock/EmptyBlock'
import { AddLessonModalController } from 'widgets/lessonsListAdmin/AddLessonModal/AddLessonModalController'

// Компонент сообщающий об отсутствии уроков в курсе
function NoLessonsScreen() {
	return (
		<EmptyBlock
			text={
				<>
					Пока не создано ни одного урока.
					<br />
					Пора приниматься за творчество!
				</>
			}
			button={<AddLessonButton />}
		/>
	)
}

export default NoLessonsScreen

function AddLessonButton() {
	return (
		<Button
			type="primary"
			icon={<PlusOutlined />}
			onClick={AddLessonModalController.open}
		>
			Добавить
		</Button>
	)
}
