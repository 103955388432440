const employeeRu = {
	// ФОРМА ИЗМЕНЕНИЯ ДАННЫХ СОТРУДНИКА
	// Заголовок формы
	employeeFormHeader: 'Данные сотрудника',

	// Подпись поля «Должность»
	employeeFormRoleLabel: 'Должность',
	// Сообщение про необходимость указания значения в поле «Должность»
	employeeFormRoleInputRequired: 'Укажите должность',
	// Пункт «Не выбрана» выпадающего списка «Должность»
	employeeFormRoleInputNotSelect: 'Не выбрана',
	// Пункт «Куратор» выпадающего списка «Должность»
	employeeFormRoleInputCurator: 'Куратор',
	// Пункт «Менеджер» выпадающего списка «Должность»
	employeeFormRoleInputManager: 'Менеджер',
	// Подпись поля «Имя»
	employeeFormNameLabel: 'Имя',
	// Подпись поля «Фамилия»
	employeeFormLastNameLabel: 'Фамилия',
	// Подпись поля «Страна»
	employeeFormCountryLabel: 'Страна',
	// Подпись поля «Город»
	employeeFormCityLabel: 'Город',
	// Подпись поля «Телефон»
	employeeFormPhoneLabel: 'Телефон',
	// Кнопка «Изменение почты...»
	employeeFormChangeEmailBtn: 'Изменение почты...',
	// Кнопка «Отменить»
	employeeFormCancelBtn: 'Отменить',
	// Кнопка «Сохранить»
	employeeFormOkBtn: 'Сохранить',
	// Сообщение «Данные сохранены» после отправки формы
	employeeFormDataWasSaved: 'Данные сохранены',

	// ВЫПАДАЮЩИЙ СПИСОК ДЕЙСТВИЙ НАД СОТРУДНИКОМ
	// Заголовочный пункт «Действия» выпадающего списка
	actionListHeaderItem: 'Действия',
	// Пункт выпадающего списка «Отправить доступ»
	actionListItemSendAccess: 'Отправить доступ',
	// Пункт выпадающего списка «Отправить доступ»
	actionListItemEdit: 'Редактировать',
	// Пункт выпадающего списка «Удалить»
	actionListItemDelete: 'Удалить',
}

export default employeeRu
