import React, { useCallback, useContext, useState } from 'react'
import { AxiosError } from 'axios'
import { message } from 'antd'
import ServerTypes from 'parts/types/ServerTypes'
import { useTranslation } from 'react-i18next'
import { MySchoolsListController } from '../../../../../../widgets/MySchoolsList/MySchoolsListController'
import { StateContext } from '../../state/stateContext'
import { StateType } from '../../state/stateType'
import schoolRequests from 'parts/requests/school/schoolRequest'
import { useGoToPage } from 'parts/utils/hooks/hooks'
import AppUrls from 'parts/constants/pageUrl'

export interface FormValues {
	schoolName: string
}

/** Функция возвращающая данные для работы формы */
export function useManageForm() {
	// В случае ошибки сюда попадут данные ошибки
	const [formErrors, setFormErrors] = useState<ServerTypes.ErrorResponse>({})

	// Обработчик отправки формы
	const onSubmit = useGetOnSubmit(setFormErrors)

	return {
		onSubmit,
		formErrors,
	}
}

/**
 * Хук возвращающий обработчик отправки формы
 * @param {Function} setFormErrors — функция устанавливающая объект ошибок в Состояние ошибок
 */
function useGetOnSubmit(
	setFormErrors: React.Dispatch<React.SetStateAction<{}>>
) {
	const { state, setState } = useContext(StateContext)
	const goToMySchools = useGoToPage(AppUrls.MySchools().url)
	const { t } = useTranslation()
	const updateDataInSchoolsList = MySchoolsListController.useGetUpdate()

	return useCallback(async (values: FormValues) => {
		try {
			setState((state): StateType.State => {
				return { ...state, removeSchoolSubmitting: true }
			})

			// Отправить запрос и получить ответ
			const response = await schoolRequests.deleteSchool(state.school!.id)

			if (response.data) {
				// Закрыть модальное окно
				setState((state): StateType.State => {
					return { ...state, isRemoveSchoolModalOpen: false }
				})

				// Показать уведомление об удалении школы
				message.success(t('mySchools.deleteSchoolModalSuccessMessage'))

				// Перебросить на страницу всех школ
				goToMySchools()

				updateDataInSchoolsList()
			}
		} catch (err) {
			const error = err as AxiosError<ServerTypes.ErrorResponse>

			if (error.response) {
				// Поставить данные ошибки в Состояние, чтобы показать их в форме
				setFormErrors(error.response.data)
			}
		} finally {
			// Убрать статус загрузки
			setState((state) => {
				return { ...state, createNewSchoolSubmitting: false }
			})
		}
	}, [])
}
