import adminTrainingRu from './adminTrainingRu'
import answersFeedRu from './answersFeedRu'
import authRu from './authRu'
import courseCreateRu from './courseCreateRu'
import courseTeachRu from './courseTeachRu'
import employeeRu from './employeeRu'
import exercisesRu from './exercisesRu'
import filesRu from './filesRu'
import expressRu from './expressRu'
import leadsRu from './leadsRu'
import liveRu from './liveRu'
import mainMenuRu from './mainMenuRu'
import adminTrainingsRu from './adminTrainingsRu'
import myPaymentsRu from './myPaymentsRu'
import mySchoolsRu from './mySchoolsRu'
import staffRu from './staffRu'
import studentRu from './studentRu'
import studentsRu from './studentsRu'
import studentTrainingRu from './studentTrainingRu'
import studentTrainingsRu from './studentTrainingsRu'
import urlRu from './url'

const mainRu = {
	url: urlRu,
	mainMenu: mainMenuRu,
	adminTrainings: adminTrainingsRu,
	studentTrainings: studentTrainingsRu,
	adminTraining: adminTrainingRu,
	studentTraining: studentTrainingRu,
	students: studentsRu,
	student: studentRu,
	leads: leadsRu,
	answersFeed: answersFeedRu,
	myPayments: myPaymentsRu,
	mySchools: mySchoolsRu,
	auth: authRu,
	exercises: exercisesRu,
	express: expressRu,
	courseTeach: courseTeachRu,
	courseCreate: courseCreateRu,
	staff: staffRu,
	employee: employeeRu,
	files: filesRu,
	live: liveRu,
}

export default mainRu
