const courseCreateEn = {
	// КУРС Как проводить Как создавать курсы на Credu
	// Заголовок курса
	mainTitle: 'How to create courses on Credu',
	// Подзаголовок курса
	mainSubtitle: 'By taking the course you will learn',

	// УРОКИ КУРСА Как создавать курсы на Credu
	lesson1Title: 'How to create a course',
	lesson1Subtitle:
		'You can create a course on Credu in half an hour, especially if you already have materials prepared. Find out how to do it.  ',
	lesson1Block1:
		'Important: First, think about the logic of the course: what it will be about, how many lessons and what exercises you will use in the lessons. Remember that at any time you can make the necessary changes literally in two clicks! The rest is a matter of technique.',
	lesson1Block2: "So, let's get to creating the course!",
	lesson1Block3:
		'To create a course after creating a school you need to log in to your school\'s account, go to the courses section and click on the "Add" button.',
	lesson1Block4:
		'After that you need to come up with a title for the course. We also recommend you to create a short description of the course and choose a cover picture for it. The cover is added after clicking the "Upload" button.  Uploading an image can be done by selecting and adding a file from the desktop via the "+" symbol or by dragging the required image to the "+" symbol area.',
	lesson1Block5:
		"If you are just testing the platform - don't worry, any content can be changed or added later.",
	lesson1Block6:
		'After clicking the "Save" button, your course will be saved in your online school and you can proceed to its content - lessons, exercises, schedule, etc.',

	lesson2Title: 'How do I set up payment acceptance?',
	lesson2Subtitle:
		'We are constantly working to expand our lineup of partners to accept payments from students. Find out what payment options are available now and how to set them up.',
	lesson2Block1:
		"Payment systems are ready-made mechanisms with which you can receive money for lessons and courses from your students. There are different payment systems, so far we cooperate with two, but the list of partners will be expanded in the near future. If you can't use the offered payment systems for some reason - we can help you with payment via Credu.",
	lesson2Block2: 'How to connect Yookassa payment acceptance',
	lesson2Block3:
		' To connect to accept payments via YooKassa (formerly known as Yandex.Kassa), follow these general steps:',
	lesson2Block4:
		'Creating an account: If you don\'t already have a YooKassa account, you will need to register on their platform here https://yookassa.ru . Follow the steps outlined on the YooKassa website in the guide: confirm your phone number, create a password and click "Done". Pass the verification, conclude the contract and receive an electronic store ID and API key.',
	lesson2Block5:
		'Payment Confirmation: Depending on the payment method selected, some payments may require your confirmation (e.g., payment via bank card). You will need to implement a payment confirmation mechanism.',
	lesson2Block6:
		'Important! Testing: before proceeding to real payments, it is recommended to test the integration in a special YooKassa test environment. This will help to make sure that the system processes payments correctly.',
	lesson2Block7:
		'Getting started: After successfully testing and making sure everything works as expected, you can move on to using YooKassa to accept real payments from your EN customers.',
	lesson2Block8: 'How to connect Stripe payment acceptance',
	lesson2Block9:
		'To connect to accept payments via Stripe, follow these general steps:',
	lesson2Block10:
		" Creating an account: If you don't already have a Stripe account, you will need to register on their platform here https://stripe.com/ru . Follow the steps outlined on the Stripe website:",
	lesson2Block11_1: 'Leave a request for connection',
	lesson2Block11_2: 'Wait for a reply from a personal manager',
	lesson2Block11_3:
		'Familiarize yourself with the list of required documents',
	lesson2Block11_4: 'Get access to your personal cabinet',
	lesson2Block11_5: 'Perform technical integration of the site',
	lesson2Block11_6:
		'Make test payments. Notify your manager about successful tests to put your site into combat mode',
	lesson2Block11_7:
		'Accept online payments on the website using bank cards as well as one-click payments',
	lesson2Block12: 'How to connect to receive payments from Credu',
	lesson2Block13:
		'If for some reason the existing integrations with payment systems do not suit you, Credu.ai offers a service for receiving payments from students and transferring this amount to your account. To find out more, please contact Credu support, we will help you.',

	lesson3Title: 'What are groups?',
	lesson3Subtitle:
		'Groups on Credu - like regular groups in offline schools - are a collection of number of students in one entity. Learn how to create and work with groups.',
	lesson3Block1:
		'To create a group, you need to go to My Courses - Group Settings and click the "Add Group" button if this is your first group in the course.',
	lesson3Block2:
		'When creating a group, you should give it a name (mandatory) and specify the number of available seats in the group (optional). All created groups can always be deleted.',
	lesson3Block3:
		'You can create as many groups as you need based on your needs and learning environment. To create new groups for a course that already has groups, you must also go to the desired course and add a group via "Group Settings".',
	lesson3Block4:
		'Important: To work with learners on your courses, you need to create learning groups. All settings are applied to the group. What does this mean? You have created a course, but you enroll participants in the course several times a year. For example, spring, summer, and fall. This means that the course itself will be unchanged, but the groups and settings may be different.',

	lesson4Title: 'Course settings',
	lesson4Subtitle:
		'Course settings will help you to calculate the right opportunities, put the necessary time and quantity constraints, if necessary! Learn how to do it right.',
	lesson4Block1:
		'Before you can proceed to course settings, you need to create a group. To create a group, go to the "My Courses" section, select the desired course, click on "Group Settings" and click on "Add Group" on the top right. In the window that opens, enter the group name and number of students.',
	lesson4Block2:
		"Important: Do not duplicate the group name one-to-one with the course name. This will help avoid confusion. Adding the start date of the group's course may be a good solution.",
	lesson4Block3:
		'To access the course settings, you need to go to the "Courses" section and select the desired course. Then click on the button "Settings" and select the section "Terms and Conditions".',
	lesson4Block4:
		'In the "Course Conditions" you can select the course start date (future selection is possible), the date and time of the training start (mandatory fields), the time when lessons open (by intervals or by days), the duration of the training and the time of access to the course. All these settings depend on the learning logic of your course.',
	lesson4Block5:
		'Important: the maximum time to complete the course is currently 365 days.',
	lesson4Block6:
		'The second part of course customization relates to who is working on the course and in what roles. Go to the Managers and Mentors subsection and view the roles of your employees.',
	lesson4Block7:
		'In this section you can also initiate the deletion of an employee or the transfer of students from one employee to another.  To transfer pupils, click on the "Transfer" button next to the employee\'s name, in the newly opened window select from which employee and to which you want to transfer pupils, and confirm your intention by clicking on the "Transfer" button.',
	lesson4Block8: 'Video on how to finalize the settings of a created group:',

	lesson5Title: 'How do I add employees to the platform?',
	lesson5Subtitle:
		"Your staff are the backbone of your school and courses. Find out how to add a member of staff to your school on Credu if you're not working alone.",
	lesson5Block1:
		'You can add and remove staff members from your school as often as needed. To add a staff member you need to log in to your account and go to the desired school. Then click on "Staff" in the navigation menu on the left and click the "Add" button at the top right.',
	lesson5Block2:
		'Fill in all fields and click "Save". In the same window you can give access to your personal cabinet. This can be done later. After you have saved the employee\'s data, he/she will be displayed in the list of employees of this school. After you add a staff member, access to Credu.ai: login and password will be sent to the e-mail address you specified.',
	lesson5Block3:
		'Once an employee logs into the Credu.ai system, their status will change to "Active".',
	lesson5Block4:
		'You can view a list of all employees in the Employees section.',
	lesson5Block5:
		'Clicking on a particular employee will open all their data, which you can change/add/delete at any time.',

	lesson6Title: 'Adding managers and supervisors to the course',
	lesson6Subtitle:
		"If you're not working alone, you may need to assign roles within your team. Learn how the role of manager differs from the role of administrator.",
	lesson6Block1:
		'On the Credu.ai platform you can work both individually and as a team. If there are several people in your team, you can add them to the school on different rights. You can view the roles of the employees in the "Employees" section. There are three team roles in total: administrator, manager, and supervisor.',
	lesson6Block2:
		'Important! Please note that you should not give your login and password to your employees. It is much safer to give roles to your employees to avoid misunderstandings and to resolve conflicts more quickly. Only the administrator can create/add and delete schools, courses, and students. It is always the responsibility of the administrator to delete any blocks or elements. This is why we do not recommend sharing administrator access with employees.',
	lesson6Block3:
		'Administrator - the person who has all the rights. Traditionally, he/she has either registered on the Credu platform and created the school(s)/courses or has access to the account.',
	lesson6Block4:
		'A manager is a person who can manage processes and students.  The manager is appointed by the administrator in the "Staff" section. A manager can assign a supervisor.',
	lesson6Block5:
		'A curator is essentially a methodologist. He cannot delete or make changes to anything other than the exercises in the course. But he (like the administrator and the manager) has a function to communicate directly with students.',
	lesson6Block6: 'Assign a supervisor to the course:',
	lesson6Block7:
		'To assign a mentor to a course, you need to go to "My Courses", select the desired course, and click "Customize".',
	lesson6Block8: 'Then go to the "Mentors and Managers" subsection.',
	lesson6Block9:
		'A list of current supervisors and course manager will be available to you.',
	lesson6Block10:
		'To add a new supervisor to a course or group (they are pulled from the list of employees), you need to click on the "Add Employee" button and select the desired employee with the role of "Supervisor" in the pop-up window. Click the "Add" button.',
	lesson6Block11: 'Assigning a manager to the course:',
	lesson6Block12:
		'If you want to assign a manager role to an employee or change it, click on the data for a specific employee. In the window that opens, click on "Actions" and then on "Edit".',
	lesson6Block13:
		'In the window that opens, change the role if necessary. Click "Save".',
	lesson6Block14:
		'Important: The administrator may appoint one or more managers based on the size of the school and its needs. There may be as many managers as there are supervisors if necessary to meet course objectives.',

	lesson7Title: 'Adding students to the course ',
	lesson7Subtitle:
		'You can add students to your course either manually or automatically. Learn the differences between these methods.',
	lesson7Block1:
		'Learners are the main users of our products. A learner is added to your course automatically or manually.',
	lesson7Block2:
		'An apprentice who has made a product purchase on Credu will be added automatically. His/her data will be pulled up in the "Apprentices" section and you will always be able to find his/her profile.',
	lesson7Block3:
		'If you have students who have purchased a product elsewhere and want to add them to your student base on Credu, it is extremely easy and manual to do so.',
	lesson7Block4:
		'To add a student, you need to go to the "Students" section and click on the "Add" button. Enter all the data for the student manually in the window that opens. The mandatory field is the e-mail address, all other fields are auxiliary. You can add the other fields at any time later by editing the student data.',
	lesson7Block5:
		'Important! The more student data you collect, the easier it will be for you to analyze your audience in general!',
	lesson7Block6:
		'In addition to the basic data, from name to purchase details, you can also select a supervisor for this student in the same window. You can also add information about the purchase or purchases of this student. This always makes sense, as the whole history will be as if in the palm of your hand.',
	lesson7Block7:
		'After entering the data, click "Save". The student will be added.',
	lesson7Block8:
		'Important: You can always correct the student data (add, change, add purchases manually). It does not matter what method the student was originally added to the database.',

	lesson8Title: 'Learner card - how to use it?',
	lesson8Subtitle:
		'The student card contains data for general and individual analysis. Learn how to work with the information in the student card.',
	lesson8Block1:
		'An individual card is automatically created, saved and updated for each student.  It contains information about purchases, visits and progress in learning.',
	lesson8Block2:
		'To view a student\'s card, you need to go to the "Students" section, select the desired student by e-mail address or manually in the general list, click on him/her. After that a new page will open with individual information about this student in the sections "Orders", "Attendance" and "Activity log".  That is, you can track virtually every action of a particular student and understand where there are problems and what can be improved.',
	lesson8Block3:
		'If the student has provided data, the cell phone and Instagram account sections will be filled in. At any time you can make changes to the student\'s card via the "Edit" tool or perform the necessary actions on the student\'s account.',
	lesson8Block4:
		'Important! The more student data you collect, the easier it will be for you to analyze your audience in general!  ',

	lesson9Title: 'Any other questions?',
	lesson9Subtitle:
		"Credu's support team will be happy to answer any questions. Learn about how it works!",
	lesson9Block1:
		'There are no stupid questions, there are questions that were not asked at the right time. Our support team will always listen and try to help. You can contact it on the platform via the "Support" symbol (a window with a blue message). You will most likely get a response within an hour or two (except weekends). Chats with support are saved. You can also always email us at support@credu.ai.',
}

export default courseCreateEn
