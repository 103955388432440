import { useParams } from 'react-router-dom'
import { RouterVarNames } from '../../../../app/routes/special/otherRoutes'
import UrlService from 'parts/services/UrlService'
import exercisesViewsManager from '../../../../widgets/exercisesViewsNew/common/exercisesViewsManager'
import useExerciseStudentStore from '../../zustand/store'

export function prevAndNextExerciseUrls() {
	const params = useParams()
	const trainingIdStr = params[RouterVarNames.TrainingId]!
	const lesson = useExerciseStudentStore((state) => state.lesson)
	const exerciseId = UrlService.useGetExerciseId()

	const prevExerciseUrl = exercisesViewsManager.getPrevOrNextExerciseUrl(
		trainingIdStr,
		lesson,
		exerciseId,
		'prev'
	)

	const nextExerciseUrl = exercisesViewsManager.getPrevOrNextExerciseUrl(
		trainingIdStr,
		lesson,
		exerciseId,
		'next'
	)

	return {
		prevExerciseUrl,
		nextExerciseUrl,
	}
}
