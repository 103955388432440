import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import LessonStudentService from 'parts/services/LessonStudentService'
import AppUrls from 'parts/constants/pageUrl'
import TrainingStudentService from 'parts/services/TrainingStudentService'

/** Загружает данные курса и урока. Если в уроке есть упражнения, то переадресовывает на первое упражнение. */
export function useRedirectToExercisePageILessonHasExercises() {
	const navigate = useNavigate()

	const trainingQuery = TrainingStudentService.useGetTraining()
	const lesson = LessonStudentService.useGetLesson()

	useEffect(
		function () {
			if (!trainingQuery.data || !lesson || !lesson.exercises.length)
				return

			const exerciseUrl = AppUrls.Exercise(
				trainingQuery.data?.data.trainingId,
				lesson.id,
				lesson.exercises[0].id!
			).url

			navigate(exerciseUrl)
		},
		[trainingQuery.data, lesson]
	)
}
