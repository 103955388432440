import { useCallback } from 'react'
import { AxiosResponse } from 'axios'
import useSendCodeBtnStore, { timeSession } from '../zustand/store'

/** Возвращает обработчик кнопки запуска функции повторной отправки кода.
 * После этого ставится secondsLeft что приводит к её блокировке на какое-то время.
 * */
export function useGetSendCode(reqFn: () => Promise<AxiosResponse<boolean>>) {
	const updateStore = useSendCodeBtnStore((state) => state.updateStore)

	return useCallback(async function () {
		await reqFn()

		updateStore({ secondsLeft: timeSession })
	}, [])
}
