import { ReactNode } from 'react'
// import { Button } from 'antd'
// import cn from 'classnames'
import ControlsFrame from 'pages/landing/landingConstructor/details/ControlsFrame/ControlsFrame'
import { LandingTypes } from 'parts/types/LandingTypes'
import EditableText from 'pages/landing/landingConstructor/details/editableElems/EditableText/EditableText'
import EditableImage from 'pages/landing/landingConstructor/details/editableElems/EditableImage/EditableImage'
// import programBlocksManager from '../programBlocksManager/programBlocksManager'
import bulletsBlockManager from 'pages/landing/landingConstructor/sections/programSection/bulletsSections/bulletsBlockManager/bulletsBlockManager'
import { useGetAddNewBullet } from 'pages/landing/landingConstructor/sections/programSection/bulletsSections/bulletsBlockManager/addNewBlock'
import 'pages/landing/landingConstructor/sections/programSection/bulletsSections/Bullet/Bullet.scss'

type BulletOuterProps = {
	// Индекс блока с пунктами
	blockIdx: number
	// Пункт блока
	bullet: LandingTypes.ProgramBullet
	// Сюда будет передаваться блок с пунктами
	children: ReactNode
}

export function BulletOuter(props: BulletOuterProps) {
	const { blockIdx, bullet, children } = props

	const addNewBulletInLeft = bulletsBlockManager.useGetAddNewBullet(
		blockIdx,
		bullet.id,
		'top'
	)
	const addNewBulletInRight = bulletsBlockManager.useGetAddNewBullet(
		blockIdx,
		bullet.id,
		'bottom'
	)
	const deleteBlock = bulletsBlockManager.useGetDeleteBullet(
		blockIdx,
		bullet.id
	)

	return (
		<ControlsFrame
			leftAddBtnHandler={addNewBulletInLeft}
			rightAddBtnHandler={addNewBulletInRight}
			deleteBtnHandler={deleteBlock}
		>
			{children}
		</ControlsFrame>
	)
}

type BulletProps = {
	blockIdx: number
	bulletIdx: number
}

export function Bullet(props: BulletProps) {
	const { blockIdx, bulletIdx } = props

	return (
		<article className="land-const-prog-bullet">
			<div className="land-const-prog-bullet__img-part">
				<EditableImage
					propsPathArr={[
						'sections',
						'program',
						'bulletsBlocks',
						blockIdx,
						'bullets',
						bulletIdx,
						'imageUrl',
					]}
				/>
			</div>
			<p className="land-const-prog-bullet__text">
				<EditableText
					propsPathArr={[
						'sections',
						'program',
						'bulletsBlocks',
						blockIdx,
						'bullets',
						bulletIdx,
						'text',
					]}
				/>
			</p>
		</article>
	)
}
