import cn from 'classnames'
import Price from '../Price/Price'
import { TariffsStoreType } from '../../zustand/storeTypes'
import ShowSettingsPanelBtn from '../ShowSettingsPanelBtn/ShowSettingsPanelBtn'
import CardWrapper from '../CardWrapper/CardWrapper'
import AdditionalPrices from '../additionalPrices/AdditionalPrices/AdditionalPrices'
import MainPriceWrapper from '../MainPriceWrapper/MainPriceWrapper'
import BuyButton from '../BuyButton/BuyButton'
import Theses from '../Theses/Theses'
import Description from '../Description/Description'
import Header from '../Header/Header'
import FocusCardWrapper from '../FocusCardWrapper/FocusCardWrapper'
import { useGetColorScheme } from '../common/common'
import './Card.scss'

type CardProps = {
	tariff: TariffsStoreType.Tariff
}

// Карточка тарифа
function Card(props: CardProps) {
	const { tariff } = props

	const colorScheme = useGetColorScheme()

	return (
		<FocusCardWrapper tariff={tariff}>
			<ShowSettingsPanelBtn tariff={tariff} />
			<CardWrapper tariff={tariff}>
				<div
					className={cn(
						'tariff-card',
						tariff.isFlashed && 'tariff-card--' + colorScheme
					)}
					data-tariff-id={tariff.innerId}
				>
					<Header tariff={tariff} />
					<Description tariff={tariff} />
					<Theses tariff={tariff} />
					<Price tariff={tariff} />
					<MainPriceWrapper>
						<BuyButton tariff={tariff} />
					</MainPriceWrapper>
				</div>
				<AdditionalPrices tariff={tariff} />
			</CardWrapper>
		</FocusCardWrapper>
	)
}

export default Card
