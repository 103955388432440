import { message } from 'antd'
import { useQueryClient } from 'react-query'
import { userQuery } from 'parts/requests/user/userQuery'
import useDeleteModalStore from '../../../../../modal/DeleteModal/store/store'
import { schoolQuery } from 'parts/requests/school/schoolQuery'
import { getQueryParams } from '../../zustand/fn/fillInStore'
import useStudentsStore from '../../zustand/store'

/** Удаление пользователя */
export function useGetDeleteUser(userId: number) {
	const updateStore = useDeleteModalStore((state) => state.updateStore)

	const { mutate } = schoolQuery.deleteUser.useMutation({
		onError: () => {
			message.error('Не удалось удалить ученика')
		},
		onSuccess: useGetDeleteStudentSuccess(),
	})

	return function () {
		updateStore({
			isOpen: true,
			header: 'Вы действительно хотите удалить ученика?',
			text: 'Это действие нельзя отменить.',
			onOk() {
				mutate(userId)
			},
		})
	}
}

function useGetDeleteStudentSuccess() {
	const queryClient = useQueryClient()
	const searchValues = useStudentsStore((state) => state.searchValues)

	return function () {
		message.success('Ученик удалён')

		// Пометить список групп неактуальным.
		// После этого React Query скачает новый список
		queryClient.refetchQueries({
			queryKey: [
				userQuery.getUsersPaginated(getQueryParams(searchValues)).key,
			],
		})
	}
}
