import { useCallback } from 'react'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'

/** Возвращает обработчик создания нового тарифа */
export function useGetCreateTariff() {
	return useCallback(function () {
		tariffsManager.createTariffAndSetToStore()
		tariffsManager.setNeedToSave()

		setTimeout(function () {
			setFocusOnHeaderOfLastTariff()
		}, 100)
	}, [])
}

function setFocusOnHeaderOfLastTariff() {
	const $tariffsHeaders = document.querySelectorAll('.tariff-card-header')
	if (!$tariffsHeaders) return

	const lastTariffHeader = $tariffsHeaders[$tariffsHeaders.length - 1]
	// @ts-ignore
	lastTariffHeader.focus()
}
