import React from 'react'

function RadioBold() {
	return (
		<>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 13.4C10.9823 13.4 13.4 10.9823 13.4 8C13.4 5.01766 10.9823 2.6 8 2.6C5.01766 2.6 2.6 5.01766 2.6 8C2.6 10.9823 5.01766 13.4 8 13.4ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
			/>
			<path
				clipRule="evenodd"
				d="M8 9.4C8.7732 9.4 9.4 8.7732 9.4 8C9.4 7.2268 8.7732 6.6 8 6.6C7.2268 6.6 6.6 7.2268 6.6 8C6.6 8.7732 7.2268 9.4 8 9.4ZM8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z"
			/>
		</>
	)
}

export default RadioBold
