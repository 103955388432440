import { message } from 'antd'
import { AxiosError, AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { AssignEmployeeToGroupModalStoreType } from 'pages/employee/groups/AssignEmployeeToGroupModal/zustand/storeTypes'
import LessonsApiTypes from 'parts/requests/lessons/lessonsApiTypes'
import { lessonsQuery } from 'parts/requests/lessons/lessonsQuery'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import LessonAdminService from 'parts/services/LessonAdminService'
import ServerTypes from 'parts/types/ServerTypes'
import { getFirstLoadedFileUrl } from 'ui/upload/UploadFileInForm/fn/fn'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import useLessonsListAdminStore from '../../../zustand/store'
import useEditLessonModalStore from '../../zustand/store'
import { EditLessonModalStoreType } from '../../zustand/storeTypes'
import { FieldNames, FormValuesType } from './form'

export function useGetOnSubmit() {
	const [lessonId, updateModalState] = useEditLessonModalStore((state) => [
		state.lessonId,
		state.updateStore,
	])

	// Объект с методом mutate для создания запроса
	const updateLessonMutation = lessonsQuery.updateLesson.useMutation({
		onMutate: getMutateOnBefore(updateModalState),
		onError: useGetMutateOnError(updateModalState),
		onSuccess: useGetMutateOnSuccess(updateModalState),
	})

	return function (fieldsValue: FormValuesType) {
		// Приготовить данные, которые передадутся в функцию-обработчик запроса
		const updatedLessonData = prepareReqData(lessonId, fieldsValue)
		updateLessonMutation.mutate(updatedLessonData)
	}
}

/**
 * Готовит и возвращает DTO для изменения урока.
 * @param lessonId id редактируемого урока
 * @param fieldsValue — значения полей формы
 */
function prepareReqData(
	lessonId: number,
	fieldsValue: FormValuesType
): LessonsApiTypes.UpdateLessonDto {
	return {
		id: lessonId,
		name: fieldsValue[FieldNames.Name],
		description: fieldsValue[FieldNames.Description],
		cover: getFirstLoadedFileUrl(fieldsValue[FieldNames.Cover]) || '',
	}
}

/**
 * Функция, выполняемая до запроса на изменение урока
 * @param updateModalState — функция изменяющая объект состояния модального окна
 */
function getMutateOnBefore(
	updateModalState: AssignEmployeeToGroupModalStoreType.UpdateStore
) {
	return function () {
		// Поставить статус загрузки
		updateModalState({ isSubmitting: true })
	}
}

/**
 * Функция, выполняемая при появлении ошибки на изменение урока
 * @param updateModalState — функция изменяющая объект состояния модального окна
 */
function useGetMutateOnError(
	updateModalState: AssignEmployeeToGroupModalStoreType.UpdateStore
) {
	const { t } = useTranslation()

	return function (err: unknown) {
		const error = err as AxiosError<ServerTypes.ErrorResponse>
		if (error.response) {
			// Поставить данные ошибки в Состояние, чтобы показать их в форме
			updateModalState({ formErrors: error.response.data })
		}

		message.error(t('adminTraining.editLessonModalReqErrorWhileSave'))
	}
}

/**
 * Функция, выполняемая при успешном запросе на добавление сотрудника в группы
 * @param updateModalState — функция изменяющая объект состояния модального окна
 */
function useGetMutateOnSuccess(
	updateModalState: EditLessonModalStoreType.UpdateStore
) {
	const { t } = useTranslation()
	const queryClient = useQueryClient()

	return function (
		updatedLessonRes: AxiosResponse<TrainingEntityTypes.LessonAdmin>
	) {
		// Закрыть модальное
		updateModalState({ isModalOpen: false })
		message.success(t('adminTraining.editLessonModalLessonCreatedMessage'))

		const { trainingId, lessons, updateStore } =
			useLessonsListAdminStore.getState()

		// Обновить урок в Состоянии уроков
		const updatedLessons = LessonAdminService.setUpdatedLessonInLessons(
			lessons,
			updatedLessonRes.data
		)
		updateStore({ lessons: updatedLessons })

		// Скачать новые данные, чтобы убрать старые из кеша React Query
		if (trainingId) {
			// Пометить данные курса неактуальными. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [trainingQuery.getTrainingForAdmin(trainingId).key],
			})
		} else {
			// Пометить массив уроков школы неактуальным. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [lessonsQuery.getSchoolLessons.key],
			})
		}
	}
}
