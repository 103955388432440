import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { RouterVarNames } from 'app/routes/special/otherRoutes'

// Тип функции, принимающей хуком useLiftViewDuration, которая будет вызываться каждую секунду,
// чтобы принять количество секунд, прошедших после открытия упражнения.
export type LiftViewDuration = (duration: number) => void

/**
 * Хук считающий количество времени видимости компонента.
 * Это время в виде секунд передаётся в переданную функцию liftViewDuration.
 * @param liftViewDuration — функция, принимающая время, прошедшее после открытия компонента, где находится этот хук.
 * @param stopTimer — если сюда будет передана правда, то таймер будет остановлен.
 */
export function useLiftViewDuration(
	liftViewDuration?: LiftViewDuration,
	stopTimer?: boolean
) {
	const params = useParams()
	// exerciseId может быть undefined если находятся не на странице упражнения. Это нормально.
	const exerciseId = params[RouterVarNames.ExerciseId]

	const setIntervalRef = useRef<null | NodeJS.Timer>(null)
	const durationSec = useRef(0)

	useEffect(
		function () {
			if (!liftViewDuration || !exerciseId) return

			const startDate = new Date()

			setIntervalRef.current = setInterval(() => {
				const durationInMs = +new Date() - +startDate
				const durationInSec = Math.round(durationInMs / 1000)

				durationSec.current = durationInSec
				liftViewDuration(durationInSec)
			}, 1000)

			// Очистка таймера после закрытия компонента
			return function () {
				if (!setIntervalRef.current) return

				clearInterval(setIntervalRef.current)
			}
		},
		[exerciseId]
	)

	useEffect(
		function () {
			if (!stopTimer || !setIntervalRef.current) return

			clearInterval(setIntervalRef.current)
		},
		[stopTimer]
	)

	return durationSec.current
}

// Тип функции запускаемой при отправке письменного или голосового ответа на упражнение.
// Если ответ ещё не послан, то кнопка завершения упражнения будет заблокированной
export type WritingOrAudioAnswerWasSentFn = () => void

// Это тип функции, которая будет вызываться при изменении упражнения пользователем.
// И туда будут передаваться данные, для восстановления этих изменений при открытии упражнения.
export type SaveExerciseChangesMadeByUser = (data: any) => void
// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
export type ExerciseChangesDataMadeByUser = any
