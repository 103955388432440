import Loader from 'ui/Loader/Loader'
import { useFillInStore } from '../../zustand/fillStore/fillInStore'
import useStaffStore from '../../zustand/store'
import { GroupsStoreType } from '../../zustand/storeTypes'
import StaffTable from '../StaffTable'

type GetStaffTableRouterReturn = {
	status: GroupsStoreType.DataLoadingStatus
	element: JSX.Element
}

export function useGetStaffTableRouter(): GetStaffTableRouterReturn {
	useFillInStore()

	const dataLoadingStatus = useStaffStore((state) => state.dataLoadingStatus)

	if (dataLoadingStatus == 'loading') {
		return {
			status: 'loading',
			element: <Loader />,
		}
	} else if (dataLoadingStatus === 'error') {
		return {
			status: 'error',
			element: <p>Произошла ошибка при получении данных.</p>,
		}
	} else if (dataLoadingStatus === 'noStaff') {
		return {
			status: 'noStaff',
			element: <p>Нет данных.</p>,
		}
	}

	return {
		status: 'hasStaff',
		element: <StaffTable />,
	}
}
