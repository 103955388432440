import { useEffect } from 'react'
import useDebounce, { useIsFirstRender } from 'parts/utils/hooks/hooks'
import { ScrollDataLSPropName } from './scrollManager'
import usePagesScrollStore from './zustand/store'

export function useGetScrollDataFromStoreAndSaveInLocalStorage() {
	const pagesScroll = usePagesScrollStore((store) => store.pagesScroll)

	// Задержка, чтобы не сохранял данные после открытия страницы
	const isFirstRender = useIsFirstRender(2000)

	// Это заторможенные данные по прокрутке чтобы не так часто их сохранять в LocalStorage
	const throttledScrollData = useDebounce(pagesScroll, 1500)

	useEffect(
		function () {
			if (isFirstRender || !pagesScroll) return

			localStorage.setItem(
				ScrollDataLSPropName,
				JSON.stringify(pagesScroll)
			)
		},
		[throttledScrollData]
	)
}
