import { Pagination } from 'antd'
import React from 'react'
import { USERS_LENGTH_IN_PAGE } from '../common/usersConsts'
import useStudentsStore from '../studentsTableAdmin/zustand/store'
import { useGetChangePageNumber } from './fn/changePageNumber'
import { useGetChangeItemsPerPage } from './fn/changeItemsPerPage'

// Постраничная навигация по ленте ответов
function UsersPagination() {
	const currentPageNum = useStudentsStore((store) => store.currentPageNum)
	const totalAnswers = useStudentsStore((store) => store.totalStudents)
	const itemsPerPage = useStudentsStore((store) => store.studentsPerPage)

	const changePageNumber = useGetChangePageNumber()
	const changeItemsPerPage = useGetChangeItemsPerPage()

	if (totalAnswers <= itemsPerPage) return null

	return (
		<Pagination
			defaultCurrent={currentPageNum}
			current={currentPageNum}
			total={totalAnswers}
			defaultPageSize={USERS_LENGTH_IN_PAGE}
			pageSize={itemsPerPage}
			onChange={changePageNumber}
			onShowSizeChange={changeItemsPerPage}
		/>
	)
}

export default UsersPagination
