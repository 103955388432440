import { TFunction } from 'i18next'
import { HeaderRouteType } from 'ui/pageHeader/PageHeader/fn/types'
import AppUrls from 'parts/constants/pageUrl'

export const routesEnd: HeaderRouteType[] = [
	{
		path: AppUrls.MyPayments().url,
		breadcrumbName: AppUrls.MyPayments().name,
	},
]

export function getHeaderName(
	paymentSystemName: string,
	t: TFunction<'translation'>
) {
	return t('myPayments.paymentPageHeader') + ' «' + paymentSystemName + '»'
}
