import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import ExerciseControlPanelContainer from '../../common/ExerciseControlPanelContainer/ExerciseControlPanelContainer'
import { useGetButtonsConfig } from './fn/getButtonsConfig'

type ExerciseControlPanelProps = {
	exercise: TrainingEntityTypes.TextExercise
}

// Нижняя часть упражнения с кнопками управления упражнением
function ExerciseControlPanel(props: ExerciseControlPanelProps) {
	const { exercise } = props

	return (
		<ExerciseControlPanelContainer
			buttons={useGetButtonsConfig(exercise)}
		/>
	)
}

export default ExerciseControlPanel
