import { MouseEvent } from 'react'

export function useGetOnTakeAPartButtonClick() {
	return function (event: MouseEvent) {
		const $tariffsSection = document.querySelector('#tariffs-section')

		if ($tariffsSection) {
			$tariffsSection.scrollIntoView({
				behavior: 'smooth',
			})
		}
	}
}
