import { useGetTrainingListRouter } from './TrainingsList/fn/useGetTrainingListRouter'
import { useQueryClient } from 'react-query'
import { trainingQuery } from 'parts/requests/training/trainingQuery'

export const TrainingListController = {
	router: useGetTrainingListRouter,
	// Функция, помечающая данные массива курсов неактуальными.
	// После этого React Query скачает новый массив курсов
	useGetUpdate: function () {
		const queryClient = useQueryClient()

		return function () {
			// Пометить список курсов неактуальным. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [trainingQuery.getTrainingsForAdmin.key],
			})
		}
	},
}
