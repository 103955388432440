import { Button, Form, Input } from 'antd'
import { useGetSetVisibilityToModal } from 'pages/landing/landingConstructor/details/AddVideoUrlModal/fn/openCloseModal'
import { useGetOnSubmit } from 'pages/landing/landingConstructor/details/AddVideoUrlModal/fn/submit'
import ModalWithForm from 'ui/ModalWithForm/ModalWithForm'
import useAddVideoUrlModalStore from 'pages/landing/landingConstructor/details/AddVideoUrlModal/zustand/store'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'
import {
	UFormNewBottomButtonsAfterGray,
	UFormNewButtonsContainer,
	UFormNewRow,
} from 'ui/UForm/UForm'
import {
	FieldNames,
	useGetInitialValues,
} from 'pages/landing/landingConstructor/details/AddVideoUrlModal/fn/form'
import 'pages/landing/landingConstructor/details/AddVideoUrlModal/AddVideoUrlModal.scss'

// Модальное окно указания цветовой схемы одностраничника
function AddVideoUrlModal() {
	const [form] = Form.useForm()

	const initialValues = useGetInitialValues()
	const onSubmit = useGetOnSubmit()

	const modalState = useAddVideoUrlModalStore((state) => state)
	const closeModal = useGetSetVisibilityToModal(false)

	return (
		<ModalWithForm
			title="Добавление ссылки на видео с Ютуба"
			open={modalState.isOpen}
			onCancel={closeModal}
		>
			<Form
				form={form}
				name="colorScheme"
				layout="vertical"
				onFinish={onSubmit}
				initialValues={initialValues}
			>
				<GrayBlock>
					<VideoUrlInput />
				</GrayBlock>
				<Bottom />
			</Form>
		</ModalWithForm>
	)
}

export default AddVideoUrlModal

// Поле адреса видео на Ютубе
function VideoUrlInput() {
	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.VideoUrl}
				label="Адрес страницы на Ютубе"
				rules={[
					{
						required: true,
						message: 'Введите адрес страницы на Ютубе',
					},
				]}
			>
				<Input />
			</Form.Item>
		</UFormNewRow>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
// Кнопки подтверждения и закрытия окна
function Bottom() {
	const closeModal = useGetSetVisibilityToModal(false)

	return (
		<UFormNewBottomButtonsAfterGray>
			<div />
			<UFormNewButtonsContainer>
				<Button onClick={closeModal}>Отмена</Button>
				<Button type="primary" htmlType="submit">
					Сохранить
				</Button>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
