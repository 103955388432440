import { FC } from 'react'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import AppUrls from 'parts/constants/pageUrl'

import styles from './LessonCard.module.scss'

interface LessonCardProps {
	no: number
	courseName: string
	title: string
	subtitle: string
	image: string
}

const LessonCard: FC<LessonCardProps> = ({
	no,
	courseName,
	title,
	subtitle,
	image,
}) => {
	const navigate = useNavigate()

	const handleGoLesson = () => {
		navigate(AppUrls.MyExpressLesson(courseName, no).url)
	}

	return (
		<div className={styles.LessonCard}>
			<div className={styles.LessonCard__Image}>
				<img src={image} />
			</div>
			<div className={styles.LessonCard__Content}>
				<div className={styles.Content__LessonNo}>{`Урок ${no}`}</div>
				<div className={styles.Content__Title}>{title}</div>
				<div className={styles.Content__SubTitle}>{subtitle}</div>
			</div>
			<div className={styles.LessonCard__Button}>
				<Button type="primary" onClick={handleGoLesson}>
					Посмотреть урок
				</Button>
			</div>
		</div>
	)
}

export default LessonCard
