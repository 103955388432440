import React from 'react'
import { PictureOutlined } from '@ant-design/icons'
import cn from 'classnames'
import './Image.scss'

type ImageProps = {
	// Форма изображения:
	// circle — круг
	// square — квадрат
	// free — ширина и высота задаются в переданном классе
	form: 'circle' | 'square' | 'free'
	width?: number
	src: undefined | null | string
	extraClass?: string
}

// Компонент изображения
function Image(props: ImageProps) {
	const { form, width, src, extraClass } = props

	let style: Record<string, string> = {}
	if (width && form !== 'free') {
		style.width = width + 'px'
		style.minWidth = width + 'px'
		style.height = width + 'px'
	}

	return (
		<div style={style} className={cn('image', extraClass)}>
			{src ? (
				<img src={src} alt="image" className="image__image" />
			) : (
				<PictureOutlined className="image__icon" width={36} />
			)}
		</div>
	)
}

export default Image
