import React, { ReactElement } from 'react'
import './PageSkeleton.scss'

type TrainingPageSkeletonProps = {
	children: (ReactElement | null)[]
}

function PageSkeleton(props: TrainingPageSkeletonProps) {
	const [header = null, main = null, footer = null] = props.children

	return (
		<div className="page-skeleton">
			<div className="page-skeleton__header">{header}</div>
			<div className="page-skeleton__main">{main}</div>
			<div className="page-skeleton__footer">{footer}</div>
		</div>
	)
}

export default PageSkeleton
