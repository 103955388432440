import { useTranslation } from 'react-i18next'

export enum LangNames {
	RU = 'ru',
	EN = 'en',
}

// Сервис с методами для работы с языком программы
const LangService = {
	// Свойство в LocalStorage со значением текущего языка
	localStoragePropWithLangName: 'lang',

	/** Значение языка по умолчанию */
	defaultLang: LangNames.RU,

	/** Получение текущего языка из LocalStorage */
	getCurrentLangFromLocalStorage(): LangNames {
		const currentLang = localStorage.getItem(
			this.localStoragePropWithLangName
		) as null | LangNames

		return currentLang ?? this.defaultLang
	},

	/** Установка текущего языка в LocalStorage */
	setCurrentLangToLocalStorage(newLang: LangNames) {
		localStorage.setItem(LangService.localStoragePropWithLangName, newLang)
	},

	/** Возвращает функцию переключающую язык */
	useGetToggleLang() {
		const { i18n } = useTranslation()

		return () => {
			const nextLang =
				this.getCurrentLangFromLocalStorage() == LangNames.RU
					? LangNames.EN
					: LangNames.RU

			this.setCurrentLangToLocalStorage(nextLang)
			i18n.changeLanguage(nextLang)
		}
	},
}

export default LangService
