import React from 'react'
import { useTranslation } from 'react-i18next'
import './SuccessMessage.scss'

function SuccessMessage() {
	const { t } = useTranslation()

	return (
		<div className="exercise-audio-message-block">
			<h3 className="exercise-audio-message-block__header">
				Ответ отправлен!
			</h3>
			<p className="exercise-audio-message-block__text">
				В течение 24 часов куратор проверит и даст подробную обратную
				связь!
			</p>
		</div>
	)
}

export default SuccessMessage
