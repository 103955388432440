import { Pagination } from 'antd'
import React from 'react'
import { ANSWERS_LENGTH_IN_PAGE } from '../../common/answersFeedConsts'
import useAnswersFeedStore from '../../zustand/store'
import { useGetChangePageNumber } from './fn/changePageNumber'

// Постраничная навигация по ленте ответов
function FeedPagination() {
	const currentPageNum = useAnswersFeedStore((store) => store.currentPageNum)
	const totalAnswers = useAnswersFeedStore((store) => store.totalAnswers)

	const changePageNumber = useGetChangePageNumber()

	if (totalAnswers <= ANSWERS_LENGTH_IN_PAGE) return null

	return (
		<Pagination
			defaultCurrent={currentPageNum}
			current={currentPageNum}
			total={totalAnswers}
			defaultPageSize={ANSWERS_LENGTH_IN_PAGE}
			pageSize={ANSWERS_LENGTH_IN_PAGE}
			onChange={changePageNumber}
		/>
	)
}

export default FeedPagination
