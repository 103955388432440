import { useCallback } from 'react'
import { produce } from 'immer'
import { DropWordsExerciseFormStateType } from './stateType'
import { getFormState, setFormState } from '../../common/exerciseFormCommonFunc'
import { getTextObjById } from './check'

/**
 * Возвращает функцию получающая сгенерированный текст, разбирает его
 * и помещает данные в поле с текстом вопроса и вариантами ответа
 */
export function useGetGeneratedTextAndSetToStore(textBlockId: number) {
	return useCallback(function (generatedText: string) {
		// В generatedText будет строка вида (ПРОВЕРИТЬ ПОЗЖЕ):
		/*'I was wearing a {jacket}, a {shirt} and a pair of {jeans} when I went out yesterday. I {bought} a new dress for my sister's birthday. My brother {wore} a {hat} and a {scarf} when he went to work this morning.'*/

		// Подрезать концевые пробелы
		const preparedText = generatedText.trim()

		const formState = getFormState<DropWordsExerciseFormStateType>()

		const newState = produce(formState, (draft) => {
			putGeneratedTextToTextBlock(draft, textBlockId, preparedText)
		})

		setFormState(newState)
	}, [])
}

/**
 * Получает строку, сгенерированную ChatGPT, и помещает её в качестве сгенерированный строки в объект текста
 * @param {Object} formState — Объект с данными упражнения
 * @param {Number} textBlockId — id текстового объекта
 * @param {Object} generatedText — строка, сгенерированная ChatGPT
 */
function putGeneratedTextToTextBlock(
	formState: DropWordsExerciseFormStateType,
	textBlockId: number,
	generatedText: string
) {
	const textBlock = getTextObjById(formState, textBlockId)
	if (!textBlock) return

	textBlock.generatedText = generatedText
}

/**
 * Возвращает функцию получающую текст запроса на генерирование текста.
 * Эта информация сохраняется в данных упражнения.
 */
export function useGetGeneratedPromptAndSetToStore(textBlockId: number) {
	return useCallback(function (generatedPrompt: string) {
		const formState = getFormState<DropWordsExerciseFormStateType>()

		const newState = produce(formState, (draft) => {
			const textBlock = getTextObjById(draft, textBlockId)
			if (!textBlock) return

			textBlock.generatedTextPrompt = generatedPrompt
		})

		setFormState(newState)
	}, [])
}
