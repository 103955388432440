import { useEffect, useRef, useState } from 'react'
import { paymentsQuery } from 'parts/requests/payments/paymentsQuery'
import Loader from 'ui/Loader/Loader'
import LeadsTable from '../LeadsTable'
import { useFillInStore } from './fillInStore'

type GetLeadsTableRouterReturn = {
	status: 'loading' | 'hasLeads' | 'noLeads' | 'unknown'
	element: JSX.Element
}

/** Хук возвращающий статус компонента StudentsTable и сам компонент в зависимости от статуса */
export function useGetStudentsTableRouter(): GetLeadsTableRouterReturn {
	// Получить список учеников
	const { status, data } = paymentsQuery.getPayments.useQuery({})

	useFillInStore()

	// Тут хранится булево значение можно ли возвращать другой результат.
	// Это нужно потому что запрос на получение учеников будет запускаться всякий раз при фильтрации.
	// Но после первого успешного результата нужно перестать обновлять возвращаемый результат чтобы компонент выше знал есть ли изначально ученики или нет.
	// При фильтрации количество учеников может быть равно нулю.
	const stopUpdatingRef = useRef<boolean>(false)

	const [result, setResult] = useState<GetLeadsTableRouterReturn>(unknownObj)

	useEffect(
		function () {
			if (stopUpdatingRef.current) {
				return
			}

			if (status == 'loading') {
				setResult({
					status: 'loading',
					element: <Loader />,
				})
			} else if (status == 'success' && data) {
				if (data.data.length) {
					setResult({
						status: 'hasLeads',
						element: <LeadsTable />,
					})
				} else {
					setResult({
						status: 'noLeads',
						element: <p>noLeads</p>,
					})
				}

				stopUpdatingRef.current = true
			}
		},
		[status]
	)

	return result
}

const unknownObj: GetLeadsTableRouterReturn = {
	status: 'unknown',
	element: <p>unknown</p>,
}
