import { FC } from 'react'
import cn from 'classnames'

import styles from './Footer.module.scss'

interface FooterProps {
	isRightSideHidden?: boolean
}

const Footer: FC<FooterProps> = (props) => {
	const { isRightSideHidden } = props

	return (
		<>
			<div className={styles.Footer}>
				<div className={styles.Footer__Left}>
					<div className={styles.Footer__Copyright}>© 2024 Credu</div>
					<div>
						<a
							href="/landings/pdf/User-agreement.pdf"
							target="_blank"
						>
							Пользовательское соглашение
						</a>
					</div>
				</div>
				<div
					className={cn(styles.Footer__Right, {
						[styles.Footer__Right_Hidden]: isRightSideHidden,
					})}
				>
					<div className={styles.Footer__NeedHelp}>Нужна помощь?</div>
					<div className={styles.Footer__HelpCenter}>
						<a href="https://help.credu.ai" target="_blank">
							⍰ Справочный центр
						</a>
					</div>
					<div className={styles.Footer__We}>Мы в соц.сетях</div>
					<div className={styles.Footer__Icons}>
						<a
							href="https://www.facebook.com/creduplatform"
							target="_blank"
						>
							<img src="/images/facebook.svg" />
						</a>
						<a
							href="https://www.youtube.com/@creduplatform/"
							target="_blank"
						>
							<img src="/images/youtube.svg" />
						</a>
						<a href="https://t.me/creduplatform" target="_blank">
							<img src="/images/telegram.svg" />
						</a>
					</div>
				</div>
			</div>
			<div className={styles.FooterMobile}>
				<div className={styles.FooterMobile__FirstLine}>
					<div className={styles.FooterMobile__HelpCenter}>
						<a href="https://help.credu.ai" target="_blank">
							⍰ Справочный центр
						</a>
					</div>
					<div className={styles.FooterMobile__Icons}>
						<a
							href="https://www.facebook.com/creduplatform"
							target="_blank"
						>
							<img src="/images/facebook.svg" />
						</a>
						<a
							href="https://www.youtube.com/@creduplatform/"
							target="_blank"
						>
							<img src="/images/youtube.svg" />
						</a>
						<a href="https://t.me/creduplatform" target="_blank">
							<img src="/images/telegram.svg" />
						</a>
					</div>
				</div>
				<div className={styles.FooterMobile__SecondLine}>
					<div className={styles.FooterMobile__Agreement}>
						<a
							href="/landings/pdf/User-agreement.pdf"
							target="_blank"
						>
							Пользовательское соглашение
						</a>
					</div>
					<div className={styles.FooterMobile__Copyright}>
						© 2024 Credu
					</div>
				</div>
			</div>
		</>
	)
}

export default Footer
