import useOpenLessonsStore from './zustand/store'
import { Button, Checkbox, Form } from 'antd'
import {
	UFormNewBottomButtonsWithBorder,
	UFormNewButtonsContainer,
	UFormNewGeneralError,
	UFormNewWhiteWrapper,
} from 'ui/UForm/UForm'
import { useGetOnSubmit } from './formFn/submit'
import { FieldsNames, setErrorsToForm } from './formFn/form'

import './GroupOpenLessonsForm.scss'
import { useGetInitialValues } from './formFn/initialValues'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

function GroupOpenLessonsForm() {
	const lessons = useOpenLessonsStore((state) => state.lessons)
	const [form] = Form.useForm()

	const initialValues = useGetInitialValues()

	const onSubmit = useGetOnSubmit()
	setErrorsToForm(form)

	const formErrors = useOpenLessonsStore((state) => state.formErrors)

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onSubmit}
			className="openlessons-form"
			initialValues={initialValues}
		>
			<UFormNewWhiteWrapper>
				<div className="openlessons-form__title">
					Отметье уроки, которые будут доступны для всех
				</div>
				<div className="openlessons-form__subtitle">
					Вы открываете бесплатный доступ к урокам
				</div>
				<div className="openlessons-form__lessonslist">
					<OpenLessonsCheckboxes lessons={lessons} />
				</div>
				<Bottom />
				<UFormNewGeneralError message={formErrors.message} />
			</UFormNewWhiteWrapper>
		</Form>
	)
}

export default GroupOpenLessonsForm

function OpenLessonsCheckboxes(props: {
	lessons: TrainingEntityTypes.LessonAdmin[]
}) {
	return (
		<Form.List name={FieldsNames.OpenLessons}>
			{(fields) => (
				<>
					{fields.map((field, index) => (
						<Form.Item {...field} valuePropName="checked">
							<Checkbox>{`Урок ${index + 1}. ${
								props.lessons[index].name
							}`}</Checkbox>
						</Form.Item>
					))}
				</>
			)}
		</Form.List>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom() {
	const form = Form.useFormInstance()

	const isSubmitting = useOpenLessonsStore((state) => state.isSubmitting)

	return (
		<UFormNewBottomButtonsWithBorder>
			<div />
			<UFormNewButtonsContainer>
				<Form.Item shouldUpdate>
					{() => {
						const formErrors = form
							.getFieldsError()
							.filter(({ errors }) => errors.length)

						return (
							<Button
								block
								type="primary"
								htmlType="submit"
								disabled={isSubmitting || formErrors.length > 0}
								loading={isSubmitting}
							>
								Сохранить
							</Button>
						)
					}}
				</Form.Item>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsWithBorder>
	)
}
