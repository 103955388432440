import React, { useState } from 'react'
import { Button } from 'antd'
import Quill from 'quill'
import { useTranslation } from 'react-i18next'
import { Range } from 'react-quill'
import RestrictionsService from '../../../../parts/services/RestrictionsService'
import { makeSelectedWordGap, useGetOnChangeEditorValue } from './fn/editor'
import HtmlEditor from 'ui/HTMLEditor/HTMLEditor'
import GapsFlasher from '../common/GapsFlasher/GapsFlasher'
import { useGetNewValueInputValue } from './fn/changeForm'
import { ExerciseType, TextStateItemType } from './fn/stateType'
import Generator from '../Generator/Generator'
import {
	useGetGeneratedPromptAndSetToStore,
	useGetGeneratedTextAndSetToStore,
} from './fn/generatedText'

type EditorProps = {
	exerciseType: ExerciseType
	textBlock: TextStateItemType
}

// Редактора создания текста с пропусками, компонент подсвечивающий текст обрамлённый фигурными скобками и кнопка создания пропуска.
function Editor(props: EditorProps) {
	const { exerciseType, textBlock } = props

	const { t } = useTranslation()

	const canUseAiGeneration = RestrictionsService.useCanUseAiGeneration()

	const [quillRef, setQuillRef] = useState<Quill | null>(null) // Quill instance
	const [isDisabled, setIsDisabled] = useState<boolean>(false)

	function changeEditorSelection(range: Range) {
		if (!range) {
			setIsDisabled(false)
			return
		}

		return setIsDisabled(!range.length)
	}

	function setBlurToEditor() {
		setIsDisabled(true)
	}

	const onChangeEditorValue = useGetOnChangeEditorValue(textBlock.id)

	// Хранится сгенерированный текст, который должен заменить текст в редакторе
	const newValue = useGetNewValueInputValue(textBlock.id)

	// Функция получающая сгенерированный текст и помещающая его в поле с текстом упражнения
	const getGeneratedText = useGetGeneratedTextAndSetToStore(textBlock.id)
	// Возвращает функцию получающую текст запроса на генерирование текста.
	// Эта информация сохраняется в данных упражнения
	const getGeneratedPrompt = useGetGeneratedPromptAndSetToStore(textBlock.id)

	return (
		<div className="exercise-gaps-in-words-editor__wrapper">
			<GapsFlasher
				exerciseType={exerciseType}
				editorHtmlStr={textBlock.text}
			>
				<HtmlEditor
					setQuillRef={setQuillRef}
					initialValue={textBlock.text}
					newValue={newValue}
					onChangeValue={onChangeEditorValue}
					onChangeSelection={changeEditorSelection}
					onBlur={setBlurToEditor}
				/>
			</GapsFlasher>
			{textBlock.noTextError && (
				<p className="exercise-gaps-in-words__input-error">
					В поле не введён текст
				</p>
			)}
			{textBlock.wrongBracesPairError && (
				<p className="exercise-gaps-in-words__input-error">
					В поле неверная последовательность открывающих и закрывающих
					фигурных скобок которыми помечаются пропуски в упражнениях.
				</p>
			)}
			{textBlock.wrongBracesTextError && (
				<p className="exercise-gaps-in-words__input-error">
					Один из текстов, заключённых в фигурные скобки, находится в
					неправильном формате.
				</p>
			)}
			<Button
				className="exercise-gaps-in-words-editor__make-gap-btn"
				onClick={() => makeSelectedWordGap(quillRef)}
				size="small"
				disabled={isDisabled}
			>
				Сделать пропуском
			</Button>
			{canUseAiGeneration && (
				<Generator
					exerciseType={exerciseType}
					getGeneratedText={getGeneratedText}
					initialPrompt={textBlock.generatedTextPrompt}
					getGeneratedPrompt={getGeneratedPrompt}
					error={null}
				/>
			)}
		</div>
	)
}

export default Editor
