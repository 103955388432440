import React, { ReactNode } from 'react'
import { Modal } from 'antd'
import './HistoryModal.scss'

type HistoryModalProps = {
	iconSrc: string
	title: string
	children: ReactNode
	open: boolean
	footerButtons: ReactNode[]
	onCancel: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

// Настройка модального окна в котором будет форма: Убраны кнопки утверждения и отмены
function HistoryModal(props: HistoryModalProps) {
	const { iconSrc, title, children, open, footerButtons, onCancel } = props

	return (
		<Modal
			open={open}
			onCancel={onCancel}
			footer={[]}
			className="s-history-modal"
		>
			<div className="s-history-modal">
				<div className="s-history-modal__icon">
					<img src={iconSrc} alt="" />
				</div>
				<h2 className="s-history-modal__header">{title}</h2>
				<div className="s-history-modal__content">{children}</div>
				<div className="s-history-modal__bottom">{footerButtons}</div>
			</div>
		</Modal>
	)
}

export default HistoryModal
