import { ExerciseStateType } from './fn/stateType'
import React from 'react'

type WordsProps = {
	words: ExerciseStateType.Word[]
}

// Блок слов на верхней части упражнения
export function Words(props: WordsProps) {
	const { words } = props

	return (
		<div className="exercise-drop-word-content__words">
			{words.map((word, i) => (
				<Word wordObj={word} key={i} />
			))}
		</div>
	)
}

export default Words

type WordProps = {
	wordObj: ExerciseStateType.Word
}

export function Word(props: WordProps) {
	const { wordObj } = props

	return (
		<button
			className="exercise-drop-word-content__word exercise-drop-word-content__word--inside"
			draggable
			data-word-id={wordObj.id}
		>
			{wordObj.word}
		</button>
	)
}
