import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StateContext, StateType } from './stateType'

/** Возвращает функцию, раскрывающую и скрывающую форму генерирования текста */
export function useGetChangeFormVisibility() {
	const { state, setState } = useContext(StateContext)

	return useCallback(
		function () {
			const newState = { ...state }
			newState.open = !state.open

			setState(newState)
		},
		[state]
	)
}

/** Возвращает обработчик изменения значения поля с текстом задания */
export function useGetOnTextareaChange() {
	const { state, setState } = useContext(StateContext)

	return useCallback(
		function (e: React.ChangeEvent<HTMLTextAreaElement>) {
			const { value } = e.target

			state.getGeneratedPrompt(value)

			const newState = { ...state }
			// Поставить в Состояние текст задания
			newState.promptText = value

			// Поменять жизненный статус на то, что поле заполнено или не заполнено
			newState.lifeStatus = value.length ? 'filled' : 'empty'

			setState(newState)
		},
		[state]
	)
}

/** Возвращает булево значение должно ли поле ввода задания быть заблокированным */
export function useIsTextareaDisabled() {
	const { state, setState } = useContext(StateContext)

	return useMemo(
		function () {
			return state.lifeStatus == 'loading'
		},
		[state]
	)
}

/** Возвращает текст на кнопке генерирования текста */
export function useGetSubmitBtnText() {
	const { state } = useContext(StateContext)

	const { t } = useTranslation()

	return useMemo(
		function () {
			switch (state.lifeStatus) {
				case 'empty':
				case 'filled':
				case 'error':
					return 'Сгенерировать'
				case 'loading':
					return 'Загрузка'
				case 'success':
					return 'Другой вариант'
			}

			return state.lifeStatus == 'loading'
		},
		[state]
	)
}

/** Возвращает булево значение должна ли кнопка генерирования текста быть заблокированной */
export function useIsSubmitBtnDisabled() {
	const { state } = useContext(StateContext)

	return state.lifeStatus == 'empty' || state.lifeStatus == 'loading'
}

/** Возвращает булево значение должен ли появиться загрузчик на кнопке генерирования текста */
export function useIsSubmitBtnLoading() {
	const { state } = useContext(StateContext)

	return useMemo(
		function () {
			return state.lifeStatus == 'loading'
		},
		[state]
	)
}

/**
 * При получении текста ошибки ставит его в Состоянии компонента
 * @param {Function} setState — функция изменяющая объект состояния упражнения
 * @param {String} error — сообщение об ошибке
 */
export function useManageError(
	setState: StateType.SetState,
	error: null | string
) {
	useEffect(
		function () {
			setState((state) => {
				const newState = { ...state }
				newState.error = error
				return newState
			})
		},
		[error]
	)
}
