import React, { useRef } from 'react'
import HtmlEditorContentView from 'ui/HTMLEditorContentView/HTMLEditorContentView'
import { useCreateInnerHtml } from './fn/html'

type TextBlockProps = {
	htmlString: string
}

// Блок текста с пропусками
function TextBlock(props: TextBlockProps) {
	const { htmlString } = props

	const areaRef = useRef(null)

	useCreateInnerHtml(areaRef, htmlString)

	return (
		<HtmlEditorContentView
			areaRef={areaRef}
			contentExtraClass="big-editor-content-view"
		/>
	)
}

export default TextBlock
