import { Button } from 'antd'
import { useParams } from 'react-router-dom'
import { EmptyBlock } from 'ui/EmptyBlock/EmptyBlock'
import { useGoToPage } from 'parts/utils/hooks/hooks'
import AppUrls from 'parts/constants/pageUrl'
import UrlService from 'parts/services/UrlService'
import { RouterVarNames } from '../../../../../app/routes/special/otherRoutes'

// Сообщение об отсутствие упражнений в уроке
function NoExercisesInLesson() {
	return (
		<EmptyBlock
			text="В уроке нет упражнений"
			button={<GoToTrainingPage />}
		/>
	)
}

export default NoExercisesInLesson

// Кнопка переходящая на страницу курса
function GoToTrainingPage() {
	const params = useParams()
	const trainingIdStr = params[RouterVarNames.TrainingId]!

	const goToTrainingPage = useGoToPage(AppUrls.Training(trainingIdStr).url)

	return (
		<Button type="primary" onClick={goToTrainingPage}>
			На страницу курса
		</Button>
	)
}
