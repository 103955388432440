import { useEffect, useState } from 'react'
import { ExerciseStateType, initialState } from './stateType'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

/**
 * Функция создаёт местное Состояние для упражнения
 * @param {Object} exercise — данные упражнения
 */
export function useCreateExerciseState(
	exercise: TrainingEntityTypes.VideoExercise
) {
	const [state, setState] = useState<ExerciseStateType.Main>(initialState)

	useEffect(
		function () {
			setState(convertExerciseDataToExerciseState(exercise))
		},
		[exercise]
	)

	return {
		state,
		setState,
	}
}

/**
 * Функция получает объект данных упражнения и создаёт данные для местного состояния
 * @param {Object} exercise — данные упражнения из БД
 */
export function convertExerciseDataToExerciseState(
	exercise: TrainingEntityTypes.VideoExercise
): ExerciseStateType.Main {
	return {
		doneAllow:
			exercise.status ===
			TrainingEntityTypes.ExerciseProgressStatus.PENDING,
	}
}
