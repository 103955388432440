import { create } from 'zustand'
import { SetConfirmationCodeStoreType } from './storeTypes'

const useSetConfirmationCodeStore = create<SetConfirmationCodeStoreType.State>(
	(set) => {
		return {
			isOpen: false,
			isSubmitting: false,
			formErrors: {},

			updateState(obj) {
				return set((state) => {
					return obj
				})
			},

			/** Открытие модального окна */
			openModal(userId) {
				return set((state) => {
					return { isOpen: true, userId }
				})
			},

			/** Закрытие модального окна */
			closeModal() {
				return set((state) => {
					return { isOpen: false, userId: null }
				})
			},
		}
	}
)

export default useSetConfirmationCodeStore
