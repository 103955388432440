import { FC, useState } from 'react'
import type { MenuProps } from 'antd'
import { Button, Drawer, Dropdown, Popconfirm, message } from 'antd'
import {
	AppstoreAddOutlined,
	CloudDownloadOutlined,
	DeleteOutlined,
	EditOutlined,
	EllipsisOutlined,
	ExportOutlined,
	FileAddOutlined,
	LinkOutlined,
} from '@ant-design/icons'
import { useCopyTextToClipboard } from '../../fn/useCopyToClipboard'
import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'
import FolderDialog from '../NewFolder/FolderDialog'
import { MenuItemType } from 'antd/es/menu/hooks/useItems'
import useLivelessonStore from 'widgets/livelesson/liveLesson/zustand/store'
import usePdfTab from 'widgets/livelesson/liveLesson/fn/usePdfTab'
import useSendToChat from 'widgets/livelesson/liveLesson/components/LiveSidepanel/fn/useSendToChat'
import usePlayerTab from 'widgets/livelesson/liveLesson/fn/usePlayerTab'
import useDeleteStorageItems from '../../fn/useDeleteStorageItem'
import useRenameStorageItem from '../../fn/useRenameStorageItem'

import styles from './ItemAction.module.scss'

interface ItemActionProps {
	storageItem: LiveLessonEntityTypes.StorageItem
	isLive: boolean
	mobile?: boolean
}

const iconStyle = { color: '#ACB4C0' }
const folderItems = ['rename', 'delete']
const LiveAction = ['addToClass', 'sendToChat', 'addToPlaylist']

export const ItemAction: FC<ItemActionProps> = ({
	storageItem,
	isLive,
	mobile = false,
}) => {
	const [messageApi, contextHolder] = message.useMessage()
	const copyToClipboard = useCopyTextToClipboard(messageApi)
	const { sendFile } = useSendToChat()
	const { isShowConfirm, onConfirmOk, onConfirmCancel, deleteItems } =
		useDeleteStorageItems()
	const {
		isShowConfirm: isFolderDialogShow,
		onConfirmOk: handleFolderDialogOk,
		onConfirmCancel: handleFolderDialogCancel,
		renameItem,
	} = useRenameStorageItem()
	const [isMenuVisible, setIsMenuVisible] = useState(false)
	const addToPlaylist = useLivelessonStore((store) => store.addToPlaylist)
	const createPlayerTab = usePlayerTab()
	const createPdfTab = usePdfTab()

	const audioItems: MenuProps['items'] = [
		{
			key: 'addToPlaylist',
			label: 'Добавить в плейлист',
			icon: <AppstoreAddOutlined style={iconStyle} />,
		},
		// {
		// 	key: 'play',
		// 	label: 'Воспроизвести',
		// 	icon: <PlayCircleOutlined style={iconStyle} />,
		// },
		{
			type: 'divider',
		},
	]

	const videoItems: MenuProps['items'] = [
		{
			key: 'addToClass',
			label: 'Добавить в класс',
			icon: <FileAddOutlined style={iconStyle} />,
		},
		{
			type: 'divider',
		},
		// {
		// 	key: 'play',
		// 	label: 'Воспроизвести',
		// 	icon: <PlayCircleOutlined style={iconStyle} />,
		// },
		// {
		// 	type: 'divider',
		// },
	]

	const documentItems: MenuProps['items'] = [
		{
			key: 'addToClass',
			label: 'Добавить в класс',
			icon: <FileAddOutlined style={iconStyle} />,
		},
		{
			type: 'divider',
		},
	]

	const commonItems: MenuProps['items'] = [
		{
			key: 'copyLink',
			label: 'Копировать ссылку',
			icon: <LinkOutlined style={iconStyle} />,
		},
		{
			key: 'sendToChat',
			label: 'Отправить в чат',
			icon: <ExportOutlined style={iconStyle} />,
		},
		{
			type: 'divider',
		},
		{
			key: 'rename',
			label: 'Переименовать',
			icon: <EditOutlined style={iconStyle} />,
		},
		{
			key: 'download',
			label: (
				<a
					href={storageItem.s3link}
					target="_blank"
					download={storageItem.name}
					className={styles.ItemAction__DownlaodLink}
				>
					Загрузить
				</a>
			),
			icon: <CloudDownloadOutlined style={iconStyle} />,
		},
		{
			key: 'delete',
			label: 'Удалить',
			icon: <DeleteOutlined style={iconStyle} />,
		},
	]

	const onClick: MenuProps['onClick'] = ({ key }) => {
		setIsMenuVisible(false)
		switch (key) {
			case 'addToClass':
				addToClass()
				break
			case 'addToPlaylist':
				addToPlaylist(storageItem)
				messageApi.open({
					type: 'success',
					content: 'Добавлено в плейлист',
					style: {
						marginTop: '90vh',
					},
				})
				break
			case 'copyLink':
				copyToClipboard(storageItem.s3link)
				break
			case 'sendToChat':
				sendFile(storageItem.name, storageItem.s3link)
				break
			case 'delete':
				deleteItems([storageItem])
				break
			case 'rename':
				renameItem(storageItem)
				break
			case 'download':
				break
			default:
				message.info('Эта функция пока недоступна')
				break
		}
	}

	const addToClass = () => {
		if (storageItem.fileType.includes('video')) {
			createPlayerTab(storageItem)
		} else if (storageItem.fileType.includes('PDF')) {
			createPdfTab(storageItem)
		} else {
			message.warning('Такой тип файлов пока не поддерживается')
		}
	}

	const getVisibleItems = () => {
		let items: MenuProps['items'] = []

		if (storageItem.fileType === 'folder') {
			items = commonItems?.filter((item) =>
				folderItems.includes(item?.key?.toString() || '')
			)
		} else if (storageItem.fileType.includes('audio')) {
			items = [...audioItems, ...commonItems]
		} else if (storageItem.fileType.includes('video')) {
			items = [...videoItems, ...commonItems]
		} else if (storageItem.fileType.includes('PDF')) {
			items = [...documentItems, ...commonItems]
		} else {
			items = commonItems
		}

		return isLive
			? items
			: items.filter((item) => !LiveAction.includes(item?.key as string))
	}

	const renderMobileAction = () => {
		return (
			<>
				<EllipsisOutlined
					style={{ color: '#181F30' }}
					onClick={() => setIsMenuVisible(true)}
				/>
				<Drawer
					open={isMenuVisible}
					closable={false}
					placement="bottom"
					className={styles.ItemAction__Drawer}
					maskClosable
					onClose={() => setIsMenuVisible(false)}
					contentWrapperStyle={{ height: 'auto' }}
				>
					{getVisibleItems().map((item, idx) => {
						if ((item as { type: string }).type === 'divider') {
							return (
								<div
									key={idx}
									className={styles.ItemAction__MenuDivider}
								/>
							)
						} else {
							return (
								<div
									key={idx}
									className={styles.ItemAction__MenuItem}
									onClick={() => onClick(item as any)}
								>
									<div
										className={styles.ItemAction__MenuIcon}
									>
										{(item as MenuItemType).icon}
									</div>
									<div
										className={styles.ItemAction__MenuTitle}
									>
										{(item as MenuItemType).label}
									</div>
								</div>
							)
						}
					})}
				</Drawer>
			</>
		)
	}

	const renderDesktopAction = () => {
		return (
			<Dropdown
				menu={{ items: getVisibleItems(), onClick }}
				trigger={['click']}
			>
				<Popconfirm
					title="Удалить"
					description="Вы действительно хотите удалить?"
					open={isShowConfirm}
					onConfirm={onConfirmOk}
					onCancel={onConfirmCancel}
				>
					<Button
						icon={<EllipsisOutlined style={{ color: '#181F30' }} />}
					/>
				</Popconfirm>
			</Dropdown>
		)
	}

	return (
		<div className={styles.ItemAction}>
			{contextHolder}
			{mobile ? renderMobileAction() : renderDesktopAction()}
			<FolderDialog
				open={isFolderDialogShow}
				initialFolderName={storageItem.name}
				okButtonText="Переименовать"
				onOk={handleFolderDialogOk}
				onCancel={handleFolderDialogCancel}
				title="Переименовать"
				mobile={mobile}
			/>
		</div>
	)
}
