import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AppUrls from 'parts/constants/pageUrl'
import { PageHeaderTab } from 'ui/pageHeader/Tabs/Tabs'
import UrlService from 'parts/services/UrlService'
import useGroupStore from '../../zustand/store'
import { useFillInStore } from '../../zustand/fillInStore/fillInStore'

/** Возвращает данные для формирования вкладок */
export function useGetTabsData() {
	const trainingId = UrlService.useGetTrainingId()

	const { groupId } = useParams()
	const [tabsData, setTabsData] = useState<PageHeaderTab[]>([])
	const group = useGroupStore((s) => s.group)

	useFillInStore(groupId!)

	useEffect(
		function () {
			if (!trainingId || !groupId || !group) return

			const tabs = [
				{
					label: AppUrls.Training_GroupConditions('', '').name,
					address: AppUrls.Training_GroupConditions(
						trainingId,
						groupId
					).url,
				},
				{
					label: AppUrls.Training_GroupCurators('', '').name,
					address: AppUrls.Training_GroupCurators(trainingId, groupId)
						.url,
				},
			]

			if (group.type === 'test') {
				tabs.push({
					label: AppUrls.Training_GroupOpenLessons('', '').name,
					address: AppUrls.Training_GroupOpenLessons(
						trainingId,
						groupId
					).url,
				})
			}

			setTabsData(tabs)
		},
		[group]
	)

	return tabsData
}
