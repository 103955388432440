import { Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import {
	UFormNewBottomButtonsAfterGray,
	UFormNewButtonsContainer,
	UFormNewRow,
} from 'ui/UForm/UForm'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'
import LessonAdminService from 'parts/services/LessonAdminService'
import { createFileListFromUrlList } from 'ui/upload/UploadImage/fn/fn'
import useLessonsListAdminStore from '../../zustand/store'
import { EditLessonModalController } from '../EditLessonModalController'
import useEditLessonModalStore from '../zustand/store'
import { FieldNames, setErrorsToForm } from './fn/form'
import { useSetValuesFormValues } from './fn/setInitialFormValues'
import { useGetOnSubmit } from './fn/submit'
import UploadAvatar from 'ui/upload/UploadFileInForm/UploadAvatar'

// Модальное окно добавления нового урока
function EditLessonForm() {
	const [form] = Form.useForm()

	useSetValuesFormValues(form)
	const onSubmit = useGetOnSubmit()
	setErrorsToForm(form)

	return (
		<Form
			form={form}
			name="editLesson"
			layout="vertical"
			onFinish={onSubmit}
		>
			<GrayBlock>
				<NameInput />
				<DescriptionInput />
				<CoverInput />
			</GrayBlock>
			<Bottom />
		</Form>
	)
}

export default EditLessonForm

// Поле названия урока
function NameInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.Name}
				label={t('adminTraining.editLessonModalNameFieldLabel')}
				rules={[
					{
						required: true,
						message: t(
							'adminTraining.editLessonModalNameFieldRequiredError'
						),
					},
				]}
			>
				<Input />
			</Form.Item>
		</UFormNewRow>
	)
}

// Поле описания урока
function DescriptionInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Description}
				label={t('adminTraining.editLessonModalNameFieldLabel')}
			>
				<Input.TextArea />
			</Form.Item>
		</UFormNewRow>
	)
}

// Поле загрузки обложки
function CoverInput() {
	const { t } = useTranslation()

	const lessonId = useEditLessonModalStore((store) => store.lessonId)

	const lessons = useLessonsListAdminStore((state) => state.lessons)
	const lesson = LessonAdminService.findLessonByIdInLessons(lessons, lessonId)

	const covers = lesson?.cover
		? createFileListFromUrlList([lesson.cover])
		: []

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Cover}
				label={t('adminTraining.editLessonModalNameFieldLabel')}
			>
				<UploadAvatar
					listType="picture-card"
					inputName={FieldNames.Cover}
					initialFileList={covers}
					maxCount={1}
				/>
			</Form.Item>
		</UFormNewRow>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
// Кнопки подтверждения и закрытия окна
function Bottom() {
	const { t } = useTranslation()

	return (
		<UFormNewBottomButtonsAfterGray>
			<div />
			<UFormNewButtonsContainer>
				<Button onClick={EditLessonModalController.close}>
					{t('adminTraining.editLessonModalCancelBtn')}
				</Button>
				<Button type="primary" htmlType="submit">
					{t('adminTraining.editLessonModalOkBtn')}
				</Button>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
