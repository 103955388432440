import Loader from 'ui/Loader/Loader'
import { useFillInStore } from '../zustand/fillStore/fillInStore'
import useTrainingsAdminStore from '../zustand/store'
import TrainingsList from '../TrainingsList'
import { TrainingsStoreType } from '../zustand/storeTypes'

type RouterReturn = {
	status: TrainingsStoreType.DataLoadingStatus
	element: JSX.Element
}

/** Хук возвращающий статус компонента TrainingList и сам компонент в зависимости от статуса */
export function useGetTrainingListRouter(): RouterReturn {
	// Получить список курсов, преобразовать данные под формат для отрисовки карточек курсов и поставить в Состояние
	useFillInStore()

	const dataLoadingStatus = useTrainingsAdminStore(
		(state) => state.dataLoadingStatus
	)

	if (dataLoadingStatus === 'loading') {
		return {
			status: dataLoadingStatus,
			element: <Loader />,
		}
	} else if (dataLoadingStatus === 'noTrainings') {
		return {
			status: dataLoadingStatus,
			element: <p>noTrainings</p>,
		}
	} else if (dataLoadingStatus === 'error') {
		return {
			status: dataLoadingStatus,
			element: <p>error</p>,
		}
	}

	return {
		status: dataLoadingStatus,
		element: <TrainingsList />,
	}
}
