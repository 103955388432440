import React from 'react'
import 'pages/landing/landingConstructor/details/Footer/Footer.scss'

function Footer() {
	return (
		<div className="land-const-footer">
			Сделано платформой{' '}
			<a href="pages/landing/landingConstructor/details/Footer/Footer">
				Credu
			</a>
		</div>
	)
}

export default Footer
