import React from 'react'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useGetSetVisibilityToAssignEmployeeToGroupModal } from '../AssignEmployeeToGroupModal/fn/openCloseModal'

// Кнопка открывающая модальное окно добавления куратора в группу
function AssignEmployeeToGroupButton() {
	const { t } = useTranslation()
	const openModal = useGetSetVisibilityToAssignEmployeeToGroupModal(true)

	return (
		<Button type="primary" icon={<PlusOutlined />} onClick={openModal}>
			Добавить сотрудника в группу
		</Button>
	)
}

export default AssignEmployeeToGroupButton
