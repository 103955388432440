import { useEffect } from 'react'
import { produce } from 'immer'
import { RcFile } from 'antd/lib/upload/interface'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { exercisesFormsManager } from '../../../exercisesFormsManager'
import useExercisesListAdminStore from '../../../zustand/store'
import {
	AudioExerciseFormStateType,
	audioFormInitialState,
} from './audioFormState'
import {
	FormFileStateItemType,
	ImageExerciseFormStateType,
	imageFormInitialState,
} from './imageFormState'
import downloadFileToAmazon from 'parts/services/downloadFileToAmazon'
import UploadFileTypes from 'parts/constants/uploadTypes'
import { getFormState, setFormState } from '../../common/exerciseFormCommonFunc'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

export type ExerciseType = 'audio' | 'image'
export type MediaExerciseFormStateType =
	| AudioExerciseFormStateType
	| ImageExerciseFormStateType
export type MediaExerciseDataType =
	| TrainingEntityTypes.AudioExercise
	| TrainingEntityTypes.ImageExercise

/** Хук устанавливающий данные формы в Хранилище */
export function useSetFormData(exerciseType: ExerciseType) {
	const exercises = useExercisesListAdminStore((store) => store.exercises)
	const exerciseId = useExercisesListAdminStore((store) => store.exerciseId)

	const setDataToExerciseFormStore =
		exercisesFormsManager.useGetSetDataToExerciseFormStore()

	useEffect(
		function () {
			let formData: MediaExerciseFormStateType =
				exerciseType == 'audio'
					? audioFormInitialState
					: imageFormInitialState

			const exercise = exercises.find(
				(exercise) => exercise.id === exerciseId
			)

			if (exercise) {
				formData = convertExerciseDataToFormStateData(
					exercise as MediaExerciseDataType,
					exerciseType
				)
			}

			setDataToExerciseFormStore(formData)
		},
		[exerciseId]
	)
}

function convertExerciseDataToFormStateData(
	exercise: MediaExerciseDataType,
	exerciseType: ExerciseType
	// @ts-ignore
): MediaExerciseFormStateType {
	const formStateData: MediaExerciseFormStateType = {
		exerciseId: exercise.id || null,
		type: exerciseType,
		order: exercise.order || 0,
		taskInput: {
			value: exercise.item.task,
		},
		noneAnswerInput: {
			checked: exercise.item.noneAnswer,
		},
		audioAnswerInput: {
			checked: exercise.item.audioAnswer,
		},
		writeAnswerInput: {
			checked: exercise.item.writtenAnswer,
		},
		transcriptionInput: {
			checked: !!exercise.item.transcription,
			value: exercise.item.transcription,
		},
		files: [],
		noFileHasDownloadedError: false,
		noTranscriptionPassedError: false,
		isFormInvalid: false,
	}

	if (exercise.type == 'audio') {
		formStateData.files = exercise.item.audio.map((file, i) => {
			return getFileObj(i, file)
		})
	} else if (exercise.type == 'image') {
		formStateData.files = exercise.item.image.map((file, i) => {
			return getFileObj(i, file)
		})
	}

	function getFileObj(i: number, fileLink: string): FormFileStateItemType {
		return {
			id: i,
			link: fileLink, // Адрес загруженного файла
			status: 'success',
			progress: 0,
			fileName: '',
		}
	}

	return formStateData
}

/**
 * Обработчик изменения поля «Задание»
 * @param {String} newValue — строка HTML из редактора
 */
export function onChangeTaskInput(newValue: string) {
	const formState = getFormState<MediaExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		draft.taskInput.value = newValue
	})

	setFormState(newState)
}

/**
 * Обработчик изменения поля «Задание»
 * @param {String} newValue — строка HTML из редактора
 */
export function onChangeTranscriptInput(newValue: string) {
	const formState = getFormState<MediaExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		draft.transcriptionInput.value = newValue
		draft.noTranscriptionPassedError = false
		draft.isFormInvalid = false
	})

	setFormState(newState)
}

/** Хук возвращает обработчик кнопки создания нового блока файла */
export function addNewMediaBlock() {
	const formState = getFormState<MediaExerciseFormStateType>()

	let maxAudioBlockId = 0

	formState.files.forEach((mediaBlock) => {
		if (mediaBlock.id > maxAudioBlockId) {
			maxAudioBlockId = mediaBlock.id
		}
	})

	const newAudioBlock: FormFileStateItemType = {
		id: ++maxAudioBlockId,
		link: '',
		status: 'empty',
		progress: 0,
		fileName: '',
	}

	const newState = produce(formState, (draft) => {
		draft.files.push(newAudioBlock)
	})

	setFormState(newState)
}

/**
 * Функция загружает файл, получает его адрес на сервере и все данные записывает в местное Хранилище
 * @param {Object} file — данные о загружаемом файле
 * @param {Array} FileList
 * @param {Number} mediaBlockId — id блока с полями касаемые файла
 * @param exerciseType
 */
export async function beforeUploadFile(
	file: RcFile,
	FileList: RcFile[],
	mediaBlockId: number,
	exerciseType: ExerciseType
) {
	const mediaType =
		exerciseType == 'audio'
			? UploadFileTypes.EXERCISE_AUDIO
			: UploadFileTypes.EXERCISE_IMAGES

	await downloadFileToAmazon(file, FileList, mediaType, {
		beforeDownloading(fileName: string) {
			// Поставить в состояние блока загрузки файла статус загрузки файла
			changeDownloadFileState(mediaBlockId, {
				status: 'downloading',
				progress: 0,
				fileName,
			} as Partial<FormFileStateItemType>)
		},
		whileDownloading(percentCompleted, cancelDownloading, fileUrl: string) {
			// Если статус загружаемого файла не равен 'downloading', то есть его изменили, то прекратить загрузку
			if (
				getMediaBlockById(
					getFormState<MediaExerciseFormStateType>(),
					mediaBlockId
				)?.status !== 'downloading'
			) {
				cancelDownloading()
			}

			// Поставить значение загруженных процентов в Состояние
			changeDownloadFileState(mediaBlockId, {
				progress: percentCompleted,
				link: fileUrl,
			} as Partial<FormFileStateItemType>)
		},
		onSuccess(fileName: string, url: string) {
			changeDownloadFileState(mediaBlockId, {
				link: url,
				status: 'success',
				fileName,
			} as Partial<FormFileStateItemType>)

			// Убрать ошибку, что не загружен ни один файл
			const formState = getFormState<MediaExerciseFormStateType>()
			const newState = produce(formState, (draft) => {
				draft.noFileHasDownloadedError = false

				if (!draft.noTranscriptionPassedError) {
					draft.isFormInvalid = false
				}
			})
			setFormState(newState)
		},
		onError(fileName: string) {
			changeDownloadFileState(mediaBlockId, {
				status: 'error',
				fileName,
			} as Partial<FormFileStateItemType>)
		},
	})
}

/**
 * Обновляет переданные свойства в объекте Состояния загружаемого файла.
 * @param {Number} mediaBlockId — id блока с полями касаемые файла
 * @param {Object} newDownloadFileProps — объект данных Состояния загружаемого файла
 */
function changeDownloadFileState(
	mediaBlockId: number,
	newDownloadFileProps: Partial<FormFileStateItemType>
) {
	const formState = getFormState<MediaExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const thisAudioIdx = draft.files.findIndex(
			(audio) => audio.id == mediaBlockId
		)
		draft.files[thisAudioIdx] = Object.assign(
			draft.files[thisAudioIdx],
			newDownloadFileProps
		)
	})

	setFormState(newState)
}

/**
 * Функция ставит статус загружаемого файла в значение empty
 * @param {Number} mediaBlockId — id блока с полями касаемые файла
 */
export function setFileDownloadedStatusToDefault(mediaBlockId: number) {
	changeDownloadFileState(mediaBlockId, {
		status: 'empty',
	} as Partial<FormFileStateItemType>)
}

/**
 * Обработчик нажатия на кнопку закрытия блока с аудио
 * @param {Number} audiosBlockId — id блока с полями касаемые аудио
 */
export function closeMediaBlock(audiosBlockId: number) {
	const formState = getFormState<MediaExerciseFormStateType>()

	const newState = { ...formState }
	newState.files = [...newState.files]

	const thisVideoIdx = newState.files.findIndex(
		(file) => file.id === audiosBlockId
	)
	newState.files.splice(thisVideoIdx, 1)

	setFormState(newState)
}

/**
 * Обработчик изменения полей «Ссылка на аудио» и «Название аудио»
 * @param {Object} event — объект события
 * @param {String} radioType — поле к которому предназначается обработчик: audioLink (Ссылка на аудио) или audioName (Название аудио)
 */
export function onChangeAnswerRadios(
	event: CheckboxChangeEvent,
	radioType: 'noneAnswer' | 'audioAnswer' | 'writeAnswer'
) {
	const formState = getFormState<MediaExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		draft.noneAnswerInput.checked = radioType === 'noneAnswer'
		draft.audioAnswerInput.checked = radioType === 'audioAnswer'
		draft.writeAnswerInput.checked = radioType === 'writeAnswer'
	})

	setFormState(newState)
}

/**
 * Обработчик изменения полей «Ссылка на аудио» и «Название аудио»
 */
export function onChangeTranscriptToggle(isOn: boolean) {
	const formState = getFormState<MediaExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		draft.transcriptionInput.checked = isOn

		draft.noTranscriptionPassedError = false
	})

	setFormState(newState)
}

/**
 * Обработчик отправки формы
 * @param {Function} saveExercise — функция сохраняющая упражнение на сервере и в Состоянии
 */
export function submitHandler(
	saveExercise: (args: TrainingEntityTypes.ExerciseItem) => void
) {
	const isFormValid = validateForm()

	const formState = getFormState<MediaExerciseFormStateType>()

	if (!isFormValid) {
		setFormState({ ...formState, isFormInvalid: true })
		return
	}

	const exerciseData = convertFormStateDataToExerciseData(formState)
	saveExercise(exerciseData)
}

/** Функция проверяет правильность заполнения формы, изменяет Состояние для показа ошибок и возвращает булево значение является ли форма правильной */
function validateForm() {
	let isFormValid = true
	let formHasAudios = false

	const formState = getFormState<MediaExerciseFormStateType>()

	formState.files.forEach((fileBlock) => {
		if (fileBlock.link) {
			formHasAudios = true
		}
	})

	const newState = produce(formState, (draft) => {
		if (!formHasAudios) {
			draft.noFileHasDownloadedError = true
			isFormValid = false
		}

		if (
			formState.transcriptionInput.checked &&
			formState.transcriptionInput.value == ''
		) {
			draft.noTranscriptionPassedError = true
			isFormValid = false
		}
	})

	setFormState(newState)

	return isFormValid
}

/**
 * Функция переводит данные формы редактирования упражнения в данные упражнения для сохранения на сервере.
 * @param {Object} formState — Объект с данными формы
 */
function convertFormStateDataToExerciseData(
	formState: MediaExerciseFormStateType
) {
	const lessonId = useExercisesListAdminStore.getState().lessonId

	let exerciseData: MediaExerciseDataType

	if (formState.type == 'audio') {
		exerciseData = {
			type: 'audio',
			lessonId,
			order: formState.order,
			item: {
				task: formState.taskInput.value,
				audio: formState.files
					.map((file) => file.link)
					.filter((link) => !!link),
				noneAnswer: formState.noneAnswerInput.checked,
				audioAnswer: formState.audioAnswerInput.checked,
				writtenAnswer: formState.writeAnswerInput.checked,
				transcription: '',
			},
			status: 0, // Чтобы TS не ругался
			statusUpdated: '', // Чтобы TS не ругался
		} as TrainingEntityTypes.AudioExercise
	} else {
		exerciseData = {
			type: 'image',
			lessonId,
			order: formState.order,
			item: {
				task: formState.taskInput.value,
				image: formState.files
					.map((file) => file.link)
					.filter((link) => !!link),
				noneAnswer: formState.noneAnswerInput.checked,
				audioAnswer: formState.audioAnswerInput.checked,
				writtenAnswer: formState.writeAnswerInput.checked,
				transcription: '',
			},
			status: 0, // Чтобы TS не ругался
			statusUpdated: '', // Чтобы TS не ругался
		} as TrainingEntityTypes.ImageExercise
	}

	if (formState.exerciseId) {
		exerciseData.id = formState.exerciseId
	}

	const transcriptionValue = formState.transcriptionInput.value
	if (formState.transcriptionInput.checked && transcriptionValue) {
		exerciseData.item.transcription = transcriptionValue
	}

	return exerciseData
}

/** Функция перебирает объект состояния формы и возвращает булево значение есть ли там аудио, которое загружается в настоящий момент */
export function isDownloadingFile() {
	const formState = getFormState<MediaExerciseFormStateType>()

	for (let i = 0; i < formState.files.length; i++) {
		const audioBlock = formState.files[i]
		if (audioBlock.status == 'downloading') {
			return true
		}
	}

	return false
}

function getMediaBlockById(
	formState: MediaExerciseFormStateType,
	mediaBlockId: number
) {
	return formState.type == 'audio'
		? formState.files.find((mediaBlock) => mediaBlock.id == mediaBlockId)
		: formState.files.find((mediaBlock) => mediaBlock.id == mediaBlockId)
}
