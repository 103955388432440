import React from 'react'
import { produce } from 'immer'
import { getFormState, setFormState } from '../../common/exerciseFormCommonFunc'
import { DescriptionImgExerciseFormStateType, ImageFile } from './formState'
import { getMatchBlockById } from './check'

/**
 * Обработчик изменения поля «Задание»
 * @param {Object} newValue — строка HTML из редактора
 */
export function onChangeTaskInput(newValue: string) {
	const formState = getFormState<DescriptionImgExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		draft.taskInput.value = newValue
	})

	setFormState(newState)
}

/**
 * Обработчик изменения поля «Вопрос»
 * @param {Object} event — объект события
 * @param {Number} matchBlockId — id объекта соответствия
 */
export function onChangeImageDescriptionInput(
	event: React.ChangeEvent<HTMLInputElement>,
	matchBlockId: number
) {
	const formState = getFormState<DescriptionImgExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const inputValue = event.target.value

		const matchBlock = getMatchBlockById(draft, matchBlockId)
		if (!matchBlock) return

		matchBlock.text = inputValue

		if (inputValue) {
			matchBlock.noTextError = false
			draft.isFormInvalid = false
		}
	})

	setFormState(newState)
}

/**
 * Обновляет переданные свойства в объекте Состояния загружаемого файла.
 * @param {Number} questionId — id объекта вопроса
 * @param {Object} newDownloadFileProps — объект данных Состояния загружаемого файла
 */
export function changeDownloadFileState(
	questionId: number,
	newDownloadFileProps: Partial<ImageFile>
) {
	const formState = getFormState<DescriptionImgExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const matchBlock = getMatchBlockById(draft, questionId)

		if (matchBlock) {
			matchBlock.image = Object.assign(
				matchBlock.image,
				newDownloadFileProps
			)
		}
	})

	setFormState(newState)
}
