import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import useDeleteModalStore from '../../../../modal/DeleteModal/store/store'
import AppUrls from 'parts/constants/pageUrl'
import schoolRequests from 'parts/requests/school/schoolRequest'
import useEmployeeStore from '../../zustand/store'

/** Удаление пользователя */
export function useGetDeleteUser() {
	const { t } = useTranslation()

	const updateModalStore = useDeleteModalStore((state) => state.updateStore)
	const employee = useEmployeeStore((store) => store.employee)
	const navigate = useNavigate()

	const deleteUser = useGetDeleteUserHandler(employee?.id)

	return function () {
		if (!employee) return

		updateModalStore({
			isOpen: true,
			header: 'Вы действительно хотите удалить сотрудника?',
			text: 'Это действие нельзя отменить.',
			onOk: deleteUser,
			afterOk: () => {
				navigate(AppUrls.Staff().url)
			},
		})
	}
}

/** Обработчик удаления пользователя */
export function useGetDeleteUserHandler(userId: undefined | number) {
	const { t } = useTranslation()

	return async () => {
		if (!userId) return

		const deleteUserRes = await schoolRequests.deleteUser(userId)

		if (deleteUserRes.status === 200) {
			message.success('Пользователь удалён')
		} else {
			message.error('Не удалось удалить пользователя')
		}
	}
}
