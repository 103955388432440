const staffEn = {
	// Кнопка добавления сотрудника
	addEmployeeBtn: 'Add',

	// ТАБЛИЦА СО СПИСКОМ СОТРУДНИКОВ
	// Заголовок ряда «Имя и фамилия»
	tableHCellName: 'Name and surname',
	// Заголовок ряда «Должность»
	tableHCellRole: 'Position',
	// Заголовок ряда «Телефон»
	tableHCellPhone: 'Phone number',
	// Заголовок ряда «Почта»
	tableHCellEmail: 'E-mail',
	// Заголовок ряда «Группы»
	tableHCellGroups: 'Groups',
	// Заголовок ряда «Статус»
	tableHCellStatus: 'Status',
}

export default staffEn
