import PaymentsApiTypes from 'parts/requests/payments/paymentsApiTypes'
import PaymentTypes from 'parts/types/PaymentTypes'
import { dateStrToHumanDateAndTime } from 'parts/utils/time'
import { HistoryStatsStoreType } from '../storeTypes'

export function convertHistoryResToStoreHistory(
	historyRes: PaymentsApiTypes.GetAdminSubscriptionsHistory
): HistoryStatsStoreType.HistoryItem[] {
	return historyRes.map((historyItem) => {
		return {
			id: historyItem.id,
			paymentDate: dateStrToHumanDateAndTime(historyItem.paymentDate),
			planName: historyItem.tariffPlanName,
			paymentSum: getPaymentSum(historyItem),
			invoiceLink: historyItem.invoice ? historyItem.invoice : null,
		}
	})
}

function getPaymentSum(historyItem: PaymentsApiTypes.AdminSubscriptionHistory) {
	const currency =
		// @ts-ignore
		PaymentTypes.Currencies[historyItem.currency.toUpperCase()].sign

	return historyItem.price + currency
}
