import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'

export const defaultWhiteboardId = 'b31caad6-da08-42b0-8c0b-4e402334e2f4'
export const defaultMeetingRoomId = 'b31caad6-da08-42b0-8c0b-4e402334e2f5'
export const defaultStorageId = 'b31caad6-da08-42b0-8c0b-4e402334e2f6'

export const defaultTabs: LiveLessonEntityTypes.Tab[] = [
	{
		id: defaultMeetingRoomId,
		type: LiveLessonEntityTypes.TabType.MeetingRoom,
		name: 'Конференция',
		extra: {
			view: 'singleView',
		},
	},
]

export enum SIDEPANEL_TABS {
	CHAT = 'CHAT',
	PARTICIPANTS = 'PARTICIPANTS',
}
