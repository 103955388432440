import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import ScreenWrapper from '../ScreenWrapper/ScreenWrapper'
import { useGetOnButtonClick } from './fn/registerUser'
import './CantAccessToLessonScreen.scss'

// Экран сообщающий, что нельзя сотруднику другой школы нельзя попасть на эфир
// и требуется создать ученическую почту
function CantAccessToLessonScreen() {
	const { t } = useTranslation()
	const onButtonClick = useGetOnButtonClick()

	return (
		<ScreenWrapper
			header={t('live.liveEntranceСantAccessScreenHeader')}
			description={t('live.liveEntranceСantAccessScreenDescription')}
			illustration
		>
			<Button
				block
				size="large"
				type="primary"
				onClick={onButtonClick}
				className="live-lesson-cant-access__button"
			>
				{t('live.liveEntranceСantAccessScreenRegBtn')}
			</Button>
		</ScreenWrapper>
	)
}

export default CantAccessToLessonScreen
