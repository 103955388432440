import { useEffect } from 'react'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { exercisesFormsManager } from '../../../exercisesFormsManager'
import useExercisesListAdminStore from '../../../zustand/store'
import { formInitialState, TextExerciseFormStateType } from './formState'

/** Функция получает из данных курса данные упражнения и преобразует в данные для сохранения в Хранилище */
export function useSetFormData() {
	const exercises = useExercisesListAdminStore((store) => store.exercises)
	const exerciseId = useExercisesListAdminStore((store) => store.exerciseId)

	const setDataToExerciseFormStore =
		exercisesFormsManager.useGetSetDataToExerciseFormStore()

	useEffect(
		function () {
			let formData: TextExerciseFormStateType = formInitialState

			const exercise = exercises.find(
				(exercise) => exercise.id === exerciseId
			)

			if (exercise) {
				formData = convertExerciseDataToFormStateData(
					exercise as TrainingEntityTypes.TextExercise
				)
			}

			setDataToExerciseFormStore(formData)
		},
		[exerciseId]
	)
}

/**
 * Получает данные упражнения сохранённые в БД и преобразует их в формат для редактирования
 * @param {Object} textExercise — данные текстового упражнения из БД
 */
function convertExerciseDataToFormStateData(
	textExercise: TrainingEntityTypes.TextExercise
): TextExerciseFormStateType {
	return {
		exerciseId: textExercise.id || null,
		type: 'text',
		order: textExercise.order || 0,
		taskInput: {
			value: textExercise.item.task,
		},
		textInput: {
			value: textExercise.item.text,
			error: '',
		},
		generatedText: '',
		generatedTextPrompt: textExercise.item.generatedTextPrompt,
		isFormInvalid: false,
	}
}
