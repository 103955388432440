import React, { useEffect } from 'react'
import { Form, FormInstance } from 'antd'
import { translite } from 'parts/utils/string'
import { setErrorsToFields } from 'parts/utils/form'
import useMakeSchoolStore from '../zustand/store'

export enum FieldNames {
	SchoolName = 'schoolName',
	Domain = 'domain',
}

export type FormValuesType = {
	[FieldNames.SchoolName]: string
	[FieldNames.Domain]: string
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useMakeSchoolStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}

/** Следит за изменением значения поля имени школы и ставит в поле домена школе транслитерированное значение домена. */
export function useManageSchoolDomainInput() {
	const form = Form.useFormInstance()
	const schoolNameValue = Form.useWatch(FieldNames.SchoolName, form)

	useEffect(
		function () {
			const transliteratedSchoolName = translite(schoolNameValue)

			let domainName = transliteratedSchoolName
				.normalize('NFD') // Преобразуем символы с диакритическими знаками в нормальную форму
				.replace(/[\u0300-\u036f]/g, '') // Убираем диакритические знаки
				.replace(/[^a-zA-Z0-9\sа-яА-ЯёЁ]/g, '') // Убираем спецсимволы, оставляем только буквы и цифры
				.replace(/\s+/g, '-') // Заменяем пробелы на дефисы
				.toLowerCase() // Приводим к нижнему регистру

			// Ограничиваем длину до 63 символов
			domainName = domainName?.substring(0, 63)

			form.setFields([
				{
					name: FieldNames.Domain,
					value: domainName,
					errors: [],
				},
			])
		},
		[schoolNameValue]
	)
}

/**
 * Обработчик изменения поля домена школы.
 * Убирает ошибку при изменении.
 * @param {Object} e — объект события.
 * @param {Object} form — объект формы
 */
export function onSchoolDomainInputChange(
	e: React.ChangeEvent<HTMLInputElement>,
	form: FormInstance
) {
	if (!isValidDomain(e.target.value)) {
		form.setFields([
			{
				name: FieldNames.Domain,
				value: e.target.value,
				errors: [
					'Домен должен содержать только латинские буквы, цифры и дефисы, макс длина 63 символа',
				],
			},
		])
	} else {
		form.setFields([
			{
				name: FieldNames.Domain,
				value: e.target.value,
				errors: [],
			},
		])
	}
}

function isValidDomain(domainName: string): boolean {
	// Правило: домен должен содержать только допустимые символы (буквы, цифры, дефисы)
	const validDomainPattern = /^[a-z0-9-]+$/

	// Проверяем длину домена
	if (domainName.length < 1 || domainName.length > 63) {
		return false
	}

	// Проверяем, соответствует ли домен допустимым символам
	if (!validDomainPattern.test(domainName)) {
		return false
	}

	// Правило: не должно быть двух дефисов подряд и домен не должен начинаться или заканчиваться дефисом
	if (
		domainName.includes('--') ||
		domainName.startsWith('-') ||
		domainName.endsWith('-')
	) {
		return false
	}

	return true
}
