import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { PaymentSystemCommonDataType } from '../paymentSystems'
import { UCardBlock } from 'ui/wrappers/UCardBlock/UCardBlock'
import AppUrls from 'parts/constants/pageUrl'
import PaymentNameBlock from '../PaymentNameBlock/PaymentNameBlock'
import PaymentConditionsBlock from '../PaymentConditionsBlock/PaymentConditionsBlock'
import { getBorderColor } from './fn/getBorderColor'
import { SettingOutlined } from '@ant-design/icons'
import { CardConnectionType } from '../common'
import './PaymentCard.scss'

type CardProps = {
	connectionType: CardConnectionType
	paymentSystemName: string
	data: PaymentSystemCommonDataType
}

// Карточка с краткой информацией о платёжной системе. Кнопка перехода на страницу настроек.
export function PaymentCard(props: CardProps) {
	const { connectionType, paymentSystemName, data } = props

	return (
		<UCardBlock
			extraClass="payment-card"
			borderColor={getBorderColor(connectionType)}
		>
			<PaymentNameBlock data={data} />
			<PaymentConditionsBlock data={data} view="horizontal" />
			<Bottom
				connectionType={connectionType}
				paymentSystemName={paymentSystemName}
			/>
		</UCardBlock>
	)
}

type BottomProps = {
	connectionType: CardConnectionType
	paymentSystemName: string
}

function Bottom(props: BottomProps) {
	const { connectionType, paymentSystemName } = props
	const { t } = useTranslation()

	return (
		<Link to={AppUrls.MyPayment(paymentSystemName).url}>
			{connectionType == 'on' ? (
				<Button icon={<SettingOutlined />} type="link" block>
					{t('myPayments.paymentShortCardSettingsButton')}
				</Button>
			) : (
				<Button type="primary" ghost block>
					{t('myPayments.paymentShortCardConnectButton')}
				</Button>
			)}
		</Link>
	)
}
