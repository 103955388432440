import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import ServerTypes from 'parts/types/ServerTypes'
import AuthApiTypes from 'parts/requests/auth/authApiTypes'
import authRequests from 'parts/requests/auth/authRequest'
import useConfirmEmailStore from '../zustand/store'
import useRegisterAdminStore from '../../../registerOwnerStart/RegistrationForm/zustand/store'
import { FormValuesType } from './form'
import AppUrls from 'parts/constants/pageUrl'

/** Хук возвращающий обработчик отправки формы */
export function useGetOnSubmit() {
	const navigate = useNavigate()

	const updateConfirmEmailStore = useConfirmEmailStore(
		(state) => state.updateStore
	)

	const userId = useRegisterAdminStore((state) => state.userId)!

	return async (fieldsValue: FormValuesType) => {
		try {
			// Поставить статус загрузки
			updateConfirmEmailStore({ isSubmitting: true })

			// Отправить запрос и получить ответ
			const confirmResponse = await authRequests.activateUserByCode(
				getCheckEmailCodeDto(fieldsValue, userId)
			)

			if (confirmResponse.data) {
				// Отправить событие в Google Analytics
				window.dataLayer.push({
					event: 'Register',
					category: 'surveyData2',
					action: 'sent',
					label: 'Register',
				})
				// Отправить на страницу создания домена школы
				navigate(AppUrls.Register_Owner_School().url)
			}
		} catch (err) {
			const error = err as AxiosError<ServerTypes.ErrorResponse>

			if (error.response) {
				// Поставить данные ошибки в Состояние, чтобы показать их в форме
				updateConfirmEmailStore({ formErrors: error.response.data })
			}
		} finally {
			// Убрать статус загрузки
			updateConfirmEmailStore({ isSubmitting: false })
		}
	}
}

/**
 * Формирует DTO для отправки запроса для создания администратора
 * @param {Array} values — массив значений полей формы
 * @param userId — id созданного администратора
 */
function getCheckEmailCodeDto(
	values: FormValuesType,
	userId: number
): AuthApiTypes.ActivateUserByCodeDto {
	return {
		userId,
		activationCode: values.activationCode,
	}
}
