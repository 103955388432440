import React, { ReactNode } from 'react'
import cn from 'classnames'
import { Alert } from 'antd'
import './UForm.scss'

// Белый контейнер с обводкой для полей формы -----------

type UFormNewWrapperProps = {
	children: ReactNode | ReactNode[]
}

export function UFormNewWhiteWrapper(props: UFormNewWrapperProps) {
	const { children } = props

	return <div className="uform__white-wrapper">{children}</div>
}

// Обёртка для левой и правой части формы -----------

type UFormNewLRPartsProps = {
	children: [ReactNode, ReactNode]
}

export function UFormNewLRParts(props: UFormNewLRPartsProps) {
	const { children } = props
	const [leftComponent, rightComponent] = children

	return (
		<div className="uform__lr">
			<div className="uform__lr-left">{leftComponent}</div>
			<div className="uform__lr-divider" />
			<div className="uform__lr-right">{rightComponent}</div>
		</div>
	)
}

// Ряд с полем/полями ввода -----------

type UFormNewBlockHeaderProps = {
	text: string // Текст заголовка
}

export function UFormNewBlockHeader(props: UFormNewBlockHeaderProps) {
	const { text } = props

	return <h3 className="uform__block-header">{text}</h3>
}

// Ряд с полем/полями ввода -----------

type UFormNewRowProps = {
	topOffset?: boolean // Отступ сверху
	children: ReactNode | ReactNode[]
}

export function UFormNewRow(props: UFormNewRowProps) {
	const { topOffset = false, children } = props

	const classes = ['uform__row']

	if (topOffset) {
		classes.push('uform__row--top-offset')
	}

	if (Array.isArray(children) && children.length > 1) {
		classes.push('uform__row--many-cell')
	}

	return <div className={cn(classes)}>{children}</div>
}

// Текстовая подсказка ниже поля ввода -----------

type UFormNewInputCaptionProps = {
	children: string | ReactNode
}

export function UFormNewInputCaption(props: UFormNewInputCaptionProps) {
	const { children } = props

	return <div className="uform__input-caption">{children}</div>
}

// Ряд с полем/полями ввода

export function UFormNewDivider() {
	return <div className="uform__divider" />
}

// Нижняя часть с кнопками очистки формы и отправки. -----------
// Следует после серого блока с полями формы.

type UFormNewBottomButtonsAfterGrayProps = {
	children: ReactNode | ReactNode[]
}

export function UFormNewBottomButtonsAfterGray(
	props: UFormNewBottomButtonsAfterGrayProps
) {
	const { children } = props

	return <div className="uform__bottom-after-gray">{children}</div>
}

// Нижняя часть с кнопками очистки формы и отправки. -----------
// Следует после серого блока с полями формы.

type UFormNewBottomButtonsWithBorderProps = {
	children: ReactNode | ReactNode[]
}

export function UFormNewBottomButtonsWithBorder(
	props: UFormNewBottomButtonsWithBorderProps
) {
	const { children } = props

	return <div className="uform__bottom-with-border">{children}</div>
}

// Контейнер для кнопок для их разделения -----------

type UFormNewButtonsContainerProps = {
	children: ReactNode | ReactNode[]
}

export function UFormNewButtonsContainer(props: UFormNewButtonsContainerProps) {
	const { children } = props

	return <div className="uform__buttons-container">{children}</div>
}

// Сообщение об общей ошибки снизу формы -----------

type UFormNewGeneralErrorProps = {
	message: undefined | string
}

export function UFormNewGeneralError(props: UFormNewGeneralErrorProps) {
	const { message } = props

	return message ? (
		<div className="uform__general-error">
			<Alert message={message} type="error" />
		</div>
	) : null
}
