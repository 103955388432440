import { Avatar, Badge, Dropdown, Menu, Select, Space } from 'antd'
import { FilterOutlined } from '@ant-design/icons'
import styles from '../Message.module.scss'
import { DataMessagesType } from './DataMessagesType'
import React from 'react'

const { Option } = Select

const list: DataMessagesType[] = [
	{
		id: 1,
		image: '/images/folder-blue.svg',
		name: 'Курс “Английский для начинающих”',
		launchDate: '04.08.2022',
	},
	{
		id: 2,
		image: '/images/folder-blue.svg',
		name: 'Курс “Произношение”',
		launchDate: '04.08.2022',
	},
	{
		id: 3,
		image: '/images/folder-blue.svg',
		name: 'Курс “Разговорный”',
		launchDate: '04.08.2022',
	},
	{
		id: 4,
		image: '/images/folder.svg',
		name: 'Сотрудники',
		launchDate: '',
	},
	{
		id: 5,
		image: '/images/folder-sky-blue.svg',
		name: 'Тех поддержка',
		launchDate: '',
	},
]

const selectOptions = [
	{
		id: 1,
		optionName: 'Ирина Никифорова',
		email: 'irina.maibax@gmail.com',
		image: '',
	},
	{
		id: 2,
		optionName: 'Ирина Никитина',
		email: 'Irina@gmail.com',
		image: '',
	},
	{
		id: 3,
		optionName: 'Ирина Костенкова',
		email: 'kostirina@yandex.ru',
		image: '',
	},
]

export const List = () => {
	const onChange = (value: string) => {}

	const onSearch = (value: string) => {}

	const options = selectOptions.map((item) => (
		<Option key={item.id}>
			<div className={styles.Option}>
				<div>{item.optionName}</div>
				<div>{item.email}</div>
			</div>
		</Option>
	))

	const menu = (
		<Menu
			items={[
				{
					label: 'Все',
					key: '0',
				},
				{
					label: 'Непрочитанные',
					key: '1',
				},
			]}
		/>
	)

	return (
		<>
			<div className={styles.List}>
				<div className={styles.Inner}>
					<Select
						showSearch
						placeholder="Введите имя или email пользователя"
						optionFilterProp="children"
						onChange={onChange}
						onSearch={onSearch}
						filterOption={(input, option) =>
							(option!.children as unknown as string)
								.toLowerCase()
								.includes(input.toLowerCase())
						}
					>
						{options}
					</Select>
					<Dropdown overlay={menu} trigger={['click']}>
						<a onClick={(e) => e.preventDefault()}>
							<Space>
								<FilterOutlined className={styles.Filter} />
							</Space>
						</a>
					</Dropdown>
				</div>
				{list.map((item, index) => (
					<React.Fragment key={item.id}>
						<div key={item.id} className={styles.Inner}>
							<div className={styles.List__Box}>
								<Avatar />
								<div>
									<div>{item.name}</div>
									<div className={styles.Date}>
										Запуск{' '}
										{item.launchDate
											? item.launchDate
											: null}
									</div>
								</div>
							</div>
							<div>
								<Badge
									style={{ backgroundColor: '#49A3F5' }}
									count={23}
								/>
							</div>
						</div>
					</React.Fragment>
				))}
			</div>
		</>
	)
}
