import { create } from 'zustand'
import { TakePauseFormStoreType } from './storeTypes'

const useTakePauseFormStore = create<TakePauseFormStoreType.State>((set) => {
	return {
		isOpen: false,
		isSubmitting: false,
		durationSelectValue: null,

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useTakePauseFormStore
