import React, { Dispatch, SetStateAction } from 'react'
import { emptyState, StateType } from './stateType'

export type PaymentStateType = StateType.State
export type SetStateType = Dispatch<SetStateAction<PaymentStateType>>

export const StateContext = React.createContext<{
	state: PaymentStateType
	setState: SetStateType
}>({ state: emptyState, setState: () => {} })
