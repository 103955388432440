import { RcFile } from 'antd/lib/upload/interface'
import { message } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import UploadFileTypes from '../constants/uploadTypes'
import fileRequests from '../requests/files/fileRequest'
import { translite } from 'parts/utils/string'

export type UploadTypes = {
	signedRequest: string
	url: string
}

/**
 * Функция загружающая данные на удалённый сервер
 * @param {Object} file — загружаемый файл
 * @param {Array} FileList — массив загружаемых файлов
 * @param {String} uploadType — тип загружаемых данных: exercise_video или exercise_images
 * @param {Object} options — различные функции запускаемые на разных этапах загрузки файла
 */
async function downloadFileToAmazon(
	file: RcFile | File,
	FileList: RcFile[] | File[],
	uploadType: UploadFileTypes,
	options: {
		// Функция запускаемая до начала загрузки файла
		beforeDownloading?: (fileName: string) => void
		// Функция, запускаемая во время загрузки файла. Получает уровень загруженных данных и функцию останавливающую загрузку.
		whileDownloading?: (
			percentCompleted: number,
			cancelDownloading: () => void,
			fileUrl: string
		) => void
		// Функция, запускаемая после удачной загрузки. Получает имя файла и его адрес.
		onSuccess?: (fileName: string, fileUrl: string) => void
		// Функция, запускаемая после неудачной загрузки. Получает имя файла.
		onError?: (fileName: string) => void
	}
) {
	let fileName = translite(file.name, '_')
	fileName = uuidv4() + '_' + fileName

	// Запустить функцию, запускаемую до начала загрузки
	if (options.beforeDownloading) {
		options.beforeDownloading(fileName)
	}

	try {
		const response = await fileRequests.getFilesUrl({
			uploadType,
			fileName,
			fileType: file.type,
		})

		const req = response.data as unknown as UploadTypes

		// Токен отмены загрузки файла
		let cancelSource = axios.CancelToken.source()

		await axios
			.put(req.signedRequest, file, {
				headers: {
					'Content-Type': file.type,
					'Cache-Control': 'public,max-age=31536000,immutable',
					'x-amz-acl': 'public-read',
				},
				cancelToken: cancelSource.token,
				onUploadProgress: (progressEvent) => {
					// Сколько процентов загружено
					const percentCompleted = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total
					)

					function cancelDownloading() {
						cancelSource.cancel('Operation canceled by the user')
					}

					// Запустить функцию получающую количество загруженных данных в процентах
					if (options.whileDownloading) {
						options.whileDownloading(
							percentCompleted,
							cancelDownloading,
							req.url
						)
					}
				},
			})
			.then((response) => {
				message.success(`${fileName} файл успешно загружен`)

				if (options.onSuccess) {
					options.onSuccess(fileName, req.url)
				}
			})
			.catch((error) => {
				throw new Error(JSON.stringify(error))
			})

		return false
	} catch (err) {
		message.error('Ошибка при загрузке файла.')

		if (options.onError) {
			options.onError(fileName)
		}

		throw new Error(JSON.stringify(err))
	}
}

export default downloadFileToAmazon
