import AddEmployeeModal from './AddEmployeeModal/AddEmployeeModal'
import useAddEmployeeModalStore from './AddEmployeeModal/zustand/store'

export const AddEmployeeModalController = {
	element: <AddEmployeeModal />,
	open() {
		const updateStore = useAddEmployeeModalStore.getState().updateStore
		updateStore({ isOpen: true })
	},
	close() {
		const updateStore = useAddEmployeeModalStore.getState().updateStore
		updateStore({ isOpen: false })
	},
}
