import React from 'react'
import { useScrollManager } from '../scrollManager/scrollManager'

// Компонент содержащий хук добавляющий обработчик прокрутки на корневой компонент.
// При прокрутке в LocalStorage сохраняются данные о прокрутках всех страниц
// чтобы при загрузке страницы содержимое автоматически прокрутилось до последнего значения
// По непонятным причинами если хук useScrollManager запустить в компоненте App, то при прокрутке компоненты начинают перерисовываться.
// Чтобы предотвратить это поведение, вынес useScrollManager в отдельный компонент.
function ScrollManagerElement() {
	useScrollManager()

	return <></>
}

export default ScrollManagerElement
