// import useLandingConstructorStore from '../../../zustand/store'
import { useGetAddNewBullet } from 'pages/landing/landingConstructor/sections/programSection/bulletsSections/bulletsBlockManager/addNewBlock'
import { LandingTypes } from 'parts/types/LandingTypes'
import { useGetDeleteBullet } from 'pages/landing/landingConstructor/sections/programSection/bulletsSections/bulletsBlockManager/deleteBullet'
// import { LandingTypes } from '../../../zustand/landingTypes'

// Объект с методами манипулирования блоков с описанием пользователя, которому подойдёт предлагаемый курс
const bulletsBlockManager = {
	/** Получение всех блоков */
	/*useGetBlocks() {
		const landing = useLandingConstructorStore((store) => store.landing)
		return landing.sections.program.blocks
	},*/
	/**
	 * Получение блоков указанной колонки
	 * @param columnNum — номер колонки, у которой нужно получить блоки
	 */
	/*useGetColumnBlocks(columnNum: 1 | 2) {
		return this.useGetBlocks().filter((block) => {
			return block.columnNum == columnNum
		})
	},*/

	// Добавление нового блока
	useGetAddNewBullet,

	// Удаление существующего блока
	useGetDeleteBullet,

	/**
	 * Возвращает индекс по которому находится блок с переданным идентификатором
	 * @param bullets — массив пунктов
	 * @param bulletId — id пункта, у которого нужно получить индекс.
	 */
	getBulletIdx(
		bullets: LandingTypes.ProgramBullet[],
		bulletId: null | number
	): number {
		if (!bulletId) return 0

		return bullets.findIndex((bullet) => bullet.id === bulletId)
	},
}

export default bulletsBlockManager
