import { message, UploadFile } from 'antd'
import { AxiosResponse } from 'axios'
import { QueryClient, useQueryClient } from 'react-query'
import { lessonsQuery } from 'parts/requests/lessons/lessonsQuery'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { getFirstLoadedFileUrl } from 'ui/upload/UploadFileInForm/fn/fn'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import useLessonPageHeaderAdminStore from '../../zustand/store'

export function useGetSaveUploadedFiles() {
	const queryClient = useQueryClient()

	const lessonAdmin = useLessonPageHeaderAdminStore(
		(state) => state.lessonAdmin
	)

	const updateLessonMutation = lessonsQuery.updateLesson.useMutation({
		onSuccess: (res) => mutateOnSuccess(queryClient, res),
		onError: mutateOnError,
	})

	return function (files: UploadFile[]) {
		const lessonCopy: TrainingEntityTypes.LessonAdmin = {
			...lessonAdmin,
			cover: getFirstLoadedFileUrl(files) || '',
		}

		updateLessonMutation.mutate(lessonCopy)
	}
}

/** Функция, выполняемая при успешном обновлении урока */
function mutateOnSuccess(
	queryClient: QueryClient,
	updatedLessonRes: AxiosResponse<TrainingEntityTypes.LessonAdmin>
) {
	// Пометить урок неактуальным. После этого React Query скачает обновлённые данные.
	queryClient.refetchQueries({
		queryKey: [lessonsQuery.getLesson(updatedLessonRes.data.id)],
	})

	// Пометить курс неактуальным. После этого React Query скачает обновлённые данные.
	queryClient.refetchQueries({
		queryKey: [
			trainingQuery.getTrainingForAdmin(updatedLessonRes.data.trainingId!)
				.key,
		],
	})
}

/** Функция, выполняемая при появлении ошибки после запроса на добавление сотрудника в группы */
function mutateOnError(err: unknown) {
	message.error('При сохранении урока произошла ошибка')
}
