import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import AuthPage from '../../../common/AuthPage/AuthPage'
import ContentWrapper from '../../../common/ContentWrapper/ContentWrapper'
import AppUrls from 'parts/constants/pageUrl'
import LoginForm from '../LoginForm/LoginForm'

// Страница с формой входа
function LoginPage() {
	const { t } = useTranslation()

	return (
		<AuthPage>
			<ContentWrapper
				header={t('auth.loginPageHeader')}
				bottomText={
					<>
						Ещё нет учётной записи?
						<Link to={AppUrls.Register_Owner_Start().url}>
							Зарегистрируйтесь
						</Link>{' '}
					</>
				}
			>
				<LoginForm />
			</ContentWrapper>
		</AuthPage>
	)
}

export default LoginPage
