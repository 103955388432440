import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'

export enum FieldNames {
	VideoUrl = 'videoUrl',
}

export type FormValuesType = {
	[FieldNames.VideoUrl]: string
}

/** Возвращает первоначальные значения полей формы редактирования ученика. */
export function useGetInitialValues(): FormValuesType {
	const landingStore = useLandingConstructorStore((state) => state)

	return {
		[FieldNames.VideoUrl]:
			landingStore.landing!.sections.video.youtubeVideoUrl,
	}
}
