import { produce } from 'immer'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import useExercisesListAdminStore from '../../../zustand/store'
import { getFormState, setFormState } from '../../common/exerciseFormCommonFunc'
import { DropWordsExerciseFormStateType } from './stateType'
import { textHasValidBracesPairs } from '../../GapsInTextForm/fn/check'

/**
 * Обработчик отправки формы
 * @param {Function} saveExercise — функция сохраняющая упражнение на сервере и в Состоянии
 */
export function submitHandler(
	saveExercise: (args: TrainingEntityTypes.ExerciseItem) => void
) {
	const formState = getFormState<DropWordsExerciseFormStateType>()

	const isFormValid = validateForm()
	if (!isFormValid) return

	const exerciseData = convertFormStateDataToExerciseData(formState)
	saveExercise(exerciseData)
}

/**
 * Функция проверяет правильность заполнения формы, изменяет Состояние для показа ошибок и возвращает булево значение является ли форма правильной
 */
function validateForm() {
	let isFormValid = true

	const formState = getFormState<DropWordsExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		draft.texts.forEach((textBlock, i) => {
			// Проверка на отсутствие текста в текстовом блоке
			if (!textBlock.text) {
				draft.texts[i].noTextError = true
				draft.isFormInvalid = true

				isFormValid = false
			}

			// Проверка на то, что идёт правильный порядок открывающих и закрывающих фигурных скобок.
			if (textBlock.text && !textHasValidBracesPairs(textBlock.text)) {
				draft.texts[i].wrongBracesPairError = true
				draft.isFormInvalid = true

				isFormValid = false
			}
		})
	})

	setFormState(newState)

	return isFormValid
}

/**
 * Функция переводит данные формы в данные упражнения
 * @param {Object} formState — объект состояния формы
 */
function convertFormStateDataToExerciseData(
	formState: DropWordsExerciseFormStateType
) {
	const lessonId = useExercisesListAdminStore.getState().lessonId

	const exerciseData: TrainingEntityTypes.DropWordExercise = {
		type: 'dropWords',
		lessonId,
		order: formState.order,
		item: {
			task: formState.taskInput.value,
			texts: formState.texts.map((textObj) => {
				return {
					word: textObj.text,
					generatedTextPrompt: textObj.generatedTextPrompt,
					wrongWords: textObj.wrongAnswers,
				}
			}),
		},
		status: 0, // Чтобы TS не ругался
		statusUpdated: '', // Чтобы TS не ругался
	}

	if (formState.exerciseId) {
		exerciseData.id = formState.exerciseId
	}

	return exerciseData
}
