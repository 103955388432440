import { create } from 'zustand'
import { LandingConstructorStoreType } from 'pages/landing/landingConstructor/zustand/storeTypes'

const useLandingConstructorStore = create<LandingConstructorStoreType.State>(
	(set) => {
		return {
			dataLoadingStatus: 'loading',
			training: null as any,
			landing: null as any,
			saveDataStatus: 'saved',

			updateStore(obj) {
				return set((state) => {
					return obj
				})
			},
		}
	}
)

export default useLandingConstructorStore
