import React, { useRef } from 'react'
import {
	ExerciseChangesDataMadeByUser,
	SaveExerciseChangesMadeByUser,
} from '../../common/useLiftViewDuration'
import useExerciseStore, { ExerciseStateType } from '../zustand/exerciseState'
import {
	useGetDropWordHandler,
	useRestoreExercise,
	useSaveStateOnServerForRestore,
} from './fn/saveAndRestoreExercise'
import Words from './Words'
import TextBlock from './TextBlock'
import {
	onDragEnd,
	onDragEnter,
	onDragLeave,
	onDragOver,
	onDragStart,
	useGetOnDrop,
} from './fn/drag'
import { TaskExerciseView } from '../../common/TaskExerciseView/TaskExerciseView'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import ExerciseDropAdvert from '../../common/DropAdvert/ExerciseDropAdvert'

type ExerciseProps = {
	exercise: TrainingEntityTypes.DropWordExercise
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

// Компонент группы упражнений
function Exercise(props: ExerciseProps) {
	const { exercise, saveExerciseChangesMadeByUser, exerciseRestoreData } =
		props

	const textBlocks = useExerciseStore((store) => store.textBlocks)

	const textsRef = useRef<null | HTMLDivElement>(null)

	const dropWordHandler = useGetDropWordHandler(
		textsRef.current,
		saveExerciseChangesMadeByUser
	)
	useSaveStateOnServerForRestore(
		textsRef.current,
		saveExerciseChangesMadeByUser
	)

	return (
		<>
			{exercise.item.task && (
				<TaskExerciseView htmlString={exercise.item.task} />
			)}
			<ExerciseDropAdvert />
			<div
				className="exercise-drop-word-content__text-blocks"
				ref={textsRef}
				onDrop={dropWordHandler}
			>
				{textBlocks.map(
					(exerciseBlock: ExerciseStateType.TextBlock) => {
						return (
							<TextBlockWrapper
								exerciseBlock={exerciseBlock}
								key={exerciseBlock.id}
							/>
						)
					}
				)}
			</div>
		</>
	)
}

export default Exercise

type TextBlockWrapperProps = {
	exerciseBlock: ExerciseStateType.TextBlock
}

// Блок со словами для перетаскивания и текстом с пропусками
function TextBlockWrapper(props: TextBlockWrapperProps) {
	const { exerciseBlock } = props

	const updateStore = useExerciseStore((store) => store.updateStore)

	return (
		<div
			className="exercise-drop-word-content__exercise-wrapper"
			onDragStart={onDragStart}
			onDragEnter={onDragEnter}
			onDragLeave={onDragLeave}
			onDrop={(e) => useGetOnDrop(e, updateStore)}
			onDragOver={onDragOver}
			onDragEnd={onDragEnd}
		>
			<Words words={exerciseBlock.words} />
			<TextBlock htmlString={exerciseBlock.htmlString} />
		</div>
	)
}
