import useStudentsStore from '../../../../zustand/store'
import { StudentsStoreType } from '../../../../zustand/storeTypes'

export enum FieldsNames {
	// Поле со строкой поиска
	Phone = 'phone',
}

export interface FormValues {
	[FieldsNames.Phone]: string
}

/** Обработчик отправки формы поиска по почте */
export function useGetOnPhoneChange() {
	const setSearchValuesProp = useStudentsStore(
		(store) => store.setSearchValuesProp
	)

	return function (values: FormValues) {
		setSearchValuesProp(
			StudentsStoreType.SearchValueNames.Phone,
			values[FieldsNames.Phone]
		)
	}
}
