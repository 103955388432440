import { useTranslation } from 'react-i18next'
import ModalWithForm from 'ui/ModalWithForm/ModalWithForm'
import EditLessonForm from '../EditLessonForm/EditLessonForm'
import { EditLessonModalController } from '../EditLessonModalController'
import useEditLessonModalStore from '../zustand/store'

// Модальное окно добавления нового урока
function EditLessonModal() {
	const { t } = useTranslation()
	const modalState = useEditLessonModalStore((state) => state)

	return (
		<ModalWithForm
			title={t('adminTraining.editLessonModalTitle')}
			open={modalState.isModalOpen}
			onCancel={EditLessonModalController.close}
		>
			<EditLessonForm />
		</ModalWithForm>
	)
}

export default EditLessonModal
