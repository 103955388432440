import { create } from 'zustand'
import { ColorSchemeModalStoreType } from 'pages/landing/landingConstructor/details/ColorSchemeModal/zustand/storeTypes'

const useColorSchemeModalStore = create<ColorSchemeModalStoreType.State>(
	(set) => {
		return {
			isOpen: false,

			/**
			 * Установщик новых значений корневого объекта.
			 * @param obj — объект со свойствами и значениями, которые должны быть изменены в Сотоянии
			 */
			updateStore(obj) {
				return set((state) => {
					return obj
				})
			},
		}
	}
)

export default useColorSchemeModalStore
