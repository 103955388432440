import { useEffect } from 'react'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { EXERCISE_ATTEMPTS, EXERCISE_DONE } from '../../../common/exerciseConst'
import { getGapWords } from '../../../common/getGapWords'
import useExerciseStore, { ExerciseStateType } from '../exerciseState'

export function useFillStore(exercise: TrainingEntityTypes.DropWordExercise) {
	const updateStore = useExerciseStore((store) => store.updateStore)

	useEffect(
		function () {
			const newTextBlocks = exercise.item.texts.map((textObj, i) => {
				return {
					id: i + 1,
					htmlString: textObj.word,
					words: getGapsWords(textObj),
				}
			})

			const doneCounter =
				exercise.status ===
				TrainingEntityTypes.ExerciseProgressStatus.SUCCESS
					? EXERCISE_DONE
					: 0

			updateStore({
				attempts: EXERCISE_ATTEMPTS,
				textBlocks: newTextBlocks,
				doneCounter,
			})
		},
		[exercise.id]
	)
}

/**
 * Очищает текстовые блоки от введённых слов через задание нового идентификатора.
 * Тогда Реакт полностью перерисует текстовые блоки.
 */
export function useGetClearTextBlocks() {
	const textBlocks = useExerciseStore((store) => store.textBlocks)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		const newTextBlocks = textBlocks.map((textBlock) => {
			return { ...textBlock, id: textBlock.id + 1000 }
		})

		updateStore({ textBlocks: newTextBlocks })
	}
}

/**
 * Функция формирует и возвращает массив слов, которые нужно перетаскивать в пропуски
 * @param {Object} textObj — данные текстового блока
 */
function getGapsWords(
	textObj: TrainingEntityTypes.DropWordText
): ExerciseStateType.Word[] {
	const gapStrings: string[] = getGapWords(textObj.word)

	const gapWords = gapStrings.map((gapString, i) => {
		return {
			id: i + 1,
			word: gapString,
			inDock: true,
		}
	})

	const lastId = gapWords.length + 1

	textObj.wrongWords.forEach((wrongWord, i) => {
		gapWords.push({
			id: lastId + i,
			word: wrongWord,
			inDock: true,
		})
	})

	// Перемешать слова чтобы не было возможно их угадать
	gapWords.sort().sort(() => {
		return Math.random() - 0.5 < 0 ? 1 : -1
	})

	return gapWords
}
