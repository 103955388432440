import { Navigate, Route } from 'react-router-dom'
import AppUrls from 'parts/constants/pageUrl'
import EntityTypes from 'parts/types/EntityTypes'
import { getSuccessAndFailRoutes } from './special/otherRoutes'
import ErrorPage from 'pages/duty/ErrorPage/ErrorPage'
import ProtectedRoute from '../../ui/ProtectedRoute/ProtectedRoute'
import AppLayout from '../AppLayout/AppLayout'
import Dashboard from 'pages/dashboard/Dashboard'

export default function getSuperAdminRouter(user: null | EntityTypes.User) {
	return (
		<Route>
			{getSuccessAndFailRoutes()}
			{getAppRoutes(user)}
		</Route>
	)
}

function getAppRoutes(user: null | EntityTypes.User) {
	return (
		<Route
			element={
				<ProtectedRoute
					isAllowed={!!user}
					redirectPath={AppUrls.Login().url}
				>
					<AppLayout />
				</ProtectedRoute>
			}
		>
			<Route
				path={AppUrls.SuperAdminDashboard().url}
				element={<Dashboard />}
			/>
			<Route
				path={AppUrls.Login().url}
				element={<Navigate to={AppUrls.Main().url} />}
			/>
			<Route
				path="*"
				element={
					<ErrorPage
						errorCode={404}
						redirectUrl={AppUrls.SuperAdminDashboard().url}
					/>
				}
			/>
		</Route>
	)
}
