import React, { useRef } from 'react'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import {
	ExerciseChangesDataMadeByUser,
	SaveExerciseChangesMadeByUser,
} from '../../common/useLiftViewDuration'
import useExerciseStore from '../zustand/exerciseState'
import {
	useGetDropWordHandler,
	useSaveStateOnServerForRestore,
} from './fn/saveAndRestoreExercise'
import { Words } from './Words'
import { ImagesWithSlots } from './ImagesWithSlots'
import { TaskExerciseView } from '../../common/TaskExerciseView/TaskExerciseView'
import {
	onDragEnd,
	onDragEnter,
	onDragLeave,
	onDragOver,
	onDragStart,
	onDrop,
} from './fn/drag'
import ExerciseDropAdvert from '../../common/DropAdvert/ExerciseDropAdvert'

type DescriptionImageExerciseViewProps = {
	exercise: TrainingEntityTypes.DescriptionImgExercise
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

// Вывод упражнения с картинкой в уроке
function Exercise(props: DescriptionImageExerciseViewProps) {
	const { exercise, saveExerciseChangesMadeByUser, exerciseRestoreData } =
		props

	const updateStore = useExerciseStore((store) => store.updateStore)
	const textsRef = useRef<null | HTMLDivElement>(null)

	// Занимается сохранением состояния упражнения для восстановления при открытии
	const dropWordHandler = useGetDropWordHandler(
		textsRef.current,
		saveExerciseChangesMadeByUser
	)
	useSaveStateOnServerForRestore(
		textsRef.current,
		saveExerciseChangesMadeByUser
	)

	return (
		<>
			{exercise.item.task && (
				<TaskExerciseView htmlString={exercise.item.task} />
			)}
			<ExerciseDropAdvert />
			<section
				className="exercise-desc-image-content__exercise-wrapper"
				ref={textsRef}
				onDragStart={onDragStart}
				onDragEnter={onDragEnter}
				onDragLeave={onDragLeave}
				onDrop={(e) => {
					onDrop(e, updateStore)
					dropWordHandler()
				}}
				onDragOver={onDragOver}
				onDragEnd={onDragEnd}
			>
				<Words />
				<ImagesWithSlots exercise={exercise} />
			</section>
		</>
	)
}

export default Exercise
