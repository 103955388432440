import React from 'react'
import HistoryModal from '../common/HistoryModal/HistoryModal'
import { Button, Form, FormInstance, Select } from 'antd'
import { useGetCancelSubscription } from './fn/cancelSubscription'
import { useGetToggleVisibilityTakePauseModal } from './fn/openCloseTakePauseModal'
import { useGetOnSubmit } from './fn/submit'
import useTakePauseFormStore from './zustand/store'
import s from './TakePauseModal.module.scss'

function TakePauseModal() {
	const [form] = Form.useForm()

	const isOpen = useTakePauseFormStore((store) => store.isOpen)
	const closeModal = useGetToggleVisibilityTakePauseModal()

	return (
		<HistoryModal
			iconSrc="/images/subscriptionsHistory/PauseCircleOutlined.svg"
			title="Возьмите паузу!"
			open={isOpen}
			footerButtons={[
				<CancelSubscriptionButton form={form} key={1} />,
				<PauseButton form={form} key={2} />,
			]}
			onCancel={closeModal}
		>
			<TakePauseForm form={form} />
		</HistoryModal>
	)
}

export default TakePauseModal

type ReasonFormProps = {
	form: FormInstance
}

function TakePauseForm(props: ReasonFormProps) {
	const { form } = props

	const updateStore = useTakePauseFormStore((store) => store.updateStore)

	return (
		<div>
			<p className={s.topText}>
				Мы понимаем: бывают случаи, когда вам нужен план Pro со всеми
				расширенными функциями совместной работы, а иногда подойдет и
				базовый план. У вас есть выбор.
			</p>
			<div className={s.blueBlock}>
				<p className={s.blueBlockText}>
					Приостановите свой план на 1, 2 или 3 месяца. Пауза начнется
					в следующую дату выставления счета, и во время паузы с вас
					не будет взиматься плата.
				</p>
				<div className={s.selectBlock}>
					<Select
						style={{ width: 250 }}
						options={[
							{ value: '1', label: '1 месяц' },
							{ value: '2', label: '2 месяца' },
							{ value: '3', label: '3 месяца' },
						]}
						onChange={(val) => {
							updateStore({ durationSelectValue: val })
						}}
					/>
				</div>
			</div>
		</div>
	)
}

type SaveSubscriptionButtonProps = {
	form: FormInstance
}

function CancelSubscriptionButton(props: SaveSubscriptionButtonProps) {
	const { form } = props

	const isSubmitting = useTakePauseFormStore((store) => store.isSubmitting)

	const cancelSubscription = useGetCancelSubscription()

	const formErrors = form
		.getFieldsError()
		.filter(({ errors }) => errors.length)

	return (
		<Button
			disabled={isSubmitting || formErrors.length > 0}
			onClick={cancelSubscription}
			type="primary"
		>
			Отменить подписку
		</Button>
	)
}

type PauseButtonProps = {
	form: FormInstance
}

function PauseButton(props: PauseButtonProps) {
	const { form } = props

	const onSubmit = useGetOnSubmit()
	const isSubmitting = useTakePauseFormStore((store) => store.isSubmitting)

	const formErrors = form
		.getFieldsError()
		.filter(({ errors }) => errors.length)

	const durationSelectValue = useTakePauseFormStore(
		(store) => store.durationSelectValue
	)

	return (
		<Button
			htmlType="submit"
			disabled={
				isSubmitting || formErrors.length > 0 || !durationSelectValue
			}
			loading={isSubmitting}
			onClick={onSubmit}
		>
			Поставить на паузу
		</Button>
	)
}
