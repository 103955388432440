import useLessonsPresetsStore from 'widgets/presets/zustand/store'

export const useDeletePreset = () => {
	const updateStore = useLessonsPresetsStore((state) => state.updateStore)

	return (presetId: number) => () => {
		updateStore({
			selectedPreset: presetId,
			isOpenDeletePresetConfirmation: true,
		})
	}
}
