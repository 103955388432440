import { AxiosResponse } from 'axios'
import AuthApiTypes from 'parts/requests/auth/authApiTypes'
import { authQuery } from 'parts/requests/auth/authQuery'
import useLoginFormStore from '../zustand/store'
import { AxiosError } from 'axios'
import ServerTypes from 'parts/types/ServerTypes'
import { LoginFormStoreType } from '../zustand/storeTypes'
import { FormValuesType } from './form'
import { useNavigate } from 'react-router-dom'
import AppUrls from 'parts/constants/pageUrl'
import { SystemStoreType } from 'parts/systemStore/systemStoreTypes'
import useSystemStore from 'parts/systemStore/systemStore'

/** Хук возвращающий обработчик отправки формы */
export function useGetOnSubmit() {
	const updateFormStore = useLoginFormStore((store) => store.updateStore)
	const updateSystemStore = useSystemStore((store) => store.updateStore)

	// Запрос на вход пользователя
	const loginAdminQuery = authQuery.login.useMutation({
		onMutate: getLoginBeforeMutate(updateFormStore),
		onError: getMutateOnError(updateFormStore),
		onSuccess: getLoginSuccessMutate(updateFormStore, updateSystemStore),
	})

	return async (values: FormValuesType) => {
		loginAdminQuery.mutate({
			email: values.email.toLowerCase(),
			password: values.password,
		})
	}
}

/**
 * Функция, выполняемая до запроса на вход пользователя
 * @param updateFormStore — функция, изменяющая объект состояния формы входа
 */
function getLoginBeforeMutate(updateFormStore: LoginFormStoreType.UpdateStore) {
	return function () {
		// Поставить статус загрузки
		updateFormStore({ isSubmitting: true })
	}
}

/**
 * Функция, выполняемая при успешном запросе на вход пользователя.
 * @param updateFormStore — функция, изменяющая объект состояния формы входа
 * @param generateTokenQuery — запрос на генерирование токена для автоматической авторизации
 */
function getLoginSuccessMutate(
	updateFormStore: LoginFormStoreType.UpdateStore,
	updateSystemStore: SystemStoreType.UpdateStore
) {
	const navigate = useNavigate()

	return function (loginResp: AxiosResponse<AuthApiTypes.Login>) {
		localStorage.setItem('token', loginResp.data.accessToken)

		// После запроса на генерирование токена данные пользователя попадут в системное хранилище
		updateFormStore({ user: loginResp.data.user, isSubmitting: false })
		updateSystemStore({ user: loginResp.data.user })

		navigate(AppUrls.Main().url)
	}
}

/**
 * Универсальная функция, выполняемая при появлении ошибки после запроса
 * @param updateFormStore — функция, изменяющая объект состояния формы входа пользователя
 */
function getMutateOnError(updateFormStore: LoginFormStoreType.UpdateStore) {
	return function (err: unknown) {
		const error = err as AxiosError<ServerTypes.ErrorResponse>

		if (error.response) {
			// Поставить данные ошибки в Состояние, чтобы показать их в форме
			updateFormStore({ formErrors: error.response.data })
		}

		updateFormStore({ isSubmitting: false })
	}
}
