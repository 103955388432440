import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import useMenuStore from 'app/menu/zustand/menuStore'
import { useGetOpenMenuToggle } from './fn/MobileMenuButton-func'
import './MobileMenuButton.scss'

// Кнопка открывающая/закрывающая главное меню для телефона
function MobileMenuButton() {
	const isMobileMenuOpen = useMenuStore((store) => store.isMobileMenuOpen)

	const openMenuToggle = useGetOpenMenuToggle()

	const cls = 'mobile-menu-button'

	return isMobileMenuOpen ? (
		<MenuFoldOutlined className={cls} onClick={openMenuToggle} />
	) : (
		<MenuUnfoldOutlined className={cls} onClick={openMenuToggle} />
	)
}

export default MobileMenuButton
