import React, { ReactElement } from 'react'
import './VerticalList.scss'

type VerticalListItem = {
	text: string
	icon: ReactElement
}

type VerticalListProps = {
	items: VerticalListItem[]
}

function VerticalList(props: VerticalListProps) {
	const { items } = props

	return (
		<ul className="vertical-list">
			{items.map((item, i) => {
				return (
					<li key={i} className="vertical-list__item">
						<span className="vertical-list__icon-wrapper">
							{item.icon}
						</span>
						{item.text}
					</li>
				)
			})}
		</ul>
	)
}

export default VerticalList
