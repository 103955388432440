import React from 'react'
import dayjs from 'dayjs'
import { Table, message } from 'antd'
import { liveLessonQuery } from 'parts/requests/liveLesson/liveLessonQuery'
import { Loader } from 'ui'
import useLiveLibraryStore from 'widgets/livelesson/liveLesson/components/LiveLibrary/zustand/store'
import useLibraryColumns from 'widgets/livelesson/liveLesson/components/LiveLibrary/fn/useLibraryColumns'
import useLiveLibraryButtons from 'widgets/livelesson/liveLesson/components/LiveLibrary/fn/useLiveLibraryButtons'
import useStorageItemClick from 'widgets/livelesson/liveLesson/components/LiveLibrary/fn/useStorageItemClick'
import useLivelessonStore from 'widgets/livelesson/liveLesson/zustand/store'
import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'
import { LiveLibraryStoreType } from 'widgets/livelesson/liveLesson/components/LiveLibrary/zustand/storeTypes'
import sortStorageItems from 'widgets/livelesson/liveLesson/components/LiveLibrary/fn/sortStorageItems'
import { humanFileType } from 'widgets/livelesson/liveLesson/fn/liveUtils'
import FolderName from 'widgets/livelesson/liveLesson/components/LiveLibrary/components/FolderName/FolderName'
import MobileFolderName from 'widgets/livelesson/liveLesson/components/LiveLibrary/components/MobileFolderName/MobileFolderName'
import useFolderDialog from 'widgets/livelesson/liveLesson/components/LiveLibrary/fn/useFolderDialog'
import useUploadFile from 'widgets/livelesson/liveLesson/components/LiveLibrary/fn/useUploadFile'
import useUploadProgress from 'widgets/livelesson/liveLesson/components/LiveLibrary/fn/useUploadProgress'
import StorageItem from 'widgets/livelesson/liveLesson/components/LiveLibrary/components/StorageItem/StorageItem'

import styles from './FilesLibrary.module.scss'

const FilesLibrary = () => {
	const { status, data: storageItems } =
		liveLessonQuery.getStorageItems.useQuery()
	const [messageApi, contextHolder] = message.useMessage()
	const selectedFolder = useLiveLibraryStore((store) => store.selectedFolder)
	const setSelectedFolder = useLiveLibraryStore(
		(store) => store.setSelectedFolder
	)
	const selectedItems = useLiveLibraryStore((store) => store.selectedItems)
	const setSelectedItems = useLiveLibraryStore(
		(store) => store.setSelectedItems
	)
	const { actionButtonMap, mobileActionButtonMap, actionButtons } =
		useLiveLibraryButtons(messageApi)
	const isMobile = useLivelessonStore((store) => store.isMobile)
	const { onStorageItemClick } = useStorageItemClick()
	const { columns } = useLibraryColumns(false)

	const handleBack = () => {
		const prevFolder = storageItems?.data.find(
			(i) => i.id === selectedFolder?.parentId
		)
		if (prevFolder) {
			setSelectedFolder(prevFolder)
		} else {
			setSelectedFolder(null)
		}
	}

	const rowSelection = {
		onChange: (
			selectedRowKeys: React.Key[],
			selectedRows: LiveLessonEntityTypes.StorageItem[]
		) => {
			setSelectedItems(selectedRows)
		},
		getCheckboxProps: (record: LiveLessonEntityTypes.StorageItem) => ({
			disabled: record.name === 'Disabled User', // Column configuration not to be checked
			name: record.name,
		}),
	}

	const handleOnCheckItem = (
		item: LiveLessonEntityTypes.StorageItem,
		state: boolean
	) => {
		if (state) {
			setSelectedItems([...selectedItems, item])
		} else {
			setSelectedItems(selectedItems.filter((i) => i.id !== item.id))
		}
	}

	const getStorageItems = (
		data: LiveLessonEntityTypes.StorageItem[],
		selectedFolder: LiveLibraryStoreType.LibraryFolder | null
	): LiveLessonEntityTypes.StorageItem[] => {
		const storageItems = data.filter(
			(item) =>
				item.parentId === (selectedFolder ? selectedFolder.id : null)
		)

		return storageItems.sort(sortStorageItems).map((item) => ({
			key: item.id,
			id: item.id,
			parentId: item.parentId,
			s3link: item.s3link,
			name: item.name,
			fileSize: item.fileSize,
			fileType: humanFileType(item.fileType),
			uploadDate: dayjs(item.uploadDate).format('YYYY-MM-DD hh:mm:ss'),
			isFolder: item.isFolder,
		}))
	}

	return (
		<div className={styles.LiveStorage}>
			<div className={styles.StorageHeader}>
				<div className={styles.StorageHeader__FolderName}>
					{isMobile ? (
						<MobileFolderName
							selectedItems={selectedItems}
							selectedFolder={selectedFolder}
							setSelectedItems={setSelectedItems}
							onBack={handleBack}
						/>
					) : (
						<FolderName
							setSelectedFolder={setSelectedFolder}
							selectedFolder={selectedFolder}
							storageItems={storageItems?.data}
						/>
					)}
				</div>
				<div className={styles.StorageHeader__Actions}>
					{actionButtons.map((btn) => (
						<React.Fragment key={btn}>
							{actionButtonMap[btn]}
						</React.Fragment>
					))}
				</div>
				<div className={styles.StorageHeader__MobileActions}>
					{actionButtons.map((btn) => (
						<React.Fragment key={btn}>
							{mobileActionButtonMap[btn]}
						</React.Fragment>
					))}
				</div>
				{useFolderDialog()}
			</div>
			{contextHolder}
			<div className={styles.LiveStorage__StorageBody}>
				{status === 'loading' && <Loader />}
				<Table
					columns={columns}
					dataSource={getStorageItems(
						storageItems?.data || [],
						selectedFolder
					)}
					rowSelection={{ type: 'checkbox', ...rowSelection }}
					pagination={false}
				/>
			</div>
			<div className={styles.LiveStorage__StorageBodyMobile}>
				{getStorageItems(storageItems?.data || [], selectedFolder).map(
					(item) => (
						<StorageItem
							key={item.id}
							item={item}
							checked={
								!!selectedItems.find((i) => i.id === item.id)
							}
							onClick={onStorageItemClick}
							onChecked={handleOnCheckItem}
						/>
					)
				)}
			</div>
			{useUploadFile(messageApi)}
			{useUploadProgress()}
		</div>
	)
}

export default FilesLibrary
