import { ReactElement } from 'react'
import { UCardBlock } from '../wrappers/UCardBlock/UCardBlock'
import './RoundIconAndTextCard.scss'

type CardProps = {
	icon: ReactElement
	header: string
	num: string | number
}

// Карточка со значком в синем круге и текстом справа
function RoundIconAndTextCard(props: CardProps) {
	const { icon, header, num } = props

	return (
		<UCardBlock
			shadow
			extraClass="round-icon-and-text-card"
			padding="small"
		>
			<div className="round-icon-and-text-card__icon-wrapper">{icon}</div>
			<div className="round-icon-and-text-card__content">
				<p className="round-icon-and-text-card__header">{header}</p>
				<p className="round-icon-and-text-card__num">{num}</p>
			</div>
		</UCardBlock>
	)
}

export default RoundIconAndTextCard
