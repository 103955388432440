import UserApiTypes from 'parts/requests/user/userApiTypes'
import EntityTypes from 'parts/types/EntityTypes'
import { getUserName } from 'parts/utils/string'
import { GroupStaffStoreType } from './storeTypes'

// Класс с методами манипулирования данными кураторов и менеджеров в Зустанде
class StaffManager {
	/**
	 * Имеет ли школа сотрудников
	 * @param allCurators
	 * @param allManagers
	 */
	hasSchoolStaff(
		allCurators: EntityTypes.Curator[],
		allManagers: UserApiTypes.GetUserByRole
	): boolean {
		return !!(allCurators.length || allManagers.length)
	}
	/**
	 * Принимает массив всех кураторов школы и кураторов прикреплённых школе и составляет массив с данными кураторов нужные в компонентах
	 * @param groupCurators — кураторы назначенные группе
	 */
	convertServCuratorsToStateCurators(
		groupCurators: EntityTypes.Curator[]
	): GroupStaffStoreType.Curator[] {
		return groupCurators.map((curator) => {
			return {
				type: 'curator',
				id: curator.id,
				avatar: curator.avatar || null,
				name: getUserName(curator),
				// Количество учеников по плану
				studentCount: curator.studentCount,
				// Фактическое количество учеников
				actualStudentCount: curator.students.length,
			}
		})
	}

	/**
	 * Принимает массив всех кураторов школы и кураторов прикреплённых школе и составляет массив с данными кураторов нужные в компонентах
	 * @param managers — все менеджеры школы
	 * @param groupId — id текущей группы
	 */
	convertServManagersToStateManagers(
		managers: UserApiTypes.UserByRole[],
		groupId: number
	): GroupStaffStoreType.Manager[] {
		const groupManagers = managers.filter((manager) => {
			// Есть ли текущий куратор в группе
			const isInGroup = !!manager.groups.find(
				(group) => group.id == groupId
			)

			return isInGroup
		})

		return groupManagers.map((manager) => {
			return {
				type: 'manager',
				id: manager.id,
				avatar: manager.avatar || null,
				name: getUserName(manager),
				groupsNumber: manager.groups.length,
			}
		})
	}
}

const staffManager = new StaffManager()

export default staffManager
