import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'

/** Возвращает дополнительные цены активного тарифа */
export function useGetAdditionalPrices(
	tariffId: number
): null | TariffsStoreType.TariffPrice[] {
	const tariff = tariffsManager.findTariff(
		tariffsManager.store.tariffs,
		tariffId
	)
	if (!tariff) return null

	return tariff.prices
}
