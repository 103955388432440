import React, { ReactNode } from 'react'
import { Button } from 'antd'
import { PlusOutlined, LinkOutlined, DeleteOutlined } from '@ant-design/icons'
import cn from 'classnames'
import 'pages/landing/landingConstructor/details/ControlsFrame/ControlsFrame.scss'

type HandlerProps = {
	// Если есть обработчики кнопок, то добавятся и сами кнопки
	// Обработчик кнопки добавления сверху
	topAddBtnHandler?: () => void
	// Обработчик кнопки добавления снизу
	bottomAddBtnHandler?: () => void
	// Обработчик кнопки добавления слева
	leftAddBtnHandler?: () => void
	// Обработчик кнопки добавления справа
	rightAddBtnHandler?: () => void
	// Обработчик удаления
	deleteBtnHandler?: () => void
	// Обработчик кнопки ссылки
	linkBtnHandler?: () => void
}

interface ControlsFrameProps extends HandlerProps {
	children: ReactNode
}

function ControlsFrame(props: ControlsFrameProps) {
	const { children, ...handlers } = props

	return (
		<div className="land-const-controls-frame">
			<TopAddBtn {...handlers} />
			<BottomAddBtn {...handlers} />
			<LeftAddBtn {...handlers} />
			<RightAddBtn {...handlers} />
			<LinkBtn {...handlers} />
			<DeleteBtn {...handlers} />
			{children}
		</div>
	)
}

export default ControlsFrame

function TopAddBtn(props: HandlerProps) {
	const { topAddBtnHandler } = props

	if (!topAddBtnHandler) {
		return null
	}

	return (
		<FrameButton
			icon={<PlusOutlined />}
			horizontalPos="center"
			vertivalPos="top"
			handler={topAddBtnHandler}
		/>
	)
}

function BottomAddBtn(props: HandlerProps) {
	const { bottomAddBtnHandler } = props

	if (!bottomAddBtnHandler) {
		return null
	}

	return (
		<FrameButton
			icon={<PlusOutlined />}
			horizontalPos="center"
			vertivalPos="bottom"
			handler={bottomAddBtnHandler}
		/>
	)
}

function LeftAddBtn(props: HandlerProps) {
	const { leftAddBtnHandler } = props

	if (!leftAddBtnHandler) {
		return null
	}

	return (
		<FrameButton
			icon={<PlusOutlined />}
			horizontalPos="left"
			vertivalPos="center"
			handler={leftAddBtnHandler}
		/>
	)
}

function RightAddBtn(props: HandlerProps) {
	const { rightAddBtnHandler } = props

	if (!rightAddBtnHandler) {
		return null
	}

	return (
		<FrameButton
			icon={<PlusOutlined />}
			horizontalPos="right"
			vertivalPos="center"
			handler={rightAddBtnHandler}
		/>
	)
}

function LinkBtn(props: HandlerProps) {
	const { linkBtnHandler } = props

	if (!linkBtnHandler) {
		return null
	}

	return (
		<FrameButton
			icon={<LinkOutlined />}
			horizontalPos="left"
			vertivalPos="top"
			handler={linkBtnHandler}
		/>
	)
}

function DeleteBtn(props: HandlerProps) {
	const { deleteBtnHandler } = props

	if (!deleteBtnHandler) {
		return null
	}

	return (
		<FrameButton
			icon={<DeleteOutlined />}
			horizontalPos="right"
			vertivalPos="top"
			handler={deleteBtnHandler}
		/>
	)
}

type FrameButtonProps = {
	icon: ReactNode
	horizontalPos: 'left' | 'center' | 'right'
	vertivalPos: 'top' | 'center' | 'bottom'
	handler: () => void
}

function FrameButton(props: FrameButtonProps) {
	const { icon, horizontalPos, vertivalPos, handler } = props

	const positionClass =
		'land-const-controls-frame__btn--' + horizontalPos + '-' + vertivalPos

	return (
		<Button
			icon={icon}
			onClick={handler}
			shape="circle"
			size="small"
			className={cn('land-const-controls-frame__btn', positionClass)}
		/>
	)
}
