import React, { useEffect } from 'react'
import AppUrls from 'parts/constants/pageUrl'
import { useGetUserRole, useGoToPage } from 'parts/utils/hooks/hooks'

function MainPage() {
	const userRoles = useGetUserRole()
	const goToTrainingsPage = useGoToPage(AppUrls.Trainings().url)
	const goToExpressCoursesPage = useGoToPage(AppUrls.MyExpressCourses().url)

	useEffect(() => {
		if (userRoles.isAdmin) {
			goToExpressCoursesPage()
		} else {
			goToTrainingsPage()
		}
	}, [])

	return <div>MainPage</div>
}

export default MainPage
