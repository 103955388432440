const authRu = {
	// ОБЁРТКА СТРАНИЦ АВТОРИЗАЦИИ.
	// Сообщение про то, что представляет собой сайт
	slogan: 'Credu — платформа для преподавания онлайн',
	// Кнопка «Назад»
	backButton: 'Назад',

	// СТРАНИЦА ВХОДА
	// Заголовок страницы
	loginPageHeader: 'Вход в Credu',

	// ФОРМА ВХОДА
	// Подпись поля ввода почты
	loginFormFieldEmailLabel: 'Почта',
	// Сообщение про обязательный ввод почты
	loginFormFieldEmailRequired: 'Введите свою почту.',
	// Сообщение про неправильный формат введённой почты в поле ввода почты
	loginFormFieldEmailWrongFormat:
		'Почта в неправильном формате. Например: mail@mail.com',
	// Подпись поля ввода почты
	loginFormFieldPasswordLabel: 'Пароль',
	// Сообщение про обязательный ввод пароля
	loginFormFieldPasswordRequired: 'Напишите пароль',
	// Сообщение про минимальную длину пароля
	loginFormFieldPasswordMin: 'Пароль должен содержать не меньше 6 символов',
	// Сообщение про максимальную длину пароля
	loginFormFieldPasswordMax: 'Пароль должен содержать не более 21 символа',
	// Текст ссылки «Забыли пароль?»
	loginFormFieldForgetPassword: 'Забыли пароль?',
	// Кнопка входа
	loginFormFieldEnterButton: 'Войти',

	// СТРАНИЦА ВОССТАНОВЛЕНИЯ ПАРОЛЯ
	// Заголовок страницы
	recoverPageHeader: 'Восстановление пароля',
	// Текст ниже заголовка страницы
	recoverPageText:
		'Письмо с кодом восстановления пароля придет на вашу почту',
	recoverPageLoginButton: 'Вход',

	// ФОРМА ВОССТАНОВЛЕНИЯ ПАРОЛЯ
	// Подпись поля вводы почты
	recoverFormFieldEmailLabel: 'Почта',
	// Сообщение об обязательном вводе почты
	recoverFormFieldEmailRequired: 'Введите свою почту.',
	// Сообщение об обязательном вводе почты
	recoverFormFieldEmailWrongFormat:
		'Почта в неправильном формате. Например: mail@mail.com',
	// Кнопка отправки письма для восстановления пароля
	recoverFormSendButton: 'Отправить письмо',

	// ИНФОРМАЦИОННОЕ СООБЩЕНИЕ НИЖЕ ФОРМЫ ВВОДА НОВОГО ПАРОЛЯ
	// Текст первого шага: Если вы не видите письмо с кодом в почте, проверьте папку СПАМ, ПРОМОАКЦИИ или РАССЫЛКИ
	stepMessageStepOneInfo:
		'Если вы не видите письмо с кодом в почте, проверьте папку СПАМ, ПРОМОАКЦИИ или РАССЫЛКИ',
	// Текст второго шага: Если вы не видите письмо с кодом в почте, проверьте папку СПАМ, ПРОМОАКЦИИ или РАССЫЛКИ
	stepMessageStepTwoInfo:
		'Если вы не видите письмо с кодом в почте, проверьте папку СПАМ',
	// Текст третьего шага: Если вы по ошибке меняете свой пароль - вернитесь на
	stepMessageStepThreeInfo:
		'Если вы по ошибке меняете свой пароль - вернитесь на',
	// Текст третьего шага: ссылка на главную страницу
	stepMessageStepThreeLink: 'ГЛАВНУЮ СТРАНИЦУ',

	// СТРАНИЦА УСТАНОВКИ НОВОГО ПАРОЛЯ ВЗАМЕН ЗАБЫТОГО
	// Заголовок страницы
	recoverSetPasswordPageHeader: 'Установите пароль',
	// Текст на ссылке входа
	recoverSetPasswordPageLoginBLink: 'Вход',
	// Сообщение после отправки письма: На вашу почту отправлено письмо с 5-значным кодом
	recoverSetPasswordPageLetterWasSent:
		'На вашу почту отправлено письмо с 6-значным кодом',

	// ФОРМА УСТАНОВКИ НОВОГО ПАРОЛЯ ВЗАМЕН ЗАБЫТОГО
	// Подпись поля ввода пароля
	recoverSetPasswordFormFieldPasswordLabel: 'Пароль',
	// Сообщение про обязательный ввод пароля
	recoverSetPasswordFormFieldPasswordRequired: 'Придумайте пароль',
	// Сообщение про минимальную длину пароля
	recoverSetPasswordFormFieldPasswordMin:
		'Пароль должен содержать не меньше 6 символов',
	// Сообщение про максимальную длину пароля
	recoverSetPasswordFormFieldPasswordMax:
		'Пароль должен содержать не более 21 символа',
	// Подпись поля повторного ввода пароля
	recoverSetPasswordFormFieldPasswordAgainLabel: 'Пароль повторно',
	// Сообщение про обязательный повторный ввод пароля
	recoverSetPasswordFormFieldPasswordAgainRequired: 'Напишите пароль ещё раз',
	// Сообщение про несовпадение нового и старого паролей
	recoverSetPasswordFormFieldPasswordAgainMismatch: 'Пароли не совпадают.',
	// Кнопка установки нового пароля
	recoverSetPasswordFormSetButton: 'Установить',

	// СТРАНИЦА ЗАВЕРШЕНИЯ УСТАНОВКИ НОВОГО ПАРОЛЯ ВЗАМЕН ЗАБЫТОГО
	// Заголовок страницы
	finishRecoverPasswordPageHeader: 'Поздравляем! Новый пароль установлен.',
	// Текст на ссылке входа
	finishRecoverPasswordLoginLink: 'Вход',

	// СТРАНИЦА ВЫБОРА ПОЛЬЗОВАТЕЛЯ ДЛЯ РЕГИСТРАЦИИ
	// Заголовок страницы
	registerSwitcherPageHeader: 'Укажите вашу роль',
	// Текст «Уже есть аккаунт?»
	registerSwitcherPageHasAccount: 'Уже есть аккаунт?',
	// Текст на ссылке входа
	registerSwitcherPageLoginLink: 'Войти',
	// Заголовок на кнопке регистрации в качестве владельца школы
	registerSwitcherPageAdminCardHeader: 'Я владелец школы',
	// Текст на кнопке регистрации в качестве владельца школы
	registerSwitcherPageAdminCardText: 'Занимаюсь обучением',

	// СТРАНИЦА НАЧАЛА РЕГИСТРАЦИИ В КАЧЕСТВЕ АДМИНИСТРАТОРА
	// Заголовок страницы
	startAdminRegPageHeader: 'Регистрация',
	// Описание страницы
	startAdminRegPageDescription:
		'Получите доступ ко всем функциям на 14 дней сразу после регистрации',
	// Текст на ссылке входа
	startAdminRegPageLoginLink: 'Войти',
	// Текст после слова «Войти»: «, если есть учётная запись»
	startAdminRegPageIfHasAccount: ', если есть учётная запись',

	// ФОРМА РЕГИСТРАЦИИ АДМИНИСТРАТОРА
	// Подпись поля ввода почты
	startAdminRegFormFieldEmailLabel: 'Почта',
	// Сообщение об обязательности ввода почты
	startAdminRegFormFieldEmailRequired: 'Введите свою почту.',
	// Сообщение о неправильном формате почты
	startAdminRegFormFieldEmailWrongFormat: 'Введите свою почту.',
	// Подпись поля ввода телефона
	startAdminRegFormFieldPhoneLabel: 'Телефон',
	// Сообщение об обязательности ввода почты
	startAdminRegFormFieldPhoneRequired: 'Напишите телефон',
	// Сообщение о неправильном формате почты
	startAdminRegFormFieldPhoneWrongFormat:
		'Телефон в неправильном формате. Пример: ',
	// Подпись поля ввода пароля
	startAdminRegFormFieldPasswordLabel: 'Пароль',
	// Сообщение об обязательности ввода пароля
	startAdminRegFormFieldPasswordRequired: 'Придумайте пароль',
	// Сообщение про минимальную длину пароля
	startAdminRegFormFieldPasswordMin:
		'Пароль должен содержать не меньше 6 символов',
	// Сообщение про максимальную длину пароля
	startAdminRegFormFieldPasswordMax:
		'Пароль должен содержать не более 21 символа',
	// Сообщение про обязательную установку приёма пользовательского соглашения
	startAdminRegFormFieldLegalCheckboxRequired:
		'Вы должны согласиться с условиями перед созданием школы',
	// Кнопка регистрации
	startAdminRegFormSubmitButton: 'Зарегистрироваться',
	// Сообщение «Такая почта уже зарегистрирована» при попытке отправки формы
	startAdminRegFormEmailExistsAlready: 'Такая почта уже зарегистрирована',

	// СТРАНИЦА СОЗДАНИЯ ШКОЛЫ
	// Заголовок страницы
	schoolCreationPageHeader:
		'Придумайте название для вашей школы. Потом можно изменить.',
	// Текст на ссылке входа
	schoolCreationPageLoginLink: 'Войти',

	// ФОРМА СОЗДАНИЯ ШКОЛЫ
	// Подпись поля названия школы
	schoolCreationFormFieldNameLabel: 'Название школы (потом можно изменить)',
	// Сообщение про обязательное заполнение поля с названием школы
	schoolCreationFormFieldNameRequired: 'Напишите название',
	// Подпись поля домена школы
	schoolCreationFormFieldDomainLabel: 'Домен школы',
	// Кнопка создания школы
	schoolCreationFormOkButton: 'Создать школу',

	// СТРАНИЦА ПОДТВЕРЖДЕНИЯ ПОЧТЫ
	// Заголовок страницы
	confirmEmailPageHeader: 'Подтвердите почту',
	confirmEmailPageDescription:
		'На указанную почту было отправлено письмо с пятизначным кодом. Введите его в поле.',
	// Текст на ссылке входа
	confirmEmailPageLoginLink: 'Войти',
	// Текст после слова «Войти»: «, если есть учётная запись»
	confirmEmailPageIfHasAccount: ', если есть учётная запись',

	// ФОРМА ПОДТВЕРЖДЕНИЯ ПОЧТЫ
	// Сообщение про обязательное заполнение поля с кодом
	startAdminRegFormFieldCodeRequired: 'Введите присланный код подтверждения',
	// Сообщение про длину кода
	startAdminRegFormFieldCodeLength: 'Должно быть 5 цифр',
	// Кнопка отправки формы
	startAdminRegFormOkButton: 'Подтвердить',
	// Предупреждение: Если не видите письмо с кодом в почте, проверьте папку «Спам».
	startAdminRegFormWarning:
		'Если не видите письмо с кодом в почте, проверьте папку «Спам».',

	// СТРАНИЦА ПОДТВЕРЖДЕНИЯ ПОЧТЫ ПРИ ВОССТАНОВЛЕНИИ ПАРОЛЯ
	// Заголовок страницы
	recoveryPasswordCheckPageHeader: 'Подтвердите почту',
	// Описание страницы
	recoveryPasswordCheckPageDescription:
		'На указанную почту было отправлено письмо с пятизначным кодом. Введите его в поле.',
	// Текст на ссылке входа
	recoveryPasswordCheckPageLoginLink: 'Войти',
	// Текст после слова «Войти»: «, если есть учётная запись»
	recoveryPasswordCheckPageIfHasAccount: ', если есть учётная запись',

	// ФОРМА ПОДТВЕРЖДЕНИЯ ПОЧТЫ ПРИ ВОССТАНОВЛЕНИИ ПАРОЛЯ
	// Сообщение про обязательное заполнение поля с названием школы
	recoveryPasswordCheckFormFieldCodeRequired:
		'Введите присланный код подтверждения',
	// Кнопка отправки формы
	recoveryPasswordCheckFormOkButton: 'Подтвердить',

	// ФОРМА ЗАПРОСА НА УДАЛЕНИЕ АККАУНТА
	deleteAccReqFormFieldEnterButton: 'Отправить',
	deleteAccReqPageHeader: 'Удаление аккаунта',
	deleteAccReqPageDescription: 'Введите адрес электронной почты',

	// ФОРМА ПОДТВЕРЖДЕНИЯ УДАЛЕНИЕ АККАУНТА
	deleteAccConfirmFormFieldEnterButton: 'Удалить',
	deleteAccConfirmPageHeader: 'Удаление аккаунта',
	deleteAccConfirmPageDescription: 'Внимание! Все ваши данные будут удалены.',
	deleteAccDonePageHeader: 'Ваш аккаунт удален',
	deleteAccMailSentPageHeader: 'Удаление аккаунта',
	deleteAccMailSentPageDescription:
		'Мы отправили вам ссылку, которая будет действовать 24 часа.',
}

export default authRu
