import { useRef } from 'react'
import {
	useGetText,
	useGetOnInput,
} from 'pages/landing/landingConstructor/tariffs/card/common/EditableText/fn/onInput'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'
import 'pages/landing/landingConstructor/tariffs/card/common/EditableText/EditableText.scss'

type EditableTextProps<
	A extends keyof TariffsStoreType.Tariff,
	B extends keyof TariffsStoreType.Tariff[A],
	C extends keyof TariffsStoreType.Tariff[A][B],
	D extends keyof TariffsStoreType.Tariff[A][B][C],
	E extends keyof TariffsStoreType.Tariff[A][B][C][D],
	F extends keyof TariffsStoreType.Tariff[A][B][C][D][E],
	G extends keyof TariffsStoreType.Tariff[A][B][C][D][E][F]
> = {
	// Идентификатор карточки у которой изменяют текст
	tariffInnerId: number
	// Путь до свойства с текстом в Состоянии
	propsPathArr: [A, B?, C?, D?, E?, F?, G?]
	// Текстозаполнитель
	placeholder?: string
	// Функция, запускаемая после изменения текста
	afterChange?: () => void
}

// Элемент со спаном где можно редактировать текст
function EditableText<
	A extends keyof TariffsStoreType.Tariff,
	B extends keyof TariffsStoreType.Tariff[A],
	C extends keyof TariffsStoreType.Tariff[A][B],
	D extends keyof TariffsStoreType.Tariff[A][B][C],
	E extends keyof TariffsStoreType.Tariff[A][B][C][D],
	F extends keyof TariffsStoreType.Tariff[A][B][C][D][E],
	G extends keyof TariffsStoreType.Tariff[A][B][C][D][E][F]
>(props: EditableTextProps<A, B, C, D, E, F, G>) {
	const { tariffInnerId, propsPathArr, placeholder, afterChange } = props

	const spanRef = useRef<HTMLElement>(null)

	const text = useGetText(tariffInnerId, propsPathArr)
	const onInput = useGetOnInput(tariffInnerId, propsPathArr, afterChange)

	return (
		<span
			contentEditable
			suppressContentEditableWarning
			onInput={onInput}
			className="land-const-editable-text"
			data-placeholder={placeholder ?? 'Текст'}
			ref={spanRef}
		>
			{text}
		</span>
	)
}

export default EditableText
