import useStudentsStore from '../../../../StudentsTableCurator/zustand/store'
import { StudentsStoreType } from '../../../../StudentsTableCurator/zustand/storeTypes'

export enum FieldsNames {
	// Поле со строкой поиска
	GroupName = 'groupName',
}

export interface FormValues {
	[FieldsNames.GroupName]: string
}

/** Обработчик отправки формы поиска по почте */
export function useGetOnGroupNameChange() {
	const setSearchValuesProp = useStudentsStore(
		(store) => store.setSearchValuesProp
	)

	return function (values: FormValues) {
		setSearchValuesProp(
			StudentsStoreType.SearchValueNames.GroupName,
			values[FieldsNames.GroupName]
		)
	}
}
