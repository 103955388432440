import { useEffect } from 'react'
import useStudentStore from '../../zustand/store'
import { StudentStoreType } from '../../zustand/storeTypes'
import userRequests from 'parts/requests/user/userRequest'
import UrlService from 'parts/services/UrlService'

/** Получает данные о выбранном ученике и помещает в Зустанд */
export function useFillInStore() {
	const studentId = UrlService.useGetStudentId()

	const updateStore = useStudentStore((store) => store.updateStore)

	useEffect(
		function () {
			if (!studentId) return

			getStudentDataFromBDAndSetToStore(studentId, updateStore)
		},
		[studentId]
	)
}

/**
 * Загружает данные ученика по переданному идентификатору и ставит в Хранилище.
 * @param {Number} studentId — id ученика
 * @param updateStore — функция обновляющая значение корневого свойства Зустанда.
 */
async function getStudentDataFromBDAndSetToStore(
	studentId: string | number,
	updateStore: StudentStoreType.UpdateStore
) {
	try {
		const response = await userRequests.getUser(studentId)

		if (response.status === 200) {
			updateStore({ student: response.data })
		}
	} catch (err) {
		updateStore({ student: null })
	}
}
