import React, { ReactNode } from 'react'
import cn from 'classnames'
import './ArticleContentWrapper.scss'

type ArticleContentWrapperProps = {
	children: ReactNode | ReactNode[] // Контент статьи
	center?: boolean // Должен ли контент быть по центру статьи?
	innerWrapperWidth?: 'extra-small' | 'small' | 'middle' | 'big' | 'full' // Какой ширины должна быть ограничивающая обёртка
	innerWrapperClass?: string // Дополнительный класс добавляемый обёртке содержимого
}

// Обёртка контента статьи
function ArticleContentWrapper(props: ArticleContentWrapperProps) {
	const {
		children,
		center = false,
		innerWrapperWidth = 'middle',
		innerWrapperClass = '',
	} = props

	const classes = ['article-content-wrapper']
	if (center) classes.push('article-content-wrapper--center')

	const innerClasses = [
		'article-content-wrapper__inner',
		'article-content-wrapper__inner--' + innerWrapperWidth + '-width',
	]

	if (center) innerClasses.push('article-content-wrapper__inner--center')
	if (innerWrapperClass) innerClasses.push(innerWrapperClass)

	return (
		<div className={cn(classes)}>
			<div className={cn(innerClasses)}>{children}</div>
		</div>
	)
}

export default ArticleContentWrapper
