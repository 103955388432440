import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { message } from 'antd'
import UrlService from 'parts/services/UrlService'
import LessonsApiTypes from 'parts/requests/lessons/lessonsApiTypes'
import { lessonsQuery } from 'parts/requests/lessons/lessonsQuery'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import useLessonsPresetsStore from '../../zustand/store'

export function resetStore() {
	const { updateStore } = useLessonsPresetsStore.getState()

	updateStore({ selectedPreset: null, isOpenApplyPresetConfirmation: false })
}

export function useChangeApplyPresetModalVisibility(isVisible: boolean) {
	const { updateStore } = useLessonsPresetsStore.getState()

	return (presetId?: number) => () => {
		if (isVisible && presetId) {
			updateStore({
				selectedPreset: presetId,
				isOpenApplyPresetConfirmation: true,
			})
		} else {
			resetStore()
		}
	}
}

export function useGetConfirmApplyPreset() {
	const selectedPreset = useLessonsPresetsStore(
		(state) => state.selectedPreset
	)

	const trainingId = UrlService.useGetTrainingId()

	const applyPresetMutation = lessonsQuery.applyPreset.useMutation({
		onSuccess: useGetMutateOnSuccess(),
		onError: useGetMutateOnError(),
	})

	return function () {
		resetStore()

		applyPresetMutation.mutate({
			presetId: selectedPreset!,
			trainingId,
		})
	}
}

/** Функция, выполняемая при успешном запросе на добавление шаблонного урока */
function useGetMutateOnSuccess() {
	const queryClient = useQueryClient()
	const { t } = useTranslation()

	return function (
		presetApplyRes: AxiosResponse<LessonsApiTypes.PresetApply>
	) {
		// Показать уведомление о добавлении урока
		message.success(t('adminTraining.lessonPresetSuccessfullyAdded'))

		queryClient.setQueryData(
			[trainingQuery.getTrainingForAdmin(presetApplyRes.data.id).key],
			(old) => presetApplyRes
		)
	}
}

/** Функция, выполняемая при появлении ошибки после запроса на добавление шаблонного урока */
function useGetMutateOnError() {
	const { t } = useTranslation()

	return function (err: unknown) {
		message.error(t('adminTraining.lessonPresetCreationError'))
	}
}
