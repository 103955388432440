import { useQueryClient } from 'react-query'
import { useGetMySchoolsListRouter } from './fn/useGetMySchoolsListRouter'
import { schoolQuery } from 'parts/requests/school/schoolQuery'

export const MySchoolsListController = {
	router: useGetMySchoolsListRouter,
	// Функция, помечающая данные массива школ неактуальными.
	// После этого React Query скачает новый массив школ
	useGetUpdate: function () {
		const queryClient = useQueryClient()

		return function () {
			// Пометить список школ неактуальным. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [schoolQuery.getSchoolsByUser.key],
			})
		}
	},
}
