import { DatePicker } from 'antd'
import { useGetOnDateChange } from './fn/onDateChange'

const { RangePicker } = DatePicker

// Функция, указания диапазона дат когда была сделана покупка
export function DateForm() {
	const onDateChange = useGetOnDateChange()

	return <RangePicker onChange={onDateChange} />
}
