import AddLessonModal from './AddLessonModal'
import useAddLessonModalStore from './zustand/store'

export const AddLessonModalController = {
	element: <AddLessonModal />,
	open() {
		const updateStore = useAddLessonModalStore.getState().updateStore
		updateStore({ isOpen: true })
	},
	close() {
		const updateStore = useAddLessonModalStore.getState().updateStore
		updateStore({ isOpen: false })
	},
}
