import React, { useRef } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { useSetTextInEditableElementInMount } from 'parts/utils/hooks/hooks'
import { getSetFocusInText, useGetChangeText } from '../common/common'
import './EditableDescription.scss'

type EditableHeaderProps = {
	// Изначальный текст в редактируемом элементе
	initialValue: string
	// Функция запускаемая после изменения текста, в которую передаётся новый текст
	afterChange: (newText: string) => void
	// Максимальное число символов
	maxCharacters?: number
}

// Элемент заголовка с редактируемым текстом
function EditableDescription(props: EditableHeaderProps) {
	const { initialValue, afterChange, maxCharacters } = props

	const textElemRef = useRef(null)
	useSetTextInEditableElementInMount(initialValue, textElemRef)
	const changeText = useGetChangeText(afterChange, maxCharacters)

	const setFocusInText = getSetFocusInText(textElemRef)

	return (
		<p className="page-header__description">
			<span
				contentEditable
				suppressContentEditableWarning
				ref={textElemRef}
				onKeyDown={changeText}
				onPaste={changeText}
			>
				{initialValue}
			</span>
			<EditOutlined
				className="editable-description__icon"
				onClick={setFocusInText}
			/>
		</p>
	)
}

export default EditableDescription
