import TrainingApiTypes from 'parts/requests/training/trainingApiTypes'
import TrainingStudentService from 'parts/services/TrainingStudentService'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { TrainingsStoreType } from '../storeTypes'
import LessonStudentService from 'parts/services/LessonStudentService'
import { dateStrToHumanDateStr } from 'parts/utils/time'

/**
 * Получает массив курсов и преобразует его в данные для отрисовки карточки курса
 * @param trainings — массив курсов
 */
export function convertTrainingsToCardsDataForStore(
	trainings: TrainingApiTypes.GetTrainingsForStudent
): TrainingsStoreType.TrainingCard[] {
	return trainings.map((training) => {
		return {
			id: training.trainingId,
			trainingStatus: TrainingStudentService.getTrainingStatus(training),
			trainingName: training.trainingName,
			cover: training.cover,
			trainingDescription: training.trainingDescription,
			groupId: training.groupId,
			groupName: training.groupName,
			groupStart: getGroupStartDate(training),
			groupEnd: getGroupEndDate(training),
			progress: training.progress,
			unperformedExercises: training.unperformedExercises,
			unperformedLessons: training.unperformedLessons,
			nextLessonOpenDate: getNextLessonOpenDate(training),
		}
	})
}

/**
 * Возвращает дату начала группы в человеческом формате.
 * @param training — данные курса для ученика
 */
function getGroupStartDate(training: TrainingApiTypes.GetTrainingForStudent) {
	const startDate =
		TrainingStudentService.getStudentTrainingGroupStartDate(training)

	return startDate ? dateStrToHumanDateStr(startDate) : null
}

/**
 * Возвращает дату окончания группы в человеческом формате
 * @param training — данные курса для ученика
 */
function getGroupEndDate(training: TrainingApiTypes.GetTrainingForStudent) {
	const endDate =
		TrainingStudentService.getStudentTrainingGroupEndDate(training)
	return endDate ? dateStrToHumanDateStr(endDate) : null
}

/**
 * Возвращает дату следующего урока
 * @param {Object} training — объект курса для ученика
 */
function getNextLessonOpenDate(training: TrainingEntityTypes.TrainingStudent) {
	const nextLessonOpenDate =
		LessonStudentService.getNearestLessonOpenDateForStudent(training)

	return nextLessonOpenDate ? dateStrToHumanDateStr(nextLessonOpenDate) : null
}
