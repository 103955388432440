import { useMemo } from 'react'
import { createFileListFromUrlList } from 'ui/upload/UploadFileInForm/fn/fn'
import useTrainingHeaderAdminStore from '../../zustand/store'

/** Возвращает адрес существующей обложки курса для поля загрузки изображения. */
export function useGetCoverSrc() {
	const training = useTrainingHeaderAdminStore((state) => state.training)

	return useMemo(
		function () {
			if (!training) {
				return []
			}

			return training.cover
				? createFileListFromUrlList([training.cover])
				: []
		},
		[training]
	)
}
