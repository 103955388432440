import React from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import ExerciseStateType from './fn/ExerciseStateType'
import { useGetCurrentQuestion } from './fn/main'
import {
	getAttemptsCount,
	getQuestionCounter,
	useGetPrevNextButtonHandler,
	usePrevNextButtonEnabled,
	getResultButtonText,
	isResultButtonEnabled,
	useGetOnResultButtonClick,
} from './fn/bottom'

type BottomProps = {
	state: ExerciseStateType.Main
	setState: React.Dispatch<React.SetStateAction<ExerciseStateType.Main>>
}

// Блок с кнопками ответа на вопрос и перехода к следующему вопросу
export function Bottom(props: BottomProps) {
	const { state, setState } = props

	const { t } = useTranslation()
	const question = useGetCurrentQuestion(state)

	const counter = getQuestionCounter(state)
	const attempts = getAttemptsCount(question)

	const prevQuestionBtnEnabled = usePrevNextButtonEnabled('prev', state)
	const nextQuestionBtnEnabled = usePrevNextButtonEnabled('next', state)
	const prevQuestionBtnFn = useGetPrevNextButtonHandler(
		'prev',
		state,
		setState
	)
	const nextQuestionBtnFn = useGetPrevNextButtonHandler(
		'next',
		state,
		setState
	)

	if (!question) return null

	return (
		<div className="exercise-test-content__bottom">
			<div className="exercise-test-content__bottom-right">
				<ResultButton state={state} setState={setState} />
				<div className="exercise-test-content__back-next-btns">
					<Button
						onClick={prevQuestionBtnFn}
						disabled={!prevQuestionBtnEnabled}
					>
						Назад
					</Button>
					<Button
						onClick={nextQuestionBtnFn}
						disabled={!nextQuestionBtnEnabled}
					>
						Вперёд
					</Button>
				</div>
			</div>
			<div className="exercise-test-content__bottom-left">
				<span>{counter}</span>
				<span>{attempts}</span>
			</div>
		</div>
	)
}

type ResultButtonProps = {
	state: ExerciseStateType.Main
	setState: React.Dispatch<React.SetStateAction<ExerciseStateType.Main>>
}

// Кнопка показа правильного ответа
function ResultButton(props: ResultButtonProps) {
	const { state, setState } = props

	const question = useGetCurrentQuestion(state)
	if (!question) return null

	const isDisabled = !isResultButtonEnabled(state, question)
	const onButtonClick = useGetOnResultButtonClick(state, setState, question)
	const buttonText = getResultButtonText(state, question)

	return (
		<Button
			type="primary"
			className="exercise-test-content__bottom-result-btn"
			onClick={onButtonClick}
			disabled={isDisabled}
		>
			{buttonText}
		</Button>
	)
}
