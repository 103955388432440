import React from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { clearStoreInUnmount } from './fn/store'
import useSendCodeBtnStore from './zustand/store'
import { useGetSendCode } from './fn/useSendCode'
import { useDecrementSecondsLeft } from './fn/decrementSeconds'
import { AxiosResponse } from 'axios'
import cn from 'classnames'

export type SendConfirmationCodeToEmailBtnProps = {
	// Функция, которая должна запускаться при нажатии на кнопку
	reqFn: () => Promise<AxiosResponse>
	extraClass?: string
}

// Кнопка, отправляющая письмо с кодом подтверждения почты
function SendConfirmationCodeToEmailBtn(
	props: SendConfirmationCodeToEmailBtnProps
) {
	const { t } = useTranslation()

	const { reqFn, extraClass } = props

	clearStoreInUnmount()
	useDecrementSecondsLeft()

	const secondsLeft = useSendCodeBtnStore((state) => state.secondsLeft)
	const onClickHandler = useGetSendCode(reqFn)

	let btnText =
		secondsLeft > 0
			? `Отправить код повторно можно через ${secondsLeft} секунд`
			: 'Отправить код повторно'

	return (
		<Button
			size="small"
			type="link"
			disabled={secondsLeft > 0}
			onClick={onClickHandler}
			className={cn(extraClass)}
		>
			{btnText}
		</Button>
	)
}

export default SendConfirmationCodeToEmailBtn
