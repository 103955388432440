import LessonStudentService from 'parts/services/LessonStudentService'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { TrainingStoreType } from '../storeTypes'

export function convertTrainingToLessonsData(
	training: TrainingEntityTypes.TrainingStudent
): TrainingStoreType.Lesson[] {
	return training.lessons.map((lesson) => {
		return {
			id: lesson.id,
			cover: lesson.cover || null,
			name: lesson.name,
			description: lesson.description || null,
			status: LessonStudentService.getLessonStatus(lesson),
			performed: lesson.performed,
			exercises: lesson.exercises,
		}
	})
}
