import { useQueryClient } from 'react-query'
import { useGetGroupConditionsFormRouter } from './fn/useGetGroupConditionsFormRouter'
import { groupQuery } from 'parts/requests/group/groupQuery'
import { lessonsQuery } from 'parts/requests/lessons/lessonsQuery'

export const GroupConditionsFormController = {
	router: useGetGroupConditionsFormRouter,
	// Функция, помечающая данные группы неактуальными. После этого React Query скачает новые данные группы.
	useGetUpdate: function () {
		const queryClient = useQueryClient()

		return function (trainingId: string | number) {
			// Пометить данные курса неактуальными. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [
					groupQuery.getTrainingGroups(trainingId).key,
					lessonsQuery.getTrainingLessons(trainingId).key,
				],
			})
		}
	},
}
