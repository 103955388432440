import React from 'react'
import { Upload, UploadFile } from 'antd'
import {
	createStore,
	makeRequest,
	removeFile,
	useSetFileUrlsToFormData,
	useSetFileUrlsToGetUploadedFilesFn,
} from './fn/fn'
import UploadFileTypes from 'parts/constants/uploadTypes'
import { PlusOutlined } from '@ant-design/icons'
import './UploadAvatar.scss'

export type GetUploadedFilesType = (files: UploadFile[]) => void

type UploadAvatarProps = {
	// Имя поля
	inputName: string
	// Аватар будет квадратным или круглым
	listType?: 'picture-card' | 'picture-circle'
	// Изначальный массив сведений об изначальных и загруженных файлов
	initialFileList: UploadFile[]
	// Максимальное количество загружаемых файлов
	maxCount: number
	// Адреса загруженных файлов ставятся в форму, которая получается из контекста.
	// Название поля формы передаётся в inputName
	// Но в случае, когда или формы нет, или данные формы отправляют при каждом изменении формы
	// нужно передать функцию, в которую передаётся адреса загружаемого файла
	getUploadedFiles?: GetUploadedFilesType
}

// Поле загрузки аватара
function UploadAvatar(props: UploadAvatarProps) {
	const {
		inputName,
		listType = 'picture-circle',
		initialFileList,
		maxCount,
		getUploadedFiles,
	} = props

	const { fileList, setFileList } = createStore(initialFileList)

	// Поставить загруженные файлы в данные формы взятой из контекста
	useSetFileUrlsToFormData(inputName, fileList)

	// После загрузки файлов поставить их в функцию, принимающей загруженные файлы
	useSetFileUrlsToGetUploadedFilesFn(getUploadedFiles, fileList)

	return (
		<div className="upload-avatar">
			<Upload
				name={inputName}
				customRequest={(options) =>
					makeRequest(
						options,
						setFileList,
						UploadFileTypes.USER_AVATAR
					)
				}
				accept="image/*"
				maxCount={maxCount}
				listType={listType}
				fileList={fileList}
				onRemove={(removedFile) =>
					removeFile(removedFile, fileList, setFileList)
				}
			>
				{fileList.length < maxCount && (
					<div className="upload-avatar__add">
						<PlusOutlined className="upload-avatar__add-sign" />
						<p className="upload-avatar__add-text">Загрузить</p>
					</div>
				)}
			</Upload>
		</div>
	)
}

export default UploadAvatar
