import { MutableRefObject, useEffect } from 'react'
import { getGapWords } from '../../../common/getGapWords'
import useExerciseStore, {
	ExerciseStateType,
} from '../../zustand/exerciseState'

/**
 * Функция создаёт HTML текстового блока с пропусками
 * @param $editor — ссылка на редактор
 * @param {String} htmlString — строка с HTML из которой нужно создать разметку с пропусками
 */
export function useCreateInnerHtml(
	$editor: MutableRefObject<null | HTMLElement>,
	htmlString: string
): void {
	useEffect(
		function () {
			if (!$editor.current) return

			createInnerHtml($editor.current, htmlString)
		},
		[$editor, htmlString]
	)
}

function createInnerHtml($editor: null | HTMLElement, htmlString: string) {
	if (!$editor) return

	// Получение массива пропущенных слов
	const gapWords = getGapWords(htmlString)

	// Создание строки с разметкой текстового блока с пропусками и вставка в редактор
	$editor.innerHTML = setGapsToHtmlString(gapWords, htmlString)
}

/**
 * Функция создаёт строку с разметкой текстового блока с пропусками.
 * Слова, которые должны быть в разметке пропусками обрамлены фигурными скобками.
 * И функция ставит вместо таких слов другую разметку в которую можно вписывать слова
 * @param {Array} gapWords — массив пропущенных слов
 * @param {String} htmlString — строка с HTML из которой нужно создать разметку с пропусками
 */
function setGapsToHtmlString(gapWords: string[], htmlString: string) {
	let newHtmlString = htmlString

	let gapWordsIdx = 0 // idx текущего слова из массива пропущенных слов
	let openBraceIdx = 0 // Номер буквы с которого начинается открывающая фигурная скобка
	let closeBraceIdx = 0 // Номер буквы с которого начинается закрывающая фигурная скобка

	for (let i = 0; i < newHtmlString.length; i++) {
		const letter = newHtmlString[i]

		if (letter == '{') {
			openBraceIdx = i
		} else if (letter == '}') {
			closeBraceIdx = i

			// Получить пропущенное слово из массива пропущенных слов
			const currentSlotWord = gapWords[gapWordsIdx++]
			// Создать разметку, которая должна быть вместо пропущенного слова
			const slotStr = createSlotStr(currentSlotWord)
			// Поставить разметку
			newHtmlString =
				newHtmlString.substring(0, openBraceIdx) +
				slotStr +
				newHtmlString.substring(closeBraceIdx + 1)

			// Увеличить счётчик на размер вставляемого слова чтобы не делать лишние проходы цикла
			i = i - currentSlotWord.length + slotStr.length
		}
	}

	return newHtmlString
}

/**
 * Возвращает строку разметки, которой нужно заменить слово, которое должно быть пропуском.
 * Это <span> с contenteditable для возможности ввести слово.
 * А следом пишется правильное слово чтобы показать его если ученик не смог ввести правильный вариант.
 * @param {String} rightWord — правильное слово, которые нужно ввести в пропуск
 */
function createSlotStr(rightWord: string) {
	const editableSpan = `<span data-right="${rightWord}" class="exercise-drop-word-content__slot exercise-drop-word-content__slot--wide"></span>`
	const rightAnswerSpan = `<span class="exercise-drop-word-content__right-answer">${rightWord}</span>`
	return editableSpan + rightAnswerSpan
}

/** Проверяет упражнение за пользователем */
export function useGetCheckExerciseFn() {
	const statistics = useExerciseStore((store) => store.statistics)
	const attempts = useExerciseStore((store) => store.attempts)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		const $texts = document.getElementsByClassName(
			'exercise-drop-word-content__text-blocks'
		)[0] as HTMLDivElement
		if (!$texts) return

		// Если попытки закончились, то показать правильные варианты
		if (attempts <= 1) {
			setTimeout(() => {
				showRightAnswers($texts)
			}, 0)
		}

		// Проверить введённые ответы
		const { totalSlotsCounter } = checkSlots($texts)

		updateStore({
			stage: 'Checked',
			totalSlots: totalSlotsCounter,
			statistics: getStatistics(statistics),
			attempts: attempts - 1,
		})
	}
}

function getStatistics(
	currentStats: ExerciseStateType.Statistic[]
): ExerciseStateType.Statistic[] {
	if (currentStats.length === 3) {
		return currentStats
	}

	// сколько правильных ответов дали за эту попытку
	const passedQuestions = getPassedQuestionsNum()
	if (passedQuestions === null) return []

	const newStatistics = [...currentStats]

	newStatistics.push({
		passedQuestions,
	})

	return newStatistics
}

/**
 * Функция получает все элементы пропуска
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
function get$Slots($texts: HTMLDivElement) {
	return $texts.querySelectorAll('.exercise-drop-word-content__slot')
}

/**
 * Функция перебирает пропуски и для каждого пропуска запускает переданную функцию, в которую передаёт элемент пропуска
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 * @param {Function} handler — функция запускаемая для каждого пропуска в которую передаёт элемент пропуска
 */
function enumerateSlots(
	$texts: HTMLDivElement,
	handler: ($gap: HTMLElement) => void
) {
	const $gaps = get$Slots($texts)

	for (let i = 0; i < $gaps.length; i++) {
		const $gap = $gaps[i]

		if ($gap.nodeType == 1) {
			handler($gap as HTMLElement)
		}
	}
}

/**
 * Функция проверяет введённые в пропуски слова и задаёт им классы в зависимости от правильности слова
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
export function checkSlots($texts: HTMLDivElement) {
	let successAnswersCounter = 0 // Количество правильно введённых слов

	enumerateSlots($texts, ($slot) => {
		// Получение правильного слова
		const rightWord = $slot.getAttribute('data-right') as string

		// Перетащенное слова
		const droppedWord = getDroppedWord($slot)

		const isWordsEqual = clearString(rightWord) === clearString(droppedWord)

		if (isWordsEqual) {
			setSuccessToSlot($slot)
			successAnswersCounter++
		} else {
			setErrorToSlot($slot)
		}
	})

	return {
		successAnswersCounter,
		totalSlotsCounter: get$Slots($texts).length,
	}
}

function getDroppedWord($slot: HTMLElement): string {
	return $slot.innerText
}

function setSuccessToSlot($slot: HTMLElement) {
	$slot.classList.remove('exercise-drop-word-content__slot--fail')
	$slot.classList.add('exercise-drop-word-content__slot--success')

	const $button = $slot.children[0] as HTMLButtonElement

	if ($button) {
		$button.classList.remove('exercise-drop-word-content__word--error')
		$button.classList.add('exercise-drop-word-content__word--success')
	}
}

function setErrorToSlot($slot: HTMLElement) {
	$slot.classList.remove('exercise-drop-word-content__slot--success')
	$slot.classList.add('exercise-drop-word-content__slot--fail')

	const $button = $slot.children[0] as HTMLButtonElement

	if ($button) {
		$button.classList.remove('exercise-drop-word-content__word--success')
		$button.classList.add('exercise-drop-word-content__word--error')
	}
}

/**
 * Функция преобразует слово для правильного сравнения
 * @param {String} str — строка, которую нужно подготовить для сравнения
 */
function clearString(str: string) {
	let preparedStr = str.toLowerCase().trim()
	preparedStr = preparedStr.replace(/\./g, '')
	preparedStr = preparedStr.replace(/<\/?[\w\s="'-]*>/g, '')
	return preparedStr
}

/**
 * Функция показывает правильный вариант слова после пропуска если пользователь ввёл неверный вариант
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
function showRightAnswers($texts: HTMLDivElement) {
	enumerateSlots($texts, ($slot) => {
		if (
			$slot.classList.contains('exercise-drop-word-content__slot--fail')
		) {
			const $rightWord = $slot.nextElementSibling

			if ($rightWord) {
				$rightWord.classList.add(
					'exercise-drop-word-content__right-answer--open'
				)
			}
		}
	})
}

/** Возвращает количество дырок, в которые ученик перетащил правильные слова */
export function getPassedQuestionsNum() {
	const $texts = document.getElementsByClassName(
		'exercise-drop-word-content__text-blocks'
	)[0] as HTMLDivElement
	if (!$texts) return null

	// Проверить введённые ответы
	const { successAnswersCounter } = checkSlots($texts)

	return successAnswersCounter
}
