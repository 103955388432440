import React from 'react'
import { produce } from 'immer'
import { RcFile } from 'antd/lib/upload/interface'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import {
	TrueOrFalseExerciseFormStateType,
	FormStatementType,
	createFormStatementItem,
	ImageFile,
} from './stateType'
import downloadFileToAmazon from 'parts/services/downloadFileToAmazon'
import UploadFileTypes from 'parts/constants/uploadTypes'
import { getFormState, setFormState } from '../../common/exerciseFormCommonFunc'
import { getStatementById } from './check'

/**
 * Обработчик изменения поля «Задание»
 * @param {String} newValue — строка HTML из редактора
 */
export function onChangeTaskInput(newValue: string) {
	const formState = getFormState<TrueOrFalseExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		draft.taskInput.value = newValue
	})

	setFormState(newState)
}

/**
 * Обработчик изменения поля текста утверждения
 * @param {Object} event — объект события
 * @param {Number} statementId — id объекта утверждения
 */
export function onChangeStatementTextInput(
	event: React.ChangeEvent<HTMLInputElement>,
	statementId: number
) {
	const formState = getFormState<TrueOrFalseExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const inputValue = event.target.value

		const statement = getStatementById(draft, statementId)

		if (statement) {
			statement.text = inputValue

			if (inputValue) {
				statement.noTextError = false
				draft.isFormInvalid = false
			}
		}
	})

	setFormState(newState)
}

/**
 * Обработчик изменения флага отмечающий правильный вариант утверждения
 * @param {Object} event — объект события
 * @param {Number} statementId — id объекта утверждения
 */
export function onChangeCorrectInput(
	event: CheckboxChangeEvent,
	statementId: number
) {
	const formState = getFormState<TrueOrFalseExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const statement = getStatementById(draft, statementId)
		if (!statement) return draft

		// Отметить флаг
		statement.correct = event.target.checked
	})

	setFormState(newState)
}

/**
 * Функция удаляет блок утверждения
 * @param {Number} statementId — id объекта утверждения
 */
export function removeStatement(statementId: number) {
	const formState = getFormState<TrueOrFalseExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const statementObj = getStatementById(draft, statementId)
		if (!statementObj) return draft

		draft.statements = draft.statements.filter((statement) => {
			return statement.id !== statementId
		})
	})

	setFormState(newState)
}

/** Обработчик кнопки создания нового блока утверждения */
export function addNewStatement() {
	const formState = getFormState<TrueOrFalseExerciseFormStateType>()

	let maxStatementId = 0

	formState.statements.forEach((statement) => {
		if (statement.id > maxStatementId) {
			maxStatementId = statement.id
		}
	})

	const newStatement = createFormStatementItem(++maxStatementId)

	const newState = produce(formState, (draft) => {
		draft.statements.push(newStatement)
	})

	setFormState(newState)
}

/**
 * Функция загружает файл, получает его адрес на сервере и все данные записывает в местное Хранилище
 * @param {Object} file — данные о загружаемом файле
 * @param {Array} FileList
 * @param {Number} statementId — id объекта вопроса
 */
export async function beforeUploadFile(
	file: RcFile,
	FileList: RcFile[],
	statementId: number
) {
	await downloadFileToAmazon(
		file,
		FileList,
		UploadFileTypes.EXERCISE_IMAGES,
		{
			beforeDownloading(fileName: string) {
				// Поставить в состояние аудио-блока статус загрузки файла
				changeDownloadFileState(statementId, {
					status: 'downloading',
					progress: 0,
					fileName,
				})
			},
			whileDownloading(
				percentCompleted,
				cancelDownloading,
				fileUrl: string
			) {
				// Если статус загружаемого файла не равен 'downloading', то есть его изменили, то прекратить загрузку
				const statementBlock = getStatementById(
					getFormState<TrueOrFalseExerciseFormStateType>(),
					statementId
				)

				if (
					statementBlock &&
					statementBlock.image.status !== 'downloading'
				) {
					cancelDownloading()
				}

				// Поставить значение загруженных процентов в Состояние
				changeDownloadFileState(statementId, {
					progress: percentCompleted,
					link: fileUrl,
				})
			},
			onSuccess(fileName: string, url: string) {
				changeDownloadFileState(statementId, {
					link: url,
					status: 'success',
					fileName,
				})
			},
			onError(fileName: string) {
				changeDownloadFileState(statementId, {
					status: 'error',
					fileName,
				})
			},
		}
	)
}

/**
 * Обновляет переданные свойства в объекте Состояния загружаемого файла.
 * @param {Number} statementId — id объекта вопроса
 * @param {Object} newDownloadFileProps — объект данных Состояния загружаемого файла
 */
function changeDownloadFileState(
	statementId: number,
	newDownloadFileProps: Partial<ImageFile>
) {
	const formState = getFormState<TrueOrFalseExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const statement = getStatementById(draft, statementId)

		if (!statement) return

		statement.image = Object.assign(statement.image, newDownloadFileProps)
	})

	setFormState(newState)
}

/**
 * Функция ставит статус загружаемого файла в значение empty.
 * Это действие дополнительно выполняется после прекращения загрузки файла.
 * @param {Number} statementId — id объекта вопроса
 */
export function setFileDownloadedStatusToDefault(statementId: number) {
	changeDownloadFileState(statementId, { status: 'empty' })
}

/**
 * Обработчик нажатия на кнопку удаления загруженного файла
 * @param {Object} statementData — id блока c данными вопроса
 */
export function removeDownloadedFile(statementData: FormStatementType) {
	const formState = getFormState<TrueOrFalseExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const statement = getStatementById(draft, statementData.id)
		if (!statement) return draft

		statement.image.link = ''
		statement.image.status = 'empty'
		statement.image.progress = 0
		statement.image.fileName = ''
	})

	setFormState(newState)
}
