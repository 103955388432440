import { useMemo } from 'react'
import { schoolQuery } from '../requests/school/schoolQuery'
import { trainingQuery } from '../requests/training/trainingQuery'
import UserApiTypes from '../requests/user/userApiTypes'
import { userQuery } from '../requests/user/userQuery'
import useSystemStore from '../systemStore/systemStore'
import EntityTypes from '../types/EntityTypes'
import { useGetUserRole } from '../utils/hooks/hooks'

const RestrictionsService = {
	// Можно ли создать ещё одну школу? (schools)
	useCanCreateNewSchool,
	// Можно ли создать ещё один курс? (trainings)
	useCanCreateNewTraining,
	// Можно ли создать ещё одного ученика? (newStudentsInMonth)
	useCanCreateNewStudent,
	// Можно ли создать ещё одного менеджера? (maxManagers)
	useCanCreateNewManager: () =>
		useCanCreateNewManagerOrCurator(EntityTypes.UserRole.CURATOR),
	// Можно ли создать ещё одного менеджера? (maxCourators)
	useCanCreateNewCurator: () =>
		useCanCreateNewManagerOrCurator(EntityTypes.UserRole.MANAGER),
	// Можно ли делать интеграцию с платёжными системами (ownPayments)
	useCanIntegrateWithPaySystems,
	// Можно ли использовать генерирование текста через ChatGPT. (aiGeneration)
	useCanUseAiGeneration,
	// Можно ли использовать конструктор одностраничников. (siteConstructor)
	useCanUseSiteConstructor,
	// Можно ли использовать шаблоны уроков. (lessonTemplate)
	useCanUseLessonTemplate,
	// Можно ли использовать систему управления. (crm)
	useCanUseCRM,
	// Можно ли начинать прямые эфиры. (liveLessons)
	useCanUseLiveLessons,
	// Можно ли использовать доску на прямом эфире. (liveWhaiteboard)
	useCanUseLiveWhiteboard,
	// Можно ли сохранять историю доски. (liveStoreWhiteboard)
	useCanSaveLiveWhiteboardHistory,
	// Можно ли использовать наклейки для доски на прямом эфире. (liveWhiteboardStickers)
	useCanUseLiveWhiteboardStickers,
	// Можно ли записывать прямой эфир. (liveLessonRecord)
	useCanRecordLive,
	// Можно ли использовать диалоги на прямом эфире. (liveChat)
	useCanUseLiveChat,
	// Можно ли использовать интеграцию с Ютубом на прямом эфире. (liveYoutube)
	useCanUseLiveYoutube,
	// Получение максимального количества дискового пространства для хранения файлов
	useGetMaxFileStorageInGb,
	// Получение максимального количества участников на прямом эфире
	useGetLiveMaxParticipants,
	// Разделение на группы учеников
	// groups: boolean
}

export default RestrictionsService

// Возвращает булево значение можно ли создать ещё одну школу
function useCanCreateNewSchool(): boolean {
	const user = useSystemStore((store) => store.user)
	const restrictions = useGetRestrictions()

	const { data: schoolsRes } = schoolQuery.getSchoolsByUser.useQuery(user.id)

	return useMemo(
		function () {
			if (!schoolsRes || !restrictions) return false

			return restrictions.schools > schoolsRes.data.length
		},
		[schoolsRes]
	)
}

// Возвращает булево значение можно ли создать ещё один курс
function useCanCreateNewTraining(): boolean {
	const restrictions = useGetRestrictions()
	const userRoles = useGetUserRole()

	// Получить список курсов для администратора если пользователь не ученик
	const { data: trainingsForAdminRes } =
		trainingQuery.getTrainingsForAdmin.useQuery({
			enabled: userRoles.notStudent,
		})

	return useMemo(
		function () {
			if (!trainingsForAdminRes || !restrictions) return false

			return restrictions.trainings > trainingsForAdminRes.data.length
		},
		[trainingsForAdminRes]
	)
}

// Возвращает булево значение можно ли создать ещё одного ученика // newStudentsInMonth
function useCanCreateNewStudent(): boolean {
	const restrictions = useGetRestrictions()
	if (!restrictions) return false

	const { data: usersRes } = userQuery.getStudentCountThisMonth().useQuery()

	return useMemo(
		function () {
			if (!usersRes) return false

			// Получить учеников зарегистрированных в этом месяце
			const usersRegisteredInThisMonth = usersRes.data

			return restrictions.newStudentsInMonth > usersRegisteredInThisMonth
		},
		[usersRes]
	)
}

// Возвращает булево значение можно ли создать ещё одного менеджера или куратора // maxManagers / maxCourators
function useCanCreateNewManagerOrCurator(role: EntityTypes.UserRole): boolean {
	const restrictions = useGetRestrictions()
	if (!restrictions) return false

	const getUsersParams: UserApiTypes.GetUsersParams = {
		filter: {
			roles: [role],
			isDeleted: false,
		},
	}

	const { data: usersRes } = userQuery.getUsers(getUsersParams).useQuery()

	return useMemo(
		function () {
			if (!usersRes) return false

			if (role === EntityTypes.UserRole.CURATOR) {
				return restrictions.maxCourators > usersRes.data.length
			} else if (role === EntityTypes.UserRole.MANAGER) {
				return restrictions.maxManagers > usersRes.data.length
			}

			return false
		},
		[usersRes]
	)
}

// Возвращает булево значение можно ли делать интеграцию с платёжными системами // ownPayments
function useCanIntegrateWithPaySystems(): boolean {
	const restrictions = useGetRestrictions()
	if (!restrictions) return false

	return restrictions.ownPayments
}

// Возвращает булево значение можно ли использовать генерирование текста через ChatGPT. // aiGeneration
function useCanUseAiGeneration(): boolean {
	const restrictions = useGetRestrictions()
	if (!restrictions) return false

	return restrictions.aiGeneration
}

// Возвращает булево значение можно ли использовать конструктор одностраничников. // siteConstructor
function useCanUseSiteConstructor(): boolean {
	const restrictions = useGetRestrictions()
	if (!restrictions) return false

	return restrictions.siteConstructor
}

// Возвращает булево значение можно ли использовать шаблоны уроков. // lessonTemplate
function useCanUseLessonTemplate(): boolean {
	const restrictions = useGetRestrictions()
	if (!restrictions) return false

	return restrictions.lessonTemplate
}

// Возвращает булево значение можно ли использовать систему управления. // crm
function useCanUseCRM(): boolean {
	const restrictions = useGetRestrictions()
	if (!restrictions) return false

	return restrictions.crm
}

// Возвращает булево значение можно ли начинать прямые эфиры. // liveLessons
function useCanUseLiveLessons(): boolean {
	// Эфиры можно делать на любом тарифе
	return true
}

// Возвращает булево значение можно ли использовать доску на прямом эфире. // liveWhaiteboard
function useCanUseLiveWhiteboard(): boolean {
	const restrictions = useGetRestrictions()
	if (!restrictions) return false

	return restrictions.liveWhaiteboard
}

// Возвращает булево значение можно ли сохранить историю доски. // liveStoreWhiteboard
function useCanSaveLiveWhiteboardHistory(): boolean {
	const restrictions = useGetRestrictions()
	if (!restrictions) return false

	return restrictions.liveStoreWhiteboard
}

// Возвращает булево значение можно ли использовать наклейки для доски на прямом эфире. // liveWhiteboardStickers
function useCanUseLiveWhiteboardStickers(): boolean {
	const restrictions = useGetRestrictions()
	if (!restrictions) return false

	return restrictions.liveStoreWhiteboard
}

// Возвращает булево значение можно ли записывать прямой эфир. // liveLessonRecord
function useCanRecordLive(): boolean {
	const restrictions = useGetRestrictions()
	if (!restrictions) return false

	return restrictions.liveWhiteboardStickers
}

// Возвращает булево значение можно ли использовать диалоги на прямом эфире. // liveChat
function useCanUseLiveChat(): boolean {
	const restrictions = useGetRestrictions()
	if (!restrictions) return false

	return restrictions.liveChat
}

// Возвращает булево значение можно ли использовать интеграцию с Ютубом на прямом эфире. // liveYoutube
function useCanUseLiveYoutube(): boolean {
	const restrictions = useGetRestrictions()
	if (!restrictions) return false

	return restrictions.liveYoutube
}

// Получение максимального количества дискового пространства для хранения файлов
function useGetMaxFileStorageInGb() {
	const restrictions = useGetRestrictions()
	if (!restrictions) return false

	return restrictions.storageInGb
}

// Получение максимального количества участников на прямом эфире
function useGetLiveMaxParticipants() {
	const restrictions = useGetRestrictions()
	if (!restrictions) return false

	return restrictions.liveMaxParticipants
}

function useGetRestrictions() {
	const userRoles = useGetUserRole()

	if (!userRoles.isAdmin) return null

	return useSystemStore((store) => store.user.tariffPlan?.restrictions)
}
