import { useMutation, useQuery } from 'react-query'
import LiveLessonEntityTypes from '../../types/LiveLessonEntityTypes'
import LiveLessonApiTypes from './liveLessonApiTypes'
import liveLessonRequests from './livelessonRequest'
import { MutationOptions, QueryOptions } from '../common'

export const liveLessonQuery = {
	// Получение списка live-уроков администратором
	getLiveLessons: {
		key: 'getLiveLessons',
		useQuery(
			options: QueryOptions<LiveLessonApiTypes.GetLiveLessons> = {}
		) {
			return useQuery({
				queryKey: [this.key],
				queryFn: liveLessonRequests.getLiveLessons,
				...options,
			})
		},
	},

	// Добавление нового live-урока или обновление существующего если передан id
	createOrUpdateOne: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(
				liveLessonRequests.createOrUpdateLiveLesson,
				options
			)
		},
	},

	// Удаление
	deleteLiveLesson: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(liveLessonRequests.deleteLiveLesson, options)
		},
	},

	// Получение списка live-уроков администратором
	getLiveLessonToken(liveId: number | string) {
		return {
			key: 'getLiveLessonToken-' + liveId,
			useQuery(options: QueryOptions<LiveLessonApiTypes.GetToken> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () =>
						liveLessonRequests.getLiveLessonToken(liveId),
					...options,
				})
			},
		}
	},

	// Получение списка live-уроков администратором
	getLiveLessonIdByLiveLessonEntranceId(liveLessonEntranceId: string) {
		return {
			key: 'getLiveLessonIdByLiveLessonEntranceId',
			useQuery(
				options: QueryOptions<LiveLessonApiTypes.GetLiveLessonIdByLiveLessonEntranceId> = {}
			) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () =>
						liveLessonRequests.getLiveLessonIdByLiveLessonEntranceId(
							liveLessonEntranceId
						),
					...options,
				})
			},
		}
	},

	leaveParticipant: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(liveLessonRequests.leaveParticipant, options)
		},
	},

	getLiveLesson(liveId: number | string) {
		return {
			key: 'getLiveLesson-' + liveId,
			useQuery(
				options: QueryOptions<LiveLessonEntityTypes.LiveLesson> = {}
			) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => liveLessonRequests.getLiveLesson(liveId),
					...options,
				})
			},
		}
	},

	getLiveWhiteboardScene(liveId: number, whiteboardId: string) {
		return {
			key: 'getLiveWhiteboardScene-' + whiteboardId,
			useQuery(
				options: QueryOptions<LiveLessonApiTypes.GetLiveWhiteboardScene> = {}
			) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () =>
						liveLessonRequests.getLiveWhiteboardScene(
							liveId,
							whiteboardId
						),
					...options,
				})
			},
		}
	},

	saveLiveWhiteboardScene: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(
				liveLessonRequests.saveLiveWhiteboardScene,
				options
			)
		},
	},

	getMessageServer: {
		key: 'getMessageServer',
		useQuery(
			options: QueryOptions<LiveLessonApiTypes.GetMessageServer> = {}
		) {
			return useQuery({
				queryKey: [this.key],
				queryFn: liveLessonRequests.getMessageServer,
				...options,
			})
		},
	},

	getStorageItems: {
		key: 'getStorageItems',
		useQuery(
			options: QueryOptions<LiveLessonApiTypes.GetStorageItem[]> = {}
		) {
			return useQuery({
				queryKey: [this.key],
				queryFn: liveLessonRequests.getStorageItems,
				...options,
			})
		},
	},

	createStorageItem: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(liveLessonRequests.createStorageItem, options)
		},
	},

	renameStorageItem: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(liveLessonRequests.renameStorageItem, options)
		},
	},

	deleteStorageItem: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(liveLessonRequests.deleteStorageItem, options)
		},
	},
}
