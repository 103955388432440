import React from 'react'
import { produce } from 'immer'
import { getFormState, setFormState } from '../../common/exerciseFormCommonFunc'
import {
	DropWordsExerciseFormStateType,
	getNewTextFormDataItem,
} from './stateType'
import { getTextObjById } from './check'

/**
 * Обработчик изменения поля «Задание»
 * @param {String} newValue — строка HTML из редактора
 */
export function onChangeTaskInput(newValue: string) {
	const formState = getFormState<DropWordsExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		draft.taskInput.value = newValue
	})

	setFormState(newState)
}

/**
 * Обработчик нажатия на кнопку закрытия блока редактора пропусков
 * @param {Object} event — объект события
 * @param {Number} textBlockId — id блока с полями касаемые текста
 */
export function closeTextBlock(
	event: React.MouseEvent<HTMLElement>,
	textBlockId: number
) {
	const formState = getFormState<DropWordsExerciseFormStateType>()

	const newState = { ...formState }
	newState.texts = [...newState.texts]

	const thisVideoIdx = newState.texts.findIndex(
		(text) => text.id === textBlockId
	)
	newState.texts.splice(thisVideoIdx, 1)

	newState.isFormInvalid = false

	setFormState(newState)
}

/** Хук возвращает обработчик кнопки создания нового текстового-блока */
export function addNewTextBlock() {
	const formState = getFormState<DropWordsExerciseFormStateType>()

	let maxTextBlockId = 0

	formState.texts.forEach((textBlock) => {
		if (textBlock.id > maxTextBlockId) {
			maxTextBlockId = textBlock.id
		}
	})

	const newState = produce(formState, (draft) => {
		draft.texts.push(getNewTextFormDataItem(++maxTextBlockId))
	})

	setFormState(newState)
}

/**
 * Функция устанавливает новый массив неправильных ответов в объект текста
 * @param {Number} textBlockId — id текстового блока
 * @param {Array} newWrongAnswers — массив неправильных слов
 */
export function setWrongAnswers(
	textBlockId: number,
	newWrongAnswers: string[]
) {
	const improvedWrongAnswers = newWrongAnswers.filter(
		(answer) => answer !== ''
	)

	const formState = getFormState<DropWordsExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const textBlock = getTextObjById(draft, textBlockId)
		if (!textBlock) return draft

		textBlock.wrongAnswers = improvedWrongAnswers
	})

	setFormState(newState)
}
