// Страница списка курсов
const studentTrainingsEn = {
	// СТРАНИЦА СО СПИСКОМ КУРСОВ
	// Сообщение Пока не приобретено ни одного курса.i18n
	trainingsPageNoTrainingsPurchased: 'No courses purchased yet.',
	// Сообщение «Произошла ошибка при получении данных.» при попытке получения списка курсов
	trainingsPageErrorWhileGetData: 'Error receiving data.',
	// Заголовок экрана с сообщением про отсутствие курсов
	trainingsPageNoContentHeader: 'No courses',
	// Текст экрана с сообщением про отсутствие курсов
	trainingsPageNoContentText: 'Buy your first course',

	// КАРТОЧКА КУРСА
	// Наклейка со статусом курса для ученика: Скоро начало
	trainingCardStickerSoon: 'Starting soon',
	// Наклейка со статусом курса для ученика: Доступен
	trainingCardStickerAvailable: 'Available',
	// Наклейка со статусом курса для ученика: Скоро начало
	trainingCardStickerFinished: 'Starting soon',
	// Статус курса: Все открытые задания выполнены
	trainingCardStatusAllFinished: 'All open assignments complete',
	// Статус курса: Осталось выполнить
	trainingCardStatusRemainsToComplete: 'Left to complete',
	// Статус курса: Занятия еще не начались
	trainingCardStatusHasNotBegun: 'Classes have not started yet',
	// Статус курса: Курс успешно завершён!
	trainingCardStatusSuccessfulFinished: 'Course finished successfully!',
	// Надпись «Старт занятий» после которой дата начала занятий
	trainingCardTrainingStart: 'Classes start on',
	// Надпись «Курс начнётся после открытия первого урока»
	trainingCardTrainingStartAfterFirstLessonOpening:
		'Course will start after opening the first lesson',
	// Надпись «Следующий урок» после которой дата следующего урока
	trainingCardNextLesson: 'Next lesson',
}

export default studentTrainingsEn
