import { create } from 'zustand'
import { ConfirmFormStoreType } from './storeTypes'

const useConfirmFormStore = create<ConfirmFormStoreType.State>((set) => {
	return {
		isSubmitting: false,
		isDeleted: false,
		formErrors: {},

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useConfirmFormStore
