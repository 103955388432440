import AppUrls from 'parts/constants/pageUrl'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import {
	BuildOutlined,
	DeleteOutlined,
	EditOutlined,
	ScheduleOutlined,
	SettingOutlined,
	TeamOutlined,
} from '@ant-design/icons'
import { UCardBlock } from 'ui/wrappers/UCardBlock/UCardBlock'
import Image from 'ui/Image/Image'
import VerticalList from 'ui/VerticalList/VerticalList'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import NoGroupsInfo from '../NoGroupsInfo/NoGroupsInfo'
import { useGetGoToGroupSettings, useGetOpenGroups } from './fn/openGroups'
import { TrainingGroupsTableController } from 'widgets/groups/trainingGroupsTable/AddGroupModalController'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import { TrainingsStoreType } from '../../TrainingsList/zustand/storeTypes'
import { useDeleteTraining } from './fn/deleteTraining'
import './TrainingCardForAdmin.scss'
import { useGetShowEditTrainingModal } from './fn/editTraining'

type TrainingCardViewProps = {
	rawTraining: TrainingEntityTypes.TrainingAdmin
	trainingData: TrainingsStoreType.TrainingCard // Данные курса
}

// Карточка курса для администратора в списке курсов
function TrainingCardForAdmin(props: TrainingCardViewProps) {
	const { rawTraining, trainingData } = props

	return (
		<UCardBlock extraClass="training-card-admin" shadow>
			<Link
				to={AppUrls.Training(trainingData.id).url}
				className="training-card-admin__content"
			>
				<Image
					src={trainingData.cover}
					form="free"
					extraClass="training-card-admin__cover-wrapper"
				/>
				<div className="training-card-admin__right-content">
					<div>
						<Stats trainingData={trainingData} />
						<h1 className="training-card-admin__header">
							{trainingData.name}
						</h1>
						{trainingData.description && (
							<p className="training-card-admin__description">
								{trainingData.description}
							</p>
						)}
					</div>
					<Buttons
						trainingData={trainingData}
						rawTraining={rawTraining}
					/>
				</div>
			</Link>
			<GroupsTableWrapper
				trainingData={trainingData}
				rawTraining={rawTraining}
			/>
		</UCardBlock>
	)
}

export default TrainingCardForAdmin

type StatsProps = {
	trainingData: TrainingsStoreType.TrainingCard // Данные курса
}

// Количество уроков, групп и учеников
function Stats(props: StatsProps) {
	const { trainingData } = props

	const userRoles = useGetUserRole()

	const statsItems = [
		{ text: trainingData.lessonsStr, icon: <ScheduleOutlined /> },
	]

	if (userRoles.isAdmin) {
		statsItems.push({
			text: trainingData.groupsStr,
			icon: <BuildOutlined />,
		})
		statsItems.push({
			text: trainingData.studentsStr,
			icon: <TeamOutlined />,
		})
	}

	return <VerticalList items={statsItems} />
}

type ButtonsProps = {
	trainingData: TrainingsStoreType.TrainingCard // Данные
	rawTraining: TrainingEntityTypes.TrainingAdmin
}

// Кнопки внизу карточки
function Buttons(props: ButtonsProps) {
	const { trainingData, rawTraining } = props

	const { t } = useTranslation()
	const userRoles = useGetUserRole()

	const setGroupsVisibility = useGetOpenGroups(trainingData.id)
	const goToGroupSettings = useGetGoToGroupSettings(trainingData.id)
	const deleteTraining = useDeleteTraining(trainingData.id)
	const showEditTrainingModal = useGetShowEditTrainingModal(trainingData.id)

	return (
		<div className="training-card-admin__buttons">
			<div className="training-card-admin__left-buttons">
				<Button
					icon={<BuildOutlined />}
					size="small"
					type="link"
					onClick={setGroupsVisibility}
				>
					{t('adminTrainings.trainingCardGroupsButton')}
				</Button>
				{userRoles.isAdminOrManager && (
					<Button
						icon={<SettingOutlined />}
						size="small"
						type="link"
						onClick={goToGroupSettings}
					>
						{t('adminTrainings.trainingCardGroupSettingsButton')}
					</Button>
				)}
			</div>
			<div>
				<Button
					icon={<EditOutlined />}
					size="small"
					type="text"
					onClick={showEditTrainingModal}
				>
					Редактировать
				</Button>
				{userRoles.isAdmin && (
					<Button
						icon={<DeleteOutlined />}
						danger
						size="small"
						type="text"
						onClick={deleteTraining}
					>
						{t('adminTrainings.trainingCardDeleteTrainingButton')}
					</Button>
				)}
			</div>
		</div>
	)
}

type GroupsTableWrapperProps = {
	rawTraining: TrainingEntityTypes.TrainingAdmin
	trainingData: TrainingsStoreType.TrainingCard // Данные курса
}

function GroupsTableWrapper(props: GroupsTableWrapperProps) {
	const { rawTraining, trainingData } = props

	if (!trainingData.isGroupsTableOpen) {
		return null
	}

	return (
		<div className="training-card-admin__groups-wrapper">
			<GroupsTable
				trainingId={trainingData.id}
				rawTraining={rawTraining}
			/>
		</div>
	)
}

type GroupsProps = {
	trainingId: number
	rawTraining: TrainingEntityTypes.TrainingAdmin
}

function GroupsTable(props: GroupsProps) {
	const { trainingId, rawTraining } = props

	const groupsTableRouter = TrainingGroupsTableController.router(
		trainingId,
		'trainingCard',
		false,
		rawTraining.groups
	)

	if (groupsTableRouter.status == 'noGroups') {
		return <NoGroupsInfo trainingId={trainingId} />
	} else {
		return groupsTableRouter.element
	}
}
