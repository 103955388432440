import { FormInstance, message } from 'antd'
import { AxiosError } from 'axios'
import UserApiTypes from 'parts/requests/user/userApiTypes'
import { StaffTableController } from '../../../StaffTableController'
import { AddEmployeeModalController } from '../../AddEmployeeModalController'
import { FieldNames, FormValuesType } from './form'
import EntityTypes from 'parts/types/EntityTypes'
import userRequests from 'parts/requests/user/userRequest'
import { generatePassword } from 'parts/utils/string'
import { CascaderOption } from 'parts/types/miscTypes'
import groupRequests from 'parts/requests/group/groupsRequest'
import GroupsApiTypes from 'parts/requests/group/groupsApiTypes'
import ServerTypes from 'parts/types/ServerTypes'
import useAddEmployeeModalStore from '../zustand/store'

export function useGetAddEmployee(form: FormInstance) {
	const updateDataInStaffTable = StaffTableController.useGetUpdate()

	const [updateModalStore, trainingOptions] = useAddEmployeeModalStore(
		(state) => [state.updateStore, state.trainingOptions]
	)

	return async function (values: FormValuesType) {
		try {
			// Поставить статус загрузки
			updateModalStore({ isSubmitting: true })

			const addUserRes = await runCreateUserRequest(values)
			const user = addUserRes.data as UserApiTypes.AddUser

			// Получить идентификаторы групп, в которые нужно назначить куратора
			const curatorGroupsIds = getCuratorGroupsIds(
				values[FieldNames.AddToGroups],
				trainingOptions
			)

			// Назначить сотрудника группе
			for (let groupId of curatorGroupsIds) {
				await assignTrainingGroupToCurator(
					groupId,
					user.id,
					values.role
				)
			}

			// Если отметили флаг «Выдать доступ в личный кабинет», то отправить запрос на выдачу доступа
			if (values[FieldNames.GetAccess]) {
				sendAccess(user.id)
			}

			// Закрыть модальное окно
			AddEmployeeModalController.close()

			// Запустить скачивание нового массива сотрудников
			updateDataInStaffTable()

			message.success('Сотрудник добавлен.')
			form.resetFields()
		} catch (err) {
			const error = err as AxiosError<ServerTypes.ErrorResponse>

			if (error.response?.data) {
				// Поставить данные ошибки в Состояние, чтобы показать их в форме
				updateModalStore({ formErrors: error.response.data })
			}

			message.error('Не удалось добавить сотрудника.')
		} finally {
			// Убрать статус загрузки
			updateModalStore({ isSubmitting: false })
		}
	}
}

/**
 * Функция создаёт пользователя, который отдельным запросом будет сотрудником.
 * @param {Object} formValues — данные введённые в форму
 */
async function runCreateUserRequest(formValues: FormValuesType) {
	const addUserReqBody: UserApiTypes.AddUserDto = {
		avatar: '', // Это поле пустое потому что нет поля загрузки фотографии пользователя
		email: formValues[FieldNames.Email], // 'user@mail.ru'
		password: generatePassword(), // '123456'
		role: formValues.role as EntityTypes.UserRole,
		firstName: formValues[FieldNames.Name], // 'John'
		lastName: formValues[FieldNames.Surname], // 'Doe'
	}

	const addUserRes = await userRequests.addUser(addUserReqBody)

	if (addUserRes.status !== 201) {
		throw new Error(
			'Пользователь или существует или произошла неизвестная ошибка'
		)
	}

	return addUserRes
}

/**
 * Функция возвращает идентификаторы групп, на которых нужно подписать куратора.
 * @param {Array} trainingsGroupsIds — массив выбранных идентификаторов курсов и групп
 * @param {Array} trainingOptions — все курсы и их группы
 */
function getCuratorGroupsIds(
	trainingsGroupsIds: [string, string][],
	trainingOptions: CascaderOption[]
): number[] {
	const groupsIdx: number[] = []

	if (!trainingsGroupsIds) return groupsIdx

	/** В trainingsGroupsIds есть массив массивов вида [[2][3, 44][3, 45]]
	 * Первое число показывает идентификатор курса, а второе идентификатор группы.
	 * Но второе число может отсутствовать если выбрали все группы курса.
	 * В этом случае нужно получить все группы курса из массива trainingOptions.
	 */
	trainingsGroupsIds.forEach((trainingGroups) => {
		if (trainingGroups[1]) {
			groupsIdx.push(parseInt(trainingGroups[1]))
		} else {
			const trainingId = trainingGroups[0]

			const trainingWithGroups = trainingOptions.find((training) => {
				return training.value === trainingId
			})

			if (trainingWithGroups && trainingWithGroups.children) {
				for (let group of trainingWithGroups.children) {
					groupsIdx.push(parseInt(group.value))
				}
			}
		}
	})

	return groupsIdx
}

/**
 * Добавляет сотрудника в группу
 * @param {Number} groupId — идентификатор группы, в которую нужно добавить куратора
 * @param {Number} userId — id куратора, которого нужно добавить в группу
 * @param {String} role — роль пользователя
 */
async function assignTrainingGroupToCurator(
	groupId: number,
	userId: number,
	role: EntityTypes.UserRole
) {
	const dto: GroupsApiTypes.AddUserToGroupDto = {
		groupId,
		userId,
		role: role,
	}

	const response = await groupRequests.addUserToGroup(dto)

	if (response.status !== 201) {
		message.error('Не удалось добавить сотрудника в группу')
	}
}

/**
 * Запрос добавляет куратору созданного ученика
 * @param {Number} userId — id ученика которому нужно выдать доступ
 */
export async function sendAccess(userId: number) {
	const response = await userRequests.sendAccess({ userId })

	if (response.status !== 200) {
		throw new Error('Не удалось дать доступ')
	}
}
