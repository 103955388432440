import AudioExerciseView from './audio/AudioExerciseView/AudioExerciseView'
import { ExerciseContainerType } from './common/ExerciseContainer/ExerciseContainer'
import {
	SaveExerciseChangesMadeByUser,
	ExerciseChangesDataMadeByUser,
} from './common/useLiftViewDuration'
import DescriptionImageExerciseView from './descriptionImage/DescriptionImageExerciseView/DescriptionImageExerciseView'
import DropWordExerciseViewNew from './dropWordNew/DropWordExerciseView/DropWordExerciseViewNew'
import GapsInWordsExerciseViewNew from './gapsInWordsNew/GapsInWordsView/GapsInWordsExerciseViewNew'
import ImageExerciseView from './image/ImageExerciseView/ImageExerciseView'
import TestExerciseViewNew from './testExerciseNew/TestExerciseView/TestExerciseViewNew'
import TextExerciseViewNew from './textExercise/TextExerciseView/TextExerciseViewNew'
import TrueOrFalseExerciseViewNew from './trueOrFalse/TrueOrFalseExerciseView/TrueOrFalseExerciseViewNew'
import VideoExerciseView from './video/VideoExerciseView/VideoExerciseView'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import ChooseWordExerciseViewNew from './сhooseWordNew/ChooseWordViewNew/ChooseWordExerciseViewNew'

type SwitchExerciseNewProps = {
	// Для какого типа страницы предназначается обёртка упражнения:
	// constructorPage — страница конструктора. Будут скрыты кнопки перехода по упражнениям и кнопки снизу
	// exercisePage — страница упражнения для ученика. Будет полный набор элементов.
	exerciseContainerType: ExerciseContainerType
	exercise: TrainingEntityTypes.ExerciseItem
	// Адрес предыдущего и следующего упражнения
	prevExerciseUrl?: string
	nextExerciseUrl?: string
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

// Вывод упражнения урока для ученика
function ExerciseSwitcherNew(props: SwitchExerciseNewProps) {
	const {
		exerciseContainerType,
		exercise,
		prevExerciseUrl,
		nextExerciseUrl,
		saveExerciseChangesMadeByUser,
		exerciseRestoreData,
	} = props

	switch (exercise?.type) {
		case 'video':
			return (
				<VideoExerciseView
					exerciseContainerType={exerciseContainerType}
					exercise={exercise}
					prevExerciseUrl={prevExerciseUrl}
					nextExerciseUrl={nextExerciseUrl}
				/>
			)
		case 'audio':
			return (
				<AudioExerciseView
					exerciseContainerType={exerciseContainerType}
					exercise={exercise}
					prevExerciseUrl={prevExerciseUrl}
					nextExerciseUrl={nextExerciseUrl}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
					exerciseRestoreData={exerciseRestoreData}
				/>
			)
		case 'image':
			return (
				<ImageExerciseView
					exerciseContainerType={exerciseContainerType}
					exercise={exercise}
					prevExerciseUrl={prevExerciseUrl}
					nextExerciseUrl={nextExerciseUrl}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
					exerciseRestoreData={exerciseRestoreData}
				/>
			)
		case 'text':
			return (
				<TextExerciseViewNew
					exerciseContainerType={exerciseContainerType}
					exercise={exercise}
					prevExerciseUrl={prevExerciseUrl}
					nextExerciseUrl={nextExerciseUrl}
				/>
			)
		case 'test':
			return (
				<TestExerciseViewNew
					exerciseContainerType={exerciseContainerType}
					exercise={exercise}
					prevExerciseUrl={prevExerciseUrl}
					nextExerciseUrl={nextExerciseUrl}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
					exerciseRestoreData={exerciseRestoreData}
				/>
			)
		case 'trueOrFalse':
			return (
				<TrueOrFalseExerciseViewNew
					exerciseContainerType={exerciseContainerType}
					exercise={exercise}
					prevExerciseUrl={prevExerciseUrl}
					nextExerciseUrl={nextExerciseUrl}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
					exerciseRestoreData={exerciseRestoreData}
				/>
			)
		case 'gapsInWords':
			return (
				<GapsInWordsExerciseViewNew
					exerciseContainerType={exerciseContainerType}
					exercise={exercise}
					prevExerciseUrl={prevExerciseUrl}
					nextExerciseUrl={nextExerciseUrl}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
					exerciseRestoreData={exerciseRestoreData}
				/>
			)
		case 'chooseWord':
			return (
				<ChooseWordExerciseViewNew
					exerciseContainerType={exerciseContainerType}
					exercise={exercise}
					prevExerciseUrl={prevExerciseUrl}
					nextExerciseUrl={nextExerciseUrl}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
					exerciseRestoreData={exerciseRestoreData}
				/>
			)
		case 'dropWords':
			return (
				<DropWordExerciseViewNew
					exerciseContainerType={exerciseContainerType}
					exercise={exercise}
					prevExerciseUrl={prevExerciseUrl}
					nextExerciseUrl={nextExerciseUrl}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
					exerciseRestoreData={exerciseRestoreData}
				/>
			)
		case 'descriptionImg':
			return (
				<DescriptionImageExerciseView
					exerciseContainerType={exerciseContainerType}
					exercise={exercise}
					prevExerciseUrl={prevExerciseUrl}
					nextExerciseUrl={nextExerciseUrl}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
					exerciseRestoreData={exerciseRestoreData}
				/>
			)
		default:
			return null
	}
}

export default ExerciseSwitcherNew
