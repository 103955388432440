import { useNavigate } from 'react-router-dom'
import { MouseEventHandler } from 'react'
import { Button, Input, Space, Tooltip } from 'antd'
import {
	FieldTimeOutlined,
	CalendarOutlined,
	EditOutlined,
	VideoCameraOutlined,
	CopyOutlined,
} from '@ant-design/icons'
import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'
import { UCardBlock } from '../../../ui/wrappers/UCardBlock/UCardBlock'
import Avatar from '../../../ui/Avatar/Avatar'
import { getDateFromStr, getTimeFromStr } from 'parts/utils/time'
import { getRusNounByNumber, getUserName } from 'parts/utils/string'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import { useGetChangeModalVisibility } from '../../../pages/liveLessons/rooms/CreateOrEditLiveRoomModal/zustand/storeManager'
import { getDefinedTimeZoneTime } from './fn/convertTimeZone'
import AppUrls from 'parts/constants/pageUrl'
import './LivelessonCardAdmin.scss'
import EntityTypes from 'parts/types/EntityTypes'
import { useGetPushTextToClipboard } from 'pages/liveLessons/rooms/CreateOrEditLiveRoomModal/fn/formFieldsFunc'
import { GrayBlock } from 'ui/wrappers'

// Данные live-урока
type LivelessonCardViewProps = {
	livelessonData: LiveLessonEntityTypes.LiveLesson
}

// Карточка live-урока для администратора
function LivelessonCardAdmin(props: LivelessonCardViewProps) {
	const { livelessonData } = props

	//	конвертирование времени из установленного час.пояса в локальный
	const definedTimeZoneTime = getDefinedTimeZoneTime(
		livelessonData.scheduledFor,
		livelessonData.timeZone
	)
	const day = getDateFromStr(definedTimeZoneTime)
	const time = getTimeFromStr(definedTimeZoneTime)

	const openEditForm = useGetChangeModalVisibility(true, livelessonData.id)

	return (
		<UCardBlock extraClass="livelesson-card-admin" shadow>
			<div className="livelesson-card-admin__content">
				<CardCuratorName
					avatarSrc={livelessonData.teacher.avatar}
					firstName={livelessonData.teacher.firstName}
					lastName={livelessonData.teacher.lastName}
				/>
				<CardTitle titleText={livelessonData.title} />
				<CardDateTime scheduledDay={day} scheduledTime={time} />
				<CardLink link={livelessonData.link} />
				<CardStudents invitedStudents={livelessonData.participants} />
				<CardButtons
					clickHandler={openEditForm}
					liveId={livelessonData.id}
				/>
			</div>
		</UCardBlock>
	)
}

export default LivelessonCardAdmin

type CardCuratorNameProps = {
	avatarSrc: string | undefined
	firstName: string | undefined
	lastName: string | undefined
}

function CardCuratorName(props: CardCuratorNameProps) {
	const { avatarSrc, firstName, lastName } = props
	return (
		<div className="livelesson-card-admin__curator-wrapper">
			<Avatar src={avatarSrc} width={20} />
			<span className="livelesson-card-admin__curator">
				{firstName} {lastName}
			</span>
		</div>
	)
}
type CardTitleProps = {
	titleText: string
}

function CardTitle(props: CardTitleProps) {
	const { titleText } = props
	return (
		<h1 className="livelesson-card-admin__header">
			{/* html-атрибут title - тултип с полным названием урока, если слишком длинное и не влезло */}
			<div title={titleText}>
				{titleText && titleText.length > 50
					? titleText.slice(0, 50) + '...'
					: titleText}
			</div>
		</h1>
	)
}

type CardDateTimeProps = {
	scheduledDay: string
	scheduledTime: string
}
function CardDateTime(props: CardDateTimeProps) {
	const { scheduledDay, scheduledTime } = props
	return (
		<div className="livelesson-card-admin__time-wrapper">
			<div>
				<CalendarOutlined />
				<span className="livelesson-card-admin__time-item">
					{scheduledDay}
				</span>
			</div>
			<div>
				<FieldTimeOutlined />
				<span className="livelesson-card-admin__time-item">
					{scheduledTime}
				</span>
			</div>
		</div>
	)
}

type CardStudentsProps = {
	invitedStudents: EntityTypes.User[]
}
function CardStudents(props: CardStudentsProps) {
	const { invitedStudents } = props
	return (
		<div className="livelesson-card-admin__students-wrapper">
			<div className="livelesson-card-admin__students-avatars">
				{invitedStudents.slice(0, 3).map((student) => {
					return (
						<Tooltip key={student.id} title={getUserName(student)}>
							<div>
								<Avatar src={student.avatar} width={20} />
							</div>
						</Tooltip>
					)
				})}
			</div>
			{invitedStudents.length > 3 && (
				<p className="livelesson-card-admin__students">
					{' '}
					+
					<span>
						{invitedStudents.length - 3}{' '}
						{getRusNounByNumber(
							invitedStudents.length - 3,
							'участник',
							'участника',
							'участников'
						)}
					</span>
				</p>
			)}
		</div>
	)
}

type CardButtonsProps = {
	clickHandler: MouseEventHandler
	liveId: number
}
function CardButtons(props: CardButtonsProps) {
	const { clickHandler, liveId } = props
	const userRoles = useGetUserRole()
	const navigate = useNavigate()

	const handleGoLesson = (livelessonId: number) => {
		navigate(AppUrls.LiveLesson(livelessonId).url)
	}
	return (
		<div className="livelesson-card-admin__buttons-wrapper">
			{userRoles.isAdminOrManager && (
				<Button onClick={clickHandler}>
					<EditOutlined />
				</Button>
			)}
			<Button type="primary" block onClick={() => handleGoLesson(liveId)}>
				<VideoCameraOutlined />
				Перейти в комнату
			</Button>
		</div>
	)
}

// Поле со ссылкой
function CardLink({ link }: { link: string }) {
	const copyLinkToLivelesson = useGetPushTextToClipboard(link)

	return (
		<div className="livelesson-card-admin__lesson-link">
			<GrayBlock>
				<div className="livelesson-card-admin__link-label">
					Ссылка на урок
				</div>
				<Space.Compact style={{ width: '100%' }}>
					<Input value={link} />
					<Button onClick={copyLinkToLivelesson}>
						<CopyOutlined />
					</Button>
				</Space.Compact>
			</GrayBlock>
		</div>
	)
}
