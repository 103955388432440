import { stateManager } from '../../stateManager/stateManager'
import useExerciseStore from '../../zustand/exerciseState'

export function useAfterDoneExercise() {
	const passedStatementsNum = stateManager.useGetPassedStatementsNum()
	const saveStoreForRestore = useExerciseStore(
		(store) => store.saveStoreForRestore
	)
	const statistics = useExerciseStore((store) => store.statistics)
	const doneCounter = useExerciseStore((store) => store.doneCounter)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		const newStatistics = [
			...statistics,
			{ passedQuestions: passedStatementsNum },
		]

		updateStore({
			showResult: true,
			statistics: newStatistics,
			doneCounter: doneCounter + 1,
			saveStoreForRestore: saveStoreForRestore + 1,
		})
	}
}
