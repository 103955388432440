import { create } from 'zustand'
import ExerciseStateType from './ExerciseStateType'

const useExerciseStore = create<ExerciseStateType.State>((set) => {
	return {
		currentStatementIdx: 0,
		statements: [],

		readyToShowResult: false,
		doneCounter: 0,
		showResult: false,
		durationsInSeconds: [],
		statistics: [],
		saveStoreForRestore: 0,

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useExerciseStore
