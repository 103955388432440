import { useCallback, useEffect } from 'react'
import useMenuStore from 'app/menu/zustand/menuStore'

/** Хук скрывает телефонное меню если ширина браузера больше допустимого */
export function useAutoCloseMobileMenu() {
	const closeMobileMenu = useGetCloseMobileMenuFn()

	useEffect(function () {
		window.addEventListener('resize', () => {
			autoCloseMobileMenu(closeMobileMenu)
		})
	}, [])
}

/** Хук возвращает функцию, закрывающую телефонное меню */
export function useGetCloseMobileMenuFn() {
	return useCallback(function () {
		// Закрыть телефонное меню
		useMenuStore.getState().updateStore({ isMobileMenuOpen: false })
	}, [])
}

// Функция проверяет ширину браузера и если она слишком широкая для телефонного меню, то запускает функцию его закрывающая
function autoCloseMobileMenu(closeMobileMenu: () => void) {
	if (document.documentElement.clientWidth > 300) {
		closeMobileMenu()
	}
}
