import { Button } from 'antd'
import { useGetOnOkHandler, useGetSetModalVisible } from './fn/modal'
import useDeleteModalStore from './store/store'
import ModalWithForm from '../../../ui/ModalWithForm/ModalWithForm'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'
import {
	UFormNewBottomButtonsAfterGray,
	UFormNewButtonsContainer,
} from '../../../ui/UForm/UForm'
import './DeleteModal.scss'

// Универсальное модальное окно удаления сущности
function DeleteModal() {
	const isOpen = useDeleteModalStore((state) => state.isOpen)
	const header = useDeleteModalStore((state) => state.header)
	const closeModal = useGetSetModalVisible(false)

	return (
		<ModalWithForm title={header} open={isOpen} onCancel={closeModal}>
			<GrayBlock>
				<WarningText />
			</GrayBlock>
			<Bottom />
		</ModalWithForm>
	)
}

export default DeleteModal

// Текст предупреждения
function WarningText() {
	const text = useDeleteModalStore((state) => state.text)

	return <p className="universal-delete-modal__text">{text}</p>
}

// Кнопки подтверждения и закрытия окна
function Bottom() {
	const isSubmitting = useDeleteModalStore((state) => state.isSubmitting)
	const onOkHandler = useGetOnOkHandler()

	const closeModal = useGetSetModalVisible(false)

	return (
		<UFormNewBottomButtonsAfterGray>
			<div />
			<UFormNewButtonsContainer>
				<Button onClick={closeModal}>Отмена</Button>
				<Button
					type="primary"
					danger
					htmlType="submit"
					disabled={isSubmitting}
					loading={isSubmitting}
					onClick={onOkHandler}
				>
					Удалить
				</Button>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
