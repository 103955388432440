// import useLandingConstructorStore from '../../../zustand/store'
import { useGetAddNewBlock } from 'pages/landing/landingConstructor/sections/faqSection/faqBlocksManager/addNewBlock'
import { useGetDeleteBlock } from 'pages/landing/landingConstructor/sections/faqSection/faqBlocksManager/deleteBlock'
import { LandingTypes } from 'parts/types/LandingTypes'
import { toggleBlockVisibility } from 'pages/landing/landingConstructor/sections/faqSection/faqBlocksManager/toggleVisibility'

// Объект с методами манипулирования блоков с описанием пользователя, которому подойдёт предлагаемый курс
const faqBlocksManager = {
	// Добавление нового блока
	useGetAddNewBlock,

	// Удаление существующего блока
	useGetDeleteBlock,

	toggleBlockVisibility,

	/**
	 * Возвращает индекс по которому находится блок с переданным идентификатором
	 * @param blocks — массив блоков с описанием пользователя которому подойдёт курс
	 * @param blockId — id блока, у которого нужно получить индекс.
	 */
	getBlockIdx(
		blocks: LandingTypes.FaqBlock[],
		blockId: null | number
	): number {
		if (!blockId) return 0

		return blocks.findIndex((block) => block.id === blockId)
	},
}

export default faqBlocksManager
