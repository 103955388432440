import { Button, Form, Input } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import ModalWithForm from 'ui/ModalWithForm/ModalWithForm'
import {
	UFormNewBottomButtonsAfterGray,
	UFormNewButtonsContainer,
	UFormNewRow,
} from 'ui/UForm/UForm'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'
import { FieldNames, useGetOnSearchInputChange } from './fn/form'
import StaffList from './StaffList'
import { useGetOnSubmit } from './fn/onSubmit'
import { useGetSetVisibilityToAddStuffModal } from './fn/openCloseModal'
import useAddStaffInGroupModalStore from './zustand/store'
import { useFillInStore } from './fn/fillInStore'
import './AddStaffInGroupModal.scss'

const { Search } = Input

// Модальное окно добавления кураторов в группу
function AddStuffInGroupModal() {
	const { t } = useTranslation()

	useFillInStore()

	const [form] = Form.useForm()

	const onSubmit = useGetOnSubmit()
	const modalState = useAddStaffInGroupModalStore((state) => state)
	const closeModal = useGetSetVisibilityToAddStuffModal(false)

	return (
		<ModalWithForm
			title="Добавление сотрудников"
			open={modalState.isOpen}
			onCancel={closeModal}
		>
			<Form
				form={form}
				name="search"
				layout="vertical"
				onFinish={onSubmit}
			>
				<GrayBlock>
					<SearchForm />
					<StaffList />
				</GrayBlock>
				<Bottom />
			</Form>
		</ModalWithForm>
	)
}

export default AddStuffInGroupModal

// Фильтрация по именам сотрудников
function SearchForm() {
	const onSearchInputChange = useGetOnSearchInputChange()

	return (
		<UFormNewRow>
			<Form.Item name={FieldNames.Search}>
				<Search
					placeholder="Поиск по имени"
					allowClear
					onChange={onSearchInputChange}
					prefix={<UserOutlined />}
				/>
			</Form.Item>
		</UFormNewRow>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
// Кнопки подтверждения и закрытия окна
function Bottom() {
	const isSubmitting = useAddStaffInGroupModalStore(
		(state) => state.isSubmitting
	)
	const closeModal = useGetSetVisibilityToAddStuffModal(false)

	const addCheckedStuff = useGetOnSubmit()

	return (
		<UFormNewBottomButtonsAfterGray>
			<div />
			<UFormNewButtonsContainer>
				<Button onClick={closeModal}>Отмена</Button>
				<Button
					type="primary"
					htmlType="submit"
					disabled={isSubmitting}
					loading={isSubmitting}
					onClick={addCheckedStuff}
				>
					Добавить
				</Button>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
