import { useParams } from 'react-router-dom'
import { RouterVarNames } from 'app/routes/special/otherRoutes'
import { HeaderRouteType } from 'ui/pageHeader/PageHeader/fn/types'
import useExerciseHeaderStudentStore from '../zustand/store'
import AppUrls from 'parts/constants/pageUrl'

/** Возвращает массив для формирования хлебных крошек выше заголовка страницы упражнения */
export function useGetBreadcrumbs(): HeaderRouteType[] {
	const params = useParams()
	const trainingIdStr = params[RouterVarNames.TrainingId]!

	const training = useExerciseHeaderStudentStore(
		(state) => state.trainingStudent
	)

	return [
		{
			path: AppUrls.Trainings().url,
			breadcrumbName: AppUrls.Trainings().name,
		},
		{
			path: AppUrls.Training(trainingIdStr).url,
			breadcrumbName: training.trainingName,
		},
	]
}
