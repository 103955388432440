import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { AddGroupModalController } from 'widgets/groups/addGroupModal/AddGroupModalController'

// Кнопка открывающая окно добавления группы
function AddGroupButton() {
	const { t } = useTranslation()

	return (
		<Button
			type="primary"
			icon={<PlusOutlined />}
			onClick={AddGroupModalController.useGetOpen}
		>
			{t('adminTraining.addGroupButton')}
		</Button>
	)
}

export default AddGroupButton
