import useStudentsStore from '../../studentsTableAdmin/zustand/store'

/** Возвращает функцию получающую номер страницы с которой данные должны отображаться */
export function useGetChangePageNumber() {
	const updateStore = useStudentsStore((store) => store.updateStore)

	return function (newPageNumber: number) {
		updateStore({ currentPageNum: newPageNumber })
	}
}
