import { useCallback, useEffect, useState } from 'react'
import UrlService from 'parts/services/UrlService'
import { useIsFirstRender } from 'parts/utils/hooks/hooks'
import { SaveExerciseChangesMadeByUser } from '../../../common/useLiftViewDuration'
import useExerciseStore, {
	ExerciseStateType,
} from '../../zustand/exerciseState'

type RestoreDataType = {
	blocksGaps: BlocksGapsRestore
	store: Omit<ExerciseStateType.State, 'updateStore'>
}

// Текстовый блок — это массив строк.
// Поэтому текстовые блоки — это массивы массивов строк
type BlocksGapsRestore = string[][]

/**
 * Возвращает обработчик потерей фокуса обёртки текстовых блоков в упражнении.
 * Срабатывает при потере фокуса одного из редактируемых элементов.
 * После формируется объект с введённым текстом в редактируемые элементы и сохраняется на сервере
 * чтобы при открытии упражнения в следующий раз в редактируемые элементы был вставлен введённый текст.
 */
export function useGetBlurContentHandler(
	$generalWrapper: null | HTMLDivElement,
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
) {
	const exerciseId = UrlService.useGetExerciseId()
	const store = useExerciseStore((store) => store)

	return useCallback(
		function () {
			if (!$generalWrapper || !saveExerciseChangesMadeByUser) return

			const restoreData = createRestoreData($generalWrapper, store)

			saveExerciseChangesMadeByUser(restoreData)
		},
		[exerciseId]
	)
}

export function useSaveStateOnServerForRestore(
	$generalWrapper: null | HTMLDivElement,
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
) {
	// Задержка, чтобы не сохранял данные после открытия страницы
	const isFirstRender = useIsFirstRender(2000)
	const store = useExerciseStore((store) => store)

	useEffect(
		function () {
			if (
				isFirstRender ||
				!$generalWrapper ||
				!saveExerciseChangesMadeByUser
			)
				return

			const restoreData = createRestoreData($generalWrapper, store)

			// Передать в функцию, которая сохранит данные на сервере.
			saveExerciseChangesMadeByUser(restoreData)
		},
		[store.saveStoreForRestore]
	)
}

function createRestoreData(
	$generalWrapper: HTMLDivElement,
	store: ExerciseStateType.State
): RestoreDataType {
	const { updateStore, ...storeClone } = store

	return {
		blocksGaps: getSlotsRestoreObj($generalWrapper),
		store: storeClone,
	}
}

function getSlotsRestoreObj($generalWrapper: HTMLDivElement) {
	const blocksGapsRestoreObj: BlocksGapsRestore = []

	for (let i = 0; i < $generalWrapper.children.length; i++) {
		// Блок текста с дырками
		const $textBlock = $generalWrapper.children[i]

		// Элементы, в которые можно вписать текст
		const $gaps: NodeListOf<HTMLElement> = $textBlock.querySelectorAll(
			'.exercise-gaps-content__gap'
		)

		for (let j = 0; j < $gaps.length; j++) {
			if (!blocksGapsRestoreObj[i]) {
				blocksGapsRestoreObj.push([])
			}

			blocksGapsRestoreObj[i].push($gaps[j].innerText)
		}
	}

	return blocksGapsRestoreObj
}

// ==========================

export function useRestoreExercise(
	exerciseRestoreDataPromise: Promise<RestoreDataType>
) {
	const $generalWrapper: null | HTMLDivElement = document.querySelector(
		'.exercise-gaps-content__text-blocks'
	)

	const textBlocks = useExerciseStore((store) => store.textBlocks)
	const updateStore = useExerciseStore((store) => store.updateStore)

	const [textBlocksIsReady, setTextBlocksIsReady] = useState(false)

	useEffect(
		function () {
			if (!textBlocks) return

			setTextBlocksIsReady(true)
		},
		[textBlocks]
	)

	useEffect(
		function () {
			if (!textBlocksIsReady || !$generalWrapper) return

			exerciseRestoreDataPromise.then((restoreData) => {
				if (!restoreData?.store) return

				// Расставить текст по слотам изображений
				// Автоматически перетащить кнопки в те места, куда они были перетащены в прошлый раз.
				setWordsInSlots($generalWrapper, restoreData.blocksGaps)

				updateStore(restoreData.store)
			})
		},
		[textBlocksIsReady]
	)
}

/**
 *
 * @param $generalWrapper
 * @param gapTexts
 */
function setWordsInSlots(
	$generalWrapper: HTMLDivElement,
	gapTexts: string[][]
) {
	for (let i = 0; i < $generalWrapper.children.length; i++) {
		// Блок текста с дырками
		const $textBlock = $generalWrapper.children[i]

		// Элементы, в которые можно вписать текст
		const $gaps = $textBlock.querySelectorAll('.exercise-gaps-content__gap')

		// Перебрать элементы дырок
		for (let j = 0; j < $gaps.length; j++) {
			// Пропустить если в данных нет текста для этой дырки
			if (!gapTexts[i] || !gapTexts[i][j]) continue

			// Поставить в дырку сохранённый текст
			$gaps[j].textContent = gapTexts[i][j]
		}
	}
}
