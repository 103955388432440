import { useSaveScroll } from './saveScroll'
import { useGetScrollDataFromStoreAndSaveInLocalStorage } from './saveScrollInLocalStorage'
import { useSetScrollAfterEnterToPage } from './setScroll'

export const ScrollDataLSPropName = 'scrollData'

/** Хук запускает необходимые функции для наблюдения за прокруткой на страницах,
 * сохранению данных в Хранилище и в LocalStorage
 * и восстановлению прокрутки при переходах по страницам.
 */
export function useScrollManager() {
	useSaveScroll()
	useSetScrollAfterEnterToPage()
	useGetScrollDataFromStoreAndSaveInLocalStorage()
}
