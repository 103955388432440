import React, { useState } from 'react'
import { Button, Form, Select } from 'antd'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'
import { DeleteOutlined } from '@ant-design/icons'
import { UFormNewRow } from 'ui/UForm/UForm'
import ToggleBlock from 'ui/ToggleBlock/ToggleBlock'
import PaymentTypes from 'parts/types/PaymentTypes'
import { useGetOnSelectChange } from 'pages/landing/landingConstructor/tariffs/panel/payment/AdditionalPaymentForm/fn/onChange'
import {
	FieldsNames,
	useGetDeleteAdditionalPayment,
	useGetInitialValues,
	useUpdateFormValuesAfterSwithToAnotherTariff,
} from 'pages/landing/landingConstructor/tariffs/panel/payment/AdditionalPaymentForm/fn/form'

const { Option } = Select

type AdditionalPaymentFormProps = {
	formIdx: number
	price: TariffsStoreType.TariffPrice
}

// Формы с настройками дополнительных способов оплаты
function AdditionalPaymentForm(props: AdditionalPaymentFormProps) {
	const { formIdx, price } = props

	const [form] = Form.useForm()

	const initialValues = useGetInitialValues(price.id)
	const [isBlockOpen, setIsBlockOpen] = useState(true)

	useUpdateFormValuesAfterSwithToAnotherTariff(form, price.id)

	return (
		<ToggleBlock
			header={'Дополнительный способ ' + formIdx}
			open={isBlockOpen}
			onHeaderClick={() => setIsBlockOpen((open) => !open)}
		>
			<Form
				form={form}
				name={`additional-payment-${formIdx}`}
				layout="vertical"
				key={formIdx}
				initialValues={initialValues}
			>
				<CurrenciesInput price={price} />
				<PaySystemInput price={price} />
				{/*<PeriodInput price={price} form={form} />*/}
			</Form>
			<DeletePayment price={price} />
		</ToggleBlock>
	)
}

export default AdditionalPaymentForm

type InputProps = {
	price: TariffsStoreType.TariffPrice
}

// Выпадающий список выбора валюты
function CurrenciesInput(props: InputProps) {
	const { price } = props

	const onChange = useGetOnSelectChange(FieldsNames.Currency, price.id)

	return (
		<UFormNewRow>
			<Form.Item
				name="currency"
				label="Валюта"
				rules={[{ required: true }]}
			>
				<Select onChange={onChange}>
					{Object.values(PaymentTypes.Currencies).map((currency) => {
						return (
							<Option
								value={currency.currency}
								key={currency.currency}
							>
								{currency.currency} {currency.sign}
							</Option>
						)
					})}
				</Select>
			</Form.Item>
		</UFormNewRow>
	)
}

// Выпадающий список платёжной системы
function PaySystemInput(props: InputProps) {
	const { price } = props

	const onChange = useGetOnSelectChange(FieldsNames.PaymentSystem, price.id)

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name="paymentSystem"
				label="Платёжная система"
				rules={[{ required: true }]}
			>
				<Select onChange={onChange}>
					{Object.values(PaymentTypes.Systems).map((system) => {
						return (
							<Option value={system.system} key={system.system}>
								{system.name}
							</Option>
						)
					})}
				</Select>
			</Form.Item>
		</UFormNewRow>
	)
}

// Выпадающий список за что идёт оплата: за период времени или за курс
/*function PeriodInput(props: InputProps) {
	const { price, form } = props

	const onChange = useGetOnSelectChange('period', price.id, form)

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name="period"
				label="Период"
				rules={[{ required: true }]}
			>
				<Select onChange={onChange}>
					<Option
						value={PaymentTypes.PricePeriod.PerMonth}
						key={PaymentTypes.PricePeriod.PerMonth}
					>
						За месяц
					</Option>
					<Option
						value={PaymentTypes.PricePeriod.PerCourse}
						key={PaymentTypes.PricePeriod.PerCourse}
					>
						За курс
					</Option>
				</Select>
			</Form.Item>
		</UFormNewRow>
	)
}*/

type DeletePaymentProps = {
	price: TariffsStoreType.TariffPrice
}

// Кнопка удаления дополнительного способа оплаты
function DeletePayment(props: DeletePaymentProps) {
	const { price } = props

	const deletePayment = useGetDeleteAdditionalPayment(price.id)

	return (
		<UFormNewRow topOffset>
			<Button
				icon={<DeleteOutlined />}
				block
				danger
				onClick={deletePayment}
			>
				Удалить способ
			</Button>
		</UFormNewRow>
	)
}
