import { useEffect } from 'react'
import { message } from 'antd'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import useDebounce from 'parts/utils/hooks/hooks'
import UrlService from 'parts/services/UrlService'

/** Наблюдает за изменением объекта с данными одностраничника и автоматически сохраняет на сервере. */
export function useAutoSaveOnServer() {
	const trainingId = UrlService.useGetTrainingId()
	const landing = useLandingConstructorStore((state) => state.landing)
	const updateStore = useLandingConstructorStore((state) => state.updateStore)

	const updateLandingMutation =
		trainingQuery.updateDraftTrainingLanding.useMutation({
			onSuccess() {
				// После сохранения поставить статус, что всё прошло успешно.
				updateStore({ saveDataStatus: 'saved' })
			},
			onError() {
				message.error('Не удалось сохранить одностраничник')
			},
		})

	// Это заторможенные данные одностраничника чтобы не так часто делать запрос на сервер.
	const throttledLanding = useDebounce(landing, 1000)

	useEffect(
		function () {
			if (!landing) return

			// Перед сохранением данных поставить статус, что идёт сохранение
			updateStore({ saveDataStatus: 'saving' })

			updateLandingMutation.mutate({
				trainingId,
				dto: { landing },
			})
		},
		[throttledLanding]
	)

	// При изменении данных одностраничника поставить статус, что данные не сохранены
	useEffect(
		function () {
			updateStore({ saveDataStatus: 'hasUnsavedData' })
		},
		[landing]
	)
}
