import React, { useCallback } from 'react'
import { ExerciseStateType, getInitialState } from './state'

/**
 * Хук запускает функцию запускающую прохождение упражнения заново
 * @param setExerciseState
 */
export function useGetPassExerciseAgainFn(
	setExerciseState: React.Dispatch<React.SetStateAction<ExerciseStateType>>
) {
	return useCallback(function () {
		setExerciseState(getInitialState())
	}, [])
}
