import { create } from 'zustand'
import { LeadsStatsStoreType } from './storeTypes'

const useLeadsStatsStore = create<LeadsStatsStoreType.State>((set) => {
	return {
		totalSuccessfulPurchasesNum: 0,
		todaySuccessfulPurchasesNum: 0,

		/**
		 * Установщик новых значений корневого объекта.
		 * @param obj — объект со свойствами и значениями, которые должны быть изменены в Сотоянии
		 */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useLeadsStatsStore
