import { useRef } from 'react'
import HtmlEditorContentView from 'ui/HTMLEditorContentView/HTMLEditorContentView'
import {
	LiftViewDuration,
	useLiftViewDuration,
} from '../common/useLiftViewDuration'
import { useSetInnerHtml } from '../common/useSetInnerHtml'
import { TaskExerciseView } from '../common/TaskExerciseView/TaskExerciseView'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import './TextExerciseView.scss'

type AudioExerciseViewProps = {
	exercise: TrainingEntityTypes.TextExercise
	liftViewDuration?: LiftViewDuration
}

// Вывод текстового упражнения в уроке
function TextExerciseView(props: AudioExerciseViewProps) {
	const { exercise, liftViewDuration } = props

	// Считает время прошедшее после открытия упражнения
	// и передаёт количество секунд в liftViewDuration
	useLiftViewDuration(liftViewDuration)

	const areaRef = useRef(null)
	useSetInnerHtml(areaRef, exercise.item.text)

	return (
		<>
			{exercise.item.task && (
				<TaskExerciseView htmlString={exercise.item.task} />
			)}
			<HtmlEditorContentView
				areaRef={areaRef}
				outerClass="exercise-task"
			/>
		</>
	)
}

export default TextExerciseView
