import { useEffect } from 'react'
import { AxiosResponse } from 'axios'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import UrlService from 'parts/services/UrlService'
import { lessonsQuery } from '../../../../../../../parts/requests/lessons/lessonsQuery'
import useLessonPageHeaderAdminStore from '../store'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

/** Наполняет Зустанд данными, которые будут использоваться на странице урока администратора */
export function useFillInStore() {
	const trainingId = UrlService.useGetTrainingId()
	const lessonId = UrlService.useGetLessonId()

	// Запросить курс, чтобы поставить в Состояние
	const { data: trainingRes } = trainingQuery
		.getTrainingForAdmin(trainingId)
		.useQuery({
			onError: queryError,
		})

	useEffect(
		function () {
			if (!trainingRes) return

			trainingQuerySuccess(trainingRes)
		},
		[trainingRes]
	)

	// Запросить урок, чтобы поставить в Состояние
	const { data: lessonRes } = lessonsQuery.getLesson(lessonId).useQuery({
		onError: queryError,
	})

	useEffect(
		function () {
			if (!lessonRes) return

			lessonQuerySuccess(lessonRes)
		},
		[lessonRes]
	)
}

/**
 * Функция запускаемая при удачном получении курса.
 * @param trainingRes — ответ от сервера с данными курса
 */
function trainingQuerySuccess(
	trainingRes: AxiosResponse<TrainingEntityTypes.TrainingAdmin>
) {
	const { updateStore } = useLessonPageHeaderAdminStore.getState()

	updateStore({
		trainingAdmin: trainingRes.data,
		dataLoadingStatus: 'success',
	})
}

/**
 * Функция запускаемая при удачном получении курса.
 * @param lessonRes — ответ от сервера с данными урока
 */
function lessonQuerySuccess(
	lessonRes: AxiosResponse<TrainingEntityTypes.LessonAdmin>
) {
	const { updateStore } = useLessonPageHeaderAdminStore.getState()

	updateStore({
		lessonAdmin: lessonRes.data,
		dataLoadingStatus: 'success',
	})
}

/**
 * Функция запускаемая при ошибке запроса данных. Срабатывает для любых запросов.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 * @param err — объект ошибки.
 */
function queryError(err: unknown) {
	const { updateStore } = useLessonPageHeaderAdminStore.getState()

	updateStore({
		dataLoadingStatus: 'error',
	})
}
