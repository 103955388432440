import { FormQuestionStateItemType } from './fn/stateTypes'
import {
	DownloadingFileBlock,
	ExerciseFileDragger,
	SuccessDownloadedFileBlock,
} from '../common/FormFileInput/FormFileInput'
import { RcFile } from 'antd/lib/upload/interface'
import {
	beforeUploadFile,
	removeDownloadedFile,
	setFileDownloadedStatusToDefault,
} from './fn/changeForms'

export type FileTypes = 'audio' | 'image'

type DownloadMediaInputVariantsProps = {
	questionData: FormQuestionStateItemType
	fileType: FileTypes
}

// Различные варианты вида кнопки загрузки файла
export function DownloadMediaInputVariants(
	props: DownloadMediaInputVariantsProps
) {
	const { questionData, fileType } = props
	const { status, progress, fileName, link } = questionData.files[fileType]

	const acceptFileExe = fileType == 'audio' ? '.mp3' : '.jpg,.jpeg,.png'

	// Если ещё не указывали файл для загрузки
	if (status == 'empty') {
		return (
			<ExerciseFileDragger
				acceptFileExe={acceptFileExe}
				beforeUpload={(file: RcFile, FileList: RcFile[]) => {
					return beforeUploadFile(
						file,
						FileList,
						questionData.id,
						fileType
					)
				}}
			/>
		)
	} else if (status == 'downloading') {
		// Если идёт загрузка указанного файла
		return (
			<DownloadingFileBlock
				fileLink={link}
				fileName={fileName}
				progress={progress}
				cancelDownloading={() =>
					setFileDownloadedStatusToDefault(questionData.id, fileType)
				}
			/>
		)
	} else if (status == 'success') {
		// Успешная загрузка файла
		return (
			<SuccessDownloadedFileBlock
				fileName={fileName}
				removeFile={() => removeDownloadedFile(questionData, fileType)}
			/>
		)
	} else {
		// Неудачная загрузка файла
		return (
			<p className="exercise-test-media-upload-error__text">
				Не удалось загрузить файл {fileName}
			</p>
		)
	}
}
