import { ReactElement } from 'react'
import './EmptyBlock.scss'

type EmptyBlockProps = {
	illustrationType?: 1
	text: string | ReactElement
	button?: ReactElement
	bottom?: ReactElement
}

/** Блок с котом в коробке, заголовком и кнопкой */
export function EmptyBlock(props: EmptyBlockProps) {
	const { illustrationType = 1, text, button, bottom } = props

	let illustration = '/images/illustrations/1.svg'
	if (illustrationType == 1) {
		illustration = '/images/illustrations/1.svg'
	}

	return (
		<div className="empty-block">
			<img
				src={illustration}
				alt="Illustration"
				className="empty-block__image"
			/>
			<p className="empty-block__text">{text}</p>
			{button && (
				<div className="empty-block__button-wrapper">{button}</div>
			)}
			{bottom && (
				<div className="empty-block__bottom-wrapper">{bottom}</div>
			)}
		</div>
	)
}
