import { useEffect, useState } from 'react'
import { SaveExerciseChangesMadeByUser } from '../../../common/useLiftViewDuration'

// Тип объекта, в котором будут храниться данные для восстановления текста, написанного учеником в текстовом ответе.
type TextAnswerRestoreObj = {
	// Текст, введённый в редактор
	textAnswer: string
}

/**
 * Обработчик потерей фокуса текстовым полем.
 * После введённый текст будет встроен в объект для восстановления данных и передан функции сохраняющей эти данные на сервере.
 * @param editorText — текст, который ученик написал в редакторе.
 * @param saveExerciseChangesMadeByUser — функция, в которую передаются данные, которые нужно сохранить на сервере.
 * Используются для восстановления в редакторе текста, который написал ученик в прошлый раз.
 */
export function useGetOnBlurHandler(
	editorText: string,
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
) {
	return function () {
		if (!saveExerciseChangesMadeByUser) return

		const restoreObj: TextAnswerRestoreObj = {
			textAnswer: editorText,
		}

		saveExerciseChangesMadeByUser(restoreObj)
	}
}

/**
 * Получает данные для восстановления текста, который ученик написал в текстовом редакторе ответа в прошлый раз и возвращает их.
 * @param exerciseRestoreData — данные для восстановления упражнения
 */
export function useRestoreTextAnswerBlockOnMount(
	exerciseRestoreData?: Promise<TextAnswerRestoreObj>
) {
	const [newEditorText, setNewEditorText] = useState<null | string>(null)

	useEffect(function () {
		if (!exerciseRestoreData) return

		exerciseRestoreData.then((data) => {
			// @ts-ignore
			if (!data?.textAnswer || data.message) return

			setNewEditorText(data.textAnswer)
		})
	}, [])

	return newEditorText
}
