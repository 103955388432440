import { DeleteOutlined, EditTwoTone, TeamOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { Button } from 'antd'
import { getUserPic } from 'parts/utils/string'
import { UTableBCell, UTableBRow } from 'ui/UTable/UTable'
import { useGetLeaveGroup } from '../AddStuffRow/fn/leaveGroup'
import useGroupStaffStore from '../StaffTable/zustand/store'
import { GroupStaffStoreType } from '../StaffTable/zustand/storeTypes'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import { TransferStudentsModalController } from '../../TransferStudentsModal/TransferStudentsModalController'

// Ряды таблицы сотрудников
function TableRows() {
	const staff = useGroupStaffStore((state) => state.staff)
	if (!staff) return null

	return (
		<>
			{staff.map((employee) => {
				return (
					<UTableBRow key={employee.id}>
						<UTableBCell>
							<AvatarAndName employee={employee} />
						</UTableBCell>
						<UTableBCell>
							<EmployeeType employee={employee} />
						</UTableBCell>
						<UTableBCell>
							<PupilCount employee={employee} />
						</UTableBCell>
						<UTableBCell>
							<PupilCount employee={employee} isActualNumber />
						</UTableBCell>
						<UTableBCell>
							<TransferOfRightsAndDeleteButtons
								employee={employee}
							/>
						</UTableBCell>
					</UTableBRow>
				)
			})}
		</>
	)
}

export default TableRows

type CommonProps = {
	employee: GroupStaffStoreType.Employee
}

// Портрет и имя с фамилией куратора
function AvatarAndName(props: CommonProps) {
	const { employee } = props

	return (
		<div className="group-stuff-table__elems-wrapper">
			<img
				src={getUserPic(employee.avatar)}
				className="group-stuff-table__avatar"
				alt="avatar"
			/>
			<p className="group-stuff-table__text">{employee.name}</p>
		</div>
	)
}

// Роль сотрудника
function EmployeeType(props: CommonProps) {
	const { employee } = props

	const role = employee.type == 'curator' ? 'Куратор' : 'Менеджер'

	return <p className="group-stuff-table__text">{role}</p>
}

type PupilCountProps = {
	employee: GroupStaffStoreType.Employee
	isActualNumber?: boolean
}

// Количество учеников (планируемое или реальное)
function PupilCount(props: PupilCountProps) {
	const { employee, isActualNumber = false } = props

	if (employee.type == 'manager') {
		return null
	}

	// В будущем посмотри можно ли это заменить на UTableTextWithIcon

	return (
		<div className="group-stuff-table__elems-wrapper">
			<TeamOutlined
				className={
					isActualNumber ? 'group-stuff-table__actual-pupil-icon' : ''
				}
			/>
			<p className="group-stuff-table__text">
				{isActualNumber
					? employee.actualStudentCount
					: employee.studentCount}
			</p>
		</div>
	)
}

// Кнопка открытия модального окна передачи учеников и удаления из группы
function TransferOfRightsAndDeleteButtons(props: CommonProps) {
	const { employee } = props

	const userRoles = useGetUserRole()
	const leaveGroup = useGetLeaveGroup(employee.id)

	return (
		<div className="group-stuff-table__actions-wrapper">
			<Button
				size="small"
				icon={<EditTwoTone />}
				type="link"
				onClick={TransferStudentsModalController.open}
				className={cn(
					employee.type == 'manager' &&
						'group-stuff-table__trans-stud-modal--invisible'
				)}
			>
				Передать{' '}
			</Button>
			{userRoles.isAdmin && (
				<Button
					size="small"
					icon={<DeleteOutlined />}
					type="text"
					danger
					onClick={leaveGroup}
				/>
			)}
		</div>
	)
}
