import { FormInstance, message } from 'antd'
import { AxiosError, AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { QueryClient, useQueryClient } from 'react-query'
import LessonsApiTypes from 'parts/requests/lessons/lessonsApiTypes'
import { lessonsQuery } from 'parts/requests/lessons/lessonsQuery'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import LessonAdminService from 'parts/services/LessonAdminService'
import ServerTypes from 'parts/types/ServerTypes'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { getFirstLoadedFileUrl } from 'ui/upload/UploadFileInForm/fn/fn'
import useLessonsListAdminStore from '../../zustand/store'
import useAddLessonModalStore from '../zustand/store'
import { AddLessonModalStoreType } from '../zustand/storeTypes'
import { FieldNames, FormValuesType } from './form'

export function useGetOnSubmit(form: FormInstance) {
	const queryClient = useQueryClient()

	const updateModalState = useAddLessonModalStore(
		(state) => state.updateStore
	)

	// Объект с методом mutate для создания запроса
	const addLessonMutation = lessonsQuery.createLesson.useMutation({
		onMutate: getMutateOnBefore(updateModalState),
		onError: useGetMutateOnError(updateModalState),
		onSuccess: useGetMutateOnSuccess(updateModalState, queryClient, form),
	})

	return function (fieldsValue: FormValuesType) {
		// Приготовить данные, которые передадутся в функцию-обработчик запроса
		const newLessonData = prepareReqData(fieldsValue)
		addLessonMutation.mutate(newLessonData)
	}
}

/**
 * Готовит и возвращает DTO для создания урока.
 * @param fieldsValue — значения полей формы
 */
function prepareReqData(
	fieldsValue: FormValuesType
): LessonsApiTypes.CreateLessonDto {
	const { lessons, trainingId } = useLessonsListAdminStore.getState()
	const maxLessonOrderNum = LessonAdminService.getMaxLessonOrder(lessons)

	return {
		trainingId,
		name: fieldsValue[FieldNames.Name],
		description: fieldsValue[FieldNames.Description] || '',
		cover: getFirstLoadedFileUrl(fieldsValue[FieldNames.Cover]) || '',
		order: maxLessonOrderNum + 1,
		isFree: fieldsValue[FieldNames.isFree] || false,
	}
}

/**
 * Функция, выполняемая до запроса на изменение урока
 * @param updateModalState — функция изменяющая объект состояния модального окна
 */
function getMutateOnBefore(
	updateModalState: AddLessonModalStoreType.UpdateStore
) {
	return function () {
		// Поставить статус загрузки
		updateModalState({ isSubmitting: true })
	}
}

/**
 * Функция, выполняемая при появлении ошибки на изменение урока
 * @param updateModalState — функция изменяющая объект состояния модального окна
 */
function useGetMutateOnError(
	updateModalState: AddLessonModalStoreType.UpdateStore
) {
	const { t } = useTranslation()

	return function (err: unknown) {
		const error = err as AxiosError<ServerTypes.ErrorResponse>
		if (error.response) {
			// Поставить данные ошибки в Состояние, чтобы показать их в форме
			updateModalState({ formErrors: error.response.data })
		}

		message.error(t('adminTraining.addLessonModalReqErrorWhileCreation'))
	}
}

/**
 * Функция, выполняемая при успешном запросе на добавление сотрудника в группы
 * @param updateModalState — функция изменяющая объект состояния модального окна
 * @param queryClient — клиент React Query
 * @param form
 */
function useGetMutateOnSuccess(
	updateModalState: AddLessonModalStoreType.UpdateStore,
	queryClient: QueryClient,
	form: FormInstance
) {
	const { t } = useTranslation()

	return function (
		newLessonRes: AxiosResponse<TrainingEntityTypes.LessonAdmin>
	) {
		form.resetFields()

		// Закрыть модальное
		updateModalState({ isOpen: false, isSubmitting: false })
		message.success(t('adminTraining.addLessonModalLessonCreatedMessage'))

		const { trainingId } = useLessonsListAdminStore.getState()

		// Скачать новые данные, чтобы убрать старые из кеша React Query
		if (trainingId) {
			// Пометить данные курса неактуальными. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [trainingQuery.getTrainingForAdmin(trainingId).key],
			})
		} else {
			// Пометить массив уроков школы неактуальным. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [lessonsQuery.getSchoolLessons.key],
			})
		}
	}
}
