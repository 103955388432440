import { useEffect } from 'react'
import { FormInstance } from 'antd'
import useSetConfirmationCodeStore from '../state/store'
import { setErrorsToFields } from 'parts/utils/form'

export enum FieldNames {
	ActivationCode = 'activationCode',
}

export type FormValues = {
	[FieldNames.ActivationCode]: string
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useSetConfirmationCodeStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
