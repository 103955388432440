import { produce } from 'immer'
import exercisesRequests from 'parts/requests/exercises/exercisesRequests'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useGetCurrentExerciseMeta } from 'pages/exerciseNew/common/common'
import useExerciseStudentStore from 'pages/exerciseNew/zustand/store'
import { ExerciseStudentStoreType } from 'pages/exerciseNew/zustand/storeTypes'
import ExerciseService from 'parts/services/ExerciseService'

/**
 * Хук возвращает функцию сообщающая серверу о выполнении упражнения
 * @param afterDone — функция, выполняемая после выполнения упражнения (нажатия на кнопку «Выполнено»).
 * @param duration
 */
export function useGetDoneExercise(afterDone: () => void, duration: number) {
	const exerciseMeta = useGetCurrentExerciseMeta()

	const lesson = useExerciseStudentStore((state) => state.lesson)
	const exercisesMeta = useExerciseStudentStore(
		(state) => state.exercisesMeta
	)
	const groupId = useExerciseStudentStore((state) => state.groupId)
	const updateStore = useExerciseStudentStore((state) => state.updateStore)

	// Обработчик щелчка по кнопке «Выполнено»
	return async function () {
		if (!exerciseMeta) return

		// Отправить на сервер запрос, что упражнение завершено успешно
		await exercisesRequests.doneExercise({
			groupId,
			exerciseId: exerciseMeta.id,
			duration,
		})

		const newLesson = produce(lesson, (draft) => {
			const exercise = ExerciseService.getExerciseInLesson(
				draft,
				exerciseMeta.id
			)!

			if (!exercise) return

			// У текущего упражнения поставить статус 1 (Выполнено).
			exercise.status = TrainingEntityTypes.ExerciseProgressStatus.SUCCESS
		})

		// Обновить состояние конфигурации упражнений...
		const newExercisesMeta = produce(exercisesMeta, (draft) => {
			const thisExerciseState = getExerciseState(draft, exerciseMeta.id)
			if (!thisExerciseState) return

			// У текущего упражнения поставить статус 1 (Выполнено).
			thisExerciseState.status =
				TrainingEntityTypes.ExerciseProgressStatus.SUCCESS
		})

		updateStore({
			lesson: newLesson,
			exercisesMeta: newExercisesMeta,
			// Поставить новый процент завершённых упражнений
			percentage: getNewPercentage(newExercisesMeta),
		})

		// Выполнить функцию, которая должна срабатывать после выполнения упражнения
		if (afterDone) {
			afterDone()
		}
	}
}

/**
 * Ищет упражнение из объекта состояния ленты навигации по упражнениям.
 * @param exerciseMetas
 * @param {Object} exerciseId — id упражнения
 */
export function getExerciseState(
	exerciseMetas: ExerciseStudentStoreType.ExerciseMeta[],
	exerciseId: number
) {
	return exerciseMetas.find((exState) => {
		return exState.id == exerciseId
	})
}

/**
 * Возвращает количество процентов выполненноси упражнений
 * @param {Object} exercisesMeta — метаданные упражнений
 */
function getNewPercentage(
	exercisesMeta: ExerciseStudentStoreType.ExerciseMeta[]
) {
	const totalExercisesLength = exercisesMeta.length

	let doneExercisesLength = exercisesMeta.reduce((sum, exercise) => {
		return exercise.status == 1 ? sum + 1 : sum
	}, 0)

	return Math.round((100 / totalExercisesLength) * doneExercisesLength)
}
