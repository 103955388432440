import { create } from 'zustand'
import { LiveRoomStoreType } from './storeTypes'

const useLiveRoomStore = create<LiveRoomStoreType.State>((set) => {
	return {
		dataLoadingStatus: 'loading',
		liveRooms: [],
		errorMessage: null,

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useLiveRoomStore
