import { useQueryClient } from 'react-query'
import { groupQuery } from 'parts/requests/group/groupQuery'
import RemoveUserModal from './RemoveUserModal'
import useRemoveUserModalStore from './zustand/store'

// Контроллер модального окна создания новой группы
export const RemoveUserModalController = {
	element: <RemoveUserModal />,
	open() {
		setVisibilityToRemoveUserModal(true)
	},
	close() {
		setVisibilityToRemoveUserModal(false)
	},
}

/**
 * Возвращает функцию, ставящую видимость модального окна создания новой группы
 * @param isVisible — должно ли модальное окно быть видимым?
 */
function setVisibilityToRemoveUserModal(isVisible: boolean) {
	const { updateStore } = useRemoveUserModalStore.getState()

	updateStore({ isModalOpen: isVisible })
}
