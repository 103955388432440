import UrlService from 'parts/services/UrlService'
import { useGetExpressCourse } from '../../lessonsContent'
import ExpressLesson from '../Lesson/Lesson'
import ExpressTitle from '../Title/ExpressTitle'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'

import styles from './Lessons.module.scss'
import AppUrls from 'parts/constants/pageUrl'

const ExpressLessons = () => {
	const navigate = useNavigate()
	const courseId = UrlService.useGetExpressCourseId() as
		| 'how-teach'
		| 'how-create'
	const expressLessonId = UrlService.useGetExpressLessonId()

	const course = useGetExpressCourse(courseId)

	if (!course) {
		return null
	}

	const lessonContent = course.lessons.find(
		(lesson) => lesson.no === expressLessonId
	)

	const handlePrev = () => {
		navigate(AppUrls.MyExpressLesson(courseId, expressLessonId - 1).url)
	}

	const handleNext = () => {
		navigate(AppUrls.MyExpressLesson(courseId, expressLessonId + 1).url)
	}

	if (lessonContent) {
		return (
			<div className={styles.ExpressLessons}>
				<ExpressTitle
					title={lessonContent.title.title}
					subtitle={lessonContent.title.subtitle}
					image={lessonContent.title.image}
				/>
				<ExpressLesson
					name={lessonContent.title.title}
					blocks={lessonContent.blocks}
				/>
				<div className={styles.ExpressLessons__Control}>
					<Button
						type="link"
						onClick={handlePrev}
						disabled={expressLessonId - 1 < 1}
					>
						Предыдущий урок
					</Button>
					<Button
						type="primary"
						onClick={handleNext}
						disabled={expressLessonId + 1 > course.lessons.length}
					>
						Следующий урок
					</Button>
				</div>
			</div>
		)
	}

	return null
}

export default ExpressLessons
