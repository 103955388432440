import { useEffect, useState } from 'react'
import UrlService from 'parts/services/UrlService'

export type ExerciseStateType = {
	totalGaps: number // Общее количество вопросов
	rightAnswers: number // На сколько вопросов ученик дал ответ
	attempts: number // Количество доступных попыток
	unfilledGaps: number // Сколько есть незаполненных дырок

	showResult: boolean // Показать ли окно результата
	readyToShowResult: boolean // Готово ли упражнение для показа результата (правильно введены все слова или вышли попытки)
	resultButtonText: string // Текст на кнопке показа результата
}

/** Функция создаёт местное Состояние для упражнения */
export function useCreateExerciseState() {
	const exerciseId = UrlService.useGetExerciseId()

	const [exerciseState, setExerciseState] =
		useState<ExerciseStateType>(createInitialState)

	useEffect(
		function () {
			setExerciseState(createInitialState)
		},
		[exerciseId]
	)

	return {
		exerciseState,
		setExerciseState,
	}
}

export function createInitialState(): ExerciseStateType {
	return {
		totalGaps: 0,
		rightAnswers: 0,
		attempts: 3,
		unfilledGaps: 0,

		showResult: false,
		readyToShowResult: false,
		resultButtonText: 'Проверить',
	}
}
