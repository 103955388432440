import React, { ReactNode } from 'react'
import { HeaderRouteType } from './fn/types'
import Breadcrumbs from './Breadcrumbs'
import HeaderTabs, { PageHeaderTab } from '../Tabs/Tabs'
import './PageHeader.scss'

type PageHeaderProps = {
	// Изображение в левой части
	image?: ReactNode
	breadcrumbs?: HeaderRouteType[] // Массив объектов с описанием маршрута в хлебных крошках.
	header?: string | ReactNode // Текст заголовка.
	buttons?: false | ReactNode[]
	description?: string | ReactNode
	// Какая-то информация ниже заголовка и описания
	meta?: ReactNode
	tabs?: PageHeaderTab[]
}

// Заголовок страниц
function PageHeader(props: PageHeaderProps) {
	const {
		image,
		breadcrumbs = [],
		header,
		buttons,
		description,
		meta,
		tabs,
	} = props

	const hasButtons = buttons && buttons.length && buttons[0]

	return (
		<div className="page-header">
			<Breadcrumbs breadcrumbs={breadcrumbs} />

			<div className="page-header__main">
				{image && image}
				<div className="page-header__content">
					<div>
						{header && (
							<h1 className="page-header__header">{header}</h1>
						)}
						{typeof description === 'string' ? (
							<p className="page-header__description">
								{description}
							</p>
						) : (
							description
						)}
					</div>
					{!!hasButtons && (
						<div className="page-header__buttons">{buttons}</div>
					)}
				</div>
			</div>

			{meta && <div className="page-header__meta">{meta}</div>}
			{tabs && <HeaderTabs dataItems={tabs} />}
		</div>
	)
}

export default PageHeader
