// import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import EditTrainingModal from './EditTrainingModal'
import useEditTrainingModalStore from './zustand/store'

export const EditTrainingModalController = {
	element: <EditTrainingModal />,
	open(trainingId: number) {
		const updateStore = useEditTrainingModalStore.getState().updateStore
		updateStore({ trainingId, isOpen: true })
	},
	close() {
		const updateStore = useEditTrainingModalStore.getState().updateStore
		updateStore({ isOpen: false })
	},
}
