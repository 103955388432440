import TableHeader from '../tableHeader/TableHeader/TableHeader'
import TableRow from '../TableRow/TableRow'
import { UTable, UTableBody } from 'ui/UTable/UTable'
import useStudentsStore from '../zustand/store'
import './StudentsTable.scss'

// Содержимое страницы со списком учеников
function StudentsTableAdmin() {
	const students = useStudentsStore((store) => store.students)

	return (
		<UTable>
			<TableHeader />
			<UTableBody>
				{students.map((student) => (
					<TableRow student={student} key={student.id} />
				))}
			</UTableBody>
		</UTable>
	)
}

export default StudentsTableAdmin
