import {
	GetStudentStatisticData,
	userQuery,
} from 'parts/requests/user/userQuery'
import { getCompRouterObj } from 'parts/utils/component'
import TrainingStats from '../TrainingStats'
import { useFillInStore } from './fillInStore'

/** Хук возвращающий статус компонента TrainingStats и сам компонент в зависимости от статуса */
export function useGetTrainingStatsRouter(
	studentId: number | string,
	groupId: number | string
) {
	// Получить данные по статистике посещения курса
	const queryData: GetStudentStatisticData = { groupId, studentId }
	const query = userQuery.getStudentStatistic(queryData).useQuery()

	useFillInStore(studentId, groupId)

	return getCompRouterObj(query, <TrainingStats />)
}
