import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import useTariffsConstructorStore from 'pages/landing/landingConstructor/tariffs/zustand/store'

export function useGetOnSwitchChange() {
	const { activeTariffId } = useTariffsConstructorStore((s) => s)

	return function (checked: boolean) {
		if (!activeTariffId) return

		tariffsManager.updateTariff(activeTariffId, { isFlashed: checked })
		tariffsManager.setNeedToSave()
	}
}

export function useGetIsTariffFlashed() {
	const activeTariff = tariffsManager.useGetActiveTariff()
	if (!activeTariff) return false

	return activeTariff.isFlashed
}
