import { useEffect } from 'react'
import { userQuery } from 'parts/requests/user/userQuery'
import useStudentsStatsStore from '../zustand/store'

/** Наполняет Состояние данными сколько успешных покупок было сделано за всё время и за сегодня. */
export function useFillInStore() {
	const updateStore = useStudentsStatsStore((state) => state.updateStore)

	// Получить кол-во студентов
	const { data: studentsCountRes } = userQuery.getStudentCount().useQuery()
	const { data: studentsCountTodayRes } = userQuery
		.getStudentCountToday()
		.useQuery()

	useEffect(
		function () {
			if (!studentsCountRes) return

			updateStore({
				totalStudentsNum: studentsCountRes.data,
				newStudentsNum: studentsCountTodayRes?.data,
			})
		},
		[studentsCountRes, studentsCountTodayRes]
	)
}
