import { useRef, useState } from 'react'
import cn from 'classnames'
import HtmlEditorContentView from 'ui/HTMLEditorContentView/HTMLEditorContentView'
import { useSetInnerHtml } from '../useSetInnerHtml'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import './TranscriptionBlock.scss'

type TranscriptionBlockProps = {
	exercise:
		| TrainingEntityTypes.AudioExercise
		| TrainingEntityTypes.ImageExercise
}

// Блок с транскрипцией
export function TranscriptionBlock(props: TranscriptionBlockProps) {
	const { transcription } = props.exercise.item

	const [isTranscriptOpen, setIsTranscriptOpen] = useState(false)

	if (!transcription) return null

	return (
		<>
			<p
				className="exercise-collapse__text"
				onClick={() => setIsTranscriptOpen((isOpen) => !isOpen)}
			>
				Расшифровка
				<img
					className={cn(
						'exercise-collapse__triangle',
						isTranscriptOpen && 'exercise-collapse__triangle--open'
					)}
					src="/images/collapse-triangle.svg"
					alt="Раскрывающийся список"
				/>
			</p>
			{isTranscriptOpen && <Content htmlString={transcription} />}
		</>
	)
}

type ContentProps = {
	htmlString: string
}

function Content(props: ContentProps) {
	const { htmlString } = props

	const areaRef = useRef(null)
	useSetInnerHtml(areaRef, htmlString)

	return <HtmlEditorContentView areaRef={areaRef} />
}
