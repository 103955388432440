import MainImage from '../MainImage/MainImage'
import TimeBlocks from '../TimeBlocks/TimeBlocks'
import './SectionContent.scss'

function TimeContent() {
	return (
		<div className="landing-time-section-content">
			<div className="landing-time-section-content__content">
				<TimeBlocks />
			</div>
			<MainImage />
		</div>
	)
}

export default TimeContent
