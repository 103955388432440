import useSubscriptionCanceledFormStore from '../zustand/store'

/** Возвращает функцию, открывающую модальное окно удаления школы */
export function useGetToggleVisibilitySubscriptionCanceledModal() {
	const isModalOpen = useSubscriptionCanceledFormStore(
		(store) => store.isOpen
	)
	const updateModalState = useSubscriptionCanceledFormStore(
		(store) => store.updateStore
	)

	return function () {
		updateModalState({ isOpen: !isModalOpen })
	}
}
