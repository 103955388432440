import AdditionalPrice from '../AdditionalPrice/AdditionalPrice'
import EntityTypes from 'parts/types/EntityTypes'
import { findAdditionalPrices } from './fn/findAdditionalPrices'
import './AdditionalPrices.scss'

type AdditionalPricesProps = {
	tariff: EntityTypes.Tariff
}

function AdditionalPrices(props: AdditionalPricesProps) {
	const { tariff } = props

	const additionalPrices = findAdditionalPrices(tariff)
	if (!additionalPrices.length) return null

	return (
		<div className="p-tariff-additional-prices">
			{additionalPrices.map((price, i) => (
				<AdditionalPrice tariff={tariff} price={price} key={i} />
			))}
		</div>
	)
}

export default AdditionalPrices
