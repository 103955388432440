import { DescriptionImgExerciseFormStateType } from './formState'
import { getFormState } from '../../common/exerciseFormCommonFunc'

/**
 * Функция принимает объект состояния формы и находит объект соответствия по переданному идентификатору
 * @param {Object} formState — объект состояния формы
 * @param {Number} questionId — id объекта вопроса
 */
export function getMatchBlockById(
	formState: DescriptionImgExerciseFormStateType,
	questionId: number
) {
	return formState.matches.find((matchBlock) => matchBlock.id == questionId)
}

/** Функция перебирает объект состояния формы и возвращает булево значение есть ли там файл загружаемый в настоящий момент. */
export function isDownloadingFile() {
	const formState = getFormState<DescriptionImgExerciseFormStateType>()

	for (let i = 0; i < formState.matches.length; i++) {
		const { image } = formState.matches[i]

		if (image.status == 'downloading') {
			return true
		}
	}

	return false
}
