import { useEffect } from 'react'
import LessonStudentService from 'parts/services/LessonStudentService'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import { useGetTrainingIdAndLessonId } from '../../../common/common'
import useExerciseHeaderStudentStore from '../store'

/** Наполняет Состояние при загрузке компонента */
export function useFillInStore() {
	const { courseId, groupId, lessonId } = useGetTrainingIdAndLessonId()

	const updateStore = useExerciseHeaderStudentStore(
		(store) => store.updateStore
	)

	const { data: trainingRes } = trainingQuery
		.getTrainingForStudent(courseId, groupId)
		.useQuery({
			onError: queryError,
		})

	useEffect(
		function () {
			if (!trainingRes) return

			const training = trainingRes.data

			const lesson = LessonStudentService.getLessonById(
				training,
				lessonId
			)

			if (!lesson) {
				updateStore({
					dataLoadingStatus: 'error',
				})

				return
			}

			updateStore({
				trainingStudent: training,
				lesson,
				dataLoadingStatus: 'hasData',
			})
		},
		[trainingRes, lessonId]
	)
}

/**
 * Функция запускаемая при ошибке запроса данных. Срабатывает для любых запросов.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 * @param err — объект ошибки.
 */
function queryError(err: unknown) {
	const { updateStore } = useExerciseHeaderStudentStore.getState()

	updateStore({ dataLoadingStatus: 'error' })
}
