import { Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import useAddLessonModalStore from './zustand/store'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'
import { FieldNames, setErrorsToForm } from './fn/form'
import {
	UFormNewBottomButtonsAfterGray,
	UFormNewButtonsContainer,
	UFormNewGeneralError,
	UFormNewRow,
} from 'ui/UForm/UForm'
import { useGetOnSubmit } from './fn/submit'
import { useGetSetVisibilityToAddLessonModal } from './fn/openCloseModal'
import UploadAvatar from 'ui/upload/UploadFileInForm/UploadAvatar'

// Модальное окно добавления нового урока
function AddLessonForm() {
	const [form] = Form.useForm()

	const onSubmit = useGetOnSubmit(form)
	setErrorsToForm(form)

	const modalState = useAddLessonModalStore((state) => state)

	return (
		<Form
			form={form}
			name="addLesson"
			layout="vertical"
			onFinish={onSubmit}
		>
			<GrayBlock>
				<NameInput />
				<DescriptionInput />
				<CoverInput />
			</GrayBlock>
			<Bottom />
			<UFormNewGeneralError message={modalState.formErrors.message} />
		</Form>
	)
}

export default AddLessonForm

// Поле названия урока
function NameInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.Name}
				label={t('adminTraining.addLessonModalNameFieldLabel')}
				rules={[
					{
						required: true,
						message: t(
							'adminTraining.addLessonModalNameFieldRequiredError'
						),
					},
				]}
			>
				<Input />
			</Form.Item>
		</UFormNewRow>
	)
}

// Поле описания урока
function DescriptionInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Description}
				label={t('adminTraining.addLessonModalDescriptionFieldLabel')}
			>
				<Input.TextArea />
			</Form.Item>
		</UFormNewRow>
	)
}

// Поле загрузки обложки
function CoverInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Cover}
				label={t('adminTraining.addLessonModalCoverFieldLabel')}
			>
				<UploadAvatar
					listType="picture-card"
					inputName={FieldNames.Cover}
					initialFileList={[]}
					maxCount={1}
				/>
			</Form.Item>
		</UFormNewRow>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
// Кнопки подтверждения и закрытия окна
function Bottom() {
	const { t } = useTranslation()

	const modalState = useAddLessonModalStore((state) => state)
	const closeModal = useGetSetVisibilityToAddLessonModal(false)

	return (
		<UFormNewBottomButtonsAfterGray>
			<div />
			<UFormNewButtonsContainer>
				<Button onClick={closeModal}>
					{t('adminTraining.addLessonModalCancelBtn')}
				</Button>
				<Button
					type="primary"
					htmlType="submit"
					disabled={modalState.isSubmitting}
					loading={modalState.isSubmitting}
				>
					{t('adminTraining.addLessonModalOkBtn')}
				</Button>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
