import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import ProfileArticle from '../ProfileArticle/ProfileArticle'
import Footer from 'ui/Footer/Footer'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import AppUrls from 'parts/constants/pageUrl'

/** Страница редактирования профиля пользователя */
function ProfilePage() {
	const userRoles = useGetUserRole()

	return (
		<PageSkeleton>
			<PageHeader
				breadcrumbs={[
					{
						path: AppUrls.Profile().url,
						breadcrumbName: AppUrls.Profile().name,
					},
				]}
				header="Редактирование профиля"
			/>
			<ProfileArticle />
			<Footer isRightSideHidden={!userRoles.isAdminOrManager} />
		</PageSkeleton>
	)
}

export default ProfilePage
