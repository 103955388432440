import React from 'react'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import { EmptyBlock } from 'ui/EmptyBlock/EmptyBlock'
import RowsContainer from 'ui/RowsContainer/RowsContainer'
import { StudentsTableCuratorController } from '../studentsTableCurator/StudentsTableCurator/StudentsTableCuratorController'

/** Статья со списком учеников */
function CuratorStudentsArticle() {
	const studentsTableRouter = StudentsTableCuratorController.router()

	const inCenter =
		studentsTableRouter.status == 'loading' ||
		studentsTableRouter.status == 'noStudents'

	const containerWidth =
		studentsTableRouter.status == 'hasStudents' ? 'full' : 'small'

	return (
		<ArticleContentWrapper
			center={inCenter}
			innerWrapperWidth={containerWidth}
		>
			<RowsContainer>
				{studentsTableRouter.status == 'noStudents' && <NoStudents />}
				{studentsTableRouter.status == 'hasStudents' &&
					studentsTableRouter.element}
			</RowsContainer>
		</ArticleContentWrapper>
	)
}

export default CuratorStudentsArticle

function NoStudents() {
	return <EmptyBlock text="Нет ни одного ученика." />
}
