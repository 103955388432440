import { liveLessonQuery } from 'parts/requests/liveLesson/liveLessonQuery'
import { useQueryClient } from 'react-query'

export function useGetDeleteStorageItem() {
	const queryClient = useQueryClient()

	// Объект с методом mutate для создания запроса
	const deleteStoreItemMutation =
		liveLessonQuery.deleteStorageItem.useMutation({
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: liveLessonQuery.getStorageItems.key,
				})
			},
		})

	return async (itemId: number) => {
		deleteStoreItemMutation.mutate(itemId)
	}
}
