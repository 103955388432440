import React, { useEffect } from 'react'

/**
 * Хук ставит в элемент строку HTML преобразовывая его в разметку
 * @param {Object} editorRef — объект со ссылкой на блок текста
 * @param {String} htmlString — строка с HTML из которой нужно создать разметку
 */
export function useSetInnerHtml(
	editorRef: React.MutableRefObject<null | HTMLElement>,
	htmlString: string
): void {
	useEffect(
		function () {
			const $editor = editorRef.current as HTMLElement
			if (!$editor) return

			// Создание строки с разметкой текстового блока
			$editor.innerHTML = htmlString
		},
		[htmlString, editorRef]
	)
}
