import { create } from 'zustand'
import { ConfirmEmailStoreType } from './storeTypes'

const useConfirmEmailStore = create<ConfirmEmailStoreType.State>((set) => {
	return {
		isSubmitting: false,
		formErrors: {},

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useConfirmEmailStore
