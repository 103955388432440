import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RouterVarNames } from 'app/routes/special/otherRoutes'
import useExerciseStudentStore from 'pages/exerciseNew/zustand/store'
import UrlService from 'parts/services/UrlService'
import exercisesViewsManager from './exercisesViewsManager'

/**
 * Если возможно переключиться на следующее упражнение, то возвращает функцию, переадресовывающую на следующее упражнение.
 * Если нет, то undefined.
 * */
export function useGetGoToNextExercise() {
	const navigate = useNavigate()
	const params = useParams()
	const trainingIdStr = params[RouterVarNames.TrainingId]!
	const lesson = useExerciseStudentStore((state) => state.lesson)
	const exerciseId = UrlService.useGetExerciseId()

	return useMemo(
		function () {
			if (!lesson) return undefined

			const nextExerciseUrl =
				exercisesViewsManager.getPrevOrNextExerciseUrl(
					trainingIdStr,
					lesson,
					exerciseId,
					'next'
				)

			if (!nextExerciseUrl) return undefined

			return () => navigate(nextExerciseUrl)
		},
		[trainingIdStr, lesson, exerciseId]
	)
}
