import React from 'react'
import { Button, Form, Input } from 'antd'
import { ArrowRightOutlined, MailOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useManageForm } from './fn/submit'
import {
	UFormOld,
	UFormOldBottomButtons,
	UFormOldRow,
} from 'ui/UFormOld/UFormOld'
import StepMessage from '../Message/StepMessage'
import { UFormNewGeneralError } from 'ui/UForm/UForm'

// Формы с вводом почты для отправки ссылки на страницу сброса пароля
function RecoveryStartForm() {
	const [form] = Form.useForm()

	const { isLoading, onSubmit, formErrors } = useManageForm(form)

	return (
		<UFormOld>
			<Form
				form={form}
				name="login"
				onFinish={onSubmit}
				layout="vertical"
			>
				<EmailInput />
				<UFormNewGeneralError message={formErrors.message} />
				<Bottom loading={isLoading} />
				<StepMessage step={1} />
			</Form>
		</UFormOld>
	)
}

export default RecoveryStartForm

function EmailInput() {
	const { t } = useTranslation()

	return (
		<UFormOldRow>
			<Form.Item
				name="email"
				label={t('auth.recoverFormFieldEmailLabel')}
				rules={[
					{
						required: true,
						message: t('auth.recoverFormFieldEmailRequired'),
					},
					{
						type: 'email',
						message: t('auth.recoverFormFieldEmailWrongFormat'),
					},
				]}
			>
				<Input
					type="email"
					placeholder="mail@mail.com"
					size="large"
					prefix={<MailOutlined />}
				/>
			</Form.Item>
		</UFormOldRow>
	)
}

type BottomProps = {
	loading: boolean
}

// Кнопка отправки формы
function Bottom(props: BottomProps) {
	const { t } = useTranslation()
	const form = Form.useFormInstance()
	const { loading } = props

	return (
		<UFormOldBottomButtons>
			<Form.Item
				shouldUpdate
				className="login-page-content__bottom-form-item"
			>
				{() => {
					const formErrors = form
						.getFieldsError()
						.filter(({ errors }) => errors.length)

					return (
						<Button
							block
							size="large"
							type="primary"
							htmlType="submit"
							disabled={loading || formErrors.length > 0}
							loading={loading}
						>
							{t('auth.recoverFormSendButton')}{' '}
							<ArrowRightOutlined />
						</Button>
					)
				}}
			</Form.Item>
		</UFormOldBottomButtons>
	)
}
