import React, { useEffect } from 'react'
import { produce } from 'immer'
import useExercisesListAdminStore from '../../../zustand/store'
import {
	DownloadFileType,
	VideoExerciseFormStateType,
	FormVideoStateItemType,
	formInitialState,
} from './formState'
import { RcFile } from 'antd/lib/upload/interface'
import UploadFileTypes from 'parts/constants/uploadTypes'
import downloadFileToAmazon from 'parts/services/downloadFileToAmazon'
import { getFormState, setFormState } from '../../common/exerciseFormCommonFunc'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { exercisesFormsManager } from '../../../exercisesFormsManager'

/** Функция получает данные упражнения из Хранилища, перерабатывает их под формат данных формы и устанавливает в Хранилище */
export function useSetFormData() {
	const exercises = useExercisesListAdminStore((store) => store.exercises)
	const exerciseId = useExercisesListAdminStore((store) => store.exerciseId)

	const setDataToExerciseFormStore =
		exercisesFormsManager.useGetSetDataToExerciseFormStore()

	useEffect(
		function () {
			let formData: VideoExerciseFormStateType = formInitialState

			const exercise = exercises.find(
				(exercise) => exercise.id === exerciseId
			)

			if (exercise) {
				formData = convertExerciseDataToFormStateData(
					exercise as TrainingEntityTypes.VideoExercise
				)
			}

			setDataToExerciseFormStore(formData)
		},
		[exerciseId]
	)
}

/**
 * Функция переводит данные упражнения в формат данных используемым в форме
 * @param {Object} videoExercise — данные упражнения
 */
function convertExerciseDataToFormStateData(
	videoExercise: TrainingEntityTypes.VideoExercise
): VideoExerciseFormStateType {
	return {
		exerciseId: videoExercise.id || null,
		type: 'video',
		order: videoExercise.order || 0,
		videoTask: {
			value: videoExercise.item.task,
		},
		videos: videoExercise.item.video.map((video, i) => {
			return {
				id: i,
				videoName: video.name,
				link: video.link, // Значение поля «Ссылка на видео»
				error: '',
				downloadFile: {
					link: video.video,
					status: video.video ? 'success' : 'empty',
					progress: 0,
					fileName: '',
				},
			}
		}),
		isFormInvalid: false,
	}
}

/** Обработчик изменения поля «Задание к видео» */
export function useGetOnChangeTaskInput() {
	const formState = getFormState<VideoExerciseFormStateType>()

	return function (newValue: string) {
		const newState = produce(formState, (draft) => {
			draft.videoTask.value = newValue
		})

		setFormState(newState)
	}
}

/**
 * Обработчик изменения полей «Ссылка на видео» и «Название видео»
 * @param {Object} event — объект события
 * @param {String} inputType — поле к которому предназначается обработчик: videoLink (Ссылка на видео) или videoName (Название видео)
 * @param {Number} videoBlockId — id блока с полями касаемые видео
 */
export function onChangeVideoInput(
	event: React.ChangeEvent<HTMLInputElement>,
	inputType: 'videoLink' | 'videoName',
	videoBlockId: number
) {
	const formState = getFormState<VideoExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const thisVideo = getVideoBlockById(draft, videoBlockId)
		if (!thisVideo) return draft

		if (inputType == 'videoLink') {
			const { value } = event.target
			thisVideo.link = value

			if (value) {
				thisVideo.error = ''
				draft.isFormInvalid = false
			}
		} else if (inputType == 'videoName') {
			thisVideo.videoName = event.target.value
		}
	})

	setFormState(newState)
}

/**
 * Функция возвращает булево значение должна ли быть заблокирована кнопка загрузки видео
 * @param {Object} videoData — данные видео
 */
export function isVideoLinkInputDisabled(
	videoData: FormVideoStateItemType
): boolean {
	return (
		videoData.downloadFile.status == 'downloading' ||
		videoData.downloadFile.status == 'success'
	)
}

/**
 * Обработчик нажатия на кнопку закрытия блока с видео
 * @param {Object} event — объект события
 * @param {Number} videoBlockId — id блока с полями касаемые видео
 */
export function closeVideoBlock(
	event: React.MouseEvent<HTMLElement>,
	videoBlockId: number
) {
	const formState = getFormState<VideoExerciseFormStateType>()

	const newState = { ...formState }
	newState.videos = [...newState.videos]

	const thisVideoIdx = newState.videos.findIndex(
		(video) => video.id === videoBlockId
	)
	newState.videos.splice(thisVideoIdx, 1)

	newState.isFormInvalid = false

	setFormState(newState)
}

/** Хук возвращает обработчик кнопки создания нового видео-блока */
export function addNewVideoBlock() {
	const formState = getFormState<VideoExerciseFormStateType>()

	let maxVideoBlockId = 0

	formState.videos.forEach((videoBlock) => {
		if (videoBlock.id > maxVideoBlockId) {
			maxVideoBlockId = videoBlock.id
		}
	})

	const newVideoBlock: FormVideoStateItemType = {
		id: ++maxVideoBlockId,
		videoName: '',
		link: '',
		error: '',
		downloadFile: {
			link: '',
			status: 'empty',
			progress: 0,
			fileName: '',
		},
	}

	const newState = produce(formState, (draft) => {
		draft.videos.push(newVideoBlock)
	})

	setFormState(newState)
}

/**
 * Функция загружает файл, получает его адрес на сервере и все данные записывает в местное Хранилище
 * @param {Object} file — данные о загружаемом файле
 * @param {Array} FileList
 * @param {Number} videoBlockId — id блока с полями касаемые видео
 */
export async function beforeUploadFile(
	file: RcFile,
	FileList: RcFile[],
	videoBlockId: number
) {
	await downloadFileToAmazon(file, FileList, UploadFileTypes.EXERCISE_VIDEO, {
		beforeDownloading(fileName: string) {
			// Поставить в состояние видео-блока статус загрузки файла
			changeDownloadFileState(videoBlockId, {
				status: 'downloading',
				progress: 0,
				fileName,
			})
		},
		whileDownloading(percentCompleted, cancelDownloading) {
			// Если статус загружаемого файла не равен 'downloading', то есть его изменили, то прекратить загрузку
			if (
				getVideoBlockById(
					getFormState<VideoExerciseFormStateType>(),
					videoBlockId
				)?.downloadFile.status !== 'downloading'
			) {
				cancelDownloading()
			}

			// Поставить значение загруженных процентов в Состояние
			changeDownloadFileState(videoBlockId, {
				progress: percentCompleted,
			})
		},
		onSuccess(fileName: string, url: string) {
			changeDownloadFileState(videoBlockId, {
				link: url,
				status: 'success',
				fileName,
			})

			// Убрать ошибку из Состояния у этого блока
			updateVideoBlockState(videoBlockId, { error: '' })

			if (validateForm()) {
				updateState({ isFormInvalid: false })
			}
		},
		onError(fileName: string) {
			changeDownloadFileState(videoBlockId, {
				status: 'error',
			})
		},
	})
}

/**
 * Обновляет переданные свойства в объекте Состояния загружаемого видео.
 * @param {Number} videoBlockId — id блока с полями касаемые видео
 * @param {Object} newDownloadFileProps — объект данных Состояния загружаемого файла
 */
function changeDownloadFileState(
	videoBlockId: number,
	newDownloadFileProps: Partial<DownloadFileType>
) {
	const formState = getFormState<VideoExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const thisVideoIdx = draft.videos.findIndex(
			(video) => video.id === videoBlockId
		)

		draft.videos[thisVideoIdx].downloadFile = Object.assign(
			draft.videos[thisVideoIdx].downloadFile,
			newDownloadFileProps
		)
	})

	setFormState(newState)
}

/**
 * Функция ставит статус загружаемого файла в значение empty
 * @param {Number} videoBlockId — id блока с полями касаемые видео
 */
export function setVideoDownloadedStatusToDefault(videoBlockId: number) {
	changeDownloadFileState(videoBlockId, { status: 'empty' })
}

/**
 * Обработчик нажатия на кнопку удаления загруженного файла
 * @param {Number} videoBlockId — id блока c данными видео
 */
export function removeDownloadedFile(videoBlockId: number) {
	changeDownloadFileState(videoBlockId, { status: 'empty', link: '' })
}

/**
 * Обработчик отправки формы
 * @param {Function} saveExercise — функция сохраняющая упражнение на сервере и в Состоянии
 */
export function submitHandler(
	saveExercise: (args: TrainingEntityTypes.ExerciseItem) => void
) {
	const formState = getFormState<VideoExerciseFormStateType>()

	const isFormValid = validateForm()

	if (!isFormValid) {
		return
	}

	const exerciseData = convertFormStateDataToExerciseData(formState)
	saveExercise(exerciseData)
}

/** Функция проверяет правильность заполнения формы, изменяет Состояние для показа ошибок и возвращает булево значение, является ли форма правильной */
function validateForm() {
	let isFormValid = true

	const formState = getFormState<VideoExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		draft.videos.forEach((videoBlock, i) => {
			if (!videoBlock.link && !videoBlock.downloadFile.link) {
				draft.videos[i].error =
					'Поставьте ссылку на видео или загрузите из файла или удалите этот блок видео.'
				draft.isFormInvalid = true

				isFormValid = false
			}
		})
	})

	setFormState(newState)

	return isFormValid
}

/**
 * Функция переводит данные формы редактирования упражнения в данные упражнения для сохранения на сервере.
 * @param {Object} formState — Объект с данными формы
 */
function convertFormStateDataToExerciseData(
	formState: VideoExerciseFormStateType
) {
	const lessonId = useExercisesListAdminStore.getState().lessonId

	const videos: TrainingEntityTypes.Video[] = formState.videos.map(
		(videoBlock) => {
			return {
				link: videoBlock.link, // Ссылка на видео на внешнем сервере
				name: videoBlock.videoName, // Название видео
				video: videoBlock.downloadFile.link, // Ссылка на видео на собственном сервере
			}
		}
	)

	const exerciseData: TrainingEntityTypes.VideoExercise = {
		type: 'video',
		lessonId,
		order: formState.order,
		item: {
			task: formState.videoTask.value,
			video: videos,
		},
		status: 0, // Чтобы TS не ругался
		statusUpdated: '', // Чтобы TS не ругался
	}

	if (formState.exerciseId) {
		exerciseData.id = formState.exerciseId
	}

	return exerciseData
}

/** Функция перебирает объект состояния формы и возвращает булево значение есть ли там видео, которое загружается в настоящий момент */
export function isDownloadingFile() {
	const formState = getFormState<VideoExerciseFormStateType>()

	for (let i = 0; i < formState.videos.length; i++) {
		const videoBlock = formState.videos[i]
		if (videoBlock.downloadFile.status == 'downloading') {
			return true
		}
	}

	return false
}

/**
 * Функция получает id данных видео, находит и возвращает объект данных
 * @param {Object} formState — объект состояния формы
 * @param {Number} videoBlockId — id блока c данными видео
 */
function getVideoBlockById(
	formState: VideoExerciseFormStateType,
	videoBlockId: number
) {
	return formState.videos.find((videoBlock) => {
		return videoBlock.id === videoBlockId
	})
}

/**
 * Обновляет объект состояния
 * @param obj — объект с изменёнными данными
 */
function updateState(obj: Partial<VideoExerciseFormStateType>) {
	const formState = getFormState<VideoExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		for (let key in obj) {
			// @ts-ignore
			draft[key] = obj[key]
		}
	})

	setFormState(newState)
}

/**
 * Обновляет объект состояния видео-блока
 * @param videoBlockId — id видео-блока
 * @param obj — объект с изменёнными данными
 */
function updateVideoBlockState(
	videoBlockId: number,
	obj: Partial<FormVideoStateItemType>
) {
	const formState = getFormState<VideoExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		draft.videos.forEach((videoBlock, i) => {
			if (videoBlock.id == videoBlockId) {
				draft.videos[i] = Object.assign(videoBlock, obj)
			}
		})
	})

	setFormState(newState)
}
