import { produce } from 'immer'
import { LandingTypes } from '../../types/LandingTypes'

// В этом файле находится функция синхронизирующая один объект с другим.

// Например в БД хранится объект со следующими свойствами:
const partialObj = {
	two: 'part',
	wrong: 'WRONG PROP',
	compound: {
		one: 'part',
		wrong: 'WRONG PROP',
		arr: [
			{
				two: 'part',
				wrong: 'WRONG PROP',
			},
		],
	},
}

// В процессе разработки стала использовать другая структура объект:
// какие-то свойства добавились, другие стали ненужными:
const sourceObj = {
	one: 1,
	two: 2,
	compound: {
		one: 'source',
		two: 'source',
		arr: [
			{
				one: 'source',
				two: 'source',
			},
		],
	},
}

// После работы функции должен получиться такой объект:
const finalObj = {
	one: 1,
	two: 'part',
	compound: {
		one: 'part',
		two: 'source',
		arr: [
			{
				one: 'source',
				two: 'part',
			},
		],
	},
}

/**
 * Функция получает объект с неправильной структурой и с правильной
 * и синхронизирует первый объект чтобы он по своей структуре соответствовал первому.
 * То есть добавляются отсутствующие свойства со значениями из sourceObj и удаляются которых нет в sourceObj.
 * @param partialObj — объект, который нужно синхронизировать
 * @param sourceObj — эталонный объект
 */
export function syncOneObjectOntoAnother(
	partialObj: Object,
	sourceObj: Object
) {
	return produce(partialObj, (partObj) => {
		syncObject(partObj, sourceObj)
	}) as LandingTypes.Landing
}

/**
 * Синхронизирует один объект с другим. В новом объекте
 * @param partObj
 * @param sourceObj
 */
function syncObject(partObj: Object, sourceObj: Object) {
	// Удалить из partObj свойства отсутствующие в sourceObj
	for (let key in partObj) {
		// @ts-ignore
		if (key in sourceObj) {
		} else {
			// @ts-ignore
			delete partObj[key]
		}
	}

	// Добавить в partObj свойства присутствующие в sourceObj
	for (let key in sourceObj) {
		if (key in partObj) {
		} else {
			// @ts-ignore
			partObj[key] = sourceObj[key]
		}

		// @ts-ignore
		if (partObj[key].toString() == '[object Object]') {
			// @ts-ignore
			syncObject(partObj[key], sourceObj[key])
			// @ts-ignore
		} else if (Array.isArray(partObj[key])) {
			// @ts-ignore
			syncArray(partObj[key], sourceObj[key])
		}
	}
}

function syncArray(partArr: Object[], sourceArr: Object[]) {
	if (!sourceArr.length) return

	const sourceObj = sourceArr[0]

	partArr.forEach((partObj) => {
		syncObject(partObj, sourceObj)
	})
}
