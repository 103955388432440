import { produce } from 'immer'
import PaymentTypes from 'parts/types/PaymentTypes'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'
import useTariffsConstructorStore from 'pages/landing/landingConstructor/tariffs/zustand/store'

// Класс с методами манипулирования состояния тарифов группы
const tariffsManager = {
	/** Возвращает объект Состояния */
	get store() {
		return useTariffsConstructorStore.getState()
	},

	/**
	 * Обёртка передающая объект со свойствами, которые должны быть обновлены в Состоянии
	 * @param updatedPropsObj — объект со свойствами, которые должны быть обновлены в Состоянии
	 */
	updateStore(updatedPropsObj: Partial<TariffsStoreType.State>) {
		useTariffsConstructorStore.getState().updateStore(updatedPropsObj)
	},

	/** Находит тариф по переданному внутреннему идентификатору. */
	getTariff(tariffInnerId: number) {
		return this.findTariff(this.store.tariffs, tariffInnerId)
	},

	/** Находит тариф по переданному внутреннему идентификатору. */
	findTariff(tariffs: TariffsStoreType.Tariff[], tariffInnerId: number) {
		return tariffs.find((tariff) => tariff.innerId == tariffInnerId)
	},

	/** Находит тезис по переданному внутреннему идентификатору тарифа и id тезиса. */
	findThesisInTariff(tariff: TariffsStoreType.Tariff, thesisId: number) {
		return tariff.theses.find((thesis) => thesis.id == thesisId)
	},

	/** Находит тезис в массиве тарифов. */
	findThesisInTariffs(
		tariffs: TariffsStoreType.Tariff[],
		tariffInnerId: number,
		thesisId: number
	) {
		const tariff = this.findTariff(tariffs, tariffInnerId)
		if (!tariff) return null

		return this.findThesisInTariff(tariff, thesisId)
	},

	/** Создаёт новый тариф и помещает в Хранилище */
	createTariffAndSetToStore() {
		const updatedTariffs = produce(this.store.tariffs, (draft) => {
			const newTariff = this.createTariff()
			draft.push(newTariff)
		})

		this.updateStore({ tariffs: updatedTariffs })
	},

	/**
	 * Создание нового тарифа
	 * @param innerId — значение свойства innerId. Если не передано, то будет назначено.
	 */
	createTariff(innerId?: number): TariffsStoreType.Tariff {
		let newInnerId = innerId ?? this.getMaxInnerIdInTariffs() + 1

		return {
			dbId: null,
			innerId: newInnerId,
			groupId: this.store.groupId,
			isFlashed: false,
			header: 'Базовый',
			description:
				'Подходит для самостоятельного изучения, без обратной связи',
			theses: [
				{
					id: 1,
					text: 'Срок обучения — 3 месяца',
					crossedOut: false,
				},
				{
					id: 2,
					text: 'Опция включенная в тариф',
					crossedOut: false,
				},
				{
					id: 3,
					text: 'Опция не включенная в тариф, хорошо подходит для сравнения',
					crossedOut: true,
				},
			],
			mainPrice: {
				id: 0, // Для основного тарифа идентификатор не имеет значения.
				isMain: true,
				text: '',
				currency: PaymentTypes.Currency.RUB,
				price: '100',
				oldPrice: '120',
				paymentSystem: PaymentTypes.System.UKassa,
				buttonText: 'Записаться',
				period: PaymentTypes.PricePeriod.PerMonth,
			},
			prices: [],
		}
	},

	/** Сбрасывает настройки тарифа и обновляет Хранилище */
	resetTariffAndSetToStore(tariffInnerId: number) {
		const newTariffs = produce(this.store.tariffs, (draft) => {
			const tariffIdx = this.findTariffIndex(draft, tariffInnerId)
			if (tariffIdx < 0) return

			draft[tariffIdx] = this.createTariff(tariffInnerId)
		})

		this.store.updateStore({ tariffs: newTariffs })
	},

	/** Удаляет тариф и обновляет Хранилище */
	deleteTariffAndSetToStore(tariffInnerId: number) {
		const newTariffs = produce(this.store.tariffs, (draftTariffs) => {
			return draftTariffs.filter(
				(tariff) => tariff.innerId !== tariffInnerId
			)
		})

		this.store.updateStore({ tariffs: newTariffs })

		if (this.store.activeTariffId == tariffInnerId) {
			this.store.updateStore({ activeTariffId: null })
		}
	},

	/** Создание новой дополнительной цены */
	createNewAdditionalPrice(id: number): TariffsStoreType.TariffPrice {
		return {
			id,
			isMain: false,
			text: '',
			currency: PaymentTypes.Currency.USD,
			price: '',
			oldPrice: '',
			paymentSystem: PaymentTypes.System.UKassa,
			buttonText: '',
			period: PaymentTypes.PricePeriod.PerCourse,
		}
	},

	/**
	 * Помещает переданную цену в Тариф в Хранилище.
	 * @param tariffId — id тарифа, в который нужно поставить переданную дополнительную цену.
	 * @param price — объект цены, который нужно поместить в Тариф в Хранилище
	 */
	setAdditionalPriceToStore(
		tariffId: number,
		price: TariffsStoreType.TariffPrice
	) {
		const newTariffs = produce(this.store.tariffs, (draft) => {
			const tariff = this.findTariff(draft, tariffId)
			if (!tariff) return

			tariff.prices.push(price)
		})

		this.store.updateStore({ tariffs: newTariffs })
	},

	/** Создаёт новую дополнительную цену и помещает в Тариф в Хранилище */
	createAdditionalPriceAndSetToStore(
		tariffs: TariffsStoreType.Tariff[],
		tariffId: number
	) {
		const tariff = this.findTariff(tariffs, tariffId)
		if (!tariff) return

		const newPriceId = tariff.prices.length + 1

		const newPrice = this.createNewAdditionalPrice(newPriceId)
		this.setAdditionalPriceToStore(tariffId, newPrice)
	},

	/** Клонирует тезис */
	cloneThesis(
		theses: TariffsStoreType.TariffThesis[],
		thesis: TariffsStoreType.TariffThesis
	): TariffsStoreType.TariffThesis {
		const newThesis: TariffsStoreType.TariffThesis = JSON.parse(
			JSON.stringify(thesis)
		)

		let maxThesisId = 0
		for (let i = 0; i < theses.length; i++) {
			if (maxThesisId < theses[i].id) {
				maxThesisId = theses[i].id
			}
		}

		newThesis.id = maxThesisId + 1

		return newThesis
	},

	/** Клонирует тезис и сохраняет в Состояние */
	cloneThesisAndSetToStore(tariffInnerId: number, thesisId: number) {
		const newTariffs = produce(this.store.tariffs, (draft) => {
			if (!draft) return

			const tariff = this.findTariff(draft, tariffInnerId)
			if (!tariff) return

			const thesis = this.findThesisInTariff(tariff, thesisId)
			if (!thesis) return

			const thesisIdx = tariff.theses.findIndex(
				(thesis) => thesis.id == thesisId
			)
			if (thesisIdx < 0) return

			const newThesis = this.cloneThesis(tariff.theses, thesis)

			tariff.theses.splice(thesisIdx + 1, 0, newThesis)
		})

		this.store.updateStore({ tariffs: newTariffs })
	},

	/** Удаляет тезис и сохраняет в Состояние */
	deleteThesisAndSetToStore(tariffInnerId: number, thesisId: number) {
		const newTariffs = produce(this.store.tariffs, (draft) => {
			if (!draft) return

			const tariff = this.findTariff(draft, tariffInnerId)
			if (!tariff) return

			const thesis = this.findThesisInTariff(tariff, thesisId)
			if (!thesis) return

			const thesisIdx = tariff.theses.findIndex(
				(thesis) => thesis.id == thesisId
			)
			if (thesisIdx < 0) return

			tariff.theses.splice(thesisIdx, 1)
		})

		this.store.updateStore({ tariffs: newTariffs })
	},

	/** Зачёркивает тезис или убирает зачёркивание и сохраняет в Состояние */
	toggleCrossOutThesisAndSetToStore(tariffInnerId: number, thesisId: number) {
		const newTariffs = produce(this.store.tariffs, (draft) => {
			if (!draft) return

			const thesis = this.findThesisInTariffs(
				draft,
				tariffInnerId,
				thesisId
			)
			if (!thesis) return

			thesis.crossedOut = !thesis.crossedOut
		})

		this.store.updateStore({ tariffs: newTariffs })
	},

	/** Возвращает активный тариф */
	useGetActiveTariff(): undefined | TariffsStoreType.Tariff {
		const { activeTariffId, tariffs } = useTariffsConstructorStore(
			(state) => state
		)

		return tariffs.find((tariff) => tariff.innerId == activeTariffId)
	},

	/** Возвращает дополнительную цену у активного тарифа */
	getAdditionalPriceInActiveTariff(
		additionalPriceId: number
	): undefined | TariffsStoreType.TariffPrice {
		const tariff = this.useGetActiveTariff()
		if (!tariff) return

		return tariff.prices.find((price) => price.id == additionalPriceId)
	},

	/** Возвращает индекс тарифа с переданным идентификатором */
	findTariffIndex(tariffs: TariffsStoreType.Tariff[], tariffId: number) {
		return tariffs.findIndex((tariff) => tariff.innerId === tariffId)
	},

	/** Возвращает индекс тарифа с переданным идентификатором */
	findAdditionalPriceIdxInTariff(
		tariff: TariffsStoreType.Tariff,
		priceId: number
	) {
		return tariff.prices.findIndex((price) => price.id === priceId)
	},

	/** Удаляет дополнительную цену из тарифа и обновляет Контекстное хранилище */
	deleteAdditionalPriceAndUpdateStore(
		tariffInnerId: number,
		additionalPriceId: number
	) {
		const newTariffs = produce(this.store.tariffs, (draft) => {
			const tariff = this.findTariff(draft, tariffInnerId)
			if (!tariff) return

			tariff.prices = tariff.prices.filter(
				(price) => price.id !== additionalPriceId
			)
		})

		this.store.updateStore({ tariffs: newTariffs })
	},

	/** Обновляет значение needToSaveOnServer чтобы запустилась функция сохранения тарифов на сервере */
	setNeedToSave() {
		this.updateStore({
			needToSaveOnServer: (this.store.needToSaveOnServer += 1),
		})
	},

	/** Возвращает максимальное значение innerId в тарифах */
	getMaxInnerIdInTariffs() {
		let maxInnerId = 0

		this.store.tariffs.forEach((tariff) => {
			if (tariff.innerId > maxInnerId) {
				maxInnerId = tariff.innerId
			}
		})

		return maxInnerId
	},

	updateTariff(
		tariffInnerId: number,
		updatedTariff: Partial<TariffsStoreType.Tariff>
	) {
		const newTariffs = produce(this.store.tariffs, (draftTariffs) => {
			const tariffIdx = this.findTariffIndex(draftTariffs, tariffInnerId)
			if (tariffIdx < 0) return

			draftTariffs[tariffIdx] = Object.assign(
				draftTariffs[tariffIdx],
				updatedTariff
			)
		})

		this.updateStore({ tariffs: newTariffs })
	},

	/** Обновляет текущую и старую цену у основной цены */
	changeMainPriceTextAndSetToStore(
		tariffInnerId: number,
		newPrice: string,
		priceType: 'price' | 'oldPrice'
	) {
		const newTariffs = produce(this.store.tariffs, (draftTariffs) => {
			const tariffIdx = this.findTariffIndex(draftTariffs, tariffInnerId)
			if (tariffIdx < 0) return

			draftTariffs[tariffIdx].mainPrice[priceType] = newPrice
		})

		this.store.updateStore({ tariffs: newTariffs })
	},

	/** Обновляет текст у объекта дополнительной цены и ставит в Контекстное хранилище */
	changeTextInAdditionalPriceAndSetToStore(
		tariffInnerId: number,
		additionalPriceId: number,
		propName: 'price' | 'oldPrice',
		newPrice: string
	) {
		const newTariffs = produce(this.store.tariffs, (draftTariffs) => {
			const tariffIdx = this.findTariffIndex(draftTariffs, tariffInnerId)
			if (tariffIdx < 0) return

			const additionalPriceIdx = draftTariffs[tariffIdx].prices.findIndex(
				(price) => price.id == additionalPriceId
			)
			if (additionalPriceIdx < 0) return

			draftTariffs[tariffIdx].prices[additionalPriceIdx][propName] =
				newPrice
		})

		this.store.updateStore({ tariffs: newTariffs })
	},
}

export default tariffsManager
