import { useEffect } from 'react'
import useCondStore from '../zustand/store'

/** Очищает Хранилище при размонтировании компонента */
export function clearStoreInUnmount() {
	const updateStore = useCondStore((state) => state.updateStore)

	useEffect(function () {
		return function () {
			updateStore({
				dataLoadingStatus: 'loading',
				group: undefined,
				isSubmitting: false,
			})
		}
	}, [])
}
