import { useEffect } from 'react'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import {
	DropWordsExerciseFormStateType,
	getFormInitialState,
	TextStatementItemType,
} from './stateType'
import { exercisesFormsManager } from '../../../exercisesFormsManager'
import useExercisesListAdminStore from '../../../zustand/store'

/** Функция получает данные упражнения из Хранилища, перерабатывает их под формат данных формы и устанавливает в местное Хранилище */
export function useSetFormData() {
	const exercises = useExercisesListAdminStore((store) => store.exercises)
	const exerciseId = useExercisesListAdminStore((store) => store.exerciseId)

	const setDataToExerciseFormStore =
		exercisesFormsManager.useGetSetDataToExerciseFormStore()

	useEffect(
		function () {
			let formData: DropWordsExerciseFormStateType = getFormInitialState()

			const exercise = exercises.find(
				(exercise) => exercise.id === exerciseId
			)

			if (exercise) {
				formData = convertExerciseDataToFormStateData(
					exercise as TrainingEntityTypes.DropWordExercise
				)
			}

			setDataToExerciseFormStore(formData)
		},
		[exerciseId]
	)
}

/**
 * Функция переводит данные упражнения в формат данных используемым в форме
 * @param {Object} exercise — данные упражнения
 */
function convertExerciseDataToFormStateData(
	exercise: TrainingEntityTypes.DropWordExercise
): DropWordsExerciseFormStateType {
	return {
		exerciseId: exercise.id || null,
		type: 'dropWords',
		order: exercise.order,
		taskInput: {
			value: exercise.item.task,
		},
		texts: exercise.item.texts.map((dropWord, i) => {
			return convertExerciseDropWordToStateItem(i, dropWord)
		}),
		isFormInvalid: false,
	}
}

function convertExerciseDropWordToStateItem(
	id: number,
	dropWordObj: TrainingEntityTypes.DropWordText
) {
	const textStateItem: TextStatementItemType = {
		id,
		text: dropWordObj.word,
		wrongAnswers: dropWordObj.wrongWords,
		noTextError: false,
		wrongBracesPairError: false,
		generatedText: '',
		generatedTextPrompt: dropWordObj.generatedTextPrompt,
	}

	return textStateItem
}
