import { paymentsQuery } from 'parts/requests/payments/paymentsQuery'
import { useTranslation } from 'react-i18next'
import Loader from 'ui/Loader/Loader'
import StudentOrdersTable from '../StudentOrdersTable'

type GetGroupsTableRouterReturn = {
	status: 'loading' | 'success' | 'unknown'
	element: JSX.Element
}

/**
 * Хук возвращающий статус компонента StudentOrdersTable и сам компонент в зависимости от статуса
 * @param userId — id ученика
 */
export function useGetStudentOrdersTableRouter(
	userId: string | number
): GetGroupsTableRouterReturn {
	const { t } = useTranslation()

	const { status, data } = paymentsQuery.getPaymentsOfUser(userId).useQuery()

	if (status == 'loading') {
		return {
			status: 'loading',
			element: <Loader />,
		}
	} else if (status == 'success') {
		return {
			status,
			element: data.data.length ? (
				<StudentOrdersTable userId={userId} />
			) : (
				<p>{t('student.ordersTableNoPayments')}</p>
			),
		}
	} else {
		return {
			status: 'unknown',
			element: <p>unknown</p>,
		}
	}
}
