import { Button } from 'antd'
import React, { MouseEventHandler } from 'react'
import { EditExerciseFormDataType } from 'parts/types/miscTypes'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useTranslation } from 'react-i18next'
import './ExerciseFormFooter.scss'

type ExerciseFormFooterProps = {
	formState: EditExerciseFormDataType
	saveExercise: (args: TrainingEntityTypes.ExerciseItem) => void
	closeModalFn: MouseEventHandler<HTMLElement> // Функция закрытия модального окна
	isSubmitButtonDisabled: boolean
	submitHandler: MouseEventHandler<HTMLElement>
}

// Блок с кнопками отправки формы и отмены создания упражнения
export default function ExerciseFormFooter(props: ExerciseFormFooterProps) {
	const { closeModalFn, isSubmitButtonDisabled, submitHandler } = props

	const { t } = useTranslation()

	return (
		<div className="exercise-form-footer">
			<Button onClick={closeModalFn}>Отмена</Button>
			<Button
				type="primary"
				disabled={isSubmitButtonDisabled}
				onClick={submitHandler}
			>
				Сохранить
			</Button>
		</div>
	)
}
