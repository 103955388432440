import { create } from 'zustand'
import { TrainingsStoreType } from './storeTypes'

const useTrainingsStudentStore = create<TrainingsStoreType.State>((set) => {
	return {
		dataLoadingStatus: 'loading',
		trainingsStudent: undefined as any,

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useTrainingsStudentStore
