import AppUrls from 'parts/constants/pageUrl'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import NoContent from 'ui/NoContent/NoContent'
import { EmptyBlock } from 'ui/EmptyBlock/EmptyBlock'
import { TrainingStatsController } from '../trainingStats/TrainingStats/TrainingStatsController'
import UrlService from 'parts/services/UrlService'

/** Страница с данными посещаемости курса учеником */
function TrainingStatsArticle() {
	const studentId = UrlService.useGetStudentId()
	const groupId = UrlService.useGetGroupId()

	const trainingStatsRouter = TrainingStatsController.router(
		studentId,
		groupId
	)

	const isContentInCenter =
		trainingStatsRouter.status == 'loading' ||
		trainingStatsRouter.status == 'noData' ||
		trainingStatsRouter.status == 'error'

	return (
		<ArticleContentWrapper center={isContentInCenter}>
			{trainingStatsRouter.status == 'loading' &&
				trainingStatsRouter.element}
			{trainingStatsRouter.status == 'noData' && <NoLessons />}
			{trainingStatsRouter.status == 'error' && <HasError />}
			{trainingStatsRouter.status == 'hasData' &&
				trainingStatsRouter.element}
		</ArticleContentWrapper>
	)
}

export default TrainingStatsArticle

// Компонент сообщающий об отсутствии уроков в курсе
function NoLessons() {
	const { t } = useTranslation()

	return (
		<EmptyBlock
			text={
				<>
					Пока не создано ни одного урока.
					<br />
					Пора приниматься за творчество!
				</>
			}
			// button={<AddLessonButton />}
		/>
	)
}

function HasError() {
	const { t } = useTranslation()

	return (
		<NoContent
			header="Курс не существует"
			text="Он был удалён или введён неправильный адрес"
			btnConf={{
				text: 'Перейти в Мои курсы',
				to: AppUrls.Trainings().url,
			}}
		/>
	)
}
