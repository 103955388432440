import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useGetCurrentQuestion } from './fn/main'
import {
	useGetPrevNextButtonHandler,
	usePrevNextButtonEnabled,
} from './fn/bottom'

// Блок с кнопками перехода к следующему вопросу
export function Bottom() {
	const { t } = useTranslation()
	const question = useGetCurrentQuestion()

	const prevQuestionBtnEnabled = usePrevNextButtonEnabled('prev')
	const nextQuestionBtnEnabled = usePrevNextButtonEnabled('next')
	const prevQuestionBtnFn = useGetPrevNextButtonHandler('prev')
	const nextQuestionBtnFn = useGetPrevNextButtonHandler('next')

	if (!question) return null

	return (
		<div className="exercise-test-content__bottom">
			<div className="exercise-test-content__bottom-right">
				<div className="exercise-test-content__back-next-btns">
					<Button
						onClick={prevQuestionBtnFn}
						disabled={!prevQuestionBtnEnabled}
						data-cy="text-ex-prev-question-btn"
					>
						Назад
					</Button>
					<Button
						onClick={nextQuestionBtnFn}
						disabled={!nextQuestionBtnEnabled}
						data-cy="text-ex-next-question-btn"
					>
						Вперёд
					</Button>
				</div>
			</div>
		</div>
	)
}
