import { Button, Form, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { FieldsNames, useGetOnFullNameChange } from './fn/onFullNameChange'

// Форма поиска по почте
function FullNameForm() {
	const [form] = Form.useForm()
	const onSubmit = useGetOnFullNameChange()

	return (
		<Form form={form} onFinish={onSubmit} className="leads-table__form">
			<Form.Item name={FieldsNames.FullName}>
				<Input />
			</Form.Item>
			<Button htmlType="submit" icon={<SearchOutlined />} />
		</Form>
	)
}

export default FullNameForm
