import { useTranslation } from 'react-i18next'
import {
	FormAnswerStateItemType,
	FormQuestionStateItemType,
} from './fn/stateTypes'
import { Button, Checkbox, Input, Radio, Tooltip } from 'antd'
import {
	addNewAnswer,
	onChangeRightAnswer,
	onChangeAnswerTextInput,
	removeAnswer,
} from './fn/changeForms'
import { alphabet } from 'parts/utils/string'
import {
	MinusCircleOutlined,
	PlusCircleOutlined,
	QuestionCircleOutlined,
} from '@ant-design/icons'

type AnswersProps = {
	questionData: FormQuestionStateItemType
}

// Ответы на вопрос
export default function Answers(props: AnswersProps) {
	const { questionData } = props

	const { t } = useTranslation()
	return (
		<>
			<label className="exercise-test__label exercise-test__label--top-margin">
				Ответы{' '}
				<Tooltip
					placement="top"
					title="Укажите поле с правильным ответом отметив флаг или переключатель правее."
				>
					<QuestionCircleOutlined />
				</Tooltip>
			</label>
			<div className="exercise-test-answers__items">
				{questionData.answers.map((answerData, i) => {
					if (!alphabet[i]) return null

					return (
						<Answer
							key={answerData.id}
							checkerType={
								questionData.multipleSelect
									? 'checkbox'
									: 'radio'
							}
							letter={alphabet[i]}
							questionData={questionData}
							answerData={answerData}
							showRemoveButton={questionData.answers.length > 2}
						/>
					)
				})}
			</div>
			{questionData.noRightAnswerCheckedError && (
				<p className="exercise-test__input-error exercise-test-answers__input-error">
					Укажите правильный вариант ответа.
				</p>
			)}
			{questionData.noAnswerTextError && (
				<p className="exercise-test__input-error exercise-test-answers__input-error">
					Напишите текст ответа как минимум для двух вариантов.
				</p>
			)}
			{questionData.answers.length < alphabet.length && (
				<AddNewAnswerButton questionData={questionData} />
			)}
		</>
	)
}

type AnswerProps = {
	checkerType: 'checkbox' | 'radio'
	letter: string
	questionData: FormQuestionStateItemType
	answerData: FormAnswerStateItemType
	showRemoveButton: boolean
}

// Ответ на вопрос
function Answer(props: AnswerProps) {
	const { checkerType, letter, questionData, answerData, showRemoveButton } =
		props

	return (
		<div className="exercise-test-answer">
			<span className="exercise-test-answer__letter">{letter}</span>
			<Input
				value={answerData.text}
				onChange={(event) =>
					onChangeAnswerTextInput(
						event,
						questionData.id,
						answerData.id
					)
				}
			/>
			<RightAnswerChecker
				inputType={checkerType}
				questionData={questionData}
				answerData={answerData}
			/>
			{showRemoveButton && (
				<Button
					className="exercise-test-answer__remove-button"
					shape="circle"
					icon={<MinusCircleOutlined />}
					onClick={(event) =>
						removeAnswer(questionData.id, answerData.id)
					}
				/>
			)}
		</div>
	)
}

type RightAnswerCheckerProps = {
	inputType: 'checkbox' | 'radio'
	questionData: FormQuestionStateItemType
	answerData: FormAnswerStateItemType
}

// Флаг или переключатель справа от вопроса чтобы отметить правильный ли он
function RightAnswerChecker(props: RightAnswerCheckerProps) {
	const { inputType, questionData, answerData } = props

	return inputType == 'checkbox' ? (
		<Checkbox
			checked={answerData.correct}
			className="exercise-test-answer__checkbox"
			onChange={(event) =>
				onChangeRightAnswer(
					event,
					questionData.id,
					answerData.id,
					inputType
				)
			}
		/>
	) : (
		<Radio
			checked={answerData.correct}
			className="exercise-test-answer__checkbox"
			onChange={(event) =>
				onChangeRightAnswer(
					event,
					questionData.id,
					answerData.id,
					inputType
				)
			}
		/>
	)
}

type AddNewAnswerButtonProps = {
	questionData: FormQuestionStateItemType
}

// Кнопка добавления нового ответа
function AddNewAnswerButton(props: AddNewAnswerButtonProps) {
	const { questionData } = props

	return (
		<Button
			onClick={() => addNewAnswer(questionData.id)}
			className="exercise-test-answers__add-button"
		>
			<PlusCircleOutlined width={17} height={17} /> Добавить ответ
		</Button>
	)
}
