import React, { FC } from 'react'
import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'
import { RightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { LiveLibraryStoreType } from '../../zustand/storeTypes'
import { getCurrentPath } from 'widgets/livelesson/liveLesson/fn/liveUtils'

import styles from './FolderName.module.scss'

interface FolderNameProps {
	setSelectedFolder: (
		folder: LiveLibraryStoreType.LibraryFolder | null
	) => void
	selectedFolder: LiveLibraryStoreType.LibraryFolder | null
	storageItems: LiveLessonEntityTypes.StorageItem[] | undefined
}

const FolderName: FC<FolderNameProps> = ({
	setSelectedFolder,
	selectedFolder,
	storageItems,
}) => {
	const { t } = useTranslation()

	if (selectedFolder) {
		return (
			<div className={styles.FolderPath}>
				<span
					className={styles.FolderName}
					onClick={() => setSelectedFolder(null)}
				>
					{t('files.filesTableExercisesFiles')}
				</span>

				{getCurrentPath(storageItems, selectedFolder.id).map((item) => (
					<React.Fragment key={item.id}>
						<RightOutlined style={{ color: '#ACB4C0' }} />
						<span
							className={styles.FolderName}
							onClick={() => setSelectedFolder(item)}
						>
							{item.name}
						</span>
					</React.Fragment>
				))}
			</div>
		)
	}
	return <span>{t('files.filesTableExercisesFiles')}</span>
}

export default FolderName
