import { AxiosError } from 'axios'
import AuthApiTypes from 'parts/requests/auth/authApiTypes'
import { authQuery } from 'parts/requests/auth/authQuery'
import ServerTypes from 'parts/types/ServerTypes'
import useLiveLessonEntrancePageStore from '../../../zustand/store'
import { LiveLessonEntrancePageStoreType } from '../../../zustand/storeTypes'
import { FieldNames, FormValuesType } from './form'
import useSystemStore from 'parts/systemStore/systemStore'
import { SystemStoreType } from 'parts/systemStore/systemStoreTypes'

/**
 * Возвращает обработчик отправки формы регистрации ученика этой школы
 */
export function useGetOnSubmit() {
	const updatePageState = useLiveLessonEntrancePageStore(
		(store) => store.updateState
	)
	const updateSystemStore = useSystemStore((s) => s.updateStore)

	const registerStudentQuery = authQuery.registerStudent.useMutation({
		onSuccess: getMutateOnSuccess(updatePageState, updateSystemStore),
		onError: getMutateOnError(updatePageState),
	})

	return async function (values: FormValuesType) {
		registerStudentQuery.mutate({
			email: values[FieldNames.Email],
			firstName: values[FieldNames.Name],
		})
	}
}

/**
 * Функция, выполняемая после запроса на регистрацию ученика в этой школе
 * @param updatePageState — функция обновляющая Хранилище страницы входа
 */
function getMutateOnSuccess(
	updatePageState: LiveLessonEntrancePageStoreType.UpdateState,
	updateSystemStore: SystemStoreType.UpdateStore
) {
	return function (registerStudentRes: AuthApiTypes.RegisterStudent) {
		localStorage.setItem('token', registerStudentRes.accessToken)
		updateSystemStore({ user: registerStudentRes.user })

		// Перебросить на экран с сообщением про созданного ученика
		updatePageState({ unregisteredStudentStatus: 'accountCreated' })
	}
}

/**
 * Функция, выполняемая после запроса на регистрацию ученика в этой школе
 * @param updatePageState — функция обновляющая Хранилище страницы входа
 */
function getMutateOnError(
	updatePageState: LiveLessonEntrancePageStoreType.UpdateState
) {
	return function (err: AxiosError<ServerTypes.ErrorResponse>) {
		// Перебросить на экран с сообщением про созданного ученика
		updatePageState({ unregisteredStudentStatus: 'error' })
	}
}
