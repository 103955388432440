import { useEffect } from 'react'
import { FormInstance } from 'antd'
import { setErrorsToFields } from 'parts/utils/form'
import useSystemStore from 'parts/systemStore/systemStore'
import usePayModalStore from '../../zustand/store'

export enum FieldsNames {
	Email = 'email',
	Phone = 'phone',
	Agree = 'agree',
}

export type FormValues = {
	[FieldsNames.Email]: string
	[FieldsNames.Phone]: string
	[FieldsNames.Agree]: boolean
}

export function useGetInitialValues() {
	const user = useSystemStore((store) => store.user)

	return {
		[FieldsNames.Email]: user?.email || '',
		[FieldsNames.Phone]: user?.phone || '',
	}
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = usePayModalStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
