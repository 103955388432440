const answersFeedRu = {
	// БЛОК ОТВЕТА
	// Кнопка «Показать упражнение»
	answerBlockShowExerciseButton: 'Показать упражнение',
	// Кнопка удаления ответа «Удалить»
	answerBlockDeleteAnswerButton: 'Удалить',
	// Сообщение про неудавшуюся попытку удаления ответа
	answerBlockDeleteAnswerFailRequest: 'Не удалось удалить ответ.',
	// Статус проверки упражнения: Проверяется
	answerBlockExerciseStatusChecking: 'Проверяется',
	// Статус проверки упражнения: Проверен
	answerBlockExerciseStatusChecked: 'Проверен',
	// Статус проверки упражнения: Не проверен
	answerBlockExerciseStatusNotChecked: 'Не проверен',
	// Роль сотрудника ответившего на выполненное упражнение: Администратор
	answerBlockRoleAdmin: 'Администратор',
	// Роль сотрудника ответившего на выполненное упражнение: Менеджер
	answerBlockRoleManager: 'Менеджер',
	// Роль сотрудника ответившего на выполненное упражнение: Куратор
	answerBlockRoleCurator: 'Куратор',

	// ЛЕНТА ОТВЕТОВ
	// Сообщение про отсутствие упражнений для проверки
	answersFeedNoAnswersScreenText:
		'Ответы на те упражнения, которые требуют писменного или устного ответа, будут отображаться здесь.',
	answersFeedNoAnswersScreenText2:
		'Вы сможете проверить их и дать обратную связь.',
	// Ссылка на все ответы учеников
	answersFeedAllAnswersLink: 'Все',

	// МОДАЛЬНОЕ ОКНО С ПОДТВЕРЖДЕНИЕМ УДАЛЕНИЯ ОТВЕТА
	// Заголовок модального окна подтверждения удаления курса
	answersFeedDeleteModalHeader: 'Удаление ответа',
	// Текст модального окна подтверждения удаления курса
	answersFeedDeleteModalText: 'Вы уверены, что хотите удалить ответ?',
}

export default answersFeedRu
