import React, { useEffect } from 'react'
import { shuffleArray } from 'parts/utils/array'
import useExerciseStore, {
	ExerciseStateType,
} from '../../zustand/exerciseState'

/**
 * Функция создаёт HTML текстового блока с пропусками
 * @param {Object} editorRef — объект со ссылкой на блок текста с пропусками
 * @param {String} htmlString — строка с HTML из которой нужно создать разметку с пропусками
 */
export function useCreateInnerHtml(
	editorRef: React.MutableRefObject<null | HTMLElement>,
	htmlString: string
): void {
	useEffect(
		function () {
			const $editor = editorRef.current as HTMLElement
			if (!$editor) return

			// Получение массива текстов, которые будут в выпадающем списке
			const gapWords = getGapWords(htmlString)

			// Создание строки с разметкой текстового блока с пропусками и вставка в редактор
			$editor.innerHTML = setSelectListToHtmlString(gapWords, htmlString)
		},
		[htmlString]
	)
}

/**
 * Функция создаёт строку с разметкой текстового блока с выпадающими списками.
 * Текст, который используется для создания выпадающих список должен быть в разметке обрамлены фигурными скобками.
 * И функция ставит вместо таких текстов выпадающий список
 * @param {Array} gapWords — массив пропущенных слов
 * @param {String} htmlString — строка с HTML из которой нужно создать разметку с пропусками
 */
function setSelectListToHtmlString(gapWords: string[], htmlString: string) {
	let newHtmlString = htmlString

	let gapWordsIdx = 0 // idx текущего слова из массива пропущенных слов
	let openBraceIdx = 0 // Номер буквы с которого начинается открывающая фигурная скобка
	let closeBraceIdx = 0 // Номер буквы с которого начинается закрывающая фигурная скобка

	for (let i = 0; i < newHtmlString.length; i++) {
		const letter = newHtmlString[i]

		if (letter == '{') {
			openBraceIdx = i
		} else if (letter == '}') {
			closeBraceIdx = i

			// Получить текст, который затем используется для создания выпадающего списка
			const currentGapWord = gapWords[gapWordsIdx++]
			// Создать выпадающий список с выбором возможных слов из которых нужно выбрать правильное
			const selectListStr = createSelectList(currentGapWord)
			// Поставить разметку
			newHtmlString =
				newHtmlString.substring(0, openBraceIdx) +
				selectListStr +
				newHtmlString.substring(closeBraceIdx + 1)

			// Увеличить счётчик на размер вставляемого слова чтобы не делать лишние проходы цикла
			i = i - currentGapWord.length + selectListStr.length
		}
	}

	return newHtmlString
}

/**
 * Возвращает строку разметки содержащей выпадающий список из которого нужно выбрать правильное слово.
 * @param {String} textInBraces — текст из которого создаётся выпадающий список вида {+begin,began,begun} где слова выпадающего списка разделены запятыми, а правильное слово помечено знаком «+».
 */
function createSelectList(textInBraces: string): string {
	const selectListItems = getSelectListItems(textInBraces)

	let correctWord = ''

	const options = selectListItems.map((listItem) => {
		const correct = listItem.correct ? 'true' : 'false'

		if (correct == 'true') {
			correctWord = listItem.word
		}

		return `<option value='${listItem.word}' data-right='${correct}'>${listItem.word}</option>`
	})

	options.unshift('<option value="">Выберите</option>')

	const selectStr = `<select name="" class='exercise-choose-word-content__select'>${options}</select>`

	const rightAnswerSpan = `<span class="exercise-choose-word-content__right-answer">${correctWord}</span>`
	return selectStr + rightAnswerSpan
}

type SelectListItemType = {
	word: string
	correct: boolean
}

function getSelectListItems(textInBraces: string): SelectListItemType[] {
	const words = textInBraces?.split(',').filter((word) => word !== '')

	const wordsDataArr = words.map((word) => {
		const trimmedWord = word.trim()
		const correct = trimmedWord.startsWith('+')

		return {
			word: correct ? trimmedWord.slice(1) : trimmedWord,
			correct,
		}
	})

	// Перемещать элементы массива чтобы было сложнее угадать правильный ответ
	shuffleArray(wordsDataArr)

	return wordsDataArr
}

/**
 * Хук возвращает функцию отслеживающую изменения выпадающих списков с выбором правильного слова
 * @param {Object} textsRef — объект со ссылкой на обёртку текстов с пропусками
 */
export function useSetSelectChangeHandler(
	textsRef: React.MutableRefObject<HTMLDivElement | null>
) {
	const updateStore = useExerciseStore((store) => store.updateStore)

	useEffect(
		function () {
			const $texts = textsRef.current
			if (!$texts) return

			// Создание устанавливаемого обработчика
			const inputHandler = getAfterSelectChangedFn(updateStore)

			$texts.addEventListener('change', inputHandler)

			return function () {
				$texts.removeEventListener('input', inputHandler)
			}
		},
		[textsRef]
	)
}

/**
 * Функция возвращает обработчик ввода текста в пропуски
 */
function getAfterSelectChangedFn(updateStore: ExerciseStateType.UpdateStore) {
	return function (e?: Event) {
		const $select = e?.target

		// При вводе убрать классы поясняющие, что выбрано правильное слово или ошибка
		if ($select) {
			// @ts-ignore
			$select.classList.remove(
				'exercise-choose-word-content__select--success'
			)
			// @ts-ignore
			$select.classList.remove(
				'exercise-choose-word-content__select--fail'
			)
		}

		// Отправить на состояние, где упражнение можно проверить
		updateStore({ stage: 'ReadyForCheck' })
	}
}

/**
 * Функция получает все элементы пропуска
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
function get$Selects($texts: HTMLDivElement): NodeListOf<HTMLSelectElement> {
	return $texts.querySelectorAll('.exercise-choose-word-content__select')
}

/**
 * Функция перебирает выпадающие списки и для каждого запускает переданную функцию, в которую передаёт перебираемый выпадающий список
 * @param {HTMLElement} $texts — обёртка текстов с выпадающими списками
 * @param {Function} handler — функция запускаемая для каждого пропуска в которую передаёт элемент пропуска
 */
function enumerateSelects(
	$texts: HTMLDivElement,
	handler: ($select: HTMLSelectElement) => void
) {
	const $selects = get$Selects($texts)

	for (let i = 0; i < $selects.length; i++) {
		const $select = $selects[i]

		if ($select.nodeType == 1) {
			handler($select as HTMLSelectElement)
		}
	}
}

/**
 * Функция проверяет выбранные значение в выпадающих списках и задаёт им классы в зависимости от правильности выбора
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
export function checkSelectInputs($texts: HTMLDivElement) {
	let allSelectsFilledRight = true // Правильно ли заполнены все пропуски
	let successAnswersCounter = 0 // Количество правильно введённых слов

	enumerateSelects($texts, ($select) => {
		// Option выбранный пользователем
		const selectedOption = $select.options[$select.selectedIndex]

		if (selectedOption.dataset.right == 'true') {
			$select.classList.remove(
				'exercise-choose-word-content__select--fail'
			)
			$select.classList.add(
				'exercise-choose-word-content__select--success'
			)
			successAnswersCounter++
		} else {
			$select.classList.remove(
				'exercise-choose-word-content__select--success'
			)
			$select.classList.add('exercise-choose-word-content__select--fail')
			allSelectsFilledRight = false
		}
	})

	return {
		allSelectsFilledRight,
		successAnswersCounter,
		totalGapsCounter: get$Selects($texts).length,
	}
}

/**
 * Функция показывает правильный вариант слова после выпадающего списка если пользователь выбрал неверный вариант
 * @param {HTMLElement} $texts — обёртка текстов с пропусками
 */
export function showRightAnswers($texts: HTMLDivElement) {
	enumerateSelects($texts, ($select) => {
		if (
			$select.classList.contains(
				'exercise-choose-word-content__select--fail'
			)
		) {
			const $rightWord = $select.nextElementSibling

			if ($rightWord) {
				$rightWord.classList.add(
					'exercise-choose-word-content__right-answer--open'
				)
			}
		}
	})
}

/**
 * Функция возвращает массив пропущенных слов
 * @param {String} htmlString — строка с HTML из которой нужно создать разметку с пропусками
 */
export function getGapWords(htmlString: string) {
	const gapWords: string[] = []

	let collectLetters = false
	let gapWord = ''

	for (let i = 0; i < htmlString.length; i++) {
		const letter = htmlString[i]

		if (letter == '{') {
			collectLetters = true
			continue
		} else if (letter == '}') {
			gapWords.push(gapWord)
			collectLetters = false
			gapWord = ''
		}

		if (collectLetters) {
			gapWord += letter
		}
	}

	return gapWords
}
