import cn from 'classnames'
import { BuildOutlined, CheckOutlined, TeamOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useGetOnEmployeeClick } from './fn/staff'
import { getUserPic } from 'parts/utils/string'
import useAddStaffInGroupModalStore from './zustand/store'
import { AddStaffModalStoreType } from './zustand/storeTypes'

// Список сотрудников, чтобы отметить какие из них должны быть добавлены в группу
function StaffList() {
	const staff = useAddStaffInGroupModalStore((state) => state.staff)
	if (!staff) return null

	return (
		<ul className="add-employee-to-group__stuff">
			{staff.map((employee) => (
				<EmployeeRow employee={employee} key={employee.id} />
			))}
		</ul>
	)
}

export default StaffList

type EmployeeRowProps = {
	employee: AddStaffModalStoreType.Employee
}

// Пункт списка сотрудников
function EmployeeRow(props: EmployeeRowProps) {
	const { employee } = props

	const { t } = useTranslation()

	const onCuratorClick = useGetOnEmployeeClick(employee.id)

	return (
		<li
			className="add-employee-to-group__employee"
			onClick={onCuratorClick}
		>
			<img
				src={getUserPic(employee.avatar)}
				className="add-employee-to-group__employee-avatar"
				alt="Фотография куратора"
			/>
			<p
				className={cn(
					'add-employee-to-group__employee-name',
					employee.addToGroupChecked &&
						'add-employee-to-group__employee-name--strong'
				)}
			>
				{employee.name}
			</p>
			<p className="add-employee-to-group__employee-role">
				{employee.type == 'manager' ? 'Менеджер' : 'Куратор'}
			</p>
			{employee.type == 'curator' && (
				<TeamOutlined className="add-employee-to-group__employee-team-icon" />
			)}
			{employee.type == 'manager' && (
				<BuildOutlined className="add-employee-to-group__employee-group-icon" />
			)}
			<p className="add-employee-to-group__employee-counter">
				{employee.type == 'manager'
					? employee.groupsNumber
					: employee.actualStudentCount}
			</p>
			<CheckOutlined
				className={cn(
					'add-employee-to-group__employee-check',
					employee.addToGroupChecked &&
						'add-employee-to-group__employee-check--visible'
				)}
			/>
		</li>
	)
}
