import { create } from 'zustand'
import { ExercisesListStoreType } from './storeTypes'

// Хранилище для массива упражнений.
const useExercisesListAdminStore = create<ExercisesListStoreType.State>(
	(set) => {
		return {
			dataLoadingStatus: 'loading',
			trainingId: undefined,
			lessonId: undefined as any,
			exercises: undefined as any,
			exerciseId: null,
			newExerciseId: null,
			exerciseType: null,
			formData: null,
			insertBeforeExerciseId: null,

			/** Установщик новых значений корневого объекта. */
			updateStore(obj) {
				return set((state) => {
					return obj
				})
			},
		}
	}
)

export default useExercisesListAdminStore
