import { useQuery } from 'react-query'
import { QueryOptions } from '../common'
import superAdminRequests from './superAdminRequest'
import SuperAdminApiTypes from './superAdminApiTypes'

export const superAdminQuery = {
	// Получение пользователей школы.
	getAdmins() {
		return {
			key: 'getAdmins',
			useQuery(options: QueryOptions<SuperAdminApiTypes.GetUsers> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => superAdminRequests.getAdmins(),
					...options,
				})
			},
		}
	},

	// Получение пользователя с переданным идентификатором
	getUser(userId: string | number) {
		return {
			key: 'getUser-' + userId,
			useQuery(options: QueryOptions<SuperAdminApiTypes.GetUser> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => superAdminRequests.getAdmin(userId),
					...options,
				})
			},
		}
	},
}
