import React from 'react'
import { FormHelpSections } from '../../FormHelp/fn/dataTypes'
import useCondStore from '../zustand/store'

export function useGetSwitchHelpOnFocus() {
	const updateStore = useCondStore((store) => store.updateStore)

	return function (event: React.FocusEvent) {
		const helpSectionName = findHelpSectionElem(
			event
		) as null | FormHelpSections

		if (helpSectionName) {
			updateStore({ helpSection: helpSectionName })
		} else {
			updateStore({ helpSection: FormHelpSections.Default })
		}
	}
}

function findHelpSectionElem(event: React.FocusEvent) {
	let $currentElem = event.target as HTMLElement

	while (true) {
		if (
			$currentElem.tagName.toLowerCase() == 'body' ||
			!$currentElem.parentElement
		) {
			return null
		}

		const helpSectionName = $currentElem.dataset.helpSection

		if (helpSectionName) {
			return helpSectionName
		}

		$currentElem = $currentElem.parentElement
	}
}
