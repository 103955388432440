import { Button, Cascader, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import ModalWithForm from 'ui/ModalWithForm/ModalWithForm'
import {
	UFormNewBottomButtonsAfterGray,
	UFormNewButtonsContainer,
	UFormNewRow,
} from 'ui/UForm/UForm'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'
import { useGetSetVisibilityToAssignEmployeeToGroupModal } from './fn/openCloseModal'
import useAssignEmployeeToGroupModalStore from './zustand/store'
import { useGetOnSubmit } from './fn/submit'
import { FieldNames, setErrorsToForm } from './fn/form'
import TrainingAdminService from 'parts/services/TrainingAdminService'

// Модальное окно добавления кураторов в группу
function AssignEmployeeToGroupModal() {
	const { t } = useTranslation()

	const [form] = Form.useForm()

	const onSubmit = useGetOnSubmit()
	setErrorsToForm(form)

	const modalState = useAssignEmployeeToGroupModalStore((state) => state)
	const closeModal = useGetSetVisibilityToAssignEmployeeToGroupModal(false)

	return (
		<ModalWithForm
			title="Назначение куратора в группу"
			open={modalState.isOpen}
			onCancel={closeModal}
		>
			<Form
				form={form}
				name="assignEmployeeToGroup"
				layout="vertical"
				onFinish={onSubmit}
			>
				<GrayBlock>
					<TrainingsSelect />
				</GrayBlock>
				<Bottom />
			</Form>
		</ModalWithForm>
	)
}

export default AssignEmployeeToGroupModal

// Выпадающий список с выбором курса и группы
function TrainingsSelect() {
	const { t } = useTranslation()

	// TODO Сейчас получается список всех групп. А отсюда нужно исключить группы, в которых сотрудник уже включон.
	const options = TrainingAdminService.useGetTrainingsGroupOptions()

	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.Groups}
				label="Группа"
				rules={[
					{
						required: true,
						message: 'Укажите группу',
					},
				]}
			>
				<Cascader options={options} multiple />
			</Form.Item>
		</UFormNewRow>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
// Кнопки подтверждения и закрытия окна
function Bottom() {
	const { t } = useTranslation()

	const isSubmitting = useAssignEmployeeToGroupModalStore(
		(state) => state.isSubmitting
	)
	const closeModal = useGetSetVisibilityToAssignEmployeeToGroupModal(false)

	return (
		<UFormNewBottomButtonsAfterGray>
			<div />
			<UFormNewButtonsContainer>
				<Button onClick={closeModal}>Отмена</Button>
				<Button
					type="primary"
					htmlType="submit"
					disabled={isSubmitting}
					loading={isSubmitting}
				>
					Добавить
				</Button>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
