import React, { Suspense } from 'react'
import UrlService from 'parts/services/UrlService'
import { liveLessonQuery } from 'parts/requests/liveLesson/liveLessonQuery'
import Loader from 'ui/Loader/Loader'
import { useFillInStore } from './fillInStore'
const Live = React.lazy(() => import('../Live'))

type GetLiveLessonRouterReturn = {
	status: 'loading' | 'hasLivelesson' | 'noLivelessons'
	element: JSX.Element
}

/** Хук возвращающий статус компонента Livelesson и сам компонент в зависимости от статуса */
export function useGetLiveLessonRouter(): GetLiveLessonRouterReturn {
	// Получить live-урок
	const liveId = UrlService.useGetLiveId()

	const { status, data } = liveLessonQuery.getLiveLesson(liveId).useQuery()

	useFillInStore()

	if (status == 'loading') {
		return {
			status: 'loading',
			element: <Loader />,
		}
	} else if (status == 'success' && data) {
		return {
			status: 'hasLivelesson',
			element: (
				<Suspense fallback={<Loader />}>
					<Live />,
				</Suspense>
			),
		}
	} else {
		return {
			status: 'noLivelessons',
			element: <p>noLivelessons</p>,
		}
	}
}
