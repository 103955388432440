import { ReactNode, useEffect, useState } from 'react'
import {
	EnvironmentOutlined,
	MailOutlined,
	PhoneOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import useEmployeeStore from '../../zustand/store'
import AppUrls from 'parts/constants/pageUrl'
import { PageHeaderTab } from 'ui/pageHeader/Tabs/Tabs'
import EntityTypes from 'parts/types/EntityTypes'
import { HeaderRouteType } from 'ui/pageHeader/PageHeader/fn/types'
import MetaOutputText from 'ui/MetaOutputText/MetaOutputText'

export const breadcrumbs: HeaderRouteType[] = [
	{
		path: AppUrls.Staff().url,
		breadcrumbName: AppUrls.Staff().name,
	},
]

/** Возвращает данные для формирования вкладок */
export function useGetTabsData() {
	const employee = useEmployeeStore((state) => state.employee)
	const [tabsData, setTabsData] = useState<PageHeaderTab[]>([])

	useEffect(
		function () {
			if (!employee) return

			setTabsData([
				{
					label: AppUrls.Employee_Works(employee.id).name,
					address: AppUrls.Employee_Works(employee.id).url,
				},
				// Временно скрыть потому что страница ещё не сделана
				/*{
					label: AppUrls.Employee_Rights(employee.id).name,
					address: AppUrls.Employee_Rights(employee.id).url,
				},*/
			])
		},
		[employee]
	)

	return tabsData
}

/** Возвращает объект настройки компонента с серой текстом с деталями сотрудника */
export function useGetMetaPanelConfig(): ReactNode[] {
	const { t } = useTranslation()
	const employee = useEmployeeStore((state) => state.employee)

	if (!employee) return []

	return [
		<MetaOutputText
			textConfigs={[
				{
					text:
						employee.role == EntityTypes.UserRole.MANAGER
							? 'Менеджер'
							: 'Куратор',
					strong: true,
				},
			]}
		/>,
		<MetaOutputText
			icon={MailOutlined}
			textConfigs={[
				{
					text: employee.email,
				},
			]}
		/>,
		<MetaOutputText
			icon={PhoneOutlined}
			textConfigs={[
				{
					text: employee.phone,
				},
			]}
		/>,
		<MetaOutputText
			icon={EnvironmentOutlined}
			textConfigs={[
				{
					text: getLocation(employee),
				},
			]}
		/>,
	]
}

function getLocation(employee: EntityTypes.User) {
	if (employee.country && employee.city) {
		return employee.country + ', ' + employee.city
	} else if (employee.country) {
		return employee.country
	} else if (employee.city) {
		return employee.city
	}

	return ''
}
