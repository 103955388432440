import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'

/** Возвращает заголовок панели с настройками */
export function useGetHeaderText() {
	const { tariffs, activeTariffId } = tariffsManager.store
	if (!activeTariffId) return ''

	const tariffIdx = tariffsManager.findTariffIndex(tariffs, activeTariffId)

	return 'Настройки карточки № ' + (tariffIdx + 1)
}

/** Возвращает обработчик нажатия на кнопку скрытия панели настроек */
export function useGetClosePanel() {
	return function () {
		tariffsManager.store.updateStore({
			isPanelOpen: false,
			activeTariffId: null,
		})
	}
}
