import React from 'react'
import HistoryModal from '../common/HistoryModal/HistoryModal'
import { Button, Form, FormInstance, Input } from 'antd'
import { useGetToggleVisibilityGiveImpressionModal } from './fn/openCloseGiveImpressionModal'
import { useGetOnSubmit } from './fn/submit'
import useGiveImpressionFormStore from './zustand/store'
import s from './GiveImpressionModal.module.scss'

function GiveImpressionModal() {
	const [form] = Form.useForm()

	const isOpen = useGiveImpressionFormStore((store) => store.isOpen)
	const closeGiveImpressionModal = useGetToggleVisibilityGiveImpressionModal()

	return (
		<HistoryModal
			iconSrc="/images/subscriptionsHistory/CommentOutlined.svg"
			title="Поделитесь впечатлениями о платформе!"
			open={isOpen}
			footerButtons={[<ShareFeedbackButton form={form} key={1} />]}
			onCancel={closeGiveImpressionModal}
		>
			<GiveImpressionForm form={form} />
		</HistoryModal>
	)
}

export default GiveImpressionModal

type ReasonFormProps = {
	form: FormInstance
}

function GiveImpressionForm(props: ReasonFormProps) {
	const { form } = props

	const updateStore = useGiveImpressionFormStore((store) => store.updateStore)

	return (
		<div>
			<p className={s.topText}>
				Расскажите, как мы можем улучшить сервис Credu. Так, вы сможете
				сделать нас лучше
			</p>
			<div className={s.personBlock}>
				<img
					src="/images/subscriptionsHistory/marat.png"
					alt="marat"
					className={s.personBlockPic}
				/>
				<div>
					<p className={s.personBlockName}>Марат Бахмедов</p>
					<p className={s.personBlockPosition}>
						CEO и основатель Credu.ai
					</p>
				</div>
			</div>
			<Input.TextArea
				rows={3}
				placeholder="Расскажите, что бы вы хотели изменить..."
				onChange={(val) => {
					updateStore({ expressionValue: val.target.value })
				}}
			/>
		</div>
	)
}

type SaveSubscriptionButtonProps = {
	form: FormInstance
}

function ShareFeedbackButton(props: SaveSubscriptionButtonProps) {
	const { form } = props

	const isSubmitting = useGiveImpressionFormStore(
		(store) => store.isSubmitting
	)

	const shareFeedback = useGetOnSubmit()

	const formErrors = form
		.getFieldsError()
		.filter(({ errors }) => errors.length)

	const expressionValue = useGiveImpressionFormStore(
		(store) => store.expressionValue
	)

	return (
		<Button
			disabled={isSubmitting || formErrors.length > 0 || !expressionValue}
			onClick={shareFeedback}
			type="primary"
		>
			Поделиться отзывом
		</Button>
	)
}
