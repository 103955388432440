import React, { useCallback } from 'react'
import { produce } from 'immer'
import ExerciseStateType from './ExerciseStateType'
import { getStatement } from './main'

/**
 * Возвращает строку с текущим номером утверждения и общим количеством. Например 2 из 5.
 * @param {Object} exerciseState — объект состояния упражнения
 */
export function getStatementCounter(exerciseState: ExerciseStateType.Main) {
	return (
		exerciseState.currentStatementIdx +
		1 +
		'/' +
		exerciseState.statements.length
	)
}

/**
 * Возвращает текст на кнопке «Вперёд».
 * @param {Object} exerciseState — объект состояния упражнения
 */
export function getResultButtonText(exerciseState: ExerciseStateType.Main) {
	let text = 'Дальше'

	if (exerciseState.readyToShowResult) {
		text = 'Показать результат'
	}

	return text
}

/**
 * Обработчик щелчка по кнопке «Вперёд».
 * @param {Object} exerciseState — объект состояния
 * @param {Function} setExerciseState — функция устанавливающая новый объект состояния
 */
export function useGetOnNextButtonClick(
	exerciseState: ExerciseStateType.Main,
	setExerciseState: React.Dispatch<
		React.SetStateAction<ExerciseStateType.Main>
	>
) {
	return useCallback(
		function () {
			const newState = produce(exerciseState, (draft) => {
				const nextIdx = draft.currentStatementIdx + 1

				// Увеличить idx показываемого утверждения чтобы отрисовать следующий если он существует
				if (draft.statements.length > nextIdx) {
					draft.currentStatementIdx++
				}

				// Если стоит флаг readyToShowResult, то поставить флаг showResult чтобы показать результат
				if (draft.readyToShowResult) {
					draft.showResult = true
				}
			})

			setExerciseState(newState)
		},
		[exerciseState]
	)
}

/**
 * Обработчик щелчка на кнопку «Ложь» или «Правда»
 * @param {Object} exerciseState — объект состояния
 * @param {Function} setExerciseState — функция устанавливающая новый объект состояния
 * @param {Number} statementId — id утверждения
 * @param {String} buttonType  — тип кнопки false (кнопка «Ложь»), true (кнопка «Правда»)
 */
export function useGetOnTrueFalseButtonClick(
	exerciseState: ExerciseStateType.Main,
	setExerciseState: React.Dispatch<
		React.SetStateAction<ExerciseStateType.Main>
	>,
	statementId: undefined | number,
	buttonType: 'false' | 'true'
) {
	return useCallback(
		function () {
			if (!statementId) return

			const newState = produce(exerciseState, (draft) => {
				const statement = getStatement(draft, statementId)
				if (!statement) {
					return draft
				}

				statement.userAnswer = buttonType == 'false' ? 'not' : 'yes'

				if (draft.statements.length == draft.currentStatementIdx + 1) {
					draft.readyToShowResult = true
				}
			})
			setExerciseState(newState)
		},
		[exerciseState, statementId]
	)
}
