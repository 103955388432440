import { useGetToggleVisibilityGiveImpressionModal } from './openCloseGiveImpressionModal'

/** Хук возвращающий обработчик отправки формы */
export function useGetOnSubmit() {
	const closeGiveImpressionModal = useGetToggleVisibilityGiveImpressionModal()

	return function () {
		closeGiveImpressionModal()
	}
}
