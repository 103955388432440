import { useCallback, useContext } from 'react'
import { produce } from 'immer'
import { StateContext } from '../../state/stateContext'
import { StateType } from '../../state/stateType'

/** Возвращает функцию, открывающую модальное окно остановки приёма платежей */
export function useGetOpenDeleteSchoolModal() {
	const { state, setState } = useContext(StateContext)

	return useCallback(
		function () {
			setState((state): StateType.State => {
				return produce(state, (draft) => {
					draft.stopPaySystemModal.isOpen = true
				})
			})
		},
		[state]
	)
}

/** Возвращает функцию, закрывающую модальное окно остановки приёма платежей */
export function useGetCloseStopPaymentModal() {
	const { state, setState } = useContext(StateContext)

	return useCallback(
		function () {
			if (state.stopPaySystemModal.isSubmitting) {
				return
			}

			setState((state): StateType.State => {
				return produce(state, (draft) => {
					draft.stopPaySystemModal.isOpen = false
				})
			})
		},
		[state]
	)
}
