import React from 'react'
import ExerciseContainer, {
	ExerciseContainerType,
} from '../../common/ExerciseContainer/ExerciseContainer'
import AudioFrame from 'ui/AudioFrame/AudioFrame'
import { TranscriptionBlock } from '../../common/TranscriptionBlock/TranscriptionBlock'
import { TaskExerciseView } from '../../common/TaskExerciseView/TaskExerciseView'
import {
	SaveExerciseChangesMadeByUser,
	ExerciseChangesDataMadeByUser,
} from '../../common/useLiftViewDuration'
import MediaAnswerBlock from '../../mediaAnswerBlock/MediaAnswerBlock/MediaAnswerBlock'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import ExerciseControlPanel from '../ExerciseControlPanel/ExerciseControlPanel'
import useExerciseStore from '../zustand/exerciseState'
import { useFillStore } from '../zustand/fn/fillStore'
import {
	useRestoreStore,
	useSaveStateOnServerForRestore,
} from './fn/saveAndRestoreState'
import { useGetAnswerWasSent } from './fn/useGetDisableDoneButton'
import './AudioExerciseView.scss'

type AudioExerciseViewProps = {
	// Для какого типа страницы предназначается обёртка упражнения:
	// constructorPage — страница конструктора. Будут скрыты кнопки перехода по упражнениям и кнопки снизу
	// exercisePage — страница упражнения для ученика. Будет полный набор элементов.
	exerciseContainerType: ExerciseContainerType
	exercise: TrainingEntityTypes.AudioExercise
	// Адрес предыдущего и следующего упражнения
	prevExerciseUrl?: string
	nextExerciseUrl?: string
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

// Вывод аудио-упражнения в уроке
function AudioExerciseView(props: AudioExerciseViewProps) {
	const {
		exerciseContainerType,
		exercise,
		prevExerciseUrl,
		nextExerciseUrl,
		saveExerciseChangesMadeByUser,
		exerciseRestoreData,
	} = props

	useFillStore(exercise)

	const answerWasSent = useExerciseStore((store) => store.answerWasSent)

	const answerWasSentFn = useGetAnswerWasSent()

	useSaveStateOnServerForRestore(saveExerciseChangesMadeByUser)
	useRestoreStore(exerciseRestoreData)

	return (
		<ExerciseContainer
			type={exerciseContainerType}
			prevExerciseUrl={prevExerciseUrl}
			nextExerciseUrl={nextExerciseUrl}
		>
			<>
				{exercise.item.task && (
					<TaskExerciseView htmlString={exercise.item.task} />
				)}
				<div className="exercise-audio-content__audios-wrapper">
					{exercise.item.audio.map((audioHref, idx) => {
						if (idx < exercise.item.audio.length - 1) {
							return (
								<React.Fragment key={idx}>
									<AudioFrame src={audioHref} />
									<div className="exercise-audio-content__audios-hr" />
								</React.Fragment>
							)
						}
						return <AudioFrame src={audioHref} key={idx} />
					})}
				</div>
				<TranscriptionBlock exercise={exercise} />
				<MediaAnswerBlock
					exercise={exercise}
					writingOrAudioAnswerWasSentFn={answerWasSentFn}
					answerWasSentAlready={!answerWasSent}
				/>
			</>
			<ExerciseControlPanel />
		</ExerciseContainer>
	)
}

export default AudioExerciseView
