import React, { useContext, useEffect, useState } from 'react'
import { FormInstance } from 'antd'
import { AxiosError } from 'axios'
import ServerTypes from 'parts/types/ServerTypes'
import schoolRequests from 'parts/requests/school/schoolRequest'
import SchoolApiTypes from 'parts/requests/school/schoolApiTypes'
import { FieldNames, FormValuesType } from './form'
import { StateContext } from '../../state/stateContext'
import { setErrorsToFields } from 'parts/utils/form'
import Site from 'parts/constants/site'
import { getFirstLoadedFileUrl } from 'ui/upload/UploadFileInForm/fn/fn'

/**
 * Функция возвращающая данные для работы формы
 * @param {Object} form — объект формы
 */
export function useManageForm(form: FormInstance) {
	// Идёт ли загрузка данных
	const [isLoading, setIsLoading] = useState(false)
	// В случае ошибки сюда попадут данные ошибки
	const [formErrors, setFormErrors] = useState<ServerTypes.ErrorResponse>({})

	// Обработчик отправки формы
	const onSubmit = useGetOnSubmit(setIsLoading, setFormErrors)

	// В случае появления ошибок показать их в форме
	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])

	return {
		isLoading,
		onSubmit,
		formErrors,
	}
}

/**
 * Хук возвращающий обработчик отправки формы
 * @param {Function} setIsLoading — функция устанавливающая статус ожидания ответа от сервера.
 * @param {Function} setFormErrors — функция устанавливающая объект ошибок в Состояние ошибок
 */
function useGetOnSubmit(
	setIsLoading: (isLoading: boolean) => void,
	setFormErrors: React.Dispatch<React.SetStateAction<{}>>
) {
	const { state } = useContext(StateContext)

	return async (values: FormValuesType) => {
		const { school } = state
		if (!school) return

		try {
			// Поставить статус загрузки
			setIsLoading(true)

			// Отправить запрос и получить ответ
			const response = await schoolRequests.updateSchool(
				getDto(values, school.id || 1)
			)

			if (response.data) {
				// Тут можно что-то сделать в случае положительного ответа...
			}
		} catch (err) {
			const error = err as AxiosError<ServerTypes.ErrorResponse>

			if (error.response) {
				// Поставить данные ошибки в Состояние, чтобы показать их в форме
				setFormErrors(error.response.data)
			}
		} finally {
			// Убрать статус загрузки
			setIsLoading(false)
		}
	}
}

/**
 * Формирует DTO для отправки запроса
 * @param {Array} values — массив значений полей формы
 * @param {Number} schoolId — id школы
 */
function getDto(
	values: FormValuesType,
	schoolId: number
): SchoolApiTypes.UpdateSchoolDto {
	return {
		id: schoolId,
		name: values[FieldNames.SchoolName],
		domain: values[FieldNames.Domain].toLowerCase() + '.' + Site.rootDomain,
		description: values[FieldNames.Description],
		cover: getFirstLoadedFileUrl(values[FieldNames.Cover]),
		oferta: getFirstLoadedFileUrl(values[FieldNames.Oferta]),
	}
}
