import { useGetChangeModalVisibility } from '../../../modal/MakeOrderModal/fn/fn'
import { useGetCurrencySign } from './fn/price'
import cn from 'classnames'
import EntityTypes from 'parts/types/EntityTypes'
import useLandingBuilderStore from '../../../../zustand/store'
import './AdditionalPrice.scss'

type AdditionalPriceProps = {
	tariff: EntityTypes.Tariff
	price: EntityTypes.TariffPrice
}

// Блок настройки дополнительной цены ниже карточки тарифа
function AdditionalPrice(props: AdditionalPriceProps) {
	const { tariff, price } = props

	const colorScheme = useLandingBuilderStore(
		(store) => store.landing.colorScheme
	)

	return (
		<div className="p-additional-price">
			<Text tariff={tariff} price={price} />
			<div className="p-additional-price__prices">
				<div
					className={cn(
						'p-additional-price__price-wrapper',
						'p-additional-price__price-wrapper--' + colorScheme
					)}
				>
					<Price type="current" price={price} />
					<CurrentCurrencySign type="current" price={price} />
				</div>
				<div className="p-additional-price__price-wrapper p-additional-price__price-wrapper--old">
					<Price type="old" price={price} />
					<CurrentCurrencySign type="old" price={price} />
				</div>
			</div>
			<BuyButton tariff={tariff} price={price} />
		</div>
	)
}

export default AdditionalPrice

// Текст левее цен
function Text(props: AdditionalPriceProps) {
	const { price } = props

	return <p className="p-additional-price__text">{price.text}</p>
}

type PriceProps = {
	type: 'current' | 'old'
	price: EntityTypes.TariffPrice
}

// Цена
function Price(props: PriceProps) {
	const { type, price } = props

	if (type === 'old' && price.oldPrice <= 0) return null

	const priceNum = type === 'current' ? price.price : price.oldPrice

	return <p className="p-additional-price__price">{priceNum}</p>
}

type CurrencySignProps = {
	type: 'current' | 'old'
	price: EntityTypes.TariffPrice
}

// Значок валюты
function CurrentCurrencySign(props: CurrencySignProps) {
	const { type, price } = props

	const currencySign = useGetCurrencySign(price)

	if (type === 'old' && price.oldPrice <= 0) {
		return null
	}

	return <span>{currencySign}</span>
}

// Кнопка покупки
function BuyButton(props: AdditionalPriceProps) {
	const { tariff, price } = props

	const colorScheme = useLandingBuilderStore(
		(store) => store.landing.colorScheme
	)

	const openModal = useGetChangeModalVisibility(true, tariff, price)

	return (
		<button
			className={cn(
				'p-additional-price__buy-button',
				'p-additional-price__buy-button--' + colorScheme
			)}
			onClick={openModal}
		>
			{price.buttonText || 'Записаться'}
		</button>
	)
}
