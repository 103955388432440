import { useTranslation } from 'react-i18next'
import Loader from 'ui/Loader/Loader'
import NoContent from 'ui/NoContent/NoContent'
import { useFillInStore } from '../../zustand/fillStore/fillInStore'
import useTrainingsStudentStore from '../../zustand/store'
import TrainingsListStudent from '../TrainingsListStudent'

type RouterReturn = {
	status: 'loading' | 'error' | 'noData' | 'hasData'
	element: JSX.Element
}

/** Хук возвращающий статус компонента TrainingList и сам компонент в зависимости от статуса */
export function useGetTrainingListRouter(): RouterReturn {
	const dataLoadingStatus = useTrainingsStudentStore(
		(state) => state.dataLoadingStatus
	)

	const { t } = useTranslation()

	// Получить список курсов, преобразовать данные под формат для отрисовки карточек курсов и поставить в Состояние
	useFillInStore()

	if (dataLoadingStatus == 'loading') {
		return {
			status: 'loading',
			element: <Loader />,
		}
	} else if (dataLoadingStatus === 'error') {
		return {
			status: 'error',
			element: (
				<p>{t('studentTrainings.trainingsPageErrorWhileGetData')}</p>
			),
		}
	} else if (dataLoadingStatus == 'noData') {
		return {
			status: 'noData',
			element: (
				<NoContent
					header={t('studentTrainings.trainingsPageNoContentHeader')}
					text={t('studentTrainings.trainingsPageNoContentText')}
				/>
			),
		}
	}

	return {
		status: 'hasData',
		element: <TrainingsListStudent />,
	}
}
