import React, { Dispatch, SetStateAction } from 'react'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

export namespace StateType {
	// Тип Состояния формы генерирования текста задания
	export type State = {
		// Тип упражнения
		exerciseType: TrainingEntityTypes.ExerciseTypesWithGenerator
		// Раскрыта ли форма генерирования текста упражнения
		open: boolean
		// Текст где написано, что требуется вписать в поле
		introText: string
		// Примеры текстов, которые можно вписать в поле
		examples: string[]
		// Текст ниже примеров
		bottomText?: string
		// Статус жизненного цикла формы:
		// empty — в поле команды ничего не введено
		// filled — в поле команды введены символы
		// loading — получение данных от ChatGPT
		// success — получен успешный ответ от ChatGPT
		// error — получен ошибочный ответ от ChatGPT
		lifeStatus: LifeStatus
		// Текст запроса, который будет отправлен в ChatGPT.
		promptText: string
		// Функция получающая сгенерированный текст упражнения,
		// чтобы разобрать его и поставить в Состояние самого упражнения для отрисовки
		getGeneratedText: (text: string) => void
		// Функция получающая задание на генерирование текста
		// для сохранения в данных упражнения
		getGeneratedPrompt: (text: string) => void
		// Сообщение об ошибке
		error: null | string
	}

	export type LifeStatus =
		| 'empty'
		| 'filled'
		| 'loading'
		| 'success'
		| 'error'

	// Тип Состояния формы генерирования текста задания
	export type SetState = React.Dispatch<React.SetStateAction<State>>
}

export type SetStateType = Dispatch<SetStateAction<StateType.State>>

export const StateContext = React.createContext<{
	state: StateType.State
	setState: SetStateType
}>({ state: {} as StateType.State, setState: () => {} })
