import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import AppUrls from 'parts/constants/pageUrl'
import AnswersFeedContainer from '../AnswersFeedRouter/AnswersFeedRouter'
import Footer from 'ui/Footer/Footer'
import { useGetUserRole } from 'parts/utils/hooks/hooks'

/** Страница ленты ответов */
function AnswersFeedPage() {
	const userRoles = useGetUserRole()

	return (
		<PageSkeleton>
			<PageHeader
				breadcrumbs={[
					{
						path: AppUrls.AnswersFeed().url,
						breadcrumbName: AppUrls.AnswersFeed().name,
					},
				]}
				header={AppUrls.AnswersFeed().name}
			/>
			<ArticleContentWrapper innerWrapperWidth="big">
				<AnswersFeedContainer />
			</ArticleContentWrapper>
			<Footer isRightSideHidden={!userRoles.isAdminOrManager} />
		</PageSkeleton>
	)
}

export default AnswersFeedPage
