import { LandingTypes } from 'parts/types/LandingTypes'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'

export enum FieldNames {
	ColorScheme = 'colorScheme',
}

export type FormValuesType = {
	[FieldNames.ColorScheme]: LandingTypes.ColorScheme
}

/** Возвращает первоначальные значения полей формы редактирования ученика. */
export function useGetInitialValues(): FormValuesType {
	const landingStore = useLandingConstructorStore((state) => state)

	return {
		[FieldNames.ColorScheme]: landingStore.landing!.colorScheme,
	}
}

/**
 * Возвращает массив с двумя классами, которыми нужно поставить на левый и правый кружки в переключателях выбора сцетовой схемы сайта.
 * @param paletteNum – номер цветовой схемы сайта
 */
export function getPaletteRadioClasses(
	paletteNum: 1 | 2 | 3 | 4
): [string, string] {
	return [
		'land-const-color-scheme-modal__radio-circle land-const-color-scheme-modal__radio-circle-left land-const-color-scheme-modal__radio-circle-left--colorScheme_' +
			paletteNum,
		'land-const-color-scheme-modal__radio-circle land-const-color-scheme-modal__radio-circle-right land-const-color-scheme-modal__radio-circle-right--colorScheme_' +
			paletteNum,
	]
}
