import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AppUrls from 'parts/constants/pageUrl'
import UrlService from 'parts/services/UrlService'
import { TrainingsStoreType } from '../../../zustand/storeTypes'

/**
 * Обработчик щелчка по карточке курса
 * @param {Object} training — объект курса
 */
export function useGetOnCardClick(training: TrainingsStoreType.TrainingCard) {
	const navigate = useNavigate()

	return useCallback(function () {
		// Для адресной строки составить идентификатор курса вместе
		// с идентификатором группы потому что эта информация нужна
		const trainingId = UrlService.createTrainingAndGroupIdsUrl(
			training.id,
			training.groupId
		)

		if (
			training.trainingStatus === 'openPartly' ||
			training.trainingStatus === 'openFully' ||
			training.trainingStatus === 'firstLessonOpeningWait' ||
			training.trainingStatus === 'groupEnded'
		) {
			navigate(AppUrls.Training(trainingId).url)
		}
	}, [])
}
