import { AxiosResponse } from 'axios'
import { useMutation, useQuery, UseQueryOptions } from 'react-query'
import schoolRequests from './schoolRequest'
import { MutationOptions, QueryOptions } from '../common'
import SchoolApiTypes from './schoolApiTypes'

export const schoolQuery = {
	// Получение списка школ администратора
	getSchoolsByUser: {
		key: 'getSchoolsByUser',
		useQuery(
			userId: number | string,
			options: QueryOptions<SchoolApiTypes.GetSchoolsByUser> = {}
		) {
			return useQuery({
				queryKey: [this.key],
				queryFn: () => schoolRequests.getSchoolsByUser(userId),
				...options,
			})
		},
	},

	// Получение школы
	getSchool(schoolId: number | string) {
		return {
			key: 'getSchool-' + schoolId,
			useQuery(options: QueryOptions<SchoolApiTypes.GetSchool> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => schoolRequests.getSchool(schoolId),
					...options,
				})
			},
		}
	},

	// Получение текущей школы
	getCurrentSchool: {
		key: 'getCurrentSchool',
		useQuery(options: QueryOptions<SchoolApiTypes.GetCurrentSchool> = {}) {
			return useQuery({
				queryKey: [this.key],
				queryFn: schoolRequests.getCurrentSchool,
				...options,
			})
		},
	},

	// Добавление новой школы
	addSchool: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(schoolRequests.addSchool, options)
		},
	},

	// Добавление новой школы авторизованным пользователем
	addSchoolByAuthorizedUser: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(
				schoolRequests.addSchoolByAuthorizedUser,
				options
			)
		},
	},

	// Обновление данных школы
	updateSchool: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(schoolRequests.updateSchool, options)
		},
	},

	// Удаление школы
	deleteSchool: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(schoolRequests.deleteSchool, options)
		},
	},

	// Удаление пользователя (не действует на администратора)
	deleteUser: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(schoolRequests.deleteUser, options)
		},
	},

	// Получение массива с данными подключения к платёжным системам конкретной школы
	getPaymentsData: {
		key: 'getPaymentsData',
		useQuery(
			schoolId: string | number,
			options: UseQueryOptions<
				AxiosResponse<SchoolApiTypes.GetPaymentData>
			> = {}
		) {
			return useQuery({
				queryKey: [this.key],
				queryFn: () => schoolRequests.getPaymentsData(schoolId),
				...options,
			})
		},
	},

	// Добавление и изменение данных по взаимодействию с платёжной системой у школы
	updatePaymentData: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(schoolRequests.updatePaymentData, options)
		},
	},

	// Включение/отключения платёжной системы у школы
	setPaymentConnection: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(schoolRequests.setPaymentConnection, options)
		},
	},

	// Проверка правильности сохранённых данных для подключения к платёжной системе
	checkPaymentData: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(schoolRequests.checkPaymentData, options)
		},
	},
}
