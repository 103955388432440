import { EXERCISE_ATTEMPTS } from '../../../common/exerciseConst'
import useExerciseStore, {
	ExerciseStateType,
} from '../../zustand/exerciseState'

/**
 * Хук возвращает функцию заново запускающую процесс прохождения упражнения
 */
export function useGetPassExerciseAgainFn() {
	const questions = useExerciseStore((store) => store.questions)
	const saveStoreForRestore = useExerciseStore(
		(store) => store.saveStoreForRestore
	)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		const updatedQuestions = questions.map((question) => {
			const newAnswers = question.answers.map((answer) => {
				return { ...answer, checked: false }
			})
			return { ...question, showCorrect: false, answers: newAnswers }
		})

		updateStore({
			stage: 'NotReadyToCheck',
			currentQuestionIdx: 0,
			questions: updatedQuestions,
			saveStoreForRestore: saveStoreForRestore + 1,
			attempts: EXERCISE_ATTEMPTS,
		})
	}
}

/**
 * Получение количество пройденных и общее количество вопросов.
 */
export function useGetCalculatedStats(questions: ExerciseStateType.Question[]) {
	const passed = getPassedQuestionsNumber(questions)
	const total = questions.length

	return {
		passedNum: passed,
		totalNum: total,
	}
}

/**
 * Возвращает количество пройденных вопросов.
 * @param questions
 */
function getPassedQuestionsNumber(questions: ExerciseStateType.Question[]) {
	let passedQuestions = 0

	questions.forEach((question) => {
		// Изначально буду считать, что вопрос отвечен правильно
		let allAnswersAreCorrect = true

		question.answers.forEach((answer) => {
			if (
				(answer.correct && !answer.checked) ||
				(answer.checked && !answer.correct)
			) {
				allAnswersAreCorrect = false
			}
		})

		if (allAnswersAreCorrect) {
			passedQuestions++
		}
	})

	return passedQuestions
}
