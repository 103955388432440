import { create } from 'zustand'
import { EmployeeStoreType } from './storeTypes'

const useEmployeeStore = create<EmployeeStoreType.State>((set) => {
	return {
		employee: undefined,

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useEmployeeStore
