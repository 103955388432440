import useAssignEmployeeToGroupModalStore from '../zustand/store'

/**
 * Возвращает функцию, открывающую/закрывающую модальное окно добавления сотрудника в группу
 * @param isVisible — должно ли окно быть видимым
 */
export function useGetSetVisibilityToAssignEmployeeToGroupModal(
	isVisible: boolean
) {
	const updateStore = useAssignEmployeeToGroupModalStore(
		(state) => state.updateStore
	)

	return function () {
		updateStore({ isOpen: isVisible })
	}
}
