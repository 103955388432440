import { Button, Form, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { FieldsNames, useGetOnCountryChange } from './fn/onCountryChange'

// Форма поиска по почте
function CountryForm() {
	const [form] = Form.useForm()
	const onSubmit = useGetOnCountryChange()

	return (
		<Form form={form} onFinish={onSubmit} className="leads-table__form">
			<Form.Item name={FieldsNames.Country}>
				<Input />
			</Form.Item>
			<Button htmlType="submit" icon={<SearchOutlined />} />
		</Form>
	)
}

export default CountryForm
