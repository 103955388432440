import { useState } from 'react'

export type ExerciseStateType = {
	startTime: null | Date // Время начала прохождения теста
	totalGaps: number // Общее количество вопросов
	rightAnswers: number // На сколько вопросов ученик дал ответ
	attempts: number // Количество доступных попыток
	unfilledSelects: number // Сколько есть незаполненных дырок

	showResult: boolean // Показать ли окно результата
	readyToShowResult: boolean // Готово ли упражнение для показа результата (правильно введены все слова или вышли попытки)
	resultButtonText: string // Текст на кнопке показа результата
}

/** Функция создаёт местное Состояние для упражнения */
export function useCreateExerciseState() {
	const [exerciseState, setExerciseState] =
		useState<ExerciseStateType>(getInitialState)

	return {
		exerciseState,
		setExerciseState,
	}
}

export function getInitialState(): ExerciseStateType {
	return {
		startTime: null,
		totalGaps: 0,
		rightAnswers: 0,
		attempts: 3,
		unfilledSelects: 0,

		showResult: false,
		readyToShowResult: false,
		resultButtonText: 'Проверить',
	}
}
