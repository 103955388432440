import React from 'react'
import MetaOutput from 'ui/MetaOutput/MetaOutput'
import EditableHeader from 'ui/pageHeader/EditableHeader/EditableHeader'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import { useGetBreadcrumbs } from './fn/breadcrumbs'
import { useGetTabsData } from './fn/getTabsData'
import {
	useGetInitialHeaderText,
	useGetSaveHeaderTextOnServerAfterChange,
} from './fn/header'
import { useGetMetaPanelConfig } from './fn/metaPanel'

function GroupPageHeader() {
	const breadcrumbs = useGetBreadcrumbs()

	const initialHeaderText = useGetInitialHeaderText()
	const saveHeader = useGetSaveHeaderTextOnServerAfterChange()

	const metaPanelConfig = useGetMetaPanelConfig()
	const tabsData = useGetTabsData()

	return (
		<PageHeader
			breadcrumbs={breadcrumbs}
			header={
				<EditableHeader
					initialValue={initialHeaderText}
					afterChange={saveHeader}
				/>
			}
			meta={<MetaOutput items={metaPanelConfig} />}
			tabs={tabsData}
		/>
	)
}

export default GroupPageHeader
