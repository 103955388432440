import React from 'react'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import TakeAPartButton from 'pages/landing/landingConstructor/details/TakeAPartButton/TakeAPartButton'
import SectionHeader from 'pages/landing/landingConstructor/details/SectionHeader/SectionHeader'
import EditableText from 'pages/landing/landingConstructor/details/editableElems/EditableText/EditableText'
import VideoFrame from 'ui/VideoFrame/VideoFrame'
import ControlsFrame from 'pages/landing/landingConstructor/details/ControlsFrame/ControlsFrame'
import { AddVideoUrlModalController } from 'pages/landing/landingConstructor/details/AddVideoUrlModal/ColorSchemeModalController'
import 'pages/landing/landingConstructor/sections/VideoSection/VideoSection.scss'

// Первая секция одностраничника
function VideoSection() {
	return (
		<>
			<section className="land-const-video-section">
				<div className="land-const-video-section__top-part">
					<Content />
				</div>
				<div className="land-const-video-section__bottom-part">
					<VideoFrameWrapper />
				</div>
			</section>
			{AddVideoUrlModalController.element}
		</>
	)
}

export default VideoSection

function Content() {
	return (
		<>
			<SectionHeader
				imagePropsPathArr={['sections', 'video', 'header', 'imageUrl']}
				headerPropsPathArr={['sections', 'video', 'header', 'text']}
			/>
			<p className="land-const-video-section__description">
				<EditableText
					propsPathArr={['sections', 'video', 'texts', 'description']}
				/>
			</p>
			<TakeAPartButton sectionName="video" />
		</>
	)
}

function VideoFrameWrapper() {
	const landing = useLandingConstructorStore((store) => store.landing)

	return (
		<ControlsFrame linkBtnHandler={AddVideoUrlModalController.open}>
			<VideoFrame
				youTubeLink={landing.sections.video.youtubeVideoUrl}
				extraClass="land-const-video-section__iframe"
			/>
		</ControlsFrame>
	)
}
