import dayjs from 'dayjs'
import PaymentTypes from 'parts/types/PaymentTypes'

export namespace LeadsStoreType {
	export type State = {
		// Массив покупок. Null если ещё не скачан.
		leads: null | Lead[]
		// Поисковые значения
		searchValues: SearchValues

		// Метод изменяющий значение свойств
		updateStore: UpdateStore

		// Метод изменяющий значение свойств объекта searchValues
		setSearchValuesProp: SetSearchValuesProp
	}

	export enum SearchValueNames {
		DateFrom = 'dateFrom',
		DateTo = 'dateTo',
		Email = 'email',
		Phone = 'phone',
		PriceFrom = 'priceFrom',
		PriceTo = 'priceTo',
		Trainings = 'trainings',
		Statuses = 'statuses',
		PaySystems = 'paySystems',
	}

	// Параметры поиска
	export type SearchValues = {
		[SearchValueNames.DateFrom]: null | dayjs.Dayjs
		[SearchValueNames.DateTo]: null | dayjs.Dayjs
		[SearchValueNames.Email]: string
		[SearchValueNames.Phone]: string
		[SearchValueNames.PriceFrom]: undefined | number
		[SearchValueNames.PriceTo]: undefined | number
		[SearchValueNames.Trainings]: number[]
		[SearchValueNames.Statuses]: PaymentTypes.PaymentStatus[]
		[SearchValueNames.PaySystems]: PaymentTypes.System[]
	}

	export type Lead = {
		id: number
		created: string
		email: string
		phone: null | string
		price: string
		trainingName: string
		paymentSystem: null | PaymentTypes.System
		paymentStatus: PaymentTypes.PaymentStatus
	}

	// Установщик новых значений корневого объекта через объект.
	export type UpdateStore = (obj: Partial<State>) => void

	export type SetSearchValuesProp = <K extends SearchValueNames>(
		groupProp: K,
		elemProp: SearchValues[K]
	) => void
}
