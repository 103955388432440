import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'

function sortStorageItems(
	a: LiveLessonEntityTypes.StorageItem,
	b: LiveLessonEntityTypes.StorageItem
) {
	if (a.isFolder && b.isFolder) {
		return 0
	}
	if (a.isFolder && !b.isFolder) {
		return -1
	}
	if (!a.isFolder && !b.isFolder) {
		return 0
	}

	return 1
}

export default sortStorageItems
