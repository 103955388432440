import { create } from 'zustand'
import { EmployeeGroupsStoreType } from './storeTypes'

const useEmployeeGroupsStore = create<EmployeeGroupsStoreType.State>((set) => {
	return {
		employeeGroups: undefined,

		/**
		 * Установщик новых значений корневого объекта.
		 * @param obj — объект со свойствами и значениями, которые должны быть изменены в Сотоянии
		 */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useEmployeeGroupsStore
