import React, { ReactNode } from 'react'
import { Tag } from 'antd'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import {
	UBlockBgColor,
	UBlockBorderColor,
	UCardBlock,
} from 'ui/wrappers/UCardBlock/UCardBlock'
import { CalendarOutlined, HourglassOutlined } from '@ant-design/icons'
import { TrainingStats } from '../TrainingStats/zustand/storeTypes'
import './LessonStatCard.scss'

type LessonStatCardProps = {
	lesson: TrainingStats.Lesson
}

// Карточка урока в статистике посещения уроков курса учеником
function LessonStatCard(props: LessonStatCardProps) {
	const { lesson } = props

	return (
		<div className="visit-training-card">
			<CardNumber lesson={lesson} />
			<CardWrapper lesson={lesson}>
				<CardContent lesson={lesson} />
			</CardWrapper>
		</div>
	)
}

export default LessonStatCard

type CardNumberProps = {
	lesson: TrainingStats.Lesson
}

function CardNumber(props: CardNumberProps) {
	const { lesson } = props

	return (
		<div
			className={cn(
				'visit-training-card__num-wrapper',
				!lesson.isLastLesson && 'visit-training-card__num-wrapper--line'
			)}
		>
			<div
				className={cn(
					'visit-training-card__num',
					'visit-training-card__num--' + lesson.status
				)}
			>
				{lesson.idx}
			</div>
		</div>
	)
}

type CardWrapperProps = {
	lesson: TrainingStats.Lesson
	children: ReactNode
}

function CardWrapper(props: CardWrapperProps) {
	const { lesson, children } = props

	let bgColor = UBlockBgColor.Blue
	let borderColor = UBlockBorderColor.Blue

	if (lesson.status == 'success') {
		bgColor = UBlockBgColor.Green
		borderColor = UBlockBorderColor.Green
	}

	if (lesson.status === 'closed') {
		bgColor = UBlockBgColor.Gray
		borderColor = UBlockBorderColor.Gray
	}

	return (
		<UCardBlock
			bgColor={bgColor}
			borderColor={borderColor}
			extraClass="visit-training-card__wrapper"
		>
			{children}
		</UCardBlock>
	)
}

type CardContentProps = {
	lesson: TrainingStats.Lesson
}

function CardContent(props: CardContentProps) {
	const { lesson } = props

	return (
		<>
			<div className="visit-training-card__top">
				<TextWithIcon
					lesson={lesson}
					Icon={CalendarOutlined}
					text={lesson.openDate}
				/>
				<StatusSticker lesson={lesson} />
			</div>
			<h3 className="visit-training-card__training-name">
				{lesson.name}
			</h3>
			<TextWithIcon
				lesson={lesson}
				Icon={HourglassOutlined}
				text={lesson.duration}
			/>
		</>
	)
}

type TextWithIconProps = {
	lesson: TrainingStats.Lesson
	Icon: any
	text: null | string
}

function TextWithIcon(props: TextWithIconProps) {
	const { lesson, Icon, text } = props
	if (!text) return null

	return (
		<p className="visit-training-card__text-with-icon">
			<Icon
				className={cn(
					'visit-training-card-icon',
					'visit-training-card__icon--' + lesson.status
				)}
			/>
			{text}
		</p>
	)
}

type StatusStickerProps = {
	lesson: TrainingStats.Lesson
}

function StatusSticker(props: StatusStickerProps) {
	const { t } = useTranslation()
	const { lesson } = props

	let color = 'processing'
	let text = 'В процессе'

	if (lesson.status == 'success') {
		color = 'success'
		text = 'Пройден'
	}
	if (lesson.status == 'closed') {
		color = 'default'
		text = 'Не доступен'
	}

	return <Tag color={color}>{text}</Tag>
}
