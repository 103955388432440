import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import trainingRequests from 'parts/requests/training/trainingRequest'
import TrainingApiTypes from 'parts/requests/training/trainingApiTypes'
import { RouterVarNames } from 'app/routes/special/otherRoutes'
import UrlService from 'parts/services/UrlService'
import { WritingOrAudioAnswerWasSentFn } from '../../../common/useLiftViewDuration'

/** Хук возвращает значение редактора и обработчик ввода текста сохраняющий новый текст в местное Состояние */
export function useManageEditor() {
	const [value, setValue] = useState('')

	const onChange = useCallback(function (newValue: string) {
		setValue(newValue)
	}, [])

	return {
		value,
		onChange,
	}
}

/** Хук возвращает булево значение нужно ли показывать сообщение об удачно отправленном ответе и обработчик щелчка по кнопке отправке ответа. */
export function useManageAnswerSending(
	answerText: string,
	// Запуск этой функции сообщает, что ответ был послан
	writingOrAudioAnswerWasSentFn?: WritingOrAudioAnswerWasSentFn
) {
	const params = useParams()
	const trainingIdStr = params[RouterVarNames.TrainingId]!
	const { courseId, groupId } =
		UrlService.getTrainingAndGroupIds(trainingIdStr)
	const exerciseId = UrlService.useGetExerciseId()

	const [showMessage, setShowMessage] = useState(false)

	const onButtonClick = useCallback(
		function () {
			if (!groupId || !exerciseId) return

			sendAnswerForCheck(groupId, exerciseId, answerText).then(() => {
				setShowMessage(true)
			})

			if (writingOrAudioAnswerWasSentFn) {
				writingOrAudioAnswerWasSentFn()
			}
		},
		[answerText]
	)

	return {
		showMessage,
		onButtonClick,
	}
}

/**
 * Отправляет текстовый ответ в качестве результата выполнения задания
 * @param {Number} groupId — id группы, к которой принадлежит ученик проходящий упражнение
 * @param {Number} exerciseId — id упражнения
 * @param {String} answerText — текст ответа ученика
 */
async function sendAnswerForCheck(
	groupId: number,
	exerciseId: number,
	answerText: string
) {
	const dto: TrainingApiTypes.SendAudioOrImageExerciseResultDto = {
		groupId,
		exerciseId,
		text: answerText,
	}

	await trainingRequests.sendAudioOrImageExerciseResult(dto)
}
