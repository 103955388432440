import Loader from 'ui/Loader/Loader'
import ExercisePageHeader from '../ExercisePageHeader'
import { useFillInStore } from '../zustand/fillStore/fillInStore'
import useExerciseHeaderStudentStore from '../zustand/store'

type RouterReturn = {
	status: 'loading' | 'noData' | 'hasData' | 'error'
	element: JSX.Element
}

/** Хук возвращающий статус компонента TrainingList и сам компонент в зависимости от статуса */
export function useGetExercisePageHeaderRouter(): RouterReturn {
	const dataLoadingStatus = useExerciseHeaderStudentStore(
		(state) => state.dataLoadingStatus
	)

	// Получить данные для отрисовки шапки и поставить в Состояние
	useFillInStore()

	if (dataLoadingStatus == 'loading') {
		return {
			status: 'loading',
			element: <Loader />,
		}
	} else if (dataLoadingStatus === 'error') {
		return {
			status: 'error',
			element: <p>Произошла ошибка при получении данных.</p>,
		}
	}

	return {
		status: 'hasData',
		element: <ExercisePageHeader />,
	}
}
