import useExerciseStore, {
	ExerciseStateType,
} from '../../zustand/exerciseState'
import { useGetCalculatedStats } from '../../TestExerciseView/fn/result'

export function useGetCheckExerciseFn() {
	const saveStoreForRestore = useExerciseStore(
		(store) => store.saveStoreForRestore
	)
	const attempts = useExerciseStore((store) => store.attempts)
	const statistics = useExerciseStore((store) => store.statistics)
	const questions = useExerciseStore((store) => store.questions)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		const updatedQuestions = questions.map((question) => {
			return { ...question, showCorrect: true }
		})

		const { passedNum, totalNum } = useGetCalculatedStats(questions)

		updateStore({
			stage: 'Checked',
			questions: updatedQuestions,
			saveStoreForRestore: saveStoreForRestore + 1,
			attempts: attempts - 1,
			statistics: getStatistics(statistics, passedNum),
		})
	}
}

function getStatistics(
	currentStats: ExerciseStateType.Statistic[],
	passedNum: number
) {
	if (currentStats.length === 3) {
		return currentStats
	}

	const newStatistics = [...currentStats]
	newStatistics.push({
		passedQuestions: passedNum,
	})
	return newStatistics
}
