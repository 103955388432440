import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import RestrictionsService from 'parts/services/RestrictionsService'
import { AddTrainingModalController } from '../addTraining/AddTrainingModal/AddTrainingModalController'

// Кнопка открывающая модальное окно добавления нового курса
function AddTrainingButton() {
	const canCreateNewTraining = RestrictionsService.useCanCreateNewTraining()

	const { t } = useTranslation()

	if (!canCreateNewTraining) {
		return null
	}

	return (
		<Button
			type="primary"
			icon={<PlusOutlined />}
			onClick={AddTrainingModalController.open}
		>
			{t('adminTrainings.addTrainingButton')}
		</Button>
	)
}

export default AddTrainingButton
