namespace ExerciseStateType {
	export type Main = {
		exerciseTask: string // Задача упражнения
		currentStatementIdx: number // Номер текущего утверждения
		totalStatements: number // Общее количество утверждений
		statements: Statement[] // Массив вопросов

		readyToShowResult: boolean // Всё готово для показа результата (к переводу showResult в true)
		showResult: boolean // Показать ли окно результата
	}

	export type Statement = {
		id: number
		text: string
		image: string // Ссылка на загруженное изображение
		correct: boolean // Является ли утверждение правильным
		userAnswer: null | 'not' | 'yes' // Как ответил пользователь: null (не ответил на вопрос), not (ответил отрицательно), yes (ответил положительно).
	}
}
export default ExerciseStateType

export const initialState: ExerciseStateType.Main = {
	exerciseTask: '',
	currentStatementIdx: 0,
	totalStatements: 0,
	statements: [],
	readyToShowResult: false,
	showResult: false,
}
