namespace ExerciseStateType {
	export type Main = {
		exerciseName: string // Название упражнения
		exerciseTask: string // Задача упражнения
		currentQuestionNumber: number // Номер текущего вопроса
		totalQuestions: number // Общее количество вопросов
		questions: Question[] // Массив вопросов
		showResult: boolean // Показать ли окно результата
	}

	export type Question = {
		id: number
		text: string
		audio: string // Ссылка на загруженный звуковой файл
		video: string // Ссылка на загруженное видео
		videoLink: string // Ссылка на видео на внешнем ресурсе (YouTube)
		image: string // Ссылка на загруженное изображение
		multipleSelect: boolean // Возможно ли несколько правильных ответов?
		attempts: number // Количество оставшихся попыток ответа
		active: boolean // Можно ли изменять ответ. Вопрос становится неактивным если кончились попытки ответа или перешли на следующий вопрос. Но создателю теста доступно перемещение между вопросами. В этом случае они становятся неактивными.
		showCorrect: boolean // Показать ли правильные варианты ответа (закрашивать ли ответы цветной рамочкой)
		resultButtonEnable: boolean // Активна ли кнопка проверки результата/перехода к следующему вопросу
		disableQuestion: boolean // Заблокирован ли вопрос чтобы нельзя было изменить ответы
		answers: Answer[]
	}

	export type Answer = {
		id: number
		text: string // Текст ответа
		view: 'unknown' | 'wrong' | 'right' // Как показывать элемент ответа: unknown (неизвестно, wrong (неправильный), right (правильный)
		checked: boolean // Отметил ли пользователь этот вариант ответа
		correct: boolean // Правильный ли вариант ответа
	}
}
export default ExerciseStateType

export const initialExerciseState: ExerciseStateType.Main = {
	exerciseName: '',
	exerciseTask: '',
	currentQuestionNumber: 0,
	totalQuestions: 0,
	questions: [],
	showResult: false,
}
