import { GapsExerciseFormStateType, TextStateItemType } from './stateType'

/**
 * Функция находит и возвращает объект с описанием текстового блока по id.
 * @param {Object} formState — объект состояния формы
 * @param textObjId
 */
export function getTextObjById(
	formState: GapsExerciseFormStateType,
	textObjId: number
) {
	return formState.texts.find((textObj) => {
		return textObj.id === textObjId
	})
}

/**
 * Функция создаёт и возвращает объект данных текста с пропусками
 * @param {Number} id — id данных
 * @param {String} text — текст текстового блока
 * @param {String} generatedTextPrompt — текст задания на негенерирование текстового блока
 */
export function createNewTextFormDataItem(
	id: number,
	text = '',
	generatedTextPrompt = ''
): TextStateItemType {
	return {
		id,
		text,
		noTextError: false,
		wrongBracesPairError: false,
		wrongBracesTextError: false,
		generatedText: '',
		generatedTextPrompt,
	}
}

/**
 * Функция проверяет что в тексте сначала идёт открывающая, затем закрыващая фигурная скобка.
 * Это требуется для правильной работы упражнения на заполнение пропусков.
 * @param {String} text — проверяемый текст
 */
export function textHasValidBracesPairs(text: string): boolean {
	// Был ли встречена открывающая и закрывающая фигурная скобка
	let isOpenBraceExist = false

	for (let i = 0; i < text.length; i++) {
		const letter = text[i]

		if (letter == '{') {
			if (isOpenBraceExist) {
				return false
			}
			isOpenBraceExist = true
		} else if (letter == '}') {
			if (!isOpenBraceExist) {
				return false
			}

			isOpenBraceExist = false
		}
	}

	return true
}
