import { Outlet } from 'react-router-dom'
import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import RestrictionsService from 'parts/services/RestrictionsService'
import LiveRoomsPageHeader from '../LiveRoomsPageHeader/LiveRoomsPageHeader'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import Footer from 'ui/Footer/Footer'

/** Обёртка статей с комнатами и с уроками */
export function LiveRoomsPagesWrapper() {
	const userRoles = useGetUserRole()

	if (!RestrictionsService.useCanUseLiveLessons()) {
		return null
	}

	return (
		<PageSkeleton>
			<LiveRoomsPageHeader />
			<Outlet />
			<Footer isRightSideHidden={!userRoles.isAdminOrManager} />
		</PageSkeleton>
	)
}
