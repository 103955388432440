import React, { useEffect, useState } from 'react'
import ReactQuill, { Range } from 'react-quill'
import Quill from 'quill'
import { useWatchAndSetNewValueToEditor } from './fn/main'
import './HTMLEditor.scss'
import 'react-quill/dist/quill.snow.css'

type HtmlEditorProps = {
	setQuillRef?: React.Dispatch<React.SetStateAction<Quill | null>>
	initialValue?: string
	// Разметка, которой нужно заменить текущий текст
	newValue?: null | string
	onChangeValue: (value: string) => void
	onChangeSelection?: (range: Range) => void // Функция запускаемая при изменении выделения текста
	onFocus?: () => void
	onBlur?: () => void
}

// Редактор редактирования форматированного контента
// Описание: github.com/zenoamaro/react-quill
function HtmlEditor(props: HtmlEditorProps) {
	const { initialValue, setQuillRef, newValue = null } = props

	// Ссылка на обёртку редактора для Реакта
	const [reactQuillRef, setReactQuillRef] = useState<ReactQuill | null>(null) // ReactQuill component

	useWatchAndSetNewValueToEditor(reactQuillRef, newValue)

	useEffect(
		function () {
			if (setQuillRef && reactQuillRef) {
				const editor = reactQuillRef.getEditor()
				setQuillRef(editor)
			}
		},
		[reactQuillRef]
	)

	const [text, setText] = useState('')
	useEffect(function () {
		setText(props.initialValue || ' ')
	}, [])

	if (!text) return null

	return (
		<div>
			<ReactQuill
				ref={setReactQuillRef}
				defaultValue={initialValue}
				theme={'snow'}
				className="html-editor-rewrite"
				onChange={(value: string) => {
					props.onChangeValue(value)
				}}
				onChangeSelection={(range, source, editor) => {
					if (!props.onChangeSelection) return
					props.onChangeSelection(range)
				}}
				onFocus={(range, source, editor) => {
					if (!props.onFocus) return
					props.onFocus()
				}}
				onBlur={(previousRange, source, editor) => {
					if (!props.onBlur) return
					props.onBlur()
				}}
			/>
		</div>
	)
}

export default HtmlEditor
