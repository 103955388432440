import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

type TextsProps = {
	lesson: TrainingEntityTypes.LessonAdmin
}

function Texts(props: TextsProps) {
	const { lesson } = props

	return (
		<>
			<h3 className="edit-lesson-card__name">{lesson.name}</h3>
			{lesson.description && (
				<p className="edit-lesson-card__description">
					{lesson.description}
				</p>
			)}
		</>
	)
}

export default Texts
