import cn from 'classnames'
import useLandingBuilderStore from 'pages/landing/landingBuilder/zustand/store'
import './MainImage.scss'

// Главное изображение справа в блоке «Вам подходит этот курс если...»
function MainImage() {
	const landing = useLandingBuilderStore((store) => store.landing)
	const { colorScheme } = landing

	return (
		<div
			className={cn(
				'landing-existence-main-img',
				'landing-existence-main-img--' + colorScheme
			)}
		>
			<img
				src={landing.sections.existence.imageUrl}
				className="landing-existence-main-img__img"
				alt=""
			/>
		</div>
	)
}

export default MainImage
