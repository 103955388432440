import { useQueryClient } from 'react-query'
import { groupQuery } from 'parts/requests/group/groupQuery'
import { useGetGroupOpenLessonsFormRouter } from './fn/useGetGroupOpenLessonsFormRouter'

export const GroupOpenLessonsFormController = {
	router: useGetGroupOpenLessonsFormRouter,
	// Функция, помечающая данные группы неактуальными. После этого React Query скачает новые данные группы.
	useGetUpdate: function () {
		const queryClient = useQueryClient()

		return function (trainingId: string | number) {
			// Пометить данные курса неактуальными. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [groupQuery.getTrainingGroups(trainingId).key],
			})
		}
	},
}
