import React from 'react'
import HistoryModal from '../common/HistoryModal/HistoryModal'
import { Button, Form, FormInstance, Select } from 'antd'
import { useGetCancelSubscription } from './fn/cancelSubscription'
import { useGetToggleVisibilitySubscriptionCanceledModal } from './fn/openCloseSubscriptionCanceledModal'
// import { useGetOnSubmit } from './fn/submit'
import useSubscriptionCanceledFormStore from './zustand/store'
import s from './SubscriptionCanceledModal.module.scss'

function SubscriptionCanceledModal() {
	const [form] = Form.useForm()

	const isOpen = useSubscriptionCanceledFormStore((store) => store.isOpen)
	const closeModal = useGetToggleVisibilitySubscriptionCanceledModal()

	return (
		<HistoryModal
			iconSrc="/images/subscriptionsHistory/CheckOutlined.svg"
			title="Ваша подписка отменена"
			open={isOpen}
			footerButtons={[<ContinueButton form={form} key={1} />]}
			onCancel={closeModal}
		>
			<SubscriptionCanceledForm form={form} />
		</HistoryModal>
	)
}

export default SubscriptionCanceledModal

type SubscriptionCanceledFormProps = {
	form: FormInstance
}

function SubscriptionCanceledForm(props: SubscriptionCanceledFormProps) {
	const { form } = props

	return (
		<p className={s.topText}>
			Вы продолжите пользоваться функциями плана Pro{' '}
			<strong>до 25 декабря 2023 г. </strong>
			Вы можете возобновить подписку в любое время.
		</p>
	)
}

type SaveSubscriptionButtonProps = {
	form: FormInstance
}

function ContinueButton(props: SaveSubscriptionButtonProps) {
	const { form } = props

	const isSubmitting = useSubscriptionCanceledFormStore(
		(store) => store.isSubmitting
	)

	const cancelSubscription = useGetCancelSubscription()

	const formErrors = form
		.getFieldsError()
		.filter(({ errors }) => errors.length)

	return (
		<Button
			disabled={isSubmitting || formErrors.length > 0}
			onClick={cancelSubscription}
			type="primary"
		>
			Продолжить
		</Button>
	)
}
