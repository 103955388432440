import React from 'react'
import { AddGroupModalController } from 'widgets/groups/addGroupModal/AddGroupModalController'
import useTariffsConstructorStore from '../../../zustand/store'
import NoActiveGroupCard from '../NoActiveGroupCard/NoActiveGroupCard'
import './NoActiveGroupScreen.scss'

// Экран с карточкой тарифа. Появляется если в курсе нет или нет групп, или не отмечена активная.
// При нажатии на карточку открывается модальное окно с формой создания активной группы.
function NoActiveGroupScreen() {
	const training = useTariffsConstructorStore((state) => state.training)

	return (
		<>
			<div
				className="no-group-tariff-card-wrapper"
				onClick={AddGroupModalController.useGetOpen}
			>
				<NoActiveGroupCard />
			</div>
			{AddGroupModalController.element(
				training.id,
				true,
				<Description />,
				true
			)}
		</>
	)
}

export default NoActiveGroupScreen

function Description() {
	return (
		<p className="no-group-tariff-card-wrapper__description">
			Чтобы изменять тарифы, нужно создать группу. Курс вы создаете 1 раз,
			а группы создаются каждый раз, когда вы захотите запустить новые
			продажи курса, чтобы старые ученики не путались с новыми.
		</p>
	)
}
