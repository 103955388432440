import { useGetToggleVisibilityReasonModal } from '../../ReasonModal/fn/openCloseReasonModal'

/** Хук возвращающий обработчик отправки формы */
export function useGetOnSubmit(closeCancelSubscriptionModal: () => void) {
	const openReasonModal = useGetToggleVisibilityReasonModal()

	return function () {
		closeCancelSubscriptionModal()
		openReasonModal()
	}
}
