import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { UTableBCell, UTableBRow } from 'ui/UTable/UTable'
import { AddStaffInGroupModalController } from '../../AddStaffInGroupModal/AddStaffInGroupModalController'

// Ряд с кнопкой добавления сотрудника к группе
function AddStaffRow() {
	const { t } = useTranslation()

	return (
		<UTableBRow>
			<UTableBCell colSpan={5}>
				<Button
					icon={<PlusOutlined />}
					onClick={AddStaffInGroupModalController.open}
				>
					Добавить сотрудника
				</Button>
			</UTableBCell>
		</UTableBRow>
	)
}

export default AddStaffRow
