import { useEffect } from 'react'
import { FormInstance } from 'antd'
import { setErrorsToFields } from 'parts/utils/form'
import useAddLiveModalStore from '../zustand/store'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import {
	useCreateLivelessonLink,
	getTitle,
	getScheduled,
	getTimeZone,
	getTeacher,
	getStudentsIdsConvertedToStr,
	getLink,
	getAdminId,
} from './formFieldsFunc'
dayjs.extend(timezone)

export enum FieldNames {
	Title = 'title',
	Date = 'date',
	Time = 'time',
	TimeZone = 'timezone',
	Curator = 'curator',
	Students = 'students',
	Link = 'link',
}

export type FormValuesType = {
	[FieldNames.Title]: string
	[FieldNames.Date]: dayjs.Dayjs
	[FieldNames.Time]: dayjs.Dayjs
	[FieldNames.TimeZone]: string
	[FieldNames.Curator]: string
	[FieldNames.Students]: string[]
	[FieldNames.Link]: string
}

// Возвращает первоначальные значения полей формы если создается новый урок (проверяется наличие id)
export function useSetValuesNewLesson(form: FormInstance) {
	const [updateStore, lessonId] = useAddLiveModalStore((state) => [
		state.updateState,
		state.lessonId,
	])

	const currentTime = dayjs()
	const currentTimezone = dayjs.tz.guess()
	const defaultTeacher = getAdminId().toString()
	const initialLinkToLivelesson = useCreateLivelessonLink()

	useEffect(
		function () {
			if (lessonId) return

			const formInitialValues: FormValuesType = {
				[FieldNames.Title]: '',
				[FieldNames.Date]: currentTime,
				[FieldNames.Time]: currentTime,
				[FieldNames.TimeZone]: currentTimezone,
				[FieldNames.Curator]: defaultTeacher,
				[FieldNames.Students]: [],
				[FieldNames.Link]: initialLinkToLivelesson,
			}
			form.setFieldsValue(formInitialValues)
			updateStore({ lessonLink: initialLinkToLivelesson })
		},
		[lessonId]
	)
}

// Возвращает первоначальные значения полей формы если редактируется существ. урок (проверяется наличие id)
export function useSetValuesExistingLesson(form: FormInstance) {
	const [updateStore, lessonId] = useAddLiveModalStore((state) => [
		state.updateState,
		state.lessonId,
	])

	const title = getTitle(lessonId)
	const time = getScheduled(lessonId)
	const curatorId = getTeacher(lessonId)
	const studentsIds = getStudentsIdsConvertedToStr(lessonId)
	const timeZone = getTimeZone(lessonId)
	const linkToLivelesson = getLink(lessonId)

	useEffect(
		function () {
			if (!lessonId) return

			const formInitialValues: FormValuesType = {
				[FieldNames.Title]: title,
				[FieldNames.Date]: dayjs(time),
				[FieldNames.Time]: dayjs(time),
				[FieldNames.TimeZone]: timeZone,
				[FieldNames.Curator]: curatorId.toString(),
				[FieldNames.Students]: studentsIds,
				[FieldNames.Link]: linkToLivelesson,
			}
			form.setFieldsValue(formInitialValues)
			updateStore({ lessonLink: linkToLivelesson })
		},
		[lessonId]
	)
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useAddLiveModalStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
