import { useMemo } from 'react'
import UrlService from 'parts/services/UrlService'
import { trainingQuery } from 'parts/requests/training/trainingQuery'

/** Возвращает заголовок страницы и описание курса */
export function useGetHeaderTexts() {
	const trainingId = UrlService.useGetTrainingId()
	const { isLoading, data } = trainingQuery
		.getTrainingForAdmin(trainingId)
		.useQuery()

	return useMemo(
		function () {
			if (isLoading || !data) {
				return { headerText: '', descriptionText: '' }
			} else {
				return {
					headerText: data.data.name,
					descriptionText: data.data.description,
				}
			}
		},
		[isLoading]
	)
}
