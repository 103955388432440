import { create } from 'zustand'
import { LandingPreviewStoreType } from './storeTypes'

const useLandingPreviewStore = create<LandingPreviewStoreType.State>((set) => {
	return {
		dataLoadingStatus: 'loading',
		errorMessage: null,
		landing: undefined as any,
		tariffs: undefined as any,

		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useLandingPreviewStore
