import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { UTableBCell, UTableBRow } from 'ui/UTable/UTable'
import { AddGroupModalController } from 'widgets/groups/addGroupModal/AddGroupModalController'
import { GroupsTableView } from 'widgets/groups/trainingGroupsTable/GroupsTable/GroupsTable'

type AddGroupRowProps = {
	tableView?: GroupsTableView
}

// Ряд с кнопкой создания новой группы
function AddGroupRow(props: AddGroupRowProps) {
	const { tableView } = props

	const { t } = useTranslation()

	if (tableView !== 'groupsPage') return null

	return (
		<UTableBRow>
			<UTableBCell>
				<Button
					icon={<PlusOutlined />}
					onClick={AddGroupModalController.useGetOpen}
				>
					Добавить группу
				</Button>
			</UTableBCell>
		</UTableBRow>
	)
}

export default AddGroupRow
