import AppUrls from '../constants/pageUrl'
import Site from '../constants/site'
import schoolRequests from '../requests/school/schoolRequest'
import SchoolApiTypes from '../requests/school/schoolApiTypes'
import useSystemStore from '../systemStore/systemStore'
import { UserRolesObj } from '../utils/hooks/hooks'
import autoLogin from './AutoLogin'

// Методы для работы с пользователем
const SchoolService = {
	// Переадресовывает пользователя в первую школу
	// Этот метод должен отрабатывать на корневом домене школ и если пользователь уже вошёл.
	async goToFirstSchoolUrl(userRoles: UserRolesObj) {
		const user = useSystemStore((store) => store.user)
		if (!user) return

		const schools = await this.getUserSchools(user.id)
		const firstSchoolUrl = schools[0].domain

		let url = ''

		if (userRoles.isStudent) {
			url = Site.protocol + firstSchoolUrl + AppUrls.Main().url
		} else {
			url = Site.protocol + firstSchoolUrl + AppUrls.MySchools().url
		}

		// Добавить в URL данные для автоматического входа в учётную запись
		window.location.href = autoLogin.getUrlWithCredentials(url)

		// Стереть из LocalStorage данные для автоматического входа в учётную запись
		autoLogin.clearCredentialsInLocalStorage()
	},

	// ВМЕСТО ЭТОГО ИСПОЛЬЗОВАТЬ React Query!!!
	// Возвращает список школ администратора
	async getUserSchools(
		userId: number
	): Promise<SchoolApiTypes.GetSchoolsByUser> {
		const response = await schoolRequests.getSchoolsByUser(userId)

		return response.status == 200 ? response.data : []
	},
}

export default SchoolService
