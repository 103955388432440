import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import './AuthPage.scss'

type PageProps = {
	children: ReactNode
}

// Обёртка для каждой страницы авторизации
function AuthPage(props: PageProps) {
	const { children } = props

	const { t } = useTranslation()

	return (
		<main className="auth-page">
			<div className="auth-page__left">
				<div className="auth-page__promo-wrapper">
					<div className="auth-page__promo-gradient">
						<img
							src="/images/illustrations/auth-cover.png"
							className="auth-page__promo"
							alt="promo"
						/>
					</div>
				</div>
				<p className="auth-page__slogan">{t('auth.slogan')}</p>
			</div>
			<div className="auth-page__right">{children}</div>
		</main>
	)
}

export default AuthPage
