import { create } from 'zustand'
import { RegisterAdminStoreType } from './storeTypes'

const useRegisterAdminStore = create<RegisterAdminStoreType.State>((set) => {
	return {
		userId: null,
		email: '',
		phone: '',
		password: '',
		legal: false,
		isSubmitting: false,
		formErrors: {},

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useRegisterAdminStore
