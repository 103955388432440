import cn from 'classnames'
import { Link } from 'react-router-dom'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useGetCurrentExerciseMeta } from '../common/common'
import { useGetPrevAndNextLessonUrl } from './fn/getPrevAndNextLessonUrl'
import {
	getExerciseTime,
	useTransformStatusToText,
	useGetOnExerciseClick,
} from './fn/main'
import checkImgSrc from './images/check.svg'
import { useGetAsideTopPos } from './fn/getTopPosition'
import useExerciseStudentStore from '../zustand/store'
import Progress from 'ui/Progress/Progress'
import { ExerciseStudentStoreType } from '../zustand/storeTypes'
import './PreviewExerciseAside.scss'

// Список упражнений
function PreviewExerciseAside() {
	const percentage = useExerciseStudentStore((state) => state.percentage)
	const exercisesMeta = useExerciseStudentStore(
		(state) => state.exercisesMeta
	)

	// Позиция сверху бокового блока с номерами уроков
	const topPosition = useGetAsideTopPos()

	const activeExerciseMeta = useGetCurrentExerciseMeta()
	if (!activeExerciseMeta) return null

	return (
		<div className="preview-exercise-aside" style={{ top: topPosition }}>
			<LessonsNavigateButtons />
			<div className="preview-exercise-aside__top">
				<Progress percent={percentage} />
			</div>
			<div className="preview-exercise-aside__bottom">
				{exercisesMeta.map((exerciseMeta, i) => {
					const isLastExercise = i > exercisesMeta.length - 2

					return (
						<ExerciseLink
							exerciseMeta={exerciseMeta}
							isLastExercise={isLastExercise}
							isCurrent={exerciseMeta.id == activeExerciseMeta.id}
							key={exerciseMeta.exerciseNumber}
						/>
					)
				})}
			</div>
		</div>
	)
}

export default PreviewExerciseAside

type ExerciseLinkProps = {
	exerciseMeta: ExerciseStudentStoreType.ExerciseMeta
	isLastExercise: boolean
	isCurrent: boolean
}

// Ссылка на упражнение
export function ExerciseLink(props: ExerciseLinkProps) {
	const { exerciseMeta, isLastExercise, isCurrent } = props

	const onExerciseClick = useGetOnExerciseClick(exerciseMeta)

	return (
		<div
			className="preview-exercise-aside__exercise"
			onClick={onExerciseClick}
		>
			<div className="preview-exercise-aside__exercise-top">
				<ExerciseCircle
					exerciseMeta={exerciseMeta}
					isCurrent={isCurrent}
				/>
				{!isLastExercise && (
					<ExerciseLine exerciseMeta={exerciseMeta} />
				)}
			</div>
			<BottomStatus exerciseMeta={exerciseMeta} />
		</div>
	)
}

type ExerciseCircleProps = {
	exerciseMeta: ExerciseStudentStoreType.ExerciseMeta
	isCurrent: boolean
}

// Круг с номером упражнения
function ExerciseCircle(props: ExerciseCircleProps) {
	const { exerciseMeta, isCurrent } = props

	return (
		<div
			className={cn(
				'preview-exercise-aside__outer-circle',
				isCurrent && 'preview-exercise-aside__outer-circle--current'
			)}
		>
			<p
				className={cn(
					'preview-exercise-aside__circle',
					exerciseMeta.status ==
						TrainingEntityTypes.ExerciseProgressStatus.SUCCESS &&
						'preview-exercise-aside__circle--success'
				)}
			>
				{exerciseMeta.status ==
				TrainingEntityTypes.ExerciseProgressStatus.SUCCESS ? (
					<img src={checkImgSrc} alt="check" />
				) : (
					<span>{exerciseMeta.exerciseNumber}</span>
				)}
			</p>
		</div>
	)
}

type ExerciseLineProps = {
	exerciseMeta: ExerciseStudentStoreType.ExerciseMeta
}

function ExerciseLine(props: ExerciseLineProps) {
	const { exerciseMeta } = props

	return (
		<div
			className={cn(
				'preview-exercise-aside__line',
				exerciseMeta.status == 1 &&
					'preview-exercise-aside__line--success'
			)}
		/>
	)
}

type BottomStatusProps = {
	exerciseMeta: ExerciseStudentStoreType.ExerciseMeta
}

function BottomStatus(props: BottomStatusProps) {
	const { exerciseMeta } = props

	const text = useTransformStatusToText(exerciseMeta.status)
	const time = getExerciseTime(exerciseMeta.time)

	return (
		<div>
			<p className="preview-exercise-aside__exercise-text">{text}</p>
			{time && <p className="preview-exercise-aside__time">{time}</p>}
		</div>
	)
}

function LessonsNavigateButtons() {
	const { prevLessonUrl, nextLessonUrl } = useGetPrevAndNextLessonUrl()

	if (!prevLessonUrl && !nextLessonUrl) {
		return null
	}

	return (
		<nav className="preview-exercise-aside__lessons-nav">
			{prevLessonUrl && <Link to={prevLessonUrl}>Назад</Link>}
			{nextLessonUrl && <Link to={nextLessonUrl}>Следующий урок</Link>}
		</nav>
	)
}
