import $api from '../http'
import SchoolApiTypes from './schoolApiTypes'

// Запросы к методам работы с пользователями
const schoolRequests = {
	// Получение списка школ администратора
	getSchoolsByUser(userId: number | string) {
		return $api.get<SchoolApiTypes.GetSchoolsByUser>(
			'/api/schools/by-user/' + userId
		)
	},

	// Получение школы
	getSchool(schoolId: number | string) {
		return $api.get<SchoolApiTypes.GetSchool>('/api/schools/' + schoolId)
	},

	// Получение текущей школы
	getCurrentSchool() {
		return $api.get<SchoolApiTypes.GetCurrentSchool>('/api/schools/current')
	},

	// Добавление новой школы у неавторизованного пользователя
	addSchool(dto: SchoolApiTypes.AddSchoolDto) {
		return $api.post<SchoolApiTypes.AddSchool>(
			'/api/schools/registration',
			dto
		)
	},

	// Добавление новой школы авторизованным пользователем
	addSchoolByAuthorizedUser(
		dto: SchoolApiTypes.AddSchoolByAuthorizedUserDto
	) {
		return $api.post<SchoolApiTypes.AddSchoolByAuthorizedUser>(
			'/api/schools',
			dto
		)
	},

	// Обновление данных школы
	updateSchool(dto: SchoolApiTypes.UpdateSchoolDto) {
		return $api.put<SchoolApiTypes.UpdateSchool>('/api/schools', dto)
	},

	// Удаление школы
	deleteSchool(schoolId: string | number) {
		return $api.delete<SchoolApiTypes.DeleteSchool>(
			'/api/schools/' + schoolId
		)
	},

	// Удаление пользователя (не действует на администратора)
	deleteUser(userId: string | number) {
		return $api.delete<SchoolApiTypes.DeleteUser>(
			'/api/schools/user/' + userId
		)
	},

	// Получение массива с данными подключения к платёжным системам конкретной школы
	getPaymentsData(schoolId: string | number) {
		return $api.get<SchoolApiTypes.GetPaymentData>(
			'/api/schools/payment-data/' + schoolId
		)
	},

	// Добавление и изменение данных по взаимодействию с платёжной системой у школы
	updatePaymentData(dto: SchoolApiTypes.UpdatePaymentDataDto) {
		return $api.post<SchoolApiTypes.UpdatePaymentData>(
			'/api/schools/payment-data/',
			dto
		)
	},

	// Включение/отключения платёжной системы у школы
	setPaymentConnection(dto: SchoolApiTypes.SetPaymentConnectionDto) {
		return $api.post<SchoolApiTypes.SetPaymentConnection>(
			'/api/schools/payment-data/connected',
			dto
		)
	},

	// Проверка правильности сохранённых данных для подключения к платёжной системе
	checkPaymentData(paymentDataId: number | string) {
		return $api.get<SchoolApiTypes.CheckPaymentData>(
			'/api/schools/payment-data/' + paymentDataId + '/check'
		)
	},
}

export default schoolRequests
