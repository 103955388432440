import { TaskExerciseView } from '../../common/TaskExerciseView/TaskExerciseView'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import ExerciseContainer, {
	ExerciseContainerType,
} from '../../common/ExerciseContainer/ExerciseContainer'
import VideoFrame from 'ui/VideoFrame/VideoFrame'
import ExerciseControlPanel from '../ExerciseControlPanel/ExerciseControlPanel'
import { useCreateExerciseState } from './fn/state'
import './VideoExerciseView.scss'

type VideoExerciseViewProps = {
	// Для какого типа страницы предназначается обёртка упражнения:
	// constructorPage — страница конструктора. Будут скрыты кнопки перехода по упражнениям и кнопки снизу
	// exercisePage — страница упражнения для ученика. Будет полный набор элементов.
	exerciseContainerType: ExerciseContainerType
	exercise: TrainingEntityTypes.VideoExercise
	// Адрес предыдущего и следующего упражнения
	prevExerciseUrl?: string
	nextExerciseUrl?: string
}

// Вывод видео-упражнения в уроке
function VideoExerciseView(props: VideoExerciseViewProps) {
	const {
		exerciseContainerType,
		exercise,
		prevExerciseUrl,
		nextExerciseUrl,
	} = props

	const { state, setState } = useCreateExerciseState(exercise)

	return (
		<ExerciseContainer
			type={exerciseContainerType}
			prevExerciseUrl={prevExerciseUrl}
			nextExerciseUrl={nextExerciseUrl}
		>
			<>
				{exercise.item.task && (
					<TaskExerciseView htmlString={exercise.item.task} />
				)}
				{exercise.item.video.map((videoBlock, idx) => {
					return (
						<div
							className="exercise-video-content__video-block"
							key={idx}
						>
							{videoBlock.name && (
								<p className="exercise-video-content__video-name">
									{videoBlock.name}
								</p>
							)}
							<VideoFrame
								downloadedVideoLink={videoBlock.video}
								youTubeLink={videoBlock.link}
								name={videoBlock.name}
							/>
						</div>
					)
				})}
			</>
			<ExerciseControlPanel state={state} setState={setState} />
		</ExerciseContainer>
	)
}

export default VideoExerciseView
