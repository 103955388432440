import { message } from 'antd'
import { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import useDeleteModalStore from 'pages/modal/DeleteModal/store/store'
import { QueryClient, useQueryClient } from 'react-query'
import AnswerFeedTypes from 'parts/requests/answerFeed/answerFeedApiTypes'
import { answerFeedQuery } from 'parts/requests/answerFeed/answerFeedQuery'
import UrlService from 'parts/services/UrlService'
import { useGetAnswersParams } from '../../../zustand/fillStore/fillInStore'

/**
 * Возвращает функцию, открывающую модальное окно удаления ответа из ленты ответов
 * @param answerId — id ответа
 */
export function useDeleteAnswer(answerId: number) {
	const { t } = useTranslation()
	const updateStore = useDeleteModalStore((state) => state.updateStore)
	const groupId = UrlService.useGetGroupId()
	const queryClient = useQueryClient()
	const getAnswersParams = useGetAnswersParams()

	const deleteReplyQuery = answerFeedQuery.deleteReply.useMutation({
		onError: () => {
			message.error(t('answersFeed.answerBlockDeleteAnswerFailRequest'))
		},
		onSuccess: getAfterSuccessfulAnswerDelete(
			queryClient,
			getAnswersParams,
			groupId
		),
	})

	return function (event: SyntheticEvent) {
		event.preventDefault()

		updateStore({
			isOpen: true,
			header: t('answersFeed.answersFeedDeleteModalHeader'),
			text: t('answersFeed.answersFeedDeleteModalText'),
			onOk: () => deleteReplyQuery.mutate(answerId),
		})
	}
}

/** Функция, выполняемая при успешном запросе на удаление ответа */
function getAfterSuccessfulAnswerDelete(
	queryClient: QueryClient,
	getAnswersParams: AnswerFeedTypes.GetAnswerFeedParams,
	groupId: null | number
) {
	return function () {
		// Пометить неактуальным массив всех ответов или конкретной группы.
		// После этого React Query скачает обновлённые данные.
		if (groupId) {
			queryClient.refetchQueries({
				queryKey: [
					answerFeedQuery.getGroupAnswersFeed(
						groupId,
						getAnswersParams
					).key,
				],
			})
		} else {
			queryClient.refetchQueries({
				queryKey: [
					answerFeedQuery.getAllAnswersFeed(getAnswersParams).key,
				],
			})
		}
	}
}
