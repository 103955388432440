import React from 'react'
import Loader from 'ui/Loader/Loader'
import { useQueryGroupsAndSetToStore } from './fn/queryGroups'
import useEmployeeGroupsStore from '../zustand/store'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import EmployeeGroupsTable from '../GroupsTable/EmployeeGroupsTable'
import { EmptyBlock } from 'ui/EmptyBlock/EmptyBlock'
import AssignEmployeeToGroupButton from '../AssignEmployeeToGroupButton/AssignEmployeeToGroupButton'
import AssignEmployeeToGroupModal from '../AssignEmployeeToGroupModal/AssignEmployeeToGroupModal'

// Таблица с покупками, которые совершил пользователь
export default function EmployeeGroupsArticle() {
	useQueryGroupsAndSetToStore()

	const employeeGroups = useEmployeeGroupsStore(
		(state) => state.employeeGroups
	)

	return (
		<ArticleContentWrapper
			center={!employeeGroups || !employeeGroups.length}
		>
			{!employeeGroups && <Loader />}
			{employeeGroups && !employeeGroups.length && (
				<ArticleContentWrapper center>
					<EmptyBlock
						text={<>Сотрудник не добавлен ни в одну группу</>}
						button={<AssignEmployeeToGroupButton />}
					/>
				</ArticleContentWrapper>
			)}
			{employeeGroups && employeeGroups.length > 0 && (
				<ArticleContentWrapper innerWrapperWidth="full">
					<EmployeeGroupsTable />
				</ArticleContentWrapper>
			)}
			<AssignEmployeeToGroupModal />
		</ArticleContentWrapper>
	)
}
