import { useMachine } from '@xstate/react'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useTranslation } from 'react-i18next'
import { ExerciseControlPanelButton } from '../../../common/ExerciseControlPanelContainer/ExerciseControlPanelContainer'
import { useGetExerciseDuration } from './getExerciseDuration'
import { useGetDoneExercise } from '../../../common/doneExercise'
import { useGetGoToNextExercise } from '../../../common/prevAndNextExerciseUrls'
import { useGetAfterDoneExercise } from './afterDoneExercise'
import { getTextExerciseMachine } from './textExerciseMachine'

/**
 * Возвращает массив с настройками кнопок для передачи
 * в компонент ExerciseControlPanelContainer.
 */
export function useGetButtonsConfig(
	exercise: TrainingEntityTypes.TextExercise
): ExerciseControlPanelButton[] {
	const { t } = useTranslation()

	const [machineState, sendEventToMachine] = useMachine(
		getTextExerciseMachine(exercise)
	)

	const duration = useGetExerciseDuration()

	// Функция, выполняемая при нажатии на кнопку «Выполнено».
	const doneExercise = useGetDoneExercise(
		useGetAfterDoneExercise(sendEventToMachine),
		duration
	)

	// Функция открывающая следующее упражнение если упражнение существует
	// или undefined если не существует.
	const goToNextExercise = useGetGoToNextExercise()

	return [
		{
			text: t('exercises.controlBtnNextExercise'),
			active: !!goToNextExercise,
			onClick: goToNextExercise,
			dataCyAttr: 'next-exercise-btn',
		},
		{
			text: t('exercises.controlBtnDone'),
			active: machineState.value === 'doneAllow',
			primary: true,
			onClick: doneExercise,
			dataCyAttr: 'done-exercise-btn',
		},
	]
}
