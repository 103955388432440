import { useTranslation } from 'react-i18next'
import { Button, Form, Input } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import {
	UFormOldBottomButtons,
	UFormOld,
	UFormOldRow,
} from 'ui/UFormOld/UFormOld'
import { UFormNewGeneralError } from 'ui/UForm/UForm'
import useConfirmFormStore from './zustand/store'
import { useGetOnSubmit } from './fn/submit'
import { setErrorsToForm } from './fn/form'
import './ConfirmForm.scss'

interface ConfirmFormProps {
	code: string
}

// Формы регистрации администратора
function ConfirmForm(props: ConfirmFormProps) {
	const [form] = Form.useForm()

	const onSubmit = useGetOnSubmit()
	setErrorsToForm(form)

	const formErrors = useConfirmFormStore((state) => state.formErrors)

	return (
		<UFormOld>
			<Form
				form={form}
				name="delete-account-confirm"
				onFinish={onSubmit}
				layout="vertical"
			>
				<CodeInput code={props.code} />
				<Bottom />
				{formErrors?.message && (
					<UFormNewGeneralError message={formErrors?.message} />
				)}
			</Form>
		</UFormOld>
	)
}

export default ConfirmForm

function CodeInput(props: { code: string }) {
	const { code } = props
	return (
		<UFormOldRow>
			<Form.Item name="code" hidden initialValue={code || ''}>
				<Input type="text" size="large" />
			</Form.Item>
		</UFormOldRow>
	)
}

// Кнопка отправки формы
function Bottom() {
	const form = Form.useFormInstance()
	const { t } = useTranslation()

	const isSubmitting = useConfirmFormStore((state) => state.isSubmitting)

	return (
		<UFormOldBottomButtons>
			<Form.Item
				shouldUpdate
				className="login-page-content__bottom-form-item"
			>
				{() => {
					const formErrors = form
						.getFieldsError()
						.filter(({ errors }) => errors.length)

					return (
						<Button
							block
							size="large"
							type="primary"
							htmlType="submit"
							disabled={isSubmitting || formErrors.length > 0}
							loading={isSubmitting}
							data-cy="delete-account-form-submit-btn"
						>
							{t('auth.deleteAccConfirmFormFieldEnterButton')}{' '}
							<ArrowRightOutlined />
						</Button>
					)
				}}
			</Form.Item>
		</UFormOldBottomButtons>
	)
}
