import { useParams } from 'react-router-dom'
import AppUrls from 'parts/constants/pageUrl'
import { TrainingStoreType } from '../../zustand/storeTypes'
import { RouterVarNames } from 'app/routes/special/otherRoutes'

/**
 * Возвращает функцию, переадресовывающую на страницу урока
 * @param lesson — урок
 */
export function useGetLessonPageUrl(lesson: TrainingStoreType.Lesson) {
	const params = useParams()
	const trainingIdStr = params[RouterVarNames.TrainingId]!

	const firstExerciseId = lesson.exercises[0]?.id

	// Если есть хотя бы одно упражнение, то перейти к нему
	if (firstExerciseId) {
		return AppUrls.Exercise(trainingIdStr, lesson.id, firstExerciseId).url
	}
	// В противном случае показать страницу урока где ничего не будет,
	// чтобы показать, что в уроке пока нет упражнений
	else {
		return AppUrls.Lesson(trainingIdStr, lesson.id).url
	}
}
