import Loader from 'ui/Loader/Loader'
import { useFillInStore } from './fillinStore'
import NoContent from 'ui/NoContent/NoContent'
import useAddLiveModalStore from '../zustand/store'
import LifelessonsForm from '../LiveRoomForm'

type GetLiveFormRouterReturn = {
	status: 'loading' | 'error' | 'hasData' | 'noData'
	element: JSX.Element
}

/** Хук возвращающий статус компонента LivelessonList и сам компонент в зависимости от статуса */

export function useGetLiveFormRouter(): GetLiveFormRouterReturn {
	const dataLoadingStatus = useAddLiveModalStore(
		(state) => state.dataLoadingStatus
	)

	useFillInStore()

	if (dataLoadingStatus == 'loading') {
		return {
			status: 'loading',
			element: <Loader />,
		}
	} else if (dataLoadingStatus === 'error') {
		return {
			status: 'error',
			element: <p>Произошла ошибка при получении данных.</p>,
		}
	} else if (dataLoadingStatus == 'hasData') {
		return {
			status: 'hasData',
			element: <LifelessonsForm />,
		}
	}
	return {
		status: 'noData',
		element: (
			<NoContent
				header="Данные не существуют"
				text="Они были удалены или введён неправильный адрес"
			/>
		),
	}
}
