import { AxiosError, AxiosResponse } from 'axios'
import { FormInstance, message } from 'antd'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import useSystemStore from 'parts/systemStore/systemStore'
import useChangePasswordStore from '../zustand/store'
import { FormValues } from './form'
import ServerTypes from 'parts/types/ServerTypes'
import authRequests from 'parts/requests/auth/authRequest'
import AuthApiTypes from 'parts/requests/auth/authApiTypes'
import EntityTypes from 'parts/types/EntityTypes'

/** Хук возвращающий обработчик отправки формы */
export function useGetOnSubmit(form: FormInstance) {
	const userRoles = useGetUserRole()
	const user = useSystemStore((store) => store.user)
	const userId = user?.id
	const updateState = useChangePasswordStore((state) => state.updateState)

	return async (values: FormValues) => {
		if (!userRoles.rolesGot || !userId) return

		try {
			updateState({ isSubmitting: true })

			let response: null | AxiosResponse<EntityTypes.User> = null

			if (userRoles.isAdmin) {
				response = await authRequests.updatePasswordOfUserById(
					createChangeNonAdminPasswordDto(userId, values)
				)
			} else {
				response = await authRequests.updatePasswordOfCurrentUser(
					createChangeAdminPasswordDto(values)
				)
			}

			if (response.data) {
				// Закрыть модальное окно
				updateState({ isOpen: false })

				// Показать уведомление об изменении пароля
				message.success('Пароль изменён')
			}
		} catch (err) {
			const error = err as AxiosError<ServerTypes.ErrorResponse>

			if (error.response) {
				// Поставить данные ошибки в Состояние, чтобы показать их в форме
				updateState({ formErrors: error.response.data })
			}
		} finally {
			// Убрать статус загрузки
			updateState({ isSubmitting: false })

			// Стереть значения во всех полях
			form.resetFields()
		}
	}
}

/**
 * Формирует DTO для отправки запроса на смену пароля администратора
 * @param values — значения полей формы
 */
function createChangeAdminPasswordDto(
	values: FormValues
): AuthApiTypes.UpdatePasswordOfCurrentUserDto {
	return {
		password: values.newPassword,
	}
}

/**
 * Формирует DTO для отправки запроса на смену пароля администратора
 * @param userId — id пользователя
 * @param values — значения полей формы
 */
function createChangeNonAdminPasswordDto(
	userId: number,
	values: FormValues
): AuthApiTypes.UpdatePasswordOfUserByIdDto {
	return {
		userId,
		password: values.newPassword,
	}
}
