import { Button, Form, Input } from 'antd'
import { SendOutlined } from '@ant-design/icons'
import styles from '../answer/AnswerBlock/AnswerBlock.module.scss'
import { FieldNames } from './fn/form'
import { useGetOnSubmit } from './fn/submit'

type ReplyFormProps = {
	answerFeedId: number
}

export default function ReplyForm(props: ReplyFormProps) {
	const { answerFeedId } = props

	const [form] = Form.useForm()

	const onSubmit = useGetOnSubmit(answerFeedId, form)

	return (
		<Form
			form={form}
			name="horizontal_login"
			layout="inline"
			onFinish={onSubmit}
			autoComplete="false"
		>
			<div className={styles.ReplyForm}>
				<Form.Item name="text">
					<Input
						placeholder="Ответить"
						autoComplete="false"
						aria-autocomplete="none"
						name={FieldNames.Text}
					/>
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						<SendOutlined />
					</Button>
				</Form.Item>
			</div>
		</Form>
	)
}
