import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AppUrls from 'parts/constants/pageUrl'
import useRegisterAdminStore from '../registerOwnerStart/RegistrationForm/zustand/store'

/** Следит за тем, что на момент открытия страницы в Состоянии уже были необходимые данные о пользователе. */
export function useReturnToStartPage() {
	const navigate = useNavigate()
	const userId = useRegisterAdminStore((state) => state.userId)

	useEffect(
		function () {
			if (!userId) {
				navigate(AppUrls.Register_Owner_Start().url)
			}
		},
		[userId]
	)
}
