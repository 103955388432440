import { useEffect } from 'react'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import { lessonsQuery } from 'parts/requests/lessons/lessonsQuery'
import typeChecker from 'parts/utils/typeChecker'
import useLessonsListAdminStore from '../store'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

/**
 * Хук получающий данные курса и помещающий их в Хранилище.
 * Затем массив уроков курса будет использован для отрисовки списка уроков
 */
export function useFillInStore(trainingId?: number) {
	useSetTrainingId(trainingId)

	useGetTrainingLessons(trainingId)
	useGetSchoolLessons(trainingId)
}

function useSetTrainingId(trainingId?: number) {
	const updateStore = useLessonsListAdminStore((state) => state.updateStore)

	useEffect(function () {
		updateStore({ trainingId })
	}, [])
}

function useGetTrainingLessons(trainingId?: number) {
	const { data: trainingRes } = trainingQuery
		.getTrainingForAdmin(trainingId as number)
		.useQuery({
			enabled: typeChecker.isNumber(trainingId),
			onError: queryError,
		})

	useEffect(
		function () {
			if (!trainingRes) return

			lessonsQuerySuccess(trainingRes.data.lessons)
		},
		[trainingRes]
	)
}

function useGetSchoolLessons(trainingId?: number) {
	const { data: lessonsRes } = lessonsQuery.getSchoolLessons.useQuery({
		enabled: typeChecker.isNullish(trainingId),
		onError: queryError,
	})

	useEffect(
		function () {
			if (!lessonsRes) return

			lessonsQuerySuccess(lessonsRes.data)
		},
		[lessonsRes]
	)
}

/**
 * Функция запускаемая при удачном получении списка уроков.
 * @param lessons — массив уроков
 */
function lessonsQuerySuccess(lessons: TrainingEntityTypes.LessonAdmin[]) {
	const { updateStore } = useLessonsListAdminStore.getState()

	updateStore({
		lessons,
		dataLoadingStatus: lessons.length ? 'hasLessons' : 'noLessons',
	})
}

/**
 * Функция запускаемая при ошибке запроса данных.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 * @param err — объект ошибки.
 */
function queryError(err: unknown) {
	const { updateStore } = useLessonsListAdminStore.getState()
	updateStore({ dataLoadingStatus: 'error' })
}
