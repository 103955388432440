import cn from 'classnames'
import UrlService from 'parts/services/UrlService'
import './VideoFrame.scss'

type VideoFrameProps = {
	youTubeLink?: string // Ссылка на видео с YouTube-а
	downloadedVideoLink?: string // Загруженное видео
	name?: string // Название видео
	extraClass?: string
	onLoad?: OnLoadEvent
}
type OnLoadEvent = () => void

// Универсальный проигрыватель видео
function VideoFrame(props: VideoFrameProps) {
	const {
		youTubeLink = '',
		downloadedVideoLink = '',
		name = '',
		extraClass,
		onLoad,
	} = props

	const videoLink = UrlService.getCorrectVideoLink(youTubeLink)

	// Если есть ссылка с Ютуба
	if (youTubeLink) {
		return (
			<div className={cn('video-frame__wrapper', extraClass)}>
				<iframe
					className="video-frame__iframe"
					src={videoLink}
					title={name}
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					loading="lazy"
					onLoad={onLoad}
				></iframe>
			</div>
		)
	}
	// Если есть ссылка на загруженное видео
	else if (downloadedVideoLink) {
		return (
			<video id="video" controls className="video-frame__video">
				<source src={downloadedVideoLink} type="video/mp4" />
			</video>
		)
	}

	return null
}

export default VideoFrame
