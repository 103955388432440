import { message } from 'antd'
import liveRequests from '../requests/liveLesson/livelessonRequest'

const LiveLessonService = {
	async getToken(liveId: number) {
		try {
			const response = await liveRequests.getLiveLessonToken(liveId)
			const { token } = response.data

			if (!token) {
				throw new Error('Не удалось получить токен')
			}

			return response.data
		} catch (error) {
			message.error('Не удалось получить токен')
			throw new Error(JSON.stringify(error))
		}
	},

	async leaveParticipant(liveId: number) {
		try {
			await liveRequests.leaveParticipant({ liveId })
		} catch (error) {
			throw new Error(JSON.stringify(error))
		}
	},

	async getLiveLesson(liveId: number) {
		try {
			const response = await liveRequests.getLiveLesson(liveId)

			return response.data
		} catch (error) {
			message.error('Не удалось получить урок')
			throw new Error(JSON.stringify(error))
		}
	},

	async getMessageServer() {
		try {
			const response = await liveRequests.getMessageServer()

			return response.data
		} catch (error) {
			message.error('Не удалось получить данные сервера')
			throw new Error(JSON.stringify(error))
		}
	},

	async getStorageItems() {
		try {
			const response = await liveRequests.getStorageItems()

			return response.data
		} catch (error) {
			message.error('Не удалось получить данные сервера')
			throw new Error(JSON.stringify(error))
		}
	},
}

export default LiveLessonService
