import { liveLessonQuery } from 'parts/requests/liveLesson/liveLessonQuery'
import useLiveLibraryStore from '../zustand/store'

function useStorageItemClick() {
	const { status, data: storageItems } =
		liveLessonQuery.getStorageItems.useQuery()
	const setSelectedFolder = useLiveLibraryStore(
		(store) => store.setSelectedFolder
	)

	const onStorageItemClick = (itemId: number) => {
		const item = storageItems?.data.find((item) => item.id === itemId)

		if (item && item.isFolder) {
			setSelectedFolder({
				id: item.id,
				name: item.name,
				parentId: item.parentId,
			})
		}
	}

	return {
		onStorageItemClick,
	}
}

export default useStorageItemClick
