import { useTranslation } from 'react-i18next'
import {
	UTableHCell,
	UTableHeaderCellSearchContent,
	UTableHRow,
	UTableSearchButton,
} from 'ui/UTable/UTable'
import { StudentsStoreType } from '../../zustand/storeTypes'
import {
	getClearSearchValueFn,
	useIsClearButtonVisible,
} from './fn/clearSearchValue'
import FullNameForm from '../forms/FullNameForm/FullNameForm'
import EmailForm from '../forms/EmailForm/EmailForm'
import PhoneForm from '../forms/PhoneForm/PhoneForm'
import CountryForm from '../forms/CountryForm/CountryForm'
import StatusForm from '../forms/StatusForm/StatusForm'

// Заголовочный ряд таблицы учеников
function TableHeader() {
	const { t } = useTranslation()

	// Видимы ли кнопки очистки значений поиска по колонке
	const {
		isFullNameClearBtnOpen,
		isEmailClearBtnOpen,
		isPhoneClearBtnOpen,
		isCountryClearBtnOpen,
		isStatusesClearBtnOpen,
	} = useIsClearButtonVisible()

	const clearFullName = getClearSearchValueFn(
		StudentsStoreType.SearchValueNames.FullName
	)
	const clearEmail = getClearSearchValueFn(
		StudentsStoreType.SearchValueNames.Email
	)
	const clearPhone = getClearSearchValueFn(
		StudentsStoreType.SearchValueNames.Phone
	)
	const clearCountry = getClearSearchValueFn(
		StudentsStoreType.SearchValueNames.Country
	)
	const clearStatus = getClearSearchValueFn(
		StudentsStoreType.SearchValueNames.Status
	)

	return (
		<UTableHRow>
			<UTableHCell>
				<UTableHeaderCellSearchContent
					text={t('students.studentsTableHeaderNameAndSurname')}
					isClearBtnVisible={isFullNameClearBtnOpen}
					onClearBtnClick={clearFullName}
					buttonWithForm={
						<UTableSearchButton ButtonWithForm={<FullNameForm />} />
					}
				/>
			</UTableHCell>
			<UTableHCell>
				<UTableHeaderCellSearchContent
					text={t('students.studentsTableHeaderEmail')}
					isClearBtnVisible={isEmailClearBtnOpen}
					onClearBtnClick={clearEmail}
					buttonWithForm={
						<UTableSearchButton ButtonWithForm={<EmailForm />} />
					}
				/>
			</UTableHCell>
			<UTableHCell>
				<UTableHeaderCellSearchContent
					text={t('students.studentsTableHeaderPhone')}
					isClearBtnVisible={isPhoneClearBtnOpen}
					onClearBtnClick={clearPhone}
					buttonWithForm={
						<UTableSearchButton ButtonWithForm={<PhoneForm />} />
					}
				/>
			</UTableHCell>
			<UTableHCell>
				<UTableHeaderCellSearchContent
					text={t('students.studentsTableHeaderCountry')}
					isClearBtnVisible={isCountryClearBtnOpen}
					onClearBtnClick={clearCountry}
					buttonWithForm={
						<UTableSearchButton ButtonWithForm={<CountryForm />} />
					}
				/>
			</UTableHCell>
			<UTableHCell>
				<UTableHeaderCellSearchContent
					text={t('students.studentsTableHeaderStatus')}
					isClearBtnVisible={isStatusesClearBtnOpen}
					onClearBtnClick={clearStatus}
					buttonWithForm={
						<UTableSearchButton ButtonWithForm={<StatusForm />} />
					}
				/>
			</UTableHCell>
		</UTableHRow>
	)
}

export default TableHeader
