import React, { useCallback, useEffect, useState } from 'react'
import { convertExerciseDataToExerciseState } from './main'
import ExerciseStateType from './ExerciseStateType'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

/**
 * Хук возвращает функцию заново запускающую процесс прохождения упражнения
 * @param {Object} exercise — объект с данными упражнения
 * @param {Function} setExerciseState — функция обновляющая объект состояния упражнения
 */
export function useGetOnPassAgainButtonClick(
	exercise: TrainingEntityTypes.TestExercise,
	setExerciseState: React.Dispatch<
		React.SetStateAction<ExerciseStateType.Main>
	>
) {
	return useCallback(
		function () {
			setExerciseState(convertExerciseDataToExerciseState(exercise))
		},
		[exercise]
	)
}

const initialStats = {
	passedNum: 0,
	totalNum: 0,
}

/**
 * Получение статистики пройденного упражнения: количество пройденных и общее количество вопросов.
 * @param {Object} state — объект Состояния упражнения.
 */
export function getExerciseResult(state: ExerciseStateType.Main) {
	const [stats, setStats] = useState(initialStats)

	useEffect(
		function () {
			setStats(calculateStats(state))
		},
		[state]
	)

	return stats
}

/**
 * Получение количество пройденных и общее количество вопросов.
 * @param {Object} state — объект Состояния упражнения.
 */
function calculateStats(state: ExerciseStateType.Main) {
	const passed = getPassedQuestionsNumber(state)
	const total = state.questions.length

	return {
		passedNum: passed,
		totalNum: total,
	}
}

/**
 * Возвращает количество пройденных вопросов.
 * @param {Object} state — объект Состояния упражнения.
 */
function getPassedQuestionsNumber(state: ExerciseStateType.Main) {
	let passedQuestions = 0

	state.questions.forEach((question) => {
		// Изначально буду считать, что вопрос отвечен правильно
		let allAnswersAreCorrect = true

		question.answers.forEach((answer) => {
			if (
				(answer.correct && !answer.checked) ||
				(answer.checked && !answer.correct)
			) {
				allAnswersAreCorrect = false
			}
		})

		if (allAnswersAreCorrect) {
			passedQuestions++
		}
	})

	return passedQuestions
}
