import { useTranslation } from 'react-i18next'
import { UTableHCell, UTableHRow } from 'ui/UTable/UTable'

// Заголовочный ряд таблицы сотрудников
function TableHeader() {
	const { t } = useTranslation()

	return (
		<UTableHRow>
			<UTableHCell>{t('staff.tableHCellName')}</UTableHCell>
			<UTableHCell>{t('staff.tableHCellRole')}</UTableHCell>
			<UTableHCell>{t('staff.tableHCellPhone')}</UTableHCell>
			<UTableHCell>{t('staff.tableHCellEmail')}</UTableHCell>
			<UTableHCell>{t('staff.tableHCellGroups')}</UTableHCell>
			<UTableHCell>{t('staff.tableHCellStatus')}</UTableHCell>
		</UTableHRow>
	)
}

export default TableHeader
