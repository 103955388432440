import { CardConnectionType } from '../../common'
import { UBlockBorderColor } from 'ui/wrappers/UCardBlock/UCardBlock'

/**
 * Возвращает цвет обводки в зависимости от типа соединения с платёжной системой.
 * @param connectionType — тип соединения с платёжной системой.
 */
export function getBorderColor(
	connectionType: CardConnectionType
): UBlockBorderColor {
	if (connectionType == 'on') {
		return UBlockBorderColor.Green
	} else if (connectionType == 'off') {
		return UBlockBorderColor.Orange
	}

	return UBlockBorderColor.Gray
}
