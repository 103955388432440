import { Block } from '../ExistenceBlock/ExistenceBlock'
import existenceBlocksManager from '../ExistenceBlocksManager/existenceBlocksManager'
import './ExistenceBlocks.scss'

// Обёртка с двумя колонками куда помещаются блоки с описанием пользователя, которому подойдёт предлагаемый курс
function ExistenceBlocks() {
	const leftBlocks = existenceBlocksManager.useGetColumnBlocks(1)
	const rightBlocks = existenceBlocksManager.useGetColumnBlocks(2)

	let blockIdx = -1

	return (
		<div className="landing-existence-blocks">
			<div className="landing-existence-blocks__column">
				{leftBlocks.map((block) => {
					blockIdx++

					return (
						<Block
							block={block}
							blockIdx={blockIdx}
							key={block.id}
						/>
					)
				})}
			</div>
			<div className="landing-existence-blocks__column">
				{rightBlocks.map((block) => {
					blockIdx++

					return (
						<Block
							block={block}
							blockIdx={blockIdx}
							key={block.id}
						/>
					)
				})}
			</div>
		</div>
	)
}

export default ExistenceBlocks
