import useLeadsStore from '../../../../LeadsTable/zustand/store'
import { LeadsStoreType } from '../../../../LeadsTable/zustand/storeTypes'

export enum FieldsNames {
	// Поле со строкой поиска
	Phone = 'phone',
}

export interface FormValues {
	[FieldsNames.Phone]: string
}

/** Обработчик отправки формы поиска по Инстаграму */
export function useGetOnPhoneChange() {
	const setSearchValuesProp = useLeadsStore(
		(store) => store.setSearchValuesProp
	)

	return function (values: FormValues) {
		setSearchValuesProp(
			LeadsStoreType.SearchValueNames.Phone,
			values[FieldsNames.Phone]
		)
	}
}
