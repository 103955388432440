import { useGetToggleVisibilityTakePauseModal } from './openCloseTakePauseModal'

/** Хук возвращающий обработчик отправки формы */
export function useGetOnSubmit() {
	const closeModal = useGetToggleVisibilityTakePauseModal()

	return function () {
		closeModal()
	}
}
