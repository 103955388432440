import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import MetaOutput from 'ui/MetaOutput/MetaOutput'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import {
	breadcrumbs,
	useGetMetaPanelConfig,
	useGetTabsData,
} from './fn/forHeader'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import Loader from 'ui/Loader/Loader'
import { ActionsButton } from '../HeaderButtons/HeaderButtons'
import { useFillInStore } from './fn/fillInStore'
import { getUserName } from 'parts/utils/string'
import Avatar from 'ui/Avatar/Avatar'
import useEmployeeStore from '../zustand/store'
import './EmployeePage.scss'
import AppUrls from 'parts/constants/pageUrl'
import NoContent from 'ui/NoContent/NoContent'
import Footer from 'ui/Footer/Footer'
import { useGetUserRole } from 'parts/utils/hooks/hooks'

/** Страница добавления ученика */
function EmployeePage() {
	const { t } = useTranslation()
	const userRoles = useGetUserRole()

	useFillInStore()
	const employee = useEmployeeStore((store) => store.employee)

	const metaPanelConfig = useGetMetaPanelConfig()
	const tabsData = useGetTabsData()

	if (employee === undefined) {
		return (
			<ArticleContentWrapper center>
				<Loader />
			</ArticleContentWrapper>
		)
	} else if (employee === null) {
		return (
			<ArticleContentWrapper center>
				<NoContent
					header="Сотрудника не существует"
					text="Он был удалён или введён неправильный адрес"
					btnConf={{
						text: 'Перейти на список сотрудников',
						to: AppUrls.Staff().url,
					}}
				/>
			</ArticleContentWrapper>
		)
	}

	return (
		<PageSkeleton>
			<PageHeader
				breadcrumbs={breadcrumbs}
				header={<UserNameBlock />}
				meta={<MetaOutput items={metaPanelConfig} />}
				buttons={[<ActionsButton key={1} />]}
				tabs={tabsData}
			/>
			<Outlet />
			<Footer isRightSideHidden={!userRoles.isAdminOrManager} />
		</PageSkeleton>
	)
}

export default EmployeePage

// Блок с фотографией, именем пользователя и отметиной активированности
function UserNameBlock() {
	const student = useEmployeeStore((store) => store.employee)
	if (!student) return null

	const name = getUserName(student)

	return (
		<div className="employee-main-article__user-name">
			<Avatar src={student.avatar} width={40} />
			{name}
			{student.isActivated && (
				<img
					src="/images/signs/CheckCircleTwoTone.svg"
					className="employee-main-article__user-activation-mark"
				/>
			)}
		</div>
	)
}
