import { useTranslation } from 'react-i18next'
import Loader from 'ui/Loader/Loader'
import ExercisesListForAdmin from './ExercisesListForAdmin/ExercisesListForAdmin'
import { useFillInStore } from './zustand/fillStore/fillInStore'
import useExercisesListAdminStore from './zustand/store'
import { ExercisesListStoreType } from './zustand/storeTypes'

type GetLessonsAdminRouterReturn = {
	status: ExercisesListStoreType.DataLoadingStatus
	element: JSX.Element
}

/** Хук возвращающий статус компонента LessonsListAdmin и сам компонент в зависимости от статуса */
export function useGetExercisesAdminRouter(): GetLessonsAdminRouterReturn {
	const { t } = useTranslation()
	useFillInStore()

	const dataLoadingStatus = useExercisesListAdminStore(
		(state) => state.dataLoadingStatus
	)

	if (dataLoadingStatus == 'loading') {
		return {
			status: dataLoadingStatus,
			element: <Loader />,
		}
	} else if (dataLoadingStatus == 'error') {
		return {
			status: dataLoadingStatus,
			element: <p>При получении данных произошла ошибка.</p>,
		}
	}

	return {
		status: dataLoadingStatus,
		element: <ExercisesListForAdmin />,
	}
}
