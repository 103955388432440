import TrainingStudentService from 'parts/services/TrainingStudentService'
import { useTranslation } from 'react-i18next'
import useTrainingHeaderStudentStore from '../../zustand/store'

export function useGetTrainingStatusForHeader() {
	const { t } = useTranslation()
	const training = useTrainingHeaderStudentStore((state) => state.training)

	const status = TrainingStudentService.getTrainingStatus(training)

	if (status == 'firstLessonOpeningWait') {
		return {
			color: '#f50',
			text: t('studentTraining.trainingHeaderStatusWaiting'),
		}
	} else if (status == 'openPartly' || status == 'openFully') {
		return {
			color: '#87d068',
			text: t('studentTraining.trainingHeaderStatusProcess'),
		}
	} else if (status == 'groupEnded') {
		return {
			color: '#f50',
			text: t('studentTraining.trainingHeaderStatusGroupFinished'),
		}
	} else if (status == 'outdated') {
	}

	return {
		color: '#000',
		text: t('studentTraining.trainingHeaderStatusForbidden'),
	}
}
