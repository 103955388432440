import { useEffect } from 'react'
import { FormInstance } from 'antd'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { FieldNames, FormValuesType } from './form'
import useTrainingsAdminStore from 'widgets/trainingsListAdmin/TrainingsList/zustand/store'
import useEditTrainingModalStore from '../zustand/store'
import TrainingAdminService from 'parts/services/TrainingAdminService'

// Возвращает первоначальные значения полей формы если создается новый урок (проверяется наличие id)
export function useSetValuesFormValues(form: FormInstance) {
	const trainings = useTrainingsAdminStore((store) => store.rawTrainings)
	const [trainingId] = useEditTrainingModalStore((s) => [s.trainingId])

	useEffect(
		function () {
			const training = TrainingAdminService.findTrainingById(
				trainings,
				trainingId
			)
			if (!training) return

			const formInitData = useGetInitialValues(training)

			form.setFieldsValue(formInitData)
		},
		[trainingId]
	)
}

/** Возвращает первоначальные значения полей формы редактирования урока. */
export function useGetInitialValues(
	training: TrainingEntityTypes.TrainingAdmin
): FormValuesType {
	return {
		[FieldNames.Name]: training.name,
		[FieldNames.Description]: training.description || '',
		[FieldNames.BlockName]: training.type,
		[FieldNames.Level]: training.level,
		[FieldNames.Lang]: training.lang,
		[FieldNames.Order]: training.order,
		[FieldNames.ShowOnMain]: training.showOnMainScreen,
	}
}
