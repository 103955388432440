import useExerciseStore from '../../zustand/exerciseState'

/**
 * Функция, показывающая экран с результатом выполнения упражнения учеником и статистикой по каждой попытке
 */
export function useGetShowResult() {
	const saveStoreForRestore = useExerciseStore(
		(store) => store.saveStoreForRestore
	)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		// Отправить на состояние с экраном результата
		updateStore({
			stage: 'Result',
			saveStoreForRestore: saveStoreForRestore + 1,
		})
	}
}
