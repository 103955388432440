import EntityTypes from 'parts/types/EntityTypes'
import useAnswersFeedStore from './fn/store'

const ExerciseModalController = {
	open(answer: EntityTypes.Answer) {
		const { updateStore } = useAnswersFeedStore.getState()
		updateStore({ answer })
	},
	close() {
		const { updateStore } = useAnswersFeedStore.getState()
		updateStore({ answer: null })
	},
}

export default ExerciseModalController
