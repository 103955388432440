import { useEffect } from 'react'
import { message } from 'antd'
import { lessonsQuery } from '../../../../parts/requests/lessons/lessonsQuery'
import UrlService from '../../../../parts/services/UrlService'
import useExerciseStudentStore from '../../zustand/store'
import trainingRequests from 'parts/requests/training/trainingRequest'

/**
 * Хук при загрузке страницы проверяет, что показываемое упражнение принадлежит первому урока.
 * И если это так, то отправляет на сервер запрос сообщающий, что первый урок был открыт.
 * После этого ученику становятся доступны все последующие уроки.
 */
export function useSayToServerThatFirstLessonWasOpened() {
	const [training, groupId] = useExerciseStudentStore((state) => [
		state.trainingStudent,
		state.groupId,
	])

	useEffect(
		function () {
			if (!training) return

			// Если время начала группы стоит в значении firstLessonOpening, то значит в первый раз открыли первый урок.
			// Об этом нужно сообщить серверу.
			// После этого в качестве даты начала курса будет текущее число.
			if (training.startDate === 'firstLessonOpening') {
				sayToServerThatFirstLessonWasOpened(groupId)
			}
		},
		[groupId]
	)
}

/**
 * Отправляет на сервер запрос сообщающий, что первый урок курса был открыт.
 * @param {Number} groupId — id группы, к которой принадлежит ученик проходящий упражнение
 */
async function sayToServerThatFirstLessonWasOpened(groupId: number) {
	try {
		await trainingRequests.openFirstLesson(groupId)
	} catch (err) {
		message.error('Не удалось открыть остальные уроки курса.')
	}
}

/**
 * Запускает функцию, сообщающую серверу, что урок открыт.
 * Требуется для статистики когда уроки были открыты
 */
export function useSayToServerTimeWhenLessonOpen() {
	const lessonId = UrlService.useGetLessonId()

	const openLessonSignalMutation = lessonsQuery.openLessonSignal.useMutation()

	useEffect(function () {
		openLessonSignalMutation.mutate(lessonId)
	}, [])
}
