import { useEffect, useState } from 'react'
import ExerciseStateType, { initialState } from './ExerciseStateType'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

/**
 * Хук создаёт состояние упражнения
 * @param {Object} exercise — данные упражнения из БД
 */
export function useManageExerciseState(
	exercise: TrainingEntityTypes.DescriptionImgExercise
) {
	const [state, setState] = useState<ExerciseStateType.Main>(initialState)

	useEffect(
		function () {
			setState(convertExerciseDataToExerciseState(exercise))
		},
		[exercise]
	)

	return { state, setState }
}

/**
 * Функция получает объект данных упражнения и создаёт данные для местного Состояния
 * @param {Object} exercise — данные упражнения из БД
 */
export function convertExerciseDataToExerciseState(
	exercise: TrainingEntityTypes.DescriptionImgExercise
): ExerciseStateType.Main {
	let state: ExerciseStateType.Main = {
		exerciseId: exercise.id!,
		exerciseTask: exercise.item.task,
		images: [],
		descriptions: [],

		totalImages: exercise.item.images.length,
		answeredImages: 0,
		attempts: 3,
		readyToShowResult: false,
		showResult: false,
		resultButtonText: 'Проверить',
	}

	exercise.item.images.forEach((imageObj, i) => {
		const index = i + 1

		state.images.push({
			id: index,
			imageSrc: imageObj.image,
			givenDescId: null,
		})

		state.descriptions.push({
			id: index,
			text: imageObj.description,
			// status: 'default',
			rightWord: imageObj.description,
		})
	})

	// Смешать изображения, чтобы изменить порядок следования
	state.images.sort(() => {
		return Math.random() - 0.5 < 0 ? 1 : -1
	})

	// Установка в массив описаний описания не подходящие ни к одной картинке
	exercise.item.wrong.forEach((wrongWord, i) => {
		state.descriptions.push({
			id: i + 1000,
			text: wrongWord,
			rightWord: '',
		})
	})

	// Смешать описания, чтобы изменить порядок следования
	state.descriptions.sort(() => {
		return Math.random() - 0.5 < 0 ? 1 : -1
	})

	return state
}

/**
 * Функция ищет и возвращает объект слова по идентификатору
 * @param {Object} state — объект состояния упражнения
 * @param {Number} wordId — id перемещаемого слова
 */
export function getWordObjById(
	state: ExerciseStateType.Main,
	wordId: number | null
) {
	return state.descriptions.find((descObj) => descObj.id == wordId)
}
