import { useEffect, useMemo } from 'react'
import { userQuery } from 'parts/requests/user/userQuery'
import { groupQuery } from 'parts/requests/group/groupQuery'
import EntityTypes from 'parts/types/EntityTypes'
import UserApiTypes from 'parts/requests/user/userApiTypes'
import useGroupStaffStore from '../zustand/store'
import staffManager from '../zustand/staffManager'
import { GroupStaffStoreType } from '../zustand/storeTypes'
import UrlService from 'parts/services/UrlService'

/** Наполняет Зустанд данными, которые будут использоваться на странице сотрудников группы */
export function useFillInStore() {
	const groupId = UrlService.useGetGroupId()
	const stuff = useGetStuff()
	const updateState = useGroupStaffStore((store) => store.updateState)

	useEffect(
		function () {
			if (!stuff) return

			fillInStore(stuff, updateState, groupId)
		},
		[stuff]
	)
}

/** Скачивает с сервера и возвращает массив сотрудников */
function useGetStuff(): undefined | Staff {
	const groupId = UrlService.useGetGroupId()

	const { data: curatorsData } = userQuery.getCurators().useQuery()

	const { data: groupCuratorsData } = groupQuery
		.getGroupCurators(groupId)
		.useQuery()

	const { data: managersData } = userQuery
		.getUserByRole(EntityTypes.UserRole.MANAGER)
		.useQuery()

	return useMemo(
		function () {
			if (!curatorsData || !groupCuratorsData || !managersData) return

			return {
				allCurators: curatorsData.data,
				groupCurators: groupCuratorsData.data,
				allManagers: managersData.data,
			}
		},
		[curatorsData, groupCuratorsData, managersData]
	)
}

/**
 * Наполняет объект Состояния данными
 * @param staff
 * @param updateState
 * @param groupId — id текущей группы
 */
async function fillInStore(
	staff: Staff,
	updateState: GroupStaffStoreType.UpdateState,
	groupId: number
) {
	const { allCurators, groupCurators, allManagers } = staff

	// Подготовить массив кураторов для помещения в Состояние...
	const curatorsForStore =
		staffManager.convertServCuratorsToStateCurators(groupCurators)

	// Подготовить массив менеджеров для помещения в Состояние...
	const managersForStore = staffManager.convertServManagersToStateManagers(
		allManagers,
		groupId
	)

	// Поставить их в Состояние
	updateState({
		schoolHasStaff: staffManager.hasSchoolStaff(allCurators, allManagers),
		staff: [...curatorsForStore, ...managersForStore],
	})
}

type Staff = {
	allCurators: EntityTypes.Curator[]
	groupCurators: EntityTypes.Curator[]
	allManagers: UserApiTypes.GetUserByRole
}
