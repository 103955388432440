import { Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { UFormNewRow } from 'ui/UForm/UForm'
import useLiveLessonEntrancePageStore from '../../zustand/store'
import ScreenWrapper from '../ScreenWrapper/ScreenWrapper'
import { FieldNames } from './fn/form'
import { useGetOnSubmit } from './fn/onSubmit'

// Страницы ввода имени ученика для регистрации ученика в этой школе.
function RegFormScreen() {
	const { t } = useTranslation()
	const [form] = Form.useForm()

	const onSubmit = useGetOnSubmit()

	return (
		<ScreenWrapper
			header={t('live.liveEntranceRegFormScreenHeader')}
			description={t('live.liveEntranceRegFormScreenDescription')}
		>
			<Form
				form={form}
				name="register"
				layout="vertical"
				onFinish={onSubmit}
			>
				<EmailInput />
				<NameInput />
				<Bottom />
			</Form>
		</ScreenWrapper>
	)
}

export default RegFormScreen

function EmailInput() {
	const { t } = useTranslation()
	const email = useLiveLessonEntrancePageStore((store) => store.email)

	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.Email}
				label={t('live.liveEntranceRegFormInputEmailLabel')}
				rules={[
					{
						type: 'email',
						message: t(
							'live.liveEntranceRegFormInputEmailWrongEmail'
						),
					},
					{
						required: true,
						message: t(
							'live.liveEntranceRegFormInputEmailRequired'
						),
					},
				]}
				initialValue={email || 'andkozinsky@yandex.ru'}
			>
				<Input
					type="email"
					placeholder="mail@mail.com"
					size="large"
					autoComplete="username"
					disabled
				/>
			</Form.Item>
		</UFormNewRow>
	)
}

function NameInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Name}
				label={t('live.liveEntranceRegFormInputNameLabel')}
				rules={[
					{
						required: true,
						message: t('live.liveEntranceRegFormInputNameRequired'),
					},
				]}
			>
				<Input type="text" size="large" autoComplete="username" />
			</Form.Item>
		</UFormNewRow>
	)
}

function Bottom() {
	const { t } = useTranslation()
	const form = Form.useFormInstance()

	return (
		<UFormNewRow topOffset>
			<Form.Item shouldUpdate>
				{() => {
					const formErrors = form
						.getFieldsError()
						.filter(({ errors }) => errors.length)

					return (
						<Button
							block
							size="large"
							type="primary"
							htmlType="submit"
							disabled={formErrors.length > 0}
							// loading={loading}
						>
							{t('live.liveEntranceRegFormOkBtn')}
						</Button>
					)
				}}
			</Form.Item>
		</UFormNewRow>
	)
}
