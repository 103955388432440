import { message } from 'antd'
import dayjs from 'dayjs'
import trainingRequests from '../requests/training/trainingRequest'
import TrainingEntityTypes from '../types/TrainingEntityTypes'
import { addDaysToDate, isDatePassed } from '../utils/time'
import { useParams } from 'react-router-dom'
import { RouterVarNames } from '../../app/routes/special/otherRoutes'
import { trainingQuery } from '../requests/training/trainingQuery'
import UrlService from './UrlService'

// Статус курса для ученика (группы):
// notOpen (группа ещё не запущена),
// firstLessonOpeningWait (для запуска требуется открыть первый урок),
// openPartly (уроки частично открыты),
// openFully (все уроки открыты),
// groupEnd (время действия группы завершено),
// outdated (истекло время доступа к курсу)
export type TrainingStatusType =
	| 'notOpen'
	| 'firstLessonOpeningWait'
	| 'openPartly'
	| 'openFully'
	| 'groupEnded'
	| 'outdated'

// Методы для работы с курсами
const TrainingStudentService = {
	/** Получение текущего курса с сервера для пользователя с правами ученика */
	useGetTraining() {
		const params = useParams()
		const trainingIdStr = params[RouterVarNames.TrainingId]!
		const { courseId, groupId } =
			UrlService.getTrainingAndGroupIds(trainingIdStr)

		return trainingQuery.getTrainingForStudent(courseId, groupId).useQuery()
	},

	// ВМЕСТО ЭТОГО ИСПОЛЬЗОВАТЬ React Query!!!
	/** Получение курса с сервера для пользователя с правами ученика */
	async getTrainingFromDBForStudent(
		trainingId: number | string,
		groupId: number | string
	) {
		try {
			const response = await trainingRequests.getTrainingForStudent(
				trainingId,
				groupId
			)

			if (response.status === 200 && response.data) {
				return response.data
			} else {
				return null
			}
		} catch (error) {
			message.error('Не удалось получить курс')
			return null
		}
	},

	/**
	 * Возвращает дату до какого числа курс доступен ученику для прохождения и просмотра
	 * @param training — курс для ученика
	 */
	getMaxStudentTrainingAccessDate(
		training: TrainingEntityTypes.TrainingStudent
	): null | Date {
		// Если курс начинается после открытия первого урока, то вернуть null
		// потому что время посчитать невозможно,
		// потому что курс ещё не начался, так как первый урок ещё не открыли.
		if (training.startDate === 'firstLessonOpening') {
			return null
		}

		const date = new Date(training.startDate)

		// Прибавить к дате количество дней сколько курс продлится
		addDaysToDate(date, training.durationInDays)
		// Прибавить к дате количество дней сколько курс будет доступен после завершения группы
		addDaysToDate(date, training.accessInDays)

		return date
	},

	/**
	 * Возвращает дату начала работы группы
	 * @param training — курс для ученика
	 */
	getStudentTrainingGroupStartDate(
		training: TrainingEntityTypes.TrainingStudent
	): null | Date {
		if (training.startDate === 'firstLessonOpening') return null

		return new Date(training.startDate)
	},

	/**
	 * Возвращает дату до какого числа будет проходить группа
	 * @param training — курс для ученика
	 */
	getStudentTrainingGroupEndDate(
		training: TrainingEntityTypes.TrainingStudent
	): null | Date {
		if (training.startDate === 'firstLessonOpening') return null

		const endDate = dayjs(training.startDate).add(
			training.durationInDays,
			'day'
		)

		return endDate.toDate()
	},

	/**
	 * Возвращает статус курса касаемо открытости его уроков.
	 * @param {Object} training — объект курса
	 */
	getTrainingStatus(
		training: TrainingEntityTypes.TrainingStudent
	): TrainingStatusType {
		const { startDate } = training

		const now = new Date()

		// Дата, до которой ученик может просматривать курс
		const trainingAccessDate =
			TrainingStudentService.getMaxStudentTrainingAccessDate(training)

		// Дата действия группы
		const groupAccessDate =
			TrainingStudentService.getStudentTrainingGroupEndDate(training)

		// Если в startDate стоит значение firstLessonOpening, то курс откроется после первого урока.
		// Если в trainingAccessDate или в groupAccessDate нет значения, то это свидетельствует о том же.
		if (
			startDate == 'firstLessonOpening' ||
			!trainingAccessDate ||
			!groupAccessDate
		) {
			return 'firstLessonOpeningWait'
		}

		// Если ученик не может просматривать курс
		if (isDatePassed(trainingAccessDate)) return 'outdated'

		// Если время действия группы прошло
		if (isDatePassed(groupAccessDate)) return 'groupEnded'

		// Есть ли хотя бы один открытый урок и все ли уроки открыты
		let hasOpenedLessons = false
		let isAllLessonsOpened = true

		for (let i = 0; i < training.lessons.length; i++) {
			const lesson = training.lessons[i]

			const lessonStartDate = new Date(lesson.startDate)

			if (lessonStartDate < now) {
				hasOpenedLessons = true
			} else {
				isAllLessonsOpened = false
			}
		}

		if (isAllLessonsOpened) {
			return 'openFully'
		}

		if (hasOpenedLessons) {
			return 'openPartly'
		}

		return 'notOpen'
	},
}

export default TrainingStudentService
