import { useEffect, useMemo } from 'react'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import useCommentsStore from '../store'
import { commentsQuery } from 'parts/requests/comments/commentsQuery'
import CommentsTypes from 'parts/requests/comments/commentsApiTypes'
import { COMMENTS_LENGTH_IN_PAGE } from 'pages/comments/common/commentsConsts'

/**
 * Делает запросы на получение данных для списка комментариев и ставит в Состояние.
 * Наполняет Хранилище данными как на странице полного списка комментариев.
 */
export function useFillInStore() {
	// Получить комментарии к упражнениям
	useFetchAllCommentsAndSetToStore()

	// Поставить успешный статус загрузки если все данные загрузились успешно
	useSetSuccessStatus()
}

/** Получает с сервера список комментариев и ставит в Хранилище */
function useFetchAllCommentsAndSetToStore() {
	const updateStore = useCommentsStore((state) => state.updateStore)

	const getCommentsParams = useGetCommentsParams()

	// Получить список комментариев
	const { data: commentsRes } = commentsQuery
		.getAllComments(getCommentsParams)
		.useQuery({
			enabled: true,
		})

	useEffect(
		function () {
			if (!commentsRes) return

			updateStore({
				comments: commentsRes.data.items,
				totalComments: commentsRes.data.totalItems,
			})
		},
		[commentsRes]
	)
}

/** Возвращает параметры запроса массива ответов */
export function useGetCommentsParams(): CommentsTypes.GetCommentsParams {
	const currentPageNum = useCommentsStore((state) => state.currentPageNum)
	const isArchived = useCommentsStore((state) => state.isArchived)

	return useMemo(
		function () {
			return {
				offset: (currentPageNum - 1) * COMMENTS_LENGTH_IN_PAGE,
				limit: COMMENTS_LENGTH_IN_PAGE,
				archive: isArchived,
			}
		},
		[currentPageNum, isArchived]
	)
}

/**
 * Следит за загрузкой комментариев.
 * Если загрузить удалось, то ставит успешный статус загрузки.
 */
function useSetSuccessStatus() {
	const comments = useCommentsStore((state) => state.comments)
	const updateStore = useCommentsStore((state) => state.updateStore)
	const userRoles = useGetUserRole()

	useEffect(
		function () {
			if (!comments) return

			updateStore({
				dataLoadingStatus: 'success',
			})
		},
		[comments, userRoles]
	)
}
