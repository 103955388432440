import { Button, Form, Input } from 'antd'
import ModalWithForm from 'ui/ModalWithForm/ModalWithForm'
import {
	UFormNewBottomButtonsAfterGray,
	UFormNewButtonsContainer,
	UFormNewGeneralError,
	UFormNewRow,
} from 'ui/UForm/UForm'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'
import { FieldNames, setErrorsToForm } from './fn/form'
import { useGetOnSubmit } from './fn/submit'
import useChangeEmailStore from './state/store'

function ChangeEmailModal() {
	const [form] = Form.useForm()

	const [isOpen, closeModal, formErrors] = useChangeEmailStore((state) => [
		state.isOpen,
		state.closeModal,
		state.formErrors,
	])

	const onSubmit = useGetOnSubmit(form)
	setErrorsToForm(form)

	return (
		<ModalWithForm
			title="Изменение почты"
			open={isOpen}
			onCancel={closeModal}
		>
			<Form
				form={form}
				name="changeEmail"
				onFinish={onSubmit}
				layout="vertical"
			>
				<GrayBlock>
					<CurrentEmailInput />
					<NewEmailInput />
					<PasswordInput />
				</GrayBlock>
				<UFormNewGeneralError message={formErrors.message} />
				<Bottom />
			</Form>
		</ModalWithForm>
	)
}

export default ChangeEmailModal

function CurrentEmailInput() {
	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.CurrentEmail}
				label="Текущая почта"
				rules={[
					{
						required: true,
						message: 'Напишите текущую почту',
					},
					{
						type: 'email',
						message:
							'Почта в неправильном формате. Например: mail@mail.com',
					},
				]}
			>
				<Input type="email" autoComplete="email" />
			</Form.Item>
		</UFormNewRow>
	)
}

function NewEmailInput() {
	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.NewEmail}
				label="Новая почта. Туда будет отправлен код подтверждения."
				rules={[
					{
						required: true,
						message: 'Напишите новую почту',
					},
					{
						type: 'email',
						message:
							'Почта в неправильном формате. Например: mail@mail.com',
					},
				]}
			>
				<Input type="email" />
			</Form.Item>
		</UFormNewRow>
	)
}

function PasswordInput() {
	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Password}
				label="Пароль"
				rules={[
					{
						required: true,
						message: 'Напишите пароль',
					},
					{
						min: 6,
						message: 'Пароль должен содержать не меньше 6 символов',
					},
					{
						max: 21,
						message: 'Пароль должен содержать не более 21 символа',
					},
				]}
			>
				<Input type="password" autoComplete="current-password" />
			</Form.Item>
		</UFormNewRow>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom() {
	const form = Form.useFormInstance()
	const isSubmitting = useChangeEmailStore((state) => state.isSubmitting)

	return (
		<UFormNewBottomButtonsAfterGray>
			<div />
			<UFormNewButtonsContainer>
				<Button
					disabled={
						isSubmitting ||
						form
							.getFieldsError()
							.filter(({ errors }) => errors.length).length > 0
					}
					onClick={() => form.resetFields()}
				>
					Отмена
				</Button>

				<Form.Item shouldUpdate>
					{() => {
						const formErrors = form
							.getFieldsError()
							.filter(({ errors }) => errors.length)

						return (
							<Button
								type="primary"
								htmlType="submit"
								disabled={isSubmitting || formErrors.length > 0}
								loading={isSubmitting}
							>
								Отправить код подтверждения
							</Button>
						)
					}}
				</Form.Item>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
