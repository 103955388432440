import { liveLessonQuery } from 'parts/requests/liveLesson/liveLessonQuery'
import { useQueryClient } from 'react-query'

export function useGetRenameStorageItem() {
	const queryClient = useQueryClient()

	// Объект с методом mutate для создания запроса
	const renameStoreItemMutation =
		liveLessonQuery.renameStorageItem.useMutation({
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: liveLessonQuery.getStorageItems.key,
				})
			},
		})

	return async (id: number, name: string) => {
		renameStoreItemMutation.mutate({
			id,
			name,
		})
	}
}
