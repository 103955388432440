import React from 'react'
import useCommentsStore from 'pages/comments/zustand/store'
import Comments from 'pages/comments/Comments/Comments'
import styles from './LayoutForAdmin.module.scss'
import { Tabs, TabsProps } from 'antd'

// Контентная часть ленты ответов для администратора
function LayoutForAdmin() {
	const comments = useCommentsStore((store) => store.comments)
	const updateStore = useCommentsStore((state) => state.updateStore)

	const items: TabsProps['items'] = [
		{
			key: 'all',
			label: 'Все',
			children: (
				<div className={styles.FeedLayout}>
					<Comments comments={comments} archive={false} />
				</div>
			),
		},
		{
			key: 'archive',
			label: 'Архив',
			children: (
				<div className={styles.FeedLayout}>
					<Comments comments={comments} archive={true} />
				</div>
			),
		},
	]

	const onChange = (key: string) => {
		if (key === 'archive') {
			updateStore({ isArchived: true })
		} else {
			updateStore({ isArchived: false })
		}
	}

	return (
		<div className={styles.AdminComments}>
			<Tabs defaultActiveKey="1" items={items} onChange={onChange} />
		</div>
	)
}

export default LayoutForAdmin
