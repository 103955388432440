import React from 'react'
import cn from 'classnames'
import EditableImage from 'pages/landing/landingConstructor/details/editableElems/EditableImage/EditableImage'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import EditableText from 'pages/landing/landingConstructor/details/editableElems/EditableText/EditableText'
import 'pages/landing/landingConstructor/sections/faqSection/AsidePart/AsidePart.scss'

// Главное изображение справа в блоке «Вам подходит этот курс если...»
function AsidePart() {
	const landing = useLandingConstructorStore((store) => store.landing)
	const { colorScheme } = landing

	const headerClasses = [
		'land-const-faq-aside__header',
		'land-const-faq-aside__header--' + colorScheme,
	]

	return (
		<div className="land-const-faq-aside">
			<h2 className={cn(headerClasses)}>
				<EditableText
					propsPathArr={['sections', 'faq', 'header', 'text']}
					placeholder="Заголовок раздела"
				/>
			</h2>
			<div className="land-const-faq-aside__img-w">
				<EditableImage
					propsPathArr={['sections', 'faq', 'header', 'imageUrl']}
				/>
			</div>
		</div>
	)
}

export default AsidePart
