const mySchoolsRu = {
	// СТАТЬЯ СО СПИСКОМ ШКОЛ
	// Кнопка создания новой школы
	schoolsArticleAddSchoolButton: 'Создать школу',
	// Сообщение про отсутствие школ (первая строка)
	schoolsArticleNoSchoolsFirstLine: 'У вас нет школ.',
	// Сообщение про отсутствие школ (вторая строка)
	schoolsArticleNoSchoolsSecondLine: 'Самое время ее создать!',

	// МОДАЛЬНОЕ ОКНО ДОБАВЛЕНИЯ НОВОЙ ШКОЛЫ
	// Заголовок модального окна
	addSchoolModalHeader: 'Создание школы',
	// Подпись поля «Название школы»
	addSchoolModalFieldSchoolNameLabel: 'Название школы',
	// Сообщение об обязательности заполнения поля «Название школы»
	addSchoolModalFieldSchoolNameRequired: 'Напишите название',
	// Подпись поля «Домен школы»
	addSchoolModalFieldDomainLabel: 'Домен школы',
	// Сообщение как писать домен
	addSchoolModalFieldDomainNote:
		'Возможно использовать латиницу, цифры или дефис. Максимум 15 символов',
	// Кнопка отмены изменения формы
	addSchoolModalCancelButton: 'Отмена',
	// Кнопка создания новой школы
	addSchoolModalOkButton: 'Создать школу',
	// Сообщение про невозможность создать школу из-за ошибки сервера
	addSchoolModalCannotCreateSchool: 'Не удалось создать школу.',
	// Сообщение про успешное создание школы на сервере
	addSchoolModalSchoolCreated: 'Создана новая школа.',

	// МОДАЛЬНОЕ ОКНО РЕДАКТИРОВАНИЯ ШКОЛЫ
	// Подпись поля «Название школы»
	editSchoolModalFieldSchoolNameLabel: 'Название школы',
	// Подпись поля «Название школы»
	editSchoolModalFieldDomainLabel: 'Домен школы',
	// Подпись поля «Название школы»
	editSchoolModalFieldDomainNote:
		'Возможно использовать латиницу, цифры или дефис. Максимум 15 символов',
	// Подпись поля «Описание»
	editSchoolModalFieldDescriptionLabel: 'Описание',
	// Подпись поля «Оферта»
	editSchoolModalFieldOfferLabel: 'Оферта',
	// Кнопка удаления школы
	editSchoolModalDeleteSchoolButton: 'Удалить школу',
	// Кнопка сохранения школы
	editSchoolModalSaveSchoolButton: 'Сохранить',

	// МОДАЛЬНОЕ ОКНО УДАЛЕНИЯ ШКОЛЫ
	// Заголовок модального окна удаления школы
	deleteSchoolModalHeader: 'Удаление школы',
	// Подпись поля с названием удаляемой школы
	deleteSchoolModalFieldNameLabel: 'Для удаление школы введите ее название',
	// Сообщение с просьбой написать название удаляемой школы
	deleteSchoolModalFieldNameRequired: 'Напишите название удаляемой школы',
	// Первая строка сообщения ниже поля ввода названия удаляемой школы
	deleteSchoolModalFieldNameBeAwareFirst: 'Будьте внимательны!',
	// Вторая строка сообщения ниже поля ввода названия удаляемой школы
	deleteSchoolModalFieldNameBeAwareSecond:
		'Удаление можете привести к потере всех данных школы.',
	// Кнопка отмены удаления школы
	deleteSchoolModalCancelButton: 'Отмена',
	// Кнопка подтверждения удаления школы
	deleteSchoolModalOkButton: 'Удалить школу',
	// Сообщение об успешном удалении школы
	deleteSchoolModalSuccessMessage: 'Школа удалена.',
}

export default mySchoolsRu
