import React from 'react'
import { useTranslation } from 'react-i18next'
import AuthPage from '../../../common/AuthPage/AuthPage'
import ContentWrapper from '../../../common/ContentWrapper/ContentWrapper'
import UrlService from 'parts/services/UrlService'
import ConfirmForm from '../ConfirmForm/ConfirmForm'

// Страница с формой запроса на удаление аккаунта
function DeleteAccountConfirmPage() {
	const { t } = useTranslation()
	const deleteAccountCode = UrlService.useGetDeleteAccountCode()

	return (
		<AuthPage>
			<ContentWrapper
				header={t('auth.deleteAccConfirmPageHeader')}
				headerDesc={t('auth.deleteAccConfirmPageDescription')}
			>
				<ConfirmForm code={deleteAccountCode} />
			</ContentWrapper>
		</AuthPage>
	)
}

export default DeleteAccountConfirmPage
