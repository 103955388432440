import { message } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import UrlService from 'parts/services/UrlService'
import useTrainingHeaderAdminStore from '../store'

/** Делает запрос на получение курса для формирования данных заголовка страницы */
export function useFillInStore() {
	const updateStore = useTrainingHeaderAdminStore(
		(state) => state.updateStore
	)

	const trainingId = UrlService.useGetTrainingId()
	const { data: trainingRes } = trainingQuery
		.getTrainingForAdmin(trainingId)
		.useQuery({
			onError: useGetQueryError(),
		})

	useEffect(
		function () {
			if (!trainingRes) return

			if (!trainingRes.data) {
				return
			}

			updateStore({
				training: trainingRes.data,
			})
		},
		[trainingRes]
	)
}

/**
 * Функция запускаемая при ошибке запроса данных. Срабатывает для любых запросов.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 */
function useGetQueryError() {
	const { t } = useTranslation()

	return function (err: unknown) {
		message.error(t('adminTraining.errorWhileDownloadTraining'))
	}
}
