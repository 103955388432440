import { Tag } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import PaymentTypes from 'parts/types/PaymentTypes'
import { useGetCopyInClipboard } from 'parts/utils/hooks/hooks'
import { useTranslation } from 'react-i18next'
import { UTableBCell, UTableBRow } from 'ui/UTable/UTable'
import { LeadsStoreType } from '../LeadsTable/zustand/storeTypes'

type TableRowProps = {
	lead: LeadsStoreType.Lead
}

// Ряд таблицы учеников
function TableRow(props: TableRowProps) {
	const { lead } = props

	const copyInClipboard = useGetCopyInClipboard()

	return (
		<UTableBRow>
			<UTableBCell key="created">
				<div className="leads-table__name-wrapper">
					<p className="leads-table__text">{lead.created}</p>
				</div>
			</UTableBCell>
			<UTableBCell key="trainingName">
				<p className="leads-table__text">{lead.trainingName}</p>
			</UTableBCell>
			<UTableBCell key="email">
				<p className="leads-table__text" onClick={copyInClipboard}>
					{lead.email}
				</p>
			</UTableBCell>
			<UTableBCell key="phone">
				<p className="leads-table__text">{lead.phone}</p>
			</UTableBCell>
			<UTableBCell key="price">
				<p className="leads-table__text">{lead.price}</p>
			</UTableBCell>
			<UTableBCell key="paymentSystem">
				<PaySystem paySystem={lead.paymentSystem} />
			</UTableBCell>
			<UTableBCell key="status">
				<p className="leads-table__text">
					<StatusTag lead={lead} />
				</p>
			</UTableBCell>
		</UTableBRow>
	)
}

export default TableRow

type StatusTagProps = {
	lead: LeadsStoreType.Lead
}

// Тег статуса лида
function StatusTag(props: StatusTagProps) {
	const { t } = useTranslation()
	const status = props.lead.paymentStatus

	if (status == PaymentTypes.PaymentStatus.Paid) {
		return (
			<Tag color="green" icon={<CheckOutlined />}>
				{t('leads.leadsTableStatusPaid')}
			</Tag>
		)
	} else {
		return (
			<Tag color="blue" icon={<CloseOutlined />}>
				{t('leads.leadsTableStatusUnPaid')}
			</Tag>
		)
	}
}

type PaySystemProps = {
	paySystem: null | PaymentTypes.System
}

// Логотип платёжной системы
function PaySystem(props: PaySystemProps) {
	const { paySystem } = props
	const { t } = useTranslation()

	let logoSrc = ''
	if (paySystem == PaymentTypes.System.UKassa) {
		logoSrc = 'images/paySystem/kassa.svg'
	} else if (paySystem == PaymentTypes.System.PayPal) {
		logoSrc = 'images/paySystem/paypal.svg'
	} else if (paySystem == PaymentTypes.System.Stripe) {
		logoSrc = 'images/paySystem/stripe.svg'
	} else if (paySystem == PaymentTypes.System.Tinkoff) {
		logoSrc = 'images/paySystem/tinkof.svg'
	}

	return logoSrc ? (
		<img src={logoSrc} alt="Касса" />
	) : (
		<span>
			{paySystem || t('leads.leadsTablePaySystemHaveNotSelected')}
		</span>
	)
}
