import { Button } from 'antd'
import ModalWithForm from 'ui/ModalWithForm/ModalWithForm'
import { RemoveUserModalController } from './RemoveUserModalController'
import useRemoveUserModalStore from './zustand/store'
import {
	UFormNewBottomButtonsWithBorder,
	UFormNewButtonsContainer,
	UFormNewGeneralError,
} from 'ui/UForm/UForm'
import { useGetDeleteUser } from './fn/deleteUser'

function RemoveUserModal() {
	const [isModalOpen, formErrors] = useRemoveUserModalStore((s) => [
		s.isModalOpen,
		s.formErrors,
	])

	return (
		<ModalWithForm
			title="Удаление учётной записи"
			open={isModalOpen}
			onCancel={RemoveUserModalController.close}
		>
			<p>Вы уверены в удалении?</p>
			<Bottom />
			<UFormNewGeneralError message={formErrors.message} />
		</ModalWithForm>
	)
}

export default RemoveUserModal

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom() {
	const [isSubmitting] = useRemoveUserModalStore((s) => [s.isSubmitting])
	const deleteUser = useGetDeleteUser()

	return (
		<UFormNewBottomButtonsWithBorder>
			<div />
			<UFormNewButtonsContainer>
				<Button
					disabled={isSubmitting}
					onClick={RemoveUserModalController.close}
				>
					Отмена
				</Button>
				<Button
					type="primary"
					htmlType="submit"
					disabled={isSubmitting}
					loading={isSubmitting}
					onClick={deleteUser}
				>
					Удалить
				</Button>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsWithBorder>
	)
}
