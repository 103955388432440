import Loader from 'ui/Loader/Loader'
import { useFillInStore } from '../../zustand/fillStore/fillInStore'
import useExerciseStudentStore from '../../zustand/store'
import ExerciseArticleNew from '../ExerciseArticleNew'
import { ExerciseStudentStoreType } from '../../zustand/storeTypes'

type RouterReturn = {
	status: ExerciseStudentStoreType.DataLoadingStatus
	element: JSX.Element
}

/** Хук возвращающий статус компонента ExerciseArticle и сам компонент в зависимости от статуса */
export function useGetExerciseArticleRouter(): RouterReturn {
	const dataLoadingStatus = useExerciseStudentStore(
		(state) => state.dataLoadingStatus
	)

	// Получить данные для отрисовки упражнения и поставить в Состояние
	useFillInStore()

	if (dataLoadingStatus == 'loading') {
		return {
			status: dataLoadingStatus,
			element: <Loader />,
		}
	} else if (dataLoadingStatus === 'error') {
		return {
			status: dataLoadingStatus,
			element: <p>Произошла ошибка при получении данных.</p>,
		}
	}

	return {
		status: dataLoadingStatus,
		element: <ExerciseArticleNew />,
	}
}
