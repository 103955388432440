import ExistenceBlocks from '../ExistenceBlocks/ExistenceBlocks'
import MainImage from '../MainImage/MainImage'
import './SectionContent.scss'

function ExistenceContent() {
	return (
		<div className="landing-existence-section-content">
			<MainImage />
			<div>
				<ExistenceBlocks />
			</div>
		</div>
	)
}

export default ExistenceContent
