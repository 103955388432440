import useExercisesAsideStore from './store'

// Менеджер с методами взаимодействия с Состоянием
export const exercisesAsideManager = {
	// Возвращает функцию открывающую панель добавления нового упражнения
	open() {
		useExercisesAsideStore.getState().updateStore({ isPanelOpen: true })
	},

	// Закрывает панель добавления нового упражнения
	close() {
		useExercisesAsideStore.getState().updateStore({ isPanelOpen: false })
	},
}
