/**
 *
 * @param {Function} timing — функция отрисовки анимации.
 * @param {Function} draw — функция вычисления прогресса анимации. Получается момент времени от 0 до 1, возвращает прогресс анимации, обычно тоже от 0 до 1.
 * @param {Number} duration — общая продолжительность анимации в миллисекундах.
 */
export function animate(
	timing: Function,
	draw: (progress: number) => number,
	duration: number
) {
	let start = performance.now()

	requestAnimationFrame(function animate(time) {
		// timeFraction изменяется от 0 до 1
		let timeFraction = (time - start) / duration
		if (timeFraction > 1) timeFraction = 1

		// вычисление текущего состояния анимации
		let progress = timing(timeFraction)

		draw(progress) // отрисовать её

		if (timeFraction < 1) {
			requestAnimationFrame(animate)
		}
	})
}
