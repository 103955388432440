import AsidePart from 'pages/landing/landingConstructor/sections/faqSection/AsidePart/AsidePart'
import FaqBlocks from 'pages/landing/landingConstructor/sections/faqSection/ExistenceBlocks/FaqBlocks'
import 'pages/landing/landingConstructor/sections/faqSection/FaqSection/FaqSection.scss'

// Секция одностраничника с частозадаваемыми вопросами
function FaqSection() {
	return (
		<div className="land-const-faq-section">
			<AsidePart />
			<FaqBlocks />
		</div>
	)
}

export default FaqSection
