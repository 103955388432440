import { useCallback } from 'react'
import { produce } from 'immer'
import JSON5 from 'json5'
import { useTranslation } from 'react-i18next'
import { getFormState, setFormState } from '../../common/exerciseFormCommonFunc'
import {
	FormQuestionStateItemType,
	TestExerciseFormStateType,
} from './stateTypes'
import { createEmptyFilesObj } from './state'

type GeneratedArrType = GeneratedObjType[]
type GeneratedObjType = {
	question: string
	answers: GeneratedAnswerObjType[]
}
type GeneratedAnswerObjType = { answer: string; correct: boolean }

/**
 * Возвращает функцию получающая сгенерированный текст, разбирает его
 * и помещает данные в поле с текстом вопроса и вариантами ответа
 */
export function useGetGeneratedTextAndSetToStore() {
	const { t } = useTranslation()

	return useCallback(function (generatedText: string) {
		// В generatedText будет строка вида:
		/*'[
			{
				"question": "What is the purpose of a foundation?",
				"answers": [
					{ "answer": "To support the weight of the building", "correct": true },
					{ "answer": "To provide shade", "correct": false },
					{ "answer": "To make the building look pretty", "correct": false }
				]
			},
			{
				"question": "What is the difference between a beam and a column?",
				"answers": [
					{ "answer": "A beam is horizontal and a column is vertical", "correct": true },
					{ "answer": "A beam is made of wood and a column is made of metal", "correct": false },
					{ "answer": "A beam is used for decoration and a column is used for support", "correct": false }
				]
			}
		]'*/

		try {
			// Попытаться превратить текст в массив
			const generatedArr: GeneratedArrType = JSON5.parse(
				generatedText.trim()
			)

			if (!generatedArrHasRightStructure(generatedArr)) {
				setGenerateErrorToExerciseStore(
					'Сгенерированные данные не в нужном формате. Попробуйте переформулировать запрос.'
				)

				return
			}

			putGeneratedDataToQuestions(generatedArr)
		} catch (error) {
			setGenerateErrorToExerciseStore(
				'Произошла ошибка при разборе текста. Попробуйте изменить текст запроса на генерирование данных.'
			)
		}
	}, [])
}

/**
 * Проверяет соответствие переданного объекта типу GeneratedArrType
 * @param {Object} generatedArr — данные, сгенерированные ChatGPT
 */
function generatedArrHasRightStructure(generatedArr: unknown) {
	if (!generatedArr || !Array.isArray(generatedArr)) return false

	// Все ли объекты имеют правильную структуру
	let objectsCheckStatus = true

	generatedArr.forEach((generatedObj) => {
		const checkResult = generatedObjHasRightStructure(generatedObj)

		if (!checkResult) {
			objectsCheckStatus = false
		}
	})

	return objectsCheckStatus
}

/**
 * Проверяет соответствие переданного объекта типу GeneratedObjType
 * @param {Object} generatedObj — данные, сгенерированные ChatGPT
 */
function generatedObjHasRightStructure(generatedObj: unknown) {
	if (!generatedObj || typeof generatedObj !== 'object') return false

	if (!('question' in generatedObj)) {
		return false
	}

	// @ts-ignore
	if (typeof generatedObj.question !== 'string') {
		return false
	}

	if (!('answers' in generatedObj)) {
		return false
	}

	// @ts-ignore
	if (!Array.isArray(generatedObj.answers)) {
		return false
	}

	let answersArrayHasWrongElems = false

	// @ts-ignore
	generatedObj.answers.forEach((answerObj) => {
		if (typeof answerObj !== 'object') {
			answersArrayHasWrongElems = true
		} else if (!('answer' in answerObj && 'correct' in answerObj)) {
			answersArrayHasWrongElems = true
		} else if (
			typeof answerObj.answer !== 'string' ||
			typeof answerObj.correct !== 'boolean'
		) {
			answersArrayHasWrongElems = true
		}
	})

	if (answersArrayHasWrongElems) {
		return false
	}

	return true
}

/**
 * Получает объект сгенерированный ChatGPT и на его основе делает вопрос и его ответы
 * @param {Object} generatedArr — данные, сгенерированные ChatGPT
 */
export function putGeneratedDataToQuestions(generatedArr: GeneratedArrType) {
	const formState = getFormState<TestExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		generatedArr.forEach((generatedObj, i) => {
			try {
				const question = createQuestionFromGeneratedData(
					i,
					generatedObj
				)

				if (draft.questions[i]) {
					draft.questions[i] = question
				} else {
					draft.questions.push(question)
				}
			} catch (err) {
				console.error('ERR--')
			}
		})

		// Обрезать массив вопросов по длине массива сгенерированных вопросов
		draft.questions.length = generatedArr.length
	})

	setFormState(newState)
}

/**
 * Получает объект сгенерированный ChatGPT и на его основе делает вопрос и его ответы
 * @param {Number} questionId — id объекта вопроса
 * @param {Object} generatedObj — объект, сгенерированный ChatGPT
 */
function createQuestionFromGeneratedData(
	questionId: number,
	generatedObj: GeneratedObjType
): FormQuestionStateItemType {
	const question: FormQuestionStateItemType = {
		id: questionId,
		questionText: generatedObj.question,
		noQuestionTextError: false,
		mediaType: 'audio',
		files: createEmptyFilesObj(),
		multipleSelect: false,
		answers: [],
		noAnswerTextError: false,
		noRightAnswerCheckedError: false,
	}

	question.answers = generatedObj.answers.map((answerObj, i) => {
		return {
			id: i,
			text: answerObj.answer,
			correct: answerObj.correct,
		}
	})

	return question
}

/**
 * Возвращает функцию получающую текст запроса на генерирование текста.
 * Эта информация сохраняется в данных упражнения
 */
export function useGetGeneratedPromptAndSetToStore() {
	return useCallback(function (generatedPrompt: string) {
		const formState = getFormState<TestExerciseFormStateType>()

		const newState = produce(formState, (draft) => {
			draft.generatedTextPrompt = generatedPrompt
		})

		setFormState(newState)
	}, [])
}

/**
 * Ставит текст ошибки в объект Состояния упражнения
 * @param {String} error — сообщение об ошибке
 */
function setGenerateErrorToExerciseStore(error: null | string) {
	const formState = getFormState<TestExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		draft.generatedTextError = error
	})

	setFormState(newState)
}
