const authEn = {
	// ОБЁРТКА СТРАНИЦ АВТОРИЗАЦИИ.
	// Сообщение про то, что представляет собой сайт
	slogan: 'Credu is a platform for teaching online!',
	// Кнопка «Назад»
	backButton: 'Back',

	// СТРАНИЦА ВХОДА
	// Заголовок страницы
	loginPageHeader: 'Entrance to Credu',

	// ФОРМА ВХОДА
	// Подпись поля ввода почты
	loginFormFieldEmailLabel: 'E-mail',
	// Сообщение про обязательный ввод почты
	loginFormFieldEmailRequired: 'Enter your e-mail.',
	// Сообщение про неправильный формат введённой почты в поле ввода почты
	loginFormFieldEmailWrongFormat:
		'The mail is in the wrong format. For example: mail@mail.com',
	// Подпись поля ввода почты
	loginFormFieldPasswordLabel: 'Password',
	// Сообщение про обязательный ввод пароля
	loginFormFieldPasswordRequired: 'Create a password',
	// Сообщение про минимальную длину пароля
	loginFormFieldPasswordMin: 'Password must contain at least 6 characters',
	// Сообщение про максимальную длину пароля
	loginFormFieldPasswordMax:
		'The password must contain no more than 21 characters',
	// Текст ссылки «Забыли пароль?»
	loginFormFieldForgetPassword: 'Forgot your password?',
	// Кнопка входа
	loginFormFieldEnterButton: 'Enter',

	// СТРАНИЦА ВОССТАНОВЛЕНИЯ ПАРОЛЯ
	// Заголовок страницы
	recoverPageHeader: 'Password recovery',
	// Текст ниже заголовка страницы
	recoverPageText:
		'An email with a password recovery code will be sent to your mailbox',
	recoverPageLoginButton: 'Entrance',

	// ФОРМА ВОССТАНОВЛЕНИЯ ПАРОЛЯ
	// Подпись поля вводы почты
	recoverFormFieldEmailLabel: 'E-mail',
	// Сообщение об обязательном вводе почты
	recoverFormFieldEmailRequired: 'Enter your e-mail.',
	// Сообщение об обязательном вводе почты
	recoverFormFieldEmailWrongFormat:
		'Mail in the wrong format. For example: mail@mail.com',
	// Кнопка отправки письма для восстановления пароля
	recoverFormSendButton: 'Send an e-mail',

	// ИНФОРМАЦИОННОЕ СООБЩЕНИЕ НИЖЕ ФОРМЫ ВВОДА НОВОГО ПАРОЛЯ
	// Текст первого шага: Если вы не видите письмо с кодом в почте, проверьте папку СПАМ, ПРОМОАКЦИИ или РАССЫЛКИ
	stepMessageStepOneInfo:
		"If you don't see the code email in the mail, check your SPAM, PROMOADS, or ADDRESSES folder",
	// Текст второго шага: Если вы не видите письмо с кодом в почте, проверьте папку СПАМ, ПРОМОАКЦИИ или РАССЫЛКИ
	stepMessageStepTwoInfo:
		"If you don't see the code email in your mail, check your SPAM folder",
	// Текст третьего шага: Если вы по ошибке меняете свой пароль - вернитесь на
	stepMessageStepThreeInfo:
		'If you change your password by mistake, go back to',
	// Текст третьего шага: ссылка на главную страницу
	stepMessageStepThreeLink: 'MAIN PAGE',

	// СТРАНИЦА УСТАНОВКИ НОВОГО ПАРОЛЯ ВЗАМЕН ЗАБЫТОГО
	// Заголовок страницы
	recoverSetPasswordPageHeader: 'Set a password',
	// Текст на ссылке входа
	recoverSetPasswordPageLoginBLink: 'Entrance',
	// Сообщение после отправки письма: На вашу почту отправлено письмо с 5-значным кодом
	recoverSetPasswordPageLetterWasSent:
		'A mail with a 5-digit code has been sent to your email',

	// ФОРМА УСТАНОВКИ НОВОГО ПАРОЛЯ ВЗАМЕН ЗАБЫТОГО
	// Подпись поля ввода пароля
	recoverSetPasswordFormFieldPasswordLabel: 'Password',
	// Сообщение про обязательный ввод пароля
	recoverSetPasswordFormFieldPasswordRequired: 'Create a password',
	// Сообщение про минимальную длину пароля
	recoverSetPasswordFormFieldPasswordMin:
		'Password must contain at least 6 characters',
	// Сообщение про максимальную длину пароля
	recoverSetPasswordFormFieldPasswordMax:
		'The password must contain no more than 21 characters',
	// Подпись поля повторного ввода пароля
	recoverSetPasswordFormFieldPasswordAgainLabel: 'Retry password',
	// Сообщение про обязательный повторный ввод пароля
	recoverSetPasswordFormFieldPasswordAgainRequired:
		'Write the password again',
	// Сообщение про несовпадение нового и старого паролей
	recoverSetPasswordFormFieldPasswordAgainMismatch:
		"The passwords don't match.",
	// Кнопка установки нового пароля
	recoverSetPasswordFormSetButton: 'Set',

	// СТРАНИЦА ЗАВЕРШЕНИЯ УСТАНОВКИ НОВОГО ПАРОЛЯ ВЗАМЕН ЗАБЫТОГО
	// Заголовок страницы
	finishRecoverPasswordPageHeader:
		'Congratulations! The new password has been set.',
	// Текст на ссылке входа
	finishRecoverPasswordLoginLink: 'Entrance',

	// СТРАНИЦА ВЫБОРА ПОЛЬЗОВАТЕЛЯ ДЛЯ РЕГИСТРАЦИИ
	// Заголовок страницы
	registerSwitcherPageHeader: 'Indicate your role',
	// Текст «Уже есть аккаунт?»
	registerSwitcherPageHasAccount: 'Already have an account?',
	// Текст на ссылке входа
	registerSwitcherPageLoginLink: 'Enter',
	// Заголовок на кнопке регистрации в качестве владельца школы
	registerSwitcherPageAdminCardHeader: "I'm the owner of the school",
	// Текст на кнопке регистрации в качестве владельца школы
	registerSwitcherPageAdminCardText: "I'm in charge of training",

	// СТРАНИЦА НАЧАЛА РЕГИСТРАЦИИ В КАЧЕСТВЕ АДМИНИСТРАТОРА
	// Заголовок страницы
	startAdminRegPageHeader: 'Registration',
	// Описание страницы
	startAdminRegPageDescription:
		'Get access to all features for 14 days immediately after registration',
	// Текст на ссылке входа
	startAdminRegPageLoginLink: 'Enter',
	// Текст после слова «Войти»: «, если есть учётная запись»
	startAdminRegPageIfHasAccount: ',if there is an account',

	// ФОРМА РЕГИСТРАЦИИ АДМИНИСТРАТОРА
	// Подпись поля ввода почты
	startAdminRegFormFieldEmailLabel: 'Mail',
	// Сообщение об обязательности ввода почты
	startAdminRegFormFieldEmailRequired: 'Enter your e-mail.',
	// Сообщение о неправильном формате почты
	startAdminRegFormFieldEmailWrongFormat: 'Enter your e-mail.',
	// Подпись поля ввода телефона
	startAdminRegFormFieldPhoneLabel: 'Phone number',
	// Сообщение об обязательности ввода почты
	startAdminRegFormFieldPhoneRequired: 'Write down the phone number',
	// Сообщение о неправильном формате почты
	startAdminRegFormFieldPhoneWrongFormat:
		'The phone is in the wrong format. Example:',
	// Подпись поля ввода пароля
	startAdminRegFormFieldPasswordLabel: 'Password',
	// Сообщение об обязательности ввода пароля
	startAdminRegFormFieldPasswordRequired: 'Make up a password ',
	// Сообщение про минимальную длину пароля
	startAdminRegFormFieldPasswordMin:
		'Password must contain at least 6 characters',
	// Сообщение про максимальную длину пароля
	startAdminRegFormFieldPasswordMax:
		'The password must contain no more than 21 characters',
	// Сообщение про обязательную установку приёма пользовательского соглашения
	startAdminRegFormFieldLegalCheckboxRequired:
		'You must agree to the terms and conditions before setting up a school',
	// Кнопка регистрации
	startAdminRegFormSubmitButton: 'Sign up',
	// Сообщение «Такая почта уже зарегистрирована» при попытке отправки формы
	startAdminRegFormEmailExistsAlready:
		'This e-mail has already been registered',

	// СТРАНИЦА СОЗДАНИЯ ШКОЛЫ
	// Заголовок страницы
	schoolCreationPageHeader:
		'Come up with a name for your school. You can change it later.',
	// Текст на ссылке входа
	schoolCreationPageLoginLink: 'Enter',

	// ФОРМА СОЗДАНИЯ ШКОЛЫ
	// Подпись поля названия школы
	schoolCreationFormFieldNameLabel:
		'Name of the school (can be changed later)',
	// Сообщение про обязательное заполнение поля с названием школы
	schoolCreationFormFieldNameRequired: 'Write the name',
	// Подпись поля домена школы
	schoolCreationFormFieldDomainLabel: "The school's domain",
	// Кнопка создания школы
	schoolCreationFormOkButton: 'Create a school',

	// СТРАНИЦА ПОДТВЕРЖДЕНИЯ ПОЧТЫ
	// Заголовок страницы
	confirmEmailPageHeader: 'Confirm e-mail',
	confirmEmailPageDescription:
		'A letter with a five-digit code was sent to the specified mailbox. Enter it in the field.',
	// Текст на ссылке входа
	confirmEmailPageLoginLink: 'Enter',
	// Текст после слова «Войти»: «, если есть учётная запись»
	confirmEmailPageIfHasAccount: "if there's an account",

	// ФОРМА ПОДТВЕРЖДЕНИЯ ПОЧТЫ
	// Сообщение про обязательное заполнение поля с кодом
	startAdminRegFormFieldCodeRequired: 'Enter the confirmation code sent',
	// Сообщение про длину кода
	startAdminRegFormFieldCodeLength: 'There must be five digits',
	// Кнопка отправки формы
	startAdminRegFormOkButton: 'Comfirm',
	// Предупреждение: Если не видите письмо с кодом в почте, проверьте папку «Спам».
	startAdminRegFormWarning:
		"If you don't see the code email in your mail, check your spam folder.",

	// СТРАНИЦА ПОДТВЕРЖДЕНИЯ ПОЧТЫ ПРИ ВОССТАНОВЛЕНИИ ПАРОЛЯ
	// Заголовок страницы
	recoveryPasswordCheckPageHeader: 'Confirm mail',
	// Описание страницы
	recoveryPasswordCheckPageDescription:
		'A letter with a five-digit code was sent to the specified mailbox. Enter it in the field.',
	// Текст на ссылке входа
	recoveryPasswordCheckPageLoginLink: 'Enter',
	// Текст после слова «Войти»: «, если есть учётная запись»
	recoveryPasswordCheckPageIfHasAccount: ",if there's an account",

	// ФОРМА ПОДТВЕРЖДЕНИЯ ПОЧТЫ ПРИ ВОССТАНОВЛЕНИИ ПАРОЛЯ
	// Сообщение про обязательное заполнение поля с названием школы
	recoveryPasswordCheckFormFieldCodeRequired:
		'Enter the confirmation code sent',
	// Кнопка отправки формы
	recoveryPasswordCheckFormOkButton: 'Confirm',

	// ФОРМА ЗАПРОСА НА УДАЛЕНИЕ АККАУНТА
	deleteAccReqFormFieldEnterButton: 'Send',
	deleteAccReqPageHeader: 'Account deleting',
	deleteAccReqPageDescription: 'Enter your email address',

	// ФОРМА ПОДТВЕРЖДЕНИЯ УДАЛЕНИЕ АККАУНТА
	deleteAccConfirmFormFieldEnterButton: 'Delete',
	deleteAccConfirmPageHeader: 'Attention',
	deleteAccConfirmPageDescription: 'All your data will be deleted.',
	deleteAccDonePageHeader: 'Your account has been deleted',
	deleteAccMailSentPageHeader: 'Account deleting',
	deleteAccMailSentPageDescription:
		'We will send you a link that will be valid for 24 hours.',
}

export default authEn
