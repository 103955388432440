import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import AuthPage from '../../../../../common/AuthPage/AuthPage'
import ContentWrapper from '../../../../../common/ContentWrapper/ContentWrapper'
import AppUrls from 'parts/constants/pageUrl'
import RegistrationForm from '../RegistrationForm/RegistrationForm'

// Страница с формой регистрации администратора
function RegisterOwnerStartArticle() {
	const { t } = useTranslation()

	return (
		<AuthPage>
			<ContentWrapper
				header={t('auth.startAdminRegPageHeader')}
				// headerDesc={t('auth.startAdminRegPageDescription')}
				bottomText={
					<>
						<Link to={AppUrls.Login().url} className="link">
							{t('auth.startAdminRegPageLoginLink')}
						</Link>
						{t('auth.startAdminRegPageIfHasAccount')}
					</>
				}
			>
				<RegistrationForm />
			</ContentWrapper>
		</AuthPage>
	)
}

export default RegisterOwnerStartArticle
