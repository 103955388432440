import useSystemStore from 'parts/systemStore/systemStore'
import { useEffect } from 'react'
import schoolRequests from 'parts/requests/school/schoolRequest'

/**
 * Возвращает статус пользователя в Хранилище:
 * loading — данные ещё не загружены
 * notExists — данные пользователя есть в Хранилище
 * exists — данных пользователя нет в Хранилище
 */
export function useGetUserStatusInStore() {
	const user = useSystemStore((store) => store.user)

	if (user === undefined) {
		return 'loading'
	}

	return user ? 'exists' : 'notExists'
}

/** Скачивает данные школы и ставит в Хранилище */
export function useSetSchool() {
	useEffect(function () {
		prepareSchool()
	}, [])
}

async function prepareSchool() {
	// Получает с сервера данные текущей школы и помещает в Хранилище
	const school = await getSchool()

	if (school) {
		const { updateStore } = useSystemStore.getState()
		updateStore({ school })
	}
}

/** Получает с сервера данные школы */
async function getSchool() {
	// Отправить запрос и получить ответ
	const response = await schoolRequests.getCurrentSchool()

	if (response.data) {
		return response.data
	}
}
