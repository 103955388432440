import MakeOrderModal from '../modal/MakeOrderModal/MakeOrderModal'
import Card from '../tariffCard/Card/Card'
import './TariffsPage.scss'

// Страница со списком тарифов для просмотра учеником для покупки
import useLandingBuilderStore from '../../zustand/store'

function TariffsPage() {
	const tariffs = useLandingBuilderStore((store) => store.tariffs)

	return (
		<div className="tariffs-page__wrapper">
			{tariffs.map((tariff) => {
				return <Card tariff={tariff} key={tariff.id} />
			})}
			<MakeOrderModal />
		</div>
	)
}

export default TariffsPage
