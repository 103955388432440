import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'

/**
 * Возвращает обработчик клонирования тезиса
 * @param tariffInnerId — внутренний идентификатор тарифа
 * @param thesisId — идентификатор тезиса
 */
export function useGetCloneThesis(tariffInnerId: number, thesisId: number) {
	return function () {
		tariffsManager.cloneThesisAndSetToStore(tariffInnerId, thesisId)

		// Сохранить на сервере
		tariffsManager.setNeedToSave()
	}
}

/**
 * Возвращает обработчик клонирования тезиса
 * @param tariffInnerId — внутренний идентификатор тарифа
 * @param thesisId — идентификатор тезиса
 */
export function useGetDeleteThesis(tariffInnerId: number, thesisId: number) {
	return function () {
		tariffsManager.deleteThesisAndSetToStore(tariffInnerId, thesisId)

		// Сохранить на сервере
		tariffsManager.setNeedToSave()
	}
}

/**
 * Возвращает обработчик кнопки устаноки или убирания перечеркивания тезиса
 * @param tariffInnerId — внутренний идентификатор тарифа
 * @param thesisId — идентификатор тезиса
 */
export function useGetSetCrossOutThesisStatus(
	tariffInnerId: number,
	thesisId: number
) {
	return function () {
		tariffsManager.toggleCrossOutThesisAndSetToStore(
			tariffInnerId,
			thesisId
		)

		// Сохранить на сервере
		tariffsManager.setNeedToSave()
	}
}
