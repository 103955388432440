import React from 'react'
import { Switch } from 'antd'
import {
	useGetOnSwitchChange,
	useGetIsTariffFlashed,
} from 'pages/landing/landingConstructor/tariffs/panel/FlashTariffSwitcher/fn/handler'
import 'pages/landing/landingConstructor/tariffs/panel/FlashTariffSwitcher/FlashTariffSwitcher.scss'

// Переключатель включения подсветки тарифа
function FlashTariffSwitcher() {
	const isChecked = useGetIsTariffFlashed()
	const onChange = useGetOnSwitchChange()

	return (
		<div className="tariff-panel-flash-switcher">
			<Switch
				defaultChecked={isChecked}
				onChange={onChange}
				size="small"
				title="Выделить карточку"
				checked={isChecked}
			/>
			<span>Выделить карточку</span>
		</div>
	)
}

export default FlashTariffSwitcher
