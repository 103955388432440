import { Button, Form, Input, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import {
	UFormNewBottomButtonsAfterGray,
	UFormNewButtonsContainer,
	UFormNewGeneralError,
	UFormNewRow,
} from 'ui/UForm/UForm'
import ModalWithForm from 'ui/ModalWithForm/ModalWithForm'
import useTransferStudentsModalStore from './zustand/store'
import { useGetSetVisibilityToTransferStudentsModal } from './fn/openCloseModal'
import { useGetOnSubmit } from './fn/submit'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'
import {
	FieldNames,
	setErrorsToForm,
	useGetOnFromCuratorSelectChange,
} from './fn/form'
import { useFillInStore } from './fn/fillInStore'

// Модальное окно передачи учеников от одного куратора другому
function TransferStudentsModal() {
	const { t } = useTranslation()
	useFillInStore()

	const [form] = Form.useForm()

	const modalState = useTransferStudentsModalStore((state) => state)

	const closeModal = useGetSetVisibilityToTransferStudentsModal(false)
	const onSubmit = useGetOnSubmit()
	setErrorsToForm(form)

	return (
		<ModalWithForm
			title="Передача учеников"
			open={modalState.isOpen}
			onCancel={closeModal}
		>
			<Form
				form={form}
				name="transferStudents"
				onFinish={onSubmit}
				layout="vertical"
			>
				<GrayBlock>
					<FromCuratorInput />
					<ToCuratorInput />
					<StudentsCountInput />
				</GrayBlock>
				<Bottom />
				<UFormNewGeneralError message={modalState.formErrors.message} />
			</Form>
		</ModalWithForm>
	)
}

export default TransferStudentsModal

// От какого куратора передать учеников
function FromCuratorInput() {
	const { t } = useTranslation()
	const fromCuratorOptions = useTransferStudentsModalStore(
		(store) => store.fromCuratorOptions
	)

	const selectChange = useGetOnFromCuratorSelectChange()

	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.FromCuratorId}
				label="Передать учеников от"
				rules={[
					{
						required: true,
						message: 'Выберите от какого куратора убрать учеников',
					},
				]}
			>
				<Select options={fromCuratorOptions} onChange={selectChange} />
			</Form.Item>
		</UFormNewRow>
	)
}

// Какому куратору передать учеников
function ToCuratorInput() {
	const { t } = useTranslation()
	const toCuratorOptions = useTransferStudentsModalStore(
		(store) => store.toCuratorOptions
	)

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.ToCuratorId}
				label="Получит учеников"
				rules={[
					{
						required: true,
						message: 'Выберите от какого куратора убрать учеников',
					},
				]}
			>
				<Select options={toCuratorOptions} />
			</Form.Item>
		</UFormNewRow>
	)
}

// Сколько передать учеников
function StudentsCountInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.StudentsCount}
				label="Количество учеников"
				rules={[
					{
						required: true,
						message: 'Укажите сколько учеников передать',
					},
				]}
			>
				<Input type="number" />
			</Form.Item>
		</UFormNewRow>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom() {
	const { t } = useTranslation()
	const form = Form.useFormInstance()

	const modalState = useTransferStudentsModalStore((state) => state)
	const closeModal = useGetSetVisibilityToTransferStudentsModal(false)

	return (
		<UFormNewBottomButtonsAfterGray>
			<div />
			<UFormNewButtonsContainer>
				<Button
					disabled={
						modalState.isSubmitting ||
						form
							.getFieldsError()
							.filter(({ errors }) => errors.length).length > 0
					}
					onClick={closeModal}
				>
					Отмена
				</Button>

				<Form.Item shouldUpdate>
					{() => {
						const formErrors = form
							.getFieldsError()
							.filter(({ errors }) => errors.length)

						return (
							<Button
								type="primary"
								htmlType="submit"
								disabled={
									modalState.isSubmitting ||
									formErrors.length > 0
								}
								loading={modalState.isSubmitting}
							>
								Передать
							</Button>
						)
					}}
				</Form.Item>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
