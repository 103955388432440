import { message } from 'antd'
import { AxiosError } from 'axios'
import { QueryClient, useQueryClient } from 'react-query'
import ServerTypes from 'parts/types/ServerTypes'
import {
	AddUserToGroupsData,
	groupQuery,
} from 'parts/requests/group/groupQuery'
import useAssignEmployeeToGroupModalStore from '../zustand/store'
import { AssignEmployeeToGroupModalStoreType } from '../zustand/storeTypes'
import { FieldNames, FormValuesType } from './form'
import EntityTypes from 'parts/types/EntityTypes'
import GroupsService from 'parts/services/GroupsService'
import useEmployeeStore from '../../../main/zustand/store'

/** Возвращает обработчик отправки формы добавления сотрудника в группу */
export function useGetOnSubmit() {
	const queryClient = useQueryClient()

	// Сформировать объект с идентификаторами всех курсов и их групп
	const allTrainingsGroupsIds = GroupsService.useGetAllTrainingsGroupsIds()

	const employee = useEmployeeStore((store) => store.employee)!

	const updateModalState = useAssignEmployeeToGroupModalStore(
		(state) => state.updateStore
	)

	// Объект с методом mutate для создания запроса
	const assignEmployeeToGroupMutation =
		groupQuery.addUserToGroups.useMutation({
			onMutate: getMutateOnBefore(updateModalState),
			onError: getMutateOnError(updateModalState),
			onSuccess: getMutateOnSuccess(updateModalState, queryClient),
			onSettled: getMutateOnAfter(updateModalState),
		})

	return function (fieldsValue: FormValuesType) {
		// Приготовить данные, которые передадутся в функцию-обработчик запроса
		const reqData = prepareReqData(
			allTrainingsGroupsIds,
			fieldsValue,
			employee
		)
		assignEmployeeToGroupMutation.mutate(reqData)
	}
}

/**
 * Готовит и возвращает данные для создания запроса на добавление сотрудника в группы
 * @param allTrainingsGroupsIds — объект с идентификатором курса и значением в виде массива идентификаторов групп
 * @param fieldsValue — значения полей формы
 * @param employee — объект сотрудника
 */
function prepareReqData(
	allTrainingsGroupsIds: { [p: number]: number[] },
	fieldsValue: FormValuesType,
	employee: EntityTypes.User
): AddUserToGroupsData {
	// Получить в groupIds массив идентификаторов выделенных групп
	const selectedGroupsId = GroupsService.getSelectedGroupsFromTrainings(
		allTrainingsGroupsIds,
		fieldsValue[FieldNames.Groups]
	)

	return {
		userId: employee.id,
		groupIds: selectedGroupsId,
		role: employee.role,
	}
}

/**
 * Функция, выполняемая до запроса на добавление сотрудника в группы
 * @param updateModalState — функция изменяющая объект состояния модального окна
 */
function getMutateOnBefore(
	updateModalState: AssignEmployeeToGroupModalStoreType.UpdateStore
) {
	return function () {
		// Поставить статус загрузки
		updateModalState({ isSubmitting: true })
	}
}

/**
 * Функция, выполняемая при появлении ошибки после запроса на добавление сотрудника в группы
 * @param updateModalState — функция изменяющая объект состояния модального окна
 */
function getMutateOnError(
	updateModalState: AssignEmployeeToGroupModalStoreType.UpdateStore
) {
	return function (err: unknown) {
		const error = err as AxiosError<ServerTypes.ErrorResponse>
		if (error.response) {
			// Поставить данные ошибки в Состояние, чтобы показать их в форме
			updateModalState({ formErrors: error.response.data })
		}

		message.error(
			'Одного или нескольких сотрудников не удалось добавить к этой группе.'
		)
	}
}

/**
 * Функция, выполняемая при успешном запросе на добавление сотрудника в группы
 * @param updateModalState — функция изменяющая объект состояния модального окна
 * @param queryClient — клиент React Query
 */
function getMutateOnSuccess(
	updateModalState: AssignEmployeeToGroupModalStoreType.UpdateStore,
	queryClient: QueryClient
) {
	return function () {
		// Закрыть модальное
		updateModalState({ isOpen: false })
		message.success('Группы добавлены.')

		// Пометить список групп, в которых добавлен пользователь, неактуальными.
		// После этого React Query скачает новый список
		// TODO Требуется поставить идентификатор группы, в которую поместили пользователя
		/*queryClient.refetchQueries({
			queryKey: [groupQuery.getUserGroups().key],
		})*/
	}
}

/**
 * Функция, выполняемая после запроса на добавление сотрудника в группы
 * @param updateModalState — функция изменяющая объект состояния модального окна
 */
function getMutateOnAfter(
	updateModalState: AssignEmployeeToGroupModalStoreType.UpdateStore
) {
	return function () {
		// Убрать статус загрузки
		updateModalState({ isSubmitting: false })
	}
}
