import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Alert, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import StaffTable from '../staffTable/StaffTable/StaffTable'
import { EmptyBlock } from 'ui/EmptyBlock/EmptyBlock'
import AppUrls from 'parts/constants/pageUrl'
import { StaffTableAdminController } from '../staffTable/StaffTable/StaffTableAdminController'
import { AddStaffInGroupModalController } from '../AddStaffInGroupModal/AddStaffInGroupModalController'
import { TransferStudentsModalController } from '../TransferStudentsModal/TransferStudentsModalController'

// Статья с таблицами кураторов и менеджеров группы
function GroupStaffArticle() {
	const groupStaffTableRouter = StaffTableAdminController.router()

	return (
		<ArticleContentWrapper
			innerWrapperWidth="full"
			center={groupStaffTableRouter.status !== 'success'}
		>
			{groupStaffTableRouter.status == 'loading' &&
				groupStaffTableRouter.element}
			{groupStaffTableRouter.status == 'noStaffInSchool' && (
				<NoStuffInSchool />
			)}
			{groupStaffTableRouter.status == 'noStaffInGroup' && (
				<NoStuffInGroup />
			)}
			{groupStaffTableRouter.status == 'success' && <StaffTable />}
			{AddStaffInGroupModalController.element}
			{TransferStudentsModalController.element}
		</ArticleContentWrapper>
	)
}

export default GroupStaffArticle

// Содержимое статьи если в школе нет сотрудников
function NoStuffInSchool() {
	const { t } = useTranslation()

	return (
		<EmptyBlock
			text={
				<>
					В школе ещё нет сотрудников для добавления в группу.
					<br />
					Перейдите в{' '}
					<Link to={AppUrls.Staff().url} className="link">
						раздел «Сотрудники»
					</Link>{' '}
					для создания.
				</>
			}
			bottom={
				<Alert
					message="Курс можно запустить без создания сотрудников. Вести группу, проверять сообщения и домашние работы будет администратор."
					type="info"
				/>
			}
		/>
	)
}

// Содержимое статьи если в группу не добавлены сотрудники
function NoStuffInGroup() {
	const { t } = useTranslation()

	return (
		<EmptyBlock
			text="В вашей группе нет кураторов или менеджеров"
			button={
				<Button
					type="primary"
					icon={<PlusOutlined />}
					onClick={AddStaffInGroupModalController.open}
				>
					Добавить в группу
				</Button>
			}
		/>
	)
}
