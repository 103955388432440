import { Button, Form, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { FieldsNames, useGetOnGroupNameChange } from './fn/onPhoneChange'

// Форма поиска по почте
function GroupNameForm() {
	const [form] = Form.useForm()
	const onSubmit = useGetOnGroupNameChange()

	return (
		<Form form={form} onFinish={onSubmit} className="leads-table__form">
			<Form.Item name={FieldsNames.GroupName}>
				<Input />
			</Form.Item>
			<Button htmlType="submit" icon={<SearchOutlined />} />
		</Form>
	)
}

export default GroupNameForm
