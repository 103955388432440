import { QueryClient, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { FormInstance, message } from 'antd'
import ServerTypes from 'parts/types/ServerTypes'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import { AddGroupModalController } from '../../AddGroupModalController'
import { FieldNames, FormValues } from './form'
import useAddGroupModalStore from '../../zustand/store'
import { groupQuery } from 'parts/requests/group/groupQuery'
import GroupsApiTypes from 'parts/requests/group/groupsApiTypes'
import EntityTypes from 'parts/types/EntityTypes'
import { AddGroupModalStoreType } from '../../zustand/storeTypes'

/**
 * Хук возвращающий обработчик отправки формы создания новой группы
 * @param trainingId — id курса, к которому будет принадлежать созданная группа
 * @param isActive — должна ли группа быть активной при создании?
 * @param form — ссылка на форму
 * @param refreshTrainingAfterCreation — требуется ли дать сигнал Реакт Квери перекачать данные курса?
 */
export function useGetOnSubmit(
	trainingId: number,
	isActive: boolean,
	form: FormInstance,
	refreshTrainingAfterCreation: boolean
) {
	const queryClient = useQueryClient()
	const updateStore = useAddGroupModalStore((state) => state.updateStore)
	const updateGroupsList = AddGroupModalController.useGetUpdate(trainingId)

	// Объект с методом mutate для создания запроса
	const addGroupMutation = groupQuery.createOrUpdateGroup.useMutation({
		onMutate: getMutateOnBefore(updateStore),
		onError: getMutateOnError(updateStore),
		onSuccess: getMutateOnSuccess(
			updateStore,
			queryClient,
			form,
			trainingId,
			updateGroupsList,
			refreshTrainingAfterCreation
		),
		onSettled: getMutateOnAfter(updateStore),
	})

	return async (values: FormValues) => {
		addGroupMutation.mutate(createGroupDto(trainingId, values, isActive))
	}
}

/**
 * Функция, выполняемая до запроса на создание группы
 * @param updateStore — функция изменяющая объект состояния модального окна
 */
function getMutateOnBefore(updateStore: AddGroupModalStoreType.UpdateStore) {
	return function () {
		// Поставить статус загрузки
		updateStore({ isSubmitting: true })
	}
}

/**
 * Функция, выполняемая при появлении ошибки после запроса на создание группы
 * @param updateStore — функция изменяющая объект состояния модального окна
 */
function getMutateOnError(updateStore: AddGroupModalStoreType.UpdateStore) {
	return function (err: unknown) {
		const error = err as AxiosError<ServerTypes.ErrorResponse>

		if (error.response) {
			// Поставить данные ошибки в Состояние, чтобы показать их в форме
			updateStore({ formErrors: error.response.data })
		}

		message.error('Не удалось создать группу.')
	}
}

/**
 * Функция, выполняемая при успешном запросе на добавление сотрудника в группы
 * @param updateStore — функция, изменяющая объект состояния модального окна
 * @param queryClient — клиент React Query
 * @param form — форма
 * @param trainingId — id курса
 * @param updateGroupsList — функция, помечающая список групп неактуальным. После React Query скачает новые данные
 * @param refreshTrainingAfterCreation — требуется ли дать сигнал Реакт Квери перекачать данные курса?
 */
function getMutateOnSuccess(
	updateStore: AddGroupModalStoreType.UpdateStore,
	queryClient: QueryClient,
	form: FormInstance,
	trainingId: number,
	updateGroupsList: () => void,
	refreshTrainingAfterCreation: boolean
) {
	return function () {
		// Закрыть модальное
		updateStore({ isModalOpen: false })
		message.success('Создана новая группа.')

		// Пометить список групп, в которых добавлен пользователь, неактуальными.
		// После этого React Query скачает новый список
		updateGroupsList()

		form.resetFields()

		if (refreshTrainingAfterCreation) {
			// Пометить данные курса неактуальными. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: trainingQuery.getTrainingForAdmin(trainingId).key,
			})
		}
	}
}

/**
 * Функция, выполняемая после запроса на добавление сотрудника в группы
 * @param updateStore — функция изменяющая объект состояния модального окна
 */
function getMutateOnAfter(updateStore: AddGroupModalStoreType.UpdateStore) {
	return function () {
		// Убрать статус загрузки
		updateStore({ isSubmitting: false })
	}
}

/**
 * Формирует DTO для отправки запроса
 * @param trainingId — id курса, к которому будет принадлежать созданная группа
 * @param {Array} values — массив значений полей формы
 * @param isActive
 */
function createGroupDto(
	trainingId: number,
	values: FormValues,
	isActive: boolean
): GroupsApiTypes.CreateOrUpdateGroupDto {
	return {
		trainingId,
		name: values[FieldNames.GroupName],
		type: values[FieldNames.Type],
		numOfParticipants: values[FieldNames.Participants]
			? parseInt(values[FieldNames.Participants])
			: 0,

		// Значения по умолчанию для создаваемой группы
		// Дата начала считается дата открытия первого урока.
		startCondition: EntityTypes.GroupStartCondition.OpenFirstLesson,
		// Уроки открыты сразу.
		mode: EntityTypes.GroupMode.Open,
		// Длительность обучения 12 месяцев.
		duration: 365,
		// Доступ на курс 12 месяцев.
		accessToCourse: 365,
		isActive,
	}
}
