import { userQuery } from 'parts/requests/user/userQuery'
import { Loader } from 'ui'
import { Select } from 'antd'
const { Option } = Select
import useAddLiveModalStore from '../zustand/store'

export function getLivelessonsCurators() {
	const { data, isLoading } = userQuery.getCurators().useQuery()

	const [adminId, adminName] = useAddLiveModalStore((state) => [
		state.schoolAdminId,
		state.schoolAdminName,
	])

	const admin = {
		id: adminId.toString(),
		name: adminName,
	}

	return (
		<Select
			showSearch
			placeholder="Добавьте учителя"
			optionFilterProp="children"
			notFoundContent={isLoading ? <Loader /> : null}
		>
			<Option value={admin.id}>{admin.name}</Option>
			{data?.data.map((curator) => {
				return (
					<Option value={curator.id.toString()} key={curator.id}>
						{`${curator.firstName || ''} ${curator.lastName || ''}`}
					</Option>
				)
			})}
		</Select>
	)
}

export function getLivelessonsStudents() {
	const { data, isLoading } = userQuery.getStudentsByAdmin.useQuery()

	return (
		<Select
			mode="multiple"
			style={{ width: '100%' }}
			placeholder="Добавьте учеников"
			optionLabelProp="label"
			maxTagCount="responsive"
			notFoundContent={isLoading ? <Loader /> : <p>Нет учеников</p>}
		>
			{data?.data.map((student) => {
				return (
					<Option
						key={student.id}
						value={student.id.toString()}
						label={`${student.firstName || ''} ${
							student.lastName || ''
						}`}
					>
						{`${student.firstName || ''} ${student.lastName || ''}`}
					</Option>
				)
			})}
		</Select>
	)
}
