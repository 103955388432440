import { useEffect, useMemo, useState } from 'react'
import { message } from 'antd'
import { AxiosError } from 'axios'
import trainingRequests from '../requests/training/trainingRequest'
import TrainingEntityTypes from '../types/TrainingEntityTypes'
import { CascaderOption } from '../types/miscTypes'
import ServerTypes from '../types/ServerTypes'
import { trainingQuery } from '../requests/training/trainingQuery'

// Методы для работы с курсами
const TrainingAdminService = {
	/**
	 * Находит в массиве курсов курс с переданным id
	 * @param trainings — массив курсов
	 * @param {Number} trainingId — id курса
	 */
	findTrainingById(
		trainings: TrainingEntityTypes.TrainingAdmin[],
		trainingId: number
	) {
		return trainings.find((training) => training.id == trainingId)
	},

	useFetchTrainingsAndGroupsAndConvertToCascaderOption() {
		const { data: trainingsRes } =
			trainingQuery.getTrainingsForAdmin.useQuery()

		return useMemo(() => {
			if (!trainingsRes) return null

			return this.convertTrainingsAndGroupsToCascaderOption(
				trainingsRes.data
			)
		}, [trainingsRes])
	},

	// ВМЕСТО ЭТОГО ИСПОЛЬЗОВАТЬ useFetchTrainingsAndGroupsAndConvertToCascaderOption!!!
	// Загружает массив курсов и их групп и формирует из него массив пунктов для каскадера в форме
	async fetchTrainingsAndGroupsAndConvertToCascaderOption_OLD_VERSION() {
		try {
			const response = await trainingRequests.getTrainingsForAdmin()

			if (response.status !== 200) {
				throw new Error('Не удалось получить список групп')
			}

			return this.convertTrainingsAndGroupsToCascaderOption(response.data)
		} catch (err) {
			const error = err as AxiosError<ServerTypes.ErrorResponse>

			if (error.response) {
				message.error(error.response.data.message)
			}
		}
	},

	/**
	 * Получает с сервера список курсов и возвращает сформированный массив
	 * с данными для отрисовки выпадающего списка курсов и групп в форме добавления новой оплаты.
	 */
	useGetTrainingsListAsCascaderOption() {
		const [trainingOptions, setTrainingOptions] = useState<
			CascaderOption[]
		>([])

		useEffect(function () {
			TrainingAdminService.fetchTrainingsAndGroupsAndConvertToCascaderOption_OLD_VERSION().then(
				(cascaderOptions) => {
					if (!cascaderOptions) return

					setTrainingOptions(cascaderOptions)
				}
			)
		}, [])

		return trainingOptions
	},

	// Получает массив курсов и формирует из них массив пунктов для каскадера в форме
	convertTrainingsAndGroupsToCascaderOption(
		trainings: TrainingEntityTypes.TrainingAdmin[]
	) {
		const trainingOptions: CascaderOption[] = []

		trainings.forEach((training) => {
			if (!training.groups.length) {
				return
			}

			trainingOptions.push({
				value: training.id.toString(),
				label: training.name,
				children: training.groups.map((group) => {
					return {
						value: group.id.toString(),
						label: group.name,
					}
				}),
			})
		})

		return trainingOptions
	},

	/**
	 * Получает с сервера список курсов и возвращает массив с идентификаторами курсов и их групп
	 * для формирования выпадающего списка курсов и групп в форме.
	 */
	useGetTrainingsGroupOptions() {
		const { data: trainingsRes } =
			trainingQuery.getTrainingsForAdmin.useQuery()

		const [trainingOptions, setTrainingOptions] = useState<
			CascaderOption[]
		>([])

		useEffect(
			function () {
				if (!trainingsRes) return

				const cascaderOptions =
					TrainingAdminService.convertTrainingsAndGroupsToCascaderOption(
						trainingsRes.data
					)
				if (!cascaderOptions) return

				setTrainingOptions(cascaderOptions)
			},
			[trainingsRes]
		)

		return trainingOptions
	},
}

export default TrainingAdminService
