import { create } from 'zustand'
import { MySchoolsListStoreType } from './storeTypes'

const useMakeOrderStore = create<MySchoolsListStoreType.State>((set) => {
	return {
		schools: [],

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useMakeOrderStore
