import { List } from '../List/List'
import styles from './messages.module.scss'

const Messages = () => {
	return (
		<div className={styles.Wrap}>
			<List />
		</div>
	)
}

export default Messages
