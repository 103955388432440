const courseTeachEn = {
	// КУРС Как проводить live-уроки на Credu
	// Заголовок курса
	mainTitle: 'How to conduct live lessons on Credu',
	// Подзаголовок курса
	mainSubtitle:
		'The Credu platform was designed specifically for online lessons. We have created 12 tips for you that will answer most of your questions. Start getting familiar with the platform now!',
	// УРОКИ КУРСА Как проводить live-уроки на Credu
	lesson1Title: 'How to add a student to a course without registration',
	lesson1Subtitle:
		'You will learn how easy it is to invite a student to a lesson',
	lesson1Block1:
		'We understand how important it is for a lesson to go smoothly, especially if it is the first lesson with a student. So, a student can get to your lesson in two ways:',
	lesson1Block2:
		"The easiest. You add a student to the platform in advance, for this you only need the student's mail. The platform will automatically create a personal account for the student, send access to the personal account to the student's mail. So he does not have to register, he just enters the login and password from the email and goes to the personal cabinet.",
	lesson1Block3:
		'Another way. You send a link to the lesson, the student enters his/her email, if he/she has never been registered before, he/she will go through a simple registration and will go straight to your lesson.',
	lesson1Block4:
		'Important! If a student has logged into the platform once, the next time the platform will recognize him/her immediately and he/she will get to your lesson without entering login and password.',

	lesson2Title: 'What happens when you send a student a link to a lesson',
	lesson2Subtitle: "You'll see the path a student takes to get to class.",
	lesson2Block1:
		'In this lesson we will show how a student will get to the lesson if you send them a link to the lesson.',
	lesson2Block2:
		'Important: By convention, the student was not registered on the Credu platform.',

	lesson3Title: 'What is a room and how to use it',
	lesson3Subtitle:
		'Learn how to create, edit, delete rooms. Learn how to assign a teacher to a lesson if you have an online school',
	lesson3Block1:
		'Room is an online classroom that has all the tools you need to conduct an interactive lesson.Room is permanently linked, meaning you can use the same room to conduct online lessons with your student.',
	lesson3Block2:
		'Important!. Everything you go through in the lessons will be saved and you can always start exactly where you left off.',
	lesson3Block3:
		'When you invite a student to a lesson, a card with the lesson is created in his/her personal cabinet. The student can access the lesson using the link or in his/her personal cabinet by clicking on the card.',

	lesson4Title: "What does a student's office look like?",
	lesson4Subtitle:
		'You will learn in detail what functions are available in the student office',
	lesson4Block1:
		"A student's office is different from a teacher's office. It was important for us to make it simple and intuitive.",
	lesson4Block2:
		"In this lesson we will show you what a student's office looks like, so that you have a clear idea of what happens when your student goes to a private office.",

	lesson5Title: 'Video conference mode',
	lesson5Subtitle:
		"Let's go through all the functions and what to do if something goes wrong",
	lesson5Block1:
		'The lesson can be done with or without video, i.e. video from your webcam will be played. In order for the lesson to be with video, you need to give permission to play video and audio when the browser asks for it. Otherwise there will be no video and audio in the lesson because you have forbidden to use it.',
	lesson5Block2:
		'How to enable audio and video in different browsers we will show in this tutorial, This is important to know because a student can without reading the post simply disallow automatically video from their device.',
	lesson5Block3:
		'We will also show you how to switch from singleview to multiview mode, how to control video and audio, how to mute audio and video for a student, and other videoconferencing features.',

	lesson6Title: 'Features of the interactive whiteboard',
	lesson6Subtitle:
		'We will show you all the functions and how to use the board in different disciplines',
	lesson6Block1:
		"The interactive whiteboard performs the same functions as a regular whiteboard at school, but with much more functionality. The collaborative whiteboard has a lot of functionality that will allow you to diversify your lessons by sharing the whiteboard in your classroom and also supports inserting images. In this lesson, we'll go through the features of the whiteboard in detail, break down the non-obvious features, and show you how you can use them in your lessons",

	lesson7Title: 'File storage',
	lesson7Subtitle:
		'Your personal cloud of files, available always and on any device',
	lesson7Block1:
		"File storage is your school's personal cloud, it is available to the manager, supervisor and you. This is where you can store all your handouts and use them in class in just 2 clicks. You can add audio, video and PDF files to your classroom, but you can upload any file of any size to the vault to share with your students.",
	lesson7Block2:
		'In this tutorial we will cover how to use the repository, what files we support, how to add files to a class, how to play video, audio, how to share a file in chat and get a link to the file how to distribute to folders and much more.',

	lesson8Title: 'Share screen',
	lesson8Subtitle:
		'How to share the screen, as well as how to select the right item to showcase',
	lesson8Block1:
		"The best way to tell about something is to show it visually. To do this, we've added a screen sharing feature. You can share an individual browser tab, show the whole screen or share some application.",
	lesson8Block2:
		'Important! Sharing the screen will not work on the mobile version of the site',
	lesson8Block3:
		'In this lesson we will show how the demo works in all browsers, what nuances you should pay attention to and how it looks on a mobile device.',

	lesson9Title: 'Listening to audio together in class',
	lesson9Subtitle:
		'How to play audio, how to add audio to a playlist, who can control the player',
	lesson9Block1:
		'We have already described in the lesson about file storage how to add audio to a class. In this lesson we will take a closer look at this functionality, which we think is quite important, especially in teaching foreign languages.',
	lesson9Block2:
		'So, in this lesson we will show how to add audio to the class, how playlists work, who can manage the audio, how it will look on the student side, How to remove audio from the lesson and share the files with the student.',

	lesson10Title: 'Watching videos together in class',
	lesson10Subtitle:
		'How to play videos, how to add videos from YouTube, what formats the platform supports',
	lesson10Block1:
		"Quite often you need to show video materials in a lesson, for this there are 2 options, to upload a video via a link from Youtube or directly from the storage. Of course, the easiest and most convenient way is to upload from Youtube. However, there are cases when you don't have the necessary materials there, then you can upload them from your personal storage.",
	lesson10Block2:
		'Important! The speed of downloading video materials depends on their size and your internet connection. If you have a large size of video materials it will be downloaded based on your internet speed.',

	lesson11Title: 'Frequent questions',
	lesson11Subtitle:
		'You will find out: whether boards are saved, whether the room is permanent, how many boards can be created, whether there are time limits, whether the student pays for the platform, etc.',
	lesson11Block1:
		'Are materials that have been uploaded to the board saved in the room?',
	lesson11Block2:
		'Yes, everything you draw on the board, all images and text are saved as you left them after the lesson, which is very convenient, because you can always start exactly where you finished the last lesson.',
	lesson11Block3: 'How many whiteboards can you create in a lesson?',
	lesson11Block4:
		"You can create a maximum of 100 boards in each room.You can delete old boards that you don't use and create new ones.",
	lesson11Block5: 'Can a student get to class, after hours?',
	lesson11Block6:
		'Yes, from their classroom, a student can log in to the lesson, see the material covered, and even solve homework assignments, which you can then review.',
	lesson11Block7: 'Why do we need mentors and managers?',
	lesson11Block8: "Let's go in order.",
	lesson11Block9:
		'Mentors are needed in two cases: 1.If you have a large school and you hire other teachers to teach lessons to your students, then when you create the room you assign that mentor/teacher to teach the lesson. 2. If you are creating courses and it is implied that other people will be testing the courses, then those people are the tutors.',
	lesson11Block10:
		"Managers. There may be multiple managers in the school, they can do all the same things you can do, but they can't delete. This is to keep all your content safe. Otherwise, the manager is your right-hand man who takes care of all the chores.",
	lesson11Block11: 'What are leads/purchases?',
	lesson11Block12:
		'If you are selling courses (running asynchronous learning), all paid and unpaid orders will be displayed there.',
	lesson11Block13: 'What is an answer feed?',
	lesson11Block14:
		'In those exercises where you are required to give a voice or written response get here for checking, so you can give feedback.',
}

export default courseTeachEn
