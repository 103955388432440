import useExerciseStore from '../../zustand/exerciseState'

/**
 * Убирает изменения, сделанные пользователем
 */
export function useGetOneMoreTime() {
	const saveStoreForRestore = useExerciseStore(
		(store) => store.saveStoreForRestore
	)
	const questions = useExerciseStore((store) => store.questions)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		const updatedQuestions = questions.map((question) => {
			const newAnswers = question.answers.map((answer) => {
				return { ...answer, checked: false }
			})
			return { ...question, showCorrect: false, answers: newAnswers }
		})

		updateStore({
			stage: 'NotReadyToCheck',
			currentQuestionIdx: 0,
			questions: updatedQuestions,
			saveStoreForRestore: saveStoreForRestore + 1,
		})
	}
}
