import { message } from 'antd'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import { QueryClient, useQueryClient } from 'react-query'
// import useLessonAdminStore from '../../../zustand/store'
import useDeleteModalStore from '../../../../pages/modal/DeleteModal/store/store'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import ExerciseService from 'parts/services/ExerciseService'
import trainingRequests from 'parts/requests/training/trainingRequest'
import UrlService from 'parts/services/UrlService'

export function useGetOpenDeleteImageModalFn(
	imageUrl: string,
	exerciseId: number
) {
	const lessonId = UrlService.useGetLessonId()

	// const trainingAdmin = useLessonAdminStore((state) => state.trainingAdmin)
	const updateModalStore = useDeleteModalStore((state) => state.updateStore)
	const queryClient = useQueryClient()

	return function () {
		/*updateModalStore({
			isOpen: true,
			header: 'Вы действительно хотите удалить изображение?',
			text: 'Это действие нельзя отменить.',
			onOk: () =>
				deleteImage(
					imageUrl,
					exerciseId,
					trainingAdmin,
					lessonId,
					queryClient
				),
		})*/
	}
}

/** Обработчик удаления картинки */
async function deleteImage(
	imageUrl: string,
	exerciseId: number,
	trainingAdmin: TrainingEntityTypes.TrainingAdmin,
	lessonId: number,
	queryClient: QueryClient
) {
	const updatedTraining = ExerciseService.deleteImageFromImageExercise(
		trainingAdmin,
		lessonId,
		exerciseId,
		imageUrl
	)

	try {
		const response = await trainingRequests.createTraining(updatedTraining)

		// Пометить данные курса неактуальными. После этого React Query скачает обновлённые данные.
		queryClient.refetchQueries({
			queryKey: [trainingQuery.getTrainingForAdmin(trainingAdmin.id).key],
		})
	} catch (error) {
		message.error('Изображение не было удалено')
	}
}
