import React from 'react'
import AnswersFeed from '../../AnswersFeed/AnswersFeed'
import AnswerFeedNavigationMenu from '../../menu/AnswerFeedNavigationMenu/AnswerFeedNavigationMenu'
import AnswerFeedNavigationPanel from '../../menu/AnswerFeedNavigationPanel/AnswerFeedNavigationPanel'
import useAnswersFeedStore from '../../zustand/store'
import styles from './LayoutForAdmin.module.scss'

// Контентная часть ленты ответов для администратора
function LayoutForAdmin() {
	const answersFeed = useAnswersFeedStore((store) => store.answersFeed)

	return (
		<div className={styles.AdminAnswerFeed}>
			<AnswerFeedNavigationMenu />
			<div className={styles.FeedLayout}>
				<AnswerFeedNavigationPanel />
				<AnswersFeed answers={answersFeed} />
			</div>
		</div>
	)
}

export default LayoutForAdmin
