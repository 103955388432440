import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import answerFeedReducer from './reducers/answerFeed/answerFeedSlice'

const RootReducer = combineReducers({
	answerFeed: answerFeedReducer,
})

const store = configureStore({
	reducer: RootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				// Ignore these action types
				ignoredActions: ['modal/deleteUserModal', 'modal/deleteModal'],
				// Ignore these field paths in all actions
				ignoredActionPaths: [''],
				// Ignore these paths in the state
				ignoredPaths: ['modal.deleteUserModal', 'modal.deleteModal'],
			},
		}),
	devTools: true,
})

export type AppStateType = ReturnType<typeof RootReducer>
export const useAppSelector: TypedUseSelectorHook<AppStateType> = useSelector

export default store
