import { produce } from 'immer'
import { stateManager } from '../../stateManager/stateManager'
import useExerciseStore from '../../zustand/exerciseState'

/**
 * Обработчик щелчка на кнопку «Ложь» или «Правда»
 * @param {String} buttonType  — тип кнопки false (кнопка «Ложь»), true (кнопка «Правда»)
 */
export function useGetOnTrueFalseButtonClick(buttonType: 'false' | 'true') {
	const saveStoreForRestore = useExerciseStore(
		(store) => store.saveStoreForRestore
	)
	const questions = useExerciseStore((store) => store.statements)
	const currentStatementIdx = useExerciseStore(
		(store) => store.currentStatementIdx
	)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		const newStatements = produce(questions, (draft) => {
			const statement = stateManager.getStatement(
				draft,
				currentStatementIdx
			)

			if (!statement) {
				return draft
			}

			statement.userAnswer = buttonType == 'false' ? 'not' : 'yes'
		})

		updateStore({
			statements: newStatements,
			saveStoreForRestore: saveStoreForRestore + 1,
		})
	}
}
