import { message } from 'antd'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useTranslation } from 'react-i18next'
import useDeleteModalStore from '../../../../pages/modal/DeleteModal/store/store'
import ExerciseService from 'parts/services/ExerciseService'
// import useLessonAdminStore from '../../../zustand/store'
import trainingRequests from 'parts/requests/training/trainingRequest'
import UrlService from 'parts/services/UrlService'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import { QueryClient, useQueryClient } from 'react-query'

/**
 * Хук возвращает обработчик нажатия на кнопку удаления изображения.
 * @param {String} imageUrl — адрес изображения
 * @param {Number} exerciseId — id упражнения в котором требуется удалить изображение
 */
export function useGetOpenDeleteModalFn(imageUrl: string, exerciseId: number) {
	const { t } = useTranslation()
	const lessonId = UrlService.useGetLessonId()

	// const trainingAdmin = useLessonAdminStore((state) => state.trainingAdmin)!

	const queryClient = useQueryClient()
	const updateStore = useDeleteModalStore((state) => state.updateStore)

	return function () {
		updateStore({
			isOpen: true,
			header: 'Вы действительно хотите удалить изображение?',
			text: 'Это действие нельзя отменить.',
			onOk: () => {
				/*deleteImage(
					imageUrl,
					exerciseId,
					trainingAdmin,
					lessonId,
					queryClient
				)*/
			},
		})
	}
}

/** Обработчик удаления картинки */
/*async function deleteImage(
	imageUrl: string,
	exerciseId: number,
	trainingAdmin: TrainingEntityTypes.TrainingAdmin,
	lessonId: number,
	queryClient: QueryClient
) {
	const updatedTraining = ExerciseService.deleteImageFromImageDescExercise(
		trainingAdmin,
		lessonId,
		exerciseId,
		imageUrl
	)

	try {
		const response = await trainingRequests.createTraining(updatedTraining)

		// Пометить данные курса неактуальными. После этого React Query скачает обновлённые данные.
		queryClient.refetchQueries({
			queryKey: [trainingQuery.getTrainingForAdmin(trainingAdmin.id).key],
		})
	} catch (error) {
		message.error('Изображение не было удалено')
	}
}*/
