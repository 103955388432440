import React, { useState } from 'react'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import ToggleBlock from 'ui/ToggleBlock/ToggleBlock'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import MainPaymentForm from 'pages/landing/landingConstructor/tariffs/panel/payment/MainPaymentForm/MainPayment'
import AdditionalPaymentForm from 'pages/landing/landingConstructor/tariffs/panel/payment/AdditionalPaymentForm/AdditionalPaymentForm'
import { useGetCreatePrice } from 'pages/landing/landingConstructor/tariffs/panel/payment/PaymentSegment/fn/createPrice'
import 'pages/landing/landingConstructor/tariffs/panel/payment/PaymentSegment/MainPayment.scss'

function PaymentSegment() {
	const [open, setOpen] = useState(true)
	const activeTariff = tariffsManager.useGetActiveTariff()

	if (!activeTariff) return null

	return (
		<ToggleBlock
			header="Основная"
			open={open}
			onHeaderClick={() => setOpen((open) => !open)}
		>
			<MainPaymentForm />
			{activeTariff.prices.length > 0 && (
				<div className="main-payment-tariff-block__payments">
					{activeTariff.prices.map((price, i) => {
						return (
							<AdditionalPaymentForm
								formIdx={i + 1}
								price={price}
								key={i}
							/>
						)
					})}
				</div>
			)}
			<Bottom />
		</ToggleBlock>
	)
}

export default PaymentSegment

// Кнопка создания дополнительной оплаты
function Bottom() {
	const createPrice = useGetCreatePrice()

	return (
		<div className="main-payment-tariff-block__bottom">
			<Button
				type="dashed"
				block
				icon={<PlusOutlined />}
				onClick={createPrice}
			>
				Добавить способ оплаты
			</Button>
		</div>
	)
}
