import React from 'react'
import cn from 'classnames'
import './Progress.scss'

type ProgressProps = {
	percent: number
	extraClass?: string
}

// Компонент показывающий градиентный прогресс выполнения
function Progress(props: ProgressProps) {
	const { percent, extraClass } = props

	return (
		<div className={cn('progress', extraClass)}>
			<div className="progress__bar">
				<div className="progress__gradient">
					<div
						className="progress__space"
						style={{ width: 100 - percent + '%' }}
					/>
				</div>
			</div>
			<p
				className="progress__text"
				style={{ left: `calc(${percent}% - 26px)` }}
			>
				{percent} %
			</p>
		</div>
	)
}

export default Progress
