import React from 'react'
import { Space } from 'antd'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import FeedPagination from '../FeedPagination/FeedPagination'
import LayoutForAdmin from '../LayoutForAdmin/LayoutForAdmin'
import LayoutForStudent from '../LayoutForStudent/LayoutForStudent'

// Контейнер контентной части ленты ответов
function MainContainer() {
	const userRoles = useGetUserRole()

	return (
		<Space direction="vertical" size={30} style={{ width: '100%' }}>
			{userRoles.notStudent ? <LayoutForAdmin /> : <LayoutForStudent />}
			<FeedPagination />
		</Space>
	)
}

export default MainContainer
