import React, { ReactNode } from 'react'
import './MetaOutput.scss'

type MetaOutputProps = {
	items: ReactNode[]
}

// Компонент с серой полоской текстовых данных применяемый ниже заголовка и описания в шапке страницы
function MetaOutput(props: MetaOutputProps) {
	const { items } = props

	if (items.length == 0) return null

	return (
		<div className="meta-output">
			{items.map((item, i) => {
				return (
					<div className="meta-output__item" key={i}>
						{item}
					</div>
				)
			})}
		</div>
	)
}

export default MetaOutput
