import React, { ReactNode } from 'react'
import { Button, Tooltip } from 'antd'
import { LinkOutlined } from '@ant-design/icons'
import 'pages/landing/landingConstructor/tariffs/card/MainPriceWrapper/MainPriceWrapper.scss'

type MainPriceWrapperProps = {
	children: ReactNode
}

// Обёртка цены и кнопки покупки
function MainPriceWrapper(props: MainPriceWrapperProps) {
	const { children } = props

	return (
		<div className="tariff-card-main-price-wrapper">
			<LinkButton />
			{children}
		</div>
	)
}

export default MainPriceWrapper

// Кнопка копирования текста ссылки на вставку кнопки на одностраничник
function LinkButton() {
	return (
		<Tooltip title="Скопировать ссылку на кнопку">
			<Button
				size="small"
				shape="circle"
				icon={<LinkOutlined />}
				className="tariff-card-main-price-wrapper__link-button"
			/>
		</Tooltip>
	)
}
