import useCancelSubscriptionModalStore from '../zustand/store'

/** Возвращает функцию, открывающую модальное окно удаления школы */
export function useGetToggleVisibilityToCancelSubscriptionModal() {
	const isModalOpen = useCancelSubscriptionModalStore((store) => store.isOpen)
	const updateModalState = useCancelSubscriptionModalStore(
		(store) => store.updateState
	)

	return function () {
		updateModalState({ isOpen: !isModalOpen })
	}
}
