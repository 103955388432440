import React from 'react'
import { Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { AreaChartOutlined } from '@ant-design/icons'
import Preset from 'widgets/presets/presetsListDrawer/Preset/Preset'
import ApplyPresetConfirmation from '../../ApplyPresetConfirmation/ApplyPresetConfirmation'
import DeletePresetConfirmation from '../../DeletePresetConfirmation/DeletePresetConfirmation'
import { useDeletePreset } from 'widgets/presets/presetsListDrawer/PresetsList/fn/useDeletePreset'
import { useChangeApplyPresetModalVisibility } from '../../ApplyPresetConfirmation/fn/actions'
import useGetPresets from 'widgets/presets/presetsListDrawer/PresetsList/fn/useGetPresets'
import './PresetsList.scss'

interface IPresetsListProps {
	isPublic?: boolean
}

export default function PresetsList({ isPublic = false }: IPresetsListProps) {
	const { t } = useTranslation()

	const presets = useGetPresets(isPublic)

	const deletePreset = useDeletePreset()

	const openApplyPresetConfirmation =
		useChangeApplyPresetModalVisibility(true)

	return (
		<Space size={16} direction="vertical" className="presets-wrapper">
			{presets.map((preset) => (
				<Preset
					key={preset.id}
					description={preset.description}
					name={preset.name}
					icon={<AreaChartOutlined />}
					onPresetClick={openApplyPresetConfirmation(preset.id)}
					onPresetDelete={deletePreset(preset.id)}
				/>
			))}
			{!presets.length && <p>{t('adminTraining.presetsHasNotFound')}</p>}
			<ApplyPresetConfirmation />
			<DeletePresetConfirmation />
		</Space>
	)
}
