import React from 'react'
import ExerciseSwitcher from '../../exercisesViews/ExerciseSwitcher'
import ExerciseSwitcherNew from '../../exercisesViewsNew/ExerciseSwitcherNew'
import AddExerciseBottomButton from '../AddExerciseBottomButton/AddExerciseBottomButton'
import ConstructorSettingsLayout from '../ConstructorSettingsLayout/ConstructorSettingsLayout'
import ExercisesAside from '../ExercisesAside/ExercisesAside'
import ExercisesFormsBox from '../exercisesForms/ExercisesFormsBox/ExercisesFormsBox'
import NoExercises from '../NoExercises/NoExercises'
import useExercisesListAdminStore from '../zustand/store'
import { useScrollToNewExerciseAfterCreation } from './fn/scrollToNewExerciseAfterCreation'

function ExercisesListForAdmin() {
	useScrollToNewExerciseAfterCreation()

	const exercises = useExercisesListAdminStore((store) => store.exercises)
	const hasExercises = exercises.length > 0

	return (
		<>
			{!hasExercises && <NoExercises />}
			{hasExercises &&
				exercises.map((exercise) => {
					const exerciseId = exercise.id

					return (
						<ConstructorSettingsLayout
							exercise={exercise}
							key={exerciseId}
						>
							{/*<ExerciseSwitcherNew
								exercise={exercise}
								exerciseContainerType="constructorPage"
							/>*/}
							<ExerciseSwitcher exercise={exercise} />
						</ConstructorSettingsLayout>
					)
				})}
			{hasExercises && <AddExerciseBottomButton />}
			<ExercisesAside />
			<ExercisesFormsBox />
		</>
	)
}

export default ExercisesListForAdmin
