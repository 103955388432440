import { create } from 'zustand'
import { EXERCISE_ATTEMPTS } from '../../common/exerciseConst'

// Состояние упражнения, где хранится продолжительность прохождения попыток
// Сделано отдельно потому что не получилось хранить это в конечном автомате.
export namespace ExerciseStateType {
	export type State = {
		stage: 'NotReadyToCheck' | 'ReadyForCheck' | 'Checked' | 'Result'
		// Общее количество дырок для вставки слов
		totalSlots: number
		// Сколько сделано попыток (независимо от того выполнили ли упражнение или нет)
		attempts: number
		// Сколько раз нажали на кнопку выполнения.
		// Если три раза, то упражнение больше нельзя отмечать выполненным.
		// Если при загрузке упражнения оно отмечено выполненным, то сразу стоит число 3.
		doneCounter: number
		// Статистика на сколько вопросов ответили в каждой попытке
		statistics: Statistic[]
		images: ImageData[]
		descriptions: DescriptionData[] // Массив утверждений
		durationsInSeconds: number[]
		// Флаг сигнализирующий сохранить состояние на сервере
		// чтобы при следующем открытии упражнения восстанавливать значения.
		// Чтобы состояние сохранилось нужно увеличить значение флага.
		saveStoreForRestore: number

		// Метод изменяющий значение свойств
		updateStore: UpdateStore
	}

	export type Statistic = {
		// На сколько вопросов ученик дал ответ
		passedQuestions: number
	}

	export type ImageData = {
		id: number // id правильного утверждения и по-совместительству id изображения
		imageSrc: string // Ссылка на загруженное изображение
		givenDescId: null | number // id утверждения, который поставил пользователь
	}

	export type DescriptionData = {
		id: number // id утверждения и по-совместительству id изображения (если такое изображение существует)
		text: string // Текст утверждения
		rightWord: string
	}

	// Установщик новых значений корневого объекта через объект.
	export type UpdateStore = (obj: Partial<State>) => void
}

const useExerciseStore = create<ExerciseStateType.State>((set) => {
	return {
		stage: 'NotReadyToCheck',
		totalSlots: 0,
		attempts: EXERCISE_ATTEMPTS,
		doneCounter: 0,
		statistics: [],
		images: [],
		descriptions: [],
		durationsInSeconds: [],
		saveStoreForRestore: 0,

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useExerciseStore
