import useLandingBuilderStore from '../../../zustand/store'
import { Block } from '../FaqBlock/FaqBlock'
import './FaqBlocks.scss'

// Обёртка с двумя колонками куда помещаются блоки
function FaqBlocks() {
	const landing = useLandingBuilderStore((store) => store.landing)
	const { faqBlocks } = landing.sections.faq

	return (
		<div className="landing-faq-blocks">
			{faqBlocks.map((block, i) => {
				return <Block block={block} blockIdx={i} key={block.id} />
			})}
		</div>
	)
}

export default FaqBlocks
