const courseTeachRu = {
	// КУРС Как проводить live-уроки на Credu
	// Заголовок курса
	mainTitle: 'Как проводить live-уроки на Credu',
	// Подзаголовок курса
	mainSubtitle:
		'Платформа Креду была разработана специально для проведения онлайн уроков. Мы создали для вас 12 подсказок, которые ответят на большинство ваших вопросов. Начните знакомство с платформой прямо сейчас!',

	// УРОКИ КУРСА Как проводить live-уроки на Credu
	lesson1Title: 'Как добавить ученика на курс без регистрации',
	lesson1Subtitle: 'Вы узнаете как просто пригласить на урок ученика',
	lesson1Block1:
		'Понимаем, как важно чтобы урок прошел без каких либо сложностей, особенно если это первый урок с учеником. Итак, Ученик может попасть к вам на урок 2 способами:',
	lesson1Block2:
		'Самый простой. Вы заранее добавляете ученика на платформу, для этого вам понадобится только почта ученика. Платформа автоматически создаст ученику личный кабинет, вышлет доступ в личный кабинет ученику на почту. Так ему не придется проходить регистрацию, он просто вводит  логин и пароль из письма и переходит в личный кабинет.',
	lesson1Block3:
		'Другой способ. Отправляете ссылку на урок, ученик вводит почту, если он еще ни разу не был зарегистрирован, он пройдет простую регистрацию и сразу попадет к Вам на урок.',
	lesson1Block4:
		'Важно! Если ученик один раз вошел на платформу, в следующий раз платформа его сразу узнает и он сразу попадет к вам на урок, без ввода логина и пароля.',

	lesson2Title: 'Что будет, если отправить ученику ссылку на урок',
	lesson2Subtitle:
		'Вы увидите какой путь пройдёт ученик чтобы попасть на урок',
	lesson2Block1:
		'В этом уроке мы наглядно покажем как ученик попадет на урок, если Вы ему отправите ссылку на урок.',
	lesson2Block2:
		'Важно! По условию ученик не был зарегистрирован на платформе Креду.',

	lesson3Title: 'Что такое комната и как ее использовать',
	lesson3Subtitle:
		'Научитесь создавать, редактировать, удалять комнаты. Узнаете как назначать преподавателя на урок, если у вас онлайн школа',
	lesson3Block1:
		'Комната – это онлайн класс, в котором есть все необходимые инструменты для проведения интерактивного урока.Комната имеет постоянную ссылку, т.е. Вы можете использовать одну и туже комнату для проведения онлайн уроков со своим учеником.',
	lesson3Block2:
		'Важно!. Все что Вы проходили на уроки сохранится и Вы всегда сможете начать именно с того места, с которого закончили.',
	lesson3Block3:
		'Когда Вы приглашаете ученика на урок, в его личном кабинете создается карточка с уроком. Ученик может зайти на урок используя ссылку или в личном кабинете нажав на карточку.',

	lesson4Title: 'Как выглядит кабинет ученика?',
	lesson4Subtitle: 'Подробно узнаете какие функции есть в кабинете ученика',
	lesson4Block1:
		'Кабинет ученика отличается от кабинета преподавателя. Для нас было важно сделать его простым и интуитивно понятным.',
	lesson4Block2:
		'В этом уроке мы покажем как выглядит кабинет ученика, чтобы вы имели четкое представление о том что происходит когда ваш ученик переходит в личный кабинет.',

	lesson5Title: 'Режим видеоконференции',
	lesson5Subtitle:
		'Разберём все функции и что делать если пошло что‑то не так',
	lesson5Block1:
		'Урок можно проводить как с видео, т. е. будет воспроизводиться видео с Вашей веб камеры, так и без него. Для того чтобы урок был с видео необходимо дать разрешение на воспроизведение видео и аудио,  когда браузер об этом попросит. В противном случае на уроке не будет видео и а аудио, так как Вы запретили его использовать.',
	lesson5Block2:
		'Как включить аудио и видео в разных браузерах мы покажем в этом уроке, Это важно знать, потому что ученик может не прочитав сообщение просто запретить автоматически видео со своего устройства.',
	lesson5Block3:
		'А также покажем как переключаться из режима в singleview на  multiview, как управлять видео и аудио, как отключить звук  и видео у ученика и другие функции видеоконференции.',

	lesson6Title: 'Возможности интерактивной доски',
	lesson6Subtitle:
		'Разберём все функции, покажем как использовать доску на уроках разных дисциплин',
	lesson6Block1:
		'Интерактивная Доска выполняет теже функции: что и обычная доска в школе, но с гораздо  большим функционалом. Доска  для совместной работы на уроке обладает большим функционалом, что позволит вам разнообразить ваши уроки, совместно используя доску в вашем А также поддерживает вставку изображений. В этом уроке подробно разберем функции доски, разберем неочевидные функции, покажем как их можно использовать на ваших уроках',

	lesson7Title: 'Файловое хранилище',
	lesson7Subtitle:
		'Ваше личное облако файлов, доступное всегда и на любых устройствах',
	lesson7Block1:
		'Файловое хранилище  - это личное облако вашей школы, оно доступно менеджеру, куратору и вам. Здесь вы можете хранить все ваши раздаточные материалы и использовать их на уроке буквально в 2 клика. Добавить в класс вы можете аудио, видео и PDF файлы, однако в хранилище вы можете загрузить любой файл любого размера, например поделиться им с вашими учениками.',
	lesson7Block2:
		'В этом уроке мы расскажем как пользоваться хранилищем, какие файлы поддерживаем, как добавить файлы в класс, как воспроизвести видео, аудио, как  поделиться файлом в  чат и получить ссылку на файл как распределять по папкам и много другое.',

	lesson8Title: 'Поделиться экраном',
	lesson8Subtitle:
		'Как поделиться экраном, а так же как выбрать нужный элемент для демонстрации',
	lesson8Block1:
		'Самый лучший способ рассказать о чем-то - это показать наглядно. Для этого мы добавили функцию демонстрации экрана. Вы можете делиться отдельной вкладкой браузера, показать весь экран или поделиться каким-то приложением.',
	lesson8Block2:
		'Важно!  Делиться экраном не получится не получится на мобильной версии сайта',
	lesson8Block3:
		'На уроке мы покажем как работает демонстрация во всех браузерах, На какие нюансы стоит обратить внимание и как это выглядит на мобильном устройстве.',

	lesson9Title: 'Совместное прослушивание аудио на уроке',
	lesson9Subtitle:
		'Как воспроизвести аудио, как добавить аудио в плейлист, кто может управлять плеером',
	lesson9Block1:
		'Мы уже рассказывали в уроке про файловое хранилище как добавить в класс аудио. В этом уроке мы подробнее рассмотрим этот функционал, который по нашему мнению, является довольно важным, особенно в преподавании иностранных языков.',
	lesson9Block2:
		'Итак,  в этом уроке мы покажем как добавлять аудио в класс, как работают плейлисты, кто может управлять аудио, как это будет выглядеть на стороне ученика, Как удалить с урока аудио и поделиться файлами с учеником.',

	lesson10Title: 'Совместный просмотр видео на уроке',
	lesson10Subtitle:
		'Как воспроизвести видео, как добавить видео с YouTube, какие форматы поддерживает платформа',
	lesson10Block1:
		'Довольно часто на уроке нужно показать видео материалы, для этого есть 2 варианта, загрузить видео по ссылке с Youtube или напрямую из хранилища. Конечно, самый простой и удобный способ загрузить по ссылке из площадки Youtube. Однако, бывают случаи когда там нет нужных материалов, тогда вы можете загрузить их с личного хранилища.',
	lesson10Block2:
		'Важно! скорость загрузки видео материалов зависит от их объема и вашего интернет соединения. Если у вас большой размер видео материалов он будет загружаться исходя из вашей скорости интернета.',

	lesson11Title: 'Частые вопросы',
	lesson11Subtitle:
		'Вы узнаете: сохраняются ли доски, постоянная ли комната, сколько можно создавать досок, есть ли ограничения по времени, платит ли ученик за платформу итд',
	lesson11Block1:
		'Сохраняются ли в комнате материалы которые были загружены на доску?',
	lesson11Block2:
		'Да, все что вы рисуете на доске, все изображения и текст сохраняются в том виде, в котором вы оставили их после урока, Это очень удобно, ведь всегда можно начать именно с того места где закончили прошлый урок',
	lesson11Block3: 'Сколько можно создавать досок на уроке?',
	lesson11Block4:
		'В каждой комнате можно создать не более 100 досок.Вы можете удалить старые доски, которые вы  не используете и создавать новые.',
	lesson11Block5: 'Может ли ученик попасть на урок, в неурочное время?',
	lesson11Block6:
		'Да, из своего кабинета ученик может зайти на урок, посмотреть пройденные материал и даже решить домашнее задание, которые вы потом сможете посмотреть.',
	lesson11Block7: 'Зачем нужны кураторы и менеджеры?',
	lesson11Block8: 'Давайте по порядку.',
	lesson11Block9:
		'Кураторы нужны в двух случаях: 1.Если у Вас большая школа и вы нанимаете других преподавателей, чтобы они проводили уроки с вашими учениками, тогда при создании комнаты вы назначаете того куратора/преподавателя, который должен проводить урок. 2. Если вы создаете курсы и подразумевается,  что на курсах будут проводить проверку другие люди вот эти люди и есть кураторы.',
	lesson11Block10:
		'Менеджеры. В школе могут быть несколько менеджеров, они могут все тоже самое, что и вы, но не могут удалять. Это сделано для того,, чтобы весь ваш контент оставался в безопасности. В остальном менеджер это ваша правая рука, который берет всю рутину на себя.',
	lesson11Block11: 'Что такое лиды/покупки?',
	lesson11Block12:
		'Если вы продаете курсы (ведете асинхронное обучение), все оплаченные и не оплаченные заказы будут отображаться там.',
	lesson11Block13: 'Что такое лента ответов?',
	lesson11Block14:
		'В тех упражнениях, где требуется дать голосовой или письменный ответ попадают сюда на проверку, так вы сможете дать обратную связь.',
}

export default courseTeachRu
