import { useQueryClient } from 'react-query'
import { commentsQuery } from 'parts/requests/comments/commentsQuery'

export function useUnarchiveComment() {
	const queryClient = useQueryClient()

	// Объект с методом mutate для создания запроса
	const unarchiveCommentMutation = commentsQuery.unarchiveComment.useMutation(
		{
			onSuccess: () => {
				queryClient.invalidateQueries({
					predicate: (query) => {
						const queryKey = query.queryKey
						return (
							Array.isArray(queryKey) &&
							typeof queryKey[0] === 'string' &&
							queryKey[0].startsWith('getAllComments-')
						)
					},
				})
			},
		}
	)

	return async (commentId: number) => {
		unarchiveCommentMutation.mutate(commentId)
	}
}
