import React, { useCallback, useEffect, useState } from 'react'
import { produce } from 'immer'
import ExerciseStateType from './ExerciseStateType'
import { getQuestion } from './main'

/**
 * Возвращает строку с текущем номером вопроса и общим количеством
 * @param {Object} state — объект состояния упражнения
 */
export function getQuestionCounter(state: ExerciseStateType.Main) {
	return state.currentQuestionNumber + '/' + state.questions.length
}

/**
 * Функция возвращает текст с количеством попыток ответить на вопрос
 * @param {Object} question — объект вопроса
 */
export function getAttemptsCount(question: ExerciseStateType.Question) {
	if (!question) return ''

	const { attempts } = question

	if (attempts > 1) {
		return attempts + ' попытки'
	} else if (attempts == 1) {
		return attempts + ' попытка'
	} else {
		return 'Попыток не осталось'
	}
}

/**
 * Функция возвращает булево значение активна ли кнопка показа правильного ответа/перехода к следующему вопросу
 * @param {Object} state — объект состояния упражнения
 * @param {Object} question — объект вопроса
 */
export function isResultButtonEnabled(
	state: ExerciseStateType.Main,
	question: ExerciseStateType.Question
) {
	if (!question.resultButtonEnable) {
		return false
	}

	// Если показывается правильный ответ, то не нужно блокировать кнопку
	if (question.showCorrect) {
		return true
	}

	let wasCheckedAtLeastOneAnswer = false

	question.answers.forEach((answer) => {
		if (answer.checked) {
			wasCheckedAtLeastOneAnswer = true
		}
	})

	return wasCheckedAtLeastOneAnswer
}

/**
 * Функция возвращает текст на кнопке показа правильного ответа на вопрос/перехода к следующему вопросу
 * @param {Object} state — объект состояния упражнения
 * @param {Object} question — объект вопроса
 */
export function getResultButtonText(
	state: ExerciseStateType.Main,
	question: ExerciseStateType.Question
) {
	let text = 'Проверить'

	if (question.attempts === 1) {
		text = 'Правильный ответ'
	}

	if (question.showCorrect) {
		text = 'Вперёд'
	}

	if (
		question.showCorrect &&
		state.currentQuestionNumber == state.questions.length
	) {
		text = 'Показать результат'
	}

	return text
}

/**
 * Хук возвращает обработчик нажатия на кнопку показа правильного ответа/перехода к следующему вопросу
 * @param {Object} state — объект состояния упражнения
 * @param {Function} setState — функция изменяющая объект состояния упражнения
 * @param question
 */
export function useGetOnResultButtonClick(
	state: ExerciseStateType.Main,
	setState: React.Dispatch<React.SetStateAction<ExerciseStateType.Main>>,
	question: ExerciseStateType.Question
) {
	return useCallback(
		function () {
			const newState = produce(state, (draft) => {
				const foundedQuestion = getQuestion(draft, question.id)
				if (!foundedQuestion) return draft

				// Если были показаны результаты, то показать следующий вопрос
				if (foundedQuestion.showCorrect) {
					// Если это был последний вопрос, то показать результаты
					if (draft.currentQuestionNumber == draft.questions.length) {
						draft.showResult = true
					} else {
						draft.currentQuestionNumber++
					}

					return
				}

				// На вопрос ответили правильно?
				const wereGivenRightAnswers = checkUserAnswers(
					foundedQuestion.answers
				)

				paintAnswers(foundedQuestion.answers)

				// Если были даны правильные ответы или осталась одна попытка на ответ...
				if (wereGivenRightAnswers || foundedQuestion.attempts == 1) {
					// Подсветить рамочкой правильные ответы и ответы, которые были даны ошибочно
					foundedQuestion.showCorrect = true
					// Заблокировать вопрос чтобы его нельзя было изменить
					foundedQuestion.disableQuestion = true
				} else {
					// Заблокировать кнопку просмотра результата пока ученик не укажет новый ответ
					foundedQuestion.resultButtonEnable = false
				}

				// Уменьшить количество доступных попыток
				foundedQuestion.attempts = foundedQuestion.attempts - 1
			})

			setState(newState)
		},
		[state]
	)
}

/**
 * Функция возвращает булево значение отметил ли пользователь правильные ответы
 * @param {Array} answers — массив ответов на вопрос
 */
function checkUserAnswers(answers: ExerciseStateType.Answer[]) {
	let allRightAnswersChecked = true

	answers.forEach((answer) => {
		if (!answer.checked && answer.correct) {
			allRightAnswersChecked = false
		}
	})

	return allRightAnswersChecked
}

/**
 *
 * @param {Array} answers — массив ответов на вопрос
 */
function paintAnswers(answers: ExerciseStateType.Answer[]) {
	answers.forEach((answer) => {
		if (answer.correct) {
			if (answer.checked) {
				answer.view = 'right'
			}
		} else {
			if (answer.checked) {
				answer.view = 'wrong'
			}
		}
	})
}

/**
 * Хук возвращает булево значение должна ли быть активной или кнопка перемещения к предыдущему или следующему вопросу
 * @param {String} btnType — тип кнопки: prev (переход к предыдущему вопросу) или next (следующему вопросу)
 * @param {Object} state — объект состояния упражнения
 */
export function usePrevNextButtonEnabled(
	btnType: 'prev' | 'next',
	state: ExerciseStateType.Main
) {
	const [enabled, setEnabled] = useState(false)

	useEffect(
		function () {
			if (btnType == 'prev') {
				setEnabled(state.currentQuestionNumber > 1)
			} else if (btnType == 'next') {
				setEnabled(state.currentQuestionNumber < state.totalQuestions)
			}
		},
		[state]
	)

	return enabled
}

/**
 * Хук возвращает обработчик кнопок перехода к предыдущему и следующему вопросу
 * @param {String} btnType — тип кнопки: prev (переход к предыдущему вопросу) или next (следующему вопросу)
 * @param {Object} state — объект состояния упражнения
 * @param {Function} setState — функция изменяющая объект состояния упражнения
 */
export function useGetPrevNextButtonHandler(
	btnType: 'prev' | 'next',
	state: ExerciseStateType.Main,
	setState: React.Dispatch<React.SetStateAction<ExerciseStateType.Main>>
) {
	return useCallback(
		function () {
			const newState = { ...state }

			if (btnType == 'prev') {
				newState.currentQuestionNumber--
			} else if (btnType == 'next') {
				newState.currentQuestionNumber++
			}

			setState(newState)
		},
		[state]
	)
}
