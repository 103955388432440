import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import AppUrls from 'parts/constants/pageUrl'
import authRequests from 'parts/requests/auth/authRequest'
import SendConfirmationCodeToEmailBtn from 'widgets/SendConfirmationCodeToEmailBtn/SendConfirmationCodeToEmailBtn'
import UrlService from 'parts/services/UrlService'
import './StepMessage.scss'

type StepMessageProps = {
	step: 1 | 2 | 3
}

// Информационное сообщение ниже формы ввода нового пароля
function StepMessage(props: StepMessageProps) {
	const { step } = props

	return (
		<div className="recover-pass-form-step">
			<Counter step={step} />
			<Message step={step} />
		</div>
	)
}

export default StepMessage

function Counter(props: StepMessageProps) {
	const { step } = props
	const { t } = useTranslation()

	return (
		<p className="recover-pass-form-step__counter">
			<span>шаг {step} из 3</span>
		</p>
	)
}

function Message(props: StepMessageProps) {
	const { step } = props
	const { t } = useTranslation()

	if (step == 1) {
		return (
			<p className="recover-pass-form-step__message">
				{t('auth.stepMessageStepOneInfo')}
			</p>
		)
	} else if (step == 2) {
		return (
			<>
				<p className="recover-pass-form-step__message">
					{t('auth.stepMessageStepTwoInfo')}
				</p>
				<SendCodeBtn />
			</>
		)
	} else {
		return (
			<p className="recover-pass-form-step__message">
				{t('auth.stepMessageStepThreeInfo')}{' '}
				<Link to={AppUrls.Main().url} className="link">
					{t('auth.stepMessageStepThreeLink')}
				</Link>
			</p>
		)
	}
}

function SendCodeBtn() {
	async function sendCode() {
		const email =
			UrlService.getSearchParamValueFromUrl(location.href, 'email') || ''
		return await authRequests.recoverPasswordByEmail(email.toLowerCase())
	}

	return (
		<SendConfirmationCodeToEmailBtn
			reqFn={sendCode}
			extraClass="recover-pass-form-step__center-button"
		/>
	)
}
