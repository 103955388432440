import cn from 'classnames'
import { LandingTypes } from 'parts/types/LandingTypes'
import useLandingBuilderStore from 'pages/landing/landingBuilder/zustand/store'
import './TimeBlock.scss'

type BlockProps = {
	blockIdx: number
	block: LandingTypes.TimeBlock
}

export function Block(props: BlockProps) {
	const { blockIdx, block } = props

	const landing = useLandingBuilderStore((store) => store.landing)
	const { colorScheme } = landing

	return (
		<article className="landing-time-block">
			<p
				className={cn(
					'landing-time-block__counter',
					'landing-time-block__counter--' + colorScheme
				)}
			>
				{blockIdx + 1}
			</p>
			<p className="landing-time-block__text">
				{landing.sections.time.blocks[blockIdx].text}
			</p>
		</article>
	)
}
