import { useCallback, useEffect, useState } from 'react'
import UrlService from 'parts/services/UrlService'
import { SaveExerciseChangesMadeByUser } from '../../common/useLiftViewDuration'
import {
	createTextButton,
	disable$button,
	removeWideFrom$slot,
} from './manipulateElems'

// Тип объекта, в котором будут храниться данные для восстановления текста, написанного учеником в дырках.
type BlocksGapsRestore = {
	// Текстовый блок — это массив строк.
	// Поэтому текстовые блоки — это массивы массивов строк
	textBlocks: TextBlocksRestore
}

type TextBlocksRestore = string[][]

/**
 * Возвращает обработчик перетаскивания кнопки с текстом в дырку.
 * После формируется объект с текстом брошенных кнопок в дырки и сохраняется на сервере
 * чтобы при открытии упражнения в следующий раз было восстановлено положение кнопок с текстом, которое сделал ученик.
 */
export function useGetDropWordHandler(
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
) {
	const exerciseId = UrlService.useGetExerciseId()

	return useCallback(
		function (e: any) {
			if (!saveExerciseChangesMadeByUser) return

			// Объект данных, сохраняемый на сервере.
			const blocksGapsRestoreObj: BlocksGapsRestore = {
				textBlocks: [],
			}

			// Обёртка с текстовыми блоками с дырками куда ученик перетаскивает кнопки
			const $textBlocksWrapper = e.target.closest(
				'.exercise-drop-word-content__text-blocks'
			)

			// Перебрать блоки с текстом
			for (let i = 0; i < $textBlocksWrapper.children.length; i++) {
				// Блок текста с дырками
				const $textBlock = $textBlocksWrapper.children[i]

				// Элементы, в которые можно бросить слова
				const $gaps = $textBlock.querySelectorAll(
					'.exercise-drop-word-content__slot'
				)

				// Перебрать дырки конкретного текстового блока
				for (let j = 0; j < $gaps.length; j++) {
					// Если нет массива для слов текстового блока, то создать.
					if (!blocksGapsRestoreObj.textBlocks[i]) {
						blocksGapsRestoreObj.textBlocks.push([])
					}

					// Поставить в данные текст кнопки, который должен быть в дырке.
					// При восстановлении сохранённый текст будет преобразована в перетащенную кнопку.
					blocksGapsRestoreObj.textBlocks[i].push($gaps[j].innerText)
				}
			}

			// Передать в функцию, которая сохранит данные на сервере.
			saveExerciseChangesMadeByUser(blocksGapsRestoreObj)
		},
		[exerciseId]
	)
}

/**
 * Получает данные для восстановления вида упражнения, которое ученик сделал.
 * @param $textBlocksWrapper — обёртка текстовых блоков с дырками, в которые можно перетащить кнопки с текстом.
 * @param exerciseRestoreData — данные для восстановления упражнения.
 */
export function useRestoreGapTextsInTextBlockOnMount(
	$textBlocksWrapper: null | HTMLDivElement,
	exerciseRestoreData?: Promise<BlocksGapsRestore>
) {
	const exerciseId = UrlService.useGetExerciseId()
	// Сюда попадут данные для восстановления как будут скачаны.
	const [restoreData, setRestoreData] = useState<TextBlocksRestore>([])

	useEffect(
		function () {
			if (!exerciseRestoreData) return

			exerciseRestoreData.then((data) => {
				if (!data?.textBlocks) return

				setRestoreData(data.textBlocks)
			})
		},
		[exerciseId]
	)

	useEffect(
		function () {
			if (!restoreData.length || !$textBlocksWrapper) return

			// Автоматически перетащить кнопки в те места, куда они были перетащаны в прошлый раз.
			setWordsInGaps($textBlocksWrapper, restoreData)
		},
		[restoreData]
	)
}

/**
 *
 * @param $textBlocksWrapper
 * @param restoreData
 */
function setWordsInGaps(
	$textBlocksWrapper: HTMLDivElement,
	restoreData: TextBlocksRestore
) {
	// Перебрать текстовые блоки с дырками для перетаскивания
	for (let i = 0; i < $textBlocksWrapper.children.length; i++) {
		const $words = $textBlocksWrapper.children[i].querySelector(
			'.exercise-drop-word-content__words'
		)
		if (!$words) continue

		// Блок текста с дырками
		const $textBlock = $textBlocksWrapper.children[i]

		// Элементы, в которые перетаскиваются слова
		const $gaps = $textBlock.querySelectorAll(
			'.exercise-drop-word-content__slot'
		)

		// Перебрать дырки
		for (let j = 0; j < $gaps.length; j++) {
			// Пропустить если в данных нет текста для этой дырки
			if (!restoreData[i] || !restoreData[i][j]) continue

			// Текст, который должен быть в дырке
			const textForGap = restoreData[i][j]
			// Найти кнопку с этим текстом на обёртке кнопок
			const textButton = getTextButtonByText($words, textForGap)
			if (!textButton) continue

			// Сделать кнопку неперетаскиваемой чтобы её нельзя было перетащить ещё раз.
			// Ниже сделаю кнопку, котораю будет в дырке и уже её перетащить можно.
			disable$button(textButton)

			// Получить дырку и удалить класс задающий ширину чтобы она полностью обнимала перетащенную кнопку
			const $gap = $gaps[j] as HTMLElement
			removeWideFrom$slot($gap)

			// Создать перетащенную кнопку
			const draggedButton = createTextButton(
				textForGap,
				textButton.dataset.wordId!
			)

			// Поставить в дырку перетащенную кнопку
			$gaps[j].append(draggedButton)
		}
	}
}

/**
 * Ищет в обёртке с кнопками кнопку с переданным текстом
 * @param $words — обёртка с кнопками для перетаскивания
 * @param text — текст, который должен быть у искомой кнопке
 */
function getTextButtonByText(
	$words: Element,
	text: string
): null | HTMLElement {
	const buttons = $words.querySelectorAll('.exercise-drop-word-content__word')

	for (let i = 0; i < buttons.length; i++) {
		const thisButton = buttons[i]

		if (thisButton.textContent === text) {
			return thisButton as HTMLElement
		}
	}

	return null
}
