import { useTranslation } from 'react-i18next'
import {
	UTableHeaderCellSearchContent,
	UTableHCell,
	UTableHRow,
	UTableSearchButton,
} from 'ui/UTable/UTable'
import { LeadsStoreType } from '../../LeadsTable/zustand/storeTypes'
import {
	getClearSearchValueFn,
	useIsClearButtonVisible,
} from './fn/clearSearchValue'
import { DateForm } from '../forms/DateForm/DateForm'
import EmailForm from '../forms/EmailForm/EmailForm'
import PhoneForm from '../forms/PhoneForm/PhoneForm'
import StatusForm from '../forms/StatusForm/StatusForm'
import PaySystemForm from '../forms/PaySystemForm/PaySystemForm'
import PriceForm from '../forms/PriceForm/PriceForm'

// Заголовочный ряд таблицы лидов
function TableHeader() {
	const { t } = useTranslation()

	// Видимы ли кнопки очистки значений поиска по колонке
	const {
		isDateClearBtnOpen,
		isEmailClearBtnOpen,
		isPhoneClearBtnOpen,
		isPriceClearBtnOpen,
		isStatusesClearBtnOpen,
		isPaySystemsClearBtnOpen,
	} = useIsClearButtonVisible()

	const clearDate = getClearSearchValueFn(
		LeadsStoreType.SearchValueNames.DateTo
	)
	const clearEmail = getClearSearchValueFn(
		LeadsStoreType.SearchValueNames.Email
	)
	const clearPrice = getClearSearchValueFn(
		LeadsStoreType.SearchValueNames.PriceFrom
	)
	const clearPhone = getClearSearchValueFn(
		LeadsStoreType.SearchValueNames.Phone
	)
	const clearStatus = getClearSearchValueFn(
		LeadsStoreType.SearchValueNames.Statuses
	)
	const clearPaySystem = getClearSearchValueFn(
		LeadsStoreType.SearchValueNames.PaySystems
	)

	return (
		<UTableHRow>
			<UTableHCell>
				<UTableHeaderCellSearchContent
					text={t('leads.leadsTableHeaderDate')}
					isClearBtnVisible={isDateClearBtnOpen}
					onClearBtnClick={clearDate}
					buttonWithForm={
						<UTableSearchButton ButtonWithForm={<DateForm />} />
					}
				/>
			</UTableHCell>
			<UTableHCell>Курс</UTableHCell>
			<UTableHCell>
				<UTableHeaderCellSearchContent
					text={t('leads.leadsTableHeaderEmail')}
					isClearBtnVisible={isEmailClearBtnOpen}
					onClearBtnClick={clearEmail}
					buttonWithForm={
						<UTableSearchButton ButtonWithForm={<EmailForm />} />
					}
				/>
			</UTableHCell>

			<UTableHCell>
				<UTableHeaderCellSearchContent
					text={t('leads.leadsTableHeaderPhone')}
					isClearBtnVisible={isPhoneClearBtnOpen}
					onClearBtnClick={clearPhone}
					buttonWithForm={
						<UTableSearchButton ButtonWithForm={<PhoneForm />} />
					}
				/>
			</UTableHCell>
			<UTableHCell>
				<UTableHeaderCellSearchContent
					text={t('leads.leadsTableHeaderCost')}
					isClearBtnVisible={isPriceClearBtnOpen}
					onClearBtnClick={clearPrice}
					buttonWithForm={
						<UTableSearchButton ButtonWithForm={<PriceForm />} />
					}
				/>
			</UTableHCell>
			<UTableHCell>
				<UTableHeaderCellSearchContent
					text={t('leads.leadsTableHeaderPaymentSystem')}
					isClearBtnVisible={isPaySystemsClearBtnOpen}
					onClearBtnClick={clearPaySystem}
					buttonWithForm={
						<UTableSearchButton
							ButtonWithForm={<PaySystemForm />}
						/>
					}
				/>
			</UTableHCell>
			<UTableHCell>
				<UTableHeaderCellSearchContent
					text={t('leads.leadsTableHeaderStatus')}
					isClearBtnVisible={isStatusesClearBtnOpen}
					onClearBtnClick={clearStatus}
					buttonWithForm={
						<UTableSearchButton ButtonWithForm={<StatusForm />} />
					}
				/>
			</UTableHCell>
		</UTableHRow>
	)
}

export default TableHeader
