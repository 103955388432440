import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import Image from 'ui/Image/Image'
import {
	getUnperformedExercisesString,
	getUnperformedLessonsString,
} from './fn/cardDetails'
import { CardSticker } from 'ui/wrappers/CardSticker/CardSticker'
import { ClockCircleOutlined } from '@ant-design/icons'
import { UCardBlock } from 'ui/wrappers/UCardBlock/UCardBlock'
import { TrainingsStoreType } from '../../zustand/storeTypes'
import Progress from 'ui/Progress/Progress'
import DatesRange from 'ui/DatesRange/DatesRange'
import { useGetOnCardClick } from './fn/cardClick'
import { getStickerDetails } from './fn/cardDetails'
import './TrainingCardForStudent.scss'

type TrainingCardForStudentProps = {
	training: TrainingsStoreType.TrainingCard
}

// Карточка курса для ученика
function TrainingCardForStudent(props: TrainingCardForStudentProps) {
	const { training } = props

	const { t } = useTranslation()
	const stickerDetails = getStickerDetails(training, t)
	const onCardClick = useGetOnCardClick(training)

	return (
		<CardSticker text={stickerDetails.text} color={stickerDetails.color}>
			<UCardBlock
				hover
				onClick={onCardClick}
				extraClass="student-training-card__container"
				dataCyAttr="training-card"
			>
				<Top training={training} />
				<Window training={training} />
				<Bottom training={training} />
			</UCardBlock>
		</CardSticker>
	)
}

export default TrainingCardForStudent

type CommonProps = {
	training: TrainingsStoreType.TrainingCard
}

function Top(props: CommonProps) {
	const { training } = props

	const isActive = training.trainingStatus !== 'notOpen'

	return (
		<div className="student-training-card__top">
			<div>
				<Image src={training.cover} form="square" width={100} />
			</div>
			<div>
				<p className="student-training-card__group-name">
					{training.groupName}
				</p>
				<h3
					className={cn(
						'student-training-card__training-name',
						isActive &&
							'student-training-card__training-name--active'
					)}
				>
					{training.trainingName}
				</h3>
				{training.trainingDescription && (
					<p className="student-training-card__description">
						{training.trainingDescription}
					</p>
				)}
			</div>
		</div>
	)
}

function Window(props: CommonProps) {
	const { training } = props

	// Доступен ли курс для прохождения?
	const isTrainingOpen =
		training.trainingStatus == 'firstLessonOpeningWait' ||
		training.trainingStatus == 'openPartly' ||
		training.trainingStatus == 'openFully'

	return (
		<div className="student-training-card__window">
			{isTrainingOpen && <WindowGroupOpen training={training} />}
			{training.trainingStatus == 'notOpen' && <WindowGroupNotOpen />}
			{training.trainingStatus == 'outdated' && <WindowGroupFinished />}
		</div>
	)
}

function WindowGroupOpen(props: CommonProps) {
	const { unperformedExercises, unperformedLessons, progress } =
		props.training

	const { t } = useTranslation()

	if (!unperformedExercises) {
		return (
			<p className="student-training-card__window-text">
				{t('studentTrainings.trainingCardStatusAllFinished')}
			</p>
		)
	}

	return (
		<div className="student-training-card__window-inner">
			<Progress percent={progress} />
			<p className="student-training-card__window-small-text">
				{t('studentTrainings.trainingCardStatusRemainsToComplete')}
			</p>
			<p className="student-training-card__window-text">
				{getUnperformedExercisesString(unperformedExercises)} в{' '}
				{getUnperformedLessonsString(unperformedLessons)}
			</p>
		</div>
	)
}

function WindowGroupNotOpen() {
	const { t } = useTranslation()

	return (
		<p className="student-training-card__window-text">
			{t('studentTrainings.trainingCardStatusHasNotBegun')}
		</p>
	)
}

function WindowGroupFinished() {
	const { t } = useTranslation()

	return (
		<div className="student-training-card__window-inner">
			<Progress percent={40} />
			<p className="student-training-card__window-text">
				{t('studentTrainings.trainingCardStatusSuccessfulFinished')}
			</p>
		</div>
	)
}

function Bottom(props: CommonProps) {
	const { training } = props

	return (
		<div className="student-training-card__bottom">
			<Duration training={training} />
			<NextDate training={training} />
		</div>
	)
}

function Duration(props: CommonProps) {
	const { training } = props
	const { groupStart, groupEnd, trainingStatus } = training

	// Если начало курса зависит от открытия первого урока, то значит курс ещё не открывали.
	// В этом случае ничего не выводить.
	if (!groupStart || !groupEnd) {
		return <div />
	}

	return (
		<DatesRange
			dateFrom={groupStart}
			dateTo={groupEnd}
			extraClass="student-training-card__range"
			colored={trainingStatus.startsWith('open')}
		/>
	)
}

function NextDate(props: CommonProps) {
	const { trainingStatus, nextLessonOpenDate } = props.training

	const { t } = useTranslation()

	// Если курс завершён, то ничего не отрисовывать
	if (trainingStatus == 'outdated') {
		return null
	}
	// Если курс не открыт, то показать дату начала занятий
	else if (trainingStatus == 'notOpen') {
		return (
			<p className="student-training-card__next-date-text">
				<ClockCircleOutlined className="student-training-card__next-date-icon" />
				<span className="student-training-card__next-date-text--gray">
					{t('studentTrainings.trainingCardTrainingStart')}
				</span>
				&nbsp;{nextLessonOpenDate}
			</p>
		)
	}
	// Если начало курса зависит от открытия первого урока, то возвратить сообщение, что курс начнётся после открытия первого урока
	else if (trainingStatus == 'firstLessonOpeningWait') {
		return (
			<p className="student-training-card__next-date-text">
				<img
					src="/images/signs/CheckCircleTwoTone.svg"
					className="student-training-card__next-date-icon"
					alt=""
				/>
				{t(
					'studentTrainings.trainingCardTrainingStartAfterFirstLessonOpening'
				)}
			</p>
		)
	}
	// Если не все уроки открыты, то возвратить дату ближайшего закрытого урока
	else if (trainingStatus == 'openPartly') {
		return (
			<p className="student-training-card__next-date-text">
				<img
					src="/images/signs/ClockCircleTwoTone.svg"
					className="student-training-card__next-date-icon"
					alt=""
				/>
				<span className="student-training-card__next-date-text--gray">
					{t('studentTrainings.trainingCardNextLesson')}&nbsp;
				</span>{' '}
				{nextLessonOpenDate}
			</p>
		)
	}

	// Если все уроки открыты, то ничего не возвращать
	return null
}
