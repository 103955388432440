import { UTable, UTableBody } from 'ui/UTable/UTable'
import TableHeader from '../TableHeader/TableHeader'
import TableRow from '../TableRow/TableRow'
import './StaffTable.scss'

// Содержимое страницы со списком учеников
import useStaffStore from '../zustand/store'

function StaffTable() {
	const staff = useStaffStore((store) => store.staff)

	return (
		<UTable>
			<TableHeader />
			<UTableBody>
				{staff.map((employee) => (
					<TableRow employee={employee} key={employee.id} />
				))}
			</UTableBody>
		</UTable>
	)
}

export default StaffTable
