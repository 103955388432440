import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'

/**
 * Возвращает функцию, делающую карточку тарифа активной для изменений
 * @param tariffId — внутренний идентификатор тарифа
 */
export function useGetMakeCardActive(tariffId: number) {
	const { updateStore } = tariffsManager.store

	return function () {
		updateStore({ activeTariffId: tariffId })
	}
}
