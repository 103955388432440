import React, { useCallback } from 'react'
import ExerciseStateType from './ExerciseStateType'
import { convertExerciseDataToExerciseState } from './main'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

/**
 * Хук запускает функцию запускающую прохождение упражнения заново
 * @param {Object} exercise — объект упражнения из БД
 * @param {Function} setState — функция устанавливающая объект состояния упражнения
 */
export function useGetPassExerciseAgainFn(
	exercise: TrainingEntityTypes.DescriptionImgExercise,
	setState: React.Dispatch<React.SetStateAction<ExerciseStateType.Main>>
) {
	return useCallback(function () {
		setState(convertExerciseDataToExerciseState(exercise))
	}, [])
}
