import ExerciseContainer, {
	ExerciseContainerType,
} from '../../common/ExerciseContainer/ExerciseContainer'
import Result from '../../common/Result/Result'
import {
	ExerciseChangesDataMadeByUser,
	SaveExerciseChangesMadeByUser,
} from '../../common/useLiftViewDuration'
import ExerciseControlPanel from '../ExerciseControlPanel/ExerciseControlPanel'
import Exercise from './Exercise'
import { useGetOnPassAgainButtonClick } from './fn/result'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import useExerciseStore from '../zustand/exerciseState'
import { fillStore } from '../zustand/fn/fillStore'
import { useSetAttemptsDuration } from './fn/setAttemptsDuration'
import {
	useRestoreStore,
	useSaveStateOnServerForRestore,
} from './fn/useSaveStateOnServerForRestore'
import './TrueOrFalseExerciseView.scss'

type TrueOrFalseExerciseViewProps = {
	// Для какого типа страницы предназначается обёртка упражнения:
	// constructorPage — страница конструктора. Будут скрыты кнопки перехода по упражнениям и кнопки снизу
	// exercisePage — страница упражнения для ученика. Будет полный набор элементов.
	exerciseContainerType: ExerciseContainerType
	exercise: TrainingEntityTypes.TrueOrFalseExercise
	// Адрес предыдущего и следующего упражнения
	prevExerciseUrl?: string
	nextExerciseUrl?: string
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

// Вывод упражнения ложь или истина в уроке
function TrueOrFalseExerciseViewNew(props: TrueOrFalseExerciseViewProps) {
	const {
		exerciseContainerType,
		exercise,
		prevExerciseUrl,
		nextExerciseUrl,
		saveExerciseChangesMadeByUser,
		exerciseRestoreData,
	} = props

	fillStore(exercise)

	const showResult = useExerciseStore((store) => store.showResult)
	const durationsInSeconds = useExerciseStore(
		(store) => store.durationsInSeconds
	)
	const statements = useExerciseStore((store) => store.statements)
	const statistics = useExerciseStore((store) => store.statistics)

	// Восстановить данные упражнения при открытии
	useRestoreStore(exerciseRestoreData)
	// Занимается сохранением состояния упражнения для восстановления при открытии
	useSaveStateOnServerForRestore(saveExerciseChangesMadeByUser)

	useSetAttemptsDuration()

	const passExerciseAgainFn = useGetOnPassAgainButtonClick(exercise)

	return (
		<ExerciseContainer
			type={exerciseContainerType}
			prevExerciseUrl={prevExerciseUrl}
			nextExerciseUrl={nextExerciseUrl}
		>
			{showResult ? (
				<Result
					seconds={durationsInSeconds[durationsInSeconds.length - 1]}
					totalQuestions={statements.length}
					stats={statistics}
					passAgainFn={passExerciseAgainFn}
				/>
			) : (
				<Exercise exercise={exercise} />
			)}
			<ExerciseControlPanel />
		</ExerciseContainer>
	)
}

export default TrueOrFalseExerciseViewNew
