import React from 'react'
import { useTranslation } from 'react-i18next'
import VisitCard from '../VisitCard/VisitCard'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import { useGetVisits } from './fn/getVisits'
import Loader from 'ui/Loader/Loader'
import './StudentVisitsArticle.scss'

// Содержимое страницы «Посещения ученика»
function StudentVisitsArticle() {
	const { t } = useTranslation()
	const visits = useGetVisits()

	if (!visits) {
		return (
			<ArticleContentWrapper center>
				<Loader />
			</ArticleContentWrapper>
		)
	} else if (!visits.length) {
		return (
			<ArticleContentWrapper center>
				<p>{t('student.studentVisitsArticleNoGroups')}</p>
			</ArticleContentWrapper>
		)
	} else {
		return (
			<ArticleContentWrapper
				innerWrapperClass="visits"
				innerWrapperWidth="full"
			>
				{visits.map((visit) => {
					return <VisitCard visitData={visit} key={visit.groupId} />
				})}
			</ArticleContentWrapper>
		)
	}
}

export default StudentVisitsArticle
