import { useTranslation } from 'react-i18next'
import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import Loader from 'ui/Loader/Loader'
import NoContent from 'ui/NoContent/NoContent'
import AppUrls from 'parts/constants/pageUrl'
import TrainingStudentArticle from '../../TrainingArticle/TrainingStudentArticle'
import { LessonsStudentController } from '../../trainingLessons/LessonsStudentController'
import { TrainingPageHeaderController } from '../../trainingPageHeader/PageHeader/TrainingPageHeaderController'

/** Обёртка страниц уроков и групп */
function TrainingForStudentPage() {
	const { t } = useTranslation()

	const headerRouter = TrainingPageHeaderController.router()
	const lessonsRouter = LessonsStudentController.router()

	if (
		headerRouter.status === 'loading' &&
		lessonsRouter.status === 'loading'
	) {
		return (
			<ArticleContentWrapper center>
				<Loader />
			</ArticleContentWrapper>
		)
	}

	if (
		headerRouter.status === 'noTraining' &&
		lessonsRouter.status === 'noTraining'
	) {
		return (
			<ArticleContentWrapper center>
				<NoContent
					header={t('studentTraining.noTrainingScreenHeader')}
					text={t('studentTraining.noTrainingScreenText')}
					btnConf={{
						text: t(
							'studentTraining.noTrainingScreenGoToTrainingsBtn'
						),
						to: AppUrls.Trainings().url,
					}}
				/>
			</ArticleContentWrapper>
		)
	}

	return (
		<PageSkeleton>
			{headerRouter.element}
			<TrainingStudentArticle />
		</PageSkeleton>
	)
}

export default TrainingForStudentPage
