import { useTranslation } from 'react-i18next'
import StaffTable from '../StaffTable'
import { useFillInStore } from './fillInStore'
import Loader from '../../../../../../ui/Loader/Loader'
import useGroupStaffStore from '../zustand/store'

type GetGroupStaffRouterReturn = {
	status: 'loading' | 'noStaffInSchool' | 'noStaffInGroup' | 'success'
	element: JSX.Element
}

/** Хук возвращающий статус компонента TrainingList и сам компонент в зависимости от статуса */
export function useGetStaffTableRouter(): GetGroupStaffRouterReturn {
	const { t } = useTranslation()

	useFillInStore()

	const [stateStaff, schoolHasStaff] = useGroupStaffStore((store) => [
		store.staff,
		store.schoolHasStaff,
	])

	if (stateStaff == undefined) {
		return {
			status: 'loading',
			element: <Loader />,
		}
	} else if (!schoolHasStaff) {
		return {
			status: 'noStaffInSchool',
			element: <p>В школу не добавлены сотрудники</p>,
		}
	} else if (!stateStaff.length) {
		return {
			status: 'noStaffInGroup',
			element: <p>В группу не добавлены сотрудники</p>,
		}
	}

	return {
		status: 'success',
		element: <StaffTable />,
	}
}
