import { Button, Checkbox, Form, Input, InputNumber } from 'antd'
import { FieldsNames, setErrorsToForm, useGetInitialValues } from './fn/form'
import { useGetOnSubmit } from './fn/submit'

function BuyForm() {
	const [form] = Form.useForm()
	const initialValues = useGetInitialValues()

	const onSubmit = useGetOnSubmit()
	setErrorsToForm(form)

	return (
		<Form
			form={form}
			layout="vertical"
			name="nest-messages"
			onFinish={onSubmit}
			initialValues={initialValues}
		>
			<NameInput />
			<PhoneInput />
			<AgreeInput />
			<SubmitButton />
		</Form>
	)
}

export default BuyForm

function NameInput() {
	return (
		<Form.Item
			required
			name={FieldsNames.Email}
			label="Email"
			rules={[
				{
					type: 'email',
					message: 'Неверный E-mail!',
				},
				{
					required: true,
					message: 'Пожалуйста укажите E-mail',
				},
			]}
		>
			<Input size="large" type="email" />
		</Form.Item>
	)
}

function PhoneInput() {
	return (
		<Form.Item
			name={FieldsNames.Phone}
			label="Телефон"
			style={{ width: '100%' }}
			rules={[
				{
					required: true,
					message: 'Пожалуйста укажите ваш номер телефона',
				},
			]}
		>
			<InputNumber
				size="large"
				controls={false}
				style={{ width: '100%' }}
			/>
		</Form.Item>
	)
}

function AgreeInput() {
	return (
		<Form.Item
			name={FieldsNames.Agree}
			label=""
			valuePropName="checked"
			rules={[
				{
					required: true,
					message: 'Требуется принять Пользовательское соглашение',
				},
			]}
		>
			<Checkbox>Я принимаю "Пользовательское соглашение"</Checkbox>
		</Form.Item>
	)
}

function SubmitButton() {
	return (
		<Form.Item>
			<Button type="default" htmlType="submit" size="large">
				Оплатить
			</Button>
		</Form.Item>
	)
}
