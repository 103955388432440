import { Dispatch, SetStateAction } from 'react'
import ExerciseControlPanelContainer from '../../common/ExerciseControlPanelContainer/ExerciseControlPanelContainer'
import { ExerciseStateType } from '../VideoExerciseView/fn/stateType'
import { useGetButtonsConfig } from './fn/getButtonsConfig'

type ExerciseControlPanelProps = {
	state: ExerciseStateType.Main
	setState: Dispatch<SetStateAction<ExerciseStateType.Main>>
}

// Нижняя часть упражнения с кнопками управления упражнением
function ExerciseControlPanel(props: ExerciseControlPanelProps) {
	const { state, setState } = props

	return (
		<ExerciseControlPanelContainer
			buttons={useGetButtonsConfig(state, setState)}
		/>
	)
}

export default ExerciseControlPanel
