import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import Image from 'ui/Image/Image'

type CoverProps = {
	lesson: TrainingEntityTypes.LessonAdmin
}

// Поле с фотографией
function CoverInput(props: CoverProps) {
	const { lesson } = props

	return (
		<Image
			src={lesson.cover}
			form="free"
			extraClass="edit-lesson-card__cover-wrapper"
		/>
	)
}

export default CoverInput
