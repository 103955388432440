import useStudentsStore from '../../../StudentsTableCurator/zustand/store'
import { StudentsStoreType } from '../../../StudentsTableCurator/zustand/storeTypes'

/** Возвращает объект с булевыми значениями видимости кнопок поиска по колонке */
export function useIsClearButtonVisible() {
	const searchValues = useStudentsStore((store) => store.searchValues)
	const ValueNames = StudentsStoreType.SearchValueNames

	return {
		isFullNameClearBtnOpen: !!searchValues[ValueNames.FullName].length,
		isTrainingNameClearBtnOpen:
			!!searchValues[ValueNames.TrainingName].length,
		isGroupNameClearBtnOpen: !!searchValues[ValueNames.GroupName].length,
		isStatusesClearBtnOpen: !!searchValues[ValueNames.Status],
	}
}

/**
 * Возвращает обработчик нажатия на кнопку обнуления введённого поискового запроса в форме поиска по колонке таблицы
 * @param searchType — тип колонки для поиска
 */
export function getClearSearchValueFn(
	searchType: StudentsStoreType.SearchValueNames
) {
	const setSearchValuesProp = useStudentsStore(
		(store) => store.setSearchValuesProp
	)
	const ValueNames = StudentsStoreType.SearchValueNames

	return function () {
		if (
			searchType == ValueNames.FullName ||
			searchType == ValueNames.TrainingName ||
			searchType == ValueNames.GroupName
		) {
			setSearchValuesProp(searchType, '')
		} else if (searchType == ValueNames.Status) {
			setSearchValuesProp(searchType, undefined)
		}
	}
}
