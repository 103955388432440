import PaymentTypes from 'parts/types/PaymentTypes'
import useLeadsStore from '../../../../LeadsTable/zustand/store'
import { LeadsStoreType } from '../../../../LeadsTable/zustand/storeTypes'

/** Формирует из списка состояний данные для формирования пунктов каскадера */
export function useGetCascaderOptions() {
	return Object.values(PaymentTypes.PaymentStatuses).map((status) => {
		return {
			value: status.status,
		}
	})
}

/** Обработчик изменения Каскадера. Новые значения записывает в Состояние */
export function useGetOnStatusChange() {
	const setSearchValuesProp = useLeadsStore(
		(store) => store.setSearchValuesProp
	)

	return function (selectedStatuses: [PaymentTypes.PaymentStatus][]) {
		const preparedStatuses = selectedStatuses.map((arr) => arr[0])

		setSearchValuesProp(
			LeadsStoreType.SearchValueNames.Statuses,
			preparedStatuses
		)
	}
}
