import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { produce } from 'immer'
import { WritingOrAudioAnswerWasSentFn } from '../../../common/useLiftViewDuration'
import { AudioBlockStateType, emptyState, getEmptyDictaphone } from './state'
import TrainingApiTypes from 'parts/requests/training/trainingApiTypes'
import trainingRequests from 'parts/requests/training/trainingRequest'
import downloadFileToAmazon from 'parts/services/downloadFileToAmazon'
import UploadFileTypes from 'parts/constants/uploadTypes'
import { RouterVarNames } from 'app/routes/special/otherRoutes'
import UrlService from 'parts/services/UrlService'

/** Хук возвращает состояние компонента AudioAnswerBlock и функции управления микрофонами */
export function useManagePlayers(
	// Запуск этой функции сообщает, что ответ был послан
	writingOrAudioAnswerWasSentFn?: WritingOrAudioAnswerWasSentFn
) {
	const params = useParams()

	const trainingIdStr = params[RouterVarNames.TrainingId]!
	const { courseId, groupId } =
		UrlService.getTrainingAndGroupIds(trainingIdStr)
	const exerciseId = params[RouterVarNames.ExerciseId]!

	// Состояние компонента AudioAnswerBlock
	const [state, setState] = useState(emptyState)

	// Была ли послана запись
	const [audioSent, setAudioSent] = useState(false)

	// Подготовить и поставить состояние компонента AudioAnswerBlock
	useEffect(function () {
		getInitialState().then((newState) => {
			setState(newState)
		})
	}, [])

	function onRecord() {
		startRecording(state, setState)
	}

	function onStopRecord() {
		stopRecording(state, setState)
	}

	function onPlay(playerId: number) {
		play(state, setState, playerId)
	}

	function onPause(playerId: number) {
		pause(state, setState, playerId)
	}

	function onDelete(playerId: number) {
		deleteAudio(state, setState, playerId)
	}

	function onSend(playerId: number) {
		sendRecordForCheck(state, playerId, groupId, parseInt(exerciseId)).then(
			() => {
				setAudioSent(true)

				// Запустить переданную функцию, которая сообщит коду выше,
				// что звуковой ответ на упражнение был записан и послан учеником.
				if (writingOrAudioAnswerWasSentFn) {
					writingOrAudioAnswerWasSentFn()
				}
			}
		)
	}

	return {
		state,
		onRecord,
		onStopRecord,
		onPlay,
		onPause,
		onDelete,
		onSend,
		audioSent,
	}
}

/** Функция возвращает объект местного Состояния блока с микрофонами */
export async function getInitialState(): Promise<AudioBlockStateType.Main> {
	const constraints = {
		audio: {
			echoCancellation: { exact: true },
		},
	}

	const stream = await navigator.mediaDevices.getUserMedia(constraints)

	return {
		dictaphone: getEmptyDictaphone(stream),
		players: [],
	}
}

/**
 * Функция запускающая запись звука
 * @param {Object} state — объект состояния компонента AudioAnswerBlock
 * @param {Function} setState — установщик нового объекта состояния компонента AudioAnswerBlock
 */
function startRecording(
	state: AudioBlockStateType.Main,
	setState: React.Dispatch<React.SetStateAction<AudioBlockStateType.Main>>
) {
	if (!state.dictaphone.stream) return

	// Создание экземпляра класса записывающего аудио
	const mediaRecorder = new MediaRecorder(state.dictaphone.stream)

	// Начать запись
	mediaRecorder.start()

	// В состоянии проигрывателя занести экземпляр класса записывающего аудио и статус, что идёт запись
	// Это приведёт к перерисовке проигрывателя
	const newState = produce(state, (draft) => {
		draft.dictaphone.mediaRecorder = mediaRecorder
		draft.dictaphone.lifeStatus = 'recording'
	})
	setState(newState)

	// Каждую секунду увеличивать время воспроизведения
	const setIntervalId = setInterval(function () {
		setState((prevState) => {
			return produce(prevState, (draft) => {
				draft.dictaphone.seconds = draft.dictaphone.seconds + 1
			})
		})
	}, 1000)

	// При остановке записи звука очистить интервал
	mediaRecorder.onstop = (event) => {
		clearInterval(setIntervalId)
	}

	// Слушатель события когда пользователь прекращает запись видео/аудио
	mediaRecorder.ondataavailable = (event) => {
		if (event.data && event.data.size > 0) {
			const blob = event.data // Blob {size: 55935, type: 'video/webm;codecs=vp9,opus'}

			// Записать блок в Состояние
			setState((prevState) => {
				return produce(prevState, (draft) => {
					draft.dictaphone.recordedBlobs = blob
				})
			})
		}
	}
}

/**
 * Остановка записи
 * @param {Object} state — объект состояния компонента AudioAnswerBlock
 * @param {Function} setState — установщик нового объекта состояния компонента AudioAnswerBlock
 */
function stopRecording(
	state: AudioBlockStateType.Main,
	setState: React.Dispatch<React.SetStateAction<AudioBlockStateType.Main>>
) {
	state.dictaphone.mediaRecorder?.stop()

	setTimeout(() => {
		setState((prevState) => {
			return produce(prevState, (draft) => {
				const { dictaphone } = draft

				// Остановить запись и в статус проигрывателя поставить 'empty' чтобы перерисовать проигрыватель
				dictaphone.lifeStatus = 'empty'
				dictaphone.seconds = 0

				if (!dictaphone.stream || !dictaphone.recordedBlobs) {
					return
				}

				// Создать проигрыватель из имеющихся данных и поставить в массив проигрывателей
				const maxPlayerId = getPlayerMaxId(draft)
				const newPlayer = createPlayerFromData(
					maxPlayerId + 1,
					dictaphone.stream,
					dictaphone.recordedBlobs
				)

				draft.players.push(newPlayer)
			})
		})
	}, 100)
}

/**
 * Функция создаёт и возвращает объект проигрывателя
 * @param {Number} id — id нового проигрывателя
 * @param {Object} mediaStream — звуковой поток
 * @param {Object} recordedBlobs — записанный звук
 */
function createPlayerFromData(
	id: number,
	mediaStream: MediaStream,
	recordedBlobs: Blob
): AudioBlockStateType.Player {
	// Перевести в audio/mpeg чтобы файл воспроизводился во всех браузерах
	const superBuffer = new Blob([recordedBlobs], {
		type: 'audio/mpeg',
	})

	// Получить ссылку на записанное аудио
	const audioURL = window.URL.createObjectURL(superBuffer)

	// Виртуальный проигрыватель чтобы была возможность в нём проиграть получившуюся запись
	const virtualPlayer = new Audio(audioURL)

	return {
		id,
		lifeStatus: 'stop',
		seconds: 0,
		virtualPlayer,
		intervalId: null,
		blob: superBuffer,
	}
}

/**
 * Обработчик кнопки воспроизведения записанного звука
 * @param {Object} state — объект состояния компонента AudioAnswerBlock
 * @param {Function} setState — установщик нового объекта состояния компонента AudioAnswerBlock
 * @param {Number} playerId — id проигрывателя
 */
function play(
	state: AudioBlockStateType.Main,
	setState: React.Dispatch<React.SetStateAction<AudioBlockStateType.Main>>,
	playerId: number
) {
	// Тут рассказано как делать звуковую волну:
	// blog.logrocket.com/audio-visualizer-from-scratch-javascript/

	const thisPlayerObj = getPlayerById(state, playerId)
	if (!thisPlayerObj) return

	// Запустить проигрывание записи
	thisPlayerObj.virtualPlayer.play()

	// Если воспроизведение завершилось, то перерисовать проигрыватель, чтобы заменить кнопку stop на play.
	thisPlayerObj.virtualPlayer.addEventListener('ended', () => {
		setState((prevState) => {
			return produce(prevState, (draft) => {
				const thisPlayerObj = getPlayerById(draft, playerId)
				if (!thisPlayerObj) return

				thisPlayerObj.lifeStatus = 'stop'

				// Очистить таймер при завершении записи чтобы счётчик не тикал после остановки
				// @ts-ignore
				clearInterval(thisPlayerObj.intervalId)
				// Обнулить значение счётчика
				thisPlayerObj.seconds = 0
			})
		})
	})

	// Каждую секунду увеличивать время воспроизведения
	const setIntervalId = setInterval(function () {
		setState((prevState) => {
			return produce(prevState, (draft) => {
				const thisPlayerObj = getPlayerById(draft, playerId)
				if (!thisPlayerObj) return

				thisPlayerObj.seconds = thisPlayerObj.seconds + 1
			})
		})
	}, 1000)

	// При начале воспроизведения перерисовать проигрыватель, чтобы заменить кнопку play на stop.
	setState((prevState) => {
		return produce(prevState, (draft) => {
			const thisPlayerObj = getPlayerById(draft, playerId)
			if (!thisPlayerObj) return

			thisPlayerObj.lifeStatus = 'play'

			thisPlayerObj.intervalId = setIntervalId
		})
	})
}

/**
 * Обработчик кнопки остановки записанного звука
 * @param {Object} state — объект состояния компонента AudioAnswerBlock
 * @param {Function} setState — установщик нового объекта состояния компонента AudioAnswerBlock
 * @param {Number} playerId — id проигрывателя
 */
function pause(
	state: AudioBlockStateType.Main,
	setState: React.Dispatch<React.SetStateAction<AudioBlockStateType.Main>>,
	playerId: number
) {
	const newState = produce(state, (draft) => {
		const thisPlayerObj = getPlayerById(draft, playerId)
		if (!thisPlayerObj) return

		thisPlayerObj.virtualPlayer.pause()

		// @ts-ignore
		clearInterval(thisPlayerObj.intervalId)
		thisPlayerObj.intervalId = null

		thisPlayerObj.lifeStatus = 'stop'
	})
	setState(newState)
}

/**
 * Возвращает максимальный идентификатор проигрывателя
 * @param {Object} state — объект состояния компонента AudioAnswerBlock
 */
function getPlayerMaxId(state: AudioBlockStateType.Main): number {
	let maxId = 0

	state.players.forEach((player) => {
		if (player.id > maxId) {
			maxId = player.id
		}
	})
	return maxId
}

/**
 * Нужно ли показывать компонент MaxPlayersHelpNote.
 * Будет показан если уже есть три микрофона с записью.
 * @param {Object} state — объект состояния компонента AudioAnswerBlock
 */
export function needToShowDictaphone(state: AudioBlockStateType.Main) {
	return state.players.length !== 3
}

/**
 * Обработчик кнопки удаления блока с записанным звуком
 * @param {Object} state — объект состояния компонента AudioAnswerBlock
 * @param {Function} setState — установщик нового объекта состояния компонента AudioAnswerBlock
 * @param {Number} playerId — id проигрывателя
 */
function deleteAudio(
	state: AudioBlockStateType.Main,
	setState: React.Dispatch<React.SetStateAction<AudioBlockStateType.Main>>,
	playerId: number
) {
	const newState = produce(state, (draft) => {
		draft.players = draft.players.filter((player) => player.id !== playerId)
	})
	setState(newState)
}

/**
 * Возвращает объект микрофона по переданному идентификатору
 * @param {Object} state — объект состояния блока с аудио
 * @param {Number} playerId — id проигрывателя
 */
function getPlayerById(state: AudioBlockStateType.Main, playerId: number) {
	return state.players.find((player) => player.id == playerId)
}

/**
 * Превращение записанного звука в файл и сохранение на Амазоне.
 * @param {Object} state — объект состояния компонента AudioAnswerBlock
 * @param {Number} playerId — id проигрывателя
 * @param {Number} groupId — id группы, к которой принадлежит ученик проходящий упражнение
 * @param {Number} exerciseId — id упражнения
 */
async function sendRecordForCheck(
	state: AudioBlockStateType.Main,
	playerId: number,
	groupId: null | number,
	exerciseId: null | number
) {
	if (!groupId || !exerciseId) return

	const player = state.players.find((player) => player.id === playerId)
	if (!player || !player.blob) return

	const recordedBlob = player.blob

	let recordedFile = new File([recordedBlob], 'audio.mpeg', {
		type: 'audio/mpeg',
	})

	let downloadedAudioUrl = ''

	// Загрузить запись на Амазон
	await downloadFileToAmazon(
		recordedFile,
		[recordedFile],
		UploadFileTypes.EXERCISE_AUDIO,
		{
			whileDownloading(percentCompleted, cancelDownloading, fileUrl) {
				downloadedAudioUrl = fileUrl
			},
		}
	)

	await sendAudioForCheck(groupId, exerciseId, downloadedAudioUrl)
}

/**
 * Отправляет адрес записанного звука в качестве результата выполнения задания
 * @param {Number} groupId — id группы, к которой принадлежит ученик проходящий упражнение
 * @param {Number} exerciseId — id упражнения
 * @param {String} answerAudioSrc — адрес файла с записью звука ответа ученика
 */
async function sendAudioForCheck(
	groupId: number,
	exerciseId: number,
	answerAudioSrc: string
) {
	const dto: TrainingApiTypes.SendAudioOrImageExerciseResultDto = {
		groupId,
		exerciseId,
		audio: answerAudioSrc,
	}
	await trainingRequests.sendAudioOrImageExerciseResult(dto)
}
