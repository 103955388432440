import { Button } from 'antd'
import portraitBlocksManager from 'pages/landing/landingConstructor/sections/portraitSection/PortraitBlocksManager/PortraitBlocksManager'

// Две кнопки создающие новый блок или в первой или во второй колонке.
// Появляются если в одной или двух колонках нет блоков.
function AddNewBlockSection() {
	const leftBlocks = portraitBlocksManager.useGetColumnBlocks(1)
	const rightBlocks = portraitBlocksManager.useGetColumnBlocks(2)

	const addNewBlockOnColumn_1 = portraitBlocksManager.useGetAddNewBlock(
		null,
		1
	)
	const addNewBlockOnColumn_2 = portraitBlocksManager.useGetAddNewBlock(
		null,
		2
	)

	return (
		<div>
			{!leftBlocks.length && (
				<Button onClick={addNewBlockOnColumn_1}>
					Добавить блок в первую колонку
				</Button>
			)}
			{!rightBlocks.length && (
				<Button onClick={addNewBlockOnColumn_2}>
					Добавить блок во вторую колонку
				</Button>
			)}
		</div>
	)
}

export default AddNewBlockSection
