import { produce } from 'immer'
import TrainingEntityTypes from '../types/TrainingEntityTypes'

// Методы для работы с курсами
const LessonAdminService = {
	/**
	 * Находит в курсе упражнение и возвращает
	 * @param lessons — массив уроков
	 * @param {Number} lessonId — id урока
	 */
	findLessonByIdInLessons(
		lessons: TrainingEntityTypes.LessonAdmin[],
		lessonId: number
	): null | TrainingEntityTypes.LessonAdmin {
		for (let i = 0; i < lessons.length; i++) {
			const lesson = lessons[i]
			if (lesson.id !== lessonId) continue

			return lesson
		}

		return null
	},

	/**
	 * Получает массив уроков и урок с обновлёнными данными и ставит его вместо старого урока
	 * @param lessons — массив уроков
	 * @param updatedLesson — обновлённый урок
	 */
	setUpdatedLessonInLessons(
		lessons: TrainingEntityTypes.LessonAdmin[],
		updatedLesson: TrainingEntityTypes.LessonAdmin
	) {
		const lessonIdx = lessons.findIndex(
			(lesson) => lesson.id === updatedLesson.id
		)

		return produce(lessons, (draftLessons) => {
			draftLessons[lessonIdx] = updatedLesson
		})
	},

	/**
	 * Находит в курсе упражнение и возвращает
	 * @param {Object} training — объект курса
	 * @param {Number} lessonId — id урока
	 */
	findLessonByIdInTraining(
		training: undefined | null | TrainingEntityTypes.TrainingAdmin,
		lessonId: number
	): null | TrainingEntityTypes.LessonAdmin {
		if (!training) return null

		return this.findLessonByIdInLessons(training.lessons, lessonId)
	},

	/**
	 * Возвращает максимальный порядковый номер урока
	 * @param {Object} training — объект курса
	 */
	getMaxLessonOrder(lessons: TrainingEntityTypes.LessonAdmin[]): number {
		let maxOrderNum = 0

		for (let i = 0; i < lessons.length; i++) {
			const lesson = lessons[i]

			if (lesson.order > maxOrderNum) {
				maxOrderNum = lesson.order
			}
		}

		return maxOrderNum
	},

	/**
	 * Создаёт и помещает новый урок в курс.
	 * @param lessons — массив уроков
	 * @param newLessonProps — некоторые значения свойств, которые должны быть поставлены вместо значений по умолчанию
	 */
	addNewLessonToLessons(
		lessons: TrainingEntityTypes.LessonAdmin[],
		newLessonProps?: Partial<TrainingEntityTypes.BareLesson>
	) {
		const nextOrder = this.getMaxLessonOrder(lessons) + 1

		let newLesson: TrainingEntityTypes.BareLesson = {
			name: 'Название  урока',
			description: 'Описание урока',
			cover: '',
			order: nextOrder,
			exercises: [],
			isFree: false,
		}

		if (newLessonProps) {
			newLesson = Object.assign(newLesson, newLessonProps)
		}

		return produce(lessons, (draft) => {
			// @ts-ignore
			lessons.push(newLesson)
		})
	},

	/**
	 * Создаёт и помещает новый урок в курс.
	 * @param {Object} training — объект курса
	 * @param newLessonProps — некоторые значения свойств, которые должны быть поставлены вместо значений по умолчанию
	 */
	addNewLessonToTraining(
		training: TrainingEntityTypes.TrainingAdmin,
		newLessonProps?: Partial<TrainingEntityTypes.BareLesson>
	) {
		const updatedLessons = this.addNewLessonToLessons(
			training.lessons,
			newLessonProps
		)

		return produce(training, (draft) => {
			draft.lessons = updatedLessons
		})
	},

	/**
	 *
	 * @param {Object} training — объект курса
	 * @param {Number} lessonId — id урока
	 */
	getAdminLessonById(
		training: TrainingEntityTypes.TrainingAdmin,
		lessonId: number
	) {
		return training.lessons.find((lesson) => lesson.id == lessonId)
	},

	/**
	 * Удаляет урок из курса по идентификатору
	 * @param {Object} training — объект курса
	 * @param {Number} lessonId — id урока
	 */
	deleteLessonByIdInLessons(
		lessons: TrainingEntityTypes.LessonAdmin[],
		lessonId: number
	) {
		return lessons.filter((lesson) => {
			return lesson.id !== lessonId
		})
	},

	/**
	 * Удаляет урок из курса по порядковому номеру
	 * @param {Object} training — объект курса
	 * @param {Number} lessonIdx — порядковый номер урока
	 */
	deleteLessonByIdx(
		training: TrainingEntityTypes.TrainingAdmin,
		lessonIdx: number
	) {
		return produce(training, (draft) => {
			draft.lessons.splice(lessonIdx, 1)
		})
	},

	/**
	 * Перемещает упражнение вверх или вниз по массиву упражнений
	 * @param {Object} training — объект курса
	 * @param {Number} lessonIdx — порядковый номер урока, у которого нужно изменить порядок
	 * @param {String} direction — направление перемещения упражнения
	 */
	moveLessonUpOrDownInLessons(
		lessons: TrainingEntityTypes.LessonAdmin[],
		lessonIdx: number,
		direction: 'up' | 'down'
	) {
		// Переместить упражнение по массиву упражнений
		const updatedLessons = produce(lessons, (draftLessons) => {
			const lesson = draftLessons[lessonIdx]

			draftLessons.splice(lessonIdx, 1)

			if (direction == 'up') {
				draftLessons.splice(lessonIdx - 1, 0, lesson)
			} else {
				draftLessons.splice(lessonIdx + 1, 0, lesson)
			}
		})

		// Заново проставить свойство order чтобы сервер отдавал их в правильном порядке
		return this.actualizeLessonsOrderProp(updatedLessons)
	},

	/**
	 * Перемещает упражнение вверх или вниз по массиву упражнений
	 * @param {Object} training — объект курса
	 * @param {Number} lessonIdx — порядковый номер урока, у которого нужно изменить порядок
	 * @param {String} direction — направление перемещения упражнения
	 */
	moveLessonUpOrDownInTraining(
		training: TrainingEntityTypes.TrainingAdmin,
		lessonIdx: number,
		direction: 'up' | 'down'
	) {
		const updatedLessons = this.moveLessonUpOrDownInLessons(
			training.lessons,
			lessonIdx,
			direction
		)

		return { ...training, lessons: updatedLessons }
	},

	/**
	 * Функция возвращает булево значение возможно ли изменять порядок следования урока в массиве уроков
	 * @param {Object} training — объект курса
	 * @param {Number} lessonIdx — порядковый номер урока, у которого нужно изменить порядок
	 * @param {String} direction — направление перемещения упражнения
	 */
	isLessonMovingAllowed(
		lessons: TrainingEntityTypes.LessonAdmin[],
		lessonIdx: number,
		direction: 'up' | 'down'
	): boolean {
		const lessonsCount = lessons.length

		if (direction == 'down' && lessonIdx < lessonsCount - 1) {
			return true
		} else if (direction == 'up' && lessonIdx > 0) {
			return true
		}

		return false
	},

	/**
	 * Пробегает по всем урокам упражнения и ставит свойство order в порядке следования уроков
	 * @param lessons — массив уроков
	 */
	actualizeLessonsOrderProp(
		lessons: TrainingEntityTypes.LessonAdmin[]
	): TrainingEntityTypes.LessonAdmin[] {
		return produce(lessons, (draftLessons) => {
			for (let i = 0; i < draftLessons.length; i++) {
				draftLessons[i].order = i
			}
		})
	},

	/**
	 * Обновляет значение свойства в уроке
	 * @param training — данные курса
	 * @param lessonId — id урока
	 * @param propName — название изменяемого свойства
	 * @param propValue — значение изменяемого свойства
	 */
	updatePropInLessonForAdmin<N extends keyof TrainingEntityTypes.LessonAdmin>(
		training: TrainingEntityTypes.TrainingAdmin,
		lessonId: number,
		propName: N,
		propValue: TrainingEntityTypes.LessonAdmin[N]
	) {
		return produce(training, (draft) => {
			const lesson = this.findLessonByIdInTraining(draft, lessonId)
			if (!lesson) return draft

			lesson[propName] = propValue
		})
	},
}

export default LessonAdminService
