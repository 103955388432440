import React, { useRef } from 'react'
import cn from 'classnames'
import PaymentTypes from 'parts/types/PaymentTypes'
import { useSetTextInEditableElementInMount } from 'parts/utils/hooks/hooks'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'
import { keyDownPriceHandler, useGetChangeMainPrice } from './fn/changePrice'
import './Price.scss'

type PricePropType = {
	tariff: TariffsStoreType.Tariff
}

// Основная цена курса
function Price(props: PricePropType) {
	const { tariff } = props
	const { price, oldPrice, currency } = tariff.mainPrice

	const priceTextElemRef = useRef(null)
	useSetTextInEditableElementInMount(tariff.mainPrice.price, priceTextElemRef)

	const oldPriceTextElemRef = useRef(null)
	useSetTextInEditableElementInMount(
		tariff.mainPrice.oldPrice,
		oldPriceTextElemRef
	)

	const changePrice = useGetChangeMainPrice(tariff.innerId, 'price')
	const changeOldPrice = useGetChangeMainPrice(tariff.innerId, 'oldPrice')

	return (
		<div className="tariff-card-prices">
			<div className="tariff-card-price tariff-card-price--current">
				<p
					contentEditable
					suppressContentEditableWarning
					className={cn(
						'tariff-card-price__price',
						!price && 'tariff-card-price__price--empty'
					)}
					onInput={changePrice}
					onKeyDown={keyDownPriceHandler}
					ref={priceTextElemRef}
				/>
				<p className="tariff-card-price__currency">
					{PaymentTypes.Currencies[currency].sign}
				</p>
			</div>
			<div className="tariff-card-price tariff-card-price--old">
				<p
					contentEditable
					suppressContentEditableWarning
					className={cn(
						'tariff-card-price__price',
						!oldPrice && 'tariff-card-price__price--empty'
					)}
					onInput={changeOldPrice}
					onKeyDown={keyDownPriceHandler}
					ref={oldPriceTextElemRef}
				/>
				<p className="tariff-card-price__currency">
					{PaymentTypes.Currencies[currency].sign}
				</p>
			</div>
		</div>
	)
}

export default Price
