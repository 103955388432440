import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import BulletsSection from 'pages/landing/landingConstructor/sections/programSection/bulletsSections/BulletsSection/BulletsSection'
import 'pages/landing/landingConstructor/sections/programSection/bulletsSections/BulletsSections/BulletsSections.scss'

function BulletsSections() {
	const landing = useLandingConstructorStore((store) => store.landing)
	const { bulletsBlocks } = landing.sections.program

	return (
		<div className="land-const-prog-bullets-sections">
			{bulletsBlocks.map((bulletBlock, i) => {
				return (
					<BulletsSection
						bulletBlockIdx={i}
						bulletBlock={bulletBlock}
						key={bulletBlock.id}
					/>
				)
			})}
		</div>
	)
}

export default BulletsSections
