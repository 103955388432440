import { useEffect } from 'react'
import { ScrollDataLSPropName } from './scrollManager'
import usePagesScrollStore from './zustand/store'

/** Получает сохранённые данные о прокрутках из LS при загрузке страницы и сохраняет их в Хранилище */
export function useGetScrollDataFromLocalStorageAndSaveToStore() {
	const updateStore = usePagesScrollStore((store) => store.updateStore)

	useEffect(function () {
		const scrollDataStr = localStorage.getItem(ScrollDataLSPropName)
		if (!scrollDataStr) return

		updateStore({ pagesScroll: JSON.parse(scrollDataStr) })
	}, [])
}
