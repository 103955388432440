import { Button } from 'antd'
import {
	ExerciseType,
	GapsExerciseFormStateType,
	TextStateItemType,
} from './fn/stateType'
import { closeTextBlock } from './fn/changeForm'
import Editor from './Editor'
import { MinusCircleOutlined } from '@ant-design/icons'

type TextBlocksProps = {
	exerciseType: ExerciseType
	formState: GapsExerciseFormStateType
}

// Блоки с редакторами текста
function TextBlocks(props: TextBlocksProps) {
	const { exerciseType, formState } = props

	return (
		<div className="exercise-gaps-in-words-text-blocks">
			{formState.texts.map((textBlock) => {
				return (
					<TextBlock
						exerciseType={exerciseType}
						showCloseButton={formState.texts.length > 1}
						textBlock={textBlock}
						key={textBlock.id}
					/>
				)
			})}
		</div>
	)
}

export default TextBlocks

type TextBlockProps = {
	exerciseType: ExerciseType
	showCloseButton: boolean // Показывать ли кнопку удаления блока
	textBlock: TextStateItemType // Объекта с данными для редактора
}

// Блок редактора пропусков и кнопки создания пропуска
function TextBlock(props: TextBlockProps) {
	const { exerciseType, showCloseButton, textBlock } = props

	return (
		<div className="exercise-gaps-in-words-text-block">
			<div className="exercise-gaps-in-words-text-block__left">
				<label className="exercise-gaps-in-words__label exercise-gaps-in-words__label--top-margin">
					Упражнение
				</label>
				<Editor exerciseType={exerciseType} textBlock={textBlock} />
			</div>
			{showCloseButton && (
				<div className="exercise-gaps-in-words-text-block__right">
					<Button
						shape="circle"
						icon={<MinusCircleOutlined />}
						onClick={(event) => {
							closeTextBlock(event, textBlock.id)
						}}
					/>
				</div>
			)}
		</div>
	)
}
