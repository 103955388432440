import { useEffect } from 'react'
import dayjs from 'dayjs'
import { FormInstance } from 'antd'
import useStudentStore from '../../../main/zustand/store'
import EntityTypes from 'parts/types/EntityTypes'
import useAccountStore from '../../zustand/store'
import { setErrorsToFields } from 'parts/utils/form'

export enum FieldNames {
	FirstName = 'firstName',
	LastName = 'lastName',
	Birthday = 'birthday',
	Gender = 'gender',
	City = 'city',
	Country = 'country',
	Phone = 'phone',
	Instagram = 'instagram',
}

export type FormValuesType = {
	[FieldNames.FirstName]?: string
	[FieldNames.LastName]?: string
	[FieldNames.Birthday]?: dayjs.Dayjs
	[FieldNames.Gender]?: EntityTypes.Gender
	[FieldNames.City]?: string
	[FieldNames.Country]?: string
	[FieldNames.Phone]?: string
	[FieldNames.Instagram]?: string
}

/** Возвращает первоначальные значения полей формы редактирования ученика. */
export function useGetInitialValues(): FormValuesType {
	const student = useStudentStore((store) => store.student)!

	const birthday = student?.birthday ? dayjs(student.birthday) : undefined

	return {
		[FieldNames.FirstName]: student.firstName,
		[FieldNames.LastName]: student.lastName,
		[FieldNames.Birthday]: birthday,
		[FieldNames.Gender]: student.sex,
		[FieldNames.City]: student.city,
		[FieldNames.Country]: student.country,
		[FieldNames.Phone]: student.phone,
		[FieldNames.Instagram]: student.instagram,
	}
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useAccountStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
