import { useEffect } from 'react'
import { FormInstance } from 'antd'
import { setErrorsToFields } from 'parts/utils/form'
import useAccountStore from '../../../profile/zustand/store'

export enum FieldNames {
	Groups = 'groups',
}

export type FormValuesType = {
	// Массив вида [['582'], ['583', '98'], ['583', '97']].
	// Первый элемент — это id курса, во втором id группы.
	// Если выбраны все группы курса, то в массиве будет только одно число.
	// Если выбраны несколько групп курса, то id курса будет повторяться в нескольких массивах.
	[FieldNames.Groups]: [string, string][]
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useAccountStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
