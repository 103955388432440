import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from 'ui/Loader/Loader'
import GroupsTable, {
	GroupsTableView,
} from 'widgets/groups/trainingGroupsTable/GroupsTable/GroupsTable'
import GroupsApiTypes from 'parts/requests/group/groupsApiTypes'
import EntityTypes from 'parts/types/EntityTypes'
import { useFillInStore } from '../../zustand/fillStore/fillInStore'
import useGroupsStore from 'widgets/groups/trainingGroupsTable/zustand/store'

type GetGroupsTableRouterReturn = {
	status: 'loading' | 'error' | 'noGroups' | 'hasGroups' | 'unknown'
	element: JSX.Element
}

/**
 * Хук возвращающий статус компонента GroupsTable и сам компонент в зависимости от статуса.
 * Если autoLoad в false, то следует передать данные для отрисовки в groups.
 * @param trainingId — id курса, которому принадлежат группы
 * @param tableView — вид таблицы с группами
 * @param autoLoad — требуется ли автоматическая загрузка данных. Если нет, то следует передать в свойстве groups список групп.
 * @param groups — массив групп для отрисовки. Будет поставлен если autoLoad в false.
 */
export function useGetGroupsTableRouter(
	trainingId: number,
	tableView?: GroupsTableView,
	autoLoad = true,
	groups?: EntityTypes.GroupData[] | GroupsApiTypes.UserGroup[]
): GetGroupsTableRouterReturn {
	const { t } = useTranslation()

	useFillInStore(trainingId, autoLoad, groups)

	const trainingsGroups = useGroupsStore((store) => store.trainingsGroups)

	return useMemo(
		function () {
			const training = trainingsGroups.find(
				(training) => training.trainingId === trainingId
			)

			if (!training) {
				return {
					status: 'loading',
					element: <Loader />,
				}
			} else if (training.dataLoadingStatus === 'error') {
				return {
					status: 'error',
					element: <p>Произошла ошибка при загрузке данных.</p>,
				}
			}

			if (training.groups.length) {
				return {
					status: 'hasGroups',
					element: (
						<GroupsTable
							tableView={tableView}
							trainingId={trainingId}
						/>
					),
				}
			}

			return {
				status: 'noGroups',
				element: <p>Групп нет.</p>,
			}
		},
		[trainingsGroups]
	)
}
