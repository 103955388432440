import { useCallback } from 'react'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { exercisesAsideManager } from '../../ExercisesAside/zustand/exercisesAsideManager'
import { exercisesFormsManager } from '../../exercisesFormsManager'

/**
 * Хук отдаёт функция открывающую модальное окно с формой для создания или редактирования упражнения
 * @param {Number} exerciseId — id упражнения
 * @param {String} exerciseType — тип открываемого упражнения
 */
export function useGetEditExercise(
	exerciseId: number,
	exerciseType: TrainingEntityTypes.ExerciseTypes
) {
	return useCallback(
		function () {
			exercisesFormsManager.updateStore({ exerciseId, exerciseType })
		},
		[exerciseId, exerciseType]
	)
}

/**
 * Хук отдаёт функцию открывающую панель со списком упражнений.
 * @param {Number} exerciseId — id упражнения
 */
export function useGetOpenExercisesAsideFn(exerciseId: number) {
	return useCallback(function () {
		// Открыть меню с выбором создаваемого упражнения
		exercisesAsideManager.open()

		exercisesFormsManager.updateStore({
			insertBeforeExerciseId: exerciseId,
		})
	}, [])
}
