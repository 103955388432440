import useLeadsStore from '../../../../LeadsTable/zustand/store'
import { LeadsStoreType } from '../../../../LeadsTable/zustand/storeTypes'

export enum FieldsNames {
	PriceFrom = 'priceFrom',
	PriceTo = 'priceTo',
}

export interface FormValues {
	[FieldsNames.PriceFrom]: number
	[FieldsNames.PriceTo]: number
}

/** Обработчик отправки формы поиска по Инстаграму */
export function useGetOnPhoneChange() {
	const setSearchValuesProp = useLeadsStore(
		(store) => store.setSearchValuesProp
	)

	return function (values: FormValues) {
		setSearchValuesProp(
			LeadsStoreType.SearchValueNames.PriceFrom,
			values[FieldsNames.PriceFrom] * 100
		)

		setSearchValuesProp(
			LeadsStoreType.SearchValueNames.PriceTo,
			values[FieldsNames.PriceTo] * 100
		)
	}
}
