import React from 'react'
import { Button } from 'antd'
import LangService from 'parts/i18n/LangService'

// Кнопка переключающая язык
function SwitchLanguageButton() {
	const toggleLang = LangService.useGetToggleLang()

	return (
		<Button onClick={toggleLang} size="small">
			{LangService.getCurrentLangFromLocalStorage()}
		</Button>
	)
}

export default SwitchLanguageButton
