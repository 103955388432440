import useStudentsStore from '../../studentsTableAdmin/zustand/store'

/** Возвращает функцию получающую номер страницы с которой данные должны отображаться */
export function useGetChangeItemsPerPage() {
	const updateStore = useStudentsStore((store) => store.updateStore)

	return function (current: number, newItemsPerPage: number) {
		updateStore({ studentsPerPage: newItemsPerPage })
	}
}
