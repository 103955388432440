import $api from '../http'
import AnswerFeedTypes from './answerFeedApiTypes'

const answerFeedRequests = {
	// Получение ленты всех ответов
	getAllAnswersFeed(params?: AnswerFeedTypes.GetAnswerFeedParams) {
		let paramsStr = createAnswerFeedParamsStr(params)

		return $api.get<AnswerFeedTypes.GetAnswerFeed>(
			'/api/answer-feed/' + paramsStr
		)
	},

	// Получение ленты ответов конкретной группы
	getGroupAnswersFeed(
		groupId: number,
		params?: AnswerFeedTypes.GetAnswerFeedParams
	) {
		let paramsStr = createAnswerFeedParamsStr(params)

		return $api.get<AnswerFeedTypes.GetAnswerFeed>(
			'/api/answer-feed/' + groupId + '/' + paramsStr
		)
	},

	answerFeedAddReply(dto: AnswerFeedTypes.AnswerFeedAddReplyDto) {
		return $api.post<AnswerFeedTypes.AnswerFeedAddReply>(
			'/api/answer-feed/reply',
			dto
		)
	},

	// Удаление
	deleteReply(replyId: number) {
		return $api.delete<AnswerFeedTypes.DeleteReply>(
			`/api/answer-feed/${replyId}`
		)
	},

	// Получение счётчика непроверенных ответов
	getUnansweredFeedCount() {
		return $api.get<AnswerFeedTypes.GetUnansweredFeedCount>(
			'/api/answer-feed/unanswered-feed-count/'
		)
	},
}

export default answerFeedRequests

function createAnswerFeedParamsStr(
	params?: AnswerFeedTypes.GetAnswerFeedParams
) {
	let paramsStr = ''

	if (params && Object.keys(params).length) {
		let paramsParts = []

		if (params.offset !== undefined) {
			paramsParts.push('offset=' + params.offset)
		}

		if (params.limit !== undefined) {
			paramsParts.push('limit=' + params.limit)
		}

		paramsStr += '?' + paramsParts.join('&')
	}

	return paramsStr
}
