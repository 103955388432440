import AppUrls from 'parts/constants/pageUrl'
import useLessonsListAdminStore from '../../../../zustand/store'

/**
 * Возвращает функцию, переадресовывающую на страницу урока
 * @param lessonId — id урока
 */
export function useGetLessonPageUrl(lessonId: number) {
	const trainingId = useLessonsListAdminStore((state) => state.trainingId)

	return trainingId
		? AppUrls.Lesson(trainingId, lessonId).url
		: AppUrls.SchoolLesson(lessonId).url
}
