import { useTranslation } from 'react-i18next'
import Loader from 'ui/Loader/Loader'
import LessonsListAdmin from './lessonsList/LessonsList/LessonsListAdmin'
import { useFillInStore } from './zustand/fillStore/fillInStore'
import useLessonsListAdminStore from './zustand/store'
import { LessonsListStoreType } from './zustand/storeTypes'

type GetLessonsAdminRouterReturn = {
	status: LessonsListStoreType.DataLoadingStatus
	element: JSX.Element
}

/**
 * Хук возвращающий статус компонента LessonsListAdmin и сам компонент в зависимости от статуса
 * @param trainingId — id курса. Если требуются уроки курса, то передаётся идентификатор. Если не передан, то будут загружены уроки школы
 */
export function useGetLessonsAdminRouter(
	trainingId?: number
): GetLessonsAdminRouterReturn {
	const { t } = useTranslation()
	useFillInStore(trainingId)

	const dataLoadingStatus = useLessonsListAdminStore(
		(state) => state.dataLoadingStatus
	)

	if (dataLoadingStatus == 'loading') {
		return {
			status: dataLoadingStatus,
			element: <Loader />,
		}
	} else if (dataLoadingStatus == 'error') {
		return {
			status: dataLoadingStatus,
			element: (
				<p>{t('adminTraining.lessonsListErrorWhileLoadingData')}</p>
			),
		}
	}

	return {
		status: dataLoadingStatus,
		element: <LessonsListAdmin />,
	}
}
