import { Button, Input, Upload } from 'antd'
import { LinkOutlined, PaperClipOutlined } from '@ant-design/icons'
import { RcFile } from 'antd/lib/upload/interface'
import { useTranslation } from 'react-i18next'
import { FormQuestionStateItemType } from './fn/stateTypes'
import {
	DownloadingFileBlock,
	SuccessDownloadedFileBlock,
} from '../common/FormFileInput/FormFileInput'
import {
	beforeUploadFile,
	onChangeVideoLinkInput,
	removeDownloadedFile,
	setFileDownloadedStatusToDefault,
} from './fn/changeForms'

type VideoBlockProps = {
	questionData: FormQuestionStateItemType
}

// Содержимое вкладки для загрузки видео
export function VideoBlock(props: VideoBlockProps) {
	const { questionData } = props

	return (
		<>
			<VideoLinkInput questionData={questionData} />
			<DownloadVideoInputVariants questionData={questionData} />
		</>
	)
}

type VideoLinkInputProps = {
	questionData: FormQuestionStateItemType
}

// Поле для ввода адреса видео
export function VideoLinkInput(props: VideoLinkInputProps) {
	const { questionData } = props
	const { videoLink, status } = questionData.files.video

	return (
		<Input
			className="exercise-test-video-link-input"
			value={videoLink}
			onChange={(event) => onChangeVideoLinkInput(event, questionData.id)}
			disabled={status !== 'empty'}
			prefix={<LinkOutlined />}
		/>
	)
}

type DownloadVideoInputVariantsProps = {
	questionData: FormQuestionStateItemType
}

// Компонент кнопки для загрузки видео-файлов. Выглядит по-разному в зависимости от этапа загрузки
export function DownloadVideoInputVariants(
	props: DownloadVideoInputVariantsProps
) {
	const { questionData } = props
	const { status, progress, fileName } = questionData.files.video

	const { t } = useTranslation()

	// Если ещё не указывали видео для загрузки
	if (status == 'empty') {
		return <DownloadVideoInput questionData={questionData} />
	}
	// Если идёт загрузка указанного видео
	else if (status == 'downloading') {
		return (
			<DownloadingFileBlock
				fileName={fileName}
				progress={progress}
				cancelDownloading={() =>
					setFileDownloadedStatusToDefault(questionData.id, 'video')
				}
			/>
		)
	}
	// Успешная загрузка видео
	else if (status == 'success') {
		return (
			<SuccessDownloadedFileBlock
				fileName={fileName}
				removeFile={() => removeDownloadedFile(questionData, 'video')}
			/>
		)
	}
	// Неудачная загрузка видео
	else {
		return (
			<div className="exercise-test-video-error-upload">
				<p className="exercise-test-video-error-upload__text">
					Не удалось загрузить файл {fileName}
				</p>
				<DownloadVideoInput questionData={questionData} />
			</div>
		)
	}
}

type DownloadVideoInputProps = {
	questionData: FormQuestionStateItemType
}

// Кнопка загрузки видео
function DownloadVideoInput(props: DownloadVideoInputProps) {
	const { questionData } = props

	return (
		<Upload
			name="file"
			showUploadList={false}
			beforeUpload={(file: RcFile, FileList: RcFile[]) => {
				return beforeUploadFile(
					file,
					FileList,
					questionData.id,
					'video'
				)
			}}
			accept=".mp4"
		>
			<Button
				icon={<PaperClipOutlined />}
				type="link"
				disabled={!!questionData.files.video.videoLink}
			>
				Загрузить из файла
			</Button>
		</Upload>
	)
}
