const studentRu = {
	// ТАБЛИЦА С ИСТОРИЕЙ ДЕЙСТВИЙ ПОЛЬЗОВАТЕЛЯ
	// Текст «Журнал действий» в заголовке таблицы
	historyTableStatusJournal: 'Журнал действий',
	// Статус «Активен» в заголовке таблицы
	historyTableStatusActive: 'Активен',
	// Заголовок ряда Дата
	historyTableHeaderDate: 'Дата',
	// Заголовок ряда Действие
	historyTableHeaderAction: 'Действие',
	// Статус действия: Вход в учетную запись
	historyTableActionNameLogin: 'Вход в учетную запись',
	// Статус действия: Оплата курса
	historyTableActionNamePayment: 'Оплата курса',
	// Статус действия: Неудачный вход
	historyTableActionNameWrongPassword: 'Неудачный вход',
	// Статус действия: Сброс пароля
	historyTableActionNameRecovery: 'Сброс пароля',
	// Статус действия: Отключение ученика от эфира
	historyTableActionNameLiveParticipantDisconnected:
		'Отключение ученика от эфира',
	// Статус действия: Подключение ученика к эфиру
	historyTableActionNameLiveParticipantConnected:
		'Подключение ученика к эфиру',

	// СТАТЬЯ СО СПИСКОМ ДЕЙСТВИЙ СОВЕРШЁННЫХ ПОЛЬЗОВАТЕЛЕМ (История)
	// Сообщение, что ученик не совершил ещё ни одного действия
	historyArticleNoActions: 'Ученик ещё не совершил ни одного действия.',

	// МЕНЮ С ВЫБОРОМ ДЕЙСТВИЙ НАД ВЫБРАННЫМ УЧЕНИКОМ
	// Текст на кнопке открывающей форму создания нового заказа
	pageHeaderOrderButton: 'Заказ',
	// Текст на кнопке открывающей выпадающий список с действиями
	pageHeaderActionsButton: 'Действия',
	// Отправить доступ
	pageHeaderActionsMenuSendAccess: 'Отправить доступ',
	// Разблокировать
	pageHeaderActionsMenuUnBan: 'Разблокировать',
	// Блокировать
	pageHeaderActionsMenuBan: 'Блокировать',
	// Выйти из всех устройств
	pageHeaderActionsMenuExit: 'Выйти из всех устройств',
	// Редактировать
	pageHeaderActionsMenuEdit: 'Редактировать',
	// Удалить
	pageHeaderActionsMenuRemove: 'Удалить',
	// Сообщение при удачной блокировке пользователя
	pageHeaderActionsUserBannedSuccessful: 'Пользователь заблокирован',
	// Сообщение при ошибке при блокировке пользователя
	pageHeaderActionsUserBannedError: 'Не удалось заблокировать пользователя',
	// Сообщение при удачной разблокировке пользователя
	pageHeaderActionsUserUnBannedSuccessful: 'Пользователь разблокирован',
	// Сообщение при ошибке при разблокировке пользователя
	pageHeaderActionsUserUnBannedError:
		'Не удалось разблокировать пользователя',
	// Сообщение при удачной разблокировке пользователя
	pageHeaderActionsUserExitSuccessful: 'Пользователь вышел из всех устройств',
	// Сообщение при ошибке при разблокировке пользователя
	pageHeaderActionsUserExitError:
		'Не удалось выгнать пользователя из всех устройств',

	// МОДАЛЬНОЕ ОКНО УДАЛЕНИЯ ПОЛЬЗОВАТЕЛЯ
	// Заголовок модального окна
	deleteStudentModalHeader: 'Вы действительно хотите удалить ученика?',
	// Текст модального окна
	deleteStudentModalText: 'Это действие нельзя отменить.',
	// Сообщение об успешном удалении ученика
	deleteStudentModalSuccess: 'Пользователь удалён',
	// Сообщение о неудачном удалении ученика
	deleteStudentModalFail: 'Пользователь удалён',

	// ЭКРАН С СООБЩЕНИЕМ ОБ ОШИБКЕ С ОТОБРАЖЕНИЕМ УЧЕНИКА
	// Заголовок экрана про несуществующего ученика
	noStudentScreenHeader: 'Ученика не существует',
	// Текст на экране про несуществующего ученика
	noStudentScreenText: 'Он был удалён или введён неправильный адрес',
	noStudentScreenGoToStudentsPageBtn: 'Перейти в список всех учеников',

	// МОДАЛЬНОЕ ОКНО НОВОГО ЗАКАЗА
	// Заголовок модального окна
	makeOrderModalHeader: 'Новый заказ',
	// Заголовок блока с покупкой
	makeOrderModalPurchaseBlockHeader: 'Покупка',
	// Подпись поля «Группа»
	makeOrderModalFieldGroupLabel: 'Группа',
	// Сообщение об обязательном поля для поля «Группа»
	makeOrderModalFieldGroupRequiredError: 'Укажите группу',
	// Подпись поля «Дата покупки»
	makeOrderModalFieldPurchaseDateLabel: 'Дата покупки',
	// Сообщение об обязательном поля для поля «Дата покупки»
	makeOrderModalFieldPurchaseDateRequiredError: 'Укажите дату покупки',
	// Подпись поля «Способ оплаты»
	makeOrderModalFieldPaymentSystemLabel: 'Способ оплаты',
	// Сообщение об обязательном поля для поля «Способ оплаты»
	makeOrderModalFieldPaymentSystemRequiredError: 'Укажите способ оплаты',
	// Подпись поля «Валюта»
	makeOrderModalFieldCurrencyLabel: 'Валюта',
	// Сообщение об обязательном поля для поля «Валюта»
	makeOrderModalFieldCurrencyRequiredError: 'Укажите валюту',
	// Подпись поля «Стоимость»
	makeOrderModalFieldAmountLabel: 'Стоимость',
	// Сообщение об обязательном поля для поля «Стоимость»
	makeOrderModalFieldAmountRequiredError: 'Укажите стоимость',
	// Подпись поля «Куратор»
	makeOrderModalFieldCuratorLabel: 'Куратор',
	// Пункт поля «Куратор» «Не выбран»
	makeOrderModalFieldCuratorNotSelectedItem: 'Не выбран',
	// Флаг «Выдать доступ в личный кабинет»
	makeOrderModalGetAccessCheckbox: 'Выдать доступ в личный кабинет',
	// Кнопка создания нового заказа по данным введённым в форму
	makeOrderModalSubmitButton: 'Сохранить',
	// Кнопка отмены создания нового заказа
	makeOrderModalCancelButton: 'Отмена',
	// Сообщение про невозможность создать заказ из-за серверной ошибки
	makeOrderModalCannotMakeOrder: 'Не удалось сделать заказ.',
	// Сообщение про невозможность добавить оплату
	makeOrderModalCannotCreatePayment: 'Не удалось добавить оплату',
	// Сообщение про невозможность добавить ученика куратору
	makeOrderModalCannotAddStudentToCurator:
		'Не удалось добавить ученика куратору',

	// ТАБЛИЦА ЗАКАЗОВ УЧЕНИКА
	// Заголовок ряда Курс
	ordersTableHeaderTraining: 'Курс',
	// Заголовок ряда Группа
	ordersTableHeaderGroup: 'Группа',
	// Заголовок ряда Дата
	ordersTableHeaderDate: 'Дата',
	// Заголовок ряда Стоимость
	ordersTableHeaderPrice: 'Стоимость',
	// Заголовок ряда Статус
	ordersTableHeaderStatus: 'Статус',
	// Статус покупки: Покупка
	ordersTableStatusPayment: 'Покупка',
	// Сообщение «Не куплено ни одного курса.»
	ordersTableNoPayments: 'Не куплено ни одного курса.',

	// ФОРМА С ИЗМЕНЕНИЕМ ДАННЫХ УЧЕНИКА
	// Заголовок группы полей формы
	accountFormBlockHeader: 'Учётная запись',
	// Текст Зарегистрирован после которого идёт дата регистрации ученика
	accountFormRegisteredText: 'Зарегистрирован',
	// Подпись поля «Имя»
	accountFormFieldFirstNameLabel: 'Имя',
	// Подпись поля «Фамилия»
	accountFormFieldLastNameLabel: 'Фамилия',
	// Подпись поля «День рождения»
	accountFormFieldBirthdayLabel: 'День рождения',
	// Подпись поля «Пол»
	accountFormFieldGenderLabel: 'Пол',
	// Переключатель «Мужской» поля «Пол»
	accountFormFieldGenderRadioMan: 'Мужской',
	// Переключатель «Женский» поля «Пол»
	accountFormFieldGenderRadioWoman: 'Женский',
	// Кнопка «Изменение почты»
	accountFormChangeEmailButton: 'Изменение почты...',
	// Кнопка «Изменение пароля...»
	accountFormChangePasswordButton: 'Изменение пароля...',
	// Подпись поля «Город»
	accountFormFieldCityLabel: 'Город',
	// Подпись поля «Страна»
	accountFormFieldCountryLabel: 'Страна',
	// Подпись поля «Телефон»
	accountFormFieldPhoneLabel: 'Телефон',
	// Подпись поля «Инстаграм»
	accountFormFieldInstagramLabel: 'Инстаграм',
	// Кнопка «Удалить учётную запись»
	accountFormDeleteAccountButton: 'Удалить учётную запись',
	// Кнопка сохранения данных формы изменения данных ученика
	accountFormDeleteSaveButton: 'Сохранить',
	// Сообщение об успешном изменении данных при сохранении формы
	accountFormFormSavedSuccessfully: 'Сохранить',

	// СТРАНИЦА ПОСЕЩЕНИЙ УЧЕНИКА
	// Сообщение про то, что ученик ещё не добавлен ни в одну группу
	studentVisitsArticleNoGroups: 'Нет ни одной группы.',

	// КАРТОЧКА ПОСЕЩЕНИЯ УЧЕНИКА
	// Строка «Осталось выполнить» после которой идёт количество непройденных упражнений и уроков
	studentVisitCardLastTasks: 'Осталось выполнить',
	// Строка «Группа завершена»
	studentVisitCardGroupFinished: 'Группа завершена',
	// Строка «Старт занятий» после которой идёт дата следующего занятия
	studentVisitCardStart: 'Старт занятий',
	// Строка «Все уроки открыты»
	studentVisitCardAllLessonsOpened: 'Все уроки открыты',
	// Строка «Следующий урок» после которой идёт дата следующего урока
	studentVisitCardNextLesson: 'Следующий урок',
}

export default studentRu
