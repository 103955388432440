import { FormInstance, message } from 'antd'
import { AxiosError } from 'axios'
import Site from 'parts/constants/site'
import { useTranslation } from 'react-i18next'
import { FormValues } from './form'
import useAddSchoolModalStore from '../zustand/store'
import { schoolQuery } from 'parts/requests/school/schoolQuery'
import SchoolApiTypes from 'parts/requests/school/schoolApiTypes'
import ServerTypes from 'parts/types/ServerTypes'
import { AddSchoolModalStoreType } from '../zustand/storeTypes'
import { MySchoolsListController } from 'widgets/MySchoolsList/MySchoolsListController'

/**
 * Хук возвращающий обработчик отправки формы
 * @param form — ссылка на форму
 */
export function useGetOnSubmit(form: FormInstance) {
	const updateStore = useAddSchoolModalStore((state) => state.updateStore)

	const updateDataInSchoolsList = MySchoolsListController.useGetUpdate()

	// Объект с методом mutate для создания запроса
	const addSchoolMutation = schoolQuery.addSchoolByAuthorizedUser.useMutation(
		{
			onMutate: getMutateOnBefore(updateStore),
			onError: useGetMutateOnError(updateStore),
			onSuccess: useGetMutateOnSuccess(
				updateStore,
				form,
				updateDataInSchoolsList
			),
			onSettled: getMutateOnAfter(updateStore),
		}
	)

	return function (values: FormValues) {
		addSchoolMutation.mutate(createSchoolDto(values))
	}
}

/**
 * Функция, выполняемая до запроса на добавление сотрудника в группы
 * @param updateStore
 */
function getMutateOnBefore(updateStore: AddSchoolModalStoreType.UpdateStore) {
	return function () {
		// Поставить статус загрузки
		updateStore({ isSubmitting: true })
	}
}

/**
 * Функция, выполняемая при появлении ошибки после запроса на добавление сотрудника в группы
 * @param updateStore — функция изменяющая объект состояния модального окна
 */
function useGetMutateOnError(updateStore: AddSchoolModalStoreType.UpdateStore) {
	const { t } = useTranslation()

	return function (err: unknown) {
		const error = err as AxiosError<ServerTypes.ErrorResponse>

		if (error.response) {
			// Поставить данные ошибки в Состояние, чтобы показать их в форме
			updateStore({ formErrors: error.response.data })
		}

		message.error(t('mySchools.addSchoolModalCannotCreateSchool'))
	}
}

/**
 * Функция, выполняемая при успешном запросе на добавление сотрудника в группы
 * @param updateStore — функция изменяющая объект состояния модального окна
 * @param form — ссылка на форму
 * @param updateDataInSchoolsList — функция, помечающая данные списка школ неактуальными. После этого React Query автоматически скачает новый список
 */
function useGetMutateOnSuccess(
	updateStore: AddSchoolModalStoreType.UpdateStore,
	form: FormInstance,
	updateDataInSchoolsList: () => void
) {
	const { t } = useTranslation()

	return function () {
		// Закрыть модальное
		updateStore({ isOpen: false })
		message.success(t('mySchools.addSchoolModalSchoolCreated'))

		// Пометить список школ неактуальным.
		// После этого React Query автоматически скачает новый список
		updateDataInSchoolsList()

		form.resetFields()
	}
}

/**
 * Функция, выполняемая после запроса на добавление сотрудника в группы
 * @param updateStore — функция изменяющая объект состояния модального окна
 */
function getMutateOnAfter(updateStore: AddSchoolModalStoreType.UpdateStore) {
	return function () {
		// Убрать статус загрузки
		updateStore({ isSubmitting: false })
	}
}

/**
 * Формирует DTO для отправки запроса
 * @param {Array} values — массив значений полей формы
 */
function createSchoolDto(
	values: FormValues
): SchoolApiTypes.AddSchoolByAuthorizedUserDto {
	return {
		name: values.schoolName,
		domain: values.domain.toLowerCase() + '.' + Site.rootDomain,
	}
}
