import { useEffect } from 'react'
import { groupQuery } from 'parts/requests/group/groupQuery'
import useGroupStore from '../store'

/**
 * Скачивает группe и ставит в Состояние группу с переданным идентификатором
 * @param groupId — id группы
 */
export function useFillInStore(groupId: number | string) {
	const updateState = useGroupStore((state) => state.updateStore)

	// Получить все группы курса
	const { data: groupRes } = groupQuery.getGroupById(groupId).useQuery({})

	useEffect(
		function () {
			if (!groupRes) return

			const group = groupRes.data

			updateState({
				group,
			})
		},
		[groupRes]
	)
}
