import { useIsCurrentPage } from './fn/tabs-logic'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import './Tabs.scss'

export type PageHeaderTab = {
	label: string
	address: string
}

type HeaderTabsProps = {
	dataItems: PageHeaderTab[]
}

function HeaderTabs(props: HeaderTabsProps) {
	const { dataItems } = props

	return (
		<div className="header-tabs">
			{dataItems.map((dataItem) => {
				return <Tab dataItem={dataItem} key={dataItem.address} />
			})}
		</div>
	)
}

export default HeaderTabs

type TabProps = {
	dataItem: PageHeaderTab
}

function Tab(props: TabProps) {
	const { dataItem } = props

	const isCurrent = useIsCurrentPage(dataItem.address)

	return (
		<Link
			className={cn(
				'header-tabs__tab',
				isCurrent && 'header-tabs__tab--current'
			)}
			to={dataItem.address}
		>
			{dataItem.label}
		</Link>
	)
}
