import { RcFile } from 'antd/lib/upload/interface'
import { useTranslation } from 'react-i18next'
import {
	DownloadingFileBlock,
	ExerciseFileDragger,
	SuccessDownloadedFileBlock,
} from '../common/FormFileInput/FormFileInput'
import {
	beforeUploadFile,
	removeDownloadedFile,
	setFileDownloadedStatusToDefault,
} from './fn/submit'
import { FormMatchStateItemType } from './fn/formState'

type DownloadImageProps = {
	matchData: FormMatchStateItemType
}

// Различные варианты вида кнопки загрузки файла
export function DownloadImage(props: DownloadImageProps) {
	const { matchData } = props
	const { status, progress, fileName, link } = matchData.image

	const { t } = useTranslation()

	// Если ещё не указывали файл для загрузки
	if (status == 'empty') {
		return (
			<ExerciseFileDragger
				acceptFileExe=".jpg,.jpeg,.png"
				beforeUpload={(file: RcFile, FileList: RcFile[]) => {
					return beforeUploadFile(file, FileList, matchData.id)
				}}
				size="small"
			/>
		)
	} else if (status == 'downloading') {
		// Если идёт загрузка указанного файла
		return (
			<DownloadingFileBlock
				fileLink={link}
				fileName={fileName}
				progress={progress}
				cancelDownloading={() =>
					setFileDownloadedStatusToDefault(matchData.id)
				}
			/>
		)
	} else if (status == 'success') {
		// Успешная загрузка файла
		return (
			<SuccessDownloadedFileBlock
				fileName={fileName}
				removeFile={() => removeDownloadedFile(matchData)}
			/>
		)
	} else {
		// Неудачная загрузка файла
		return (
			<p className="exercise-test-media-upload-error__text">
				Не удалось загрузить файл {fileName}
			</p>
		)
	}
}
