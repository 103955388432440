import {
	checkSlots,
	showRightAnswers,
} from '../../DescriptionImageExerciseView/fn/bottom'
import useExerciseStore, {
	ExerciseStateType,
} from '../../zustand/exerciseState'

export function useGetCheckExerciseFn() {
	const saveStoreForRestore = useExerciseStore(
		(store) => store.saveStoreForRestore
	)
	const statistics = useExerciseStore((store) => store.statistics)
	const attempts = useExerciseStore((store) => store.attempts)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		const $texts = document.getElementsByClassName(
			'exercise-desc-image-content__exercise-wrapper'
		)[0] as HTMLDivElement
		if (!$texts) return

		// Если попытки закончились, то показать правильные варианты
		if (attempts <= 1) {
			setTimeout(() => {
				showRightAnswers($texts)
			}, 0)
		}

		// Проверить введённые ответы
		const {
			allSlotsFilledRight,
			successAnswersCounter,
			totalSlotsCounter,
		} = checkSlots($texts)

		updateStore({
			stage: 'Checked',
			statistics: getStats(statistics, totalSlotsCounter),
			totalSlots: totalSlotsCounter,
			saveStoreForRestore: saveStoreForRestore + 1,
			attempts: attempts - 1,
		})
	}
}

function getStats(
	currentStatistics: ExerciseStateType.Statistic[],
	totalSlotsCounter: number
): ExerciseStateType.Statistic[] {
	if (currentStatistics.length === 3) {
		return currentStatistics
	}

	return [
		...currentStatistics,
		{
			passedQuestions: totalSlotsCounter,
		},
	]
}
