const exercisesRu = {
	// СТРАНИЦА УПРАЖНЕНИЯ ДЛЯ УЧЕНИКА
	// exerciseArticle: '',

	// КНОПКИ УПРАВЛЕНИЯ УПРАЖНЕНИЕМ СНИЗУ
	// Кнопка перехода к следующему упражнению
	controlBtnNextExercise: 'Следующее',
	// Кнопка перехода пропуска упражнения
	controlBtnSkipExercise: 'Пропустить',
	// Кнопка прохождения упражнения с новой попытки
	controlBtnNewAttempt: 'Ещё раз',
	// Кнопка перезапуска упражнения
	controlBtnPassAgain: 'Пройти ещё раз',
	// Кнопка сообщающая серверу о выполнении упражнения
	controlBtnDone: 'Выполнено',
	// Кнопка проверяющая упражнение
	controlBtnCheck: 'Проверить',
	// Кнопка показывающая результат выполнения упражнения со статистикой
	controlBtnResult: 'Показать результат',

	// ПАНЕЛЬ СО СПИСКОМ УПРАЖНЕНИЙ УРОКА НА СТРАНИЦЕ УПРАЖНЕНИЯ
}

export default exercisesRu
