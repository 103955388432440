import { useEffect, useState } from 'react'

/** Возвращает количество секунд сколько упражнение было открыто. */
export function useGetExerciseDuration() {
	const [duration, setDuration] = useState(0)

	useEffect(function () {
		const intervalId = setInterval(() => {
			setDuration((duration) => duration + 1)
		}, 1000)

		return function () {
			clearInterval(intervalId)
		}
	}, [])

	return duration
}
