import { useMutation, useQuery } from 'react-query'
import TrainingApiTypes from 'parts/requests/training/trainingApiTypes'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import trainingRequests from './trainingRequest'
import { MutationOptions, QueryOptions } from '../common'

export const trainingQuery = {
	// Получение списка курсов администратором. Ученик получает курсы по другому маршруту.
	getTrainingsForAdmin: {
		key: 'getTrainingsForAdmin',
		useQuery(
			options: QueryOptions<TrainingApiTypes.GetTrainingsForAdmin> = {}
		) {
			return useQuery({
				queryKey: [this.key],
				queryFn: trainingRequests.getTrainingsForAdmin,
				...options,
			})
		},
	},

	// Получение списка курсов учеником. Администратор получает курсы по другому маршруту.
	getTrainingsForStudent: {
		key: 'getTrainingsForStudent',
		useQuery(
			options: QueryOptions<TrainingApiTypes.GetTrainingsForStudent> = {}
		) {
			return useQuery({
				queryKey: [this.key],
				queryFn: trainingRequests.getAllTrainingsForStudent,
				...options,
			})
		},
	},

	// Получение курса администратором
	getTrainingForAdmin(trainingId: number | string) {
		return {
			key: 'getTrainingForAdmin-' + trainingId,
			useQuery(
				options: QueryOptions<TrainingEntityTypes.TrainingAdmin> = {}
			) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () =>
						trainingRequests.getTrainingForAdmin(trainingId),
					...options,
				})
			},
		}
	},

	// Получение курса администратором
	getTrainingForStudent(
		trainingId: number | string,
		groupId: number | string
	) {
		return {
			key: 'getTrainingForStudent-' + trainingId + '-' + groupId,
			useQuery(
				options: QueryOptions<TrainingApiTypes.GetTrainingForStudent> = {}
			) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () =>
						trainingRequests.getTrainingForStudent(
							trainingId,
							groupId
						),
					...options,
				})
			},
		}
	},

	// Добавление нового курса или обновление существующего если передан id
	createOrUpdateTraining: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(trainingRequests.createTraining, options)
		},
	},

	// Добавление нового курса или обновление существующего если передан id
	updateTraining: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(trainingRequests.updateTraining, options)
		},
	},

	// Удаление курса
	deleteTraining: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(trainingRequests.deleteTraining, options)
		},
	},

	// Получение идентификатора прогресса прохождения упражнения учеником.
	// Метод нужен, чтобы сообщить серверу, что курс начали проходить
	openFirstLesson: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(trainingRequests.openFirstLesson, options)
		},
	},

	// Получение тарифов по адресу одностраничника
	getTariffsByUrl: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(trainingRequests.getTariffsByUrl, options)
		},
	},

	// Получение данных одностраничника по его адресу
	getLandingByUrl: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(trainingRequests.getLandingByUrl, options)
		},
	},

	// Отправка ответа на звуковое упражнение или упражнение с картинками.
	sendAudioOrImageExerciseResult: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(
				trainingRequests.sendAudioOrImageExerciseResult,
				options
			)
		},
	},

	// Получение данных одностраничника для продажи указанного курса для редактирования (используется в конструкторе одностраничника)
	getDraftTrainingLanding(trainingId: number | string) {
		return {
			key: 'getDraftTrainingLanding-' + trainingId,
			useQuery(options: QueryOptions<TrainingApiTypes.GetLanding> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () =>
						trainingRequests.getDraftTrainingLanding(trainingId),
					...options,
				})
			},
		}
	},

	// Сохранение обновлённых данных одностраничника для продажи указанного курса
	updateDraftTrainingLanding: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(
				trainingRequests.updateDraftTrainingLanding,
				options
			)
		},
	},

	// Получение опубликованных данных одностраничника для продажи указанного курса (используется на странице одностраничника)
	getPublishedTrainingLanding(trainingId: number | string) {
		return {
			key: 'getPublishedTrainingLanding',
			useQuery(options: QueryOptions<TrainingApiTypes.GetLanding> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () =>
						trainingRequests.getPublishedTrainingLanding(
							trainingId
						),
					...options,
				})
			},
		}
	},

	// Публикация редактируемого одностраничника для продажи указанного курса (берутся уже сохранённые данные для редактирования и копируются в ячейку с данными опубликованного одностраничника)
	publishTrainingLanding: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(trainingRequests.publishTrainingLanding, options)
		},
	},

	// Получение идентификатора прогресса прохождения упражнения учеником.
	// Метод нужен, чтобы сообщить серверу, что курс начали проходить
	sendViewed: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(trainingRequests.sendViewed, options)
		},
	},
}
