import { Tag } from 'antd'
import EntityTypes from 'parts/types/EntityTypes'
import { useTranslation } from 'react-i18next'

type StatusTagProps = {
	status: EntityTypes.AnswerStatus
}

function StatusTag(props: StatusTagProps) {
	const { status } = props

	const { t } = useTranslation()

	return (
		<>
			{status === EntityTypes.AnswerStatus.Checking && (
				<Tag color="#98A2B2">
					{t('answersFeed.answerBlockExerciseStatusChecking')}
				</Tag>
			)}
			{status === EntityTypes.AnswerStatus.Checked && (
				<Tag color="#66B77D">
					{t('answersFeed.answerBlockExerciseStatusChecked')}
				</Tag>
			)}
			{status === EntityTypes.AnswerStatus.NotChecked && (
				<Tag color="#DB2777">
					{t('answersFeed.answerBlockExerciseStatusNotChecked')}
				</Tag>
			)}
		</>
	)
}

export default StatusTag
