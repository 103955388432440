import AddTariffButton from 'pages/landing/landingConstructor/tariffs/content/AddTariffButton/AddTariffButton'
import Cards from 'pages/landing/landingConstructor/tariffs/content/Cards/Cards'
import TariffPanel from 'pages/landing/landingConstructor/tariffs/panel/Panel/Panel'
import { saveTariffsOnServerAfterChanges } from 'pages/landing/landingConstructor/tariffs/general/PageParts/fn/saveTariffs'
// import { useSetOverflowHiddenToPageSkeleton } from './fn/setOverflowHidden'
import 'pages/landing/landingConstructor/tariffs/general/PageParts/PageParts.scss'

// Обёртка страницы с двумя областями: карточками и панелью настройки активной карточки
function PageParts() {
	saveTariffsOnServerAfterChanges()
	// useSetOverflowHiddenToPageSkeleton()

	return (
		<section className="pay-cards-article">
			<div className="pay-cards-article__content">
				<Cards />
				<AddTariffButton />
			</div>
			<TariffPanel />
		</section>
	)
}

export default PageParts
