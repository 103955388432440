import AppUrls from 'parts/constants/pageUrl'
import { HeaderRouteType } from 'ui/pageHeader/PageHeader/fn/types'
import useGroupStore from '../../zustand/store'
import UrlService from 'parts/services/UrlService'

export function useGetBreadcrumbs(): HeaderRouteType[] {
	const trainingId = UrlService.useGetTrainingId()

	const trainingName = useGetTrainingName()

	const breadcrumbs: HeaderRouteType[] = [
		{
			path: AppUrls.Trainings().url,
			breadcrumbName: AppUrls.Trainings().name,
		},
	]

	if (trainingName) {
		breadcrumbs.push(
			{
				path: AppUrls.Training(trainingId).url,
				breadcrumbName: trainingName,
			},
			{
				path: AppUrls.Training_Groups(trainingId).url,
				breadcrumbName: AppUrls.Training_Groups('').name,
			}
		)
	}

	return breadcrumbs
}

function useGetTrainingName() {
	const groupStore = useGroupStore((state) => state)

	if (!groupStore.trainingAdmin) return null

	return groupStore.trainingAdmin.name
}
