import React from 'react'
import WaysHeader from 'pages/landing/landingConstructor/sections/waysSections/WaysHeader/WaysHeader'
import { WaysBlocks } from 'pages/landing/landingConstructor/sections/waysSections/WaysBlocks/WaysBlocks'
import 'pages/landing/landingConstructor/sections/waysSections/WaysSection/WaysSection.scss'

// Секция одностраничника с частозадаваемыми вопросами
function WaysSection() {
	return (
		<div className="land-const-ways-section">
			<WaysHeader />
			<WaysBlocks />
		</div>
	)
}

export default WaysSection
