import { produce } from 'immer'
import { RcFile } from 'antd/lib/upload/interface'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import {
	AudioFile,
	FileType,
	ImageFile,
	FormAnswerStateItemType,
	FormQuestionStateItemType,
	TestExerciseFormStateType,
	VideoFile,
} from './stateTypes'
import downloadFileToAmazon from 'parts/services/downloadFileToAmazon'
import UploadFileTypes from 'parts/constants/uploadTypes'
import { getFormState, setFormState } from '../../common/exerciseFormCommonFunc'
import { createFormQuestionStateItem } from './state'
import {
	getAnswerById,
	getQuestionById,
	isAtLeastAnswerChecked,
	isQuestionHasAtLeastTwoAnswersWithText,
} from './check'

/**
 * Обработчик изменения поля «Название теста»
 * @param {String} newValue — строка HTML из редактора
 */
export function onChangeNameInput(newValue: string) {
	const formState = getFormState<TestExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		draft.nameInput.value = newValue
	})

	setFormState(newState)
}

/**
 * Обработчик изменения поля «Задание»
 * @param {String} newValue — строка HTML из редактора
 */
export function onChangeTaskInput(newValue: string) {
	const formState = getFormState<TestExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		draft.taskInput.value = newValue
	})

	setFormState(newState)
}

/**
 * Обработчик изменения полей «Одиночный выбор» и «Множественный выбор»
 * @param {Object} event — объект события
 * @param {String} radioType — поле к которому предназначается обработчик: one (Одиночный выбор) или multiple (Множественный выбор)
 * @param {Number} questionId — id объекта вопроса
 */
export function onChangeAnswerRadios(
	event: CheckboxChangeEvent,
	radioType: 'one' | 'multiple',
	questionId: number
) {
	const formState = getFormState<TestExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const question = getQuestionById(draft, questionId)

		if (!question) return draft

		question.multipleSelect = radioType != 'one'

		if (radioType == 'one') {
			question.answers.forEach((answer) => {
				answer.correct = false
			})
		}
	})

	setFormState(newState)
}

/**
 * Обработчик изменения поля «Вопрос»
 * @param {String} newValue — строка HTML из редактора
 * @param {Number} questionId — id объекта вопроса
 */
export function onChangeQuestionTextInput(
	newValue: string,
	questionId: number
) {
	const formState = getFormState<TestExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const inputValue = newValue
		const question = getQuestionById(draft, questionId)

		if (question) {
			question.questionText = inputValue

			if (inputValue) {
				question.noQuestionTextError = false
				draft.isFormInvalid = false
			}
		}
	})

	setFormState(newState)
}

/**
 * Обработчик изменения поля ссылки на видео с внешнего ресурса в каждом вопросе
 * @param {Object} event — объект события
 * @param {Number} questionId — id объекта вопроса
 */
export function onChangeVideoLinkInput(
	event: React.ChangeEvent<HTMLInputElement>,
	questionId: number
) {
	const formState = getFormState<TestExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const question = getQuestionById(draft, questionId)

		if (question) {
			question.files.video.videoLink = event.target.value
		}

		return draft
	})

	setFormState(newState)
}

/**
 * Обработчик изменения активной вкладки выбора типа загружаемого файла
 * @param {String} tabName — имя вкладки (из поля key объекта настройки)
 * @param {Number} questionId — id объекта вопроса
 */
export function onChangeMediaTabs(tabName: FileType, questionId: number) {
	const formState = getFormState<TestExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const question = getQuestionById(draft, questionId)

		if (question) {
			question.mediaType = tabName
		}

		return draft
	})

	setFormState(newState)
}

/**
 * Обработчик изменения поля «Вопрос»
 * @param {Object} event — объект события
 * @param {Number} questionId — id объекта вопроса
 * @param {Number} answerId — id объекта ответа на вопрос
 */
export function onChangeAnswerTextInput(
	event: React.ChangeEvent<HTMLInputElement>,
	questionId: number,
	answerId: number
) {
	const formState = getFormState<TestExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const answer = getAnswerById(draft, questionId, answerId)
		if (!answer) return draft

		answer.text = event.target.value

		// Если в массиве вопросов есть как минимум два ответа с написанным текстом...
		if (isQuestionHasAtLeastTwoAnswersWithText(draft, questionId)) {
			// ...убрать ошибку про не один не отмеченный ответ в качестве правильного
			const question = getQuestionById(draft, questionId)
			if (question) {
				question.noAnswerTextError = false
			}

			// Убрать флаг сообщающий, что форма заполнена не правильно
			draft.isFormInvalid = false
		}
	})

	setFormState(newState)
}

/**
 * Обработчик изменения флага отмечающий правильный вариант ответа
 * @param {Object} event — объект события
 * @param {Number} questionId — id объекта вопроса
 * @param {Number} answerId — id объекта ответа на вопрос
 * @param {String} inputType — тип поля, по которому щелкнули
 */
export function onChangeRightAnswer(
	event: CheckboxChangeEvent,
	questionId: number,
	answerId: number,
	inputType: 'checkbox' | 'radio'
) {
	const formState = getFormState<TestExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const question = getQuestionById(draft, questionId)
		if (!question) return draft

		if (inputType == 'radio') {
			question.answers.forEach((answer) => {
				answer.correct = false
			})
		}

		const answer = getAnswerById(draft, questionId, answerId)
		if (!answer) return draft

		// Отметить флаг
		answer.correct = event.target.checked

		// Если в массиве вопросов отмечен как минимум 1 вопрос в качестве правильного...
		if (isAtLeastAnswerChecked(draft, questionId)) {
			// ...убрать ошибку про не один не отмеченный ответ в качестве правильного
			const question = getQuestionById(draft, questionId)
			if (question) {
				question.noRightAnswerCheckedError = false
			}

			// Убрать флаг сообщающий, что форма заполнена не правильно
			draft.isFormInvalid = false
		}
	})

	setFormState(newState)
}

/**
 * Функция создаёт новый вариант ответа в объекте вопроса
 * @param {Number} questionId — id объекта вопроса
 */
export function addNewAnswer(questionId: number) {
	const formState = getFormState<TestExerciseFormStateType>()

	let maxAnswerId = 0

	const questionObj = getQuestionById(formState, questionId)
	if (!questionObj) return

	questionObj.answers.forEach((answer) => {
		if (answer.id > maxAnswerId) {
			maxAnswerId = answer.id
		}
	})

	const newAnswerObj: FormAnswerStateItemType = {
		id: ++maxAnswerId,
		text: '',
		correct: false,
	}

	const newState = produce(formState, (draft) => {
		const questionObj = getQuestionById(draft, questionId)
		if (!questionObj) return draft

		questionObj.answers.push(newAnswerObj)
	})

	setFormState(newState)
}

/**
 * Функция удаляет ответ в объекте вопроса
 * @param {Number} questionId — id объекта вопроса
 * @param {Number} answerId — id объекта ответа
 */
export function removeAnswer(questionId: number, answerId: number) {
	const formState = getFormState<TestExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const questionObj = getQuestionById(draft, questionId)
		if (!questionObj) return draft

		questionObj.answers = questionObj.answers.filter((answer) => {
			return answer.id !== answerId
		})
	})

	setFormState(newState)
}

/**
 * Функция удаляет ответ в объекте вопроса
 * @param {Number} questionId — id объекта вопроса
 */
export function removeQuestion(questionId: number) {
	const formState = getFormState<TestExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const questionObj = getQuestionById(draft, questionId)
		if (!questionObj) return draft

		draft.questions = draft.questions.filter((question) => {
			return question.id !== questionId
		})
	})

	setFormState(newState)
}

/** Обработчик кнопки создания нового вопроса */
export function addNewQuestion() {
	const formState = getFormState<TestExerciseFormStateType>()

	let maxQuestionId = 0

	formState.questions.forEach((question) => {
		if (question.id > maxQuestionId) {
			maxQuestionId = question.id
		}
	})

	const newQuestion = createFormQuestionStateItem(++maxQuestionId)

	const newState = produce(formState, (draft) => {
		draft.questions.push(newQuestion)
	})

	setFormState(newState)
}

/**
 * Функция загружает файл, получает его адрес на сервере и все данные записывает в местное Хранилище
 * @param {Object} file — данные о загружаемом файле
 * @param {Array} FileList
 * @param {Number} questionId — id объекта вопроса
 * @param {String} fileType — тип файла: audio, video или image
 */
export async function beforeUploadFile(
	file: RcFile,
	FileList: RcFile[],
	questionId: number,
	fileType: FileType
) {
	let mediaType:
		| typeof UploadFileTypes.EXERCISE_AUDIO
		| typeof UploadFileTypes.EXERCISE_VIDEO
		| typeof UploadFileTypes.EXERCISE_IMAGES =
		UploadFileTypes.EXERCISE_AUDIO

	if (fileType == 'video') mediaType = UploadFileTypes.EXERCISE_VIDEO
	else if (fileType == 'image') mediaType = UploadFileTypes.EXERCISE_IMAGES

	await downloadFileToAmazon(file, FileList, mediaType, {
		beforeDownloading(fileName: string) {
			// Поставить в состояние аудио-блока статус загрузки файла
			changeDownloadFileState(questionId, fileType, {
				status: 'downloading',
				progress: 0,
				fileName,
			})
		},
		whileDownloading(percentCompleted, cancelDownloading, fileUrl: string) {
			// Если статус загружаемого файла не равен 'downloading', то есть его изменили, то прекратить загрузку
			const questionBlock = getQuestionById(
				getFormState<TestExerciseFormStateType>(),
				questionId
			)

			if (
				questionBlock &&
				questionBlock.files[fileType].status !== 'downloading'
			) {
				cancelDownloading()
			}

			// Поставить значение загруженных процентов в Состояние
			changeDownloadFileState(questionId, fileType, {
				progress: percentCompleted,
				link: fileUrl,
			})
		},
		onSuccess(fileName: string, url: string) {
			changeDownloadFileState(questionId, fileType, {
				link: url,
				status: 'success',
				fileName,
			})
		},
		onError(fileName: string) {
			changeDownloadFileState(questionId, fileType, {
				status: 'error',
				fileName,
			})
		},
	})
}

/**
 * Обновляет переданные свойства в объекте Состояния загружаемого файла.
 * @param {Number} questionId — id объекта вопроса
 * @param {String} fileType — тип файла: audio, video или image
 * @param {Object} newDownloadFileProps — объект данных Состояния загружаемого файла
 */
function changeDownloadFileState(
	questionId: number,
	fileType: FileType,
	newDownloadFileProps: Partial<AudioFile | VideoFile | ImageFile>
) {
	const formState = getFormState<TestExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const question = getQuestionById(draft, questionId)

		if (question) {
			if (fileType == 'audio') {
				question.files.audio = Object.assign(
					question.files.audio,
					newDownloadFileProps
				)
			} else if (fileType == 'video') {
				question.files.video = Object.assign(
					question.files.video,
					newDownloadFileProps
				)
			} else if (fileType == 'image') {
				question.files.image = Object.assign(
					question.files.image,
					newDownloadFileProps
				)
			}
		}
	})

	setFormState(newState)
}

/**
 * Функция ставит статус загружаемого файла в значение empty.
 * Это действие дополнительно выполняется после прекращения загрузки файла.
 * @param {Number} questionId — id объекта вопроса
 * @param fileType
 */
export function setFileDownloadedStatusToDefault(
	questionId: number,
	fileType: FileType
) {
	changeDownloadFileState(questionId, fileType, { status: 'empty' })
}

/**
 * Обработчик нажатия на кнопку удаления загруженного файла
 * @param {Object} questionData — id блока c данными вопроса
 * @param {String} fileType — тип файла: audio, video или image
 */
export function removeDownloadedFile(
	questionData: FormQuestionStateItemType,
	fileType: FileType
) {
	const formState = getFormState<TestExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const question = getQuestionById(draft, questionData.id)
		if (!question) return draft

		question.files[fileType].link = ''
		question.files[fileType].status = 'empty'
		question.files[fileType].progress = 0
		question.files[fileType].fileName = ''
	})

	setFormState(newState)
}
