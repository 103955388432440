import React, { MouseEventHandler } from 'react'
import { Button, Radio, Switch } from 'antd'
import { RcFile } from 'antd/lib/upload/interface'
import { useTranslation } from 'react-i18next'
import { ModalLayout } from 'ui/ModalLayout/ModalLayout'
import useExercisesListAdminStore from '../../zustand/store'
import {
	ExerciseType,
	MediaExerciseFormStateType,
	beforeUploadFile,
	isDownloadingFile,
	addNewMediaBlock,
	submitHandler,
	useSetFormData,
	setFileDownloadedStatusToDefault,
	onChangeAnswerRadios,
	onChangeTranscriptToggle,
	onChangeTaskInput,
	onChangeTranscriptInput,
	closeMediaBlock,
} from './fn/MediaForm-func'
import { PlusCircleOutlined } from '@ant-design/icons'
import { FormFileStateItemType } from './fn/imageFormState'
import {
	DownloadingFileBlock,
	ExerciseFileDragger,
	SuccessDownloadedFileBlock,
} from '../common/FormFileInput/FormFileInput'
import ExerciseFormFooter from '../common/ExerciseFormFooter/ExerciseFormFooter'
import HtmlEditor from 'ui/HTMLEditor/HTMLEditor'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import './MediaForm.scss'

type MediaFormProps = {
	saveExercise: (args: TrainingEntityTypes.ExerciseItem) => void // Функция сохраняющая данные упражнения на сервере и в Хранилище
	closeModalFn: MouseEventHandler<HTMLElement> // Функция закрытия модального окна
	exerciseType: ExerciseType
}

// Форма составления звукового-упражнения и упражнения с картинкой
function MediaForm(props: MediaFormProps) {
	const { saveExercise, closeModalFn, exerciseType } = props

	const { t } = useTranslation()
	useSetFormData(exerciseType)

	const formState = useExercisesListAdminStore(
		(store) => store.formData
	) as MediaExerciseFormStateType
	if (!formState) return null

	const modalTitle =
		exerciseType == 'audio' ? 'Добавление аудио' : 'Добавление изображения'

	return (
		<ModalLayout
			key="1"
			title={modalTitle}
			cancelBtn={false}
			isModalVisible
			closeModalFn={closeModalFn}
			footer={null}
		>
			<TaskInput formState={formState} />
			<MediaBlocks exerciseType={exerciseType} />
			<AddMediaBlockButton />
			<AnswerRadios />
			<TranscriptToggle />
			<TranscriptField />
			<ExerciseFormFooter
				formState={formState}
				saveExercise={saveExercise}
				closeModalFn={closeModalFn}
				isSubmitButtonDisabled={
					isDownloadingFile() || formState.isFormInvalid
				}
				submitHandler={() => submitHandler(saveExercise)}
			/>
		</ModalLayout>
	)
}

export default MediaForm

type TaskInputProps = {
	formState: MediaExerciseFormStateType
}

// Поле с текстом задания
function TaskInput(props: TaskInputProps) {
	const { formState } = props

	return (
		<div className="exercise-media-task__wrapper">
			<label className="exercise-media__label">Задание</label>
			<HtmlEditor
				initialValue={formState.taskInput.value}
				onChangeValue={onChangeTaskInput}
			/>
		</div>
	)
}

type MediaBlocksProps = {
	exerciseType: ExerciseType
}

function MediaBlocks(props: MediaBlocksProps) {
	const { exerciseType } = props

	const { t } = useTranslation()

	const formState = useExercisesListAdminStore(
		(store) => store.formData
	) as MediaExerciseFormStateType
	if (!formState) return null

	const rows = formState.files.map((mediaData) => {
		return (
			<DownloadInputVariants
				key={mediaData.id}
				mediaData={mediaData}
				exerciseType={exerciseType}
			/>
		)
	})

	return (
		<>
			<label className="exercise-media__label">Загрузка файла</label>
			<div className="exercise-media-blocks">{rows}</div>
			{formState.noFileHasDownloadedError && (
				<p className="exercise-media__input-error">
					Загрузите как минимум один файл.
				</p>
			)}
		</>
	)
}

type DownloadInputVariantsProps = {
	mediaData: FormFileStateItemType
	exerciseType: ExerciseType
}

// Различные варианты вида кнопки загрузки файла
function DownloadInputVariants(props: DownloadInputVariantsProps) {
	const { mediaData, exerciseType } = props
	const { status, progress, fileName, link } = mediaData

	const { t } = useTranslation()
	const acceptFileExe = exerciseType == 'audio' ? '.mp3' : '.jpg,.jpeg,.png'

	// Если ещё не указывали файл для загрузки
	if (status == 'empty') {
		return (
			<>
				<ExerciseFileDragger
					acceptFileExe={acceptFileExe}
					beforeUpload={(file: RcFile, FileList: RcFile[]) => {
						return beforeUploadFile(
							file,
							FileList,
							mediaData.id,
							exerciseType
						)
					}}
				/>
			</>
		)
	}
	// Если идёт загрузка указанного файла
	else if (status == 'downloading') {
		return (
			<DownloadingFileBlock
				fileLink={link}
				fileName={fileName}
				progress={progress}
				cancelDownloading={() =>
					setFileDownloadedStatusToDefault(mediaData.id)
				}
			/>
		)
	}
	// Успешная загрузка файла
	else if (status == 'success') {
		return (
			<SuccessDownloadedFileBlock
				fileName={fileName}
				removeFile={() => closeMediaBlock(mediaData.id)}
			/>
		)
	}
	// Неудачная загрузка файла
	else {
		return (
			<p className="exercise-media-error-upload__text">
				Не удалось загрузить файл {fileName}
			</p>
		)
	}
}

// Кнопка добавления нового блока с файлом
function AddMediaBlockButton() {
	const { t } = useTranslation()

	return (
		<div className="exercise-media-add-media-block">
			<Button onClick={() => addNewMediaBlock()}>
				<PlusCircleOutlined width={17} height={17} /> Добавить файл
			</Button>
		</div>
	)
}

// Переключатели «Аудио ответ» и «Письменный ответ»
function AnswerRadios() {
	const { t } = useTranslation()

	const formState = useExercisesListAdminStore(
		(store) => store.formData
	) as MediaExerciseFormStateType
	if (!formState) return null

	return (
		<div className="exercise-media-answers">
			<label className="exercise-media-answers__label">Ответ:</label>
			<Radio
				checked={formState.noneAnswerInput.checked}
				onChange={(e) => onChangeAnswerRadios(e, 'noneAnswer')}
			>
				Без ответа
			</Radio>
			<Radio
				checked={formState.audioAnswerInput.checked}
				onChange={(e) => onChangeAnswerRadios(e, 'audioAnswer')}
			>
				Аудио
			</Radio>
			<Radio
				checked={formState.writeAnswerInput.checked}
				onChange={(e) => onChangeAnswerRadios(e, 'writeAnswer')}
			>
				Письменный
			</Radio>
		</div>
	)
}

// Флаги «Аудио ответ» и «Письменный ответ»
function TranscriptToggle() {
	const { t } = useTranslation()

	const formState = useExercisesListAdminStore(
		(store) => store.formData
	) as MediaExerciseFormStateType
	if (!formState) return null

	return (
		<div className="exercise-media-transcript-toggle">
			<label className="exercise-media-transcript-toggle__label">
				Добавить расшифровку
			</label>
			<Switch
				onChange={onChangeTranscriptToggle}
				checked={formState.transcriptionInput.checked}
			/>
		</div>
	)
}

// Флаги «Аудио ответ» и «Письменный ответ»
function TranscriptField() {
	const { t } = useTranslation()

	const formState = useExercisesListAdminStore(
		(store) => store.formData
	) as MediaExerciseFormStateType
	if (!formState) return null

	if (!formState || !formState.transcriptionInput.checked) return null

	return (
		<>
			<div className="exercise-media-transcript-input">
				<HtmlEditor
					initialValue={formState.transcriptionInput.value}
					onChangeValue={onChangeTranscriptInput}
				/>
			</div>
			{formState.noTranscriptionPassedError && (
				<p className="exercise-media__input-error">
					Или выключите флаг «Добавить расшифровку» или введите текст
				</p>
			)}
		</>
	)
}
