import React from 'react'
import { useTranslation } from 'react-i18next'
import { MySchoolsListController } from 'widgets/MySchoolsList/MySchoolsListController'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import { EmptyBlock } from 'ui/EmptyBlock/EmptyBlock'
import AddSchoolButton from '../AddSchoolButton/AddSchoolButton'

// Статья со всеми школами пользователя
function MySchoolsArticle() {
	const mySchoolsListRouter = MySchoolsListController.router()

	return (
		<ArticleContentWrapper
			center={
				mySchoolsListRouter.status == 'loading' ||
				mySchoolsListRouter.status == 'noSchools'
			}
		>
			{mySchoolsListRouter.status == 'noSchools' && <NoSchools />}
			{mySchoolsListRouter.status == 'hasSchools' &&
				mySchoolsListRouter.element}
		</ArticleContentWrapper>
	)
}

export default MySchoolsArticle

function NoSchools() {
	const { t } = useTranslation()

	return (
		<EmptyBlock
			text={
				<>
					{t('mySchools.schoolsArticleNoSchoolsFirstLine')}
					<br />
					{t('mySchools.schoolsArticleNoSchoolsSecondLine')}
				</>
			}
			button={<AddSchoolButton />}
		/>
	)
}
