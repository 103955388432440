import React from 'react'
import cn from 'classnames'
import './MetaOutputText.scss'

type MetaOutputTextConfig = {
	text: string
	strong?: boolean
}

type MetaOutputTextProps = {
	icon?: React.FC
	textConfigs: MetaOutputTextConfig[]
}

function MetaOutputText(props: MetaOutputTextProps) {
	const { icon: Icon, textConfigs } = props

	return (
		<p className="meta-output-text">
			{/*@ts-ignore*/}
			{Icon && <Icon className="meta-output-text__icon" />}
			{textConfigs.map((textConfig) => {
				return (
					<span
						className={cn(
							'meta-output-text',
							textConfig.strong &&
								'meta-output-text__text--strong'
						)}
						key={textConfig.text}
					>
						{textConfig.text}
					</span>
				)
			})}
		</p>
	)
}

export default MetaOutputText
