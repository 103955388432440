import type { MenuProps } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useGetDeleteUser } from './deleteUser'
import { useGetUserRole, useGoToPage } from 'parts/utils/hooks/hooks'
import AppUrls from 'parts/constants/pageUrl'

import useEmployeeStore from '../../zustand/store'
import UserService from 'parts/services/UserService'

enum ItemKey {
	SendAccess = 'sendAccess',
	Edit = 'edit',
	Remove = 'remove',
}

/** Хук возвращает пункты списка меню действий с сотрудником. */
export function useGetMenuItems(): MenuProps['items'] {
	const { t } = useTranslation()

	const userRoles = useGetUserRole()

	const employee = useEmployeeStore((store) => store.employee)

	if (!employee) return []

	const items: MenuProps['items'] = [
		{
			key: ItemKey.SendAccess,
			label: t('employee.actionListItemSendAccess'),
		},
		{
			type: 'divider',
		},
	]

	if (location.pathname !== AppUrls.Employee(employee.id).url) {
		items.push({
			key: ItemKey.Edit,
			label: (
				<>
					<EditOutlined className="employee-header-buttons-list__icon" />
					{t('employee.actionListItemEdit')}
				</>
			),
		})
	}

	if (userRoles.isAdmin) {
		items.push({
			key: ItemKey.Remove,
			label: (
				<div className="employee-header-buttons-list__danger">
					<DeleteOutlined className="employee-header-buttons-list__icon" />
					{t('employee.actionListItemDelete')}
				</div>
			),
		})
	}

	return items
}

/** Обработчик щелчка по пункту меню действий с сотрудником. */
export function useGetActionDropdownItem() {
	const employee = useEmployeeStore((store) => store.employee)

	const goToEditPage = useGoToPage(AppUrls.Employee(employee?.id || 0).url, [
		employee,
	])
	const deleteUser = useGetDeleteUser()

	return function (e: any) {
		if (!employee) return

		const key: ItemKey = e.key

		if (key == ItemKey.SendAccess) {
			UserService.sendAccess(employee.id)
		} else if (key == ItemKey.Edit) {
			goToEditPage()
		} else if (key == ItemKey.Remove) {
			deleteUser()
		}
	}
}
