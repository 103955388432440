import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { getRusNounByNumber } from 'parts/utils/string'
import LangService, { LangNames } from 'parts/i18n/LangService'

/**
 * Возвращает строки с количеством уроков, групп и учеников
 * @param trainingData — данные курса
 */
export function getStatisticTexts(
	trainingData: TrainingEntityTypes.TrainingAdmin
) {
	const lang = LangService.getCurrentLangFromLocalStorage()

	const lessonsNum = trainingData.lessonCount
	const groupsNum = trainingData.groups.length
	const studentsNum = trainingData.studentCount

	let lessonsStr = ''

	if (lang === LangNames.RU) {
		lessonsStr =
			lessonsNum +
			' ' +
			getRusNounByNumber(lessonsNum, 'урок', 'урока', 'уроков')
		if (!lessonsNum) lessonsStr = 'уроков нет'
	} else if (lang === LangNames.EN) {
		if (lessonsNum === 0) {
			lessonsStr = 'No lessons'
		} else if (lessonsNum === 1) {
			lessonsStr = lessonsNum + ' lesson'
		} else {
			lessonsStr = lessonsNum + ' lessons'
		}
	}

	let groupsStr = ''

	if (lang === LangNames.RU) {
		groupsStr =
			groupsNum +
			' ' +
			getRusNounByNumber(groupsNum, 'группа', 'группы', 'групп')
		if (!groupsNum) groupsStr = 'групп нет'
	} else if (lang === LangNames.EN) {
		if (groupsNum === 0) {
			groupsStr = 'No groups'
		} else if (groupsNum === 1) {
			groupsStr = groupsNum + ' group'
		} else {
			groupsStr = groupsNum + ' groups'
		}
	}

	let studentsStr = ''

	if (lang === LangNames.RU) {
		studentsStr =
			studentsNum +
			' ' +
			getRusNounByNumber(studentsNum, 'ученик', 'ученика', 'учеников')
		if (!studentsNum) studentsStr = 'учеников нет'
	} else if (lang === LangNames.EN) {
		if (studentsNum === 0) {
			groupsStr = 'No students'
		} else if (studentsNum === 1) {
			groupsStr = studentsNum + ' student'
		} else {
			groupsStr = studentsNum + ' students'
		}
	}

	return { lessonsStr, groupsStr, studentsStr }
}
