import { FormInstance } from 'antd'
import { useEffect } from 'react'
import { setErrorsToFields } from 'parts/utils/form'
import useEmailFormStore from '../zustand/store'

export enum FieldNames {
	Email = 'email',
}

export type FormValuesType = {
	[FieldNames.Email]: string
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useEmailFormStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
