import { useParams } from 'react-router-dom'
import { RouterVarNames } from '../../app/routes/special/otherRoutes'
import { getYouTubeVideoId } from '../utils/string'
import Site from '../constants/site'

// Методы для работы с адресной строкой
const UrlService = {
	/** Возвращает id курса из адресной строки */
	useGetTrainingId() {
		const params = useParams()
		const trainingId = params[RouterVarNames.TrainingId]!
		return parseInt(trainingId)
	},

	/** Возвращает id урока из адресной строки */
	useGetLessonId() {
		const params = useParams()
		const lessonId = params[RouterVarNames.LessonId]!
		return parseInt(lessonId)
	},

	/** Возвращает id урока из адресной строки */
	useGetExerciseId() {
		const params = useParams()
		const exerciseId = params[RouterVarNames.ExerciseId]!
		return parseInt(exerciseId)
	},

	/** Возвращает id эфира из адресной строки */
	useGetLiveId() {
		const params = useParams()
		const liveId = params[RouterVarNames.LiveLessonId]!
		return parseInt(liveId)
	},

	/** Возвращает id входа в эфир из адресной строки */
	useGetLiveEntranceId() {
		const params = useParams()
		return params[RouterVarNames.LiveLessonEntranceId]!
	},

	/**
	 * Для ученика в адресной строке идентификатор курса пишется вместе с группой разделённой тире.
	 * Функция разбирает строку на идентификатор курса и группы
	 * @param str — строка с идентификатором курса и группы. Например '50-12'
	 */
	getTrainingAndGroupIds(str: string) {
		const parts = str.split('-')

		const courseId = parseInt(parts[0])
		const groupId = parseInt(parts[1])

		return { courseId, groupId }
	},

	/** Возвращает id группы из адресной строки */
	useGetGroupId() {
		const params = useParams()
		const groupId = params[RouterVarNames.GroupId]!
		return parseInt(groupId)
	},

	/** Возвращает id ученика из адресной строки */
	useGetStudentId() {
		const params = useParams()
		const studentId = params[RouterVarNames.StudentId]!
		return parseInt(studentId)
	},

	/** Возвращает id сотрудника из адресной строки */
	useGetEmployeeId() {
		const params = useParams()
		const employeeId = params[RouterVarNames.EmployeeId]!
		return parseInt(employeeId)
	},

	useGetDeleteAccountCode() {
		const params = useParams()
		const deleteAccountCode = params[RouterVarNames.DeleteAccountCode]!
		return deleteAccountCode
	},

	/**
	 * Для ученика в адресной строке идентификатор курса пишется вместе с группой разделённой тире.
	 * Функция создаёт такую строку
	 * @param trainingId — строка с идентификатором курса.
	 * @param groupId — строка с идентификатором группы.
	 */
	createTrainingAndGroupIdsUrl(
		trainingId: number | string,
		groupId: number | string
	) {
		return trainingId + '-' + groupId
	},

	getCorrectVideoLink(videoLink: string) {
		const link = getYouTubeVideoId(videoLink)

		return link ? 'https://www.youtube.com/embed/' + link : videoLink
	},

	getRootDomainFromUrl() {
		const { host } = location

		if (host.startsWith('localhost')) {
			return Site.rootDomain
		} else {
			// Разобрать цепочку доменов школы на строки
			const domainParts = host.split('.')
			// Удалить домен где располагается текущая школа
			domainParts.shift()

			// Собрать из кусочков домен выше.
			return domainParts.join('.')
		}
	},

	/**
	 * Возвращает значение запроса из переданного адреса
	 * @param {String} url — URL c запросом
	 * @param {String} searchParamName — название запроса, значение которого нужно получить
	 */
	getSearchParamValueFromUrl(url: string, searchParamName: string) {
		const urlObj = new URL(url)
		return urlObj.searchParams.get(searchParamName)
	},

	/**
	 * Возвращает булево значение, находится ли пользователь на главном домене.
	 * Функция не работает на localhost!
	 * */
	isRootDomain() {
		const { hostname } = location // localhost

		// Если совпадает с главным доменом...
		return hostname == Site.devRootDomain || hostname == Site.prodRootDomain
		// return true
	},

	/**
	 * Получает полный адрес страницы вида: http://localhost:3000/lp/1000_125550
	 * и возвращает только часть с путём до одностраничника: lp/1000_125550
	 * @param fullUrl - полный адрес страницы
	 */
	getLandingUrlFromFullUrl(fullUrl: string) {
		// Получение частей адреса: ['https:', '', 'testovaya1.dev.credu.ai', 'lp', 'pervii_120429']
		const urlParts = fullUrl.split('/')

		// Получение частей, из которых будет состоять путь до страницы: ['lp', 'pervii_120429']
		const urlPageSegments = urlParts.splice(-2)

		// Получение строки lp/pervii_120429
		return urlPageSegments.join('/')
	},

	/** Возвращает id ученика из адресной строки */
	useGetExpressCourseId() {
		const params = useParams()
		const courseName = params[RouterVarNames.ExpressCourseId]!
		return courseName
	},

	/** Возвращает id ученика из адресной строки */
	useGetExpressLessonId() {
		const params = useParams()
		const expressLessonId = params[RouterVarNames.ExpressLessonId]!
		return parseInt(expressLessonId)
	},
}

export default UrlService
