import React from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import {
	getPercentage,
	getRingMetrics,
	getTimeFromMs,
	useAnimateRing,
} from './fn/Result-func'
import './Result.scss'

type ResultProps = {
	passed: number // Количество пройденных вопросов
	total: number // Общее количество вопросов
	onPassAgainButtonClick: Function // Обработчик нажатия на кнопку повторного прохождения упражнения
	seconds: number // Количество миллисекунд потребовавшееся на прохождение упражнения
}

// Экран с результатом выполнения упражнения
function Result(props: ResultProps) {
	const { passed, total, onPassAgainButtonClick, seconds } = props

	const { t } = useTranslation()
	const time = getTimeFromMs(seconds * 1000)
	const percentage = getPercentage(total, passed)

	return (
		<section className="exercise-result">
			<div className="exercise-result__ring-wrapper">
				<Ring percentage={percentage} />
				<div>
					<p className="exercise-result__progress">
						{passed} из {total}
					</p>
					<p className="exercise-result__percents-text">
						{percentage}
					</p>
					<p className="exercise-result__percents-sign">%</p>
				</div>
			</div>
			<p className="exercise-result__time">{time}</p>
			<Button onClick={() => onPassAgainButtonClick()}>
				Пройти снова
			</Button>
		</section>
	)
}

export default Result

type RingProps = {
	percentage: number
}

function Ring(props: RingProps) {
	const { percentage } = props

	const { radius, diameter, lineWidth, offset } = getRingMetrics()
	const strokeDasharray = useAnimateRing(diameter, percentage)

	return (
		<svg
			version="1.1"
			baseProfile="full"
			xmlns="http://www.w3.org/2000/svg"
			width={diameter + lineWidth}
			height={diameter + lineWidth}
			className="exercise-result__ring"
		>
			<g transform={`rotate(-90 ${offset} ${offset})`}>
				<circle
					cx={offset}
					cy={offset}
					r={radius}
					stroke="#F5F5F5"
					strokeWidth={lineWidth}
					fill="none"
				/>
				<circle
					cx={offset}
					cy={offset}
					r={radius}
					stroke="#64B625"
					strokeWidth={lineWidth}
					strokeDasharray={strokeDasharray}
					strokeDashoffset="0"
					fill="none"
				/>
			</g>
		</svg>
	)
}
