import { useParams } from 'react-router-dom'
import { RouterVarNames } from 'app/routes/special/otherRoutes'
import UrlService from 'parts/services/UrlService'
import useExerciseStudentStore from '../zustand/store'

/** Получает из адресной строки и возвращает идентификаторы курса, группы, урока и упражнения */
export function useGetTrainingIdAndLessonId() {
	const params = useParams()

	const { courseId, groupId } = UrlService.getTrainingAndGroupIds(
		params[RouterVarNames.TrainingId]!
	)

	const lessonId = UrlService.useGetLessonId()
	const exerciseId = UrlService.useGetExerciseId()

	return { courseId, groupId, lessonId, exerciseId }
}

/** Возвращает данные активного метаупражнения */
export function useGetCurrentExerciseMeta() {
	const exerciseId = UrlService.useGetExerciseId()

	const exercisesMeta = useExerciseStudentStore(
		(state) => state.exercisesMeta
	)

	const exerciseMeta = exercisesMeta.find(
		(exercise) => exercise.id === exerciseId
	)

	return exerciseMeta ? exerciseMeta : null
}
