import React from 'react'
import { LandingTypes } from 'parts/types/LandingTypes'
import {
	useGetInitialText,
	useGetOnInput,
} from 'pages/landing/landingConstructor/details/editableElems/EditableText/fn/onInput'
import 'pages/landing/landingConstructor/details/editableElems/EditableText/EditableText.scss'

type EditableTextProps<
	A extends keyof LandingTypes.Landing,
	B extends keyof LandingTypes.Landing[A],
	C extends keyof LandingTypes.Landing[A][B],
	D extends keyof LandingTypes.Landing[A][B][C],
	E extends keyof LandingTypes.Landing[A][B][C][D],
	F extends keyof LandingTypes.Landing[A][B][C][D][E],
	G extends keyof LandingTypes.Landing[A][B][C][D][E][F]
> = {
	propsPathArr: [A, B?, C?, D?, E?, F?, G?]
	placeholder?: string
}

// Элемент со спаном где можно редактировать текст
function EditableText<
	A extends keyof LandingTypes.Landing,
	B extends keyof LandingTypes.Landing[A],
	C extends keyof LandingTypes.Landing[A][B],
	D extends keyof LandingTypes.Landing[A][B][C],
	E extends keyof LandingTypes.Landing[A][B][C][D],
	F extends keyof LandingTypes.Landing[A][B][C][D][E],
	G extends keyof LandingTypes.Landing[A][B][C][D][E][F]
>(props: EditableTextProps<A, B, C, D, E, F, G>) {
	const { propsPathArr, placeholder } = props

	const text = useGetInitialText(propsPathArr)
	const onInput = useGetOnInput(propsPathArr)

	return (
		<span
			contentEditable
			suppressContentEditableWarning
			onInput={onInput}
			className="land-const-editable-text"
			data-placeholder={placeholder ?? 'Текст'}
		>
			{text}
		</span>
	)
}

export default EditableText
