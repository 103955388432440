import EntityTypes from 'parts/types/EntityTypes'
import { useTranslation } from 'react-i18next'
import { EmptyBlock } from 'ui/EmptyBlock/EmptyBlock'
import AnswerBlock from '../answer/AnswerBlock/AnswerBlock'
import styles from '../answer/AnswerBlock/AnswerBlock.module.scss'
import ExerciseModal from '../answer/ExerciseModal/ExerciseModal'

type AnswersFeedProps = {
	answers: EntityTypes.Answer[]
}

export default function AnswersFeed(props: AnswersFeedProps) {
	const { answers } = props

	const { t } = useTranslation()

	return (
		<>
			<div className={styles.AnswersFeed}>
				<div className={styles.RepliesContainer}>
					{answers.length ? (
						answers.map((answer) => {
							return (
								<AnswerBlock key={answer.id} answer={answer} />
							)
						})
					) : (
						<EmptyBlock
							text={
								<>
									{t(
										'answersFeed.answersFeedNoAnswersScreenText'
									)}
									<br />
									{t(
										'answersFeed.answersFeedNoAnswersScreenText2'
									)}
								</>
							}
						/>
					)}
				</div>
			</div>
			<ExerciseModal />
		</>
	)
}
