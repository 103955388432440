import ProgramBlocks from 'pages/landing/landingConstructor/sections/programSection/ProgramBlocks/ProgramBlocks'
import AddNewBlockSection from '../AddNewBlockSection/AddNewBlockSection'

function SectionContent() {
	return (
		<div>
			<ProgramBlocks />
			<AddNewBlockSection />
		</div>
	)
}

export default SectionContent
