import cn from 'classnames'
import { useGetColorScheme } from 'pages/landing/landingConstructor/tariffs/card/common/common'
import EditableText from 'pages/landing/landingConstructor/tariffs/card/common/EditableText/EditableText'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'
import 'pages/landing/landingConstructor/tariffs/card/BuyButton/BuyButton.scss'

type HeaderProps = {
	tariff: TariffsStoreType.Tariff
}

// Кнопка покупки
function BuyButton(props: HeaderProps) {
	const { tariff } = props
	const colorScheme = useGetColorScheme()

	const classes = ['tariff-card-buy-btn']
	if (tariff.isFlashed) {
		classes.push('tariff-card-buy-btn--white')
	} else {
		classes.push(
			'tariff-card-buy-btn--colored',
			'tariff-card-buy-btn--' + colorScheme
		)
	}

	return (
		<p className={cn(classes)}>
			<EditableText
				tariffInnerId={tariff.innerId}
				propsPathArr={['mainPrice', 'buttonText']}
				placeholder="Заголовок"
				afterChange={tariffsManager.setNeedToSave.bind(tariffsManager)}
			/>
		</p>
	)
}

export default BuyButton
