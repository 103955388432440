import { create } from 'zustand'
import { TrainingHeaderStudentStoreType } from './storeTypes'

const useTrainingHeaderAdminStore =
	create<TrainingHeaderStudentStoreType.State>((set) => {
		return {
			training: undefined as any,

			/** Установщик новых значений корневого объекта. */
			updateStore(obj) {
				return set((state) => {
					return obj
				})
			},
		}
	})

export default useTrainingHeaderAdminStore
