import LessonStudentService from 'parts/services/LessonStudentService'

/** Возвращает заголовок страницы и описание урока для ученика */
export function useGetHeaderTexts() {
	const lesson = LessonStudentService.useGetLesson()

	if (!lesson) {
		return { headerText: '', descriptionText: '' }
	} else {
		return {
			headerText: lesson.name,
			descriptionText: lesson.description,
		}
	}
}
