import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'
import useLivelessonStore from '../zustand/store'
import { getPlayerTab } from './liveUtils'
import { WebSocketService } from 'parts/services/WebSocketService'
import { LIVE_EVENT_TYPES } from '../components/LiveWhiteboard/constants'

function usePlayerTab() {
	const setCurrentTab = useLivelessonStore((s) => s.setCurrentTab)
	const addTab = useLivelessonStore((s) => s.addTab)

	return function (
		track: LiveLessonEntityTypes.StorageItem | undefined,
		tabId?: string
	) {
		if (!track) return

		const playerTab = getPlayerTab(track, tabId)

		addTab(playerTab)
		setCurrentTab(playerTab.id)

		if (!tabId) {
			WebSocketService.getWebSocketService().sendLiveEvent({
				type: LIVE_EVENT_TYPES.LIVE_PLAY_VIDEO_TRACK,
				body: {
					track: track,
					tabId: playerTab.id,
				},
			})
		}

		return playerTab
	}
}

export default usePlayerTab
