import { Button, Dropdown, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useGetActionDropdownItem, useGetMenuItems } from './fn/actions'
import './HeaderButtons.scss'

export function ActionsButton() {
	const { t } = useTranslation()
	const menuItems = useGetMenuItems()
	const onMenuClick = useGetActionDropdownItem()

	return (
		<Dropdown menu={{ items: menuItems, onClick: onMenuClick }}>
			<Button>
				<Space>
					{t('employee.actionListHeaderItem')}
					<DownOutlined />
				</Space>
			</Button>
		</Dropdown>
	)
}
