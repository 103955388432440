import { create } from 'zustand'
import { EditTrainingModalStoreType } from './storeTypes'

const useEditTrainingModalStore = create<EditTrainingModalStoreType.State>(
	(set) => {
		return {
			trainingId: undefined as any,
			isOpen: false,
			isSubmitting: false,
			formErrors: {},

			/**
			 * Установщик новых значений корневого объекта.
			 */
			updateStore(obj) {
				return set((state) => {
					return obj
				})
			},
		}
	}
)

export default useEditTrainingModalStore
