import { create } from 'zustand'
import { PresetsStoreType } from './storeTypes'

const useLessonsPresetsStore = create<PresetsStoreType.State>((set) => {
	return {
		dataLoadingStatus: 'loading',
		presets: undefined,
		isOpenApplyPresetConfirmation: false,
		isOpenPresetsListDrawer: false,
		isOpenDeletePresetConfirmation: false,
		selectedPreset: null,

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useLessonsPresetsStore
