import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { UseMutationResult } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import AppUrls from 'parts/constants/pageUrl'
import { FormValuesType } from './form'
import { userQuery } from 'parts/requests/user/userQuery'
import useRegisterAdminStore from '../zustand/store'
import ServerTypes from 'parts/types/ServerTypes'
import { RegisterAdminStoreType } from '../zustand/storeTypes'
import { authQuery } from 'parts/requests/auth/authQuery'
import UserApiTypes from 'parts/requests/user/userApiTypes'
import AuthApiTypes from 'parts/requests/auth/authApiTypes'
import EntityTypes from 'parts/types/EntityTypes'

/** Хук возвращающий обработчик отправки формы регистрации администратора школы */
export function useGetOnSubmit() {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const updateFormStore = useRegisterAdminStore((state) => state.updateStore)

	// Запрос на получение идентификатора пользователя.
	// Нужен если при проверке почты выяснится, что она зарегистрирована, но не подтверждена.
	// Идентификатор пользователя будет использоваться для последующей автоматической авторизации на сайте школы.
	const userByCredentialsQuery = authQuery.userByCredentials.useMutation({
		onSuccess: userIdByCredentialsOnSuccess(navigate, updateFormStore),
		onError: getMutateOnError(updateFormStore),
		onSettled: getOnAfterКRequest(updateFormStore),
	})

	// Объект с методом mutate для создания запроса на проверку зарегистрирована ли введённая почта уже в системе
	const emailCheckQuery = userQuery.checkEmailStatus.useMutation({
		onMutate: getCheckEmailOnBefore(updateFormStore),
		onError: getMutateOnError(updateFormStore),
	})

	// Объект с методом mutate для создания запроса на регистрацию пользователя в качестве администратора
	const registerAdminQuery = authQuery.registerAdmin.useMutation({
		onError: getMutateOnError(updateFormStore),
		onSuccess: getRegisterOnSuccess(navigate, updateFormStore),
		onSettled: getOnAfterКRequest(updateFormStore),
	})

	return async (fieldsValue: FormValuesType) => {
		// Тело запроса на проверку введённой почты.
		const emailCheckDto: UserApiTypes.CheckEmailStatusDto = {
			email: fieldsValue.email,
		}

		// Сделать запрос на проверку почты
		emailCheckQuery.mutate(emailCheckDto, {
			onSuccess: (res) => {
				checkEmailOnSuccess(
					res,
					updateFormStore,
					fieldsValue,
					registerAdminQuery,
					userByCredentialsQuery,
					t
				)
			},
		})
	}
}

/**
 * Функция, выполняемая до запросов
 * @param updateFormStore — функция, изменяющая объект состояния страниц регистрации администратора
 */
function getCheckEmailOnBefore(
	updateFormStore: RegisterAdminStoreType.UpdateStore
) {
	return function () {
		// Поставить статус загрузки
		updateFormStore({ isSubmitting: true })
	}
}

/**
 * Функция, выполняемая при успешном запросе на проверку переданной почты.
 * @param checkEmailStatusResp
 * @param updateFormStore — функция, изменяющая объект состояния страниц регистрации администратора
 * @param fieldsValue — значения полей формы
 * @param registerAdminQuery — объект запроса на регистрацию администратора
 * @param userByCredentialsQuery
 * @param t
 */
function checkEmailOnSuccess(
	checkEmailStatusResp: AxiosResponse<UserApiTypes.CheckEmailStatus>,
	updateFormStore: RegisterAdminStoreType.UpdateStore,
	fieldsValue: FormValuesType,
	registerAdminQuery: UseMutationResult<
		AxiosResponse<EntityTypes.User>,
		any,
		AuthApiTypes.RegisterAdminDto
	>,
	userByCredentialsQuery: UseMutationResult<
		AxiosResponse<number, any>,
		any,
		AuthApiTypes.UserIdByCredentialsDto,
		any
	>,
	t: TFunction<'translation'>
) {
	// Занести данные в Состояние потому что они потребуются на следующей странице и на этой,
	// чтобы поставить в поля формы если вернутся назад.
	updateFormStore({
		email: fieldsValue.email,
		phone: fieldsValue.phone,
		password: fieldsValue.password,
	})

	// Не продолжать если почта уже зарегистрирована и подтверждена
	if (checkEmailStatusResp.data == UserApiTypes.CheckEmailStatus.Exists) {
		updateFormStore({
			formErrors: {
				message: t('auth.startAdminRegFormEmailExistsAlready'),
			},
		})
		return
	}
	// Если почта зарегистрирована, но не подтверждена, то сделать запрос на вход для получения идентификатора пользователя.
	// Затем поставить его в Хранилище и переадресовать на страницу подтверждения почты.
	// Идентификатор пользователя требуется на странице подтверждения иначе без него она переадресует обратно на форму регистрации.
	else if (
		checkEmailStatusResp.data == UserApiTypes.CheckEmailStatus.NotConfirm
	) {
		userByCredentialsQuery.mutate({
			email: fieldsValue.email,
			password: fieldsValue.password,
		})

		return
	}

	// ...если почта или не регистрировалась, или регистрировалась в другой школе.
	// Данные, которые передадутся в функцию-обработчик запроса на регистрацию администратора
	const regReqDto: AuthApiTypes.RegisterAdminDto = {
		email: fieldsValue.email,
		password: fieldsValue.password,
		phone: fieldsValue.phone,
	}

	// Сделать запрос на регистрацию администратора
	registerAdminQuery.mutate(regReqDto)
}

/**
 * Универсальная функция, выполняемая при появлении ошибки после запроса
 * @param updateFormStore — функция, изменяющая объект состояния страниц регистрации администратора
 */
function getMutateOnError(updateFormStore: RegisterAdminStoreType.UpdateStore) {
	return function (err: unknown) {
		const error = err as AxiosError<ServerTypes.ErrorResponse>

		if (error.response) {
			// Поставить данные ошибки в Состояние, чтобы показать их в форме
			updateFormStore({ formErrors: error.response.data })
		}

		// Убрать статус загрузки
		updateFormStore({ isSubmitting: false })
	}
}

/**
 * Функция, выполняемая при успешном запросе на регистрацию пользователя.
 * @param navigate — функция, переадресовывающая на другую страницу.
 * @param updateFormStore — функция, изменяющая объект состояния страниц регистрации администратора.
 */
function getRegisterOnSuccess(
	navigate: NavigateFunction,
	updateFormStore: RegisterAdminStoreType.UpdateStore
) {
	return function (regResp: AxiosResponse<EntityTypes.User>) {
		updateFormStore({ userId: regResp.data.id })

		// Отправить на страницу подтверждения почты
		navigate(AppUrls.Register_ConfirmEmail().url)
	}
}

/**
 * Функция, выполняемая после запроса. Ставит в Хранилище статус окончания загрузки.
 * @param updateFormStore — функция, изменяющая объект состояния страниц регистрации администратора.
 */
function getOnAfterКRequest(
	updateFormStore: RegisterAdminStoreType.UpdateStore
) {
	return function () {
		// Убрать статус загрузки
		updateFormStore({ isSubmitting: false })
	}
}

/**
 * Функция, выполняемая после запроса на вход пользователя.
 * Получает пользователя и его идентификатор ставит в Хранилище. Он требуется на странице ввода проверочного кода из письма.
 * После переадресовывает на страницу ввода проверочного кода из письма.
 * @param navigate — функция, переадресовывающая на другую страницу
 * @param updateFormStore — функция, изменяющая объект состояния формы регистрации администратора
 */
function userIdByCredentialsOnSuccess(
	navigate: NavigateFunction,
	updateFormStore: RegisterAdminStoreType.UpdateStore
) {
	return function (
		userIdByCredentialsResp: AxiosResponse<AuthApiTypes.UserIdByCredentials>
	) {
		updateFormStore({ userId: userIdByCredentialsResp.data })
		navigate(AppUrls.Register_ConfirmEmail().url)
	}
}
