import { liveLessonQuery } from 'parts/requests/liveLesson/liveLessonQuery'
import { useQueryClient } from 'react-query'
import LiveLessonApiTypes from 'parts/requests/liveLesson/liveLessonApiTypes'

export function useGetCreateStorageItem() {
	const queryClient = useQueryClient()

	// Объект с методом mutate для создания запроса
	const createStoreItemMutation =
		liveLessonQuery.createStorageItem.useMutation({
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: liveLessonQuery.getStorageItems.key,
				})
			},
		})

	return async (data: LiveLessonApiTypes.CreateStorageItem) => {
		createStoreItemMutation.mutate(data)
	}
}
