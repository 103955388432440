import AddVideoUrlModal from 'pages/landing/landingConstructor/details/AddVideoUrlModal/AddVideoUrlModal'
import useAddVideoUrlModalStore from 'pages/landing/landingConstructor/details/AddVideoUrlModal/zustand/store'

export const AddVideoUrlModalController = {
	element: <AddVideoUrlModal />,
	open() {
		const updateStore = useAddVideoUrlModalStore.getState().updateStore
		updateStore({ isOpen: true })
	},
	close() {
		const updateStore = useAddVideoUrlModalStore.getState().updateStore
		updateStore({ isOpen: false })
	},
}
