import { useTranslation } from 'react-i18next'
import useLeadsStatsStore from './zustand/store'
import { CreditCardOutlined, WalletOutlined } from '@ant-design/icons'
import RoundIconAndTextCard from 'ui/RoundIconAndTextCard/RoundIconAndTextCard'
import './LeadsStats.scss'

// Статистика по успешным покупкам за всё время и за сегодня
function LeadsStats() {
	const { t } = useTranslation()

	const stats = useLeadsStatsStore((store) => store)

	return (
		<div className="leads-stats">
			<RoundIconAndTextCard
				icon={<CreditCardOutlined />}
				header={t('leads.leadsStatsTotalPurchasesHeader')}
				num={stats.totalSuccessfulPurchasesNum}
			/>
			<RoundIconAndTextCard
				icon={<WalletOutlined />}
				header={t('leads.leadsStatsTodayPurchasesHeader')}
				num={stats.todaySuccessfulPurchasesNum}
			/>
		</div>
	)
}

export default LeadsStats
