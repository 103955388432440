import { FormInstance } from 'antd'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import useTariffsConstructorStore from 'pages/landing/landingConstructor/tariffs/zustand/store'
import { useEffect } from 'react'

export enum FieldsNames {
	Currency = 'currency',
	PaymentSystem = 'paymentSystem',
	Period = 'period',
}

/** Возвращает первоначальные значения полей формы редактирования дополнительного способа оплаты. */
export function useGetInitialValues(priceId: number) {
	const price = tariffsManager.getAdditionalPriceInActiveTariff(priceId)
	if (!price) return {}

	return {
		[FieldsNames.Currency]: price.currency,
		[FieldsNames.PaymentSystem]: price.paymentSystem,
		[FieldsNames.Period]: price.period,
	}
}

// Возвращает обработчик удаления дополнительного способа оплаты
export function useGetDeleteAdditionalPayment(additionalPriceId: number) {
	const { activeTariffId } = useTariffsConstructorStore((state) => state)

	return function () {
		if (!activeTariffId) return

		tariffsManager.deleteAdditionalPriceAndUpdateStore(
			activeTariffId,
			additionalPriceId
		)
	}
}

/**
 * Хук наблюдает за переключением на другой тариф.
 * Если это произошло, то ставит в поля формы значения этого тарифа.
 * @param form — объект формы
 * @param priceId — id дополнительной цены
 */
export function useUpdateFormValuesAfterSwithToAnotherTariff(
	form: FormInstance,
	priceId: number
) {
	const initialValues = useGetInitialValues(priceId)
	const tariff = tariffsManager.useGetActiveTariff()

	useEffect(
		function () {
			form.setFieldsValue(initialValues)
		},
		[tariff?.innerId]
	)
}
