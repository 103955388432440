import ExerciseControlPanelContainer from '../../common/ExerciseControlPanelContainer/ExerciseControlPanelContainer'
import { useGetAttemptsText } from './fn/useGetAttemptsText'
import { useGetButtonsConfig } from './fn/getButtonsConfig'

// Нижняя часть упражнения с кнопками управления упражнением
function ExerciseControlPanel() {
	return (
		<ExerciseControlPanelContainer
			leftText={useGetAttemptsText()}
			buttons={useGetButtonsConfig()}
		/>
	)
}

export default ExerciseControlPanel
