import cn from 'classnames'
import { LandingTypes } from 'parts/types/LandingTypes'
import useLandingBuilderStore from '../../zustand/store'
import { useGetOnTakeAPartButtonClick } from './fn/clickHandler'
import './TakeAPartButton.scss'

type TakeAPartButtonProps = {
	sectionName: keyof LandingTypes.Sections
}

// Кнопка, прокручивающая к секции с тарифами
function TakeAPartButton(props: TakeAPartButtonProps) {
	const { sectionName } = props

	const landing = useLandingBuilderStore((store) => store.landing)
	const colorScheme = landing.colorScheme

	const onButtonClick = useGetOnTakeAPartButtonClick()

	return (
		<button
			className={cn(
				'landing-take-a-part-btn',
				'landing-take-a-part-btn--' + colorScheme
			)}
			onClick={onButtonClick}
		>
			{landing.sections[sectionName].buyBtnText || 'Записаться на курс'}
		</button>
	)
}

export default TakeAPartButton
