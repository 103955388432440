import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { authQuery } from 'parts/requests/auth/authQuery'
import useEmailFormStore from '../zustand/store'
import ServerTypes from 'parts/types/ServerTypes'
import { EmailFormStoreType } from '../zustand/storeTypes'
import AppUrls from 'parts/constants/pageUrl'
import { FormValuesType } from './form'
import UserService from 'parts/services/UserService'
import { useGetUserStatusInStore } from 'app/App/fn/getData'

/** Хук возвращающий обработчик отправки формы */
export function useGetOnSubmit() {
	const updateFormStore = useEmailFormStore((store) => store.updateStore)
	const userStatusInStore = useGetUserStatusInStore()

	// Запрос удаление пользователя
	const deleteAccountRequestQuery =
		authQuery.deleteAccountRequest.useMutation({
			onMutate: getDeleteAccountReqBeforeMutate(
				updateFormStore,
				userStatusInStore
			),
			onError: getMutateOnError(updateFormStore),
			onSuccess: getDeleteAccountReqSuccessMutate(),
		})

	return async (values: FormValuesType) => {
		deleteAccountRequestQuery.mutate({
			email: values.email.toLowerCase(),
		})
	}
}

/**
 * Функция, выполняемая до запроса на удаление аккаунта
 * @param updateFormStore — функция, изменяющая объект состояния формы входа
 */
function getDeleteAccountReqBeforeMutate(
	updateFormStore: EmailFormStoreType.UpdateStore,
	userStatus: string
) {
	return function () {
		// Поставить статус загрузки
		updateFormStore({ isSubmitting: true })

		if (userStatus === 'exists') {
			UserService.logout()
		}
	}
}

/**
 * Функция, выполняемая при успешном запросе на вход пользователя.
 * @param updateFormStore — функция, изменяющая объект состояния формы входа
 * @param generateTokenQuery — запрос на генерирование токена для автоматической авторизации
 */
function getDeleteAccountReqSuccessMutate() {
	const navigate = useNavigate()

	return function () {
		navigate(AppUrls.DeleteAccountMailSent().url)
	}
}

/**
 * Универсальная функция, выполняемая при появлении ошибки после запроса
 * @param updateFormStore — функция, изменяющая объект состояния формы входа пользователя
 */
function getMutateOnError(updateFormStore: EmailFormStoreType.UpdateStore) {
	return function (err: unknown) {
		const error = err as AxiosError<ServerTypes.ErrorResponse>

		if (error.response) {
			// Поставить данные ошибки в Состояние, чтобы показать их в форме
			updateFormStore({ formErrors: error.response.data })
		}

		updateFormStore({ isSubmitting: false })
	}
}
