import { BellOutlined, MessageOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Badge } from 'antd'
import MobileMenuButton from '../MobileMenuButton/MobileMenuButton'
import UserMenu from '../RightUserMenu/UserMenu'
import AppUrls from 'parts/constants/pageUrl'
import SwitchLanguageButton from '../SwitchLanguageButton/SwitchLanguageButton'
import './Navbar.scss'

// Верхняя панель сайта
function Navbar() {
	return (
		<header className="navbar">
			<MobileMenuButton />
			<Link to={AppUrls.Main().url} className="navbar__logo">
				<img src="/images/site/logo.svg" alt="logo" />
			</Link>
			<SwitchLanguageButton />
			<div className="navbar__right">
				{/*<Badge count={5} className="navbar__badge">
					<BellOutlined className="navbar__icon" />
				</Badge>
				<Badge count={21} className="navbar__badge">
					<MessageOutlined className="navbar__icon" />
				</Badge>*/}
				<UserMenu />
			</div>
		</header>
	)
}

export default Navbar
