import { FormInstance } from 'antd'
import { useGetToggleVisibilityTakePauseModal } from '../../TakePauseModal/fn/openCloseTakePauseModal'
import useReasonFormStore from '../zustand/store'
import { FieldsNames, FormValues } from './form'

/** Хук возвращающий обработчик отправки формы */
export function useGetOnSubmit(form: FormInstance) {
	const openTakePauseModal = useGetToggleVisibilityTakePauseModal()
	const updateReasonStore = useReasonFormStore((store) => store.updateStore)

	return function () {
		const formValues: FormValues = form.getFieldsValue()

		const reasonMapper = {
			[FieldsNames.ReasonExpensive]: 'Дорого',
			[FieldsNames.ReasonBadSupport]: 'Не устраивает поддержка',
			[FieldsNames.ReasonNoFunctionality]: 'Нет подходящего функционала',
			[FieldsNames.ReasonAnother]: 'Другое',
		}

		// @ts-ignore
		let reasonText = reasonMapper[formValues[FieldsNames.Reason]]

		// @ts-ignore
		const fullAnswer = formValues[FieldsNames.FullAnswer]

		if (fullAnswer) {
			reasonText += ' Примечание: ' + fullAnswer
		}

		updateReasonStore({ selectedReasonText: reasonText, isOpen: false })

		openTakePauseModal()
	}
}
