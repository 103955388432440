import React, { useRef } from 'react'
import HtmlEditorContentView from 'ui/HTMLEditorContentView/HTMLEditorContentView'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useSetInnerHtml } from '../../common/useSetInnerHtml'
import { Question } from './Question'
import { Bottom } from './Bottom'
import './TestExerciseView.scss'

type ExerciseProps = {
	exercise: TrainingEntityTypes.TestExercise
}

// Компонент упражнения-теста
function Exercise(props: ExerciseProps) {
	const { exercise } = props

	return (
		<>
			<Name name={exercise.item.name} />
			<Task task={exercise.item.task} />
			<Question />
			<Bottom />
		</>
	)
}

export default Exercise

type NameProps = {
	name: string
}

// Название теста
function Name(props: NameProps) {
	const { name } = props

	const areaRef = useRef(null)
	useSetInnerHtml(areaRef, name)

	if (!name) return null

	return <HtmlEditorContentView areaRef={areaRef} />
}

type TaskProps = {
	task: string
}

// Задача
function Task(props: TaskProps) {
	const { task } = props

	const areaRef = useRef(null)
	useSetInnerHtml(areaRef, task)

	if (!task) return null

	return (
		<HtmlEditorContentView
			areaRef={areaRef}
			outerClass="exercise-test-content__task"
		/>
	)
}
