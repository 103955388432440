import useStudentsStore from '../../../../StudentsTableCurator/zustand/store'
import { StudentsStoreType } from '../../../../StudentsTableCurator/zustand/storeTypes'

export enum FieldsNames {
	// Поле со строкой поиска
	TrainingName = 'trainingName',
}

export interface FormValues {
	[FieldsNames.TrainingName]: string
}

/** Обработчик отправки формы поиска по почте */
export function useGetOnTrainingNameChange() {
	const setSearchValuesProp = useStudentsStore(
		(store) => store.setSearchValuesProp
	)

	return function (values: FormValues) {
		setSearchValuesProp(
			StudentsStoreType.SearchValueNames.TrainingName,
			values[FieldsNames.TrainingName].toLowerCase()
		)
	}
}
