import { getCompRouterObj } from 'parts/utils/component'
import { paymentsQuery } from 'parts/requests/payments/paymentsQuery'
import LeadsStats from '../LeadsStats'
import { useFillInStore } from './fillInStore'

/** Хук возвращающий статус компонента LeadsStats и сам компонент в зависимости от статуса */
export function useGetLeadsStatsRouter() {
	// Получить список покупок
	const query = paymentsQuery.getPayments.useQuery({})

	useFillInStore()

	return getCompRouterObj(query, <LeadsStats />)
}
