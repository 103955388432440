import useEditTrainingModalStore from '../zustand/store'

/**
 * Возвращает функцию, открывающую/закрывающую модальное окно добавления нового курса
 * @param isVisible — должно ли окно быть видимым
 */
export function useSetVisibilityToEditTrainingModal(isVisible: boolean) {
	const updateStore = useEditTrainingModalStore((state) => state.updateStore)

	return function () {
		updateStore({ isOpen: isVisible })
	}
}
