import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import AppUrls from 'parts/constants/pageUrl'
import AddStudentButton from '../AddStudentButton/AddStudentButton'
import AdminStudentsArticle from '../AdminStudentsArticle/AdminStudentsArticle'
import Footer from 'ui/Footer/Footer'
import { useGetUserRole } from 'parts/utils/hooks/hooks'

/** Страница со списком учеников */
function AdminStudentsPage() {
	const userRoles = useGetUserRole()

	return (
		<PageSkeleton>
			<PageHeader
				breadcrumbs={[
					{
						path: AppUrls.Students().url,
						breadcrumbName: AppUrls.Students().name,
					},
				]}
				header={AppUrls.Students().name}
				buttons={[<AddStudentButton key="1" />]}
			/>
			<AdminStudentsArticle />
			<Footer isRightSideHidden={!userRoles.isAdminOrManager} />
		</PageSkeleton>
	)
}

export default AdminStudentsPage
