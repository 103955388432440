import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { RouterVarNames } from 'app/routes/special/otherRoutes'
import UrlService from 'parts/services/UrlService'
import exercisesRequests from 'parts/requests/exercises/exercisesRequests'

/** Функция, получающая с сервера данные для восстановления всех изменений сделанных учеником в упражнении. */
export function useGetExerciseRestoreData() {
	const params = useParams()
	const trainingIdStr = params[RouterVarNames.TrainingId]!
	const { groupId } = UrlService.getTrainingAndGroupIds(trainingIdStr)
	const exerciseId = UrlService.useGetExerciseId()

	return useMemo(
		async function () {
			const exerciseRestoreDataRes =
				await exercisesRequests.getExerciseRestoreInfo({
					exerciseId,
					groupId,
				})

			return exerciseRestoreDataRes.data
				? exerciseRestoreDataRes.data
				: null
		},
		[exerciseId]
	)
}
