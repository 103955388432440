import { useMemo } from 'react'

/**
 * Возвращает строку с временем выполнения упражнения вида: 01:34:09
 * @param milliseconds
 */
export function getTimeFromMs(milliseconds: number) {
	return useMemo(
		function () {
			const diffDate = new Date(milliseconds)

			let hours = setZero(getHours(diffDate))
			let minutes = setZero(diffDate.getMinutes())
			let seconds = setZero(diffDate.getSeconds())

			return hours + ':' + minutes + ':' + seconds
		},
		[milliseconds]
	)
}

function getHours(date: Date) {
	const diffHours = date.getTimezoneOffset() / 60

	return diffHours > 0
		? date.getHours() - diffHours
		: date.getHours() + diffHours
}

function setZero(num: number) {
	return num < 10 ? '0' + num : num.toString()
}
