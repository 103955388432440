import { useEffect } from 'react'
import useSystemStore from 'parts/systemStore/systemStore'
import useLiveLessonEntrancePageStore from '../../zustand/store'

/**
 * Смотрит за появлением значения liveLessonId в Хранилище страницы.
 * Если появился, то ставит в Системное Хранилище.
 * Сценарий в <App />
 */
export function useSetLiveLessonIdToSystemStore() {
	const liveLessonId = useLiveLessonEntrancePageStore(
		(store) => store.liveLessonId
	)
	const updateSystemStore = useSystemStore((store) => store.updateStore)

	useEffect(
		function () {
			updateSystemStore({ liveLessonId })
		},
		[liveLessonId]
	)
}
