import AuthApiTypes from 'parts/requests/auth/authApiTypes'
import React, { useCallback } from 'react'
import { message } from 'antd'
import type { MenuProps } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import useStudentStore from '../../zustand/store'
import userRequests from 'parts/requests/user/userRequest'
import UserApiTypes from 'parts/requests/user/userApiTypes'
import { useGetDeleteUser } from './deleteUser'
import authRequests from 'parts/requests/auth/authRequest'
import { useGetUserRole, useGoToPage } from 'parts/utils/hooks/hooks'
import AppUrls from 'parts/constants/pageUrl'
import UserService from 'parts/services/UserService'

enum ItemKey {
	SendAccess = 'sendAccess',
	Ban = 'ban',
	UnBan = 'unBan',
	Exit = 'exit',
	Remove = 'remove',
	Edit = 'edit',
}

/** Хук возвращает пункты списка меню действий с пользователем. */
export function useGetMenuItems(): MenuProps['items'] {
	const { t } = useTranslation()

	const userRoles = useGetUserRole()

	const student = useStudentStore((store) => store.student)
	if (!student) return []

	const isStudentAccountPage =
		location.pathname !== AppUrls.Student_Account(student.id).url

	const items: MenuProps['items'] = [
		{
			key: ItemKey.SendAccess,
			label: t('student.pageHeaderActionsMenuSendAccess'),
		},
	]

	if (student.banned) {
		items.push({
			key: ItemKey.UnBan,
			label: t('student.pageHeaderActionsMenuUnBan'),
		})
	} else {
		items.push({
			key: ItemKey.Ban,
			label: t('student.pageHeaderActionsMenuBan'),
		})
	}

	items.push({
		key: ItemKey.Exit,
		label: t('student.pageHeaderActionsMenuExit'),
	})

	if (!isStudentAccountPage && userRoles.isAdmin) {
		items.push({
			type: 'divider',
		})
	}

	if (isStudentAccountPage) {
		items.push({
			key: ItemKey.Edit,
			label: (
				<>
					<EditOutlined className="student-header-buttons-list__icon" />
					{t('student.pageHeaderActionsMenuEdit')}
				</>
			),
		})
	}

	if (userRoles.isAdmin) {
		items.push({
			key: ItemKey.Remove,
			label: (
				<div className="student-header-buttons-list__danger">
					<DeleteOutlined className="student-header-buttons-list__icon" />
					{t('student.pageHeaderActionsMenuRemove')}
				</div>
			),
		})
	}

	return items
}

/** Обработчик щелчка по пункту меню действий с пользователем. */
export function useGetActionDropdownItem() {
	const student = useStudentStore((store) => store.student)

	const banUser = useGetBanUser()
	const unBanUser = useGetUnBanUser()

	const goToEditPage = useGoToPage(
		AppUrls.Student_Account(student?.id || 0).url,
		[student]
	)
	const deleteUser = useGetDeleteUser()

	return useCallback(
		function (e: any) {
			if (!student) return

			const key: ItemKey = e.key

			if (key == ItemKey.SendAccess) {
				UserService.sendAccess(student.id)
			} else if (key == ItemKey.Ban) {
				banUser()
			} else if (key == ItemKey.UnBan) {
				unBanUser()
			} else if (key == ItemKey.Exit) {
				exit(student.id)
			} else if (key == ItemKey.Edit) {
				goToEditPage()
			} else if (key == ItemKey.Remove) {
				deleteUser()
			}
		},
		[student]
	)
}

/** Блокировка текущего ученика */
function useGetBanUser() {
	const { t } = useTranslation()

	const student = useStudentStore((state) => state.student)
	const updateStore = useStudentStore((state) => state.updateStore)

	return function () {
		if (!student) return

		const banUserDto: UserApiTypes.BanUserDto = {
			userId: student.id,
			banReason: '0', // Причина блокировка пока без уточнения
		}

		userRequests.banUser(banUserDto).then((response) => {
			if (response.status === 201) {
				message.success(
					t('student.pageHeaderActionsUserBannedSuccessful')
				)

				// Установить в Хранилище, что ученик заблокирован чтобы меню показывало актуальную информацию
				const updatedStudent = Object.assign(student, { banned: true })
				updateStore({ student: updatedStudent })
			} else {
				message.error(t('student.pageHeaderActionsUserBannedError'))
			}
		})
	}
}

/**
 * Разблокировка текущего ученика
 */
function useGetUnBanUser() {
	const { t } = useTranslation()

	const student = useStudentStore((state) => state.student)
	const updateStore = useStudentStore((state) => state.updateStore)

	return function () {
		if (!student) return

		const unbanUserDto: UserApiTypes.UnBanUserDto = {
			userId: student.id,
		}

		userRequests.unBanUser(unbanUserDto).then((response) => {
			if (response.status === 201) {
				message.success(
					t('student.pageHeaderActionsUserUnBannedSuccessful')
				)

				// Установить в Хранилище, что ученик разблокирован чтобы меню показывало актуальную информацию
				const updatedStudent = Object.assign(student, { banned: false })
				updateStore({ student: updatedStudent })
			} else {
				message.error(t('student.pageHeaderActionsUserUnBannedError'))
			}
		})
	}
}

/**
 * Выход пользователя из всех устройств
 * @param {String} userId — id ученика, которого нужно выгнать из всех устройств
 */
async function exit(userId: number) {
	const { t } = useTranslation()

	const unbanUserDto: AuthApiTypes.ExitDto = {
		userId: userId,
	}

	const exitRes = await authRequests.exit(unbanUserDto)

	if (exitRes.status === 200) {
		message.success(t('student.pageHeaderActionsUserExitSuccessful'))
	} else {
		message.error(t('student.pageHeaderActionsUserExitError'))
	}
}
