import { useEffect } from 'react'
import { FormInstance } from 'antd'
import useLessonsListAdminStore from '../../../zustand/store'
import LessonAdminService from 'parts/services/LessonAdminService'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { createFileListFromUrlList } from 'ui/upload/UploadImage/fn/fn'
import { FieldNames, FormValuesType } from './form'
import useEditLessonModalStore from '../../zustand/store'

// Возвращает первоначальные значения полей формы если создается новый урок (проверяется наличие id)
export function useSetValuesFormValues(form: FormInstance) {
	const lessons = useLessonsListAdminStore((store) => store.lessons)
	const [lessonId] = useEditLessonModalStore((s) => [s.lessonId])

	useEffect(
		function () {
			const lesson = LessonAdminService.findLessonByIdInLessons(
				lessons,
				lessonId
			)
			if (!lesson) return

			const formInitData = useGetInitialValues(lesson)

			form.setFieldsValue(formInitData)
		},
		[lessonId]
	)
}

/** Возвращает первоначальные значения полей формы редактирования урока. */
export function useGetInitialValues(
	lesson: TrainingEntityTypes.LessonAdmin
): FormValuesType {
	return {
		[FieldNames.Name]: lesson.name,
		[FieldNames.Description]: lesson.description || '',
		[FieldNames.Cover]: lesson.cover
			? createFileListFromUrlList([lesson.cover])
			: [],
	}
}
