import useStudentsStore from '../../../zustand/store'
import { StudentsStoreType } from '../../../zustand/storeTypes'
import SearchValuesStatus = StudentsStoreType.SearchValuesStatus

/** Возвращает объект с булевыми значениями видимости кнопок поиска по колонке */
export function useIsClearButtonVisible() {
	const searchValues = useStudentsStore((store) => store.searchValues)
	const ValueNames = StudentsStoreType.SearchValueNames

	return {
		isFullNameClearBtnOpen: !!searchValues[ValueNames.FullName].length,
		isEmailClearBtnOpen: !!searchValues[ValueNames.Email].length,
		isPhoneClearBtnOpen: !!searchValues[ValueNames.Phone].length,
		isCountryClearBtnOpen: !!searchValues[ValueNames.Country].length,
		isStatusesClearBtnOpen: !!searchValues[ValueNames.Status],
	}
}

/**
 * Возвращает обработчик нажатия на кнопку обнуления введённого поискового запроса в форме поиска по колонке таблицы
 * @param searchType — тип колонки для поиска
 */
export function getClearSearchValueFn(
	searchType: StudentsStoreType.SearchValueNames
) {
	const setSearchValuesProp = useStudentsStore(
		(store) => store.setSearchValuesProp
	)
	const ValueNames = StudentsStoreType.SearchValueNames

	return function () {
		if (
			searchType == ValueNames.FullName ||
			searchType == ValueNames.Email ||
			searchType == ValueNames.Phone ||
			searchType == ValueNames.Country
		) {
			setSearchValuesProp(searchType, '')
		} else if (searchType == ValueNames.Status) {
			setSearchValuesProp(searchType, SearchValuesStatus.All)
		}
	}
}
