import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import {
	useGetClosePanel,
	useGetHeaderText,
} from 'pages/landing/landingConstructor/tariffs/panel/PanelHeader/fn/header'
import 'pages/landing/landingConstructor/tariffs/panel/PanelHeader/PanelHeader.scss'

// Заголовок панели редактирования выбранного тарифа
function PanelHeader() {
	const headerText = useGetHeaderText()
	const closePanel = useGetClosePanel()

	return (
		<div className="tariff-panel-header">
			<p className="tariff-panel-header__text">{headerText}</p>
			<Button icon={<CloseOutlined />} type="text" onClick={closePanel} />
		</div>
	)
}

export default PanelHeader
