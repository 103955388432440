import { useEffect, useRef } from 'react'
import { message } from 'antd'
import { OpenVidu, Session } from 'openvidu-browser'
import LiveLessonService from 'parts/services/LiveLessonService'
import useLivelessonStore from '../zustand/store'
import useSystemStore from 'parts/systemStore/systemStore'
import {
	ParticipantRole,
	ParticipantVideoType,
	getScreenTab,
} from './liveUtils'
import { WebSocketService } from 'parts/services/WebSocketService'
import { LIVE_EVENT_TYPES } from '../components/LiveWhiteboard/constants'
import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'
import useIsTeacher from './useIsTeacher'
import RecordDialog from '../components/RecordDialog/RecordDialog'

export enum ScreenSharingState {
	IDLE = 'idle',
	STARTING = 'starting',
	PREPARING = 'preparing',
	PREPARED = 'prepared',
	CAPTURE_SCREEN = 'capture-screen',
	CREATE_TAB = 'create-tab',
	STARTED = 'started',
	STOPPING = 'stopping',
	CLOSE_TAB = 'close-tab',
}

export function useLiveScreenShare() {
	const openViduScreen = useRef<OpenVidu | null>(null)
	const openViduScreenSession = useRef<Session | null>(null)
	const tryCount = useRef(0)
	const screenTracks = useLivelessonStore((s) => s.screenTracks)
	const setScreenTracks = useLivelessonStore((s) => s.setScreenTracks)
	const live = useLivelessonStore((s) => s.live)
	const tabsList = useLivelessonStore((s) => s.tabsList)
	const screenShareState = useLivelessonStore((s) => s.screenShareState)
	const screenShareTab = useLivelessonStore((s) => s.screenShareTab)
	const setCurrentTab = useLivelessonStore((s) => s.setCurrentTab)
	const addTab = useLivelessonStore((s) => s.addTab)
	const removeTab = useLivelessonStore((s) => s.removeTab)
	const setScreenShareState = useLivelessonStore((s) => s.setScreenShareState)
	const setScreenShareTab = useLivelessonStore((s) => s.setScreenShareTab)
	const user = useSystemStore((s) => s.user)
	const isTeacher = useIsTeacher()

	useEffect(() => {
		if (screenShareState === ScreenSharingState.STARTING) {
			prepareScreenSharing()
		}
		if (screenShareState === ScreenSharingState.CREATE_TAB) {
			createScreenShareTab()
		}
		if (screenShareState === ScreenSharingState.CLOSE_TAB) {
			removeScreenShareTab()
		}
	}, [screenShareState, screenShareTab])

	const removeScreenShareTab = () => {
		if (screenShareTab) {
			setScreenShareState(ScreenSharingState.IDLE)
			const event = {
				type: LIVE_EVENT_TYPES.LIVE_SCREENSHARING_ENDED,
				body: {
					participantId: user.id,
					tabId: screenShareTab?.id,
				},
			}
			WebSocketService.getWebSocketService().sendLiveEvent(event)

			removeTab(screenShareTab.id)
			setCurrentTab(tabsList[0].id)
		}
	}

	const prepareScreenSharing = () => {
		if (live && screenShareState === ScreenSharingState.STARTING) {
			setScreenShareState(ScreenSharingState.PREPARING)

			return getToken(live.id)
				.then((token) => {
					console.log('useLiveScreenshare::getToken', token)

					return initSession(token)
				})
				.then(({ session, openVidu }) => {
					openViduScreen.current = openVidu
					openViduScreenSession.current = session

					if (!openVidu.checkScreenSharingCapabilities()) {
						message.error(
							'Демонстрация экрана не поддерживается вашим браузером. Рекомендуем использовать Google Chrome'
						)
						setScreenShareState(ScreenSharingState.IDLE)
						return
					}

					setScreenShareState(ScreenSharingState.PREPARED)
				})
				.catch((err) => {
					if (tryCount.current === 3) {
						setScreenShareState(ScreenSharingState.IDLE)
						message.error(
							'Возникала ошибка при старте Демонстрации экрана'
						)
					}
					console.error('Screensahring connect', err)
				})
		}
	}

	const startScreenSharing = () => {
		const openVidu = openViduScreen.current
		const session = openViduScreenSession.current

		if (
			openVidu &&
			session &&
			live &&
			screenShareState === ScreenSharingState.PREPARED
		) {
			setScreenShareState(ScreenSharingState.CAPTURE_SCREEN)
			initPublisher(session, openVidu, live)
		}
	}

	const initSession = (token: string) => {
		console.log('useLiveScreenShare::initSession 1')
		const openViduApi = new OpenVidu()
		const mySession = openViduApi.initSession()
		console.log('useLiveScreenShare::initSession 2')
		return mySession
			.connect(token, {
				clientData: {
					participantId: user.id,
					participantRole: isTeacher
						? ParticipantRole.Teacher
						: ParticipantRole.Student,
					participantVideoType: ParticipantVideoType.Screen,
				},
			})
			.then(() => ({
				openVidu: openViduApi,
				session: mySession,
			}))
			.catch((err) => {
				if (err.code === 401) {
					console.log('Token is not valid')
					tryCount.current += 1
					if (tryCount.current < 3) {
						setScreenShareState(ScreenSharingState.STARTING)
					}
				}
				throw err
			})
	}

	const initPublisher = (
		session: Session,
		openVidu: OpenVidu,
		live: LiveLessonEntityTypes.LiveLesson
	) => {
		const publisher = openVidu.initPublisher(undefined, {
			videoSource: 'screen',
			publishVideo: true,
		})
		console.log('useLiveScreenShare::initPublisher 1')
		publisher.on('accessDenied', (event) => {
			console.log('ACCESS DENIED')
			console.log(event)
			setScreenShareState(ScreenSharingState.IDLE)
		})
		console.log('useLiveScreenShare::initPublisher 1')
		publisher.once('accessAllowed', () => {
			session.publish(publisher)

			setScreenShareState(ScreenSharingState.CREATE_TAB)

			publisher.stream
				.getMediaStream()
				.getVideoTracks()[0]
				.addEventListener('ended', () => {
					console.log('SET CLOSE_TAB')
					setScreenShareState(ScreenSharingState.CLOSE_TAB)
					const tracks = publisher.stream.getMediaStream().getTracks()
					tracks.forEach((track) => {
						track.stop()
					})
					LiveLessonService.leaveParticipant(live.id)
				})
			setScreenTracks(publisher.stream.getMediaStream().getTracks())
			console.log('useLiveScreenShare::accessAllowed')
		})
	}

	const createScreenShareTab = () => {
		const tab = getScreenTab(user.id)
		addTab(tab)
		setCurrentTab(tab.id)
		setScreenShareTab(tab)

		const event = {
			type: LIVE_EVENT_TYPES.LIVE_SCREENSHARING_STARTED,
			body: {
				participantId: user.id,
				tabId: tab.id,
			},
		}
		WebSocketService.getWebSocketService().sendLiveEvent(event)

		setScreenShareState(ScreenSharingState.STARTED)
	}

	const getToken = (liveId: number) => {
		return LiveLessonService.getToken(liveId).then((response) => {
			console.log('useLiveScreenshare::getToken', response)

			return response.token
		})
	}

	const stopScreenSharing = () => {
		setScreenShareState(ScreenSharingState.CLOSE_TAB)
		message.info('Демонтрация экрана остановлена')
		screenTracks.forEach((track) => track.stop())

		removeScreenShareTab()
	}

	const handleCancel = () => {
		setScreenShareState(ScreenSharingState.IDLE)
	}

	const handleOk = () => {
		if (screenShareState === ScreenSharingState.PREPARED) {
			startScreenSharing()
		}
		if (screenShareState === ScreenSharingState.STOPPING) {
			stopScreenSharing()
		}
	}

	const title =
		screenShareState === ScreenSharingState.PREPARED
			? 'Поделиться экраном'
			: 'Остановить демонстрацию экрана?'

	const content =
		screenShareState === ScreenSharingState.PREPARED
			? ['После подтверждения ваш экран увидят другие участники']
			: ['Демонстрация экрана будет остановлена.']

	const okText =
		screenShareState === ScreenSharingState.PREPARED
			? 'Начать'
			: 'Остановить'

	return (
		<RecordDialog
			title={title}
			content={content}
			visible={
				screenShareState === ScreenSharingState.PREPARED ||
				screenShareState === ScreenSharingState.STOPPING
			}
			okText={okText}
			onOk={handleOk}
			onCancel={handleCancel}
		/>
	)
}
