import { message } from 'antd'
import { useParams } from 'react-router-dom'
import { exercisesQuery } from 'parts/requests/exercises/exercisesQuery'
import { RouterVarNames } from 'app/routes/special/otherRoutes'
import UrlService from 'parts/services/UrlService'

/** Функция, принимающая данные для восстановления всех изменений сделанных учеником в упражнении и сохраняющая на сервере. */
export function useGetSaveExerciseChangesMadeByUser() {
	const params = useParams()
	const trainingIdStr = params[RouterVarNames.TrainingId]!
	const { groupId } = UrlService.getTrainingAndGroupIds(trainingIdStr)
	const exerciseId = UrlService.useGetExerciseId()

	const saveExerciseRestoreInfoMutation =
		exercisesQuery.saveExerciseRestoreInfo.useMutation({
			onError: mutateOnError,
		})

	return function (restoreData: any) {
		saveExerciseRestoreInfoMutation.mutate({
			exerciseId,
			groupId,
			state: restoreData,
		})
	}
}

/** Функция, выполняемая при появлении ошибки после запроса на добавление сотрудника в группы */
function mutateOnError(err: unknown) {
	message.error('Не удалось сохранить данные для восстановления упражнения')
}
