import { useCallback } from 'react'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { exercisesFormsManager } from '../../exercisesFormsManager'
import { exercisesAsideManager } from '../zustand/exercisesAsideManager'

/**
 * Обработчик щелчка по кнопке упражнения в списке
 * @param {String} exerciseType — тип упражнения
 */
export function useGetOnExerciseItemClick(
	exerciseType: TrainingEntityTypes.ExerciseTypes
) {
	const openExerciseForm = exercisesFormsManager.useGetOpenExerciseForm()

	return useCallback(function () {
		// Закрыть меню с выбором создаваемого упражнения
		exercisesAsideManager.close()

		// Поставить тип формы с данными упражнения, который должен быть открыт
		openExerciseForm(exerciseType)
	}, [])
}
