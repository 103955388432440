import { message } from 'antd'
import { AxiosError } from 'axios'
import ServerTypes from 'parts/types/ServerTypes'
import useCondStore from '../zustand/store'
import { FieldsNames, FormValues } from './form'
import { groupQuery } from 'parts/requests/group/groupQuery'
import { CondStoreType } from '../zustand/storeType'
import { GroupConditionsFormController } from '../GroupConditionsFormController'
import EntityTypes from 'parts/types/EntityTypes'
import GroupsApiTypes from 'parts/requests/group/groupsApiTypes'

/** Хук возвращающий обработчик отправки формы */
export function useGetOnSubmit() {
	const updateStore = useCondStore((state) => state.updateStore)
	const group = useCondStore((state) => state.group)
	const trainingId = useCondStore((state) => state.trainingId)

	const updateTrainingGroups = GroupConditionsFormController.useGetUpdate()

	// Объект с методом mutate для создания запроса
	const updateGroupMutation = groupQuery.createOrUpdateGroup.useMutation({
		onMutate: getMutateOnBefore(updateStore),
		onError: getMutateOnError(updateStore),
		onSuccess: getMutateOnSuccess(trainingId, updateTrainingGroups),
		onSettled: getMutateOnAfter(updateStore),
	})

	return async (values: FormValues) => {
		updateGroupMutation.mutate(getDto(group!, values))
	}
}

/**
 * Функция, выполняемая до запроса на добавление сотрудника в группы
 * @param updateStore — функция изменяющая объект состояния формы
 */
function getMutateOnBefore(updateStore: CondStoreType.UpdateStore) {
	return function () {
		// Поставить статус загрузки
		updateStore({ isSubmitting: true })
	}
}

/**
 * Функция, выполняемая при появлении ошибки после запроса на добавление сотрудника в группы
 * @param updateStore — функция изменяющая объект состояния формы
 */
function getMutateOnError(updateStore: CondStoreType.UpdateStore) {
	return function (err: unknown) {
		const error = err as AxiosError<ServerTypes.ErrorResponse>

		if (error.response) {
			// Поставить данные ошибки в Состояние, чтобы показать их в форме
			updateStore({ formErrors: error.response.data })
		}

		message.error('Не удалось сохранить данные.')
	}
}

/**
 * Функция, выполняемая при успешном запросе на добавление сотрудника в группы
 * @param trainingId — id курса
 * @param updateTrainingGroups — функция, помечающая данные групп курса неактуальными. После этого React Query автоматически скачает новый список
 */
function getMutateOnSuccess(
	trainingId: number,
	updateTrainingGroups: (trainingId: number) => void
) {
	return function () {
		message.success('Настройки обновлены.')

		// Пометить список групп курса неактуальным.
		// После этого React Query скачает новый список
		updateTrainingGroups(trainingId)
	}
}

/**
 * Функция, выполняемая после запроса на добавление сотрудника в группы
 * @param updateStore — функция изменяющая объект состояния формы
 */
function getMutateOnAfter(updateStore: CondStoreType.UpdateStore) {
	return function () {
		// Убрать статус загрузки
		updateStore({ isSubmitting: false })
	}
}

/**
 * Возвращает объект с телом запрос на изменение условий прохождения группы
 * @param group — данные группы
 * @param values — значения полей формы изменений условий прохождения группы
 */
function getDto(
	group: EntityTypes.GroupData,
	values: FormValues
): GroupsApiTypes.CreateOrUpdateGroupDto {
	const startDateStr = getStartDateStr(values)
	const openLessonCondition = getOpenLessonCondition(values)

	return {
		id: group.id,
		trainingId: group.trainingId,
		name: group.name,
		type: group.type,
		startCondition: values[FieldsNames.StartCondition],
		mode: values[FieldsNames.PassingMode],
		openLessonCondition,
		startDate: startDateStr,
		duration: values[FieldsNames.Duration],
		accessToCourse: values[FieldsNames.Access],
		openLessons: group.openLessons,
	}
}

/**
 * Возвращает строку вида '2023-04-20T16:16:58.658Z'
 * @param values — значения полей формы изменения условий прохождения группы
 */
function getStartDateStr(values: FormValues) {
	const startDate = values[FieldsNames.StartDate]
	const startTime = values[FieldsNames.StartTime]

	if (!startDate || !startTime) return null

	const exactDate = startDate
		.hour(startTime.hour())
		.minute(startTime.minute())
		.second(startTime.second())

	return exactDate.toString()
}

function getOpenLessonCondition(
	values: FormValues
): EntityTypes.OpenLessonCondition {
	if (values[FieldsNames.PassingMode] === EntityTypes.GroupMode.ByIntervals) {
		return values[FieldsNames.Interval]
			? [values[FieldsNames.Interval]]
			: []
	} else if (
		values[FieldsNames.PassingMode] === EntityTypes.GroupMode.ByWeekDays
	) {
		return values[FieldsNames.OpeningByDay]
	}

	return []
}
