import { useEffect } from 'react'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import UrlService from 'parts/services/UrlService'
import ServerTypes from 'parts/types/ServerTypes'
import { prepareTariffs } from '../../../common/common'
import useLandingPreviewStore from '../store'
import useLandingStore from '../store'
import TrainingApiTypes from 'parts/requests/training/trainingApiTypes'
import { AxiosError, AxiosResponse } from 'axios'
import { LandingTypes } from 'parts/types/LandingTypes'

/** Получает данные для построения одностраничника и помещает их в Зустанд */
export function useFillInStore() {
	useFetchLandingAndSetToStore()
	useFetchTariffsAndSetToStore()

	useSetSuccessStatus()
}

// =========================

/** Загружает данные одностраничника и ставит в Состояние */
function useFetchLandingAndSetToStore() {
	// Получение адреса одностраничника вида lp/pervii_120429
	const landingUrl = UrlService.getLandingUrlFromFullUrl(location.href)

	// Получить данные одностраничника по адресу
	const getLandingMutation = trainingQuery.getLandingByUrl.useMutation({
		onError: queryError,
		onSuccess: setLandingToStore,
	})

	useEffect(function () {
		getLandingMutation.mutate(landingUrl)
	}, [])
}

/** Функция, выполняемая при успешном запросе получения данных одностраничника. */
function setLandingToStore(landingRes: AxiosResponse<LandingTypes.Landing>) {
	const { updateStore } = useLandingPreviewStore.getState()

	// Пустая строка будет если одностраничник ещё не опубликован
	// @ts-ignore
	if (landingRes.data === '') {
		updateStore({ dataLoadingStatus: 'notPublished' })
	}

	updateStore({ landing: landingRes.data })
}

// =========================

/** Загружает все курсы, находит текущий курс и его активную группу
 * и ставит в Состояние адрес его одностраничника и идентификатор активной группы */
function useFetchTariffsAndSetToStore() {
	// Получить тарифы
	const getTariffsMutation = trainingQuery.getTariffsByUrl.useMutation({
		onSuccess: setTariffsToStore,
		onError: queryError,
	})

	useEffect(function () {
		// Получение адреса одностраничника вида lp/pervii_120429
		const landingUrl = UrlService.getLandingUrlFromFullUrl(location.href)

		getTariffsMutation.mutate({
			landingUrl,
		})
	}, [])
}

function setTariffsToStore(
	tariffsRes: AxiosResponse<TrainingApiTypes.GetTariffsByUrl>
) {
	const { updateStore } = useLandingPreviewStore.getState()
	const tariffs = tariffsRes.data

	prepareTariffs(tariffs)

	updateStore({
		tariffs,
	})
}

// =========================

/** Следит за загрузкой всех данных и после ставит в Состояние успешный статус */
function useSetSuccessStatus() {
	const store = useLandingPreviewStore((store) => store)

	useEffect(
		function () {
			if (!store.tariffs || !store.landing) {
				return
			}

			store.updateStore({ dataLoadingStatus: 'success' })
		},
		[store.landing, store.tariffs]
	)
}

// =========================

/**
 * Функция запускаемая при ошибке запроса данных. Срабатывает для любых запросов.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 * @param err — объект ошибки.
 */
function queryError(err: unknown) {
	const error = err as AxiosError<ServerTypes.ErrorResponse>

	const { updateStore } = useLandingStore.getState()

	updateStore({
		dataLoadingStatus: 'error',
		errorMessage: error.response?.data.message,
	})
}
