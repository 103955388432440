import React from 'react'
import { useTranslation } from 'react-i18next'
import AuthPage from '../../../common/AuthPage/AuthPage'
import ContentWrapper from '../../../common/ContentWrapper/ContentWrapper'

// Страница с уведомлением об отправке письма для удаления аккаунта
function DeleteAccountMailSentPage() {
	const { t } = useTranslation()

	return (
		<AuthPage>
			<ContentWrapper
				header={t('auth.deleteAccMailSentPageHeader')}
				headerDesc={t('auth.deleteAccMailSentPageDescription')}
			/>
		</AuthPage>
	)
}

export default DeleteAccountMailSentPage
