import React from 'react'
import { Route } from 'react-router-dom'
import AppUrls from 'parts/constants/pageUrl'
import ConfirmEmailPageAfterReg from 'pages/auth/pages/register/owner/confirmEmailAfterReg/ConfirmEmailPageAfterReg/ConfirmEmailPageAfterReg'
import LoginPage from 'pages/auth/pages/login/LoginPage/LoginPage'
import RecoveryCheckPage from 'pages/auth/pages/recovery/recoveryCheck/RecoveryCheckPage/RecoveryCheckPage'
import RecoverySetPage from 'pages/auth/pages/recovery/recoverySet/RecoverySetPage/RecoverySetPage'
import RecoverStartPage from 'pages/auth/pages/recovery/recoveryStart/RecoveryStartPage/RecoverStartPage'
import RegisterPage from 'pages/auth/pages/register/registerMain/RegisterPage/RegisterPage'
import RegisterOwnerStartArticle from 'pages/auth/pages/register/owner/registerOwnerStart/RegisterOwnerPageStart/RegisterOwnerStartArticle'
import RegisterOwnerSchoolArticle from 'pages/auth/pages/register/owner/registerOwnerSchool/RegisterOwnerSchoolPage/RegisterOwnerSchoolArticle'
import ErrorPage from 'pages/duty/ErrorPage/ErrorPage'
import RecoveryFinishPage from 'pages/auth/pages/recovery/recoveryFinish/RecoveryFinishPage/RecoveryFinishPage'
import { RouterVarNames, getLandingRoute } from './otherRoutes'
import DeleteAccountPage from 'pages/auth/pages/deleteAccount/DeleteAccountPage/DeleteAccountPage'
import DeleteAccountConfirmPage from 'pages/auth/pages/deleteAccount/DeleteAccountConfirm/DeleteAccountConfirmPage'
import DeleteAccountDonePage from 'pages/auth/pages/deleteAccount/DeleteAccountDone/DeleteAccountDone'
import DeleteAccountMailSentPage from 'pages/auth/pages/deleteAccount/DeleteAccountMailSent/DeleteAccountMailSent'

export default function getAuthRouter() {
	return (
		<Route key="1235">
			{getAuthRoutes()}
			{getLandingRoute()}
		</Route>
	)
}

function getAuthRoutes() {
	return (
		<Route>
			<Route path={AppUrls.Login().url} element={<LoginPage />} />
			<Route
				path={AppUrls.RecoveryStart().url}
				element={<RecoverStartPage />}
			/>
			<Route
				path={AppUrls.RecoveryCheck('').urlShort}
				element={<RecoveryCheckPage />}
			/>
			<Route
				path={AppUrls.RecoverySet('').urlShort}
				element={<RecoverySetPage />}
			/>
			<Route
				path={AppUrls.RecoveryFinish().url}
				element={<RecoveryFinishPage />}
			/>
			{/* <Route path={AppUrls.Register().url} element={<RegisterPage />} /> */}
			<Route
				path={AppUrls.Register_Owner_Start().url}
				element={<RegisterOwnerStartArticle />}
			/>
			<Route
				path={AppUrls.Register_Owner_School().url}
				element={<RegisterOwnerSchoolArticle />}
			/>
			<Route
				path={AppUrls.Register_ConfirmEmail().url}
				element={<ConfirmEmailPageAfterReg />}
			/>
			<Route
				path="*"
				element={
					<ErrorPage
						errorCode={404}
						redirectUrl={AppUrls.Login().url}
					/>
				}
			/>
			<Route
				path={AppUrls.DeleteAccount().url}
				element={<DeleteAccountPage />}
			/>
			<Route
				path={
					AppUrls.DeleteAccountConfirm(
						':' + RouterVarNames.DeleteAccountCode
					).url
				}
				element={<DeleteAccountConfirmPage />}
			/>
			<Route
				path={AppUrls.DeleteAccountDone().url}
				element={<DeleteAccountDonePage />}
			/>
			<Route
				path={AppUrls.DeleteAccountMailSent().url}
				element={<DeleteAccountMailSentPage />}
			/>
		</Route>
	)
}
