import { useTranslation } from 'react-i18next'
import { ExerciseControlPanelButton } from '../../../common/ExerciseControlPanelContainer/ExerciseControlPanelContainer'
import useExerciseStore from '../../zustand/exerciseState'
import { useGetDoneExercise } from '../../../common/doneExercise'
import { useGetGoToNextExercise } from '../../../common/prevAndNextExerciseUrls'
import { useGetExerciseDuration } from '../../AudioExerciseView/fn/getExerciseDuration'
import { useGetAfterDoneExercise } from './afterDoneExercise'

/**
 * Возвращает массив с настройками кнопок для передачи в компонент ExerciseControlPanelContainer
 */
export function useGetButtonsConfig(): ExerciseControlPanelButton[] {
	const doneAllow = useExerciseStore((store) => store.doneAllow)

	const { t } = useTranslation()

	const duration = useGetExerciseDuration()

	// Функция, выполняемая при нажатии на кнопку «Выполнено».
	const doneExercise = useGetDoneExercise(useGetAfterDoneExercise(), duration)

	// Функция открывающая следующее упражнение если упражнение существует
	// или undefined если не существует.
	const goToNextExercise = useGetGoToNextExercise()

	return [
		{
			text: t('exercises.controlBtnNextExercise'),
			active: !!goToNextExercise,
			onClick: goToNextExercise,
			dataCyAttr: 'next-exercise-btn',
		},
		{
			text: t('exercises.controlBtnDone'),
			active: doneAllow,
			primary: true,
			onClick: doneExercise,
			dataCyAttr: 'done-exercise-btn',
		},
	]
}
