import { useParams } from 'react-router-dom'
import LessonStudentService from 'parts/services/LessonStudentService'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { RouterVarNames } from 'app/routes/special/otherRoutes'
import AppUrls from 'parts/constants/pageUrl'
import useExerciseStudentStore from '../../zustand/store'

export function useGetPrevAndNextLessonUrl() {
	const training = useExerciseStudentStore((state) => state.trainingStudent)
	const lesson = useExerciseStudentStore((state) => state.lesson)

	const prevLesson = LessonStudentService.getLessonInRelationToTargetLessonId(
		training.lessons,
		lesson.id,
		-1
	)
	const nextLesson = LessonStudentService.getLessonInRelationToTargetLessonId(
		training.lessons,
		lesson.id,
		1
	)

	return {
		prevLessonUrl: useGetLessonUrlFromLesson(prevLesson),
		nextLessonUrl: useGetLessonUrlFromLesson(nextLesson),
	}
}

function useGetLessonUrlFromLesson(lesson?: TrainingEntityTypes.LessonStudent) {
	const params = useParams()

	if (!lesson) return ''

	const trainingIdStr = params[RouterVarNames.TrainingId]!
	const lessonId = lesson.id
	const exerciseId = lesson.exercises[0]?.id

	if (!lessonId || !exerciseId) {
		return ''
	}

	return AppUrls.Exercise(trainingIdStr, lessonId, exerciseId).url
}
