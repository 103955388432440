import React, { useState } from 'react'
import { UploadFile } from 'antd'
import cn from 'classnames'
import { useGetMakeRequest, useSetFileUrlsToGetUploadedFilesFn } from './fn/fn'
import './UploadImage.scss'

export type GetUploadedFilesType = (files: UploadFile[]) => void

type UploadAvatarProps = {
	// Изначальный массив сведений об изначальных и загруженных файлов
	initialFileList: UploadFile[]
	// Функция, в которую передаётся адреса загружаемого файла
	getUploadedFiles: GetUploadedFilesType
	// Дополнительный класс, накладываемый на изображение
	extraClass?: string
}

// Изображение с возможностью загрузки
function UploadImage(props: UploadAvatarProps) {
	const { initialFileList, getUploadedFiles, extraClass } = props

	const [fileList, setFileList] = useState<UploadFile[]>(initialFileList)

	// После загрузки файлов поставить их в функцию, принимающей загруженные файлы
	useSetFileUrlsToGetUploadedFilesFn(getUploadedFiles, fileList)

	const onWrapperClick = useGetMakeRequest(setFileList)

	return (
		<label className="upload-image">
			<input
				type="file"
				id="myFile"
				name="filename"
				onChange={onWrapperClick}
				className="upload-image__input"
			/>
			<img
				className="upload-image__upload-sign"
				srcSet="/landing/signs/upload-image.svg"
				alt="img"
			/>
			<img
				src={fileList[0].url}
				alt=""
				className={cn('upload-image__image', extraClass)}
			/>
		</label>
	)
}

export default UploadImage
