import React from 'react'
import VideoFrame from 'ui/VideoFrame/VideoFrame'
import SectionHeader from '../../details/SectionHeader/SectionHeader'
import useLandingBuilderStore from '../../zustand/store'
import TakeAPartButton from '../../details/TakeAPartButton/TakeAPartButton'
import './VideoSection.scss'

// Первая секция одностраничника
function VideoSection() {
	return (
		<section className="landing-video-section">
			<div className="landing-video-section__top-part">
				<Content />
			</div>
			<div className="landing-video-section__bottom-part">
				<VideoFrameWrapper />
			</div>
		</section>
	)
}

export default VideoSection

function Content() {
	const landing = useLandingBuilderStore((store) => store.landing)

	return (
		<>
			<SectionHeader
				imageSrc={landing.sections.video.header.imageUrl}
				text={landing.sections.video.header.text}
			/>
			<p className="landing-video-section__description">
				{landing.sections.video.texts.description}
			</p>
			<TakeAPartButton sectionName="video" />
		</>
	)
}

function VideoFrameWrapper() {
	const landing = useLandingBuilderStore((store) => store.landing)

	return (
		<VideoFrame
			youTubeLink={landing.sections.video.youtubeVideoUrl}
			extraClass="landing-video-section__iframe"
		/>
	)
}
