import { useEffect } from 'react'
import {
	NavigateFunction,
	useNavigate,
	useSearchParams,
} from 'react-router-dom'
import AppUrls from '../constants/pageUrl'
import authRequests from '../requests/auth/authRequest'
import useSystemStore from '../systemStore/systemStore'
import { SystemStoreType } from '../systemStore/systemStoreTypes'

// Имя ключа в LocalStorage где временно будет храниться сгенерированный токен для получения токена авторизации и данных пользователя.
export enum AutoLoginLSKeys {
	Token = 'tokenForAutoLogin',
}

/**
 * Класс с логикой для автоматического входа на сайт школы если вошли на корневой сайн.
 * При входе в учётную запись на корневом сайте в LocalStorage будут записаны введённую почту и пароль для входа.
 * Другой код на корневом сайте, делающий переадресацию на сайт школы, ищет в LocalStorage записанную почту и пароль и подставляет в адресную строку при переадресации
 * Сайт школы прочитает это из адресной строки и сложит в свой LocalStorage.
 * При заходе на страницу логина школы эти данные будут прочитаны из LocalStorage и будет сделан автоматический вход уже на сайте школы.
 */
class AutoLogin {
	/**
	 * Ставит в LocalStorage сгенерированный токен для получения по нему токена авторизации, чтобы использовать для автовхода в учётную запись на сайте школы.
	 * @param generatedToken — сгенерированный токен для получения по нему токена авторизации
	 */
	setGeneratedTokenToLocalStorage(generatedToken: string) {
		localStorage.setItem(AutoLoginLSKeys.Token, generatedToken)
	}

	/** Получает почту и пароль из LocalStorage */
	getCredentialsFromLocalStorage() {
		return {
			[AutoLoginLSKeys.Token]: localStorage.getItem(
				AutoLoginLSKeys.Token
			),
		}
	}
	/** Убирает сгенерированный токен из LocalStorage */
	clearCredentialsInLocalStorage() {
		localStorage.removeItem(AutoLoginLSKeys.Token)
	}

	useMoveGeneratedTokenFromUrlToLocalStorage() {
		let [searchParams, setSearchParams] = useSearchParams()

		useEffect(() => {
			const generatedToken = searchParams.get(AutoLoginLSKeys.Token)
			if (!generatedToken) return

			this.setGeneratedTokenToLocalStorage(generatedToken)
		}, [])
	}

	getUrlWithCredentials(url: string) {
		const { tokenForAutoLogin } = this.getCredentialsFromLocalStorage()

		if (!tokenForAutoLogin) {
			return url
		}

		return (
			url + '?' + this.createQueryUrlWithGeneratedToken(tokenForAutoLogin)
		)
	}

	createQueryUrlWithGeneratedToken(generatedToken: string) {
		return AutoLoginLSKeys.Token + '=' + generatedToken
	}
	/**
	 * При загрузке страницы запускает функцию ищущую в localStorage почту и пароль для автоматического входа
	 * И если находит, делает запрос на вход.
	 */
	useMakeAutoLogin() {
		const navigate = useNavigate()
		const updateSystemStore = useSystemStore((store) => store.updateStore)

		useEffect(() => {
			this.makeAutoLogin(navigate, updateSystemStore)
		}, [])
	}

	/**
	 * Функция ищет в localStorage сгенерированный токен для автоматического входа.
	 * Если находит, делает запрос на получение данных пользователя по этому токену.
	 * После данные стираются из localStorage.
	 */
	async makeAutoLogin(
		navigate: NavigateFunction,
		updateSystemStore: SystemStoreType.UpdateStore
	) {
		const genToken = localStorage.getItem(AutoLoginLSKeys.Token)
		if (!genToken) return

		try {
			// Отправить запрос на авторизацию по токену
			const loginResponse = await authRequests.loginByGeneratedToken({
				token: genToken,
			})
			if (loginResponse.status !== 200) return

			localStorage.setItem('token', loginResponse.data.accessToken)

			setTimeout(() => {
				updateSystemStore({ user: loginResponse.data.user })
				navigate(AppUrls.Main().url)
			}, 30)

			this.clearCredentialsInLocalStorage()
		} catch (err) {
			console.log('Произошла ошибка при авторизации пользователя.')
		}
	}
}

const autoLogin = new AutoLogin()

export default autoLogin
