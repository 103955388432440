import { createWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'
import { LivelessonStoreType } from './storeTypes'
import { defaultTabs } from 'widgets/livelesson/liveLesson/defaultTabs'
import { getUpdatedParticipantsList } from '../fn/liveUtils'
import { ScreenSharingState } from '../fn/useLiveScreenshare'
import { RecordingState } from '../fn/useLiveRecording'
import { LiveLessonState } from '../fn/useLiveSession'

const useLivelessonStore = createWithEqualityFn<LivelessonStoreType.State>(
	(set) => {
		return {
			live: null,
			participants: [],
			tabsList: [],
			currentTab: defaultTabs[0],
			previousTab: null,
			lastParticipantIdChange: null,
			isUploadingFile: false,
			isPlayerVisible: false,
			playlist: [],
			currentTrack: null,
			isMobile: false,
			isFullScreen: false,
			recordingState: RecordingState.IDLE,
			isTrackLoading: false,
			isCameraBlockAlertVisible: false,
			screenShareState: ScreenSharingState.IDLE,
			screenShareTab: null,
			screenTracks: [],
			isRecWarningVisible: false,
			isNeedToLeave: false,
			liveState: LiveLessonState.Idle,

			setLive(live) {
				return set(() => {
					return {
						live,
					}
				})
			},

			setParticipants(participants) {
				return set((state) => {
					return {
						participants,
					}
				})
			},

			updateParticipants(updatedList) {
				return set((state) => {
					return {
						participants: getUpdatedParticipantsList(
							state.participants,
							updatedList
						),
					}
				})
			},

			setCurrentTab(tabId) {
				return set((state) => {
					const newCurrentTab = state.tabsList.find(
						(tab) => tab.id === tabId
					)

					return {
						previousTab: newCurrentTab
							? state.currentTab
							: state.previousTab,
						currentTab: newCurrentTab
							? newCurrentTab
							: state.currentTab,
					}
				})
			},

			addParticipant(participant) {
				return set((state) => {
					if (
						state.participants.find((p) => p.id === participant.id)
					) {
						const participants = state.participants.map((p) =>
							p.id === participant.id ? participant : p
						)
						return {
							participants,
						}
					} else {
						return {
							participants: [...state.participants, participant],
						}
					}
				})
			},

			removeParticipantById(participantId) {
				return set((state) => {
					const participants = state.participants.filter(
						(p) => p.id !== participantId
					)
					return {
						participants,
					}
				})
			},

			removeAllParticipants() {
				return set((state) => {
					return {
						participants: [],
					}
				})
			},

			clearRoom() {
				return set((state) => {
					return {
						live: null,
						participants: [],
						tabsList: [],
						previousTab: null,
						lastParticipantIdChange: null,
						isUploadingFile: false,
						isPlayerVisible: false,
						playlist: [],
						currentTrack: null,
						hasScreenSharing: false,
					}
				})
			},

			setCurrentTabExtra(extra) {
				return set((state) => {
					return {
						currentTab: {
							...state.currentTab,
							extra,
						},
						tabsList: state.tabsList.map((tab) =>
							tab.id === state.currentTab?.id
								? { ...tab, extra }
								: tab
						),
					}
				})
			},

			addTab(tab) {
				return set((state) => ({
					tabsList: [...state.tabsList, tab],
				}))
			},

			addWhiteboard(whiteboard) {
				return set((state) => {
					return {
						tabsList: [...state.tabsList, whiteboard],
					}
				})
			},

			removeTab(tabId) {
				return set((state) => ({
					tabsList: state.tabsList.filter((tab) => tab.id !== tabId),
				}))
			},

			setParticipantAudioStatus(
				participantId,
				status,
				owner = false,
				remote = false
			) {
				return set((state) => {
					if (owner || !status) {
						const participants = state.participants.map((p) =>
							p.id === participantId
								? { ...p, isAudioOn: status }
								: p
						)
						return {
							participants,
							lastParticipantIdChange: remote
								? null
								: participantId,
						}
					}

					return {}
				})
			},

			setParticipantVideoStatus(
				participantId,
				status,
				owner = false,
				remote = false
			) {
				return set((state) => {
					if (owner || !status) {
						const participants = state.participants.map((p) =>
							p.id === participantId
								? { ...p, isVideoOn: status }
								: p
						)
						return {
							participants,
							lastParticipantIdChange: remote
								? null
								: participantId,
						}
					}

					return {}
				})
			},

			setParticipantSubscriber(participantId, subscriber) {
				return set((state) => {
					return {
						participants: state.participants.map((p) =>
							p.id !== participantId
								? p
								: {
										...p,
										subscriber,
								  }
						),
					}
				})
			},

			setParticipantIsSpeaking(participantId, status) {
				return set((state) => {
					return {
						participants: state.participants.map((p) =>
							p.id !== participantId
								? p
								: {
										...p,
										isSpeaking: status,
								  }
						),
					}
				})
			},

			setParticipantScreenSharing(participantId, screenSharing) {
				return set((state) => {
					return {
						participants: state.participants.map((p) =>
							p.id !== participantId
								? p
								: {
										...p,
										screenSharing,
								  }
						),
					}
				})
			},

			clearLastParticipantIdChage() {
				return set(() => ({ lastParticipantIdChange: null }))
			},

			setIsUploadingFile(isUploadingFile) {
				return set(() => ({ isUploadingFile }))
			},

			setIsPlayerVisible(visible) {
				return set(() => ({ isPlayerVisible: visible }))
			},

			addToPlaylist(item) {
				return set((state) => {
					return {
						isPlayerVisible: true,
						playlist: [...state.playlist, item],
					}
				})
			},

			removeFromPlaylist(item) {
				return set((state) => {
					const playlist = state.playlist.filter(
						(i) => i.id !== item.id
					)

					return {
						playlist,
					}
				})
			},

			clearPlaylist() {
				return set(() => ({ playlist: [] }))
			},

			setCurrentTrack(track) {
				return set(() => ({
					currentTrack: track,
				}))
			},

			setIsMobile(status) {
				return set(() => ({ isMobile: status }))
			},

			toggleFullscreen() {
				return set((state) => ({
					isFullScreen: !state.isFullScreen,
				}))
			},

			setRecordingState(state) {
				return set(() => ({
					recordingState: state,
				}))
			},

			setIsTrackLoading(status) {
				return set(() => ({
					isTrackLoading: status,
				}))
			},

			setIsCameraBlockAlertVisible(visible) {
				return set(() => ({
					isCameraBlockAlertVisible: visible,
				}))
			},

			setScreenShareState(state) {
				return set(() => ({
					screenShareState: state,
				}))
			},

			setScreenShareTab(tab) {
				return set(() => ({
					screenShareTab: tab,
				}))
			},

			setIsRecWarningVisible(visible) {
				return set(() => ({
					isRecWarningVisible: visible,
				}))
			},

			setIsNeedToLeave(status) {
				return set(() => ({
					isNeedToLeave: status,
				}))
			},

			setLiveState(state) {
				return set(() => ({
					liveState: state,
				}))
			},

			setScreenTracks(tracks) {
				return set(() => ({
					screenTracks: tracks,
				}))
			},
		}
	},
	shallow
)

export default useLivelessonStore
