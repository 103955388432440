import { EditTrainingModalController } from 'pages/trainings/admin/editTraining/EditTrainingModal/EditTrainingModalController'
import { SyntheticEvent } from 'react'

/**
 * Хук отдаёт обработчик нажатия на кнопку показа модального окна редактирования урока
 * @param {Number} trainingId — id редактируемого урока
 */
export function useGetShowEditTrainingModal(trainingId: number) {
	return function (event: SyntheticEvent) {
		event.preventDefault()

		EditTrainingModalController.open(trainingId)
	}
}
