const courseCreateRu = {
	// КУРС Как проводить Как создавать курсы на Credu
	// Заголовок курса
	mainTitle: 'Как создавать курсы на Credu',
	// Подзаголовок курса
	mainSubtitle: 'Пройдя курсы вы узнаете',

	// УРОКИ КУРСА Как создавать курсы на Credu
	lesson1Title: 'Как создать курс',
	lesson1Subtitle:
		'Создать курс на Сredu можно за полчаса, особенно если у Вас уже есть подготовленные материалы. Узнайте, как это сделать.',
	lesson1Block1:
		'Важно! Для начала продумайте логику курса: о чем он будет, сколько уроков и какие упражнения в уроках Вы будете использовать. При этом помните, что в любой момент Вы можете внести необходимые изменения буквально в два клика! Остальное – дело техники.',
	lesson1Block2: 'Итак, приступим к созданию курса!',
	lesson1Block3:
		'Чтобы создать курс после создания школы необходимо войти в эккаунт вашей школы, перейти в раздел курсы и кликнуть на кнопку «Добавить».',
	lesson1Block4:
		'После этого необходимо придумать название курса. Также мы рекомендуем создать сразу краткое описание курса и придумать/подобрать картинку – обложку для него. Обложка добавляется после нажатия кнопки «Загрузить».  Загрузка изображения может быть произведена методом выбора и добавления файла с рабочего стола через символ «+» или путем перетягивания необходимого изображения в зону символа «+».',
	lesson1Block5:
		'Если Вы только тестируете платформу – не переживайте, любой контент можно изменить или добавить позже.',
	lesson1Block6:
		'После нажатия кнопки «Сохранить» Ваш курс будет сохранен в Вашей онлайн школе, и Вы можете приступить к его содержанию – урокам, упражнениям, расписанию и так далее.',

	lesson2Title: 'Как настроить прием оплат?',
	lesson2Subtitle:
		'Мы постоянно работаем над расширением линейки партнёров для приема оплат от учеников. Узнайте, какие способы оплаты есть сейчас и как их настроить.',
	lesson2Block1:
		'Платежные системы – это готовых механизмы, с помощью которых Вы можете получать деньги за уроки и курсы от Ваших учеников. Существуют разные платежные системы, пока мы сотрудничаем с двумя, но список партнеров будет расширен в ближайшее время. Если Вы не можете использовать предлагаемые платежные системы по каким-то причинам – мы можем помочь Вам с оплатой через Credu.',
	lesson2Block2: 'Как подключить прием платежей Yookassa',
	lesson2Block3:
		'Для подключения приема платежей через YooKassa (ранее известная как Яндекс.Касса), следуйте этим общим шагам:',
	lesson2Block4:
		'Создание учетной записи: Если у вас еще нет учетной записи YooKassa, вам нужно будет зарегистрироваться на их платформе тут https://yookassa.ru . Следуйте шагам, которые описаны на сайте YooKassa в руководстве: подтвердите номер телефона, создайте пароль и нажмите кнопку «Готово». Пройдите проверку, заключите договор и получите электронный идентификатор магазина и ключ API.',
	lesson2Block5:
		'Подтверждение платежей: В зависимости от выбранного способа оплаты некоторые платежи могут потребовать вашего подтверждения (например, оплата через банковскую карту). Вам нужно будет реализовать механизм подтверждения платежей.',
	lesson2Block6:
		'Важно! Тестирование: перед тем как перейти к реальным платежам, рекомендуется протестировать интеграцию в специальной тестовой среде YooKassa. Это поможет удостовериться, что система корректно обрабатывает платежи.',
	lesson2Block7:
		'Начало применения: После успешного тестирования и проверки, что всё работает как ожидается, вы можете перейти к использованию YooKassa для приема реальных платежей от ваших клиентов',
	lesson2Block8: 'Как подключить прием платежей Stripe',
	lesson2Block9:
		'Для подключения приема платежей через Stripe, следуйте этим общим шагам:',
	lesson2Block10:
		'Создание учетной записи: Если у вас еще нет учетной записи Stripe, вам нужно будет зарегистрироваться на их платформе тут https://stripe.com/ru . Следуйте шагам, которые описаны на сайте Stripe:',
	lesson2Block11_1: 'Оставьте заявку на подключение',
	lesson2Block11_2: 'Дождитесь ответа от персонального менеджера',
	lesson2Block11_3: 'Ознакомьтесь со списком необходимых документов',
	lesson2Block11_4: 'Получите доступ в личный кабинет',
	lesson2Block11_5: 'Выполните техническую интеграцию сайта',
	lesson2Block11_6:
		'Проведите тестовые платежи. Сообщите менеджеру об успешных тестах, чтобы он перевел ваш сайт в боевой режим',
	lesson2Block11_7:
		'Принимайте онлайн-платежи на сайте с помощью банковских карт, а также в один клик',
	lesson2Block12: 'Как подключить прием платежей от Credu',
	lesson2Block13:
		'КЕсли по каким-то причинам Вам не подходят существующие интеграции с платежными системами, Credu.ai предлагает сервис для получения платежей от учеников и передачи вам этой суммы на счет. Чтобы узнать подробности – обратитесь в службу поддержки Credu, мы обязательно поможем.',

	lesson3Title: 'Что такое группы?',
	lesson3Subtitle:
		'Группы на Credu - как и обычные группы в оффлайн школах - это набор количества студентов в одной сущности. Узнайте, как создать группы и работать с ними.',
	lesson3Block1:
		'Чтобы создать группу, нужно перейти в раздел «Мои курсы» - «Настройки групп» и нажать кнопку «Добавить группу», если это Ваша первая группа в курсе.',
	lesson3Block2:
		'При создании группы нужно придумать ей название (обязательно) и указать доступное количество мест в группе (опционально). Все созданные группы всегда можно удалить.',
	lesson3Block3:
		'Вы можете создавать нужное количество групп, исходя из Ваших потребностей и условий обучения. Для создания новых групп к курсу, у которого уже есть группы, нужно также зайти в нужный курс и добавить группу через «Настройки групп».',
	lesson3Block4:
		'Важно! Для работы с учениками на Ваших курсах нужно создать группы обучения.Все настройки применяются именно группе. Что это значит?Вы создали курс, но набор участников на курс вы осуществляете несколько раз в год. Например, весной, летом и осенью. Это означает, что курс сам по себе будет неизменным, а вот группы и условия для прохождения могут быть разными.',

	lesson4Title: 'Настройки курса',
	lesson4Subtitle:
		'Настройки курса помогут Вам правильно рассчитать возможности, поставить необходимые временные и количественные ограничения, если это необходимо! Узнайте, как это сделать правильно.',
	lesson4Block1:
		'Прежде, чем Вы перейдете к настройкам курса, Вам нужно создать группу. Чтобы создать группу, нужно зайти в раздел «Мои курсы», выбрать нужный курс, нажать «Настройки групп» и кликнуть на «Добавить группу» справа вверху. В открывшемся окне внести данные группы – название и количество обучающихся.',
	lesson4Block2:
		'Важно: не дублируйте название группы один в один с названием курса. Это поможет избежать путаницы. Хорошим решением может стать добавление даты начала обучения группы.',
	lesson4Block3:
		'Чтобы попасть в настройки курса, Вам нужно зайти в раздел «Курсы» и выбрать нужный курс. Затем нажать на кнопку «Настройки» и выбрать раздел «Условия прохождения».',
	lesson4Block4:
		'В «Условиях прохождения» Вы можете выбрать дату старта курса (возможен выбор в будущем), дату и время старта обучения (обязательные поля), время, когда открываются уроки (по интервалам или по дням), длительность обучения и время доступа на курс. Все эти настройки зависят от логики обучения на Вашем курсе.',
	lesson4Block5:
		'Важно: максимальное время прохождения курса на текущий момент – 365 дней.',
	lesson4Block6:
		'Вторая часть настройки курсов относится к тому, кто и в каких ролях работает на данном курсе. Перейдите в подраздел «Менеджеры и кураторы» и просмотрите роли Ваших сотрудников.',
	lesson4Block7:
		'В этом разделе можно также инициировать удаление сотрудника или передачу учеников от сотрудника к сотруднику.  Для передачи учеников нажмите на кнопку «Передать» рядом с именем сотрудника, во вновь открывшемся окне выберите от какого сотрудника и какому нужно передать учеников, и подтвердите Ваше намерение, нажав кнопку «Передать».',
	lesson4Block8: 'Видео о том, как завершить настройки созданной группы:',

	lesson5Title: 'Как добавить сотрудников на платформу?',
	lesson5Subtitle:
		'Ваши сотрудники - надежная опора Вашей школы и курсов. Узнайте, как добавить сотрудника в Вашу школу на Credu, если Вы работаете не один.',
	lesson5Block1:
		'Вы можете добавлять и удалять сотрудников вашей школы так часто, как это требуется. Чтобы добавить сотрудника нужно войти в ваш эккаунт, и перейти в нужную школу. После этого кликнуть на «Сотрудники» в меню навигации слева и нажать кнопку «Добавить» вверху справа.',
	lesson5Block2:
		'Заполните все поля и нажмите «Сохранить». В этом же окне вы можете выдать доступ в личный кабинет. Это можно сделать и позже. После того, как вы сохранили данные сотрудника, он/она будут отображаться в списке сотрудников этой школы. После того, как вы добавите сотрудника, на указанную Вами его электронную почту придет доступ к Credu.ai: логин и пароль.',
	lesson5Block3:
		'Как только сотрудник залогинится в системе Credu.ai, его статус изменится на «Активный».',
	lesson5Block4:
		'Вы можете просмотреть список всех сотрудников в разделе «Сотрудники».',
	lesson5Block5:
		'При клике на конкретного сотрудника откроются все его данные, которые вы можете изменить/дополнить/удалить в любой момент.',

	lesson6Title: 'Добавляем менеджеров и кураторов на курс',
	lesson6Subtitle:
		'Если Вы работаете не один, то Вам может понадобиться распределение ролей в команде. Узнайте, чем отличается роль менеджера, от роли администратора.',
	lesson6Block1:
		'На платформе Credu.ai Вы можете работать как индивидуально, так и в команде. Если в Вашей команде несколько человек, то Вы можете добавить их в школу на разных права. Роли сотрудников можно просмотреть в разделе «Сотрудники». Всего ролей в команде три: администратор, менеджер и куратор.',
	lesson6Block2:
		'Важно! Пожалуйста, обратите внимание, что не следует давать ваши логин и пароль сотрудникам. Гораздо безопаснее во избежание недоразумений и для более оперативного решения конфликтов раздавать вашим сотрудникам роли. Только администратор может создавать/добавлять и удалять школы, курсы, учеников. Ответственность за удаление каких-то блоков или элементов всегда лежит на администраторе. Именно поэтому мы не рекомендуем делиться доступом администратора с сотрудниками.',
	lesson6Block3:
		'Администратор – человек, обладающий всеми правами. Традиционно он либо регистрировался на платформе Credu и создавал школу (ы)/курсы, либо у него есть доступ к этому аккаунту.',
	lesson6Block4:
		'Менеджер – человек, который может управлять процессами и учениками.  Менеджера назначает администратор в разделе «Сотрудники». Менеджер может назначать куратора.',
	lesson6Block5:
		'Куратор – по сути это методист. Он не может удалять или вносить изменения куда-либо кроме упражнений в курсе. Но у него (как и у администратора и у менеджера) есть функция прямого общения с учениками.',
	lesson6Block6: 'Назначаем куратора на курс:',
	lesson6Block7:
		'Чтобы назначить куратора на курс, вам нужно зайти в раздел «Мои курсы», выбрать нужный курс и нажать кнопку «Настроить».',
	lesson6Block8: 'После этого перейдите в подраздел «Кураторы и менеджеры».',
	lesson6Block9:
		'Вам станет доступен список текущих кураторов и менеджера курса.',
	lesson6Block10:
		'Чтобы добавить нового куратора на курс или в группу (они подтягиваются из списка сотрудников), вам нужно нажать на кнопку «Добавить сотрудника» и выбрать в сплывающем окне нужного сотрудника с ролью «Куратор». Нажать кнопку «Добавить».',
	lesson6Block11: 'Назначаем менеджера на курс:',
	lesson6Block12:
		'Если вы хотите выдать роль менеджера сотруднику или изменить ее, нажмите на данные по конкретному сотруднику. В открывшемся окне нажмите на «Действия» и далее на «Редактировать».',
	lesson6Block13:
		'В открывшемся окне измените роль, если это необходимо. Нажмите «Сохранить».',
	lesson6Block14:
		'Важно! Администратор может назначать одного и более менеджеров, исходя из размеров школы и ее потребностей. Менеджером может быть столько же, сколько и кураторов, если это необходимо для решения задач курсов.',

	lesson7Title: 'Добавляем учеников на курс',
	lesson7Subtitle:
		'Добавить учеников на курс можно как вручную, так и автоматически. Узнайте об отличиях этих способов.',
	lesson7Block1:
		'Ученики – это главные пользователи наших продуктов. Ученик добавляется на Ваш курс автоматически или вручную.',
	lesson7Block2:
		'Автоматически будет добавляться ученик, совершивший покупку продукта на Credu. Данные по нему будут подтягиваться в разделе «Ученики», и Вы всегда сможете найти его профиль.',
	lesson7Block3:
		'Если у Вас есть ученики, которые купили продукт где-то еще и Вы хотите добавить их в свою базу учеников на Credu, то сделать это крайне легко и вручную.',
	lesson7Block4:
		'Чтобы добавить ученика, Вам нужно зайти в раздел «Ученики» и нажать кнопку «Добавить». Заведите все данные по нему вручную в дополнительно открывшемся окне. Обязательным полем является электронный адрес, все остальные поля являются вспомогательными. Остальные поля Вы можете добавить в любой момент позже через редактирование данных ученика.',
	lesson7Block5:
		'Важно! Чем больше данных по ученику у Вас будет собираться, тем проще Вам будет анализировать Вашу аудиторию в общем!',
	lesson7Block6:
		'Кроме основных данных – от ФИО до деталей по покупке, в этом же окне можно выбрать куратора для этого ученика. Также Вы можете добавить информацию по покупке или покупкам этого ученика. Это всегда имеет смысл, так как вся история будет как на ладони.',
	lesson7Block7:
		'После внесения данных нажмите «Сохранить». Ученик будет добавлен.',
	lesson7Block8:
		'Важно! Корректировать данные по ученикам (дополнять, изменять, добавлять их покупки вручную) Вы можете всегда. Неважно, каким методом ученик был добавлен в базу изначально.',

	lesson8Title: 'Карточка ученика – как использовать?',
	lesson8Subtitle:
		'Карточка ученика содержит данные для общего и индивидуального анализа. Узнайте, как работать с информацией в карточке ученика.',
	lesson8Block1:
		'По каждому из учеников автоматически заводится, сохраняется и обновляется индивидуальная карточка.  В ней есть информация о покупках, посещениях и прогрессу в обучении.',
	lesson8Block2:
		'Чтобы посмотреть карточку ученика, Вам нужно перейти в раздел «Ученики», выбрать нужного ученика по адресу его электронной почты или вручную в общем списке, нажать на него. После этого откроется новая страница с индивидуальной информацией по этому ученику в разделах «Заказы», «Посещения» и «Журнал действий».  То есть Вы можете отследить практически каждое действие конкретного ученика и понять, где есть проблемы и что можно улучшить.',
	lesson8Block3:
		'Если ученик предоставил данные, то будут заполнены разделы мобильный телефон и эккаунт в Instagram. В любой момент Вы можете внести изменения в карточку ученика через инструмент «Редактирование» или совершить необходимые действия с его аккаунтом.',
	lesson8Block4:
		'Важно! Чем больше данных по ученику у Вас будет собираться, тем проще Вам будет анализировать Вашу аудиторию в общем! ',

	lesson9Title: 'Остались вопросы?',
	lesson9Subtitle:
		'Команда поддержки Credu будет рада ответить на любые вопросы. Узнайте о том, как она работает!',
	lesson9Block1:
		'Нет глупых вопросов, есть вопросы, которые не были заданы в нужный момент. Наша служба поддержки всегда выслушает и попытается помочь. Связаться с ней можно на платформе через символ «Поддержка» (окно с синим сообщением). Скорее всего, Вам ответят в течение часа или двух (за исключением выходных). Чаты с поддержкой сохраняются. Также Вы всегда можете написать нам письмо на электронный адрес support@credu.ai.',
}

export default courseCreateRu
