import React from 'react'
import SectionHeader from '../../../details/SectionHeader/SectionHeader'
import SectionWrapper from '../../../details/SectionWrapper/SectionWrapper'
import TariffsPage from '../../../tariffs/TariffsPage/TariffsPage'
import useLandingBuilderStore from '../../../zustand/store'

function TariffsSection() {
	const [landing, tariffs] = useLandingBuilderStore((s) => [
		s.landing,
		s.tariffs,
	])

	if (!tariffs.length) {
		return <p>Тарифы не созданы.</p>
	}

	return (
		<SectionWrapper idAttr="tariffs-section">
			<SectionHeader
				imageSrc={landing.sections.tariffs.header.imageUrl}
				text={landing.sections.tariffs.header.text}
			/>
			<TariffsPage />
		</SectionWrapper>
	)
}

export default TariffsSection
