import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import useSystemStore from '../../systemStore/systemStore'
import EntityTypes from '../../types/EntityTypes'
import SchoolService from 'parts/services/SchoolService'
import Site from 'parts/constants/site'
import AppUrls from 'parts/constants/pageUrl'
import { AutoLoginLSKeys } from 'parts/services/AutoLogin'
import { authQuery } from 'parts/requests/auth/authQuery'
import { AxiosResponse } from 'axios'
import AuthApiTypes from 'parts/requests/auth/authApiTypes'

/**
 * Возвращает функцию, переадресовывающую на указанную страницу
 * @param {String} url — адрес страницы на которую нужно перебросить пользователя
 * @param {Array} depends — массив зависимостей при изменении которых пересобирается возращаемая функция
 */
export function useGoToPage(url: string, depends: any[] = []) {
	const navigate = useNavigate()

	return useCallback(() => {
		navigate(url)
	}, depends)
}

export type UserRolesObj = {
	rolesGot: boolean // Получены ли роли
	isAdmin: boolean
	isSuperAdmin: boolean
	isStudent: boolean
	isCurator: boolean
	isManager: boolean
	isManagerOrCurator: boolean
	isAdminOrManager: boolean
	isAdminOrManagerOrCurator: boolean
	isAdminOrCurator: boolean
	notStudent: boolean
	// Авторизованный пользователь с любой ролью
	// В противном случае неавторизованный
	hasRole: boolean
}

/** Хук возвращает объект с ролями пользователя */
export function useGetUserRole(): UserRolesObj {
	const user = useSystemStore((store) => store.user)

	return useMemo(
		function () {
			return getUserRole(user?.role)
		},
		[user]
	)
}

/** Функция возвращает объект с ролями пользователя */
export function getUserRole(
	userRole: undefined | EntityTypes.UserRole
): UserRolesObj {
	if (!userRole) {
		return {
			rolesGot: false,
			isAdmin: false,
			isSuperAdmin: false,
			isStudent: false,
			isCurator: false,
			isManager: false,
			isManagerOrCurator: false,
			isAdminOrManager: false,
			isAdminOrManagerOrCurator: false,
			isAdminOrCurator: false,
			notStudent: false,
			hasRole: false,
		}
	}

	const isAdmin = userRole === EntityTypes.UserRole.ADMIN
	const isSuperAdmin = userRole === EntityTypes.UserRole.SUPERADMIN
	const isStudent = userRole === EntityTypes.UserRole.STUDENT
	const isCurator = userRole === EntityTypes.UserRole.CURATOR
	const isManager = userRole === EntityTypes.UserRole.MANAGER
	const notStudent = userRole !== EntityTypes.UserRole.STUDENT

	return {
		rolesGot: true,
		isAdmin,
		isSuperAdmin,
		isStudent,
		isCurator,
		isManager,
		isManagerOrCurator: isCurator || isManager,
		isAdminOrManager: isAdmin || isManager,
		isAdminOrManagerOrCurator: isAdmin || isManager || isCurator,
		isAdminOrCurator: isAdmin || isCurator,
		notStudent,
		hasRole: true,
	}
}

/**
 * Возвращает обработчик щелчка по элементу с текстом.
 * После этого текст копируется в буфер обмена и появляется сообщение об успехе.
 */
export function useGetCopyInClipboard() {
	return useCallback(async function (
		e: React.MouseEvent<HTMLElement, MouseEvent>
	) {
		e.preventDefault()

		const $target = e.target
		// @ts-ignore
		const text = $target.textContent

		try {
			await navigator.clipboard.writeText(text)
			message.success('Скопировано в буфер обмена')
		} catch (err) {
			message.error('Не удалось скопировать строку в буфер обмена')
		}
	},
	[])
}

/**
 * Хук при отрисовке ставит в переданный элемент переданный текст.
 * @param text — текст, который нужно поставить в элемент
 * @param elemRef — ref элемента с текстом тезиса.
 */
export function useSetTextInEditableElementInMount(
	text: string,
	elemRef: React.MutableRefObject<null | HTMLElement>
) {
	useEffect(function () {
		const $paragraph = elemRef.current
		if (!$paragraph) return

		$paragraph.innerText = text
	}, [])
}

/**
 * Хук возвращает булево значение вызван ли он в первый раз.
 * Эта вспомогательная функция нужна, чтобы делать проверку в useEffect() и избегать срабатывания при монтировании компонента.
 */
export function useIsFirstRender(timeout = 100) {
	const [isFirstRender, setIsFirstRender] = useState(true)

	useEffect(function () {
		setTimeout(function () {
			setIsFirstRender(false)
		}, timeout)
	}, [])

	return isFirstRender
}

/**
 * Тормозилка значений переменной. Например у меня, при изменении какого-то объекта, должен запускататься запрос на сервер.
 * Чтобы запросы не запускались слишком часто нужно чтобы не так часто обновлялся объект с данными.
 * Эту операцию и делает этот хук.
 * Пример использования: const debouncedSearchTerm = useDebounce(someVal, 300);
 * @param value — значение, которое нужно затормозить
 * @param delay — на сколько затормаживается значение
 */
export default function useDebounce(value: any, delay: number) {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value)

	useEffect(
		() => {
			// Update debounced value after delay
			const handler = setTimeout(() => {
				setDebouncedValue(value)
			}, delay)

			// Cancel the timeout if value changes (also on delay change or unmount)
			// This is how we prevent debounced value from updating if value is changed ...
			// .. within the delay period. Timeout gets cleared and restarted.
			return () => {
				clearTimeout(handler)
			}
		},
		[value, delay] // Only re-call effect if value or delay changes
	)

	return debouncedValue
}

export function useCallbackRefState<T>() {
	const [refValue, setRefValue] = useState<T | null>(null)
	const refCallback = useCallback((value: T | null) => setRefValue(value), [])

	return [refValue, refCallback] as const
}

export function useGoToFirstSchool(userRoles: UserRolesObj) {
	const user = useSystemStore((store) => store.user)
	// Запрос на генерирование токена, который будет включён в LocalStorage для автоматического входа.
	const generateTokenQuery = authQuery.generateAuthToken.useMutation({
		onSuccess: getGenerateTokenMutateOnSuccess(),
	})

	/**
	 * Функция, выполняемая при успешном запросе на генерирование токена для последующей авторизации
	 * @param updateSystemStore — функция обновляющая системное хранилище
	 * @param navigate — функция, переадресовывающая на другую страницу
	 */
	function getGenerateTokenMutateOnSuccess() {
		return async function (
			generatedTokenRes: AxiosResponse<AuthApiTypes.GenerateAuthToken>
		) {
			// Получить токен для автоматической авторизации на сайте школы
			const generatedToken = generatedTokenRes.data

			const schools = await SchoolService.getUserSchools(user.id)
			const firstSchoolUrl = schools[0].domain

			let url = ''

			if (userRoles.isStudent) {
				url = Site.protocol + firstSchoolUrl + AppUrls.Main().url
			} else {
				url = Site.protocol + firstSchoolUrl + AppUrls.MySchools().url
			}

			window.location.href =
				url + '?' + AutoLoginLSKeys.Token + '=' + generatedToken

			// Стереть из LocalStorage данные для автоматического входа в учётную запись
			// autoLogin.clearCredentialsInLocalStorage()
		}
	}

	useEffect(() => {
		if (user && !userRoles.isSuperAdmin) {
			generateTokenQuery.mutate({})
		}
	}, [user])
}
