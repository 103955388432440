import {
	ExerciseChangesDataMadeByUser,
	LiftViewDuration,
	SaveExerciseChangesMadeByUser,
	useLiftViewDuration,
} from '../common/useLiftViewDuration'
import { useManageExerciseState } from './fn/main'
import Result from '../common/Result/Result'
import Exercise from './Exercise'
import { useGetPassExerciseAgainFn } from './fn/result'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import './DescriptionImageExerciseView.scss'

type DescriptionImageExerciseViewProps = {
	exercise: TrainingEntityTypes.DescriptionImgExercise
	liftViewDuration?: LiftViewDuration
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

// Вывод упражнения на перетаскивание слов в качестве описания изображения
function DescriptionImageExerciseView(
	props: DescriptionImageExerciseViewProps
) {
	const {
		exercise,
		liftViewDuration,
		saveExerciseChangesMadeByUser,
		exerciseRestoreData,
	} = props

	const { state, setState } = useManageExerciseState(exercise)

	// Считает время прошедшее после открытия упражнения
	// и передаёт количество секунд в liftViewDuration
	const durationSec = useLiftViewDuration(liftViewDuration, state.showResult)

	// Функция запускающая прохождение упражнения заново
	const passExerciseAgainFn = useGetPassExerciseAgainFn(exercise, setState)

	return (
		<>
			{state.showResult ? (
				<Result
					onPassAgainButtonClick={passExerciseAgainFn}
					seconds={durationSec}
					passed={state.answeredImages}
					total={state.totalImages}
				/>
			) : (
				<Exercise
					state={state}
					setState={setState}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
					exerciseRestoreData={exerciseRestoreData}
				/>
			)}
		</>
	)
}

export default DescriptionImageExerciseView
