import { useEffect } from 'react'
import { produce } from 'immer'
import usePagesScrollStore from './zustand/store'

/** Хук при загрузке страницы ставит на window обработчик прокрутки любого элемента, чтобы записать эти данные в Хранилище.
 * Другой хук наблюдает за переходами по страницам и ищет в Хранилище данные по прокрутке.
 * Если находит, то ставит указанному элементу записанные значения прокрутки.
 * */
export function useSaveScroll() {
	useEffect(function () {
		window.addEventListener('scroll', scrollElemsHandler, true)
	}, [])
}

/**
 * Обработчик прокрутки элементов
 * @param event — объект события прокрутки
 */
function scrollElemsHandler(event: Event) {
	const { pathname } = location

	const $scrolledElem = event.target as HTMLElement
	// @ts-ignore
	if (!$scrolledElem || $scrolledElem === document) return

	// Классы прокручиваемого элемента в виде строки разделённые пробелами.
	// Требуется в дальнейшем чтобы при заходе на страницу найти этот элемент и применить значение прокрутки.
	const $scrolledElemCls = $scrolledElem.classList.value as string

	const topScroll = $scrolledElem.scrollTop
	const leftScroll = $scrolledElem.scrollLeft

	const { pagesScroll, updateStore } = usePagesScrollStore.getState()

	// Создание нового объекта где записаны прокручиваемый элемент на каждой странице
	const updatedPagesScroll = produce(pagesScroll, (draft) => {
		draft[pathname] = {
			classes: $scrolledElemCls,
			top: topScroll,
			left: leftScroll,
		}
	})

	// Сохранить, объект чтобы при заходе на эту страницу поставить прокрутку
	updateStore({ pagesScroll: updatedPagesScroll })
}
