import React from 'react'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { AddSchoolModalController } from 'pages/my/admin/mySchoolsAdmin/AddSchoolModal/AddSchoolModalController'
import RestrictionsService from 'parts/services/RestrictionsService'

// Кнопка открывающая модальное окно добавления школы
function AddSchoolButton() {
	const { t } = useTranslation()

	if (!RestrictionsService.useCanCreateNewSchool()) {
		return null
	}

	return (
		<Button
			type="primary"
			icon={<PlusOutlined />}
			onClick={AddSchoolModalController.open}
		>
			{t('mySchools.schoolsArticleAddSchoolButton')}
		</Button>
	)
}

export default AddSchoolButton
