import { SyntheticEvent } from 'react'
import { EditLessonModalController } from '../../../../editLessonModal/EditLessonModalController'

/**
 * Хук отдаёт обработчик нажатия на кнопку показа модального окна редактирования урока
 * @param {Number} lessonId — id редактируемого урока
 */
export function useGetShowEditLessonModal(lessonId: number) {
	return function (event: SyntheticEvent) {
		event.preventDefault()

		EditLessonModalController.open(lessonId)
	}
}
