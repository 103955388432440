import React from 'react'
import { WaysBlocks } from '../WaysBlocks/WaysBlocks'
import WaysHeader from '../WaysHeader/WaysHeader'
import './WaysSection.scss'

// Секция одностраничника с частозадаваемыми вопросами
function WaysSection() {
	return (
		<div className="landing-ways-section">
			<WaysHeader />
			<WaysBlocks />
		</div>
	)
}

export default WaysSection
