import { create } from 'zustand'
import { LiveLessonEntrancePageStoreType } from './storeTypes'

const useLiveLessonEntrancePageStore =
	create<LiveLessonEntrancePageStoreType.State>((set) => ({
		liveEntranceIdCheckStatus: 'loading',
		liveEntranceIdCheckStatusErrorMessage: null,
		liveLessonId: null,
		email: null,

		currentRole: 'unknown',
		unregisteredStudentStatus: 'unregistered',

		updateState(obj) {
			return set((state) => {
				return obj
			})
		},
	}))

export default useLiveLessonEntrancePageStore
