import { useQueryClient } from 'react-query'
import { useGetLiveRoomsRouter } from './fn/useGetLiveRoomsRouter'
import { liveLessonQuery } from 'parts/requests/liveLesson/liveLessonQuery'

export const LiveRoomsListController = {
	router: useGetLiveRoomsRouter,
	// Функция, помечающая данные массива неактуальными.
	// После этого React Query скачает новый массив
	useGetUpdate: function () {
		const queryClient = useQueryClient()

		return function () {
			// Пометить список неактуальным. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [liveLessonQuery.getLiveLessons.key],
			})
		}
	},
}
