import React from 'react'
import cn from 'classnames'
import EditableImage from 'pages/landing/landingConstructor/details/editableElems/EditableImage/EditableImage'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import 'pages/landing/landingConstructor/sections/existenceSection/MainImage/MainImage.scss'

// Главное изображение справа в блоке «Вам подходит этот курс если...»
function MainImage() {
	const landing = useLandingConstructorStore((store) => store.landing)
	const { colorScheme } = landing

	return (
		<div
			className={cn(
				'land-const-existence-main-img',
				'land-const-existence-main-img--' + colorScheme
			)}
		>
			<EditableImage
				propsPathArr={['sections', 'existence', 'imageUrl']}
			/>
		</div>
	)
}

export default MainImage
