import { Button } from 'antd'
import {
	useGetHeaderText,
	useGetOnClickHandler,
} from 'pages/landing/landingConstructor/tariffs/card/ShowSettingsPanelBtn/fn/button'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'
import 'pages/landing/landingConstructor/tariffs/card/ShowSettingsPanelBtn/ShowSettingsPanelBtn.scss'

type ShowSettingsPanelBtnProps = {
	tariff: TariffsStoreType.Tariff
}

function ShowSettingsPanelBtn(props: ShowSettingsPanelBtnProps) {
	const { tariff } = props

	const onClick = useGetOnClickHandler(tariff)
	const text = useGetHeaderText(tariff)

	return (
		<div className="tariff-show-mobile-panel">
			<Button type="primary" block ghost onClick={onClick}>
				{text}
			</Button>
		</div>
	)
}

export default ShowSettingsPanelBtn
