import useSystemStore from 'parts/systemStore/systemStore'
import { Suspense } from 'react'
import { Spin } from 'antd'
import { Routes } from 'react-router-dom'
import { getLiveLessonEntranceRoute } from '../routes/special/otherRoutes'
import { useGoToLiveLessonPageIfNecessary } from './fn/goToLiveLessonPage'
import { useSetUser } from './fn/setUser'
import { useGetUserStatusInStore, useSetSchool } from './fn/getData'
import getAuthRouter from '../routes/special/authRouter'
import getAdminRouter from '../routes/adminRouter'
import { useGetUserRole, useGoToFirstSchool } from 'parts/utils/hooks/hooks'
import getStudentRouter from '../routes/studentRouter'
import getCuratorRouter from '../routes/curatorRouter'
import getManagerRouter from '../routes/managerRouter'
import getSuperAdminRouter from 'app/routes/superAdminRouter'
import autoLogin from 'parts/services/AutoLogin'
import UrlService from 'parts/services/UrlService'
import ScrollManagerElement from './fn/ScrollManagerElement'
import { useGetScrollDataFromLocalStorageAndSaveToStore } from './scrollManager/getScrollDataFromLocalStorage'
import { Loader } from 'ui'
import 'antd/dist/reset.css'
import './css/general.scss'
import './css/fonts.scss'
import './css/variables.scss'

function App() {
	// Если в URL есть сгенерированный токен для авторизации, то поставить в LocalStorage,
	// чтобы получить на странице входа. Это должно работать на домене школы.
	autoLogin.useMoveGeneratedTokenFromUrlToLocalStorage()

	useSetUser()
	useGetScrollDataFromLocalStorageAndSaveToStore()

	// Отправить событие в Google Analytics
	window.dataLayer.push({
		event: 'pageview',
	})

	return (
		<div className="app">
			{UrlService.isRootDomain() ? <RootDomain /> : <SchoolDomain />}
		</div>
	)
}

// export default Sentry.withProfiler(App)
export default App

// ROOT DOMAIN ------------------------------------------------------------------------

function RootDomain() {
	const userStatusInStore = useGetUserStatusInStore()

	if (userStatusInStore == 'loading') {
		return <Spin />
	} else if (userStatusInStore === 'notExists') {
		return <RootDomainNotAuthorizedUser />
	} else {
		return <RootDomainAuthorizedUser />
	}
}

function RootDomainNotAuthorizedUser() {
	return <Routes>{getAuthRouter()}</Routes>
}

function RootDomainAuthorizedUser() {
	const userRoles = useGetUserRole()
	useGoToFirstSchool(userRoles)

	if (userRoles.isSuperAdmin) {
		return <RootDomainSuperAdmin />
	}

	// Переадресовать на домен школы с основного домена
	// SchoolService.goToFirstSchoolUrl(userRoles)

	return <Spin />
}

function RootDomainSuperAdmin() {
	const user = useSystemStore((store) => store.user)

	return (
		<Suspense fallback={<Loader />}>
			<Routes>{getSuperAdminRouter(user)}</Routes>
		</Suspense>
	)
}

// SCHOOL DOMAIN ----------------------------------------------------------------------

function SchoolDomain() {
	const userStatusInStore = useGetUserStatusInStore()

	if (userStatusInStore == 'loading') {
		return <Spin />
	} else if (userStatusInStore === 'notExists') {
		return <SchoolDomainNotAuthorizedUser />
	} else {
		return <SchoolDomainAuthorizedUser />
	}
}

function SchoolDomainNotAuthorizedUser() {
	return <Routes>{[getAuthRouter(), getLiveLessonEntranceRoute()]}</Routes>
}

function SchoolDomainAuthorizedUser() {
	const userRoles = useGetUserRole()
	const user = useSystemStore((store) => store.user)

	useSetSchool()
	const school = useSystemStore((store) => store.school)

	useGoToLiveLessonPageIfNecessary()

	if (!userRoles.rolesGot || !school) {
		return <Spin />
	}

	return (
		<Suspense fallback={<p>Loading</p>}>
			<ScrollManagerElement />
			{userRoles.isAdmin && <Routes>{getAdminRouter(user)}</Routes>}
			{userRoles.isManager && <Routes>{getManagerRouter(user)}</Routes>}
			{userRoles.isCurator && <Routes>{getCuratorRouter(user)}</Routes>}
			{userRoles.isStudent && <Routes>{getStudentRouter(user)}</Routes>}
		</Suspense>
	)
}
