import React from 'react'

export namespace ExerciseStateType {
	export type Main = {
		totalSlots: number // Общее количество вопросов
		rightAnswers: number // На сколько вопросов ученик дал ответ
		attempts: number // Количество доступных попыток
		textBlocks: TextBlock[]

		showResult: boolean // Показать ли окно результата
		readyToShowResult: boolean // Готово ли упражнение для показа результата (правильно введены все слова или вышли попытки)
		resultButtonText: string // Текст на кнопке показа результата
	}

	export type TextBlock = {
		id: number // id текстового блока
		htmlString: string // HTML c текстом
		words: Word[]
	}

	export type Word = {
		id: number // id слова
		word: string // Слово
	}
}

export const initialState: ExerciseStateType.Main = {
	totalSlots: 0,
	rightAnswers: 0,
	attempts: 0,
	textBlocks: [],
	showResult: false,
	readyToShowResult: false,
	resultButtonText: '',
}

export type DragEvent =
	| React.DragEvent<HTMLDivElement>
	| React.TouchEvent<HTMLDivElement>
