import { useMutation, useQuery } from 'react-query'
import groupRequests from './groupsRequest'
import EntityTypes from '../../types/EntityTypes'
import GroupsApiTypes from './groupsApiTypes'
import { MutationOptions, QueryOptions } from '../common'

export const groupQuery = {
	// Создаёт новую группу или изменяет существующую.
	createOrUpdateGroup: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(groupRequests.createOrUpdateGroup, options)
		},
	},

	/**
	 * Получение списка групп курса.
	 * @param trainingId — id курса
	 */
	getTrainingGroups(trainingId: number | string) {
		return {
			key: 'getTrainingGroups-' + trainingId,
			useQuery(
				options: QueryOptions<GroupsApiTypes.GetAdminTrainingGroups> = {}
			) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () =>
						groupRequests.getAdminTrainingGroups(trainingId),
					...options,
				})
			},
		}
	},

	getGroupById(groupId: number | string) {
		return {
			key: 'getGroup-' + groupId,
			useQuery(options: QueryOptions<GroupsApiTypes.GetGroup> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => groupRequests.getGroupById(groupId),
					...options,
				})
			},
		}
	},

	/**
	 * Получение кураторов группы.
	 * @param groupId — id группы
	 */
	getGroupCurators(groupId: number | string) {
		return {
			key: 'getTrainingGroups-' + groupId,
			useQuery(
				options: QueryOptions<GroupsApiTypes.GetGroupCurators> = {}
			) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => groupRequests.getGroupCurators(groupId),
					...options,
				})
			},
		}
	},

	// Активация группы
	activateGroup: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(groupRequests.activateGroup, options)
		},
	},

	// Удаление пользователя из группы
	deleteGroup: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(groupRequests.deleteGroup, options)
		},
	},

	// Восстановление группы
	restoreGroup: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(groupRequests.restoreGroup, options)
		},
	},

	// Удаление тарифа
	deleteTariff: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(groupRequests.deleteTariff, options)
		},
	},

	/**
	 * Получение списка групп, на которые поставлен пользователь.
	 * Для получения отдельной группы нужно воспользоваться сервисом GroupsService.getGroupFromGroupsArr()
	 */
	getUserGroups(userId: number | string) {
		return {
			key: 'getUserGroups-' + userId,
			useQuery(options: QueryOptions<GroupsApiTypes.GetUserGroups> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => groupRequests.getUserGroups(userId),
					...options,
				})
			},
		}
	},

	// Создание или редактирование тарифов группы
	createOrEditTariffs: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(groupRequests.createOrEditTariffs, options)
		},
	},

	/** Получение тарифов группы */
	getTariffs(groupId: number | string) {
		return {
			key: 'getTariffs-' + groupId,
			useQuery(options: QueryOptions<GroupsApiTypes.GetTariffs> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => groupRequests.getTariffs(groupId),
					...options,
				})
			},
		}
	},

	// Добавляет пользователя в группы.
	addUserToGroups: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(addUserToGroups, options)
		},
	},

	// Удаление пользователя из группы
	removeUserFromGroup: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(groupRequests.removeUserFromGroup, options)
		},
	},

	// Перемещение учеников между кураторами
	moveStudentsBetweenCurators: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(
				groupRequests.moveStudentsBetweenCurators,
				options
			)
		},
	},
}

export type AddUserToGroupsData = {
	// Какого сотрудника добавить в группы
	userId: number
	// Идентификаторы групп, в которые нужно добавить сотрудника
	groupIds: number[]
	// Роль сотрудника
	role: EntityTypes.UserRole
}

/**
 * Обработчик добавления сотрудника в несколько групп
 * @param data — данные какого сотрудника и в какие группы добавлять
 */
async function addUserToGroups(data: AddUserToGroupsData) {
	const dtos: GroupsApiTypes.AddUserToGroupDto[] = data.groupIds.map(
		(groupId) => {
			return {
				groupId,
				userId: data.userId,
				role: data.role,
			}
		}
	)

	return Promise.all(
		dtos.map((dto) => {
			return groupRequests.addUserToGroup(dto)
		})
	)
}
