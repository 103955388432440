import { DropWordsExerciseFormStateType } from './stateType'

/**
 * Функция находит и возвращает объект с описанием текстового блока по id.
 * @param {Object} formState — объект состояния формы
 * @param textObjId
 */
export function getTextObjById(
	formState: DropWordsExerciseFormStateType,
	textObjId: number
) {
	return formState.texts.find((textObj) => {
		return textObj.id === textObjId
	})
}
