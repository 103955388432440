import { Button, Form, Input, Alert } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import {
	UFormOldBottomButtons,
	UFormOld,
	UFormOldRow,
} from 'ui/UFormOld/UFormOld'
import { useManageForm } from './fn/submit'
import ServerTypes from 'parts/types/ServerTypes'
import StepMessage from '../../recoveryStart/Message/StepMessage'
import './RecoverySetForm.scss'

// Форма ввода нового пароля взамен забытого
function SetNewPasswordForm() {
	const [form] = Form.useForm()

	const { isLoading, onSubmit, formErrors } = useManageForm(form)

	return (
		<UFormOld>
			<Form
				form={form}
				name="newPassword"
				onFinish={onSubmit}
				className="recovery-set-form"
				layout="vertical"
			>
				<PasswordInputs />
				<Bottom loading={isLoading} />
				<GeneralError formErrors={formErrors} />
				<StepMessage step={3} />
			</Form>
		</UFormOld>
	)
}

export default SetNewPasswordForm

// Два поля с проверкой равнозначности введённых значений
function PasswordInputs() {
	const { t } = useTranslation()

	return (
		<>
			<UFormOldRow>
				<Form.Item
					name="password"
					label={t('auth.recoverSetPasswordFormFieldPasswordLabel')}
					rules={[
						{
							required: true,
							message: t(
								'auth.recoverSetPasswordFormFieldPasswordRequired'
							),
						},
						{
							min: 6,
							message: t(
								'auth.recoverSetPasswordFormFieldPasswordMin'
							),
						},
						{
							max: 21,
							message: t(
								'auth.recoverSetPasswordFormFieldPasswordMax'
							),
						},
					]}
				>
					<Input.Password type="password" size="large" />
				</Form.Item>
			</UFormOldRow>
			<UFormOldRow topOffset>
				<Form.Item
					name="passwordAgain"
					label={t(
						'auth.recoverSetPasswordFormFieldPasswordAgainLabel'
					)}
					rules={[
						{
							required: true,
							message: t(
								'auth.recoverSetPasswordFormFieldPasswordAgainRequired'
							),
						},
						{
							min: 6,
							message: t(
								'auth.recoverSetPasswordFormFieldPasswordMin'
							),
						},
						{
							max: 21,
							message: t(
								'auth.recoverSetPasswordFormFieldPasswordMax'
							),
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (
									!value ||
									getFieldValue('password') === value
								) {
									return Promise.resolve()
								}
								return Promise.reject(
									new Error(
										t(
											'auth.recoverSetPasswordFormFieldPasswordAgainMismatch'
										)
									)
								)
							},
						}),
					]}
				>
					<Input.Password type="password" size="large" />
				</Form.Item>
			</UFormOldRow>
		</>
	)
}

type BottomProps = {
	loading: boolean
}

// Кнопка отправки формы
// Кнопка возвращается в функции чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom(props: BottomProps) {
	const form = Form.useFormInstance()
	const { loading } = props

	const { t } = useTranslation()

	return (
		<UFormOldBottomButtons>
			<Form.Item
				shouldUpdate
				className="recovery-set-form__bottom-form-item"
			>
				{() => {
					const formErrors = form
						.getFieldsError()
						.filter(({ errors }) => errors.length)

					return (
						<Button
							block
							type="primary"
							htmlType="submit"
							disabled={loading || formErrors.length > 0}
							loading={loading}
							size="large"
						>
							{t('auth.recoverSetPasswordFormSetButton')}{' '}
							<ArrowRightOutlined />
						</Button>
					)
				}}
			</Form.Item>
		</UFormOldBottomButtons>
	)
}

type GeneralErrorProps = {
	formErrors: ServerTypes.ErrorResponse
}

// Показ общей ошибки при отправке формы
function GeneralError(props: GeneralErrorProps) {
	const { formErrors } = props

	return formErrors.message ? (
		<Alert message={formErrors.message} type="error" />
	) : null
}
