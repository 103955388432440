import ExerciseControlPanelContainer from '../../common/ExerciseControlPanelContainer/ExerciseControlPanelContainer'
import { useGetAttemptsText } from './fn/useGetAttemptsText'
import { useGetButtonsConfig } from './fn/getButtonsConfig'

type ExerciseControlPanelProps = {}

// Нижняя часть упражнения с кнопками управления упражнением
function ExerciseControlPanel(props: ExerciseControlPanelProps) {
	return (
		<ExerciseControlPanelContainer
			leftText={useGetAttemptsText()}
			buttons={useGetButtonsConfig()}
		/>
	)
}

export default ExerciseControlPanel
