import { useQueryClient } from 'react-query'
import { commentsQuery } from 'parts/requests/comments/commentsQuery'

export function useGetStoreAnswer() {
	const queryClient = useQueryClient()

	// Объект с методом mutate для создания запроса
	const answerToCommentMutation = commentsQuery.answerToComment.useMutation({
		onSuccess: () => {
			queryClient.invalidateQueries({
				predicate: (query) => {
					const queryKey = query.queryKey
					return (
						Array.isArray(queryKey) &&
						typeof queryKey[0] === 'string' &&
						queryKey[0].startsWith('getAllComments-')
					)
				},
			})
		},
	})

	return async (commentId: number, answer: string) => {
		answerToCommentMutation.mutate({
			commentId,
			answer,
		})
	}
}
