import { Loader } from 'ui'
import AnswersFeedContainer from '../../containers/MainContainer/MainContainer'
import { useFillInStore } from '../../zustand/fillStore/fillInStore'
import useAnswersFeedStore from '../../zustand/store'

function AnswersFeedRouter() {
	useFillInStore()

	const dataLoadingStatus = useAnswersFeedStore(
		(store) => store.dataLoadingStatus
	)

	if (dataLoadingStatus === 'loading') {
		return <Loader />
	}

	return <AnswersFeedContainer />
}

export default AnswersFeedRouter
