import { create } from 'zustand'
import { MakeSchoolStoreType } from './storeTypes'

const useMakeSchoolStore = create<MakeSchoolStoreType.State>((set) => {
	return {
		isSubmitting: false,
		formErrors: {},
		schoolDomain: '',

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useMakeSchoolStore
