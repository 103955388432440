import React, { ReactNode } from 'react'
import cn from 'classnames'
import './CardSticker.scss'

export enum CardStickerColors {
	Blue = 'blue',
	Purple = 'purple',
	Orange = 'orange',
	Green = 'green',
}

type BlockProps = {
	children: ReactNode
	// Текст на наклейке
	text: string
	// Цвет наклейки
	color: CardStickerColors
	extraClass?: string
}

// Обёртка, добавляющая оборачиваемому компоненту наклейку типа «Вы в этой школе», «Отключена», «Подключена» и так далее.
export function CardSticker(props: BlockProps) {
	const { children, text, color, extraClass = '' } = props

	return (
		<div
			className={cn(
				'card-sticker',
				'card-sticker--color-' + color,
				extraClass
			)}
			data-text={text}
		>
			{children}
		</div>
	)
}
