import { SyntheticEvent } from 'react'
import { useGoToPage } from 'parts/utils/hooks/hooks'
import AppUrls from 'parts/constants/pageUrl'
import useTrainingsAdminStore from '../../../TrainingsList/zustand/store'
import { produce } from 'immer'

/**
 * Возвращает функцию, открывающую в карточке курса список групп.
 * @param trainingId — id курса в карточке которого нужно открыть или закрыть таблицу групп.
 */
export function useGetOpenGroups(trainingId: number) {
	const [trainingsAdmin, updateStore] = useTrainingsAdminStore((state) => [
		state.trainingsAdmin,
		state.updateStore,
	])

	return function (event: SyntheticEvent) {
		event.preventDefault()

		const updatedTrainings = produce(trainingsAdmin, (draft) => {
			if (!draft) return

			const training = draft.find(
				(training) => training.id === trainingId
			)
			if (!training) return

			training.isGroupsTableOpen = !training.isGroupsTableOpen
		})

		updateStore({ trainingsAdmin: updatedTrainings })
	}
}

/**
 * Переадресовывает на страницу настроек группы
 * @param trainingId — id курса
 */
export function useGetGoToGroupSettings(trainingId: number) {
	const goToGroupSettings = useGoToPage(
		AppUrls.Training_Groups(trainingId).url
	)

	return function (event: SyntheticEvent) {
		event.preventDefault()

		goToGroupSettings()
	}
}
