import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Col, Button, Form, Input, Row } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import {
	UFormOldBottomButtons,
	UFormOld,
	UFormOldRow,
} from 'ui/UFormOld/UFormOld'
import AppUrls from 'parts/constants/pageUrl'
import autoLogin from 'parts/services/AutoLogin'
import { UFormNewGeneralError } from 'ui/UForm/UForm'
import useLiveLessonEntrancePageStore from '../../../../liveLessons/liveLessonEntrance/zustand/store'
import { setErrorsToForm } from './fn/form'
import { useGetOnSubmit } from './fn/submit'
import useLoginFormStore from './zustand/store'
import './LoginForm.scss'

// Формы регистрации администратора
function LoginForm() {
	// Это должно срабатывать на домене школы, если в local storage записан токен
	autoLogin.useMakeAutoLogin()

	const [form] = Form.useForm()

	const onSubmit = useGetOnSubmit()
	setErrorsToForm(form)

	const formErrors = useLoginFormStore((state) => state.formErrors)

	return (
		<UFormOld>
			<Form
				form={form}
				name="login"
				onFinish={onSubmit}
				layout="vertical"
			>
				<EmailInput />
				<PasswordInput />
				<ForgotPassword />
				<Bottom />
				{formErrors?.message && (
					<UFormNewGeneralError message={formErrors?.message} />
				)}
			</Form>
		</UFormOld>
	)
}

export default LoginForm

function EmailInput() {
	const { t } = useTranslation()

	// Если пользователь ввёл свою почту на странице входа в эфир, то получить и поставить в качестве начального значения
	const emailFromLiveLessonEnterPage = useLiveLessonEntrancePageStore(
		(store) => store.email
	)

	return (
		<UFormOldRow>
			<Form.Item
				name="email"
				label={t('auth.loginFormFieldEmailLabel')}
				rules={[
					{
						type: 'email',
						message: t('auth.loginFormFieldEmailWrongFormat'),
					},
					{
						required: true,
						message: t('auth.loginFormFieldEmailRequired'),
					},
				]}
				initialValue={emailFromLiveLessonEnterPage || ''}
			>
				<Input
					type="email"
					placeholder="mail@mail.com"
					size="large"
					autoComplete="username"
					data-cy="login-form-email-input"
				/>
			</Form.Item>
		</UFormOldRow>
	)
}

function PasswordInput() {
	const { t } = useTranslation()

	return (
		<UFormOldRow topOffset>
			<Form.Item
				name="password"
				label={t('auth.loginFormFieldPasswordLabel')}
				rules={[
					{
						required: true,
						message: t('auth.loginFormFieldPasswordRequired'),
					},
					{
						min: 6,
						message: t('auth.loginFormFieldPasswordMin'),
					},
					{
						max: 21,
						message: t('auth.loginFormFieldPasswordMax'),
					},
				]}
			>
				<Input.Password
					type="password"
					size="large"
					autoComplete="current-password"
					data-cy="login-form-password-input"
				/>
			</Form.Item>
		</UFormOldRow>
	)
}

function ForgotPassword() {
	const { t } = useTranslation()

	return (
		<UFormOldRow topOffset>
			<Row justify="end">
				<Col>
					<Link to={AppUrls.RecoveryStart().url}>
						{t('auth.loginFormFieldForgetPassword')}
					</Link>
				</Col>
			</Row>
		</UFormOldRow>
	)
}

// Кнопка отправки формы
function Bottom() {
	const form = Form.useFormInstance()
	const { t } = useTranslation()

	const isSubmitting = useLoginFormStore((state) => state.isSubmitting)

	return (
		<UFormOldBottomButtons>
			<Form.Item
				shouldUpdate
				className="login-page-content__bottom-form-item"
			>
				{() => {
					const formErrors = form
						.getFieldsError()
						.filter(({ errors }) => errors.length)

					return (
						<Button
							block
							size="large"
							type="primary"
							htmlType="submit"
							disabled={isSubmitting || formErrors.length > 0}
							loading={isSubmitting}
							data-cy="login-form-submit-btn"
						>
							{t('auth.loginFormFieldEnterButton')}{' '}
							<ArrowRightOutlined />
						</Button>
					)
				}}
			</Form.Item>
		</UFormOldBottomButtons>
	)
}
