import { useTranslation } from 'react-i18next'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import Footer from 'ui/Footer/Footer'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import FilesArticle from './FilesArticle/FilesArticle'
import AppUrls from 'parts/constants/pageUrl'

function FilesPage() {
	const { t } = useTranslation()
	const userRoles = useGetUserRole()

	return (
		<PageSkeleton>
			<PageHeader
				breadcrumbs={[
					{
						path: AppUrls.MyFiles().url,
						breadcrumbName: AppUrls.MyFiles().name,
					},
				]}
				header={t('files.pageHeader')}
			/>
			<FilesArticle />
			<Footer isRightSideHidden={!userRoles.isAdminOrManager} />
		</PageSkeleton>
	)
}

export default FilesPage
