import { useState } from 'react'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useTranslation } from 'react-i18next'
import { StateType } from './stateType'

/**
 * Создаёт и возвращает объект Состояния и функцию обновления объекта Состояния компонента
 * @param {String} exerciseType — тип упражнения
 * @param {String} initialPrompt — изначальный текст задания на генерирование текста
 * @param {Function} getGeneratedText — функция получающая сгенерированный текст
 * @param {Function} getGeneratedPrompt — функция получающая задание на генерирование текста
 */
export function useGetState(
	exerciseType: TrainingEntityTypes.ExerciseTypesWithGenerator,
	initialPrompt: string,
	getGeneratedText: (text: string) => void,
	getGeneratedPrompt: (text: string) => void
) {
	const [state, setState] = useState<StateType.State>(
		getInitialState(
			exerciseType,
			initialPrompt,
			getGeneratedText,
			getGeneratedPrompt
		)
	)

	return {
		state,
		setState,
	}
}

/**
 * Возвращает первоначальный вид Состояния компонента
 * @param {String} exerciseType — тип упражнения
 * @param {String} initialPrompt —
 * @param {Function} getGeneratedText — функция получающая сгенерированный текст
 * @param {Function} getGeneratedPrompt — функция получающая задание на генерирование текста
 */
function getInitialState(
	exerciseType: TrainingEntityTypes.ExerciseTypesWithGenerator,
	initialPrompt: string,
	getGeneratedText: (text: string) => void,
	getGeneratedPrompt: (text: string) => void
): StateType.State {
	return {
		exerciseType,
		open: true,
		introText: useGetIntroText(exerciseType),
		examples: getExamples(exerciseType),
		bottomText: getBottomText(exerciseType),
		lifeStatus: initialPrompt ? 'success' : 'empty',
		promptText: initialPrompt,
		getGeneratedText,
		getGeneratedPrompt,
		error: null,
	}
}

/**
 * Возвращает вводный текст блока генерирования текста
 * @param {String} exerciseType — тип упражнения
 */
function useGetIntroText(
	exerciseType: TrainingEntityTypes.ExerciseTypesWithGenerator
) {
	const { t } = useTranslation()

	if (exerciseType == 'text') {
		return 'Текст можно сгенерировать. В поле ниже напишите задачу. Например:'
	} else if (
		exerciseType == 'test-single-answer' ||
		exerciseType == 'test-many-answers'
	) {
		return 'Напишите запрос на генерирование текста вопроса и ответов. Например:'
	} else if (exerciseType == 'trueOrFalse') {
		return 'Напишите запрос на генерирование текста утверждения. Например:'
	} else if (exerciseType == 'gapsInWords') {
		return 'Напишите запрос на генерирование текста упражнения. Например:'
	} else if (exerciseType == 'chooseWord') {
		return 'Напишите запрос на генерирование текста упражнения. Например:'
	} else if (exerciseType == 'dropWords') {
		return 'Напишите запрос на генерирование текста упражнения. Например:'
	}

	return ''
}

/**
 * Возвращает вводный текст блока генерирования текста
 * @param {String} exerciseType — тип упражнения
 */
function getBottomText(
	exerciseType: TrainingEntityTypes.ExerciseTypesWithGenerator
) {
	if (
		exerciseType == 'test-single-answer' ||
		exerciseType == 'test-many-answers'
	) {
		return 'Вопросы и ответы будут вписаны ниже.'
	}

	return undefined
}

/**
 * Возвращает массив текстов примеров запросов, которые может ввести пользователь.
 * Эти примеры различаются в зависимости от типа упражнения
 * @param {String} exerciseType — тип упражнения
 */
function getExamples(
	exerciseType: TrainingEntityTypes.ExerciseTypesWithGenerator
): string[] {
	if (exerciseType == 'text') {
		return [
			'Сгенерируй текст на английском языке про английских писателей для уровня Starter.',
			'Напиши 5 предложений для изучения английских предлогов на английском.',
		]
	} else if (exerciseType == 'test-single-answer') {
		return [
			'Напиши 5 вопросов про строительство на английском языке. В каждом 3 ответа.',
			'Создай 10 вопросов по типу multiple choice на тему одежда для изучающих английский на уровне pre-intermediate.',
		]
	} else if (exerciseType == 'trueOrFalse') {
		return [
			'Создай упражнение по английскому по типу true or false на тему the weather для изучающих английский на уровне elementary',
		]
	} else if (exerciseType == 'gapsInWords') {
		return [
			'Создай упражнение по английскому по типу fill in the gaps используя слова по теме "одежда" для тренировки на уровне pre-intermediate.',
		]
	} else if (exerciseType == 'chooseWord') {
		return [
			'Создай упражнение на английском по типу Choose the Correct Form на тему Past Simple для изучающих английский на уровень pre-intermediate.',
		]
	} else if (exerciseType == 'dropWords') {
		return [
			'Создай предложения на английском по типу Choose the Correct Form на тему Past Simple.',
		]
	}

	return ['']
}
