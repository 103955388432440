import { Outlet } from 'react-router-dom'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import PageHeaderAdmin from '../trainingPageHeader/PageHeaderAdmin/PageHeaderAdmin'
import PageHeaderManagerCurator from '../trainingPageHeader/PageHeaderManagerCurator/PageHeaderManagerCurator'
import Footer from 'ui/Footer/Footer'

/** Заголовок страницы уроков курса для администратора, менеджера и куратора */
function TrainingStaffPage() {
	const userRoles = useGetUserRole()

	return (
		<PageSkeleton>
			{userRoles.isAdmin ? (
				<PageHeaderAdmin />
			) : (
				<PageHeaderManagerCurator />
			)}
			<Outlet />
			<Footer isRightSideHidden={!userRoles.isAdminOrManager} />
		</PageSkeleton>
	)
}

export default TrainingStaffPage
