import { Modal } from 'antd'
import ModalTop from '../ModalTop/ModalTop'
import usePayModalStore from '../zustand/store'
import { useGetChangeModalVisibility } from './fn/fn'
import BuyForm from '../BuyForm/BuyForm'

function MakeOrderModal() {
	const isModalOpen = usePayModalStore((state) => state.isModalOpen)
	const closeModal = useGetChangeModalVisibility(false, null, null)

	return (
		<Modal
			width={544}
			open={isModalOpen}
			footer={null}
			keyboard={true}
			onCancel={closeModal}
		>
			<div>
				<ModalTop />
				<BuyForm />
			</div>
		</Modal>
	)
}

export default MakeOrderModal
