import { useCallback, useEffect, useState } from 'react'
import UrlService from 'parts/services/UrlService'
import { useIsFirstRender } from 'parts/utils/hooks/hooks'
import { SaveExerciseChangesMadeByUser } from '../../../common/useLiftViewDuration'
import useExerciseStore, {
	ExerciseStateType,
} from '../../zustand/exerciseState'
import { createTextButton, disable$button } from './manipulateElems'

type RestoreDataType = {
	// Описание вставленные в слоты по порядку.
	slotsTexts: string[]
	store: Omit<ExerciseStateType.State, 'updateStore'>
}

/**
 * Возвращает обработчик перетаскивания кнопки с текстом в дырку.
 * После формируется объект с текстом брошенных кнопок в дырки и сохраняется на сервере
 * чтобы при открытии упражнения в следующий раз было восстановлено положение кнопок с текстом, которое сделал ученик.
 */
export function useGetDropWordHandler(
	$generalWrapper: null | HTMLDivElement,
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
) {
	const exerciseId = UrlService.useGetExerciseId()
	const store = useExerciseStore((store) => store)

	return useCallback(
		function () {
			if (!$generalWrapper || !saveExerciseChangesMadeByUser) return

			const restoreData = getRestoreObj($generalWrapper, store)

			// Передать в функцию, которая сохранит данные на сервере.
			saveExerciseChangesMadeByUser(restoreData)
		},
		[$generalWrapper, exerciseId]
	)
}

export function useSaveStateOnServerForRestore(
	$generalWrapper: null | HTMLDivElement,
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
) {
	// Задержка, чтобы не сохранял данные после открытия страницы
	const isFirstRender = useIsFirstRender(2000)
	const store = useExerciseStore((store) => store)

	useEffect(
		function () {
			if (
				isFirstRender ||
				!$generalWrapper ||
				!saveExerciseChangesMadeByUser
			)
				return

			const restoreData = getRestoreObj($generalWrapper, store)

			// Передать в функцию, которая сохранит данные на сервере.
			saveExerciseChangesMadeByUser(restoreData)
		},
		[store.saveStoreForRestore]
	)
}

function getRestoreObj(
	$generalWrapper: HTMLDivElement,
	store: ExerciseStateType.State
) {
	const { updateStore, ...storeClone } = store

	// Объект данных, сохраняемый на сервере.
	const slotsRestoreObj: RestoreDataType = {
		slotsTexts: [],
		store: storeClone,
	}

	// Слоты куда ученик перетаскивает кнопки
	const $slots = get$slots($generalWrapper)

	// Перебрать слоты
	for (let i = 0; i < $slots.length; i++) {
		// Поставить в данные текст кнопки, который должен быть в слоте.
		// При восстановлении сохранённый текст будет преобразована в перетащенную кнопку.
		slotsRestoreObj.slotsTexts.push($slots[i].innerText)
	}

	return slotsRestoreObj
}

// ==========================

export function useRestoreExercise(
	exerciseRestoreDataPromise: Promise<RestoreDataType>
) {
	const images = useExerciseStore((store) => store.images)
	const updateStore = useExerciseStore((store) => store.updateStore)

	const $generalWrapper: null | HTMLDivElement = document.querySelector(
		'.exercise-desc-image-content__exercise-wrapper'
	)

	const [imagesIsReady, setImagesIsReady] = useState(false)

	useEffect(
		function () {
			if (!images) return

			setImagesIsReady(true)
		},
		[images]
	)

	useEffect(
		function () {
			if (!imagesIsReady || !$generalWrapper) return

			exerciseRestoreDataPromise.then((restoreData) => {
				if (!restoreData?.store) return

				// Расставить текст по слотам изображений
				// Автоматически перетащить кнопки в те места, куда они были перетащены в прошлый раз.
				setWordsInSlots($generalWrapper, restoreData.slotsTexts)
				updateStore(restoreData.store)
			})
		},
		[imagesIsReady]
	)
}

/**
 *
 * @param $generalWrapper
 * @param restoreData
 */
function setWordsInSlots(
	$generalWrapper: HTMLDivElement,
	restoreData: string[]
) {
	const $words = $generalWrapper.querySelector(
		'.exercise-desc-image-content__words'
	)
	if (!$words) return

	// Слоты куда ученик перетаскивает кнопки
	const $slots = get$slots($generalWrapper)

	// Перебрать слоты
	for (let i = 0; i < $slots.length; i++) {
		// Текст, который должен быть в дырке
		const textForSlot = restoreData[i]

		// Найти кнопку с этим текстом на обёртке кнопок
		const textButton = getTextButtonByText($words, textForSlot)
		if (!textButton) continue

		// Сделать кнопку неперетаскиваемой чтобы её нельзя было перетащить ещё раз.
		// Ниже сделаю кнопку, которая будет в дырке и уже её перетащить можно.
		disable$button(textButton)

		// Создать перетащенную кнопку
		const draggedButton = createTextButton(
			textForSlot,
			textButton.dataset.wordId!
		)

		// Поставить в дырку перетащенную кнопку
		$slots[i].append(draggedButton)
	}
}

/**
 * Находит в главной обёртке упражнения слоты куда перетаскиваются кнопки описания изображений
 * @param $generalWrapper — обёртка кнопок, изображений и описаний.
 */
function get$slots($generalWrapper: HTMLDivElement): NodeListOf<HTMLElement> {
	// Обёртка с текстовыми блоками с дырками куда ученик перетаскивает кнопки
	return $generalWrapper.querySelectorAll(
		'.exercise-desc-image-content__slot'
	)
}

/**
 * Ищет в обёртке с кнопками кнопку с переданным текстом
 * @param $words — обёртка с кнопками для перетаскивания
 * @param text — текст, который должен быть у искомой кнопке
 */
function getTextButtonByText(
	$words: Element,
	text: string
): null | HTMLElement {
	const buttons = $words.querySelectorAll(
		'.exercise-desc-image-content__word'
	)

	for (let i = 0; i < buttons.length; i++) {
		const thisButton = buttons[i]

		if (thisButton.textContent === text) {
			return thisButton as HTMLElement
		}
	}

	return null
}
