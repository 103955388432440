import { ReactNode } from 'react'
import { useQueryClient } from 'react-query'
import { groupQuery } from 'parts/requests/group/groupQuery'
import AddGroupModal from './AddGroupModal/AddGroupModal'
import useAddGroupModalStore from './zustand/store'

// Контроллер модального окна создания новой группы
export const AddGroupModalController = {
	element(
		trainingId: number,
		isActive = false,
		elemBeforeForm?: ReactNode,
		refreshTrainingAfterCreation?: boolean
	) {
		return (
			<AddGroupModal
				trainingId={trainingId}
				isActive={isActive}
				elemBeforeForm={elemBeforeForm}
				refreshTrainingAfterCreation={refreshTrainingAfterCreation}
			/>
		)
	},
	useGetOpen() {
		setVisibilityToAddGroupModal(true)
	},
	useGetClose() {
		setVisibilityToAddGroupModal(false)
	},
	// Функция, помечающая данные списка групп неактуальными. После этого React Query скачает новый список групп.
	useGetUpdate: function (trainingId: string | number) {
		const queryClient = useQueryClient()

		return function () {
			// Пометить данные курса неактуальными. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [groupQuery.getTrainingGroups(trainingId).key],
			})
		}
	},
}

/**
 * Возвращает функцию, ставящую видимость модального окна создания новой группы
 * @param isVisible — должно ли модальное окно быть видимым?
 */
function setVisibilityToAddGroupModal(isVisible: boolean) {
	const { updateStore } = useAddGroupModalStore.getState()

	updateStore({ isModalOpen: isVisible })
}
