import { useCallback, useEffect, useState } from 'react'
import UrlService from 'parts/services/UrlService'
import { SaveExerciseChangesMadeByUser } from '../../common/useLiftViewDuration'

// Тип объекта, в котором будут храниться данные для восстановления значений в выпадающих списках, сделанные учеником.
type BlocksSelectRestore = {
	// Текстовый блок — это массив строк с выбранными значениями.
	// Поэтому текстовые блоки — это массивы массивов строк
	textBlocks: string[][]
}

/**
 * Возвращает обработчик потерей фокуса обёртки текстовых блоков в упражнении.
 * Срабатывает при потере фокуса одного из редактируемых элементов.
 * После формируется объект с введённым текстом в редактируемые элементы и сохраняется на сервере
 * чтобы при открытии упражнения в следующий раз в редактируемые элементы был вставлен введённый текст.
 */
export function useGetInputContentHandler(
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
) {
	const exerciseId = UrlService.useGetExerciseId()

	return useCallback(
		function (e: any) {
			if (!saveExerciseChangesMadeByUser) return

			const blocksSelectsRestoreObj: BlocksSelectRestore = {
				textBlocks: [],
			}

			// Обёртка с текстовыми блоками с выпадающими списками из которых ученик выбирает значение
			const $textBlocksWrapper = e.target.closest(
				'.exercise-choose-word-content__text-blocks'
			)

			for (let i = 0; i < $textBlocksWrapper.children.length; i++) {
				// Блок текста c выпадающими списками
				const $textBlock = $textBlocksWrapper.children[i]

				// Выпадающие списки
				const $selectLists = $textBlock.querySelectorAll(
					'.exercise-choose-word-content__select'
				)

				for (let j = 0; j < $selectLists.length; j++) {
					if (!blocksSelectsRestoreObj.textBlocks[i]) {
						blocksSelectsRestoreObj.textBlocks.push([])
					}

					blocksSelectsRestoreObj.textBlocks[i].push(
						$selectLists[j].value
					)
				}
			}

			saveExerciseChangesMadeByUser(blocksSelectsRestoreObj)
		},
		[exerciseId]
	)
}

/**
 * Получает данные для восстановления текста, который ученик написал в текстовом редакторе ответа в прошлый раз и возвращает их.
 * @param $textBlocksWrapper — обёртка текстовых блоков с редактируемыми дырками.
 * @param exerciseRestoreData — данные для восстановления упражнения.
 */
export function useRestoreSelectListsTextBlockOnMount(
	$textBlocksWrapper: null | HTMLDivElement,
	exerciseRestoreData?: Promise<BlocksSelectRestore>
) {
	const exerciseId = UrlService.useGetExerciseId()
	const [blocksSelectValues, setBlocksSelectValues] = useState<string[][]>([])

	useEffect(
		function () {
			if (!exerciseRestoreData) return

			exerciseRestoreData.then((data) => {
				if (!data?.textBlocks) return

				setBlocksSelectValues(data.textBlocks)
			})
		},
		[exerciseId]
	)

	useEffect(
		function () {
			if (!blocksSelectValues.length || !$textBlocksWrapper) return

			for (let i = 0; i < $textBlocksWrapper.children.length; i++) {
				// Блок текста с выпадающими списками
				const $textBlock = $textBlocksWrapper.children[i]

				// Выпадающие списки
				const $selects: NodeListOf<HTMLSelectElement> =
					$textBlock.querySelectorAll(
						'.exercise-choose-word-content__select'
					)

				// Перебрать выпадающие списки и установить сохранённые значения
				for (let j = 0; j < $selects.length; j++) {
					// Пропустить если в данных нет текста для этого выпадающего списка
					if (!blocksSelectValues[i] || !blocksSelectValues[i][j])
						continue

					// Поставить сохранённое значение
					$selects[j].value = blocksSelectValues[i][j]
				}
			}
		},
		[blocksSelectValues]
	)
}
