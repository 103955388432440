export type TextExerciseFormStateType = {
	exerciseId: null | number // id упражнения. Если это новое упражнение, то id не существует
	type: 'text' // Тип упражнения
	order: number
	// Поле «Задание»
	taskInput: {
		value: string // Значение поля «Задание к видео»
	}
	// Поле «Текст»
	textInput: {
		value: string // Значение поля «Задание к видео»
		error: string // Сообщение об ошибке
	}
	// Сгенерированный текст, который нужно поместить в поле с текстом
	generatedText: string
	// Текст задания на генерирование текста
	generatedTextPrompt: string
	isFormInvalid: boolean // Является ли форма неверной
}

// Объект начального состояния формы видео-упражнения
export const formInitialState: TextExerciseFormStateType = {
	exerciseId: null,
	type: 'text',
	order: 0,
	taskInput: {
		value: '',
	},
	textInput: {
		value: '',
		error: '',
	},
	generatedText: '',
	generatedTextPrompt: '',
	isFormInvalid: false,
}
