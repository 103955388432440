import cn from 'classnames'
import useLandingConstructorStore from '../../../../zustand/store'
import EntityTypes from 'parts/types/EntityTypes'
import { theses } from './fn/cardData'
import './NoActiveGroupCard.scss'

// Карточка тарифа
function NoActiveGroupCard() {
	return (
		<div className="no-group-tariff-card">
			<Header />
			<Description />
			<Theses />
			<Price />
			<BuyButton />
		</div>
	)
}

export default NoActiveGroupCard

function Header() {
	const colorScheme = useLandingConstructorStore(
		(store) => store.landing.colorScheme
	)

	const classes = [
		'no-group-tariff-card__header',
		'no-group-tariff-card__header--' + colorScheme,
	]

	return <h2 className={cn(classes)}>Базовый</h2>
}

function Description() {
	return (
		<p className="no-group-tariff-card__description">
			Подходит для самостоятельного изучения, без обратной связи
		</p>
	)
}

function Theses() {
	return (
		<div className="no-group-tariff-card__theses">
			{theses.map((thesis, i) => {
				return thesis.text ? <Thesis thesis={thesis} key={i} /> : null
			})}
		</div>
	)
}

type ThesisProps = {
	thesis: EntityTypes.TariffThesis
}

function Thesis(props: ThesisProps) {
	const { thesis } = props

	const colorScheme = useLandingConstructorStore(
		(store) => store.landing.colorScheme
	)

	const thesisTextCls = [
		'no-group-tariff-card__thesis-text',
		'no-group-tariff-card__thesis-text--' + colorScheme,
	]

	return (
		<div className="no-group-tariff-card__thesis">
			<p className={cn(thesisTextCls)}>{thesis.text}</p>
		</div>
	)
}

// Основная цена курса
function Price() {
	const colorScheme = useLandingConstructorStore(
		(store) => store.landing.colorScheme
	)

	return (
		<div className="no-group-tariff-card__price">
			<div className="no-group-tariff-card__price-part">
				<p
					className={cn(
						'no-group-tariff-card__price-price',
						'no-group-tariff-card__price-price--' + colorScheme
					)}
				>
					10
				</p>
				<p
					className={cn(
						'no-group-tariff-card__price-currency',
						'no-group-tariff-card__price-currency--' + colorScheme
					)}
				>
					$
				</p>
			</div>
			<div className="no-group-tariff-card__price-part">
				<p className="no-group-tariff-card__price-price no-group-tariff-card__price-price--old">
					12
				</p>
				<p
					className={cn(
						'no-group-tariff-card__price-currency',
						'no-group-tariff-card__price-currency--old'
					)}
				>
					$
				</p>
			</div>
		</div>
	)
}

function BuyButton() {
	const colorScheme = useLandingConstructorStore(
		(store) => store.landing.colorScheme
	)

	const classes = [
		'no-group-tariff-card__buy-btn',
		'no-group-tariff-card__buy-btn--' + colorScheme,
	]

	return <button className={cn(classes)}>Купить</button>
}
