import { useMutation, useQuery } from 'react-query'
import { MutationOptions, QueryOptions } from '../common'
import LessonsApiTypes from './lessonsApiTypes'
import lessonsRequests from './lessonsRequests'
import PresetsEntityTypes from 'parts/types/PresetsEntityTypes'

export const lessonsQuery = {
	// Добавление нового урока (школы или курса)
	createLesson: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(lessonsRequests.createLesson, options)
		},
	},

	// Изменение урока (школы или курса)
	updateLesson: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(lessonsRequests.updateLesson, options)
		},
	},

	// Получение списка уроков школы
	getSchoolLessons: {
		key: 'getLessons',
		useQuery(options: QueryOptions<LessonsApiTypes.GetLessons> = {}) {
			return useQuery({
				queryKey: [this.key],
				queryFn: lessonsRequests.getLessons,
				...options,
			})
		},
	},

	// Получение урока школы.
	getLesson(lessonId: number | string) {
		return {
			key: 'getLesson-' + lessonId,
			useQuery(options: QueryOptions<LessonsApiTypes.GetLesson> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => lessonsRequests.getLesson(lessonId),
					...options,
				})
			},
		}
	},

	// получение уроков от курса
	getTrainingLessons(trainingId: number | string) {
		return {
			key: 'getTrainingLessons' + trainingId,
			useQuery(options: QueryOptions<LessonsApiTypes.GetLessons> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () =>
						lessonsRequests.getTrainingLessons(trainingId),
					...options,
				})
			},
		}
	},

	// Удаление урока школы.
	deleteLesson: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(lessonsRequests.deleteLesson, options)
		},
	},

	// Получение списка шаблонов предустановленных уроков
	getPresets() {
		return {
			key: 'getPresets',
			useQuery(options: QueryOptions<PresetsEntityTypes.Preset[]> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => lessonsRequests.getPresets(),
					...options,
				})
			},
		}
	},

	// Добавление нового урока по шаблону
	applyPreset: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(lessonsRequests.applyPreset, options)
		},
	},

	// Удаление шаблона урока
	deletePreset: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(lessonsRequests.deletePreset, options)
		},
	},

	// Назначение урока шаблоном
	makeLessonPreset: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(lessonsRequests.makeLessonPreset, options)
		},
	},

	// Сообщение серверу об открытии урока чтобы это учитывалось в статистике
	openLessonSignal: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(lessonsRequests.openLessonSignal, options)
		},
	},
}
