import { Connection } from 'openvidu-browser'
import { v4 as uuidv4 } from 'uuid'
import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'

export enum ParticipantRole {
	Teacher = 'teacher',
	Student = 'student',
}

export enum ParticipantVideoType {
	Camera = 'camera',
	Screen = 'screen',
}

export interface IServerData {
	id: number
	email: string
	firstName: string
	lastName: string
	roles: string[]
	avatar: string
}

export interface IClientData {
	participantId: number
	participantRole: ParticipantRole
	participantVideoType: ParticipantVideoType
}

export function getConnectionData(connection: Connection) {
	const clientData = JSON.parse(connection.data.split('%/%')[0])
		.clientData as IClientData
	const serverData = JSON.parse(connection.data.split('%/%')[1])
		.serverData as IServerData

	return {
		clientData,
		serverData,
	}
}

export function getScreenTab(
	participantId?: number,
	tabId?: string
): LiveLessonEntityTypes.Tab {
	const screenTabId = tabId || uuidv4()

	const screenTab: LiveLessonEntityTypes.Tab = {
		id: screenTabId,
		type: LiveLessonEntityTypes.TabType.ScreenShare,
		name: 'Демонстрация экрана',
		extra: {
			view: 'screen',
			participantId,
		},
	}

	return screenTab
}

export function getStorageTab(tabId?: string) {
	const storageTabId = tabId || uuidv4()

	const storageTab: LiveLessonEntityTypes.Tab = {
		id: storageTabId,
		type: LiveLessonEntityTypes.TabType.Library,
		name: 'Файлы',
		extra: null,
	}

	return storageTab
}

export function getPlayerTab(
	track: LiveLessonEntityTypes.StorageItem,
	tabId?: string
) {
	const playerTabId = tabId || uuidv4()

	const playerTab: LiveLessonEntityTypes.Tab = {
		id: playerTabId,
		type: LiveLessonEntityTypes.TabType.Player,
		name: 'Видео',
		extra: {
			track,
		},
	}

	return playerTab
}

export function getPdfTab(
	track: LiveLessonEntityTypes.StorageItem,
	tabId?: string
) {
	const pdfTabId = tabId || uuidv4()

	const pdfTab: LiveLessonEntityTypes.Tab = {
		id: pdfTabId,
		type: LiveLessonEntityTypes.TabType.PdfViewer,
		name: track.name,
		extra: {
			track,
		},
	}

	return pdfTab
}

export function getCurrentPath(
	storageItems: LiveLessonEntityTypes.StorageItem[] | undefined,
	folderId: number,
	useVisibleName = true
) {
	if (!storageItems) {
		throw new Error('Storage items not found')
	}

	let folder = storageItems.find((item) => item.id === folderId)

	if (!folder) {
		throw new Error('Folder not found')
	}

	let path = [
		{
			id: folder.id,
			name: useVisibleName ? folder.name : folder.s3link,
			parentId: folder.parentId,
		},
	]

	while (folder.parentId) {
		folder = storageItems.find((item) => item.id === folder?.parentId)
		if (!folder) {
			throw new Error('Folder not found')
		}
		path = [
			{
				id: folder.id,
				name: useVisibleName ? folder.name : folder.s3link,
				parentId: folder.parentId,
			},
			...path,
		]
	}

	return path
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: number, si = false, dp = 1) {
	const thresh = si ? 1000 : 1024

	if (Math.abs(bytes) < thresh) {
		return bytes + ' B'
	}

	const units = si
		? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
	let u = -1
	const r = 10 ** dp

	do {
		bytes /= thresh
		++u
	} while (
		Math.round(Math.abs(bytes) * r) / r >= thresh &&
		u < units.length - 1
	)

	return bytes.toFixed(dp) + ' ' + units[u]
}

const FileTypes: Record<string, string> = {
	'application/pdf': 'PDF document',
	'application/msword': 'Word document',
	'application/msexcel': 'Excel document',
	'audio/mpeg': 'MP3 audio',
	'audio/wav': 'WAV audio',
	'audio/x-wav': 'WAV audio',
	'audio/ogg': 'Ogg audio',
}

export function humanFileType(mimeFileType: string) {
	if (mimeFileType in FileTypes) {
		return FileTypes[mimeFileType]
	}

	return mimeFileType
}

export function getParticipantName(participant: {
	firstName?: string
	lastName?: string
}) {
	return `${participant.firstName || 'Guest'} ${participant.lastName || ''}`
}

export function getUpdatedParticipantsList(
	currentList: LiveLessonEntityTypes.Participant[],
	updatedList: LiveLessonEntityTypes.Participant[]
) {
	const currentParticipants = new Set(currentList.map((p) => p.id))
	const updatedParticipants = new Set(updatedList.map((p) => p.id))

	const newList = new Set(
		currentList.filter((p) => updatedParticipants.has(p.id))
	)

	for (const participant of updatedList) {
		if (!currentParticipants.has(participant.id)) {
			currentParticipants.add(participant.id)
			newList.add(participant)
		}
	}

	return [...newList.values()]
}

export function getFileNameWOSpace(folderName: string) {
	return folderName.replace(/\s/g, '_')
}
