import { Loader } from 'ui'
import { useFillInStore } from '../zustand/fillStore/fillStore'
import CommentsContainer from '../containers/MainContainer/MainContainer'
import useCommentsStore from '../zustand/store'

function CommentsRouter() {
	useFillInStore()

	const dataLoadingStatus = useCommentsStore(
		(store) => store.dataLoadingStatus
	)

	if (dataLoadingStatus === 'loading') {
		return <Loader />
	}

	return <CommentsContainer />
}

export default CommentsRouter
