import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import AppUrls from 'parts/constants/pageUrl'
import CuratorStudentsArticle from '../CuratorStudentsArticle/CuratorStudentsArticle'

/** Страница со списком учеников */
function CuratorStudentsPage() {
	return (
		<PageSkeleton>
			<PageHeader header={AppUrls.Students().name} />
			<CuratorStudentsArticle />
		</PageSkeleton>
	)
}

export default CuratorStudentsPage
