import React from 'react'
import AppUrls from 'parts/constants/pageUrl'
import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import ExpressCourses from 'widgets/expressCourse/Courses/Courses'
import Footer from 'ui/Footer/Footer'

/** Страница со списком курсов */
export default function ExpressAdminPage() {
	return (
		<PageSkeleton>
			<PageHeader
				breadcrumbs={[
					{
						path: AppUrls.MyExpressCourses().url,
						breadcrumbName: AppUrls.MyExpressCourses().name,
					},
				]}
				header={AppUrls.Main().name}
				buttons={[]}
			/>
			<ExpressCourses />
			<Footer />
		</PageSkeleton>
	)
}
