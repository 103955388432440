import { ErrorCodeType } from '../ErrorPage'
import forbiddenImgUrl from '../img/403.svg'
import notFoundImgUrl from '../img/404.svg'

export function getText(errorCode: ErrorCodeType) {
	let headerText = ''
	let descText = ''

	if (errorCode == 403) {
		headerText = 'Ошибка 403'
		descText =
			'Вы запросили страницу, доступ к которой ограничен специальными правами. '
	} else if (errorCode == 404) {
		headerText = 'Ошибка 404'
		descText =
			'Страница не найдена!  Возможно она устарела, была удалена, или был введен неверный адрес в адресной строке.'
	} else if (errorCode == 500) {
		headerText = 'Что-то пошло не так'
		descText =
			'Данные об ошибке были отправлены в систему мониторинга, специалисты уже работают над её исправлением.'
	}

	return {
		headerText,
		descText,
	}
}

export function getImageUrl(errorCode: ErrorCodeType) {
	if (errorCode == 403) {
		return forbiddenImgUrl
	} else if (errorCode == 404 || errorCode == 500) {
		return notFoundImgUrl
	}
}
