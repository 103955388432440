import TariffsMain from 'pages/landing/landingConstructor/tariffs/general/TariffsMain/TariffsMain'
import SectionHeader from 'pages/landing/landingConstructor/details/SectionHeader/SectionHeader'
import SectionWrapper from 'pages/landing/landingConstructor/details/SectionWrapper/SectionWrapper'

// Секция одностраничника с рассказом с пунктами, что должно и не должно быть у ученика
function TariffsSection() {
	return (
		<SectionWrapper idAttr="tariffs-section">
			<SectionHeader
				imagePropsPathArr={[
					'sections',
					'tariffs',
					'header',
					'imageUrl',
				]}
				headerPropsPathArr={['sections', 'tariffs', 'header', 'text']}
			/>
			<div className="land-const-existence-section">
				<TariffsMain />
			</div>
		</SectionWrapper>
	)
}

export default TariffsSection
