import { useEffect } from 'react'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import LessonStudentService from 'parts/services/LessonStudentService'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useGetTrainingIdAndLessonId } from '../../common/common'
import useExerciseStudentStore from '../store'
import { ExerciseStudentStoreType } from '../storeTypes'

/** Наполняет Состояние при загрузке компонента */
export function useFillInStore() {
	const { courseId, groupId, lessonId, exerciseId } =
		useGetTrainingIdAndLessonId()

	const updateStore = useExerciseStudentStore.getState().updateStore

	const { data: trainingRes } = trainingQuery
		.getTrainingForStudent(courseId, groupId)
		.useQuery({
			onError: queryError,
		})

	useEffect(
		function () {
			if (!trainingRes) return

			const training = trainingRes.data

			const lesson = LessonStudentService.getLessonById(
				training,
				lessonId
			)

			if (!lesson) {
				updateStore({
					dataLoadingStatus: 'error',
				})

				return
			}

			const exercisesMeta = createExercisesMeta(training, lesson)

			const exerciseMeta = exercisesMeta.find(
				(exerciseMeta) => exerciseMeta.id == exerciseId
			)

			if (!exerciseMeta) {
				updateStore({
					dataLoadingStatus: 'error',
				})

				return
			}

			updateStore({
				dataLoadingStatus: 'hasData',
				trainingStudent: training,
				lesson,
				groupId,
				exercisesMeta,
				percentage: lesson.performed,
			})
		},
		[trainingRes, lessonId]
	)
}

/**
 * Функция возвращает объект Состояния страницы прохождения упражнения
 * @param training — курс
 * @param {Object} lesson — объект урока
 */
export function createExercisesMeta(
	training: TrainingEntityTypes.TrainingStudent,
	lesson: TrainingEntityTypes.LessonStudent
): ExerciseStudentStoreType.ExerciseMeta[] {
	return lesson.exercises.map((exercise, i) => {
		// Статус выполнения упражнения: 0 не выполняли, 1 — выполнили
		let status = exercise.status

		return {
			id: exercise.id || 0,
			exerciseNumber: i + 1,
			// Выполнили ли упражнение.
			// Если это невыполненное упражнение где нужно отправлять письменный или устный ответ,
			// то после отправки оно в коде будет изменено на Выполнено (1).
			status,
			time: exercise.statusUpdated,
			duration: 0,
		}
	})
}

/**
 * Функция запускаемая при ошибке запроса данных. Срабатывает для любых запросов.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 * @param err — объект ошибки.
 */
function queryError(err: unknown) {
	const { updateStore } = useExerciseStudentStore.getState()

	updateStore({ dataLoadingStatus: 'error' })
}
