import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import MetaOutput from 'ui/MetaOutput/MetaOutput'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import {
	getBreadcrumbs,
	useGetMetaPanelConfig,
	useGetTabsData,
} from './fn/forHeader'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import Loader from 'ui/Loader/Loader'
import { ActionsButton, NewOrderButton } from '../HeaderButtons/HeaderButtons'
import { useFillInStore } from './fn/fillInStore'
import MakeOrderModal from '../../MakeOrderModal/MakeOrderModal'
import useStudentStore from '../zustand/store'
import { getUserName } from 'parts/utils/string'
import Avatar from 'ui/Avatar/Avatar'
import AppUrls from 'parts/constants/pageUrl'
import NoContent from 'ui/NoContent/NoContent'
import './StudentWrapArticle.scss'

/** Страница добавления ученика */
function StudentArticle() {
	useFillInStore()

	const breadcrumbs = getBreadcrumbs()
	const metaPanelConfig = useGetMetaPanelConfig()
	const tabsData = useGetTabsData()

	return (
		<PageSkeleton>
			<PageHeader
				breadcrumbs={breadcrumbs}
				header={<UserNameBlock />}
				meta={<MetaOutput items={metaPanelConfig} />}
				buttons={[
					<ActionsButton key={1} />,
					<NewOrderButton key={2} />,
				]}
				tabs={tabsData}
			/>
			<Content />
		</PageSkeleton>
	)
}

export default StudentArticle

function Content() {
	const { t } = useTranslation()

	const student = useStudentStore((store) => store.student)

	if (student === undefined) {
		return (
			<ArticleContentWrapper center>
				<Loader />
			</ArticleContentWrapper>
		)
	} else if (student === null) {
		return (
			<ArticleContentWrapper center>
				<NoContent
					header={t('student.noStudentScreenHeader')}
					text={t('student.noStudentScreenText')}
					btnConf={{
						text: t('student.noStudentScreenGoToStudentsPageBtn'),
						to: AppUrls.Students().url,
					}}
				/>
			</ArticleContentWrapper>
		)
	}

	return (
		<>
			<Outlet />
			<MakeOrderModal />
		</>
	)
}

// Блок с фотографией, именем пользователя и отметиной активированности
function UserNameBlock() {
	const student = useStudentStore((store) => store.student)
	if (!student) return null

	const name = getUserName(student)

	return (
		<div className="student-main-article__user-name">
			<Avatar src={student.avatar} width={40} />
			{name}
			{student.isActivated && (
				<img
					src="/images/signs/CheckCircleTwoTone.svg"
					className="student-main-article__user-activation-mark"
					alt=""
				/>
			)}
		</div>
	)
}
