import { useEffect } from 'react'
import { FormInstance } from 'antd'
import dayjs from 'dayjs'
import EntityTypes from 'parts/types/EntityTypes'
import { setErrorsToFields } from 'parts/utils/form'
import useCondStore from '../zustand/store'

// Названия полей формы
export enum FieldsNames {
	// Когда начинается обучение
	StartCondition = 'startCondition',
	// Если обучение начинается в указанную дату, то тут указывается эта дата
	StartDate = 'startDate',
	// Если обучение начинается в указанную дату, то тут указывается время
	StartTime = 'startTime',
	// Режим прохождения: все уроки открыты сразу, по интервалам или по дням
	PassingMode = 'passingMode',
	// Интервал открытия уроков
	Interval = 'interval',
	// Интервал открытия уроков
	OpeningByDay = 'openingByDay',
	// Длительность обучения в днях
	Duration = 'duration',
	// Доступ на курс в днях
	Access = 'access',
}

// Типы полей формы
export interface FormValues {
	[FieldsNames.StartCondition]: EntityTypes.GroupStartCondition
	[FieldsNames.StartDate]: null | dayjs.Dayjs
	[FieldsNames.StartTime]: null | dayjs.Dayjs
	[FieldsNames.PassingMode]: EntityTypes.GroupMode
	[FieldsNames.Interval]: EntityTypes.OpeningByIntervals
	[FieldsNames.OpeningByDay]: EntityTypes.OpeningByWeekDays[]
	[FieldsNames.Duration]: number
	[FieldsNames.Access]: number
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useCondStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
