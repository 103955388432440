import AppUrls from 'parts/constants/pageUrl'
import UrlService from 'parts/services/UrlService'
import { PageHeaderTab } from 'ui/pageHeader/Tabs/Tabs'
import { trainingQuery } from 'parts/requests/training/trainingQuery'

export function useGetTabs(): PageHeaderTab[] {
	const trainingId = UrlService.useGetTrainingId()
	const { isLoading, data } = trainingQuery
		.getTrainingForAdmin(trainingId)
		.useQuery()

	if (isLoading || !data) {
		return []
	}

	return [
		{
			label: AppUrls.Training(data.data.id).name,
			address: AppUrls.Training(data.data.id).url,
		},
	]
}
