import { useState } from 'react'
import { emptyState, StateType } from './stateType'

/** Формирует и возвращает объект состояния списка школ */
export function useGetState() {
	const [state, setState] = useState<StateType.State>(emptyState)

	return {
		state,
		setState,
	}
}
