import EntityTypes from '../../types/EntityTypes'
import PaymentTypes from '../../types/PaymentTypes'

// Типы для взаимодействия с API пользователя
namespace UserApiTypes {
	// Параметры получения пользователей
	export type GetUsersParams = {
		filter?: {
			fullName?: string
			email?: string
			phone?: string
			country?: string
			isActivated?: boolean
			isDeleted?: boolean
			roles?: EntityTypes.UserRole[]
		}
		offset?: number
		limit?: number
	}

	// Получение всех пользователей
	export type GetUsersPaginated = {
		totalItems: 0
		size: 0
		offset: 0
		items: EntityTypes.User[]
	}

	export type GetUsers = EntityTypes.User[]

	// Получение пользователя
	export type GetUser = EntityTypes.User

	// Создание пользователя
	export type AddUserDto = {
		avatar?: string // Ссылка на фотографию пользователя
		email: string // 'user@mail.ru'
		password: string // '123456'
		role: EntityTypes.UserRole
		firstName?: string // 'John'
		lastName?: string // 'Doe'
		phone?: string // '+79272445566'
		instagram?: string //'https://www.instagram.com/mariapedraza_/'
		isActivated?: boolean
		country?: string // 'Russia'
		city?: string // 'Kazan'
		birthday?: string // '2023-03-03T11:48:40.398Z'
		sex?: EntityTypes.Gender
	}
	export type AddUser = Omit<
		EntityTypes.User,
		'created' | 'lastVisit' | 'roles' | 'password'
	>

	// DTO создания пользователя
	export type UpdateUserDto = {
		id?: number
		firstName?: string // 'John'
		lastName?: string // 'Doe'
		role?: EntityTypes.UserRole
		avatar?: null | string // Адрес до изображения пользователя
		phone?: string // '+79272445566'
		instagram?: string // 'https://www.instagram.com/mariapedraza_/'
		country?: string // 'Russia'
		city?: string // 'Kazan'
		birthday?: string // '2022-12-30T14:44:26.396Z'
		sex?: EntityTypes.Gender // 'male'
	}
	export type UpdateUser = EntityTypes.User

	// Поиск пользователей по почте
	export type SearchByEmailDto = {
		email: string
	}
	export type SearchByEmail = EntityTypes.User[]

	// Возвращает статус почты: не зарегистрирована, не подтверждена
	// или зарегистрирована и подтверждена
	export type CheckEmailStatusDto = {
		email: string
	}
	// Одно из значений возвращаемое checkEmailStatus
	export enum CheckEmailStatus {
		// Почта не регистрировалась
		NotExist = 0,
		// Почта не подтверждена
		NotConfirm = 1,
		// Зарегистрирована и подтверждена в этой школе (если запрос делается с домена школы) или в одной из школ (если запрос делается от корневого домена)
		Exists = 2,
		// Зарегистрирована и подтверждена в другой школе (если запрос делается с домена школы)
		ExistInAnotherSchool = 3,
	}

	// Получение списка кураторов школы
	export type GetCurators = EntityTypes.Curator[]

	// Получение списка учеников школы администратором или менеджером
	export type GetAdminStudents = EntityTypes.User[]

	// Параметры получения пользователей
	export type GetCuratorStudentsParams = {
		filter?: {
			fullName?: string
			trainingName?: string
			groupName?: string
			isActivated?: boolean
			isDeleted?: boolean
		}
		offset?: number
		limit?: number
	}
	// Получение списка учеников прикреплённых к куратору
	export type CuratorStudent = {
		id: number
		avatar: null | string
		banReason: EntityTypes.UserBanReason
		banned: boolean
		birthday: null | string
		city: null | string
		country: null | string
		createdAt: string // '2023-06-21T08:52:49.149Z'
		email: string
		firstName: string
		lastName: string
		groupName: string
		instagram: null | string
		isActivated: boolean
		isDeleted: boolean
		lastVisit: string // '2023-09-14T14:11:22.572Z'
		phone: null | string
		roles: ['STUDENT']
		role: 'STUDENT'
		sex: null | string
		trainingName: string
		groupEndDate: string // "2024-08-09T00:00:14.972Z"
		groupStartDate: string // "2023-11-13T00:00:14.972Z"
	}
	export type GetCuratorStudents = CuratorStudent[]

	// DTO для добавления ученика куратору
	export type AddStudentToCuratorDto = {
		studentId: number
		curatorId: number
	}
	// Ответ после добавления ученика куратору
	export type AddStudentToCurator = {
		id: number
		curatorId: number
		studentId: number
	}

	export type GetUserByRole = UserByRole[]
	export interface UserByRole extends EntityTypes.User {
		groups: EntityTypes.GroupData[]
	}

	// Создание оплаты за курс в ручном режиме
	export type CreateManualPaymentDto = {
		customerId: number
		groupId: number
		purchaseDate: string // "2022-12-12T06:33:29.054Z"
		paymentSystem: PaymentTypes.System
		amount: number
		currency: PaymentTypes.Currency
		mode: PaymentTypes.PaymentMode
	}
	export type CreateManualPayment = {
		id: number
		checkoutSessionId: string
		created: string // '2023-08-07T10:42:16.316Z'
		amountSubtotal: number
		amountTotal: number
		currency: string
		productId: number
		productType: string
		paymentStatus: string
		status: string
		mode: string
		paymentSystem: string
		isManual: boolean
	}

	// Получение журнала посещения пользователя
	export type GetUserLogs = EntityTypes.UserLogs

	// DTO для блокировки пользователя
	export type BanUserDto = {
		userId: number
		banReason: string // Причина блокировка
	}
	// Блокировка пользователя
	export type BanUser = null

	// DTO для разблокировки пользователя
	export type UnBanUserDto = {
		userId: number
	}
	// Разблокировка пользователя
	export type UnBanUser = null

	// DTO для изменения почты пользователя
	export type UpdateUserEmailDto = {
		userId: number
		oldEmail: string
		password: string
		email: string // "mail@yandex.ru"
	}
	// Изменение почты пользователя
	export type UpdateUserEmail = EntityTypes.User

	// DTO для изменения пароля пользователя
	export type UpdatePasswordByAdminDto = {
		userId: number | string
		password: string
	}
	// Изменение пароля пользователя
	export type UpdatePasswordByAdmin = null

	// DTO для отправки доступа ученику
	export type SendAccessDto = {
		userId: number
	}
	// Отправка доступа ученику
	export type SendAccess = null

	export type StudentTrainingsVisitStats = StudentTrainingsVisitStatsItem[]
	// Получение статистики по прохождению курсов учеником
	export type StudentTrainingsVisitStatsItem = {
		trainingId: number
		trainingName: string
		groupId: number
		groupName: string
		// Дата начала группы курса
		groupStart: string // '2023-02-01T09:17:27.639Z'
		// Дата окончания группы курса
		groupEnd: string // '2023-02-01T09:17:27.639Z'
		// Прогресс прохождения в процентах (null если группа ещё не началась)
		progress: null | number
		// Сколько открытых уроков не пройдено. null если все уроки пройдены
		unperformed: null | {
			// Сколько уроков не выполнено
			lessons: number
			// Сколько упражнений не выполнено
			exercises: number
		}
		// Когда открывается следующий урок (null если все уроки открыты)
		nextLessonOpenDate: null | string // '2023-02-01T09:17:27.639Z'
		// Все ли уроки открыты
		allLessonsOpen: boolean
	}

	// Получение статистики по прохождению курса учеником
	export type StudentTrainingVisitStats = {
		trainingId: number
		trainingName: string
		groupId: number
		groupName: string
		openedLessons: number
		totalLessons: number
		lessons: StudentLessonVisitStats[]
	}
	export type StudentLessonVisitStats = {
		id: number
		// Продолжительность прохождения урока в миллисекундах
		duration: number
		// Открыт ли урок
		isOpen: boolean
		// Название урока
		name: string // 'Первый урок'
		// Дата открытия урока
		openDate: string // '2023-06-14T15:37:37.725Z'
		// Статус прохождения урока
		status: StudentLessonVisitStatsStatus // Потом уточнить возможные значения !!!
	}
	// Статус прохождения урока: success (все упражнения пройдены)
	export type StudentLessonVisitStatsStatus = 'success' | 'wait'
}

export default UserApiTypes
