import AudioExerciseView from './AudioExerciseView/AudioExerciseView'
import {
	SaveExerciseChangesMadeByUser,
	LiftViewDuration,
	WritingOrAudioAnswerWasSentFn,
	ExerciseChangesDataMadeByUser,
} from './common/useLiftViewDuration'
import ImageExerciseView from './ImageExerciseView/ImageExerciseView'
import TestExerciseView from './TestExerciseView/TestExerciseView'
import TextExerciseView from './TextExerciseView/TextExerciseView'
import TrueOrFalseExerciseView from './TrueOrFalseExerciseView/TrueOrFalseExerciseView'
import VideoExerciseView from './VideoExerciseView/VideoExerciseView'
import GapsInWordsExerciseView from './GapsInWordsView/GapsInWordsExerciseView'
import ChooseWordExerciseView from './ChooseWordView/ChooseWordExerciseView'
import DropWordExerciseView from './DropWordExerciseView/DropWordExerciseView'
import DescriptionImageExerciseView from './DescriptionImageExerciseView/DescriptionImageExerciseView'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

type SwitchExerciseProps = {
	exercise: TrainingEntityTypes.ExerciseItem
	liftViewDuration?: LiftViewDuration
	// Запуск этой функции сообщает, что ответ был послан
	writingOrAudioAnswerWasSentFn?: WritingOrAudioAnswerWasSentFn
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

// Вывод упражнения урока для ученика
function ExerciseSwitcher(props: SwitchExerciseProps) {
	const {
		exercise,
		liftViewDuration,
		writingOrAudioAnswerWasSentFn,
		saveExerciseChangesMadeByUser,
		exerciseRestoreData,
	} = props

	switch (exercise?.type) {
		case 'video':
			return (
				<VideoExerciseView
					exercise={exercise}
					liftViewDuration={liftViewDuration}
				/>
			)
		case 'audio':
			return (
				<AudioExerciseView
					exercise={exercise}
					liftViewDuration={liftViewDuration}
					writingOrAudioAnswerWasSentFn={
						writingOrAudioAnswerWasSentFn
					}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
					exerciseRestoreData={exerciseRestoreData}
				/>
			)
		case 'image':
			return (
				<ImageExerciseView
					exercise={exercise}
					liftViewDuration={liftViewDuration}
					writingOrAudioAnswerWasSentFn={
						writingOrAudioAnswerWasSentFn
					}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
					exerciseRestoreData={exerciseRestoreData}
				/>
			)
		case 'text':
			return (
				<TextExerciseView
					exercise={exercise}
					liftViewDuration={liftViewDuration}
				/>
			)
		case 'test':
			return (
				<TestExerciseView
					exercise={exercise}
					liftViewDuration={liftViewDuration}
				/>
			)
		case 'trueOrFalse':
			return (
				<TrueOrFalseExerciseView
					exercise={exercise}
					liftViewDuration={liftViewDuration}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
					exerciseRestoreData={exerciseRestoreData}
				/>
			)
		case 'gapsInWords':
			return (
				<GapsInWordsExerciseView
					exercise={exercise}
					liftViewDuration={liftViewDuration}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
					exerciseRestoreData={exerciseRestoreData}
				/>
			)
		case 'chooseWord':
			return (
				<ChooseWordExerciseView
					exercise={exercise}
					liftViewDuration={liftViewDuration}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
					exerciseRestoreData={exerciseRestoreData}
				/>
			)
		case 'dropWords':
			return (
				<DropWordExerciseView
					exercise={exercise}
					liftViewDuration={liftViewDuration}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
					exerciseRestoreData={exerciseRestoreData}
				/>
			)
		case 'descriptionImg':
			return (
				<DescriptionImageExerciseView
					exercise={exercise}
					liftViewDuration={liftViewDuration}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
					exerciseRestoreData={exerciseRestoreData}
				/>
			)
		default:
			return null
	}
}

export default ExerciseSwitcher
