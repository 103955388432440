import { ReactNode } from 'react'
import cn from 'classnames'
import ControlsFrame from 'pages/landing/landingConstructor/details/ControlsFrame/ControlsFrame'
import { LandingTypes } from 'parts/types/LandingTypes'
import EditableText from 'pages/landing/landingConstructor/details/editableElems/EditableText/EditableText'
import timeBlocksManager from 'pages/landing/landingConstructor/sections/timeSection/TimeBlocksManager/timeBlocksManager'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import 'pages/landing/landingConstructor/sections/timeSection/TimeBlock/TimeBlock.scss'

type BlockOuterProps = {
	block: LandingTypes.TimeBlock
	children: ReactNode
}

export function BlockOuter(props: BlockOuterProps) {
	const { block, children } = props

	const addNewBlockInTop = timeBlocksManager.useGetAddNewBlock(
		block.id,
		block.columnNum,
		'top'
	)
	const addNewBlockInBottom = timeBlocksManager.useGetAddNewBlock(
		block.id,
		block.columnNum,
		'bottom'
	)

	const deleteBlock = timeBlocksManager.useGetDeleteBlock(block.id)

	return (
		<ControlsFrame
			topAddBtnHandler={addNewBlockInTop}
			bottomAddBtnHandler={addNewBlockInBottom}
			deleteBtnHandler={deleteBlock}
		>
			{children}
		</ControlsFrame>
	)
}

type BlockProps = {
	blockIdx: number
	block: LandingTypes.TimeBlock
}

export function Block(props: BlockProps) {
	const { blockIdx, block } = props

	const landing = useLandingConstructorStore((store) => store.landing)
	const { colorScheme } = landing

	return (
		<article className="land-const-time-block">
			<p
				className={cn(
					'land-const-time-block__counter',
					'land-const-time-block__counter--' + colorScheme
				)}
			>
				{blockIdx + 1}
			</p>
			<p className="land-const-time-block__text">
				<EditableText
					propsPathArr={[
						'sections',
						'time',
						'blocks',
						blockIdx,
						'text',
					]}
				/>
			</p>
		</article>
	)
}
