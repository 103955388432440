import { useTranslation } from 'react-i18next'
import { Button, Form, Input } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import {
	UFormOldBottomButtons,
	UFormOld,
	UFormOldRow,
} from 'ui/UFormOld/UFormOld'
import { UFormNewGeneralError } from 'ui/UForm/UForm'
import useLiveLessonEntrancePageStore from '../../../../liveLessons/liveLessonEntrance/zustand/store'
import useEmailFormStore from './zustand/store'
import { useGetOnSubmit } from './fn/submit'
import { setErrorsToForm } from './fn/form'
import './EmailForm.scss'

// Формы ввода емейла для удаления аккаунта
function EmailForm() {
	const [form] = Form.useForm()

	const onSubmit = useGetOnSubmit()
	setErrorsToForm(form)

	const formErrors = useEmailFormStore((state) => state.formErrors)

	return (
		<UFormOld>
			<Form
				form={form}
				name="delete-account-req"
				onFinish={onSubmit}
				layout="vertical"
			>
				<EmailInput />
				<Bottom />
				{formErrors?.message && (
					<UFormNewGeneralError message={formErrors?.message} />
				)}
			</Form>
		</UFormOld>
	)
}

export default EmailForm

function EmailInput() {
	const { t } = useTranslation()

	// Если пользователь ввёл свою почту на странице входа в эфир, то получить и поставить в качестве начального значения
	const emailFromLiveLessonEnterPage = useLiveLessonEntrancePageStore(
		(store) => store.email
	)

	return (
		<UFormOldRow>
			<Form.Item
				name="email"
				label={t('auth.loginFormFieldEmailLabel')}
				rules={[
					{
						type: 'email',
						message: t('auth.loginFormFieldEmailWrongFormat'),
					},
					{
						required: true,
						message: t('auth.loginFormFieldEmailRequired'),
					},
				]}
				initialValue={emailFromLiveLessonEnterPage || ''}
			>
				<Input
					type="email"
					placeholder="mail@mail.com"
					size="large"
					autoComplete="username"
					data-cy="login-form-email-input"
				/>
			</Form.Item>
		</UFormOldRow>
	)
}

// Кнопка отправки формы
function Bottom() {
	const form = Form.useFormInstance()
	const { t } = useTranslation()

	const isSubmitting = useEmailFormStore((state) => state.isSubmitting)

	return (
		<UFormOldBottomButtons>
			<Form.Item
				shouldUpdate
				className="login-page-content__bottom-form-item"
			>
				{() => {
					const formErrors = form
						.getFieldsError()
						.filter(({ errors }) => errors.length)

					return (
						<Button
							block
							size="large"
							type="primary"
							htmlType="submit"
							disabled={isSubmitting || formErrors.length > 0}
							loading={isSubmitting}
							data-cy="login-form-submit-btn"
						>
							{t('auth.deleteAccReqFormFieldEnterButton')}{' '}
							<ArrowRightOutlined />
						</Button>
					)
				}}
			</Form.Item>
		</UFormOldBottomButtons>
	)
}
