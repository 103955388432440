import { useGetGoToNextExercise } from '../../../common/prevAndNextExerciseUrls'
import useExerciseStore from '../../zustand/exerciseState'

/**
 * Возвращает функцию, выполняемую после нажатия на кнопку «Выполнено» и отправки запроса.
 * Она переадресовывает на следующее упражнение если оно существует
 * И свойство doneAllow если
 */
export function useGetAfterDoneExercise() {
	const updateStore = useExerciseStore((store) => store.updateStore)

	// Функция открывающая следующее упражнение если упражнение существует
	// или undefined если не существует.
	const goToNextExercise = useGetGoToNextExercise()

	return function () {
		updateStore({ doneAllow: false })

		if (goToNextExercise) {
			goToNextExercise()
		}
	}
}
