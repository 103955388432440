import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { ReactNode } from 'react'
import { useGoToPage } from 'parts/utils/hooks/hooks'
import s from './ExerciseContainer.module.scss'

type ExerciseContainerProps = {
	// Для какого типа страницы предназначается обёртка упражнения:
	// constructorPage — страница конструктора. Будут скрыты кнопки перехода по упражнениям и кнопки снизу
	// exercisePage — страница упражнения для ученика. Будет полный набор элементов.
	type: ExerciseContainerType
	// Адрес предыдущего и следующего упражнения
	prevExerciseUrl?: string
	nextExerciseUrl?: string
	children: [ReactNode, ReactNode]
}

export type ExerciseContainerType = 'constructorPage' | 'exercisePage'

// Настройка кнопки перехода к предыдущему или следующему упражнению
export type ExerciseNavBtnConfType = {
	disabled: boolean
	handler: () => void
}

// Контейнер, в который завернуто каждое упражнение.
// Добавляет кнопки переходов между упражнениями и белую подложку
function ExerciseContainer(props: ExerciseContainerProps) {
	const { type, children, prevExerciseUrl, nextExerciseUrl } = props

	return (
		<div className={s.container}>
			<div className={s.content}>
				{type === 'exercisePage' && (
					<ExercisesNavigateButtons
						prevExerciseUrl={prevExerciseUrl}
						nextExerciseUrl={nextExerciseUrl}
					/>
				)}
				{children[0]}
			</div>
			{type === 'exercisePage' && children[1]}
		</div>
	)
}

export default ExerciseContainer

type ExercisesNavigateButtonsProps = {
	prevExerciseUrl?: string
	nextExerciseUrl?: string
}

function ExercisesNavigateButtons(props: ExercisesNavigateButtonsProps) {
	const { prevExerciseUrl, nextExerciseUrl } = props

	const goToPrevExercisePage = useGoToPage(prevExerciseUrl || '', [
		prevExerciseUrl,
	])
	const goToNextExercisePage = useGoToPage(nextExerciseUrl || '', [
		nextExerciseUrl,
	])

	return (
		<nav className={s.navContainer}>
			<Button
				onClick={goToPrevExercisePage}
				icon={<LeftOutlined />}
				disabled={!prevExerciseUrl}
				data-cy="ex-container-prev-exrcise-btn"
			/>
			<Button
				onClick={goToNextExercisePage}
				icon={<RightOutlined />}
				disabled={!nextExerciseUrl}
				data-cy="ex-container-next-exrcise-btn"
			/>
		</nav>
	)
}
