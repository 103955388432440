import React from 'react'
import { Alert } from 'antd'
import { useTranslation } from 'react-i18next'
import './ExerciseDropAdvert.scss'

function ExerciseDropAdvert() {
	const { t } = useTranslation()

	return (
		<Alert
			message="Полсекунды задержите палец на слове и после этого его можно будет
			перетаскивать."
			type="warning"
		/>
	)
}

export default ExerciseDropAdvert
