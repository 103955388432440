import ColorSchemeModal from 'pages/landing/landingConstructor/details/ColorSchemeModal/ColorSchemeModal'
import useColorSchemeModalStore from 'pages/landing/landingConstructor/details/ColorSchemeModal/zustand/store'

export const ColorSchemeModalController = {
	element: <ColorSchemeModal />,
	open() {
		const updateStore = useColorSchemeModalStore.getState().updateStore
		updateStore({ isOpen: true })
	},
	close() {
		const updateStore = useColorSchemeModalStore.getState().updateStore
		updateStore({ isOpen: false })
	},
}
