import { TFunction } from 'i18next'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FormInstance, message } from 'antd'
import { AxiosError } from 'axios'
import { produce } from 'immer'
import useSystemStore from 'parts/systemStore/systemStore'
import ServerTypes from 'parts/types/ServerTypes'
import { useTranslation } from 'react-i18next'
import {
	FormValuesType,
	StripeFormValuesType,
	UKassaFormValuesType,
} from './form'
import { SetStateType, StateContext } from '../../state/stateContext'
import { setErrorsToFields } from 'parts/utils/form'
import schoolRequests from 'parts/requests/school/schoolRequest'
import { StateType } from '../../state/stateType'
import SchoolApiTypes from 'parts/requests/school/schoolApiTypes'
import PaymentTypes from 'parts/types/PaymentTypes'

/**
 * Функция возвращающая данные для работы формы
 * @param {Object} form — объект формы
 */
export function useManageForm(form: FormInstance) {
	// Идёт ли загрузка данных
	const [isLoading, setIsLoading] = useState(false)
	// В случае ошибки сюда попадут данные ошибки
	const [formErrors, setFormErrors] = useState<ServerTypes.ErrorResponse>({})

	// Обработчик отправки формы
	const onSubmit = useGetOnSubmit(setIsLoading, setFormErrors)

	// В случае появления ошибок показать их в форме
	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])

	return {
		isLoading,
		onSubmit,
		formErrors,
	}
}

/**
 * Хук возвращающий обработчик отправки формы
 * @param {Function} setIsLoading — функция устанавливающая статус ожидания ответа от сервера.
 * @param {Function} setFormErrors — функция устанавливающая объект ошибок в Состояние ошибок
 */
function useGetOnSubmit(
	setIsLoading: (isLoading: boolean) => void,
	setFormErrors: React.Dispatch<React.SetStateAction<{}>>
) {
	const school = useSystemStore((store) => store.school)
	const { state, setState } = useContext(StateContext)
	const { t } = useTranslation()

	return useCallback(
		async (values: FormValuesType) => {
			if (!school) return

			// Поставить статус загрузки
			setIsLoading(true)

			try {
				if (state.formSubmitType == 'save') {
					await saveForm(
						state,
						setState,
						school,
						values,
						setFormErrors,
						t
					)
				} else if (state.formSubmitType == 'connect') {
					await connectPaymentSystem(state, setState, t)
				}
			} catch (err) {
				const error = err as AxiosError<ServerTypes.ErrorResponse>

				if (error.response) {
					// Поставить данные ошибки в Состояние, чтобы показать их в форме
					setFormErrors(error.response.data)
				}
			} finally {
				// Убрать статус загрузки
				setIsLoading(false)
			}
		},
		[state]
	)
}

/**
 * Сохраняет данные подключения к платёжной системы.
 * @param state объект состояния
 * @param setState функция изменяющая объект состояния
 * @param school — данные о текущей школе
 * @param values — значения полей формы
 * @param setFormErrors — функция изменяющая объект ошибок формы.
 * @param t — функция подставляющая текст на выбранном языке
 */
async function saveForm(
	state: StateType.State,
	setState: SetStateType,
	school: SchoolApiTypes.School,
	values: FormValuesType,
	setFormErrors: React.Dispatch<React.SetStateAction<{}>>,
	t: TFunction<'translation'>
) {
	const responseSave = await schoolRequests.updatePaymentData(
		getSaveDto(state, school.id, values)
	)

	if (responseSave.data) {
		message.success(t('myPayments.editPaymentFormDataUpdated'))
		setFormErrors({})

		// Отключить платёжную систему потому что изменились данные
		setState((state): StateType.State => {
			return produce(state, (draft) => {
				draft.connectionType = 'off'
			})
		})
	}

	const responseCheck = await schoolRequests.checkPaymentData(
		state.initialFormData!.id!
	)

	if (responseCheck.data) {
		setState((state): StateType.State => {
			return produce(state, (draft) => {
				draft.formSubmitType = 'connect'
			})
		})

		setFormErrors({})
	}
}

/**
 * Включает работу платёжной системы на текущей школе.
 * @param state объект состояния
 * @param setState функция изменяющая объект состояния
 * @param t
 */
async function connectPaymentSystem(
	state: StateType.State,
	setState: SetStateType,
	t: TFunction<'translation'>
) {
	const response = await schoolRequests.setPaymentConnection(
		getConnectDto(state)
	)

	if (response && response.data) {
		message.success(t('myPayments.editPaymentFormPaySystemConnected'))

		setState((state): StateType.State => {
			return produce(state, (draft) => {
				draft.formSubmitType = 'save'
				draft.connectionType = 'on'
			})
		})
	}
}

/**
 * Формирует DTO для отправки запроса
 * @param ctxState объект состояния
 * @param {Array} values — массив значений полей формы
 * @param {Number} schoolId — id школы
 */
function getSaveDto(
	ctxState: StateType.State,
	schoolId: number,
	values: FormValuesType
): SchoolApiTypes.UpdatePaymentDataDto {
	const paymentSystem = ctxState.paymentSystemType!

	return {
		id: ctxState.initialFormData!.id || undefined,
		schoolId: schoolId,
		paymentSystem,
		paymentData: getPaymentData(ctxState, values),
	}
}

/**
 * Возвращает DTO для включения работы платёжной системы в текущей школе
 * @param ctxState объект состояния
 */
function getConnectDto(
	ctxState: StateType.State
): SchoolApiTypes.SetPaymentConnectionDto {
	return {
		id: ctxState!.initialFormData!.id!,
		connected: true,
	}
}

/**
 * Возвращает настройки соединения с платёжной системой.
 * Используется при формировании DTO для сохранения соединения с платёжной системой.
 * @param ctxState объект состояния
 * @param values — массив значений полей формы
 */
function getPaymentData(
	ctxState: StateType.State,
	values: FormValuesType
): SchoolApiTypes.PaymentData {
	const paymentSystem = ctxState.paymentSystemType!

	if (paymentSystem == PaymentTypes.System.UKassa) {
		const fieldsValues = values as UKassaFormValuesType

		return {
			shopId: fieldsValues.shopId,
			secretKey: fieldsValues.secretKey,
		}
	} else {
		const fieldsValues = values as StripeFormValuesType

		return {
			apiKey: fieldsValues.apiKey,
			webhookSecret: fieldsValues.webhookSecret,
		}
	}
}
