import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import useExercisesListAdminStore from '../../../zustand/store'
import { getFormState, setFormState } from '../../common/exerciseFormCommonFunc'
import {
	FormAnswerStateItemType,
	TestExerciseFormStateType,
} from './stateTypes'
import { produce } from 'immer'
import {
	isAtLeastAnswerChecked,
	isQuestionHasAtLeastTwoAnswersWithText,
} from './check'

/**
 * Обработчик отправки формы
 * @param {Function} saveExercise — функция сохраняющая упражнение на сервере и в Состоянии
 */
export function submitHandler(
	saveExercise: (args: TrainingEntityTypes.ExerciseItem) => void
) {
	const isFormValid = validateForm()

	if (!isFormValid) {
		return
	}

	const formState = getFormState<TestExerciseFormStateType>()

	const exerciseData = convertFormStateDataToExerciseData(formState)
	saveExercise(exerciseData)
}

/** Проверяет правильность заполнения формы, изменяет Состояние для показа ошибок и возвращает булево значение является ли форма правильной */
function validateForm() {
	// Есть ли в форме ошибка noQuestionTextError.
	// Обозначает отсутствие в форме блоков вопросов с написанным текстом вопроса
	let formHasNoQuestionTextError = false
	// Есть ли в форме ошибка noRightAnswerCheckedError.
	// Обозначает присутствие в форме блоков ответов где не отмечен верный ответ
	let formHasNoRightAnswerCheckedError = false
	// Есть ли в форме ошибка noAnswerTextError.
	// Обозначает присутствие в форме блоков ответов где нет как минимум двух ответов с введённым текстом.
	let formHasNoAnswerTextError = false

	const formState = getFormState<TestExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		// Проверка того, что есть хотя бы один блок вопроса с написанным текстом вопроса
		draft.questions.forEach((question) => {
			if (!question.questionText) {
				question.noQuestionTextError = true
				formHasNoQuestionTextError = true
			}
		})

		// Проверка того, что в блоках ответов отмечен хотя бы один правильный ответ
		draft.questions.forEach((question) => {
			if (!isAtLeastAnswerChecked(draft, question.id)) {
				question.noRightAnswerCheckedError = true
				formHasNoRightAnswerCheckedError = true
			}
		})

		// Проверка того, что в блоках ответов есть как минимум два ответа с введённым текстом.
		draft.questions.forEach((question) => {
			if (!isQuestionHasAtLeastTwoAnswersWithText(draft, question.id)) {
				// Если нет, то показать соответствующую ошибку
				question.noAnswerTextError = true
				// Пометить, что форма содержит ошибки
				formHasNoAnswerTextError = true
			}
		})

		if (
			formHasNoQuestionTextError ||
			formHasNoRightAnswerCheckedError ||
			formHasNoAnswerTextError
		) {
			draft.isFormInvalid = true
		}
	})

	setFormState(newState)

	return !newState.isFormInvalid
}

/**
 * Функция переводит данные формы редактирования упражнения в данные упражнения для сохранения на сервере.
 * @param {Object} formState — Объект с данными формы
 */
function convertFormStateDataToExerciseData(
	formState: TestExerciseFormStateType
) {
	const lessonId = useExercisesListAdminStore.getState().lessonId

	let exerciseData: TrainingEntityTypes.TestExercise = {
		type: 'test',
		lessonId,
		order: formState.order,
		item: {
			name: formState.nameInput.value,
			task: formState.taskInput.value,
			generatedTextPrompt: formState.generatedTextPrompt,
			questions: formState.questions.map((question, i) => {
				return {
					id: i + 1,
					multipleSelect: question.multipleSelect,
					text: question.questionText,
					audio:
						question.files.audio.status == 'success'
							? question.files.audio.link
							: '',
					video:
						question.files.video.status == 'success'
							? question.files.video.link
							: '',
					videoLink: question.files.video.videoLink || '',
					image:
						question.files.image.status == 'success'
							? question.files.image.link
							: '',
					answers: convertAnswersFormStateDataToAnswersExerciseData(
						question.answers
					),
				}
			}),
		},
		status: 0, // Чтобы TS не ругался
		statusUpdated: '', // Чтобы TS не ругался
	}

	if (formState.exerciseId) {
		exerciseData.id = formState.exerciseId
	}

	return exerciseData
}

/**
 * Переводит объект ответа из Состояния упражнения в объект ответа для сохранения в БД
 * @param {Array} answers — массив ответов на вопрос
 */
function convertAnswersFormStateDataToAnswersExerciseData(
	answers: FormAnswerStateItemType[]
) {
	let answersData: TrainingEntityTypes.TestAnswer[] = []

	answers.forEach((answer) => {
		if (answer.text) {
			answersData.push({
				id: answer.id,
				answer: answer.text,
				correct: answer.correct,
			})
		}
	})

	return answersData
}
