// Страница списка курсов
const adminTrainingEn = {
	// Сообщение при ошибке при обновлении данных курса
	errorWhileUpdateTraining: 'An error occurred while saving the course',
	// Сообщение при ошибке при получении данных курса
	errorWhileDownloadTraining: 'Failed to load course data',
	// Надпись на кнопке добавления урока
	addLessonButton: 'Add',
	// Надпись на кнопке добавления группы
	addGroupButton: 'Add a group',

	// ЭКРАН ПОКАЗЫВАЮЩИЙ НЕПРАВИЛЬНЫЙ АДРЕС КУРСА
	// Заголовок экрана про неправильный адрес курса
	wrongTrainingScreenHeader: "The course doesn't exist",
	// Текст экрана про неправильный адрес курса
	wrongTrainingScreenText:
		'It has been deleted or an incorrect address has been entered',
	// Текст на кнопке переадресации в Мои курсы на экране про неправильный адрес курса
	wrongTrainingScreenRedirectButton: 'Go to My Courses',

	// ЭКРАН ПРО ОТСУТСТВИЕ УРОКОВ В КУРСЕ
	noLessonsScreenFirstTextLne: 'No lessons have been created yet',
	noLessonsScreenSecondTextLne: 'Time to get creative!',

	// ШАБЛОНЫ УРОКОВ
	// Сообщение об ошибке при получении шаблонов уроков с сервера
	lessonPresetErrorWhileDataLoading:
		'An error occurred while retrieving data.',
	// Сообщение об ошибке при не возможность создать шаблон урок при запросе на сервер
	lessonPresetCreationError: 'Failed to create a lesson from the template.',
	// Сообщение об успешном добавлении урока
	lessonPresetSuccessfullyAdded: 'Lesson successfully added',

	// МОДАЛЬНОЕ ОКНО ПРИМЕНЕНИЯ ШАБЛОНА К УРОКУ
	applyPresetModalTitle: 'Create a lesson from a template?',
	// Кнопка подтверждения в модальном окне
	applyPresetModalOnBtn: 'Create',
	// Кнопка отмены в модальном окне
	applyPresetModalCancelBtn: 'Cancellation',
	// Текст модального окна
	applyPresetModalText: 'Create a lesson using this template',

	// МОДАЛЬНОЕ ОКНО УДАЛЕНИЯ ШАБЛОНА
	// Заголовок модального окна
	deletePresetModalTitle: 'Delete this template?',
	// Кнопка подтверждения в модальном окне
	deletePresetModalOkBtn: 'Delete',
	// Кнопка отмены в модальном окне
	deletePresetModalCancelBtn: 'Cancellation',
	// Текст в модальном окне
	deletePresetModalText:
		'The template will be removed from your template library. This action will not delete the lesson.',
	// Сообщение после успешного удаления шаблона
	deletePresetModalSuccess: 'Template removed',
	// Сообщение об ошибке после попытки удаления шаблона
	deletePresetModalError: 'Template deletion error',

	// СПИСОК ШАБЛОНОВ
	// Кнопка Все шаблоны
	allPresetsButton: 'All templates',
	// Сообщение про ненайденные шаблоны
	presetsHasNotFound: 'No templates found',
	// Вкладка «Публичные шаблоны»
	presetsTabsPublic: 'Public templates',
	// Вкладка «Ваши шаблоны»
	presetsTabsYours: 'Your templates',
	// Заголовок боковой панели со списком шаблонов уроков
	presetsDrawerTitle: 'Templates',

	// КАРТОЧКА ШАБЛОНА УРОКА
	// Кнопка удаления шаблона
	presetCardDeleteButton: 'Delete',

	// Ошибка при получении списка уроков
	lessonsListErrorWhileLoadingData:
		'An error occurred while retrieving the data.',
	// Текст на карточке-кнопке добавления урока
	lessonsListAddLessonCard: 'Add a lesson',

	// МОДАЛЬНОЕ ОКНО СОЗДАНИЯ УРОКА
	// Заголовок модального окна
	addLessonModalTitle: 'Adding a lesson',
	// Подпись поля названия урока
	addLessonModalNameFieldLabel: 'Title',
	// Ошибка если поле названия урока не заполнили
	addLessonModalNameFieldRequiredError: 'Enter the title of the lesson.',
	// Подпись поля описания урока
	addLessonModalDescriptionFieldLabel: 'Description',
	// Подпись поля обложки урока
	addLessonModalCoverFieldLabel: 'Description',
	// Кнопка отмены изменения формы
	addLessonModalCancelBtn: 'Cancellation',
	// Кнопка создания урока
	addLessonModalOkBtn: 'Add',
	// Ошибка сервера при создании урока
	addLessonModalReqErrorWhileCreation: 'Failed to add a lesson',
	// Сообщение после успешного создания урока
	addLessonModalLessonCreatedMessage: 'Lesson created.',

	// МОДАЛЬНОЕ ОКНО РЕДАКТИРОВАНИЯ УРОКА
	// Заголовок модального окна
	editLessonModalTitle: 'Editing a lesson',
	// Подпись поля названия урока
	editLessonModalNameFieldLabel: 'Title',
	// Ошибка если поле названия урока не заполнили
	editLessonModalNameFieldRequiredError: 'Enter the title of the lesson.',
	// Подпись поля описания урока
	editLessonModalDescriptionFieldLabel: 'Description',
	// Подпись поля обложки урока
	editLessonModalCoverFieldLabel: 'Cover',
	// Кнопка отмены изменения формы
	editLessonModalCancelBtn: 'Cancellation',
	// Кнопка создания урока
	editLessonModalOkBtn: 'Add',
	// Ошибка сервера при создании урока
	editLessonModalReqErrorWhileSave: 'Failed to change the lesson.',
	// Сообщение после успешного создания урока
	editLessonModalLessonCreatedMessage: 'Lesson updated.',

	// МОДАЛЬНОЕ ОКНО УДАЛЕНИЯ УРОКА
	// Заголовок модального окна
	deleteLessonModalTitle: 'Do you really want to delete the lesson?',
	// Текст модального окна
	deleteLessonModalText: 'This action cannot be undone.',
	// Ошибка сервера при удалении урока
	deleteLessonModalReqError: 'Failed to delete the lesson.',

	// КАРТОЧКА УРОКА
	// Кнопка редактирования урока
	lessonCardButtonEdit: 'Edit',
	// Кнопка перемещающая урок выше
	lessonCardButtonUp: 'Above',
	// Кнопка перемещающая урок ниже
	lessonCardButtonDown: 'Below',
	// Кнопка открывающая модальное окно подтверждения удаления урока
	lessonCardButtonDelete: 'Delete',
	// Кнопка создающая шаблон урока на основе другого урока
	lessonCardButtonCreateTemplate: 'Make it a template',
	// Текст 'Урок № '. Пробел справа обязателен.
	lessonCardLessonNumber: 'Delete',
	// Сообщение про успешное создание шаблона урока
	lessonCardTemplateCreatedMessage: 'Template successfully added',
	// Сообщение про ошибку при создании шаблона урока
	lessonCardErrorWhileTemplateCreationMessage: 'Template creation error',
}

export default adminTrainingEn
