// Страница списка курсов
const adminTrainingsEn = {
	// ЭКРАН СООБЩАЮЩИЙ ПРО ОТСУТСТВИЕ СОЗДАННЫХ КУРСОВ
	// Верхняя надпись сообщения про отсутствие созданных курсов
	noTrainingCreatedFirstLine: 'No courses have been created yet.',
	// Нижняя надпись сообщения про отсутствие созданных курсов
	noTrainingCreatedSecondLine: 'Start creating right now.',
	// Сообщение на странице со списком курсов для менеджера и куратора
	noTrainingCreatedInCuratorPage:
		'This page will display the courses that the school administrator will create',

	// Надпись на кнопке добавления курса
	addTrainingButton: 'Add',

	// МОДАЛЬНОЕ ОКНО ДОБАВЛЕНИЯ КУРСА
	// Заголовок модального окна добавления курса
	addTrainingModalTitle: 'Adding a course',
	editTrainingModalTitle: 'Update a course',
	// Подпись к полю с названием создаваемого курса
	addTrainingModalFieldTrainingNameLabel: 'Title',
	// Текст ошибки в поле с названием создаваемого курса
	addTrainingModalFieldTrainingNameError: 'Enter the name of the course.',
	// Подпись к полю с описанием создаваемого курса
	addTrainingModalFieldTrainingDescriptionLabel: 'Desciption',
	// Подпись к полю с отображением курса на главной странице
	addTrainingModalFieldTrainingShowOnMainLabel: 'Mobile app',
	//
	addTrainingModalFieldTrainingShowOnMainCaption:
		'Display on the home screen',
	// Подпись к полю с обложкой создаваемого курса
	addTrainingModalFieldTrainingCoverLabel: 'Cover',
	// Подпись к полю с типом курса
	addTrainingModalFieldTrainingTypeLabel: 'Type of course',
	// Подпись к полю с уровнем курса
	addTrainingModalFieldTrainingLevelLabel: 'Level',
	// Подпись к полю язык курса
	addTrainingModalFieldTrainingLangLabel: 'Lang',
	// Порядковый номер курса
	addTrainingModalFieldTrainingOrderLabel: 'Order',
	// Кнопка отмены создания курса
	addTrainingModalCancelButton: 'Cancellation',
	// Кнопка создания курса
	addTrainingModalOkButton: 'Save',
	// Сообщение про невозможность создания курса из-за ошибки
	addTrainingModalCannotCreateDueError: 'Failed to create a course.',
	// Сообщение про успешное создание курса
	addTrainingModalTrainingCreatedMessage: 'Course added.',
	// Сообщение об успешном изменении курса
	editTrainingModalTrainingCreatedMessage: 'Course updated.',
	// Надпись карточке добавления нового курса
	addTrainingButtonCard: 'Add a course',

	// КАРТОЧКА КУРСА
	// Сообщение про неудачную попытку удаления курса
	trainingCardCannotDeleteTrainingError: 'Failed to delete the course',
	// Сообщение про успешное удаление курса
	trainingCardTrainingDeletedMessage: 'Course deleted',
	// Заголовок модального окна подтверждения удаления курса
	trainingCardDeleteModalHeader: 'Course deletion',
	// Текст модального окна подтверждения удаления курса
	trainingCardDeleteModalText: 'Are you sure you want to delete the course?',
	// Кнопка «Группы»
	trainingCardGroupsButton: 'Groups',
	// Кнопка «Настройки групп»
	trainingCardGroupSettingsButton: 'Group settings',
	// Кнопка удаления курса
	trainingCardDeleteTrainingButton: 'Delete',
}

export default adminTrainingsEn
