import { useCallback } from 'react'
import { produce } from 'immer'
import Quill from 'quill'
import { DropWordsExerciseFormStateType } from './stateType'
import { getFormState, setFormState } from '../../common/exerciseFormCommonFunc'
import { getTextObjById } from './check'

/** Функция обрамляет выделенный текст фигурными скобками */
export function makeSelectedWordGap(quillRef: null | Quill) {
	if (!quillRef) return
	const range = quillRef.getSelection() // {index: 6, length: 7}

	if (range) {
		quillRef.insertText(range.index, '{')
		quillRef.insertText(range.index + range.length + 1, '}')
	}
}

/**
 * Хук возвращает функцию, которая срабатывает при изменении значения редактора
 * @param {Number} textObjId — id текстового блока
 */
export function useGetOnChangeEditorValue(textObjId: number) {
	return useCallback(function (newValue: string) {
		const formState = getFormState<DropWordsExerciseFormStateType>()

		const newState = produce(formState, (draft) => {
			const textObj = getTextObjById(draft, textObjId)
			if (!textObj) return draft

			textObj.text = newValue

			// Убрать ошибку про отсутствующий текст и нужно ли показывать сообщение с ошибкой про отсутствие текста в редакторе
			textObj.noTextError = false
			textObj.wrongBracesPairError = false

			draft.isFormInvalid = false
		})

		setFormState(newState)
	}, [])
}
