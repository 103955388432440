import { FC } from 'react'
import { FileTextOutlined } from '@ant-design/icons'
import CloseIcon from 'ui/icons/live/Close'

import styles from './UploadProgress.module.scss'

interface UploadProgressProps {
	isVisible: boolean
	fileName: string | null
	progress: number
}

export const UploadProgress: FC<UploadProgressProps> = (props) => {
	const { fileName, progress, isVisible } = props

	if (!isVisible) {
		return null
	}

	return (
		<div className={styles.UploadProgress}>
			<div className={styles.UploadProgress__Header}>
				<div className={styles.Header__FileName}>
					<FileTextOutlined style={{ color: '#2573E6' }} />
					<span className={styles.UploadProgress__Text}>
						{fileName}
					</span>
				</div>
				<div className={styles.UploadProgress__Close}>
					<CloseIcon />
				</div>
			</div>
			<div className={styles.UploadProgress__Content}>
				<progress
					className={styles.UploadProgress__Progress}
					value={progress}
					max="100"
				/>
				<span
					className={styles.UploadProgress__ProgressValue}
				>{`${progress}%`}</span>
			</div>
		</div>
	)
}
