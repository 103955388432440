import React, { ReactNode } from 'react'
import { Modal } from 'antd'
import './ModalWithForm.scss'

type ModalWithFormProps = {
	title: string
	children: ReactNode
	open: boolean
	onCancel?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

// Настройка модального окна в котором будет форма: Убраны кнопки утверждения и отмены
function ModalWithForm(props: ModalWithFormProps) {
	const { title, children, open, onCancel } = props

	return (
		<Modal
			title={title}
			open={open}
			onCancel={onCancel}
			footer={[]}
			className="modal-with-form"
			centered
		>
			{children}
		</Modal>
	)
}

export default ModalWithForm
