import { AxiosError } from 'axios'
import useSystemStore from 'parts/systemStore/systemStore'
import { schoolQuery } from 'parts/requests/school/schoolQuery'
import ServerTypes from 'parts/types/ServerTypes'
import useRemoveUserModalStore from '../zustand/store'
import { RemoveUserModalStoreType } from '../zustand/storeTypes'

/** Хук возвращающий функцию удаляющую пользователя */
export function useGetDeleteUser() {
	const user = useSystemStore((store) => store.user)
	const updateModalStore = useRemoveUserModalStore(
		(store) => store.updateStore
	)

	const deleteUserQuery = schoolQuery.deleteUser.useMutation({
		onMutate: getMutateOnBefore(updateModalStore),
		onError: getMutateOnError(updateModalStore),
		onSettled: getMutateOnAfter(updateModalStore),
	})

	return function () {
		deleteUserQuery.mutate(user.id)
	}
}

/**
 * Функция, выполняемая до запросов
 * @param updateModalStore — функция изменяющая данные в объекте состояния модального окна
 */
function getMutateOnBefore(
	updateModalStore: RemoveUserModalStoreType.UpdateStore
) {
	return function () {
		// Поставить статус загрузки
		updateModalStore({ isSubmitting: true })
	}
}

/**
 * Универсальная функция, выполняемая при появлении ошибки после запроса
 * @param updateModalStore — функция изменяющая данные в объекте состояния модального окна
 */
function getMutateOnError(
	updateModalStore: RemoveUserModalStoreType.UpdateStore
) {
	return function (err: unknown) {
		const error = err as AxiosError<ServerTypes.ErrorResponse>

		if (error.response) {
			// Поставить данные ошибки в Состояние, чтобы показать их в форме
			updateModalStore({ formErrors: error.response.data })
		}

		// Убрать статус загрузки
		updateModalStore({ isSubmitting: false })
	}
}

/**
 * Функция, выполняемая при успешном запросе на удаление пользователя.
 * @param updateModalStore — функция изменяющая данные в объекте состояния модального окна
 */
function getMutateOnAfter(
	updateModalStore: RemoveUserModalStoreType.UpdateStore
) {
	return function () {
		// Убрать статус загрузки и закрыть модальное окно
		updateModalStore({ isSubmitting: false, isModalOpen: false })
	}
}
