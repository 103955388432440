import $api from '../http'
import CommentsTypes from './commentsApiTypes'

const commentsRequests = {
	// Получение ленты всех ответов
	getAllComments(params?: CommentsTypes.GetCommentsParams) {
		let paramsStr = createCommentsParamsStr(params)

		return $api.get<CommentsTypes.GetComments>(
			'/api/exercises/comments' + paramsStr
		)
	},

	answerToComment(dto: CommentsTypes.AnswerToCommentDto) {
		return $api.post<CommentsTypes.AnswerToComment>(
			'/api/exercises/comments/answer',
			dto
		)
	},

	archiveComment(commentId: number) {
		return $api.post<CommentsTypes.ArchiveComment>(
			`/api/exercises/comments/${commentId}/archive`
		)
	},

	unArchiveComment(commentId: number) {
		return $api.post<CommentsTypes.ArchiveComment>(
			`/api/exercises/comments/${commentId}/unarchive`
		)
	},
}

export default commentsRequests

function createCommentsParamsStr(params?: CommentsTypes.GetCommentsParams) {
	let paramsStr = ''

	if (params && Object.keys(params).length) {
		let paramsParts = []

		if (params.offset !== undefined) {
			paramsParts.push('offset=' + params.offset)
		}

		if (params.limit !== undefined) {
			paramsParts.push('limit=' + params.limit)
		}

		if (params.archive !== undefined) {
			paramsParts.push('archive=' + params.archive)
		}

		paramsStr += '?' + paramsParts.join('&')
	}

	return paramsStr
}
