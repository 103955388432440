import { useGetBreadcrumbs } from './fn/breadcrumbs'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import TrainingStudentService from 'parts/services/TrainingStudentService'
import { useGetHeaderTexts } from './fn/headerAndDescription'

/** Обёртка страницы урока */
function LessonPageHeader() {
	const { isLoading, isError } = TrainingStudentService.useGetTraining()

	const breadcrumbs = useGetBreadcrumbs()
	const { headerText, descriptionText } = useGetHeaderTexts()

	// Не отрисовывать шапку если данные ещё не загрузились или есть ошибка
	if (isLoading || isError) {
		return null
	}

	return (
		<PageHeader
			breadcrumbs={breadcrumbs}
			header={headerText}
			description={descriptionText}
		/>
	)
}

export default LessonPageHeader
