import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import './UCardBlock.scss'

type UBlockProps = {
	bgColor?: UBlockBgColor
	// Цвет обводки. Если не указывать, то обводки не будет
	borderColor?: UBlockBorderColor
	// Должен ли показываться курсор-палец при наведении на карточку
	hover?: boolean
	href?: string
	shadow?: boolean
	extraClass?: string
	padding?: 'middle' | 'small'
	onClick?: () => void
	children: ReactNode | ReactNode[] // Контент блока
	// Значение атрибута data-cy для выбора элемента при тестировании
	dataCyAttr?: string
}

export enum UBlockBgColor {
	White = 'white',
	Green = 'green',
	Red = 'red',
	Orange = 'orange',
	Blue = 'blue',
	Gray = 'gray',
}

export enum UBlockBorderColor {
	Green = 'green',
	Red = 'red',
	Orange = 'orange',
	Blue = 'blue',
	Gray = 'gray',
}

// Обёртка контента для карточки с закруглением и серой обводкой
export function UCardBlock(props: UBlockProps) {
	const {
		bgColor = 'white',
		borderColor,
		hover = false,
		href,
		shadow = false,
		extraClass,
		padding = 'middle',
		onClick = () => {},
		children,
		dataCyAttr,
	} = props

	const classes = cn(
		'u-card-block',
		'u-card-block--bg-' + bgColor,
		borderColor && 'u-card-block--border',
		borderColor && 'u-card-block--border-' + borderColor,
		hover && 'u-card-block--hover',
		shadow && 'u-card-block--shadow',
		'u-card-block--padding-' + padding,
		extraClass
	)

	return href ? (
		<Link to={href!} className={classes} data-cy={dataCyAttr ?? null}>
			{children}
		</Link>
	) : (
		<div className={classes} onClick={onClick} data-cy={dataCyAttr ?? null}>
			{children}
		</div>
	)
}
