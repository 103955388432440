import React from 'react'
import { useTranslation } from 'react-i18next'
import AuthPage from '../../../common/AuthPage/AuthPage'
import ContentWrapper from '../../../common/ContentWrapper/ContentWrapper'

// Страница с подтверждением удаления аккаунта
function DeleteAccountDonePage() {
	const { t } = useTranslation()

	return (
		<AuthPage>
			<ContentWrapper header={t('auth.deleteAccDonePageHeader')} />
		</AuthPage>
	)
}

export default DeleteAccountDonePage
