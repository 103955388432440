import { useEffect, useMemo } from 'react'
import EntityTypes from 'parts/types/EntityTypes'
import { groupQuery } from 'parts/requests/group/groupQuery'
import useTransferStudentsModalStore from '../zustand/store'
import { TransferStudentsModalStoreType } from '../zustand/storeTypes'
import { SelectProps } from 'antd'
import { getUserName } from 'parts/utils/string'
import UrlService from 'parts/services/UrlService'

/** Наполняет Зустанд данными, которые будут использоваться в модальном окне
 * перемещения учеников между кураторами входящими в группу */
export function useFillInStore() {
	const groupCurators = useGetGroupCurators()

	const updateState = useTransferStudentsModalStore(
		(store) => store.updateState
	)

	useEffect(
		function () {
			if (!groupCurators) return

			fillInStore(groupCurators, updateState)
		},
		[groupCurators]
	)
}

/** Скачивает с сервера и возвращает массив кураторов группы */
function useGetGroupCurators(): undefined | EntityTypes.Curator[] {
	const groupId = UrlService.useGetGroupId()

	const { data: groupCuratorsData } = groupQuery
		.getGroupCurators(groupId)
		.useQuery()

	return useMemo(
		function () {
			if (!groupCuratorsData) return

			return groupCuratorsData.data
		},
		[groupCuratorsData]
	)
}

/**
 * Наполняет объект Состояния данными
 * @param groupCurators
 * @param updateState
 */
async function fillInStore(
	groupCurators: EntityTypes.Curator[],
	updateState: TransferStudentsModalStoreType.UpdateState
) {
	// Подготовить пункты выпадающего списка для выбора куратора
	const curatorSelectOptions = convertCuratorsToSelectOptions(groupCurators)

	// Поставить их в Состояние
	updateState({
		fromCuratorOptions: curatorSelectOptions,
		toCuratorOptions: curatorSelectOptions,
	})
}

function convertCuratorsToSelectOptions(
	curators: EntityTypes.Curator[]
): SelectProps['options'] {
	return curators.map((curator) => {
		return {
			value: curator.id,
			label: getUserName(curator),
		}
	})
}
