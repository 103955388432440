import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import AppUrls from 'parts/constants/pageUrl'
import { useGetButtons } from './fn/buttons'
import { useGetTabConfig } from './fn/tabsConfig'

// Заголовок страниц с комнатами и с уроками
function LiveRoomsPageHeader() {
	const buttons = useGetButtons()
	const tabsConfig = useGetTabConfig()

	return (
		<PageHeader
			breadcrumbs={[
				{
					path: AppUrls.LiveRooms().url,
					breadcrumbName: AppUrls.LiveRooms().name,
				},
			]}
			header={AppUrls.LiveRooms().name}
			buttons={buttons}
			tabs={tabsConfig}
		/>
	)
}

export default LiveRoomsPageHeader
