import React, { useMemo } from 'react'
import { LandingTypes } from 'parts/types/LandingTypes'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import { useGetUpdateTextInLandingStore } from 'pages/landing/landingConstructor/common/updateTextInLandingStore'

/**
 * Возвращает обработчик изменения редактируемого спана
 * @param propsPathArr — массив с названиями свойств и индексов с путём до свойства, в котором находится редактируемый текст.
 */
export function useGetOnInput<
	A extends keyof LandingTypes.Landing,
	B extends keyof LandingTypes.Landing[A],
	C extends keyof LandingTypes.Landing[A][B],
	D extends keyof LandingTypes.Landing[A][B][C],
	E extends keyof LandingTypes.Landing[A][B][C][D],
	F extends keyof LandingTypes.Landing[A][B][C][D][E],
	G extends keyof LandingTypes.Landing[A][B][C][D][E][F]
>(propsPathArr: [A, B?, C?, D?, E?, F?, G?]) {
	const updateTextInLanding = useGetUpdateTextInLandingStore()

	return (event: React.FormEvent<HTMLSpanElement>) => {
		//@ts-ignore
		const newText = event.target.textContent

		// @ts-ignore
		updateTextInLanding(propsPathArr, newText)
	}
}

/**
 * Возвращает изначальный текст абзаца при открытии конструктора одностраничников.
 * Не стал делать связывание текста с данными потому что при редактировании курсор улетает в начало редактируемого блока
 * @param propsPathArr — массив с названиями свойств и индексов с путём до свойства, у которого нужно получить значение.
 */
export function useGetInitialText<
	A extends keyof LandingTypes.Landing,
	B extends keyof LandingTypes.Landing[A],
	C extends keyof LandingTypes.Landing[A][B],
	D extends keyof LandingTypes.Landing[A][B][C],
	E extends keyof LandingTypes.Landing[A][B][C][D],
	F extends keyof LandingTypes.Landing[A][B][C][D][E],
	G extends keyof LandingTypes.Landing[A][B][C][D][E][F]
>(propsPathArr: [A, B?, C?, D?, E?, F?, G?]) {
	const landing = useLandingConstructorStore((store) => store.landing)

	return useMemo(function () {
		let parentObj: any = landing

		for (let i = 0; i < propsPathArr.length; i++) {
			const isLastItem = i == propsPathArr.length - 1
			if (isLastItem) break

			parentObj = parentObj[propsPathArr[i]]
		}

		const lastPropName = propsPathArr[propsPathArr.length - 1]

		return parentObj[lastPropName]
	}, [])
}
