import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { LessonsListAdminWrapperController } from 'widgets/lessonsListAdmin/LessonsListAdminController'

// Кнопка открывающая модальное окно добавления нового урока
function AddLessonButton() {
	const { t } = useTranslation()

	return (
		<Button
			type="primary"
			icon={<PlusOutlined />}
			onClick={LessonsListAdminWrapperController.openAddLessonModal}
		>
			{t('adminTraining.addLessonButton')}
		</Button>
	)
}

export default AddLessonButton
