import useReasonFormStore from '../zustand/store'

/** Возвращает функцию, открывающую модальное окно удаления школы */
export function useGetToggleVisibilityReasonModal() {
	const isModalOpen = useReasonFormStore((store) => store.isOpen)
	const updateModalState = useReasonFormStore((store) => store.updateStore)

	return function () {
		updateModalState({ isOpen: !isModalOpen })
	}
}
