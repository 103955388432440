import { message } from 'antd'
import { AxiosError } from 'axios'
import ServerTypes from 'parts/types/ServerTypes'
import authRequests from 'parts/requests/auth/authRequest'
import AuthApiTypes from 'parts/requests/auth/authApiTypes'
import userRequests from 'parts/requests/user/userRequest'
import UserApiTypes from 'parts/requests/user/userApiTypes'
import { FieldNames, FormValues } from './form'
import useSetConfirmationCodeStore from '../state/store'
import useChangeEmailStore from '../../ChangeEmailModal/state/store'
import { ChangeEmailStoreType } from '../../ChangeEmailModal/state/storeTypes'

/** Хук возвращающий обработчик отправки формы */
export function useGetOnSubmit() {
	const changeEmailModalState = useChangeEmailStore((state) => state)
	const updateConfirmationCodeStore = useSetConfirmationCodeStore(
		(state) => state.updateState
	)

	return async (values: FormValues) => {
		if (!changeEmailModalState.userId) return

		try {
			updateConfirmationCodeStore({ isSubmitting: true })

			// Отправить запрос и получить ответ
			const response = await authRequests.checkEmailCode(
				createDto(values, changeEmailModalState.userId)
			)

			if (response.data) {
				// Отправить запрос на смену почты
				const changeEmailResp = await userRequests.updateEmail(
					createChangeEmailDto(changeEmailModalState)
				)

				if (changeEmailResp.data) {
					// Закрыть модальное окно
					updateConfirmationCodeStore({ isOpen: false })

					message.success('Почта изменена')
				}
			}
		} catch (err) {
			const error = err as AxiosError<ServerTypes.ErrorResponse>

			if (error.response) {
				// Поставить данные ошибки в Состояние, чтобы показать их в форме
				updateConfirmationCodeStore({
					formErrors: error.response.data,
				})
			}
		} finally {
			// Убрать статус загрузки
			updateConfirmationCodeStore({ isSubmitting: false })
		}
	}
}

/**
 * Формирует DTO для отправки запроса
 * @param {Array} values — массив значений полей формы
 * @param {String} userId — id пользователя
 */
function createDto(
	values: FormValues,
	userId: number
): AuthApiTypes.CheckEmailCodeDto {
	return {
		userId,
		activationCode: values[FieldNames.ActivationCode],
	}
}

/**
 * Формирует DTO для отправки запроса
 * @param {Array} changeEmailModalState — объект Состояния модального окна ввода почты для замены
 */
function createChangeEmailDto(
	changeEmailModalState: ChangeEmailStoreType.State
): UserApiTypes.UpdateUserEmailDto {
	return {
		userId: changeEmailModalState.userId!,
		oldEmail: changeEmailModalState.currentEmail,
		password: changeEmailModalState.password,
		email: changeEmailModalState.newEmail,
	}
}
