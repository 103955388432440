import useLessonsPresetsStore from 'widgets/presets/zustand/store'

export default function useGetPresets(isPublic: boolean) {
	const presets = useLessonsPresetsStore((state) =>
		state.presets ? state.presets : []
	)

	if (isPublic) {
		return presets.filter((preset) => preset.isGlobalPreset)
	}

	return presets
}
