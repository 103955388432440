import React from 'react'
import { Route } from 'react-router-dom'
import AppUrls, { LandingUrl, LiveLessonsUrls } from 'parts/constants/pageUrl'
import SuccessPage from 'pages/duty/SuccessPage'
import LandingPage from 'pages/landing/landing/LandingPage/LandingPage'
import LiveLessonEntrancePage from 'pages/liveLessons/liveLessonEntrance/LiveLessonEntrancePage/LiveLessonEntrancePage'
import MainPage from 'pages/duty/MainPage/MainPage'
import MySubscriptionNotification from '../../../pages/my/admin/MySubscriptionNotification/MySubscriptionNotification'
import DeleteAccountPage from 'pages/auth/pages/deleteAccount/DeleteAccountPage/DeleteAccountPage'
import DeleteAccountConfirmPage from 'pages/auth/pages/deleteAccount/DeleteAccountConfirm/DeleteAccountConfirmPage'
import DeleteAccountDonePage from 'pages/auth/pages/deleteAccount/DeleteAccountDone/DeleteAccountDone'
import DeleteAccountMailSentPage from 'pages/auth/pages/deleteAccount/DeleteAccountMailSent/DeleteAccountMailSent'

export enum RouterVarNames {
	SchoolId = 'schoolId',
	PaymentSystemName = 'paymentSystemName',
	TrainingId = 'trainingId',
	LessonId = 'lessonId',
	ExerciseId = 'exerciseId',
	StudentId = 'studentId',
	GroupId = 'groupId',
	EmployeeId = 'employeeId',
	LiveLessonId = 'livelessonId',
	LiveLessonEntranceId = 'liveLessonEntranceId',
	LandingId = 'landingId',
	ExpressCourseId = 'expressCourseId',
	ExpressLessonId = 'expressLessonId',
	DeleteAccountCode = 'deleteAccountCode',
}

export function getSuccessAndFailRoutes() {
	return (
		<Route>
			<Route path={AppUrls.Main().url} element={<MainPage />} />
			<Route path={AppUrls.Success().url} element={<SuccessPage />} />
			<Route
				path={AppUrls.MySubscriptionSuccess().url}
				element={
					<MySubscriptionNotification notificationType="success" />
				}
			/>
			<Route
				path={AppUrls.MySubscriptionFail().url}
				element={<MySubscriptionNotification notificationType="fail" />}
			/>
			<Route
				path={AppUrls.DeleteAccount().url}
				element={<DeleteAccountPage />}
			/>
			<Route
				path={
					AppUrls.DeleteAccountConfirm(
						':' + RouterVarNames.DeleteAccountCode
					).url
				}
				element={<DeleteAccountConfirmPage />}
			/>
			<Route
				path={AppUrls.DeleteAccountDone().url}
				element={<DeleteAccountDonePage />}
			/>
			<Route
				path={AppUrls.DeleteAccountMailSent().url}
				element={<DeleteAccountMailSentPage />}
			/>
		</Route>
	)
}

// Страница одностраничника
export function getLandingRoute() {
	return (
		<Route
			path={LandingUrl.Landing(':' + RouterVarNames.LandingId).url}
			element={<LandingPage />}
		/>
	)
}

// Страница одностраничника
export function getLiveLessonEntranceRoute() {
	return (
		<Route
			path={
				LiveLessonsUrls.LiveLessonEntrance(
					':' + RouterVarNames.LiveLessonEntranceId
				).url
			}
			element={<LiveLessonEntrancePage />}
			key="juy"
		/>
	)
}
