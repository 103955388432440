import { DownloadExerciseFileStatus } from '../../common/exerciseFormCommonFunc'

export type ImageExerciseFormStateType = {
	exerciseId: null | number // id упражнения. Если это новое упражнение, то id не существует
	type: 'image' // Тип упражнения
	order: number
	// Поле «Задание к аудио»
	taskInput: {
		value: string // Значение поля «Задание к аудио»
	}
	noneAnswerInput: {
		checked: boolean // Отмечено ли поле «Без ответа»
	}
	audioAnswerInput: {
		checked: boolean // Отмечено ли поле «Аудио ответ»
	}
	writeAnswerInput: {
		checked: boolean // Отмечено ли поле «Письменный ответ»
	}
	transcriptionInput: {
		checked: boolean // Отмечено ли флаг «Добавить расшифровку»
		value: string // Значение поля «Добавить расшифровку»
	}
	files: FormFileStateItemType[] // Данные об указанных аудио
	noFileHasDownloadedError: boolean // Флаг сообщающий, что не загружено ни одна запись
	noTranscriptionPassedError: boolean // Флаг сообщающий, что поставлен флаг «Добавить расшифровку», но сама расшифровка не написана
	isFormInvalid: boolean // Является ли форма неверной
}

export type FormFileStateItemType = {
	id: number // Идентификатор блока с данными указанного аудио
	link: string // Адрес загруженного файла
	status: DownloadExerciseFileStatus // Статус загрузки
	progress: number // Процент загрузки файла
	fileName: string // Название загружаемого файла
}

const formImageStateItem: FormFileStateItemType = {
	id: 1,
	link: '', // Значение поля «Ссылка на видео»
	status: 'empty',
	progress: 0,
	fileName: '',
}

// Объект начального состояния формы видео-упражнения
export const imageFormInitialState: ImageExerciseFormStateType = {
	exerciseId: null,
	type: 'image',
	order: 0,
	taskInput: {
		value: '',
	},
	noneAnswerInput: {
		checked: true,
	},
	audioAnswerInput: {
		checked: false,
	},
	writeAnswerInput: {
		checked: false,
	},
	transcriptionInput: {
		checked: false,
		value: '',
	},
	files: [formImageStateItem],
	noFileHasDownloadedError: false,
	noTranscriptionPassedError: false,
	isFormInvalid: false,
}
