import { useEffect } from 'react'
import { Form, FormInstance } from 'antd'
import { translite } from 'parts/utils/string'
import useAccountStore from '../../../../../student/profile/zustand/store'
import { setErrorsToFields } from 'parts/utils/form'

export enum FieldsNames {
	SchoolName = 'schoolName',
	Domain = 'domain',
}

export interface FormValues {
	[FieldsNames.SchoolName]: string
	[FieldsNames.Domain]: string
}

/** Хук следит за изменением значения поля имени школы и ставит в поле домена школе транслитерированное значение домена. */
export function useManageSchoolDomainInput() {
	const form = Form.useFormInstance()
	const schoolNameValue = Form.useWatch('schoolName', form)

	useEffect(
		function () {
			const transliteratedSchoolName = translite(schoolNameValue)
				.toLowerCase()
				.replaceAll(' ', '')

			form.setFields([
				{
					name: 'domain',
					value: transliteratedSchoolName,
					errors: [],
				},
			])
		},
		[schoolNameValue]
	)
}

/**
 * Обработчик изменения поля домена школы.
 * Убирает ошибку при изменении.
 * @param {Object} e — объект события.
 * @param {Object} form — объект формы
 */
export function onSchoolDomainInputChange(
	e: React.ChangeEvent<HTMLInputElement>,
	form: FormInstance
) {
	form.setFields([
		{
			name: 'domain',
			value: e.target.value,
			errors: [],
		},
	])
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useAccountStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
