import React, { useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Button, Upload, UploadFile } from 'antd'
import {
	AcceptFileType,
	makeRequest,
	removeFile,
	useSetFileUrlsToFormData,
} from './fn/fn'
import UploadFileTypes from 'parts/constants/uploadTypes'

type UploadFileButtonProps = {
	// Имя поля
	inputName: string
	// Изначальный массив сведений об изначальных и загруженных файлов
	initialFileList: UploadFile[]
	// Тип файла. От этого зависит в какой папке на сервере он будет располагаться
	fileType: UploadFileTypes
	accept: AcceptFileType
	// Максимальное количество загружаемых файлов
	maxCount: number
}

function UploadFileButton(props: UploadFileButtonProps) {
	const { inputName, initialFileList, fileType, accept, maxCount } = props

	const [fileList, setFileList] = useState<UploadFile[]>(initialFileList)

	useSetFileUrlsToFormData(inputName, fileList)

	return (
		<Upload
			// name={inputName}
			customRequest={(options) =>
				makeRequest(options, setFileList, fileType)
			}
			accept={accept}
			maxCount={maxCount}
			defaultFileList={fileList}
			onRemove={(removedFile) =>
				removeFile(removedFile, fileList, setFileList)
			}
		>
			<Button icon={<UploadOutlined />}>Загрузка файла</Button>
		</Upload>
	)
}

export default UploadFileButton
