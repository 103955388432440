import { Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useIsPanelVisible } from 'pages/landing/landingConstructor/tariffs/panel/Panel/fn/various'
import PaymentSegment from 'pages/landing/landingConstructor/tariffs/panel/payment/PaymentSegment/MainPayment'
import { useGetResetTariff } from 'pages/landing/landingConstructor/tariffs/panel/Panel/fn/resetTariff'
import { useGetDeleteTariff } from 'pages/landing/landingConstructor/tariffs/panel/Panel/fn/deleteTariff'
import PanelHeader from 'pages/landing/landingConstructor/tariffs/panel/PanelHeader/PanelHeader'
import FlashTariffSwitcher from 'pages/landing/landingConstructor/tariffs/panel/FlashTariffSwitcher/FlashTariffSwitcher'
import 'pages/landing/landingConstructor/tariffs/panel/Panel/Panel.scss'

// Панель с настройками активного тарифа
function Panel() {
	const isPanelVisible = useIsPanelVisible()
	if (!isPanelVisible) return null

	return (
		<div className="tariff-panel">
			<PanelHeader />
			<FlashTariffSwitcher />
			<PaymentSegment />
			<BottomButtons />
		</div>
	)
}

export default Panel

// Кнопки сброса изменений и удаления тарифа
function BottomButtons() {
	const resetTariff = useGetResetTariff()
	const deleteTariff = useGetDeleteTariff()

	return (
		<div className="tariff-panel__bottom">
			{/*<Button icon={<ClearOutlined />} block onClick={resetTariff}>
				Сбросить настройки
			</Button>*/}
			<Button
				danger
				icon={<DeleteOutlined />}
				block
				onClick={deleteTariff}
			>
				Удалить тариф
			</Button>
		</div>
	)
}
