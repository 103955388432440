import { ReactNode } from 'react'
import Loader from 'ui/Loader/Loader'
import LiveRoomsList from '../LiveRoomsList'
import { useFillInStore } from '../zustand/fillStore/fillInStore'
import useLiveRoomStore from '../zustand/store'
import { LiveRoomStoreType } from '../zustand/storeTypes'

type useGetLiveRoomsRouterReturn = {
	status: LiveRoomStoreType.DataLoadingStatus
	element: ReactNode
}

/** Хук возвращающий статус компонента LivelessonList и сам компонент в зависимости от статуса */
export function useGetLiveRoomsRouter(): useGetLiveRoomsRouterReturn {
	useFillInStore()

	const [dataLoadingStatus, errorMessage] = useLiveRoomStore((store) => [
		store.dataLoadingStatus,
		store.errorMessage,
	])

	if (dataLoadingStatus == 'loading') {
		return {
			status: dataLoadingStatus,
			element: <Loader />,
		}
	} else if (dataLoadingStatus == 'error') {
		const errorText = errorMessage
			? errorMessage
			: 'Во время запроса произошла ошибка'

		return {
			status: dataLoadingStatus,
			element: <p>{errorText}</p>,
		}
	} else if (dataLoadingStatus == 'noRooms') {
		return {
			status: dataLoadingStatus,
			element: <p>Комнат нет</p>,
		}
	}

	return {
		status: dataLoadingStatus,
		element: <LiveRoomsList />,
	}
}
