import { TFunction } from 'i18next'
import { useContext, useEffect } from 'react'
import { produce } from 'immer'
import useSystemStore from 'parts/systemStore/systemStore'
import { useTranslation } from 'react-i18next'
import { StateContext } from '../../state/stateContext'
import { StateType } from '../../state/stateType'
import SchoolApiTypes from 'parts/requests/school/schoolApiTypes'
import PaymentTypes from 'parts/types/PaymentTypes'
import { getPaymentsDataOfSchool } from '../../../myPaymentsAdmin/common'
import { getCardConnectionType } from '../../../myPaymentsAdmin/MyPayments/fn/getPaymentsData'

/**
 * Получает данные сохранённых платёжных систем, среди них находит данные открытой ПС.
 * И по ним составляет данные нужные для отрисовки формы.
 * Если сохранённых данных нет, то формирует чистые.
 */
export function useSetFormDataToCtx() {
	const school = useSystemStore((store) => store.school)
	const { state, setState } = useContext(StateContext)
	const { t } = useTranslation()

	useEffect(
		function () {
			if (!school) return

			getPaymentsDataOfSchool(school.id).then((payments) => {
				if (!payments) return

				setState((state): StateType.State => {
					return produce(state, (draft) => {
						// Установлю тип соединения с платёжной системой
						draft.connectionType = getCardConnectionType(
							payments,
							state.paymentSystemType!
						)
						// Установлю данные для отрисовки формы
						draft.initialFormData = setInitialFormData(
							state,
							payments,
							t
						)
					})
				})
			})
		},
		[school]
	)
}

/**
 * Создаёт данные для отрисовки формы изменения настроек подключения к платёжной системе.
 * @param state — объект состояния
 * @param payments — массив с данными сохранённых настроек подключения к платёжной системе.
 * @param t
 */
function setInitialFormData(
	state: StateType.State,
	payments: SchoolApiTypes.GetPaymentData,
	t: TFunction<'translation'>
): StateType.InitialFormData {
	if (!state.paymentSystemType) return null

	const paymentData = payments.find(
		(payment) => payment.paymentSystem == state.paymentSystemType
	)

	if (state.paymentSystemType == PaymentTypes.System.UKassa) {
		const payData = paymentData?.paymentData as null | PaymentTypes.UKassa

		return {
			id: paymentData?.id || null,
			type: PaymentTypes.System.UKassa,
			isConnected: !!paymentData?.isConnected,
			fields: {
				shopId: {
					inputType: 'text',
					inputLabel: t('myPayments.paymentFormDataShopId'),
					initValue: payData?.shopId || '',
					tooltip: t('myPayments.paymentFormDataContain_16_letters'),
					placeholder: t(
						'myPayments.paymentFormDataContainTypeShopId'
					),
				},
				secretKey: {
					inputType: 'text',
					inputLabel: 'Ключ API',
					initValue: payData?.secretKey || '',
					tooltip: t('myPayments.paymentFormDataContain_16_letters'),
					placeholder: t(
						'myPayments.paymentFormDataContainTypeApiKey'
					),
				},
			},
		}
	} else if (state.paymentSystemType == PaymentTypes.System.Stripe) {
		const payData = paymentData?.paymentData as null | PaymentTypes.Stripe

		return {
			id: paymentData?.id || null,
			type: PaymentTypes.System.Stripe,
			isConnected: !!paymentData?.isConnected,
			fields: {
				apiKey: {
					inputType: 'text',
					inputLabel: t('myPayments.paymentFormDataContainPublicKey'),
					initValue: payData?.apiKey || '',
					tooltip: t('myPayments.paymentFormDataContain_16_letters'),
					placeholder: t(
						'myPayments.paymentFormDataContainTypePublicKey'
					),
				},
				webhookSecret: {
					inputType: 'text',
					inputLabel: 'Секретный ключ',
					initValue: payData?.webhookSecret || '',
					tooltip: t('myPayments.paymentFormDataContain_16_letters'),
					placeholder: t(
						'myPayments.paymentFormDataContainTypeSecretKey'
					),
				},
			},
		}
	}

	return null
}
