import React, { useState } from 'react'
import { InputRef } from 'antd'
import { getRusNounByNumber } from 'parts/utils/string'

const DAYS_IN_MONTH = 30
const DAYS_IN_YEAR = 360

/**
 * Логика сохранения количества дней в качестве нового значения выпадающего списка и его изменение.
 * Логика добавления нового количества дней в массив дней.
 * @param addItemInputRef — ссылка на поле ввода нового количества дней
 */
export function useManageOptions(addItemInputRef: React.RefObject<InputRef>) {
	const [newDayNum, setNewDayNum] = useState('')

	const onNewDaysNumChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewDayNum(event.target.value)
	}

	const [daysNumArr, setDaysNumArr] = useState([
		DAYS_IN_MONTH,
		DAYS_IN_MONTH * 6,
		DAYS_IN_MONTH * 9,
		DAYS_IN_YEAR,
		DAYS_IN_YEAR * 5,
		DAYS_IN_YEAR * 10,
	])

	const addNewDaysNum = (e: any) => {
		if (newDayNum && daysNumArr.indexOf(Number(newDayNum)) < 0) {
			e.preventDefault()

			setDaysNumArr([...daysNumArr, Number(newDayNum)])
			setNewDayNum('')

			setTimeout(() => {
				addItemInputRef.current?.focus()
			}, 0)
		}
	}

	return {
		daysNumArr,
		onNewDaysNumChange,
		newDayNum,
		addNewDaysNum,
	}
}

/**
 * Преобразовывает массив количества дней в массив для отрисовки пунктов выпадающего списка.
 * @param daysNumArr — массив с количеством дней
 */
export function convertNumbersArrToOptionsDataArr(
	daysNumArr: number[]
): { label: string; value: number }[] {
	return daysNumArr.map((daysNum) => {
		return convertNumberToOptionData(daysNum)
	})
}

/**
 * Преобразовывает число дней в объект для отрисовки пункта выпадающего списка.
 * @param daysNum — массив с количеством дней
 */
function convertNumberToOptionData(daysNum: number): {
	label: string
	value: number
} {
	let label = ''

	if (daysNum % DAYS_IN_MONTH === 0 && daysNum <= DAYS_IN_YEAR) {
		label = getMonthsLabel(daysNum)
	} else if (daysNum % DAYS_IN_YEAR === 0) {
		label = getYearsLabel(daysNum)
	} else {
		label = getDaysLabel(daysNum)
	}

	return {
		label: label,
		value: daysNum,
	}
}

/**
 * Возвращает подпись для пункта сообщающем о количестве месяцев
 * @param days — количество дней
 */
function getMonthsLabel(days: number) {
	return `${days / DAYS_IN_MONTH} ${getRusNounByNumber(
		days / DAYS_IN_MONTH,
		'месяц',
		'месяца',
		'месяцев'
	)}`
}

/**
 * Возвращает подпись для пункта сообщающем о количестве месяцев
 * @param days — количество дней
 */
function getYearsLabel(days: number) {
	return `${days / DAYS_IN_YEAR} ${getRusNounByNumber(
		days / DAYS_IN_YEAR,
		'год',
		'года',
		'лет'
	)}`
}

/**
 * Возвращает подпись для пункта сообщающем о количестве дней
 * @param days — количество дней
 */
function getDaysLabel(days: number) {
	return `${days} ${getRusNounByNumber(days, 'день', 'дня', 'дней')}`
}
