import React from 'react'
import AnswersFeed from '../../AnswersFeed/AnswersFeed'
import useAnswersFeedStore from '../../zustand/store'

// Контентная часть ленты ответов для ученика
function LayoutForStudent() {
	const answersFeed = useAnswersFeedStore((store) => store.answersFeed)

	return (
		<div
			style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
		>
			<AnswersFeed answers={answersFeed} />
		</div>
	)
}

export default LayoutForStudent
