import { useEffect } from 'react'
import { produce } from 'immer'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import { groupQuery } from 'parts/requests/group/groupQuery'
import useGroupsStore from 'widgets/groups/trainingGroupsTable/zustand/store'
import { GroupsStoreType } from 'widgets/groups/trainingGroupsTable/zustand/storeTypes'
import GroupsApiTypes from 'parts/requests/group/groupsApiTypes'
import { addDaysToDate, dateStrToHumanDateStr } from 'parts/utils/time'
import useSystemStore from 'parts/systemStore/systemStore'
import EntityTypes from 'parts/types/EntityTypes'

/** Получает данные о группах курса и помещает в Зустанд */
export function useFillInStore(
	trainingId: number,
	autoLoad: boolean,
	groups?: EntityTypes.GroupData[] | GroupsApiTypes.UserGroup[]
) {
	useSetAdminGroups(trainingId, autoLoad, groups as EntityTypes.GroupData[])
	useSetCuratorGroups(
		trainingId,
		autoLoad,
		groups as GroupsApiTypes.UserGroup[]
	)
}

/** В таблице групп для администратора или менеджера нужно показывать группы курса, а для куратора группы курса где он назначен.
 * Поэтому используются один из двух запросов в зависимости от роли пользователя.
 * Функция скачивает с сервера данные по группам или курса или сотрудника, формирует массив групп и возвращает его. */
function useSetAdminGroups(
	trainingId: number,
	autoLoad: boolean,
	groups?: EntityTypes.GroupData[]
) {
	const userRoles = useGetUserRole()

	// Получить данные групп если пользователь администратор или менеджер
	const { data: adminTrainingGroupsRes } = groupQuery
		.getTrainingGroups(trainingId)
		.useQuery({
			onError: queryError,
			enabled: autoLoad && userRoles.isAdminOrManager && !groups,
		})

	// Если данные получены с сервера
	useEffect(
		function () {
			if (!adminTrainingGroupsRes || groups) return

			const preparedGroups = convertAdminTrainingGroupsToStateGroups(
				adminTrainingGroupsRes.data
			)

			putGroupsInStore(trainingId, preparedGroups)
		},
		[adminTrainingGroupsRes]
	)

	// Если данные передали сверху
	useEffect(
		function () {
			if (autoLoad || !groups || !userRoles.isAdminOrManager) return

			const preparedGroups =
				convertAdminTrainingGroupsToStateGroups(groups)

			putGroupsInStore(trainingId, preparedGroups)
		},
		[groups, autoLoad]
	)
}

function useSetCuratorGroups(
	trainingId: number,
	autoLoad: boolean,
	groups?: GroupsApiTypes.UserGroup[]
) {
	const userRoles = useGetUserRole()
	const user = useSystemStore((store) => store.user)
	const currentUserId = user.id

	// Получить данные групп если пользователь куратор
	const { data: curatorTrainingGroupsRes } = groupQuery
		.getUserGroups(currentUserId)
		.useQuery({
			onError: queryError,
			enabled: autoLoad && userRoles.isCurator && !groups,
		})

	// Если данные получены с сервера
	useEffect(
		function () {
			if (!curatorTrainingGroupsRes || groups) return

			const preparedGroups = convertCuratorGroupsToStateGroups(
				curatorTrainingGroupsRes.data
			)

			putGroupsInStore(trainingId, preparedGroups)
		},
		[curatorTrainingGroupsRes]
	)

	// Если данные передали сверху
	useEffect(
		function () {
			if (autoLoad || !groups || !userRoles.isCurator) return
			const preparedGroups = convertCuratorGroupsToStateGroups(groups)

			putGroupsInStore(trainingId, preparedGroups)
		},
		[groups, autoLoad]
	)
}

/**
 * Принимает массив групп курса и составляет массив с данными для отрисовки таблицы групп
 * @param trainingGroups — все группы курса
 */
function convertAdminTrainingGroupsToStateGroups(
	trainingGroups: GroupsApiTypes.GetAdminTrainingGroups
): GroupsStoreType.Group[] {
	return trainingGroups.map((group) => {
		return {
			trainingId: group.trainingId,
			groupId: group.id,
			name: group.name,
			maxStudentsNum: group.numOfParticipants,
			studentsNum: group.numOfParticipants,
			startDate: getStartDate(group),
			endDate: getEndDate(group),
			isActive: group.isActive || false,
		}
	})
}

/**
 * Принимает массив групп курса и составляет массив с данными для отрисовки таблицы групп
 * @param curatorGroups
 */
function convertCuratorGroupsToStateGroups(
	curatorGroups: GroupsApiTypes.GetUserGroups
): GroupsStoreType.Group[] {
	return curatorGroups.map((group) => {
		return {
			trainingId: group.trainingId,
			groupId: group.id,
			name: group.name,
			maxStudentsNum: group.numOfParticipants,
			studentsNum: group.numOfParticipants,
			startDate: getStartDate(group),
			endDate: getEndDate(group),
			isActive: group.isActive,
		}
	})
}

/**
 * Функция запускаемая при ошибке запроса данных. Срабатывает для любых запросов.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 * @param err — объект ошибки.
 */
function queryError(err: unknown) {
	tariffsManager.updateStore({
		dataLoadingStatus: 'error',
	})
}

/**
 * Получает массив групп указанного курса и помещает в объект с идентификатором их курса. А этот объект помещает в массив объектов.
 * Это нужно для того, чтобы на странице можно было вывести множество таблиц с группами для разных курсов.
 * @param trainingId — id курса, к которому принадлежат группы, которые нужно добавить в Хранилище.
 * @param trainingGroups — группы курса, которые нужно поставить в массив курсов с группами
 */
function putGroupsInStore(
	trainingId: number,
	trainingGroups: GroupsStoreType.Group[]
) {
	const { trainingsGroups, updateStore } = useGroupsStore.getState()

	const trainingIdx = trainingsGroups.findIndex(
		(training) => training.trainingId === trainingId
	)

	const newTrainingsGroups = produce(trainingsGroups, (draftTrainings) => {
		if (trainingIdx == -1) {
			draftTrainings.push({
				trainingId,
				dataLoadingStatus: 'success',
				groups: trainingGroups,
			})
		} else {
			draftTrainings[trainingIdx].groups = trainingGroups
			draftTrainings[trainingIdx].dataLoadingStatus = 'success'
		}
	})

	updateStore({ trainingsGroups: newTrainingsGroups })
}

type GroupForDate = { startDate: null | string; duration: number }

function getStartDate(group: GroupForDate) {
	if (!group.startDate) {
		return null
	}

	return dateStrToHumanDateStr(group.startDate)
}

function getEndDate(group: GroupForDate) {
	if (!group.startDate && !group.duration) {
		return 'Неизвестно'
	}

	if (group.startDate && !group.duration) {
		return 'Бессрочно'
	}

	if (group.startDate && group.duration) {
		const endDate = new Date(group.startDate)
		addDaysToDate(endDate, group.duration)

		return dateStrToHumanDateStr(endDate)
	}

	return null
}
