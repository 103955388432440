import { useGetAdditionalPrices } from 'pages/landing/landingConstructor/tariffs/card/additionalPrices/AdditionalPrices/fn/getAdditionalPrices'
import AdditionalPrice from 'pages/landing/landingConstructor/tariffs/card/additionalPrices/AdditionalPrice/AdditionalPrice'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'
import 'pages/landing/landingConstructor/tariffs/card/additionalPrices/AdditionalPrices/AdditionalPrices.scss'

type AdditionalPricesPropType = {
	tariff: TariffsStoreType.Tariff
}

function AdditionalPrices(props: AdditionalPricesPropType) {
	const { tariff } = props

	const additionalPrices = useGetAdditionalPrices(tariff.innerId)
	if (!additionalPrices?.length) return null

	return (
		<div className="tariff-additional-prices">
			{additionalPrices.map((price) => (
				<AdditionalPrice tariff={tariff} price={price} key={price.id} />
			))}
		</div>
	)
}

export default AdditionalPrices
