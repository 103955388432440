import { FormFileStateItemType } from './imageFormState'

export type AudioExerciseFormStateType = {
	exerciseId: null | number // id упражнения. Если это новое упражнение, то id не существует.
	type: 'audio' // Тип упражнения
	order: number
	// Поле «Задание к аудио»
	taskInput: {
		value: string // Значение поля «Задание к аудио»
	}
	noneAnswerInput: {
		checked: boolean // Отмечено ли поле «Без ответа»
	}
	audioAnswerInput: {
		checked: boolean // Отмечено ли поля «Аудио ответ»
	}
	writeAnswerInput: {
		checked: boolean // Отмечено ли поля «Письменный ответ»
	}
	transcriptionInput: {
		checked: boolean // Отмечено ли флаг «Добавить расшифровку»
		value: string // Значение поля «Добавить расшифровку»
	}
	files: FormFileStateItemType[] // Данные об указанных аудио
	noFileHasDownloadedError: boolean // Флаг сообщающий, что не загружено ни одна запись
	noTranscriptionPassedError: boolean // Флаг сообщающий, что поставлен флаг «Добавить расшифровку», но сама расшифровка не написана
	isFormInvalid: boolean // Является ли форма неверной
}

const formAudioStateItem: FormFileStateItemType = {
	id: 1,
	link: '', // Значение поля «Ссылка на видео»
	status: 'empty',
	progress: 0,
	fileName: '',
}

// Объект начального состояния формы видео-упражнения
export const audioFormInitialState: AudioExerciseFormStateType = {
	exerciseId: null,
	type: 'audio',
	order: 0,
	taskInput: {
		value: '',
	},
	noneAnswerInput: {
		checked: true,
	},
	audioAnswerInput: {
		checked: false,
	},
	writeAnswerInput: {
		checked: false,
	},
	transcriptionInput: {
		checked: false,
		value: '',
	},
	files: [formAudioStateItem],
	noFileHasDownloadedError: false,
	noTranscriptionPassedError: false,
	isFormInvalid: false,
}
