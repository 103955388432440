import { create } from 'zustand'
import { TrainingStoreType } from './storeTypes'

const useLessonsStudentStore = create<TrainingStoreType.State>((set) => {
	return {
		dataLoadingStatus: 'loading',
		training: undefined as any,
		lessons: undefined as any,

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useLessonsStudentStore
