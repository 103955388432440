import { useFillInStore } from '../../zustand/fn/fillInStore'
import useStudentsStore from '../../zustand/store'
import Loader from 'ui/Loader/Loader'
import StudentsTableAdmin from '../StudentsTableAdmin'
import { StudentsStoreType } from '../../zustand/storeTypes'

type GetTrainingListRouterReturn = {
	status: 'loading' | 'noStudents' | 'hasStudents' | 'error'
	element: JSX.Element
}

/** Хук возвращающий статус компонента StudentsTable и сам компонент в зависимости от статуса */
export function useGetStudentsTableRouter(): GetTrainingListRouterReturn {
	const dataLoadingStatus = useStudentsStore(
		(store) => store.dataLoadingStatus
	)
	const students = useStudentsStore((store) => store.students)
	const searchValues = useStudentsStore((store) => store.searchValues)

	useFillInStore()

	if (dataLoadingStatus === 'loading') {
		return {
			status: dataLoadingStatus,
			element: <Loader />,
		}
	} else if (dataLoadingStatus === 'error') {
		return {
			status: dataLoadingStatus,
			element: <p>Ошибка при загрузке данных</p>,
		}
	}

	// Данные загружены удачно на этом этапе...

	// Если нет учеников и фильтры не использовались, то значит учеников вообще нет. Поэтому поставить статус noStudents.
	if (students.length === 0 && !isFilterUsed(searchValues)) {
		return {
			status: 'noStudents',
			element: <p>noStudents</p>,
		}
	}

	return {
		status: 'hasStudents',
		element: <StudentsTableAdmin />,
	}
}

/**
 * Возвращает булево значение были ли изменены значения в фильтре
 * @param searchValues — значения в фильтре
 */
function isFilterUsed(searchValues: StudentsStoreType.SearchValues) {
	return (
		searchValues.fullName ||
		searchValues.email ||
		searchValues.phone ||
		searchValues.country
	)
}
