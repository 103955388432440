import { AxiosResponse } from 'axios'
import UserApiTypes from 'parts/requests/user/userApiTypes'
import { userQuery } from 'parts/requests/user/userQuery'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { authQuery } from 'parts/requests/auth/authQuery'
import useLiveLessonEntrancePageStore from '../../../zustand/store'
import { LiveLessonEntrancePageStoreType } from '../../../zustand/storeTypes'
import { FieldNames, FormValuesType } from './form'

export function useGetOnSubmit() {
	const email = useLiveLessonEntrancePageStore((store) => store.email)
	const updateEntrancePageState = useLiveLessonEntrancePageStore(
		(store) => store.updateState
	)

	const [emailCheckStatus, setEmailCheckStatus] =
		useState<null | UserApiTypes.CheckEmailStatus>(null)

	// Получение статуса переданной почты
	const checkEmailQuery = userQuery.checkEmailStatus.useMutation({
		onSuccess: afterCheckEmail(setEmailCheckStatus),
	})

	const regStudentQuery = authQuery.registerStudent.useMutation({
		onSuccess: afterRegStudent(updateEntrancePageState),
		onError: regStudentError(updateEntrancePageState),
	})

	useEffect(
		function () {
			if (emailCheckStatus === null || !email) return

			// Если пользователь нигде не зарегистрирован
			if (
				emailCheckStatus === UserApiTypes.CheckEmailStatus.NotExist ||
				emailCheckStatus === UserApiTypes.CheckEmailStatus.NotConfirm
			) {
				updateEntrancePageState({ currentRole: 'unregisteredUser' })
			}
			// Если пользователь существует в другой школе
			else if (
				emailCheckStatus ===
				UserApiTypes.CheckEmailStatus.ExistInAnotherSchool
			) {
				// Сделать запрос на регистрацию ученика.
				// Если вернёт 400, то пользователь является сотрудником в другой школе.
				// Если ученик, то становится учеником в этой.
				regStudentQuery.mutate({ email })
			}
			// Если пользователь зарегистрирован в этой школе или если пользователь не подтвердил почту,
			// то поставить в currentRole значение allowedRole чтобы была автоматическая переадресация на страницу входа.
			else if (
				emailCheckStatus === UserApiTypes.CheckEmailStatus.Exists
			) {
				updateEntrancePageState({
					currentRole: 'allowedRole',
				})
			}
		},
		[emailCheckStatus]
	)

	return async function (values: FormValuesType) {
		updateEntrancePageState({ email: values[FieldNames.Email] })

		setTimeout(() => {
			checkEmailQuery.mutate({ email: values[FieldNames.Email] })
		}, 10)
	}
}

/**
 * Функция, выполняемая после запроса на проверку почты.
 * @param setEmailCheckStatus — функция устанавливающая статус почты в местное Состояние
 */
function afterCheckEmail(
	setEmailCheckStatus: Dispatch<
		SetStateAction<UserApiTypes.CheckEmailStatus | null>
	>
) {
	return function (
		checkEmailStatusRes: AxiosResponse<UserApiTypes.CheckEmailStatus>
	) {
		setEmailCheckStatus(checkEmailStatusRes.data)
	}
}

/**
 * Функция, выполняемая после запроса на регистрацию ученика с написанной почтой.
 * Если регистрация прошла успешно, то пользователь является учеником в другой школе и сейчас стал учеником в этой.
 * Поэтому поставлю роль allowedRole чтобы открылась страница входа.
 * @param updateEntrancePageState — функция, устанавливающая значение свойства в Хранилище страницы входа в эфир
 */
function afterRegStudent(
	updateEntrancePageState: LiveLessonEntrancePageStoreType.UpdateState
) {
	return function (
		regStudentRes: AxiosResponse<UserApiTypes.CheckEmailStatus>
	) {
		updateEntrancePageState({ currentRole: 'allowedRole' })
	}
}

/**
 * Функция, выполняемая в случае ошибки после запроса на регистрацию ученика с написанной почтой.
 * Если регистрацию совершить не удалось, то пользователь является сотрудником в другой школе.
 * Поэтому поставлю роль staffFromOtherSchool чтобы открылась страница сообщающая это.
 * @param updateEntrancePageState — функция, устанавливающая значение свойства в Хранилище страницы входа в эфир
 */
function regStudentError(
	updateEntrancePageState: LiveLessonEntrancePageStoreType.UpdateState
) {
	return function () {
		updateEntrancePageState({ currentRole: 'staffFromOtherSchool' })
	}
}
