import { produce } from 'immer'
import { LandingTypes } from 'parts/types/LandingTypes'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'

/**
 * Возвращает функцию, переключающую видимость секции одностраничника на противоположную
 * @param sectionName — название секции у которого нужно изменить видимость
 */
export function useGetToggleSectionVisibility(
	sectionName: keyof LandingTypes.Sections
) {
	const [landing, updateStore] = useLandingConstructorStore((store) => [
		store.landing,
		store.updateStore,
	])

	return function () {
		if (!landing) return

		const newLanding = produce(landing, (draft) => {
			const block = draft.sections[sectionName]

			block.isOpen = !block.isOpen
		})

		updateStore({ landing: newLanding })
	}
}
