import useEmployeeStore from '../../../main/zustand/store'
import EntityTypes from 'parts/types/EntityTypes'
import { FormInstance, UploadFile } from 'antd'
import { createFileListFromUrlList } from 'ui/upload/UploadFileInForm/fn/fn'
import useAccountStore from '../../zustand/store'
import { useEffect } from 'react'
import { setErrorsToFields } from 'parts/utils/form'

export enum FieldNames {
	Avatar = 'avatar',
	Role = 'role',
	FirstName = 'firstName',
	LastName = 'lastName',
	City = 'city',
	Country = 'country',
	Phone = 'phone',
}

export type FormValuesType = {
	[FieldNames.Avatar]: UploadFile[]
	[FieldNames.Role]?: EntityTypes.UserRole
	[FieldNames.FirstName]?: string
	[FieldNames.LastName]?: string
	[FieldNames.City]?: string
	[FieldNames.Country]?: string
	[FieldNames.Phone]?: string
}

/** Возвращает первоначальные значения полей формы редактирования ученика. */
export function useGetInitialValues(): FormValuesType {
	const employee = useEmployeeStore((store) => store.employee)!

	return {
		[FieldNames.Avatar]: employee.avatar
			? createFileListFromUrlList([employee.avatar])
			: [],
		[FieldNames.Role]: employee.role,
		[FieldNames.FirstName]: employee.firstName,
		[FieldNames.LastName]: employee.lastName,
		[FieldNames.City]: employee.city,
		[FieldNames.Country]: employee.country,
		[FieldNames.Phone]: employee.phone,
	}
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useAccountStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
