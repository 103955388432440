import { FormInstance, message } from 'antd'
import { AxiosError, AxiosResponse } from 'axios'
import UserApiTypes from 'parts/requests/user/userApiTypes'
import { useTranslation } from 'react-i18next'
import { UseMutationResult, useQueryClient } from 'react-query'
import { userQuery } from 'parts/requests/user/userQuery'
import { getQueryParams } from '../../studentsTableAdmin/zustand/fn/fillInStore'
import useStudentsStore from '../../studentsTableAdmin/zustand/store'
import { AddStudentModalStoreType } from '../zustand/storeTypes'
import { FieldNames, FormValuesType } from './form'
import { generatePassword } from 'parts/utils/string'
import EntityTypes from 'parts/types/EntityTypes'
import PaymentTypes from 'parts/types/PaymentTypes'
import ServerTypes from 'parts/types/ServerTypes'
import useAddStudentStore from '../zustand/store'

/**
 * Возвращает функцию, запускаемую при отправке формы создания нового ученика
 * @param form — ссылка на форму
 */
export function useGetOnSubmit(form: FormInstance) {
	const { t } = useTranslation()

	const [updateStore, isPurchaseOpen] = useAddStudentStore((state) => [
		state.updateStore,
		state.isPurchaseOpen,
	])

	// Запрос создаёт информацию о платеже
	const createManualPaymentQuery = userQuery.createManualPayment.useMutation({
		onError: useGetOnError(t('students.addStudentModalManualPaymentReq')),
	})

	// Запрос добавляет созданного ученика куратору
	const addStudentToCuratorQuery = userQuery.addStudentToCurator.useMutation({
		onError: useGetOnError(
			t('students.addStudentModalAddStudentToCuratorReq')
		),
	})

	// Запрос даёт созданному ученику доступ в личный кабинет
	const sendAccessQuery = userQuery.sendAccess.useMutation({})

	const addUserQuery = userQuery.addUser.useMutation({
		onMutate: useGetAddUserOnBefore(),
		onError: useGetOnError(t('students.addStudentModalSendAccessReq')),
		onSettled: useGetAddUserOnAfter(),
	})

	return async function (formValues: FormValuesType) {
		const addUserReqBody: UserApiTypes.AddUserDto = {
			email: formValues[FieldNames.Email], // 'user@mail.ru'
			password: generatePassword(), // '123456'
			role: EntityTypes.UserRole.STUDENT,
			firstName: formValues[FieldNames.Name], // 'John'
			lastName: formValues[FieldNames.Surname], // 'Doe'
			phone: formValues[FieldNames.Phone], // '+79272445566'
		}

		addUserQuery.mutate(addUserReqBody, {
			onSuccess: getAddUserOnSuccess(
				updateStore,
				isPurchaseOpen,
				createManualPaymentQuery,
				addStudentToCuratorQuery,
				sendAccessQuery,
				formValues,
				form
			),
		})
	}
}

/** Функция, выполняемая до запроса на добавление ученика */
function useGetAddUserOnBefore() {
	const updateStore = useAddStudentStore((state) => state.updateStore)

	return function () {
		// Поставить статус загрузки
		updateStore({ isSubmitting: true })
	}
}

/**
 * Функция, выполняемая при успешном запросе на создание ученика
 * @param updateStore — функция изменяющая объект состояния формы добавления ученика
 * @param isPurchaseOpen — был ли открыт раздел формы с настройками оплаты
 * @param createManualPaymentQuery — запрос на создание информации о платеже
 * @param addStudentToCuratorQuery — запрос на добавление ученика куратору
 * @param sendAccessQuery — запрос на выдачу ученику доступа в личный кабинет
 * @param formValues — данные, введённые в поля формы добавления ученика
 * @param form — ссылка на форму
 */
function getAddUserOnSuccess(
	updateStore: AddStudentModalStoreType.UpdateStore,
	isPurchaseOpen: boolean,
	createManualPaymentQuery: UseMutationResult<
		AxiosResponse<UserApiTypes.CreateManualPayment>,
		any,
		UserApiTypes.CreateManualPaymentDto
	>,
	addStudentToCuratorQuery: UseMutationResult<
		AxiosResponse<UserApiTypes.AddStudentToCurator>,
		any,
		UserApiTypes.AddStudentToCuratorDto
	>,
	sendAccessQuery: UseMutationResult<
		AxiosResponse<null>,
		any,
		UserApiTypes.SendAccessDto
	>,
	formValues: FormValuesType,
	form: FormInstance
) {
	return function (addUserRes: AxiosResponse<UserApiTypes.AddUser>) {
		const studentId = addUserRes.data.id

		// Если форму добавления новой продажи открыли, то добавить новую продажу
		if (isPurchaseOpen) {
			const manualPaymentDto: UserApiTypes.CreateManualPaymentDto = {
				customerId: studentId,
				groupId: parseInt(formValues[FieldNames.Training][1]), // Придёт массив вида ['467', '4']. В первом числе будет id курса, во втором id группы. Это и нужно передавать.
				purchaseDate: formValues[FieldNames.PurchaseDate]
					? formValues[FieldNames.PurchaseDate].toString()
					: '',
				paymentSystem: formValues[FieldNames.PaymentSystem],
				// Сумма. Умножается на 100 потому что значение на сервере хранятся в копейках и центах.
				amount: parseInt(formValues[FieldNames.Amount]) * 100,
				currency: formValues[FieldNames.Currency],
				mode: PaymentTypes.PaymentMode.Payment, // Пока всегда такое значение потому что подписки пока нет.
			}

			// Добавить новую продажу
			createManualPaymentQuery.mutate(manualPaymentDto)
		}

		if (
			formValues[FieldNames.CuratorId] &&
			formValues[FieldNames.CuratorId] !== 'none'
		) {
			const addStudentToCuratorDto: UserApiTypes.AddStudentToCuratorDto =
				{
					studentId,
					curatorId: parseInt(formValues.curatorId),
				}

			// Добавить ученика куратору
			addStudentToCuratorQuery.mutate(addStudentToCuratorDto)
		}

		// Если отметили флаг «Выдать доступ в личный кабинет», то отправить запрос на выдачу доступа
		if (formValues[FieldNames.GetAccess]) {
			sendAccessQuery.mutate({ userId: studentId })
		}

		// Убрать статус загрузки
		updateStore({ isSubmitting: false })

		// Стереть значения во всех полях
		form.resetFields()
	}
}

/** Функция, выполняемая после запроса на добавление ученика */
function useGetAddUserOnAfter() {
	const updateStore = useAddStudentStore((state) => state.updateStore)
	const queryClient = useQueryClient()
	const searchValues = useStudentsStore((state) => state.searchValues)

	return function () {
		// Закрыть модальное окно
		updateStore({ isOpen: false })

		// Пометить список групп неактуальным.
		// После этого React Query скачает новый список
		queryClient.refetchQueries({
			queryKey: [
				userQuery.getUsersPaginated(getQueryParams(searchValues)).key,
			],
		})

		message.success('Ученик добавлен.')
	}
}

/**
 * Универсальная функция, запускаемая при появлении ошибки при запросе.
 * @param errorMessage — сообщение об ошибке, которое должно показываться пользователю.
 */
function useGetOnError(errorMessage: string) {
	const updateStore = useAddStudentStore((state) => state.updateStore)

	return function (err: unknown) {
		const error = err as AxiosError<ServerTypes.ErrorResponse>

		if (error.response) {
			// Поставить данные ошибки в Состояние, чтобы показать их в форме
			updateStore({ formErrors: error.response.data })
		}

		message.error(errorMessage)
	}
}
