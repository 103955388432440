import CyrillicToTranslit from 'cyrillic-to-translit-js'
import EntityTypes from '../types/EntityTypes'

/**
 * Функция принимает строку вида
 * https://www.youtube.com/watch?v=FWxMJnS7JRk или
 * https://www.youtube.com/watch?v=FWxMJnS7JRk&list=WL&index=2&t=214s
 * И возвращает идентификатор видео вида FWxMJnS7JRk
 * */
export function getYouTubeVideoId(link: string) {
	const regExp =
		/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
	const match = link.match(regExp)

	return match && match[2].length === 11 ? match[2] : null
}

export const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

/**
 * Функция возвращает слово в зависимости от числа, которое стоит до него
 * @param {Number} number — число
 * @param {String} one — слово стоящее после числа 1
 * @param {String} two — слово стоящее после числа 2, 3 и 4
 * @param {String} five — слово стоящее после числа 5 и далее
 */
export function getRusNounByNumber(
	number: number,
	one: string,
	two: string,
	five: string
) {
	let n = Math.abs(number)
	n %= 100
	if (n >= 5 && n <= 20) {
		return five
	}
	n %= 10
	if (n === 1) {
		return one
	}
	if (n >= 2 && n <= 4) {
		return two
	}
	return five
}

/**
 * Принимает число и существительное в нулевом, единственном или множественном числе.
 * Возвращает строку с числом и существительным.
 * @param number — количество
 * @param zeroNoun — существительное для нулевого числа (No items)
 * @param oneNoun — существительное в единственном числе (item)
 * @param manyNoun — существительное во множественном числе (items)
 */
export function getNumberAndNounStr(
	number: number,
	zeroNoun: string,
	oneNoun: string,
	manyNoun: string
) {
	if (number === 0) {
		return zeroNoun
	} else if (number === 1) {
		return '1 ' + oneNoun
	}

	return manyNoun + ' ' + manyNoun
}

export function padTo2Digits(num: number) {
	return num.toString().padStart(2, '0')
}

/**
 * Собирает имя пользователя из имеющихся данных.
 * @param user — данные пользователя
 * @param withEmail — можно ли поставить почту если нет имени и фамилии
 */
export function getUserName(
	user: {
		firstName?: null | string
		lastName?: null | string
		email: string
	},
	withEmail = true
) {
	if (user.firstName && !user.lastName) {
		return user.firstName
	} else if (!user.firstName && user.lastName) {
		return user.lastName
	} else if (user.firstName && user.lastName) {
		return user.firstName + ' ' + user.lastName
	}

	return withEmail ? user.email : ''
}

/**
 * Возвращает адрес до изображения пользователя.
 * Принимает адрес до изображения пользователя. Если его нет, то возвращает картинку по умолчанию.
 * @param picSrc — адрес до изображения пользователя (если есть)
 */
export function getUserPic(picSrc: undefined | null | string) {
	return picSrc ? picSrc : '/images/signs/default-user.svg'
}

/**
 * Удаляет из строки новые строки и табуляцию
 * @param string
 */
export function removeTabsAndNewLinesFromStr(string: string) {
	let newString = string.trim()
	newString = newString.replace(/(?:\r\n|\r|\n)/g, '')

	return newString
}

export const generatePassword = () => {
	let length = 8,
		charset =
			'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
		retVal = ''
	for (let i = 0, n = charset.length; i < length; ++i) {
		retVal += charset.charAt(Math.floor(Math.random() * n))
	}
	return retVal
}

export function getCurrentUrl(): string {
	return window.location.hostname === 'localhost'
		? `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
		: `${window.location.protocol}//${window.location.hostname}`
}

/**
 * Возвращает тринслитерированное значение введённого текста
 * @param {String} rusText — текст, которого нужно транслитерировать
 * @param {String} spaceReplacement — каким символом заменить пробельный символ
 */
export function translite(rusText: string, spaceReplacement = ' ') {
	return CyrillicToTranslit().transform(rusText, spaceReplacement)
}

/**
 * Принимает enum с ролью и возвращает человекочитаемое название
 * @param role — название роли
 */
export function getRoleName(role: EntityTypes.UserRole) {
	if (role == EntityTypes.UserRole.ADMIN) {
		return 'Администратор'
	} else if (role == EntityTypes.UserRole.CURATOR) {
		return 'Куратор'
	} else if (role == EntityTypes.UserRole.MANAGER) {
		return 'Менеджер'
	} else {
		return 'Ученик'
	}
}
