import UserApiTypes from 'parts/requests/user/userApiTypes'
import { getUserName, getUserPic } from 'parts/utils/string'
import { dateStrToHumanDateStr } from '../../../../../../../parts/utils/time'
import { StudentsStoreType } from '../storeTypes'

// Класс с методами манипулирования данными учеников в Зустанде
const studentsManager = {
	/**
	 * Принимает массив всех учеников школы и подготавливает его для помещения в Состояние
	 * @param rawStudents — все ученики школы
	 */
	convertServStudentsToStateStudents(
		rawStudents: UserApiTypes.GetCuratorStudents
	): StudentsStoreType.Student[] {
		return rawStudents.map((user) => {
			return {
				id: user.id,
				avatar: getUserPic(user.avatar),
				fullName: getUserName(user, false),
				trainingName: user.trainingName,
				groupName: user.groupName,
				groupStart: dateStrToHumanDateStr(user.groupStartDate),
				groupEnd: dateStrToHumanDateStr(user.groupEndDate),
				isActivated: !!user.isActivated,
			}
		})
	},
}

export default studentsManager
