import React from 'react'
import { Tag } from 'antd'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import useTrainingHeaderStudentStore from '../zustand/store'
import { breadcrumbs, useGetMetaPanelConfig } from './fn/header'
import MetaOutput from 'ui/MetaOutput/MetaOutput'
import { useGetTrainingStatusForHeader } from './fn/getTrainingStatusForHeader'

function TrainingPageHeaderStudent() {
	const trainingDescription = useTrainingHeaderStudentStore(
		(store) => store.header.trainingDescription
	)

	const metaPanelConfig = useGetMetaPanelConfig()

	return (
		<PageHeader
			breadcrumbs={breadcrumbs}
			header={<TrainingNameBlock />}
			description={trainingDescription}
			meta={<MetaOutput items={metaPanelConfig} />}
		/>
	)
}

export default TrainingPageHeaderStudent

function TrainingNameBlock() {
	const header = useTrainingHeaderStudentStore((state) => state.header)

	return (
		<div className="">
			{header.trainingName} <GroupStatus />
		</div>
	)
}

function GroupStatus() {
	const status = useGetTrainingStatusForHeader()
	return <Tag color={status.color}>{status.text}</Tag>
}
