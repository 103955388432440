import { createWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'
import { SidepanelStoreType } from './storeTypes'
import {
	getDefaultCurrentSidepanelTab,
	getDefaultIsSidepanelHide,
} from './defaultValues'

const useLiveSidepanelStore = createWithEqualityFn<SidepanelStoreType.State>(
	(set) => {
		return {
			currentSidepanelTab: getDefaultCurrentSidepanelTab(),
			isSidepanelHide: getDefaultIsSidepanelHide(),
			hasChatNewMessage: false,
			chatMessages: [],

			setCurrentSidepanelTab(value) {
				return set((state) => {
					return {
						currentSidepanelTab: value,
					}
				})
			},

			setIsSidepanelHide(value) {
				return set((state) => {
					return {
						isSidepanelHide: value,
					}
				})
			},

			setHasChatNewMessages(status: boolean) {
				return set((state) => {
					return {
						hasChatNewMessage: status,
					}
				})
			},

			setChatMessages(messages) {
				return set((state) => {
					return {
						chatMessages: messages,
					}
				})
			},

			addChatMessage(message) {
				return set((state) => {
					return {
						chatMessages: [...state.chatMessages, message],
					}
				})
			},
		}
	},
	shallow
)

export default useLiveSidepanelStore
