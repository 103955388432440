import { EditExerciseFormDataType } from 'parts/types/miscTypes'
import useExercisesListAdminStore from '../../zustand/store'

/** Функция возвращает актуальное состояние формы */
export function getFormState<T extends EditExerciseFormDataType>() {
	return useExercisesListAdminStore.getState().formData as T
}
export function setFormState(newFormState: EditExerciseFormDataType) {
	useExercisesListAdminStore
		.getState()
		.updateStore({ formData: newFormState })
}

export type DownloadExerciseFileStatus =
	| 'empty'
	| 'downloading'
	| 'success'
	| 'error'
