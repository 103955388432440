import { Outlet } from 'react-router-dom'
import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import GroupPageHeader from '../GroupPageHeader/GroupPageHeader'
import { useFillInStore } from './fn/fillInStore'
import Footer from 'ui/Footer/Footer'
import { useGetUserRole } from 'parts/utils/hooks/hooks'

/** Обёрточная страница объединяющая страницы группы */
function GroupPage() {
	const userRoles = useGetUserRole()
	// Загружает данные по курсу при первой отрисовке страницы
	useFillInStore()

	return (
		<PageSkeleton>
			<GroupPageHeader />
			<Outlet />
			<Footer isRightSideHidden={!userRoles.isAdminOrManager} />
		</PageSkeleton>
	)
}

export default GroupPage
