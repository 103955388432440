import { AxiosResponse } from 'axios'
import Loader from '../../ui/Loader/Loader'
import { UseQueryResult } from 'react-query'
import NoContent from '../../ui/NoContent/NoContent'

type GetTCompRouterReturn = {
	status: 'loading' | 'error' | 'noData' | 'hasData' | 'unknown'
	element: JSX.Element
}

// Устаревшая функция. Не использовать такой подход.
/**
 * Функция, возвращающая объект со статусом получения данных компонентом и сам компонент в зависимости от этого статуса.
 * Используется в маршрутизаторе компонента чтобы внешний компонент знал на каком этапе получения данных находится вложенный компонент.
 * @param query — объект, возвращаемый useQuery (React Query)
 * @param element — элемент, который должен быть отрисован при удачном получении данных
 */
export function getCompRouterObj(
	query: UseQueryResult<AxiosResponse<unknown, any>, unknown>,
	element: JSX.Element
): GetTCompRouterReturn {
	const { status, error, data } = query

	if (status == 'loading') {
		return {
			status: 'loading',
			element: <Loader />,
		}
	} else if (error) {
		return {
			status: 'error',
			element: <p>Произошла ошибка при получении данных.</p>,
		}
	} else if (status == 'success' && data) {
		if (data.data) {
			return {
				status: 'hasData',
				element: element,
			}
		}

		return {
			status: 'noData',
			element: (
				<NoContent
					header="Данные не существуют"
					text="Они были удалены или введён неправильный адрес"
				/>
			),
		}
	}

	return unknownObj
}

const unknownObj: GetTCompRouterReturn = {
	status: 'unknown',
	element: <p>unknown</p>,
}
