import { create } from 'zustand'
import { AddLiveModalStoreType } from './storeTypes'

const useAddLiveModalStore = create<AddLiveModalStoreType.State>((set) => {
	return {
		dataLoadingStatus: 'loading',
		schoolAdminId: 0,
		schoolAdminName: '',
		lessonLink: '',
		lessonId: 0,
		isOpen: false,
		isSubmitting: false,
		formErrors: {},

		/**
		 * Установщик новых значений корневого объекта.
		 * @param obj — объект со свойствами, которыми нужно заменить текущие значения
		 */
		updateState(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useAddLiveModalStore
