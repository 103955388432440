import useSystemStore from 'parts/systemStore/systemStore'
import { useMemo } from 'react'

/** Возвращает название текущей школы */
export function useGetSchoolName(): string {
	const school = useSystemStore((store) => store.school)

	return useMemo(
		function () {
			return school ? school.name : ''
		},
		[school]
	)
}
