import { DownloadExerciseFileStatus } from '../../common/exerciseFormCommonFunc'

export type VideoExerciseFormStateType = {
	exerciseId: null | number // id упражнения. Если это новое упражнение, то id не существует
	type: 'video' // Тип упражнения
	order: number
	// Поле «Задание к видео»
	videoTask: {
		value: string // Значение поля «Задание к видео»
	}
	videos: FormVideoStateItemType[] // Данные об указанных видео
	isFormInvalid: boolean // Является ли форма неверной
}

export type FormVideoStateItemType = {
	id: number // Идентификатор блока с данными указанного видео
	videoName: string // Значение поля «Задать название видео»
	link: string // Значение поля «Ссылка на видео»
	error: string // Текст ошибки ниже поля «Ссылка на видео»
	// Данные о загружаемом файле
	downloadFile: DownloadFileType
}

export type DownloadFileType = {
	link: string // Адрес загруженного файла
	status: DownloadExerciseFileStatus // Статус загрузки
	progress: number // Процент загрузки файла
	fileName: string // Название загружаемого файла
}

const formVideoStateItem: FormVideoStateItemType = {
	id: 1,
	videoName: '', // Значение поля «Задать название видео»
	link: '', // Значение поля «Ссылка на видео»
	error: '',
	downloadFile: {
		link: '',
		status: 'empty',
		progress: 0,
		fileName: '',
	},
}

// Объект начального состояния формы видео-упражнения
export const formInitialState: VideoExerciseFormStateType = {
	exerciseId: null,
	type: 'video',
	order: 0,
	videoTask: {
		value: '',
	},
	videos: [formVideoStateItem],
	isFormInvalid: false,
}
