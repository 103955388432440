import { useGetUserRole } from 'parts/utils/hooks/hooks'
import { useTranslation } from 'react-i18next'
import { UTableHCell, UTableHRow } from 'ui/UTable/UTable'

function TableHeader() {
	const { t } = useTranslation()
	const userRoles = useGetUserRole()

	return (
		<UTableHRow>
			<UTableHCell>Группа</UTableHCell>
			<UTableHCell>Активная группа</UTableHCell>
			{userRoles.isAdminOrManager && (
				<UTableHCell>Мест в группе</UTableHCell>
			)}
			{userRoles.isCurator && <UTableHCell>Учеников</UTableHCell>}
			<UTableHCell>Проводится</UTableHCell>
			{userRoles.isAdminOrManager && <UTableHCell>Действия</UTableHCell>}
		</UTableHRow>
	)
}

export default TableHeader
