import { FC } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { Table } from 'antd'
import type { ColumnsType, TableProps } from 'antd/es/table'
import { Loader } from 'ui'
import EntityTypes from 'parts/types/EntityTypes'
import { superAdminQuery } from 'parts/requests/superadmin/superAdminQuery'

import styles from './Dashboard.module.scss'

interface DataType {
	key: React.Key
	name: string
	email: string
	phone: string
	createdAt: Dayjs
	lastVisit: Dayjs
}

const columns: ColumnsType<DataType> = [
	{
		title: 'Name',
		dataIndex: 'name',
		// sorter: (a, b) => a.name.length - b.name.length,
		// sortDirections: ['descend'],
	},
	{
		title: 'Email',
		dataIndex: 'email',
	},
	{
		title: 'Phone',
		dataIndex: 'phone',
	},
	{
		title: 'Register date',
		dataIndex: 'createdAt',
		defaultSortOrder: 'descend',
		sorter: (a, b) => a.createdAt.unix() - b.createdAt.unix(),
		render: (value, record) => (
			<span>{dayjs(value).format('DD.MM.YYYY HH:mm:ss')}</span>
		),
	},
	{
		title: 'Last visit',
		dataIndex: 'lastVisit',
		sorter: (a, b) => a.createdAt.unix() - b.createdAt.unix(),
		render: (value, record) => (
			<span>{dayjs(value).format('DD.MM.YYYY HH:mm:ss')}</span>
		),
	},
]

const onChange: TableProps<DataType>['onChange'] = (
	pagination,
	filters,
	sorter,
	extra
) => {
	console.log('params', pagination, filters, sorter, extra)
}

const Dashboard: FC = () => {
	const { status, data: adminsList } = superAdminQuery.getAdmins().useQuery()

	const getData = (data: EntityTypes.User[]) => {
		return data.map((admin) => {
			return {
				key: admin.id,
				name: `${admin.firstName} ${admin.lastName}`,
				email: admin.email,
				phone: admin.phone,
				createdAt: dayjs(admin.createdAt),
				lastVisit: dayjs(admin.lastVisit),
			}
		})
	}

	return (
		<div className={styles.Dashboard}>
			<div className={styles.Dashboard__Header}>
				<h1>Dashboard</h1>
			</div>
			<div className={styles.Dashboard__Table}>
				{status === 'loading' && <Loader />}
				<Table
					columns={columns}
					dataSource={getData(adminsList?.data || [])}
					onChange={onChange}
					pagination={false}
				/>
			</div>
		</div>
	)
}

export default Dashboard
