import AuthApiTypes from 'parts/requests/auth/authApiTypes'
import authRequests from 'parts/requests/auth/authRequest'
import useRegisterAdminStore from '../../../registerOwnerStart/RegistrationForm/zustand/store'

/** Возвращает функцию делающую запрос на повторную отправку кода для подтверждения почты */
export function useGetSendConfirmationCode() {
	const registerAdminStore = useRegisterAdminStore((state) => state)

	const dto: AuthApiTypes.SendEmailCodeDto = {
		userId: registerAdminStore.userId!,
		email: registerAdminStore.email,
	}

	return async function () {
		return await authRequests.sendEmailCode(dto)
	}
}
