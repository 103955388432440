import React, { useRef } from 'react'
import {
	ExerciseChangesDataMadeByUser,
	SaveExerciseChangesMadeByUser,
} from '../common/useLiftViewDuration'
import {
	useGetDropWordHandler,
	useRestoreGapTextsInTextBlockOnMount,
} from './fn/saveAndRestoreExercise'
import { Words } from './Words'
import { ImagesWithSlots } from './ImagesWithSlots'
import Bottom from './Bottom'
import ExerciseStateType from './fn/ExerciseStateType'
import { TaskExerciseView } from '../common/TaskExerciseView/TaskExerciseView'
import {
	onDragEnd,
	onDragEnter,
	onDragLeave,
	onDragOver,
	onDragStart,
	onDrop,
} from './fn/drag'
import ExerciseDropAdvert from '../common/DropAdvert/ExerciseDropAdvert'

type DescriptionImageExerciseViewProps = {
	state: ExerciseStateType.Main
	setState: React.Dispatch<React.SetStateAction<ExerciseStateType.Main>>
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

// Вывод упражнения с картинкой в уроке
function Exercise(props: DescriptionImageExerciseViewProps) {
	const {
		state,
		setState,
		saveExerciseChangesMadeByUser,
		exerciseRestoreData,
	} = props

	const textsRef = useRef<null | HTMLDivElement>(null)

	// Занимается сохранением состояния упражнения для восстановления при открытии
	const dropWordHandler = useGetDropWordHandler(
		textsRef.current,
		saveExerciseChangesMadeByUser
	)
	// Занимается восстановлением состояния упражнения при открытии
	useRestoreGapTextsInTextBlockOnMount(textsRef.current, exerciseRestoreData)

	return (
		<>
			{state.exerciseTask && (
				<TaskExerciseView htmlString={state.exerciseTask} />
			)}
			<ExerciseDropAdvert />
			<section
				className="exercise-desc-image-content__exercise-wrapper"
				ref={textsRef}
				onDragStart={onDragStart}
				onDragEnter={onDragEnter}
				onDragLeave={onDragLeave}
				onDrop={(e) => {
					onDrop(e)
					dropWordHandler(e)
				}}
				onDragOver={onDragOver}
				onDragEnd={onDragEnd}
			>
				<Words state={state} />
				<ImagesWithSlots state={state} />
				<Bottom state={state} setState={setState} textsRef={textsRef} />
			</section>
		</>
	)
}

export default Exercise
