import { Modal } from 'antd'
import React from 'react'
import ExerciseSwitcher from 'widgets/exercisesViews/ExerciseSwitcher'
import ExerciseModalController from './ExerciseModalController'
import useAnswersFeedStore from './fn/store'

// Модальное окно с упражнением, на которое получен ответ
function ExerciseModal() {
	const answer = useAnswersFeedStore((store) => store.answer)
	if (!answer) return null

	return (
		<Modal
			title={answer?.exercise?.type}
			open={!!answer}
			onOk={ExerciseModalController.close}
			onCancel={ExerciseModalController.close}
			footer={null}
		>
			{answer && <ExerciseSwitcher exercise={answer?.exercise} />}
		</Modal>
	)
}

export default ExerciseModal
