import React, { useEffect, useState } from 'react'
import UploadFileTypes from 'parts/constants/uploadTypes'
import downloadFileToAmazon from 'parts/services/downloadFileToAmazon'
import { Form, UploadFile } from 'antd'
import { UploadProps } from 'antd/lib/upload/interface'
import { GetUploadedFilesType } from '../UploadAvatar'
import { useIsFirstRender } from 'parts/utils/hooks/hooks'

export type AcceptFileType =
	| '*'
	| 'image/*'
	| 'image/png'
	| '.jpg, .jpeg, .png'
	| '.txt, .doc, .docx'

export function createStore(initialFileList: UploadFile[]) {
	const [fileList, setFileList] = useState<UploadFile[]>(initialFileList)

	useEffect(
		function () {
			setFileList(initialFileList)
		},
		[initialFileList]
	)

	return { fileList, setFileList }
}

/**
 * Функция получает массив строк с адресами загруженных файлов
 * и преобразует его в массив данных для компонента загрузки файлов
 * чтобы он показал эти файлы как уже загруженные.
 * @param fileList — массив с адресами загруженных файлов
 */
export function createFileListFromUrlList(fileList: string[]): UploadFile[] {
	return fileList.map((fileSrc, i) => {
		const filePathParts = fileSrc.split('/')
		const fileName = filePathParts[filePathParts.length - 1]

		return {
			uid: i.toString(),
			name: fileName,
			status: 'done',
			url: fileSrc,
		}
	})
}

type RcCustomRequestOptions<T = any> = Parameters<
	Exclude<UploadProps<T>['customRequest'], undefined>
>[0]

/**
 * Собственный обработчик загрузки файлов для поля ввода
 * @param options — методы для оповещения поля ввода о прогрессе выполнения, ошибке, успехе и так далее.
 * @param setFileList — функция изменяющая массив сведений об изначальных и загруженных файлов
 * @param fileType — тип файла. Это влияет на то, в какую папку он будет загружен.
 */
export async function makeRequest(
	options: RcCustomRequestOptions,
	setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>,
	fileType: UploadFileTypes
) {
	const { onSuccess, onError, file, onProgress } = options

	if (!onSuccess || !onError || !onProgress) return

	await downloadFileToAmazon(file as any, FileList as any, fileType, {
		beforeDownloading(fileName: string) {},
		whileDownloading(percentCompleted, cancelDownloading, fileUrl) {
			onProgress({ percent: percentCompleted })
		},
		onSuccess(fileName: string, fileUrl: string) {
			onSuccess(`Файл ${fileName} успешно загружен`)

			setFileList((state) => {
				return state.concat(createFileListFromUrlList([fileUrl]))
			})
		},
		onError(fileName: string) {
			onError(`Не удалось загрузить файл ${fileName}` as any)
		},
	})
}

/**
 * Обработчик удаления файла
 * @param removedFile — удаляемый файл
 * @param fileList — массив всех загруженных файлов
 * @param setFileList — функция изменяющая массив всех загруженных файлов
 */
export function removeFile(
	removedFile: UploadFile,
	fileList: UploadFile<any>[],
	setFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>
) {
	const newFileList = fileList.filter((fileObj) => {
		return fileObj.url !== removedFile.url
	})

	setFileList(newFileList)
}

/**
 * После загрузки файла записывает его путь в данные формы
 * @param inputName — имя поля ввода
 * @param fileList — массив загруженных файлов
 */
export function useSetFileUrlsToFormData(
	inputName: string,
	fileList: UploadFile[]
) {
	const form = Form.useFormInstance()

	useEffect(
		function () {
			if (!form) return

			form.setFieldValue(inputName, fileList)
		},
		[fileList]
	)
}

/**
 * Получает массив загруженных файлов и возвращает URL первого загруженного файла
 * @param fileList — массив загруженных файлов
 */
export function getFirstLoadedFileUrl(fileList: UploadFile[]) {
	return fileList.length ? fileList[0].url! : null
}

/**
 * Запускает функцию принимающую массив загруженных файлов
 * @param getUploadedFiles — функция, принимающая загруженные файлы, чтобы компонент выше смог получить их адреса
 * @param fileList — массив загруженных файлов
 */
export function useSetFileUrlsToGetUploadedFilesFn(
	getUploadedFiles: undefined | GetUploadedFilesType,
	fileList: UploadFile[]
) {
	if (!getUploadedFiles) return

	const isFirstRender = useIsFirstRender(1000)

	useEffect(
		function () {
			if (isFirstRender) return

			getUploadedFiles(fileList)
		},
		[fileList]
	)
}
