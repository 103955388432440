import React from 'react'
import { useTranslation } from 'react-i18next'
import AddTrainingButton from '../AddTrainingButton/AddTrainingButton'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import { EmptyBlock } from 'ui/EmptyBlock/EmptyBlock'
import { TrainingListController } from 'widgets/trainingsListAdmin/TrainingListController'
import { AddTrainingModalController } from '../addTraining/AddTrainingModal/AddTrainingModalController'

function TrainingsArticle() {
	const trainingListRouter = TrainingListController.router()

	return (
		<ArticleContentWrapper
			center={
				trainingListRouter.status == 'loading' ||
				trainingListRouter.status == 'noTrainings'
			}
		>
			{trainingListRouter.status == 'noTrainings' ? (
				<NoTrainings />
			) : (
				trainingListRouter.element
			)}
			{AddTrainingModalController.element}
		</ArticleContentWrapper>
	)
}

export default TrainingsArticle

function NoTrainings() {
	const { t } = useTranslation()

	return (
		<EmptyBlock
			text={
				<>
					{t('adminTrainings.noTrainingCreatedFirstLine')}
					<br />
					{t('adminTrainings.noTrainingCreatedSecondLine')}
				</>
			}
			button={<AddTrainingButton />}
		/>
	)
}
