import { UserOutlined } from '@ant-design/icons'
import { Avatar, Button } from 'antd'
import dayjs from 'dayjs'
import cn from 'classnames'
import relativeTime from 'dayjs/plugin/relativeTime'
import EntityTypes from 'parts/types/EntityTypes'
import { getUserName } from 'parts/utils/string'
import { useTranslation } from 'react-i18next'
import ReplyForm from '../../ReplyForm/ReplyForm'
import AudioFrame from 'ui/AudioFrame/AudioFrame'
import DeleteButton from '../DeleteButton/DeleteButton'
import ExerciseModalController from '../ExerciseModal/ExerciseModalController'
import StatusTag from '../StatusTag/StatusTag'
import UserTag from '../UserTag/UserTag'
import styles from './AnswerBlock.module.scss'
import { getAnswerDate } from './fn/getAnswerDate'

dayjs.extend(relativeTime)

type AnswersProps = { answer: EntityTypes.Answer }

function AnswerBlock(props: AnswersProps) {
	const { answer } = props
	const { user, text, createdAt, id, audio } = answer

	const { t } = useTranslation()

	const wrapperClasses = cn({
		[styles.MessagesContainer]: true,
		[styles.Nested]: !answer.status,
		[styles.Line]: answer.status && answer.answerFeedItems.length,
	})

	return (
		<>
			<div className={wrapperClasses}>
				<div className={styles.MessageContent}>
					<Avatar
						src={user.avatar ? user.avatar : null}
						icon={<UserOutlined />}
						className={styles.Avatar}
					/>
					<div className={styles.Message}>
						<div className={styles.Message_Top}>
							<div className={styles.Message_Head}>
								<span className={styles.Author}>
									{getUserName(user)}
								</span>
								{typeof answer.status === 'number' && (
									<Button
										className={styles.BreadCrumbs}
										onClick={() =>
											ExerciseModalController.open(answer)
										}
										type="text"
										size="small"
									>
										{t(
											'answersFeed.answerBlockShowExerciseButton'
										)}
									</Button>
								)}
							</div>
							<StatusTag status={answer.status} />
							<UserTag role={answer.user.role} />
						</div>
						{text ? (
							<div
								className={styles.Message_Content}
								dangerouslySetInnerHTML={{ __html: text }}
							/>
						) : null}
						{audio && <AudioFrame src={audio} />}

						<div className={styles.Message_Bottom}>
							<DeleteButton answer={answer} />
							<span className={styles.Time}>
								{getAnswerDate(createdAt)}
							</span>
						</div>
					</div>
				</div>
			</div>
			{answer?.answerFeedItems?.length ? (
				<div className={styles.InnerReplies}>
					{answer.answerFeedItems.map((answer) => {
						// @ts-ignore
						return <AnswerBlock answer={answer} key={answer.id} />
					})}
				</div>
			) : null}
			{answer.groupId && <ReplyForm answerFeedId={id} />}
		</>
	)
}

export default AnswerBlock
