import { Button } from 'antd'
import React, { ReactElement } from 'react'
import cn from 'classnames'
import {
	EyeInvisibleOutlined,
	EyeOutlined,
	InfoCircleOutlined,
} from '@ant-design/icons'
import 'pages/landing/landingConstructor/sections/SectionOuter/SectionOuter.scss'

type SectionOuterProps = {
	width?: 'big' | 'middle'
	// Текст подсказки с назначением блока
	helpTexts: string[]
	// Открыта ли секция одностраничника
	isOpen: boolean
	// Функция, срабатывающая при нажатии на кнопку изменения видимости блока
	onChangeVisibilityButtonClick: () => void
	// Секция одностраничника
	children: ReactElement
}

// Обёртка секции одностраничника.
// Тут будет блок с подсказкой о назначение блока и кнопка его скрытия.
// Внутрь вкладывается секция.
function SectionOuter(props: SectionOuterProps) {
	const {
		width,
		helpTexts,
		isOpen,
		onChangeVisibilityButtonClick,
		children,
	} = props

	return (
		<div
			className={cn(
				'land-const-section-outer',
				width && 'land-const-section-outer--' + width + '-width'
			)}
		>
			<div className="land-const-section-outer__top">
				<div />
				<HelpBlock helpTexts={helpTexts} isOpen={isOpen} />
				<VisibilityButton
					isOpen={isOpen}
					onChangeVisibilityButtonClick={
						onChangeVisibilityButtonClick
					}
				/>
			</div>
			{isOpen && (
				<div className="land-const-section-outer__content">
					{children}
				</div>
			)}
		</div>
	)
}

export default SectionOuter

type HelpBlockProps = {
	helpTexts: string[]
	isOpen: boolean
}

function HelpBlock(props: HelpBlockProps) {
	const { helpTexts, isOpen } = props

	return (
		<div
			className={cn(
				'land-const-section-outer__help',
				'land-const-section-outer__help--' +
					(isOpen ? 'opened' : 'closed')
			)}
		>
			<InfoCircleOutlined className="land-const-section-outer__help-sign" />
			<div className="land-const-section-outer__texts">
				{helpTexts.map((text, i) => (
					<p className="land-const-section-outer__text" key={i}>
						{text}
					</p>
				))}
			</div>
		</div>
	)
}

type VisibilityButtonProps = {
	isOpen: boolean
	onChangeVisibilityButtonClick: () => void
}

function VisibilityButton(props: VisibilityButtonProps) {
	const { isOpen, onChangeVisibilityButtonClick } = props

	return (
		<Button
			type="text"
			size="large"
			icon={isOpen ? <EyeOutlined /> : <EyeInvisibleOutlined />}
			onClick={onChangeVisibilityButtonClick}
			className="land-const-section-outer__visibility-button"
		/>
	)
}
