import { FormInstance } from 'antd'
import { useEffect } from 'react'
import { setErrorsToFields } from 'parts/utils/form'
import useAddGroupModalStore from '../../zustand/store'

export enum FieldNames {
	GroupName = 'groupName',
	Type = 'type',
	Participants = 'participants',
}

export interface FormValues {
	// Название группу
	[FieldNames.GroupName]: string
	// Платная или бесплатная
	[FieldNames.Type]: string
	// Количество участников
	[FieldNames.Participants]?: string
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useAddGroupModalStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
