import React, { ReactNode } from 'react'
import cn from 'classnames'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import { useGetMakeCardActive } from 'pages/landing/landingConstructor/tariffs/card/FocusCardWrapper/fn/activateCard'
import 'pages/landing/landingConstructor/tariffs/card/FocusCardWrapper/FocusCardWrapper.scss'

type FocusCardWrapperProps = {
	tariff: TariffsStoreType.Tariff
	children: ReactNode
}

// Обёртка карточки тарифа и дополнительных цен. Подсвечивается если тариф веделен.
function FocusCardWrapper(props: FocusCardWrapperProps) {
	const { tariff, children } = props

	const { activeTariffId } = tariffsManager.store
	const makeCardActive = useGetMakeCardActive(tariff.innerId)

	return (
		<div
			className={cn(
				'tariff-card-focus-wrapper',
				activeTariffId == tariff.innerId &&
					'tariff-card-focus-wrapper--active'
			)}
			onClick={makeCardActive}
		>
			{children}
		</div>
	)
}

export default FocusCardWrapper
