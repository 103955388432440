import React from 'react'
import { useParams } from 'react-router-dom'
import { RouterVarNames } from '../../../../app/routes/special/otherRoutes'
import {
	ExerciseChangesDataMadeByUser,
	SaveExerciseChangesMadeByUser,
	WritingOrAudioAnswerWasSentFn,
} from '../../common/useLiftViewDuration'
import AnswerWrapper from '../AnswerWrapper/AnswerWrapper'
import TextAnswerBlock from '../TextAnswerBlock/TextAnswerBlock'
import AudioAnswerBlock from '../AudioAnswerBlock/AudioAnswerBlock'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useGetUserRole } from 'parts/utils/hooks/hooks'

type MediaAnswerBlockProps = {
	exercise:
		| TrainingEntityTypes.AudioExercise
		| TrainingEntityTypes.ImageExercise
	// Запуск этой функции сообщает, что ответ был послан
	writingOrAudioAnswerWasSentFn?: WritingOrAudioAnswerWasSentFn
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData: ExerciseChangesDataMadeByUser
}

// Текстовый или голосовой ответ пользователя на упражнение
function MediaAnswerBlock(props: MediaAnswerBlockProps) {
	const {
		exercise,
		writingOrAudioAnswerWasSentFn,
		saveExerciseChangesMadeByUser,
		exerciseRestoreData,
	} = props

	const params = useParams()
	const trainingIdStr = params[RouterVarNames.TrainingId]!
	const userRoles = useGetUserRole()

	// Блоки ответа ученика не могут видеть сотрудники и если в адресной строке нет идентификатор курса,
	// то не показывать этот блок
	if (
		!userRoles.rolesGot ||
		userRoles.isAdminOrManagerOrCurator ||
		!trainingIdStr
	) {
		return null
	}

	return (
		<>
			{exercise.item.writtenAnswer && (
				<AnswerWrapper answerType="text">
					<TextAnswerBlock
						writingOrAudioAnswerWasSentFn={
							writingOrAudioAnswerWasSentFn
						}
						saveExerciseChangesMadeByUser={
							saveExerciseChangesMadeByUser
						}
						exerciseRestoreData={exerciseRestoreData}
					/>
				</AnswerWrapper>
			)}
			{exercise.item.audioAnswer && (
				<AnswerWrapper answerType="audio">
					<AudioAnswerBlock
						writingOrAudioAnswerWasSentFn={
							writingOrAudioAnswerWasSentFn
						}
					/>
				</AnswerWrapper>
			)}
		</>
	)
}

export default MediaAnswerBlock
