import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { getRusNounByNumber } from 'parts/utils/string'
import LangService, { LangNames } from 'parts/i18n/LangService'

/**
 * Возвращает строки с номером урока и количеством упражнений
 * @param lessonNumber — порядковый номер урока
 * @param lesson — данные урока
 */
export function getStatisticTexts(
	lessonNumber: number,
	lesson: TrainingEntityTypes.LessonAdmin
) {
	const lang = LangService.getCurrentLangFromLocalStorage()

	const exercisesNum = lesson.exercises.length
	let lessonsStr = ''
	let exercisesStr = ''

	if (lang === LangNames.RU) {
		lessonsStr = 'Урок №' + (lessonNumber + 1)

		exercisesStr =
			exercisesNum +
			' ' +
			getRusNounByNumber(
				exercisesNum,
				'упражнение',
				'упражнения',
				'упражнений'
			)

		if (!exercisesNum) exercisesStr = 'Упражнений нет'
	} else if (lang === LangNames.EN) {
		lessonsStr = 'Lesson №' + (lessonNumber + 1)

		if (exercisesNum === 0) {
			exercisesStr = 'No exercises'
		} else if (exercisesNum === 1) {
			exercisesStr = exercisesNum + ' exercise'
		} else {
			exercisesStr = exercisesNum + ' exercises'
		}
	}

	return { lessonsStr, exercisesStr }
}
