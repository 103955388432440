import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import './ContentWrapper.scss'

type ContentWrapperProps = {
	// Адрес страницы, куда ведёт кнопка «Назад». Если ничего не передано, то кнопки не будет
	backBthHref?: string
	// Адрес картинки выше заголовка
	imageAboveSrc?: string
	// Заголовок страницы
	header: string
	// Примечание к заголовку
	headerDesc?: string | ReactNode
	// Главное содержимое
	children?: ReactNode
	// Текст в самом низу страницы
	bottomText?: string | ReactNode
}

// Белая обёртка правой части страниц авторизации
function ContentWrapper(props: ContentWrapperProps) {
	const {
		backBthHref,
		imageAboveSrc,
		header,
		headerDesc,
		children,
		bottomText,
	} = props

	return (
		<div className="auth-content-wrapper">
			<Top backBthHref={backBthHref} />
			<Middle
				imageAboveSrc={imageAboveSrc}
				header={header}
				headerDesc={headerDesc}
			>
				{children}
			</Middle>
			<Bottom bottomText={bottomText} />
		</div>
	)
}

export default ContentWrapper

type TopProps = {
	backBthHref: undefined | string
}

function Top(props: TopProps) {
	const { backBthHref } = props

	const { t } = useTranslation()

	return (
		<div className="auth-content-wrapper__top">
			{backBthHref && (
				<Link
					to={backBthHref}
					className="auth-content-wrapper__back-link"
				>
					<Button icon={<ArrowLeftOutlined />} type="link">
						{t('auth.backButton')}
					</Button>
				</Link>
			)}
			{!backBthHref && (
				<a href="https://credu.ai">
					<img
						src="/images/site/logo.svg"
						className="auth-content-wrapper__top-logo"
						alt="logo"
					/>
				</a>
			)}
		</div>
	)
}

type MiddleProps = {
	imageAboveSrc: undefined | string
	header: string
	headerDesc: undefined | string | ReactNode
	children: ReactNode
}

function Middle(props: MiddleProps) {
	const { imageAboveSrc, header, headerDesc, children } = props

	return (
		<div className="auth-content-wrapper__middle">
			{imageAboveSrc && (
				<img
					src={imageAboveSrc}
					className="auth-content-wrapper__image-above"
					alt="promo"
				/>
			)}
			<h1 className="auth-content-wrapper__header">{header}</h1>
			{headerDesc && (
				<p className="auth-content-wrapper__header-desc">
					{headerDesc}
				</p>
			)}
			<div className="auth-content-wrapper__content">{children}</div>
		</div>
	)
}

type BottomProps = {
	bottomText: undefined | string | ReactNode
}

function Bottom(props: BottomProps) {
	const { bottomText } = props

	return bottomText ? (
		<div className="auth-content-wrapper__bottom">
			<p className="auth-content-wrapper__bottom-text">{bottomText}</p>
		</div>
	) : null
}
