import React, { ReactNode } from 'react'
import './ScreenWrapper.scss'

type ScreenWrapperProps = {
	// Заголовок
	header: string
	description?: string
	illustration?: boolean
	children: ReactNode
}

function ScreenWrapper(props: ScreenWrapperProps) {
	const { header, description, illustration = false, children } = props

	return (
		<div className="live-entrance-screen-wrapper">
			{illustration && (
				<img
					src="/images/illustrations/2.svg"
					className="live-entrance-screen-wrapper__illustration"
					alt="illustration"
				/>
			)}
			<h1 className="live-entrance-screen-wrapper__header">{header}</h1>
			{description && (
				<p className="live-entrance-screen-wrapper__description">
					{description}
				</p>
			)}
			{children}
		</div>
	)
}

export default ScreenWrapper
