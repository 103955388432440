import { create } from 'zustand'
import { LiveLibraryStoreType } from './storeTypes'

const useLiveLibraryStore = create<LiveLibraryStoreType.State>((set) => {
	return {
		isUploadDialogShow: false,
		isFileUploading: false,
		uploadFileName: null,
		selectedFolder: null,
		uploadPercentCompleted: 0,
		selectedItems: [],
		isFolderDialogShow: false,

		setIsUploadDialogShow(visible) {
			return set((state) => {
				return {
					isUploadDialogShow: visible,
				}
			})
		},

		setIsFileUploading(status) {
			return set((state) => {
				return {
					isFileUploading: status,
				}
			})
		},

		setUploadFilename(fileName) {
			return set((state) => {
				return {
					uploadFileName: fileName,
				}
			})
		},

		setSelectedFolder(folder) {
			return set((state) => {
				return {
					selectedFolder: folder,
				}
			})
		},

		setUploadPercentCompleted(percent) {
			return set((state) => {
				return {
					uploadPercentCompleted: percent,
				}
			})
		},

		setSelectedItems(items) {
			return set((state) => {
				return {
					selectedItems: items,
				}
			})
		},

		setIsFolderDialogShow(visible) {
			return set((state) => {
				return {
					isFolderDialogShow: visible,
				}
			})
		},
	}
})

export default useLiveLibraryStore
