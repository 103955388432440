import { create } from 'zustand'
import { PasswordRecoveryStoreType } from './storeTypes'

const usePasswordRecoveryStore = create<PasswordRecoveryStoreType.State>(
	(set) => {
		return {
			email: null,

			/** Установщик новых значений корневого объекта. */
			updateStore(obj) {
				return set((state) => {
					return obj
				})
			},
		}
	}
)

export default usePasswordRecoveryStore
