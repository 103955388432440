import { useEffect } from 'react'
import useLandingConstructorStore from '../../../zustand/store'
import { LandingConstructorStoreType } from '../../../zustand/storeTypes'

export const initStoreData: Partial<LandingConstructorStoreType.State> = {
	dataLoadingStatus: 'loading',
	training: null as any,
	landing: null as any,
}

/**
 * Хук срабатывающий при размонтировании страницы конструктора одностраничников.
 * Это требуется для того, чтобы при открытии конструтора на другой страницы он не брал старые данные.
 */
export function useClearStoreInUnmount() {
	const updateStore = useLandingConstructorStore((store) => store.updateStore)

	useEffect(function () {
		return function () {
			updateStore(initStoreData)
		}
	}, [])
}
