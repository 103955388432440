import { useEffect, useState } from 'react'
import EntityTypes from 'parts/types/EntityTypes'
import UserService from 'parts/services/UserService'
import UrlService from 'parts/services/UrlService'

/** Запрашивает журнал действий с сервера и возвращает */
export function useGetLogs() {
	const studentId = UrlService.useGetStudentId()

	const [logs, setLogs] = useState<null | EntityTypes.UserLogs>()

	useEffect(
		function () {
			if (!studentId) return

			UserService.getUserLogs(studentId).then((logs) => {
				if (!logs) return
				setLogs(logs)
			})
		},
		[studentId]
	)

	return logs
}
