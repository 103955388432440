import { AxiosError } from 'axios'
import { useEffect } from 'react'
import { liveLessonQuery } from 'parts/requests/liveLesson/liveLessonQuery'
import ServerTypes from 'parts/types/ServerTypes'
import UrlService from 'parts/services/UrlService'
import useLiveLessonEntrancePageStore from '../../zustand/store'

/** Получает из адреса идентификатор входа на эфир урока и по нему получает идентификатор эфира урока,
 * чтобы проверить, что идентификатор входа передан правильно.
 * Исходя из этого ставит статус проверки.
 * Он используется на странице входа, чтобы указать на проблемы или пустить программу дальше.
 * */
export function useCheckLiveLessonEntranceId() {
	const liveLessonEntranceId = UrlService.useGetLiveEntranceId()

	const updatePageState = useLiveLessonEntrancePageStore(
		(store) => store.updateState
	)

	const { data: liveLessonIdRes } = liveLessonQuery
		.getLiveLessonIdByLiveLessonEntranceId(liveLessonEntranceId)
		.useQuery({
			onError: queryError,
		})

	useEffect(
		function () {
			if (!liveLessonIdRes) return

			updatePageState({
				liveEntranceIdCheckStatus: liveLessonIdRes.data
					? 'rightLiveEntranceId'
					: 'error',
				liveLessonId: liveLessonIdRes.data,
			})
		},
		[liveLessonIdRes]
	)
}

/**
 * Функция запускаемая при ошибке запроса данных.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 * @param err — объект ошибки.
 */
function queryError(err: unknown) {
	const error = err as AxiosError<ServerTypes.ErrorResponse>

	const { updateState } = useLiveLessonEntrancePageStore.getState()

	updateState({
		liveEntranceIdCheckStatus: 'error',
		liveEntranceIdCheckStatusErrorMessage: error.response?.data.message,
	})
}
