import React, { FC } from 'react'
import { Modal, Button } from 'antd'

import styles from './RecordDialog.module.scss'

interface RecordDialogProps {
	title: string
	content: string[]
	visible: boolean
	okText: string
	cancelText?: string
	onOk?: () => void
	onCancel?: () => void
}

const RecordDialog: FC<RecordDialogProps> = ({
	title,
	content,
	visible,
	okText,
	cancelText,
	onOk,
	onCancel,
}) => {
	const handleCancel = () => {
		if (onCancel) {
			onCancel()
		}
	}

	const handleOk = () => {
		if (onOk) {
			onOk()
		}
	}

	return (
		<Modal
			title={title}
			centered
			open={visible}
			onCancel={handleCancel}
			footer={[
				<Button key="cancel" onClick={handleCancel}>
					{cancelText ? cancelText : 'Отмена'}
				</Button>,
				<Button key="submit" type="primary" onClick={handleOk}>
					{okText}
				</Button>,
			]}
			width={480}
		>
			<div className={styles.RecordDialog}>
				{content.map((item, i) => (
					<p key={i}>{item}</p>
				))}
			</div>
		</Modal>
	)
}

export default RecordDialog
