import { create } from 'zustand'
import { AnswersFeedStoreType } from './storeTypes'

const useAnswersFeedStore = create<AnswersFeedStoreType.State>((set) => {
	return {
		dataLoadingStatus: 'loading',
		currentPageNum: 1,
		totalAnswers: 0,
		answersFeed: undefined as any,
		trainingsAdmin: undefined as any,

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useAnswersFeedStore
