import {
	Block,
	BlockOuter,
} from 'pages/landing/landingConstructor/sections/faqSection/FaqBlock/FaqBlock'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import 'pages/landing/landingConstructor/sections/faqSection/ExistenceBlocks/FaqBlocks.scss'

// Обёртка с двумя колонками куда помещаются блоки
function FaqBlocks() {
	const landing = useLandingConstructorStore((store) => store.landing)
	const { faqBlocks } = landing.sections.faq

	return (
		<div className="land-const-faq-blocks">
			{faqBlocks.map((block, i) => {
				return (
					<BlockOuter block={block} blockIdx={i} key={block.id}>
						<Block block={block} blockIdx={i} key={block.id} />
					</BlockOuter>
				)
			})}
		</div>
	)
}

export default FaqBlocks
