import i18next from 'i18next'
import React from 'react'
import { BankOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useGetSchoolName } from './fn/getSchoolName'
import { useGoToPage } from 'parts/utils/hooks/hooks'
import AppUrls from 'parts/constants/pageUrl'
import './SchoolName.scss'

// Название школы в основном меню
function SchoolName() {
	const { t } = useTranslation()
	const goToSchoolsPage = useGoToPage(AppUrls.MySchools().url)
	const schoolName = useGetSchoolName()

	return (
		<div className="school-name" onClick={goToSchoolsPage}>
			<p className="school-name__top">{t('mainMenu.youAreInSchool')}</p>
			<div className="school-name__name-wrapper">
				<BankOutlined />
				<p className="school-name__name">{schoolName}</p>
			</div>
		</div>
	)
}

export default SchoolName
