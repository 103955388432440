import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LangService, { LangNames } from './LangService'
import mainEn from './en/mainEn'
import mainRu from './ru/mainRu'

i18next.use(initReactI18next).init({
	resources: {
		en: {
			translation: mainEn,
		},
		ru: {
			translation: mainRu,
		},
	},
	lng: LangService.getCurrentLangFromLocalStorage(),
	fallbackLng: LangNames.RU,
	// debug: true,
})
