import { CoffeeOutlined, DatabaseOutlined } from '@ant-design/icons'
import FormHelpType from './fn/dataTypes'
import { useGetData } from './fn/getData'
import './FormHelp.scss'

// Блок со справкой по выделенному полю
function FormHelp() {
	const data = useGetData()

	return (
		<aside className="form-help">
			<p className="form-help__static-header">Подсказка</p>
			<div className="form-help__content">
				{data.map((dataObj, i) => {
					if (dataObj.type == 'header') {
						return <Header data={dataObj} key={i} />
					} else if (dataObj.type == 'text') {
						return <Text data={dataObj} key={i} />
					} else if (dataObj.type == 'list') {
						return <List data={dataObj} key={i} />
					} else if (dataObj.type == 'exampleCard') {
						return <ExampleCard data={dataObj} key={i} />
					}
				})}
			</div>
		</aside>
	)
}

export default FormHelp

type HeaderProps = {
	data: FormHelpType.CardHeader
}

function Header(props: HeaderProps) {
	const { data } = props

	return <h2 className="form-help__header">{data.text}</h2>
}

type TextProps = {
	data: FormHelpType.Text
}

function Text(props: TextProps) {
	const { data } = props

	return <p className="form-help__text">{data.text}</p>
}

type ListProps = {
	data: FormHelpType.List
}

function List(props: ListProps) {
	const { data } = props

	return (
		<div>
			{data.textBefore && (
				<p className="form-help__text">{data.textBefore}</p>
			)}
			<ol className="form-help__list">
				{data.items.map((itemText, i) => {
					return (
						<li className="form-help__list-item" key={i}>
							{itemText}
						</li>
					)
				})}
			</ol>
		</div>
	)
}

type ExampleCardProps = {
	data: FormHelpType.ExampleCard
}

function ExampleCard(props: ExampleCardProps) {
	const { data } = props

	return (
		<div className="form-help__card">
			{data.header1 && (
				<h3 className="form-help__card-header-1">{data.header1}</h3>
			)}
			{data.header2 && (
				<div className="form-help__card-header-2-wrapper">
					{data.iconType == 'cup' && (
						<CoffeeOutlined className="form-help__card-header-2-icon" />
					)}
					{data.iconType == 'book' && (
						<DatabaseOutlined className="form-help__card-header-2-icon" />
					)}
					<p className="form-help__card-header-2">{data.header2}</p>
				</div>
			)}
			{data.texts && (
				<div className="form-help__card-content">
					{data.texts.map((dataObj, i) => {
						return <Text data={dataObj} key={i} />
					})}
				</div>
			)}
		</div>
	)
}
