import React from 'react'
import ChangePasswordModal from '../../../pages/modal/ChangePasswordModal/ChangePasswordModal'
import ChangeEmailModal from '../../../pages/modal/ChangeEmailModal/ChangeEmailModal'
import DeleteModal from '../../../pages/modal/DeleteModal/DeleteModal'
import SetConfirmationCodeModal from '../../../pages/modal/SetConfirmationCodeModal/SetConfirmationCodeModal'

// Набор модальных окон показываемых для всей категорий пользователей
function CommonModals() {
	return (
		<>
			<DeleteModal />
			<ChangeEmailModal />
			<SetConfirmationCodeModal />
			<ChangePasswordModal />
		</>
	)
}

export default CommonModals
