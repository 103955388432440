import { Tag } from 'antd'
import EntityTypes from 'parts/types/EntityTypes'
import { useTranslation } from 'react-i18next'

type UserTagProps = {
	role: EntityTypes.UserRole
}

function UserTag(props: UserTagProps) {
	const { role } = props

	const { t } = useTranslation()

	return (
		<>
			{role === EntityTypes.UserRole.ADMIN && (
				<Tag color="#3462AB">
					{t('answersFeed.answerBlockRoleAdmin')}
				</Tag>
			)}
			{role === EntityTypes.UserRole.MANAGER && (
				<Tag color="#9333EA">
					{t('answersFeed.answerBlockRoleManager')}
				</Tag>
			)}
			{role === EntityTypes.UserRole.CURATOR && (
				<Tag color="#1DABF2">
					{t('answersFeed.answerBlockRoleCurator')}
				</Tag>
			)}
		</>
	)
}

export default UserTag
