import SectionHeader from 'pages/landing/landingConstructor/details/SectionHeader/SectionHeader'
import SectionWrapper from 'pages/landing/landingConstructor/details/SectionWrapper/SectionWrapper'
import TakeAPart from 'pages/landing/landingConstructor/details/TakeAPart/TakeAPart'
import SectionContent from 'pages/landing/landingConstructor/sections/timeSection/SectionContent/SectionContent'

// Секция одностраничника с рассказом чему обучится ученик за курс
function TimeSection() {
	return (
		<SectionWrapper>
			<SectionHeader
				imagePropsPathArr={['sections', 'time', 'header', 'imageUrl']}
				headerPropsPathArr={['sections', 'time', 'header', 'text']}
			/>
			<div className="land-const-time-section">
				<SectionContent />
				<TakeAPart sectionName="time" />
			</div>
		</SectionWrapper>
	)
}

export default TimeSection
