import { create } from 'zustand'
import { AnswersFeedStoreType } from './storeTypes'

const useAnswersFeedStore = create<AnswersFeedStoreType.State>((set) => {
	return {
		answer: null,

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useAnswersFeedStore
