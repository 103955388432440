import { Progress } from 'antd'
import { useTranslation } from 'react-i18next'
import useLessonsStudentStore from '../zustand/store'
import { LessonStatus } from 'parts/services/LessonStudentService'
import { UCardBlock } from 'ui/wrappers/UCardBlock/UCardBlock'
import { useGetLessonPageUrl } from './fn/goToLessonPage'
import Image from 'ui/Image/Image'
import { useIsCardActive } from './fn/isCardActive'
import { TrainingStoreType } from '../zustand/storeTypes'
import './LessonCard.scss'

type LessonProps = {
	lesson: TrainingStoreType.Lesson
	isFirstLesson: boolean
}

// Карточка урока для просмотра администратором (с редактированием)
function LessonCard(props: LessonProps) {
	const { lesson, isFirstLesson } = props

	const training = useLessonsStudentStore((store) => store.training)

	const lessonPageUrl = useGetLessonPageUrl(lesson)
	const isCardActive = useIsCardActive(training, lesson, isFirstLesson)

	return (
		<UCardBlock
			extraClass="lesson-card"
			shadow={isCardActive}
			hover={isCardActive}
			href={isCardActive ? lessonPageUrl : undefined}
			dataCyAttr="lesson-card"
		>
			<Image
				src={lesson.cover}
				form="free"
				extraClass="lesson-card__cover-wrapper"
			/>
			<div className="lesson-card__content">
				<h2 className="lesson-card__name">{lesson.name}</h2>
				{lesson.description && (
					<p className="lesson-card__description">
						{lesson.description}
					</p>
				)}
			</div>
			<CircleProgress lesson={lesson} />
		</UCardBlock>
	)
}

export default LessonCard

type CircleProgressProps = {
	lesson: TrainingStoreType.Lesson
}

// Круг с прогрессом выполнения урока
function CircleProgress(props: CircleProgressProps) {
	const { lesson } = props

	const { t } = useTranslation()

	if (
		lesson.status == LessonStatus.Process ||
		lesson.status == LessonStatus.Finished
	) {
		return <Progress type="circle" percent={lesson.performed} size={60} />
	} else {
		return (
			<Progress
				type="circle"
				percent={lesson.performed}
				status="exception"
				size={60}
				format={() => (
					<img
						src="/images/signs/LockTwoTone.svg"
						alt={t('studentTraining.lessonCardForbiddenLessonAlt')}
					/>
				)}
			/>
		)
	}
}
