import cn from 'classnames'
import { useGetColorScheme } from 'pages/landing/landingConstructor/tariffs/card/common/common'
import EditableText from 'pages/landing/landingConstructor/tariffs/card/common/EditableText/EditableText'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'
import 'pages/landing/landingConstructor/tariffs/card/Header/Header.scss'

type HeaderProps = {
	tariff: TariffsStoreType.Tariff
}

// Заголовок карточки
function Header(props: HeaderProps) {
	const { tariff } = props
	const colorScheme = useGetColorScheme()

	const classes = ['tariff-card-header']
	if (tariff.isFlashed) {
		classes.push('tariff-card-header--white')
	} else {
		classes.push('tariff-card-header--' + colorScheme)
	}

	return (
		<h1 className={cn(classes)}>
			<EditableText
				tariffInnerId={tariff.innerId}
				propsPathArr={['header']}
				placeholder="Заголовок"
				afterChange={tariffsManager.setNeedToSave.bind(tariffsManager)}
			/>
		</h1>
	)
}

export default Header
