import { PlusOutlined } from '@ant-design/icons'
import {
	Button,
	DatePicker,
	Divider,
	Form,
	Input,
	InputRef,
	Select,
	Space,
	TimePicker,
} from 'antd'
import EntityTypes from 'parts/types/EntityTypes'
import { getRusNounByNumber } from 'parts/utils/string'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
	UFormNewBottomButtonsWithBorder,
	UFormNewButtonsContainer,
	UFormNewGeneralError,
	UFormNewRow,
	UFormNewWhiteWrapper,
} from 'ui/UForm/UForm'
import { FormHelpSections } from '../FormHelp/fn/dataTypes'
import FormHelp from '../FormHelp/FormHelp'
import { clearStoreInUnmount } from './fn/clearStoreInUnmount'
import {
	convertNumbersArrToOptionsDataArr,
	useManageOptions,
} from './formFn/daysInput'
import {
	revalidateFormAfterSectionHiding,
	useIsIntervalInputVisible,
	useIsStartDateInputVisible,
} from './formFn/fieldsVisibility'
import { FieldsNames, setErrorsToForm } from './formFn/form'
import { useGetInitialValues } from './formFn/initialValues'
import {
	getStartDateOptions,
	intervalOptions,
	openingByWeekDaysOptions,
	passingModeOptions,
} from './formFn/optionsData'
import { useGetOnSubmit } from './formFn/submit'
import { useGetSwitchHelpOnFocus } from './formFn/switchHelpOnFocus'
import './GroupConditionsForm.scss'
import useCondStore from './zustand/store'

const { Option } = Select

// Форма с настройками условий прохождения курса в группе
function GroupConditionsForm() {
	const group = useCondStore((state) => state.group)
	const [form] = Form.useForm()

	const initialValues = useGetInitialValues()

	const onSubmit = useGetOnSubmit()
	setErrorsToForm(form)

	const formErrors = useCondStore((state) => state.formErrors)
	const switchHelpOnFocus = useGetSwitchHelpOnFocus()
	clearStoreInUnmount()

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onSubmit}
			className="conditions-form"
			onFocus={switchHelpOnFocus}
			initialValues={initialValues}
		>
			<UFormNewWhiteWrapper>
				<div className="conditions-form__parts">
					<div className="conditions-form__left-part">
						<FormHelp />
					</div>
					<div className="conditions-form__right-part">
						<StartInput group={group} />
						<StartDateInput />
						<PassingModeInput />
						<IntervalOpeningInput />
						<OpeningByDaysInput />
						<DaysInput
							name={FieldsNames.Duration}
							label="Длительность обучения"
							helpSectionName={FormHelpSections.Duration}
						/>
						<DaysInput
							name={FieldsNames.Access}
							label="Доступ на курс"
							helpSectionName={FormHelpSections.Access}
						/>
					</div>
				</div>
				<Bottom />
				<UFormNewGeneralError message={formErrors.message} />
			</UFormNewWhiteWrapper>
		</Form>
	)
}

export default GroupConditionsForm

type StartInputProps = {
	group: EntityTypes.GroupData
}

// Выпадающий список с выбором когда открывается курс
function StartInput(props: StartInputProps) {
	const { group } = props

	return (
		<div data-help-section={FormHelpSections.Start}>
			<UFormNewRow>
				<Form.Item
					name={FieldsNames.StartCondition}
					label="Что считать датой старта обучения"
				>
					<Select>
						{getStartDateOptions(group.type === 'paid').map(
							(option, i) => (
								<Option key={i} value={option.value}>
									{option.label}
								</Option>
							)
						)}
					</Select>
				</Form.Item>
			</UFormNewRow>
		</div>
	)
}

// Поля с датой и временем начала запуска группы.
// Видно только если в поле «Что считать датой старта обучения» выбрали «Дата старта курса».
function StartDateInput() {
	const { t } = useTranslation()
	const isVisible = useIsStartDateInputVisible()

	revalidateFormAfterSectionHiding(isVisible)

	if (!isVisible) return null

	return (
		<div data-help-section={FormHelpSections.Start}>
			<UFormNewRow topOffset>
				<div className="conditions-form__inputs-wrapper">
					<Form.Item
						name={FieldsNames.StartDate}
						label="Дата старта обучения"
						rules={[
							{
								required: isVisible,
								message: 'Укажите дату начала обучения',
							},
						]}
					>
						<DatePicker />
					</Form.Item>
					<Form.Item
						name={FieldsNames.StartTime}
						label="Время старта обучения"
						rules={[
							{
								required: isVisible,
								message: 'Укажите время начала обучения',
							},
						]}
					>
						<TimePicker format={'HH:mm'} />
					</Form.Item>
				</div>
			</UFormNewRow>
		</div>
	)
}

// Выпадающий список с условием открытия уроков: сразу, по интервалу или по дням недели
function PassingModeInput() {
	const { t } = useTranslation()

	return (
		<div data-help-section={FormHelpSections.Mode}>
			<UFormNewRow topOffset>
				<Form.Item
					name={FieldsNames.PassingMode}
					label="Когда открываются уроки"
				>
					<Select>
						{passingModeOptions.map((option, i) => (
							<Option key={i} value={option.value}>
								{option.label}
							</Option>
						))}
					</Select>
				</Form.Item>
			</UFormNewRow>
		</div>
	)
}

// Выпадающий список с интервалом открытия уроков
function IntervalOpeningInput() {
	const { t } = useTranslation()

	const isVisible = useIsIntervalInputVisible(
		EntityTypes.GroupMode.ByIntervals
	)

	revalidateFormAfterSectionHiding(isVisible)

	if (!isVisible) return null

	return (
		<div data-help-section={FormHelpSections.Open}>
			<UFormNewRow topOffset>
				<Form.Item
					name={FieldsNames.Interval}
					label="Открытие уроков"
					rules={[
						{
							required: true,
							message: 'Укажите интервал открытия уроков',
						},
					]}
				>
					<Select>
						{intervalOptions.map((day, i) => {
							const word = getRusNounByNumber(
								i + 1,
								'день',
								'дня',
								'дней'
							)

							return (
								<Option
									key={i}
									value={
										EntityTypes.OpeningByIntervals[
											day as EntityTypes.OpeningByIntervals
										]
									}
								>
									Через {i + 1} {word}
								</Option>
							)
						})}
					</Select>
				</Form.Item>
			</UFormNewRow>
		</div>
	)
}

// Выпадающий список с днями открытия уроков
function OpeningByDaysInput() {
	const { t } = useTranslation()

	const isVisible = useIsIntervalInputVisible(
		EntityTypes.GroupMode.ByWeekDays
	)

	revalidateFormAfterSectionHiding(isVisible)

	if (!isVisible) return null

	return (
		<div data-help-section={FormHelpSections.Open}>
			<UFormNewRow topOffset>
				<Form.Item
					name={FieldsNames.OpeningByDay}
					label="Открытие уроков"
					rules={[
						{
							required: isVisible,
							message: 'Укажите в какие дни открываются уроки',
						},
					]}
				>
					<Select mode="multiple">
						{openingByWeekDaysOptions.map((option, i) => (
							<Option key={i} value={option.value}>
								{option.label}
							</Option>
						))}
					</Select>
				</Form.Item>
			</UFormNewRow>
		</div>
	)
}

type DaysInputProps = {
	name: string
	label: string
	helpSectionName: FormHelpSections
}

// Выпадающий список с возможностью добавить новое значение
function DaysInput(props: DaysInputProps) {
	const { name, label, helpSectionName } = props

	const addItemInputRef = useRef<InputRef>(null)

	const { daysNumArr, onNewDaysNumChange, newDayNum, addNewDaysNum } =
		useManageOptions(addItemInputRef)

	const options = convertNumbersArrToOptionsDataArr(daysNumArr)

	return (
		<div data-help-section={helpSectionName}>
			<UFormNewRow topOffset>
				<Form.Item name={name} label={label}>
					<Select
						dropdownRender={(menu) => (
							<>
								{menu}
								<Divider style={{ margin: '8px 0' }} />
								<Space style={{ padding: '0 8px 4px' }}>
									<Input
										placeholder="Дней"
										ref={addItemInputRef}
										value={newDayNum}
										onChange={onNewDaysNumChange}
										className="conditions-form__add-num-input"
									/>
									<Button
										type="text"
										icon={<PlusOutlined />}
										onClick={addNewDaysNum}
									>
										Добавить
									</Button>
								</Space>
							</>
						)}
						options={options}
					/>
				</Form.Item>
			</UFormNewRow>
		</div>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom() {
	const form = Form.useFormInstance()

	const isSubmitting = useCondStore((state) => state.isSubmitting)

	return (
		<UFormNewBottomButtonsWithBorder>
			<div />
			<UFormNewButtonsContainer>
				<Form.Item shouldUpdate>
					{() => {
						const formErrors = form
							.getFieldsError()
							.filter(({ errors }) => errors.length)

						return (
							<Button
								block
								type="primary"
								htmlType="submit"
								disabled={isSubmitting || formErrors.length > 0}
								loading={isSubmitting}
							>
								Сохранить
							</Button>
						)
					}}
				</Form.Item>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsWithBorder>
	)
}
