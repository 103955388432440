import cn from 'classnames'
import useLandingBuilderStore from '../../../zustand/store'
import './MainImage.scss'

// Главное изображение справа в секции с описанием чему научится человек после обучения.
function MainImage() {
	const landing = useLandingBuilderStore((store) => store.landing)
	const { colorScheme } = landing

	return (
		<div
			className={cn(
				'landing-time-main-img',
				'landing-time-main-img--' + colorScheme
			)}
		>
			<img
				src={landing.sections.time.imageUrl}
				className="landing-time-main-img__img"
				alt=""
			/>
		</div>
	)
}

export default MainImage
