import React from 'react'
import RestrictionsService from 'parts/services/RestrictionsService'
import { useClearStoreInUnmount } from './fn/clearStoreInUnmount'
import { useRefetchQueryCacheOnUnmount } from './fn/refetchQueryCacheOnUnmount'
import { useAutoSaveOnServer } from './fn/useAutoSaveOnServer'
import LandingConstructor from '../LandingConstructor/LandingConstructor'
import Loader from 'ui/Loader/Loader'
import useLandingConstructorStore from '../../zustand/store'
import { useFillInStore } from '../../zustand/fillStore/fillInStore'

function LandingConstructorPage() {
	useFillInStore()
	useAutoSaveOnServer()
	useClearStoreInUnmount()

	const landing = useLandingConstructorStore((store) => store.landing)
	useRefetchQueryCacheOnUnmount()

	const canUseSiteConstructor = RestrictionsService.useCanUseSiteConstructor()

	if (!landing || !canUseSiteConstructor) {
		return <Loader />
	}

	return <LandingConstructor />
}

export default LandingConstructorPage
