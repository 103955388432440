import { produce } from 'immer'
import { UploadFile } from 'antd'
import { LandingTypes } from 'parts/types/LandingTypes'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'

export function useGetInitialImageSrc<
	A extends keyof LandingTypes.Landing,
	B extends keyof LandingTypes.Landing[A],
	C extends keyof LandingTypes.Landing[A][B],
	D extends keyof LandingTypes.Landing[A][B][C],
	E extends keyof LandingTypes.Landing[A][B][C][D],
	F extends keyof LandingTypes.Landing[A][B][C][D][E],
	G extends keyof LandingTypes.Landing[A][B][C][D][E][F]
>(propsPathArr: [A, B?, C?, D?, E?, F?, G?]): string {
	const landing = useLandingConstructorStore((store) => store.landing)

	let parentObj: any = landing

	for (let i = 0; i < propsPathArr.length; i++) {
		parentObj = parentObj[propsPathArr[i]]

		const isLastItem = i == propsPathArr.length - 1

		if (isLastItem) {
			return parentObj
		}
	}

	return ''
}

/**
 * Возвращает функцию принимающую массив загруженных изображений и ставящих адрес первого изображения в указанное место Состояния одностраничника
 * @param propsPathArr — массив с названиями свойств и индексов с путём до свойства, в котором находится url изображения.
 */
export function useGetUpdateLandingStoreAfterUploadImage<
	A extends keyof LandingTypes.Landing,
	B extends keyof LandingTypes.Landing[A],
	C extends keyof LandingTypes.Landing[A][B],
	D extends keyof LandingTypes.Landing[A][B][C],
	E extends keyof LandingTypes.Landing[A][B][C][D],
	F extends keyof LandingTypes.Landing[A][B][C][D][E],
	G extends keyof LandingTypes.Landing[A][B][C][D][E][F]
>(propsPathArr: [A, B?, C?, D?, E?, F?, G?]) {
	const landing = useLandingConstructorStore((store) => store.landing)
	const updateState = useLandingConstructorStore((store) => store.updateStore)

	/**
	 * Изменяет значение текстового свойства в Состоянии одностраничника.
	 * @param propsPathArr — массив с названиями свойств и индексов с путём до свойства, которое нужно изменить.
	 * @param newText — текст, на который нужно изменить текущий текст.
	 */
	return function (files: UploadFile[]) {
		const newLanding = produce(landing, (draft) => {
			let parentObj: any = draft

			for (let i = 0; i < propsPathArr.length; i++) {
				const isLastItem = i == propsPathArr.length - 1
				if (isLastItem) break

				parentObj = parentObj[propsPathArr[i]]
			}

			const lastPropName = propsPathArr[propsPathArr.length - 1]

			parentObj[lastPropName] = files[0].url
		})

		updateState({ landing: newLanding })
	}
}
