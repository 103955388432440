import { LandingTypes } from 'parts/types/LandingTypes'
import TakeAPartButton from 'pages/landing/landingBuilder/details/TakeAPartButton/TakeAPartButton'
import './TakeAPart.scss'

type TakeAPartProps = {
	sectionName: keyof LandingTypes.Sections
}

// Обёртка кнопки «Записаться на курс». Ставится внизу секции.
function TakeAPart(props: TakeAPartProps) {
	const { sectionName } = props

	return (
		<div className="landing-take-a-part">
			<TakeAPartButton sectionName={sectionName} />
		</div>
	)
}

export default TakeAPart
