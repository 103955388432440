import $api from '../http'
import ExercisesApiTypes from './exercisesApiTypes'

// Запросы к методам получения уроков школы
const exercisesRequests = {
	// Создание упражнения
	createExercise(dto: ExercisesApiTypes.CreateExerciseDto) {
		return $api.post<ExercisesApiTypes.CreateExercise>(
			'/api/exercises',
			dto
		)
	},

	// Обновление упражнения
	updateExercise(dto: ExercisesApiTypes.UpdateExerciseDto) {
		return $api.patch<ExercisesApiTypes.UpdateExercise>(
			'/api/exercises',
			dto
		)
	},

	// Получение упражнения
	getExercise(exerciseId: number | string) {
		return $api.get<ExercisesApiTypes.GetExercise>(
			'/api/exercises/' + exerciseId
		)
	},

	// Удаление упражнения
	deleteExercise(exerciseId: number | string) {
		return $api.delete<ExercisesApiTypes.DeleteExercise>(
			'/api/exercises/' + exerciseId
		)
	},

	/**
	 * Отправка на сервер данных какое упражнение было выполнено и сколько это заняло времени
	 * @param dto — данные, передаваемые на сервер
	 */
	doneExercise(dto: ExercisesApiTypes.DoneExerciseDto) {
		return $api.post<ExercisesApiTypes.DoneExercise>(
			'/api/trainings/exercise/done',
			dto
		)
	},

	// Сохранение данных для восстановления изменений сделанных учеником в упражнении
	saveExerciseRestoreInfo(dto: ExercisesApiTypes.SaveExerciseRestoreInfoDto) {
		return $api.post<ExercisesApiTypes.SaveExerciseRestoreInfo>(
			'/api/exercises/state/save',
			dto
		)
	},

	// Получение данных для восстановления изменений сделанных учеником в упражнении
	getExerciseRestoreInfo(dto: ExercisesApiTypes.GetExerciseRestoreInfoDto) {
		return $api.post<ExercisesApiTypes.GetExerciseRestoreInfo>(
			'/api/exercises/state/restore',
			dto
		)
	},
}

export default exercisesRequests
