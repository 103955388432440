import { useEffect } from 'react'
import { FormInstance } from 'antd'
import dayjs from 'dayjs'
import PaymentTypes from 'parts/types/PaymentTypes'
import { setErrorsToFields } from 'parts/utils/form'
import useMakeOrderStore from '../zustand/store'

export enum FieldNames {
	Training = 'training',
	PurchaseDate = 'purchaseDate',
	PaymentSystem = 'paymentSystem',
	Currency = 'currency',
	Amount = 'amount',
	PaymentMode = 'paymentMode',
	CuratorId = 'curatorId',
	GetAccess = 'getAccess',
}

export type FormValuesType = {
	// Массив вида ['571', '82']. В первом id курса, во втором id группы
	[FieldNames.Training]: [string, string]
	[FieldNames.PurchaseDate]: dayjs.Dayjs
	[FieldNames.PaymentSystem]: PaymentTypes.System
	[FieldNames.Currency]: PaymentTypes.Currency
	[FieldNames.Amount]: string
	[FieldNames.PaymentMode]: PaymentTypes.PricePeriod
	[FieldNames.CuratorId]: string
	[FieldNames.GetAccess]: undefined | boolean
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useMakeOrderStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
