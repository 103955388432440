import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import AuthPage from '../../../../common/AuthPage/AuthPage'
import ContentWrapper from '../../../../common/ContentWrapper/ContentWrapper'
import AppUrls from 'parts/constants/pageUrl'
import SetNewPasswordForm from '../RecoverySetForm/RecoverySetForm'
import { useGetDescriptionText } from './fn/descriptionText'

// Страница с формой ввода нового пароля взамен забытого
function RecoverySetPage() {
	const { t } = useTranslation()
	const description = useGetDescriptionText()

	return (
		<AuthPage>
			<ContentWrapper
				header={t('auth.recoverSetPasswordPageHeader')}
				headerDesc={description}
				bottomText={
					<Link to={AppUrls.Login().url}>
						{t('auth.recoverSetPasswordPageLoginBLink')}
					</Link>
				}
			>
				<SetNewPasswordForm />
			</ContentWrapper>
		</AuthPage>
	)
}

export default RecoverySetPage
