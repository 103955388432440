import React, { useRef } from 'react'
import { ExerciseStateType } from '../zustand/exerciseState'
import { useGetCurrentQuestion } from './fn/main'
import { useSetInnerHtml } from '../../common/useSetInnerHtml'
import HtmlEditorContentView from 'ui/HTMLEditorContentView/HTMLEditorContentView'
import Media from './Media'
import {
	getAnswerClasses,
	getSignClasses,
	useGetOnClickAnswerFn,
} from './fn/answer'
import Sign from './icons/Sign'

// Вопрос
export function Question() {
	const question = useGetCurrentQuestion()

	const areaRef = useRef(null)
	useSetInnerHtml(areaRef, question?.text || '')

	if (!question) return null

	return (
		<div className="exercise-test-content__question">
			<HtmlEditorContentView
				areaRef={areaRef}
				outerClass="exercise-test-content__task"
			/>
			<Media question={question} />
			<div className="exercise-test-content__answers">
				{question.answers.map((answer) => {
					return (
						<Answer
							showCorrect={question.showCorrect}
							multipleSelect={question.multipleSelect}
							question={question}
							answer={answer}
							key={answer.id}
						/>
					)
				})}
			</div>
		</div>
	)
}

type AnswerProps = {
	showCorrect: boolean
	multipleSelect: boolean
	question: ExerciseStateType.Question
	answer: ExerciseStateType.Answer
}

// Кнопка ответа на вопрос
function Answer(props: AnswerProps) {
	const { showCorrect, multipleSelect, question, answer } = props

	const classes = getAnswerClasses(showCorrect, question, answer)
	const onAnswerClick = useGetOnClickAnswerFn(
		question,
		answer,
		multipleSelect
	)

	return (
		<div className={classes} onClick={onAnswerClick}>
			<AnswerSign multipleSelect={multipleSelect} answer={answer} />
			{answer.text}
		</div>
	)
}

type AnswerSignProps = {
	multipleSelect: boolean
	answer: ExerciseStateType.Answer
}

// Значок левее текста ответа
function AnswerSign(props: AnswerSignProps) {
	const { multipleSelect, answer } = props

	const classes = getSignClasses(answer)

	if (multipleSelect) {
		return answer.checked ? (
			<Sign type="checkBold" className={classes} />
		) : (
			<Sign type="checkThin" className={classes} />
		)
	}

	return answer.checked ? (
		<Sign type="radioBold" className={classes} />
	) : (
		<Sign type="radioThin" className={classes} />
	)
}
