import { useState } from 'react'
import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'
import { useGetRenameStorageItem } from '../../../fn/renameStorageItem'

function useRenameStorageItem() {
	const onRenameStorageItem = useGetRenameStorageItem()
	const [isShowConfirm, setIsSchowConfirm] = useState(false)
	const [item, setItem] = useState<LiveLessonEntityTypes.StorageItem | null>(
		null
	)

	const onConfirmOk = (name: string) => {
		if (item && name) {
			onRenameStorageItem(item.id, name)
		}
		setIsSchowConfirm(false)
	}

	const onConfirmCancel = () => {
		setIsSchowConfirm(false)
	}

	const renameItem = (storageItem: LiveLessonEntityTypes.StorageItem) => {
		setItem(storageItem)
		setIsSchowConfirm(true)
	}

	return {
		isShowConfirm,
		onConfirmOk,
		onConfirmCancel,
		renameItem,
	}
}

export default useRenameStorageItem
