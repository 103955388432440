import React, { useCallback } from 'react'
import { ExerciseStateType } from './stateType'
import { convertExerciseDataToExerciseState } from './state'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

/**
 * Хук запускает функцию запускающую прохождение упражнения заново
 * @param {Object} exercise — данные упражнения
 * @param {Function} setState — функция устанавливающая новый объект состояния упражнения
 */
export function useGetPassExerciseAgainFn(
	exercise: TrainingEntityTypes.DropWordExercise,
	setState: React.Dispatch<React.SetStateAction<ExerciseStateType.Main>>
) {
	return useCallback(function () {
		setState(convertExerciseDataToExerciseState(exercise))
	}, [])
}

/**
 * Получение текста с количеством доступных попыток просмотра результата
 * @param {Number} attempts — количество доступных попыток просмотра результата
 */
export function getAttemptsText(attempts: number) {
	if (attempts > 1) {
		return attempts + ' попытки'
	} else if (attempts == 1) {
		return '1 попытка'
	} else {
		return 'Попыток не осталось'
	}
}
