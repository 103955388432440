import EntityTypes from 'parts/types/EntityTypes'
import { LandingTypes } from 'parts/types/LandingTypes'
import { useFillInStore } from '../../zustand/fillStore/fillStore'
import useLandingBuilderStore from '../../zustand/store'
import LandingBuilder from '../LandingBuilder/LandingBuilder'

type LandingBuilderPageProps = {
	// Данные одностраничника
	landing: LandingTypes.Landing
	tariffs: EntityTypes.Tariff[]
}

// Страница строителя одностраничника с продажей курса
function LandingBuilderPage(props: LandingBuilderPageProps) {
	const { landing, tariffs } = props

	useFillInStore(landing, tariffs)

	const [landingData] = useLandingBuilderStore((store) => [store.landing])

	return landingData ? <LandingBuilder /> : null
}

export default LandingBuilderPage
