import { EXERCISE_ATTEMPTS } from '../../../common/exerciseConst'
import useExerciseStore from '../../zustand/exerciseState'
import { useGetClearTextBlocks } from '../../zustand/fn/fillStore'

/**
 * Хук запускает функцию запускающую прохождение упражнения заново
 */
export function useGetPassExerciseAgainFn() {
	const clearTextBlocks = useGetClearTextBlocks()

	const saveStoreForRestore = useExerciseStore(
		(store) => store.saveStoreForRestore
	)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		// ВОЗМОЖНО ЭТО МОЖНО УДАЛИТЬ, ЕСЛИ НИЧЕГО НЕ СЛОМАЛОСЬ.
		// Обнулить статистику по продолжительности выполнения упражнения
		// useExerciseStore.getState().updateStore({ durationsInSeconds: [] })

		updateStore({
			stage: 'NotReadyToCheck',
			saveStoreForRestore: saveStoreForRestore + 1,
			attempts: EXERCISE_ATTEMPTS,
		})

		clearTextBlocks()
	}
}
