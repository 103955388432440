import { useGetOnTakeAPartButtonClick } from 'pages/landing/landingConstructor/details/TakeAPartButton/fn/clickHandler'
import React from 'react'
import cn from 'classnames'
import EditableText from 'pages/landing/landingConstructor/details/editableElems/EditableText/EditableText'
import { LandingTypes } from 'parts/types/LandingTypes'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import 'pages/landing/landingConstructor/details/TakeAPartButton/TakeAPartButton.scss'

type TakeAPartButtonProps = {
	sectionName: keyof LandingTypes.Sections
}

// Кнопка, прокручивающая к секции с тарифами
function TakeAPartButton(props: TakeAPartButtonProps) {
	const { sectionName } = props

	const landing = useLandingConstructorStore((store) => store.landing)
	const colorScheme = landing.colorScheme

	const onButtonClick = useGetOnTakeAPartButtonClick()

	return (
		<button
			className={cn(
				'land-const-take-a-part-btn',
				'land-const-take-a-part-btn--' + colorScheme
			)}
			onClick={onButtonClick}
		>
			<EditableText
				propsPathArr={['sections', sectionName, 'buyBtnText']}
			/>
		</button>
	)
}

export default TakeAPartButton
