import React from 'react'
import cn from 'classnames'
import EditableText from 'pages/landing/landingConstructor/details/editableElems/EditableText/EditableText'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import TakeAPartButton from 'pages/landing/landingConstructor/details/TakeAPartButton/TakeAPartButton'
import './WaysBlocks.scss'
import './WaysBlock.scss'

export function WaysBlocks() {
	return (
		<div className="land-const-ways-blocks">
			<WrongBlock />
			<RightBlock />
		</div>
	)
}

export function WrongBlock() {
	return (
		<div className="land-const-ways-block land-const-ways-block--wrong">
			<div className="land-const-ways-block__content">
				<h3 className="land-const-ways-block__header">
					<EditableText
						propsPathArr={[
							'sections',
							'ways',
							'wrongWay',
							'header',
						]}
						placeholder="Заголовок"
					/>
				</h3>
				<p className="land-const-ways-block__text">
					<EditableText
						propsPathArr={['sections', 'ways', 'wrongWay', 'text']}
						placeholder="Заголовок"
					/>
				</p>
			</div>
			<img
				src="/landing/images/two-ways-sad.png"
				className="land-const-ways-block__emoji land-const-ways-block__emoji--sad"
				alt=""
			/>
			<div className="land-const-ways-block__btn-wrapper">
				<button className="land-const-ways-block__do-nothing-btn">
					Ничего не делать
				</button>
			</div>
		</div>
	)
}

export function RightBlock() {
	const landing = useLandingConstructorStore((store) => store.landing)
	const { colorScheme } = landing

	const wrapperClasses = [
		'land-const-ways-block',
		'land-const-ways-block--right',
		'land-const-ways-block--right--' + colorScheme,
	]

	return (
		<div className={cn(wrapperClasses)}>
			<div className="land-const-ways-block__content">
				<h3 className="land-const-ways-block__header">
					<EditableText
						propsPathArr={[
							'sections',
							'ways',
							'rightWay',
							'header',
						]}
						placeholder="Заголовок"
					/>
				</h3>
				<p className="land-const-ways-block__text">
					<EditableText
						propsPathArr={['sections', 'ways', 'rightWay', 'text']}
						placeholder="Заголовок"
					/>
				</p>
			</div>
			<img
				src="/landing/images/two-ways-happy.png"
				className="land-const-ways-block__emoji land-const-ways-block__emoji--happy"
				alt=""
			/>
			<div className="land-const-ways-block__btn-wrapper">
				<TakeAPartButton sectionName="ways" />
			</div>
		</div>
	)
}
