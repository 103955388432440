import React, { memo } from 'react'
import { Layout } from 'antd'
import MainMenu from '../MainMenu/MainMenu'
import useMenuSiderStore from './zustand/store'
import './SideMenu.scss'

const { Sider } = Layout

// Правое главное меню сайта
function MenuSider() {
	const isCollapsed = useMenuSiderStore((store) => store.isCollapsed)
	const setIsCollapsed = useMenuSiderStore((store) => store.setIsCollapsed)

	return (
		<Sider
			breakpoint="md"
			collapsible
			collapsed={isCollapsed}
			onCollapse={setIsCollapsed}
			className="menu-sider"
		>
			<MainMenu />
		</Sider>
	)
}

export const MemoizedMenuSider = memo(MenuSider)
