import PageSkeleton from '../../../ui/wrappers/PageSkeleton/PageSkeleton'
import AppUrls from 'parts/constants/pageUrl'
import PageHeader from '../../../ui/pageHeader/PageHeader/PageHeader'
import LeadsArticle from '../LeadsArticle/LeadsArticle'
import Footer from 'ui/Footer/Footer'
import { useGetUserRole } from 'parts/utils/hooks/hooks'

/** Страница со списком покупок */
function LeadsPage() {
	const userRoles = useGetUserRole()

	return (
		<PageSkeleton>
			<PageHeader
				breadcrumbs={[
					{
						path: AppUrls.Leads().url,
						breadcrumbName: AppUrls.Leads().name,
					},
				]}
				header={AppUrls.Leads().name}
				// Временно скрыть потому что формы ещё не существует
				// buttons={[<AddLeadButton key="1" />]}
			/>
			<LeadsArticle />
			<Footer isRightSideHidden={!userRoles.isAdminOrManager} />
		</PageSkeleton>
	)
}

export default LeadsPage
