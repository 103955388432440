import { Cascader } from 'antd'

import {
	useGetCascaderOptions,
	useGetOnStatusChange,
} from './fn/onStatusChange'

function StatusForm() {
	const options = useGetCascaderOptions()
	const onStatusChange = useGetOnStatusChange()

	return <Cascader options={options} onChange={onStatusChange as any} />
}

export default StatusForm
