import React from 'react'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import { AddEmployeeModalController } from '../staffTable/addEmployeeModal/AddEmployeeModalController'
import { StaffTableController } from '../staffTable/StaffTableController'
import NoStuffScreen from './NoStuff'

function StaffArticle() {
	const staffTableRouter = StaffTableController.router()

	const inCenter =
		staffTableRouter.status === 'loading' ||
		staffTableRouter.status == 'noStaff' ||
		staffTableRouter.status == 'error'

	return (
		<ArticleContentWrapper
			center={inCenter}
			innerWrapperWidth={inCenter ? 'small' : 'full'}
		>
			{staffTableRouter.status == 'loading' && staffTableRouter.element}
			{staffTableRouter.status == 'noStaff' && <NoStuffScreen />}
			{staffTableRouter.status == 'hasStaff' && staffTableRouter.element}
			{AddEmployeeModalController.element}
		</ArticleContentWrapper>
	)
}

export default StaffArticle
