import useLiveLessonEntrancePageStore from '../../../zustand/store'

/**
 * Возвращает обработчик кнопки «Я проверил почту, перехожу к уроку».
 * Так как кнопка должна отправлять на форму входа так как ученик создан,
 * то можно задать роль allowedRole и другой хук получит это значение и отправит на стандартную страницу входа.
 */
export function useGetOnButtonClick() {
	const updatePageState = useLiveLessonEntrancePageStore(
		(store) => store.updateState
	)

	return function () {
		updatePageState({ currentRole: 'allowedRole' })
	}
}
