import useExerciseStore from '../exerciseState'

/**
 * Очищает текстовые блоки от введённых слов через задание нового идентификатора.
 * Тогда Реакт полностью перерисует текстовые блоки.
 */
export function useGetClearHtmlBlocks() {
	const images = useExerciseStore((store) => store.images)
	const descriptions = useExerciseStore((store) => store.descriptions)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		// Так как при перетаскивании изменяется DOM через стандартный JS, то React ничего про это не знает.
		// Поэтому принудительно перерисую элементы.
		const newImages = images.map((image) => {
			return { ...image, id: image.id + 100 }
		})

		// Так как при перетаскивании изменяется DOM через стандартный JS, то React ничего про это не знает.
		// Поэтому принудительно перерисую элементы.
		const newDescriptions = descriptions.map((description) => {
			return { ...description, id: description.id + 100 }
		})

		updateStore({ images: newImages, descriptions: newDescriptions })
	}
}
