import { produce } from 'immer'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'

/**
 * Возвращает функцию, удаляющую существующий блок
 * @param blockId — id удаляемого блока
 */
export function useGetDeleteBlock(blockId: number) {
	const [landing, updateStore] = useLandingConstructorStore((s) => [
		s.landing,
		s.updateStore,
	])

	return function () {
		if (!landing) return

		const updatedLanding = produce(landing, (draft) => {
			draft.sections.faq.faqBlocks =
				landing.sections.faq.faqBlocks.filter(
					(block) => block.id !== blockId
				)
		})

		updateStore({ landing: updatedLanding })
	}
}
