// Константы относящиеся к сайту
import useSystemStore from '../systemStore/systemStore'

const Site = {
	protocol: 'https://',
	devRootDomain: 'dev.credu.ai',
	prodRootDomain: 'credu.ai',
	devS3Domain: 'theclub.fra1.cdn.digitaloceanspaces.com',
	prodS3Domain: 'credu.fra1.cdn.digitaloceanspaces.com',
	// Главный домен: dev.credu.ai или credu.ai
	get rootDomain() {
		return location.hostname == 'localhost' ||
			location.hostname.includes(this.devRootDomain)
			? this.devRootDomain
			: this.prodRootDomain
	},
	// Домен школы: testovaya1.dev.credu.ai
	get schoolDomain() {
		return useSystemStore.getState().school.domain
	},
	// домен S3 хранилища
	get storageDomain() {
		return location.hostname == 'localhost' ||
			location.hostname.includes(this.devRootDomain)
			? this.devS3Domain
			: this.prodS3Domain
	},
	// Пример номера телефона для полей формы
	examplePhone: '+1 999 999 99 99',
	// Пример почты для полей формы
	exampleEmail: 'mail@mail.com',
	// Приставка к адресу, добавляемая к адресу одностраничника,
	// чтобы получился адрес страницы с тарифами для вставки на другой сайт.
	// Например school-1.credu.ai/lp/training-1 → school-1.credu.ai/lp/training-1__tariffs
	tariffsPageUrlPostfix: '__tariffs',
}

export default Site
