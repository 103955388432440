import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import { useGetBreadcrumbs } from './fn/breadcrumbs'
import { useGetHeaderTexts } from './fn/header'

function ExercisePageHeader() {
	const breadcrumbs = useGetBreadcrumbs()
	const { pageHeader, pageDescription } = useGetHeaderTexts()

	return (
		<PageHeader
			breadcrumbs={breadcrumbs}
			header={pageHeader}
			description={pageDescription}
		/>
	)
}

export default ExercisePageHeader
