import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { ExercisesListAdminController } from '../../../../../widgets/exercisesListForAdmin/ExercisesListAdminController'

// Кнопка добавляющая в урок новое упражнение
function AddExerciseButton() {
	return (
		<Button
			type="primary"
			icon={<PlusOutlined />}
			onClick={ExercisesListAdminController.openExercisesAside}
		>
			Добавить упражнение
		</Button>
	)
}

export default AddExerciseButton
