import { useGetDoneExercise } from '../../../common/doneExercise'
import useExerciseStore from '../../zustand/exerciseState'
import { useGetExerciseDuration } from './getExerciseDuration'

/**
 * Возвращает функцию, ставящую в Хранилище свойство doneAllow в false,
 * чтобы заблокировать кнопку выполнения упражнения
 * и отправляющая на сервер статус выполненности упражнения.
 */
export function useGetAnswerWasSent() {
	const updateStore = useExerciseStore((store) => store.updateStore)
	const duration = useGetExerciseDuration()

	return () => {
		updateStore({ answerWasSent: true, doneAllow: true })
	}
	/*return useGetDoneExercise(
		() => updateStore({ answerWasSent: true, doneAllow: true }),
		duration
	)*/
}
