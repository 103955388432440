const expressEn = {
	// БАННЕР НА ГЛАВНОЙ СТРАНИЦЕ
	// Заголовок баннера
	mainPageTitle: 'Express courses how to work with Credu',
	// Подзаголовок баннера
	mainPageSubtitle: 'By taking the course you will learn',

	// КАРТОЧКА КУРСА Как проводить Live-уроки
	mainPageHowToTeachCardLessonCount: '12 detailed lessons',
	mainPageHowToTeachCardTitle: 'How to Live-lessons',
	mainPageHowToTeachCardSubtitle:
		'Credu was designed specifically for online tutorials. We have created 12 tips for you that will answer most of your questions how to work on Credu features. Start getting familiar with the Credu platform now!',

	// КАРТОЧКА КУРСА Как создавать курсы на Сredu
	mainPageHowToCreateCardLessonCount: '12 detailed lessons',
	mainPageHowToCreateCardTitle: 'How to create courses on Сredu',
	mainPageHowToCreateCardSubtitle:
		'At Credu, you can create any online course you want: from a three-lecture mini-course with automatic homework checking to a large engagement course with midterm testing, manual homework checking and feedback to students, audio and video exercises, and webinars. Take our mini-lessons and learn how to prepare and start your dream course up and running quickly.',
}

export default expressEn
