import { create } from 'zustand'
import { ExerciseStudentStoreType } from './storeTypes'

const useExerciseStudentStore = create<ExerciseStudentStoreType.State>(
	(set) => {
		return {
			dataLoadingStatus: 'loading',
			trainingStudent: undefined as any,
			lesson: undefined as any,
			groupId: 0,
			exercisesMeta: [],
			percentage: 0,

			updateStore(obj) {
				return set((state) => {
					return obj
				})
			},
		}
	}
)

export default useExerciseStudentStore
