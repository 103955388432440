import React, { useCallback, useContext, useState } from 'react'
import { AxiosError } from 'axios'
import { FormInstance, message } from 'antd'
import { produce } from 'immer'
import ServerTypes from 'parts/types/ServerTypes'
import { useTranslation } from 'react-i18next'
import { StateContext } from '../../state/stateContext'
import { StateType } from '../../state/stateType'
import SchoolApiTypes from 'parts/requests/school/schoolApiTypes'
import schoolRequests from 'parts/requests/school/schoolRequest'

export interface FormValues {
	paySystemName: string
}

/**
 * Функция возвращающая данные для работы формы
 */
export function useManageForm() {
	// В случае ошибки сюда попадут данные ошибки
	const [formErrors, setFormErrors] = useState<ServerTypes.ErrorResponse>({})

	// Обработчик отправки формы
	const onSubmit = useGetOnSubmit(setFormErrors)

	return {
		onSubmit,
		formErrors,
	}
}

/**
 * Хук возвращающий обработчик отправки формы
 * @param {Function} setFormErrors — функция устанавливающая объект ошибок в Состояние ошибок
 */
function useGetOnSubmit(
	setFormErrors: React.Dispatch<React.SetStateAction<{}>>
) {
	const { t } = useTranslation()
	const { state, setState } = useContext(StateContext)

	return useCallback(
		async (values: FormValues) => {
			try {
				setState((state): StateType.State => {
					return produce(state, (draft) => {
						draft.stopPaySystemModal.isSubmitting = true
					})
				})

				// Отправить запрос и получить ответ
				const response = await schoolRequests.setPaymentConnection(
					getDisconnectDto(state)
				)

				if (response.data) {
					setState((state): StateType.State => {
						return produce(state, (draft) => {
							// Закрыть модальное окно
							draft.stopPaySystemModal.isOpen = false
							// Отметить, что подключение отстутствует
							draft.connectionType = 'off'
						})
					})

					// Показать уведомление об отключении
					message.success(
						t('myPayments.stopPaymentSystemModalSuccess')
					)
				}
			} catch (err) {
				const error = err as AxiosError<ServerTypes.ErrorResponse>

				if (error.response) {
					// Поставить данные ошибки в Состояние, чтобы показать их в форме
					setFormErrors(error.response.data)
				}
			} finally {
				// Убрать статус загрузки
				setState((state): StateType.State => {
					return produce(state, (draft) => {
						draft.stopPaySystemModal.isSubmitting = false
					})
				})
			}
		},
		[state]
	)
}

/**
 * Возвращает DTO для отключения платёжной системы
 * @param ctxState —  объект состояния
 */
function getDisconnectDto(
	ctxState: StateType.State
): SchoolApiTypes.SetPaymentConnectionDto {
	return {
		id: ctxState!.initialFormData!.id!,
		connected: false,
	}
}
