import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import {
	UTable,
	UTableBCell,
	UTableBody,
	UTableBRow,
	UTableHCell,
	UTableHRow,
} from 'ui/UTable/UTable'
import { getPaymentStats } from './fn/totalSum'
import { dateStrToHumanDateStr } from 'parts/utils/time'
import './StudentOrdersTable.scss'

import { paymentsQuery } from 'parts/requests/payments/paymentsQuery'
import EntityTypes from 'parts/types/EntityTypes'

type CommonProps = {
	userId: string | number
}

export default function StudentOrdersTable(props: CommonProps) {
	const { userId } = props

	const { data } = paymentsQuery.getPaymentsOfUser(userId).useQuery()
	const payments = data!.data

	return (
		<UTable header={<SumStats payments={payments} />}>
			<TableHeader />
			<UTableBody>
				{payments.map((payment) => (
					<TableRow payment={payment} key={payment.id} />
				))}
			</UTableBody>
		</UTable>
	)
}

type SumStatsProps = {
	payments: EntityTypes.PaymentOfUser[]
}

function SumStats(props: SumStatsProps) {
	const { payments } = props

	const paymentsStrings = getPaymentStats(payments)

	return (
		<div className="student-orders__stats">
			Всего заказов{' '}
			{paymentsStrings.map((stat, i) => {
				return (
					<Tag color="green" key={i}>
						{stat}
					</Tag>
				)
			})}
		</div>
	)
}

function TableHeader() {
	const { t } = useTranslation()

	return (
		<UTableHRow>
			<UTableHCell>{t('student.ordersTableHeaderTraining')}</UTableHCell>
			<UTableHCell>{t('student.ordersTableHeaderGroup')}</UTableHCell>
			<UTableHCell>{t('student.ordersTableHeaderDate')}</UTableHCell>
			<UTableHCell>{t('student.ordersTableHeaderPrice')}</UTableHCell>
			<UTableHCell>{t('student.ordersTableHeaderStatus')}</UTableHCell>
		</UTableHRow>
	)
}

type TableRowProps = {
	payment: EntityTypes.PaymentOfUser
}

function TableRow(props: TableRowProps) {
	const { payment } = props
	const { t } = useTranslation()

	return (
		<UTableBRow>
			<UTableBCell key="training">{payment.training}</UTableBCell>
			<UTableBCell key="group">{payment.group}</UTableBCell>
			<UTableBCell key="date">
				{dateStrToHumanDateStr(payment.paymentDate)}
			</UTableBCell>
			<UTableBCell key="price">
				{payment.price / 100 + ' ' + payment.currency}
			</UTableBCell>
			<UTableBCell key="status">
				<Tag color="green">{t('student.ordersTableStatusPayment')}</Tag>
			</UTableBCell>
		</UTableBRow>
	)
}
