import { Button } from 'antd'
import React from 'react'
import { getAttemptText } from './fn/getAttemptText'
import { getPercentage } from './fn/getPercentage'
import { getTimeFromMs } from './fn/getTime'
import { getRingMetrics, useAnimateRing } from './fn/ring'
import './Result.scss'

type ResultProps = {
	// Количество секунд потребовавшееся на прохождение упражнения по всем попыткам
	seconds: undefined | number
	// Общее количество вопросов в упражнении
	totalQuestions: number
	// Статистика по трём попыткам
	stats: ResultStatistic[]
	// Функция, запускаемая
	passAgainFn?: Function // Обработчик нажатия на кнопку повторного прохождения упражнения
}

export type ResultStatistic = {
	passedQuestions: number // Количество пройденных вопросов
}

// Экран с результатом выполнения упражнения
function Result(props: ResultProps) {
	const { seconds, totalQuestions, stats, passAgainFn } = props

	const time = getTimeFromMs((seconds || 0) * 1000)
	const percentage = getPercentage(stats, totalQuestions)

	return (
		<section className="exercise-result">
			<div className="exercise-result__ring-wrapper">
				<Ring percentage={percentage} />
				<div>
					<p className="exercise-result__percents-text">
						{percentage}
					</p>
					<p className="exercise-result__percents-sign">%</p>
				</div>
			</div>
			<div className="exercise-result__stats">
				<p className="exercise-result__stats-paragraph">{time}</p>
				{stats.map((statsObj, i) => {
					return (
						<p className="exercise-result__stats-paragraph" key={i}>
							{getAttemptText(i + 1, statsObj, totalQuestions)}
						</p>
					)
				})}
			</div>

			{passAgainFn && (
				<Button
					onClick={() => passAgainFn()}
					className="exercise-result__pass-again-btn"
				>
					Пройти снова
				</Button>
			)}
		</section>
	)
}

export default Result

type RingProps = {
	percentage: number
}

function Ring(props: RingProps) {
	const { percentage } = props

	const { radius, diameter, lineWidth, offset } = getRingMetrics()
	const strokeDasharray = useAnimateRing(diameter, percentage)

	return (
		<svg
			version="1.1"
			baseProfile="full"
			xmlns="http://www.w3.org/2000/svg"
			width={diameter + lineWidth}
			height={diameter + lineWidth}
			className="exercise-result__ring"
		>
			<g transform={`rotate(-90 ${offset} ${offset})`}>
				<circle
					cx={offset}
					cy={offset}
					r={radius}
					stroke="#F5F5F5"
					strokeWidth={lineWidth}
					fill="none"
				/>
				<circle
					cx={offset}
					cy={offset}
					r={radius}
					stroke="#64B625"
					strokeWidth={lineWidth}
					strokeDasharray={strokeDasharray}
					strokeDashoffset="0"
					fill="none"
				/>
			</g>
		</svg>
	)
}
