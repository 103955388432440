import { useEffect } from 'react'
import useSendCodeBtnStore from '../zustand/store'

/**
 * Хук следит за изменением свойства secondsLeft в Состоянии и если оно больше нуля, то каждую секунду уменьшает это значение до тех пор, пока она снова не будет нулём.
 */
export function useDecrementSecondsLeft() {
	const [secondsLeft, updateStore] = useSendCodeBtnStore((state) => [
		state.secondsLeft,
		state.updateStore,
	])

	useEffect(
		function () {
			if (secondsLeft == 0) return

			setTimeout(function () {
				updateStore({ secondsLeft: secondsLeft - 1 })
			}, 1000)
		},
		[secondsLeft]
	)
}
