import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { groupQuery } from 'parts/requests/group/groupQuery'

/**
 * Возвращает обработчик нажатия на переключатель активации группы
 * @param trainingId
 * @param groupId — id группы, которую нужно активировать
 */
export function useGetActivateGroupHandler(
	trainingId: number,
	groupId: number
) {
	const { t } = useTranslation()
	const queryClient = useQueryClient()

	const { mutate } = groupQuery.activateGroup.useMutation({
		onError: () => {
			message.error('Не удалось запустить группу пользователя')
		},
		onSuccess: () => {
			message.success('Группа запущена')
			// Пометить список групп курса неактуальным.
			// После этого React Query скачает новый список
			queryClient.refetchQueries({
				queryKey: [groupQuery.getTrainingGroups(trainingId).key],
			})
		},
	})

	return function () {
		mutate(groupId)
	}
}
