import { SyntheticEvent } from 'react'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import { QueryClient, useQueryClient } from 'react-query'
import { lessonsQuery } from 'parts/requests/lessons/lessonsQuery'
import useDeleteModalStore from 'pages/modal/DeleteModal/store/store'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import LessonAdminService from 'parts/services/LessonAdminService'
import useLessonsListAdminStore from '../../../../zustand/store'

/**
 * Хук возвращает функцию, открывающую модальное окно удаления урока
 * @param lessonId — id удаляемого урока
 */
export function useGetDeleteLessonModal(lessonId: number) {
	const { t } = useTranslation()
	const updateModalStore = useDeleteModalStore((state) => state.updateStore)

	const deleteLesson = useGetDeleteLesson(lessonId)

	return function (event: SyntheticEvent) {
		event.preventDefault()

		updateModalStore({
			isOpen: true,
			header: t('adminTraining.deleteLessonModalTitle'),
			text: t('adminTraining.deleteLessonModalText'),
			onOk: deleteLesson,
		})
	}
}

/**
 * Возвращает функцию удаляющую урок.
 * @param lessonId — id удаляемого урока
 */
function useGetDeleteLesson(lessonId: number) {
	const queryClient = useQueryClient()

	const deleteLessonMutation = lessonsQuery.deleteLesson.useMutation({
		onError: useGetMutateOnError(),
		onSuccess: getMutateOnSuccess(queryClient, lessonId),
	})

	return function () {
		deleteLessonMutation.mutate(lessonId)
	}
}

/** Функция, выполняемая при появлении ошибки после запроса на удаление урока */
function useGetMutateOnError() {
	const { t } = useTranslation()

	return function (err: unknown) {
		message.error(t('adminTraining.deleteLessonModalReqError'))
	}
}

/**
 * Функция, выполняемая при успешном запросе удаления урока
 * @param queryClient — клиент React Query
 * @param lessonId — id удаляемого урока
 */
function getMutateOnSuccess(queryClient: QueryClient, lessonId: number) {
	return function () {
		const { trainingId, lessons, updateStore } =
			useLessonsListAdminStore.getState()

		const updatedLessons = LessonAdminService.deleteLessonByIdInLessons(
			lessons,
			lessonId
		)
		updateStore({ lessons: updatedLessons })

		// Скачать новые данные, чтобы убрать старые из кеша React Query
		if (trainingId) {
			// Пометить данные курса неактуальными. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [trainingQuery.getTrainingForAdmin(trainingId).key],
			})
		} else {
			// Пометить массив уроков школы неактуальным. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [lessonsQuery.getSchoolLessons.key],
			})
		}
	}
}
