import {
	Button,
	Form,
	Input,
	Select,
	Space,
	DatePicker,
	TimePicker,
} from 'antd'
import { LinkOutlined } from '@ant-design/icons'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'
import { useGetOnSubmit } from './fn/submit'
import {
	FieldNames,
	setErrorsToForm,
	useSetValuesNewLesson,
	useSetValuesExistingLesson,
} from './fn/formFields'
import {
	UFormNewButtonsContainer,
	UFormNewGeneralError,
	UFormNewRow,
	UFormNewBottomButtonsAfterGray,
} from 'ui/UForm/UForm'
import useAddLiveModalStore from './zustand/store'
import { getTimeZones } from './fn/getTimeZonesList'
import { useGetChangeModalVisibility } from './zustand/storeManager'
import { useGetDeleteLiveRoom } from './fn/deleteRoom'
import { useGetPushTextToClipboard } from './fn/formFieldsFunc'
const { Option } = Select
import { getLivelessonsStudents, getLivelessonsCurators } from './fn/fillForm'

function LifelessonsForm() {
	const [form] = Form.useForm()

	setErrorsToForm(form)
	const ErrorMessage = useAddLiveModalStore(
		(state) => state.formErrors.message
	)
	useSetValuesNewLesson(form)
	useSetValuesExistingLesson(form)

	const onSubmit = useGetOnSubmit(form)

	return (
		<Form
			form={form}
			name="addLivelesson"
			layout="vertical"
			onFinish={onSubmit}
		>
			<Space
				direction="vertical"
				size="middle"
				style={{ display: 'flex' }}
			>
				<GrayBlock>
					<NameInput />
					<DateInput />
					<TimeInput />
				</GrayBlock>
				<GrayBlock>
					<CuratorInput />
					<StudentsInput />
				</GrayBlock>
				<LinkInput />
			</Space>
			<Bottom />
			<UFormNewGeneralError message={ErrorMessage} />
		</Form>
	)
}

export default LifelessonsForm

// Поле с заголовком встречи
function NameInput() {
	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.Title}
				label="Название комнаты"
				rules={[
					{
						required: true,
						message: 'Введите название комнаты.',
					},
				]}
				shouldUpdate
			>
				<Input placeholder="Мой урок" />
			</Form.Item>
		</UFormNewRow>
	)
}

function DateInput() {
	const dateFormat = 'DD-MM-YYYY'
	return (
		<UFormNewRow topOffset>
			<Form.Item name={FieldNames.Date} label="Дата" shouldUpdate>
				<DatePicker
					format={dateFormat}
					placeholder="Выберите дату встречи"
					locale={locale}
					style={{ width: '100%' }}
				/>
			</Form.Item>
		</UFormNewRow>
	)
}

//Поле выбора времени начала и поле выбора часового пояса
function TimeInput() {
	const timeFormat = 'HH:mm'
	const timeZonesList = getTimeZones()
	return (
		<UFormNewRow topOffset>
			<Form.Item name={FieldNames.Time} label="Время" shouldUpdate>
				<TimePicker
					format={timeFormat}
					placeholder="Выберите время"
					locale={locale}
				/>
			</Form.Item>
			<Form.Item
				name={FieldNames.TimeZone}
				label="Часовой пояс"
				shouldUpdate
			>
				<Select
					showSearch
					placeholder="Выберите часовой пояс"
					optionFilterProp="children"
					style={{ width: '100%' }}
				>
					{timeZonesList.map((timezone) => {
						return (
							<Option value={timezone.value} key={timezone.id}>
								{timezone.value}
							</Option>
						)
					})}
				</Select>
			</Form.Item>
		</UFormNewRow>
	)
}

//Поле выбора куратора
function CuratorInput() {
	const curatorsList = getLivelessonsCurators()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Curator}
				label="Назначить учителя"
				shouldUpdate
			>
				{curatorsList}
			</Form.Item>
		</UFormNewRow>
	)
}

//Поле приглашения участников
function StudentsInput() {
	const studentsList = getLivelessonsStudents()
	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Students}
				label="Пригласить участников"
				shouldUpdate
			>
				{studentsList}
			</Form.Item>
		</UFormNewRow>
	)
}

// Поле со ссылкой
function LinkInput() {
	/* Копирование ссылки в буфер обмена */
	const linkToLivelesson = useAddLiveModalStore((state) => state.lessonLink)
	const copyLinkToLivelesson = useGetPushTextToClipboard(linkToLivelesson)

	return (
		<Form.Item label="Ссылка на комнату" hidden>
			<Space.Compact style={{ width: '100%' }}>
				<Form.Item
					name={FieldNames.Link}
					style={{ width: '100%' }}
					shouldUpdate
					hidden
				>
					<Input />
				</Form.Item>
				<Button onClick={copyLinkToLivelesson}>
					<LinkOutlined />
				</Button>
			</Space.Compact>
		</Form.Item>
	)
}

// Кнопки отправки формы и отмены и удаления
function Bottom() {
	const modalState = useAddLiveModalStore((state) => state)
	const close = useGetChangeModalVisibility(false)

	const form = Form.useFormInstance()
	const deleteRoom = useGetDeleteLiveRoom(modalState.lessonId, form)

	return (
		<UFormNewBottomButtonsAfterGray>
			{modalState.lessonId ? (
				<Button type="text" onClick={deleteRoom}>
					Удалить
				</Button>
			) : (
				<div></div>
			)}
			<UFormNewButtonsContainer>
				<Button onClick={close}>Отмена</Button>
				<Button
					type="primary"
					htmlType="submit"
					disabled={modalState.isSubmitting}
					loading={modalState.isSubmitting}
				>
					{!modalState.lessonId ? 'Создать' : 'Сохранить'}
				</Button>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
