import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { UTable, UTableBody, UTableHCell, UTableHRow } from 'ui/UTable/UTable'
import AddStaffRow from '../AddStuffRow/AddStuffRow'
import TableRows from '../TableRows/TableRows'
import './StuffTable.scss'

// Таблица кураторов, назначенных группе
function StaffTable() {
	const { t } = useTranslation()

	return (
		<UTable header="Кураторы и менеджеры">
			<UTableHRow>
				<UTableHCell>Имя</UTableHCell>
				<UTableHCell>
					Роль{' '}
					<Tooltip placement="top" title="Роль пользователя">
						<QuestionCircleOutlined />
					</Tooltip>
				</UTableHCell>
				<UTableHCell>Планируется</UTableHCell>
				<UTableHCell>Заполнено</UTableHCell>
				<UTableHCell>Действия</UTableHCell>
			</UTableHRow>
			<UTableBody>
				<TableRows />
				<AddStaffRow />
			</UTableBody>
		</UTable>
	)
}

export default StaffTable
