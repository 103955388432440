import { useMemo } from 'react'
import AppUrls from 'parts/constants/pageUrl'

/** Хук возвращает адреса пунктов выделенные по умолчанию. */
export function useGetDefaultSelectedItemKey(): string[] {
	const { pathname } = location

	const currentUrl = useMemo(() => {
		if (pathname === '/') {
			return AppUrls.Main().url
		} else {
			const patchNameParts = pathname.split('/')
			return '/' + patchNameParts[1]
		}
	}, [pathname])

	return [currentUrl]
}
