import { createMachine } from 'xstate'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

/**
 * Возвращает конечный автомат текстового упражнения
 * @param exercise — данные упражнения
 */
export function getTextExerciseMachine(
	exercise: TrainingEntityTypes.TextExercise
) {
	const initialStateName = getInitialStateName(exercise)

	return createMachine({
		id: 'textExercise',
		initial: initialStateName,
		states: {
			doneDisallow: {
				on: { toggleDoneBtn: 'doneAllow' },
			},
			doneAllow: {
				on: { toggleDoneBtn: 'doneDisallow' },
			},
		},
	})
}

export function getInitialStateName(
	exercise: TrainingEntityTypes.TextExercise
) {
	return exercise.status ===
		TrainingEntityTypes.ExerciseProgressStatus.PENDING
		? 'doneAllow'
		: 'doneDisallow'
}
