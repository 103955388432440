import { message } from 'antd'
// import { sendAccess } from '../../../students/AddStudentModal/fn/submit'
import UserApiTypes from 'parts/requests/user/userApiTypes'
import { useTranslation } from 'react-i18next'
import { FieldNames, FormValuesType } from './form'
import userRequests from 'parts/requests/user/userRequest'
import PaymentTypes from 'parts/types/PaymentTypes'
import { AxiosError } from 'axios'
import ServerTypes from 'parts/types/ServerTypes'
import useMakeOrderStore from '../zustand/store'
import { useQueryClient } from 'react-query'
import { paymentsQuery } from 'parts/requests/payments/paymentsQuery'
import UrlService from 'parts/services/UrlService'

export function useMakeOrder() {
	const { t } = useTranslation()
	const studentId = UrlService.useGetStudentId()

	const updateMakeOrderModalStore = useMakeOrderStore(
		(state) => state.updateStore
	)

	const queryClient = useQueryClient()
	const runCreatePayment = useGetRunCreatePayment()
	const applyCurator = useGetApplyCurator()

	return async function (values: FormValuesType) {
		try {
			// Поставить статус загрузки
			updateMakeOrderModalStore({ isSubmitting: true })

			// Добавить новую продажу
			await runCreatePayment(values, studentId)

			if (values[FieldNames.CuratorId]) {
				// Добавить ученика куратору
				await applyCurator(values, studentId)
			}

			// Если отметили флаг «Выдать доступ в личный кабинет», то отправить запрос на выдачу доступа
			if (values[FieldNames.GetAccess]) {
				// sendAccess(parseInt(studentId))
			}

			// Закрыть модальное окно
			updateMakeOrderModalStore({ isOpen: false })

			message.success('Заказ сохранён.')

			// Пометить список покупок неактуальным.
			// После этого React Query скачает новый список
			queryClient.refetchQueries({
				queryKey: [paymentsQuery.getPaymentsOfUser(studentId).key],
			})
		} catch (err) {
			const error = err as AxiosError<ServerTypes.ErrorResponse>

			if (error.response) {
				// Поставить данные ошибки в Состояние, чтобы показать их в форме
				updateMakeOrderModalStore({ formErrors: error.response.data })
			}

			message.error(t('student.makeOrderModalCannotMakeOrder'))
		} finally {
			// Убрать статус загрузки
			updateMakeOrderModalStore({ isSubmitting: false })
		}
	}
}

/** Создание оплаты за курс
 */
function useGetRunCreatePayment() {
	const { t } = useTranslation()

	return async function (formValues: FormValuesType, userId: number) {
		const manualPaymentDto: UserApiTypes.CreateManualPaymentDto = {
			customerId: userId,
			groupId: parseInt(formValues[FieldNames.Training][1]), // Придёт массив вида ['467', '4']. В первом числе будет id курса, во втором id группы. Это и нужно передавать.
			purchaseDate: formValues[FieldNames.PurchaseDate]
				? formValues[FieldNames.PurchaseDate].toString()
				: '',
			paymentSystem: formValues[FieldNames.PaymentSystem],
			// Сумма. Умножается на 100 потому что значение на сервере хранятся в копейках и центах.
			amount: parseInt(formValues[FieldNames.Amount]) * 100,
			currency: formValues[FieldNames.Currency],
			mode: PaymentTypes.PaymentMode.Payment, // Пока всегда такое значение потому что подписки пока нет.
		}

		const response = await userRequests.createManualPayment(
			manualPaymentDto
		)

		if (response.status !== 201) {
			throw new Error(t('student.makeOrderModalCannotCreatePayment'))
		}
	}
}

/**
 * Запрос добавляет куратору созданного ученика
 * @param {Object} formValues — данные введённые в форму
 * @param {Number} studentId — id ученика к которому нужно применить куратора
 */
function useGetApplyCurator() {
	const { t } = useTranslation()

	return async function (formValues: FormValuesType, studentId: number) {
		if (formValues.curatorId === 'none') {
			return
		}

		const addStudentToCuratorDto: UserApiTypes.AddStudentToCuratorDto = {
			studentId,
			curatorId: parseInt(formValues.curatorId),
		}

		const response = await userRequests.addStudentToCurator(
			addStudentToCuratorDto
		)

		if (response.status !== 201) {
			throw new Error(
				t('student.makeOrderModalCannotAddStudentToCurator')
			)
		}
	}
}
