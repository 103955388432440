import useAddStaffInGroupModalStore from '../zustand/store'

/**
 * Возвращает функцию, открывающую/закрывающую модальное окно добавления сотрудника в группу
 * @param isVisible — должно ли окно быть видимым
 */
export function useGetSetVisibilityToAddStuffModal(isVisible: boolean) {
	const updateStore = useAddStaffInGroupModalStore(
		(state) => state.updateStore
	)

	return function () {
		updateStore({ isOpen: isVisible })
	}
}
