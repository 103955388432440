import {
	ExerciseChangesDataMadeByUser,
	LiftViewDuration,
	SaveExerciseChangesMadeByUser,
	useLiftViewDuration,
} from '../common/useLiftViewDuration'
import Exercise from './Exercise'
import Result from '../common/Result/Result'
import { useGetPassExerciseAgainFn } from './fn/result'
import { useCreateExerciseState } from './fn/state'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import './DropWordExerciseView.scss'

type DropWordExerciseViewProps = {
	exercise: TrainingEntityTypes.DropWordExercise
	liftViewDuration?: LiftViewDuration
	// Функция, которая будет вызываться при изменении упражнения пользователем.
	// В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
	// Данные для восстановления упражнения к тем изменениям, которые сделал ученик
	exerciseRestoreData?: ExerciseChangesDataMadeByUser
}

// Упражнение по выбору правильного слова (обвес + упражнение + результат)
function DropWordExerciseView(props: DropWordExerciseViewProps) {
	const {
		exercise,
		liftViewDuration,
		saveExerciseChangesMadeByUser,
		exerciseRestoreData,
	} = props

	const { state, setState } = useCreateExerciseState(exercise)

	// Считает время прошедшее после открытия упражнения
	// и передаёт количество секунд в liftViewDuration
	const durationSec = useLiftViewDuration(liftViewDuration, state.showResult)

	// Функция запускающая прохождение упражнения заново
	const passExerciseAgainFn = useGetPassExerciseAgainFn(exercise, setState)

	return (
		<>
			{state.showResult ? (
				<Result
					onPassAgainButtonClick={passExerciseAgainFn}
					seconds={durationSec}
					passed={state.rightAnswers}
					total={state.totalSlots}
				/>
			) : (
				<Exercise
					exercise={exercise}
					state={state}
					setState={setState}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
					exerciseRestoreData={exerciseRestoreData}
				/>
			)}
		</>
	)
}

export default DropWordExerciseView
