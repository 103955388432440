import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import videoPicSrc from '../images/video.svg'
import imagePicSrc from '../images/image.svg'
import audioPicSrc from '../images/audio.svg'
import textPicSrc from '../images/text.svg'
import testPicSrc from '../images/test.svg'
import trueFalsePicSrc from '../images/trueFalse.svg'
import descriptionImagePicSrc from '../images/descriptionImage.svg'
import chooseWordPicSrc from '../images/chooseWord.svg'
import gapsInWordsPicSrc from '../images/gapsInWords.svg'
import dropWordPicSrc from '../images/dropWord.svg'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

export type DataDrawerType = {
	id: number
	title: string
	text: string
	formType: TrainingEntityTypes.ExerciseTypes
	picSrc: string
}

export function useGetMenuData(): DataDrawerType[] {
	const { t } = useTranslation()

	return useMemo(function () {
		return [
			{
				id: 1,
				title: 'Загрузить видео',
				text: 'Загружайте видео с YouTube и Vimeo, а также напрямую с вашего компьютера.',
				formType: 'video',
				picSrc: videoPicSrc,
			},
			{
				id: 2,
				title: 'Загрузить Аудио',
				text: 'Загружайте аудиоматериалы в формате mp3 любой длительности.',
				formType: 'audio',
				picSrc: audioPicSrc,
			},
			{
				id: 3,
				title: 'Загрузить изображение',
				text: 'Загружайте изображения в формате jpg, png.',
				formType: 'image',
				picSrc: imagePicSrc,
			},
			{
				id: 4,
				title: 'Вставить текст',
				text: 'Упражнение для развития навыка чтения.',
				formType: 'text',
				picSrc: textPicSrc,
			},
			{
				id: 5,
				title: 'Создание теста',
				text: 'Создавайте тесты и опросы. Используйте видео, аудио и изображения.',
				formType: 'test',
				picSrc: testPicSrc,
			},
			{
				id: 6,
				title: 'Выбрать ложь или истину',
				text: 'Ученик должен указать ложное или истинное утверждение.',
				formType: 'trueOrFalse',
				picSrc: trueFalsePicSrc,
			},
			{
				id: 7,
				title: 'Ввести слова в пропуски',
				text: 'Ученик должен ввести в пропуски предложенные слова.',
				formType: 'gapsInWords',
				picSrc: gapsInWordsPicSrc,
			},
			{
				id: 8,
				title: 'Выбор правильной формы слова',
				text: 'Ученик должен выбрать правильную форму слова из списка.',
				formType: 'chooseWord',
				picSrc: chooseWordPicSrc,
			},
			{
				id: 9,
				title: 'Перетаскивание слов в пропуски',
				text: 'Ученик должен перетащить слова в пропуски. Можно указать варианты не подходящие ни к одному пропуску.',
				formType: 'dropWords',
				picSrc: dropWordPicSrc,
			},
			{
				id: 10,
				title: 'Сопоставить описание с картинкой',
				text: 'Ученик должен сопоставить верное описание с изображением',
				formType: 'descriptionImg',
				picSrc: descriptionImagePicSrc,
			},
		]
	}, [])
}
