import { useEffect } from 'react'
import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { groupQuery } from 'parts/requests/group/groupQuery'
import GroupsApiTypes from 'parts/requests/group/groupsApiTypes'
import { EmployeeGroupsStoreType } from '../../zustand/storeTypes'
import { dateStrToHumanDateStr } from 'parts/utils/time'
import useEmployeeGroupsStore from '../../zustand/store'
import UrlService from 'parts/services/UrlService'

/**
 * Запрашивает массив групп куратора и ставит в Состояние.
 */
export function useQueryGroupsAndSetToStore() {
	const employeeId = UrlService.useGetEmployeeId()

	const { data } = groupQuery.getUserGroups(employeeId).useQuery()

	const updateStore = useEmployeeGroupsStore((state) => state.updateStore)

	useEffect(
		function () {
			if (!data) return

			const stateGroups = convertServGroupsToStateGroups(data)
			updateStore({ employeeGroups: stateGroups })
		},
		[data]
	)
}

/**
 * Получает ответ от сервера на запрос списка групп, в которых есть текущий сотрудник,
 * и превращает их в формат данных используемый в Состоянии
 * @param response — ответ сервера.
 */
function convertServGroupsToStateGroups(
	response: AxiosResponse<GroupsApiTypes.GetUserGroups>
): EmployeeGroupsStoreType.Group[] {
	return response.data.map((group) => {
		return {
			groupId: group.id,
			groupName: group.name,
			trainingName: group.name,
			// Количество участников в группе (учеников и сотрудников)
			numOfParticipants: group.numOfParticipants,
			// Заполнено
			studentsCount: group.studentsCount,
			// Дата начала группы
			startDate: getStartDate(group),
			// Дата окончания группы
			finishDate: getFinishDate(group),
		}
	})
}

function getStartDate(group: GroupsApiTypes.UserGroup) {
	const startDateStr = group.startDate
	if (!startDateStr) return ''

	return dateStrToHumanDateStr(startDateStr)
}

function getFinishDate(group: GroupsApiTypes.UserGroup) {
	const startDateStr = group.startDate
	if (!startDateStr) return ''

	const finishDate = dayjs(startDateStr).add(group.duration, 'days')

	return dateStrToHumanDateStr(finishDate)
}
