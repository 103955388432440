import { FormInstance } from 'antd'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import { useEffect } from 'react'

export enum FieldsNames {
	Currency = 'currency',
	PaymentSystem = 'paymentSystem',
	Period = 'period',
}

/** Возвращает первоначальные значения полей формы редактирования сотрудника. */
export function useGetInitialValues() {
	const tariff = tariffsManager.useGetActiveTariff()
	if (!tariff) return {}

	return {
		[FieldsNames.Currency]: tariff.mainPrice.currency,
		[FieldsNames.PaymentSystem]: tariff.mainPrice.paymentSystem,
		[FieldsNames.Period]: tariff.mainPrice.period,
	}
}

/**
 * Хук наблюдает за переключением на другой тариф.
 * Если это произошло, то ставит в поля формы значения этого тарифа.
 * @param form — объект формы
 */
export function useUpdateFormValuesAfterSwithToAnotherTariff(
	form: FormInstance
) {
	const initialValues = useGetInitialValues()
	const tariff = tariffsManager.useGetActiveTariff()

	useEffect(
		function () {
			form.setFieldsValue(initialValues)
		},
		[tariff?.innerId]
	)
}
