import { useEffect } from 'react'
import { exercisesFormsManager } from '../../../exercisesFormsManager'
import useExercisesListAdminStore from '../../../zustand/store'
import {
	ExerciseType,
	GapsExerciseFormStateType,
	getFormInitialState,
} from './stateType'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { createNewTextFormDataItem } from './check'

/** Функция получает данные упражнения из Хранилища, перерабатывает их под формат данных формы и устанавливает в Хранилище */
export function useSetFormData(exerciseType: ExerciseType) {
	const exercises = useExercisesListAdminStore((store) => store.exercises)
	const exerciseId = useExercisesListAdminStore((store) => store.exerciseId)

	const setDataToExerciseFormStore =
		exercisesFormsManager.useGetSetDataToExerciseFormStore()

	useEffect(
		function () {
			let formData: GapsExerciseFormStateType =
				getFormInitialState(exerciseType)

			const exercise = exercises.find(
				(exercise) => exercise.id === exerciseId
			)

			if (exercise) {
				formData = convertExerciseDataToFormStateData(
					exercise as TrainingEntityTypes.GapsInWordsExercise
				)
			}

			setDataToExerciseFormStore(formData)
		},
		[exerciseId]
	)
}

/**
 * Функция переводит данные упражнения в формат данных используемым в форме
 * @param {Object} gapsInWordsExercise — данные упражнения
 */
function convertExerciseDataToFormStateData(
	gapsInWordsExercise: TrainingEntityTypes.GapsInWordsExercise
): GapsExerciseFormStateType {
	return {
		exerciseId: gapsInWordsExercise.id || null,
		type: 'gapsInWords',
		order: gapsInWordsExercise.order,
		taskInput: {
			value: gapsInWordsExercise.item.task,
		},
		texts: gapsInWordsExercise.item.texts.map((textBlock, i) => {
			return createNewTextFormDataItem(
				i,
				textBlock.text,
				textBlock.generatedTextPrompt
			)
		}),
		isFormInvalid: false,
	}
}
