import React from 'react'
import cn from 'classnames'
import useLandingBuilderStore from '../../../zustand/store'
import './WaysHeader.scss'

// Секция одностраничника с частозадаваемыми вопросами
function WaysHeader() {
	const landing = useLandingBuilderStore((store) => store.landing)
	const { colorScheme } = landing

	const headerClasses = [
		'landing-ways-section-header',
		'landing-ways-section-header--' + colorScheme,
	]

	return (
		<h2 className={cn(headerClasses)}>
			{landing.sections.ways.header.text}
		</h2>
	)
}

export default WaysHeader
