import { AxiosError } from 'axios'
import { useEffect, useMemo, useRef, useState } from 'react'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import UrlService from 'parts/services/UrlService'
import ServerTypes from 'parts/types/ServerTypes'
import { groupQuery } from 'parts/requests/group/groupQuery'
import typeChecker from 'parts/utils/typeChecker'
import { prepareTariffs } from '../../../common/common'
import useLandingPreviewStore from '../store'
import EntityTypes from 'parts/types/EntityTypes'
import { divideTo100 } from 'parts/utils/number'

/** Получает данные для построения одностраничника и помещает их в Зустанд */
export function useFillInStore() {
	useFetchLandingAndSetToStore()

	const groupId = useGetGroupId()
	useFetchTariffsAndSetToStore(groupId)

	useSetSuccessStatus()
}

/** Загружает данные одностраничника и ставит в Состояние */
function useFetchLandingAndSetToStore() {
	const store = useLandingPreviewStore((store) => store)
	const trainingId = UrlService.useGetTrainingId()

	// Получить данные одностраничника
	const { data: landingDataRes } = trainingQuery
		.getDraftTrainingLanding(trainingId)
		.useQuery({
			onError: queryError,
		})

	useEffect(
		function () {
			if (store.dataLoadingStatus == 'error' || !landingDataRes) {
				return
			}

			store.updateStore({ landing: landingDataRes.data })
		},
		[landingDataRes]
	)
}

/** Загружает все курсы, находит текущий курс и его активную группу
 * и ставит в Состояние адрес его одностраничника и идентификатор активной группы */
function useGetGroupId() {
	const updateStore = useLandingPreviewStore((store) => store.updateStore)
	const trainingId = UrlService.useGetTrainingId()

	// Получить все курсы
	const { data: trainingRes } = trainingQuery
		.getTrainingForAdmin(trainingId)
		.useQuery({
			onError: queryError,
		})

	const [activeGroupId, setActiveGroupId] = useState<undefined | number>(
		undefined
	)

	useEffect(
		function () {
			if (!trainingRes) {
				return
			}

			const activeGroup = trainingRes.data.groups.find(
				(group) => group.isActive
			)

			if (!activeGroup) {
				updateStore({
					dataLoadingStatus: 'error',
					errorMessage: 'Нет активной группы',
				})

				setActiveGroupId(undefined)
				return
			}

			setActiveGroupId(activeGroup.id)
		},
		[trainingRes]
	)

	return activeGroupId
}

function useFetchTariffsAndSetToStore(groupId: undefined | number) {
	const updateStore = useLandingPreviewStore((store) => store.updateStore)

	const tariffsRes = groupQuery.getTariffs(groupId as number).useQuery({
		enabled: typeChecker.isNumber(groupId),
		onError: queryError,
	})

	// Завершена ли вставка тарифов в Состояние.
	// Требуется, чтобы избежать циклических вызовов useEffect
	const operationDoneRef = useRef(false)

	useEffect(
		function () {
			if (!tariffsRes || !tariffsRes.data || operationDoneRef.current)
				return

			operationDoneRef.current = true

			const tariffs = tariffsRes.data.data
			prepareTariffs(tariffs)

			updateStore({
				tariffs,
			})
		},
		[tariffsRes]
	)
}

/** Следит за загрузкой всех данных и после ставит в Состояние успешный статус */
function useSetSuccessStatus() {
	const store = useLandingPreviewStore((store) => store)

	useEffect(
		function () {
			if (!store.landing || !store.tariffs) {
				return
			}

			store.updateStore({
				dataLoadingStatus: 'success',
				errorMessage: null,
			})
		},
		[store.landing, store.tariffs]
	)
}

/**
 * Функция запускаемая при ошибке запроса данных. Срабатывает для любых запросов.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 * @param err — объект ошибки.
 */
function queryError(err: unknown) {
	const { updateStore } = useLandingPreviewStore.getState()

	const error = err as AxiosError<ServerTypes.ErrorResponse>

	updateStore({ dataLoadingStatus: 'error', errorMessage: error.message })
}
