const answersFeedEn = {
	// БЛОК ОТВЕТА
	// Кнопка «Показать упражнение»
	answerBlockShowExerciseButton: 'Show exercise',
	// Кнопка удаления ответа «Удалить»
	answerBlockDeleteAnswerButton: 'Delete',
	// Сообщение про неудавшуюся попытку удаления ответа
	answerBlockDeleteAnswerFailRequest: 'Failed to delete the reply.',
	// Статус проверки упражнения: Проверяется
	answerBlockExerciseStatusChecking: 'Checking',
	// Статус проверки упражнения: Проверен
	answerBlockExerciseStatusChecked: 'Checked',
	// Статус проверки упражнения: Не проверен
	answerBlockExerciseStatusNotChecked: 'Unchecked',
	// Роль сотрудника ответившего на выполненное упражнение: Администратор
	answerBlockRoleAdmin: 'Administrator',
	// Роль сотрудника ответившего на выполненное упражнение: Менеджер
	answerBlockRoleManager: 'Manager',
	// Роль сотрудника ответившего на выполненное упражнение: Куратор
	answerBlockRoleCurator: 'Curator',

	// ЛЕНТА ОТВЕТОВ
	// Сообщение про отсутствие упражнений для проверки
	answersFeedNoAnswersScreenText: 'There are no test exercises in this group',
	answersFeedNoAnswersScreenText2: '',
	// Ссылка на все ответы учеников
	answersFeedAllAnswersLink: 'All',

	// МОДАЛЬНОЕ ОКНО С ПОДТВЕРЖДЕНИЕМ УДАЛЕНИЯ ОТВЕТА
	// Заголовок модального окна подтверждения удаления курса
	answersFeedDeleteModalHeader: 'Deleting reply',
	// Текст модального окна подтверждения удаления курса
	answersFeedDeleteModalText: 'Are you sure you want to delete the reply?',
}

export default answersFeedEn
