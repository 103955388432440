import { useEffect } from 'react'
import ExerciseSwitcherNew from 'widgets/exercisesViewsNew/ExerciseSwitcherNew'
import { useGetExerciseRestoreData } from './fn/getExerciseChangesMadeByUser'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import PreviewExerciseAside from '../PreviewExerciseAside/PreviewExerciseAside'
import {
	useSayToServerThatFirstLessonWasOpened,
	useSayToServerTimeWhenLessonOpen,
} from './fn/sendSignalsToServer'
import { useGetExercise, useGetExerciseMeta } from './fn/getExercise'
import { useGetSaveExerciseChangesMadeByUser } from './fn/saveExerciseChangesMadeByUser'
import { prevAndNextExerciseUrls } from './fn/prevAndNextExerciseUrls'
import './ExerciseArticleNew.scss'

// Блок вывода упражнения из урока для учащегося
function ExerciseArticleNew() {
	useSayToServerTimeWhenLessonOpen()
	useSayToServerThatFirstLessonWasOpened()

	const saveExerciseChangesMadeByUser = useGetSaveExerciseChangesMadeByUser()
	const exerciseRestoreData = useGetExerciseRestoreData()

	const { prevExerciseUrl, nextExerciseUrl } = prevAndNextExerciseUrls()

	const exercise = useGetExercise()
	const exerciseMeta = useGetExerciseMeta()
	if (!exercise || !exerciseMeta) return null

	return (
		<ArticleContentWrapper
			innerWrapperWidth="big"
			innerWrapperClass="preview-exercise"
		>
			<aside
				className="preview-exercise__aside"
				id="preview-exercise__aside"
			>
				<PreviewExerciseAside />
			</aside>
			<div className="preview-exercise__content">
				<ExerciseSwitcherNew
					exerciseContainerType="exercisePage"
					exercise={exercise}
					prevExerciseUrl={prevExerciseUrl}
					nextExerciseUrl={nextExerciseUrl}
					exerciseRestoreData={exerciseRestoreData}
					saveExerciseChangesMadeByUser={
						saveExerciseChangesMadeByUser
					}
				/>
			</div>
		</ArticleContentWrapper>
	)
}

export default ExerciseArticleNew
