import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import useExerciseStore from '../../zustand/exerciseState'
import { createEmptyState } from '../../zustand/fn/fillStore'

/**
 * Обработчик кнопки прохождения упражнения заново
 * @param {Object} exercise — объект с данными упражнения
 */
export function useGetOnPassAgainButtonClick(
	exercise: TrainingEntityTypes.TrueOrFalseExercise
) {
	const saveStoreForRestore = useExerciseStore(
		(store) => store.saveStoreForRestore
	)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		const emptyState = createEmptyState({
			exercise,
			stateType: 'cleared',
			newSaveStoreForRestore: saveStoreForRestore + 1,
		})

		updateStore(emptyState)
	}
}
