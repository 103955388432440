import { useEffect } from 'react'
import useSendCodeBtnStore, { timeSession } from '../zustand/store'

/** Очищает Состояние при размонтировании компонента */
export function clearStoreInUnmount() {
	const updateStore = useSendCodeBtnStore((state) => state.updateStore)

	useEffect(function () {
		return function () {
			updateStore({
				secondsLeft: timeSession,
			})
		}
	}, [])
}
