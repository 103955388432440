import useSystemStore from 'parts/systemStore/systemStore'
import { useEffect, useState } from 'react'
import schoolRequests from 'parts/requests/school/schoolRequest'
import SchoolApiTypes from 'parts/requests/school/schoolApiTypes'
import PaymentTypes from 'parts/types/PaymentTypes'

/** Скачивает с сервера массив объектов с данными по подключению к платёжной системе */
export function useGetPaymentsData() {
	const school = useSystemStore((store) => store.school)
	const [payments, setPayments] = useState<SchoolApiTypes.GetPaymentData>([])

	useEffect(
		function () {
			if (!school) return

			getPaymentsDataOfSchool(school.id).then((payments) => {
				if (!payments) return

				setPayments(payments)
			})
		},
		[school]
	)

	return payments
}

/** Получает с сервера данные настроек платёжных сервисов подключенных к школе */
async function getPaymentsDataOfSchool(schoolId: number) {
	// Отправить запрос и получить ответ
	const response = await schoolRequests.getPaymentsData(schoolId)

	if (response.data) {
		return response.data
	}
}

/**
 * Тип подключения карточки платёжной системы:
 * idle — данные для подключения ещё не переданы
 * off — платёжная система отключена
 * on — платёжная система включена
 */
export type CardConnectionType = 'idle' | 'off' | 'on'

/**
 * Возвращает тип подключения карточки.
 * @param payments массив объектов с информацией о подключении к ПС.
 * Если данные ещё не переданы, то объект с данными для подключения будет отсутствовать.
 * @param paymentType тип платёжной системы
 */
export function getCardConnectionType(
	payments: SchoolApiTypes.GetPaymentData,
	paymentType: PaymentTypes.System
): CardConnectionType {
	const paymentData = payments.find(
		(payment) => payment.paymentSystem == paymentType
	)

	if (!paymentData) return 'idle'

	return paymentData.isConnected ? 'on' : 'off'
}
