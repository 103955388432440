import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Badge, Collapse } from 'antd'
import classNames from 'classnames'
import AppUrls from 'parts/constants/pageUrl'
import useAnswersFeedStore from '../../zustand/store'
import EntityTypes from 'parts/types/EntityTypes'
import styles from './AnswerFeedNavigationPanel.module.scss'

const { Panel } = Collapse

export default function AnswerFeedNavigationPanel() {
	const { t } = useTranslation()

	const trainings = useAnswersFeedStore((state) => state.trainingsAdmin)
	const answersFeed = useAnswersFeedStore((state) => state.answersFeed)

	let trainingAnswersCounter: Record<number, number> = {}
	const [counter, setCounter] = useState<Record<number, number>>(
		trainingAnswersCounter
	)

	return (
		<div className={styles.NavigationPanel}>
			<Link className={styles.AllLink} to={AppUrls.AnswersFeed().url}>
				{t('answersFeed.answersFeedAllAnswersLink')}
			</Link>
			<Collapse ghost>
				{trainings.map((training) => {
					return (
						<Panel
							className={styles.Panel}
							header={
								<Badge
									offset={[32, 6]}
									style={{
										backgroundColor:
											'rgba(59, 130, 246, 0.1)',
										color: '#1dabf2',
									}}
									count={counter[training.id]}
								>
									{training.name}
								</Badge>
							}
							key={training.id}
						>
							{training.groups.map((group) => {
								const answerCount = answersFeed.reduce(
									(acc, next) => {
										if (
											next.groupId === group.id &&
											next.status !==
												EntityTypes.AnswerStatus.Checked
										) {
											acc++
										}
										return acc
									},
									0
								)

								trainingAnswersCounter = {
									...trainingAnswersCounter,
									[training.id]: trainingAnswersCounter[
										training.id
									]
										? trainingAnswersCounter[training.id] +
										  answerCount
										: answerCount,
								}

								const linkClassNames = classNames({
									[styles.Link]: true,
									[styles.ActiveLink]:
										location.pathname ==
										AppUrls.AnswersFeedGroup(group.id).url,
								})

								return (
									<div
										className={linkClassNames}
										key={group.id}
									>
										<Link
											to={
												AppUrls.AnswersFeedGroup(
													group.id
												).url
											}
										>
											{group.name}
										</Link>
										{answerCount > 0 && (
											<span>{answerCount}</span>
										)}
									</div>
								)
							})}
						</Panel>
					)
				})}
			</Collapse>
		</div>
	)
}
