import ArticleContentWrapper from '../../../ui/ArticleContentWrapper/ArticleContentWrapper'
import AccountForm from '../AccountForm/AccountForm'
import { RemoveUserModalController } from '../RemoveUserModal/RemoveUserModalController'
import './ProfileArticle.scss'

// Содержимое страницы профиля текущего пользователя
function ProfileArticle() {
	return (
		<ArticleContentWrapper innerWrapperWidth="small" center>
			<div className="profile-forms-wrapper">
				<AccountForm />
			</div>
			{RemoveUserModalController.element}
		</ArticleContentWrapper>
	)
}

export default ProfileArticle
