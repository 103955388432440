import { useTranslation } from 'react-i18next'
import ModalWithForm from 'ui/ModalWithForm/ModalWithForm'
import AddLessonForm from './AddLessonForm'
import useAddLessonModalStore from './zustand/store'
import { useGetSetVisibilityToAddLessonModal } from './fn/openCloseModal'

// Модальное окно добавления нового урока
function AddLessonModal() {
	const { t } = useTranslation()
	const modalState = useAddLessonModalStore((state) => state)
	const closeModal = useGetSetVisibilityToAddLessonModal(false)

	return (
		<ModalWithForm
			title={t('adminTraining.addLessonModalCancelBtn')}
			open={modalState.isOpen}
			onCancel={closeModal}
		>
			<AddLessonForm />
		</ModalWithForm>
	)
}

export default AddLessonModal
