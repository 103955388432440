import { Navigate, Outlet, Route } from 'react-router-dom'
import AppUrls from 'parts/constants/pageUrl'
import EntityTypes from 'parts/types/EntityTypes'
import AnswersFeedPage from '../../pages/answersFeed/page/AnswersFeedPage/AnswersFeedPage'
import LandingConstructorPreviewPage from '../../pages/landing/landingPreview/LandingPreviewPage/LandingConstructorPreviewPage'
import SchoolLessonsAdminPage from '../../pages/liveLessons/schoolLessons/SchoolLessonsArticle/SchoolLessonsArticle'
import { LiveRoomsPagesWrapper } from '../../pages/liveLessons/liveRoomsPagesWrapper/LiveRoomsAdminPage/LiveRoomsPagesWrapper'
import SchoolLessonArticle from '../../pages/liveLessons/schoolLesson/SchoolLessonArticle/SchoolLessonArticle'
import MyPaymentsHistoryArticle from '../../pages/my/admin/myPaymentsHistory/MyPaymentsHistoryArticle/MyPaymentsHistoryArticle'
import MySubscriptionArticle from '../../pages/my/admin/mySubscription/MySubscriptionArticle/MySubscriptionArticle'
import TrainingStaffPage from '../../pages/training/admin/TrainingPage/TrainingStaffPage'
import {
	getLandingRoute,
	getLiveLessonEntranceRoute,
	getSuccessAndFailRoutes,
	RouterVarNames,
} from './special/otherRoutes'
import ErrorPage from 'pages/duty/ErrorPage/ErrorPage'
import ProtectedRoute from '../../ui/ProtectedRoute/ProtectedRoute'
import LeadsPage from 'pages/leads/LeadsPage/LeadsPage'
import Messages from 'pages/messages/Messages/Messages'
import StudentWrapArticle from 'pages/student/main/StudentWrapArticle/StudentWrapArticle'
import AppLayout from '../AppLayout/AppLayout'
import MainPart from '../MainPart/MainPart'
import MyPageAdmin from 'pages/my/admin/MyPageAdmin/MyPageAdmin'
import MyPayments from 'pages/my/admin/myPaymentsAdmin/MyPayments/MyPayments'
import MySchoolsArticle from 'pages/my/admin/mySchoolsAdmin/MySchoolsArticle/MySchoolsArticle'
import MySchoolArticle from 'pages/my/admin/mySchoolAdmin/MySchoolArticle/MySchoolArticle'
import ProfilePage from 'pages/profile/ProfilePage/ProfilePage'
import MyPaymentArticle from 'pages/my/admin/myPaymentAdmin/MyPaymentArticle/MyPaymentArticle'
import TrainingsAdminPage from 'pages/trainings/admin/TrainingsAdminPage/TrainingsAdminPage'
import GroupPage from 'pages/group/groupPage/GroupPage/GroupPage'
import GroupsArticle from 'pages/training/admin/articles/groups/GroupsArticle/GroupsArticle'
import LessonAdminPage from 'pages/lesson/admin/page/LessonPage/LessonAdminPage'
import ConditionsArticle from 'pages/group/conditions/ConditionsArticle/ConditionsArticle'
import StudentProfileArticle from 'pages/student/profile/StudentProfileArticle/StudentProfileArticle'
import AdminStudentsPage from 'pages/students/admin/AdminStudentsPage/AdminStudentsPage'
import StudentOrdersArticle from 'pages/student/order/OrdersArticle/StudentOrdersArticle'
import StudentHistoryArticle from 'pages/student/history/StudentHistoryArticle/StudentHistoryArticle'
import StudentVisitsArticle from 'pages/student/visits/StudentVisitsArticle/StudentVisitsArticle'
import VisitArticle from 'pages/student/visit/TrainingStatsArticle/VisitArticle'
import GroupStaffArticle from '../../pages/group/groupStaff/StaffArticle/GroupStaffArticle'
import StaffPage from '../../pages/staff/StaffPage/StaffPage'
import EmployeePage from '../../pages/employee/main/EmployeeWrapArticle/EmployeePage'
import EmployeeGroupsArticle from '../../pages/employee/groups/EmployeeGroupsArticle/EmployeeGroupsArticle'
import EmployeeProfileArticle from '../../pages/employee/profile/EmployeeProfileArticle/EmployeeProfileArticle'
import LandingConstructorArticle from '../../pages/training/admin/articles/LandingConstructor/LandingConstructorArticle'
import LandingConstructorPage from 'pages/landing/landingConstructor/main/LandingConstructorPage/LandingConstructorPage'
import { LiveLessonPage } from 'pages/liveLessons/live/LiveLessonPage'
import TrainingAdminArticle from 'pages/training/admin/articles/TrainingArticleForAdmin/TrainingArticleForAdmin'
import LiveRoomsArticle from 'pages/liveLessons/rooms/LiveRoomsArticle/LiveRoomsArticle'
import ExpressCourse from 'widgets/expressCourse/Course/Course'
import ExpressLessons from 'widgets/expressCourse/components/Lessons/Lessons'
import ExpressAdminPage from 'pages/expressCourse/admin/ExpressAdminPage/ExpressAdminPage'
import FilesPage from 'pages/files/FilesPage'
import OpenLessonsArticle from 'pages/group/openLessons/OpenLessonsArticle/OpenLessonsArticle'
import CommentsPage from 'pages/comments/CommentsPage/CommentsPage'

export default function getAdminRouter(user: null | EntityTypes.User) {
	return (
		<Route>
			{getSuccessAndFailRoutes()}
			{getLandingRoute()}
			{getAppRouterWithLive(user)}
			{getLandingConstructorRoute(user)}
			{getLiveLessonEntranceRoute()}
		</Route>
	)
}

function getAppRouterWithLive(user: null | EntityTypes.User) {
	return (
		<Route>
			<Route
				path={AppUrls.LiveLesson(':' + RouterVarNames.LiveLessonId).url}
				element={
					<ProtectedRoute
						isAllowed={!!user}
						redirectPath={AppUrls.Login().url}
					>
						<LiveLessonPage />
					</ProtectedRoute>
				}
			/>
			{getAppRoutes(user)}
		</Route>
	)
}

function getAppRoutes(user: null | EntityTypes.User) {
	return (
		<Route
			element={
				<ProtectedRoute
					isAllowed={!!user}
					redirectPath={AppUrls.Login().url}
				>
					<AppLayout />
				</ProtectedRoute>
			}
		>
			<Route element={<MainPart />}>
				<Route element={<MyPageAdmin />}>
					<Route
						path={AppUrls.MySchools().url}
						element={<MySchoolsArticle />}
					/>
					<Route
						path={AppUrls.MyPayments().url}
						element={<MyPayments />}
					/>
					{/* <Route
						path={AppUrls.MySubscription().url}
						element={<MySubscriptionArticle />}
					/> */}
					<Route
						path={AppUrls.MyPaymentsHistory().url}
						element={<MyPaymentsHistoryArticle />}
					/>
				</Route>
				<Route
					path={AppUrls.MyExpressCourses().url}
					element={<ExpressAdminPage />}
				/>
				<Route
					path={
						AppUrls.MyExpressCourse(
							':' + RouterVarNames.ExpressCourseId
						).url
					}
					element={<ExpressCourse />}
				/>
				<Route
					path={
						AppUrls.MyExpressLesson(
							':' + RouterVarNames.ExpressCourseId,
							':' + RouterVarNames.ExpressLessonId
						).url
					}
					element={<ExpressLessons />}
				/>
				<Route
					path={AppUrls.MySchool(':' + RouterVarNames.SchoolId).url}
					element={<MySchoolArticle />}
				/>
				<Route
					path={
						AppUrls.MyPayment(
							':' + RouterVarNames.PaymentSystemName
						).url
					}
					element={<MyPaymentArticle />}
				/>
				<Route
					path={AppUrls.Profile().url + '/*'}
					element={<ProfilePage />}
				/>
				<Route
					path={AppUrls.Comments().url}
					element={<CommentsPage />}
				/>
				<Route
					path={AppUrls.Trainings().url}
					element={<TrainingsAdminPage />}
				/>
				<Route path={AppUrls.MyFiles().url} element={<FilesPage />} />
				<Route element={<LiveRoomsPagesWrapper />}>
					<Route
						path={AppUrls.LiveRooms().url}
						element={<LiveRoomsArticle />}
					/>
					<Route
						path={AppUrls.SchoolLessons.url}
						element={<SchoolLessonsAdminPage />}
					/>
					<Route
						path={
							AppUrls.SchoolLesson(':' + RouterVarNames.LessonId)
								.url
						}
						element={<SchoolLessonArticle />}
					/>
				</Route>

				<Route element={<TrainingStaffPage />}>
					<Route
						path={
							AppUrls.Training(':' + RouterVarNames.TrainingId)
								.url
						}
						element={<TrainingAdminArticle />}
					/>
					<Route
						path={
							AppUrls.Training_Groups(
								':' + RouterVarNames.TrainingId
							).url
						}
						element={<GroupsArticle />}
					/>
					<Route
						path={
							AppUrls.Training_LandingConstructor(
								':' + RouterVarNames.TrainingId
							).url
						}
						element={<LandingConstructorArticle />}
					/>
				</Route>
				<Route
					path={
						AppUrls.Lesson(
							':' + RouterVarNames.TrainingId,
							':' + RouterVarNames.LessonId
						).url
					}
					element={<LessonAdminPage />}
				/>
				<Route
					path={AppUrls.Students().url}
					element={<AdminStudentsPage />}
				/>
				<Route element={<StudentWrapArticle />}>
					<Route
						path={
							AppUrls.Student_Account(
								':' + RouterVarNames.StudentId
							).url
						}
						element={<StudentProfileArticle />}
					/>
					<Route
						path={
							AppUrls.Student_Orders(
								':' + RouterVarNames.StudentId
							).url
						}
						element={<StudentOrdersArticle />}
					/>
					<Route
						path={
							AppUrls.Student_Visits(
								':' + RouterVarNames.StudentId
							).url
						}
						element={<StudentVisitsArticle />}
					/>
					<Route
						path={
							AppUrls.Student_Visit(
								':' + RouterVarNames.StudentId,
								':' + RouterVarNames.GroupId
							).url
						}
						element={<VisitArticle />}
					/>
					<Route
						path={
							AppUrls.Student_History(
								':' + RouterVarNames.StudentId
							).url
						}
						element={<StudentHistoryArticle />}
					/>
				</Route>
				<Route element={<GroupPage />}>
					<Route
						path={
							AppUrls.Training_GroupConditions(
								':' + RouterVarNames.TrainingId,
								':' + RouterVarNames.GroupId
							).url
						}
						element={<ConditionsArticle />}
					/>
					<Route
						path={
							AppUrls.Training_GroupOpenLessons(
								':' + RouterVarNames.TrainingId,
								':' + RouterVarNames.GroupId
							).url
						}
						element={<OpenLessonsArticle />}
					/>
					<Route
						path={
							AppUrls.Training_GroupCurators(
								':' + RouterVarNames.TrainingId,
								':' + RouterVarNames.GroupId
							).url
						}
						element={<GroupStaffArticle />}
					/>
				</Route>
				<Route path={AppUrls.Staff().url} element={<StaffPage />} />
				<Route element={<EmployeePage />}>
					<Route
						path={
							AppUrls.Employee(':' + RouterVarNames.EmployeeId)
								.url
						}
						element={<EmployeeProfileArticle />}
					/>
					<Route
						path={
							AppUrls.Employee_Works(
								':' + RouterVarNames.EmployeeId
							).url
						}
						element={<EmployeeGroupsArticle />}
					/>
					<Route
						path={
							AppUrls.Employee_Rights(
								':' + RouterVarNames.EmployeeId
							).url
						}
						element={<p>Страница прав сотрудника</p>}
					/>
				</Route>
				<Route path={AppUrls.Leads().url} element={<LeadsPage />} />
				<Route path={AppUrls.Messages().url} element={<Messages />} />
				<Route
					path={AppUrls.AnswersFeed().url}
					element={<AnswersFeedPage />}
				/>
				<Route
					path={
						AppUrls.AnswersFeedGroup(':' + RouterVarNames.GroupId)
							.url
					}
					element={<AnswersFeedPage />}
				/>
				{/*<Route
					path={
						AppUrls.Exercise(
							':' + RouterVarNames.TrainingId,
							':' + RouterVarNames.LessonId,
							':' + RouterVarNames.ExerciseId
						).url
					}
					element={<ExerciseArticle />}
				/>*/}
				<Route
					path={AppUrls.Login().url}
					element={<Navigate to={AppUrls.Main().url} />}
				/>
				<Route path="*" element={<ErrorPage errorCode={404} />} />
			</Route>
		</Route>
	)
}

function getLandingConstructorRoute(user: null | EntityTypes.User) {
	return (
		<Route
			element={
				<ProtectedRoute
					isAllowed={!!user}
					redirectPath={AppUrls.Login().url}
				>
					<Outlet />
				</ProtectedRoute>
			}
		>
			<Route
				path={
					AppUrls.LandingConstructor(':' + RouterVarNames.TrainingId)
						.url
				}
				element={<LandingConstructorPage />}
			></Route>
			<Route
				path={
					AppUrls.Training_LandingConstructorPreview(
						':' + RouterVarNames.TrainingId
					).url
				}
				element={<LandingConstructorPreviewPage />}
			/>
		</Route>
	)
}
