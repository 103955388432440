import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import StaffArticle from '../StaffArticle/StaffArticle'
import StaffPageHeader from '../StaffPageHeader/StaffPageHeader'
import Footer from 'ui/Footer/Footer'

/** Страница со списком сотрудников */
function StaffPage() {
	const userRoles = useGetUserRole()

	return (
		<PageSkeleton>
			<StaffPageHeader />
			<StaffArticle />
			<Footer isRightSideHidden={!userRoles.isAdminOrManager} />
		</PageSkeleton>
	)
}

export default StaffPage
