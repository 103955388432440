import useLandingBuilderStore from '../../../../zustand/store'
import './Bullet.scss'

type BulletProps = {
	blockIdx: number
	bulletIdx: number
}

export function Bullet(props: BulletProps) {
	const { blockIdx, bulletIdx } = props

	const landing = useLandingBuilderStore((store) => store.landing)

	return (
		<article className="landing-prog-bullet">
			<div className="landing-prog-bullet__img-part">
				<img
					src={
						landing.sections.program.bulletsBlocks[blockIdx]
							.bullets[bulletIdx].imageUrl
					}
					className="landing-prog-bullet__img-part-img"
					alt=""
				/>
			</div>
			<p className="landing-prog-bullet__text">
				{
					landing.sections.program.bulletsBlocks[blockIdx].bullets[
						bulletIdx
					].text
				}
			</p>
		</article>
	)
}
