import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import { FormValuesType } from 'pages/landing/landingConstructor/details/ColorSchemeModal/fn/form'
import useColorSchemeModalStore from 'pages/landing/landingConstructor/details/ColorSchemeModal/zustand/store'

export function useGetOnSubmit() {
	const [updateMainStore, landing] = useLandingConstructorStore((state) => [
		state.updateStore,
		state.landing,
	])

	const updateModalState = useColorSchemeModalStore(
		(state) => state.updateStore
	)

	return async function (values: FormValuesType) {
		if (!landing) return

		const landingCopy = { ...landing }
		landingCopy.colorScheme = values.colorScheme
		updateMainStore({ landing: landingCopy })

		// Закрыть модальное окно
		updateModalState({ isOpen: false })
	}
}
