import $api from '../http'
import LessonsApiTypes from './lessonsApiTypes'

// Запросы к методам получения уроков школы
const lessonsRequests = {
	// Добавление нового урока (школы или курса)
	createLesson(data: LessonsApiTypes.CreateLessonDto) {
		return $api.post<LessonsApiTypes.CreateLesson>('/api/lessons', data)
	},

	// Изменение урока (школы или курса)
	updateLesson(data: LessonsApiTypes.UpdateLessonDto) {
		return $api.patch<LessonsApiTypes.UpdateLesson>('/api/lessons', data)
	},

	// Получение списка уроков школы
	getLessons() {
		return $api.get<LessonsApiTypes.GetLessons>('/api/lessons')
	},

	// Получение урока школы.
	getLesson(lessonId: number | string) {
		return $api.get<LessonsApiTypes.GetLesson>('/api/lessons/' + lessonId)
	},

	// получение уроков от курса
	getTrainingLessons(trainingId: number | string) {
		return $api.get<LessonsApiTypes.GetLessons>(
			'/api/lessons/training/' + trainingId
		)
	},

	// Удаление урока школы.
	deleteLesson(lessonId: number | string) {
		return $api.delete<LessonsApiTypes.DeleteLesson>(
			'/api/lessons/' + lessonId
		)
	},

	// Получение списка шаблонов предустановленных уроков
	getPresets() {
		return $api.get<LessonsApiTypes.GetPresets>('/api/lessons/presets')
	},

	// Добавление нового урока по шаблону
	applyPreset(dto: LessonsApiTypes.PresetApplyDto) {
		return $api.post<LessonsApiTypes.PresetApply>(
			'/api/lessons/preset/apply',
			dto
		)
	},

	// Удаление шаблона урока
	deletePreset(id: number) {
		return $api.delete<LessonsApiTypes.DeletePreset>(
			`/api/lessons/preset/${id}`
		)
	},

	// Назначение урока шаблоном
	makeLessonPreset(lessonId: number) {
		return $api.post<LessonsApiTypes.MakeLessonPreset>(
			`/api/lessons/${lessonId}/make-preset`
		)
	},

	// Сообщение серверу об открытии урока чтобы это учитывалось в статистике
	openLessonSignal(lessonId: number) {
		return $api.post<LessonsApiTypes.OpenLessonSignal>(
			'/api/lessons/open',
			{ lessonId }
		)
	},
}

export default lessonsRequests
