import { divideTo100 } from 'parts/utils/number'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'
import EntityTypes from 'parts/types/EntityTypes'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'

/** Переводит массив тарифов с сервера в данные для Состояния*/
export function convertServerDataToStateData(
	tariffs: EntityTypes.Tariff[],
	groupId: number
): TariffsStoreType.Tariff[] {
	return tariffs.map((servTariff, i) => {
		const tariff: TariffsStoreType.Tariff = {
			dbId: servTariff.id,
			innerId: i + 1,
			groupId,
			isFlashed: servTariff.isFlashed,
			header: servTariff.name,
			description: servTariff.description,
			theses: createTheses(servTariff.theses),
			mainPrice: createMainPrice(servTariff.prices),
			prices: createAdditionalPrices(servTariff.prices),
		}

		return tariff
	})
}

function createTheses(
	servTheses: EntityTypes.TariffThesis[]
): TariffsStoreType.TariffThesis[] {
	return servTheses.map((servThesis, i) => {
		return {
			id: i + 1,
			text: servThesis.text,
			crossedOut: !!servThesis.crossedOut,
		}
	})
}

function createMainPrice(
	servTariffs: EntityTypes.TariffPrice[]
): TariffsStoreType.TariffPrice {
	const mainPrice = servTariffs.find((tariff) => tariff.isMain)

	if (!mainPrice) {
		return tariffsManager.createNewAdditionalPrice(0)
	}

	return createPrice(mainPrice, 0, true)
}

function createPrice(
	servPrice: EntityTypes.TariffPrice,
	id: number,
	isMain: boolean
): TariffsStoreType.TariffPrice {
	return {
		id,
		isMain,
		text: servPrice.text,
		currency: servPrice.currency,
		price: divideTo100(servPrice.price).toString(),
		oldPrice: divideTo100(servPrice.oldPrice).toString(),
		paymentSystem: servPrice.paymentSystem,
		buttonText: servPrice.buttonText,
		period: servPrice.period,
	}
}

function createAdditionalPrices(
	servTariffs: EntityTypes.TariffPrice[]
): TariffsStoreType.TariffPrice[] {
	const servPrices = servTariffs.filter((tariff) => !tariff.isMain)

	return servPrices.map((price, i) => {
		return createPrice(price, i + 1, false)
	})
}
