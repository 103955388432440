import React, { MouseEventHandler } from 'react'
import { Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { ModalLayout } from 'ui/ModalLayout/ModalLayout'
import { addNewMatchBlock, setWrongWords, submitHandler } from './fn/submit'
import { DescriptionImgExerciseFormStateType } from './fn/formState'
import { MatchesBlocks } from './MatchesBlocks'
import ExerciseFormFooter from '../common/ExerciseFormFooter/ExerciseFormFooter'
import HtmlEditor from 'ui/HTMLEditor/HTMLEditor'
import WrongAnswers from '../common/WrongAnswers/WrongAnswers'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useSetFormData } from './fn/state'
import { isDownloadingFile } from './fn/check'
import { onChangeTaskInput } from './fn/changeForm'
import useExercisesListAdminStore from '../../zustand/store'
import './DescriptionImgForm.scss'

type TestFormProps = {
	saveExercise: (args: TrainingEntityTypes.ExerciseItem) => void // Функция сохраняющая данные упражнения на сервере и в Хранилище
	closeModalFn: MouseEventHandler<HTMLElement> // Функция закрытия модального окна
}

// Форма составления звукового-упражнения
function DescriptionImgForm(props: TestFormProps) {
	const { saveExercise, closeModalFn } = props

	const { t } = useTranslation()

	// Получение данных формы
	useSetFormData()

	const formState = useExercisesListAdminStore(
		(store) => store.formData
	) as DescriptionImgExerciseFormStateType
	if (!formState) return null

	return (
		<ModalLayout
			key="1"
			title="Сопоставление описания с изображением"
			cancelBtn={false}
			isModalVisible
			closeModalFn={closeModalFn}
			footer={null}
		>
			<TaskInput formState={formState} />
			<MatchesBlocks />
			<AddNewMatchButton />
			<WrongAnswers
				tags={formState.wrong}
				setTags={(newWrongWords) => setWrongWords(newWrongWords)}
			/>
			<ExerciseFormFooter
				formState={formState}
				saveExercise={saveExercise}
				closeModalFn={closeModalFn}
				isSubmitButtonDisabled={
					isDownloadingFile() || formState.isFormInvalid
				}
				submitHandler={() => submitHandler(saveExercise)}
			/>
		</ModalLayout>
	)
}

export default DescriptionImgForm

type TaskInputProps = {
	formState: DescriptionImgExerciseFormStateType
}

// Поле с текстом задания
function TaskInput(props: TaskInputProps) {
	const { formState } = props

	const { t } = useTranslation()

	return (
		<>
			<label className="exercise-description-img__label">
				Описание задания
			</label>
			<HtmlEditor
				initialValue={formState.taskInput.value}
				onChangeValue={onChangeTaskInput}
			/>
		</>
	)
}

// Кнопка добавления нового блока вопроса
function AddNewMatchButton() {
	const { t } = useTranslation()

	return (
		<Button
			className="exercise-description-img-add-question-button"
			onClick={() => addNewMatchBlock()}
		>
			<PlusCircleOutlined width={17} height={17} /> Добавить сопоставление
		</Button>
	)
}
