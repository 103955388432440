import { create } from 'zustand'
import { LandingBuilderStoreType } from 'pages/landing/landingBuilder/zustand/storeTypes'

const useLandingBuilderStore = create<LandingBuilderStoreType.State>((set) => {
	return {
		landing: undefined as any,
		tariffs: undefined as any,

		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useLandingBuilderStore
