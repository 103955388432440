import { create } from 'zustand'
import { LandingStoreType } from './storeTypes'

const useLandingStore = create<LandingStoreType.State>((set) => {
	return {
		dataLoadingStatus: 'loading',
		errorMessage: null,
		landing: undefined as any,
		tariffs: undefined as any,

		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useLandingStore
