import EntityTypes from 'parts/types/EntityTypes'
import usePayModalStore from '../../zustand/store'

export function useGetChangeModalVisibility(
	isVisible: boolean,
	tariff: null | EntityTypes.Tariff,
	price: null | EntityTypes.TariffPrice
) {
	const updateStore = usePayModalStore((state) => state.updateStore)

	return function () {
		updateStore({ isModalOpen: isVisible, tariff, price })
	}
}
