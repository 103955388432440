import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import { RouterVarNames } from 'app/routes/special/otherRoutes'
import UrlService from 'parts/services/UrlService'
import useLessonsStudentStore from '../store'
import { convertTrainingToLessonsData } from './convertTrainingToHeaderData'

/** Делает запрос на получение курса, формирует данные для Состояния и ставит в него */
export function useFillInStore() {
	const updateStore = useLessonsStudentStore((state) => state.updateStore)

	const params = useParams()
	const trainingIdStr = params[RouterVarNames.TrainingId]!
	const { courseId, groupId } =
		UrlService.getTrainingAndGroupIds(trainingIdStr)

	// Получить курс
	const { data } = trainingQuery
		.getTrainingForStudent(courseId, groupId)
		.useQuery({
			onError: queryError,
		})

	useEffect(
		function () {
			if (!data) return

			if (!data.data) {
				updateStore({
					dataLoadingStatus: 'noTraining',
				})

				return
			}

			const lessonsData = convertTrainingToLessonsData(data.data)

			updateStore({
				lessons: lessonsData,
				training: data.data,
				dataLoadingStatus: 'success',
			})
		},
		[data]
	)
}

/**
 * Функция запускаемая при ошибке запроса данных. Срабатывает для любых запросов.
 * Ставит в Состояние статус загрузки «Ошибка» и сообщение об этом показывается в интерфейсе.
 * @param err — объект ошибки.
 */
function queryError(err: unknown) {
	const { updateStore } = useLessonsStudentStore.getState()

	updateStore({ dataLoadingStatus: 'error' })
}
