import { ResultStatistic } from '../Result'

// Возвращает процент выполненности упражнений
export function getPercentage(
	stats: ResultStatistic[],
	totalQuestions: number
) {
	const passedQuestionsLastTime = stats[stats.length - 1].passedQuestions
	return Math.round((passedQuestionsLastTime / totalQuestions) * 100)
}
