import EntityTypes from 'parts/types/EntityTypes'

export const theses: EntityTypes.TariffThesis[] = [
	{ text: 'Срок обучения - 3 месяца', crossedOut: false },
	{ text: 'Опция включенная в тариф', crossedOut: false },
	{
		text: 'Опция не включенная в тариф, хорошо подходит для сравнения',
		crossedOut: false,
	},
]
