import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'

/** Возвращает обработчик создания новой дополнительной цены */
export function useGetCreatePrice() {
	const { activeTariffId, tariffs } = tariffsManager.store

	return function () {
		if (!activeTariffId) return

		tariffsManager.createAdditionalPriceAndSetToStore(
			tariffs,
			activeTariffId
		)

		// Сохранить на сервере при изменении
		tariffsManager.setNeedToSave()
	}
}
