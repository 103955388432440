import { PaymentSystemCommonDataType } from '../../myPaymentsAdmin/paymentSystems'
import PaymentTypes from 'parts/types/PaymentTypes'
import { CardConnectionType } from '../../myPaymentsAdmin/common'

export namespace StateType {
	export type State = {
		// Тип открытой платёжной системы
		paymentSystemType: null | PaymentTypes.System
		// Тип соединения с платёжной системой
		connectionType: CardConnectionType
		// Общие данные о платёжной системе
		paymentSystemCommonData: null | PaymentSystemCommonDataType
		// Данные для отрисовки формы.
		// Null, если данные ещё не пришли с сервера.
		// Затем будет поставлен тип данных в зависимости от открытой формы
		initialFormData: InitialFormData
		// Что будет делать кнопка отправки формы: сохранять введённые данные
		formSubmitType: 'save' | 'connect'
		// Модальное окно приостановки приёма платежей
		stopPaySystemModal: {
			// Открыто ли модальное окно
			isOpen: boolean
			// Отправляются ли данные модального окна
			isSubmitting: boolean
		}
	}

	export type InitialFormData = null | UKassaFormData | StripeFormData

	export type GeneralFormData = {
		// id данных с настройками платёжной системы в БД
		id: null | number
		// Подключена ли платёжная система
		isConnected: boolean
	}

	export type UKassaFormData = GeneralFormData & {
		type: PaymentTypes.System.UKassa
		fields: {
			shopId: {
				inputType: 'text'
				inputLabel: string
				initValue: string
				tooltip: string
				placeholder: string
			}
			secretKey: {
				inputType: 'text'
				inputLabel: string
				initValue: string
				tooltip: string
				placeholder: string
			}
		}
	}

	export type StripeFormData = GeneralFormData & {
		type: PaymentTypes.System.Stripe
		fields: {
			apiKey: {
				inputType: 'text'
				inputLabel: string
				initValue: string
				tooltip: string
				placeholder: string
			}
			webhookSecret: {
				inputType: 'text'
				inputLabel: string
				initValue: string
				tooltip: string
				placeholder: string
			}
		}
	}
}

export const emptyState: StateType.State = {
	paymentSystemType: null,
	connectionType: 'idle',
	paymentSystemCommonData: null,
	initialFormData: null,
	formSubmitType: 'save',
	stopPaySystemModal: {
		isOpen: false,
		isSubmitting: false,
	},
}
