import { Block } from '../TimeBlock/TimeBlock'
import timeBlocksManager from '../TimeBlocksManager/timeBlocksManager'
import './TimeBlocks.scss'

// Обёртка с двумя колонками куда помещаются блоки с описанием чему научится человек после обучения.
function TimeBlocks() {
	const leftBlocks = timeBlocksManager.useGetColumnBlocks(1)
	const rightBlocks = timeBlocksManager.useGetColumnBlocks(2)

	let blockIdx = -1

	return (
		<div className="landing-time-blocks">
			<div className="landing-time-blocks__column">
				{leftBlocks.map((block) => {
					blockIdx++

					return (
						<Block
							block={block}
							blockIdx={blockIdx}
							key={block.id}
						/>
					)
				})}
			</div>
			<div className="landing-time-blocks__column">
				{rightBlocks.map((block) => {
					blockIdx++

					return (
						<Block
							block={block}
							blockIdx={blockIdx}
							key={block.id}
						/>
					)
				})}
			</div>
		</div>
	)
}

export default TimeBlocks
