import { Col, Row, Tooltip } from 'antd'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { UCardBlock } from 'ui/wrappers/UCardBlock/UCardBlock'
import Buttons from '../Buttons/Buttons'
import CoverInput from '../CoverInput/CoverInput'
import Info from '../Info/Info'
import Menu from '../Menu/Menu'
import Texts from '../Texts/Texts'
import { useGetLessonPageUrl } from './fn/goToLessonPage'
import './Card.scss'

type EditLessonProps = {
	lessonIdx: number // Порядковый номер урока
	lesson: TrainingEntityTypes.LessonAdmin
}

// Карточка урока для просмотра администратором (с редактированием)
function Card(props: EditLessonProps) {
	const { lessonIdx, lesson } = props

	const lessonPageUrl = useGetLessonPageUrl(lesson.id)

	return (
		<Tooltip placement="top" title="Конструктор урока">
			<UCardBlock
				extraClass="edit-lesson-card"
				shadow
				hover
				href={lessonPageUrl}
			>
				<CoverInput lesson={lesson} />
				<div className="edit-lesson-card__content">
					<div>
						<Row justify="space-between">
							<Col>
								<Info lessonIdx={lessonIdx} lesson={lesson} />
							</Col>
							<Col>
								<Menu lesson={lesson} />
							</Col>
						</Row>
						<Texts lesson={lesson} />
					</div>
					<Buttons lessonIdx={lessonIdx} lesson={lesson} />
				</div>
			</UCardBlock>
		</Tooltip>
	)
}

export default Card
