import React from 'react'
import EditableText from 'pages/landing/landingConstructor/details/editableElems/EditableText/EditableText'
import { LandingTypes } from 'parts/types/LandingTypes'
import EditableImage from 'pages/landing/landingConstructor/details/editableElems/EditableImage/EditableImage'
import 'pages/landing/landingConstructor/details/SectionHeader/SectionHeader.scss'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import cn from 'classnames'

type SectionHeaderProps<
	A extends keyof LandingTypes.Landing,
	B extends keyof LandingTypes.Landing[A],
	C extends keyof LandingTypes.Landing[A][B],
	D extends keyof LandingTypes.Landing[A][B][C],
	E extends keyof LandingTypes.Landing[A][B][C][D],
	F extends keyof LandingTypes.Landing[A][B][C][D][E],
	G extends keyof LandingTypes.Landing[A][B][C][D][E][F]
> = {
	imagePropsPathArr: [A, B?, C?, D?, E?, F?, G?]
	headerPropsPathArr: [A, B?, C?, D?, E?, F?, G?]
	// Заголовок белым цветом?
	white?: true
}

// Заголовок секции одностраничника
function SectionHeader<
	A extends keyof LandingTypes.Landing,
	B extends keyof LandingTypes.Landing[A],
	C extends keyof LandingTypes.Landing[A][B],
	D extends keyof LandingTypes.Landing[A][B][C],
	E extends keyof LandingTypes.Landing[A][B][C][D],
	F extends keyof LandingTypes.Landing[A][B][C][D][E],
	G extends keyof LandingTypes.Landing[A][B][C][D][E][F]
>(props: SectionHeaderProps<A, B, C, D, E, F, G>) {
	const { imagePropsPathArr, headerPropsPathArr, white } = props

	const landing = useLandingConstructorStore((store) => store.landing)
	const { colorScheme } = landing

	const headerClasses = ['land-const-section-header__header']
	if (white) {
		headerClasses.push('land-const-section-header__header--white')
	} else {
		headerClasses.push('land-const-section-header__header--' + colorScheme)
	}

	return (
		<div className="land-const-section-header">
			<EditableImage
				propsPathArr={imagePropsPathArr}
				extraClass="land-const-section-header__image"
			/>
			<h2 className={cn(headerClasses)}>
				<EditableText
					propsPathArr={headerPropsPathArr}
					placeholder="Заголовок раздела"
				/>
			</h2>
		</div>
	)
}

export default SectionHeader
