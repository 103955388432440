import { message } from 'antd'
import { AxiosError } from 'axios'
import { useEffect } from 'react'
import { answerFeedQuery } from 'parts/requests/answerFeed/answerFeedQuery'
import ServerTypes from 'parts/types/ServerTypes'
import useMenuStore from '../../zustand/menuStore'

export function useGetUnansweredFeedCount() {
	const updateStore = useMenuStore((store) => store.updateStore)

	const { data: unansweredFeedCountRes } =
		answerFeedQuery.getUnansweredFeedCount.useQuery({
			onError: getMutateOnError,
		})

	useEffect(
		function () {
			if (!unansweredFeedCountRes) {
				return
			}

			updateStore({ unansweredFeedCount: unansweredFeedCountRes.data })
		},
		[unansweredFeedCountRes]
	)
}

/**
 * Функция, выполняемая при появлении ошибки после запроса на количество неотвеченных сообщений от ученика
 */
function getMutateOnError() {
	return function (err: unknown) {
		const error = err as AxiosError<ServerTypes.ErrorResponse>

		if (error.response) {
			message.error(
				error.response.data.message ||
					'Не удалось получить количество неотвеченных сообщений от ученика.'
			)
		}
	}
}
