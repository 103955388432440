import React from 'react'
import { Button } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import ExerciseStateType from './fn/ExerciseStateType'
import { useGetOpenDeleteModalFn } from './fn/deleteImage'
import ImageFrame from '../common/ImageFrame/ImageFrame'
import { Slot } from './Slot'

type ImagesProps = {
	state: ExerciseStateType.Main
}

// Блок с изображениями и их описания
export function ImagesWithSlots(props: ImagesProps) {
	const { state } = props

	return (
		<div className="exercise-desc-image-content__images">
			{state.images.map((imageObj, idx) => {
				const descObj = state.descriptions.find(
					(desc) => desc.id == imageObj.id
				)

				return (
					<ImageWithSlot
						state={state}
						imageObj={imageObj}
						descObj={descObj!}
						key={idx}
					/>
				)
			})}
		</div>
	)
}

type ExerciseImageProps = {
	state: ExerciseStateType.Main
	imageObj: ExerciseStateType.ImageData
	descObj: ExerciseStateType.DescriptionData
}

// Блок изображения с местом для перетаскивания описания
function ImageWithSlot(props: ExerciseImageProps) {
	const { state, imageObj, descObj } = props

	const openDeleteImageModal = useGetOpenDeleteModalFn(
		imageObj.imageSrc,
		state.exerciseId
	)

	return (
		<div className="exercise-desc-image-content__image-wrapper">
			<Button
				className="exercise-desc-image-content__close-btn"
				shape="circle"
				size="small"
				icon={<CloseCircleOutlined />}
				onClick={openDeleteImageModal}
			/>
			<ImageFrame src={imageObj.imageSrc} alt="Картинка из упражнения" />
			<Slot descObj={descObj} />
		</div>
	)
}
