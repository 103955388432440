import { MenuStoreType } from 'app/menu/zustand/menuStoreTypes'
import { create } from 'zustand'

const useMenuStore = create<MenuStoreType.State>((set) => {
	return {
		isMobileMenuOpen: false,
		unansweredFeedCount: 0,

		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useMenuStore
