import React, { ReactElement } from 'react'
import './AnswerWrapper.scss'

type AudioAnswerWrapperProps = {
	answerType: 'text' | 'audio' // Тип ответа: текстовый или аудио
	children: ReactElement
}

// Обёртка для блока с ответом
function AnswerWrapper(props: AudioAnswerWrapperProps) {
	const { answerType, children } = props

	let header = 'Письменный ответ'
	if (answerType == 'audio') header = 'Голосовой ответ'

	return (
		<div className="exercise-audio-answer-answer">
			<div className="exercise-audio-answer-answer__top">
				<h3 className="exercise-audio-answer-answer__header">
					{header}
				</h3>
				{answerType == 'audio' && (
					<p>
						Устно ответьте на задание. На проверку можно отправить
						только один вариант.
					</p>
				)}
			</div>

			{children}
		</div>
	)
}

export default AnswerWrapper
