import React, { useEffect, useState } from 'react'
import { animate } from 'parts/utils/animate'

export function getRingMetrics() {
	// Ширина или высота круга
	const radius = 70
	const diameter = radius * 2
	const lineWidth = 10

	return {
		radius,
		diameter,
		lineWidth,
		offset: radius + lineWidth / 2,
	}
}

export function useAnimateRing(diameter: number, percents: number) {
	const [strokeDasharray, setStrokeDasharray] = useState('0 0')

	useEffect(
		function () {
			createStrokeDasharray(diameter, percents, setStrokeDasharray)
		},
		[percents, diameter]
	)

	return strokeDasharray
}

function createStrokeDasharray(
	diameter: number,
	percents: number,
	setStrokeDasharray: React.Dispatch<React.SetStateAction<string>>
) {
	const fullLength = 3.1415 * diameter

	animate(
		(progress: number) => {
			let greenLineLength = (fullLength / 100) * percents * progress
			let gapLineLength = fullLength - greenLineLength

			setStrokeDasharray(greenLineLength + ' ' + gapLineLength)
		},
		() => 1,
		1000
	)
}
