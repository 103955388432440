import { useEffect } from 'react'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { EXERCISE_DONE } from '../../../common/exerciseConst'
import useExerciseStore, { ExerciseStateType } from '../exerciseState'

export function useFillStore(
	exercise: TrainingEntityTypes.DescriptionImgExercise
) {
	const updateStore = useExerciseStore((store) => store.updateStore)

	useEffect(
		function () {
			const images: ExerciseStateType.ImageData[] = []
			const descriptions: ExerciseStateType.DescriptionData[] = []

			const doneCounter =
				exercise.status ===
				TrainingEntityTypes.ExerciseProgressStatus.SUCCESS
					? EXERCISE_DONE
					: 0

			exercise.item.images.forEach((imageObj, i) => {
				const index = i + 1

				images.push({
					id: index,
					imageSrc: imageObj.image,
					givenDescId: null,
				})

				descriptions.push({
					id: index,
					text: imageObj.description,
					rightWord: imageObj.description,
				})
			})

			updateStore({ doneCounter, images, descriptions })
		},
		[exercise.id]
	)
}
