import $api from '../http'
import FileApiTypes from './fileApiTypes'

// Запросы к методам загрузки файлов
const fileRequests = {
	// Получение адреса для загрузки изображений курса
	getFilesUrl(dto: FileApiTypes.GetFilesUrlDto) {
		return $api.post<FileApiTypes.GetFilesUrl>('/api/files/s3sign', dto)
	},

	deleteFile(dto: FileApiTypes.DeleteFileDto) {
		return $api.post('/api/files/s3delete', dto)
	},
}

export default fileRequests
