import { FC } from 'react'
import { Alert } from 'antd'
import ReactPlayer from 'react-player'
import { LessonContentBlock, TextBlockType } from 'widgets/expressCourse/types'

import styles from './Lesson.module.scss'

interface ExpressLessonProps {
	name: string
	blocks: LessonContentBlock[]
}

const ExpressLesson: FC<ExpressLessonProps> = ({ name: title, blocks }) => {
	return (
		<div className={styles.ExpressLesson}>
			<div className={styles.ExpressLesson__Title}>{title}</div>
			{blocks.map((block, idx) => (
				<Block key={idx} block={block} />
			))}
		</div>
	)
}

interface BlockProps {
	block: LessonContentBlock
}

const Block: FC<BlockProps> = ({ block }) => {
	switch (block.blockType) {
		case TextBlockType.Text:
			return (
				<div className={styles.ExpressLesson__Text}>
					{block.content}
				</div>
			)
		case TextBlockType.Alert:
			return (
				<div className={styles.ExpressLesson__Alert}>
					<Alert message={block.content} type="warning" showIcon />
				</div>
			)
		case TextBlockType.SubTitle:
			return (
				<div className={styles.ExpressLesson__SubTitle}>
					{block.content}
				</div>
			)
		case TextBlockType.List:
			return (
				<ul className={styles.ExpressLesson__List}>
					{(block.content as string[]).map((item) => (
						<li>{item}</li>
					))}
				</ul>
			)
		case TextBlockType.Video:
			return (
				<div className={styles.ExpressLesson__Video}>
					<ReactPlayer
						className={styles.ExpressLesson__ReactPlayer}
						url={block.content}
						controls
						width="100%"
						height="100%"
					/>
				</div>
			)
		default:
			return null
	}
}

export default ExpressLesson
