import { useQueryClient } from 'react-query'
import { userQuery } from 'parts/requests/user/userQuery'
import { useGetStaffTableRouter } from './StaffTable/fn/getGroupsTableRouter'
import { getStaffParams } from './zustand/fillStore/fillInStore'

export const StaffTableController = {
	router: useGetStaffTableRouter,
	// Функция, помечающая данные таблицы сотрудников неактуальными. После этого React Query скачает новый массив сотрудников.
	useGetUpdate: function () {
		const queryClient = useQueryClient()

		return function () {
			// Пометить данные курса неактуальными. После этого React Query скачает обновлённые данные.
			queryClient.refetchQueries({
				queryKey: [userQuery.getUsers(getStaffParams).key],
			})
		}
	},
}
