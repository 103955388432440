export namespace LandingTypes {
	export type Landing = {
		// Цветовая схема одностраничника
		colorScheme: ColorScheme
		sections: Sections
	}

	export enum ColorScheme {
		ColorScheme_1 = 'colorScheme_1',
		ColorScheme_2 = 'colorScheme_2',
		ColorScheme_3 = 'colorScheme_3',
		ColorScheme_4 = 'colorScheme_4',
	}

	export type Sections = {
		main: {
			// Открыта ли секция
			isOpen: boolean
			texts: {
				// Текст выше заголовка (Аудио-курс по английскому языку)
				topHeader: string
				// Заголовок (Заговори на английском за 30 часов!)
				header: string
				// Описание ниже заголовка (Быстрый и интенсивный курс английского языка: заговори свободно и уверенно всего за 30 часов!)
				description: string
			}
			// Адрес картинки справа
			imageUrl: string
			// Текст на кнопке перехода к покупке
			buyBtnText: string
		}
		video: {
			// Открыта ли секция
			isOpen: boolean
			header: {
				// Картинка выше заголовка раздела
				imageUrl: string
				// Заголовок раздела
				text: string
			}
			texts: {
				// Описание раздела
				description: string
			}
			// Ссылка на страницу Ютуба с видео
			youtubeVideoUrl: string
			// Текст на кнопке перехода к покупке
			buyBtnText: string
		}
		portrait: {
			// Открыта ли секция
			isOpen: boolean
			header: {
				// Картинка выше заголовка раздела
				imageUrl: string
				// Заголовок раздела
				text: string
			}
			// Блоки с описанием ученика
			blocks: PortraitBlock[]
			// Текст на кнопке перехода к покупке
			buyBtnText: string
		}
		existence: {
			// Открыта ли секция
			isOpen: boolean
			header: {
				// Картинка выше заголовка раздела
				imageUrl: string
				// Заголовок раздела
				text: string
			}
			// Адрес картинки справа
			imageUrl: string
			// Блоки с описанием ученика
			blocks: ExistenceBlock[]
			// Текст на кнопке перехода к покупке
			buyBtnText: string
		}
		time: {
			// Открыта ли секция
			isOpen: boolean
			header: {
				// Картинка выше заголовка раздела
				imageUrl: string
				// Заголовок раздела
				text: string
			}
			// Адрес картинки слева
			imageUrl: string
			// Блоки с описанием чему научится ученик
			blocks: TimeBlock[]
			// Текст на кнопке перехода к покупке
			buyBtnText: string
		}
		program: {
			// Открыта ли секция
			isOpen: boolean
			header: {
				// Картинка выше заголовка раздела
				imageUrl: string
				// Заголовок раздела
				text: string
			}
			// Блоки с программой обучения
			blocks: ProgramBlock[]
			// Блоки ниже блоков с программой обучения
			bulletsBlocks: ProgramBulletsGroup[]
			// Текст на кнопке перехода к покупке
			buyBtnText: string
		}
		tariffs: {
			// Открыта ли секция
			isOpen: boolean
			header: {
				// Картинка выше заголовка раздела
				imageUrl: string
				// Заголовок раздела
				text: string
			}
			// Текст на кнопке перехода к покупке
			buyBtnText: string
		}
		ways: {
			// Открыта ли секция
			isOpen: boolean
			header: {
				// Заголовок раздела
				text: string
			}
			// Блок «Остаться на прежнем уровне»
			wrongWay: {
				// Заголовок
				header: string
				// Текст
				text: string
			}
			// Блок «Сделать шаг и пройти обучение»
			rightWay: {
				// Заголовок
				header: string
				// Текст
				text: string
			}
			// Текст на кнопке перехода к покупке
			buyBtnText: string
		}
		faq: {
			// Открыта ли секция
			isOpen: boolean
			header: {
				// Картинка выше заголовка раздела
				imageUrl: string
				// Заголовок раздела
				text: string
			}
			// Блоки с описанием ученика
			faqBlocks: FaqBlock[]
			// Текст на кнопке перехода к покупке
			buyBtnText: string
		}
	}

	// Блок с описанием ученика
	export type PortraitBlock = {
		id: number
		// Номер колонки, где находится этот блок
		columnNum: 1 | 2
		// Цветовая тема блока
		colorTheme: PortraitBlockColorTheme
		// Заголовок блока
		header: string
		// Текст блока
		text: string
		// Картинка блока
		imageUrl: string
	}

	export type PortraitBlockColorTheme = 'white' | 'color'

	// Блок с описанием кому подходит курс
	export type ExistenceBlock = {
		id: number
		// Номер колонки, где находится этот блок
		columnNum: 1 | 2
		// Цветовая тема блока
		colorTheme: ExistenceBlockColorTheme
		// Заголовок блока
		header: string
		// Текст блока
		text: string
		// Картинка блока
		imageUrl: string
	}

	export type ExistenceBlockColorTheme = 'green' | 'red'

	// Блок чему человек научится за время обучения
	export type TimeBlock = {
		id: number
		// Номер колонки, где находится этот блок
		columnNum: 1 | 2
		// Текст блока
		text: string
	}

	// Блок с пунктами программы обучения
	export type ProgramBlock = {
		id: number
		// Номер колонки, где находится этот блок
		columnNum: 1 | 2
		// Заголовок блока
		header: string
		// Текст блока
		text: string
		// Картинка блока
		imageUrl: string
	}

	export type ProgramBulletsGroup = {
		id: number
		// Заголовок блока
		header: string
		bullets: ProgramBullet[]
	}

	export type ProgramBullet = {
		id: number
		// Текст блока
		text: string
		// Картинка блока
		imageUrl: string
	}

	export type FaqBlock = {
		id: number
		// Вопрос
		question: string
		// Ответ
		answer: string
		// Открыт ли блок?
		isOpen: boolean
	}
}
