import React from 'react'
import './Footer.scss'

function Footer() {
	return (
		<div className="landing-footer">
			Сделано платформой{' '}
			<a href="pages/landing/landingBuilder/details/Footer/Footer">
				Credu
			</a>
		</div>
	)
}

export default Footer
