import { create } from 'zustand'
import { LessonStudentStoreType } from './storeTypes'

const useGroupStore = create<LessonStudentStoreType.State>((set) => {
	return {
		trainingAdmin: undefined,
		group: undefined,

		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useGroupStore
