import portraitBlocksManager from 'pages/landing/landingConstructor/sections/portraitSection/PortraitBlocksManager/PortraitBlocksManager'
import {
	Block,
	BlockOuter,
} from 'pages/landing/landingConstructor/sections/portraitSection/PortraitBlock/PortraitBlock'
import 'pages/landing/landingConstructor/sections/portraitSection/PortraitBlocks/PortraitBlocks.scss'

// Обёртка с двумя колонками куда помещаются блоки с описанием пользователя, которому подойдёт предлагаемый курс
function PortraitBlocks() {
	const leftBlocks = portraitBlocksManager.useGetColumnBlocks(1)
	const rightBlocks = portraitBlocksManager.useGetColumnBlocks(2)

	let blockIdx = -1

	return (
		<div className="land-const-portrait-blocks">
			<div className="land-const-portrait-blocks__column">
				{leftBlocks.map((block) => {
					blockIdx++

					return (
						<BlockOuter block={block} key={block.id}>
							<Block
								block={block}
								blockIdx={blockIdx}
								key={block.id}
							/>
						</BlockOuter>
					)
				})}
			</div>
			<div className="land-const-portrait-blocks__column">
				{rightBlocks.map((block) => {
					blockIdx++

					return (
						<BlockOuter block={block} key={block.id}>
							<Block
								block={block}
								blockIdx={blockIdx}
								key={block.id}
							/>
						</BlockOuter>
					)
				})}
			</div>
		</div>
	)
}

export default PortraitBlocks
