import { Form, Select } from 'antd'
import { useEffect } from 'react'
import { UFormNewRow } from 'ui/UForm/UForm'
import PaymentTypes from 'parts/types/PaymentTypes'
import { useGetOnSelectChange } from 'pages/landing/landingConstructor/tariffs/panel/payment/MainPaymentForm/fn/onChange'
import {
	FieldsNames,
	useGetInitialValues,
	useUpdateFormValuesAfterSwithToAnotherTariff,
} from 'pages/landing/landingConstructor/tariffs/panel/payment/MainPaymentForm/fn/form'

const { Option } = Select

// Форма настройки главной оплаты
function MainPaymentForm() {
	const [form] = Form.useForm()

	const initialValues = useGetInitialValues()
	useUpdateFormValuesAfterSwithToAnotherTariff(form)

	return (
		<Form
			form={form}
			name="payment-main"
			layout="vertical"
			initialValues={initialValues}
		>
			<CurrenciesInput />
			<PaySystemInput />
			<PeriodInput />
		</Form>
	)
}

export default MainPaymentForm

// Выпадающий список выбора валюты
function CurrenciesInput() {
	const onChange = useGetOnSelectChange(FieldsNames.Currency)

	return (
		<UFormNewRow>
			<Form.Item
				name="currency"
				label="Валюта"
				rules={[{ required: true }]}
			>
				<Select onChange={onChange}>
					{Object.values(PaymentTypes.Currencies).map((currency) => {
						return (
							<Option
								value={currency.currency}
								key={currency.currency}
							>
								{currency.currency} {currency.sign}
							</Option>
						)
					})}
				</Select>
			</Form.Item>
		</UFormNewRow>
	)
}

// Выпадающий список платёжной системы
function PaySystemInput() {
	const onChange = useGetOnSelectChange(FieldsNames.PaymentSystem)

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name="paymentSystem"
				label="Платёжная система"
				rules={[{ required: true }]}
			>
				<Select onChange={onChange}>
					{Object.values(PaymentTypes.Systems).map((system) => {
						return (
							<Option value={system.system} key={system.system}>
								{system.name}
							</Option>
						)
					})}
				</Select>
			</Form.Item>
		</UFormNewRow>
	)
}

// Выпадающий список за что идёт оплата: за период времени или за курс
function PeriodInput() {
	const onChange = useGetOnSelectChange(FieldsNames.Period)

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name="period"
				label="Период"
				rules={[{ required: true }]}
			>
				<Select onChange={onChange}>
					<Option
						value={PaymentTypes.PricePeriod.PerMonth}
						key={PaymentTypes.PricePeriod.PerMonth}
					>
						За месяц
					</Option>
					<Option
						value={PaymentTypes.PricePeriod.PerCourse}
						key={PaymentTypes.PricePeriod.PerCourse}
					>
						За курс
					</Option>
				</Select>
			</Form.Item>
		</UFormNewRow>
	)
}
