import { useCallback, useContext } from 'react'
import openAIRequest from 'parts/requests/openAI/openAIRequest'
import { StateContext, StateType } from './stateType'

/** Возвращает обработчик отправки формы генерирования текста по заданию */
export function useGetOnSubmit() {
	const { state, setState } = useContext(StateContext)

	return useCallback(
		function () {
			generateTextAndSetToState(state, setState)
		},
		[state]
	)
}

/**
 * Обработчик отправки формы генерирования текста по заданию
 * @param {Object} state — объект состояния компонента формы генерирования текста
 * @param {Function} setState — установщик нового объекта состояния компонента
 */
async function generateTextAndSetToState(
	state: StateType.State,
	setState: StateType.SetState
) {
	const { promptText } = state

	// Добавить в текст запроса пример формата,
	// в котором требуется получить сгенерированный текст
	const prompt = populatePrompt(state, promptText)

	// Поставить в Состояние, что идёт загрузка ответа
	const newState = { ...state }
	newState.lifeStatus = 'loading'
	setState(newState)

	try {
		const response = await openAIRequest.generate({
			prompt,
			model: 'text-davinci-003',
		})

		const newState2 = { ...newState }

		if (response.status == 201) {
			// Поменять жизненный статус на то, что пришёл успешный ответ.
			newState2.lifeStatus = 'success'
			newState2.error = null

			// Передать сгенерированный текст в верхний компонент для разбора
			state.getGeneratedText(response.data)
		} else {
			showWrongResponseError(state, setState)
		}

		setState(newState2)
	} catch (err) {
		showWrongResponseError(state, setState)
	}
}

/**
 * Получает текст запроса от пользователя и наполняет его примером формата отдаваемых данных
 * @param {Object} state — объект состояния компонента формы генерирования текста
 * @param {String} queryText — текст задания на генерирования текста
 */
function populatePrompt(state: StateType.State, queryText: string) {
	const { exerciseType } = state

	if (exerciseType == 'text') {
		return queryText
	} else if (exerciseType == 'test-single-answer') {
		return `${queryText}
		
1 вариант должен быть правильным. Данные в формате JSON. Пример:
[
	{
		question: 'What material is used to make walls?',
		answers: [
			{ 'answer': 'Glass', 'correct': true },
			{ 'answer': 'Water', 'correct': false },
			{ 'answer': 'Paint', 'correct': false },
		],
	},
	{
		question: 'What was the name of the main character in the movie Titanic?',
		answers: [
			{ 'answer': 'David', 'correct': false },
			{ 'answer': 'John', 'correct': false },
			{ 'answer': 'Jack', 'correct': true }
		]
	}
]`
	} else if (exerciseType == 'trueOrFalse') {
		return `${queryText}
		
Данные предоставь в формате JSON. Пример:
{
	"sentence": "Water is used to make walls."'",
	"correct": true
}`
	} else if (exerciseType == 'gapsInWords') {
		return `${queryText}
		
Правильный ответ поставь в фигурные скобки. Пример:
My family and I {visited} New York last summer. I {saw} a movie yesterday with my friends. She {bought} a new car last week.`
	} else if (exerciseType == 'chooseWord') {
		return `${queryText}
		
Пример:
My family and I {+visited, go, run} New York last summer. I {+saw, watch, cook} a movie yesterday with my friends. She {+bought, steal, close} a new car last week.`
	} else if (exerciseType == 'dropWords') {
		return `${queryText}
		
Пример:
My family and I {visited} New York last summer. I {saw} a movie yesterday with my friends. She {bought} a new car last week.`
	}

	return queryText
}

/**
 * Показывает в нижней части компонента сообщение об ошибке при отправке запроса
 * @param {Object} state — объект состояния компонента формы генерирования текста
 * @param {Function} setState — установщик нового объекта состояния компонента
 */
function showWrongResponseError(
	state: StateType.State,
	setState: StateType.SetState
) {
	const newState2 = { ...state }

	// Поменять жизненный статус на то, что пришёл неуспешный ответ.
	newState2.lifeStatus = 'error'
	newState2.error =
		'Произошла ошибка при отправке запроса. Попробуйте ещё раз или через некоторое время.'

	setState(newState2)
}
