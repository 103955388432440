import { Spin } from 'antd'
import React from 'react'
import './Loader.scss'

type LoaderProps = {
	text?: string
}

function Loader(props: LoaderProps) {
	const { text } = props

	return (
		<div className="loader">
			<Spin tip={text} />
		</div>
	)
}

export default Loader
