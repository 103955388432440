import { useMutation, useQuery } from 'react-query'
import { MutationOptions, QueryOptions } from '../common'
import ExercisesApiTypes from './exercisesApiTypes'
import exercisesRequests from './exercisesRequests'

export const exercisesQuery = {
	// Создание упражнения
	createExercise: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(exercisesRequests.createExercise, options)
		},
	},

	// Обновление упражнения
	updateExercise: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(exercisesRequests.updateExercise, options)
		},
	},

	// Получение упражнения
	getExercise(exerciseId: number | string) {
		return {
			key: 'getExercise-' + exerciseId,
			useQuery(
				options: QueryOptions<ExercisesApiTypes.GetExercise> = {}
			) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => exercisesRequests.getExercise(exerciseId),
					...options,
				})
			},
		}
	},

	// Удаление упражнения
	deleteExercise: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(exercisesRequests.deleteExercise, options)
		},
	},

	// Отправка на сервер данных какое упражнение было выполнено и сколько это заняло времени
	doneExercise: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(exercisesRequests.doneExercise, options)
		},
	},

	// Сохранение данных для восстановления изменений сделанных учеником в упражнении
	saveExerciseRestoreInfo: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(
				exercisesRequests.saveExerciseRestoreInfo,
				options
			)
		},
	},

	// Получение данных для восстановления изменений сделанных учеником в упражнении
	getExerciseRestoreInfo: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(
				exercisesRequests.getExerciseRestoreInfo,
				options
			)
		},
	},
}
