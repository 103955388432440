import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { produce } from 'immer'
import { paymentSystemsData } from '../../../myPaymentsAdmin/paymentSystems'
import { StateContext } from '../../state/stateContext'
import { StateType } from '../../state/stateType'
import PaymentTypes from 'parts/types/PaymentTypes'

/** Получение типа платёжной системы для помещения в Контекст */
export function useSetPaymentSystemTypeToCtx() {
	const { paymentSystemName } = useParams()
	const { setState } = useContext(StateContext)

	return useEffect(
		function () {
			if (paymentSystemName) {
				setState((state): StateType.State => {
					return produce(state, (draft) => {
						draft.paymentSystemType =
							paymentSystemName as PaymentTypes.System
					})
				})
			}
		},
		[paymentSystemName]
	)
}

/** Получение объекта с общими данными о платёжной системе и помещение в Контекст */
export function useSetPaymentCommonDataToCtx() {
	const { state, setState } = useContext(StateContext)

	return useEffect(
		function () {
			const { paymentSystemType } = state
			if (!paymentSystemType) return

			setState((state): StateType.State => {
				return produce(state, (draft) => {
					draft.paymentSystemCommonData =
						paymentSystemsData[paymentSystemType]
				})
			})
		},
		[state]
	)
}
