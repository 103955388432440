import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import Navbar from '../navbar/Navbar/Navbar'
import CommonModals from './modals/CommonModals'
import './AppLayout.scss'

// Обёртка страниц вошедшего пользователя
function AppLayout() {
	const { t } = useTranslation()

	return (
		<div className="home-layout">
			<Navbar />
			<Outlet />
			<CommonModals />
			{/*<StudentModals />*/}
		</div>
	)
}

export default AppLayout
