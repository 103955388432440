namespace PaymentTypes {
	export enum Currency {
		RUB = 'RUB',
		USD = 'USD',
		EUR = 'EUR',
		UAH = 'UAH',
		BYN = 'BYN',
		KZT = 'KZT',
	}

	// Объект для перебора всех валют
	export const Currencies = {
		[Currency.RUB]: {
			currency: Currency.RUB,
			sign: '₽',
		},
		[Currency.USD]: {
			currency: Currency.USD,
			sign: '$',
		},
		[Currency.EUR]: {
			currency: Currency.EUR,
			sign: '€',
		},
		[Currency.UAH]: {
			currency: Currency.UAH,
			sign: '₴',
		},
		[Currency.BYN]: {
			currency: Currency.BYN,
			sign: 'Br',
		},
		[Currency.KZT]: {
			currency: Currency.KZT,
			sign: '₸',
		},
	}

	export enum System {
		UKassa = 'yookassa',
		Stripe = 'stripe',
		PayPal = 'paypal',
		ByCard = 'bycard',
		Tinkoff = 'tinkoff',
		WesternUnion = 'western_union',
		MoneyGram = 'money_gram',
		QIWI = 'qiwi',
	}

	export enum PaymentInterval {
		Year = 'year',
		Month = 'month',
	}

	// Объект для перебора всех платёжных систем
	export const Systems = {
		[System.UKassa]: {
			system: System.UKassa,
			name: 'ЮКасса',
		},
		[System.Stripe]: {
			system: System.Stripe,
			name: 'Stripe',
		},
		/*[System.PayPal]: {
			system: System.PayPal,
			name: 'PayPal',
		},
		[System.ByCard]: {
			system: System.ByCard,
			name: 'ByCard',
		},
		[System.Tinkoff]: {
			system: System.Tinkoff,
			name: 'Tinkoff',
		},
		[System.WesternUnion]: {
			system: System.WesternUnion,
			name: 'WesternUnion',
		},
		[System.MoneyGram]: {
			system: System.MoneyGram,
			name: 'MoneyGram',
		},
		[System.QIWI]: {
			system: System.QIWI,
			name: 'QIWI',
		},*/
	}

	// Данные для проведения платежа по ЮКассе
	export type UKassa = {
		shopId: string
		secretKey: string
	}

	// Данные для проведения платежа по Stripe
	export type Stripe = {
		apiKey: string
		webhookSecret: string
	}

	// На какой период покупка
	export enum PricePeriod {
		// Покупается бессрочно
		PerCourse = 1,
		// Покупается на 1 месяц
		PerMonth = 2,
	}

	export const PricePeriods = {
		[PricePeriod.PerCourse]: {
			period: PricePeriod.PerCourse,
			name: 'Бессрочно',
		},
		[PricePeriod.PerMonth]: {
			period: PricePeriod.PerMonth,
			name: 'За месяц',
		},
	}

	export enum PaymentMode {
		Payment = 'payment',
		Subscription = 'subscription',
	}

	// Жизненный статус платежа
	export enum PaymentStatus {
		Paid = 'paid',
		Open = 'open',
	}

	export const PaymentStatuses = {
		[PaymentStatus.Paid]: {
			status: PaymentStatus.Paid,
		},
		[PaymentStatus.Open]: {
			status: PaymentStatus.Open,
		},
	}

	// Данные тарифного плана
	export type TariffPlan = {
		id: number
		name: string // 'Базовый'
		description: string // 'Подойдет для того, чтобы попробовать'
		// Тип значка, который показывается около тарифа
		icon: string
		// Дата, когда план был применён
		startDate: string // '2023-11-29T10:30:14.318Z'
		// Интервал плана, который был оплачен в последний раз: месяц или год
		interval: 'month' | 'year'
		// Строка типа «Экономия 120$». Может не быть.
		badge?: string
		prices: TariffPlanPrice[]
		// Что доступно на этом тарифе
		restrictions: TariffPlanRestrictions
		// Это план по умолчанию?
		isDefaultPlan: boolean
		isTrialPlan: boolean
		// Длительность пробного периода
		trialPeriod: number
		// Рекомендуемый тарифный план?
		isRecommended: false
		// Архивный тарифный план?
		isArchive: false
		// Платный ТП
		isPaid: boolean
		// Скрытый ТП
		isHidden: boolean
	}

	// Что доступно на этом тарифе
	export type TariffPlanRestrictions = {
		// Доступное количество школ
		schools: number
		// Доступное количество курсов. Если >= 1000000, то без ограничений.
		trainings: number
		// Количество новых учеников в месяц. Если 0, то недоступно. Если >= 1000000, то без ограничений.
		newStudentsInMonth: number
		// Максимальное количество менеджеров. Если 0, то недоступно. Если >= 1000000, то без ограничений.
		maxManagers: number
		// Максимальное количество кураторов. Если 0, то недоступно. Если >= 1000000, то без ограничений.
		maxCourators: number
		// Интеграция с платежными сервисами
		ownPayments: boolean
		// Место для файлов в гигибайтах
		storageInGb: number
		// Генерирование контента через искусственный интеллект
		aiGeneration: boolean
		// Конструктор одностраничников
		siteConstructor: boolean
		// Шаблоны уроков
		lessonTemplate: boolean
		// ЦРМ
		crm: boolean
		// Разделение на группы учеников
		groups: boolean
		// Модуль Live-уроки
		liveLessons: boolean
		// Сколько участников может быть на эфире
		liveMaxParticipants: number
		// Онлайн доска
		liveWhaiteboard: boolean
		// Сохранение истории доски
		liveStoreWhiteboard: boolean
		// Стикеры для досок
		liveWhiteboardStickers: boolean
		// Запись урока
		liveLessonRecord: boolean
		// Онлайн чат
		liveChat: boolean
		// Интеграция с YouTube
		liveYoutube: boolean
		// Поддержка мобильного приложения
		mobileApp: boolean
	}

	export type TariffPlanPrice = {
		interval: 'month' | 'year'
		amount: number // 99 euro = 9900
		currency: string
		paymentSystem: System
		stripePriceId?: string
	}
}

export default PaymentTypes
