import PaymentTypes from 'parts/types/PaymentTypes'
import useLeadsStore from '../../../../LeadsTable/zustand/store'
import { LeadsStoreType } from '../../../../LeadsTable/zustand/storeTypes'

/** Формирует из списка состояний данные для формирования пунктов каскадера */
export function useGetCascaderOptions() {
	return Object.values(PaymentTypes.Systems).map((status) => {
		return {
			label: status.name,
			value: status.system,
		}
	})
}

/** Обработчик изменения Каскадера. Новые значения записывает в Состояние */
export function useGetOnPaySystemChange() {
	const setSearchValuesProp = useLeadsStore(
		(store) => store.setSearchValuesProp
	)

	return function (selectedSystems: [PaymentTypes.System][]) {
		const preparedSystems = selectedSystems.map((arr) => arr[0])

		setSearchValuesProp(
			LeadsStoreType.SearchValueNames.PaySystems,
			preparedSystems
		)
	}
}
