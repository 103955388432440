import { Dispatch, SetStateAction } from 'react'
import { useGetGoToNextExercise } from '../../../common/prevAndNextExerciseUrls'
import { ExerciseStateType } from '../../VideoExerciseView/fn/stateType'

/**
 * Возвращает функцию, выполняемую после нажатия на кнопку «Выполнено» и отправки запроса.
 * Она переадресовывает на следующее упражнение если оно существует
 * И свойство doneAllow если
 */
export function useGetAfterDoneExercise(
	state: ExerciseStateType.Main,
	setState: Dispatch<SetStateAction<ExerciseStateType.Main>>
) {
	// Функция открывающая следующее упражнение если упражнение существует
	// или undefined если не существует.
	const goToNextExercise = useGetGoToNextExercise()

	return function () {
		setState({ ...state, doneAllow: false })

		if (goToNextExercise) {
			goToNextExercise()
		}
	}
}
