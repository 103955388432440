import { SyntheticEvent } from 'react'
import { message } from 'antd'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'

/** Возвращает обработчик нажатия на кнопку удаления тарифа */
export function useGetDeleteTariff(tariff: TariffsStoreType.Tariff) {
	return function (e: SyntheticEvent) {
		// Это требуется потому что происходит щелчок, который всплывает на элемент,
		// где есть обработчик, помечающий идентификатор карточки выделенным.
		// Той карточки, которая будет удалена.
		e.stopPropagation()

		tariffsManager.deleteTariffAndSetToStore(tariff.innerId)

		// Сохранить на сервере
		tariffsManager.setNeedToSave()

		message.success('Тариф удалён')
	}
}
