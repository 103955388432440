import { AnyEventObject } from 'xstate'
import { useGetGoToNextExercise } from '../../../common/prevAndNextExerciseUrls'

/**
 * Возвращает функцию, выполняемую после нажатия на кнопку «Выполнено» и отправки запроса.
 * Она переадресовывает на следующее упражнение если оно существует
 * И свойство doneAllow если
 */
export function useGetAfterDoneExercise(send: (event: AnyEventObject) => void) {
	// Функция открывающая следующее упражнение если упражнение существует
	// или undefined если не существует.
	const goToNextExercise = useGetGoToNextExercise()

	return function () {
		send({ type: 'toggleDoneBtn' })

		if (goToNextExercise) {
			goToNextExercise()
		}
	}
}
