import AsidePart from '../AsidePart/AsidePart'
import FaqBlocks from '../ExistenceBlocks/FaqBlocks'
import './FaqSection.scss'

// Секция одностраничника с частозадаваемыми вопросами
function FaqSection() {
	return (
		<div className="landing-faq-section">
			<AsidePart />
			<FaqBlocks />
		</div>
	)
}

export default FaqSection
