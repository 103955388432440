const staffRu = {
	// Кнопка добавления сотрудника
	addEmployeeBtn: 'Добавить',

	// ТАБЛИЦА СО СПИСКОМ СОТРУДНИКОВ
	// Заголовок ряда «Имя и фамилия»
	tableHCellName: 'Имя и фамилия',
	// Заголовок ряда «Должность»
	tableHCellRole: 'Должность',
	// Заголовок ряда «Телефон»
	tableHCellPhone: 'Телефон',
	// Заголовок ряда «Почта»
	tableHCellEmail: 'Почта',
	// Заголовок ряда «Группы»
	tableHCellGroups: 'Группы',
	// Заголовок ряда «Статус»
	tableHCellStatus: 'Статус',
}

export default staffRu
