import { useEffect, useState } from 'react'
import useLeadsStore from '../zustand/store'
import { paymentsQuery } from 'parts/requests/payments/paymentsQuery'
import { LeadsStoreType } from '../zustand/storeTypes'
import PaymentsApiTypes from 'parts/requests/payments/paymentsApiTypes'
import { dateStrToHumanDateAndTime, getStrDateFromDate } from 'parts/utils/time'

/** При монтировании компонента и при изменении объекта с фильтрами таблицы учеников делает запрос
 * на получение списка учеников, формирует данные для Состояния и ставит в него */
export function useFillInStore() {
	const [isFirstInvoke, setIsFirstInvoke] = useState(true)

	const [updateStore, searchValues] = useLeadsStore((state) => [
		state.updateStore,
		state.searchValues,
	])

	// Получить список покупок в зависимости от параметров
	const { data, refetch } = paymentsQuery.getPayments.useQuery(
		getQueryParams(searchValues, isFirstInvoke)
	)

	// При изменении данных подготовить их под формат используемый в таблице учеников и поставить
	useEffect(
		function () {
			if (isFirstInvoke) {
				setIsFirstInvoke(false)
				return
			}

			if (!data) return

			const preparedLeads = convertRawDataToTableData(data.data)

			updateStore({
				leads: preparedLeads,
			})
		},
		[data]
	)

	useEffect(
		function () {
			// Сделать новый запрос при изменении объекта с фильтрами таблицы
			refetch()
		},
		[searchValues]
	)
}

function getQueryParams(
	searchValues: LeadsStoreType.SearchValues,
	firstInvoke: boolean
): PaymentsApiTypes.GetPaymentsParams {
	const ValueNames = LeadsStoreType.SearchValueNames

	let fromDate = '',
		toDate = ''

	if (
		!firstInvoke &&
		searchValues[ValueNames.DateFrom] &&
		searchValues[ValueNames.DateTo]
	) {
		fromDate = convertMomentDateToStandardDate(
			searchValues[ValueNames.DateFrom]!.toDate()
		)

		toDate = convertMomentDateToStandardDate(
			searchValues[ValueNames.DateTo]!.toDate()
		)
	}

	return {
		filter: {
			email: searchValues[ValueNames.Email],
			fromDate,
			toDate,
			phone: searchValues[ValueNames.Phone],
			paymentStatus: searchValues[ValueNames.Statuses],
			paymentSystem: searchValues[ValueNames.PaySystems],
			priceFrom: searchValues[ValueNames.PriceFrom],
			priceTo: searchValues[ValueNames.PriceTo],
		},
	}
}

function convertMomentDateToStandardDate(date: null | Date | string) {
	if (!date) return ''

	const dateObj = typeof date == 'string' ? new Date(date) : date
	return getStrDateFromDate(dateObj)
}

/**
 * Переводит данные присланные с сервера в данные для Состояния для отрисовки рядов таблицы
 * @param {Array} rawPayments — массив покупок с сервера
 */
function convertRawDataToTableData(
	rawPayments: PaymentsApiTypes.GetPayments
): LeadsStoreType.Lead[] {
	return rawPayments.map((rawPayment) => {
		return {
			id: rawPayment.id,
			created: dateStrToHumanDateAndTime(rawPayment.paymentDate),
			email: rawPayment.email,
			phone: rawPayment.phone,
			price: rawPayment.amountTotal + ' ' + rawPayment.currency,
			trainingName: rawPayment.training,
			paymentStatus: rawPayment.status,
			paymentSystem: rawPayment.paymentSystem,
		}
	})
}
