import { Button, Cascader, Checkbox, Form, Input, Select } from 'antd'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'
import RestrictionsService from '../../../../../parts/services/RestrictionsService'
import { AddEmployeeModalController } from '../AddEmployeeModalController'
import { FieldNames, setErrorsToForm } from './fn/form'
import {
	UFormNewBottomButtonsAfterGray,
	UFormNewButtonsContainer,
	UFormNewDivider,
	UFormNewGeneralError,
	UFormNewRow,
} from 'ui/UForm/UForm'
import Site from 'parts/constants/site'
import { useGetAddEmployee } from './fn/submit'
import { fillInStore } from './zustand/fn/fillInStore'
import useAddEmployeeModalStore from './zustand/store'
import {
	getEmailInputSuffix,
	getEmailInputValidator,
	useCheckIsEmailExists,
} from 'parts/utils/hooks/useManageSignInEmailInput'
import EntityTypes from 'parts/types/EntityTypes'

const { Option } = Select

// Модальное окно добавления нового ученика
function AddEmployeeForm() {
	fillInStore()

	const [form] = Form.useForm()

	const submit = useGetAddEmployee(form)
	setErrorsToForm(form)

	const modalState = useAddEmployeeModalStore((state) => state)

	return (
		<Form
			form={form}
			name="addEmployee"
			layout="vertical"
			onFinish={submit}
		>
			<GrayBlock>
				<EmailAndRoleInputs />
				<AddToGroupInput />
				<NameAndSurnameInputs />
				<UFormNewDivider />
				<GetAccessToAccountInput />
			</GrayBlock>
			<Bottom />
			<UFormNewGeneralError message={modalState.formErrors.message} />
		</Form>
	)
}

export default AddEmployeeForm

// Поля с почтой и выбором роли
function EmailAndRoleInputs() {
	const { onChangeEmailInput, emailCheckStatus } = useCheckIsEmailExists()
	const EmailInputSuffix = getEmailInputSuffix(emailCheckStatus)

	const canCreateNewManager = RestrictionsService.useCanCreateNewManager()
	const canCreateNewCurator = RestrictionsService.useCanCreateNewCurator()

	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.Email}
				label="Почта"
				rules={[
					{
						required: true,
						message: 'Укажите почту',
					},
					getEmailInputValidator(emailCheckStatus),
				]}
			>
				<Input
					type="email"
					placeholder={Site.exampleEmail}
					suffix={<EmailInputSuffix />}
					onChange={onChangeEmailInput}
				/>
			</Form.Item>
			<Form.Item
				name={FieldNames.Role}
				label="Роль"
				rules={[
					{
						required: true,
						message: 'Укажите роль',
					},
				]}
			>
				<Select
					showSearch
					placeholder="Не выбрана"
					optionFilterProp="children"
				>
					{canCreateNewManager && (
						<Option
							value={EntityTypes.UserRole.CURATOR}
							key={EntityTypes.UserRole.CURATOR}
						>
							Куратор
						</Option>
					)}
					{canCreateNewCurator && (
						<Option
							value={EntityTypes.UserRole.MANAGER}
							key={EntityTypes.UserRole.MANAGER}
						>
							Менеджер
						</Option>
					)}
				</Select>
			</Form.Item>
		</UFormNewRow>
	)
}

function AddToGroupInput() {
	const trainingOptions = useAddEmployeeModalStore(
		(state) => state.trainingOptions
	)

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.AddToGroups}
				label="Добавление в группу"
			>
				<Cascader
					style={{ width: '100%' }}
					options={trainingOptions}
					multiple
					maxTagCount="responsive"
				/>
			</Form.Item>
		</UFormNewRow>
	)
}

// Поля с почтой и телефоном
function NameAndSurnameInputs() {
	return (
		<UFormNewRow topOffset>
			<Form.Item name={FieldNames.Name} label="Имя">
				<Input />
			</Form.Item>
			<Form.Item name={FieldNames.Surname} label="Фамилия">
				<Input />
			</Form.Item>
		</UFormNewRow>
	)
}

// Флаг Выдать доступ в личный кабинет
function GetAccessToAccountInput() {
	return (
		<UFormNewRow>
			<Form.Item name={FieldNames.GetAccess} valuePropName="checked">
				<Checkbox>Выдать доступ в личный кабинет</Checkbox>
			</Form.Item>
		</UFormNewRow>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
// Кнопки подтверждения и закрытия окна
function Bottom() {
	const isSubmitting = useAddEmployeeModalStore((state) => state.isSubmitting)

	return (
		<UFormNewBottomButtonsAfterGray>
			<div />
			<UFormNewButtonsContainer>
				<Button onClick={AddEmployeeModalController.close}>
					Отмена
				</Button>
				<Button
					type="primary"
					htmlType="submit"
					disabled={isSubmitting}
					loading={isSubmitting}
				>
					Сохранить
				</Button>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
