import SectionContent from 'pages/landing/landingBuilder/sections/timeSection/SectionContent/SectionContent'
import useLandingBuilderStore from 'pages/landing/landingBuilder/zustand/store'
import SectionHeader from '../../../details/SectionHeader/SectionHeader'
import TakeAPart from '../../../details/TakeAPart/TakeAPart'
import SectionWrapper from '../../../details/SectionWrapper/SectionWrapper'

// Секция одностраничника с рассказом чему обучится ученик за курс
function TimeSection() {
	const landing = useLandingBuilderStore((store) => store.landing)

	return (
		<SectionWrapper>
			<SectionHeader
				imageSrc={landing.sections.time.header.imageUrl}
				text={landing.sections.time.header.text}
			/>
			<div>
				<SectionContent />
				<TakeAPart sectionName="time" />
			</div>
		</SectionWrapper>
	)
}

export default TimeSection
