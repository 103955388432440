import React from 'react'
import { ExerciseType } from '../../GapsInTextForm/fn/stateType'

/**
 * Функция формирует разметку подсвечивающую слова в фигурных скобках
 * @param {Object} textAreaRef — объект со ссылкой на текстовую область с подсвечивающими прямоугольниками
 * @param {String} htmlString — HTML-контент из редактора текста
 * @param {String} exerciseType — тип предложения для которого создаётся подсветка
 */
export function useCreateMarkupAndSetToTextArea(
	textAreaRef: React.MutableRefObject<HTMLDivElement | null>,
	htmlString: string,
	exerciseType: ExerciseType
) {
	const $textArea = textAreaRef.current
	if (!$textArea) return

	$textArea.innerHTML = wrapBracesByCoordsSpan(htmlString, exerciseType)
}

/**
 * Функция получает строку HTML из редактора и формирует новую строку где есть подсвечивающие <span>-ы
 * @param {String} htmlString — HTML-контент из редактора текста
 * @param {String} exerciseType — тип предложения для которого создаются подсвечивающие прямоугольники
 */
function wrapBracesByCoordsSpan(
	htmlString: string,
	exerciseType: ExerciseType
) {
	let newHtmlString = htmlString

	// Можно ли вставлять закрывающий подсвечивающий </span>
	// Это защита на случай если закрывающая фигурная скобка стоит раньше открывающей
	let isClosingFlashSpanAllowed = false

	for (let i = 0; i < newHtmlString.length; i++) {
		const letter = newHtmlString[i]

		if (letter == '{') {
			// Проверить есть ли далее закрывающая фигурная скобка чтобы не ставить подсвечивающий <span> если её нет.
			const isCloseBraceExist = isCloseBraceAhead(newHtmlString, i + 1)
			if (!isCloseBraceExist) continue

			// Поставить флаг, что доступна установка закрывающего подсвечивающего <span>
			isClosingFlashSpanAllowed = true

			// Создать открывающий тег подсвечивающего элемента
			const textInBraces = getTextInBraces(newHtmlString, i + 1)
			const flashElemColor = getFlashElemColor(textInBraces, exerciseType)
			const spanStr = createFlashElem('open', flashElemColor)

			newHtmlString =
				newHtmlString.substring(0, i) +
				spanStr +
				newHtmlString.substring(i)

			// Увеличить счётчик на размер вставляемого слова чтобы не делать лишние проходы цикла
			i = i + spanStr.length
		} else if (letter == '}' && isClosingFlashSpanAllowed) {
			const spanStr = createFlashElem('close', 'ok')

			newHtmlString =
				newHtmlString.substring(0, i + 1) +
				spanStr +
				newHtmlString.substring(i + 1)

			// Увеличить счётчик на размер вставляемого слова чтобы не делать лишние проходы цикла
			i = i + spanStr.length
			isClosingFlashSpanAllowed = false
		}
	}

	return newHtmlString
}

/**
 * Функция проходит по строке и проверяет есть ли далее закрывающая фигурная скобка
 * @param {String} htmlString — HTML-контент из редактора текста
 * @param {Number} startLetterIdx — с какого символа начать перебор
 */
function isCloseBraceAhead(htmlString: string, startLetterIdx: number) {
	for (let i = startLetterIdx; i < htmlString.length; i++) {
		const letter = htmlString[i]

		if (letter == '{') {
			return false
		} else if (letter == '}') {
			return true
		}
	}
}

/**
 * Функция возвращает текст в фигурных скобках
 * @param {String} string — исходная строка где есть открывающие и закрывающие фигурные скобки
 * @param {Number} startLetterIdx — с какой буквы начать проход букв
 */
function getTextInBraces(string: string, startLetterIdx: number) {
	let textInBraces = ''

	for (let i = startLetterIdx; i < string.length; i++) {
		const letter = string[i]

		// Завершить проход если достигли закрывающей фигурной скобки и возвратить получившееся слово
		if (letter == '}') {
			return textInBraces
		}

		textInBraces += letter
	}

	return textInBraces
}

/**
 * Функция возвращает строку с кодом открывающего или закрывающего подсвечивающего <span>-а.
 * @param {String} tagType — требуется отрывающий или закрывающий тег.
 * @param {String} color — цвет тега: success (информация в элементе заполнена правильно), warning (есть ошибка)
 */
function createFlashElem(tagType: 'open' | 'close', color: 'ok' | 'warning') {
	return tagType == 'open'
		? `<span class="gaps-flasher__flash gaps-flasher__flash--${color}">`
		: '</span>'
}

/**
 * Функция возвращает слово по которому понятно в какой цвет красить подсвечивающий прямоугольник
 * @param {String} textInBraces — текст в фигурных скобках
 * @param {String} exerciseType — тип предложения для которого создаются подсвечивающие прямоугольники
 */
function getFlashElemColor(
	textInBraces: string,
	exerciseType: ExerciseType
): 'ok' | 'warning' {
	if (exerciseType == 'gapsInWords') {
		return 'ok'
	}

	return isTextCorrectForChooseWordGap(textInBraces) ? 'ok' : 'warning'
}

/**
 * Функция запускается для проверки текста в фигурных скобках для упражнения на выбор правильного слова.
 * Этот текст должен иметь вид типа 'слово,+слова, словечко'. То есть должно быть несколько слов разделённых запятыми.
 * Около слова, которое считается правильным должен стоять знак «+». Правильное слово может быть только одно.
 * Функция проверяет чтобы текст в фигурных скобках соответствовал этим правилам.
 * @param {String} textInBraces — текст в фигурных скобках
 */
export function isTextCorrectForChooseWordGap(textInBraces: string): boolean {
	const words = textInBraces?.split(',')

	if (words.length == 1) {
		return false
	}

	// Количество слов помеченных правильными
	let correctWordsCount = 0

	for (let i = 0; i < words.length; i++) {
		const word = words[i].trim()

		if (word.startsWith('+')) {
			correctWordsCount++
		}
	}

	return correctWordsCount == 1
}
