import { create } from 'zustand'
import { ExercisesAsideStoreType } from './storeTypes'

const useExercisesAsideStore = create<ExercisesAsideStoreType.State>((set) => {
	return {
		isPanelOpen: false,

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useExercisesAsideStore
