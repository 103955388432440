import { create } from 'zustand'
import { StudentStoreType } from './storeTypes'

const useStudentStore = create<StudentStoreType.State>((set) => {
	return {
		student: undefined,

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useStudentStore
