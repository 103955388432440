import React from 'react'
import { useTranslation } from 'react-i18next'
import AuthPage from '../../../common/AuthPage/AuthPage'
import ContentWrapper from '../../../common/ContentWrapper/ContentWrapper'
import EmailForm from '../EmailForm/EmailForm'

// Страница с формой запроса на удаление аккаунта
function DeleteAccountPage() {
	const { t } = useTranslation()

	return (
		<AuthPage>
			<ContentWrapper
				header={t('auth.deleteAccReqPageHeader')}
				headerDesc={t('auth.deleteAccReqPageDescription')}
			>
				<EmailForm />
			</ContentWrapper>
		</AuthPage>
	)
}

export default DeleteAccountPage
