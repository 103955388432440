import { useMutation, useQuery } from 'react-query'
import AnswerFeedTypes from './answerFeedApiTypes'
import answerFeedRequests from './answerFeedRequests'
import { MutationOptions, QueryOptions } from '../common'

export const answerFeedQuery = {
	// Получение ленты всех ответов
	getAllAnswersFeed(params: AnswerFeedTypes.GetAnswerFeedParams = {}) {
		return {
			key: 'getAllAnswerFeed-' + JSON.stringify(params),
			useQuery(
				options: QueryOptions<AnswerFeedTypes.GetAnswerFeed> = {}
			) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => answerFeedRequests.getAllAnswersFeed(params),
					...options,
				})
			},
		}
	},

	// Получение ленты ответов конкретной группы
	getGroupAnswersFeed(
		groupId: number,
		params: AnswerFeedTypes.GetAnswerFeedParams = {}
	) {
		return {
			key:
				'getGroupAnswersFeed-' + groupId + '-' + JSON.stringify(params),
			useQuery(
				options: QueryOptions<AnswerFeedTypes.GetAnswerFeed> = {}
			) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () =>
						answerFeedRequests.getGroupAnswersFeed(groupId, params),
					...options,
				})
			},
		}
	},

	answerFeedAddReply: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(answerFeedRequests.answerFeedAddReply, options)
		},
	},

	// Удаление
	deleteReply: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(answerFeedRequests.deleteReply, options)
		},
	},

	// Получение счётчика непроверенных ответов
	getUnansweredFeedCount: {
		key: 'getUnansweredFeedCount',
		useQuery(
			options: QueryOptions<AnswerFeedTypes.GetUnansweredFeedCount> = {}
		) {
			return useQuery({
				queryKey: [this.key],
				queryFn: answerFeedRequests.getUnansweredFeedCount,
				...options,
			})
		},
	},
}
