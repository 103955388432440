import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import DatesRange from 'ui/DatesRange/DatesRange'
import MetaOutputText from 'ui/MetaOutputText/MetaOutputText'
import { ClockCircleTwoTone } from '@ant-design/icons'
import Progress from 'ui/Progress/Progress'
import { HeaderRouteType } from 'ui/pageHeader/PageHeader/fn/types'
import AppUrls from 'parts/constants/pageUrl'
import useTrainingHeaderStudentStore from '../../zustand/store'

/** Возвращает объект настройки компонента с серой текстом с деталями группы в шапке страницы */
export function useGetMetaPanelConfig(): ReactNode[] {
	const { t } = useTranslation()
	const header = useTrainingHeaderStudentStore((state) => state.header)

	const config: JSX.Element[] = []

	if (header.groupStartDate && header.groupEndDate) {
		config.push(
			<DatesRange
				dateFrom={header.groupStartDate}
				dateTo={header.groupEndDate}
				colored
			/>
		)
	}

	if (header.nextLessonDate) {
		config.push(
			<MetaOutputText
				icon={ClockCircleTwoTone}
				textConfigs={[
					{
						text: t(
							'studentTraining.trainingHeaderNextLessonPhrase'
						),
					},
					{
						text: header.nextLessonDate || '',
						strong: true,
					},
				]}
			/>
		)
	}

	config.push(
		<>
			{header.leftToExecute && (
				<MetaOutputText
					textConfigs={[
						{
							text: t(
								'studentTraining.trainingHeaderRestLessonsNumPhrase'
							),
						},
						{
							text: header.leftToExecute,
							strong: true,
						},
					]}
				/>
			)}
			<Progress percent={header.totalProgress} />
		</>
	)

	return config
}

export const breadcrumbs: HeaderRouteType[] = [
	{
		path: AppUrls.Trainings().url,
		breadcrumbName: AppUrls.Trainings().name,
	},
]
