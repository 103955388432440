import { useCallback, useEffect, useState } from 'react'
import UrlService from 'parts/services/UrlService'
import { SaveExerciseChangesMadeByUser } from '../../common/useLiftViewDuration'

// Тип объекта, в котором будут храниться данные для восстановления текста, написанного учеником в дырках.
type BlocksGapsRestore = {
	// Текстовый блок — это массив строк.
	// Поэтому текстовые блоки — это массивы массивов строк
	textBlocks: string[][]
}

/**
 * Возвращает обработчик потерей фокуса обёртки текстовых блоков в упражнении.
 * Срабатывает при потере фокуса одного из редактируемых элементов.
 * После формируется объект с введённым текстом в редактируемые элементы и сохраняется на сервере
 * чтобы при открытии упражнения в следующий раз в редактируемые элементы был вставлен введённый текст.
 */
export function useGetBlurContentHandler(
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
) {
	const exerciseId = UrlService.useGetExerciseId()

	return useCallback(
		function (e: any) {
			if (!saveExerciseChangesMadeByUser) return

			const blocksGapsRestoreObj: BlocksGapsRestore = {
				textBlocks: [],
			}

			// Обёртка с текстовыми блоками с элементами куда ученик вписывает текст
			const $textBlocksWrapper = e.target.closest(
				'.exercise-gaps-content__text-blocks'
			)

			for (let i = 0; i < $textBlocksWrapper.children.length; i++) {
				// Блок текста с дырками
				const $textBlock = $textBlocksWrapper.children[i]

				// Элементы, в которые можно вписать текст
				const $gaps = $textBlock.querySelectorAll(
					'.exercise-gaps-content__gap'
				)

				for (let j = 0; j < $gaps.length; j++) {
					if (!blocksGapsRestoreObj.textBlocks[i]) {
						blocksGapsRestoreObj.textBlocks.push([])
					}

					blocksGapsRestoreObj.textBlocks[i].push($gaps[j].innerText)
				}
			}

			saveExerciseChangesMadeByUser(blocksGapsRestoreObj)
		},
		[exerciseId]
	)
}

/**
 * Получает данные для восстановления текста, который ученик написал в текстовом редакторе ответа в прошлый раз и возвращает их.
 * @param $textBlocksWrapper — обёртка текстовых блоков с редактируемыми дырками.
 * @param exerciseRestoreData — данные для восстановления упражнения.
 */
export function useRestoreGapTextsInTextBlockOnMount(
	$textBlocksWrapper: null | HTMLDivElement,
	exerciseRestoreData?: Promise<BlocksGapsRestore>
) {
	const exerciseId = UrlService.useGetExerciseId()
	const [gapTexts, setGapTexts] = useState<string[][]>([])

	useEffect(
		function () {
			if (!exerciseRestoreData) return

			exerciseRestoreData.then((data) => {
				if (!data?.textBlocks) return

				setGapTexts(data.textBlocks)
			})
		},
		[exerciseId]
	)

	useEffect(
		function () {
			if (!gapTexts.length || !$textBlocksWrapper) return

			for (let i = 0; i < $textBlocksWrapper.children.length; i++) {
				// Блок текста с дырками
				const $textBlock = $textBlocksWrapper.children[i]

				// Элементы, в которые можно вписать текст
				const $gaps = $textBlock.querySelectorAll(
					'.exercise-gaps-content__gap'
				)

				// Перебрать элементы дырок
				for (let j = 0; j < $gaps.length; j++) {
					// Пропустить если в данных нет текста для этой дырки
					if (!gapTexts[i] || !gapTexts[i][j]) continue

					// Поставить в дырку сохранённый текст
					$gaps[j].textContent = gapTexts[i][j]
				}
			}
		},
		[gapTexts]
	)
}
