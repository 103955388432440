import usePresentFormStore from '../zustand/store'

/** Возвращает функцию, открывающую модальное окно */
export function useGetToggleVisibilityPresentModal() {
	const isModalOpen = usePresentFormStore((store) => store.isOpen)
	const updateModalState = usePresentFormStore((store) => store.updateStore)

	return function () {
		updateModalState({ isOpen: !isModalOpen })
	}
}
