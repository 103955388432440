import useExerciseStore from '../../zustand/exerciseState'
import { useGetClearTextBlocks } from '../../zustand/fn/fillStore'

/**
 * Убирает изменения, сделанные пользователем
 */
export function useGetOneMoreTime() {
	const clearTextBlocks = useGetClearTextBlocks()

	const saveStoreForRestore = useExerciseStore(
		(store) => store.saveStoreForRestore
	)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return function () {
		updateStore({
			stage: 'NotReadyToCheck',
			saveStoreForRestore: saveStoreForRestore + 1,
		})

		clearTextBlocks()
	}
}
