import LessonPageHeader from 'pages/lesson/admin/page/lessonPageHeader/LessonPageHeader/LessonPageHeader'
import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import LessonArticle from '../../article/LessonArticle/LessonArticle'
import Footer from 'ui/Footer/Footer'
import { useGetUserRole } from 'parts/utils/hooks/hooks'

// Страница урока
function LessonAdminPage() {
	const userRoles = useGetUserRole()

	return (
		<PageSkeleton>
			<LessonPageHeader />
			<LessonArticle />
			<Footer isRightSideHidden={!userRoles.isAdminOrManager} />
		</PageSkeleton>
	)
}

export default LessonAdminPage
