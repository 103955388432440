import { useEffect } from 'react'
import { userQuery } from 'parts/requests/user/userQuery'
import useStudentsStore from '../store'
import { StudentsStoreType } from '../storeTypes'
import UserApiTypes from 'parts/requests/user/userApiTypes'
import EntityTypes from 'parts/types/EntityTypes'
import studentsManager from './studentsManager'

/** При монтировании компонента и при изменении объекта с фильтрами таблицы учеников делает запрос
 * на получение списка учеников, формирует данные для Состояния и ставит в него */
export function useFillInStore() {
	const [updateStore, searchValues] = useStudentsStore((state) => [
		state.updateStore,
		state.searchValues,
	])

	// Получить список учеников в зависимости от параметров
	const { data: studentsRes, refetch } = userQuery
		.getUsersPaginated(getQueryParams(searchValues))
		.useQuery()

	// При изменении данных подготовить их под формат используемый в таблице учеников и поставить
	useEffect(
		function () {
			if (!studentsRes) return

			const preparedStudents =
				studentsManager.convertServStudentsToStateStudents(
					studentsRes.data.items
				)

			updateStore({
				students: preparedStudents,
				totalStudents: studentsRes.data.totalItems,
				dataLoadingStatus: 'success',
			})
		},
		[studentsRes]
	)

	useEffect(
		function () {
			// Сделать новый запрос при изменении объекта с фильтрами таблицы
			refetch()
		},
		[searchValues]
	)
}

export function getQueryParams(
	searchValues: StudentsStoreType.SearchValues
): UserApiTypes.GetUsersParams {
	const currentPageNum = useStudentsStore((state) => state.currentPageNum)
	const itemsPerPage = useStudentsStore((state) => state.studentsPerPage)

	const params: UserApiTypes.GetUsersParams = {
		filter: {
			fullName: searchValues.fullName,
			email: searchValues.email,
			phone: searchValues.phone,
			country: searchValues.country,
			roles: [EntityTypes.UserRole.STUDENT],
			isDeleted: false,
		},
		offset: (currentPageNum - 1) * itemsPerPage,
		limit: itemsPerPage,
	}

	const isActivated =
		searchValues.status == StudentsStoreType.SearchValuesStatus.IsActivated
	const isNotActivated =
		searchValues.status ==
		StudentsStoreType.SearchValuesStatus.IsNotActivated

	// Если указали только статус isActivated...
	if (isActivated && !isNotActivated) {
		params.filter!.isActivated = true
	}
	// Если указали только статус isNotActivated...
	if (!isActivated && isNotActivated) {
		params.filter!.isActivated = false
	}
	// Если указали и isActivated и isNotActivated, то ничего не передавать
	// и тогда сервер пришлёт учеников с обоими статусами...
	return params
}
