import { create } from 'zustand'
import { AddLessonModalStoreType } from './storeTypes'

const useAddLessonModalStore = create<AddLessonModalStoreType.State>((set) => {
	return {
		isOpen: false,
		isSubmitting: false,
		formErrors: {},

		/**
		 * Установщик новых значений корневого объекта.
		 * @param obj — объект со свойствами и значениями, которые должны быть изменены в Сотоянии
		 */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useAddLessonModalStore
