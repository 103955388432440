import cn from 'classnames'
import UserApiTypes from 'parts/requests/user/userApiTypes'
import { useTranslation } from 'react-i18next'
import {
	CardDetailsType,
	getCardDetails,
	getStickerDetails,
} from './fn/cardDetails'
import { useGetGoToStatisticPage } from './fn/goToStatistic'
import { CardSticker } from 'ui/wrappers/CardSticker/CardSticker'
import { ClockCircleOutlined } from '@ant-design/icons'
import Progress from 'ui/Progress/Progress'
import './VisitCard.scss'
import { UCardBlock } from 'ui/wrappers/UCardBlock/UCardBlock'
import DatesRange from 'ui/DatesRange/DatesRange'

type VisitsCardProps = {
	visitData: UserApiTypes.StudentTrainingsVisitStatsItem
}

// Карточка со статистикой прохождения курса учеником
function VisitCard(props: VisitsCardProps) {
	const { visitData } = props

	const cardDetails = getCardDetails(visitData)
	const stickerDetails = getStickerDetails(cardDetails)
	const isActive = cardDetails.groupLiveStatus !== 'notOpen'

	const goToStatisticPage = useGetGoToStatisticPage(cardDetails)

	return (
		<CardSticker
			text={stickerDetails.text}
			color={stickerDetails.color}
			extraClass="visit-card__sticker"
		>
			<UCardBlock
				hover={isActive}
				onClick={goToStatisticPage}
				extraClass="visit-card__container"
			>
				<div>
					<Top cardDetails={cardDetails} />
					<Duration cardDetails={cardDetails} />
				</div>
				<Window cardDetails={cardDetails} />
				<Bottom cardDetails={cardDetails} />
			</UCardBlock>
		</CardSticker>
	)
}

export default VisitCard

type CommonProps = {
	cardDetails: CardDetailsType
}

function Top(props: CommonProps) {
	const { cardDetails } = props

	const isActive = cardDetails.groupLiveStatus !== 'notOpen'

	return (
		<>
			<p className="visit-card__group-name">{cardDetails.groupName}</p>
			<h3
				className={cn(
					'visit-card__training-name',
					isActive && 'visit-card__training-name--active'
				)}
			>
				{cardDetails.trainingName}
			</h3>
		</>
	)
}

function Duration(props: CommonProps) {
	const { cardDetails } = props
	const { groupStart, groupEnd, groupLiveStatus } = cardDetails

	return (
		<DatesRange
			dateFrom={groupStart}
			dateTo={groupEnd}
			extraClass="visit-card__range"
			colored={groupLiveStatus == 'open'}
		/>
	)
}

function Window(props: CommonProps) {
	const { cardDetails } = props

	return (
		<div className="visit-card__window">
			{cardDetails.groupLiveStatus == 'notOpen' && <WindowGroupNotOpen />}
			{cardDetails.groupLiveStatus == 'open' && (
				<WindowGroupOpen cardDetails={cardDetails} />
			)}
			{cardDetails.groupLiveStatus == 'finished' && (
				<WindowGroupFinished />
			)}
		</div>
	)
}

function WindowGroupNotOpen() {
	return <p className="visit-card__window-text">Занятия еще не начались</p>
}

function WindowGroupOpen(props: CommonProps) {
	const { t } = useTranslation()

	const { unperformed, progress } = props.cardDetails
	if (!unperformed) return null

	return (
		<div className="visit-card__window-inner">
			<Progress percent={progress} />
			<p className="visit-card__window-small-text">
				{t('student.studentVisitCardLastTasks')}
			</p>
			<p className="visit-card__window-text">
				{unperformed.exercises} в {unperformed.lessons}
			</p>
		</div>
	)
}

function WindowGroupFinished() {
	const { t } = useTranslation()

	return (
		<p className="visit-card__window-text">
			{t('student.studentVisitCardGroupFinished')}
		</p>
	)
}

function Bottom(props: CommonProps) {
	const { groupLiveStatus, nextLessonOpenDate } = props.cardDetails
	const { t } = useTranslation()

	if (groupLiveStatus == 'finished') {
		return null
	} else if (groupLiveStatus == 'notOpen') {
		return (
			<p className="visit-card__bottom-text">
				<ClockCircleOutlined className="visit-card__bottom-icon" />
				<span className="visit-card__bottom-text--gray">
					{t('student.studentVisitCardStart')}
				</span>
				&nbsp;{nextLessonOpenDate}
			</p>
		)
	} else {
		if (nextLessonOpenDate) {
			return (
				<p className="visit-card__bottom-text">
					<img
						src="/images/signs/CheckCircleTwoTone.svg"
						className="visit-card__bottom-icon"
					/>
					{t('student.studentVisitCardAllLessonsOpened')}
				</p>
			)
		} else {
			return (
				<p className="visit-card__bottom-text">
					<img
						src="/images/signs/ClockCircleTwoTone.svg"
						className="visit-card__bottom-icon"
					/>
					<span className="visit-card__bottom-text--gray">
						{t('student.studentVisitCardNextLesson')}
					</span>{' '}
					{nextLessonOpenDate}
				</p>
			)
		}
	}
}
