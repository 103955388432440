import FormHelpType, { FormHelpSections } from './dataTypes'

// Данные, по которым будет генерироваться справочная информация
const helpData: Record<string, FormHelpType.Elements> = {
	[FormHelpSections.Default]: [
		{
			type: 'header',
			text: 'Все настройки применяются именно группе. Что это значит?',
		},
		{
			type: 'text',
			text: 'Вы создали курс, но набор участников на курс вы осуществляете несколько раз в год, Например,  весной, летом и осенью. Это значит что курс сам по себе будет не изменным, а вот группы и условия для прохождения могут быть разными.',
		},
		{
			type: 'exampleCard',
			header2: 'Пример',
			iconType: 'cup',
			texts: [
				{
					type: 'text',
					text: 'Весной вы курс продавали по 100$ а летом уже за 150$ или еще пример, вы летом хотите чтобы ученики учились 3 раза в неделю, а осенью 2 раза.',
				},
				{
					type: 'text',
					text: 'Это значит что курс сам по себе будет не изменным, а вот группы и условия для прохождения могут быть разными.',
				},
			],
		},
		{
			type: 'text',
			text: 'Для этого под каждый запуск набора на курс создается новая группа и именно к группе применяются все настройки. Так вам не придется каждый раз создавать курс, достаточно просто создать новую группу.',
		},
		{
			type: 'text',
			text: 'При этом если вы внесете изменения в сам курс, например, добавите новые уроки, они будут доступны всем 3 группам.',
		},
	],
	[FormHelpSections.Start]: [
		{
			type: 'header',
			text: 'Что считать датой старта обучения',
		},
		{
			type: 'list',
			textBefore: 'Есть 3 варианта:',
			items: [
				'Дата старта курса',
				'Открытие первого урока ',
				'Дата покупки',
			],
		},
		{
			type: 'exampleCard',
			header1: 'вариант № 1',
			header2: 'Дата старта курса',
			iconType: 'book',
			texts: [
				{
					type: 'text',
					text: 'В этом случае Credu будет считать что курс должен начаться в определенную дату. Например, Вы запланировали, что курс начнется через месяц, 25 мая. Таким образом 1 урок откроется именно в эту дату, все кто купил курс раньше этой даты, увидят список уроков, но они будут не доступны до даты старта курса.',
				},
			],
		},
		{
			type: 'exampleCard',
			header1: 'вариант № 2',
			header2: 'Открытие первого урока',
			iconType: 'book',
			texts: [
				{
					type: 'text',
					text: 'В этом случае credu будет считать стартом курса, когда ученик открыл первый урок. Например,  ученик купил курс сегодня, а открыл первый урок через месяц, значит расписание открытия следующих уроков будет считаться именно датой открытия первого урока',
				},
			],
		},
		{
			type: 'exampleCard',
			header1: 'вариант № 3',
			header2: 'Дата покупки',
			iconType: 'book',
			texts: [
				{
					type: 'text',
					text: 'Стартом обучения будет считаться дата покупки. То есть первый урок откроется сразу после покупки курса. Обратите внимание, что открытие остальных уроков зависит от того, как Вы запланируете расписание открытия уроков.',
				},
			],
		},
	],
	[FormHelpSections.Mode]: [
		{
			type: 'header',
			text: 'Режим прохождения ',
		},
		{
			type: 'text',
			text: 'Здесь мы задаем расписание открытия уроков.',
		},
		{
			type: 'list',
			textBefore: 'Есть 3 варианта:',
			items: ['Открытый', 'По интервалам ', 'По дням недели'],
		},
		{
			type: 'exampleCard',
			header1: 'вариант №1',
			header2: 'Открытый',
			iconType: 'book',
			texts: [
				{
					type: 'text',
					text: 'Если выбран этот режим, в таком случае, все уроки будут доступны ученику сразу.',
				},
			],
		},
		{
			type: 'exampleCard',
			header1: 'вариант № 2',
			header2: 'По интервалам',
			iconType: 'book',
			texts: [
				{
					type: 'text',
					text: 'Уроки будут открываться по заданному интервалу времени. Например, уроки открываются через день после открытия первого урока.',
				},
			],
		},
		{
			type: 'exampleCard',
			header1: 'вариант № 3',
			header2: 'По дням недели',
			iconType: 'book',
			texts: [
				{
					type: 'text',
					text: 'Уроки будут отрываться по определенным дням недели. Например, можно задать чтобы уроки открывались в понедельник, среду и пятницу.',
				},
			],
		},
	],
	[FormHelpSections.Open]: [
		{
			type: 'header',
			text: 'Открытие уроков',
		},
		{
			type: 'text',
			text: 'В режиме прохождения «По интервалам» задайте интервалы, через которые уроки будут открываться ученикам.',
		},
		{
			type: 'exampleCard',
			header2: 'Пример',
			iconType: 'cup',
			texts: [
				{
					type: 'text',
					text: 'Если Вы укажите через день, то уроки будут открываться через день после открытия первого урока',
				},
			],
		},
		{
			type: 'text',
			text: 'В режиме прохождения “По дням недели” вы можете выбрать по каким дням недели будут открываться уроки после открытия первого урока.',
		},
		{
			type: 'exampleCard',
			header2: 'Пример',
			iconType: 'cup',
			texts: [
				{
					type: 'text',
					text: 'Вы можете выбрать открытие уроков по понедельникам, или выбрать несколько дней, как понедельник среда пятница. Также возможно указать чтобы уроки открывались только по будням или только по выходным.',
				},
			],
		},
	],
	[FormHelpSections.Duration]: [
		{
			type: 'header',
			text: 'Длительность курса',
		},
		{
			type: 'exampleCard',
			header2: 'Пример',
			iconType: 'cup',
			texts: [
				{
					type: 'text',
					text: 'У Вас  на курсе 30 уроков, уроки открываются в понедельник, среду и пятницу, значит, курс будет длиться 10 недель или 2 с половиной месяца.',
				},
			],
		},
		{
			type: 'text',
			text: 'Таким образом система будет знать точное окончание курса и выводить эту информацию ученику.',
		},
		{
			type: 'text',
			text: 'Предполагается, что ученик сможет отправлять на проверку задания только во время действия курса.',
		},
	],
	[FormHelpSections.Access]: [
		{
			type: 'header',
			text: 'Доступ на курс',
		},
		{
			type: 'text',
			text: 'Укажите срок, в течение которого курс будет доступен ученику.',
		},
		{
			type: 'text',
			text: ' Обычно доступ на курс в 2 раза дольше чем длительность курса, чтобы если ученик не успел пройти курс вместе со всей группой, у него было достаточно времени для обучения.',
		},
	],
}

export default helpData
