import { LandingTypes } from 'parts/types/LandingTypes'
import { Bullet } from '../Bullet/Bullet'
import './Bullets.scss'

// Обёртка с двумя колонками куда помещаются блоки с описанием пользователя, которому подойдёт предлагаемый курс
type BulletsProps = {
	bulletBlockIdx: number
	bullets: LandingTypes.ProgramBullet[]
}

function Bullets(props: BulletsProps) {
	const { bulletBlockIdx, bullets } = props

	return (
		<div className="landing-prog-bullets-blocks">
			{bullets.map((bullet, i) => {
				return (
					<Bullet
						blockIdx={bulletBlockIdx}
						bulletIdx={i}
						key={bullet.id}
					/>
				)
			})}
		</div>
	)
}

export default Bullets
