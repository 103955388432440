import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'

/** Возвращает текст кнопки показа панели настроек */
export function useGetHeaderText(tariff: TariffsStoreType.Tariff) {
	const { tariffs } = tariffsManager.store
	const tariffIdx = tariffsManager.findTariffIndex(tariffs, tariff.innerId)

	return 'Настройки карточки № ' + (tariffIdx + 1)
}

/**
 * Возвращает обработчик нажатия на кнопку показа панели настроек
 * @param tariff — данные тарифа
 */
export function useGetOnClickHandler(tariff: TariffsStoreType.Tariff) {
	const { updateStore } = tariffsManager.store

	return function () {
		updateStore({ isPanelOpen: true, activeTariffId: tariff.innerId })
	}
}
