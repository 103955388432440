import { useEffect, useState } from 'react'
import ExerciseStateType, { initialState } from './ExerciseStateType'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

/**
 * Функция создаёт и возвращает объект состояния упражнения
 * @param {Object} exercise — объект с данными упражнения
 */
export function useManageExerciseState(
	exercise: TrainingEntityTypes.TrueOrFalseExercise
) {
	const [state, setState] = useState<ExerciseStateType.Main>(initialState)

	useEffect(
		function () {
			setState(convertExerciseDataToExerciseState(exercise))
		},
		[exercise]
	)

	return { state, setState }
}

/**
 * Функция возвращает первоначальный объект состояния упражнения
 * @param {Object} exercise — объект с данными упражнения
 */
export function convertExerciseDataToExerciseState(
	exercise: TrainingEntityTypes.TrueOrFalseExercise
): ExerciseStateType.Main {
	return {
		exerciseTask: exercise.item.task,
		currentStatementIdx: 0,
		totalStatements: exercise.item.statements.length,
		statements: getStatements(exercise),
		readyToShowResult: false,
		showResult: false,
	}
}

/**
 * Формирует массив утверждений для объекта состояния упражнения
 * @param {Object} exercise — объект с данными упражнения
 */
function getStatements(
	exercise: TrainingEntityTypes.TrueOrFalseExercise
): ExerciseStateType.Statement[] {
	return exercise.item.statements.map((statement, i) => {
		return {
			id: i,
			text: statement.text,
			image: statement.image,
			correct: statement.correct,
			userAnswer: null,
		}
	})
}

/**
 * Возвращает объект текущего утверждения
 * @param {Object} exerciseState — объект состояния
 */
export function useGetCurrentStatement(exerciseState: ExerciseStateType.Main) {
	const { statements, currentStatementIdx } = exerciseState
	return statements[currentStatementIdx] || null
}

/**
 * Функция ищет объекст состояния упражнения по переданному идентификатору
 * @param {Object} exerciseState — объект состояния упражнения
 * @param {Number} statementId — id утверждения
 */
export function getStatement(
	exerciseState: ExerciseStateType.Main,
	statementId: number
): undefined | ExerciseStateType.Statement {
	return exerciseState.statements.find(
		(statement) => statement.id === statementId
	)
}

/**
 * Функция возвращает случайную строку положительного ответа.
 */
export function generateRightAnswerText() {
	const possibleAnswers = [
		'Вот это да, супер 👏',
		'Вы молодец 👍',
		'Все отлично!',
		'Awesome 👏',
		'Perfect 🤩',
		'You’re doing great 👍🏻',
		'Поздравляю, Вы все сделали верно!',
		'Продолжайте в том же духе! 🎉',
		'Вы отлично справились! 👏',
	]

	const randomIdx = Math.round(Math.random() * possibleAnswers.length)

	return possibleAnswers[randomIdx]
}
