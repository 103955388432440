import React, { useCallback } from 'react'
import { convertExerciseDataToExerciseState } from './main'
import ExerciseStateType from './ExerciseStateType'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'

/**
 * Обработчик кнопки прохождения упражнения заново
 * @param {Object} exercise — объект с данными упражнения
 * @param {Function} setExerciseState — функция устанавливающая новый объект состояния
 */
export function useGetOnPassAgainButtonClick(
	exercise: TrainingEntityTypes.TrueOrFalseExercise,
	setExerciseState: React.Dispatch<
		React.SetStateAction<ExerciseStateType.Main>
	>
) {
	return useCallback(
		function () {
			setExerciseState(convertExerciseDataToExerciseState(exercise))
		},
		[exercise]
	)
}

/**
 * Функция возвращает количество утверждений которые ответили правильно и общее количество утверждений.
 * @param {Object} exerciseState — объект состояния упражнения
 */
export function calculateStats(exerciseState: ExerciseStateType.Main) {
	const passed = getPassedQuestionsNumber(exerciseState)
	const total = exerciseState.statements.length

	return {
		passedNum: passed,
		totalNum: total,
	}
}

/**
 * Функция возвращает количество отгаданных утверждений
 * @param {Object} exerciseState — объект состояния упражнения
 */
function getPassedQuestionsNumber(exerciseState: ExerciseStateType.Main) {
	let passedQuestions = 0

	exerciseState.statements.forEach((statement) => {
		if (
			(statement.correct && statement.userAnswer === 'yes') ||
			(!statement.correct && statement.userAnswer === 'not')
		) {
			passedQuestions++
		}
	})

	return passedQuestions
}
