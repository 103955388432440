import { useMutation, useQuery } from 'react-query'
import CommentsTypes from './commentsApiTypes'
import commentsRequests from './commentsRequests'
import { MutationOptions, QueryOptions } from '../common'

export const commentsQuery = {
	// Получение ленты всех ответов
	getAllComments(params: CommentsTypes.GetCommentsParams = {}) {
		return {
			key: 'getAllComments-' + JSON.stringify(params),
			useQuery(options: QueryOptions<CommentsTypes.GetComments> = {}) {
				return useQuery({
					queryKey: [this.key],
					queryFn: () => commentsRequests.getAllComments(params),
					...options,
				})
			},
		}
	},

	answerToComment: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(commentsRequests.answerToComment, options)
		},
	},

	archiveComment: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(commentsRequests.archiveComment, options)
		},
	},

	unarchiveComment: {
		useMutation(options: MutationOptions = {}) {
			return useMutation(commentsRequests.unArchiveComment, options)
		},
	},
}
