import { FC } from 'react'
import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { LiveLibraryStoreType } from '../../zustand/storeTypes'

import styles from './MobileFolderName.module.scss'

interface MobileFolderNameProps {
	selectedItems: LiveLessonEntityTypes.StorageItem[]
	selectedFolder: LiveLibraryStoreType.LibraryFolder | null
	setSelectedItems: (items: LiveLessonEntityTypes.StorageItem[]) => void
	onBack: () => void
}

const MobileFolderName: FC<MobileFolderNameProps> = ({
	selectedItems,
	selectedFolder,
	setSelectedItems,
	onBack,
}) => {
	const { t } = useTranslation()

	if (selectedItems.length > 0) {
		return (
			<div
				className={styles.FolderPath}
				onClick={() => setSelectedItems([])}
			>
				<PlusOutlined
					rotate={45}
					style={{ fontSize: 20, color: '#ACB4C0' }}
				/>
				{`${selectedItems.length} объекта`}
			</div>
		)
	}

	if (selectedFolder) {
		return (
			<div className={styles.FolderPath} onClick={onBack}>
				<ArrowLeftOutlined style={{ color: '#ACB4C0' }} />
				{selectedFolder.name}
			</div>
		)
	}

	return (
		<div className={styles.FolderPath}>
			{t('files.filesTableExercisesFiles')}
		</div>
	)
}

export default MobileFolderName
