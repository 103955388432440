import LivelessonCardAdmin from '../livelessonCardAdmin/LivelessonCardAdmin'
import CreateNewCard from 'ui/CreateNewCard/CreateNewCard'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import { useGetChangeModalVisibility } from 'pages/liveLessons/rooms/CreateOrEditLiveRoomModal/zustand/storeManager'
import useLiveRoomStore from './zustand/store'
import './LiveRoomsList.scss'
import { sortLiveLessons } from './fn/sortLiveLessons'

// Список live-уроков
function LiveRoomsList() {
	const userRoles = useGetUserRole()
	const openModal = useGetChangeModalVisibility(true)

	const liveRooms = useLiveRoomStore((store) => store.liveRooms)

	return (
		<div className="livelessons-list">
			{liveRooms.sort(sortLiveLessons).map((liveRoom) => {
				return (
					<LivelessonCardAdmin
						key={liveRoom.id}
						livelessonData={liveRoom}
					/>
				)
			})}
			{userRoles.isAdminOrManager && (
				<CreateNewCard
					extraClass="livelessons-list__new"
					text="Добавить live-урок"
					onClick={openModal}
				/>
			)}
		</div>
	)
}

export default LiveRoomsList
