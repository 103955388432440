import useLandingBuilderStore from 'pages/landing/landingBuilder/zustand/store'

// Объект с методами манипулирования блоков с описанием чему научится человек после обучения.
const timeBlocksManager = {
	/** Получение всех блоков */
	useGetBlocks() {
		const landing = useLandingBuilderStore((store) => store.landing)
		return landing.sections.time.blocks
	},

	/**
	 * Получение блоков указанной колонки
	 * @param columnNum — номер колонки, у которой нужно получить блоки
	 */
	useGetColumnBlocks(columnNum: 1 | 2) {
		return this.useGetBlocks().filter((block) => {
			return block.columnNum == columnNum
		})
	},
}

export default timeBlocksManager
