import { useEffect } from 'react'
import { message } from 'antd'
import { convertStoreTariffsToServerTariffs } from 'pages/landing/landingConstructor/tariffs/general/PageParts/fn/convertStoreTariffsToServerTariffs'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import { groupQuery } from 'parts/requests/group/groupQuery'
import useDebounce from 'parts/utils/hooks/hooks'

/** Следит за изменением свойства needToSaveOnServer. Если в true, то сохраняет тарифы на сервере */
export function saveTariffsOnServerAfterChanges() {
	const { needToSaveOnServer, groupId, tariffs } = tariffsManager.store

	const updateTariffsMutation = groupQuery.createOrEditTariffs.useMutation({
		onError: () => {
			message.error('Не удалось сохранить одностраничник')
		},
	})

	// Это заторможенные данные тарифов чтобы не так часто делать запрос на сервер.
	const throttledTariffs = useDebounce(tariffs, 1000)

	useEffect(
		function () {
			if (needToSaveOnServer == 0) return

			const groupTariffsDto = convertStoreTariffsToServerTariffs()

			updateTariffsMutation.mutate({
				groupId,
				tariffs: groupTariffsDto,
			})
		},
		[throttledTariffs]
	)
}
