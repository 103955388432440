import { Button, Dropdown, Space } from 'antd'
import { DownOutlined, PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useGetActionDropdownItem, useGetMenuItems } from './fn/actions'
import { useGetSetVisibilityToMakeOrderModal } from '../../MakeOrderModal/fn/openCloseModal'
import './HeaderButtons.scss'

export function ActionsButton() {
	const { t } = useTranslation()

	const menuItems = useGetMenuItems()
	const onMenuClick = useGetActionDropdownItem()

	return (
		<Dropdown menu={{ items: menuItems, onClick: onMenuClick }}>
			<Button>
				<Space>
					{t('student.pageHeaderActionsButton')}
					<DownOutlined />
				</Space>
			</Button>
		</Dropdown>
	)
}

export function NewOrderButton() {
	const { t } = useTranslation()
	const openModal = useGetSetVisibilityToMakeOrderModal(true)

	return (
		<Button icon={<PlusOutlined />} type="primary" onClick={openModal}>
			{t('student.pageHeaderOrderButton')}
		</Button>
	)
}
