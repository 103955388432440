import { useEffect } from 'react'
import useExercisesListAdminStore from '../../zustand/store'

/**
 * Хук смотрит за свойством newExerciseId куда заносится идентификатор добавленного упражнения.
 * При появлении находит элемент упражнения и прокручивает до него.
 * Само свойство newExerciseId обнуляет в Хранилище
 */
export function useScrollToNewExerciseAfterCreation() {
	let [newExerciseId, updateStore] = useExercisesListAdminStore((state) => [
		state.newExerciseId,
		state.updateStore,
	])

	useEffect(
		function () {
			if (!newExerciseId) return

			setTimeout(() => {
				const $newExercise = document.querySelector(
					`[data-exercise-id="${newExerciseId}"]`
				)
				if (!$newExercise) return

				$newExercise.scrollIntoView({
					behavior: 'smooth',
				})

				updateStore({ newExerciseId: null })
			}, 1000)
		},
		[newExerciseId]
	)
}
