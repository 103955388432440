import ArticleContentWrapper from '../../../ui/ArticleContentWrapper/ArticleContentWrapper'
import FilesLibrary from '../FilesLibrary/FilesLibrary'

function FilesArticle() {
	return (
		<ArticleContentWrapper innerWrapperWidth="big" center>
			<FilesLibrary />
		</ArticleContentWrapper>
	)
}

export default FilesArticle
