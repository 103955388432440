import { trainingQuery } from 'parts/requests/training/trainingQuery'
import { useEffect } from 'react'
import { groupQuery } from 'parts/requests/group/groupQuery'
import useGroupStore from '../../zustand/store'
import GroupsService from 'parts/services/GroupsService'
import UrlService from 'parts/services/UrlService'

export function useFillInStore() {
	const trainingId = UrlService.useGetTrainingId()
	const groupId = UrlService.useGetGroupId()

	const updateStore = useGroupStore((state) => state.updateStore)

	// Получить курс и группу курса
	const { data: trainingRes } = trainingQuery
		.getTrainingForAdmin(trainingId)
		.useQuery()

	const { data: groupsRes } = groupQuery
		.getTrainingGroups(trainingId)
		.useQuery()

	useEffect(
		function () {
			if (!trainingRes) return

			updateStore({
				trainingAdmin: trainingRes.data,
			})
		},
		[trainingRes]
	)

	useEffect(
		function () {
			if (!groupsRes) return

			const group = GroupsService.getGroupFromGroupsArr(
				groupsRes.data,
				groupId
			)

			updateStore({
				group,
			})
		},
		[groupsRes]
	)
}
