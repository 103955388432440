import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import RestrictionsService from 'parts/services/RestrictionsService'
import { AddStudentModalController } from '../AddStudentModal/AddStudentModalController'

// Кнопка открывающая модальное окно добавления нового ученика
function AddStudentButton() {
	const { t } = useTranslation()

	const canCreateNewStudent = RestrictionsService.useCanCreateNewStudent()

	if (!canCreateNewStudent) {
		return null
	}

	return (
		<Button
			type="primary"
			icon={<PlusOutlined />}
			onClick={AddStudentModalController.open}
		>
			{t('students.addStudentButton')}
		</Button>
	)
}

export default AddStudentButton
