import PaymentTypes from 'parts/types/PaymentTypes'
import { FormInstance, message } from 'antd'
import { FieldsNames } from 'pages/landing/landingConstructor/tariffs/panel/payment/MainPaymentForm/fn/form'
import { TariffsStoreType } from 'pages/landing/landingConstructor/tariffs/zustand/storeTypes'

/**
 * Юкасса работает только с рублями, а остальные платёжные системы с другими валютами кроме рублей.
 * Функция корректирует это поведение у формы и у данных
 * @param price — объект цены из данных
 * @param form — ссылка на форму
 * @param fieldName — название изменённого поля
 * @param value — выбранное значение выпадающего списка
 * @param stripeSetupDone — настроен ли Страйп?
 * @return — возвращает булево значение изменила ли функция форму настройки платёжных данные и данные Состояния
 */
export function paySystemAndCurrencyAdaptation(
	price: TariffsStoreType.TariffPrice,
	form: FormInstance,
	fieldName: FieldsNames,
	value: string,
	stripeSetupDone: boolean
) {
	// Выбрали ЮКассу или рубли?
	if (isChooseUKassaOrRubles(fieldName, value)) {
		// Тогда в качестве валюты поставить рубли и в качестве платёжной системы ЮКассу.
		updateFormAndData(form, price, {
			currency: PaymentTypes.Currency.RUB,
			paySystem: PaymentTypes.System.UKassa,
		})

		return true
	}

	// Выбрали не ЮКассу, но стоят рубли
	if (isChooseNotUKassaButSetRubles(form, fieldName, value)) {
		// Тогда в качестве валюты поставить доллары
		updateFormAndData(form, price, {
			currency: PaymentTypes.Currency.USD,
		})

		return true
	}

	// Выбрали не рубли, но стоит ЮКасса
	if (isChooseNotRublesButSetUKassa(form, fieldName, value)) {
		// Тогда в качестве платёжной системы поставить Страйп
		updateFormAndData(form, price, {
			paySystem: PaymentTypes.System.Stripe,
			currency: value as PaymentTypes.Currency,
		})

		return true
	}

	// Выбрали Страйп, но он не настроен
	if (isChooseStripeButItDoesNotNotDone(fieldName, value, stripeSetupDone)) {
		message.error(
			'Сначала требуется настроить Stripe на странице Мой кабинет/Мои системы оплат'
		)

		updateFormAndData(form, price, {
			currency: PaymentTypes.Currency.RUB,
			paySystem: PaymentTypes.System.UKassa,
		})

		return true
	}

	return false
}

/**
 * Проверяет, выбрали ли рубли или Юкассу.
 * @param fieldName — название изменённого поля
 * @param value — выбранное значение выпадающего списка
 */
function isChooseUKassaOrRubles(fieldName: FieldsNames, value: string) {
	// Если в качестве валюты поставили рубли или в качестве платёжной системы выбрали ЮКассу, то возвратить правду
	return (
		// Если выбрали рубли
		(fieldName == FieldsNames.Currency &&
			value == PaymentTypes.Currency.RUB) ||
		// Если выбрали Юкассу
		(fieldName == FieldsNames.PaymentSystem &&
			value === PaymentTypes.System.UKassa)
	)
}

/**
 * Функция возвращает правду если выбрали не ЮКассу, но в валюте стоят рубли
 * @param form — ссылка на форму
 * @param fieldName — название изменённого поля
 * @param value — выбранное значение выпадающего списка
 */
function isChooseNotUKassaButSetRubles(
	form: FormInstance,
	fieldName: FieldsNames,
	value: string
) {
	// Если в качестве валюты поставили не ЮКассу, но в качестве валюты стоят рубли, то возвратить правду
	return (
		fieldName == FieldsNames.PaymentSystem &&
		value !== PaymentTypes.System.UKassa &&
		form.getFieldValue(FieldsNames.Currency) == PaymentTypes.Currency.RUB
	)
}

/**
 * Функция возвращает правду если выбрали не рубли, но в качестве платёжной системе стоит ЮКасса
 * @param form — ссылка на форму
 * @param fieldName — название изменённого поля
 * @param value — выбранное значение выпадающего списка
 */
function isChooseNotRublesButSetUKassa(
	form: FormInstance,
	fieldName: FieldsNames,
	value: string
) {
	// Если в качестве валюты поставили не ЮКассу, но в качестве валюты стоят рубли, то возвратить правду
	return (
		fieldName == FieldsNames.Currency &&
		value !== PaymentTypes.Currency.RUB &&
		form.getFieldValue(FieldsNames.PaymentSystem) ==
			PaymentTypes.System.UKassa
	)
}

/**
 * Ставит в данные в качестве платёжной системы ЮКассу
 * @param fieldName — название изменённого поля
 * @param value — выбранное значение выпадающего списка
 * @param stripeSetupDone — настроен ли Страйп?
 */
function isChooseStripeButItDoesNotNotDone(
	fieldName: FieldsNames,
	value: string,
	stripeSetupDone: boolean
) {
	return (
		fieldName == FieldsNames.PaymentSystem &&
		value == PaymentTypes.System.Stripe &&
		!stripeSetupDone
	)
}

/**
 * Обновляет форму настройки оплаты и данные компонента по переданным значениям
 * @param form — ссылка на форму
 * @param priceData — данные цены их Состояния
 * @param values — новые значения, которые нужно поставить и в форму и в данные
 */
export function updateFormAndData(
	form: FormInstance,
	priceData: TariffsStoreType.TariffPrice,
	values: {
		currency?: PaymentTypes.Currency
		paySystem?: PaymentTypes.System
	}
) {
	if (values.currency) {
		form.setFieldsValue({
			[FieldsNames.Currency]: values.currency,
		})
		priceData.currency = values.currency
	}

	if (values.paySystem) {
		form.setFieldsValue({
			[FieldsNames.PaymentSystem]: values.paySystem,
		})
		priceData.paymentSystem = values.paySystem
	}
}

/**
 *
 * @param form — ссылка на форму
 * @param priceData — данные цены их Состояния
 * @param changedFieldName — имя изменённого поля
 * @param newValue — новое значение поля
 */
export function updateFormAndDataDependOnChangedField(
	form: FormInstance,
	priceData: TariffsStoreType.TariffPrice,
	changedFieldName: FieldsNames,
	newValue: string
) {
	if (changedFieldName == FieldsNames.Currency) {
		updateFormAndData(form, priceData, {
			currency: newValue as PaymentTypes.Currency,
		})
	} else if (changedFieldName == FieldsNames.PaymentSystem) {
		updateFormAndData(form, priceData, {
			paySystem: newValue as PaymentTypes.System,
		})
	}
}
