export enum FieldsNames {
	Reason = 'reason',
	ReasonNotRequired = 'reasonNotRequired',
	ReasonExpensive = 'reasonExpensive',
	ReasonBadSupport = 'reasonBadSupport',
	ReasonNoFunctionality = 'reasonNoFunctionality',
	ReasonAnother = 'reasonAnother',
	FullAnswer = 'fullAnswer',
}

export interface FormValues {
	[FieldsNames.Reason]: string
}
