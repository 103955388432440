import { FormInstance, SelectProps } from 'antd'
import ServerTypes from '../types/ServerTypes'

/**
 * Возвращает значение поля формы Анта
 * @param {Object} form — форма Анта
 * @param inputName
 */
export function getInputValueInForm(form: FormInstance, inputName: string) {
	return form.getFieldsValue()[inputName]
}

/**
 * Возвращает массив ошибок поля в форме Анта
 * @param {Object} form — форма Анта
 * @param {String} fieldName — имя поля
 */
export function getInputErrorsInForm(
	form: FormInstance,
	fieldName: string
): string[] {
	const fieldErrors = form.getFieldsError().filter((field) => {
		return field.name[0] == fieldName
	})

	if (!fieldErrors) return []

	return fieldErrors[0].errors
}

/**
 * Получает объект с ошибками и ставит их в поля формы
 * @param {Object} form — объект формы
 * @param {Object} formErrors — объект с сообщениями об ошибках
 */
export function setErrorsToFields(
	form: FormInstance,
	formErrors: ServerTypes.ErrorResponse
) {
	const formErrorFields = formErrors?.fields
	if (!formErrorFields) return

	form.setFields(
		Object.keys(formErrorFields).map((key) => {
			return { name: key, errors: [formErrorFields[key]] }
		})
	)
}

/**
 * Принимает массив пунктов выпадающего списка и блокирует переданный пункт.
 * Остальные ставит разблокированными.
 * @param options — массив пунктов выпадающего списка
 * @param disabledOptionValue — значение пункта, который должен быть заблокированным
 */
export function makeSelectOptionsEnabledExceptOne(
	options: SelectProps['options'],
	disabledOptionValue: string | number
): SelectProps['options'] {
	if (!options) return []

	return options.map((option) => {
		const optionCopy = { ...option }
		optionCopy.disabled = optionCopy.value == disabledOptionValue

		return optionCopy
	})
}
