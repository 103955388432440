import React from 'react'
import { Button } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import {
	DropWordsExerciseFormStateType,
	TextStatementItemType,
} from './fn/stateType'
import WrongAnswers from '../common/WrongAnswers/WrongAnswers'
import Editor from './Editor'
import {
	addNewTextBlock,
	closeTextBlock,
	setWrongAnswers,
} from './fn/changeForm'

type TextBlocksProps = {
	formState: DropWordsExerciseFormStateType
}

// Блоки с редакторами текста
export function TextBlocks(props: TextBlocksProps) {
	const { formState } = props

	return (
		<div className="exercise-drop-words-text-blocks">
			{formState.texts.map((textObj) => {
				return (
					<React.Fragment key={textObj.id}>
						<TextBlock
							showCloseButton={formState.texts.length > 1}
							textBlock={textObj}
							key={textObj.id}
						/>
						<WrongAnswers
							tags={textObj.wrongAnswers}
							setTags={(newAnswers) =>
								setWrongAnswers(textObj.id, newAnswers)
							}
						/>
					</React.Fragment>
				)
			})}
		</div>
	)
}

type TextBlockProps = {
	showCloseButton: boolean // Показывать ли кнопку удаления блока
	textBlock: TextStatementItemType // Объекта с данными для редактора
}

// Блок редактора пропусков и кнопки создания пропуска
function TextBlock(props: TextBlockProps) {
	const { showCloseButton, textBlock } = props

	const { t } = useTranslation()

	return (
		<div className="exercise-drop-words-text-block">
			<div className="exercise-drop-words-text-block__left">
				<label className="exercise-drop-words__label exercise-drop-words__label--top-margin">
					Упражнение
				</label>
				<Editor textBlock={textBlock} />
			</div>
			{showCloseButton && (
				<div className="exercise-drop-words-text-block__right">
					<Button
						shape="circle"
						icon={<MinusCircleOutlined />}
						onClick={(event) => {
							closeTextBlock(event, textBlock.id)
						}}
					/>
				</div>
			)}
		</div>
	)
}

// Кнопка добавления нового редактора текста
export function AddTextBlockButton() {
	return (
		<div className="exercise-drop-words-add-video-block">
			<Button onClick={() => addNewTextBlock()}>
				<PlusCircleOutlined width={17} height={17} /> Добавить
				упражнение
			</Button>
		</div>
	)
}
