import { useEffect } from 'react'
import { FormInstance } from 'antd'
import { setErrorsToFields } from 'parts/utils/form'
import useOpenLessonsStore from '../zustand/store'

// Названия полей формы
export enum FieldsNames {
	// Список открытых уроков для тестовых групп
	OpenLessons = 'openLessons',
}

// Типы полей формы
export interface FormValues {
	[FieldsNames.OpenLessons]: boolean[]
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useOpenLessonsStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
