import AppUrls from 'parts/constants/pageUrl'
import PageSkeleton from 'ui/wrappers/PageSkeleton/PageSkeleton'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import TrainingsStudentArticle from '../TrainingsArticle/TrainingsStudentArticle'
import Footer from 'ui/Footer/Footer'

/** Страница со списком курсов */
export default function TrainingsStudentPage() {
	return (
		<PageSkeleton>
			<PageHeader breadcrumbs={[]} header={AppUrls.Trainings().name} />
			<TrainingsStudentArticle />
			<Footer isRightSideHidden />
		</PageSkeleton>
	)
}
