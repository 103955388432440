import React from 'react'
import { Button, Form, Input, FormInstance, UploadFile } from 'antd'
import { useTranslation } from 'react-i18next'
import {
	FieldNames,
	useGetInitialValues,
	useGetOnNameInputChange,
} from './fn/form'
import { useManageForm } from './fn/submit'
import {
	UFormNewLRParts,
	UFormNewWhiteWrapper,
	UFormNewRow,
	UFormNewInputCaption,
	UFormNewGeneralError,
	UFormNewBottomButtonsWithBorder,
} from 'ui/UForm/UForm'
import { useGetOpenDeleteSchoolModal } from '../RemoveSchoolModal/fn/openCloseRemoveSchoolModal'
import UploadFileTypes from 'parts/constants/uploadTypes'
import Site from 'parts/constants/site'
import UploadFileButton from 'ui/upload/UploadFileInForm/UploadFileButton'
import UploadAvatar from 'ui/upload/UploadFileInForm/UploadAvatar'

// Форма редактирования данных школы
function EditSchoolForm() {
	const [form] = Form.useForm()

	const initialValues = useGetInitialValues()

	const { isLoading, onSubmit, formErrors } = useManageForm(form)

	return (
		<Form
			form={form}
			name="register"
			initialValues={initialValues}
			onFinish={onSubmit}
			layout="vertical"
		>
			<UFormNewWhiteWrapper>
				<UFormNewLRParts>
					<AvatarInput
						initialAvatarUrls={initialValues[FieldNames.Cover]}
					/>
					<>
						<SchoolNameInput form={form} />
						<SchoolDomainInput />
						<DescriptionInput />
						<OfferInput
							initialOfferUrls={initialValues[FieldNames.Oferta]}
						/>
					</>
				</UFormNewLRParts>
				<Bottom loading={isLoading} />
				<UFormNewGeneralError message={formErrors.message} />
			</UFormNewWhiteWrapper>
		</Form>
	)
}

export default EditSchoolForm

type AvatarInputProps = {
	initialAvatarUrls: UploadFile[]
}

// Поле загрузки фотографии
function AvatarInput(props: AvatarInputProps) {
	const { initialAvatarUrls } = props

	return (
		<Form.Item name={FieldNames.Cover}>
			<UploadAvatar
				inputName={FieldNames.Cover}
				initialFileList={initialAvatarUrls}
				maxCount={1}
			/>
		</Form.Item>
	)
}

type SchoolNameInputProps = {
	form: FormInstance
}

function SchoolNameInput(props: SchoolNameInputProps) {
	const { form } = props

	const { t } = useTranslation()
	const onNameInputChange = useGetOnNameInputChange(form)

	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.SchoolName}
				label={t('mySchools.editSchoolModalFieldSchoolNameLabel')}
			>
				<Input type="text" onChange={onNameInputChange} />
			</Form.Item>
		</UFormNewRow>
	)
}

// Поле зависимое от значения другого поля
function SchoolDomainInput() {
	const { t } = useTranslation()

	return (
		<>
			<UFormNewRow topOffset>
				<Form.Item
					name={FieldNames.Domain}
					label={t('mySchools.editSchoolModalFieldDomainLabel')}
				>
					<Input
						addonBefore={Site.protocol}
						addonAfter={Site.rootDomain}
						type="text"
					/>
				</Form.Item>
			</UFormNewRow>
			<UFormNewInputCaption>
				{t('mySchools.editSchoolModalFieldDomainNote')}
			</UFormNewInputCaption>
		</>
	)
}

// Обычное текстовое поле
function DescriptionInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Description}
				label={t('mySchools.editSchoolModalFieldDescriptionLabel')}
			>
				<Input type="text" />
			</Form.Item>
		</UFormNewRow>
	)
}

type OfferInputProps = {
	initialOfferUrls: UploadFile[]
}

// Обычное текстовое поле
function OfferInput(props: OfferInputProps) {
	const { initialOfferUrls } = props
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				label={t('mySchools.editSchoolModalFieldOfferLabel')}
				name={FieldNames.Oferta}
			>
				<UploadFileButton
					inputName={FieldNames.Oferta}
					initialFileList={initialOfferUrls}
					fileType={UploadFileTypes.FILE}
					accept="*"
					maxCount={1}
				/>
			</Form.Item>
		</UFormNewRow>
	)
}

type BottomProps = {
	loading: boolean
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom(props: BottomProps) {
	const form = Form.useFormInstance()
	const { loading } = props

	const { t } = useTranslation()
	const openDeleteSchoolModal = useGetOpenDeleteSchoolModal()

	return (
		<UFormNewBottomButtonsWithBorder>
			<Button type="text" onClick={openDeleteSchoolModal}>
				{t('mySchools.editSchoolModalDeleteSchoolButton')}
			</Button>
			<Form.Item shouldUpdate>
				{() => {
					const formErrors = form
						.getFieldsError()
						.filter(({ errors }) => errors.length)

					return (
						<Button
							block
							type="primary"
							htmlType="submit"
							disabled={loading || formErrors.length > 0}
							loading={loading}
						>
							{t('mySchools.editSchoolModalSaveSchoolButton')}
						</Button>
					)
				}}
			</Form.Item>
		</UFormNewBottomButtonsWithBorder>
	)
}
