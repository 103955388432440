import cn from 'classnames'
import { LandingTypes } from 'parts/types/LandingTypes'
import useLandingBuilderStore from '../../../../zustand/store'
import Bullets from '../Bullets/Bullets'
import './BulletsSection.scss'

type BulletsSectionProps = {
	bulletBlockIdx: number
	bulletBlock: LandingTypes.ProgramBulletsGroup
}

function BulletsSection(props: BulletsSectionProps) {
	const { bulletBlockIdx, bulletBlock } = props

	const landing = useLandingBuilderStore((store) => store.landing)
	const { colorScheme } = landing

	const headerClasses = [
		'landing-prog-bullets-section__header',
		'landing-prog-bullets-section__header--' + colorScheme,
	]

	return (
		<div className="landing-prog-bullets-section">
			<h2 className={cn(headerClasses)}>
				{landing.sections.program.bulletsBlocks[bulletBlockIdx].header}
			</h2>
			<Bullets
				bulletBlockIdx={bulletBlockIdx}
				bullets={bulletBlock.bullets}
			/>
		</div>
	)
}

export default BulletsSection
