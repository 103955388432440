import { SyntheticEvent } from 'react'
import { useQueryClient } from 'react-query'
import LessonAdminService from 'parts/services/LessonAdminService'
import { lessonsQuery } from 'parts/requests/lessons/lessonsQuery'
import lessonsRequests from 'parts/requests/lessons/lessonsRequests'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import useLessonsListAdminStore from '../../../../zustand/store'

/**
 * Хук отдаёт функцию изменяющую порядок упражнения
 * @param {Number} lessonIdx — порядковый номер урока, у которого нужно изменить порядок
 * @param {String} direction — направление перемещения упражнения
 */
export function useGetMoveLesson(lessonIdx: number, direction: 'up' | 'down') {
	const queryClient = useQueryClient()

	const [lessons, updateStore, trainingId] = useLessonsListAdminStore(
		(store) => [store.lessons, store.updateStore, store.trainingId]
	)

	return function (event: SyntheticEvent) {
		event.preventDefault()

		const updatedLessons = LessonAdminService.moveLessonUpOrDownInLessons(
			lessons,
			lessonIdx,
			direction
		)

		synchronizeLessonsOrder(lessons, updatedLessons)
		updateStore({ lessons: updatedLessons })

		setTimeout(() => {
			if (trainingId) {
				// Пометить данные курса неактуальными. После этого React Query скачает обновлённые данные.
				queryClient.refetchQueries({
					queryKey: [
						trainingQuery.getTrainingForAdmin(trainingId).key,
					],
				})
			} else {
				queryClient.setQueryData(
					[lessonsQuery.getSchoolLessons.key],
					(old) => updatedLessons
				)
			}
		}, 1000)
	}
}

/**
 * Хук отдаёт булево значение должна ли кнопка перемещающая вперёд и назад упражнение быть неактивной.
 * @param lessonIdx
 * @param {String} direction — направление перемещения
 */
export function useIsMoveLessonBtnDisabled(
	lessonIdx: number,
	direction: 'up' | 'down'
) {
	const lessons = useLessonsListAdminStore((store) => store.lessons)

	return !LessonAdminService.isLessonMovingAllowed(
		lessons,
		lessonIdx,
		direction
	)
}

function synchronizeLessonsOrder(
	oldLessons: TrainingEntityTypes.LessonAdmin[],
	updatedLessons: TrainingEntityTypes.LessonAdmin[]
) {
	oldLessons.forEach((oldLesson) => {
		const updatedLesson = LessonAdminService.findLessonByIdInLessons(
			updatedLessons,
			oldLesson.id
		)
		if (!updatedLesson) return

		if (oldLesson.order === updatedLesson.order) {
			return
		}

		lessonsRequests.updateLesson({
			id: updatedLesson.id,
			order: updatedLesson.order,
		})
	})
}
