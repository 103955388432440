import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import LessonStudentService from 'parts/services/LessonStudentService'
import Loader from 'ui/Loader/Loader'
import HasError from '../HasError/HasError'
import { useRedirectToExercisePageILessonHasExercises } from './fn/getLesson'
import NoExercisesInLesson from '../NoLessons/NoLessons'

// Страница урока для ученика.
// На эту страницу можно попасть или прямо введя адрес или нажав на карточку урока.
// И если в уроке не будет упражнений, то будет переход на эту страницу
function LessonStudentArticle() {
	const lesson = LessonStudentService.useGetLesson()

	useRedirectToExercisePageILessonHasExercises()

	return (
		<ArticleContentWrapper center>
			{lesson === undefined && <Loader />}
			{lesson === null && <HasError />}
			{lesson && <NoExercisesInLesson />}
		</ArticleContentWrapper>
	)
}

export default LessonStudentArticle
