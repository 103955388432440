import { useCallback } from 'react'
import { produce } from 'immer'
import { getAnswer, getQuestion } from './main'
import useExerciseStore, {
	ExerciseStateType,
} from '../../zustand/exerciseState'

/**
 * Функция возвращает классы для обёртки ответа на вопрос
 * @param {Boolean} showCorrect — должен ли быть показан правильный ответ
 * @param {Object} question — объект вопроса
 * @param {Object} answer — объект ответа
 */
export function getAnswerClasses(
	showCorrect: boolean,
	question: ExerciseStateType.Question,
	answer: ExerciseStateType.Answer
) {
	const CN = 'exercise-test-content'

	const classesArr = [`${CN}__answer`]

	if (answer.checked) {
		classesArr.push(`${CN}__answer--checked`)
	}

	if (answer.view === 'right') {
		classesArr.push(`${CN}__answer--right`)
	} else if (answer.view === 'wrong') {
		classesArr.push(`${CN}__answer--wrong`)
	}

	if (showCorrect) {
		if (answer.checked) {
			if (answer.correct) classesArr.push(`${CN}__answer--show-right`)
			else classesArr.push(`${CN}__answer--show-wrong`)
		} else {
			if (answer.correct) {
				classesArr.push(`${CN}__answer--show-right`)
			}
		}
	}

	// Если вопрос заблокирован, то убрать стили при наведении
	if (question.disableQuestion) {
		classesArr.push(`${CN}__answer--disabled`)
	}

	return classesArr.join(' ')
}

/**
 * Классы значка левее текста ответа
 * @param {Object} answer — объект ответа
 */
export function getSignClasses(answer: ExerciseStateType.Answer) {
	const CN = 'exercise-test-content'

	const classesArr = [`${CN}__sign`]

	if (answer.checked) {
		classesArr.push(`${CN}__sign--checked`)
	}

	if (answer.view === 'right') classesArr.push(`${CN}__sign--right`)
	else if (answer.view === 'wrong') classesArr.push(`${CN}__sign--wrong`)

	return classesArr.join(' ')
}

/**
 * Хук возвращает обработчик выбора правильного ответа
 * @param {Object} question — объект вопроса
 * @param {Object} answer — объект ответа
 * @param multipleSelect
 */
export function useGetOnClickAnswerFn(
	question: ExerciseStateType.Question,
	answer: ExerciseStateType.Answer,
	multipleSelect: boolean
) {
	const saveStoreForRestore = useExerciseStore(
		(store) => store.saveStoreForRestore
	)
	const questions = useExerciseStore((store) => store.questions)
	const updateStore = useExerciseStore((store) => store.updateStore)

	return useCallback(
		function () {
			const newQuestions = produce(questions, (draft) => {
				const foundedQuestion = getQuestion(draft, question.id)
				if (!foundedQuestion) return draft

				// Если вопрос заблокирован, то ничего не делать
				if (foundedQuestion.disableQuestion) {
					return draft
				}

				// Разблокировать кнопку просмотра результата
				// foundedQuestion.resultButtonEnable = true

				// Если можно выбрать только один вариант (ответы в виде переключателей),
				// то убрать выделение с других вариантов
				if (!multipleSelect) {
					foundedQuestion.answers.forEach((answer) => {
						answer.checked = false
						answer.view = 'unknown'
					})
				}

				const foundedAnswer = getAnswer(draft, question.id, answer.id)
				if (!foundedAnswer) return draft

				foundedAnswer.checked = multipleSelect
					? !foundedAnswer.checked
					: true
				if (!foundedAnswer.checked) {
					foundedAnswer.view = 'unknown'
				}
			})

			// Отправить на состояние, где упражнение можно проверить
			updateStore({
				stage: 'ReadyForCheck',
				questions: newQuestions,
				saveStoreForRestore: saveStoreForRestore + 1,
			})
		},
		[questions, question, answer, saveStoreForRestore]
	)
}
