import React from 'react'
import { Button } from 'antd'
import { useGoToPage } from 'parts/utils/hooks/hooks'
import './NoContent.scss'

type NotContentProps = {
	header?: string
	text?: string
	btnConf?: { text: string; to: string }
}

// Сообщение, что на странице нет содержимое.
// Ставить на страницах, где по какой-то причине не удалось загрузить данные с сервера
function NoContent(props: NotContentProps) {
	const { header, text, btnConf } = props

	const goToPage = useGoToPage(btnConf?.to || '')

	return (
		<div className="no-content">
			{header && <h1 className="no-content__header">{header}</h1>}
			{text && <p className="no-content__text">{text}</p>}
			{btnConf && (
				<Button onClick={goToPage} className="no-content__btn">
					{btnConf.text}
				</Button>
			)}
		</div>
	)
}

export default NoContent
