import React, { FC, createRef, useEffect, useState } from 'react'
import { Input, InputRef, Modal } from 'antd'

import styles from './FolderDialog.module.scss'

interface FolderDialogProps {
	open: boolean
	initialFolderName?: string
	onOk?: (name: string) => void
	onCancel?: () => void
	okButtonText?: string
	title: string
	mobile?: boolean
}

const FolderDialog: FC<FolderDialogProps> = (props) => {
	const {
		open,
		onOk: onCreate,
		initialFolderName = '',
		onCancel: onClose,
		okButtonText,
		title,
		mobile = false,
	} = props
	const [folderName, setFolderName] = useState(initialFolderName)
	const [isSaveBtnActive, setIsSaveBtnActive] = useState(false)
	const inputRef = createRef<InputRef>()

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus()
		}
	}, [inputRef])

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.target.value

		setFolderName(name)
		if (name === '') {
			setIsSaveBtnActive(false)
		} else {
			setIsSaveBtnActive(true)
		}
	}

	const handleOnCreateFolder = () => {
		if (onCreate) {
			onCreate(folderName)
			setFolderName('')
			if (inputRef.current) {
				inputRef.current.focus()
			}
		}
	}

	return (
		<Modal
			open={open}
			onOk={handleOnCreateFolder}
			onCancel={onClose}
			cancelText="Отмена"
			okText={okButtonText || 'Создать'}
			okButtonProps={{ disabled: !isSaveBtnActive }}
			width={360}
			centered
			title={title}
			style={{ bottom: mobile ? 0 : 'auto' }}
		>
			<div className={styles.NewFolder}>
				<Input
					ref={inputRef}
					className={styles.NewFolder__Input}
					type="text"
					placeholder="Имя папки"
					value={folderName}
					onChange={handleInputChange}
				/>
			</div>
		</Modal>
	)
}

export default FolderDialog
