import useExerciseStore, { ExerciseStateType } from '../zustand/exerciseState'
import { getWordButtonClasses } from './fn/classes'

// Блок слов на верхней части упражнения
export function Words() {
	const descriptions = useExerciseStore((store) => store.descriptions)

	return (
		<div className="exercise-desc-image-content__words">
			{descriptions.map((word) => (
				<Word wordObj={word} position="inside" key={word.id} />
			))}
		</div>
	)
}

type WordProps = {
	wordObj: ExerciseStateType.DescriptionData
	position: 'inside' | 'outside'
}

export function Word(props: WordProps) {
	const { wordObj, position } = props

	const buttonClasses = getWordButtonClasses(position)

	return (
		<button className={buttonClasses} draggable data-word-id={wordObj.id}>
			{wordObj.text}
		</button>
	)
}
