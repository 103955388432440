import { Button, Checkbox, Form, Input, InputNumber, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import ModalWithForm from 'ui/ModalWithForm/ModalWithForm'
import useAddTrainingModalStore from './zustand/store'
import { useSetVisibilityToAddTrainingModal } from './fn/openCloseModal'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'
import { FieldNames, setErrorsToForm } from './fn/form'
import {
	UFormNewBottomButtonsAfterGray,
	UFormNewButtonsContainer,
	UFormNewGeneralError,
	UFormNewRow,
} from 'ui/UForm/UForm'
import { useGetOnSubmit } from './fn/submit'
import UploadAvatar from 'ui/upload/UploadFileInForm/UploadAvatar'
import useSystemStore from 'parts/systemStore/systemStore'

// Модальное окно добавления нового курса
function AddTrainingModal() {
	const { t } = useTranslation()

	const [form] = Form.useForm()

	const onSubmit = useGetOnSubmit(form)
	setErrorsToForm(form)

	const modalState = useAddTrainingModalStore((state) => state)
	const closeModal = useSetVisibilityToAddTrainingModal(false)

	const user = useSystemStore((store) => store.user)
	const isMobileTariffPlan = user.tariffPlan?.restrictions?.mobileApp

	return (
		<ModalWithForm
			title={t('adminTrainings.addTrainingModalTitle')}
			open={modalState.isOpen}
			onCancel={closeModal}
		>
			<Form
				form={form}
				name="addTraining"
				layout="vertical"
				onFinish={onSubmit}
			>
				<GrayBlock>
					<NameInput />
					<DescriptionInput />
					{isMobileTariffPlan && <BlockNameInput />}
					<LevelInput />
					<LangInput />
					<OrderInput />
					<CoverInput />
					{isMobileTariffPlan && <ShowOnMobileMainInput />}
				</GrayBlock>
				<Bottom />
				<UFormNewGeneralError message={modalState.formErrors.message} />
			</Form>
		</ModalWithForm>
	)
}

export default AddTrainingModal

// Поле названием курса
function NameInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.Name}
				label={t(
					'adminTrainings.addTrainingModalFieldTrainingNameLabel'
				)}
				rules={[
					{
						required: true,
						message: t(
							'adminTrainings.addTrainingModalFieldTrainingNameError'
						),
					},
				]}
			>
				<Input />
			</Form.Item>
		</UFormNewRow>
	)
}

// Поле описания курса
function DescriptionInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Description}
				label={t(
					'adminTrainings.addTrainingModalFieldTrainingDescriptionLabel'
				)}
			>
				<Input.TextArea />
			</Form.Item>
		</UFormNewRow>
	)
}

// Поле загрузки фотографии
function CoverInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Cover}
				label={t(
					'adminTrainings.addTrainingModalFieldTrainingCoverLabel'
				)}
			>
				<UploadAvatar
					listType="picture-card"
					inputName={FieldNames.Cover}
					initialFileList={[]}
					maxCount={1}
				/>
			</Form.Item>
		</UFormNewRow>
	)
}

// Поле раздела курса
function BlockNameInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.BlockName}
				label={t(
					'adminTrainings.addTrainingModalFieldTrainingTypeLabel'
				)}
			>
				<Select>
					<Select.Option value="big">Большие курсы</Select.Option>
					<Select.Option value="mini">Мини курсы</Select.Option>
					<Select.Option value="verb">Фразовые глаголы</Select.Option>
					<Select.Option value="gramma">Грамматика</Select.Option>
					<Select.Option value="pronunciation">
						Произношение
					</Select.Option>
					<Select.Option value="podcast">Подкасты</Select.Option>
					<Select.Option value="jokes">Шутки</Select.Option>
				</Select>
			</Form.Item>
		</UFormNewRow>
	)
}

// Поле уровень курса
function LevelInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Level}
				label={t(
					'adminTrainings.addTrainingModalFieldTrainingLevelLabel'
				)}
			>
				<Select>
					<Select.Option value="a1">A1</Select.Option>
					<Select.Option value="a2">A2</Select.Option>
					<Select.Option value="b1">B1</Select.Option>
					<Select.Option value="b2">B2</Select.Option>
					<Select.Option value="c1">C1</Select.Option>
					<Select.Option value="c2">C2</Select.Option>
				</Select>
			</Form.Item>
		</UFormNewRow>
	)
}

function LangInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Lang}
				label={t(
					'adminTrainings.addTrainingModalFieldTrainingLangLabel'
				)}
			>
				<Select>
					<Select.Option value="ru">Русский</Select.Option>
					<Select.Option value="es">Испанский</Select.Option>
				</Select>
			</Form.Item>
		</UFormNewRow>
	)
}

function OrderInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Order}
				label={t(
					'adminTrainings.addTrainingModalFieldTrainingOrderLabel'
				)}
			>
				<InputNumber />
			</Form.Item>
		</UFormNewRow>
	)
}

// Поле описания курса
function ShowOnMobileMainInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.ShowOnMain}
				label={t(
					'adminTrainings.addTrainingModalFieldTrainingShowOnMainLabel'
				)}
				valuePropName="checked"
			>
				<Checkbox>
					{t(
						'adminTrainings.addTrainingModalFieldTrainingShowOnMainCaption'
					)}
				</Checkbox>
			</Form.Item>
		</UFormNewRow>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
// Кнопки подтверждения и закрытия окна
function Bottom() {
	const { t } = useTranslation()

	const modalState = useAddTrainingModalStore((state) => state)
	const closeModal = useSetVisibilityToAddTrainingModal(false)

	return (
		<UFormNewBottomButtonsAfterGray>
			<div />
			<UFormNewButtonsContainer>
				<Button onClick={closeModal}>
					{t('adminTrainings.addTrainingModalCancelButton')}
				</Button>
				<Button
					type="primary"
					htmlType="submit"
					disabled={modalState.isSubmitting}
					loading={modalState.isSubmitting}
				>
					{t('adminTrainings.addTrainingModalOkButton')}
				</Button>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
