import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { DotsMenu } from 'ui'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { useGetCreatePreset } from './fn/createPreset'

type MenuProps = {
	lesson: TrainingEntityTypes.LessonAdmin
}

function Menu(props: MenuProps) {
	const { lesson } = props

	const { t } = useTranslation()
	const createPreset = useGetCreatePreset(lesson.id)

	return (
		<DotsMenu
			menuItems={[
				{
					key: '1',
					label: (
						<Button
							type="link"
							onClick={createPreset}
							className="edit-lesson-card__action"
						>
							{t('adminTraining.lessonCardButtonCreateTemplate')}
						</Button>
					),
				},
			]}
		/>
	)
}

export default Menu
