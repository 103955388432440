import AppUrls from 'parts/constants/pageUrl'
import NoContent from 'ui/NoContent/NoContent'

// Страница с сообщением об ошибке в получении урока
function HasError() {
	return (
		<NoContent
			header="Такого урока не существует"
			text="Он был удалён или введён неправильный адрес"
			btnConf={{
				text: 'Перейти в Мои курсы',
				to: AppUrls.Trainings().url,
			}}
		/>
	)
}

export default HasError
