import { useEffect } from 'react'
import dayjs from 'dayjs'
import { paymentsQuery } from 'parts/requests/payments/paymentsQuery'
import PaymentsApiTypes from 'parts/requests/payments/paymentsApiTypes'
import useLeadsStatsStore from '../zustand/store'
import PaymentTypes from 'parts/types/PaymentTypes'

const queryParams: PaymentsApiTypes.GetPaymentsParams = {
	filter: {
		paymentStatus: [PaymentTypes.PaymentStatus.Paid],
	},
}

/** Наполняет Состояние данными сколько успешных покупок было сделано за всё время и за сегодня. */
export function useFillInStore() {
	const updateStore = useLeadsStatsStore((state) => state.updateStore)

	// Получить список покупок в зависимости от параметров
	const { data } = paymentsQuery.getPayments.useQuery(queryParams)

	useEffect(
		function () {
			if (!data) return

			updateStore({
				totalSuccessfulPurchasesNum: data.data.length,
				todaySuccessfulPurchasesNum: getTodaySuccessfulPurchasesNum(
					data.data
				),
			})
		},
		[data]
	)
}

/**
 * Принимает массив успешных оплат и вычисляет сколько оплат было сделано за сегодня
 * @param successPayments — массив успешных оплат
 */
function getTodaySuccessfulPurchasesNum(
	successPayments: PaymentsApiTypes.GetPayments
) {
	const todayDateFormatStr = dayjs(new Date()).format('DD MMMM YYYY')

	return successPayments.reduce((sum, item) => {
		const dateFormatStr = dayjs(item.created).format('DD MMMM YYYY')

		if (todayDateFormatStr == dateFormatStr) {
			return sum + 1
		}

		return sum
	}, 0)
}
