import { useTranslation } from 'react-i18next'
import useTrainingStatsStore from './zustand/store'
import LessonStatCard from '../LessonStatCard/LessonStatCard'
import './TrainingStats.scss'

function TrainingStats() {
	const { t } = useTranslation()

	const [lessons, trainingName] = useTrainingStatsStore((state) => [
		state.lessons,
		state.trainingName,
	])
	if (!lessons) return null

	return (
		<div className="visit-training">
			<h2 className="visit-training__header">
				Посещаемость уроков курса «{trainingName}»
			</h2>
			<div className="visit-training__cards">
				{lessons.map((lesson) => {
					return <LessonStatCard lesson={lesson} key={lesson.id} />
				})}
			</div>
		</div>
	)
}

export default TrainingStats
