import React from 'react'
import UploadAvatar from 'ui/upload/UploadFileInForm/UploadAvatar'
import { useGetCoverSrc } from './fn/getImageSrc'
import { useGetSaveUploadedFiles } from './fn/useGetSaveUploadedFiles'

// Загрузчик обложки курса
function LessonAvatarUploadInput() {
	const cover = useGetCoverSrc()
	const saveUploadedFiles = useGetSaveUploadedFiles()

	return (
		<UploadAvatar
			listType="picture-card"
			inputName="cover"
			initialFileList={cover}
			maxCount={1}
			getUploadedFiles={saveUploadedFiles}
		/>
	)
}

export default LessonAvatarUploadInput
