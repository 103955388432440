import { useEffect, useState } from 'react'
import useSystemStore from 'parts/systemStore/systemStore'
import useLivelessonStore from '../zustand/store'

function useIsTeacher() {
	const user = useSystemStore((s) => s.user)
	const live = useLivelessonStore((s) => s.live)
	const [isTeacher, setIsTeacher] = useState(false)

	useEffect(() => {
		setIsTeacher(user.id === live?.teacher.id)
	}, [live, user])

	return isTeacher
}

export default useIsTeacher
