import { create } from 'zustand'
import { ChangeEmailStoreType } from './storeTypes'

const useChangeEmailStore = create<ChangeEmailStoreType.State>((set) => {
	return {
		isOpen: false,
		isSubmitting: false,
		formErrors: {},

		userId: null,
		currentEmail: '',
		newEmail: '',
		password: '',

		updateState(obj) {
			return set((state) => {
				return obj
			})
		},

		/** Открытие модального окна */
		openModal(userId) {
			return set((state) => {
				return { isOpen: true, userId }
			})
		},

		/** Закрытие модального окна */
		closeModal() {
			return set((state) => {
				return { isOpen: false, userId: null }
			})
		},
	}
})

export default useChangeEmailStore
