// import React from 'react'
import cn from 'classnames'
import TakeAPartButton from '../../details/TakeAPartButton/TakeAPartButton'
import useLandingBuilderStore from '../../zustand/store'
import './MainSection.scss'

// Первая секция одностраничника
function MainSection() {
	const landing = useLandingBuilderStore((store) => store.landing)
	const { colorScheme } = landing

	return (
		<section
			className={cn(
				'landing-main-section',
				'landing-main-section--' + colorScheme
			)}
		>
			<div className="landing-main-section__left">
				<div className="landing-main-section__top-header-wrapper">
					<p
						className={cn(
							'landing-main-section__top-header',
							'landing-main-section__top-header--' + colorScheme
						)}
					>
						{landing.sections.main.texts.topHeader}
					</p>
				</div>
				<h1 className="landing-main-section__header">
					{landing.sections.main.texts.header}
				</h1>
				<p className="landing-main-section__description">
					{landing.sections.main.texts.description}
				</p>
				<TakeAPartButton sectionName="main" />
			</div>
			<div className="landing-main-section__right">
				<div>
					<img
						src={landing.sections.main.imageUrl}
						className="landing-main-section__cover"
						alt=""
					/>
				</div>
			</div>
		</section>
	)
}

export default MainSection
