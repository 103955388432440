import React, { MouseEventHandler, ReactElement, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Popover } from 'antd'
import './UTable.scss'

type UTableProps = {
	// Заголовок всей таблицы
	header?: ReactNode | string
	extraClass?: false | string
	children: ReactNode | ReactNode[]
}

export function UTable(props: UTableProps) {
	const { header, extraClass, children } = props

	return (
		<div className={cn('utable__table-wrapper', extraClass)}>
			{header && <h2 className="utable__table-header">{header}</h2>}
			<table className="utable">{children}</table>
		</div>
	)
}

// Ряд заголовка таблицы -------------------

type UTableHRowProps = {
	children: ReactNode | ReactNode[]
}

export function UTableHRow(props: UTableHRowProps) {
	const { children } = props

	return (
		<thead className="utable__head">
			<tr className="utable__head-row">{children}</tr>
		</thead>
	)
}

// Ячейка заголовка таблицы -------------------

type UTableHCellProps = {
	children: string | ReactNode
}

export function UTableHCell(props: UTableHCellProps) {
	const { children } = props

	const content =
		typeof children == 'string' ? (
			<p className="utable__head-cell-text">{children}</p>
		) : (
			children
		)

	return <th className="utable__head-cell">{content}</th>
}

// Тело таблицы -------------------

type UTableBodyProps = {
	children: ReactNode | ReactNode[]
}

export function UTableBody(props: UTableBodyProps) {
	const { children } = props

	return <tbody className="utable__body">{children}</tbody>
}

type UTableBRowProps = {
	// Нужно ли подсвечивать ряд при наведении. Требуется если в ячейках есть ссылка.
	bgOnHover?: boolean
	children: ReactNode | ReactNode[]
}

export function UTableBRow(props: UTableBRowProps) {
	const { bgOnHover, children } = props

	return (
		<tr
			className={cn(
				'utable__body-row',
				!!bgOnHover && 'utable__body-row--bg-on-hover'
			)}
		>
			{children}
		</tr>
	)
}

type UTableBCellProps = {
	href?: string
	children: string | ReactNode
	colSpan?: number
	extraClass?: string
}

export function UTableBCell(props: UTableBCellProps) {
	const { href, children, colSpan, extraClass } = props

	const content =
		typeof children == 'string' ? (
			<p className="utable__body-cell-text">{children}</p>
		) : (
			children
		)

	return (
		<td
			className={cn('utable__body-cell', extraClass)}
			colSpan={colSpan ?? 1}
		>
			{href && <Link to={href} className="utable__body-cell-link" />}
			{content}
		</td>
	)
}

type GroupNameProps = {
	text: number | string
	Icon: Function
	iconColor?: 'gray' | 'green'
}

// Текст со значком
export function UTableTextWithIcon(props: GroupNameProps) {
	const { text, Icon, iconColor = 'gray' } = props

	return (
		<div className="utable__twi">
			<Icon
				className={cn(
					'utable__twi-icon',
					'utable__twi-icon--' + iconColor
				)}
			/>
			<p className="utable__twi-text">{text}</p>
		</div>
	)
}

type HeaderCellSearchContentProps = {
	// Текст в ячейке
	text: string
	// Показывать ли кнопку очистки результата?
	isClearBtnVisible: boolean
	// Функция, запускаемая при нажатии на кнопку очистки значения
	onClearBtnClick: MouseEventHandler
	// Кнопка поиска открывающая форму поиска
	buttonWithForm?: ReactElement
}

// Обёртка для контента ячейки заголовка с текстом и кнопками очистки поиска и кнопкой открывающей форму поиска
export function UTableHeaderCellSearchContent(
	props: HeaderCellSearchContentProps
) {
	const { text, isClearBtnVisible, onClearBtnClick, buttonWithForm } = props

	return (
		<div className="utable__head-cell-search-content">
			<p className="utable__head-cell-search-content-text">{text}</p>
			<div className="utable__head-cell-search-content-buttons">
				{isClearBtnVisible && (
					<UTableHeaderCellClearButton handler={onClearBtnClick} />
				)}
				{buttonWithForm && buttonWithForm}
			</div>
		</div>
	)
}

type UTableHeaderCellClearButtonProps = {
	handler: MouseEventHandler
}

// Кнопка очистки фильтрации по параметру
function UTableHeaderCellClearButton(props: UTableHeaderCellClearButtonProps) {
	const { handler } = props

	return (
		<Button
			icon={
				<CloseCircleOutlined className="utable__head-cell-search-content-icon" />
			}
			size="small"
			type="text"
			className="utable__head-cell-search-content-button"
			onClick={handler}
		/>
	)
}

type UTableSearchButton = {
	// Форма поиска, открываемая при нажатии на кнопку поиска
	ButtonWithForm: ReactElement
}

// Кнопка поиска, вставляемая в заголовочную ячейку таблицы, открывающая форму поиска
export function UTableSearchButton(props: UTableSearchButton) {
	const { ButtonWithForm } = props

	return (
		<Popover placement="bottom" content={ButtonWithForm} trigger="click">
			<Button
				icon={
					<SearchOutlined className="utable__head-cell-search-content-icon" />
				}
				size="small"
				type="text"
				className="utable__head-cell-search-content-button"
			/>
		</Popover>
	)
}
