import { message, UploadFile } from 'antd'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import { getFirstLoadedFileUrl } from 'ui/upload/UploadFileInForm/fn/fn'
import useTrainingHeaderAdminStore from '../../zustand/store'

export function useGetSaveUploadedFiles() {
	const training = useTrainingHeaderAdminStore((store) => store.training)

	const successTrainingMutation = useGetMutateOnSuccess()

	const updateTrainingMutation = trainingQuery.updateTraining.useMutation({
		onError: useGetMutateOnError(),
		onSuccess: successTrainingMutation,
	})

	return function (files: UploadFile[]) {
		const trainingCopy = Object.assign(training, {
			cover: getFirstLoadedFileUrl(files) || '',
		})

		updateTrainingMutation.mutate(trainingCopy)
	}
}

/** Функция, выполняемая при появлении ошибки после запроса на добавление сотрудника в группы */
function useGetMutateOnError() {
	const { t } = useTranslation()

	return function (err: unknown) {
		message.error(t('adminTraining.errorWhileUpdateTraining'))
	}
}

/** Функция, выполняемая при успешном запросе изменение данных курса */
function useGetMutateOnSuccess() {
	const queryClient = useQueryClient()

	return function () {
		queryClient.refetchQueries({
			queryKey: [trainingQuery.getTrainingsForAdmin.key],
		})
	}
}
