import { BuildOutlined, ScheduleOutlined } from '@ant-design/icons'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import VerticalList from 'ui/VerticalList/VerticalList'
import { getStatisticTexts } from './fn/getStats'

type InfoProps = {
	lessonIdx: number // Порядковый номер урока
	lesson: TrainingEntityTypes.LessonAdmin
}

function Info(props: InfoProps) {
	const { lessonIdx, lesson } = props
	const { lessonsStr, exercisesStr } = getStatisticTexts(lessonIdx, lesson)

	return (
		<VerticalList
			items={[
				{ text: lessonsStr, icon: <ScheduleOutlined /> },
				{ text: exercisesStr, icon: <BuildOutlined /> },
			]}
		/>
	)
}

export default Info
