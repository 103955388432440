import React from 'react'
import { Button, Checkbox, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import ModalWithForm from 'ui/ModalWithForm/ModalWithForm'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'
import { FieldNames, setErrorsToForm } from './fn/form'
import { useGetSetVisibilityToAddStudentModal } from './fn/openCloseModal'
import {
	UFormNewBlockHeader,
	UFormNewBottomButtonsAfterGray,
	UFormNewButtonsContainer,
	UFormNewDivider,
	UFormNewGeneralError,
	UFormNewRow,
} from 'ui/UForm/UForm'
import Site from 'parts/constants/site'
import PurchaseForm from './PurchaseForm'
import { useGetOnSubmit } from './fn/submit'
import {
	getEmailInputSuffix,
	getEmailInputValidator,
	useCheckIsEmailExists,
} from 'parts/utils/hooks/useManageSignInEmailInput'
import useAddStudentStore from './zustand/store'

// Модальное окно добавления нового ученика
function AddStudentModal() {
	const { t } = useTranslation()
	const [form] = Form.useForm()

	const onSubmit = useGetOnSubmit(form)
	setErrorsToForm(form)

	const modalState = useAddStudentStore((state) => state)
	const closeModal = useGetSetVisibilityToAddStudentModal(false)

	return (
		<ModalWithForm
			title={t('students.addStudentModalTitle')}
			open={modalState.isOpen}
			onCancel={closeModal}
		>
			<Form
				form={form}
				name="addStudent"
				layout="vertical"
				onFinish={onSubmit}
			>
				<GrayBlock>
					<UFormNewBlockHeader
						text={t('students.addStudentModalPersonalBlockHeader')}
					/>
					<EmailAndPhoneInputs />
					<NameAndSurnameInputs />
					<UFormNewDivider />
					<PurchaseForm />
					<UFormNewDivider />
					<GetAccessToAccountInput />
				</GrayBlock>
				<Bottom />
				<UFormNewGeneralError message={modalState.formErrors.message} />
			</Form>
		</ModalWithForm>
	)
}

export default AddStudentModal

// Поля с почтой и телефоном
function EmailAndPhoneInputs() {
	const { t } = useTranslation()

	const { onChangeEmailInput, emailCheckStatus } = useCheckIsEmailExists()
	const EmailInputSuffix = getEmailInputSuffix(emailCheckStatus)

	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.Email}
				label={t('students.addStudentModalFieldEmailLabel')}
				rules={[
					{
						required: true,
						message: t(
							'students.addStudentModalFieldEmailRequiredError'
						),
					},
					getEmailInputValidator(emailCheckStatus),
				]}
			>
				<Input
					type="email"
					placeholder={Site.exampleEmail}
					suffix={<EmailInputSuffix />}
					onChange={onChangeEmailInput}
				/>
			</Form.Item>
			<Form.Item
				name={FieldNames.Phone}
				label={t('students.addStudentModalFieldPhoneLabel')}
			>
				<Input type="phone" />
			</Form.Item>
		</UFormNewRow>
	)
}

// Поля с именем и фамилией
function NameAndSurnameInputs() {
	const { t } = useTranslation()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name={FieldNames.Name}
				label={t('students.addStudentModalFieldNameLabel')}
			>
				<Input />
			</Form.Item>
			<Form.Item
				name={FieldNames.Surname}
				label={t('students.addStudentModalFieldSurnameLabel')}
			>
				<Input />
			</Form.Item>
		</UFormNewRow>
	)
}

// Флаг Выдать доступ в личный кабинет
function GetAccessToAccountInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow>
			<Form.Item name={FieldNames.GetAccess} valuePropName="checked">
				<Checkbox>
					{t('students.addStudentModalFieldGetAccessText')}
				</Checkbox>
			</Form.Item>
		</UFormNewRow>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
// Кнопки подтверждения и закрытия окна
function Bottom() {
	const { t } = useTranslation()

	const isSubmitting = useAddStudentStore((state) => state.isSubmitting)
	const closeModal = useGetSetVisibilityToAddStudentModal(false)

	return (
		<UFormNewBottomButtonsAfterGray>
			<div />
			<UFormNewButtonsContainer>
				<Button onClick={closeModal}>Отмена</Button>
				<Button
					type="primary"
					htmlType="submit"
					disabled={isSubmitting}
					loading={isSubmitting}
				>
					{t('students.addStudentModalOnButton')}
				</Button>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
