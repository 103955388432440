import { useEffect, useState } from 'react'
import { useIsFirstRender } from 'parts/utils/hooks/hooks'
import { SaveExerciseChangesMadeByUser } from '../../../common/useLiftViewDuration'
import useExerciseStore from '../../zustand/exerciseState'
import ExerciseStateType from '../../zustand/ExerciseStateType'

/**
 * Наблюдает за объектом состояния упражнения и передаёт функции сохраняющей его на сервере
 * чтобы при следующем открытии упражнения восстановить это состояние.
 * @param saveExerciseChangesMadeByUser — функция, которая будет вызываться при изменении упражнения пользователем.
 * В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
 */
export function useSaveStateOnServerForRestore(
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
) {
	// Задержка, чтобы не сохранял данные после открытия страницы
	const isFirstRender = useIsFirstRender(2000)
	const store = useExerciseStore((store) => store)

	useEffect(
		function () {
			if (isFirstRender || !saveExerciseChangesMadeByUser) return

			saveExerciseChangesMadeByUser({
				currentStatementIdx: store.currentStatementIdx,
				statements: store.statements,
				readyToShowResult: store.readyToShowResult,
				doneCounter: store.doneCounter,
				showResult: store.showResult,
				durationsInSeconds: store.durationsInSeconds,
				statistics: store.statistics,
			})
		},
		[store.saveStoreForRestore]
	)
}

export function useRestoreStore(
	exerciseRestoreDataPromise: Promise<ExerciseStateType.State>
) {
	const statements = useExerciseStore((store) => store.statements)
	const updateStore = useExerciseStore((store) => store.updateStore)

	const [statementsIsReady, setStatementsIsReady] = useState(false)

	useEffect(
		function () {
			if (!statements) return

			setStatementsIsReady(true)
		},
		[statements]
	)

	useEffect(
		function () {
			if (!statementsIsReady) return

			exerciseRestoreDataPromise.then((restoreData) => {
				if (!restoreData) return

				updateStore(createRestoredStore(statements, restoreData))
			})
		},
		[statementsIsReady]
	)
}

function createRestoredStore(
	statements: ExerciseStateType.Statement[],
	exerciseRestoreData: ExerciseStateType.State
) {
	const newStatements = statements.map((statement, i) => {
		const savedStatement = exerciseRestoreData.statements[i]

		if (savedStatement) {
			return {
				...statement,
				userAnswer: savedStatement.userAnswer,
			}
		}

		return statement
	})

	return {
		currentStatementIdx: exerciseRestoreData.currentStatementIdx,
		statements: newStatements,
		readyToShowResult: exerciseRestoreData.readyToShowResult,
		showResult: exerciseRestoreData.showResult,
		doneCounter: exerciseRestoreData.doneCounter,
		durationsInSeconds: exerciseRestoreData.durationsInSeconds,
		statistics: exerciseRestoreData.statistics,
	}
}
