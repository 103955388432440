import { MouseEvent } from 'react'

/**
 * Обработчик щелчка по кнопке перехода к разделу тарифов. Если нажали на редактируемый текст, то ничего не происходит.
 */
export function useGetOnTakeAPartButtonClick() {
	return function (event: MouseEvent) {
		// @ts-ignore
		if (event.target.hasAttribute('contenteditable')) {
			event.stopPropagation()
			return
		}

		const $tariffsSection = document.querySelector('#tariffs-section')

		if ($tariffsSection) {
			$tariffsSection.scrollIntoView({
				behavior: 'smooth',
			})
		}
	}
}
