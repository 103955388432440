const studentsEn = {
	// Кнопка добавления ученика
	addStudentButton: 'Add',

	// МОДАЛЬНОЕ ОКНО ДОБАВЛЕНИЯ УЧЕНИКА
	// Заголовок модального окна
	addStudentModalTitle: 'Adding a student',
	// Заголовок блока «Личные данные»
	addStudentModalPersonalBlockHeader: 'Personal data',
	// Подпись поля «Почта»
	addStudentModalFieldEmailLabel: 'E-mail',
	// Сообщение об ошибке при незаполненной почте
	addStudentModalFieldEmailRequiredError: 'Indicate mail',
	// Подпись поля «Почта»
	addStudentModalFieldPhoneLabel: 'Phone number',
	// Подпись поля «Имя»
	addStudentModalFieldNameLabel: 'Name',
	// Подпись поля «Фамилия»
	addStudentModalFieldSurnameLabel: 'Surname',
	// Подпись флага «Выдать доступ в личный кабинет»
	addStudentModalFieldGetAccessText: 'Grant access to the personal account',
	// Текст на кнопке сохранения формы добавления ученика
	addStudentModalOnButton: 'Save',
	// Сообщение про ошибку при создании оплаты
	addStudentModalManualPaymentReq: 'Failed to create a payment',
	// Сообщение про ошибку про добавление ученика куратору
	addStudentModalAddStudentToCuratorReq:
		'Failed to add a student to the supervisor',
	// Сообщение про ошибку при запросе на отправку ученику доступа к личному кабинету
	addStudentModalSendAccessReq: 'Failed to add a student',

	// ФОРМА ЗАКАЗА
	// Заголовок блока «Покупка»
	purchaseFormPersonalBlockHeader: 'Purchase',
	// Подпись поля с выбора группы
	purchaseFormFieldGroupLabel: 'Group',
	// Ошибка про обязательное заполнение поля выбора группы
	purchaseFormFieldGroupRequiredError: 'Indicate the group',
	// Подпись поля даты покупки
	purchaseFormFieldPurchaseDateLabel: 'Date of purchase',
	// Ошибка про обязательное заполнение поля выбора группы
	purchaseFormFieldPurchaseDateRequiredError: 'Enter the date of purchase',
	// Подпись поля способа оплаты
	purchaseFormFieldPaymentSystemLabel: 'Payment method::',
	// Ошибка про обязательное заполнение поля способа оплаты
	purchaseFormFieldPaymentSystemRequiredError: 'Specify payment method',
	// Подпись выпадающего списка выбора валюты
	purchaseFormFieldCurrencyLabel: 'Currency',
	// Ошибка про обязательное поле выбора валюты
	purchaseFormFieldCurrencyRequiredError: 'Indicate currency',
	// Подпись поля стоимости
	purchaseFormFieldAmountLabel: 'Price',
	// Ошибка про обязательное поле стоимости
	purchaseFormFieldAmountRequiredError: 'Specify price',
	// Подпись выпадающего списка выбора куратора
	purchaseFormFieldCuratorLabel: 'Curator',
	// Невыбранный пункт выпадающего списка выбора куратора
	purchaseFormFieldCuratorEmptyItem: 'Not selected',
	// Кнопка удаления покупки
	purchaseFormDeletePurchaseButton: 'Delete purchase',
	// Кнопка добавления покупки
	purchaseFormAddPurchaseButton: 'Add purchase',

	// ЭКРАН ПРО ОТСУТСТВИЕ УЧЕНИКОВ
	// Первая строка сообщения про отсутствие учеников
	noStudentsScreenFirstTextLine: 'There are no students.',
	// Вторая строка сообщения про отсутствие учеников
	noStudentsScreenSecondTextLine: 'Start creating right now!',

	// СТАТИСТИКА ПО УЧЕНИКАМ
	// Заголовок статистики по общему количеству учеников
	studentsStatsTotalStudentsHeader: 'Number of students (total)',
	// Заголовок статистики по количеству новых учеников
	studentsStatsTodayStudentsHeader: 'New students (for today)',

	// ТАБЛИЦА УЧЕНИКОВ
	// Статус Ученик активирован
	studentsTableStudentActivated: 'Activated',
	// Статус Ученик не активирован
	studentsTableStudentNotActivated: 'Not activated',
	// Заголовок ряда Имя и фамилия
	studentsTableHeaderNameAndSurname: 'Name and surname',
	// Заголовок ряда Почта
	studentsTableHeaderEmail: 'E-mail',
	// Заголовок ряда Телефон
	studentsTableHeaderPhone: 'Phone number',
	// Заголовок ряда Страна
	studentsTableHeaderCountry: 'Country',
	// Заголовок ряда Статус
	studentsTableHeaderStatus: 'Status',
}

export default studentsEn
