import useLessonsPresetsStore from 'widgets/presets/zustand/store'

export const useGetClosePresetsListDrawer = () => {
	const updateStore = useLessonsPresetsStore((state) => state.updateStore)

	return function () {
		updateStore({ isOpenPresetsListDrawer: false })
	}
}

export const useGetOpenPresetsListDrawer = () => {
	const updateStore = useLessonsPresetsStore((state) => state.updateStore)

	return function () {
		updateStore({ isOpenPresetsListDrawer: true })
	}
}
