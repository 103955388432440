import React from 'react'
import { Button } from 'antd'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import { Loader } from 'ui'
import CancelSubscriptionModal from '../CancelSubscriptionModal/CancelSubscriptionModal'
import { useGetToggleVisibilityToCancelSubscriptionModal } from '../CancelSubscriptionModal/fn/openCloseCancelSubscriptionModal'
import GiveImpressionModal from '../GiveImpressionModal/GiveImpressionModal'
import NoPayments from '../NoPayments/NoPayments'
import PresentModal from '../PresentModal/PresentModal'
import ReasonModal from '../ReasonModal/ReasonForm'
import SubscriptionCanceledModal from '../SubscriptionCanceledModal/SubscriptionCanceledModal'
import TakePauseModal from '../TakePauseModal/TakePauseModal'
import { useFillStore } from '../zustand/fn/fillStore'
import useHistoryStore from '../zustand/store'
import HistoryTable from '../HistoryTable/HistoryTable'
import s from './MyPaymentsHistoryArticle.module.scss'

// Статья со всеми школами пользователя
function MyPaymentsHistoryArticle() {
	const dataLoadingStatus = useHistoryStore(
		(store) => store.dataLoadingStatus
	)
	useFillStore()

	return (
		<ArticleContentWrapper innerWrapperWidth="big">
			{dataLoadingStatus === 'loading' && <Loader />}
			{dataLoadingStatus === 'noItems' && <NoPayments />}
			{dataLoadingStatus === 'hasItems' && <Content />}
		</ArticleContentWrapper>
	)
}

export default MyPaymentsHistoryArticle

function Content() {
	const openCancelSubscriptionModal =
		useGetToggleVisibilityToCancelSubscriptionModal()

	return (
		<div>
			<HistoryTable />
			<div className={s.cancelSubscriptionWrapper}>
				<Button type="link" onClick={openCancelSubscriptionModal}>
					Отменить подписку
				</Button>
			</div>
			<CancelSubscriptionModal />
			<ReasonModal />
			<TakePauseModal />
			<GiveImpressionModal />
			<PresentModal />
			<SubscriptionCanceledModal />
		</div>
	)
}
