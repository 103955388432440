import Card from 'pages/landing/landingConstructor/tariffs/card/Card/Card'
import tariffsManager from 'pages/landing/landingConstructor/tariffs/tariffsManager/tariffsManager'
import 'pages/landing/landingConstructor/tariffs/content/Cards/Cards.scss'

// Карточки тарифов
function Cards() {
	const tariffs = tariffsManager.store.tariffs

	return (
		<div className="tariff-cards">
			{tariffs.map((tariff) => {
				return <Card tariff={tariff} key={tariff.innerId} />
			})}
		</div>
	)
}

export default Cards
