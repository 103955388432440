export namespace AudioBlockStateType {
	// Состояние AudioAnswerBlock
	export type Main = {
		dictaphone: Dictaphone
		players: Player[]
	}

	// Данные диктофона
	export type Dictaphone = {
		stream: null | MediaStream
		lifeStatus: 'empty' | 'recording' // В каком виде находится микрофон
		mediaRecorder: null | MediaRecorder // Ссылка на объект с API записи медиа
		recordedBlobs: null | Blob // Записанные куски данных
		seconds: number // Сколько идёт запись в секундах
		intervalId: null | NodeJS.Timer // id интервала увеличивающий количество проигрываемых секунд
	}

	// Данные проигрывателя
	export type Player = {
		id: number
		lifeStatus: 'stop' | 'play' // В каком виде находится микрофон
		seconds: number // Сколько идёт запись в секундах
		virtualPlayer: any // Ссылка на <audio> который требуется для воспроизведения записанного звука. Не получилось дать правильный тип из-за ошибки в иммере.
		intervalId: null | NodeJS.Timer // id интервала увеличивающий количество проигрываемых секунд
		blob: null | Blob // Записанные данные
	}
}

// Объект пустого состояния компонента AudioAnswerBlock
export const emptyState: AudioBlockStateType.Main = {
	dictaphone: {
		stream: null,
		lifeStatus: 'empty',
		mediaRecorder: null,
		recordedBlobs: null,
		seconds: 0,
		intervalId: null,
	},
	players: [],
}

/**
 * Функция возвращает новый объект состояния проигрывателя
 * @param {Object} mediaStream — звуковой поток
 */
export function getEmptyDictaphone(
	mediaStream: MediaStream
): AudioBlockStateType.Dictaphone {
	return {
		stream: mediaStream,
		lifeStatus: 'empty',
		mediaRecorder: null,
		recordedBlobs: null,
		seconds: 0,
		intervalId: null,
	}
}
