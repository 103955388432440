import { useCallback, useContext } from 'react'
import { StateContext } from '../../state/stateContext'
import { StateType } from '../../state/stateType'

/** Возвращает функцию, открывающую модальное окно удаления школы */
export function useGetOpenDeleteSchoolModal() {
	const { state, setState } = useContext(StateContext)

	return useCallback(function () {
		setState((state): StateType.State => {
			return {
				...state,
				isRemoveSchoolModalOpen: true,
			}
		})
	}, [])
}

/** Возвращает функцию, закрывающую модальное окно создания новой школы */
export function useGetCloseDeleteSchoolModal() {
	const { state, setState } = useContext(StateContext)

	return useCallback(
		function () {
			if (state.removeSchoolSubmitting) {
				return
			}

			setState((state): StateType.State => {
				return { ...state, isRemoveSchoolModalOpen: false }
			})
		},
		[state]
	)
}
