import dayjs from 'dayjs'
import { FormInstance, UploadFile } from 'antd'
import EntityTypes from 'parts/types/EntityTypes'
import { useEffect } from 'react'
import { createFileListFromUrlList } from 'ui/upload/UploadFileInForm/fn/fn'
import { setErrorsToFields } from 'parts/utils/form'
import useProfileModalStore from '../zustand/store'

export enum FieldNames {
	FirstName = 'firstName',
	LastName = 'lastName',
	Email = 'email',
	Phone = 'phone',
	Instagram = 'instagram',
	Country = 'country',
	City = 'city',
	Birthday = 'birthday',
	Gender = 'gender',
	Avatar = 'avatar',
}

export type FormValuesType = {
	[FieldNames.FirstName]: string
	[FieldNames.LastName]: string
	[FieldNames.Email]: string
	[FieldNames.Phone]: string
	[FieldNames.Instagram]: string
	[FieldNames.Country]: string
	[FieldNames.City]: string
	[FieldNames.Birthday]: null | dayjs.Dayjs
	[FieldNames.Gender]?: EntityTypes.Gender
	[FieldNames.Avatar]: UploadFile[]
}

export const dateFormat = 'DD.MM.YYYY'

/**
 * Возвращает первоначальные значения полей формы редактирования пользователя.
 * @param {Object} user — данные пользователя
 */
export function getInitialValues(user: EntityTypes.User): FormValuesType {
	const birthday = user?.birthday ? dayjs(user.birthday) : null

	return {
		[FieldNames.FirstName]: user.firstName || '',
		[FieldNames.LastName]: user.lastName || '',
		[FieldNames.Email]: user.email,
		[FieldNames.Phone]: user.phone,
		[FieldNames.Instagram]: user.instagram,
		[FieldNames.Country]: user.country || '',
		[FieldNames.City]: user.city || '',
		[FieldNames.Birthday]: birthday,
		[FieldNames.Gender]: user.sex,
		[FieldNames.Avatar]: user.avatar
			? createFileListFromUrlList([user.avatar])
			: [],
	}
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useProfileModalStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
