import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/ru'
import LangService from '../i18n/LangService'
import EntityTypes from '../types/EntityTypes'

// проверяем есть ли среди выбранных дней выходные/будни/либо каждый день
export const modifyWeekdaysOptions = (
	daysOptions: EntityTypes.SelectDayOptions[],
	selected: number[]
): EntityTypes.SelectDayOptions[] => {
	let modifiedOptions

	if (!selected.length) {
		modifiedOptions = daysOptions.map((option) => {
			option.disabled = false
			return option
		})

		return modifiedOptions
	}

	if (selected.includes(EntityTypes.OpeningByWeekDays.OnWeekends)) {
		modifiedOptions = daysOptions.map((option) => {
			if (option.value !== EntityTypes.OpeningByWeekDays.OnWeekends) {
				option.disabled = true
			}

			return option
		})
		return modifiedOptions
	} else if (selected.includes(EntityTypes.OpeningByWeekDays.OnWeekdays)) {
		modifiedOptions = daysOptions.map((option) => {
			if (option.value !== EntityTypes.OpeningByWeekDays.OnWeekdays) {
				option.disabled = true
			}

			return option
		})
		return modifiedOptions
	} else if (selected.includes(EntityTypes.OpeningByWeekDays.Everyday)) {
		modifiedOptions = daysOptions.map((option) => {
			if (option.value !== EntityTypes.OpeningByWeekDays.Everyday) {
				option.disabled = true
			}

			return option
		})

		return modifiedOptions
	} else {
		modifiedOptions = daysOptions.map((option) => {
			option.disabled = false
			return option
		})

		return modifiedOptions
	}
}

/**
 * Функция принимате количество миллисекунд, а возвращает строку вида 00:01:53 где количество миллесекунд разбито на часты, минуты и секунды.
 * @param {Number} ms — количество миллисекунд
 */
export function getPassedTime(ms: number) {
	const sec = Math.round(ms / 1000)

	const hours = Math.floor(((sec % 31536000) % 86400) / 3600)
	const minutes = Math.floor((((sec % 31536000) % 86400) % 3600) / 60)
	const seconds = (((sec % 31536000) % 86400) % 3600) % 60

	let text = ''

	if (hours > 0) {
		text =
			(hours < 10 ? '0' + hours : hours) +
			':' +
			(minutes < 10 ? '0' + minutes : minutes) +
			':' +
			(seconds < 10 ? '0' + seconds : seconds)
	} else {
		text =
			(minutes < 10 ? '0' + minutes : minutes) +
			':' +
			(seconds < 10 ? '0' + seconds : seconds)
	}

	return text
}

/**
 * Принимает строку вида "2022-07-14T11:19:22.601Z" и возвращает вида 23 октября 2022
 * @param {String} time — дата
 */
export function dateStrToHumanDateStr(time: Date | string | dayjs.Dayjs) {
	const lang =
		LangService.getCurrentLangFromLocalStorage().toLocaleLowerCase()
	return dayjs(time).locale(lang).format('DD MMMM YYYY')
}

/**
 * Принимает строку вида "2022-07-14T11:19:22.601Z" и возвращает время вида 17:30
 * @param {String} time — дата
 */
export function dateStrToHumanTimeStr(time: Date | string | dayjs.Dayjs) {
	return dayjs(time).locale('ru').format('HH:mm')
}

/**
 * Принимает строку вида "2022-07-14T11:19:22.601Z" и возвращает время вида 23 октября 2022 в 17:30
 * @param {String} time — дата
 */
export function dateStrToHumanDateAndTime(time: Date | string | dayjs.Dayjs) {
	return dateStrToHumanDateStr(time) + ' в ' + dateStrToHumanTimeStr(time) //'17:30'
}

/**
 * Переданная дата уже истекла?
 * @param {String} date — дата, которую нужно проверить
 */
export function isDatePassed(date: string | Date) {
	const checkDate = typeof date == 'string' ? new Date(date) : date
	const nowDate = new Date()

	return nowDate > checkDate
}

/**
 * Прибавляет к дате указанное количество дней
 * @param {Date} date — дата, которой нужно прибавить дни
 * @param {Number} days — количество дней, которые нужно прибавить к дате
 */
export function addDaysToDate(date: Date, days: number) {
	date.setDate(date.getDate() + days)
}

/**
 * Принимает дату и возвращает строковое представление по формату принятому в dayjs.
 * @param {Object} date — дату в виде строки или объекта.
 * @param {String} format — формат результирующей строки, который применяется в dayjs.
 */
export function getStrDateFromDate(
	date: string | Date | dayjs.Dayjs,
	format = 'YYYY-MM-DD'
) {
	return dayjs(date).format(format)
}

/**
 * Принимает строку ISO 8601 и возвращает дату формата DD-MM-YYYY
 * для предзаполнения формы создания live-урока
 */
export function getDateFromStr(isoStr: string, format = 'DD-MM-YYYY') {
	return dayjs(isoStr).format(format)
}

/**
 * Принимает строку ISO 8601 и возвращает время формата HH:mm
 * для предзаполнения формы создания live-урока
 */
export function getTimeFromStr(isoStr: string, format = 'HH:mm') {
	return dayjs(isoStr).format(format)
}
