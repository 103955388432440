import { SyntheticEvent } from 'react'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import useDeleteModalStore from 'pages/modal/DeleteModal/store/store'
import { trainingQuery } from 'parts/requests/training/trainingQuery'
import { TrainingListController } from '../../../TrainingListController'

/**
 * Возвращает обработчик удаления курса
 * @param trainingId — id курса
 */
export function useDeleteTraining(trainingId: number) {
	const { t } = useTranslation()
	const updateStore = useDeleteModalStore((state) => state.updateStore)

	// Функция помечает список курсов неактуальным.
	// После этого React Query автоматически скачает новый список
	const updateDataInTrainingsList = TrainingListController.useGetUpdate()

	const { mutate } = trainingQuery.deleteTraining.useMutation({
		onError: () => {
			message.error(
				t('adminTrainings.trainingCardTrainingDeletedMessage')
			)
		},
		onSuccess: () => {
			message.success(
				t('adminTrainings.trainingCardTrainingDeletedMessage')
			)
			// Пометить список групп неактуальным.
			// После этого React Query скачает новый список
			updateDataInTrainingsList()
		},
	})

	return function (event: SyntheticEvent) {
		event.preventDefault()

		updateStore({
			isOpen: true,
			header: t('adminTrainings.trainingCardDeleteModalHeader'),
			text: t('adminTrainings.trainingCardDeleteModalText'),
			onOk: () => mutate(trainingId),
		})
	}
}
