import { useState } from 'react'
import { useGetStoreAnswer } from './storeCommentAnswer'
import EntityTypes from 'parts/types/EntityTypes'

function useAnswerToComment() {
	const onAnswerToComment = useGetStoreAnswer()
	const [isShowDialog, setIsShowDialog] = useState(false)
	const [item, setItem] = useState<EntityTypes.CommentItem | null>(null)

	const onDialogOk = (answer: string) => {
		if (item && answer) {
			onAnswerToComment(item.id, answer)
		}
		setIsShowDialog(false)
	}

	const onDialogCancel = () => {
		setIsShowDialog(false)
	}

	const answerToComment = (comment: EntityTypes.CommentItem) => {
		setItem(comment)
		setIsShowDialog(true)
	}

	return {
		isShowDialog,
		onDialogOk,
		onDialogCancel,
		answerToComment,
		answerText: item?.answer,
	}
}

export default useAnswerToComment
