const filesEn = {
	// СТРАНИЦА ФАЙЛОВ ЭФИРА
	// Заголовок страницы
	pageHeader: 'Files for live lessons',

	// ТАБЛИЦА ФАЙЛОВ К ЭФИРАМ
	// Текст «Файлы к занятиям»
	filesTableExercisesFiles: 'Files for classes ',
	// Кнопка «Новая папка»
	filesTableNewFolderBtn: 'New folder',
	// Кнопка «Загрузить файл»
	filesTableUploadFileBtn: 'Download the file',
	// Заголовок всплывашки подтверждения удаления файла
	filesTableDeletePopupHeader: 'Delete',
	// Кнопка во всплывашке подтверждения удаления файла
	filesTableDeletePopupBtn: 'Delete',
	// Кнопка «Добавить в класс»
	filesTableAddToClassBtn: 'Add to class',
	// Кнопка «Добавить в класс»
	filesTableSendToChatBtn: 'Send to chat',
	// Кнопка «Добавить в класс»
	filesTableDownloadBtn: 'Download ',
	// Заголовок модального окна загрузки файла
	filesTableUploadFileModalHeader: 'Download file',
	// Кнопка отмены модального окна загрузки файла
	filesTableUploadFileModalCancelBtn: 'Cancel',
	// Текст модального окна загрузки файла
	filesTableUploadFileModalText: 'Click or drag the file here to download it',
}

export default filesEn
