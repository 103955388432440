import { FormInstance } from 'antd'
import { useEffect } from 'react'
import { setErrorsToFields } from 'parts/utils/form'
import useChangePasswordStore from '../zustand/store'

export enum FieldNames {
	CurrentPassword = 'currentPassword',
	NewPassword = 'newPassword',
	NewPasswordAgain = 'newPasswordAgain',
}

export type FormValues = {
	[FieldNames.CurrentPassword]: string
	[FieldNames.NewPassword]: string
	[FieldNames.NewPasswordAgain]: string
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useChangePasswordStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
