import React from 'react'

function RadioThin() {
	return (
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 13.8C11.2033 13.8 13.8 11.2033 13.8 8C13.8 4.79675 11.2033 2.2 8 2.2C4.79675 2.2 2.2 4.79675 2.2 8C2.2 11.2033 4.79675 13.8 8 13.8ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
		/>
	)
}

export default RadioThin
