import { FormInstance } from 'antd'
import { AxiosError } from 'axios'
import ServerTypes from 'parts/types/ServerTypes'
import AuthApiTypes from 'parts/requests/auth/authApiTypes'
import { FormValues } from './form'
import useChangeEmailStore from '../state/store'
import authRequests from 'parts/requests/auth/authRequest'
import useSetConfirmationCodeStore from '../../SetConfirmationCodeModal/state/store'

/** Хук возвращающий обработчик отправки формы */
export function useGetOnSubmit(form: FormInstance) {
	const [updateState, userId] = useChangeEmailStore((state) => [
		state.updateState,
		state.userId,
	])

	const updateConfirmationCodeStore = useSetConfirmationCodeStore(
		(state) => state.updateState
	)

	return async (values: FormValues) => {
		if (!userId) return

		try {
			// Поставить введённые данные в Состояние.
			// Они потребуются во второй форме для отправки запроса смены почты.
			updateState({
				isSubmitting: true,
				currentEmail: values.currentEmail,
				newEmail: values.newEmail,
				password: values.password,
			})

			// Отправить код подтверждения
			const response = await authRequests.sendEmailCode(
				createDto(values, userId)
			)

			if (response.data) {
				// Закрыть модальное окно
				updateState({ isOpen: false })

				// Открыть окно ввода кода подтверждения почты
				updateConfirmationCodeStore({ isOpen: true })
			}
		} catch (err) {
			const error = err as AxiosError<ServerTypes.ErrorResponse>

			if (error.response) {
				// Поставить данные ошибки в Состояние, чтобы показать их в форме
				updateState({ formErrors: error.response.data })
			}
		} finally {
			// Убрать статус загрузки
			updateState({ isSubmitting: false })

			// Стереть значения во всех полях
			form.resetFields()
		}
	}
}

/**
 * Формирует DTO для отправки запроса
 * @param {Array} values — массив значений полей формы
 * @param {Number} userId — id пользователя
 */
function createDto(
	values: FormValues,
	userId: number
): AuthApiTypes.SendEmailCodeDto {
	return {
		userId,
		email: values.newEmail,
	}
}
