import ProgramBlocks from '../ProgramBlocks/ProgramBlocks'

function SectionContent() {
	return (
		<div>
			<ProgramBlocks />
		</div>
	)
}

export default SectionContent
