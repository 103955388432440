import { message } from 'antd'
import { AxiosResponse } from 'axios'
import { produce } from 'immer'
import TrainingEntityTypes from 'parts/types/TrainingEntityTypes'
import { lessonsQuery } from 'parts/requests/lessons/lessonsQuery'
import { QueryClient, useQueryClient } from 'react-query'
import useLessonPageHeaderAdminStore from '../../zustand/store'

/** Возвращает заголовок страницы и описание курса */
export function useGetInitialHeaderText() {
	const headerTexts = { initPageHeader: '', initPageDescription: '' }

	const lessonAdmin = useLessonPageHeaderAdminStore(
		(state) => state.lessonAdmin
	)
	if (!lessonAdmin) return headerTexts

	return {
		initPageHeader: lessonAdmin.name,
		initPageDescription: lessonAdmin.description || '',
	}
}

/**
 * Возвращает обработчик изменения текста заголовка.
 * При изменении на сервер делается запрос на сохранение нового имени группы.
 */
export function useGetSaveHeaderTextOnServerAfterChange(
	trainingPropName: keyof TrainingEntityTypes.LessonAdmin
) {
	const queryClient = useQueryClient()

	const lessonAdmin = useLessonPageHeaderAdminStore(
		(state) => state.lessonAdmin
	)

	const updateLessonMutation = lessonsQuery.updateLesson.useMutation({
		onSuccess: (res) => mutateOnSuccess(queryClient, res),
		onError: mutateOnError,
	})

	return function (propValue: any) {
		const lessonCopy = produce(lessonAdmin, (draft) => {
			// @ts-ignore
			draft[trainingPropName] = propValue
		})

		updateLessonMutation.mutate(lessonCopy)
	}
}

/** Функция, выполняемая при появлении ошибки после запроса на добавление сотрудника в группы */
function mutateOnSuccess(
	queryClient: QueryClient,
	updatedLessonRes: AxiosResponse<TrainingEntityTypes.LessonAdmin>
) {
	// Закомментировано потому что в updatedLessonRes нет упражнений. Сервер не присылает.
	/*queryClient.setQueryData(
		[lessonsQuery.getLesson(updatedLessonRes.data.id).key],
		(old) => updatedLessonRes
	)*/
}

/** Функция, выполняемая при появлении ошибки после запроса на добавление сотрудника в группы */
function mutateOnError(err: unknown) {
	message.error('При сохранении урока произошла ошибка')
}
