import { useCallback } from 'react'
import { produce } from 'immer'
import { useTranslation } from 'react-i18next'
import { getFormState, setFormState } from '../../common/exerciseFormCommonFunc'
import { TrueOrFalseExerciseFormStateType } from './stateType'
import { getStatementById } from './check'

type GeneratedObjType = {
	sentence: string
	correct: boolean
}

/**
 * Возвращает функцию получающая сгенерированный текст, разбирает его
 * и помещает данные в поле с текстом вопроса и вариантами ответа
 */
export function useGetGeneratedTextAndSetToStore(statementId: number) {
	const { t } = useTranslation()

	return useCallback(function (generatedObj: string) {
		// В generatedObj будет объект вида:
		// {sentence: 'A rainbow appears when it snows.', correct: false}

		if (!ifGeneratedObjHasRightStructure(generatedObj)) {
			setGenerateErrorToExerciseStore(
				statementId,
				'Сгенерированные данные не в нужном формате. Попробуйте переформулировать запрос.'
			)

			return
		}

		const formState = getFormState<TrueOrFalseExerciseFormStateType>()

		const newState = produce(formState, (draft) => {
			putGeneratedDataToQuestion(
				draft,
				statementId,
				// @ts-ignore
				generatedObj as GeneratedObjType
			)
		})

		setFormState(newState)
	}, [])
}

/**
 * Проверяет соответствует ли переданный объект типу GeneratedObjType
 * @param {Object} generatedObj — данные, сгенерированные ChatGPT
 */
function ifGeneratedObjHasRightStructure(generatedObj: unknown) {
	if (!generatedObj || typeof generatedObj !== 'object') return false

	if (!('sentence' in generatedObj && 'correct' in generatedObj)) {
		return false
	}

	// @ts-ignore
	if (typeof generatedObj.sentence !== 'string') {
		return false
	}

	// @ts-ignore
	if (typeof generatedObj.correct !== 'boolean') {
		return false
	}

	return true
}

/**
 * Получает объект сгенерированный ChatGPT и на его основе делает вопрос и его ответы
 * @param {Object} formState — Объект с данными упражнения
 * @param {Number} statementId — id объекта вопроса
 * @param {Object} generatedObj — данные, сгенерированные ChatGPT
 */
function putGeneratedDataToQuestion(
	formState: TrueOrFalseExerciseFormStateType,
	statementId: number,
	generatedObj: GeneratedObjType
) {
	const statement = getStatementById(formState, statementId)
	if (!statement) return

	statement.text = generatedObj.sentence
	statement.correct = generatedObj.correct
}

/**
 * Возвращает функцию получающую текст запроса на генерирование текста.
 * Эта информация сохраняется в данных упражнения.
 */
export function useGetGeneratedPromptAndSetToStore(statementId: number) {
	return useCallback(function (generatedPrompt: string) {
		const formState = getFormState<TrueOrFalseExerciseFormStateType>()

		const newState = produce(formState, (draft) => {
			const statement = getStatementById(draft, statementId)
			if (!statement) return

			statement.generatedTextPrompt = generatedPrompt
		})

		setFormState(newState)
	}, [])
}

/**
 * Ставит ошибку в объект Состояния вопроса упражнения
 * @param {Number} statementId — id объекта вопроса
 * @param {String} error — сообщение об ошибке
 */
function setGenerateErrorToExerciseStore(
	statementId: number,
	error: null | string
) {
	const formState = getFormState<TrueOrFalseExerciseFormStateType>()

	const newState = produce(formState, (draft) => {
		const question = getStatementById(draft, statementId)
		if (!question) return

		question.generatedTextError = error
	})

	setFormState(newState)
}
