import React from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { PaymentSystemCommonDataType } from '../paymentSystems'
import './PaymentConditionsBlock.scss'

type ConditionsProps = {
	// Расположение блока: горизонтальное или вертикальное
	view: 'horizontal' | 'vertical'
	data: PaymentSystemCommonDataType
}

// Блок с расписанными характеристиками платёжной системы
function PaymentConditionsBlock(props: ConditionsProps) {
	const { data, view } = props
	const { t } = useTranslation()

	return (
		<div
			className={cn('payment-conditions', 'payment-conditions--' + view)}
		>
			<div className="payment-conditions__part">
				<div>
					<p className="payment-condition__header">
						{t('myPayments.paymentFormDataCommission')}
					</p>
					<p className="payment-condition__text">
						От {data.commissionFrom}%
					</p>
				</div>
				<div>
					<p className="payment-condition__header">
						{t('myPayments.paymentFormDataCurrenciesFrom')}
					</p>
					<p className="payment-condition__text">{data.currencies}</p>
				</div>
			</div>
			<div className="payment-conditions__part">
				<div>
					<p className="payment-condition__header">
						{t('myPayments.paymentFormDataConnect')}
					</p>
					<Categories data={data} />
				</div>
				{view == 'vertical' && (
					<div>
						<p className="payment-condition__header">
							{t(
								'myPayments.paymentFormDataWhichCountriesDoesItWork'
							)}
						</p>
						<p className="payment-condition__text">
							{data.countries}
						</p>
					</div>
				)}
			</div>
		</div>
	)
}

export default PaymentConditionsBlock

type CategoriesProps = {
	data: PaymentSystemCommonDataType
}

function Categories(props: CategoriesProps) {
	const { data } = props
	const { t } = useTranslation()

	return (
		<div className="payment-condition__categories">
			<Category
				isOn={data.categories.individuals}
				text={t('myPayments.paymentFormDataCategoryIndividuals')}
			/>
			<Category
				isOn={data.categories.entity}
				text={t('myPayments.paymentFormDataCategoryLegalEntity')}
			/>
			<Category
				isOn={data.categories.entrepreneur}
				text={t('myPayments.paymentFormDataCategoryEntrepreneur')}
			/>
		</div>
	)
}

type CategoryProps = {
	isOn: boolean
	text: string
}

function Category(props: CategoryProps) {
	const { isOn, text } = props

	return (
		<p className="payment-condition__category">
			<img
				src={
					isOn
						? '/images/signs/CheckCircleTwoTone.svg'
						: '/images/signs/CloseCircleTwoTone.svg'
				}
				alt=""
			/>
			<span>{text}</span>
		</p>
	)
}
