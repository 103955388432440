import { useEffect } from 'react'
import { FormInstance, UploadFile } from 'antd'
import useAddLessonModalStore from '../zustand/store'
import { setErrorsToFields } from 'parts/utils/form'

export enum FieldNames {
	Name = 'name',
	Description = 'description',
	Cover = 'cover',
	isFree = 'isFree',
}

export type FormValuesType = {
	[FieldNames.Name]: string
	[FieldNames.Description]: string
	[FieldNames.Cover]: UploadFile[]
	[FieldNames.isFree]: boolean
}

/** Показывает в форме ошибки в случае появления */
export function setErrorsToForm(form: FormInstance) {
	const formErrors = useAddLessonModalStore((state) => state.formErrors)

	useEffect(() => {
		setErrorsToFields(form, formErrors)
	}, [formErrors])
}
