import { Button } from 'antd'
import { LandingTypes } from 'parts/types/LandingTypes'
import faqBlocksManager from 'pages/landing/landingBuilder/sections/faqSection/faqBlocksManager/faqBlocksManager'
import useLandingBuilderStore from '../../../zustand/store'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import './FaqBlock.scss'

type BlockProps = {
	blockIdx: number
	block: LandingTypes.FaqBlock
}

export function Block(props: BlockProps) {
	const { blockIdx, block } = props

	const landing = useLandingBuilderStore((store) => store.landing)
	const toggleVisibility = faqBlocksManager.toggleBlockVisibility(block.id)

	return (
		<article className="landing-faq-block">
			<div className="landing-faq-block__text-part">
				<h3 className="landing-faq-block__question">
					{landing.sections.faq.faqBlocks[blockIdx].question}
				</h3>
				{block.isOpen && (
					<p className="landing-faq-block__answer">
						{landing.sections.faq.faqBlocks[blockIdx].answer}
					</p>
				)}
			</div>

			<div className="landing-faq-block__right-part">
				<Button
					icon={
						block.isOpen ? (
							<MinusCircleOutlined />
						) : (
							<PlusCircleOutlined />
						)
					}
					onClick={toggleVisibility}
				/>
			</div>
		</article>
	)
}
