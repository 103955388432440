import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import AppUrls from 'parts/constants/pageUrl'
import './NoGroupsInfo.scss'

type NoGroupsInfoProps = {
	trainingId: number
}

// Сообщение об отсутствии групп в курсе
function NoGroupsInfo(props: NoGroupsInfoProps) {
	const { trainingId } = props

	const { t } = useTranslation()

	return (
		<div className="no-groups-info">
			<img
				src="images/illustrations/2.svg"
				className="no-groups-info__illustration"
				alt=""
			/>
			<p className="no-groups-info__text">
				Для этого курса не создано ни одной группы.
				<br />
				Чтобы создать группу перейдите в{' '}
				<Link
					to={AppUrls.Training_Groups(trainingId).url}
					className="link"
				>
					Настройки групп
				</Link>
			</p>
		</div>
	)
}

export default NoGroupsInfo
