import React from 'react'

function CheckBold() {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.5659 4.56594L6.00026 13.1316L1.43457 8.56594L2.56594 7.43457L6.00026 10.8689L13.4346 3.43457L14.5659 4.56594Z"
			/>
		</svg>
	)
}

export default CheckBold
