import { useState } from 'react'
import LiveLessonEntityTypes from 'parts/types/LiveLessonEntityTypes'
import { useGetDeleteStorageItem } from '../../../fn/deleteStorageItem'
import fileRequests from 'parts/requests/files/fileRequest'
import useLiveLibraryStore from '../zustand/store'

function useDeleteStorageItems() {
	const onDeleteStorageItem = useGetDeleteStorageItem()
	const [isShowConfirm, setIsShowConfirm] = useState(false)
	const [items, setItems] = useState<LiveLessonEntityTypes.StorageItem[]>([])
	const setSelectedItems = useLiveLibraryStore(
		(store) => store.setSelectedItems
	)

	const onConfirmOk = async () => {
		const promises = items.map((item) => {
			if (item.isFolder) {
				// TODO: удалять рекурсивно все вложенные папки и файлы в них
				onDeleteStorageItem(item.id)
			} else {
				onDeleteStorageItem(item.id).then(() => {
					const url = new URL(item.s3link)
					const filePath = url.pathname.slice(1)

					fileRequests.deleteFile({ filePath })
				})
			}
		})
		await Promise.all(promises)
		setIsShowConfirm(false)
		setSelectedItems([])
	}

	const onConfirmCancel = () => {
		setIsShowConfirm(false)
	}

	const deleteItems = (storageItems: LiveLessonEntityTypes.StorageItem[]) => {
		setItems(storageItems)
		setIsShowConfirm(true)
	}

	return {
		isShowConfirm,
		onConfirmOk,
		onConfirmCancel,
		deleteItems,
	}
}

export default useDeleteStorageItems
