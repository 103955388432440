import { create } from 'zustand'
import { PayModalStoreType } from './storeType'

const usePayModalStore = create<PayModalStoreType.State>((set) => {
	return {
		// Открыто ли модальное окно оплаты курса
		isModalOpen: false,
		tariff: null,
		price: null,
		formErrors: {},

		/**
		 * Установщик новых значений корневого объекта.
		 * @param obj — объект со свойствами и значениями, которые должны быть изменены в Сотоянии
		 */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default usePayModalStore
