import { useTranslation } from 'react-i18next'
import { EXERCISE_DONE } from '../../../common/exerciseConst'
import { ExerciseControlPanelButton } from '../../../common/ExerciseControlPanelContainer/ExerciseControlPanelContainer'
import { useGetDoneExercise } from '../../../common/doneExercise'
import { useGetGoToNextExercise } from '../../../common/prevAndNextExerciseUrls'
import useExerciseStore from '../../zustand/exerciseState'
import { useGetCheckExerciseFn } from '../../DropWordExerciseView/fn/html'
import { useGetAfterDoneExercise } from './afterDoneExercise'
import { useGetShowResult } from './showResult'
import { useGetOneMoreTime } from './oneMoreTime'

/**
 * Возвращает массив с настройками кнопок для передачи в компонент ExerciseControlPanelContainer
 */
export function useGetButtonsConfig(): ExerciseControlPanelButton[] {
	const { t } = useTranslation()

	const stage = useExerciseStore((store) => store.stage)
	const attempts = useExerciseStore((store) => store.attempts)
	const doneCounter = useExerciseStore((store) => store.doneCounter)

	// Функция, выполняемая при нажатии на кнопку «Выполнено».
	const doneExercise = useGetDoneExercise(
		useGetAfterDoneExercise(),
		useExerciseStore.getState().durationsInSeconds[
			useExerciseStore.getState().durationsInSeconds.length - 1
		]
	)

	// Функция, обнуляющая изменения сделанные пользователем
	const oneMoreTime = useGetOneMoreTime()

	// Функция, проверяющая упражнения после изменений учеником
	const checkExercise = useGetCheckExerciseFn()

	// Функция, показывающая экран с результатом выполнения упражнения
	const showResultStats = useGetShowResult()

	// Функция открывающая следующее упражнение если упражнение существует
	// или undefined если не существует.
	const goToNextExercise = useGetGoToNextExercise()

	// Кнопка перемещающая к новому упражнению
	function getNewExerciseBtn(
		text: 'next' | 'skip'
	): ExerciseControlPanelButton {
		const tMap = {
			next: t('exercises.controlBtnNextExercise'),
			skip: t('exercises.controlBtnSkipExercise'),
		}

		return {
			text: tMap[text],
			active: !!goToNextExercise,
			onClick: goToNextExercise,
			dataCyAttr: 'next-exercise-btn',
		}
	}

	// Кнопка уменьшающая количество попыток
	const oneMoreTimeBtn: ExerciseControlPanelButton = {
		text: t('exercises.controlBtnNewAttempt'),
		active: true,
		onClick: oneMoreTime,
		dataCyAttr: 'one-more-time-exercise-btn',
	}

	// Кнопка отмечающая упражнение выполненным
	const doneBtn: ExerciseControlPanelButton = {
		text: t('exercises.controlBtnDone'),
		active: true,
		primary: true,
		onClick: doneExercise,
		dataCyAttr: 'done-exercise-btn',
	}

	// Кнопка проверяющая упражнение
	const checkBtn: ExerciseControlPanelButton = {
		text: t('exercises.controlBtnCheck'),
		active: stage === 'ReadyForCheck',
		primary: stage === 'ReadyForCheck',
		onClick: checkExercise,
		dataCyAttr: 'check-exercise-btn',
	}

	// Кнопка показывающая экран статистики выполнения упржанения
	const showResultBtn: ExerciseControlPanelButton = {
		text: t('exercises.controlBtnResult'),
		active: true,
		primary: true,
		onClick: showResultStats,
		dataCyAttr: 'result-exercise-btn',
	}

	if (stage === 'NotReadyToCheck' || stage === 'ReadyForCheck') {
		return [getNewExerciseBtn('next'), checkBtn]
	} else if (stage === 'Checked') {
		const buttons: ExerciseControlPanelButton[] = []

		// Если попытки есть или нет
		buttons.push(attempts ? oneMoreTimeBtn : getNewExerciseBtn('next'))

		// Если можно пометить упражнение выполненным или нет
		buttons.push(doneCounter < EXERCISE_DONE ? doneBtn : showResultBtn)

		return buttons
	} else if (stage === 'Result') {
		const buttons: ExerciseControlPanelButton[] = []

		// Если попытки есть
		if (attempts) {
			buttons.push(oneMoreTimeBtn)
		}

		// Если можно пометить упражнение выполненным или нет
		buttons.push(
			attempts ? getNewExerciseBtn('skip') : getNewExerciseBtn('next')
		)

		return buttons
	}

	return []
}
