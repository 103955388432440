import { produce } from 'immer'
import { LandingTypes } from 'parts/types/LandingTypes'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import portraitBlocksManager from 'pages/landing/landingConstructor/sections/portraitSection/PortraitBlocksManager/PortraitBlocksManager'

/**
 * Добавляет новый блок в массив блоков
 * @param anchorBlockId — id блока, относительно которого будет добавляться новый блок.
 * Если null, тогда в массиве блоков пусто и новый будет добавлен первом элементом.
 * @param columnNum — номер колонки, куда будет добавлен новый блок.
 * @param position — позиция куда будет добавлен новый блок: сверху или снизу.
 */
export function useGetAddNewBlock(
	anchorBlockId: null | number,
	columnNum: 1 | 2,
	position: 'top' | 'bottom' = 'top'
) {
	const [landing, updateStore] = useLandingConstructorStore((s) => [
		s.landing,
		s.updateStore,
	])

	return function () {
		if (!landing) return

		const newBlock = createNewBlock(
			landing.sections.portrait.blocks,
			columnNum
		)

		const anchorBlockIdx = portraitBlocksManager.getBlockIdx(
			landing.sections.portrait.blocks,
			anchorBlockId
		)

		const updatedLanding = produce(landing, (draft) => {
			if (position === 'top') {
				draft.sections.portrait.blocks.splice(
					anchorBlockIdx,
					0,
					newBlock
				)
			} else {
				draft.sections.portrait.blocks.splice(
					anchorBlockIdx + 1,
					0,
					newBlock
				)
			}
		})

		updateStore({ landing: updatedLanding })
	}
}

/**
 * Создаёт объект нового блока с описанием пользователя которому подойдёт курс
 * @param blocks — массив блоков с описанием пользователя которому подойдёт курс
 * @param columnNum — номер колонки, куда будет добавлен новый блок.
 */
function createNewBlock(
	blocks: LandingTypes.PortraitBlock[],
	columnNum: 1 | 2
): LandingTypes.PortraitBlock {
	return {
		id: createNewBlockId(blocks),
		columnNum,
		colorTheme: 'white',
		header: '',
		text: '',
		imageUrl: '/landing/signs/empty-image.svg',
	}
}

/**
 * Возвращает id блока, который должен быть у нового блока
 * @param blocks — массив блоков с описанием пользователя которому подойдёт курс
 */
function createNewBlockId(blocks: LandingTypes.PortraitBlock[]) {
	let newBlockId = 0

	for (let i = 0; i < blocks.length; i++) {
		const currentBlock = blocks[i]

		if (currentBlock.id > newBlockId) {
			newBlockId = currentBlock.id
		}
	}

	return newBlockId + 1
}
