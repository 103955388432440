import { create } from 'zustand'
import { MenuSiderStoreType } from './storeTypes'

const useMenuSiderStore = create<MenuSiderStoreType.State>((set) => {
	return {
		isCollapsed: false,

		setIsCollapsed(collapsed) {
			return set((state) => {
				return {
					isCollapsed: collapsed,
				}
			})
		},
	}
})

export default useMenuSiderStore
