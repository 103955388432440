import React from 'react'
import { Modal, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { GrayBlock } from 'ui/wrappers'
import useLessonsPresetsStore from '../zustand/store'
import { useGetCloseModal, useDeletePreset } from './fn/actions'

export default function DeletePresetConfirmation() {
	const { t } = useTranslation()

	const isOpenDeletePresetConfirmation = useLessonsPresetsStore(
		(state) => state.isOpenDeletePresetConfirmation
	)
	const close = useGetCloseModal()
	const confirm = useDeletePreset()

	return (
		<Modal
			title={t('adminTraining.deletePresetModalTitle')}
			open={isOpenDeletePresetConfirmation}
			onCancel={close}
			onOk={confirm}
			okText={t('adminTraining.deletePresetModalOkBtn')}
			cancelText={t('adminTraining.deletePresetModalCancelBtn')}
		>
			<GrayBlock>
				<Typography>
					{t('adminTraining.deletePresetModalText')}
				</Typography>
			</GrayBlock>
		</Modal>
	)
}
