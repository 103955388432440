import { useCallback } from 'react'
import { AxiosError } from 'axios'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import { FieldNames, FormValuesType } from './form'
import useTransferStudentsModalStore from '../zustand/store'
import ServerTypes from 'parts/types/ServerTypes'
import GroupsApiTypes from 'parts/requests/group/groupsApiTypes'
import groupRequests from 'parts/requests/group/groupsRequest'
import { StaffTableAdminController } from '../../staffTable/StaffTable/StaffTableAdminController'
import UrlService from 'parts/services/UrlService'

/** Хук возвращающий обработчик отправки формы */
export function useGetOnSubmit() {
	const { t } = useTranslation()
	const groupId = UrlService.useGetGroupId()

	const updateModalState = useTransferStudentsModalStore(
		(state) => state.updateState
	)

	const updateGroupStaffTable = StaffTableAdminController.useGetUpdate()

	return useCallback(async (values: FormValuesType) => {
		try {
			updateModalState({ isSubmitting: true })

			// Отправить запрос и получить ответ
			const response = await groupRequests.moveStudentsBetweenCurators(
				getDto(groupId, values)
			)

			if (response.data) {
				// Закрыть модальное окно
				updateModalState({ isOpen: false })

				// Показать уведомление о переброске учеников другому куратору
				message.success('Ученики переданы.')

				updateModalState({ formErrors: {} })

				// Послать сигнал, что данные в таблице сотрудников группы устарели
				// и их нужно заново перекачасть.
				updateGroupStaffTable()
			}
		} catch (err) {
			const error = err as AxiosError<ServerTypes.ErrorResponse>

			if (error.response) {
				// Поставить данные ошибки в Состояние, чтобы показать их в форме
				updateModalState({
					formErrors: error.response.data,
				})
			}
		} finally {
			// Убрать статус загрузки
			updateModalState({ isSubmitting: false })
		}
	}, [])
}

/**
 * Формирует DTO для отправки запроса
 * @param {Array} values — массив значений полей формы
 * @param {Number} groupId — id группы
 */
function getDto(
	groupId: number,
	values: FormValuesType
): GroupsApiTypes.MoveStudentsBetweenCuratorsDto {
	return {
		groupId: groupId,
		fromCuratorId: values[FieldNames.FromCuratorId],
		toCuratorId: values[FieldNames.ToCuratorId],
		numOfStudents: values[FieldNames.StudentsCount],
	}
}
