import { Space } from 'antd'
import { LiveRoomsListController } from 'widgets/livelessons/liveRoomsList/LiveRoomsListController'
import { useGetUserRole } from 'parts/utils/hooks/hooks'
import AddLiveButton from '../AddLiveRoomButton/AddLiveButton'
import ArticleContentWrapper from 'ui/ArticleContentWrapper/ArticleContentWrapper'
import { EmptyBlock } from 'ui/EmptyBlock/EmptyBlock'
import LivelessonModal from '../CreateOrEditLiveRoomModal/LiveRoomModal'

function LiveRoomsArticle() {
	const liveLessonListRouter = LiveRoomsListController.router()

	return (
		<ArticleContentWrapper
			center={
				liveLessonListRouter.status == 'loading' ||
				liveLessonListRouter.status == 'noRooms'
			}
		>
			<Space
				direction="vertical"
				size="large"
				style={{ display: 'flex' }}
			>
				{liveLessonListRouter.status == 'noRooms' && <NoLiveLessons />}
				{liveLessonListRouter.status == 'hasRooms' &&
					liveLessonListRouter.element}
				<LivelessonModal />
			</Space>
		</ArticleContentWrapper>
	)
}

export default LiveRoomsArticle

function NoLiveLessons() {
	const userRoles = useGetUserRole()

	if (userRoles.isAdmin) {
		return (
			<EmptyBlock
				text={
					<>
						Для проведения индивидуальных и групповых
						<br />
						live-уроков нажмите кнопку ниже
					</>
				}
				button={<AddLiveButton />}
			/>
		)
	}

	return (
		<EmptyBlock text="Пока не создано ни одного live-урока с вашим участием" />
	)
}
