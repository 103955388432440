import EntityTypes from 'parts/types/EntityTypes'
import './Description.scss'

type DescriptionProps = {
	tariff: EntityTypes.Tariff
}

// Описание карточки
function Description(props: DescriptionProps) {
	const { tariff } = props

	if (!tariff.description) return null

	return <p className="p-tariff-card-description">{tariff.description}</p>
}

export default Description
