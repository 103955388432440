import { useEffect, useState } from 'react'

/**
 * Хук возвращает позицию сверху бокового блока с номерами уроков
 * Этот блок всегда должен быть виден при прокрутке.
 * Поэтому верхняя координата равна верхней координате его обёртке, а если верх обёртки скрылся за верхним краем экрана, то координата берётся от верхнего края окна.
 * Нижняя координата остаётся всегда внизу окна браузера
 */
export function useGetAsideTopPos() {
	const [top, setTop] = useState(0)

	useEffect(function () {
		// Обёртка контентной части. Сюда будет применён обработчик прокрутки
		const $homeLayoutArticle = document.getElementsByClassName(
			'home-layout__article'
		)[0]

		// Обёртка боковой части
		const $asideWrapper = document.getElementById('preview-exercise__aside')
		if (!$asideWrapper) return

		// Поставить css top при загрузке страницы
		const newTop = getAsideTopPos($asideWrapper)
		setTop(newTop)

		if (!$homeLayoutArticle) return

		// Обновлять css top при прокрутке
		$homeLayoutArticle.addEventListener('scroll', () => {
			const newTop = getAsideTopPos($asideWrapper)
			setTop(newTop)
		})
	}, [])

	return top
}

/**
 * Возвращает координату верхнего края обёртки боковой панели или 60 пикселей если обёртка
 * зашла за верхнюю часть экраначтобы вся боковая часть всегда была видима.
 * @param {HTMLElement} $asideWrapper — обёртка боковой части
 */
function getAsideTopPos($asideWrapper: HTMLElement): number {
	const asideTop = $asideWrapper.getBoundingClientRect().top
	return asideTop > 60 ? asideTop : 60
}
