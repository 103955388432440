import React from 'react'
import TopToolbar from 'pages/landing/landingConstructor/details/TopToolbar/TopToolbar'
import TariffsSection from 'pages/landing/landingConstructor/tariffs/general/TariffsSection/TariffsSection'
import { ColorSchemeModalController } from 'pages/landing/landingConstructor/details/ColorSchemeModal/ColorSchemeModalController'
import SectionOuter from 'pages/landing/landingConstructor/sections/SectionOuter/SectionOuter'
import useLandingConstructorStore from 'pages/landing/landingConstructor/zustand/store'
import { useGetToggleSectionVisibility } from 'pages/landing/landingConstructor/main/LandingConstructor/fn/changeBlockVisibility'
import MainSection from 'pages/landing/landingConstructor/sections/MainSection/MainSection'
import VideoSection from 'pages/landing/landingConstructor/sections/VideoSection/VideoSection'
import PortraitSection from 'pages/landing/landingConstructor/sections/portraitSection/PortraitSection/PortraitSection'
import ExistenceSection from 'pages/landing/landingConstructor/sections/existenceSection/ExistenceSection/ExistenceSection'
import TimeSection from 'pages/landing/landingConstructor/sections/timeSection/TimeSection/TimeSection'
import ProgramSection from 'pages/landing/landingConstructor/sections/programSection/ProgramSection/ProgramSection'
import FaqSection from 'pages/landing/landingConstructor/sections/faqSection/FaqSection/ExistenceSection'
import WaysSection from 'pages/landing/landingConstructor/sections/waysSections/WaysSection/WaysSection'
import Footer from 'pages/landing/landingConstructor/details/Footer/Footer'
import 'pages/landing/landingConstructor/main/LandingConstructor/LandingConstructor.scss'

// Страница конструктора одностраничника когда данные уже загружены
function LandingConstructor() {
	return (
		<div className="land-const">
			<TopToolbar />
			{ColorSchemeModalController.element}
			<div className="land-const__sections">
				<MainPart />
				<VideoPart />
				<PortraitPart />
				<ExistencePart />
				<TimePart />
				<ProgramPart />
				<TariffsPart />
				<WaysPart />
				<FaqPart />
			</div>
			<Footer />
		</div>
	)
}

export default LandingConstructor

function MainPart() {
	const landing = useLandingConstructorStore((store) => store.landing)
	const toggleBlockVisibility = useGetToggleSectionVisibility('main')

	return (
		<SectionOuter
			width="big"
			isOpen={landing.sections.main.isOpen}
			onChangeVisibilityButtonClick={toggleBlockVisibility}
			helpTexts={[
				'Дисклеймер: Любой текст, заголовок или изображение в конструкторе сайта  Вы можете заменить на подходящий именно вам. Этот блок - первое что увидят ваши клиенты. Он должен сразу давать понять куда ваш клиент попал, что вы предлагаете и как это купить (кнопка призыв к действию).  Замените текст ниже на свой, исходя из рекомендаций выше. ',
				'Постарайтесь сделать качественное фото, однако картинка не должна занимать много места. Сожмите ее в любом онлайн редакторе, просто вбейте в google сжать изображение. Чем больше картинка - тем дольше загружается сайт. Это напрямую влияет на продажи.',
			]}
		>
			<MainSection />
		</SectionOuter>
	)
}

function VideoPart() {
	const landing = useLandingConstructorStore((store) => store.landing)
	const toggleBlockVisibility = useGetToggleSectionVisibility('video')

	return (
		<SectionOuter
			width="middle"
			isOpen={landing.sections.video.isOpen}
			onChangeVisibilityButtonClick={toggleBlockVisibility}
			helpTexts={[
				'Дисклеймер: Любой текст, заголовок или изображение в конструкторе сайта  Вы можете заменить на подходящий именно вам.',
				'Снимите небольшой ролик про ваш курс, длительность ролика 45-60 секунд. Постарайтесь в ролике рассказать чему научится ваш ученик, какие навыки получит после обучения. Блок с видео не обязательно должен присутствовать на сайте, поэтому если по каким-то причинам вы не готовы записывать видео, просто скройте этот блок. ',
			]}
		>
			<VideoSection />
		</SectionOuter>
	)
}

function PortraitPart() {
	const landing = useLandingConstructorStore((store) => store.landing)
	const toggleBlockVisibility = useGetToggleSectionVisibility('portrait')

	return (
		<SectionOuter
			width="middle"
			isOpen={landing.sections.portrait.isOpen}
			onChangeVisibilityButtonClick={toggleBlockVisibility}
			helpTexts={[
				'Дисклеймер: Любой текст, заголовок или изображение в конструкторе сайта  Вы можете заменить на подходящий именно вам.',
				'В этом блоке расскажите для кого вы создали курс. Подумайте, в какой ситуации может находится ваш потенциальный клиент. Возможно, у него должен  быть какой-то уровень знаний, чтобы пройти ваш курс.  С какими трудностями он сталкивается? К чему стремится? Как он решит свою проблему с помощью вашего курса?',
				'Помните, вся информация на вашем сайте должна подталкивать к покупке вашего клиента, чем больше клиент узнает себя в  вашем послании, тем более вероятно он совершит покупку.',
			]}
		>
			<PortraitSection />
		</SectionOuter>
	)
}

function ExistencePart() {
	const landing = useLandingConstructorStore((store) => store.landing)
	const toggleBlockVisibility = useGetToggleSectionVisibility('existence')

	return (
		<SectionOuter
			width="middle"
			isOpen={landing.sections.existence.isOpen}
			onChangeVisibilityButtonClick={toggleBlockVisibility}
			helpTexts={[
				'Дисклеймер: Любой текст, заголовок или изображение в конструкторе сайта  Вы можете заменить на подходящий именно вам.',
				'На первый взгляд этот блок дублирует предыдущий. На самом деле этот блок дополняет предыдущий.',
			]}
		>
			<ExistenceSection />
		</SectionOuter>
	)
}

function TimePart() {
	const landing = useLandingConstructorStore((store) => store.landing)
	const toggleBlockVisibility = useGetToggleSectionVisibility('time')

	return (
		<SectionOuter
			width="middle"
			isOpen={landing.sections.time.isOpen}
			onChangeVisibilityButtonClick={toggleBlockVisibility}
			helpTexts={[
				'Дисклеймер: Любой текст, заголовок или изображение в конструкторе сайта  Вы можете заменить на подходящий именно вам',
				'Мы уверены, что создание любого курса, начинается с вопроса: чему мой ученик научится после прохождения курса? Какие навыки он получит?',
				'Постарайтесь отвечать коротко, большие тексты часто остаются не прочитанными читать. ваш клиент должен быстро пробежаться глазами по тексту, чтобы понять чему он научится. ',
			]}
		>
			<TimeSection />
		</SectionOuter>
	)
}

function ProgramPart() {
	const landing = useLandingConstructorStore((store) => store.landing)
	const toggleBlockVisibility = useGetToggleSectionVisibility('program')

	return (
		<SectionOuter
			width="middle"
			isOpen={landing.sections.program.isOpen}
			onChangeVisibilityButtonClick={toggleBlockVisibility}
			helpTexts={[
				'Дисклеймер: Любой текст, заголовок или изображение в конструкторе сайта  Вы можете заменить на подходящий именно вам',
				'Блок программа обучения,  закрывает большое количество сомнений и возражений клиента. Посмотрев программу курса, он убедится, что обучение  му подходит, потому что  на курсе вы будете разбирать именно те темы, которые ему интересы. ',
				'Не переживайте, выше вы уже ответили на вопрос для кого этот курс, программа курса это лишний раз подтверждает и укрепляет уверенность клиента. ',
			]}
		>
			<ProgramSection />
		</SectionOuter>
	)
}

function TariffsPart() {
	const landing = useLandingConstructorStore((store) => store.landing)
	const toggleBlockVisibility = useGetToggleSectionVisibility('tariffs')

	return (
		<SectionOuter
			isOpen={landing.sections.tariffs.isOpen}
			onChangeVisibilityButtonClick={toggleBlockVisibility}
			helpTexts={[
				'Дисклеймер: Любой текст, заголовок или изображение в конструкторе сайта  Вы можете заменить на подходящий именно вам',
				'Совершив покупку на вашем сайте, вашему ученику автоматически придет письмо с доступом на платформу. В настройках курса вы уже указали будет ли ему доступен курс сразу или нет.',
				'Чтобы принимать оплату за курсы, вам необходимо подключить платежную систему здесь или воспользоваться нашей системой приема платежей, подробнее тут Мы позволяем принимать оплату в нескольких валютах, например, ваши клиенты из Европы могут оплачивать в евро,  а клиенты из России оплачивать в рублях, подробнее тут',
			]}
		>
			<TariffsSection />
		</SectionOuter>
	)
}

function WaysPart() {
	const landing = useLandingConstructorStore((store) => store.landing)
	const toggleBlockVisibility = useGetToggleSectionVisibility('ways')

	return (
		<SectionOuter
			width="middle"
			isOpen={landing.sections.ways.isOpen}
			onChangeVisibilityButtonClick={toggleBlockVisibility}
			helpTexts={[
				'Дисклеймер: Любой текст, заголовок или изображение в конструкторе сайта  Вы можете заменить на подходящий именно вам.',
				'Это сильный блок, который должен окончательно убедить клиента произвести покупку. Ведь он пришел на ваш сайт с надеждой изменить ту ситуацию, в которой оказался, напомните ему об этом.',
				'Конечно, он может уйти с сайта и не менять свою жизнь, а может сделать шаг, навстречу переменам. ваша задача рассказать ему об этом.',
			]}
		>
			<WaysSection />
		</SectionOuter>
	)
}

function FaqPart() {
	const landing = useLandingConstructorStore((store) => store.landing)
	const toggleBlockVisibility = useGetToggleSectionVisibility('faq')

	return (
		<SectionOuter
			width="middle"
			isOpen={landing.sections.faq.isOpen}
			onChangeVisibilityButtonClick={toggleBlockVisibility}
			helpTexts={[
				'Дисклеймер: Любой текст, заголовок или изображение в конструкторе сайта вы можете заменить на подходящий именно вам.',
				'Помните, основная задача сайта, закрыть возражения клиента и подтолкнуть его к  нужному действию. Какие вопросы могли остаться у вашего клиента, которые требуют ответа? ',
				'Поставьте себя на его место, что было бы вам интересно узнать о курсе перед его покупкой? Эти ответы еще на один шаг приближают вашего ученика купить курс.',
			]}
		>
			<FaqSection />
		</SectionOuter>
	)
}
