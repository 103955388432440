import PaymentTypes from 'parts/types/PaymentTypes'

// Тип с общими данными о платёжной системе
export type PaymentSystemCommonDataType = {
	// Путь до логотипа
	logo: string
	// Название платёжной системы
	name: string
	// Адрес сайта
	site: string
	// Минимальный размер комиссии в процентах
	commissionFrom: number
	// Строка с принимаемыми валютами
	currencies: string
	// В каких странах работает
	countries: string
	// Каким категориям доступна оплата
	categories: {
		// Частные лица
		individuals: boolean
		// Юр. лица
		entity: boolean
		// Предприниматели
		entrepreneur: boolean
	}
}

// Данные по платёжным системам
export const paymentSystemsData: Record<string, PaymentSystemCommonDataType> = {
	// Временно закомментировано
	[PaymentTypes.System.UKassa]: {
		logo: '/images/paySystemInSquare/yookassa.svg',
		name: 'ЮКаssa',
		site: 'yookassa.ru',
		commissionFrom: 2.5,
		currencies: 'Российские Рубли',
		countries: 'Россия, Казахстан, Беларусь, Армения и еще 23 страны',
		categories: {
			individuals: false,
			entity: true,
			entrepreneur: true,
		},
	},
	[PaymentTypes.System.Stripe]: {
		logo: '/images/paySystemInSquare/stripe.svg',
		name: 'Stripe',
		site: 'stripe.com',
		commissionFrom: 2.8,
		currencies: '125 валют',
		countries: 'Россия, Казахстан, Беларусь, Армения и еще 23 страны',
		categories: {
			individuals: true,
			entity: true,
			entrepreneur: true,
		},
	},
}
