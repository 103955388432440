import RestrictionsService from 'parts/services/RestrictionsService'
import { PageHeaderTab } from 'ui/pageHeader/Tabs/Tabs'
import AppUrls from 'parts/constants/pageUrl'

export function useGetTabsConfig() {
	const canIntegrateWithPaySystems =
		RestrictionsService.useCanIntegrateWithPaySystems()

	const tabs: PageHeaderTab[] = [
		{
			label: AppUrls.MySchools().name,
			address: AppUrls.MySchools().url,
		},
	]

	if (canIntegrateWithPaySystems) {
		tabs.push({
			label: AppUrls.MyPayments().name,
			address: AppUrls.MyPayments().url,
		})
	}

	// tabs.push(
	// 	{
	// 		label: AppUrls.MySubscription().name,
	// 		address: AppUrls.MySubscription().url,
	// 	},
	// 	{
	// 		label: AppUrls.MyPaymentsHistory().name,
	// 		address: AppUrls.MyPaymentsHistory().url,
	// 	}
	// )

	return tabs
}
