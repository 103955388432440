import { getFormState } from '../../common/exerciseFormCommonFunc'
import { TrueOrFalseExerciseFormStateType } from './stateType'

/** Функция перебирает объект состояния формы и возвращает булево значение есть ли там файл загружаемый в настоящий момент. */
export function isDownloadingFile() {
	const formState = getFormState<TrueOrFalseExerciseFormStateType>()

	for (let i = 0; i < formState.statements.length; i++) {
		const { image } = formState.statements[i]

		if (image.status == 'downloading') {
			return true
		}
	}

	return false
}

/**
 * Функция принимает объект состояния формы и находит объект утверждения по переданному идентификатору
 * @param {Object} formState — объект состояния формы
 * @param {Number} statementId — id объекта утверждения
 */
export function getStatementById(
	formState: TrueOrFalseExerciseFormStateType,
	statementId: number
) {
	return formState.statements.find((statement) => statement.id == statementId)
}
