import React from 'react'
import { Button, Form, Radio, Space } from 'antd'
import {
	FieldNames,
	getPaletteRadioClasses,
	useGetInitialValues,
} from 'pages/landing/landingConstructor/details/ColorSchemeModal/fn/form'
import { useGetSetVisibilityToModal } from 'pages/landing/landingConstructor/details/ColorSchemeModal/fn/openCloseModal'
import { useGetOnSubmit } from 'pages/landing/landingConstructor/details/ColorSchemeModal/fn/submit'
import ModalWithForm from 'ui/ModalWithForm/ModalWithForm'
import { GrayBlock } from 'ui/wrappers/GrayBlock/GrayBlock'
import {
	UFormNewBottomButtonsAfterGray,
	UFormNewButtonsContainer,
	UFormNewRow,
} from 'ui/UForm/UForm'
import { LandingTypes } from 'parts/types/LandingTypes'
import useColorSchemeModalStore from 'pages/landing/landingConstructor/details/ColorSchemeModal/zustand/store'
import 'pages/landing/landingConstructor/details/ColorSchemeModal/ColorSchemeModal.scss'

// Модальное окно указания цветовой схемы одностраничника
function ColorSchemeModal() {
	const [form] = Form.useForm()

	const initialValues = useGetInitialValues()
	const onSubmit = useGetOnSubmit()

	const modalState = useColorSchemeModalStore((state) => state)
	const closeModal = useGetSetVisibilityToModal(false)

	return (
		<ModalWithForm
			title="Изменение палитры"
			open={modalState.isOpen}
			onCancel={closeModal}
		>
			<Form
				form={form}
				name="colorScheme"
				layout="vertical"
				onFinish={onSubmit}
				initialValues={initialValues}
			>
				<GrayBlock>
					<PaletteRadios />
				</GrayBlock>
				<Bottom />
			</Form>
		</ModalWithForm>
	)
}

export default ColorSchemeModal

// Поля с почтой и телефоном
function PaletteRadios() {
	return (
		<UFormNewRow>
			<Form.Item name={FieldNames.ColorScheme}>
				<Radio.Group>
					<Space direction="vertical">
						<Radio value={LandingTypes.ColorScheme.ColorScheme_1}>
							<PaletteRadioValue paletteNum={1} />
						</Radio>
						<Radio value={LandingTypes.ColorScheme.ColorScheme_2}>
							<PaletteRadioValue paletteNum={2} />
						</Radio>
						<Radio value={LandingTypes.ColorScheme.ColorScheme_3}>
							<PaletteRadioValue paletteNum={3} />
						</Radio>
						<Radio value={LandingTypes.ColorScheme.ColorScheme_4}>
							<PaletteRadioValue paletteNum={4} />
						</Radio>
					</Space>
				</Radio.Group>
			</Form.Item>
		</UFormNewRow>
	)
}

type PaletteRadioValueProps = {
	paletteNum: 1 | 2 | 3 | 4
}

function PaletteRadioValue(props: PaletteRadioValueProps) {
	const { paletteNum } = props

	const circleColors = getPaletteRadioClasses(paletteNum)

	return (
		<div className="land-const-color-scheme-modal__radio">
			<div className="land-const-color-scheme-modal__radio-circles">
				<div className={circleColors[0]} />
				<div className={circleColors[1]} />
			</div>
			<p className="land-const-color-scheme-modal__radio-text">
				Палитра {paletteNum}
			</p>
		</div>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
// Кнопки подтверждения и закрытия окна
function Bottom() {
	const closeModal = useGetSetVisibilityToModal(false)

	return (
		<UFormNewBottomButtonsAfterGray>
			<div />
			<UFormNewButtonsContainer>
				<Button onClick={closeModal}>Отмена</Button>
				<Button type="primary" htmlType="submit">
					Сохранить
				</Button>
			</UFormNewButtonsContainer>
		</UFormNewBottomButtonsAfterGray>
	)
}
