import $api from '../http'
import UserApiTypes from './userApiTypes'
import EntityTypes from '../../types/EntityTypes'

// Запросы к методам работы с пользователями
const userRequests = {
	// Получение пользователей школы.
	getUsers(params?: UserApiTypes.GetUsersParams) {
		let paramsStr = createUserParamsStr(params)

		return $api.get<UserApiTypes.GetUsers>('/api/users/' + paramsStr)
	},

	// Получение пользователей школы.
	getUsersPaginated(params?: UserApiTypes.GetUsersParams) {
		let paramsStr = createUserParamsStr(params)

		return $api.get<UserApiTypes.GetUsersPaginated>(
			'/api/users/paginated' + paramsStr
		)
	},

	getStudentCount() {
		return $api.get<number>('/api/users/students-count')
	},

	getStudentCountToday() {
		return $api.get<number>('/api/users/students-count-today')
	},

	getStudentCountThisMonth() {
		return $api.get<number>('/api/users/students-count-this-month')
	},

	// Получение пользователя с переданным идентификатором
	getUser(userId: string | number) {
		return $api.get<UserApiTypes.GetUser>('/api/users/' + userId)
	},

	// Добавление нового пользователя (роль задаётся отдельным запросом)
	addUser(dto: UserApiTypes.AddUserDto) {
		return $api.post<UserApiTypes.AddUser>('/api/users', dto)
	},

	// Обновление пользователя с переданным идентификатором
	updateUser(dto: UserApiTypes.UpdateUserDto) {
		return $api.put<UserApiTypes.UpdateUser>('/api/users/', dto)
	},

	// Поиск пользователей по почте
	searchByEmail(dto: UserApiTypes.SearchByEmailDto) {
		return $api.post<UserApiTypes.SearchByEmail>(
			'/api/users/search-by-email',
			dto
		)
	},

	// Возвращает статус почты: не зарегистрирована, не подтверждена, подтверждена
	checkEmailStatus(dto: UserApiTypes.CheckEmailStatusDto) {
		return $api.post<UserApiTypes.CheckEmailStatus>(
			'/api/users/check-email',
			dto
		)
	},

	// Получение списка кураторов школы
	getCurators() {
		return $api.get<UserApiTypes.GetCurators>('/api/users/curators')
	},

	// Получение списка учеников школы администратором или менеджером
	getStudentsByAdmin() {
		return $api.get<UserApiTypes.GetAdminStudents>('/api/users/students')
	},

	// Получение списка учеников прикреплённых к куратору
	getCuratorStudents(
		curatorId: number | string,
		params?: UserApiTypes.GetCuratorStudentsParams
	) {
		let paramsStr = createUserParamsStr(params)

		return $api.get<UserApiTypes.GetCuratorStudents>(
			'/api/users/curator/' + curatorId + '/students' + paramsStr
		)
	},

	// Добавление ученика куратору
	addStudentToCurator(dto: UserApiTypes.AddStudentToCuratorDto) {
		return $api.post<UserApiTypes.AddStudentToCurator>(
			'/api/groups/curator/add-student',
			dto
		)
	},

	// Получение пользователей с определённой ролью
	getUserByRole(role: EntityTypes.UserRole) {
		return $api.get<UserApiTypes.GetUserByRole>(
			`/api/users/by-role/${role}`
		)
	},

	// Создание оплаты за курс в ручном режиме
	createManualPayment(dto: UserApiTypes.CreateManualPaymentDto) {
		return $api.post<UserApiTypes.CreateManualPayment>(
			'/api/payment/manual',
			dto
		)
	},

	// Получение журнала посещения пользователя
	getUserLogs(userId: number | string) {
		return $api.get<UserApiTypes.GetUserLogs>('/api/logs/' + userId)
	},

	// Блокировка пользователя
	banUser(dto: UserApiTypes.BanUserDto) {
		return $api.post<UserApiTypes.BanUser>('/api/users/ban', dto)
	},

	// Разблокировка пользователя
	unBanUser(dto: UserApiTypes.UnBanUserDto) {
		return $api.post<UserApiTypes.UnBanUser>('/api/users/unban', dto)
	},

	// Изменение почты пользователя
	updateEmail(dto: UserApiTypes.UpdateUserEmailDto) {
		return $api.post<UserApiTypes.UpdateUserEmail>('/api/users/email', dto)
	},

	// Изменение пароля пользователя с указанным id администратором
	updatePasswordByAdmin(dto: UserApiTypes.UpdatePasswordByAdminDto) {
		return $api.patch<UserApiTypes.UpdatePasswordByAdmin>(
			'/api/auth/password',
			dto
		)
	},

	// Отправка доступа на почту пользователя
	sendAccess(dto: UserApiTypes.SendAccessDto) {
		return $api.post<UserApiTypes.SendAccess>('/api/users/send-access', dto)
	},

	// Получение статистики по прохождению курсов учеником
	getStudentStatistics(userId: number | string) {
		return $api.get<UserApiTypes.StudentTrainingsVisitStats>(
			`api/users/${userId}/statistics`
		)
	},

	// Получение статистики по прохождению курса учеником
	getStudentStatistic(studentId: number | string, groupId: number | string) {
		return $api.get<UserApiTypes.StudentTrainingVisitStats>(
			`api/users/${studentId}/visits/${groupId}`
		)
	},
}

export default userRequests

function createUserParamsStr(params?: UserApiTypes.GetUsersParams) {
	let paramsStr = ''

	if (params && Object.keys(params).length) {
		let paramsParts = []

		if (params.filter) {
			for (let key in params.filter) {
				if (params.filter[key as keyof typeof params.filter] === '') {
					continue
				}
				if (key == 'roles') {
					params.filter[key]?.forEach((role, i) => {
						paramsParts.push(
							// @ts-ignore
							`filter[${key}]=${params.filter[key][i]}`
						)
					})
				} else {
					// @ts-ignore
					paramsParts.push(`filter[${key}]=${params.filter[key]}`)
				}
			}
		}

		if (params.offset !== undefined) {
			paramsParts.push('offset=' + params.offset)
		}

		if (params.limit !== undefined) {
			paramsParts.push('limit=' + params.limit)
		}

		paramsStr += '?' + paramsParts.join('&')
	}

	return paramsStr
}
