import { SyntheticEvent } from 'react'
import { FormInstance, message } from 'antd'
import { LiveRoomsListController } from 'widgets/livelessons/liveRoomsList/LiveRoomsListController'
import useDeleteModalStore from '../../../../modal/DeleteModal/store/store'
import { liveLessonQuery } from 'parts/requests/liveLesson/liveLessonQuery'
import useAddLiveModalStore from '../zustand/store'

/** Возвращает обработчик нажатия на кнопку удаления live-урока */
export function useGetDeleteLiveRoom(livelessonId: number, form: FormInstance) {
	const updateStore = useDeleteModalStore((state) => state.updateStore)

	// Функция помечает список неактуальным.
	// После этого React Query автоматически скачает новый список
	const updateDataInLiveList = LiveRoomsListController.useGetUpdate()

	const { mutate } = liveLessonQuery.deleteLiveLesson.useMutation({
		onError: () => {
			message.error('Не удалось удалить live-урок')
		},
		onSuccess: () => {
			const updateStore = useAddLiveModalStore.getState().updateState
			updateStore({ isOpen: false })

			message.success('Live-урок удалён')

			form.resetFields()

			updateDataInLiveList()
		},
	})

	return function (event: SyntheticEvent) {
		event.preventDefault()

		updateStore({
			isOpen: true,
			header: 'Удаление live-урока',
			text: 'Вы уверены, что хотите удалить live-урок?',
			onOk: () => mutate(livelessonId),
		})
	}
}
