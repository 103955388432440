import { create } from 'zustand'
import { CommentsStoreType } from './storeTypes'

const useCommentsStore = create<CommentsStoreType.State>((set) => {
	return {
		dataLoadingStatus: 'loading',
		currentPageNum: 1,
		totalComments: 0,
		comments: undefined as any,
		isArchived: false,

		/** Установщик новых значений корневого объекта. */
		updateStore(obj) {
			return set((state) => {
				return obj
			})
		},
	}
})

export default useCommentsStore
