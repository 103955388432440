import cn from 'classnames'
import { LandingTypes } from 'parts/types/LandingTypes'
import useLandingBuilderStore from '../../../zustand/store'
import './PortraitBlock.scss'

type BlockProps = {
	blockIdx: number
	block: LandingTypes.PortraitBlock
}

export function Block(props: BlockProps) {
	const { blockIdx, block } = props

	const landing = useLandingBuilderStore((store) => store.landing)
	const { colorScheme } = landing

	const articleClasses = ['landing-portrait-block']
	if (block.colorTheme == 'white') {
		articleClasses.push('landing-portrait-block--white')
	} else {
		articleClasses.push('landing-portrait-block--' + colorScheme)
	}

	return (
		<article className={cn(articleClasses)}>
			<BlockContent blockIdx={blockIdx} />
		</article>
	)
}

type BlockContentProps = {
	blockIdx: number
}

function BlockContent(props: BlockContentProps) {
	const { blockIdx } = props
	const landing = useLandingBuilderStore((store) => store.landing)

	return (
		<div className="landing-portrait-block__content">
			<div className="landing-portrait-block__text-part">
				<h3 className="landing-portrait-block__header">
					{landing.sections.portrait.blocks[blockIdx].header}
				</h3>
				<p className="landing-portrait-block__text">
					{landing.sections.portrait.blocks[blockIdx].text}
				</p>
			</div>
			<div className="landing-portrait-block__img-part">
				<img
					src={landing.sections.portrait.blocks[blockIdx].imageUrl}
					className="landing-portrait-block__block-img"
					alt=""
				/>
			</div>
		</div>
	)
}
