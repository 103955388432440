import { useEffect } from 'react'
import { useIsFirstRender } from 'parts/utils/hooks/hooks'
import { SaveExerciseChangesMadeByUser } from '../../../common/useLiftViewDuration'
import useExerciseStore, {
	ExerciseStateType,
} from '../../zustand/exerciseState'

type RestoreDataType = Omit<ExerciseStateType.State, 'updateStore'>

/**
 * Наблюдает за объектом состояния упражнения и передаёт функции сохраняющей его на сервере
 * чтобы при следующем открытии упражнения восстановить это состояние.
 * @param saveExerciseChangesMadeByUser — функция, которая будет вызываться при изменении упражнения пользователем.
 * В неё будут передаваться данные для восстановления этих изменений при открытии упражнения.
 */
export function useSaveStateOnServerForRestore(
	saveExerciseChangesMadeByUser?: SaveExerciseChangesMadeByUser
) {
	// Задержка, чтобы не сохранял данные после открытия страницы
	const isFirstRender = useIsFirstRender(2000)
	const store = useExerciseStore((store) => store)

	useEffect(
		function () {
			if (isFirstRender || !saveExerciseChangesMadeByUser) return

			const restoreData = createRestoreData(store)

			// Передать в функцию, которая сохранит данные на сервере.
			saveExerciseChangesMadeByUser(restoreData)
		},
		[store.saveStoreForRestore]
	)
}

function createRestoreData(store: ExerciseStateType.State): RestoreDataType {
	const { updateStore, ...storeClone } = store

	return storeClone
}

// ==========================

export function useRestoreExercise(
	exerciseRestoreDataPromise: Promise<RestoreDataType>
) {
	const updateStore = useExerciseStore((store) => store.updateStore)

	useEffect(function () {
		exerciseRestoreDataPromise.then((restoreData) => {
			if (!restoreData) return

			// Поставить сохранённые данные в Зустанд
			updateStore(restoreData)
		})
	}, [])
}
