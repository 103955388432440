import { FormInstance, message } from 'antd'
import { AxiosError } from 'axios'
import { LiveRoomsListController } from 'widgets/livelessons/liveRoomsList/LiveRoomsListController'
import { FieldNames, FormValuesType } from './formFields'
import useAddLiveModalStore from '../zustand/store'
import LiveLessonApiTypes from 'parts/requests/liveLesson/liveLessonApiTypes'
import { liveLessonQuery } from 'parts/requests/liveLesson/liveLessonQuery'
import ServerTypes from 'parts/types/ServerTypes'
import { AddLiveModalStoreType } from '../zustand/storeTypes'
import dayjs from 'dayjs'
import { QueryClient, useQueryClient } from 'react-query'

export function useGetOnSubmit(form: FormInstance) {
	const [updateStore, livelessonId] = useAddLiveModalStore((state) => [
		state.updateState,
		state.lessonId,
	])
	const queryClient = useQueryClient()

	const updateDataInLiveList = LiveRoomsListController.useGetUpdate()

	// Объект с методом mutate для создания запроса
	const addLivelessonMutation = liveLessonQuery.createOrUpdateOne.useMutation(
		{
			onMutate: getMutateOnBefore(updateStore),
			onError: getMutateOnError(updateStore),
			onSuccess: getMutateOnSuccess(
				updateStore,
				form,
				livelessonId,
				updateDataInLiveList,
				queryClient
			),
			onSettled: getMutateOnAfter(updateStore),
		}
	)

	return async function (values: FormValuesType) {
		addLivelessonMutation.mutate(getAddOrEditDto(values, livelessonId))
	}
}

function getMutateOnBefore(updateStore: AddLiveModalStoreType.UpdateState) {
	return function () {
		// Поставить статус загрузки
		updateStore({ isSubmitting: true })
	}
}

function getMutateOnError(updateStore: AddLiveModalStoreType.UpdateState) {
	return function (err: unknown) {
		const error = err as AxiosError<ServerTypes.ErrorResponse>

		if (error.response) {
			// Поставить данные ошибки в Состояние, чтобы показать их в форме
			updateStore({ formErrors: error.response.data })
		}

		message.error('Не удалось сохранить live-урок')
	}
}

function getMutateOnSuccess(
	updateStore: AddLiveModalStoreType.UpdateState,
	form: FormInstance,
	livelessonId: number,
	updateDataInLiveList: () => void,
	queryClient: QueryClient
) {
	return function () {
		// Закрыть модальное окно
		updateStore({ isOpen: false, lessonId: 0 })

		message.success('Live-урок сохранен')

		updateDataInLiveList()

		!livelessonId && form.resetFields()

		updateStore({ formErrors: {} })

		queryClient.invalidateQueries(
			liveLessonQuery.getLiveLesson(livelessonId).key
		)
	}
}

function getMutateOnAfter(updateStore: AddLiveModalStoreType.UpdateState) {
	return function () {
		// Убрать статус загрузки
		updateStore({ isSubmitting: false })
	}
}

/**
 * Возвращает DTO для создания или редактирования урока
 * @param {Object} values — данные введённые в форму
 * @param id — id урока если он есть
 */
function getAddOrEditDto(
	values: FormValuesType,
	id: number
): LiveLessonApiTypes.AddOrEditDto {
	const scheduledDate = getDateStr(values)

	return {
		id: id,
		title: values[FieldNames.Title],
		scheduledFor: scheduledDate,
		timeZone: values[FieldNames.TimeZone],
		teacherId: parseInt(values[FieldNames.Curator]),
		link: values[FieldNames.Link],
		participantsIds: values[FieldNames.Students],
	}
}

/**
 * Возвращает строку вида '2023-04-20T16:16:58.658Z'
 * @param values
 */
function getDateStr(values: FormValuesType) {
	const Date = values[FieldNames.Date]
	const Time = values[FieldNames.Time]

	if (!Date || !Time) return dayjs.toString()

	const exactDate = Date.hour(Time.hour()).minute(Time.minute())

	return exactDate.toString()
}
