import { create } from 'zustand'
import { LessonPageHeaderStoreType } from './storeTypes'

const useLessonPageHeaderAdminStore = create<LessonPageHeaderStoreType.State>(
	(set) => {
		return {
			dataLoadingStatus: 'loading',
			trainingAdmin: undefined as any,
			lessonAdmin: undefined as any,

			/** Установщик новых значений корневого объекта. */
			updateStore(obj) {
				return set((state) => {
					return obj
				})
			},
		}
	}
)

export default useLessonPageHeaderAdminStore
