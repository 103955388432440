import AppUrls from 'parts/constants/pageUrl'
import EditableHeader from 'ui/pageHeader/EditableHeader/EditableHeader'
import EditableDescription from 'ui/pageHeader/EditableDescription/EditableDescription'
import AddGroupButton from '../../articles/groups/AddGroupButton/AddGroupButton'
import AddLessonButton from '../AddLessonButton/AddLessonButton'
import TrainingAvatarUploadInput from '../TrainingAvatarUploadInput/TrainingAvatarUploadInput'
import { useFillInStore } from '../zustand/fillStore/fillInStore'
import {
	useGetInitialHeaderText,
	useGetSaveHeaderTextOnServerAfterChange,
} from './fn/headerAndDescription'
import { getBreadcrumbs } from './fn/breadcrumbs'
import { useGetTabs } from './fn/getTabsConfig'
import PageHeader from 'ui/pageHeader/PageHeader/PageHeader'
import UrlService from 'parts/services/UrlService'

/** Заголовок страниц уроков и групп */
function PageHeaderAdmin() {
	useFillInStore()

	const breadcrumbs = getBreadcrumbs()

	const { initialHeaderText, initialDescriptionText } =
		useGetInitialHeaderText()

	const saveHeader = useGetSaveHeaderTextOnServerAfterChange('name')
	const saveDescription =
		useGetSaveHeaderTextOnServerAfterChange('description')

	const tabsConfig = useGetTabs()

	return (
		<PageHeader
			breadcrumbs={breadcrumbs}
			image={<TrainingAvatarUploadInput />}
			header={
				<EditableHeader
					initialValue={initialHeaderText}
					afterChange={saveHeader}
					maxCharacters={255}
				/>
			}
			description={
				<EditableDescription
					initialValue={initialDescriptionText}
					afterChange={saveDescription}
					maxCharacters={255}
				/>
			}
			buttons={[<HeaderButton key="1" />]}
			tabs={tabsConfig}
		/>
	)
}

export default PageHeaderAdmin

// Кнопка добавления урока или группы в зависимости от страницы
export function HeaderButton() {
	const trainingId = UrlService.useGetTrainingId()

	if (location.pathname == AppUrls.Training(trainingId).url) {
		return <AddLessonButton key={1} />
	} else {
		return <AddGroupButton key={2} />
	}
}
