import React from 'react'
import { Pagination } from 'antd'
import useCommentsStore from 'pages/comments/zustand/store'
import { COMMENTS_LENGTH_IN_PAGE } from 'pages/comments/common/commentsConsts'
import { useGetChangePageNumber } from './fn/changePageNumber'

// Постраничная навигация по комментариям
function CommentsPagination() {
	const currentPageNum = useCommentsStore((store) => store.currentPageNum)
	const totalComments = useCommentsStore((store) => store.totalComments)

	const changePageNumber = useGetChangePageNumber()

	if (totalComments <= COMMENTS_LENGTH_IN_PAGE) return null

	return (
		<Pagination
			defaultCurrent={currentPageNum}
			current={currentPageNum}
			total={totalComments}
			defaultPageSize={COMMENTS_LENGTH_IN_PAGE}
			pageSize={COMMENTS_LENGTH_IN_PAGE}
			onChange={changePageNumber}
		/>
	)
}

export default CommentsPagination
