import cn from 'classnames'
import EntityTypes from 'parts/types/EntityTypes'
import PaymentTypes from 'parts/types/PaymentTypes'
import useLandingBuilderStore from '../../../zustand/store'
import { findMainPrice } from '../../common/findMainPrice'
import './Price.scss'

type PriceProps = {
	tariff: EntityTypes.Tariff
}

// Основная цена курса
function Price(props: PriceProps) {
	const { tariff } = props

	const colorScheme = useLandingBuilderStore(
		(store) => store.landing.colorScheme
	)

	const mainPrice = findMainPrice(tariff)
	if (!mainPrice) return null

	const { price, oldPrice, currency } = mainPrice

	return (
		<div className="p-tariff-card-price">
			<div className="p-tariff-card-price__part">
				<p
					className={cn(
						'p-tariff-card-price__price',
						'p-tariff-card-price__price--current--' + colorScheme
					)}
				>
					{price}
				</p>
				<p
					className={cn(
						'p-tariff-card-price__currency',
						'p-tariff-card-price__currency--current--' + colorScheme
					)}
				>
					{PaymentTypes.Currencies[currency].sign}
				</p>
			</div>
			{oldPrice > 0 && (
				<div className="p-tariff-card-price__part">
					<p className="p-tariff-card-price__price p-tariff-card-price__price--old">
						{oldPrice}
					</p>
					<p
						className={cn(
							'p-tariff-card-price__currency',
							'p-tariff-card-price__currency--old'
						)}
					>
						{PaymentTypes.Currencies[currency].sign}
					</p>
				</div>
			)}
		</div>
	)
}

export default Price
