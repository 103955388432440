import { useTranslation } from 'react-i18next'
import Loader from 'ui/Loader/Loader'
import { useFillInStore } from '../../zustand/fillStore/fillInStore'
import useLessonsStudentStore from '../../zustand/store'
import { TrainingStoreType } from '../../zustand/storeTypes'
import TrainingLessons from '../TrainingLessons'

type RouterReturn = {
	status: TrainingStoreType.DataLoadingStatus
	element: JSX.Element
}

/** Хук возвращающий статус компонента TrainingList и сам компонент в зависимости от статуса */
export function useGetLessonsRouter(): RouterReturn {
	const { t } = useTranslation()

	const dataLoadingStatus = useLessonsStudentStore(
		(state) => state.dataLoadingStatus
	)

	// Получить данные для отрисовки списка уроков и поместить в Состояние
	useFillInStore()

	if (dataLoadingStatus == 'loading') {
		return {
			status: dataLoadingStatus,
			element: <Loader />,
		}
	} else if (dataLoadingStatus === 'error') {
		return {
			status: dataLoadingStatus,
			element: <p>{t('studentTraining.errorWhileDownloadTraining')}</p>,
		}
	}

	return {
		status: dataLoadingStatus,
		element: <TrainingLessons />,
	}
}
