import React, { useEffect, useMemo, useState } from 'react'
import { animate } from 'parts/utils/animate'

export function getPercentage(total: number, passed: number) {
	return Math.round((passed / total) * 100)
}

export function getRingMetrics() {
	// Ширина или высота круга
	const radius = 70
	const diameter = radius * 2
	const lineWidth = 10

	return {
		radius,
		diameter,
		lineWidth,
		offset: radius + lineWidth / 2,
	}
}

export function useAnimateRing(diameter: number, percents: number) {
	const [strokeDasharray, setStrokeDasharray] = useState('0 0')

	useEffect(
		function () {
			createStrokeDasharray(diameter, percents, setStrokeDasharray)
		},
		[percents, diameter]
	)

	return strokeDasharray
}

function createStrokeDasharray(
	diameter: number,
	percents: number,
	setStrokeDasharray: React.Dispatch<React.SetStateAction<string>>
) {
	const fullLength = 3.1415 * diameter

	animate(
		(progress: number) => {
			let greenLineLength = (fullLength / 100) * percents * progress
			let gapLineLength = fullLength - greenLineLength

			setStrokeDasharray(greenLineLength + ' ' + gapLineLength)
		},
		() => 1,
		1000
	)
}

/**
 * Возвращает строку с временем выполнения упражнения вида: 01:34:09
 * @param milliseconds
 */
export function getTimeFromMs(milliseconds: number) {
	return useMemo(function () {
		const diffDate = new Date(milliseconds)

		let hours = setZero(getHours(diffDate))
		let minutes = setZero(diffDate.getMinutes())
		let seconds = setZero(diffDate.getSeconds())

		return hours + ':' + minutes + ':' + seconds
	}, [])
}

function getHours(date: Date) {
	const diffHours = date.getTimezoneOffset() / 60

	return diffHours > 0
		? date.getHours() - diffHours
		: date.getHours() + diffHours
}

function setZero(num: number) {
	return num < 10 ? '0' + num : num.toString()
}
