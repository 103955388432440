import { Button, Form, Input } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import {
	onSchoolDomainInputChange,
	setErrorsToForm,
	useManageSchoolDomainInput,
} from './fn/form'
import { useGetOnSubmit } from './fn/submit'
import Site from 'parts/constants/site'
import {
	UFormNewBottomButtonsWithBorder,
	UFormNewGeneralError,
	UFormNewRow,
} from 'ui/UForm/UForm'
import { FieldNames } from './fn/form'
import useMakeSchoolStore from './zustand/store'
import './MakeSchoolForm.scss'

// Формы регистрации администратора
function MakeSchoolForm() {
	const [form] = Form.useForm()

	const onSubmit = useGetOnSubmit()
	setErrorsToForm(form)

	const formErrors = useMakeSchoolStore((state) => state.formErrors)

	return (
		<Form
			form={form}
			name="schoolCreation"
			onFinish={onSubmit}
			className="reg-owner-school-page-content"
			layout="vertical"
		>
			<SchoolNameInput />
			<SchoolDomainInput />
			<UFormNewGeneralError message={formErrors.message} />
			<Bottom />
		</Form>
	)
}

export default MakeSchoolForm

function SchoolNameInput() {
	const { t } = useTranslation()

	return (
		<UFormNewRow>
			<Form.Item
				name={FieldNames.SchoolName}
				label={t('auth.schoolCreationFormFieldNameLabel')}
				rules={[
					{
						required: true,
						message: t('auth.schoolCreationFormFieldNameRequired'),
					},
				]}
			>
				<Input type="text" size="large" />
			</Form.Item>
		</UFormNewRow>
	)
}

// Поле зависимое от значения другого поля
function SchoolDomainInput() {
	const { t } = useTranslation()

	const form = Form.useFormInstance()

	// Следить за изменениями поля названия школы и писать в это поле транслитерированное значение
	useManageSchoolDomainInput()

	return (
		<UFormNewRow topOffset>
			<Form.Item
				name="domain"
				label={t('auth.schoolCreationFormFieldDomainLabel')}
			>
				<Input
					addonBefore={Site.protocol}
					addonAfter={'.' + Site.rootDomain}
					type="text"
					size="large"
					onChange={(e) => {
						onSchoolDomainInputChange(e, form)
					}}
				/>
			</Form.Item>
		</UFormNewRow>
	)
}

// Кнопка отправки формы
// Кнопка возвращается в функции, чтобы получать обновляемые значения количества ошибок.
// Кнопка отправки не активная если в форме есть ошибки.
function Bottom() {
	const { t } = useTranslation()

	const form = Form.useFormInstance()
	const isSubmitting = useMakeSchoolStore((state) => state.isSubmitting)

	return (
		<UFormNewBottomButtonsWithBorder>
			<Form.Item
				shouldUpdate
				className="reg-owner-page-content__bottom-form-item"
			>
				{() => {
					const formErrors = form
						.getFieldsError()
						.filter(({ errors }) => errors.length)

					return (
						<Button
							size="large"
							block
							type="primary"
							htmlType="submit"
							disabled={isSubmitting || formErrors.length > 0}
							loading={isSubmitting}
						>
							{t('auth.schoolCreationFormOkButton')}{' '}
							<ArrowRightOutlined />
						</Button>
					)
				}}
			</Form.Item>
		</UFormNewBottomButtonsWithBorder>
	)
}
