import $api from '../http'
import TrainingApiTypes from './trainingApiTypes'

// Запросы к методам получения курсов
const trainingRequests = {
	// Получение списка курсов администратором. Ученик получает курсы по другому маршруту.
	getTrainingsForAdmin() {
		return $api.get<TrainingApiTypes.GetTrainingsForAdmin>('/api/trainings')
	},

	// Получение списка курсов учеником. Администратор получает курсы по другому маршруту.
	getAllTrainingsForStudent() {
		return $api.get<TrainingApiTypes.GetTrainingsForStudent>(
			'/api/profile/trainings'
		)
	},

	// Получение курса администратором
	async getTrainingForAdmin(trainingId: number | string) {
		return $api.get<TrainingApiTypes.GetTrainingForAdmin>(
			'/api/trainings/' + trainingId
		)
	},

	// Получение курса учеником
	async getTrainingForStudent(
		trainingId: number | string,
		groupId: number | string
	) {
		return $api.get<TrainingApiTypes.GetTrainingForStudent>(
			'/api/profile/trainings/' + trainingId + '/' + groupId
		)
	},

	// Добавление нового курса
	createTraining(data: TrainingApiTypes.CreateTrainingDto) {
		return $api.post<TrainingApiTypes.CreateTraining>(
			'/api/trainings',
			data
		)
	},

	// Обновление существующего курса
	updateTraining(data: TrainingApiTypes.UpdateTrainingDto) {
		return $api.patch<TrainingApiTypes.UpdateTraining>(
			'/api/trainings',
			data
		)
	},

	// Удаление курса
	deleteTraining(trainingId: number) {
		return $api.delete<TrainingApiTypes.DeleteTraining>(
			`/api/trainings/${trainingId}`
		)
	},

	// Получение идентификатора прогресса прохождения упражнения учеником
	// Метод нужен, чтобы сообщить серверу, что курс начали проходить
	openFirstLesson(groupId: number) {
		return $api.post<TrainingApiTypes.OpenFirstLesson>(
			'/api/trainings/open-first-lesson',
			{ groupId }
		)
	},

	// Получение тарифов по адресу одностраничника
	getTariffsByUrl(dto: TrainingApiTypes.GetTariffsByUrlDto) {
		return $api.post<TrainingApiTypes.GetTariffsByUrl>(
			'/api/trainings/tariffs-by-url',
			dto
		)
	},

	// Получение данных одностраничника по его адресу
	getLandingByUrl(landingUrl: string) {
		return $api.post<TrainingApiTypes.GetLandingByUrl>(
			'/api/trainings/landing',
			{ landingUrl }
		)
	},

	// Отправка ответа на звуковое упражнение или упражнение с картинками.
	sendAudioOrImageExerciseResult(
		dto: TrainingApiTypes.SendAudioOrImageExerciseResultDto
	) {
		return $api.post<TrainingApiTypes.sendAudioOrImageExerciseResult>(
			'/api/answer-feed',
			dto
		)
	},

	// Получение данных одностраничника для продажи указанного курса для редактирования (используется в конструкторе одностраничника)
	async getDraftTrainingLanding(trainingId: number | string) {
		return await $api.get<TrainingApiTypes.GetLanding>(
			'/api/trainings/' + trainingId + '/landing/draft'
		)
	},

	// Сохранение обновлённых данных одностраничника для продажи указанного курса
	async updateDraftTrainingLanding(
		details: TrainingApiTypes.UpdateDraftTrainingLandingData
	) {
		return await $api.put<TrainingApiTypes.UpdateDraftTrainingLanding>(
			'/api/trainings/' + details.trainingId + '/landing',
			details.dto
		)
	},

	// Получение опубликованных данных одностраничника для продажи указанного курса (используется на странице одностраничника)
	async getPublishedTrainingLanding(trainingId: number | string) {
		return await $api.get<TrainingApiTypes.GetPublishedTrainingLanding>(
			'/api/trainings/' + trainingId + '/landing'
		)
	},

	// Публикация редактируемого одностраничника для продажи указанного курса (берутся уже сохранённые данные для редактирования и копируются в ячейку с данными опубликованного одностраничника)
	async publishTrainingLanding(trainingId: number | string) {
		return await $api.post<TrainingApiTypes.PublishLanding>(
			'/api/trainings/' + trainingId + '/publish-landing'
		)
	},

	sendViewed(dto: TrainingApiTypes.SendViewedDto) {
		return $api.post('/api/trainings/viewed', dto)
	},
}

export default trainingRequests
