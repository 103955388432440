import cn from 'classnames'
import useLandingBuilderStore from '../../../zustand/store'
import './AsidePart.scss'

function AsidePart() {
	const landing = useLandingBuilderStore((store) => store.landing)
	const { colorScheme } = landing

	const headerClasses = [
		'landing-faq-aside__header',
		'landing-faq-aside__header--' + colorScheme,
	]

	return (
		<div className="landing-faq-aside">
			<h2 className={cn(headerClasses)}>
				{landing.sections.faq.header.text}
			</h2>
			<div className="landing-faq-aside__img-w">
				<img
					src={landing.sections.faq.header.imageUrl}
					className="landing-faq-aside__img"
					alt=""
				/>
			</div>
		</div>
	)
}

export default AsidePart
